export class FilterPanelFormControlNames {
    public static country: string = 'country';
    public static state: string = 'state';
    public static city: string = 'city';
    public static date: string = 'date';
    public static withholdingTypes: string = 'withholdingTypes';
    public static specialization: string = 'specialization';
    public static qualifier: string = 'qualifier';
}

// This is a way to override the default name property on the class.
Object.defineProperty(FilterPanelFormControlNames, 'name', { value: 'name', writable: false });
