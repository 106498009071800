import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, ReplaySubject } from 'rxjs';
import { BaseHttpService } from 'app/nexus-core/services/index';

@Injectable()
export class AuthenticatedUserSyncService {
    public isSyncedCompleted: ReplaySubject<boolean> = new ReplaySubject<boolean>();

    constructor(private baseHttpService: BaseHttpService) {
    }

    sync(): Observable<boolean> {
        const apiUrl = environment().webAPIUrls.auth + 'userSync/';
        return this.baseHttpService.post(apiUrl);
    }
}
