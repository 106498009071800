export class ClientFolderModel {
    companyKey: string;
    individualKey: string;
    companyName: string;
    companyLegalName: string;
    individualFirstName: string;
    individualLastName: string;
    individualEmailAddress: string;


    constructor(model: ClientFolderModel = null) {
        this.individualKey = model?.individualKey ?? null;
        this.companyKey = model?.companyKey ?? null;
        this.companyName = model?.companyName ?? null;
        this.companyLegalName = model?.companyLegalName ?? null;
        this.individualFirstName = model?.individualFirstName ?? null;
        this.individualLastName = model?.individualLastName ?? null;
        this.individualEmailAddress = model?.individualEmailAddress ?? null;
    }
}
