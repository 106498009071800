import { CheckboxSelectionCallbackParams, ColDef, ProcessCellForExportParams } from 'ag-grid-community';
import { CellTooltipComponent } from 'app/nexus-shared/components/controls/components/base-grid/cell-renderers/cell-tooltip/cell-tooltip.component';
import { AgGridHelper } from 'app/nexus-core/helpers/ag-grid.helper';
import { EnumHelper } from 'app/nexus-core/helpers/enum.helper';
import { Dictionary } from 'app/nexus-shared/models/dictionary';
import { AgGridColDefExtras } from 'app/nexus-shared/models/ag-grid-col-def-extras.model';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { CountryColumnComponent, CurrencyColumnComponent } from 'app/nexus-shared/components/controls';
import { LocationModel } from 'app/nexus-shared';
import { DateHelper } from 'app/nexus-core/helpers/date.helper';
import { NumberHelper } from 'app/nexus-core/helpers/number.helper';


export class AgGridColDefHelper {
    // column types
    static readonly actionColumn: string = 'action';
    static readonly checkboxSelectColumn: string = 'checkbox';
    static readonly countryColumn: string = 'country';
    static readonly currencyColumn: string = 'currency';
    static readonly dateColumn: string = 'date';
    static readonly dateTimeColumn: string = 'dateTime';
    static readonly editColumn: string = 'edit';
    static readonly iconCheckboxColumn: string = 'iconCheck';
    static readonly enumColumn: string = 'enum';
    static readonly moneyColumn: string = 'money';
    static readonly numberColumn: string = 'number';
    static readonly hourColumn: string = 'hour';
    static readonly percentageColumn: string = 'percentage';
    static readonly phoneNumberColumn: string = 'phoneNumber';
    static readonly yesNoColumn: string = 'yesNo';

    static readonly centerCellClass: string = 'center-cell';
    static readonly iconCellClass: string = 'icon-cell';
    static readonly iconCellField: string = 'icon-field';
    static readonly checkboxCellClass: string = 'icon-cell';

    static readonly checkboxCellField: string = 'checkbox-field';
    static readonly navigateCellField: string = 'navigate-field';
    static readonly previewCompanyColumnField: string = 'preview-company-field';
    static readonly previewIndividualColumnField: string = 'preview-individual-field';

    // preloaded column definitions, uses properties from default, then defined type(s) and then any props overwritten defined in the extras model

    static readonly defaultColDef: ColDef = {
        minWidth: 125,
        suppressMenu: false,
        enableRowGroup: true,
        enablePivot: true,
        columnsMenuParams: {
            suppressColumnFilter: true
        },
        comparator: AgGridHelper.stringComparator,
        filter: AgGridHelper.textColumnFilter,
        tooltipComponent: CellTooltipComponent,
        useValueFormatterForExport: false,
        sortable: true,
        resizable: true,
        cellDataType: 'text'
    };

    static readonly defaultAutoGroupColDef: ColDef = {
        sortable: true,
        comparator: null
    };

    static readonly actionColDef: ColDef = {
        maxWidth: 40,
        field: AgGridColDefHelper.iconCellField,
        sortable: false,
        resizable: false,
        filter: null,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
        cellClass: AgGridColDefHelper.iconCellClass,
        pinned: 'right'
    };

    static readonly checkboxColDef: ColDef = {
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        minWidth: 40,
        width: 40,
        maxWidth: 40,
        field: AgGridColDefHelper.checkboxCellField,
        sortable: false,
        resizable: false,
        filter: null,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
        cellClass: AgGridColDefHelper.checkboxCellClass,
        pinned: 'left',
        suppressSizeToFit: true,
        suppressMenu: true,
        suppressMovable: true,
        enableRowGroup: false,
        showRowGroup: false,
        checkboxSelection: params => {
            return true;
        },
        tooltipComponent: CellTooltipComponent
    };

    static readonly countryColDef: ColDef = {
        cellRenderer: CountryColumnComponent,
    };

    static readonly dateColDef: ColDef = {
        minWidth: 75,
        cellDataType: 'date',
        filter: AgGridHelper.dateColumnFilter,
        comparator: AgGridHelper.dateComparator,
        filterParams: AgGridHelper.defaultDateFilterParams,
        cellRenderer: AgGridHelper.dateCellRenderer,
        useValueFormatterForExport: true,
        cellClass: [AgGridHelper.dateExcelClass]

    };

    static readonly dateTimeColDef: ColDef = {
        minWidth: 75,
        cellDataType: 'date',
        comparator: AgGridHelper.dateComparator,
        filter: AgGridHelper.dateColumnFilter,
        filterParams: AgGridHelper.defaultDateFilterParams,
        cellRenderer: AgGridHelper.dateTimeCellRenderer,
        useValueFormatterForExport: true,
        cellClass: [AgGridHelper.dateTimeExcelClass],
    };

    static readonly currencyColDef: ColDef = {
        type: AgGridHelper.rightAligned,
        cellClass: [AgGridHelper.currencyExcelClass],
        minWidth: 75,
        cellDataType: 'number',
        useValueFormatterForExport: true,
        filter: AgGridHelper.numberColumnFilter,
        enableValue: true
    };

    static readonly editableColDef: ColDef = {
        editable: true
    };

    static readonly hourColDef: ColDef = {
        minWidth: 75,
        cellDataType: 'number',
        comparator: AgGridHelper.numberComparator,
        filter: AgGridHelper.numberColumnFilter,
        useValueFormatterForExport: true,
        cellClass: [AgGridHelper.numberExcelClass],
        enableValue: true,
        cellRenderer: (params): string => {
            if (params?.value) {
                const hours: number = Math.floor(params.value);
                const minutes: number = Math.floor((params.value - hours) * 60);
                return hours + '.' + (minutes < 10 ? '0' : '') + minutes;
            }
        },
        valueParser: (params) => {
            const [hours, minutes] = params.newValue.split('.');
            return parseInt(hours) + parseInt(minutes) / 60;
        }
    };

    static readonly iconCheckColDef: ColDef = {
        minWidth: 50,
        filter: AgGridHelper.setColumnFilter,
        cellRenderer: AgGridHelper.checkboxRenderer,
        cellDataType: 'boolean',
        cellClass: AgGridColDefHelper.centerCellClass,
        comparator: AgGridHelper.booleanSortingComparator
    };

    static readonly moneyColDef: ColDef = {
        minWidth: 75,
        cellDataType: 'number',
        comparator: AgGridHelper.numberComparator,
        cellRenderer: AgGridHelper.moneyCellRenderer,
        useValueFormatterForExport: true,
        filter: AgGridHelper.numberColumnFilter,
        type: AgGridHelper.rightAligned,
        cellClass: [AgGridHelper.currencyExcelClass],
        enableValue: true
    };

    static readonly numberColDef: ColDef = {
        minWidth: 75,
        cellDataType: 'number',
        comparator: AgGridHelper.numberComparator,
        filter: AgGridHelper.numberColumnFilter,
        useValueFormatterForExport: true,
        cellClass: [AgGridHelper.numberExcelClass],
        enableValue: true
    };

    static readonly percentageColDef: ColDef = {
        minWidth: 75,
        cellDataType: 'number',
        comparator: AgGridHelper.numberComparator,
        cellRenderer: AgGridHelper.percentageCellRenderer,
        useValueFormatterForExport: true,
    };

    static readonly phoneNumberColDef: ColDef = {
        minWidth: 75,
        cellRenderer: AgGridHelper.phoneNumberCellRenderer,
        useValueFormatterForExport: true,
    };

    static readonly yesNoColDef: ColDef = {
        minWidth: 50,
        filter: AgGridHelper.setColumnFilter,
        cellDataType: 'boolean',
        comparator: AgGridHelper.booleanSortingComparator
    };

    // custom column defs

    static getCheckboxColDef(showSelectAll: boolean = true, isCheckboxShownFunc: (params: CheckboxSelectionCallbackParams) => boolean = null, field: string = AgGridColDefHelper.checkboxCellField, extras: AgGridColDefExtras = null): ColDef {
        const colDef: ColDef = AgGridColDefHelper.colDefGenerator(field, '', [AgGridColDefHelper.checkboxSelectColumn], extras);

        colDef.headerCheckboxSelection = showSelectAll;
        if (isCheckboxShownFunc) {
            colDef.checkboxSelection = isCheckboxShownFunc;
        }

        return colDef;
    };

    static getActionColDef(icon: string, field: string = AgGridColDefHelper.iconCellField, isCheckValue: boolean = false, extras: AgGridColDefExtras = null): ColDef {
        const colDef: ColDef = AgGridColDefHelper.colDefGenerator(field, '', [AgGridColDefHelper.actionColumn], extras);
        colDef.cellRenderer = params => {
            if (!isCheckValue) {
                return `<i class="${icon} ${field}"></i>`;
            } else {
                if (ObjectHelper.getValueOfProperty(params?.data, field)) {
                    return `<i class="${icon} ${field}"></i>`;
                }
            }
            return;
        };
        return colDef;
    };

    static getCompanyPreviewColDef(extras: AgGridColDefExtras = null): ColDef {
        const colDef: ColDef = AgGridColDefHelper.colDefGenerator(AgGridColDefHelper.previewCompanyColumnField, '', [AgGridColDefHelper.actionColumn], extras);
        colDef.cellRenderer = params => {
            // use field for row click class on the preview column
            return `<i class="fa fa-building ${AgGridColDefHelper.previewCompanyColumnField}"></i>`;
        };
        colDef.pinned = 'left';
        AgGridColDefHelper.applyExtras(colDef, extras);
        return colDef;
    }

    static getCountryColDef(field: string, headerName: string, countries: LocationModel[], extras: AgGridColDefExtras = null): ColDef {
        const colDef = AgGridColDefHelper.colDefGenerator(field, headerName, null, extras);
        colDef.valueGetter = params => {
            return countries.find(x => x.id === ObjectHelper.getValueOfProperty(params.data, field))?.name ?? null;
        };

        return colDef;
    }

    static getCountryListColDef(field: string, headerName: string, countries: LocationModel[], nestedCountryField: string = null, extras: AgGridColDefExtras = null): ColDef {
        const colDef = AgGridColDefHelper.colDefGenerator(field, headerName, null, extras);
        colDef.valueGetter = params => {
            const value = ObjectHelper.getValueOfProperty(params.data, field) as Array<any>;
            return value.map(x => countries.find(y => y.id === (nestedCountryField ? ObjectHelper.getValueOfProperty(x, nestedCountryField) : x))?.name ?? null);
        };

        return colDef;
    }

    static getCurrencyColDef(field: string, headerName: string, currencyField: string = null, defaultCurrency: string = 'USD', extras: AgGridColDefExtras = null): ColDef {
        const colDef = AgGridColDefHelper.colDefGenerator(field, headerName, [AgGridColDefHelper.currencyColumn, AgGridHelper.rightAligned], extras);
        colDef.cellRenderer = CurrencyColumnComponent;
        colDef.cellRendererParams = params => {
            return {
                currencyField: currencyField,
                defaultCurrency: defaultCurrency
            };
        };
        colDef.useValueFormatterForExport = false;

        return colDef;
    }

    static getEnumColumnDef(field: string, headerName: string, enumValue: any, extras: AgGridColDefExtras = null, customDisplayNameFunc: (enumValue: number) => string = null): ColDef {
        const colDef = AgGridColDefHelper.colDefGenerator(field, headerName, null, extras);
        colDef.valueGetter = params => {
            return EnumHelper.getDisplayName(enumValue, ObjectHelper.getValueOfProperty(params.data, field), customDisplayNameFunc);
        };
        colDef.filter = AgGridHelper.setColumnFilter;
        colDef.filterParams = {
            values: EnumHelper.convertToSelectList(enumValue, false, false, null, null, customDisplayNameFunc).map(x => x.value)
        };

        return colDef;
    }

    static getEnumListColumnDef(field: string, headerName: string, enumValue: any, extras: AgGridColDefExtras = null): ColDef {
        const colDef = AgGridColDefHelper.colDefGenerator(field, headerName, null, extras);
        colDef.valueGetter = params => {
            const value = ObjectHelper.getValueOfProperty(params.data, field) as Array<any>;
            if (value?.length) {
                return value.map(x => {
                    return EnumHelper.getDisplayName(enumValue, x);
                });
            }
            return;
        };
        colDef.filter = AgGridHelper.setColumnFilter;
        colDef.filterParams = {
            values: EnumHelper.convertToSelectList(enumValue, false, false).map(x => x.value)
        };

        return colDef;
    }

    static getHourColDef(field: string, headerName: string, extras: AgGridColDefExtras = null): ColDef {
        return AgGridColDefHelper.colDefGenerator(field, headerName, [AgGridColDefHelper.hourColumn, AgGridHelper.rightAligned], extras);
    }

    static getIconCheckboxColDef(field: string, headerName: string, extras: AgGridColDefExtras = null) {
        return AgGridColDefHelper.colDefGenerator(field, headerName, AgGridColDefHelper.iconCheckboxColumn, extras);
    }

    static getIndividualPreviewColDef(extras: AgGridColDefExtras = null): ColDef {
        const colDef: ColDef = AgGridColDefHelper.colDefGenerator(AgGridColDefHelper.previewIndividualColumnField, '', [AgGridColDefHelper.actionColumn], extras);
        colDef.cellRenderer = params => {
            // use field for row click class on the preview column
            return `<i class="fas fa-id-badge ${AgGridColDefHelper.previewIndividualColumnField}"></i>`;
        };
        colDef.pinned = 'left';
        AgGridColDefHelper.applyExtras(colDef, extras);
        return colDef;
    };

    static getNavigateColDef(extras: AgGridColDefExtras = null, field: string = AgGridColDefHelper.navigateCellField, isCheckField: boolean = false): ColDef {
        return AgGridColDefHelper.getActionColDef('fa fa-right fa-blue', field, isCheckField, extras);
    }

    static getObjectListColumnDef(field: string, headerName: string, nestedField: string, enumValue: any = null, extras: AgGridColDefHelper = null, separator: string = ', '): ColDef {
        const colDef = AgGridColDefHelper.colDefGenerator(field, headerName, null, extras);

        colDef.cellRenderer = params => {
            if (params.value?.length) {
                return params.value.join(separator);
            }

            return params.value;
        };

        colDef.valueGetter = params => {
            const value = ObjectHelper.getValueOfProperty(params.data, field) as Array<any>;
            if (value?.length) {
                return value.map(x => {
                    const value: any = ObjectHelper.getValueOfProperty(x, nestedField);
                    if (enumValue) {
                        return EnumHelper.getDisplayName(enumValue, value);
                    }
                    return value;
                });
            }
            return;
        };

        colDef.filter = AgGridHelper.setColumnFilter;
        colDef.filterParams = {
            values: enumValue ? EnumHelper.convertToSelectList(enumValue, false, false).map(x => x.value) : null
        };

        return colDef;
    }

    static getDateColDef(field: string, headerName: string, extras: AgGridColDefExtras = null): ColDef {
        return AgGridColDefHelper.colDefGenerator(field, headerName, [AgGridColDefHelper.dateColumn], extras);
    }

    static getDateTimeColDef(field: string, headerName: string, extras: AgGridColDefExtras = null): ColDef {
        return AgGridColDefHelper.colDefGenerator(field, headerName, [AgGridColDefHelper.dateTimeColumn], extras);
    }

    static getMoneyColDef(field: string, headerName: string, extras: AgGridColDefExtras = null): ColDef {
        return AgGridColDefHelper.colDefGenerator(field, headerName, [AgGridColDefHelper.moneyColumn, AgGridHelper.rightAligned], extras);
    }

    static getNumberColDef(field: string, headerName: string, extras: AgGridColDefExtras = null): ColDef {
        return AgGridColDefHelper.colDefGenerator(field, headerName, [AgGridColDefHelper.numberColumn, AgGridHelper.rightAligned], extras);
    }

    static getPercentageColDef(field: string, headerName: string, extras: AgGridColDefExtras = null): ColDef {
        return AgGridColDefHelper.colDefGenerator(field, headerName, [AgGridColDefHelper.percentageColumn, AgGridHelper.rightAligned], extras);
    }

    static getPhoneNumberColDef(field: string, headerName: string, extras: AgGridColDefExtras = null): ColDef {
        return AgGridColDefHelper.colDefGenerator(field, headerName, [AgGridColDefHelper.phoneNumberColumn], extras);
    }

    static getRoundedDecimalColDef(field: string, headerName: string, extras: AgGridColDefExtras = null): ColDef {
        const colDef = AgGridColDefHelper.colDefGenerator(field, headerName, [AgGridColDefHelper.numberColumn, AgGridHelper.rightAligned], extras);
        colDef.cellRenderer = params => {
            return NumberHelper.roundingWithWholeNumberReturn(params.value);
        };
        return colDef;
    }

    static getYesNoColDef(field: string, headerName: string, extras: AgGridColDefExtras = null): ColDef {
        const colDef = AgGridColDefHelper.colDefGenerator(field, headerName, [AgGridColDefHelper.yesNoColumn], extras);
        colDef.valueGetter = params => {
            const value = ObjectHelper.getValueOfProperty(params.data, field);
            if (value) {
                return 'Yes';
            }
            return 'No';
        };
        colDef.filterParams = {
            values: ['Yes', 'No']
        };
        return colDef;
    }

    // generate col def

    static colDefGenerator(field: string, headerName: string, types: string | string[] = null, extras: AgGridColDefExtras = null, isMultiFilter: boolean = false): ColDef {
        const colDef: ColDef = { field: field, headerName: headerName, type: types };
        if (isMultiFilter) {
            colDef['filter'] = AgGridHelper.multiColumnFilter;
        }
        const extrasColDef = AgGridColDefHelper.applyExtras(colDef, extras, field);

        return Object.assign(colDef, extrasColDef ?? {});
    }

    static applyExtras(colDef: ColDef, extras: AgGridColDefExtras, field: string = null): ColDef {
        if (!extras) {
            return;
        }

        if (!extras?.colDef) {
            extras['colDef'] = {};
        }

        if (extras?.bind) {
            Object.keys(extras.bind).forEach(key => {
                extras.colDef[key] = extras.bind[key];
            });
        }

        if (extras?.hide) {
            extras.colDef.hide = true;
        }

        if (extras?.edit) {
            if (Array.isArray(colDef.type)) {
                colDef.type = colDef.type.concat([AgGridColDefHelper.editColumn]);
            } else {
                colDef.type = [colDef.type, AgGridColDefHelper.editColumn];
            }
        }
        if (extras?.tooltip) {
            extras.colDef.tooltipComponent = CellTooltipComponent;
            extras.colDef.tooltipField = field;
        }

        if (extras?.precision || extras?.precision === 0) {
            extras.colDef.cellRenderer = (params) => {
                if (params.value) {
                    const value: number = +(params.value?.toString() ?? null);
                    if (isNaN(value)) {
                        return null;
                    }

                    if (value) {
                        return value.toFixed(extras.precision);
                    }
                } else {
                    const value: number = +(ObjectHelper.getValueOfProperty(params.data, colDef.field)?.toString() ?? null);
                    if (isNaN(value)) {
                        return null;
                    }

                    if (value) {
                        return value.toFixed(extras.precision);
                    }
                }
            };
        }

        return extras.colDef;
    }

    static excelExportCellCallback(cell: ProcessCellForExportParams): any {
        const colDef = cell.column.getColDef();
        const cellClassArray = Array.isArray(colDef.cellClass) ? colDef.cellClass : [colDef.cellClass];
        switch (colDef.cellDataType) {
            case 'datestring':
            case 'date':
                if (cellClassArray.includes(AgGridHelper.dateTimeExcelClass)) {
                    return cell.value ? DateHelper.getTimezoneAddedDate(cell.value)?.toISOString() : null;
                } else if (cellClassArray.includes(AgGridHelper.dateExcelClass)) {
                    return cell.value ? new Date(cell.value)?.toISOString() : null;
                }
                return cell.value;
            default:
                return cell.value;
        }
    }

    // key value pairs of types defined up to, used by ag grid on col def type property
    public static readonly columnTypes: Dictionary<ColDef> = {
        action: AgGridColDefHelper.actionColDef,
        checkbox: AgGridColDefHelper.checkboxColDef,
        country: AgGridColDefHelper.countryColDef,
        currency: AgGridColDefHelper.currencyColDef,
        date: AgGridColDefHelper.dateColDef,
        dateTime: AgGridColDefHelper.dateTimeColDef,
        edit: AgGridColDefHelper.editableColDef,
        iconCheck: AgGridColDefHelper.iconCheckColDef,
        money: AgGridColDefHelper.moneyColDef,
        number: AgGridColDefHelper.numberColDef,
        percentage: AgGridColDefHelper.percentageColDef,
        phoneNumber: AgGridColDefHelper.phoneNumberColDef,
        yesNo: AgGridColDefHelper.yesNoColDef,
    };

}
