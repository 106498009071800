import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BaseHttpService } from 'app/nexus-core/services/base-http.service';
import { Observable, of } from 'rxjs';
import { BaseService } from 'app/nexus-core/services/base-service.directive';
import { FolderTagModel } from 'app/nexus-shared/domain/documents/models/folder-tag.model';
import { tap } from 'rxjs/operators';

@Injectable()
export class FolderTagService extends BaseService {
    private apiUrl = `${environment().webAPIUrls.documents}folder-tags/`;

    tags: FolderTagModel[] = [];

    constructor(private baseHttpService: BaseHttpService) {
        super();
    }

    search(): Observable<FolderTagModel[]> {
        if (this.tags?.length) {
            return of(this.tags);
        }
        return this.baseHttpService.post(`${this.apiUrl}search`).pipe(tap(res => {
            this.tags = res;
        }));
    }
}
