export class DocumentRoutingKeyConstants {
    static areaKeys = {
        clients: '5d82279f-8d47-4c1d-a8af-2b6952c5a2c0',
        resources: '7b2bc2a5-d728-4e55-b492-ccb403b8ed54',
        solutions: 'a6bcf078-0b72-4d9b-ae06-1f5a16c4d8e2',
        fileTemplates: '58fb9c43-b805-4884-b858-8bba895e83f9',
        folderTemplates: '970dcc88-f300-41e7-8eba-deca2c2c0521',
        training: 'f0acb0c2-eef2-49e3-8d8f-9ab1cec0f0c3',
        personal: '1120550a-fa42-4fc1-92b3-e59f5b602b63',
        teams: 'eea9a7da-bfe2-44c7-b7de-ffb6841da054',
    };

    static routeParamKeys = {
        area: 'area-key',
        document: 'document-key',
        client: 'client-key'
    };
}
