import { ColumnApi, ColumnGroup, GridApi, IHeaderGroupParams } from 'ag-grid-community';
import { Dictionary } from 'app/nexus-shared/models/dictionary';

export class ReportHeaderGroupParamsModel implements IHeaderGroupParams {
    columnGroup: ColumnGroup;
    displayName: string;
    setExpanded: (expanded: boolean) => void;
    api: GridApi;
    columnApi: ColumnApi;
    context: any;
    styles: Dictionary<any>;
    height: number;
    colSpan: number;
}
