<form [formGroup]="formGroupRef">
    <ng-container
        *ngIf="tableColumnHeader.tableInputType === userInputTypesEnum.FreeFormText ||
             tableColumnHeader.tableInputType === userInputTypesEnum.PhoneNumber ||
             tableColumnHeader.tableInputType === userInputTypesEnum.EmailAddress">
        <gtn-input [textAlign]="tableColumnHeader.alignment"  formControlName="value"></gtn-input>
    </ng-container>
    <ng-container
        *ngIf="tableColumnHeader.tableInputType === userInputTypesEnum.Numeric">
        <gtn-input [textAlign]="tableColumnHeader.alignment"  type="number" formControlName="value"></gtn-input>
    </ng-container>
    <ng-container
        *ngIf="tableColumnHeader.tableInputType === userInputTypesEnum.Date">
        <gtn-datepicker formControlName="value"></gtn-datepicker>
    </ng-container>
    <ng-container
        *ngIf="tableColumnHeader.tableInputType === userInputTypesEnum.DropDown">
        <gtn-questionnaire-dropdown-option-select formControlName="value" [questionnaireDropdownService]="questionnaireDropdownService"
            [dropdownId]="tableColumnHeader.dropdownTypeId"></gtn-questionnaire-dropdown-option-select>
    </ng-container>
</form>
