import { DropdownOptionModel } from 'app/nexus-shared/domain/questionnaires/models/dropdown-option.model';

export class DropdownOptionTypeModel {
    id: number;
    label: string;
    options: DropdownOptionModel[];

    constructor(model: DropdownOptionTypeModel = null) {
        this.id = model?.id ?? null;
        this.label = model?.label ?? null;
        this.options = model?.options?.length ? model.options.map(x => new DropdownOptionModel(x)) : [];
    }
}
