import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { AgreementBaseFormComponent } from "../agreement-base-form.component";
import { AgreementTypeEnum } from "../../../enums/agreement-type.enum";
import { AgreementsHelper } from "app/nexus-core/helpers/agreements.helper";
import { AgreementWildcardConstants } from "../../../constants/agreement-wildcard.constants";
import { AgreementSubtypeEnum } from "../../../enums/agreement-subtype.enum";
import { IAgreementTypeModelInterface } from "../../../interfaces/iagreement-type-model.interface";
import { NumberHelper } from "app/nexus-core";
import { SelectListInterface } from "app/nexus-shared/interfaces";
import { IndividualInterface } from "app/nexus-shared/interfaces/individual.interface";
import { ObjectHelper } from "app/nexus-core/helpers/object.helper";
import { IAgreementModelInterface } from "../../../interfaces/iagreement-model.interface";
import { AgreementStatusEnum } from "../../../enums/agreement-status.enum";
import { SimpleChangesTyped } from "app/nexus-shared/models/simple-changes-typed.type";
import { IAgreementReferentialValueModelInterface } from "../../..";

@Component({
    selector: 'gtn-agreement-iel-form',
    templateUrl: './agreement-iel-form.component.html',
    styleUrls: ['./agreement-iel-form.component.scss']
})
export class AgreementIELFormComponent extends AgreementBaseFormComponent implements OnInit, OnChanges {
    @Input() reviewers: IndividualInterface[] = [];
    @Output() reviewerChange: EventEmitter<IndividualInterface> = new EventEmitter();

    complianceFee = 0.0;
    consultingFee = 0.0;
    isCompliance = false;
    isConsulting = false;
    reviewerOptions: SelectListInterface[] = [];
    selectedReviewerOption: SelectListInterface;
    services = '';
    serviceYears = '';
    totalFee = NumberHelper.formatCurrency(0.0);

    ngOnInit(): void {
        super.ngOnInit();
    }

    ngOnChanges(changes: SimpleChangesTyped<this>) {
        super.ngOnChanges(changes);

        if (changes.reviewers) {
            this.setupReviewerOptions();
        }
    }

    onReviewerChanged(reviewerOption: SelectListInterface) {
        const reviewer = this.reviewers?.find(x => x.individualKey === reviewerOption.id);

        this.formGroupRef.get(ObjectHelper.nameOf<IAgreementModelInterface>('reviewerIndividualKey')).setValue(reviewer?.individualKey ?? null);
        this.reviewerChange.emit(reviewer ?? null);
    }

    onSaveClicked(): void {
        if (!this.value.reviewerIndividualKey) {
            this.value.status = AgreementStatusEnum.PendingSignature;
        }
    }

    updateComplianceFee($event) {
        const value = Number($event.target?.value);

        if (!Number.isNaN(value)) {
            this.complianceFee = value;
            this.setReferentialValueFee(AgreementWildcardConstants.complianceFee, value);

            this.updateTotalFee();
        }
    }

    updateConsultingFee($event) {
        const value = Number($event.target?.value);

        if (!Number.isNaN(value)) {
            this.consultingFee = value;
            this.setReferentialValueFee(AgreementWildcardConstants.consultingFee, value);

            this.updateTotalFee();
        }
    }

    updateServiceYears($event) {
        if ($event.target?.value) {
            this.serviceYears = $event.target.value;
            this.setReferentialValue(AgreementWildcardConstants.calendarYears, this.serviceYears);
        }
    }

    updateServices($event) {
        if ($event.target?.value) {
            this.services = $event.target.value;
            this.setReferentialValue(AgreementWildcardConstants.services, this.services);
        }
    }

    protected getDefaultReferentialValues(): IAgreementReferentialValueModelInterface[] {
        const defaultReferentialValues = super.getDefaultReferentialValues();
        const defaultIELReferentialValues = this.value.agreementReferentialValues?.filter(x =>
            x.id === AgreementWildcardConstants.calendarYears ||
            x.id === AgreementWildcardConstants.services);

        return defaultReferentialValues.concat(defaultIELReferentialValues);
    }

    protected getFilteredAgreementTypes(): IAgreementTypeModelInterface[] {
        return this.agreementTypes.filter(x => x.type === AgreementTypeEnum.IndependentEngagementLetter);
    }

    protected initUIControls(): void {
        this.setupReviewerOptions();
        this.complianceFee = this.getReferentialValueFee(AgreementWildcardConstants.complianceFee);
        this.consultingFee = this.getReferentialValueFee(AgreementWildcardConstants.consultingFee);
        this.totalFee = this.agreementsHelper.getReferentialValue(this.value.agreementReferentialValues, AgreementWildcardConstants.totalFee);

        this.serviceYears = this.agreementsHelper.getReferentialValue(this.value.agreementReferentialValues, AgreementWildcardConstants.calendarYears);
        this.services = this.agreementsHelper.getReferentialValue(this.value.agreementReferentialValues, AgreementWildcardConstants.services);

        super.initUIControls();
    }

    protected updateSubtypeFlags() {
        const agreementType = this.value.agreementType;

        this.isCompliance = (agreementType?.subtype === AgreementSubtypeEnum.Compliance || agreementType?.subtype === AgreementSubtypeEnum.ComplianceAndConsulting);
        this.isConsulting = (agreementType?.subtype === AgreementSubtypeEnum.Consulting || agreementType?.subtype === AgreementSubtypeEnum.ComplianceAndConsulting);

        if (!this.isCompliance) {
            this.resetComplianceFee();
        }

        if (!this.isConsulting) {
            this.resetConsultingFee();
        }
    }

    private getReferentialValueFee(feeId: string): number {
        const stringValue = AgreementsHelper.getReferentialValue(this.value.agreementReferentialValues, feeId);
        const strippedValue = stringValue.replace(/[^0-9\.-]+/g, "");

        const numericValue = Number(strippedValue);

        return (numericValue && !Number.isNaN(numericValue)) ? numericValue : 0.0;
    }

    private resetComplianceFee() {
        this.complianceFee = 0.0;
        this.setReferentialValueFee(AgreementWildcardConstants.complianceFee, this.complianceFee);

        this.updateTotalFee();
    }

    private resetConsultingFee() {
        this.consultingFee = 0.0;
        this.setReferentialValueFee(AgreementWildcardConstants.consultingFee, this.consultingFee);

        this.updateTotalFee();
    }

    private setReferentialValueFee(id: string, value: number) {
        const currencyValue = NumberHelper.formatCurrency(value);
        this.setReferentialValue(id, currencyValue);
    }

    private setupReviewerOptions() {
        this.reviewerOptions = [...this.reviewers?.map(individual => ({
            id: individual.individualKey,
            value: individual.fullName ?? ''
        }))];

        this.selectedReviewerOption = this.reviewerOptions.find(x => x.id === this.value.reviewerIndividualKey);
    }

    private updateTotalFee() {
        const total = this.complianceFee + this.consultingFee;
        this.totalFee = NumberHelper.formatCurrency(total);

        this.setReferentialValue(AgreementWildcardConstants.totalFee, this.totalFee);
    }
}
