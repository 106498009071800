import { Component } from '@angular/core';
import { ModalComponent } from 'app/nexus-shared/components/modal/modal.component';

@Component({
    selector: 'gtn-modal-footer-left-buttons',
    template: '<ng-content></ng-content>'
})
export class ModalFooterLeftButtonsComponent {
    // noinspection JSUnusedLocalSymbols
    constructor(gtnModalComponent: ModalComponent) {
        //THIS WILL THROW AN ERROR IF ITS NOT NESTED WITHIN THE gtn-modal ELEMENT
    }
}
