<gtn-modal class="modal-small"
    headerText="Agreement"
    cancelButtonText="Close"
    okButtonText="Save"
    [disableOkButton]="!formGroupRef?.dirty || isSaving || !fileUploadComplete"
    [validationModels]="validationModels"
    (cancelClick)="onCancelClicked()"
    (okClick)="onSaveClicked()">
    <gtn-modal-content>
        <gtn-agreement-upload-form #uploadForm
            (fileChange)="onFileChanged()"
            (reviewerChange)="onReviewerChanged($event)"
            (sendStatusChange)="onSendStatusChanged($event)"
            (signerCompanyChange)="onSignerCompanyChanged($event)"
            (signerIndividualChange)="onSignerIndividualChanged($event)"
            (spouseNameChange)="onSpouseNameChanged($event)"
            (typeChange)="onTypeChanged($event)"
            [agreementTypes]="agreementTypes"
            [companies]="companies"
            [displayMainAgreementType]="displayMainAgreementType"
            [displaySigner]="displaySigner"
            [isSubForm]="true"
            [mainAgreementType]="mainAgreementType"
            [requireFileUpload]="requireFileUpload"
            [reviewers]="reviewers"
            [signerCompany]="signerCompany"
            [signerIndividual]="signerIndividual"
            [signerSpouse]="signerSpouse"
            [(formGroupRef)]="formGroupRef"
            [(validationModels)]="validationModels"
            [(value)]="value">
        </gtn-agreement-upload-form>
    </gtn-modal-content>
</gtn-modal>
