import { BaseFormModel } from 'app/nexus-shared/models/base-form.model';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { QuestionnaireTemplateModel } from 'app/nexus-shared/domain/questionnaires/models/questionnaire-template.model';
import { SectionModel } from 'app/nexus-shared/domain/questionnaires/models/section.model';
import { SectionRowModel } from 'app/nexus-shared/domain/questionnaires/models/section-row.model';
import { TableColumnHeaderModel } from 'app/nexus-shared/domain/questionnaires/models/table-column-header.model';
import { SectionColumnModel } from 'app/nexus-shared/domain/questionnaires/models/section-column.model';
import { TableRowModel } from 'app/nexus-shared/domain/questionnaires/models/table-row.model';
import { DropdownOptionModel } from 'app/nexus-shared/domain/questionnaires/models/dropdown-option.model';
import { DropdownOptionTypeModel } from 'app/nexus-shared/domain/questionnaires/models/dropdown-option-type.model';
import { TableColumnModel } from 'app/nexus-shared/domain/questionnaires/models/table-column.model';

export class QuestionnaireFormHelper {
    static getDropdownOptionFormArray(rootPropertyName: string, baseFormModel = new BaseFormModel(), addBlank: boolean = false): BaseFormModel {
        baseFormModel.formArrayConfiguration[rootPropertyName] = {
            template: new DropdownOptionModel(),
            addBlank: addBlank,
        };

        return baseFormModel;
    }

    //keep in order of tree
    static getSectionsFormArrayConfiguration(rootPropertyName: string, baseFormModel = new BaseFormModel(), addBlank: boolean = false): BaseFormModel {
        baseFormModel.formArrayConfiguration[rootPropertyName] = {
            template: new SectionModel(),
            addBlank: addBlank,
            formConfiguration: QuestionnaireFormHelper.getSectionFormConfiguration()
        };

        return baseFormModel;
    }

    static getSectionRowFormArrayConfiguration(rootPropertyName: string, baseFormModel = new BaseFormModel(), addBlank: boolean = false): BaseFormModel {
        baseFormModel.formArrayConfiguration[rootPropertyName] = {
            template: new SectionRowModel(),
            addBlank: addBlank,
            formConfiguration: QuestionnaireFormHelper.getSectionRowFormConfiguration()
        };

        return baseFormModel;
    }

    static getSectionColumnsFormArrayConfiguration(rootPropertyName: string, baseFormModel = new BaseFormModel(), addBlank: boolean = false): BaseFormModel {
        baseFormModel.formArrayConfiguration[rootPropertyName] = {
            template: new SectionRowModel(),
            addBlank: addBlank,
            formConfiguration: QuestionnaireFormHelper.getSectionColumnFormConfiguration()
        };

        return baseFormModel;
    }

    static getTableColumnHeaderFormArrayConfiguration(rootPropertyName: string, baseFormModel = new BaseFormModel(), addBlank: boolean = false): BaseFormModel {
        baseFormModel.formArrayConfiguration[rootPropertyName] = {
            template: new TableColumnHeaderModel(),
            addBlank: addBlank,
            formConfiguration: QuestionnaireFormHelper.getTableColumnHeaderFormConfiguration()
        };

        return baseFormModel;
    }

    static getTableRowFormArrayConfiguration(rootPropertyName: string, baseFormModel = new BaseFormModel(), addBlank: boolean = false): BaseFormModel {
        baseFormModel.formArrayConfiguration[rootPropertyName] = {
            template: new TableRowModel(),
            addBlank: addBlank,
            formConfiguration: QuestionnaireFormHelper.getTableRowFormConfiguration()
        };

        return baseFormModel;
    }

    static getTableColumnFormArrayConfiguration(rootPropertyName: string, baseFormModel = new BaseFormModel(), addBlank: boolean = false): BaseFormModel {
        baseFormModel.formArrayConfiguration[rootPropertyName] = {
            template: new TableColumnModel(),
            addBlank: addBlank,
            formConfiguration: QuestionnaireFormHelper.getTableColumnFormConfiguration()
        };

        return baseFormModel;
    }

    static getSectionFormConfiguration(): BaseFormModel {
        const formConfiguration = new BaseFormModel();
        QuestionnaireFormHelper.getSectionRowFormArrayConfiguration(ObjectHelper.nameOf<SectionModel>('rows'), formConfiguration);
        return formConfiguration;
    }

    static getSectionRowFormConfiguration(): BaseFormModel {
        const formConfiguration = new BaseFormModel();
        QuestionnaireFormHelper.getSectionColumnsFormArrayConfiguration(ObjectHelper.nameOf<SectionRowModel>('columns'), formConfiguration);
        return formConfiguration;
    }

    static getSectionColumnFormConfiguration(): BaseFormModel {
        const formConfiguration = new BaseFormModel();
        formConfiguration.formConfiguration[ObjectHelper.nameOf<SectionColumnModel>('action')] = new BaseFormModel();
        QuestionnaireFormHelper.getTableColumnHeaderFormArrayConfiguration(ObjectHelper.nameOf<SectionColumnModel>('tableColumnHeaders'), formConfiguration);
        QuestionnaireFormHelper.getTableRowFormArrayConfiguration(ObjectHelper.nameOf<SectionColumnModel>('tableRows'), formConfiguration);
        return formConfiguration;
    }

    static getTableColumnHeaderFormConfiguration(): BaseFormModel {
        return new BaseFormModel();
    }

    static getTableRowFormConfiguration(): BaseFormModel {
        const formConfiguration = new BaseFormModel();
        QuestionnaireFormHelper.getTableColumnFormArrayConfiguration(ObjectHelper.nameOf<TableRowModel>('columns'), formConfiguration);
        return formConfiguration;
    }

    static getTableColumnFormConfiguration(): BaseFormModel {
        return new BaseFormModel();
    }

    static getQuestionnaireTemplateFormConfiguration(): BaseFormModel {
        const formConfiguration = new BaseFormModel();
        formConfiguration.formConfiguration[ObjectHelper.nameOf<QuestionnaireTemplateModel>('location')] = new BaseFormModel();
        QuestionnaireFormHelper.getSectionsFormArrayConfiguration(ObjectHelper.nameOf<QuestionnaireTemplateModel>('sections'), formConfiguration, false);
        return formConfiguration;
    }

    static getQuestionnaireFormConfiguration(): BaseFormModel {
        const formConfiguration = new BaseFormModel();
        QuestionnaireFormHelper.getSectionsFormArrayConfiguration(ObjectHelper.nameOf<QuestionnaireTemplateModel>('sections'), formConfiguration, false);
        return formConfiguration;
    }

    static getDropdownFormConfiguration(): BaseFormModel {
        const formConfiguration = new BaseFormModel();
        QuestionnaireFormHelper.getDropdownOptionFormArray(ObjectHelper.nameOf<DropdownOptionTypeModel>('options'), formConfiguration, false);
        return formConfiguration;
    }
}
