<div gtnChildResize [ngClass]="{'show-border': isCollapsible,
    'show-header-border': isCollapsed}" class="grid-container">
    <div class="grid-container-header">
        <gtn-filter [component]="panelComponent" (newRecord)="newRate()" (applyEmitter)="applyFilter($event)">
        </gtn-filter>
    </div>
    <div class="grid-container-body">
        <gtn-grid [columnDefs]="columnDefs" [defaultSortModel]="defaultSortModel" [data]="data" [isLoading]="isLoading"
            (rowClick)="onSelectionChange($event)">
        </gtn-grid>
    </div>
</div>
