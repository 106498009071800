import { Injectable } from '@angular/core';
import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { environment } from 'environments/environment';
import { AuthenticatedUserService } from 'app/nexus-core/services/authenticated-user.service';
import { AuthenticatedUserModel } from 'app/nexus-shared/models/authenticated-user.model';
import { EnvironmentHelper } from '../helpers/environment.helper';

@Injectable()
export class LoggingService {

    public errors: Error[] = [];

    private appInsights: ApplicationInsights;
    private aiEnabled: boolean = false;
    private readonly isLocalhost: boolean;

    constructor(private authenticatedUserClient: AuthenticatedUserService) {
        this.isLocalhost = EnvironmentHelper.isLocalhost();
    }

    appInit() {
        if (!this.isLocalhost && environment().appInsightsKey) {
            this.appInsights = new ApplicationInsights({
                config: {
                    instrumentationKey: environment().appInsightsKey,
                    enableAutoRouteTracking: true
                }
            });

            this.authenticatedUserClient.user$.subscribe((user: AuthenticatedUserModel) => {
                if (user) {
                    this.appInsights.setAuthenticatedUserContext(user.userKey, null, true);
                }
            });

            this.appInsights.loadAppInsights();
            this.appInsights.trackPageView({refUri: document.referrer});
            this.aiEnabled = true;

            this.appInsights.addTelemetryInitializer((telemetryItem: ITelemetryItem) => {
                if (telemetryItem.baseType === 'ExceptionData') {
                    const exceptions = telemetryItem?.baseData?.exceptions ?? [];

                    //IGNORE SILENT AUTH FAILURE IN APP INSIGHTS
                    if (exceptions.find(t => t?.message === 'Login Required' || t?.message === 'Failed Silent Auth' || t?.message === 'ErrorEvent: ResizeObserver loop completed with undelivered notifications.')) {
                        return false;
                    }
                }

                // add custom log properties across all Telemetry types here
                telemetryItem.data['GTN_AppName'] = `NexusUI.${environment().appName}`;

                if (this.authenticatedUserClient.user?.userId) {
                    telemetryItem.data['GTN_Auth0UserId'] = this.authenticatedUserClient.user.userId;
                }

                if (this.authenticatedUserClient.user?.userKey) {
                    telemetryItem.data['GTN_AuthWebAPIUserKey'] = this.authenticatedUserClient.user.userKey;
                }

                if (this.authenticatedUserClient.user?.individualKey) {
                    telemetryItem.data['GTN_IndividualKey'] = this.authenticatedUserClient.user.individualKey;
                }

                telemetryItem.data['GTN_IsImpersonating'] = this.authenticatedUserClient.isImpersonating;

                telemetryItem.data['GTN_IsSystemUser'] = false;

                return true;
            });
        }
    }

    logInfo(message: string, location: string = '') {
        if (this.aiEnabled) {
            this.appInsights.trackTrace({ message }, { location });
        }
    }

    logError(error: any) {
        try {
            if (error?.isServerError || error?.isValidationError) {
                return;
            }

            console.error(error);

            if (error?.stack) {
                error = <Error>{
                    message: error?.message,
                    stack: error?.stack
                };
            } else if (typeof error === 'string') {
                error = <Error>{
                    message: error
                };
            } else {
                // @ts-ignore
                error = <Error>{
                    message: JSON.stringify(error)
                };
            }

            if (this.aiEnabled) {
                this.appInsights.trackException({
                    exception: error
                });
            }

            this.errors.push(error);
        } catch {
            this.errors.push(<Error>{ message: 'An uncatchable/parsable error occurred', stack: null });
        }
    }
}
