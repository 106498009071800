import { FolderModel } from 'app/nexus-shared/domain/documents/models/folder.model';

export class FolderFavoriteModel {
    folderFavoriteKey: string;
    folder: FolderModel;

    constructor(model: FolderFavoriteModel = null) {
        this.folderFavoriteKey = model?.folderFavoriteKey ?? null;
        this.folder = model?.folder ?? null;
    }
}
