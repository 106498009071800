import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NexusAnimations } from 'app/nexus-shared/animations';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { ToastService } from 'app/nexus-core/services/toast.service';
import { AuthenticationService } from 'app/nexus-core/services/authentication.service';
import { SpinnerService, ValidationErrorHelper } from 'app/nexus-core';
import { ValidationModel } from 'app/nexus-shared/models';
import { ClientAuaService } from 'app/nexus-core/services/client-aua.service';
import { ClientAUAModel } from 'app/nexus-shared/models/client-aua.model';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'gtn-aua-modal',
    templateUrl: './aua-modal.component.html',
    styleUrls: [
        './aua-modal.component.scss'
    ],
    animations: [
        NexusAnimations.modalFadeIn
    ]
})
export class AUAModalComponent extends BaseComponent implements OnInit {
    @Output() completed = new EventEmitter<boolean>();

    validationModels: ValidationModel[];
    showModal = false;
    disableAccept = true;
    disableButtons = false;
    clientAUA: ClientAUAModel;

    constructor(private clientAUAService: ClientAuaService,
                private toastService: ToastService,
                private authenticationService: AuthenticationService) {
        super();
    }

    ngOnInit(): void {
        SpinnerService.start();
        this.clientAUAService.get().pipe(finalize(() => {
            SpinnerService.stop();
        })).subscribe(model => {
            this.clientAUA = model;
            this.showModal = true;
        }, error => {
            ValidationErrorHelper.handleServiceError(error, (validationModels) => {
                this.validationModels = validationModels;
                this.disableButtons = false;
            });
        });
    }

    onContentScrolled($event: any): void {
        if ($event.currentTarget.scrollTop >= (($event.currentTarget.scrollHeight - $event.currentTarget.offsetHeight) - 200)) {
            this.disableAccept = false;
        }
    }

    onAgreeClicked(): void {
        this.disableButtons = true;
        this.clientAUAService.agree(this.clientAUA).subscribe(result => {
            this.authenticationService.updateUserContext().subscribe(_ => {
                this.completed.emit(!!result);
                this.showModal = false;
            });
        }, error => {
            ValidationErrorHelper.handleServiceError(error, (validationModels) => {
                this.validationModels = validationModels;
                this.disableButtons = false;
            });
        });
    }

    onCancel2FAClicked(): void {
        this.completed.emit(false);
    }
}
