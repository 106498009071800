import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable()
export class BaseGuard implements OnDestroy {
    protected subscriptions = new Subscription();

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
