import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BaseHttpService, BaseService } from 'app/nexus-core/services/index';
import { Observable } from 'rxjs';
import { BlobStorageRequest } from 'app/nexus-core/services/blob/types/azure-storage';
import { shareReplay, tap } from 'rxjs/operators';
import { FileHelper } from 'app/nexus-core/helpers';

@Injectable()
export class DocumentTranslationService extends BaseService {
    private apiUrl = environment().webAPIUrls.documents + 'document-translations/';

    constructor(private baseHttpService: BaseHttpService) {
        super();
    }

    getOperationStatus(operationId: string): Observable<string> {
        return this.baseHttpService.get(`${this.apiUrl}status/${operationId}`, null, 'text');
    }

    createSasToken(model: {documentKey: string, fileName: string}): Observable<BlobStorageRequest> {
        return this.baseHttpService.post(`${this.apiUrl}token/`, model);
    }

    listLanguages(): Observable<{key: string, value: string}[]> {
        return this.baseHttpService.get(`${this.apiUrl}languages`).pipe(shareReplay());
    }

    downloadTranslation(model: {documentKey: string, fileName: string}): Observable<any> {
        return this.baseHttpService.post(`${this.apiUrl}download`, model, null, 'blob').pipe(tap(blob => {
            FileHelper.openFile(blob.blob, model.fileName);
        }));
    }

    executeTranslation(model: {documentKey: string, fileName: string, sourceLanguage: string}): Observable<string> {
        return this.baseHttpService.post(`${this.apiUrl}translate`, model);
    }
}
