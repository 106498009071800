import { ClientDocumentTypesEnum } from 'app/nexus-shared/domain/documents/enums/client-document-types.enum';

export class DocumentAdvancedSearchModel {
    individualKey?: string;
    companyKey?: string;
    taxYear?: number;
    countryId?: number;
    documentTypes: ClientDocumentTypesEnum[];

    constructor(model:DocumentAdvancedSearchModel = null) {
        this.individualKey = model?.individualKey ?? null;
        this.companyKey = model?.companyKey ?? null;
        this.taxYear = model?.taxYear ?? null;
        this.countryId = model?.countryId ?? null;
        this.documentTypes = model?.documentTypes ?? [];
    }
}
