import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthenticatedUserService } from 'app/nexus-core/services/authenticated-user.service';
import { AuthenticationService } from 'app/nexus-core/services/authentication.service';
import { ApplicationConfigurationService } from 'app/nexus-core/services';
import { MenusEnum } from 'app/nexus-shared/enums/menus.enum';
import { map } from 'rxjs/operators';
import { AuthorizationHelper } from '../helpers/authorization.helper';
import { AuthenticatedUserSyncService } from 'app/nexus-core/services/authenticated-user-sync.service';

@Injectable()
export class AuthGuard  {
    constructor(
        private applicationConfigurationService: ApplicationConfigurationService,
        private authenticatedUserService: AuthenticatedUserService,
        private authenticationService: AuthenticationService,
        private authenticatedUserSyncService: AuthenticatedUserSyncService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.authenticatedUserSyncService.isSyncedCompleted.pipe(map(_ => {
            const authorization = route.data?.authorization || [];

            if (!this.authenticatedUserService.user) {
                // error returned from auth0 authentication
                if (!window.location.href.startsWith(`${window.location.origin}/?error=`)) {
                    this.authenticationService.login();
                }
                return false;
            }

            if (!this.authenticatedUserService.hasCompletedSetup()) {
                const landingMenu = this.applicationConfigurationService.getApplicationMenuConfigurationByMenuId(MenusEnum.Landing);
                const isNavigatingToLanding = state.url === landingMenu.routing.relativeUrl;

                if (AuthorizationHelper.hasGtnEmail(this.authenticatedUserService.user)) {
                    this.authenticationService.error$.next('You have not been fully setup in the system.  Please contact an administrator.');
                } else if (!isNavigatingToLanding) {
                    this.applicationConfigurationService.redirectToDefaultRoute();
                }

                return isNavigatingToLanding;
            }

            if (!this.authenticatedUserService.hasAuthorization(authorization)) {
                this.authenticationService.error$.next('You are not authorized to access this page. If you believe you should have access, please contact an administrator.');

                return false;
            }

            return true;
        }));
    }
}
