import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { BaseHttpService } from 'app/nexus-core/services/index';
import { ApplicationSettingsModel } from 'app/nexus-shared/models/application-settings.model';
import { tap } from 'rxjs/operators';
import { StringHelper } from 'app/nexus-core/helpers';

@Injectable()
export class ApplicationSettingsService {
    apiUrl = `${environment().webAPIUrls.core}`;
    applicationSettings: ApplicationSettingsModel;

    constructor(private baseHttpService: BaseHttpService) {
    }

    init(): Observable<ApplicationSettingsModel> {
        return this.baseHttpService.get(`${environment().webAPIUrls.core}application-settings`).pipe(tap(applicationSettings => {
            const envPrefix = environment().envPrefix;
            const hostnameSuffix = '.gtn.com';
            const replacementString = StringHelper.format(applicationSettings.applicationUIConfiguration, [envPrefix, hostnameSuffix]);
            applicationSettings.applicationUIConfiguration = JSON.parse(replacementString);
            this.applicationSettings = applicationSettings;
        }));
    }
}
