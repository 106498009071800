<div class="collapsible-tray" [ngClass]="{'raise-up': isInQuestionnaires}">
    <div class="collapsible-tray-header">
        <div class="tray-header-text">
            {{headerText}}
        </div>
        <div class="tray-header-actions">
            <div *ngIf="showCollapsibleTrayContent" (click)="onToggleTrayClicked()"><i class="fal fa-chevron-down"></i></div>
            <div *ngIf="!showCollapsibleTrayContent" (click)="onToggleTrayClicked()"><i class="fal fa-chevron-up"></i></div>
            <div *ngIf="showCloseTray" (click)="onCloseTrayClicked()"><i class="fal fa-times"></i></div>
        </div>
    </div>
    <div *ngIf="showCollapsibleTrayContent" class="collapsible-tray-content">
        <ng-content select="gtn-collapsible-tray-content"></ng-content>
    </div>
</div>
