import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { BaseHttpService } from 'app/nexus-core/services/index';
import { UserImpersonationModel } from 'app/nexus-shared/domain/users/models/user-impersonation.model';
import { UserImpersonationSearchModel } from 'app/nexus-shared/domain/users/models/user-impersonation-search.model';

@Injectable()
export class UserImpersonationService {
    private apiUrl = environment().webAPIUrls.auth + 'user-impersonations/';

    constructor(private baseHttpService: BaseHttpService) {
    }

    getCurrentImpersonation(): Observable<UserImpersonationModel> {
        return this.baseHttpService.get(this.apiUrl);
    }

    search(model: UserImpersonationSearchModel = null): Observable<UserImpersonationModel[]> {
        return this.baseHttpService.post(this.apiUrl + 'search', model ?? {});
    }

    create(model: UserImpersonationModel): Observable<string> {
        return this.baseHttpService.post(this.apiUrl, model);
    }
    update(model: UserImpersonationModel): Observable<boolean> {
        return this.baseHttpService.put(this.apiUrl, model);
    }
}
