export class ModelBase {
    public returnStatus: Boolean;
    public returnMessages: string[];
    public validationErrors: any[];
    public totalPages: number;
    public totalRows: number;
    public skip: number;
    public pageSize: number;
    public isAuthenticated: Boolean;
    public sortExpression: string;
    public sortDirection: string;
    public currentPageNumber: number;
    public newId: number;
}
