<gtn-company-create-template-modal
    [headerText]="value?.companyRelationshipKey ? 'Edit Relationship' : 'Add Relationship'"
    [validationModels]="validationModels"
    [isCreateNewCompany]="isCreateNewCompany"
    [value]="company"
    [formGroupRef]="formGroupRef"
    (cancelClick)="onCancelClicked()" (saveClick)="onSaveClicked()" (companySaveSuccess)="onCompanySaveSuccess($event)">
    <gtn-company-create-dual-form-content>
        <gtn-company-relationship-form *ngIf="value && !isCreateNewCompany"
            [canCreateNew]="isAdmin"
            [(formGroupRef)]="formGroupRef" [(value)]="value"
            [(isParentChildRelationship)]="isParentChildRelationship"
            (formGroupRefChange)="onFormGroupRefChanged($event)"
            (createCompany)="onCreateNewCompanyClicked()">
        </gtn-company-relationship-form>
    </gtn-company-create-dual-form-content>
</gtn-company-create-template-modal>
