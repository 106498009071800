import { Injectable } from '@angular/core';
import { ArrayHelper, BaseHttpService } from 'app/nexus-core/index';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CurrencyModel } from 'app/nexus-shared/domain/currency';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';

@Injectable({
    providedIn: 'root'
})
export class CurrencyService {
    static readonly countryIds = {
        unitedStatesLocationId: 6252001,
        canadaLocationId: 6251999,
        unitedKingdomLocationId: 2635167
    };

    private currencyList: CurrencyModel[] = null;

    constructor(private baseHttpService: BaseHttpService) {
    }

    static sortCurrencies(currencies: CurrencyModel[]): CurrencyModel[] {

        const currencyList = [...currencies];

        const unitedKingdom = currencyList.find(c => c.locationId === CurrencyService.countryIds.unitedKingdomLocationId);
        if (unitedKingdom != null && currencyList[0].id !== CurrencyService.countryIds.unitedKingdomLocationId && currencyList.find(c => c.locationId !== unitedKingdom.locationId)) {
            const index = currencyList.map(c => c.locationId).indexOf(CurrencyService.countryIds.unitedKingdomLocationId);
            currencyList.splice(index, 1);
            currencyList.unshift(unitedKingdom);
        }

        const canada = currencyList.find(c => c.locationId === CurrencyService.countryIds.canadaLocationId);
        if (canada != null && currencyList[0].locationId !== CurrencyService.countryIds.canadaLocationId && currencyList.find(c => c.locationId !== canada.locationId)) {
            const index = currencyList.map(c => c.locationId).indexOf(CurrencyService.countryIds.canadaLocationId);
            currencyList.splice(index, 1);
            currencyList.unshift(canada);
        }

        const unitedStates = currencyList.find(c => c.locationId === CurrencyService.countryIds.unitedStatesLocationId);
        if (unitedStates != null && currencyList[0].locationId !== CurrencyService.countryIds.unitedStatesLocationId &&
            currencyList.find(c => c.locationId !== unitedStates.locationId)) {
            const index = currencyList.map(c => c.locationId).indexOf(CurrencyService.countryIds.unitedStatesLocationId);
            currencyList.splice(index, 1);
            currencyList.unshift(unitedStates);
        }

        return currencyList;
    }

    static removeDuplicates(currencies: CurrencyModel[]): CurrencyModel[] {
        return ArrayHelper.getUniqueItemsByProperty([...currencies], ObjectHelper.nameOf<CurrencyModel>('currencyCode'));
    }

    list(sorted: boolean = false): Observable<CurrencyModel[]> {
        if (this.currencyList) {
            if (sorted) {
                return of(JSON.parse(JSON.stringify(CurrencyService.sortCurrencies(this.currencyList))));
            }
            return of(JSON.parse(JSON.stringify(this.currencyList)));
        }
        return this.baseHttpService.get(`${this.apiUrl()}currency`)
            .pipe(map((data) => {
                this.currencyList = data;
                if (sorted) {
                    return CurrencyService.sortCurrencies(data);
                }
                return this.currencyList;
            }));
    }

    private apiUrl = () => environment().webAPIUrls.core;
}
