import { Component } from '@angular/core';
import { ApplicationConfigurationService } from 'app/nexus-core/services';

@Component({
    selector: 'gtn-external-landing-page',
    templateUrl: './external-landing-page.component.html',
    styleUrls: ['./external-landing-page.component.scss']
})
export class ExternalLandingPageComponent {
    constructor(
        public applicationConfigurationService: ApplicationConfigurationService
    ) {
    }

    onToolClicked(appName: string) {
        this.applicationConfigurationService.redirectToExternalApplication(appName);
    }
}
