import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BaseEntryComponent } from 'app/nexus-shared/components/base-component/base-entry.component';
import { IndividualModel, IndividualToCompanyAccessModel } from 'app/nexus-shared/domain/contacts/models';
import { concatMap } from 'rxjs/operators';
import { IndividualCompanyAccessService } from 'app/nexus-core/services/domain/contacts/individual-company-access.service';
import { IndividualService } from 'app/nexus-core/services/domain/contacts/individual.service';
import { RegionTypesEnum } from 'app/nexus-shared/domain/contacts/enums/region-types.enum';
import { DateHelper, EnumHelper } from 'app/nexus-core';
import { Observable } from 'rxjs';
import { ViewModesEnum } from 'app/nexus-shared/enums/view-modes.enum';
import { IndividualTypesEnum } from 'app/nexus-shared/domain/contacts/enums/individual-types.enum';
import { IndividualTypeModel } from 'app/nexus-shared/domain/contacts/models/individual-type.model';

@Component({
    template: ''
})
export abstract class BaseAccessModalComponent extends BaseEntryComponent<IndividualToCompanyAccessModel> implements OnInit, OnDestroy {
    @Input() isRelationshipTypeReadonly: boolean = false;

    @Output() saveSuccess: EventEmitter<void> = new EventEmitter<void>();
    @Output() cancelClick: EventEmitter<void> = new EventEmitter<void>();

    headerText: string;
    isControllingCompany: boolean = false;
    viewModes = ViewModesEnum;

    protected constructor() {
        super();
    }

    ngOnInit() {
        this.getHeaderText();
    }

    onCancelClicked(): void {
        this.cancelClick.emit();
    }

    protected saveAccess(individualCompanyAccessService: IndividualCompanyAccessService, individualService: IndividualService): void {
        this.isSaving = true;
        if (this.value.individualToCompanyAccessKey) {
            individualCompanyAccessService.update(this.value).pipe(concatMap((res) => this.saveIndividual(individualService)
            )).subscribe(_ => {
                this.onSaveSuccess();
                this.saveSuccess.emit();
            }, err => {
                this.isSaving = false;
                this.onSaveFailure(err);
            });
        } else {
            individualCompanyAccessService.create(this.value).pipe(concatMap((res) => this.saveIndividual(individualService)
            )).subscribe(_ => {
                this.onSaveSuccess();
                this.saveSuccess.emit();
            }, err => {
                this.isSaving = false;
                this.onSaveFailure(err);
            });
        }
    }

    private saveIndividual(individualService): Observable<boolean> {
        const individual = new IndividualModel(this.value.individual);
        individual.region = BaseAccessModalComponent.setRegion(this.value, this.value?.individual?.region);
        if (this.isControllingCompany && !individual.individualTypes.some(x => x.type === IndividualTypesEnum.GtnEmployee)) {
            const individualType = new IndividualTypeModel();
            individualType.type = IndividualTypesEnum.GtnEmployee;
            individual.individualTypes.push(individualType);
        }
        return individualService.update(individual);
    }

    private static setRegion(mapping: IndividualToCompanyAccessModel, currentRegion: RegionTypesEnum): RegionTypesEnum {
        const isValid: boolean = (!mapping.effectiveStartDate && !mapping.effectiveEndDate ||
            (!mapping.effectiveStartDate && DateHelper.lessThan(new Date(), new Date(mapping.effectiveEndDate))) ||
            (!mapping.effectiveEndDate && DateHelper.lessThan(new Date(mapping.effectiveStartDate), new Date())) ||
            (DateHelper.isBetweenDates(mapping.effectiveStartDate, mapping.effectiveEndDate, new Date())));

        if (isValid && mapping?.company?.region) {
            return mapping.company.region;
        }
        return currentRegion;
    }


    private getHeaderText(): void {
        let action: string = 'Create';
        let type: string = 'Relationship';
        if (this.value?.individualToCompanyAccessKey) {
            action = 'Edit';
        }

        if (this.value?.relationshipType && this.isRelationshipTypeReadonly) {
            type = EnumHelper.getDisplayName(IndividualTypesEnum, this.value.relationshipType);
        }

        this.headerText = `${action} ${type}`;
    }
}
