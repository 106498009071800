import { Component } from '@angular/core';
import { IStatusPanelAngularComp } from 'ag-grid-angular';
import { IStatusPanelParams } from 'ag-grid-community';

@Component({
    selector: 'gtn-last-updated-status-bar',
    templateUrl: './last-updated-status-bar.component.html',
    styleUrls: ['./last-updated-status-bar.component.scss']
})
export class LastUpdatedStatusBarComponent implements IStatusPanelAngularComp {
    static readonly modelUpdatedEvent: string = 'firstDataRendered';
    lastUpdatedDateTime: string | Date;
    params: IStatusPanelParams;

    agInit(params: IStatusPanelParams): void {
        //INTENTIONALLY LEFT BLANK
    }

    public setLastUpdatedDateTime(value: Date | string): void {
        this.lastUpdatedDateTime = value;
    }
}
