import { AfterViewInit, Component, EventEmitter, forwardRef, Input, Output, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { NexusValidatorHelper } from 'app/nexus-core';
import { DatePickerDateAdapter } from 'app/nexus-shared/components/controls/components/datepicker/date-picker-date-adapter';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import * as moment from 'moment';
import { Moment } from 'moment';

@Component({
    selector: 'gtn-datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.scss'],
    providers: [
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        {
            provide: DateAdapter,
            useClass: DatePickerDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DatepickerComponent),
            multi: true
        }, {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => DatepickerComponent),
            multi: true
        }
    ]
})
export class DatepickerComponent implements ControlValueAccessor, AfterViewInit {
    @Input('value') _value: Date | string | Moment;
    @Input() appearance: MatFormFieldAppearance = 'fill';
    @Input() canShowValidation: boolean;
    @Input() disabled: boolean;
    @Input() focus: boolean = false;
    @Input() format;
    @Input() label: string = '';
    @Input() isLocaleAware: boolean = false;
    @Input() readonly: boolean;
    @Input() minDate: Date | string | Moment = null;
    @Input() maxDate: Date | string | Moment = null;
    @Input() suppressInitialChangeEvent = false;
    @Input() startAt: Date | string | Moment;
    @Output() dateChanged: EventEmitter<Date> = new EventEmitter();

    formControl: UntypedFormControl;
    isValid: boolean = false;

    get value(): Date | string | Moment {
        return this._value;
    }

    set value(val: Date | string | Moment) {
        const outDate = moment(val).isValid() && moment(val).toISOString() !== moment(this._value).toISOString() ? moment(val).toDate() : null;
        this._value = val;
        this.startAt = val;

        // do not fire value when initially set to null
        if (!this.suppressInitialChangeEvent) {
            this.onChange(val);
            if (outDate) {
                this.dateChanged.emit(outDate);
            }
            this.onTouched();
        }
        this.suppressInitialChangeEvent = false;
    }

    constructor(private renderer: Renderer2, private dateAdapter: DateAdapter<DatePickerDateAdapter>) {
    }

    onChange: Function = () => {
    };
    onTouched: Function = () => {
    };

    ngAfterViewInit() {
        if (this.focus) {
            this.setFocus();
        }

        if (this.format) {
            this.dateAdapter['formatString'] = this.format;
        }

        if (this.readonly && !this.value) {
            this.readonly = false;
        }
    }

    onBlur(): void {
        this.onTouched();
    }

    registerOnChange(fn): void {
        this.onChange = fn;
    }

    registerOnTouched(fn): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    validate(formControl: UntypedFormControl) {
        this.formControl = formControl;
    }

    writeValue(value): void {
        if (value) {
            this.value = value;
        } else if (!value && this._value) {
            this.value = null;
        }
    }

    setFocus(): void {
        this.renderer.selectRootElement('input').focus();
    }

    getErrorMessage(): string[] {
        return NexusValidatorHelper.getErrorMessage(this.formControl, this.label);
    }
}
