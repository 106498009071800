import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { BaseFormComponent } from 'app/nexus-shared/components/base-component/base-form.component';
import { CostCenterModel } from 'app/nexus-shared/domain/contacts/models/cost-center.model';

@Component({
    selector: 'gtn-cost-centers-subform',
    templateUrl: './company-cost-centers-subform.component.html',
    styleUrls: ['./company-cost-centers-subform.component.scss']
})
export class CompanyCostCentersSubformComponent extends BaseFormComponent<CostCenterModel[]> implements OnInit {
    @Input() formArray: UntypedFormArray;

    ngOnInit(): void {
        this.initUIControls();
        if (!this.formArray?.length) {
            this.onAddCostCenterClicked(true);
        }
    }

    onAddCostCenterClicked(isInit: boolean = false): void {
        const newFormGroup = new UntypedFormGroup({});
        this.buildFormFromTemplate(new CostCenterModel(), newFormGroup);
        this.formArray.push(newFormGroup);
        if (!isInit) {
            this.formArray.markAsDirty();
            this.formArray.updateValueAndValidity();
        }
    }

    onRemoveCostCenterClicked(formGroup, i): void {
        this.formArray.removeAt(i);
        this.formArray.markAsDirty();
        this.formArray.updateValueAndValidity();
    }
}
