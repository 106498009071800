import { Component } from '@angular/core';
import { BaseViewComponent } from 'app/nexus-shared/components/base-component/base-view.component';
import { CommonBaseAuditModel } from 'app/nexus-shared/domain/contacts/models/common-base-audit.model';

@Component({
    selector: 'gtn-auditing-info',
    templateUrl: './auditing-info.component.html',
    styleUrls: ['./auditing-info.component.scss']
})
export class AuditingInfoComponent extends BaseViewComponent<CommonBaseAuditModel> {

}
