import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { LocationCompositeModel, LocationModel, LocationSearchModel } from 'app/nexus-shared/domain/locations/models';
import { LocationTypesEnum } from 'app/nexus-shared/domain/locations';
import { ContinentConstants } from 'app/nexus-shared/domain/locations/constants/continent.constants';
import { BaseHttpService } from 'app/nexus-core/services/index';
import { tap } from 'rxjs/operators';
import { LocationGroupModel } from 'app/nexus-shared/domain/locations/models/location-group.model';

@Injectable()
export class LocationService {

    static readonly countryIds = {
        unitedStatesLocationId: 6252001,
        canadaLocationId: 6251999,
        israelLocationId: 294640,
        switzerlandLocationId: 2658434,
        unitedKingdomLocationId: 2635167,
        israelId: 294640
    };
    static readonly locationGroupIds = {
        EU: 1,
        EEA: 2,
        SchengenArea: 3
    };

    countryList: LocationModel[] = null;

    constructor(private baseHttpService: BaseHttpService) {
    }

    static sortCountries(locations: LocationModel[]): LocationModel[] {
        if (!locations) {
            return locations;
        }

        const countries = [...locations];
        const canada = countries.find(c => c.id === LocationService.countryIds.canadaLocationId);
        if (canada != null && countries[0].id !== LocationService.countryIds.canadaLocationId && countries.find(c => c.id !== canada.id)) {
            const index = countries.map(c => c.id).indexOf(LocationService.countryIds.canadaLocationId);
            countries.splice(index, 1);
            countries.unshift(canada);
        }

        const unitedStates = countries.find(c => c.id === LocationService.countryIds.unitedStatesLocationId);
        if (unitedStates != null && countries[0].id !== LocationService.countryIds.unitedStatesLocationId &&
            countries.find(c => c.id !== unitedStates.id)) {
            const index = countries.map(c => c.id).indexOf(LocationService.countryIds.unitedStatesLocationId);
            countries.splice(index, 1);
            countries.unshift(unitedStates);
        }

        return countries;
    }

    static getLocationCompositeModel(countryId: number, stateId: number = null): LocationCompositeModel {
        if (!countryId && !stateId) {
            return null;
        }

        const locationCompositeModel = new LocationCompositeModel();

        if (countryId) {
            locationCompositeModel.country = new LocationModel();
            locationCompositeModel.country.id = countryId;
        }

        if (stateId) {
            locationCompositeModel.state = new LocationModel();
            locationCompositeModel.state.id = stateId;
        }

        return locationCompositeModel;
    }

    getByIds(locationIds: number[]): Observable<LocationModel[]> {
        return this.baseHttpService.post(`${this.apiUrl()}locations/ids`, locationIds);
    }

    getAirportsByIATACodes(airportCodes: string[]): Observable<LocationModel[]> {
        return this.baseHttpService.post(`${this.apiUrl()}airports/iata`, airportCodes);
    }

    getCountry(countryId: number): Observable<LocationModel> {
        if (this.countryList) {
            const country = this.countryList.find(x => x.id === countryId);
            if (country) {
                return of(JSON.parse(JSON.stringify(country)));
            }
        }
        return this.baseHttpService.get(`${this.apiUrl()}countries/${countryId}`);
    }

    getState(stateId: number): Observable<LocationModel> {
        return this.baseHttpService.get(`${this.apiUrl()}states/${stateId}`);
    }

    getCity(cityId: number): Observable<LocationModel> {
        return this.baseHttpService.get(`${this.apiUrl()}cities/${cityId}`);
    }

    listCountries(): Observable<LocationModel[]> {
        if (this.countryList) {
            return of(JSON.parse(JSON.stringify(this.countryList)));
        }
        return this.baseHttpService.get(this.apiUrl() + 'countries')
            .pipe(tap((data) => {
                this.countryList = data;
            }));
    }

    listCountriesByContinent(continent: ContinentConstants): Observable<LocationModel[]> {
        return this.baseHttpService.get(this.apiUrl() + 'countries', {
            continentCode: continent.toString()
        });
    }

    listCountriesWithStates(): Observable<LocationModel[]> {
        return this.baseHttpService.get(this.apiUrl() + 'countries/states');
    }

    listStates(countryId: number): Observable<LocationModel[]> {
        return this.baseHttpService.get(`${this.apiUrl()}states/country/${countryId}`);
    }

    listCities(countryId: number, stateId: number): Observable<LocationModel[]> {
        return this.baseHttpService.get(`${this.apiUrl()}cities/country/${countryId}/state/${stateId}`);
    }

    listCounties(stateId: number): Observable<LocationModel[]> {
        return this.baseHttpService.get(`${this.apiUrl()}counties/state/${stateId}`);
    }

    getLocationGroup(id: number): Observable<LocationGroupModel> {
        return this.baseHttpService.get(`${this.apiUrl()}location-groups/${id}`);
    }

    listLocationGroups(): Observable<LocationGroupModel[]> {
        return this.baseHttpService.get(`${this.apiUrl()}location-groups`);
    }

    search(request: LocationSearchModel) {
        switch (request.locationType) {
            case LocationTypesEnum.Country:
                return this.searchCountries(request.nameOrCode);
            case LocationTypesEnum.StateProvince:
                return this.searchStates(request.nameOrCode, request.countryId);
            case LocationTypesEnum.City:
                return this.searchCities(request.nameOrCode, request.countryId, request.stateId);
            case LocationTypesEnum.Airport:
                return this.searchAirports(request.nameOrCode);
            case LocationTypesEnum.Any:
                return this.searchLocations(request.nameOrCode);
            case LocationTypesEnum.County:
                throw new Error('LocationType.County is not supported.');
            default:
                throw new Error('LocationType must be set on request.');
        }
    }

    private searchCountries(countryNameOrCode: string) {
        return this.baseHttpService.get(`${this.apiUrl()}countries/search/${encodeURIComponent(countryNameOrCode)}`);
    }

    private searchStates(stateNameOrCode: string, countryId: number) {
        return this.baseHttpService.get(`${this.apiUrl()}states/country/${countryId}/search/${encodeURIComponent(stateNameOrCode)}`);
    }

    private searchCities(cityName: string, countryId: number, stateId: number) {
        return this.baseHttpService.get(`${this.apiUrl()}cities/country/${countryId}/state/${stateId}/search/${encodeURIComponent(cityName)}`);
    }

    private searchAirports(airportNameOrCode: string) {
        return this.baseHttpService.get(`${this.apiUrl()}airports/search/${encodeURIComponent(airportNameOrCode)}`);
    }

    private searchLocations(nameOrCode: string) {
        return this.baseHttpService.get(`${this.apiUrl()}locations/search/${encodeURIComponent(nameOrCode)}`);
    }

    private apiUrl = () => environment().webAPIUrls.locations;
}
