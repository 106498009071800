import { animate, AnimationMetadata, style, transition, trigger } from '@angular/animations';

export class EaseIn {

    /**
     * Use '@easeIn' in the markup.
     */
    static animations = EaseIn.getAnimation();

    private static getAnimation(): AnimationMetadata {
        return trigger('easeIn', [
            transition(':enter', [
                style({ transform: 'translateY(-40%)', opacity: 0 }),
                animate('500ms ease-in', style({ transform: 'translateY(0%)', opacity: 1 }))
            ]),
            transition(':leave', [
                animate('500ms ease-out', style({ transform: 'translateY(-40%)', opacity: 0 }))
            ]),
            transition('* => *', animate('500ms ease-in-out'))
        ]);
    }
}
