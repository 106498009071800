<gtn-modal *ngIf="individual" class="modal-small-wide" [headerText]="this.individual.fullName" [showFooter]="false"
    (closeClick)="onCancelClicked()">
    <gtn-modal-content>
        <gtn-template-view>
            <gtn-template-view-content>
                <ng-container>
                    <div class="row">
                        <div class="col-md-6">
                            <label class="list-label">First name</label>
                            <div class="data-field">
                                {{individual.firstName || noValueText}}
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label class="list-label">Last name</label>
                            <div class="data-field">
                                {{individual.lastName || noValueText}}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label class="list-label">Email</label>
                            <div class="data-field overflow-text-hidden">
                                <gtn-email [email]="individual?.emailAddress"></gtn-email>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label class="list-label">Company association(s)</label>
                            <div class="d-flex flex-column">
                                <div class="data-field" *ngFor="let company of individualToAssociatedCompanies last as isLast">
                                    {{company.name}}<span *ngIf="!isLast">,&nbsp;</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label class="list-label">Individual type(s)</label>
                            <div class="d-flex flex-wrap flex-row">
                                <div class="data-field" *ngFor="let individualType of individual.individualTypes last as isLast">
                                    {{individualType.type | enum: individualTypesEnum}}<span *ngIf="!isLast">,&nbsp;</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label class="list-label">Region</label>
                            <div class="data-field">
                                <span *ngIf="individual.region">{{individual.region | enum: regionsEnum}}</span>
                                <span *ngIf="!individual.region">{{noValueText}}</span>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container>
                    <div class="row">
                        <div class="col-md-6">
                            <label class="list-label">Source application</label>
                            <div class="data-field">{{individual.applicationSourceType | enum : applicationsEnum}}</div>
                        </div>
                        <div class="col-md-6">
                            <gtn-application-links [value]="individual.individualToApplications"
                                [currentApplication]="application"
                                [isDisplayingLogo]="true"
                                [individualKey]="individual.individualKey"
                                [myGTNPortalUserKey]="individual.myGTNPortalUserKey"></gtn-application-links>
                        </div>
                    </div>
                </ng-container>
            </gtn-template-view-content>
        </gtn-template-view>
    </gtn-modal-content>
</gtn-modal>
