<mat-form-field [appearance]="appearance"
    [ngClass]="{ 'gtn-read-only' : readonly, 'gtn-control-show-error': !formControl?.valid && !formControl?.pending && canShowValidation}">
    <mat-label *ngIf="!icon">{{ label }}</mat-label>
    <div *ngIf="icon" class="custom-label">
        <span>{{ label }}</span>
        <mat-icon class="{{icon}}" matTooltip="{{iconTooltip}}"></mat-icon>
    </div>
    <input *ngIf="type !== 'currency' && type !== 'number' && type !== 'fmv' && type !== 'password'"
        gtnDisableAutofill
        matInput
        #matInput
        [(ngModel)]="value"
        (blur)="onBlur()"
        [style.text-align]="textAlign"
        [type]="type"
        [readonly]="readonly"
        [disabled]="disabled"/>
    <input *ngIf="type === 'currency'"
        gtnDisableAutofill
        matInput
        #matInput
        [value]="(_value | currency: currencyCode:'symbol':'1.0-'+precision)"
        [style.text-align]="textAlign"
        [type]="'string'"
        [readonly]="readonly"
        [disabled]="disabled"
        (change)="value=$event.currentTarget['value']"
        (blur)="onBlur()"/>
    <input *ngIf="type === 'number'"
        gtnDisableAutofill
        matInput
        #matInput
        [(ngModel)]="value"
        [min]="min"
        [max]="max"
        [step]="step"
        [style.text-align]="textAlign"
        type="number"
        [readonly]="readonly"
        (blur)="onBlur()"/>
    <input *ngIf="type === 'fmv'"
        gtnDisableAutofill
        matInput
        #matInput
        [ngModel]="value | gtnMarketValue"
        (ngModelChange)="value=$event"
        [min]="min"
        [max]="max"
        [step]="step"
        [style.text-align]="textAlign"
        type="number"
        [readonly]="readonly"
        (blur)="onBlur()"/>
    <input
        *ngIf="isPasswordInput"
        gtnDisableAutofill
        matInput
        #matInput
        [(ngModel)]="value"
        (blur)="onBlur()"
        [style.text-align]="textAlign"
        [type]="isPasswordVisible ? 'text' : 'password'"
        [readonly]="readonly"/>
    <i *ngIf="isPasswordInput && !readonly"
        [ngClass]="{'pointer fas fa-solid fa-eye': !isPasswordVisible, 'pointer fas fa-eye-slash': isPasswordVisible }"
        matSuffix
        [matTooltip]="isPasswordVisible ? 'Hide password' : 'Show password'"
        (click)="isPasswordVisible = !isPasswordVisible"></i>
    <i class="fa fa-times clear-button"
        matSuffix
        *ngIf="showClearButton && value && !readonly"
        (click)="onClearClicked()"></i>
</mat-form-field>
<div class="gtn-control-error-message error-message"
    *ngIf="!formControl?.valid && !formControl?.pending && canShowValidation">
    <span *ngFor="let msg of getErrorMessage()">{{ (msg ? msg : formControl.valid) }}</span>
</div>
