import { ApplicationsEnum } from 'app/nexus-shared/enums';
import { CommonBaseAuditModel } from '../domain/contacts/models/common-base-audit.model';

export class SavedSettingModel extends CommonBaseAuditModel {
    savedSettingId: number;
    individualKey: string;
    name: string;
    application: ApplicationsEnum;
    key: string;
    value: any;

    constructor(model: SavedSettingModel = null) {
        super(model);
        this.savedSettingId = model?.savedSettingId ?? null;
        this.individualKey = model?.individualKey ?? null;
        this.name = model?.name ?? null;
        this.application = model?.application ?? null;
        this.key = model?.key ?? null;
        this.value = model?.value ?? null;
    }
}
