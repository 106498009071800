import { MatSnackBarConfig } from '@angular/material/snack-bar';

export class ToastModel {
    message: string;
    action: string;
    config: MatSnackBarConfig;

    constructor(model: ToastModel = null) {
        this.message = model?.message ?? null;
        this.action = model?.action ?? null;
        this.config = model?.config ?? {
            duration: 4000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: 'gtn-toast'
        };
    }
}
