import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { DocumentModel } from 'app/nexus-shared/domain/documents/models/document.model';
import { DocumentsGridComponent } from 'app/nexus-shared/domain/documents/components/grids/documents-grid/documents-grid.component';
import { FolderModel } from 'app/nexus-shared/domain/documents/models/folder.model';
import { FoldersGridComponent } from 'app/nexus-shared/domain/documents/components/grids/folders-grid/folders-grid.component';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FolderService } from 'app/nexus-core/services/domain/documents/folder.service';
import { DocumentService } from 'app/nexus-core/services/domain/documents/document.service';
import { FolderSearchModel } from 'app/nexus-shared/domain/documents/models/folder-search.model';
import { DocumentSearchModel } from 'app/nexus-shared/domain/documents/models/document-search.model';
import { forkJoin } from 'rxjs';
import { DocumentTabsEnum } from 'app/nexus-shared/domain/documents/enums/document-tabs.enum';
import { ApplicationsEnum, SelectListInterface } from 'app/nexus-shared/index';
import { TrashBinGridComponent } from 'app/nexus-shared/domain/documents/components/grids/trash-bin-grid/trash-bin-grid.component';
import { DocumentGridViewModel } from 'app/nexus-shared/domain/documents/models/document-view-models/document-grid-view.model';
import { DocumentsAuthModel } from 'app/nexus-shared/domain/documents/models/documents-auth.model';
import { AreaTypesEnum } from 'app/nexus-shared/domain/documents/enums/area-types.enum';
import { TemplateDocumentGridComponent } from 'app/nexus-shared/domain/documents/components/grids/template-document-grid/template-document-grid.component';
import { FolderFavoriteService } from 'app/nexus-core/services/domain/documents/folder-favorite.service';
import { FolderFavoriteModel } from 'app/nexus-shared/domain/documents/models/folder-favorite.model';
import { FolderFavoriteSearchModel } from 'app/nexus-shared/domain/documents/models/folder-favorite-search.model';
import { ToastService } from 'app/nexus-core/services/toast.service';
import { DocumentFolderMoveViewModel } from 'app/nexus-shared/domain/documents/models/document-view-models/document-folder-move-view.model';
import { FolderMoveModel } from 'app/nexus-shared/domain/documents/models/folder-move.model';
import { FolderDocumentGridService } from 'app/nexus-core/services/domain/documents/folder-document-grid.service';
import { TemplateFolderGridComponent } from 'app/nexus-shared/domain/documents/components/grids/template-folder-grid/template-folder-grid.component';
import { FolderCopyModel } from 'app/nexus-shared/domain/documents/models/folder-copy.model';
import { SpinnerService } from 'app/nexus-core';
import { finalize } from 'rxjs/operators';
import { FileDocumentModel } from 'app/nexus-shared/domain/documents/models/document-view-models/file-document.model';
import { DocumentProcessService } from 'app/nexus-core/services/domain/documents/document-process.service';
import { AuthorizationConstants } from 'app/nexus-shared/constants/authorization.constants';
import { DocumentRoutingKeyConstants } from 'app/modules/documents/routing/document-routing-key.constants';
import { DocumentHelperService } from 'app/nexus-core/services/domain/documents/document-helper.service';
import { ViewModesEnum } from 'app/nexus-shared/enums/view-modes.enum';
import { FolderTagModel } from 'app/nexus-shared/domain/documents/models/folder-tag.model';
import { Guid } from 'app/nexus-shared/models/guid.type';
import { DocumentsHelper } from 'app/nexus-core/helpers/documents.helper';

@Component({
    selector: 'gtn-folder-document',
    templateUrl: './folder-document.component.html',
    styleUrls: ['./folder-document.component.scss']
})
export class FolderDocumentComponent extends BaseComponent implements OnInit {
    @Input() rootFolder: FolderModel;
    @Input() tabs: SelectListInterface[];
    @Input() documentsUserAuth: DocumentsAuthModel;
    @Input() isIndividualCompanyView: boolean = false;
    @Input() application: ApplicationsEnum;

    document: DocumentModel;
    documents: DocumentModel[];
    documentFolderMove: DocumentFolderMoveViewModel;
    documentsGridData: DocumentGridViewModel[];
    externalDroppedFiles: File[];
    modalFolderKey: string;
    foldersGridData: FolderModel[];
    folderFavorites: FolderFavoriteModel[];
    folderModalView: ViewModesEnum;
    tagFoldersSearch: FolderSearchModel;
    gridAdminConstant: AuthorizationConstants = AuthorizationConstants.documentsAdmin;
    isShowTabContent: boolean = false;
    isFolderTagSearch: boolean = false;
    pageInit: boolean = true;
    selectedFolder: FolderModel;
    selectedFolderKey: string;
    selectedTab: SelectListInterface;
    selectedFolderTags: FolderTagModel[];
    showBulkUploadModal: boolean = false;
    showDocumentDetailModal: boolean = false;
    showFolderModal: boolean = false;
    showFolderTagIcon: boolean = false;
    showMoveConfirmModal: boolean = false;
    showUploadModal: boolean = false;
    tabsEnum = DocumentTabsEnum;

    isClientAreaFolder: boolean = false; //TODO remove when a better solution is found

    @ViewChild('documentsGrid') documentsGridComponent: DocumentsGridComponent;
    @ViewChild('foldersGrid') foldersGridComponent: FoldersGridComponent;
    @ViewChild('trashBinGrid') trashBinGridComponent: TrashBinGridComponent;
    @ViewChild('templateDocumentGrid') templateDocumentGridComponent: TemplateDocumentGridComponent;
    @ViewChild('templateFolderGrid') templateFolderGridComponent: TemplateFolderGridComponent;

    constructor(
        public documentProcessService: DocumentProcessService,
        private activatedRoute: ActivatedRoute,
        private documentService: DocumentService,
        private documentHelperService: DocumentHelperService,
        private folderService: FolderService,
        private folderFavoriteService: FolderFavoriteService,
        private folderDocumentGridService: FolderDocumentGridService,
        private toastService: ToastService,
        private router: Router
    ) {
        super();
        this.documentProcessService.documentService = this.documentService;
        this.documentProcessService.folderService = this.folderService;
    }

    ngOnInit(): void {
        this.selectedTab = this.tabs[0];
        this.activatedRoute.queryParams.subscribe(params => {
            if (params?.folder) {
                this.selectedFolderKey = params.folder;
            } else if (params?.tags) {
                this.isFolderTagSearch = true;
                this.handleTagParams(params.tags);
            } else {
                this.selectedFolderKey = null;
            }

            if (this.rootFolder.folderKey === DocumentRoutingKeyConstants.areaKeys.clients) {
                if (!this.isFolderTagSearch) {
                    this.handleRootClientFolder();
                } else if (this.selectedFolderKey) {
                    this.getSelectedFolder();
                }

            } else {
                this.setGridData(this.rootFolder.folderPath, this.selectedFolderKey, !this.pageInit);
                if (this.rootFolder.areaType === AreaTypesEnum.Clients) {
                    this.showFolderTagIcon = this.getShowTagIcon();
                }
            }
            this.pageInit = false;
        });

        this.setFolderFavorites();

        this.subscriptions.add(this.folderDocumentGridService.documentsGridRefresh$.subscribe(useCache => {
            if (this.isFolderTagSearch) {
                this.handleRefreshOnTags();
            } else {
                this.setGridData(this.rootFolder.folderPath, this.selectedFolderKey, useCache);
            }
        }));
    }

    private handleRootClientFolder(): void {
        if (!this.selectedFolderKey) {
            this.selectedFolder = this.rootFolder;
            this.documentsGridData = [];
            this.foldersGridData = [this.rootFolder];
            this.isClientAreaFolder = true;
        } else {
            this.folderService.get(this.selectedFolderKey).subscribe(res => {
                this.documentHelperService.routeToFolderDocument(res);
            });
        }
    }

    onCancelClicked(): void {
        this.showBulkUploadModal = false;
        this.showUploadModal = false;
        this.showDocumentDetailModal = false;
        this.showMoveConfirmModal = false;
        this.showFolderModal = false;
    }

    onCopyDocumentTemplates(selectedFolder: FolderModel = null): void {
        const folder = selectedFolder ?? this.selectedFolder;
        const documents = this.templateDocumentGridComponent.gridApi.getSelectedNodes().map(x => x.data);
        this.folderDocumentGridService.onDocumentsCopied(documents, folder);
        this.templateDocumentGridComponent.gridApi.deselectAll();
    }

    onCopyFolderTemplates(folderCopy: FolderCopyModel): void {
        this.folderDocumentGridService.copyFolder(folderCopy);
    }

    onCreateDocumentsClicked(documents: FileDocumentModel[]): void {
        this.documentProcessService.createDocuments(documents);
        this.showBulkUploadModal = false;
    }

    onDelete(): void {
        if (this.trashBinGridComponent) {
            this.trashBinGridComponent.refreshGridData();
        }
    }

    onDocumentFolderMoved(documentFolderMove: DocumentFolderMoveViewModel): void {
        if (!documentFolderMove?.folderMovedFrom && documentFolderMove?.folderMovedFromParentFolderKey) {
            documentFolderMove.folderMovedFrom = this.foldersGridData.find(x => x.folderKey === documentFolderMove.folderMovedFromParentFolderKey);
        }
        this.documentFolderMove = documentFolderMove;
        this.showMoveConfirmModal = true;
    }

    onDocumentFolderMoveConfirmed(): void {
        this.showMoveConfirmModal = false;
        if (this.documentFolderMove?.folderMoved) {
            const folderMoveModel = new FolderMoveModel(this.documentFolderMove.folderMoved.folderKey, this.documentFolderMove.folderMovedTo.folderKey);
            this.tagFoldersSearch = <FolderSearchModel>{ parentFolderPath: DocumentsHelper.getCommonPath(this.documentFolderMove.folderMovedFrom.folderPath, this.documentFolderMove.folderMovedTo.folderPath) };
            this.folderDocumentGridService.moveFolder(folderMoveModel);
        } else if (this.documentFolderMove?.documentsMoved?.length) {
            this.folderDocumentGridService.moveDocuments(this.documentFolderMove.documentsMoved, this.documentFolderMove.folderMovedTo.folderKey);
        }
    }

    onDocumentsGridReady(): void {
        this.setDropzones();
    }

    onDocumentRowClicked(document: DocumentModel) {
        this.document = new DocumentModel(document);
        this.showDocumentDetailModal = true;
    }

    onExternalFilesDropped(files: File[]): void {
        this.externalDroppedFiles = files;
        this.showBulkUploadModal = true;
    }

    onFolderFavorited(folder: FolderModel) {
        const folderFavorite = new FolderFavoriteModel();
        folderFavorite.folder = folder;
        this.folderFavoriteService.create(folderFavorite).subscribe(res => {
            folderFavorite.folderFavoriteKey = res;
            this.folderFavorites.push(folderFavorite);
            this.toastService.showMessageToast(`Folder ${folder.name} added to favorites`);
        }, error => {
            this.toastService.showErrorToast(error);
        });
    }

    onFolderFavoriteDeleted(folder: FolderModel) {
        const folderFavorite = this.folderFavorites.find(x => x.folder.folderKey === folder.folderKey);
        this.folderFavoriteService.delete(folderFavorite.folderFavoriteKey).subscribe(res => {
            const index = this.folderFavorites.findIndex(x => x.folderFavoriteKey === folderFavorite.folderFavoriteKey);
            this.folderFavorites.splice(index, 1);
            this.toastService.showMessageToast(`Folder ${folder.name} removed from favorites`);
        }, error => {
            this.toastService.showErrorToast(error);
        });
    }

    onFoldersGridReady(): void {
        this.setDropzones();
    }

    onFolderPropertiesSaved(): void {
        this.tagFoldersSearch = <FolderSearchModel>{ folderKeys: [this.modalFolderKey] };
        this.folderDocumentGridService.refreshDocuments();
        this.showFolderModal = false;
    }

    onFolderTemplatesGridReady(): void {
        this.setDropzones();
    }


    onFolderDataChanged(folderData: FolderModel[]) {
        if (this.selectedFolderKey && !folderData?.some(x => x.folderKey === this.selectedFolderKey)) {
            this.selectedFolderKey = null;
            this.router.navigate([],
                {
                    relativeTo: this.activatedRoute,
                    queryParams: {
                        tags: this.selectedFolderTags?.length ? this.selectedFolderTags.map(x => x.folderTagName).toString() : null
                    },
                });
        }
    }

    onFolderTagSearchModelChanged(folderSearchModel: FolderSearchModel): void {
        this.tagFoldersSearch = folderSearchModel;
    }

    onSaveUploadDocumentClicked(fileDocument: FileDocumentModel): void {
        this.showUploadModal = false;
        this.documentProcessService.uploadDocument(fileDocument);
        const node = this.documentsGridComponent.gridApi.getRowNode(fileDocument.document.documentKey);
        this.documentsGridComponent.setProcessingDocuments([node]);
    }

    onSelectedFolderChanged(folder: FolderModel): void {
        this.setQueryParameters(folder?.folderKey);
    }

    onBreadcrumbClicked(folderKey: string): void {
        if (folderKey !== this.rootFolder.folderKey) {
            this.setQueryParameters(folderKey);
        } else {
            this.setQueryParameters(null);
        }
    }

    onSelectedFolderDeleted(parentFolderKey: string): void {
        if (!this.isFolderTagSearch) {
            const searchModel: FolderSearchModel = new FolderSearchModel();
            searchModel.parentFolderPath = this.rootFolder.folderPath;
            this.folderService.searchByPath(searchModel, false).subscribe(res => {
                this.foldersGridData = res;
                this.setQueryParameters(parentFolderKey);
            });
        } else {
            this.onSelectedFolderChanged(this.foldersGridData.find(x => x.folderKey === parentFolderKey));
            this.folderDocumentGridService.refreshDocuments();
        }
    }

    onTabChanged(): void {
        // grids not resizing on tab change
        setTimeout(() => {
            if (this.selectedTab.id === DocumentTabsEnum.FileTemplates) {
                this.templateDocumentGridComponent.gridApi.sizeColumnsToFit();
            } else if (this.selectedTab.id === DocumentTabsEnum.DeletedItems) {
                this.trashBinGridComponent.gridApi.sizeColumnsToFit();
            } else if (this.selectedTab.id === DocumentTabsEnum.FolderTemplates) {
                this.templateFolderGridComponent.gridApi.sizeColumnsToFit();
            }
        }, 10);
    }

    onTemplateDocumentsGridReady(): void {
        this.setDropzones();
    }

    onUploadDocumentClicked(document: DocumentModel): void {
        if (!document?.documentKey) {
            this.showBulkUploadModal = true;
        } else {
            this.document = new DocumentModel(document);
            this.document.eventNote = null;
            this.showUploadModal = true;
        }
    }

    onViewFolderPropertiesClicked(folder: FolderModel): void {
        this.modalFolderKey = folder.folderKey;
        this.folderModalView = ViewModesEnum.FolderProperties;
        this.showFolderModal = true;
    }

    onFolderTagsClicked(): void {
        this.modalFolderKey = this.foldersGridData.find(x => x.folderKey === this.rootFolder.folderKey)?.folderKey;
        this.folderModalView = ViewModesEnum.FolderTags;
        this.showFolderModal = true;
    }

    private handleRefreshOnTags(): void {
        // handling the folders grid updates manually;
        this.getSelectedFolder();
        if (this.tagFoldersSearch) {
            this.folderService.search(this.tagFoldersSearch).subscribe(res => {
                //add or update
                if (res?.length) {
                    res.forEach(x => {
                        const i = this.foldersGridData.findIndex(y => y.folderKey === x.folderKey);
                        if (i < 0) {
                            this.foldersGridData.push(x);
                        } else {
                            this.foldersGridData[i] = new FolderModel(x);
                        }
                    });
                } else if (this.tagFoldersSearch?.folderKeys?.length === 1) { //todo btter way to delete?
                    //delete
                    const parentFolder = this.foldersGridData.find(y => y.folderKey === this.tagFoldersSearch.folderKeys[0]);
                    this.foldersGridData = this.foldersGridData.filter(x => !x.folderPath.includes(parentFolder.folderPath));
                }

                if (!this.foldersGridData.some(x => this.selectedFolder.folderKey === x.folderKey)) {
                    this.documentsGridData = [];
                    this.documentsGridComponent.refreshGridData();
                }
                this.foldersGridComponent.refreshGridData();
                this.tagFoldersSearch = null;
            });
        }
    }

    private handleTagParams(tags: string): void {
        this.foldersGridData = null;
        this.documentsGridData = null;
        this.selectedFolder = null;
        this.selectedFolderTags = tags.split(',').map(x => {
            return { folderTagName: x, folderTagKey: Guid.Empty };
        });
        this.selectedFolder = JSON.parse(JSON.stringify(this.rootFolder));
        this.documentsGridData = [];
        this.folderService.search(<FolderSearchModel>{ folderTags: this.selectedFolderTags.map(x => x.folderTagName), includeChildren: true }).subscribe(res => {
            this.foldersGridData = res;
        });
        this.router.navigate([], { replaceUrl: true });
    }

    private getShowTagIcon(): boolean {
        return (!!this.rootFolder.clientFolder.companyKey && !this.rootFolder.clientFolder.individualKey) || (!!this.rootFolder?.clientFolder.individualKey && !this.rootFolder.clientFolder.companyKey);
    }

    private getSelectedFolder(): void {
        const documentSearchModel = new DocumentSearchModel();
        documentSearchModel.areaTypes = [this.rootFolder.areaType];
        documentSearchModel.includeCompany = true;
        documentSearchModel.includeIndividual = true;
        documentSearchModel.folderKey = this.selectedFolderKey;
        forkJoin({
            documents: this.documentService.search(documentSearchModel),
            selectedFolder: this.folderService.get(this.selectedFolderKey)
        }).subscribe(res => {
            this.documentsGridData = [];
            if (res.documents?.length) {
                this.documents = res.documents;
                this.documentsGridData = res.documents.map(x => new DocumentGridViewModel(x));
            }

            if (res.selectedFolder) {
                this.selectedFolder = new FolderModel(res.selectedFolder);
                this.documentsGridData = this.documentsGridData.concat(this.selectedFolder?.childFolders?.map(y => new DocumentGridViewModel(y)));
            }
        });
    }

    private setFolderFavorites(): void {
        this.folderFavoriteService.search(new FolderFavoriteSearchModel()).subscribe(res => {
            this.folderFavorites = res.map(x => new FolderFavoriteModel(x));
        });
    }

    private setGridData(rootFolderPath: string, selectedFolderKey: string, useCache: boolean = false): void {
        if (!useCache) {
            SpinnerService.start();
        }
        const folderSearchModel = new FolderSearchModel();
        const documentSearchModel = new DocumentSearchModel();
        documentSearchModel.areaTypes = [this.rootFolder.areaType];
        documentSearchModel.includeCompany = true;
        documentSearchModel.includeIndividual = true;
        if (!selectedFolderKey || selectedFolderKey === this.rootFolder.folderKey) {
            selectedFolderKey = this.rootFolder.folderKey;
            this.setQueryParameters(null);
            documentSearchModel.folderKey = this.rootFolder.folderKey;
        } else {
            documentSearchModel.folderKey = selectedFolderKey;
        }

        folderSearchModel.parentFolderPath = rootFolderPath;
        forkJoin({
            documents: this.documentService.search(documentSearchModel),
            folders: this.folderService.searchByPath(folderSearchModel, useCache),
            selectedFolder: this.folderService.get(selectedFolderKey)
        }).pipe(finalize(() => {
            SpinnerService.stop();
        })).subscribe(res => {
            this.documentsGridData = [];
            if (res.selectedFolder?.folderKey) {
                this.selectedFolder = res.selectedFolder;
            } else {
                this.setQueryParameters(null);
                return;
            }

            if (res.documents?.length) {
                this.documents = res.documents;
                this.documentsGridData = res.documents.map(x => new DocumentGridViewModel(x));
            }

            if (res.folders) {
                this.documentsGridData = this.documentsGridData.concat(this.selectedFolder?.childFolders?.map(y => new DocumentGridViewModel(y)));
                this.foldersGridData = res.folders;
            }
        }, err => {
            this.toastService.showErrorToast(err);
            this.setQueryParameters(null);
        });
    }

    private setDropzones(): void {
        if (this.foldersGridComponent?.isGridReady && this.documentsGridComponent?.isGridReady && this.templateDocumentGridComponent?.isGridReady && this.templateFolderGridComponent?.isGridReady) {
            this.setDocumentsGridDropzone();
            this.setFoldersGridDropzone();
            if (this.rootFolder.areaType === AreaTypesEnum.Clients) {
                this.setTemplateDocumentsGridDropzone();
                this.setTemplateFoldersGridDropzone();
            }
        }
    }

    private setFoldersGridDropzone(): void {
        const folderDropzone = this.documentsGridComponent.gridApi.getRowDropZoneParams(
        );

        this.foldersGridComponent.gridApi.addRowDropZone(folderDropzone);
    }

    private setDocumentsGridDropzone(): void {
        const documentDropzone = this.foldersGridComponent.gridApi.getRowDropZoneParams(
        );

        this.documentsGridComponent.gridApi.addRowDropZone(documentDropzone);
    }

    private setTemplateDocumentsGridDropzone(): void {
        const documentDropzone = this.documentsGridComponent.gridApi.getRowDropZoneParams(
            {
                onDragStop: (event) => {
                    this.onCopyDocumentTemplates();
                }
            }
        );
        this.templateDocumentGridComponent.gridApi.addRowDropZone(documentDropzone);

        const folderDropzone = this.foldersGridComponent.gridApi.getRowDropZoneParams({
            onDragStop: (event) => {
                this.onCopyDocumentTemplates(event?.overNode?.data ?? this.rootFolder);
            }
        });
        this.templateDocumentGridComponent.gridApi.addRowDropZone(folderDropzone);
    }

    private setTemplateFoldersGridDropzone(): void {
        const documentDropzone = this.documentsGridComponent.gridApi.getRowDropZoneParams(
            {
                onDragStop: (event) => {
                    const folderCopy = new FolderCopyModel(event.node.data.folderKey, this.selectedFolder?.folderKey ?? this.rootFolder.folderKey);
                    this.onCopyFolderTemplates(folderCopy);
                }
            }
        );
        this.templateFolderGridComponent.gridApi.addRowDropZone(documentDropzone);

        const folderDropzone = this.foldersGridComponent.gridApi.getRowDropZoneParams({
            onDragStop: (event) => {
                const folderCopy = new FolderCopyModel(event.node.data.folderKey, event.overNode?.data?.folderKey ?? this.rootFolder.folderKey);
                this.onCopyFolderTemplates(folderCopy);
            }
        });
        this.templateFolderGridComponent.gridApi.addRowDropZone(folderDropzone);
    }

    private setQueryParameters(folderKey: string): void {
        const queryParams: Params = { folder: folderKey };
        this.router.navigate([],
            {
                relativeTo: this.activatedRoute,
                queryParams: queryParams,
                queryParamsHandling: 'merge'
            });
    }

}
