export enum IndividualTypesEnum {
    Unknown = 0,
    GtnEmployee = 10,
    MobileEmployee = 20,
    Independent = 30,
    CompanyContact = 40,
    CompanyAdministrator = 50,
    RelocationAdministrator = 60,
    Other = 500
}
