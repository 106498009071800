<gtn-modal *ngIf="showModal" class="modal-wide" headerText="Application Use Agreement" footerPosition="middle"
           okButtonText="I Agree"
           (scroll)="onContentScrolled($event)"
           [disableOkButton]="disableAccept"
           (okClick)="onAgreeClicked()"
           (cancelClick)="onCancel2FAClicked()">
    <gtn-modal-content>
        <div class="main-container row">
            <div class="col-12" >
                <div class="aua-content-container" [innerHTML]="clientAUA.aua.htmlContent | safeHtml"></div>
            </div>
        </div>
    </gtn-modal-content>
</gtn-modal>
