<gtn-modal class="small" cancelButtonText="Log Out" (cancelClick)="onLogOutClicked()" headerText="Inactive Session Detected" okButtonText="Stay Logged In"
           (okClick)="onResetInactivityClicked()">
    <gtn-modal-content>
        <div class="row">
            <div class="col-12">
                We have detected no activity in your session for the last {{idleWarningInMinutes}} minutes. You can choose to take action now, or you will be automatically logged out.
            </div>
        </div>
        <div class="row mt-3 align-center">
            <div class="col-12 font-weight-bold timer-text">
                {{timerText}}
            </div>
        </div>
    </gtn-modal-content>
</gtn-modal>
