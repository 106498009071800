<gtn-user-impersonations-grid
    #userImpersonationGrid [application]="application"
    [gridAdminAuthConstant]="gridAdminConstant"
    (userImpersonationClick)="onUserImpersonationClicked($event)"
    (addButtonClick)="onAddUserImpersonationClicked()">
</gtn-user-impersonations-grid>
<gtn-user-impersonation-modal
    *ngIf="showImpersonationModal"
    [userImpersonationKey]="userImpersonation?.userImpersonationKey"
    (completed)="onUserImpersonationCompleted($event)">
</gtn-user-impersonation-modal>
