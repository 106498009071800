import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { ThemePalette } from '@angular/material/core';

@Component({
    selector: 'gtn-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnChanges {
    @Input() color: ThemePalette = 'primary';
    @Input() mode: ProgressBarMode = 'determinate';
    @Input() bufferValue: number;
    @Input() remainingValue: number;
    @Input() showProgressOverlay: boolean = false;
    @Input() heightPx: number = 24;

    progressPercentage: number;

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.remainingValue && this.remainingValue > 0) {
            this.progressPercentage = Math.round(((this.bufferValue - this.remainingValue) / this.bufferValue) * 100);
        } else if (this.remainingValue <= 0) {
            this.progressPercentage = 100;
        }
    }
}
