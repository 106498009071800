import { Component } from '@angular/core';
import { BaseDynamicComponentDirective } from 'app/nexus-shared/components/dynamic-form/shared';

@Component({
    selector: 'gtn-dynamic-checkbox',
    templateUrl: './dynamic-checkbox.component.html',
    styleUrls: ['./dynamic-checkbox.component.scss']
})
export class DynamicCheckboxComponent extends BaseDynamicComponentDirective {
}
