import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyCodeConstants } from 'app/nexus-shared/constants/currency-code.constants';

@Pipe({
    name: 'gtnCurrency'
})
export class GtnCurrencyPipe implements PipeTransform {
    constructor(private currencyPipe: CurrencyPipe) { }

    transform(value: any, currencyCode: string = CurrencyCodeConstants.USD, exchangeRate: number = null): string {
        if (value !== null && !Number.isNaN(value)) {
            if (exchangeRate) {
                value = value * exchangeRate;
            }

            return this.currencyPipe.transform(value, currencyCode);
        } else {
            return value;
        }
    }
}
