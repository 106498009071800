import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { ControlValueAccessor } from '@angular/forms';
import { SearchModel } from 'app/nexus-shared/components/controls/shared/models/search.model';
import { SearchResultIconEnum, SearchResultModel } from 'app/nexus-shared/components/controls';
import { FolderSearchModel } from 'app/nexus-shared/domain/documents/models/folder-search.model';
import { FolderService } from 'app/nexus-core/services/domain/documents/folder.service';
import { FolderModel } from 'app/nexus-shared/domain/documents/models/folder.model';
import { SearchHelper } from 'app/nexus-core';

@Component({
    selector: 'gtn-client-individual-folder-search',
    templateUrl: './client-individual-folder-search.component.html',
    styleUrls: ['./client-individual-folder-search.component.scss']
})
export class ClientIndividualFolderSearchComponent extends BaseComponent implements ControlValueAccessor {
    @Input('value') _value: any = null;
    @Input() ngModel: FolderModel;
    @Input() disabled: boolean;
    @Input() readonly: boolean;
    @Input() label: string = 'Client';
    @Input() placeholder: string = 'Search client or employee';
    @Input() showAddLink: boolean = false;
    @Input() addDefaultOptions: boolean = false;
    @Input() folderSearchModel: FolderSearchModel = new FolderSearchModel();

    @Output() searchResultClick: EventEmitter<FolderModel> = new EventEmitter();
    @Output() clearResultClick: EventEmitter<void> = new EventEmitter();

    searchModel = new SearchModel();
    requiredCharacterCount: number = 2;

    constructor(
        private folderService: FolderService
    ) {
        super();
        this.subscriptions.add(this.searchModel.onTextChanged.subscribe(searchText => {
            this.folderSearchModel.searchTerm = searchText;
            this.folderSearchModel.includeIndividual = true;
            this.folderSearchModel.includeCompany = true;
            this.folderService.search(this.folderSearchModel).subscribe(res => {
                const x = res.filter(y => y.name === y.clientFolder.companyLegalName || y.name === y.clientFolder.individualLastName + ', ' + y.clientFolder.individualFirstName);
                const results = x.map((folder: FolderModel) => {
                    return {
                        id: folder.folderKey,
                        name: folder.name,
                        subName: folder.clientFolder.companyKey && folder.clientFolder.individualKey ? folder.clientFolder.companyName : null,
                        type: null,
                        iconClass: folder.clientFolder.individualKey ? SearchResultIconEnum.user : SearchResultIconEnum.business,
                        resultObject: folder,
                        rank: 0
                    } as SearchResultModel<void, FolderModel>;
                });

                const orderedResults = SearchHelper.orderSearchResults<void, FolderModel>(results, searchText, []);
                this.searchModel.onResultsReceived.next(orderedResults);
            }, err => this.searchModel.onErrorReceived.next(err));
        }));

        this.subscriptions.add(this.searchModel.onResultClicked.subscribe(result => {
            if (result) {
                this.searchResultClick.emit(result.resultObject);
                this.writeValue(result.resultObject);
            } else {
                this.clearResultClick.emit();
            }

        }));
    }

    get value() {
        return this._value;
    }

    set value(client: any) {
        this._value = client;
        this.writeValue(client);

    }

    registerOnChange(fn: Function): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: Function): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    writeValue(client: any): void {
        this.ngModel = client;
        this._value = client;
        this.onChange(client);
        this.onTouched();
    }

    private onChange: Function = () => {
    };
    private onTouched: Function = () => {
    };
}
