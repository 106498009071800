import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateHelper } from 'app/nexus-core';
import { BaseControlComponent } from '../base-control.component';

@Component({
    selector: 'gtn-month-day-picker',
    templateUrl: './month-day-picker.component.html',
    styleUrls: ['./month-day-picker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MonthDayPickerComponent),
            multi: true
        }, {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => MonthDayPickerComponent),
            multi: true
        }
    ]
})
export class MonthDayPickerComponent extends BaseControlComponent<Date> implements ControlValueAccessor, OnInit {
    @Input() suppressInitialChangeEvent: boolean = false;
    @Input() isLocaleAware: boolean = false;

    @Output() dateChanged: EventEmitter<Date> = new EventEmitter<Date>();

    monthOptions: { id: number, name: string }[];
    dayOptions: { id: number, name: string }[];
    initialMonthLoad: boolean = false;
    initialDayLoad: boolean = false;
    selectedDay: number;
    selectedMonth: number;

    ngOnInit(): void {
        this.monthOptions = DateHelper.monthAbbreviations.map((month: string, index: number) => {
            return {
                id: index,
                name: month
            };
        });
        this.dayOptions = this.generateDays(0);
    }

    set value(val: Date) {
        if (val && !this.isLocaleAware) {
            this._value = DateHelper.convertToZeroDate(val);
        }
        this.onChange(this._value);
        this.dateChanged.emit(this._value);
        this.onTouched();
    }

    onMonthChanged(month): void {
        this.dayOptions = this.generateDays(month.value);
        if (!this.initialMonthLoad) {
            this.writeValue(null);
        }
        this.initialMonthLoad = false;

    }

    onDayChanged(): void {
        if (!this.initialDayLoad) {
            this.writeValue(null);
        }
        this.initialDayLoad = false;
    }

    writeValue(val): void {
        let value = null;
        const year = new Date().getFullYear();
        if (val && (!this.selectedDay || !this.selectedMonth)) {
            const date = DateHelper.convertToUtcDate(val);
            this.initialMonthLoad = true;
            this.initialDayLoad = true;
            this.selectedMonth = date.getMonth();
            this.selectedDay = date.getDate();
        } else if ((this.selectedMonth || this.selectedMonth === 0) && this.selectedDay) {
            value = new Date(year, this.selectedMonth, this.selectedDay);
            this.value = value;
        }
    }

    private generateDays(month: number) {
        const year = new Date().getFullYear();
        // Days in month actually returns all days for previous month.  Hence the +1
        const days = DateHelper.daysInMonth(year, month + 1);

        const daysOptions = [];

        for (let i = 1; i <= days; i++) {
            daysOptions.push({
                id: i,
                name: i.toString()
            });
        }

        return daysOptions;
    }
}
