import { animate, AnimationMetadata, style, transition, trigger } from '@angular/animations';

export class InfoPanelOpen {
    /**
     * Use '@infoPanelOpen' on the parent element
     * in the markup.
     */
    static animations = InfoPanelOpen.getAnimation();

    private static getAnimation(): AnimationMetadata[] {
        return [trigger('infoPanelOpen', [
            transition(':enter', [
                style({
                    overflow: 'hidden',
                    height: '0px'
                }),
                // group([
                animate('300ms', style({
                    overflow: 'hidden',
                    height: '*',
                    // 'padding-bottom': '15px',
                    // 'padding-top': '25px'
                })),
                //     query('@fadeInBumpDown',
                //         stagger(100, [
                //             animateChild()
                //         ]),
                //         { optional: true })
                // ])
            ]),
            transition(':leave', [
                style({
                    overflow: 'hidden',
                    height: '*'
                }),
                // group([
                animate('300ms', style({
                    overflow: 'hidden',
                    height: '0px',
                    'padding-bottom': '0',
                    'padding-top': '0'
                })),
                //     query('@fadeInBumpDown',
                //         animateChild(),
                //         { optional: true })
                // ])
            ])
        ]),
            // FadeInBumpDown.animations('400ms')
        ];
    }
}
