import { Component, OnInit } from '@angular/core';
import { BaseQuestionnaireFormComponent } from 'app/nexus-shared/domain/questionnaires/components/forms/base-questionnaire-form.component';
import { QuestionnaireFormHelper } from 'app/nexus-shared/domain/questionnaires/components/forms/questionnaire-form.helper';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { QuestionnaireTemplateModel } from 'app/nexus-shared/domain/questionnaires/models/questionnaire-template.model';
import { UntypedFormArray } from '@angular/forms';
import { NumericActionEventModel } from 'app/nexus-shared/domain/questionnaires/models/numeric-action-event.model';
import { SectionModel } from 'app/nexus-shared/domain/questionnaires/models/section.model';
import { Guid } from 'guid-typescript';
import { QuestionnaireHelper } from 'app/nexus-core/helpers/questionnaire.helper';
import { QuestionnaireEventService } from 'app/nexus-core/services/domain/questionnaires/questionnaire-event.service';
import { BaseFormModel } from 'app/nexus-shared/models/base-form.model';
import { QuestionnaireInterface } from 'app/nexus-shared/domain/questionnaires/interfaces/questionnaire.interface';

@Component({ template: '' })
export abstract class BaseEditQuestionnaireFormComponent extends BaseQuestionnaireFormComponent<QuestionnaireInterface> implements OnInit {
    visibleSections: SectionModel[];
    sectionsFormArray: UntypedFormArray;
    selectedSection: SectionModel;

    protected constructor(protected questionnaireEventService: QuestionnaireEventService) {
        super();
    }


    ngOnInit(configuration: BaseFormModel = null, isReload: boolean = false) {
        const formConfiguration = configuration ?? QuestionnaireFormHelper.getQuestionnaireFormConfiguration();
        this.questionnaireEventService.sectionColumnDropdownActionValueChange$.subscribe(_ => {
            this.setVisibleSections();
        });
        this.questionnaireEventService.sectionColumnNumericActionValueChange$.subscribe(action => {
            this.onSectionTriggered(action);
        });
        super.ngOnInit(formConfiguration, isReload);
    }

    protected initFormCustomizations() {
        this.sectionsFormArray = (this.formGroupRef.get(ObjectHelper.nameOf<QuestionnaireTemplateModel>('sections')) as UntypedFormArray);
        this.setVisibleSections();
        this.selectedSection = this.visibleSections[0];

    }

    onSectionTriggered(action: NumericActionEventModel) {
        const sectionIndexes = this.formGroupRef.value.sections
            .filter(s => s.parentSectionColumnKey === action.columnKey)
            .map(s => this.formGroupRef.value.sections.findIndex(ss => s.key === ss.key))
            .reverse();
        const numberOfExistingSections = sectionIndexes.length;
        // if there are multiple then adjust as needed
        if (action.value) {
            // if there are currently more sections than new number, remove up to number of sections
            if (numberOfExistingSections > action.value) {
                const minIndex = Math.min(...sectionIndexes);
                for (const sectionIndexToRemove of sectionIndexes.filter(s => s >= minIndex + action.value)) {
                    this.sectionsFormArray.removeAt(sectionIndexToRemove);
                }
            }
            // else if there are less sections then add more, starting at number of sections
            else if (numberOfExistingSections < action.value) {
                const section = this.formGroupRef.value.sections.find(s => s.key === action.sectionKey);

                for (let i = numberOfExistingSections + 1; i <= action.value; i++) {
                    const newSection = new SectionModel(section);
                    newSection.key = Guid.create().toString();
                    newSection.title = (section.title + ' ' + i);
                    newSection.order = this.formGroupRef.value.sections.length + 1;
                    newSection.parentSectionColumnKey = action.columnKey;
                    if (newSection.rows.length) {
                        for (const row of newSection.rows) {
                            row.key = Guid.create().toString();

                            for (const col of row.columns) {
                                col.key = Guid.create().toString();
                            }
                        }
                    }

                    this.initFormArrayFormGroup(newSection, this.sectionsFormArray, QuestionnaireFormHelper.getSectionFormConfiguration());
                }
            }
        } else if (numberOfExistingSections > action.value) {
            for (const sectionIndexToRemove of sectionIndexes.filter(s => s > action.value + 1)) {
                this.sectionsFormArray.removeAt(sectionIndexToRemove);
            }
        }

        this.setVisibleSections();
    }

    onSectionClicked(section: SectionModel) {
        this.selectedSection = section;
    }

    private setVisibleSections(): any {
        this.visibleSections = this.value.sections.filter((section: SectionModel) => QuestionnaireHelper.isSectionVisible(section, this.value));
    }
}
