import { BaseRouteApplicationConfigurationModel } from 'app/nexus-shared';
import { ContactsRoutingKeyConstants } from 'app/modules/contacts/routing/contacts-routing-key.constants';

export class ContactsRoutingCompaniesConfigurationConstants {
    static baseRoute: string = 'contacts/companies/';

    static readonly configurations = {
        getRoot: (): BaseRouteApplicationConfigurationModel => {
            return {
                applicationMenuId: ContactsRoutingKeyConstants.menuIds.companyDetailRoot,
                routing: {
                    routerModulePath: `company-detail/:${ContactsRoutingKeyConstants.routeParamKeys.company}`,
                    navigationRoute: (key: string) => [`${ContactsRoutingCompaniesConfigurationConstants.baseRoute}company-detail/${key}`]
                }
            };
        },
        getGeneral: (): BaseRouteApplicationConfigurationModel => {
            return {
                applicationMenuId: ContactsRoutingKeyConstants.menuIds.companyDetailGeneral,
                menu: {
                    title: 'General',
                    iconClass: 'fas fa-address-card',
                    order: 0,
                },
                routing: {
                    routerModulePath: 'general',
                    navigationRoute: (key: string) => [`${ContactsRoutingCompaniesConfigurationConstants.baseRoute}company-detail/${key}/general`]
                }
            };
        },
        getRelationships: (): BaseRouteApplicationConfigurationModel => {
            return {
                menu: {
                    title: 'Relationships',
                    iconClass: 'fas fa-handshake-angle',
                    order: 20,
                    isExpanded: true
                },
                routing: {
                    useExact: false,
                },
                childRoutes: [
                    ContactsRoutingCompaniesConfigurationConstants.configurations.getCompanyRelationships(),
                    ContactsRoutingCompaniesConfigurationConstants.configurations.getIndividualRelationships()
                ]
            };
        },
        getIndividualRelationships: (): BaseRouteApplicationConfigurationModel => {
            return {
                menu: {
                    title: 'Individuals',
                    iconClass: 'fas fa-user-friends',
                    order: 10,
                },
                routing: {
                    routerModulePath: 'relationships/individuals',
                    navigationRoute: (key: string) => [`${ContactsRoutingCompaniesConfigurationConstants.baseRoute}company-detail/${key}/relationships/individuals`]
                }
            };
        },
        getCompanyRelationships: (): BaseRouteApplicationConfigurationModel => {
            return {
                menu: {
                    title: 'Companies',
                    iconClass: 'far fa-buildings',
                    order: 0,
                },
                routing: {
                    routerModulePath: 'relationships/companies',
                    navigationRoute: (key: string) => [`${ContactsRoutingCompaniesConfigurationConstants.baseRoute}company-detail/${key}/relationships/companies`]
                }
            };
        }
    };

    static mainMenuItems = [
        ContactsRoutingCompaniesConfigurationConstants.configurations.getGeneral(),
        ContactsRoutingCompaniesConfigurationConstants.configurations.getRelationships(),
    ];
}
