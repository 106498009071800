import { Component } from '@angular/core';
import { IToolPanelAngularComp } from 'ag-grid-angular';
import { IGridSettingsToolPanelParamsInterface } from 'app/nexus-shared/components/controls/components/base-grid/tool-panels/grid-settings-panel/grid-settings-tool-panel-params.interface';
import { SavedSettingService } from 'app/nexus-core/services/domain/core/saved-setting.service';
import { AuthenticatedUserService } from 'app/nexus-core/services/authenticated-user.service';
import { SavedSettingSearchModel } from 'app/nexus-shared/models/saved-setting-search.model';
import { SavedSettingModel } from 'app/nexus-shared/models/saved-setting.model';
import { UserModel } from 'app/nexus-shared/domain/users/models/user.model';
import { GridSettingsModel } from 'app/nexus-shared/models/grid-settings.model';
import { AuthorizationHelper } from 'app/nexus-core/helpers/authorization.helper';

@Component({
    selector: 'gtn-grid-settings-panel',
    templateUrl: './grid-settings-panel.component.html',
    styleUrls: ['./grid-settings-panel.component.scss']
})
export class GridSettingsPanelComponent implements IToolPanelAngularComp  {
    params: IGridSettingsToolPanelParamsInterface;

    canShareGrid: boolean;
    gridSettings: GridSettingsModel;
    isSaveGridSettings: boolean = false;
    lastSettingApplied: string;
    sharedSetting: SavedSettingModel;
    sharedUserGridSettings: SavedSettingModel[];
    user: UserModel;
    userGridSettings: SavedSettingModel[];
    userSetting: SavedSettingModel;

    constructor(private savedSettingService: SavedSettingService,
                private authenticatedUserService: AuthenticatedUserService) {
    }

    agInit(params: IGridSettingsToolPanelParamsInterface): void {
        this.params = params;
        this.user = this.authenticatedUserService.user;
        this.canShareGrid = AuthorizationHelper.hasAuthorization(this.user, this.params.gridAdminConstant);
        this.getUserGridSettings();
        this.getSharedViews();
    }

    onSizeColumnsToFitClicked(): void {
        this.params.api.sizeColumnsToFit();
        this.params.sizeColumnsToFitClick();
    }

    onSizeColumnsToFitContentClicked(): void {
        this.params.columnApi.autoSizeAllColumns();
        this.params.sizeColumnsToFitContentClick();
    }

    onResetColumnFiltersClicked(): void {
        this.params.resetColumnFiltersClick();
        this.lastSettingApplied = null;
    }

    onSavedSettingSelected(savedSetting: SavedSettingModel) {
        if (savedSetting?.savedSettingId) {
            this.params.savedGridSettingClick(savedSetting);
            this.lastSettingApplied = savedSetting?.name;
        }
        setTimeout(() => {
            this.sharedSetting = null;
            this.userSetting = null;
        });

    }

    onSaveUserGridSettingsClicked(): void {
        this.gridSettings = new GridSettingsModel();
        this.gridSettings.columnState = this.params.columnApi.getColumnState();
        this.gridSettings.filterState = this.params.api.getFilterModel();
        this.isSaveGridSettings = true;
    }

    onSaveSettingSuccess(closeModal: boolean = true) {
        this.getUserGridSettings();
        this.getSharedViews();
        if (closeModal) {
            this.isSaveGridSettings = false;
        }
    }

    private getUserGridSettings(): void {
        if (this.params?.application && this.params?.gridVersion) {
            this.savedSettingService.search(<SavedSettingSearchModel>
                { individualKey: this.user?.individualKey, application: this.params.application, key: this.params.gridVersion }).subscribe(res => {
                this.userGridSettings = res;
            });
        }
    }

    private getSharedViews(): void {
        if (this.params?.application && this.params?.gridVersion) {
            this.savedSettingService.search(<SavedSettingSearchModel>{ application: this.params.application, key: this.params.gridVersion }).subscribe(res => {
                this.sharedUserGridSettings = res;
            });
        }
    }

}
