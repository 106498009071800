import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { NexusAnimations } from 'app/nexus-shared/animations';
import { FileViewModel } from 'app/nexus-shared/components/controls/shared/models/file-view.model';

@Component({
    selector: 'gtn-image-carousel',
    templateUrl: './image-carousel.component.html',
    styleUrls: ['./image-carousel.component.scss'],
    animations: [
        NexusAnimations.fadeIn
    ]
})
export class ImageCarouselComponent extends BaseComponent {
    @Input() imageSources: FileViewModel[];
    @Input() selectedImageIndex: number = 0;
    @Input() isModal: boolean = false;
    @Input() isOutsideClose: boolean = false;
    @Output() carouselClose: EventEmitter<void> = new EventEmitter();
    @Output() nextImageClick: EventEmitter<void> = new EventEmitter();
    @Output() previousImageClick: EventEmitter<void> = new EventEmitter();

    onCloseCarouselClicked(isOutsideCloseClick: boolean = false): void {
        if (isOutsideCloseClick && this.isOutsideClose) {
            this.carouselClose.emit();
        } else if (!isOutsideCloseClick) {
            this.carouselClose.emit();
        }
    }

    nextImage(): void {
        if (this.selectedImageIndex === this.imageSources.length - 1) {
            this.selectedImageIndex = 0;
            return;
        }
        this.selectedImageIndex++;
        this.nextImageClick.emit();
    }

    previousImage(): void {
        if (this.selectedImageIndex === 0) {
            this.selectedImageIndex = this.imageSources.length - 1;
            return;
        }
        this.selectedImageIndex--;
        this.previousImageClick.emit();
    }
}


