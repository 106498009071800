import { DocumentModel } from 'app/nexus-shared/domain/documents/models/document.model';

export class FileDocumentModel {
    file: File;
    document: DocumentModel;

    constructor(document: DocumentModel = null, file: File = null) {
        this.file = file ?? null;
        this.document = document ?? new DocumentModel();
    }
}
