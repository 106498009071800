<mat-toolbar id="nav">
    <ng-container *ngIf="userImpersonation">
        <div class="flex error-message mr-2 pointer" matTooltip="IMPERSONATION ACTIVE"
            (click)="applicationConfigurationService.showUserImpersonationModal = true">
            <div>
                <i class="fa-solid fa-triangle-exclamation mr-1"></i>
            </div>
            <div class="flex mr-1">
                {{ userImpersonation.impersonationUserName }}
            </div>
            <div>
                <i class="fa-solid fa-triangle-exclamation"></i>
            </div>
        </div>
    </ng-container>
    <div id="rightModuleBar">
        <div class="mr-3" *ngIf="applicationConfigurationService.dynamicHeaderComponent">
            <dynamic-component
                [componentConfiguration]="applicationConfigurationService.dynamicHeaderComponent"></dynamic-component>
        </div>
        <gtn-search *ngIf="isSearchVisible" [useDefaultStyling]="false" [searchModel]="searchService">
        </gtn-search>
    </div>
    <ng-container *ngIf="!authenticatedUserService.isAuthenticated">
        <a (click)="onLoginClicked()">Login</a>
    </ng-container>

    <div class="user-container" *ngIf="isSyncCompleted">
        <i *ngIf="navMenuItems?.length > 1" [matMenuTriggerFor]="appMenu" class="material-icons noselect">apps</i>
        <img *ngIf="profilePictureSrcUrl" [matMenuTriggerFor]="userMenu" [src]="profilePictureSrcUrl"
            alt="Profile picture"/>
    </div>
    
    <mat-menu #appMenu="matMenu">
        <div class="app-menu">
            <ng-container *ngFor="let navMenuItem of navMenuItems">
                <a (click)="navigateToNavMenuItem(navMenuItem)" class="app-item">
                    <img *ngIf="navMenuItem.menu.img" class="app-item-icon"
                        src="{{publicBlobStorageUrl + 'logos/' + navMenuItem.menu.img.fileName}}"
                        [alt]="navMenuItem.menu.title"/>
                    {{ navMenuItem.menu.title }}
                </a>
            </ng-container>
        </div>
    </mat-menu>

    <mat-menu #userMenu="matMenu">
        <ng-container *ngIf="!userImpersonation && !hasGtnAuthorization && isSyncCompleted">
            <button *gtnIfHasAuthorization="authorizationConstants.portalMobileEmployee" mat-menu-item
                (click)="applicationConfigurationService.showUpdateEmailModal = true">Change Email
            </button>
            <button mat-menu-item *gtnIfDoesNotHaveAuthorization="authorizationConstants.gtnUser"
                (click)="applicationConfigurationService.showForgotPasswordModal = true">Reset Password
            </button>
            <button mat-menu-item *ngIf="hasRememberedDevice" (click)="onForgetDeviceClicked()">Forget this device
            </button>
        </ng-container>
        <ng-container *ngIf="hasImpersonationAccess">
            <button mat-menu-item (click)="applicationConfigurationService.showUserImpersonationModal = true">
                Impersonation
            </button>
        </ng-container>
        <button mat-menu-item (click)="applicationConfigurationService.showContactSupportModal = true">Need Help?
        </button>
        <button mat-menu-item (click)="onLogoutClicked()">Logout</button>
    </mat-menu>
</mat-toolbar>
