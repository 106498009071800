import { Component } from '@angular/core';
import { TemplateEntryPageComponent } from 'app/nexus-shared/components/templates/template-entry-page/template-entry-page.component';

@Component({
    selector: 'gtn-template-entry-page-content',
    template: '<ng-content></ng-content>'
})
export class TemplateEntryPageContentComponent<T> {
    // noinspection JSUnusedLocalSymbols
    constructor(templateEntryPageComponent: TemplateEntryPageComponent<T>) {
        // THIS WILL THROW AN ERROR IF ITS NOT NESTED WITHIN PARENT
    }
}
