<gtn-modal class="small" headerText="Change Account Email" [okButtonText]="isValidationStep ? 'Verify Code' : 'Send Verification Code'"
    (okClick)="onSubmitClicked()" (cancelClick)="onCancelClicked()"
    [disableOkButton]="!isValidationStep ?? disableButtons">
    <gtn-modal-content>
        <gtn-update-email #updateEmail (formGroupRefChange)="onFormGroupRefChanged($event)"
            (isValidationStepChange)="isValidationStep = $event"
            (disableButtonsChange)="disableButtons = $event"
            (updateEmailVerificationCompleted)="onCompleted()">
        </gtn-update-email>
    </gtn-modal-content>
</gtn-modal>
