import { PhoneNumberModel } from 'app/nexus-shared/models/phone-number.model';
import { isValidPhoneNumber, parsePhoneNumber, PhoneNumber } from 'libphonenumber-js';

export class PhoneNumberHelper {

    static filterBlankPhoneNumbers(phoneNumbers: PhoneNumberModel[]): PhoneNumberModel[] {
        if (phoneNumbers?.length) {
            return phoneNumbers.filter(x => x.formattedPhoneNumber);
        }
        return phoneNumbers;
    }

    static nullOutPhoneNumber(phoneNumber: PhoneNumberModel): PhoneNumberModel {
        if (!phoneNumber?.formattedPhoneNumber) {
            return null;
        }
        return phoneNumber;
    }

    static parsePhoneNumber(phoneNumber: string, countryCode: any): PhoneNumber {
        try {
            return parsePhoneNumber(phoneNumber, countryCode);
        } catch (e) {
            return null;
        }
    }

    static validatePhoneNumber(phoneNumber: string, countryCode: any): boolean {
        try {
            const parsedPhoneNumber = parsePhoneNumber(phoneNumber, countryCode);
            return isValidPhoneNumber(parsedPhoneNumber.number.toString(), countryCode);
        } catch (e) {
            return false;
        }
    }

    static isCountryPhoneNumberMismatch(phoneNumber: string, countryCode: any): boolean {
        return PhoneNumberHelper.parsePhoneNumber(phoneNumber, countryCode)?.country !== countryCode;
    }
}
