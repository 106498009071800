<gtn-template-view [cardMode]="cardMode">
    <gtn-template-view-content>
        <ng-container *ngIf="viewMode === viewModes.All || viewMode === viewModes.General">
            <div class="row">
                <div class="col-3">
                    <label class="list-label">First name</label>
                    <div class="data-field">
                        {{ value.firstName || noValueText }}
                    </div>
                </div>
                <div class="col-3">
                    <label class="list-label">Last name</label>
                    <div class="data-field">
                        {{ value.lastName || noValueText }}
                    </div>
                </div>
                <div class="col-3">
                    <label class="list-label">Email</label>
                    <div class="data-field">
                        {{ value.email || noValueText }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <label class="list-label">Last Login</label>
                    <div class="data-field" *ngIf="value?.lastLoginDateTime">
                        {{ value.lastLoginDateTime | gtnDate : true }}
                    </div>
                    <div class="data-field" *ngIf="!value?.lastLoginDateTime">
                        {{ noValueText }}
                    </div>
                </div>
                <div class="col-3">
                    <label class="list-label">Last Invited</label>
                    <div class="data-field" *ngIf="value?.lastInvitedDateTime">
                        {{ value.lastInvitedDateTime | gtnDate : true }}
                    </div>
                    <div class="data-field" *ngIf="!value?.lastInvitedDateTime">
                        {{ noValueText }}
                    </div>
                </div>
                <div *ngIf="value?.individualKey" class="col-3">
                    <label class="list-label">Contacts user</label>
                    <div class="data-field">
                        <button mat-mini-fab color="secondary" matTooltip="View individual in CMS"
                            (click)="onNavigateToIndividualClicked()">
                            <mat-icon>person</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="viewMode === viewModes.All || viewMode === viewModes.Roles">
            <div class="row">
                <div class="col-12">
                    <table *ngIf="value?.userRoleDefinitions?.length">
                        <tr>
                            <th class="small">
                                Name
                            </th>
                            <th class="small">
                                Description
                            </th>
                        </tr>
                        <tr class="role-container" *ngFor="let roleDefinition of value.userRoleDefinitions">
                            <td class="small">{{ roleDefinition.name }}</td>
                            <td class="small">{{ roleDefinition.description }}</td>
                        </tr>
                    </table>
                    <div *ngIf="!value?.userRoleDefinitions?.length">
                        User is not assigned to any roles
                    </div>
                </div>
            </div>
        </ng-container>
    </gtn-template-view-content>
</gtn-template-view>
