import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseEntryComponent } from 'app/nexus-shared/components/base-component/base-entry.component';
import { DocumentSearchModel } from 'app/nexus-shared/domain/documents/models/document-search.model';

@Component({
    selector: 'gtn-document-search-filters',
    templateUrl: './document-search-filters.component.html',
    styleUrls: ['./document-search-filters.component.scss']
})
export class DocumentSearchFiltersComponent extends BaseEntryComponent<DocumentSearchModel> {
    @Output() valueChange: EventEmitter<DocumentSearchModel> = new EventEmitter<DocumentSearchModel>();
    @Input() isFiltersVisible: boolean = true;
    @Output() isFiltersVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    onSearchClicked(): void {
        this.valueChange.emit(this.value);
    }

    onToggleVisibilityClicked(isFiltersVisible: boolean) {
        this.isFiltersVisible = isFiltersVisible;
        this.isFiltersVisibleChange.emit(isFiltersVisible);
    }
}
