import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { NexusRoutes } from 'app/nexus-routing/nexus-routes';
import { RoutingService } from 'app/nexus-routing/services/routing.service';

@NgModule({
    imports: [
        RouterModule.forRoot(
            NexusRoutes,
            {
                useHash: true,
                enableTracing: false,
                onSameUrlNavigation: 'reload'
            }
        )
    ],
    providers: [
        RoutingService
    ],
    exports: [
        RouterModule
    ]
})
export class NexusRoutingModule {
}
