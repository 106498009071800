export class DropdownOptionModel {
    id: number;
    label: string;
    value: number;
    order: number;

    constructor(model: DropdownOptionModel = null) {
        this.id = model?.id ?? null;
        this.label = model?.label ?? null;
        this.value = model?.value ?? null;
        this.order = model?.order ?? null;
    }
}
