import { PreviewFileTypesEnum } from 'app/nexus-shared/components/controls/shared/enums/preview-file-types.enum';
import { FileBlobHttpResponseModel } from 'app/nexus-shared/models';

export class FileViewModel {
    type: PreviewFileTypesEnum;
    description?: string;
    src?: string;
    blobSrc?: FileBlobHttpResponseModel;
    isBlobSrc?: boolean;
    contentType?: string;
    name?: string;
    iconClass?: string;

}
