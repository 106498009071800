import { Component, OnInit } from '@angular/core';
import { BaseQuestionnaireFormComponent } from 'app/nexus-shared/domain/questionnaires/components/forms/base-questionnaire-form.component';
import { SectionColumnFormControlNames, SectionColumnModel } from 'app/nexus-shared/domain/questionnaires/models/section-column.model';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { SectionColumnTypesEnum } from 'app/nexus-shared/domain/questionnaires/enums/section-column-types.enum';
import { UserInputTypesEnum } from 'app/nexus-shared/domain/questionnaires/enums/user-input-types.enum';
import { QuestionnaireEventService } from 'app/nexus-core/services/domain/questionnaires/questionnaire-event.service';
import { SectionColumnActionModel } from 'app/nexus-shared/domain/questionnaires/models/section-column-action.model';
import { NumericActionEventModel } from 'app/nexus-shared/domain/questionnaires/models/numeric-action-event.model';

@Component({
    selector: 'gtn-section-column-view-form',
    templateUrl: './section-column-view-form.component.html',
    styleUrls: ['./section-column-view-form.component.scss', '../questionnaire-forms.component.scss']
})
export class SectionColumnViewFormComponent extends BaseQuestionnaireFormComponent<SectionColumnModel> implements OnInit {
    sectionColumnType: SectionColumnTypesEnum;
    sectionColumnTypesEnum = SectionColumnTypesEnum;
    userInputTypesEnum = UserInputTypesEnum;
    formControlNames: SectionColumnFormControlNames;
    numericValue: number = null;

    constructor(private questionnaireEventService: QuestionnaireEventService) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
    }

    protected initUIControls() {
        this.formControlNames = new SectionColumnFormControlNames();
    }

    protected initFormCustomizations() {
        this.sectionColumnType = this.formGroupRef.get(ObjectHelper.nameOf<SectionColumnModel>('type'))?.value;
        if (this.sectionColumnType === SectionColumnTypesEnum.UserInput) {
            const userInputType = this.formGroupRef.get(this.formControlNames.userInputType).value;
            const value = this.formGroupRef.get(this.formControlNames.value).value;
            if (value && userInputType === UserInputTypesEnum.DropDown) {
                this.formGroupRef.get(this.formControlNames.value).setValue(+value);
            }
        }
    }

    onNumericActionInputChanged(): void {
        if(this.numericValue !== this.formGroupRef.get(ObjectHelper.nameOf<SectionColumnModel>('value')).value) {
            const action: SectionColumnActionModel = this.formGroupRef.get(this.formControlNames.action)?.value;
            if (action?.key) {
                const numericAction = <NumericActionEventModel>
                    {
                        columnKey: action.parentSectionColumnKey,
                        sectionKey: action.childSectionKey,
                        value: this.formGroupRef.get(ObjectHelper.nameOf<SectionColumnModel>('value')).value ?? 0
                    };
                this.questionnaireEventService.sectionColumnNumericActionValueChange$.next(numericAction);
            }
            this.numericValue = this.formGroupRef.get(ObjectHelper.nameOf<SectionColumnModel>('value')).value;
        }

    }

    onDropdownActionInputChanged() {
        const action: SectionColumnActionModel = this.formGroupRef.get(this.formControlNames.action)?.value;
        if (action?.key) {
            this.questionnaireEventService.sectionColumnDropdownActionValueChange$.next();
        }
    }

}
