import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { CurrencyCellRendererParams } from 'app/nexus-shared/components/controls/components/base-grid/cell-renderers/currency-column/currency-cell-renderer-params.interface';

@Component({
    selector: 'gtn-currency-column',
    templateUrl: './currency-column.component.html',
    styleUrls: ['./currency-column.component.scss']
})

export class CurrencyColumnComponent<T> implements ICellRendererAngularComp {
    currencyCode: string = '';
    dataModel: T;
    field: string;
    params: any;

    agInit(params: CurrencyCellRendererParams): void {
        this.params = params;
        this.dataModel = params.data;
        this.currencyCode = params?.currencyField ? ObjectHelper.getValueOfProperty(params.data, params.currencyField) : params.defaultCurrency;
    }

    refresh(): boolean {
        return false;
    }
}
