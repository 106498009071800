import { AddressTypeEnum } from 'app/nexus-shared/enums/address-type.enum';
import { CommonBaseAuditModel } from 'app/nexus-shared/domain/contacts/models/common-base-audit.model';

export class AddressModel extends CommonBaseAuditModel {
    addressKey: string;
    fullAddress: string;
    attnLine: string;
    addressLineOne: string;
    addressLineTwo: string;
    city: string;
    stateCode: string;
    stateName: string;
    stateId: number;
    postalCode: string;
    latitude: number;
    longitude: number;
    countryCode: string;
    countryName: string;
    countryId: number;
    type: AddressTypeEnum;
    googlePlaceId: string;
    googlePrimaryType: string;

    constructor(model: AddressModel = null) {
        super(model);
        this.addressKey = model?.addressKey ?? null;
        this.fullAddress = model?.fullAddress ?? null;
        this.attnLine = model?.attnLine ?? null;
        this.addressLineOne = model?.addressLineOne ?? null;
        this.addressLineTwo = model?.addressLineTwo ?? null;
        this.city = model?.city ?? null;
        this.stateId = model?.stateId ?? null;
        this.stateCode = model?.stateCode ?? null;
        this.stateName = model?.stateName ?? null;
        this.postalCode = model?.postalCode ?? null;
        this.countryName = model?.countryName ?? null;
        this.countryCode = model?.countryCode ?? null;
        this.countryId = model?.countryId ?? null;
        this.type = model?.type ?? null;
        this.latitude = model?.latitude ?? null;
        this.longitude = model?.longitude ?? null;
        this.googlePlaceId = model?.googlePlaceId;
        this.googlePrimaryType = model?.googlePrimaryType;
    }
}
