import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SpinnerService } from 'app/nexus-core/components/spinner/spinner.service';

@Component({
    selector: 'gtn-nexus-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
    providers: []
})

export class SpinnerComponent implements OnInit, OnDestroy {
    isBackgroundTransparent = false;
    isGlobalActive: boolean;
    message = '';
    subscriptions: Subscription = new Subscription();

    ngOnInit() {
        this.subscriptions.add(SpinnerService.onStateChanged.subscribe((data) => {
            this.isGlobalActive = SpinnerService.isGlobalSpinnerActive();
            if (data && data.hasOwnProperty('isTransparent')) {
                this.isBackgroundTransparent = data.isTransparent;
            }
        }));

        this.subscriptions.add(SpinnerService.onCounterChange.subscribe(message => {
            this.message = message;
        }));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}
