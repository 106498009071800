import { Component, ElementRef, OnDestroy } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { InputCellEditorParametersModel } from 'app/nexus-shared/components/controls';
import { InputType } from 'app/nexus-shared/components/controls/shared';
import { Subscription } from 'rxjs';

@Component({
    selector: 'gtn-input-cell-editor',
    templateUrl: './input-cell-editor.component.html',
    styleUrls: ['./input-cell-editor.component.scss']
})
export class InputCellEditorComponent implements ICellEditorAngularComp, OnDestroy {
    params: InputCellEditorParametersModel;
    formControl: UntypedFormControl;
    textAlign: 'left' | 'right' = 'left';

    private subscriptions: Subscription = new Subscription();

    constructor(private el: ElementRef) {
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    agInit(params: InputCellEditorParametersModel): void {
        this.params = params;
        if (params.isPopup) {
            params.width = params.column.getActualWidth() - 32;
        } else if (params.isInline) {
            params.width = params.column.getActualWidth() - 26;
        }

        if (params.type) {
            if (params.type === InputType.number || params.type === InputType.currency) {
                this.textAlign = 'right';
            }
        }

        if (params.formGroupRef) {
            const formGroup = params.formGroupRef;

            const formArray = formGroup.controls[params.formArrayName] as UntypedFormArray;

            if (formArray?.controls) {

                const rowFormGroup = formArray.controls[params.rowIndex] as UntypedFormGroup;

                if (rowFormGroup?.controls) {
                    const formControl = rowFormGroup.controls[params.colDef.field] as UntypedFormControl;

                    if (formControl) {
                        this.subscriptions.add(formControl.valueChanges.subscribe(value => this.params.value = value));

                        this.formControl = formControl;
                    }
                }
            }
        }

        /**
        * Do not pass focus down to the gtn-input.
        * It focuses on the search for some reason if it's passed down.
        * This method is the most consistent.
        **/
        if (params.focus) {
            setTimeout(() => {
                const focusElement = this.el.nativeElement.querySelector('input');
                focusElement.focus();
                if (params.selectAll) {
                    focusElement.select();
                }
            }, 10);
        }
    }

    getValue() {
        return this.params.value;
    }

    isPopup(): boolean {
        return this.params.isPopup;
    }


}
