import { Pipe, PipeTransform } from '@angular/core';

import { SelectListInterface } from 'app/nexus-shared/interfaces/select-list.interface';

@Pipe({
    name: 'selectList'
})
export class SelectListPipe implements PipeTransform {

    transform(values: any[], idName: string, valueName: string, typeId: number, alternateName: string = ''): SelectListInterface[] {
        let response: SelectListInterface[] = [];

        if (values && values.length > 0) {
            let count = values.length;
            do {
                for (const value of values) {
                    --count;
                    const item: SelectListInterface = {
                        id: value[idName],
                        typeId: typeId,
                        value: alternateName && value[alternateName] ? value[alternateName] : value[valueName],
                        visible: true,
                    };
                    response = Object.assign([{}], response.concat(item));
                }
            } while (count > 0);
        }

        return response;
    }
}
