import { FadeInBumpDown } from './fade-in-bump-down';
import { FooterButtonGroupOpenUp } from './footer-button-group-open-up';
import { GroupBumpDown } from './group-bump-down';
import { MenuOpenDownChildSlideDown } from './menu-open-down-child-slide-down';
import { MenuOpenDownChildSlideRight } from './menu-open-down-child-slide-right';
import { ModalFadeIn } from './modal-fade-in';
import { ModalSlideIn } from './modal-slide-in';
import { GridPanelOpen } from './grid-panel-open';
import { FadeInBumpDownFadeOutBumpUp } from './fade-in-bump-down-fade-out-bump-up';
import { FadeIn } from './fade-in';
import { InfoPanelOpen } from './info-panel-open';
import { FilterPanel } from './filter-panel';
import { EaseIn } from './ease-in';
import { FadeInFadeOut } from './fade-in-fade-out';
import { SlideInOut } from 'app/nexus-shared/animations/slide-in-out';

export class NexusAnimations {
    static defaultDelay = 100;

    /**
     * Use '@fadeIn' in the markup.
     */
    static easeIn = EaseIn.animations;

    /**
     * Use '@fadeInBumpDownFadeOutBumpUp' in the markup.
     */
    static fadeInBumpDownFadeOutBumpUp = FadeInBumpDownFadeOutBumpUp.animations;

    /**
     * Use '@fadeInFadeOut' in the markup
     */
    static fadeInFadeOut = FadeInFadeOut.animations;

    /**
     * Use '@filterPanel' in the markup.
     */
    static filterPanel = FilterPanel.animations;

    /**
     * Use '@slideInOut' in the markup.
     */
    static slideInOut = SlideInOut.animations;

    /**
     * Use '@footerButtonGroupOpen' in markup.
     */
    static footerButtonGroupOpenUp = FooterButtonGroupOpenUp.animations;

    /**
     * Use '@groupBumpDown' on the parent element
     * and '@childSlide' on the child element
     * in the markup.
     */
    static groupBumpDown = GroupBumpDown.animations;

    /**
     * Use '@infoPanelOpen' on the parent element
     * in the markup.
     */
    static infoPanelOpen = InfoPanelOpen.animations;

    /**
     * Use '@menuOpen' on the parent element
     * and '@childSlide' on the child element
     * in the markup.
     */
    static menuOpenDownChildSlideDown = MenuOpenDownChildSlideDown.animations;

    /**
     * Use '@menuOpen' on the parent element
     * and '@childSlide' on the child element
     * in the markup.
     */
    static menuOpenDownChildSlideRight = MenuOpenDownChildSlideRight.animations;

    /**
     * Use '@gridPanelOpen' on the parent element
     * and '@fadeInBumpDown' on the child element
     * in the markup.
     */
    static gridPanelOpen = GridPanelOpen.animations;

    /**
     * Use '@modalFadeIn' in markup.
     */
    static modalFadeIn = ModalFadeIn.animations;

    /**
     * Use '@modalSlideIn' in markup.
     */
    static modalSlideIn = ModalSlideIn.animations;

    static fadeInBumpDownWithDelay = (delay: string) => FadeInBumpDown.animations(delay);
    /**
    * Use '@fadeInBumpDown' in markup.
    */
    static fadeInBumpDown = NexusAnimations.fadeInBumpDownWithDelay(`${NexusAnimations.defaultDelay}ms`);

    /**
     * Use '@bumpDown' in markup.
     */
    static bumpDown = NexusAnimations.fadeInBumpDownWithDelay(`${4 * NexusAnimations.defaultDelay}ms`);

    /**
     * Use '@fadeIn' in the markup.
     */
    static fadeInWithDelay = (delay: string) => FadeIn.animations(delay);
    static fadeIn = NexusAnimations.fadeInWithDelay(`${NexusAnimations.defaultDelay}ms`);
}
