import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BaseService } from 'app/nexus-core';
import { RoutingHelper } from 'app/nexus-routing/helpers/routing.helper';
import { filter } from 'rxjs/operators';

@Injectable()
export class RoutingService extends BaseService {
    public history: string[] = [];
    public previousUrls: string[] = [];

    private currentUrl: string;
    private isBackNavigation = false;

    constructor(private router: Router) {
        super();
        this.initService();
    }

    public navigateToPreviousUrl(): void {
        this.isBackNavigation = true;
        const url = this.previousUrls.pop();
        this.router.navigateByUrl(decodeURI(url));
    }

    public registerInitialUrl(url: string) {
        this.currentUrl = url;
        this.subscriptions.add(this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {

                // determine if query params were just added to the route
                const currentUrlStripped = RoutingHelper.getStrippedUrl(this.currentUrl);
                const eventUrlStripped = RoutingHelper.getStrippedUrl(event.url);
                const previousUrlStripped = this.previousUrls?.length ? RoutingHelper.getStrippedUrl(this.previousUrls[this.previousUrls.length - 1]) : '';

                // if new route navigated to, push it onto stack
                if (currentUrlStripped !== eventUrlStripped) {

                    if (!this.isBackNavigation) {
                        this.previousUrls.push(this.currentUrl);
                    }

                    this.currentUrl = event.url;
                } else if (this.previousUrls.length && previousUrlStripped === eventUrlStripped) {
                    // not a new page, update the latest url in stack to get latest query parameters
                    this.previousUrls.splice(this.previousUrls.length - 1, 1, event.url);
                } else if (currentUrlStripped === eventUrlStripped) {
                    // track latest url (to track query param changes)
                    this.currentUrl = event.url;
                }

                // reset history when navigating to dashboard
                if (this.currentUrl.indexOf('dashboard') > -1) {
                    this.previousUrls = [];
                }

                this.isBackNavigation = false;
            }
        }));
    }

    private initService(): void {
        this.subscriptions.add(this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
                this.history = [urlAfterRedirects, ...this.history];
            }));
    }
}
