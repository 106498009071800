<div [formGroup]="formGroup" class="filter-panel-container">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-4 col-lg-4">
                <gtn-datepicker [formControlName]="controlNames.effectiveDate" [readonly]="isDateDisabled" label="Date">
                </gtn-datepicker>
            </div>
            <div class="col-md-4 col-lg-4">
                <gtn-multiselect [formControlName]="controlNames.qualifier" idKey="taxQualifierId" displayKey="name" label="Qualifier"
                    [options]="taxQualifiers">
                </gtn-multiselect>
            </div>
            <div class="col-md-4 col-lg-4">
                <gtn-dropdown [formControlName]="controlNames.specialization" label="Specialization"
                    [readonly]="isSpecializationDisabled" [options]="specializationOptions">
                </gtn-dropdown>
            </div>
        </div>
        <div class="button-group">
            <button mat-flat-button (click)="applyFilters()" class="mat-accent apply-button">Apply</button>
            <button mat-flat-button (click)="clearFilters()" class="mat-button-default clear-button">Clear</button>
        </div>
    </div>
</div>
