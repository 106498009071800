import { UserModel } from 'app/nexus-shared/domain/users/models/user.model';
import { RoleDefinitionModel } from 'app/nexus-shared/domain/users/models/role-definition.model';

export class UserViewModel extends UserModel {
    userRoleDefinitions: RoleDefinitionModel[];

    constructor(model: UserModel = null) {
        super(model);
        this.userRoleDefinitions = [];
    }
}
