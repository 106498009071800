import { SearchResultIconEnum } from 'app/nexus-shared/components/controls/shared/enums/search-result-icon.enum';

export class SearchResultModel<T, S> {
    id: string | number;
    name: string;
    subName: string;
    type: T;
    rank?: number;
    iconClass: SearchResultIconEnum;
    resultObject: S;
}
