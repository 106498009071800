import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { ResizeService } from 'app/nexus-core';
import { Subject } from 'rxjs';
import { BaseDirective } from '../components/base-directive/base.directive';

@Directive({
    selector: '[gtnResize]'
})
export class ResizeDirective extends BaseDirective implements AfterViewInit, OnDestroy {

    @Input() resizeHandler: Function;

    private resizeObserver: ResizeObserver = new ResizeObserver(entries => {
        window.requestAnimationFrame(() => {
            if (!Array.isArray(entries) || !entries.length) {
                return;
            }

            this.resizeSubject.next();
        });
    });
    private resizeSubject: Subject<void> = new Subject();

    constructor(
        protected resizeService: ResizeService,
        private _elementRef: ElementRef,
    ) {
        super();
    }

    ngAfterViewInit() {
        this.resizeObserver.observe(this._elementRef.nativeElement as HTMLElement);

        this.subscriptions.add(this.resizeSubject.subscribe(_ => this.handleResize()));
        this.subscriptions.add(this.resizeService.resize.subscribe(_ => this.handleResize()));
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.resizeObserver.unobserve(this._elementRef.nativeElement);
    }

    protected handleResize() {
        if (this.resizeHandler) {
            this.resizeHandler();
        } else {
            console.warn('Resize Handler is not defined, no work to do on resize.', this._elementRef?.nativeElement);
        }
    }
}
