import { ApplicationsEnum } from 'app/nexus-shared/enums/applications.enum';
import { RoleTypesEnum } from 'app/nexus-shared/domain/users/enums/role-types.enum';

export class RoleDefinitionModel {
    roleDefinitionId: number;
    roleType: RoleTypesEnum;
    name: string;
    description: string;
    isGtnRole: boolean;
    application: ApplicationsEnum;

    constructor(model: RoleDefinitionModel = null) {
        this.roleDefinitionId = model?.roleDefinitionId ?? null;
        this.roleType = model?.roleType ?? null;
        this.name = model?.name ?? null;
        this.description = model?.description ?? null;
        this.isGtnRole = model?.isGtnRole ?? null;
        this.application = model?.application ?? null;
    }
}
