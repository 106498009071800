import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FolderModel } from 'app/nexus-shared/domain/documents/models/folder.model';
import { SimpleChangesTyped } from 'app/nexus-shared/models/simple-changes-typed.type';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { DocumentHelperService } from 'app/nexus-core/services/domain/documents/document-helper.service';
import { DocumentsHelper } from 'app/nexus-core/helpers/documents.helper';
import { AreaTypesEnum } from 'app/nexus-shared/domain/documents/enums/area-types.enum';
import { Router } from '@angular/router';
import { FolderSearchModel } from 'app/nexus-shared/domain/documents/models/folder-search.model';
import { FolderService } from 'app/nexus-core/services/domain/documents/folder.service';
import { ClientFolderModel } from 'app/nexus-shared/domain/documents/models/client-folder.model';
import { DocumentsRoutingConfigurationConstants } from 'app/modules/documents/routing/documents-routing-configuration.constants';

@Component({
    selector: 'gtn-folder-breadcrumbs',
    templateUrl: './folder-breadcrumbs.component.html',
    styleUrls: ['./folder-breadcrumbs.component.scss']
})
export class FolderBreadcrumbsComponent extends BaseComponent implements OnChanges {
    static readonly companyPath: string = 'Clients/Companies';

    @Input() selectedFolder: FolderModel;
    @Input() rootFolder: FolderModel;
    @Input() folders: FolderModel[];
    @Input() isIndividualCompanyView: boolean;
    @Input() isDocuments: boolean = true;
    @Input() replaceRootWithIcon: boolean = false;
    @Output() breadcrumbClick: EventEmitter<string> = new EventEmitter();

    folderBreadcrumbs: FolderBreadcrumbModel[] = [];
    currentIndividualKey: string;
    currentCompanyKey: string;
    correspondingFolders: FolderModel[];

    constructor(private documentHelperService: DocumentHelperService, private folderService: FolderService, private router: Router) {
        super();
    }

    ngOnChanges(changes: SimpleChangesTyped<this>) {
        if (changes.folders && !this.folders?.some(x => x.folderKey === this.selectedFolder.folderKey)) {
            this.folderBreadcrumbs = [];
        }
        
        if (changes.selectedFolder && this.selectedFolder && this.folders?.length) {
            this.folderBreadcrumbs = [];
            const folderPathSplit = this.selectedFolder?.folderPath?.split('/');
            let i = 0;
            if (folderPathSplit?.length) {
                while (i < folderPathSplit.length) {
                    const path = folderPathSplit.slice(0, i + 1).join('/');
                    const folder = this.folders?.find(y => y.folderPath === path);
                    if (folder && folder.folderPath !== FolderBreadcrumbsComponent.companyPath) {
                        this.folderBreadcrumbs.push(new FolderBreadcrumbModel(folder));
                    }
                    i++;
                }

                if (this.isIndividualCompanyView) {
                    if (this.rootFolder.clientFolder.individualKey && this.rootFolder.clientFolder.companyKey) {
                        this.folderBreadcrumbs.unshift(
                            { name: this.rootFolder.clientFolder.companyName, folder: this.rootFolder, isIndividualCompanyRoute: true, isClientAreaRoute: false }
                        );
                    }
                    this.folderBreadcrumbs.unshift(
                        { name: 'Clients', folder: null, isIndividualCompanyRoute: false, isClientAreaRoute: true }
                    );
                }

                if (this.selectedFolder.areaType === AreaTypesEnum.Clients && this.selectedFolder?.clientFolder?.individualKey && this.isDocuments) {
                    this.checkCorrespondingIndividual(this.selectedFolder.clientFolder);
                }

                if (this.replaceRootWithIcon) {
                    this.folderBreadcrumbs[0].name = '<span><i class="fa-sharp-duotone fa-solid fa-folder"></i></span>';
                }
            }
        }
    }

    onNavigateToIndependent(folder): void {
        this.router.navigate(DocumentsRoutingConfigurationConstants.documentAreaConfigurations.getClient().routing.navigationRoute(folder.folderKey));
    }

    onNavigateToMobileEmployee(folder): void {
        this.documentHelperService.routeToFolderDocument(folder);
    }

    onBreadcrumbClicked(folderBreadcrumb: FolderBreadcrumbModel): void {
        if (folderBreadcrumb.isClientAreaRoute) {
            this.router.navigate(DocumentsRoutingConfigurationConstants.documentAreaConfigurations.getClients().routing.navigationRoute());
        } else if (folderBreadcrumb?.isIndividualCompanyRoute) {
            this.documentHelperService.routeToFolderDocument(folderBreadcrumb.folder, true);
        } else {
            this.breadcrumbClick.emit(folderBreadcrumb.folder.folderKey);
        }
    }

    private checkCorrespondingIndividual(clientFolder: ClientFolderModel): void {
        if (clientFolder?.individualKey && ((!this.currentIndividualKey || clientFolder.individualKey !== this.currentIndividualKey) || (this.currentCompanyKey !== clientFolder?.companyKey))) {
            this.currentIndividualKey = clientFolder?.individualKey;
            this.currentCompanyKey = clientFolder?.companyKey;
            this.correspondingFolders = [];
            this.folderService.search(<FolderSearchModel>{ includeIndividual: true, includeCompany: true, individualKey: clientFolder.individualKey }).subscribe(res => {
                this.correspondingFolders = res.filter(x =>
                    //independents
                    (x.folderPath === DocumentsHelper.getIndependentFolderPath(x.clientFolder) && this.selectedFolder.clientFolder.companyKey)
                    ||
                    //clients
                    (x.folderPath === DocumentsHelper.getMobileEmployeeFolderPath(x.clientFolder) && this.selectedFolder.clientFolder.companyKey !== x.clientFolder.companyKey)
                );
            });
        }
    }
}

export class FolderBreadcrumbModel {
    name: string;
    folder: FolderModel;
    isIndividualCompanyRoute?: boolean;
    isClientAreaRoute?: boolean;
    tags?: string;

    constructor(folder: FolderModel) {
        this.name = folder?.name ?? null;
        this.folder = folder ?? null;
        this.isIndividualCompanyRoute = false;
        this.isClientAreaRoute = false;
        this.tags = folder?.tags?.length ? folder.tags.map(x => x.folderTagName).join(', ') : null;
    }
}
