import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { AuthorizationConstants } from 'app/nexus-shared/constants/authorization.constants';
import { AuthenticatedUserModel } from 'app/nexus-shared/models/authenticated-user.model';
import { BehaviorSubject } from 'rxjs';
import { AuthorizationHelper } from '../helpers/authorization.helper';
import { ApplicationsEnum } from 'app/nexus-shared';

/*
NOTE:  IF YOU ARE LOOKING FOR A HOOK WHEN THE APP IS FULLY LOADED, ITS THE isSyncedCompleted OBSERVABLE FROM AuthenticatedUserSyncService
 */

@Injectable({
    providedIn: 'root'
})
export class AuthenticatedUserService {
    public isAuthenticated: boolean = false;
    public isAuthenticated$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    public user: AuthenticatedUserModel = null;
    public user$: BehaviorSubject<AuthenticatedUserModel> = new BehaviorSubject(null);

    public isGtn: boolean = null;
    public isGtn$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    public isImpersonating: boolean = false;
    public isImpersonating$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    public settings = {
        dateFormat: null,
        dateTimeFormat: null
    };

    public setUserContext(user: AuthenticatedUserModel) {
        if (environment().auth.enabled === false) {
            this.initMock();
        } else {
            this.initUser(user);
        }
    }

    public hasGtnAuthorization(): boolean {
        return AuthorizationHelper.hasGtnAuthorization(this.user);
    }

    public hasAuthorization(authorization: AuthorizationConstants[] | AuthorizationConstants, bypassGtnCheck = false): boolean {
        return AuthorizationHelper.hasAuthorization(this.user, authorization, bypassGtnCheck);
    }

    public doesNotHaveAuthorization(authorization: AuthorizationConstants[] | AuthorizationConstants): boolean {
        return !this.hasAuthorization(authorization, true);
    }

    public hasAnyAuthorization(authorization: AuthorizationConstants[]): boolean {
        return AuthorizationHelper.hasAnyAuthorization(this.user, authorization);
    }

    public hasApplicationAuthorization(application: ApplicationsEnum): boolean {
        return AuthorizationHelper.hasApplicationAuthorization(this.user, application);
    }

    public hasCompletedSetup(): boolean {
        return AuthorizationHelper.hasAppAuthorization(this.user);
    }

    public hasCompanyAuthorization(companyKeys: string[] | string): boolean {
        return AuthorizationHelper.hasCompanyAuthorization(this.user, companyKeys);
    }

    public hasMyGtnPortalAccess(myGtnPortalUserKey: string): boolean {
        return AuthorizationHelper.hasMyGtnPortalAuthorization(this.user, myGtnPortalUserKey);
    }

    private initUser(currentUser: AuthenticatedUserModel): void {
        let isGtnUser: boolean = false;

        if (currentUser) {
            isGtnUser = AuthorizationHelper.hasGtnAuthorization(currentUser);
        }

        this.isGtn = isGtnUser;
        this.isGtn$.next(isGtnUser);

        this.user = currentUser;
        this.user$.next(currentUser);

        this.isImpersonating = currentUser?.isImpersonating ?? false;
        this.isImpersonating$.next(currentUser?.isImpersonating ?? false);

        this.isAuthenticated = currentUser !== null;
        this.isAuthenticated$.next(currentUser !== null);
    }

    private initMock(): void {
        const mockUser: AuthenticatedUserModel = environment().auth.mockUser;

        this.isGtn = AuthorizationHelper.hasAuthorization(mockUser, AuthorizationConstants.gtnUser) || AuthorizationHelper.hasAuthorization(mockUser, AuthorizationConstants.gtnAdministrator);
        this.isGtn$.next(this.isGtn);

        this.user = mockUser;
        this.user$.next(mockUser);

        this.isImpersonating = mockUser.isImpersonating;
        this.isImpersonating$.next(mockUser.isImpersonating);

        this.isAuthenticated = true;
        this.isAuthenticated$.next(true);
    }
}

