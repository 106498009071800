<form [formGroup]="formGroupRef">
    <table class="table table-bordered">
        <tr>
            <ng-container
                *ngFor="let tableColumnHeader of tableColumnHeadersFormArray.controls; index as columnHeaderIndex">
                <th> {{ tableColumnHeader?.value?.headerText }}</th>
            </ng-container>
        </tr>
        <ng-container *ngFor="let tableRow of tableRowsFormArray.controls; index as rowIndex">
            <tr>
                <ng-container
                    *ngFor="let tableColumn of getTableColumnFormArray(tableRow).controls; index as columnIndex">
                    <td *ngIf="columnIndex === 0 && rowHeaders?.length && rowHeaders[rowIndex]">
                        {{ rowHeaders[rowIndex] }}
                    </td>
                    <td *ngIf="!(columnIndex === 0 && rowHeaders?.length && rowHeaders[rowIndex])">
                        <gtn-table-column-view-form [formGroupRef]="getFormGroupFromAbstractControl(tableColumn)"
                            [isSubForm]="true" [questionnaireDropdownService]="questionnaireDropdownService"
                            [tableColumnHeader]="tableColumnHeadersFormArray.at(columnIndex).value"></gtn-table-column-view-form>
                    </td>
                </ng-container>
            </tr>
        </ng-container>
    </table>
</form>
<div class="table-row-buttons">
    <div class="table-buttons">
        <span class="mr-1" (click)="onAddTableRowClicked()"><i class="fa fa-add"></i></span>
        <span *ngIf="tableRowsFormArray?.length" (click)="onDeleteTableRowClicked()"><i class="fa fa-trash"></i></span>
    </div>
</div>
