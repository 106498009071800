<gtn-modal *ngIf="formGroupRef" class="modal-medium-wide" headerText="Upload Files"
    okButtonText="Upload" [disableOkButton]="!formGroupRef.dirty || (canShowValidation || isSaving)"
    [formGroupRef]="formGroupRef" [validationModels]="validationModels" [canShowValidation]="canShowValidation"
    [disableCancelButton]="isSaving" (okClick)=" onSaveClicked()" (cancelClick)="onCancelClicked()"
    (closeClick)="onCancelClicked()">
    <gtn-modal-content>
        <ng-container>
            <gtn-bulk-documents-form [(formGroupRef)]="formGroupRef" [(value)]="value" [files]="files" [folder]="folder"
                [canShowValidation]="canShowValidation"
                (validationModelsChange)="onValidationModelsChanged($event)"
                (formGroupRefChange)="onFormGroupRefChanged($event)"
                (documentRemoved)="onDocumentRemoved($event)"></gtn-bulk-documents-form>
            <div class="row">
                <gtn-file-upload #fileUpload class="col-12" label="Select file(s)"
                    [isDragAndDrop]="true"
                    [isMultiSelect]="true"
                    [externalFiles]="files"
                    (filesChange)="onFilesChanged($event)"></gtn-file-upload>
            </div>
        </ng-container>
    </gtn-modal-content>
</gtn-modal>
