import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { Dictionary } from 'app/nexus-shared/models/dictionary';

export class ArrayHelper {
    static unique(value: any, index: number, self: any[]): boolean {
        return self.indexOf(value) === index;
    }

    static getUniqueValues(array: any[]): any[] {
        return array ? array.map(a => a).filter(ArrayHelper.unique) : array;
    }

    static getUniqueItemsByProperty(array: any[], property: string): any[] {
        return array.filter((x, i, arr) => arr.findIndex(y => y !== null && typeof y !== 'undefined' && x !== null && typeof x !== 'undefined' && ObjectHelper.getValueOfProperty(y, property) === ObjectHelper.getValueOfProperty(x, property)) === i);
    }

    static getUniqueValuesOfProperty(array: any[], property: string): any[] {
        return array ? array.map(a => a[property]).filter(ArrayHelper.unique) : array;
    }

    static getUniqueValuesOfSubProperty<T>(array: any[], subPropertyPath: string, propertyKey: string): any[] {
        return ArrayHelper.getUniqueItemsByProperty(ArrayHelper.flatten(array?.map(r => ObjectHelper.getValueOfProperty<T[]>(r, subPropertyPath))), propertyKey);
    }

    static getAverage(numbers: number[]) {
        return ArrayHelper.getSum(numbers) / numbers.length;
    }

    static getSum(numbers: number[]) {
        return numbers.reduce((a, b) => a + b, 0);
    }

    static moveInArray(arr, fromIndex, toIndex): void {
        const element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
    }

    static removeItem(array: any[], item: any): any[] {
        return array.filter(a => a !== item);
    }

    static flatten<T>(arrays: T[][]): T[] {
        const flattened: T[] = [];
        arrays.forEach(array => {
            flattened.push(...array);
        });
        return flattened;
    }

    static areEqual<T>(a: T[], b: T[]) {
        if (a == null || b == null) {
            return false;
        }
        if (a.length !== b.length) {
            return false;
        }

        for (let i = 0; i < a.length; i++) {
            if (a[i] !== b[i]) {
                return false;
            }
        }

        return true;
    }

    static areEqualDisregardingOrder<T>(a: T[], b: T[]): boolean {
        if (a.length !== b.length) {
            return false;
        }
        for (var i = a.length; i--;) {
            if (!b.some(x => a[i])) {
                return false;
            }
        }
        return true;
    }

    static areEqualByProperty<T>(a: T[], b: T[], propertyName: string) {
        if (a == null || b == null) {
            return false;
        }
        if (a.length !== b.length) {
            return false;
        }

        for (let i = 0; i < a.length; i++) {
            if (ObjectHelper.getValueOfProperty(a[i], propertyName) !== ObjectHelper.getValueOfProperty(a[i], propertyName)) {
                return false;
            }
        }

        return true;
    }

    static groupByProperty<T>(array: T[], propertyName: string): T[][] {
       return Object.values(array.reduce((acc, item) => {
            // Append the item to the array for each country
            acc[item[propertyName]] = [...(acc[item[propertyName]] || []), item];
            return acc;
        }, {}));
    }

    static groupBy<T>(array: T[], func: (item: T) => any[]) {
        const groups = {};
        array.forEach(function (o) {
            const group = JSON.stringify(func(o));
            groups[group] = groups[group] || [];
            groups[group].push(o);
        });
        return Object.keys(groups).map(function (group) {
            return groups[group];
        });
    }

    static groupByWithKeys<T>(array: T[], predicate: (value: T, index: number, array: T[]) => string): Dictionary<T[]> {
        return array.reduce((acc, value, index, array) => {
            (acc[predicate(value, index, array)] ||= []).push(value);
            return acc;
        }, {} as { [key: string]: T[] });
    }

    static groupByReadonlyMap<T>(array: T[], predicate: (value: T, index: number, array: T[]) => string): ReadonlyMap<string, T[]> {
        return array.reduce((acc, value, index, array) => {
            (acc[predicate(value, index, array)] ||= []).push(value);
            return acc;
        }, {} as ReadonlyMap<string, T[]>);
    }

    static sortItemsByProperty(array: any[], property: string, descending: boolean = true): any[] {
        return array = array.sort((x, y) => {
            if (x[property] > y[property]) {
                return descending ? 1 : -1;
            }

            if (x[property] < y[property]) {
                return descending ? -1 : 1;
            }

            return 0;
        });
    }

    static sortItems(array: string[] | number[], descending: boolean = true): any[] {
        return array = array.sort((x, y) => {
            if (x > y) {
                return descending ? 1 : -1;
            }

            if (x < y) {
                return descending ? -1 : 1;
            }

            return 0;
        });
    }

    static toggleArrayByExistingValue(array: any, value: any) {
        const i = array.findIndex(x => x === value);
        if (i !== -1) {
            array.splice(i, 1);
        } else {
            array.push(value);
        }
        return array;
    }

    static toggleArrayByExistingValueProperty(array: any, value: any, property: string) {
        const i = array.findIndex(x => x === value[property]);
        if (i !== -1) {
            array.splice(i, 1);
        } else {
            array.push(value[property]);
        }
        return array;
    }

    static getClosestNumberValueInArray(array: any, value: any, property: string): any {
        const exactValue = array.find(x => x[property] === value);
        if (exactValue) {
            return exactValue; // Returns first value if the current value exists
        }

        // Find the closest value
        const closest = array.reduce((closest, x) => {
            const closestDiff = Math.abs(closest[property] - value);
            const currentDiff = Math.abs(x[property] - value);
            return currentDiff < closestDiff ? x : closest;
        }, array[0]);

        return closest || null;
    }

}
