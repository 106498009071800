import { Component } from '@angular/core';
import { IHeaderParams } from 'ag-grid-community';
import { IHeaderAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'gtn-checkbox-header',
    templateUrl: './checkbox-header.component.html',
    styleUrls: ['./checkbox-header.component.scss']
})
export class CheckboxHeaderComponent implements IHeaderAngularComp {
    params: IHeaderParams;

    changeEvent: Function;
    label: string;
    checked: boolean = false;

    constructor() {
    }

    agInit(params: IHeaderParams) {
        if (typeof (params.column.getColDef().headerComponentParams.changeEvent) === 'function') {
            this.changeEvent = params.column.getColDef().headerComponentParams.changeEvent;
        }
    }

    refresh(params: IHeaderParams): boolean {
        return false;
    }

    onCheckboxChanged(): void {
        this.changeEvent(this.checked);
    }

}
