import { AreaTypesEnum } from 'app/nexus-shared/domain/documents/enums/area-types.enum';
import { DocumentModel } from 'app/nexus-shared/domain/documents/models/document.model';

export class DocumentDeleteModel {
    documentKey: string;
    areaType: AreaTypesEnum;

    constructor(model: DocumentModel) {
        this.documentKey = model?.documentKey ?? null;
        this.areaType = model?.areaType ?? null;
    }
}
