import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseEntryComponent } from 'app/nexus-shared/components/base-component/base-entry.component';
import { CompanyRelationshipModel } from 'app/nexus-shared/domain/contacts/models/company-relationship.model';
import { CompanyModel } from 'app/nexus-shared/domain/contacts/models';
import { CompanyRelationshipService } from 'app/nexus-core/services/domain/contacts/company-relationship.service';
import { CompanyService } from 'app/nexus-core/services/domain/contacts/company.service';
import { ApplicationsEnum } from 'app/nexus-shared/enums';
import { ViewModesEnum } from 'app/nexus-shared/enums/view-modes.enum';
import { CardModesEnum } from 'app/nexus-shared/enums/card-modes.enum';

@Component({
    selector: 'gtn-company-relationship-modal',
    templateUrl: './company-relationship-modal.component.html',
    styleUrls: ['./company-relationship-modal.component.scss']
})
export class CompanyRelationshipModalComponent extends BaseEntryComponent<CompanyRelationshipModel> implements OnInit {
    @Input() applicationSource: ApplicationsEnum = ApplicationsEnum.Contacts;
    @Input() companyKey: string;
    @Input() isAdmin: boolean;

    @Output() saveSuccess: EventEmitter<void> = new EventEmitter<void>();

    isCreateNewCompany: boolean;
    isParentChildRelationship: boolean = true;
    company: CompanyModel;
    companyViewMode = ViewModesEnum.General;
    companyCardMode = CardModesEnum.hidden;

    constructor(
        private companyService: CompanyService,
        private companyRelationshipService: CompanyRelationshipService) {
        super();
    }

    ngOnInit(): void {
        if (!this.value?.companyRelationshipKey) {
            this.value.parentCompany = <CompanyModel>{ companyKey: this.companyKey };
        } else {
            this.isParentChildRelationship = this.value?.parentCompany.companyKey === this.companyKey;
        }
    }

    onCancelClicked(): void {
        if (this.isCreateNewCompany) {
            this.isCreateNewCompany = false;
        } else {
            this.cancelClick.emit();
        }
    }

    onCreateNewCompanyClicked(): void {
        this.company = new CompanyModel();
        this.company.applicationSourceType = this.applicationSource;
        this.isCreateNewCompany = true;
    }


    onSaveClicked(): void {
        if (this.value?.companyRelationshipKey) {
            this.companyRelationshipService.update(this.value).subscribe(_ => {
                this.onSaveSuccess();
                this.saveSuccess.emit();
            }, (error) => {
                this.onSaveFailure(error);
            });
        } else {
            if (!this.isParentChildRelationship) {
                this.value.parentCompany = new CompanyModel(this.value.childCompany);
                const company: CompanyModel = new CompanyModel();
                company.companyKey = this.companyKey;
                this.value.childCompany = company;
            }
            this.companyRelationshipService.create(this.value).subscribe(_ => {
                this.onSaveSuccess();
                this.saveSuccess.emit();
            }, (error) => {
                this.onSaveFailure(error);
            });
        }
    }

    onCompanySaveSuccess(company: CompanyModel) {
        this.value.childCompany = new CompanyModel(company);
        this.isCreateNewCompany = false;
        setTimeout(() => {
            this.formGroupRef.markAsDirty();
        });
    }
}
