import { Component, Input } from '@angular/core';

@Component({
    selector: 'gtn-modal-header',
    templateUrl: './nexus-modal-header.component.html',
    styleUrls: ['./nexus-modal-header.component.scss']
})
export class NexusModalHeaderComponent {
    @Input() closeClick: Function = () => { };
    @Input() mainHeaderText: string;
    @Input() showCloseButton: boolean = true;
    @Input() subHeaderText: string;
}
