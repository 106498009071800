<div class="tab-container" *ngIf="tabs?.length">
    <div #tabsContainer class="tabs">
        <ng-container *ngIf="!showTabContentWithoutTabs || isShowTabContent || isAnimating">
            <ng-container *ngFor="let tab of tabs; index as i">
                <div class="tab"
                    [hidden]="!tab?.visible ?? false"
                    [ngStyle]="{'background-color': tabColors[i]}"
                    [ngClass]="{'in-active': selectedTab.id !== tab.id && isShowTabContent}"
                    (click)="onShowTabClicked(tab)">
                    <div class="tab-label">{{ tab.value }}</div>
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="!showTabContentWithoutTabs">
            <button (click)="onShowTabClicked(null)" class="collapse"
                color="default" mat-mini-fab>
                <mat-icon *ngIf="!isShowTabContent">chevron_left</mat-icon>
                <mat-icon *ngIf="isShowTabContent">chevron_right</mat-icon>
            </button>
        </ng-container>
    </div>

    <div class="slide-out-tab-content" [@slideInOut]="isAnimating" [ngStyle]="{'height': contentHeight}"
        [ngClass]="{'show-slide-out-tab-content': isAnimating || isShowTabContent, 'hide-slide-out-tab-content': !isAnimating || !isShowTabContent, 'box-shadow': isSlideOver && isShowTabContent, 'wide-content': isWide, 'narrow-content': isNarrow}">
        <ng-content select="gtn-slide-out-tabs-content"></ng-content>
    </div>
</div>
