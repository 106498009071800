import { Component, forwardRef } from '@angular/core';
import { DropdownComponent } from 'app/nexus-shared/components/controls';
import { DropdownOptionTypeModel } from 'app/nexus-shared/domain/questionnaires/models/dropdown-option-type.model';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { QuestionnaireDropdownsService } from 'app/nexus-core/services/domain/questionnaires/questionnaire-dropdowns.service';

@Component({
    selector: 'gtn-questionnaire-dropdown-option-type-select',
    templateUrl: '../../../../components/controls/components/dropdown/dropdown.component.html',
    styleUrls: ['../../../../components/controls/components/dropdown/dropdown.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => QuestionnaireDropdownOptionTypeSelectComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => QuestionnaireDropdownOptionTypeSelectComponent),
            multi: true
        }
    ]
})
export class QuestionnaireDropdownOptionTypeSelectComponent extends DropdownComponent<DropdownOptionTypeModel> {

    idOnly = true;
    idKey = 'id';
    displayKey = 'label';

    constructor(private questionnaireDropdownsService: QuestionnaireDropdownsService) {
        super();
    }

    initOptions(): void {
        this.questionnaireDropdownsService.search().subscribe(res => {
            this.options = res;
        });
    }
}
