import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NexusAnimations } from 'app/nexus-shared/animations';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { LoggingService } from 'app/nexus-core';
import { environment } from 'environments/environment';
import { UntypedFormGroup } from '@angular/forms';
import { UpdateEmailComponent } from 'app/nexus-shared/components/update-email/update-email.component';

@Component({
    selector: 'gtn-update-email-modal',
    templateUrl: './update-email-modal.component.html',
    animations: [
        NexusAnimations.modalFadeIn
    ]
})
export class UpdateEmailModalComponent extends BaseComponent implements OnInit {
    @Output() completed: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('updateEmail') updateEmailComponent: UpdateEmailComponent;

    isValidationStep: boolean = false;
    disableButtons: boolean = false;
    changeEmailLinkTimeToLiveInMinutes: number;
    formGroupRef: UntypedFormGroup;

    constructor(public loggingService: LoggingService) {
        super();
    }

    ngOnInit(): void {
        // ToDo: what's the intention of this? Bug? Mike?
        this.changeEmailLinkTimeToLiveInMinutes = environment().auth.passwordChangeLinkTimeToLiveInMinutes;
    }

    onSubmitClicked(): void {
        this.updateEmailComponent.onSubmit()?.subscribe();
    }

    onCancelClicked(): void {
        this.completed.emit(false);
    }

    onFormGroupRefChanged(formGroup: UntypedFormGroup): void {
        this.formGroupRef = formGroup;
    }

    onCompleted(): void {
        this.completed.emit(true);
    }
}
