import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { LocationService } from 'app/nexus-core/services/location.service';


@Injectable()
export class CountryResolver  {
    constructor(
        private locationService: LocationService
    ) {
    }

    resolve(activatedRouteSnapshot: ActivatedRouteSnapshot): Observable<any> | any {
       return this.locationService.listCountries();
    }
}
