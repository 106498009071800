import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BaseHttpService } from 'app/nexus-core/index';
import { Observable } from 'rxjs';
import { MyGTNPortalSearchResultModel } from 'app/nexus-shared/domain/contacts/models/my-gtn-portal-search-restult.model';
import { ContactSearchModel } from 'app/nexus-shared/domain/contacts/models/contact-search-model';


@Injectable()
export class MyGTNPortalService {
    private apiUrl = `${environment().webAPIUrls.contacts}my-gtn-portal/`;

    constructor(private http: BaseHttpService) {
    }

    searchCompanies(search: ContactSearchModel): Observable<MyGTNPortalSearchResultModel[]> {
        return this.http.post(`${this.apiUrl}company/search`, search);
    }

    searchIndividuals(search: ContactSearchModel): Observable<MyGTNPortalSearchResultModel[]> {
        return this.http.post(`${this.apiUrl}individual/search`, search);
    }

    getCompany(myGTNPortalCompanyKey: string):  Observable<string> {
        return this.http.get(`${this.apiUrl}company/${myGTNPortalCompanyKey}/name`, null, 'text');
    }

    getIndividual(myGTNPortalUserKey: string): Observable<string> {
        return this.http.get(`${this.apiUrl}individual/${myGTNPortalUserKey}/name`, null, 'text');
    }
}

