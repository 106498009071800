import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';

@Component({
    selector: 'gtn-dashboard-frame',
    templateUrl: './dashboard-frame.component.html',
    styleUrls: ['./dashboard-frame.component.scss']
})
export class DashboardFrameComponent extends BaseComponent {

    @Input() headerTitle: string = '';
    @Input() launchButtonTitle: string = '';
    @Input() showLaunchButton = true;
    @Output() externalLinkClick: EventEmitter<void> = new EventEmitter();

    onExternalLinkClicked() {
        this.externalLinkClick.emit();
    }
}
