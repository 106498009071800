import { AreaTypesEnum } from 'app/nexus-shared/domain/documents/enums/area-types.enum';
import { DocumentStatusesEnum } from 'app/nexus-shared/domain/documents/enums/document-statuses.enum';
import { DocumentAdvancedSearchModel } from 'app/nexus-shared/domain/documents/models/document-advanced-search.model';

export class DocumentSearchModel {
    documentKeys: string[];
    searchTerm: string;
    folderKey: string;
    folderPath: string;
    individualKey: string;
    companyKey: string;
    areaTypes: AreaTypesEnum[];
    statuses: DocumentStatusesEnum[];
    startDate: string | Date;
    endDate: string | Date;
    includeHistory: boolean;
    includeInactive: boolean;
    includeCompany: boolean;
    includeIndividual: boolean;
    showDeletedFilesFromLastWeek: boolean;
    userKey: string;
    advancedSearch: DocumentAdvancedSearchModel;

    constructor(model: DocumentSearchModel = null) {
        this.documentKeys = model?.documentKeys ?? [];
        this.searchTerm = model?.searchTerm ?? null;
        this.folderKey = model?.folderKey ?? null;
        this.folderPath = model?.folderPath ?? null;
        this.areaTypes = model?.areaTypes ?? null;
        this.statuses = model?.statuses ?? null;
        this.startDate = model?.startDate ?? null;
        this.endDate = model?.startDate ?? null;
        this.includeHistory = model?.includeHistory ?? false;
        this.includeInactive = model?.includeInactive ?? false;
        this.includeCompany = model?.includeCompany ?? false;
        this.includeIndividual = model?.includeIndividual ?? false;
        this.userKey = model?.userKey ?? null;
        this.advancedSearch = model?.advancedSearch ?? null;
    }
}
