<form [formGroup]="formGroupRef">
    <gtn-assign-questionnaire-form [isSubForm]="true" [formGroupRef]="questionnaireFormGroup"
        [disableIndividualSearch]="disableIndividualSearch"
        [questionnaireTemplates]="questionnaireTemplates" [questionnaireType]="questionnaireType">
    </gtn-assign-questionnaire-form>
    <div class="row">
        <gtn-input class="col-md-6" label="CCH username" formControlName="userName"></gtn-input>
        <gtn-input class="col-md-6" type="password" label="CCH password" formControlName="password"></gtn-input>
    </div>
    <div class="row">
        <div class="col-md-6">
            <gtn-individual-search label="Gatekeeper" formControlName="gateKeeper"
                [individualSearchModel]="gatekeeperIndividualSearchModel"></gtn-individual-search>
        </div>
    </div>
</form>
