import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationInfoModel } from 'app/nexus-shared/models/application-info.model';
import { BaseHttpService } from 'app/nexus-core/services/index';

@Injectable()
export class TaxesApplicationInfoService {

    constructor(private baseHttpService: BaseHttpService) {
    }

    get(): Observable<ApplicationInfoModel> {
        return this.baseHttpService.get(this.apiUrl());
    }

    private apiUrl = () => environment().webAPIUrls.taxes + 'applicationInfo';
}
