import { ServiceAreaTypesEnum } from '../enums/service-area-types.enum';
import { VendorSubTypesEnum } from '../enums/vendor-sub-types.enum';

export class ServiceAreaTypeModel {
    serviceAreaTypeKey: string;
    vendorSubType: VendorSubTypesEnum;
    type: ServiceAreaTypesEnum;

    constructor(model: ServiceAreaTypeModel = null) {
        this.serviceAreaTypeKey = model?.serviceAreaTypeKey ?? null;
        this.vendorSubType = model?.vendorSubType ?? null;
        this.type = model?.type ?? null;
    }
}
