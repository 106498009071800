import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NexusValidatorHelper } from 'app/nexus-core';
import { ControlAppearanceType } from 'app/nexus-shared/components/controls/shared/types';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { MatFormFieldAppearance } from '@angular/material/form-field';

@Component({
    selector: 'gtn-base-control',
    template: ''
})
export abstract class BaseControlComponent<T> extends BaseComponent implements ControlValueAccessor {
    @Input('value') _value: T;
    @Input() appearance: MatFormFieldAppearance = ControlAppearanceType.fill;
    @Input() canShowValidation: boolean;
    @Input() classNames: string;
    @Input() disabled: boolean;
    @Input() readonly: boolean;
    @Input() focus: boolean = false;
    @Input() label: string = '';
    @Input() required: boolean;
    @Input() suppressInitialChangeEvent: boolean = false;

    @ViewChild('element') elementRef: ElementRef<HTMLElement>;

    formGroup: UntypedFormGroup;

    formControl: UntypedFormControl;
    isValid: boolean = false;

    get value(): T {
        return this._value;
    }

    set value(val: T) {
        this._value = val;
        if (!this.suppressInitialChangeEvent) {
            this.onChange(val);
            this.onTouched();
            this.triggerChanged();
        }
        this.suppressInitialChangeEvent = false;
    }

    getErrorMessage(): string[] {
        return NexusValidatorHelper.getErrorMessage(this.formControl, this.label);
    }

    onBlur(): void {
        this.onTouched();
    }

    registerOnChange(fn): void {
        this.onChange = fn;
    }

    registerOnTouched(fn): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    setFocus(): void {
        // This must be overridden in the extender class.
    }

    triggerChanged(el: ElementRef<HTMLElement> = null) {
        const event = new CustomEvent('change', { bubbles: true });
        event['value'] = this._value;
        if (el) {
            el.nativeElement.dispatchEvent(event);
        } else {
            this.elementRef?.nativeElement?.dispatchEvent(event);
        }
    }

    validate(formControl: UntypedFormControl) {
        this.formControl = formControl;
        return this.validateFn(formControl);
    }

    writeValue(value: T): void {
        const currentValue = this._value;

        if (typeof currentValue !== 'undefined' && currentValue !== null && currentValue !== value) {
            this.value = value;
        } else if (typeof value !== 'undefined' && value !== null && currentValue !== value) {
            this.value = value;
        }
    }

    protected onChange: Function = () => {
    };
    protected onTouched: Function = () => {
    };
    protected validateFn: Function = () => {
    };
}
