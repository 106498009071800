import { Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LocationModel } from 'app/nexus-shared/domain/locations/models';
import { BaseLocationMultiSelectComponent } from 'app/nexus-shared/domain/locations/components/select/base-location-multiselect.component';
import { LocationService } from 'app/nexus-core/services/location.service';
import { SortingHelper } from 'app/nexus-core';

@Component({
    selector: 'gtn-country-multi-select',
    templateUrl: '../../../../components/controls/components/dropdown/dropdown.component.html',
    styleUrls: ['../../../../components/controls/components/dropdown/dropdown.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CountryMultiSelectComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => CountryMultiSelectComponent),
            multi: true
        }
    ]
})
export class CountryMultiSelectComponent extends BaseLocationMultiSelectComponent implements ControlValueAccessor {
    @Input() countriesWithStatesOnly = false;
    @Input() label: string = 'Countries';

    @ViewChild('element') element1: ElementRef<HTMLElement>;

    constructor(private locationService: LocationService) {
        super();
    }

    dataFetch = () => {
        return this.countriesWithStatesOnly
            ? this.locationService.listCountriesWithStates()
            : this.locationService.listCountries();
    };

    handleResponse(data: LocationModel[]) {
        super.handleResponse(data);
        this.resortOptions();
    };


    resortOptions(): void {
        if (!this.options) {
            return;
        }

        const selectedCountryIds = this.idOnly ? this.value ?? [] : this.value?.map(c => c.id) ?? [];
        // "Deselect All" at the top then selected options then unselected options. US & Canada come before other countries
        this.options = this.options.sort((a, b) => {
            if (a.id === this.deselectAllId) {
                return -1;
            }

            if (b.id === this.deselectAllId) {
                return 1;
            }

            const aSelected = selectedCountryIds.indexOf(a.id) > -1;
            const bSelected = selectedCountryIds.indexOf(b.id) > -1;

            if (aSelected && !bSelected) {
                return -1;
            }

            if (bSelected && !aSelected) {
                return 1;
            }

            if (a.id === LocationService.countryIds.unitedStatesLocationId) {
                return -1;
            }

            if (b.id === LocationService.countryIds.unitedStatesLocationId) {
                return 1;
            }

            if (a.id === LocationService.countryIds.canadaLocationId) {
                return -1;
            }

            if (b.id === LocationService.countryIds.canadaLocationId) {
                return 1;
            }

            return SortingHelper.sortByPropertyComparer(a, b, 'name');
        });
    }
}
