import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { ValidationModel } from 'app/nexus-shared';
import { StringHelper } from './string.helper';
import { Subscription } from 'rxjs';
import { NexusValidatorHelper } from './nexus-validator.helper';

@Injectable()
export class BaseFormGroupHelper implements OnDestroy {
    formGroup: UntypedFormGroup = new UntypedFormGroup({});
    subscriptions: Subscription = new Subscription();
    validationModels: ValidationModel[] = [];

    private _editMode: boolean;

    constructor() { }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    getInvalidControls(group: UntypedFormGroup | UntypedFormArray) {
        const invalidControls: any[] = [];
        const recursiveFunc = (form: UntypedFormGroup | UntypedFormArray) => {
            Object.keys(form.controls).forEach(field => {
                const control = form.get(field);

                if (control.invalid) {
                    invalidControls.push({
                        name: field,
                        control: control
                    });
                }

                if (control instanceof UntypedFormGroup) {
                    recursiveFunc(control);
                } else if (control instanceof UntypedFormArray) {
                    recursiveFunc(control);
                }
            });
        };

        recursiveFunc(group);
        return invalidControls;
    }

    setControlValues(data: any): void {
        let count = Object.keys(this.formGroup.controls).length;
        do {
            for (const controlName in this.formGroup.controls) {
                if (controlName) {
                    const control = this.formGroup.controls[controlName];
                    if (control) {
                        --count;
                        const value = data[controlName];
                        control.setValue(value);
                    }
                }
            }
        } while (count > 0);

        this.formGroup.markAsPristine();
        this.formGroup.markAsUntouched();
    }

    reset(): void {
        this.formGroup.reset();
        this.formGroup.markAsPristine();
        this.formGroup.markAsTouched();
    }

    updateModel(dataModel: any): any {
        if (dataModel) {
            let count = Object.keys(this.formGroup.controls).length;

            do {
                for (const controlName in this.formGroup.controls) {
                    if (controlName) {
                        --count;
                        const control = this.formGroup.controls[controlName];
                        if (control && control.dirty) {
                            dataModel[controlName] = control.value;
                        }
                    }
                }
            } while (count > 0);

            return dataModel;
        } else {
            return null;
        }
    }

    buildFormGroup(): any {
        return this.formGroup;
    }

    bindValidation() {
        this.subscriptions.add(this.formGroup.statusChanges.subscribe(status => {
            if (status === 'VALID' || status === 'INVALID') {
                if (status === 'INVALID') {
                    // Get all invalid controls
                    const invalidControls = this.getInvalidControls(this.formGroup);
                    const validationModels: ValidationModel[] = this.validationModels ? JSON.parse(JSON.stringify(this.validationModels)) : null;
                    // Loop the existing validationModels
                    if (validationModels?.length > 0) {
                        let modelCount: number = validationModels.length;
                        do {
                            for (const model of validationModels) {
                                --modelCount;
                                const propertyName = StringHelper.lowerCaseFirst(model.field);
                                const controlIndex = invalidControls.findIndex(x => x.name === propertyName);
                                if (controlIndex > -1) {
                                    const control = invalidControls[controlIndex],
                                        errorMessages = NexusValidatorHelper.getErrorMessage(control.control),
                                        models = validationModels.filter(x => x.field.toLowerCase() === propertyName.toLowerCase());

                                    if (errorMessages?.length === models?.length) {
                                        invalidControls.splice(controlIndex, 1);
                                    } else {
                                        //  Make the shared match the errors for this control
                                        const existingModels: ValidationModel[] = JSON.parse(JSON.stringify(models));

                                        errorMessages.forEach(message => {
                                            const currentModel = models.find(x => x.message.toLowerCase() === message.toLowerCase());
                                            if (currentModel) {
                                                existingModels.splice(existingModels.findIndex(x => x.message.toLowerCase() === message.toLowerCase()));
                                            } else {
                                                // Create a model here
                                                const newModel: ValidationModel = {
                                                    code: '',
                                                    message: message,
                                                    dataBag: null,
                                                    field: control.name,
                                                    fieldValue: control.control.value
                                                };

                                                this.validationModels.push(newModel);
                                            }
                                        });

                                        if (existingModels?.length > 0) {
                                            // Drop these ones from the master list
                                            existingModels.forEach(existingModel => {
                                                this.validationModels.splice(this.validationModels.findIndex(x => x.field.toLowerCase() === existingModel.field.toLowerCase()), 1);
                                            });
                                        }

                                        invalidControls.splice(controlIndex, 1);
                                    }
                                } else {
                                    this.validationModels.splice(this.validationModels.findIndex(x => x.field.toLowerCase() === model.field.toLowerCase()), 1);
                                }
                            }
                        } while (modelCount > 0);
                    }

                    if (invalidControls?.length > 0) {
                        // Create new shared here from the controls
                        let controlCount: number = invalidControls.length;
                        do {
                            for (const control of invalidControls) {
                                --controlCount;

                                const errors = control.control.errors;

                                for (const key in errors) {
                                    if (key && errors[key]) {
                                        const model: ValidationModel = {
                                            code: '',
                                            message: errors[key],
                                            dataBag: null,
                                            field: control.name,
                                            fieldValue: control.control.value
                                        };

                                        this.validationModels.push(model);
                                    }
                                }
                            }
                        } while (controlCount > 0);
                    }
                } else if (this.validationModels?.length > 0) {
                    this.validationModels = [];
                }
            }
        }));
    }

    get editMode(): boolean {
        return this._editMode;
    }

    set editMode(val: boolean) {
        this._editMode = val;

        // enable/disable the form group
        if (val) {
            this.formGroup.enable();
        } else {
            this.formGroup.disable();
        }
    }
}
