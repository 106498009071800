<div class="row d-flex justify-content-between header-container">
    <div>
        <ng-container *ngIf="!hideFilters">
            <span *ngIf="showAll" [ngClass]="{selected: selectedValue === null}" (click)="onHeaderItemClicked(null)">
                {{allLabel}}
            </span>
            <span *ngFor="let currentValue of headerValues" (click)="onHeaderItemClicked(currentValue)"
                  [ngClass]="{selected: selectedValue === currentValue.value, disabled: currentValue.disabled}">{{currentValue.value}}
            </span>
        </ng-container>
    </div>
    <div>
        <ng-content select="gtn-filterable-tiles-header"></ng-content>
    </div>
</div>
<div class="row content-container">
    <div class="col-sm-auto content-column-container pointer" *ngFor="let currentValue of contentValues">
        <mat-card appearance="outlined" (click)="tileClick.emit(currentValue)">
            <ng-template [ngTemplateOutlet]="templateRef"
                         [ngTemplateOutletContext]="{$implicit: currentValue}"></ng-template>
        </mat-card>
    </div>
</div>

