<form [formGroup]="formGroupRef" *ngIf="formGroupRef">
    <gtn-template-dynamic-card
        *ngIf="viewMode === viewModes.Simple"
        [mode]="cardMode">
        <ng-template #content>
            <div class="row">
                <gtn-input class="col-md-4" formControlName="firstName" type="text" label="First name"></gtn-input>
                <gtn-input class="col-md-4" formControlName="middleName" type="text" label="Middle name"></gtn-input>
                <gtn-input class="col-md-4" formControlName="lastName" type="text" label="Last name"></gtn-input>
            </div>
            <div class="row">
                <gtn-input *ngIf="!isControllingCompany" class="col-md-3" formControlName="emailAddress" type="text"
                    label="Primary email">
                </gtn-input>
                <gtn-input *ngIf="!isControllingCompany" class="col-md-3" formControlName="secondaryEmailAddress"
                    type="text"
                    label="Secondary email">
                </gtn-input>
                <gtn-input class="col-md-3" formControlName="preferredName" type="text"
                    label="Preferred name"></gtn-input>
                <gtn-dropdown class="col-md-3" formControlName="region" [options]="regionTypeOptions" [idOnly]="true"
                    label="Region"></gtn-dropdown>
            </div>
        </ng-template>
    </gtn-template-dynamic-card>
    <gtn-template-dynamic-card
        *ngIf="(viewMode === viewModes.All || viewMode === viewModes.General)"
        [mode]="cardMode">
        <ng-template #content>
            <div class="row">
                <div class="col-md-4">
                    <gtn-multiselect [readonly]="isIndividualTypeReadonly" formControlName="individualTypes"
                        [options]="individualTypeOptions"
                        [displayFunc]="individualTypeDisplayFn.bind(this)"
                        (change)="onIndividualTypeChanged($event['value'])" displayKey="type" idKey="type"
                        label="Individual type">
                    </gtn-multiselect>
                </div>
                <div class="col-md-4" *ngIf="isIndependent || isGtnIndividualType">
                    <gtn-dropdown formControlName="region" [options]="regionTypeOptions" [idOnly]="true"
                        label="Region"></gtn-dropdown>
                </div>
            </div>
            <div class="row">
                <gtn-input class="col-md-4" formControlName="firstName" type="text" label="First name"></gtn-input>
                <gtn-input class="col-md-4" formControlName="middleName" type="text" label="Middle name"></gtn-input>
                <gtn-input class="col-md-4" formControlName="lastName" type="text" label="Last name"></gtn-input>
            </div>
            <div class="row align-items-center">
                <gtn-input class="col-md-4" formControlName="preferredName" type="text"
                    label="Preferred name"></gtn-input>
                <gtn-dropdown class="col-md-4" label="Preferred pronouns" formControlName="pronoun" [idOnly]="true"
                    [options]="pronounTypes"></gtn-dropdown>
                <gtn-datepicker class="col-md-4" formControlName="birthDate" label="Date of birth"></gtn-datepicker>
            </div>
            <div class="row">
                <gtn-input class="col-md-4" formControlName="emailAddress" type="text"
                    label="Primary email"></gtn-input>
                <gtn-input *ngIf="!isControllingCompany" class="col-md-4" formControlName="secondaryEmailAddress"
                    type="text" label="Secondary email"></gtn-input>
            </div>
            <gtn-phone-number-subform type="individual"
                [isPhoneNumberSubFormArrayModal]="isPhoneNumberSubFormArrayModal"
                [formArray]="phoneNumbersFormArray"></gtn-phone-number-subform>
        </ng-template>
    </gtn-template-dynamic-card>
    <gtn-template-dynamic-card *ngIf="(viewMode === viewModes.All || viewMode === viewModes.AssociatedApplications)"
        [mode]="cardMode">
        <ng-template #content>
            <div class="row">
                <div class="col-md-4">
                    <gtn-my-gtn-portal-user-search *ngIf="!value?.myGTNPortalUserKey || !initialKey"
                        (searchResultClick)="onMyGTNPortalUserSelect($event)" formControlName="myGTNPortalUserKey">
                    </gtn-my-gtn-portal-user-search>
                    <div *ngIf="value?.myGTNPortalUserKey && initialKey" class="mb-3">
                        <label class="list-label">Portal user</label>
                        <div class="data-field">
                            <span *ngIf="myGTNPortalUserName">{{ myGTNPortalUserName }}
                                <i *ngIf="isGtnAdmin" (click)="onRemoveMyGTNPortalLinkClicked()"
                                    class="fa fa-times-circle pointer">
                                </i>
                            </span>
                            <i *ngIf="!myGTNPortalUserName" class="fas fa-spinner fa-spin"></i>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </gtn-template-dynamic-card>
    <gtn-template-dynamic-card *ngIf="viewMode === viewModes.Addresses"
        [mode]="cardMode">
        <ng-template #content>
            <gtn-addresses-subform [formArray]="addressFormArray" [(formGroupRef)]="formGroupRef"
                [isSubForm]="true"></gtn-addresses-subform>
        </ng-template>
    </gtn-template-dynamic-card>
</form>
