export enum ReportOptionsEnum {
    //reports
    TaxPreparersTravelReport = 0,
    TaxJurisdictionSummary = 1,
    SummaryByDate = 2,

    //grids
    DaysPresenceGrid = 20,
    TripsGrid = 21,
    WorkDayGrid = 22

}
