import { Component, forwardRef, Input } from '@angular/core';
import { DropdownComponent } from 'app/nexus-shared/components/controls';
import { DropdownOptionModel } from 'app/nexus-shared/domain/questionnaires/models/dropdown-option.model';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IQuestionnaireDropdownServiceInterface } from 'app/nexus-shared/domain/questionnaires/interfaces/iquestionnaire-dropdown-service.interface';

@Component({
    selector: 'gtn-questionnaire-dropdown-option-select',
    templateUrl: '../../../../components/controls/components/dropdown/dropdown.component.html',
    styleUrls: ['../../../../components/controls/components/dropdown/dropdown.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => QuestionnaireDropdownOptionSelectComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => QuestionnaireDropdownOptionSelectComponent),
            multi: true
        }
    ]
})
export class QuestionnaireDropdownOptionSelectComponent extends DropdownComponent<DropdownOptionModel> {
    @Input() dropdownId: number;
    @Input() questionnaireDropdownService: IQuestionnaireDropdownServiceInterface;

    idKey = 'value';
    idOnly = true;
    displayKey = 'label';

    constructor() {
        super();
    }

    initOptions(): void {
        this.questionnaireDropdownService.get(this.dropdownId).subscribe(res => {
            this.options = res.options;
        });
    }
}
