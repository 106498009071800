<gtn-company-create-template-modal
    [headerText]="value?.individualToCompanyAccessKey ? 'Edit Relationship' : 'Add Relationship'"
    [validationModels]="validationModels"
    [isCreateNewCompany]="isCreateCompany"
    [value]="company"
    [isSaving]="isSaving"
    [formGroupRef]="formGroupRef"
    (cancelClick)="onCancelClicked()" (saveClick)="onSaveClicked()" (companySaveSuccess)="onCompanySaveSuccess($event)">
    <gtn-company-create-dual-form-content>
        <gtn-individual-to-company-access-form *ngIf="!isCreateCompany" [(formGroupRef)]="formGroupRef"
            [(isControllingCompany)]="isControllingCompany" [individual]="individual"
            [isRelationshipTypeReadonly]="isRelationshipTypeReadonly"
            [applicationSource]="applicationSource"
            [canAddNewCompany]="canAddNewCompany"
            [isMappingIndividualToCompany]="true"
            (formGroupRefChange)="onFormGroupRefChanged($event)" [(value)]="value"
            (createCompany)="onCreateCompanyClicked()">
        </gtn-individual-to-company-access-form>
    </gtn-company-create-dual-form-content>
</gtn-company-create-template-modal>
