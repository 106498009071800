import { Component, EventEmitter, Output } from '@angular/core';
import { BaseViewComponent } from 'app/nexus-shared/components/base-component/base-view.component';
import { IndividualModel } from 'app/nexus-shared/domain/contacts/models';
import { IndividualTypesEnum } from 'app/nexus-shared/domain/contacts/enums/individual-types.enum';

@Component({
    selector: 'gtn-fuzzy-individual-options-view',
    templateUrl: './fuzzy-individual-options-view.component.html',
    styleUrls: ['./fuzzy-individual-options-view.component.scss']
})
export class FuzzyIndividualOptionsViewComponent extends BaseViewComponent<IndividualModel[]> {
    @Output() individualClick: EventEmitter<IndividualModel> = new EventEmitter<IndividualModel>();

    individualTypesEnum = IndividualTypesEnum;

    onIndividualClicked(individual: IndividualModel): void {
        this.individualClick.emit(individual);
    }
}
