import { ErrorHandler, Injectable } from '@angular/core';
import { LoggingService } from '../services/logging.service';


@Injectable()
export class ErrorHandlerUtility implements ErrorHandler {

    private loggingService: LoggingService;

    constructor(loggingService: LoggingService) {
        this.loggingService = loggingService;
    }

    handleError(error: Error): void {
        this.loggingService.logError(error);
    }

    handle(error: Error): void {
        this.loggingService.logError(error);
    }
}
