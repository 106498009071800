import { Component } from '@angular/core';
import { IStatusPanelAngularComp } from 'ag-grid-angular';
import { IStatusPanelParams } from 'ag-grid-community';
import { DocumentGridViewModel } from 'app/nexus-shared/domain/documents/models/document-view-models/document-grid-view.model';
import { DocumentsGridViewTypes } from 'app/nexus-shared/domain/documents/enums/documents-grid-view.types';

@Component({
    selector: 'gtn-documents-grid-status-bar',
    templateUrl: './documents-grid-status-bar.component.html',
    styleUrls: ['./documents-grid-status-bar.component.scss']
})
export class DocumentsGridStatusBarComponent implements IStatusPanelAngularComp {
    static readonly modelUpdatedEvent: string = 'modelUpdated';
    folders: number;
    documents: number;

    private params: IStatusPanelParams;

    agInit(params: IStatusPanelParams): void {
        this.params = params;
        this.params.api.addEventListener(DocumentsGridStatusBarComponent.modelUpdatedEvent, () => {
            this.updateDocumentTotals();
        });
    }

    updateDocumentTotals(): void {
        this.documents = 0;
        this.folders = 0;
        this.params.api.forEachNode(x => {
            const rowNodeData = x.data as DocumentGridViewModel;
            if (rowNodeData?.type === DocumentsGridViewTypes.File) {
                this.documents++;
            } else if (rowNodeData?.type === DocumentsGridViewTypes.Folder) {
                this.folders++;
            }
        });
    }

}
