<div [formGroup]="formGroup" class="filter-panel-container">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-4 col-lg-4">
                <gtn-country-select #country [formControlName]="formControlNames.country" [options]="countryOptions" showBlank="true">
                </gtn-country-select>
                <gtn-state-select #state [formControlName]="formControlNames.state" [countryId]="country?.value?.id ?? null"
                    showBlank="true">
                </gtn-state-select>
                <gtn-city-select [formControlName]="formControlNames.city" [countryId]="country?.value?.id ?? null"
                    [stateId]="state?.value?.id ?? null" showBlank="true">
                </gtn-city-select>
            </div>

            <div class="col-md-4 col-lg-4">
                <gtn-multiselect [formControlName]="formControlNames.withholdingTypes" idKey="taxWithholdingTypeId"
                    displayKey="name" label="Withholding types" [options]="withholdingTypes">
                </gtn-multiselect>
                <gtn-dropdown [formControlName]="formControlNames.specialization" label="Specialization"
                    [options]="specializationOptions">
                </gtn-dropdown>
            </div>

            <div class="col-md-4 col-lg-4">
                <gtn-datepicker [formControlName]="formControlNames.date" label="Date"></gtn-datepicker>
            </div>

        </div>
        <div class="button-group">
            <button mat-flat-button (click)="onApplyClicked()" class="mat-accent apply-button">Apply</button>
            <button mat-flat-button (click)="onClearClicked()" class="mat-button-default clear-button">Clear</button>
        </div>
    </div>
</div>
