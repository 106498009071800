<gtn-template-grid [showQuickFilter]="true" [showExportButton]="true"
    (addButtonClick)="onAddButtonClicked()"
    (exportButtonClick)="onExportButtonClicked()"
    (quickFilterTextChange)="onFilterTextChanged($event)">
    <gtn-template-grid-content>
        <ag-grid-angular [gtnHeight]="gridHeight" *ngIf="gridOptions" class="ag-theme-balham customized-theme"
            [gridOptions]="gridOptions"></ag-grid-angular>
    </gtn-template-grid-content>
</gtn-template-grid>

