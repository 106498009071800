import { Subject } from 'rxjs';
import { SearchResultModel } from 'app/nexus-shared/components/controls/shared/models/search-result.model';

export class SearchModel {
    // consumers will subscribe to these
    public onTextChanged = new Subject<string>();
    public onResultClicked = new Subject<SearchResultModel<any, any>>();
    public onCreateNewClicked = new Subject<void | boolean>();
    public onCreateNewSecondaryClicked = new Subject<void>();
    public onClearSearchText = new Subject<void>();

    // consumers will publish values to these
    public onResultsReceived = new Subject<SearchResultModel<any, any>[]>();
    public onErrorReceived = new Subject<Error>();
}
