export enum GtnDepartmentsEnum {
    Unknown = 0,
    Accounting = 1,
    Atlantic = 2,
    BusinessDevelopment = 3,
    Canada = 4,
    GreatLakes = 5,
    HumanResources = 6,
    InternalTechnology = 7,
    Marketing = 8,
    Pacific = 9,
    SharedServices = 10,
    SoftwareDevelopment = 11,
    South = 12,
    US = 13,
    WestCentral = 14
}
