<gtn-template-grid [showQuickFilter]="rootFolder.areaType !== areaTypesEnum.Clients"
    [quickFilterLabel]="quickFilterLabel"
    [quickFilterPositionRight]="false"
    (quickFilterTextChange)="onFilterTextChanged($event)">
    <gtn-template-grid-content>
        <div class="client-area-search">
            <gtn-client-individual-folder-search class="folder-search"
                *ngIf="rootFolder.areaType === areaTypesEnum.Clients && !isFolderTagSearch"
                label="Client/Employee search"
                (searchResultClick)="onClientFolderClicked($event)"></gtn-client-individual-folder-search>
            <ng-container *ngIf="rootFolder.areaType === areaTypesEnum.Clients">
                <gtn-multiselect *ngIf="folderTagOptions?.length && isFolderTagSearch" class="folder-search"
                    label="Tags"
                    [(ngModel)]="selectedFolderTags"
                    displayKey="folderTagName" [options]="folderTagOptions" idKey="folderTagName"
                    (change)="onFolderTagChange()">
                </gtn-multiselect>
                <gtn-slide-toggle class="tag-toggle" [(ngModel)]="isFolderTagSearch"
                    label="Tag search"></gtn-slide-toggle>
            </ng-container>
        </div>
        <ag-grid-angular [gtnHeight]="gridHeight" [minHeight]="minGridHeight" *ngIf="gridOptions"
            class="ag-theme-balham folders-grid"
            [gridOptions]="gridOptions"></ag-grid-angular>
    </gtn-template-grid-content>
</gtn-template-grid>
<gtn-documents-confirm-modal *ngIf="showConfirmModal" [confirmActionsEnum]="confirmActionType"
    [confirmActionEntities]="confirmActionEntities"
    (cancelClick)="onCancelClicked()" (confirmClick)="onConfirmClicked()"></gtn-documents-confirm-modal>
