import { Directive, HostBinding } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { InputType } from 'app/nexus-shared/components/controls';
import { DynamicFormFieldConfig } from 'app/nexus-shared/components/dynamic-form/shared/interfaces/dynamic-form-field-config.interface';

@Directive({
    selector: '[gtnBaseDynamicComponent]'
})
export class BaseDynamicComponentDirective {
    @HostBinding('class') get className() {
        return this.config.classNames;
    }

    config: DynamicFormFieldConfig;
    formGroup: UntypedFormGroup;

    type: InputType;
}
