import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { NexusAnimations } from 'app/nexus-shared/animations';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { ValidationModel } from 'app/nexus-shared/index';
import { UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'gtn-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    animations: [
        NexusAnimations.modalFadeIn
    ],
})
export class ModalComponent extends BaseComponent implements OnInit {
    @Input() headerText: string = '';
    @Input() subHeaderText: string = null;
    @Input() addFlexJustifyBetweenToFooter: boolean = false;

    @Input() isSplitView: boolean = false;

    @Input() disableOkButton: boolean = false;
    @Input() okButtonText: string = 'Ok';
    @Input() showOkButton: boolean = true;

    @Input() showCancelButton: boolean = true;
    @Input() disableCancelButton: boolean = false;
    @Input() cancelButtonText: string = 'Cancel';
    @Input() isCancelButtonColorOrange: boolean = false;

    @Input() showDeleteButton: boolean = false;
    @Input() disableDeleteButton: boolean = false;
    @Input() deleteButtonText: string = 'Delete';

    @Input() showFooter: boolean = true;
    @Input() shouldConfirmCancel = true;
    @Input() shouldConfirmSave = false;
    @Input() shouldConfirmDelete = true;
    @Input() isSaving: boolean = false;

    @Input() validationModels: ValidationModel[] = [];
    @Input() canShowValidation: boolean = true;
    @Input() closeOnBodyClick: boolean = false;

    @Input() formGroupRef: UntypedFormGroup = new UntypedFormGroup({});

    @Output() okClick: EventEmitter<void> = new EventEmitter();
    @Output() cancelClick: EventEmitter<void> = new EventEmitter();
    @Output() closeClick: EventEmitter<void> = new EventEmitter();
    @Output() deleteClick: EventEmitter<void> = new EventEmitter();
    @Output() scroll: EventEmitter<any> = new EventEmitter<any>();

    showHeaderCloseButton = true;
    confirmedCancel = false;
    confirmedSave: boolean = false;
    confirmedDelete: boolean = false;


    @HostListener('click', ['$event'])
    onModalClicked($event) {
        if (this.closeOnBodyClick && $event.target.nodeName === 'GTN-MODAL') {
            this.closeClick.emit();
        }
    }

    ngOnInit(): void {
        this.showOkButton = this.showOkButton && !!this.okClick.observers.length;
        this.showCancelButton = this.showCancelButton && !!this.cancelClick.observers.length;
        this.showHeaderCloseButton = !!this.closeClick.observers.length;

    }

    onOkButtonClicked(): void {
        if (this.shouldConfirmSave && !this.confirmedSave) {
            this.confirmedSave = true;
            this.okButtonText = 'Confirm';
            this.disableDeleteButton = true;
        } else {
            this.okClick.emit();
        }
    }

    onCancelClicked(): void {
        if (this.formGroupRef.dirty && this.shouldConfirmCancel && !this.confirmedCancel && !this.confirmedSave && !this.confirmedDelete) {
            this.confirmedCancel = true;
            this.cancelButtonText = 'Confirm';
            this.disableDeleteButton = true;
        } else {
            this.cancelClick.emit();
        }
    }

    onCloseClicked(): void {
        if (this.formGroupRef.dirty && this.shouldConfirmCancel && !this.confirmedCancel && !this.confirmedSave && !this.confirmedDelete) {
            this.confirmedCancel = true;
            this.cancelButtonText = 'Confirm';
            this.disableDeleteButton = true;
        } else {
            this.closeClick.emit();
        }
    }

    onDeleteButtonClicked() {
        if (this.shouldConfirmDelete && !this.confirmedDelete) {
            this.confirmedDelete = true;
            this.deleteButtonText = 'Confirm';
            this.disableOkButton = true;
        } else {
            this.deleteClick.emit();
        }
    }
}
