import { Injectable } from '@angular/core';
import { BaseService } from 'app/nexus-core/services/base-service.directive';
import { IndividualGtnOrganizerService } from 'app/nexus-core/services/domain/questionnaires/individual-gtn-organizer.service';
import { QuestionnaireTypesEnum } from 'app/nexus-shared/domain/questionnaires/enums/questionnaire-types.enum';
import { Observable } from 'rxjs';
import { IndividualCchOrganizerService } from 'app/nexus-core/services/domain/questionnaires/individual-cch-organizer.service';
import { IndividualSupplementalOrganizerService } from 'app/nexus-core/services/domain/questionnaires/individual-supplemental-organizer.service';
import { BaseQuestionnaireSearchModel } from 'app/nexus-shared/domain/questionnaires/models/base-questionnaire-search.model';
import { map } from 'rxjs/operators';
import { IndividualGtnOrganizerModel } from 'app/nexus-shared/domain/questionnaires/models/individual-gtn-organizer.model';
import { IndividualSupplementalOrganizerModel } from 'app/nexus-shared/domain/questionnaires/models/individual-supplemental-organizer.model';
import { IndividualCchOrganizerModel } from 'app/nexus-shared/domain/questionnaires/models/individual-cch-organizer.model';

@Injectable()
export class QuestionnaireCoordinatorService extends BaseService {

    constructor(private individualGtnOrganizerService: IndividualGtnOrganizerService,
                private individualSupplementalOrganizerService: IndividualSupplementalOrganizerService,
                private individualCchOrganizerService: IndividualCchOrganizerService) {
        super();
    }

    create(type: QuestionnaireTypesEnum, model: any): Observable<any> {
        switch (type) {
            case QuestionnaireTypesEnum.GTNOrganizer:
                return this.individualGtnOrganizerService.create(model);
            case QuestionnaireTypesEnum.SupplementalQuestionnaire:
                return this.individualSupplementalOrganizerService.create(model);
            case QuestionnaireTypesEnum.CCHOrganizer:
                return this.individualCchOrganizerService.create(model);
        }
    }

    update(type: QuestionnaireTypesEnum, model: any): Observable<any> {
        switch (type) {
            case QuestionnaireTypesEnum.GTNOrganizer:
                return this.individualGtnOrganizerService.update(model);
            case QuestionnaireTypesEnum.SupplementalQuestionnaire:
                return this.individualSupplementalOrganizerService.update(model);
            case QuestionnaireTypesEnum.CCHOrganizer:
                return this.individualCchOrganizerService.update(model);
        }
    }

    get(type: QuestionnaireTypesEnum, key: string): Observable<any> {
        const searchModel = <BaseQuestionnaireSearchModel>{ questionnaireKeys: [key] };
        switch (type) {
            case QuestionnaireTypesEnum.GTNOrganizer:
                return this.individualGtnOrganizerService.search(searchModel).pipe(map(res => {
                    return res?.length ? new IndividualGtnOrganizerModel(res[0]) : null;
                }));
            case QuestionnaireTypesEnum.SupplementalQuestionnaire:
                return this.individualSupplementalOrganizerService.search(searchModel).pipe(map(res => {
                    return res?.length ? new IndividualSupplementalOrganizerModel(res[0]) : null;
                }));
            case QuestionnaireTypesEnum.CCHOrganizer:
                return this.individualCchOrganizerService.search(searchModel).pipe(map(res => {
                    return res?.length ? new IndividualCchOrganizerModel(res[0]) : null;
                }));
        }
    }

}
