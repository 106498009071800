<gtn-modal class="modal-medium-wide" headerText="Contact Support" okButtonText="Send"
           (okClick)="onContactClicked()" (cancelClick)="onCancelClicked()" [disableOkButton]="disableButtons">
    <gtn-modal-content>
        <div class="row">
            <gtn-input class="col-md-4" label="First Name" [(ngModel)]="firstName"></gtn-input>
            <gtn-input class="col-md-4" label="Last Name" [(ngModel)]="lastName"></gtn-input>
            <gtn-input class="col-md-4" label="Email" [(ngModel)]="email"></gtn-input>
        </div>
        <div class="row">
            <gtn-text-area class="col-md-12" label="Notes" [(ngModel)]="contactNotes" [minRows]="5"></gtn-text-area>
        </div>
        <div class="row" *ngIf="loggingService?.errors?.length" (click)="showErrors = !showErrors">
            <div class="col-md-12 error-toggle">
                {{showErrors ? 'Hide Errors' : 'Show Errors'}}
            </div>
        </div>
        <div class="row" *ngIf="showErrors && loggingService.errors">
            <div class="col-md-12" *ngFor="let error of loggingService.errors">
                <div *ngIf="error.message">
                    {{error.message}}
                </div>
                <div *ngIf="error.stack">
                    {{error.stack}}
                </div>
                <hr />
            </div>
        </div>
    </gtn-modal-content>
</gtn-modal>
