<div class="rocket-container">
    <img src="../../../assets/images/rocket-gtn-flat.svg"/>
    <span>Uh oh</span>
</div>
<div class="text-center body">
    <div class="centered">
        <div class="row">
            <div class="col-sm-auto">
                <div class="circle hidden-circle"></div>
            </div>
            <div class="col-8 title">
                <p>
                    {{ message }}
                </p>
            </div>
        </div>
        <ng-container *ngIf="authenticationService.isAuthenticated$ | async">
            <div class="mt-5 row">
                <div class="col-sm-auto">
                    <div class="circle hidden-circle"></div>
                </div>
                <div class="col-8">
                    <p>
                        If you feel this was an error, here are some things you can try to get back on course
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-auto">
                    <div class="circle">
                    <span class="pr-1">
                        1
                    </span>
                    </div>
                </div>
                <div class="col-8">
                    <p>
                        First, try logging out and then logging back in
                    </p>
                    <button mat-flat-button class="mt-3" matTooltip="Logout" (click)="onLogoutClicked()">
                        Log out
                    </button>
                </div>
            </div>
            <div class="mt-5 row">
                <div class="col-sm-auto">
                    <div class="circle">2</div>
                </div>
                <div class="col-8">
                    <p class="mt-3">
                        If that doesn't work, contact us to report the error so we can look into what happened
                    </p>
                    <button mat-flat-button class="mt-3" matTooltip="Contact support"
                        (click)="this.applicationConfigurationService.showContactSupportModal = true">
                        Contact support
                    </button>
                </div>
            </div>
            <div class="mt-5 row">
                <div class="col-sm-auto">
                    <div class="circle hidden-circle"></div>
                </div>
                <div class="col-8">
                    <p>
                        Or, just <a href="javascript:void(0)" (click)="onBackToAppClicked()">go back</a>
                    </p>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="!(authenticationService.isAuthenticated$ | async)">
            <div class="col-8">
                <p>
                    First, try resetting your state
                </p>
                <button mat-flat-button class="mt-3" matTooltip="Logout" (click)="onLogoutClicked()">
                    Log out
                </button>
            </div>
            <div class="col-8">
                <p>
                    If the problem persists, contact us
                </p>
                <a href="mailto:{{supportAddress}}">{{ supportAddress }}</a>
            </div>
        </ng-container>
    </div>
</div>
