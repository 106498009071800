import { TaxResidencyEnum } from 'app/nexus-shared/domain/taxes/enums';
import { TaxModel } from 'app/nexus-shared/domain/taxes/models/tax-model';

export class TaxRateModel {
    taxRateId: number;
    taxId: number;
    effectiveStartDate: Date;
    effectiveEndDate?: Date;
    taxQualifierId?: number;
    limit?: number;
    rate: number;
    specializationKey: string;
    specializationTitle: string;
    taxResidencyId: TaxResidencyEnum;

    tax: TaxModel;

    constructor(taxId: number) {
        this.taxId = taxId ? taxId : undefined;
        this.effectiveStartDate = null;
        this.effectiveEndDate = null;
        this.taxQualifierId = null;
        this.limit = null;
        this.rate = null;
        this.specializationKey = null;
        this.specializationTitle = '';
        this.taxRateId = TaxResidencyEnum.None;

        this.tax = null;
    }
}
