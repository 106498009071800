import { ClientDocumentTypesEnum } from 'app/nexus-shared/domain/documents/enums/client-document-types.enum';

export class ClientDocumentModel {
    companyKey: string;
    individualKey: string;
    companyName: string;
    companyLegalName: string;
    individualFirstName: string;
    individualLastName: string;
    individualEmailAddress: string;
    taxYear: number;
    nonStandardTaxYear: boolean;
    countryId: number;
    type: ClientDocumentTypesEnum;

    constructor(model: ClientDocumentModel = null) {
        this.companyKey = model?.companyKey ?? null;
        this.individualKey = model?.individualKey ?? null;
        this.companyName = model?.companyName ?? null;
        this.companyLegalName = model?.companyLegalName ?? null;
        this.individualFirstName = model?.individualFirstName ?? null;
        this.individualLastName = model?.individualLastName ?? null;
        this.individualEmailAddress = model?.individualEmailAddress ?? null;
        this.taxYear = model?.taxYear ?? null;
        this.nonStandardTaxYear = model?.nonStandardTaxYear ?? null;
        this.countryId = model?.countryId ?? null;
        this.type = model?.type ?? null;
    }
}
