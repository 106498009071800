import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BaseEntryComponent } from 'app/nexus-shared/components/base-component/base-entry.component';
import { AuthenticatedUserService } from 'app/nexus-core/services/authenticated-user.service';
import { FileUploadComponent } from 'app/nexus-shared/components/controls/components/file-upload/file-upload.component';
import { FolderModel } from 'app/nexus-shared/domain/documents/models/folder.model';
import { ValidationModel } from 'app/nexus-shared/models';
import { BulkDocumentModel } from 'app/nexus-shared/domain/documents/models/document-view-models/bulk-document.model';
import { FileDocumentModel } from 'app/nexus-shared/domain/documents/models/document-view-models/file-document.model';
import { forkJoin, Observable } from 'rxjs';
import { ValidationErrorHelper } from 'app/nexus-core';
import { ToastService } from 'app/nexus-core/services/toast.service';
import { DocumentProcessService } from 'app/nexus-core/services/domain/documents/document-process.service';

@Component({
    selector: 'gtn-bulk-file-upload-modal',
    templateUrl: './bulk-file-upload-modal.component.html',
    styleUrls: ['./bulk-file-upload-modal.component.scss']
})
export class BulkFileUploadModalComponent extends BaseEntryComponent<BulkDocumentModel> implements OnInit {
    @Input() folder: FolderModel;
    @Input() files: File[];
    @Input() documentProcessService: DocumentProcessService;

    @Output() documentSaveClick: EventEmitter<FileDocumentModel[]> = new EventEmitter<FileDocumentModel[]>();

    canShowValidation = false;

    @ViewChild('fileUpload') fileUpload: FileUploadComponent;

    constructor(public authenticatedUserService: AuthenticatedUserService,
                private toastService: ToastService) {
        super();
    }

    ngOnInit(): void {
        this.value = new BulkDocumentModel();
    }

    onSaveClicked(): void {
        if (this.validateEntry()) {
            this.isSaving = true;
            const observables: Observable<boolean>[] = this.value.documents.map(x => this.documentProcessService.validateCreate(x.document));
            forkJoin(observables).subscribe(_ => {
                this.documentSaveClick.emit(this.value.documents);
            }, err => {
                this.isSaving = false;
                if (err.isValidationError) {
                    this.canShowValidation = true;
                    ValidationErrorHelper.handleServiceError(err, (validationModels) => {
                        this.validationModels = validationModels;
                    });
                } else {
                    this.toastService.showErrorToast(err);
                }
            });
        } else {
            this.canShowValidation = true;
        }
    }

    onCancelClicked(): void {
        this.cancelClick.emit();
    }

    onDocumentRemoved(index: number): void {
        this.fileUpload.onRemoveFileClicked(index);
    }

    onFilesChanged(files: File[]): void {
        this.files = files.map(x => x);
    }

    onValidationModelsChanged(validationModels: ValidationModel[]): void {
        //breaking expression changed after checked lifecycle
        setTimeout(() => {
            this.validationModels = validationModels;
        });
    }
}
