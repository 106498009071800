import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ColumnApi, GridApi, ICellRendererParams, RowNode } from 'ag-grid-community';

@Component({
    selector: 'gtn-folder-cell-group-renderer',
    templateUrl: './folder-cell-group-renderer.component.html',
    styleUrls: ['./folder-cell-group-renderer.component.scss']
})
export class FolderCellGroupRendererComponent implements ICellRendererAngularComp {
    name: string;
    isSelected: boolean = false;
    params: FolderCellGroupRendererParams;

    agInit(params: FolderCellGroupRendererParams) {
        this.params = params;
        this.name = params?.data?.name;
        this.isSelected = this.params.getSelectedFolderKey() === this.params?.data?.folderKey;
    }

    refresh(params: ICellRendererParams): boolean {
        return false;
    }

}

export class FolderCellGroupRendererParams implements  ICellRendererParams {
    api: GridApi<any>;
    columnApi: ColumnApi;
    context: any;
    data: any;
    eGridCell: HTMLElement;
    eParentOfValue: HTMLElement;
    node: RowNode<any>;

    registerRowDragger(rowDraggerElement: HTMLElement, dragStartPixels: number | undefined, value: string | undefined, suppressVisibilityChange: boolean | undefined): void {
    }

    rowIndex: number;
    value: any;
    valueFormatted: string | null | undefined;

    getSelectedFolderKey: Function;

}

