<form [formGroup]="formGroupRef">
    <div class="row">
        <div class="col-md-2 col-sm-6" [ngClass]="{'col-md-6': isModal || isSubFormArrayModal}">
            <gtn-dropdown *ngIf="countries?.length"
                idKey="countryCode" displayKey="display"
                formControlName="countryCode" label="Country code"
                [idOnly]="true"
                [showBlank]="true"
                [options]="countries"
                (change)="onCountryChanged($event['value'])"
            ></gtn-dropdown>
            <div *ngIf="isCountryPhoneNumberMismatch" class="warning-message">
                Country/number conflict
            </div>
        </div>
        <div class="col-md-2 col-sm-6" [ngClass]="{'col-md-6': isModal || isSubFormArrayModal}">
            <gtn-phone-number-input [formGroup]="formGroupRef"
                formControlName="formattedPhoneNumber" label="Phone no."
                [enableValidation]="true"
                (countryPhoneNumberMismatch)="isCountryPhoneNumberMismatch = $event"
                (validNumberChange)="validNumber = $event"
            ></gtn-phone-number-input>
            <div *ngIf="!validNumber" class="warning-message">
                Invalid phone number
            </div>
        </div>
        <gtn-input class="col-md-1 col-sm-6" *ngIf="showExtension"
            [ngClass]="{'col-md-6': !canRemove && !showIsPrimary && isModal, 'col-md-3' : !canRemove && !showIsPrimary, 'col-md-2': isSubFormArrayModal}"
            formControlName="extension" label="Ext."></gtn-input>
        <gtn-dropdown class="col-md-2 col-sm-6"
            [ngClass]="{'col-md-6': !canRemove && !showIsPrimary && isModal, 'col-md-3' : !canRemove && !showIsPrimary, 'col-md-5': isSubFormArrayModal}"
            formControlName="type" [options]="phoneNumberTypeOptions"
            label="Phone no. type" idKey="id" [idOnly]="true"></gtn-dropdown>
        <gtn-checkbox *ngIf="showIsPrimary" class="col-md-1 col-sm-6 flex flex-column justify-content-center"
            formControlName="isPrimary" label="Primary" [ngClass]="{'col-md-2': isSubFormArrayModal}">
        </gtn-checkbox>
        <div *ngIf="canRemove" class="col-md-1 col-sm-6 flex flex-column justify-content-center">
            <a href="javascript:void(0)" title="Remove number">
                <mat-icon (click)="onRemovePhoneNumberClicked()">delete
                </mat-icon>
            </a>
        </div>
    </div>
</form>
