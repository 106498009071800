import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'yesNoPipe'
})
export class YesNoPipe implements PipeTransform {
    transform(value: Boolean): string {
        let response = '';

        if (value === true) {
            response = 'Yes';
        } else if (value === false) {
            response = 'No';
        }

        return response;
    }
}
