<gtn-template-view [cardMode]="cardMode">
    <gtn-template-view-content>
        <ng-container *ngIf="value?.length">
            <div class="row">
                <div class="col-12">
                    Matching individuals
                </div>
            </div>
        </ng-container>
        <ng-container *ngFor="let i of value">
            <div class="row active" (click)="onIndividualClicked(i)">
                <div class="col-md-6">
                    <label class="list-label">Name</label>
                    <div class="data-field">
                        {{i.lastName}}, {{i.firstName}}
                    </div>
                    <div class="email-subtext">
                        {{i.emailAddress}}
                    </div>
                </div>
                <div class="col-md-6">
                    <label class="list-label">Type</label>
                    <div class="data-field">
                       <span *ngFor="let type of i.individualTypes">
                           {{type.type | enum : individualTypesEnum}}
                       </span>
                    </div>
                </div>
            </div>
        </ng-container>
    </gtn-template-view-content>
</gtn-template-view>
