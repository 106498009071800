import { ApplicationsEnum } from 'app/nexus-shared';
import { IndustryTypesEnum } from 'app/nexus-shared/domain/contacts/enums/industry-types.enum';
import { PhoneNumberModel } from 'app/nexus-shared/models/phone-number.model';
import { CostCenterModel } from 'app/nexus-shared/domain/contacts/models/cost-center.model';
import { CompanyTypeModel } from 'app/nexus-shared/domain/contacts/models/company-type.model';
import { AddressModel } from 'app/nexus-shared/domain/contacts/models/address.model';
import { ContactIntegrationModel } from './contact-integration.model';
import { ServiceAreaTypeModel } from './service-area-type.model';
import { RegionTypesEnum } from 'app/nexus-shared/domain/contacts/enums/region-types.enum';
import { CommonBaseAuditModel } from 'app/nexus-shared/domain/contacts/models/common-base-audit.model';

export class CompanyModel extends CommonBaseAuditModel {
    companyKey?: string;
    myGTNPortalCompanyKey?: string;
    name?: string;
    shortName?: string;
    legalName?: string;
    emailDomain?: string;
    industryType?: IndustryTypesEnum;
    isSecRegistered?: boolean = null;
    secTickerSymbol?: string;
    region?: RegionTypesEnum;
    companyTypes?: CompanyTypeModel[];
    applicationSourceType?: ApplicationsEnum;
    companyToApplications?: ApplicationsEnum[];
    costCenters?: CostCenterModel[];
    phoneNumbers?: PhoneNumberModel[];
    addresses?: AddressModel[];
    contactIntegrations?: ContactIntegrationModel[];
    serviceAreaTypes?: ServiceAreaTypeModel[];

    constructor(company: CompanyModel = null) {
        super(company);
        this.companyKey = company?.companyKey ?? null;
        this.myGTNPortalCompanyKey = company?.myGTNPortalCompanyKey ?? null;
        this.name = company?.name ?? null;
        this.shortName = company?.shortName ?? null;
        this.legalName = company?.legalName ?? null;
        this.emailDomain = company?.emailDomain ?? null;
        this.industryType = company?.industryType ?? null;
        this.isSecRegistered = company?.isSecRegistered ?? null;
        this.secTickerSymbol = company?.secTickerSymbol ?? null;
        this.region = company?.region ?? null;
        this.companyTypes = company?.companyTypes ?? null;
        this.applicationSourceType = company?.applicationSourceType ?? null;
        this.companyToApplications = company?.companyToApplications ?? null;
        this.costCenters = company?.costCenters ? CompanyModel.initializeCostCentersNumbers(company?.costCenters) : [];
        this.phoneNumbers = company?.phoneNumbers ? CompanyModel.initializePhoneNumbers(company.phoneNumbers) : [];
        this.addresses = company?.addresses ? CompanyModel.initializeAddresses(company.addresses) : [];
        this.contactIntegrations = company?.contactIntegrations ? CompanyModel.initializeContactIntegrations(company.contactIntegrations) : [];
        this.serviceAreaTypes = company?.serviceAreaTypes ? CompanyModel.initializeServiceAreaTypes(company?.serviceAreaTypes) : [];
    }

    private static initializeAddresses(addresses) {
        return addresses.map(a => {
            return new AddressModel(a);
        });
    }

    private static initializePhoneNumbers(numbers) {
        return numbers.map(n => {
            return new PhoneNumberModel(n);
        });
    }

    private static initializeCostCentersNumbers(costCenters: CostCenterModel[]) {
        return costCenters.map(n => {
            return new CostCenterModel(n);
        });
    }

    private static initializeContactIntegrations(contactIntegrations: ContactIntegrationModel[]) {
        return contactIntegrations.map(n => {
            return new ContactIntegrationModel(n);
        });
    }

    private static initializeServiceAreaTypes(serviceAreaTypes: ServiceAreaTypeModel[]) {
        return serviceAreaTypes.map(n => {
            return new ServiceAreaTypeModel(n);
        });
    }
}
