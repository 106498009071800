import { Component, forwardRef, Input, OnChanges, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LocationService } from 'app/nexus-core/services/location.service';
import { LocationModel } from 'app/nexus-shared/domain/locations/index';
import { SimpleChangesTyped } from 'app/nexus-shared/models/simple-changes-typed.type';
import { BaseLocationSelectComponent } from 'app/nexus-shared/domain/locations/components/select/base-location-select.component';

@Component({
    selector: 'gtn-city-select',
    templateUrl: '../../../../components/controls/components/dropdown/dropdown.component.html',
    styleUrls: ['../../../../components/controls/components/dropdown/dropdown.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CitySelectComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => CitySelectComponent),
            multi: true
        }
    ]
})
export class CitySelectComponent extends BaseLocationSelectComponent implements ControlValueAccessor, OnChanges, OnInit {
    @Input() label: string = 'City';
    @Input() countryId: number = null;
    @Input() stateId: number = null;
    @Input() customOptions: LocationModel | LocationModel[];
    @Input() customOptionsLocation: 'top' | 'bottom';

    constructor(private locationService: LocationService) {
        super();
    }

    ngOnInit() {
        // overriding the base onInit so initOptions() doesn't get called when it's not supposed to
    }

    ngOnChanges(changes: SimpleChangesTyped<this>): void {
        super.ngOnChanges(changes);
        if (changes.stateId && changes.stateId.previousValue !== changes.stateId.currentValue && this.countryId && this.stateId) {
            this.options = null;
            this.initOptions();
        }
    }

     dataFetch = () => this.locationService.listCities(this.countryId, this.stateId);

     setExtraOptions(): void {
        if (this.customOptions) {
            let customOptions: LocationModel[];

            if (this.customOptions && !Array.isArray(this.customOptions)) {
                customOptions = [this.customOptions];
            } else {
                customOptions = this.customOptions as Array<LocationModel>;
            }

            if (this.customOptionsLocation === 'top') {
                this.options = customOptions.concat(this.options);
            } else {
                this.options.concat(customOptions);
            }
        }
    }
}
