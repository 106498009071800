import { SelectModel } from 'app/nexus-shared/index';

export class UserInputModalComponentInputsModel {
    mainHeaderText: string;
    controlLabel: string;
    options: SelectModel[];
    message?: string;
    okButtonText?: string = 'Ok';
    cancelButtonText?: string = 'Cancel';
}
