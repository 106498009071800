<ng-container *ngIf="value">
    <div class="row">
        <div class="col-md-6">
            <label class="list-label">Last modified by</label>
            <div class="data-field">
                {{ value.lastModifiedByUser?.fullName || noValueText }}
            </div>
        </div>
        <div class="col-md-6">
            <label class="list-label">Last modified on</label>
            <div class="data-field">
                {{ (value.lastModifiedByUser?.changeDateTime | gtnDate: true) || noValueText }}
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <label class="list-label">Created by</label>
            <div class="data-field">
                {{ value.createdByUser?.fullName || noValueText }}
            </div>
        </div>
        <div class="col-md-6">
            <label class="list-label">Created on</label>
            <div class="data-field">
                {{ (value.createdByUser?.changeDateTime | gtnDate: true) || noValueText }}
            </div>
        </div>
    </div>
</ng-container>
