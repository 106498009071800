<form [formGroup]="formGroupRef">
    <div class="row">
        <div class="col-md-3">
            <gtn-template-dynamic-card>
                <ng-template #header>
                    Sections
                </ng-template>
                <ng-template #content>
                    <ng-container
                        *ngFor="let section of visibleSections; index as i; last as isLast">
                        <div (click)="onSectionClicked(section)"
                            class="div-link section">{{ section.title }}
                        </div>
                    </ng-container>
                </ng-template>
            </gtn-template-dynamic-card>
        </div>
        <div class="col-md-9">
            <gtn-template-dynamic-card [mode]="cardMode">
                <ng-template #content>
                    <ng-container
                        *ngFor="let formGroup of sectionsFormArray.controls; index as i; last as isLast">
                        <ng-container
                            *ngIf="selectedSection.key === getFormGroupFromAbstractControl(formGroup)?.value?.key">
                            <gtn-section-form [formGroupRef]="getFormGroupFromAbstractControl(formGroup)"
                                [isTemplateMode]="false"
                                [questionnaireDropdownService]="questionnaireDropdownService"
                                [isEditMode]="true"
                                [sectionKey]="formGroup.value?.key"
                                [isSubForm]="true"></gtn-section-form>
                        </ng-container>
                    </ng-container>
                </ng-template>
            </gtn-template-dynamic-card>
        </div>
    </div>
</form>
