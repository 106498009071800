import { Pipe, PipeTransform } from '@angular/core';
import { LocationHelper } from 'app/nexus-core/helpers/location.helper';
import { LocationModel } from 'app/nexus-shared/domain/locations/models';

@Pipe({
    name: 'locationNames'
})
export class LocationNamesPipe implements PipeTransform {
    transform(locations: LocationModel[], separator: string = ', ', sort = true) {
        return LocationHelper.getLocationNames(locations, false, separator, sort);
    }
}
