<gtn-template-view [cardMode]="cardMode">
    <gtn-template-view-content>
        <ng-container *ngIf="viewMode === viewModes.All || viewMode === viewModes.Addresses">
            <div class="row">
                <div class="col-md-3 col-sm-6">
                    <label class="list-label">Type</label>
                    <div class="data-field">
                        {{(value.type | enum: addressTypeEnum) || noValueText}}
                    </div>
                </div>
                <div class="col-md-3 co-sm-6" *ngIf="value.type !== addressTypeEnum.Physical">
                    <label class="list-label">ATTN Line</label>
                    <div class="data-field">
                        {{value.attnLine || noValueText}}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3 col-sm-6">
                    <label class="list-label">Address line 1</label>
                    <div class="data-field">
                        {{value.addressLineOne}}
                    </div>
                </div>
                <div class="col-md-3 co-sm-6">
                    <label class="list-label">Address line 2</label>
                    <div class="data-field">
                        {{value.addressLineTwo ?? noValueText}}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <label class="list-label">City</label>
                    <div class="data-field">
                        {{value.city ?? noValueText}}
                    </div>
                </div>
                <div class="col-md-3">
                    <label class="list-label">State</label>
                    <div class="data-field">
                        {{value.stateName ?? noValueText}}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <label class="list-label">Postal code</label>
                    <div class="data-field">
                        {{value.postalCode}}
                    </div>
                </div>
                <div class="col-md-3">
                    <label class="list-label">Country</label>
                    <div class="data-field">
                        {{value.countryName ?? noValueText}}
                    </div>
                </div>
            </div>
        </ng-container>
    </gtn-template-view-content>
</gtn-template-view>
