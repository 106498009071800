<button (click)="onAddContactIntegrationClicked()" color="primary" mat-flat-button>Add Integration
</button>
<div *ngFor="let formGroup of formArray.controls; index as i; last as isLast">
    <ng-container [formGroup]="getFormGroupFromAbstractControl(formGroup)">
        <div class="row controls">
            <gtn-dropdown class="col-md-4 col-sm-6" formControlName="type" [options]="integrationTypeOptions"
                [idOnly]="true" idKey="id" displayKey="value">
            </gtn-dropdown>
            <gtn-input class="col-md-4 col-sm-6" formControlName="integrationValue" label="Value"></gtn-input>
        </div>
    </ng-container>
</div>
