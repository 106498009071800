import { Component, Input, OnInit } from '@angular/core';
import { ApplicationConfigurationService } from 'app/nexus-core';
import { AuthenticationService } from 'app/nexus-core/services/authentication.service';
import { RoutingService } from 'app/nexus-routing/services/routing.service';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { environment } from 'environments/environment';

@Component({
    selector: 'gtn-error-page',
    templateUrl: './error-page.component.html',
    styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent extends BaseComponent implements OnInit {
    @Input() message: string;
    showModal = false;
    supportAddress = environment().supportEmailAddress;

    constructor(
        public routingService: RoutingService,
        public applicationConfigurationService: ApplicationConfigurationService,
        public authenticationService: AuthenticationService
    ) {
        super();
    }

    ngOnInit() {
        if (!this.message) {
            this.message = 'Something went wrong.';
        }
    }

    onLogoutClicked() {
        this.authenticationService.logout();
    }

    onBackToAppClicked() {
        this.applicationConfigurationService.errorPageTrigger$.next(null);
        this.routingService.navigateToPreviousUrl();
    }
}
