export class ColorConstants {
    public static readonly danger: string = '#d34853';
    public static readonly warning: string = '#fce68b';
    public static readonly success: string = '#7ebc71';
    public static readonly deactivated: string = 'rgba(240, 128, 128, .2)';
    public static readonly deletePending: string = 'rgba(240, 128, 128, .4)';
    public static readonly editPending: string = 'rgba(97, 194, 80, .4)';
    public static readonly borderColor: string = '#d1d1d1';
    public static readonly lightBlue: string = 'rgba(124, 181, 236, 0.5)';
    public static readonly gtnBlue: string = '#003478';
    public static readonly gtnGreen: string = '#61c250';
    public static readonly lightOrange: string = 'rgba(247, 191, 144, .2)';
    public static readonly orange: string = '#f79646';
    public static readonly midnightblue2: string = '#000033';
    public static readonly lightGray: string = '#eff1f5';
    public static readonly white: string = '#ffffff';
    public static readonly backgroundGray: string = '#f7f8fa';
}
