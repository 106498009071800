import { Component, Input } from '@angular/core';
import { IndividualGtnOrganizerModel } from 'app/nexus-shared/domain/questionnaires/models/individual-gtn-organizer.model';
import { BaseAssignQuestionnaireFormComponent } from 'app/nexus-shared/domain/questionnaires/components/forms/base-assign-questionnaire-form.component';
import { IndividualSearchModel } from 'app/nexus-shared/domain/contacts/models/individual-search.model';
import { IndividualTypesEnum } from 'app/nexus-shared/domain/contacts/enums/individual-types.enum';

@Component({
    selector: 'gtn-individual-gtn-organizer-form',
    templateUrl: './individual-gtn-organizer-form.component.html',
    styleUrls: ['./individual-gtn-organizer-form.component.scss']
})
export class IndividualGtnOrganizerFormComponent extends BaseAssignQuestionnaireFormComponent<IndividualGtnOrganizerModel> {
    @Input() disableIndividualSearch: boolean = false;
    gatekeeperIndividualSearchModel: IndividualSearchModel = {
        individualTypes: [IndividualTypesEnum.GtnEmployee]
    };

    constructor() {
        super();
    }

}
