import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'weekday'
})
export class WeekdayPipe implements PipeTransform {

    transform(value: Date | number): string | boolean {
        let weekday = '';

        if (value instanceof Date) {
            const day = value.getDay();
            return day < 6 && day > 0;
        } else if (typeof value === 'number') {
            switch(value) {
                case 0:
                    weekday = 'Sunday';
                    break;
                case 1:
                    weekday = 'Monday';
                    break;
                case 2:
                    weekday = 'Tuesday';
                    break;
                case 3:
                    weekday = 'Wednesday';
                    break;
                case 4:
                    weekday = 'Thursday';
                    break;
                case 5:
                    weekday = 'Friday';
                    break;
                case 6:
                    weekday = 'Saturday';
                    break;
                default:
                    weekday = 'Invalid day';
            }
        }

        return weekday;
    }

}
