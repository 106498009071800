<div class="file-backdrop">
</div>
<div *ngIf="file"
    class="file-view-controls">
    <div class="file-view-header">
        <div class="left-file-view-header">
            <div class="icon-button-fa"
                (click)="onCloseClicked()"
                [matTooltip]="'Close'">
                <i class="fal fa-arrow-left"></i>
            </div>
            <div class="header-item item-container">
                <span><i class="mr-2"
                        [ngClass]="file.iconClass"></i>{{file.name}}</span>
            </div>
        </div>
        <div class="right-file-view-header">
            <ng-content select="gtn-file-view-header-center-controls"></ng-content>
            <div *ngIf="enablePrinting && filePrintData"
                class="mat-tooltip-trigger icon-button-fa print"
                matTooltip="Print"
                (click)="printFile()">
                <i class="fa fa-print"></i>
            </div>
            <div *ngIf="isDownload"
                class="mat-tootip-trigger icon-button-fa"
                matTooltip="Download"
                (click)="onDownloadClicked()">
                <i class="fas fa-down-to-line"></i>
            </div>
            <div *ngIf="enableSearch && file.type === fileTypesEnum.PDF"
                class="mat-tooltip-trigger icon-button-fa search-icon"
                matTooltip="Search"
                [ngClass]="{'active': showSearch}"
                (click)="onSearchIconClicked()">
                <i class="fas fa-search"></i>
            </div>
            <ng-content select="gtn-file-view-header-controls"></ng-content>
        </div>
    </div>

    <div #fileSearch
        *ngIf="showSearch"
        class="gtn-file-search">
        <gtn-input #searchInput
            type="text"
            id="gtn-file-search-input"
            (change)="onSearchTextChanged($event)"
            [value]="searchText"></gtn-input>
        <div *ngIf="searchMatchDetail"
            class="gtn-file-search-match-detail">
            {{searchMatchDetail.currentMatchId}}/{{searchMatchDetail.matchCount}}
        </div>
        <div class="gtn-file-search-divider">&nbsp;</div>
        <div class="gtn-file-search-navigators">
            <div class="icon-button-fa search-chevron"
                (click)="onSearchPreviousClicked()">
                <i class="fa-solid fa-chevron-up"></i>
            </div>
            <div class="icon-button-fa search-chevron"
                (click)="onSearchNextClicked()">
                <i class="fa-solid fa-chevron-down"></i>
            </div>
        </div>
    </div>

    <div class="file-view-container"
        [ngClass]="{'extra-padding': isPadding}">
        <ng-container *ngIf="file.type === fileTypesEnum.PDF">
            <gtn-pdf-file-view [src]="file.blobSrc"
                [pdfViewPadding]="44"
                [enablePDFOutline]="enablePDFOutline"
                [enableThumbnails]="enablePDFThumbnails"
                [searchModel]="searchModel"
                [signatureValidationErrors]="signatureValidationErrors"
                [isBlob]="file.isBlobSrc"
                [isAgreement]="isAgreement"
                (pdfLoadComplete)="onFileLoadComplete()"
                (pdfPrintDataUpdate)="onPrintDataUpdated($event)"
                (pdfSearchComplete)="onSearchUpdated($event)"
                (outsideCloseClick)="onCloseClicked()"></gtn-pdf-file-view>
        </ng-container>
        <ng-container *ngIf="file.type === fileTypesEnum.Image">
            <gtn-image-file-view [src]="file.blobSrc"
                [isBlob]="file.isBlobSrc"
                (outsideCloseClick)="onCloseClicked()"></gtn-image-file-view>
        </ng-container>
        <ng-container *ngIf="file.type === fileTypesEnum.Text">
            <gtn-object-file-view [src]="file.blobSrc"
                [isBlob]="file.isBlobSrc"
                (outsideCloseClick)="onCloseClicked()"></gtn-object-file-view>
        </ng-container>
    </div>
</div>
