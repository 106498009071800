import { IndividualInterface } from 'app/nexus-shared/interfaces/individual.interface';
import { QuestionnaireModel } from 'app/nexus-shared/domain/questionnaires/models/questionnaire.model';
import { CommonBaseAuditModel } from 'app/nexus-shared/domain/contacts/models/common-base-audit.model';

export class IndividualGtnOrganizerModel extends CommonBaseAuditModel {
    individualGtnOrganizerKey: string;
    gateKeeper: IndividualInterface;
    questionnaire: QuestionnaireModel;

    constructor(model: IndividualGtnOrganizerModel = null) {
        super(model);
        this.individualGtnOrganizerKey = model?.individualGtnOrganizerKey ?? null;
        this.gateKeeper = model?.gateKeeper ?? null;
        this.questionnaire = model?.questionnaire ? new QuestionnaireModel(model.questionnaire) : new QuestionnaireModel();
    }
}
