import { Pipe, PipeTransform } from '@angular/core';
import { LocationCompositeModel } from 'app/nexus-shared/domain/locations/models';
import { LocationHelper } from 'app/nexus-core/helpers/location.helper';

@Pipe({
    name: 'locationName'
})
export class LocationNamePipe implements PipeTransform {
    transform(location: LocationCompositeModel, includeCity: boolean = true) {
        return LocationHelper.getLocationNameFromCompositeModel(location, includeCity);
    }
}
