import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppComponent } from 'app/app.component';
import { NexusCoreModule } from 'app/nexus-core/nexus-core.module';
import { NexusRoutingModule } from 'app/nexus-routing/nexus-routing.module';
import { LoggingService } from './nexus-core/services/logging.service';
import { ExternalLandingPageComponent } from './components/external-landing-page/external-landing-page.component';
import { InternalLandingPageComponent } from './components/internal-landing-page/internal-landing-page.component';
import { LandingPageComponent } from 'app/components/landing-page/landing-page.component';
import { EnvironmentSettingsService } from 'app/nexus-core/services/environment-settings.service';
import { ApplicationInitializerService } from './nexus-core/services/application-initializer.service';
import { NexusSharedModule } from 'app/nexus-shared/nexus-shared.module';
import { AuthClientConfig, AuthModule } from '@auth0/auth0-angular';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { GTNPrivacyPolicyComponent } from 'app/nexus-core/components/gtn-privacy-policy/gtn-privacy-policy.component';
import { TermsAndConditionsComponent } from 'app/nexus-core/components/terms-and-conditions/terms-and-conditions.component';

export function init_app(appInitService: ApplicationInitializerService, authClientConfig: AuthClientConfig) {
    return async () => await appInitService.init(authClientConfig);
}

@NgModule({
    declarations: [
        AppComponent,
        ExternalLandingPageComponent,
        LandingPageComponent,
        InternalLandingPageComponent,
        ErrorPageComponent,
        GTNPrivacyPolicyComponent,
        TermsAndConditionsComponent
    ],
    imports: [
        AuthModule.forRoot(),
        BrowserAnimationsModule,
        NexusCoreModule.forRoot(),
        NexusRoutingModule,
        NexusSharedModule
    ],
    providers: [
        ApplicationInitializerService,
        EnvironmentSettingsService,
        LoggingService,
        {
            provide: APP_INITIALIZER,
            useFactory: init_app,
            deps: [
                ApplicationInitializerService,
                AuthClientConfig
            ],
            multi: true
        }
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
