<div class="verification-code-row">
    <div class="flex">
        <div class="verification-code-col" *ngFor="let i of [0, 1, 2, 3, 4, 5]">
            <input class="code-input"
                [(ngModel)]="verificationCode[i]"
                (keydown)="onKeydown(i, $event)"
                (paste)="onPaste($event)" maxlength="1"
                (ngModelChange)="onVerificationCodeChange()">
        </div>
    </div>
</div>
