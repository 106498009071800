import { Component, EventEmitter, Output } from '@angular/core';
import { SectionRowModel } from 'app/nexus-shared/domain/questionnaires/models/section-row.model';
import { BaseQuestionnaireFormComponent } from 'app/nexus-shared/domain/questionnaires/components/forms/base-questionnaire-form.component';
import { SelectListInterface } from 'app/nexus-shared/interfaces';
import { SectionRowTypesEnum } from 'app/nexus-shared/domain/questionnaires/enums/section-row-types.enum';
import { EnumHelper } from 'app/nexus-core';
import { UntypedFormArray } from '@angular/forms';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { Guid } from 'app/nexus-shared/models/guid.type';
import { QuestionnaireFormHelper } from 'app/nexus-shared/domain/questionnaires/components/forms/questionnaire-form.helper';
import { SectionColumnModel } from 'app/nexus-shared/domain/questionnaires/models/section-column.model';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

@Component({
    selector: 'gtn-section-row-form',
    templateUrl: './section-row-form.component.html',
    styleUrls: ['./section-row-form.component.scss', '../questionnaire-forms.component.scss']
})
export class SectionRowFormComponent extends BaseQuestionnaireFormComponent<SectionRowModel> {
    sectionRowTypes: SelectListInterface[];
    sectionRowTypesEnum = SectionRowTypesEnum;
    sectionRowColumnsFormArray: UntypedFormArray;

    @Output() deleteRowClick: EventEmitter<string> = new EventEmitter<string>();
    @Output() cloneRowClick: EventEmitter<void> = new EventEmitter<void>();

    protected initUIControls(): void {
        this.sectionRowTypes = EnumHelper.convertToSelectList(SectionRowTypesEnum, false, false);
    }

    protected initFormCustomizations(): void {
        this.sectionRowColumnsFormArray = this.formGroupRef.get(ObjectHelper.nameOf<SectionRowModel>('columns')) as UntypedFormArray;
    }

    onAddColumnClicked(): void {
        const newRow = new SectionColumnModel();
        newRow.key = Guid.newGuid();
        newRow.widthPercentage = 100;
        newRow.order = this.sectionRowColumnsFormArray?.length ? this.sectionRowColumnsFormArray?.length + 1 : 1;
        this.initFormArrayFormGroup(newRow, this.sectionRowColumnsFormArray, QuestionnaireFormHelper.getSectionColumnFormConfiguration());
        this.formGroupRef.markAsDirty();
    }

    onDeleteRowClicked(): void {
        this.deleteRowClick.emit(this.formGroupRef.get(ObjectHelper.nameOf<SectionRowModel>('key')).value);
        this.formGroupRef.markAsDirty();
    }

    onDeleteColumnClicked(columnKey: string): void {
        const i = this.sectionRowColumnsFormArray.controls.findIndex(x => x.value?.key === columnKey);
        this.sectionRowColumnsFormArray.removeAt(i);
    }

    onColumnDropped($event: CdkDragDrop<any>): void {
        this.moveItemInFormArray(this.sectionRowColumnsFormArray, $event.currentIndex, $event.previousIndex, ObjectHelper.nameOf<SectionColumnModel>('order'));
    }

    onCloneRowClicked(): void {
        this.cloneRowClick.emit();
    }
}
