import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { SpeedDialButtonOptionModel } from 'app/nexus-shared/components/controls/shared/models/speed-dial-button-option.model';
import { SpeedDialButtonsAnimations } from 'app/nexus-shared/components/controls/components/speed-dial-buttons/animations/speed-dial-buttons.animations';

@Component({
    selector: 'gtn-speed-dial-buttons',
    templateUrl: './speed-dial-buttons.component.html',
    styleUrls: ['./speed-dial-buttons.component.scss'],
    animations: SpeedDialButtonsAnimations
})
export class SpeedDialButtonsComponent extends BaseComponent implements OnInit, OnChanges {

    /*
    *   Relative allows consumer to position the button
    *   Fixed will place the button down in the lower right corner
    */
    @Input() controlPosition: 'fixed' | 'relative' = 'relative';
    @Input() expandDirection: 'left' | 'right' | 'above' | 'below' = 'left';
    @Input() isAllowClickOutsideToClose: boolean = true;
    @Input() buttonOptions: SpeedDialButtonOptionModel[] = [];
    @Input() speedDialButton: SpeedDialButtonOptionModel;

    expandDirectionClass: string;
    flexDirection: string;
    buttons = [];

    ngOnInit() {
        this.setFlexDirection();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.buttonOptions && this.buttons?.length) {
            this.buttons = this.buttonOptions;
        }
    }

    onButtonOptionClicked(buttonOption: SpeedDialButtonOptionModel) {
        buttonOption.click();
    }

    onExpand() {
        if (!this.buttons?.length) {
            this.buttons = this.buttonOptions;
        }
    }

    onCollapse() {
        this.buttons = [];
    }

    private setFlexDirection() {
        this.expandDirectionClass = `expand-${this.expandDirection}`;

        switch (this.expandDirection) {
            case 'left':
                this.flexDirection = 'row-reverse';
                break;
            case 'right':
                this.flexDirection = 'row';
                break;
            case 'above':
                this.flexDirection = 'column-reverse';
                break;
            case 'below':
                this.flexDirection = 'column';
                break;
        }
    }
}
