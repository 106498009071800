import { Component, Input, OnInit } from '@angular/core';
import { SectionColumnModel } from 'app/nexus-shared/domain/questionnaires/models/section-column.model';
import { SectionModel } from 'app/nexus-shared/domain/questionnaires/models/section.model';
import { BaseQuestionnaireFormComponent } from 'app/nexus-shared/domain/questionnaires/components/forms/base-questionnaire-form.component';
import { QuestionnaireEventService } from 'app/nexus-core/services/domain/questionnaires/questionnaire-event.service';
import { SectionColumnActionModel } from 'app/nexus-shared/domain/questionnaires/models/section-column-action.model';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { SectionActionEventModel } from 'app/nexus-shared/domain/questionnaires/models/section-action-event.model';

@Component({
    selector: 'gtn-column-editor-action-form',
    templateUrl: './column-editor-action-form.component.html',
    styleUrls: ['./column-editor-action-form.component.scss', '../questionnaire-forms.component.scss']
})
export class ColumnEditorActionFormComponent extends BaseQuestionnaireFormComponent<SectionColumnModel> implements OnInit {
    @Input() dropdownTypeId: number;
    actionFormGroup: UntypedFormGroup;
    previousChildSectionKey: string;
    isAction: boolean;
    sections: SectionModel[];

    constructor(private questionnaireEventService: QuestionnaireEventService) {
        super();
        this.subscriptions.add(
            this.questionnaireEventService.questionnaireSections$.subscribe(res => {
                this.sections = res.filter(x => x.key !== this.sectionKey);
            }));
    }

    ngOnInit() {
        this.sections = this.questionnaireEventService.getQuestionnaireSections().filter(x => x.key !== this.sectionKey);
        super.ngOnInit();
    }

    protected initFormCustomizations() {
        this.actionFormGroup = this.formGroupRef.get(ObjectHelper.nameOf<SectionColumnModel>('action')) as UntypedFormGroup;
        this.isAction = !!this.actionFormGroup.get(ObjectHelper.nameOf<SectionColumnActionModel>('key'))?.value ?? false;
        this.previousChildSectionKey = this.actionFormGroup.get(ObjectHelper.nameOf<SectionColumnActionModel>('childSectionKey'))?.value ?? null;
    }

    onSectionChanged(childSectionKey: string): void {
        const sectionColumnActionEvent = <SectionActionEventModel>{
            previousSectionKey: this.previousChildSectionKey,
            newSectionKey: childSectionKey,
            sectionColumnKey: this.formGroupRef.get(ObjectHelper.nameOf<SectionColumnModel>('key')).value
        };
        this.questionnaireEventService.sectionColumnAction$.next(sectionColumnActionEvent);
        this.formGroupRef.markAsDirty();
        this.previousChildSectionKey = childSectionKey;

    }

    onActionChange(): void {
        const action = new SectionColumnActionModel();
        if (this.isAction) {
            action.parentSectionColumnKey = this.formGroupRef.get(ObjectHelper.nameOf<SectionColumnModel>('key')).value;
            this.initFormGroupFromControl(action, 'action', this.formGroupRef);
            this.actionFormGroup = this.formGroupRef.get(ObjectHelper.nameOf<SectionColumnModel>('action')) as UntypedFormGroup;
        } else {
            this.initFormControl('action', new UntypedFormControl(), this.formGroupRef);
        }
    }

}
