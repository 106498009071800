<form *ngIf="formGroupRef" [formGroup]="formGroupRef">
    <div class="row">
        <div class="col-12">
            <label class="list-label">
                Parent individual
            </label>
            <div class="data-field">
                {{value.parentIndividual.fullName}}
            </div>
        </div>
    </div>
    <div class="row">
        <gtn-individual-search *ngIf="!value.individualRelationshipKey" class="col-12"
            label="Child individual"
            formControlName="childIndividual"></gtn-individual-search>
        <div class="col-12" *ngIf="value.individualRelationshipKey" >
            <label class="list-label">
                Child individual
            </label>
            <div class="data-field">
                {{value.childIndividual.fullName}}
            </div>
        </div>
    </div>
    <div class="row">
        <gtn-dropdown class="col-12" label="Relationship type" formControlName="relationshipType"
            [options]="individualRelationshipTypeOptions" [idOnly]="true"></gtn-dropdown>
    </div>
    <div *ngIf="value.relationshipType === spouseRelationshipType">
        <div class="row">
            <gtn-datepicker class="col-md-6" label="Marriage date" formControlName="marriageDate"></gtn-datepicker>
            <gtn-country-select class="col-md-6" label="Marriage location" formControlName="marriageLocationCountryId" [idOnly]="true"></gtn-country-select>
        </div>
        <div class="row">
            <gtn-slide-toggle class="col-md-12 toggle" [labelPosition]="'after'" formControlName="isDivorced" label="No longer married"></gtn-slide-toggle>
        </div>
        <div class="row">
            <gtn-datepicker *ngIf="value.isDivorced" class="col-md-6"  label="Marriage end date" formControlName="divorceDate"></gtn-datepicker>
            <gtn-dropdown class="col-md-6" *ngIf="value.isDivorced"
                label="Marriage end reason"
                formControlName="marriageEndType"
                [options]="marriageEndTypeOptions"
                [idOnly]="true">
            </gtn-dropdown>
        </div>
    </div>
    <div class="row">
        <gtn-datepicker class="col-12" label="Effective start date" formControlName="effectiveStartDate"></gtn-datepicker>
    </div>
    <div class="row">
        <gtn-datepicker class="col-12" label="Effective end date" formControlName="effectiveEndDate"></gtn-datepicker>
    </div>
</form>
