import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BaseHttpService } from 'app/nexus-core/services/index';
import { RoleDefinitionModel } from 'app/nexus-shared/domain/users/models/role-definition.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { SortingHelper } from 'app/nexus-core/helpers';

@Injectable()
export class UserRoleService {
    constructor(private baseHttpService: BaseHttpService) {
    }

    listByUserKey(userKey: string): Observable<RoleDefinitionModel[]> {
        return this.baseHttpService.get(`${this.apiUrl()}users/${userKey}`).pipe(map(res => {
            return res.sort((a, b) => SortingHelper.sortByPropertyComparer(a, b, ObjectHelper.nameOf<RoleDefinitionModel>('name')));
        }));
    }

    update(userKey: string, roleDefinitionIds: number[]): Observable<boolean> {
        return this.baseHttpService.put(`${this.apiUrl()}users/${userKey}`, roleDefinitionIds);
    }

    private apiUrl = () => environment().webAPIUrls.auth + 'userRoles/';
}
