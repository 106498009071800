import { Component } from '@angular/core';
import { ImageCarouselComponent } from 'app/nexus-shared/components/controls/components/image-carousel/image-carousel.component';

@Component({
    selector: 'gtn-image-carousel-content',
    template: '<ng-content></ng-content>'
})
export class ImageCarouselContentComponent {
    // noinspection JSUnusedLocalSymbols
    constructor(imageCarouseComponent: ImageCarouselComponent) {
        //THIS WILL THROW AN ERROR IF ITS NOT NESTED WITHIN THE carousel ELEMENT
    }
}
