<div cdkDropList #element class="items-list" [ngClass]="{ 'item-list-draggable': isDragAllowed }"
    (cdkDropListDropped)="onDropped($event)">
    <div cdkDrag [cdkDragDisabled]="!isDragAllowed" class="item-box" *ngFor="let item of value | sortBy: 'orderIndex'"
        [ngClass]="{ 'selected-item': item?.value === selectedItem?.value, 'item-box-draggable': isDragAllowed }"
        (click)="onItemClicked(item)">
        <div class="item-drag-icon" matTooltip="Reorder" *ngIf="isDragAllowed">
            <i class="fas fa-grip-lines-vertical"></i>
        </div>
        <div class="item-text" [ngClass]="{ 'has-drag': isDragAllowed }">
            <strong [matTooltip]="item.label">
                {{ item.label }}
            </strong>
        </div>
        <div *ngIf="isRemoveAllowed" class="item-action" [matTooltip]="removeButtonText"
            (click)="onRemoveItemClicked(item)">
            <i class="fa fa-times"></i>
        </div>
    </div>
</div>
