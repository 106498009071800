import { PhoneNumberTypesEnum } from 'app/nexus-shared/domain/contacts/enums/phone-number-types.enum';
import { CommonBaseAuditModel } from 'app/nexus-shared/domain/contacts/models/common-base-audit.model';

export class PhoneNumberModel extends CommonBaseAuditModel {
    phoneNumberKey?: string;
    isPrimary: boolean;
    countryCode: string;
    formattedPhoneNumber: string;
    extension?: string;
    type?: PhoneNumberTypesEnum;

    constructor(model: PhoneNumberModel = null) {
        super(model);
        this.phoneNumberKey = model?.phoneNumberKey ?? null;
        this.isPrimary = model?.isPrimary ?? null;
        this.countryCode = model?.countryCode ?? null;
        this.formattedPhoneNumber = model?.formattedPhoneNumber ?? null;
        this.extension = model?.extension ?? null;
        this.type = model?.type ?? null;
    }
}
