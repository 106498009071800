import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationsEnum } from 'app/nexus-shared/enums/applications.enum';
import { ApplicationConfigurationModel } from 'app/nexus-shared/models/application-configuration.model';
import { environment } from 'environments/environment';
import { MenusEnum } from 'app/nexus-shared/enums/menus.enum';
import { BaseRouteApplicationConfigurationModel } from 'app/nexus-shared/models/base-route-application-configuration.model';
import { SortingHelper } from 'app/nexus-core/helpers';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { BaseRouteApplicationConfigurationHelper } from 'app/nexus-core/helpers/base-route-application-configuration.helper';
import { Title } from '@angular/platform-browser';
import { ApplicationSettingsService } from 'app/nexus-core/services/application-settings.service';
import { tap } from 'rxjs/operators';
import { ApplicationSettingsModel } from 'app/nexus-shared/models/application-settings.model';
import { AuthenticatedUserService } from 'app/nexus-core/services/authenticated-user.service';
import { EnvironmentHelper } from 'app/nexus-core/helpers/environment.helper';
import { BaseDynamicComponentModel } from 'app/nexus-shared/components/dynamic-component/base-dynamic-component.model';

@Injectable()
export class ApplicationConfigurationService {
    appChange$: BehaviorSubject<BaseRouteApplicationConfigurationModel> = new BehaviorSubject(null);
    errorPageTrigger$: Subject<string> = new Subject();
    isSearchbarVisible: boolean = true;
    isNavigatorVisible: boolean = true;
    showContactSupportModal: boolean = false;
    showForgotPasswordModal: boolean = false;
    showUpdateEmailModal: boolean = false;
    showUserImpersonationModal: boolean = false;
    showIdleWarningModal: boolean = false;
    applicationConfigurations: ApplicationConfigurationModel[] = [];
    dynamicHeaderComponent: BaseDynamicComponentModel<any> = null;
    externalApplications: {
        name: string,
        hasAuthorization: () => boolean,
        routeConfig: BaseRouteApplicationConfigurationModel,
        subtitle: string,
        message: string,
        learnMoreUrl?: string;
        image?: string
        imageTxt?: string
    }[];

    constructor(
        private applicationSettingsService: ApplicationSettingsService,
        private authenticatedUserService: AuthenticatedUserService,
        private titleService: Title,
        private router: Router,
        protected activatedRoute: ActivatedRoute
    ) {
    }

    init(): Observable<ApplicationSettingsModel> {
        return this.applicationSettingsService.init().pipe(tap(_ => {
            this.applicationConfigurations = this.applicationSettingsService.applicationSettings.applicationUIConfiguration;

            this.externalApplications = [
                {
                    name: 'portal',
                    hasAuthorization: () => this.hasApplicationAuthorization('portal'),
                    routeConfig: this.getApplicationConfig(ApplicationsEnum.Portal).navigationData,
                    subtitle: '',
                    learnMoreUrl: 'https://www.gtn.com/services/mobile-equity-compliance',
                    message: 'A client facing portal for all your tax service needs',
                    image: '../../../assets/images/mygtnportal-logo.svg'
                },
                {
                    name: 'mobileEquityCompliance',
                    hasAuthorization: () => false,
                    routeConfig: this.getApplicationConfig(ApplicationsEnum.MyGTNPortal).navigationData,
                    learnMoreUrl: 'https://www.gtn.com/services/mobile-equity-compliance',
                    subtitle: '',
                    message: 'Effectively manage your company\'s mobile equity compliance risks worldwide via a personalized process tailored to your program with ongoing support as your needs change.',
                    imageTxt: 'Mobile Equity Compliance'
                },
                {
                    name: 'mobileWorkforceManagement',
                    hasAuthorization: () => false,
                    routeConfig: this.getApplicationConfig(ApplicationsEnum.MyGTNPortal).navigationData,
                    learnMoreUrl: 'https://www.gtn.com/services/mobile-equity-compliance',
                    subtitle: '',
                    message: 'Our Mobile Workforce Management solution provides a seamless approach to managing the risks and requirements related to your mobile employees, remote workers, and business travelers.',
                    imageTxt: 'Mobile Workforce Management'
                },
                {
                    name: 'mobilityTaxComplianceConsulting',
                    hasAuthorization: () => false,
                    routeConfig: this.getApplicationConfig(ApplicationsEnum.MyGTNPortal).navigationData,
                    learnMoreUrl: 'https://www.gtn.com/services/mobile-equity-compliance',
                    subtitle: '',
                    message: 'With world-class coverage, technology, and resources, GTN manages your tax compliance and consulting needs related to your mobile population wherever they are.',
                    imageTxt: 'Mobility Tax Compliance and Consulting'
                },
                {
                    name: 'privateClientServices',
                    hasAuthorization: () => false,
                    routeConfig: this.getApplicationConfig(ApplicationsEnum.MyGTNPortal).navigationData,
                    subtitle: '',
                    message: 'Navigating tax laws as a mobile or remote employee can be overwhelming. As your global tax provider, we\'ll take care of those details, allowing you to focus on the many other items on your to-do list.',
                    imageTxt: 'Private Client Services'
                },
            ];

            this.setApplicationTitle();
        }));
    }

    getApplicationConfig(applicationId: ApplicationsEnum): ApplicationConfigurationModel {
        return this.applicationConfigurations.find(app => app.applicationId === applicationId);
    }

    getApplicationLogo(applicationId: ApplicationsEnum): string {
        let file: string = this.applicationConfigurations.find(app => app.applicationId === applicationId)?.navigationData?.menu?.img?.fileName;
        file = file ?? 'gtn-logo-circle.svg';
        if (file) {
            return `${environment().publicBlobStorageUrl}logos/${file}`;
        }
        return null;
    }

    getApplicationConfigByRoute(): BaseRouteApplicationConfigurationModel {
        if (window.location.href.indexOf('/#') !== -1) {
            const currentUrl = window.location.href.split('/#')[1].split('/')[1];

            if (!currentUrl) {
                return null;
            }

            return this.applicationConfigurations.find(app => app.navigationData?.routing?.relativeUrl?.indexOf(currentUrl) > -1)?.navigationData;
        }

        return null;
    }

    getApplicationMenuConfiguration(): BaseRouteApplicationConfigurationModel[] {
        const configurationRoutes = [];
        const applications = this.applicationConfigurations || [];

        for (const application of applications) {
            if (application && application.navigationData?.menu && !application.navigationData?.menu.isHidden) {
                application.navigationData.childRoutes.sort((a, b) => {
                    return SortingHelper.sortByPropertyComparer(a.menu, b.menu, 'order');
                });

                configurationRoutes.push(application.navigationData);
            }
        }

        return configurationRoutes.sort((a, b) => {
            return SortingHelper.sortByPropertyComparer(a.menu, b.menu, 'order');
        });
    }

    redirectToRoute(routeConfig: BaseRouteApplicationConfigurationModel): void {
        if (routeConfig) {
            if (this.isSameOrigin(routeConfig.routing.fullUrl) || EnvironmentHelper.isLocalhost()) {
                this.router.navigate([routeConfig.routing.relativeUrl]);
            } else {
                window.location.href = routeConfig.routing.fullUrl;
            }
        } else {
            this.redirectToDefaultRoute();
        }
    }

    redirectToDefaultRoute(menuEnum: MenusEnum = MenusEnum.Landing): void {
        this.redirectToRoute(this.getApplicationMenuConfigurationByMenuId(menuEnum));
    }

    redirectToExternalApplication(appName: string) {
        const application = this.externalApplications.find(a => a.name.toLowerCase() === appName.toLowerCase());

        if (application.hasAuthorization()) {
            this.redirectToRoute(application.routeConfig);
        } else {
            window.open(application.routeConfig.learnMoreUrl || application.learnMoreUrl || environment().externalUrls.gtn);
        }
    }

    redirectToUrl(url: string): void {
        if (this.isSameOrigin(url)) {
            this.router.navigate([url.split('#')[1]]);
        } else {
            window.location.href = url;
        }
    }

    isSameOrigin(url: string): boolean {
        if (!url) {
            return false;
        }

        return url.split('/#')[0] === window.location.href.split('/#')[0];
    }

    getApplicationMenuConfigurationByMenuId(menuId: MenusEnum): BaseRouteApplicationConfigurationModel {
        for (const applicationId in ApplicationsEnum) {
            if (!isNaN(Number(applicationId))) {
                const application = this.applicationConfigurations.find(app => app.applicationId === +applicationId);
                if (application) {
                    if (application.navigationData.coreMenuId === menuId) {
                        return application.navigationData;
                    } else if (application.navigationData?.childRoutes) {
                        const foundMenuItem = application.navigationData.childRoutes.find(menu => menu.coreMenuId === menuId);

                        if (foundMenuItem) {
                            return foundMenuItem;
                        }
                    }
                }
            }
        }

        return null;
    }

    setApplicationTitle(title: string = null): void {
        const application = this.getApplicationConfigByRoute();
        this.titleService.setTitle(environment().envPrefix.toUpperCase() + (title || application?.menu?.title || environment().appName || 'GTN Nexus'));
    }

    setActiveConfiguration(routeConfig?: BaseRouteApplicationConfigurationModel) {
        let navigation: BaseRouteApplicationConfigurationModel[];

        if (!routeConfig) {
            routeConfig = this.getApplicationConfigByRoute();
        }

        const findNavigation = function (route) {
            if (route.snapshot.data.navigation) {
                navigation = route.snapshot.data.navigation;
            }

            if (route.children.length > 0) {
                route.children.map(child => {
                    findNavigation(child);
                });
            }
        };

        findNavigation(this.activatedRoute);

        if (navigation) {
            BaseRouteApplicationConfigurationHelper.sortByMenuItems(navigation);
            routeConfig.childRoutes = navigation;
        }

        if (this.authenticatedUserService.user !== null && routeConfig?.childRoutes) {
            routeConfig.childRoutes = routeConfig.childRoutes?.filter(t => !t.authorization || this.authenticatedUserService.hasAuthorization(t.authorization));
        }

        this.setApplicationTitle();

        this.appChange$.next(routeConfig);
    }

    validateRouteOrRedirect(route: string): void {
        if (!EnvironmentHelper.isLocalhost() && route) {
            const currentApplication = this.getApplicationConfigByRoute();

            if (!currentApplication) {
                this.redirectToDefaultRoute();
            }

            if (!currentApplication || (currentApplication.routing?.fullUrl && currentApplication.routing?.fullUrl.split('#')[1] && !`#${route}`.toLowerCase().startsWith(currentApplication.routing.fullUrl.toLowerCase()))) {
                window.location.href = currentApplication.routing.fullUrl;
            }
        }
    }

    private hasApplicationAuthorization(appName: string) {
        const application = this.externalApplications.find(a => a.name.toLowerCase() === appName.toLowerCase()).routeConfig;

        if (!application.authorization?.length) {
            return true;
        }

        return this.authenticatedUserService.hasAuthorization(application.authorization);
    }

    showSearchBar(): void {
        this.isSearchbarVisible = true;
    }

    hideSearchBar(): void {
        this.isSearchbarVisible = false;
    }

    showNavigator(): void {
        this.isNavigatorVisible = true;
    }

    hideNavigator(): void {
        this.isNavigatorVisible = false;
    }

    showDynamicHeaderComponent<T>(dynamicHeaderComponent: BaseDynamicComponentModel<T>): void {
        this.dynamicHeaderComponent = dynamicHeaderComponent;
    }

    hideDynamicHeaderComponent(): void {
        this.dynamicHeaderComponent = null;
    }
}

