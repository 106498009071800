import { Component } from '@angular/core';
import { TemplateCardComponent } from 'app/nexus-shared/components/templates/template-card/template-card.component';

@Component({
    selector: 'gtn-template-card-header',
    template: '<ng-content></ng-content>'
})
export class TemplateCardHeaderComponent {
    // noinspection JSUnusedLocalSymbols
    constructor(templateCardComponent: TemplateCardComponent) {
        // THIS WILL THROW AN ERROR IF ITS NOT NESTED WITHIN PARENT
    }
}
