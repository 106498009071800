import { Injectable } from '@angular/core';
import { DocumentModel } from 'app/nexus-shared/domain/documents/models/document.model';
import { FolderModel } from 'app/nexus-shared/domain/documents/models/folder.model';
import { Observable } from 'rxjs';
import { SearchHelper } from 'app/nexus-core/helpers';
import { DocumentsHelper } from 'app/nexus-core/helpers/documents.helper';
import { FolderService } from 'app/nexus-core/services/domain/documents/folder.service';
import { FolderSearchModel } from 'app/nexus-shared/domain/documents/models/folder-search.model';
import { ClientDocumentModel } from 'app/nexus-shared/domain/documents/models/client-document.model';
import { ClientFolderModel } from 'app/nexus-shared/domain/documents/models/client-folder.model';
import { SearchResultIconEnum, SearchResultModel } from 'app/nexus-shared/components/controls';
import { Router } from '@angular/router';
import { AreaTypesEnum } from 'app/nexus-shared/domain/documents/enums/area-types.enum';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { DocumentsRoutingConfigurationConstants } from 'app/modules/documents/routing/documents-routing-configuration.constants';
import { BaseCoreGlobalSearchService } from 'app/nexus-core/services/base-core-global-search.service';
import { DocumentFolderService } from 'app/nexus-core/services/domain/documents/document-folder.service';

@Injectable()
export class DocumentHelperService extends BaseCoreGlobalSearchService<FolderModel[], FolderModel> {
    constructor(
        private folderService: FolderService,
        private documentFolderService: DocumentFolderService,
        private router: Router
    ) {
        super();
    }

    getSearchResults(searchText: string): Observable<FolderModel[]> {
        const folderSearch = new FolderSearchModel();
        folderSearch.searchTerm = searchText;
        folderSearch.includeCompany = true;
        folderSearch.includeIndividual = true;

        return this.folderService.search(folderSearch);
    }

    handleSearchResults(results: FolderModel[], searchText: string): SearchResultModel<void, FolderModel>[] {
        const folderSearchResults = results.map((folder: FolderModel) => {
            return {
                id: folder.folderKey,
                name: folder.name,
                subName: DocumentsHelper.getClientName(folder.clientFolder),
                type: null,
                iconClass: SearchResultIconEnum.folder,
                resultObject: folder,
                rank: 1
            } as SearchResultModel<void, FolderModel>;
        });
        return SearchHelper.orderSearchResults(folderSearchResults, searchText, []);
    }

    protected onResultClicked(searchResult: SearchResultModel<any, any>) {
        if (searchResult?.resultObject) {
            this.routeToFolderDocument(searchResult.resultObject);
        }
    }

    //the ultimate router
    routeToFolderDocument(entity: FolderModel | DocumentModel, isGetIndividualCompany: boolean = false): void {
        if (entity.areaType !== AreaTypesEnum.Clients) {
            const routeConfig = DocumentsHelper.getAreaRoutingConfiguration(entity.areaType);
            this.router.navigate(routeConfig.routing.navigationRoute(), {
                queryParams: { folder: entity.folderKey }
            });
        } else {
            let client: ClientDocumentModel | ClientFolderModel;
            if (entity.hasOwnProperty(ObjectHelper.nameOf<DocumentModel>('clientDocument'))) {
                entity = entity as DocumentModel;
                client = entity.clientDocument;
            } else {
                entity = entity as FolderModel;
                client = entity.clientFolder;
            }
            const clientFolder = new ClientFolderModel();
            clientFolder.companyKey = client.companyKey;
            if (!isGetIndividualCompany || !clientFolder.companyKey) {
                clientFolder.individualKey = client.individualKey;
            }
            this.documentFolderService.getClientRootFolder(clientFolder).subscribe(res => {
                this.router.navigate(DocumentsRoutingConfigurationConstants.documentAreaConfigurations.getClient().routing.navigationRoute(res.folderKey), {
                    queryParams: { folder: entity.folderKey !== res.folderKey && !isGetIndividualCompany ? entity.folderKey : null }
                });
            });

        }
    }
}
