import { Injectable } from '@angular/core';
import { EnvironmentSettingsService } from 'app/nexus-core/services/environment-settings.service';
import { LoggingService } from './logging.service';
import { AuthClientConfig } from '@auth0/auth0-angular';
import { environment } from 'environments/environment';
import { LicenseManager } from 'ag-grid-enterprise';
import { LocalStorageHelper, SessionStorageHelper } from 'app/nexus-core/helpers';
import { LocalStorageNameConstants, SessionStorageNameConstants } from 'app/nexus-shared';
import { ApplicationConfigurationService } from 'app/nexus-core/services/application-configuration.service';

@Injectable()
export class ApplicationInitializerService {

    constructor(
        private environmentSettingsService: EnvironmentSettingsService,
        private applicationConfigurationService: ApplicationConfigurationService,
        private loggingService: LoggingService
    ) {
    }

    public async init(authClientConfig: AuthClientConfig): Promise<void> {
        // environment variables
        await this.environmentSettingsService.appInit().toPromise();

        const sessionKey = SessionStorageHelper.get(SessionStorageNameConstants.deviceId) ?? LocalStorageHelper.get(LocalStorageNameConstants.deviceId);

        authClientConfig.set({
            domain: environment().auth.configuration.domain,
            clientId: environment().auth.configuration.clientId,
            authorizationParams: {
                redirect_uri: window.location.origin,
                gtnDeviceId: sessionKey ?? '',
                audience: environment().auth.configuration.apiIdentifier
            }
        });

        await this.applicationConfigurationService.init().toPromise();

        LicenseManager.setLicenseKey(environment().licenseKeys.agGrid);

        // subsequent calls all require environment
        this.loggingService.appInit();
    }
}
