<div class="form-body row">
    <div class="col-12 text-center">
        <div>
            <h1>Your GTN Tools</h1>
            <div class="mt-2 d-flex flex-row justify-content-center">
                <ng-container *ngFor="let app of applicationConfigurationService.externalApplications">
                    <gtn-tool-summary-panel *ngIf="app.hasAuthorization()" [isPrimaryBackground]="false"
                        class="ml-3 mr-3" [summaryTxt]="app.message" [subtitle]="app.subtitle" [imageSrc]="app.image"
                        (toolClick)="onToolClicked(app.name)">
                    </gtn-tool-summary-panel>
                </ng-container>
            </div>
        </div>
        <div class="mt-5 pt-3">
            <h2 class="other-tools">GTN Offerings</h2>
            <div class="mt-2 d-flex flex-row justify-content-center">
                <ng-container *ngFor="let app of applicationConfigurationService.externalApplications">
                    <gtn-tool-summary-panel *ngIf="!app.hasAuthorization()" [isPrimaryBackground]="true"
                        class="ml-3 mr-3" [summaryTxt]="app.message" [subtitle]="app.subtitle" [imageSrc]="app.image" [imageTxt]="app.imageTxt"
                        (toolClick)="onToolClicked(app.name)">
                    </gtn-tool-summary-panel>
                </ng-container>
            </div>
        </div>
    </div>
</div>
