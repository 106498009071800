export class ClaimsConstants {
    public static readonly userId = 'sub';
    public static readonly userKey = 'http://gtn.com/authuserkey';
    public static readonly individualKey = 'http://gtn.com/individualkey';
    public static readonly policies = 'http://gtn.com/policies';
    public static readonly emailVerified = 'email_verified';
    public static readonly email = 'email';
    public static readonly firstName = 'given_name';
    public static readonly lastName = 'family_name';
    public static readonly picture = 'picture';
    public static readonly companyKeys = 'http://gtn.com/companykeys';
    public static readonly myGTNPortalUserKey = 'http://gtn.com/mygtnportaluserkey';
    public static readonly requiresTwoFactor = 'http://gtn.com/requirestwofactor';
    public static readonly requiresAUA = 'http://gtn.com/requiresaua';
    public static readonly requiresDataAcknowledgment = 'http://gtn.com/requiresdataacknowledgment';
    public static readonly isImpersonating = 'http://gtn.com/isimpersonating';
}
