<form [formGroup]="formGroupRef" *ngIf="formGroupRef">
    <gtn-template-dynamic-card *ngIf="(viewMode === viewModes.All || viewMode === viewModes.General)" [mode]="cardMode">
        <ng-template #content>
            <div class="row">
                <div class="col-md-4">
                    <gtn-input formControlName="name" type="text" label="Company name">
                    </gtn-input>
                </div>
                <div class="col-md-4">
                    <gtn-input formControlName="shortName" type="text" label="Short name"></gtn-input>
                </div>
                <div class="col-md-4">
                    <gtn-input formControlName="legalName" type="text" label="Legal name"></gtn-input>
                </div>

            </div>
            <div class="row">
                <div class="col-md-4">
                    <gtn-input formControlName="emailDomain" type="text" label="Email domain"></gtn-input>
                </div>
                <div class="col-md-4">
                    <gtn-dropdown label="Sec status" [idOnly]="true" idKey="value"
                        displayKey="displayText"
                        formControlName="isSecRegistered" [options]="secRegisteredOptions"></gtn-dropdown>
                </div>
                <div class="col-md-4">
                    <gtn-input type="text" formControlName="secTickerSymbol" label="SEC ticker"></gtn-input>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4">
                    <gtn-multiselect formControlName="companyTypes" [options]="companyTypeOptions"
                        [displayFunc]="companyTypeDisplayFn.bind(this)" (change)="onCompanyTypeChanged($event['value'])"
                        displayKey="type" idKey="type" label="Company types">
                    </gtn-multiselect>
                </div>
                <div class="col-md-4" *ngIf="isClientType">
                    <gtn-dropdown formControlName="region" [options]="regionTypeOptions" [idOnly]="true"
                        label="Region"></gtn-dropdown>
                </div>
            </div>
            <gtn-phone-number-subform type="company" [formArray]="phoneNumbersFormArray" [isSubForm]="true">
            </gtn-phone-number-subform>
        </ng-template>
    </gtn-template-dynamic-card>
    <gtn-template-dynamic-card *ngIf="(viewMode === viewModes.All || viewMode === viewModes.AssociatedApplications)"
        [mode]="cardMode">
        <ng-template #content>
            <div class="row">
                <div class="col-md-4">
                    <gtn-my-gtn-portal-company-search *ngIf="!value?.myGTNPortalCompanyKey || !initialKey"
                        formControlName="myGTNPortalCompanyKey"
                        (searchResultClick)="onMyGTNPortalCompanySelected($event)"></gtn-my-gtn-portal-company-search>
                    <div *ngIf="value?.myGTNPortalCompanyKey && initialKey" class="mb-3">
                        <label class="list-label">Portal company</label>
                        <div class="data-field">
                            <span *ngIf="myGTNPortalCompanyName">{{ myGTNPortalCompanyName }}
                                <i *ngIf="isGtnAdmin" (click)="onRemoveMyGTNPortalLinkClicked()"
                                    class="fa fa-times-circle pointer">
                                </i>
                            </span>
                            <i *ngIf="!myGTNPortalCompanyName" class="fas fa-spinner fa-spin"></i>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </gtn-template-dynamic-card>
    <gtn-template-dynamic-card *ngIf="viewMode === viewModes.Addresses"
        [mode]="cardMode">
        <ng-template #header>
            Addresses
        </ng-template>
        <ng-template #content>
            <gtn-addresses-subform [formArray]="addressFormArray" [isSubForm]="true"></gtn-addresses-subform>
        </ng-template>
    </gtn-template-dynamic-card>
    <!--    <gtn-template-dynamic-card *ngIf="(viewMode === viewModes.All || viewMode === viewModes.CostCenters)"-->
    <!--        [mode]="cardMode">-->
    <!--        <ng-template #header>-->
    <!--            Cost Centers-->
    <!--        </ng-template>-->
    <!--        <ng-template #content>-->
    <!--            <gtn-cost-centers-subform [formArray]="costCentersFormArray" [isSubForm]="true"></gtn-cost-centers-subform>-->
    <!--        </ng-template>-->
    <!--    </gtn-template-dynamic-card>-->

</form>
