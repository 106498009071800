import { Component } from '@angular/core';
import { BaseDynamicComponentDirective } from 'app/nexus-shared/components/dynamic-form/shared';

@Component({
    selector: 'gtn-dynamic-datepicker',
    templateUrl: './dynamic-datepicker.component.html',
    styleUrls: ['./dynamic-datepicker.component.scss']
})
export class DynamicDatepickerComponent extends BaseDynamicComponentDirective {
}
