import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ValidationModel } from 'app/nexus-shared/models';

@Component({
    selector: 'gtn-validation-items',
    templateUrl: './validation-items.component.html',
    styleUrls: ['./validation-items.component.scss']
})
export class ValidationItemsComponent {
    @Input() validationModels: ValidationModel[];
    @Input() areLinksActive: boolean = true;
    @Input() canShowDelimiters: boolean = true;
    @Output() rowClick: EventEmitter<ValidationModel> = new EventEmitter();

    constructor() {
    }

    onItemClick(model: ValidationModel) {
        this.rowClick.emit(model);
    }
}
