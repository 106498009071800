import { Component, OnChanges, OnInit } from '@angular/core';
import { BaseFormComponent } from 'app/nexus-shared/components/base-component/base-form.component';
import { DropdownOptionTypeModel } from 'app/nexus-shared/domain/questionnaires/models/dropdown-option-type.model';
import { QuestionnaireFormHelper } from 'app/nexus-shared/domain/questionnaires/components/forms/questionnaire-form.helper';
import { UntypedFormArray } from '@angular/forms';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { DropdownOptionModel } from 'app/nexus-shared/domain/questionnaires/models/dropdown-option.model';
import { SimpleChangesTyped } from 'app/nexus-shared/models/simple-changes-typed.type';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

@Component({
    selector: 'gtn-dropdown-form',
    templateUrl: './dropdown-form.component.html',
    styleUrls: ['./dropdown-form.component.scss']
})
export class DropdownFormComponent extends BaseFormComponent<DropdownOptionTypeModel> implements OnInit, OnChanges {
    dropdownOptionsFormArray: UntypedFormArray;
    isFocused: boolean = false;

    ngOnChanges(changes: SimpleChangesTyped<this>): void {
        const copiedValue = JSON.parse(JSON.stringify(this.value));
        super.ngOnChanges(changes);

        if (changes.value && !changes.value.isFirstChange()) {
            this.isFocused = false;
            this.clearFormArray(this.dropdownOptionsFormArray);
            if (copiedValue.options?.length) {
                copiedValue.options.forEach(x => {
                    this.initFormArrayFormGroup(x, this.dropdownOptionsFormArray);
                });
            }
        }
    }

    ngOnInit(): void {
        const formConfiguration = QuestionnaireFormHelper.getDropdownFormConfiguration();
        super.ngOnInit(formConfiguration);
    }

    onAddClicked(): void {
        const newOption = new DropdownOptionModel();
        newOption.order = this.dropdownOptionsFormArray?.length ? this.dropdownOptionsFormArray?.length + 1 : 1;
        newOption.value = newOption.order;
        this.initFormArrayFormGroup(newOption, this.dropdownOptionsFormArray);
        this.isFocused = true;
    }

    protected initFormCustomizations(): void {
        this.dropdownOptionsFormArray = this.formGroupRef.get(ObjectHelper.nameOf<DropdownOptionTypeModel>('options')) as UntypedFormArray;
    }

    onDrop($event: CdkDragDrop<any, any>): void {
        if ($event.currentIndex !== $event.previousIndex) {
            this.moveItemInFormArray(this.dropdownOptionsFormArray, $event.currentIndex, $event.previousIndex, ObjectHelper.nameOf<DropdownOptionModel>('order'));
        }
    }

    onDeleteLabel(index: number): void {
        this.dropdownOptionsFormArray.removeAt(index);
        this.dropdownOptionsFormArray.markAsDirty();
        this.dropdownOptionsFormArray.updateValueAndValidity();
    }
}
