import { Component } from '@angular/core';
import { BaseViewComponent } from 'app/nexus-shared/components/base-component/base-view.component';
import { ContactsRoutingIndividualsConfigurationConstants } from 'app/modules/contacts/routing/contacts-routing-individuals-configuration.constants';
import { Router } from '@angular/router';
import { UserViewModel } from 'app/nexus-shared/domain/users/models/user-view.model';

@Component({
    selector: 'gtn-user-view',
    templateUrl: './user-view.component.html',
    styleUrls: ['./user-view.component.scss']
})
export class UserViewComponent extends BaseViewComponent<UserViewModel> {

    constructor(private router: Router) {
        super();
    }

    onNavigateToIndividualClicked(): void {
        this.router.navigate(ContactsRoutingIndividualsConfigurationConstants.configurations.getGeneral().routing.navigationRoute(this.value.individualKey));
    }
}
