<mat-form-field [appearance]="appearance"
    [ngClass]="{'gtn-read-only' : readonly, 'gtn-control-show-error': !formControl?.valid && !formControl?.pending && canShowValidation}">
    <mat-label>{{ label }}</mat-label>

    <mat-select class="override-mdc-styles" #element [panelClass]="isComboboxEnabled ? 'panel-class' : ''"
        [value]="_value"
        [compareWith]="compareWith" [disabled]="disabled || readonly" [multiple]="isMultiSelect"
        [typeaheadDebounceInterval]="debounceInterval" [disableOptionCentering]="isComboboxEnabled"
        (opened)="onSelectOpened()" (closed)="onSelectClosed()" (valueChange)="onValueChange($event)">
        <div class="filter mt-2" *ngIf="isComboboxEnabled">
            <span class="filter-icon">
                <i class="fa fa-search"></i>
            </span>
            <input type="text" #filter gtnDisableAutofill [formControl]="filterControl" placeholder="Filter">
        </div>
        <div class="options">
            <ng-container *ngIf="!isOptionGroups">
                <mat-option *ngIf="showBlank" id="null">{{ blankOptionText }}</mat-option>
                <mat-option *ngFor="let option of (options || [])" [value]="option"
                    [matTooltip]="tooltipDisplayFunc ? tooltipDisplayFunc(option) :  getObjectValue(option, displayKey)"
                    [matTooltipDisabled]="!isTooltip"
                    [matTooltipShowDelay]="1000"
                    [matTooltipPosition]="tooltipPosition"
                    [hidden]="filteredOptions.indexOf(option) === -1">
                    {{ getObjectValue(option, displayKey) }}
                </mat-option>
            </ng-container>
            <ng-container *ngIf="isOptionGroups">
                <ng-container *ngFor="let optionGroup of optionGroups">
                    <mat-optgroup [label]="optionGroup.label">
                        <mat-option *ngFor="let option of optionGroup.options" [value]="option"
                            [matTooltip]=" tooltipDisplayFunc ? tooltipDisplayFunc(option) : getObjectValue(option, displayKey)"
                            [matTooltipDisabled]="!isTooltip"
                            [matTooltip]="getObjectValue(option, displayKey)" [matTooltipDisabled]="!isTooltip"
                            [matTooltipShowDelay]="1000"
                            [ngClass]="{'is-hidden': filteredOptions.indexOf(option) === -1}">
                            {{ getObjectValue(option, displayKey) }}
                        </mat-option>
                    </mat-optgroup>
                </ng-container>
            </ng-container>
        </div>
    </mat-select>

    <div class="gtn-control-error-message error-message"
        *ngIf="!formControl?.valid && !formControl?.pending && canShowValidation">
        <span *ngFor="let msg of getErrorMessage()">{{ msg }}</span>
    </div>
</mat-form-field>
