import { Component, Input, OnInit } from '@angular/core';
import { BaseEnterpriseGridComponent } from 'app/nexus-shared/components/base-component/base-enterprise-grid.component';
import { IndividualRelationshipModel } from 'app/nexus-shared/domain/contacts/models/individual-relationship.model';
import { IndividualRelationshipService } from 'app/nexus-core/services/domain/contacts/individual-relationship.service';
import { IndividualSettingService } from 'app/nexus-core/services/domain/core/individual-setting.service';
import { CellClickedEvent, ColDef } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { IndividualRelationshipSearchModel } from 'app/nexus-shared/domain/contacts/models/individual-relationship-search.model';
import { AgGridColDefHelper } from 'app/nexus-core/helpers/ag-grid-col-def.helper';
import { CompanyModel, IndividualModel } from 'app/nexus-shared/domain/contacts/models';
import { map } from 'rxjs/operators';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { IndividualRelationshipTypesEnum } from 'app/nexus-shared/domain/contacts/enums/individual-relationship-types.enum';
import { CommonBaseAuditUserModel } from 'app/nexus-shared/domain/contacts/models/common-base-audit-user.model';

@Component({
    selector: 'gtn-individual-relationships-grid',
    template: `
        <gtn-template-grid [showAddButton]="canAdd" [showQuickFilter]="true" [showExportButton]="true" [gridStateKey]="gridStateKey"
            [application]="application" [gridSettings]="gridSettings" (quickFilterTextChange)="onFilterTextChanged($event)" (chipGridSettingClick)="onSavedSettingClicked($event)"
            (addButtonClick)="onAddButtonClicked()" (exportButtonClick)="onExportButtonClicked()">
            <gtn-template-grid-content>
                <ag-grid-angular [gtnHeight]="gridHeight" *ngIf="gridOptions" class="ag-theme-balham"
                    [gridOptions]="gridOptions"></ag-grid-angular>
            </gtn-template-grid-content>
        </gtn-template-grid>
        <gtn-individual-preview-modal *ngIf="showPreviewModal" [individualKey]="selectedIndividualKey" (cancelClick)="onModalClosed()"></gtn-individual-preview-modal>
    `
})
export class IndividualRelationshipsGridComponent extends BaseEnterpriseGridComponent<IndividualRelationshipModel> implements OnInit {
    @Input() individualKey: string;
    @Input() isAdmin: boolean = false;

    gridName: string = 'individual_relationship_grid';
    gridVersion: string = '1.0.1';
    showPreviewModal: boolean = false;
    selectedIndividualKey: string;

    constructor(protected individualSettingService: IndividualSettingService,
                private individualRelationshipService: IndividualRelationshipService) {
        super(individualSettingService);
    }

    ngOnInit() {
        super.ngOnInit();
    }

    onCellClicked(cellClick: CellClickedEvent) {
        if (cellClick?.colDef?.field === AgGridColDefHelper.previewIndividualColumnField) {
            this.selectedIndividualKey = cellClick.data.relationshipIndividual.individualKey;
            this.showPreviewModal = true;
            return;
        }

        if (this.isAdmin) {
            this.cellClick.emit(cellClick.data);
        }
    }

    onModalClosed(): void {
        this.showPreviewModal = false;
        this.selectedIndividualKey = null;
    }


    protected setColumnDefinitions(): ColDef[] {
        return [
            AgGridColDefHelper.getIndividualPreviewColDef(),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOfSubProperty<IndividualRelationshipGridViewModel, IndividualModel>('relationshipIndividual', 'fullName'), 'Individual'),
            AgGridColDefHelper.getYesNoColDef(ObjectHelper.nameOf<IndividualRelationshipGridViewModel>('isChildIndividual'), 'Is Parent'),
            AgGridColDefHelper.getEnumColumnDef(ObjectHelper.nameOf<IndividualRelationshipGridViewModel>('relationshipType'), 'Relationship Type', IndividualRelationshipTypesEnum),
            AgGridColDefHelper.getDateColDef(ObjectHelper.nameOf<IndividualRelationshipGridViewModel>('effectiveStartDate'), 'Start Date'),
            AgGridColDefHelper.getDateColDef(ObjectHelper.nameOf<IndividualRelationshipGridViewModel>('effectiveEndDate'), 'End Date'),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOfSubProperty<IndividualRelationshipGridViewModel, CommonBaseAuditUserModel>('createdByUser', 'fullName'), 'Created By', null, { hide: true }),
            AgGridColDefHelper.getDateTimeColDef(ObjectHelper.nameOfSubProperty<IndividualRelationshipGridViewModel, CommonBaseAuditUserModel>('createdByUser', 'changeDateTime'), 'Created On', { hide: true }),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOfSubProperty<IndividualRelationshipGridViewModel, CommonBaseAuditUserModel>('lastModifiedByUser', 'fullName'), 'Last Modified By', null, { hide: true }),
            AgGridColDefHelper.getDateTimeColDef(ObjectHelper.nameOfSubProperty<IndividualRelationshipGridViewModel, CommonBaseAuditUserModel>('lastModifiedByUser', 'changeDateTime'), 'Modified On', { hide: true }),
        ];
    }

    protected setRowData(): Observable<any[]> {
        return this.individualRelationshipService.search(<IndividualRelationshipSearchModel>{
            parentIndividualKeys: [this.individualKey],
            childIndividualKeys: [this.individualKey],
            includeAllRelationships: true
        }).pipe(map((relationships: IndividualRelationshipModel[]) => {
            return relationships.map((model: IndividualRelationshipModel) => {
                return new IndividualRelationshipGridViewModel(model, this.individualKey);
            });
        }));
    }

}


export class IndividualRelationshipGridViewModel extends IndividualRelationshipModel {
    isChildIndividual: boolean;
    relationshipIndividual: CompanyModel;

    constructor(individualRelationship: IndividualRelationshipModel = null, individualKey: string = null) {
        super(individualRelationship);
        this.isChildIndividual = individualRelationship?.childIndividual.individualKey === individualKey;
        this.relationshipIndividual = individualRelationship?.childIndividual.individualKey === individualKey ? new IndividualModel(individualRelationship?.parentIndividual) : new IndividualModel(individualRelationship.childIndividual);
    }
}
