import { Component, Input, OnInit } from '@angular/core';
import { BaseEntryComponent } from 'app/nexus-shared/components/base-component/base-entry.component';
import { DocumentsAuthModel } from 'app/nexus-shared/domain/documents/models/documents-auth.model';
import { FolderModel } from 'app/nexus-shared/domain/documents/models/folder.model';
import { FolderService } from 'app/nexus-core/services/domain/documents/folder.service';
import { concatMap, finalize } from 'rxjs/operators';
import { ViewModesEnum } from 'app/nexus-shared/enums/view-modes.enum';
import { FolderIndividualAccessService } from 'app/nexus-core/services/domain/documents/folder-individual-access.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'gtn-folder-detail-modal',
    templateUrl: './folder-detail-modal.component.html',
    styleUrls: ['./folder-detail-modal.component.scss']
})
export class FolderDetailModalComponent extends BaseEntryComponent<FolderModel> implements OnInit {
    @Input() documentUserAuth: DocumentsAuthModel;
    @Input() viewMode: ViewModesEnum;
    @Input() folderKey: string;

    originalValue: FolderModel;

    constructor(private folderService: FolderService, private folderIndividualAccessService: FolderIndividualAccessService) {
        super();
    }

    ngOnInit(): void {
        this.getFolder();
    }

    onCancelClicked(): void {
        this.cancelClick.emit();
    }

    onSaveClicked(): void {
        this.isSaving = true;
        this.saveValue().pipe(finalize(() => this.isSaving = false)).subscribe(res => {
            this.onSaveSuccess();
            this.saveClick.emit();
        }, err => {
            this.onSaveFailure(err);
        });
    }

    private getFolder(): void {
        this.folderService.get(this.folderKey).subscribe(res => {
            this.value = new FolderModel(res);
            this.originalValue = JSON.parse(JSON.stringify(this.value));
        });
    }

    private saveValue(): Observable<boolean> {
        if (this.value?.folderIndividualAccessModels?.length && JSON.stringify(this.value.folderIndividualAccessModels) !== JSON.stringify(this.originalValue.folderIndividualAccessModels)) {
            return this.folderIndividualAccessService.upsert(this.value.folderKey, this.value.folderIndividualAccessModels).pipe(concatMap(_ => {
                return this.folderService.update(this.value);
            }));
        } else {
            return this.folderService.update(this.value);
        }
    }

}
