import { Component } from '@angular/core';
import { QuestionnaireModel } from 'app/nexus-shared/domain/questionnaires/models/questionnaire.model';
import { BaseEnterpriseGridComponent } from 'app/nexus-shared/components/base-component/base-enterprise-grid.component';
import { IndividualSettingService } from 'app/nexus-core/services/domain/core/individual-setting.service';
import { QuestionnaireService } from 'app/nexus-core/services/domain/questionnaires/questionnaire.service';
import { Observable } from 'rxjs';
import { ColDef } from 'ag-grid-community';
import { AgGridColDefHelper } from 'app/nexus-core/helpers/ag-grid-col-def.helper';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { IndividualInterface } from 'app/nexus-shared/interfaces/individual.interface';
import { QuestionnaireTypesEnum } from 'app/nexus-shared/domain/questionnaires/enums/questionnaire-types.enum';
import { CommonBaseAuditUserModel } from 'app/nexus-shared/domain/contacts/models/common-base-audit-user.model';
import { ApplicationsEnum } from 'app/nexus-shared/enums';

@Component({
    selector: 'gtn-assigned-questionnaires-grid',
    templateUrl: '../../../../../components/base-component/base-grid-template.component.html'

})
export class AssignedQuestionnairesGridComponent extends BaseEnterpriseGridComponent<QuestionnaireModel> {
    gridName: string = 'assigned_questionnaires_grid';
    gridVersion: string = '1.0.0';
    application = ApplicationsEnum.Questionnaires;

    constructor(protected individualSettingService: IndividualSettingService, private questionnaireService: QuestionnaireService) {
        super(individualSettingService);
    }

    setDefaultSortModel(): void {
        this.defaultSortModel = [
            { colId: ObjectHelper.nameOfSubProperty<QuestionnaireModel, IndividualInterface>('individual', 'fullName'), sort: 'asc' }
        ];
    }

    protected setColumnDefinitions(): ColDef[] {
        return [
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOf<QuestionnaireModel>('title'), 'Title'),
            AgGridColDefHelper.getNumberColDef(ObjectHelper.nameOf<QuestionnaireModel>('year'), 'Year'),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOfSubProperty<QuestionnaireModel, IndividualInterface>('individual', 'fullName'), 'Individual'),
            AgGridColDefHelper.getEnumColumnDef(ObjectHelper.nameOf<QuestionnaireModel>('type'), 'Type', QuestionnaireTypesEnum),
            AgGridColDefHelper.getYesNoColDef(ObjectHelper.nameOf<QuestionnaireModel>('isAccessible'), 'Accessible'),
            AgGridColDefHelper.getYesNoColDef(ObjectHelper.nameOf<QuestionnaireModel>('isCompleted'), 'Completed'),
            AgGridColDefHelper.getYesNoColDef(ObjectHelper.nameOf<QuestionnaireModel>('isDeleted'), 'Deleted'),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOfSubProperty<QuestionnaireModel, CommonBaseAuditUserModel>('lastModifiedByUser', 'fullName'), 'Last Modified By'),
            AgGridColDefHelper.getDateTimeColDef(ObjectHelper.nameOfSubProperty<QuestionnaireModel, CommonBaseAuditUserModel>('lastModifiedByUser', 'changeDateTime'), 'Last Modified On'),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOfSubProperty<QuestionnaireModel, CommonBaseAuditUserModel>('createdByUser', 'fullName'), 'Created By'),
            AgGridColDefHelper.getDateTimeColDef(ObjectHelper.nameOfSubProperty<QuestionnaireModel, CommonBaseAuditUserModel>('createdByUser', 'changeDateTime'), 'Created On'),

        ];
    }

    protected setRowData(): Observable<QuestionnaireModel[]> {
        return this.questionnaireService.search({});
    }
}
