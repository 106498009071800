import { AuthorizationConstants } from 'app/nexus-shared/constants/authorization.constants';
import { UserModel } from 'app/nexus-shared/domain/users/models/user.model';
import { AuthenticatedUserModel } from 'app/nexus-shared/models/authenticated-user.model';
import { ApplicationsEnum } from 'app/nexus-shared';

export class AuthorizationHelper {
    // these lists can be programmatically built out with the roles endpoints: todo!
    public static documentsUserAuthConstants: AuthorizationConstants[] = [AuthorizationConstants.documents, AuthorizationConstants.documentsAdmin, AuthorizationConstants.documentsTemplatesAdmin];
    public static accountingUserAuthConstants: AuthorizationConstants[] = [AuthorizationConstants.accounting, AuthorizationConstants.accountingStaff, AuthorizationConstants.accountingStaff];
    public static tractionUserAuthConstants: AuthorizationConstants[] = [AuthorizationConstants.traction, AuthorizationConstants.tractionAdmin];
    public static contactsUserAuthConstants: AuthorizationConstants[] = [AuthorizationConstants.contacts, AuthorizationConstants.contactsAdmin];
    public static allocateUserAuthConstants: AuthorizationConstants[] = [AuthorizationConstants.equity, AuthorizationConstants.equityAdmin];
    public static portalUserAuthConstants: AuthorizationConstants[] = [AuthorizationConstants.portal, AuthorizationConstants.portalMobileEmployee, AuthorizationConstants.portalCompanyAdmin, AuthorizationConstants.portalRelocationAdmin];
    public static agreementsUserAuthConstants: AuthorizationConstants[] = [AuthorizationConstants.agreements, AuthorizationConstants.agreements];

    public static hasAuthorization(authenticatedUserModel: AuthenticatedUserModel, authorization: AuthorizationConstants[] | AuthorizationConstants, bypassGtnCheck = false): boolean {
        if (!AuthorizationHelper.hasValidSetup(authenticatedUserModel)) {
            return false;
        }

        if (Array.isArray(authorization)) {
            if (authorization && authorization.length) {
                for (let i = 0; i < authorization.length; i++) {
                    if (!AuthorizationHelper.hasValidAuthorization(authenticatedUserModel, authorization[i], bypassGtnCheck)) {
                        return false;
                    }
                }
            }
        } else {
            return AuthorizationHelper.hasValidAuthorization(authenticatedUserModel, authorization, bypassGtnCheck);
        }

        return true;
    }

    public static hasAnyAuthorization(authenticatedUserModel: AuthenticatedUserModel, authorizations: AuthorizationConstants[]): boolean {
        if (!AuthorizationHelper.hasValidSetup(authenticatedUserModel)) {
            return false;
        }

        for (let j = 0; j < authorizations.length; j++) {
            const authorization = authorizations[j];

            if (AuthorizationHelper.hasAuthorization(authenticatedUserModel, authorization)) {
                return true;
            }
        }

        return false;
    }

    public static hasCompanyAuthorization(authenticatedUserModel: AuthenticatedUserModel, companyKeys: string[] | string) {
        if (!AuthorizationHelper.hasValidSetup(authenticatedUserModel)) {
            return false;
        }

        return AuthorizationHelper.hasValidAuthorization(authenticatedUserModel, companyKeys);
    }

    public static hasMyGtnPortalAuthorization(authenticatedUserModel: AuthenticatedUserModel, myGtnPortalUserKey: string) {
        if (!AuthorizationHelper.hasValidSetup(authenticatedUserModel)) {
            return false;
        }

        return AuthorizationHelper.hasValidAuthorization(authenticatedUserModel, myGtnPortalUserKey);
    }

    public static hasGtnEmail(user: UserModel) {
        return !!user?.email?.endsWith('@gtn.com');
    }

    public static hasGtnAuthorization(authenticatedUserModel: AuthenticatedUserModel): boolean {
        return AuthorizationHelper.hasAuthorization(authenticatedUserModel, AuthorizationConstants.gtnUser) || AuthorizationHelper.hasAuthorization(authenticatedUserModel, AuthorizationConstants.gtnAdministrator);
    }

    public static hasApplicationAuthorization(authenticatedUserModel: AuthenticatedUserModel, application: ApplicationsEnum): boolean {
        switch (application) {
            case ApplicationsEnum.Documents:
                return this.hasAuthorization(authenticatedUserModel, this.documentsUserAuthConstants);
            case ApplicationsEnum.Accounting:
                return this.hasAuthorization(authenticatedUserModel, this.accountingUserAuthConstants);
            case ApplicationsEnum.Traction:
                return this.hasAuthorization(authenticatedUserModel, this.tractionUserAuthConstants);
            case ApplicationsEnum.Contacts:
                return this.hasAuthorization(authenticatedUserModel, this.contactsUserAuthConstants);
            case ApplicationsEnum.Equity:
                return this.hasAuthorization(authenticatedUserModel, this.allocateUserAuthConstants);
            case ApplicationsEnum.Portal:
                return this.hasAuthorization(authenticatedUserModel, this.portalUserAuthConstants);
            case ApplicationsEnum.Agreements:
                return this.hasAuthorization(authenticatedUserModel, this.agreementsUserAuthConstants);
            default:
                return false;
        }
    }

    public static hasAppAuthorization(authenticatedUserModel: AuthenticatedUserModel): boolean {
        return !!authenticatedUserModel.individualKey;
    }

    private static hasValidAuthorization(authenticatedUserModel: AuthenticatedUserModel, authorization: AuthorizationConstants, bypassGtnAdminCheck = false): boolean {
        if (!bypassGtnAdminCheck && authenticatedUserModel.policies.indexOf(AuthorizationConstants.gtnAdministrator) !== -1) {
            return true;
        }

        if (authorization) {
            return authenticatedUserModel.policies.indexOf(authorization) !== -1;
        }

        return true;
    }

    private static hasValidSetup(authenticatedUserModel: AuthenticatedUserModel): boolean {
        return authenticatedUserModel && AuthorizationHelper.hasAppAuthorization(authenticatedUserModel);
    }
}
