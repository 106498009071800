<gtn-modal class="modal-medium" headerText="Data Accuracy and Sharing" footerPosition="middle" okButtonText="Confirm"
    cancelButtonText="Decline" (okClick)="onAgreeClicked()" (cancelClick)="onCancelClicked()">
    <gtn-modal-content>
        <div class="main-container row">
            <div class="col-12">
                <p>
                    I agree I am responsible for the accuracy of all information entered into this Application by myself
                    or a person authorized by me.
                </p>
                <p>
                    I agree I am responsible for assuring that all information in this Application that relates to me is
                    current. I will review that information frequently and promptly attend to any updating requested by
                    my employer, GTN, or another service provider.
                </p>
                <p>
                    I agree that information that I enter into this Application, including personal information, may be
                    shared with other persons who are providing services to me or for my benefit, or persons who are
                    providing services on their behalf. These persons include providers of mobility tax services, and
                    the information is shared with them in order for them to provide those services.
                </p>
                <p>
                    If am using this Application in connection with a service provided by or on behalf of my current,
                    prospective, or former employer (“employer”), I agree that my employer and persons providing
                    services to my employer may enter information relating to me into this Application. Further, I agree
                    that my employer and those persons may have access to information relating to me in this
                    Application, which information may also be disclosed to them by way of other means, such as via
                    email. This information may be accessed by or disclosed to them for purposes of providing services
                    to me and to administer and manage a mobility program.
                </p>
                <p>
                    <b>Access to My GTN Portal is contingent upon my confirmation of the above. Declining to confirm
                        will restrict access to My GTN Portal and redirect me to the login page. I can withdraw my
                        consent, but I will then no longer have My GTN Portal access.</b>
                </p>
            </div>
        </div>
    </gtn-modal-content>
</gtn-modal>
