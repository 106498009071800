<div class="breadcrumb-content" #breadcrumbParent>
    <ol class="breadcrumb" *ngIf="breadcrumbs?.length > 1" [hidden]="isLoading">
        <li *ngIf="isBackButtonEnabled && previousRouteService.previousUrls?.length" class="back-button">
            <gtn-back-button></gtn-back-button>
        </li>
        <li *ngIf="!breadcrumbs[0].isHidden">
            <a *ngIf="!breadcrumbs[0].isNavigationDisabled" href="javascript:void(0)" class="pointer"
                (click)="onBreadcrumbClicked(breadcrumbs[0])">
                <span *ngIf="breadcrumbs[0].icon" class="breadcrumb-item" [matTooltip]="breadcrumbs[0].title"><i
                    [ngClass]="breadcrumbs[0].icon"></i></span>
                <span *ngIf="!breadcrumbs[0].icon" class="breadcrumb-item"> {{ breadcrumbs[0].title }}</span>
            </a>
        </li>
        <li *ngIf="hiddenBreadcrumbs">
            <a href="javascript:void(0)" class="pointer" (click)="showHiddenBreadcrumbs()">
                <span>... </span>
            </a>
        </li>
        <ng-container *ngFor="let breadcrumb of breadcrumbs; let last = last; index as i">
            <li *ngIf="!breadcrumb.isNavigationDisabled && !last && i !== 0 && !breadcrumb.isHidden">
                <a href="javascript:void(0)" class="pointer"
                    (click)="onBreadcrumbClicked(breadcrumb)">
                    <span *ngIf="breadcrumb.icon" class="breadcrumb-item"><i [ngClass]="breadcrumb.icon"></i></span>
                    <span *ngIf="!breadcrumb.icon" class="breadcrumb-item"
                        [matTooltip]="breadcrumb.title"> {{ breadcrumb.title }}</span>
                </a>
            </li>
            <li *ngIf="breadcrumb.isNavigationDisabled || last">
                {{ breadcrumb.title }}
            </li>
        </ng-container>
    </ol>
</div>
