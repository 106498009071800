import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { SearchModel } from 'app/nexus-shared/components/controls/shared/models/search.model';
import { SearchResultIconEnum, SearchResultModel } from 'app/nexus-shared/components/controls';
import { MyGTNPortalService } from 'app/nexus-core/services/domain/contacts/my-gtn-portal.service';
import { MyGTNPortalSearchResultModel } from 'app/nexus-shared/domain/contacts/models/my-gtn-portal-search-restult.model';
import { ContactSearchModel } from 'app/nexus-shared/domain/contacts/models/contact-search-model';

@Component({
    selector: 'gtn-my-gtn-portal-user-search',
    templateUrl: './my-gtn-portal-user-search.component.html',
    styleUrls: ['./my-gtn-portal-user-search.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MyGTNPortalUserSearchComponent),
            multi: true
        }
    ]
})
export class MyGTNPortalUserSearchComponent extends BaseComponent {
    @Input('value') _value: string = null;
    @Input() disabled: boolean;
    @Input() readonly: boolean;
    @Input() label: string = 'My Gtn Portal User';
    @Input() placeholder: string = 'Search by name or email . . .';

    @Output() searchResultClick: EventEmitter<MyGTNPortalSearchResultModel> = new EventEmitter();

    searchModel = new SearchModel();
    requiredCharacterCount: number = 2;
    options: MyGTNPortalSearchResultModel[];

    constructor(
        private myGTNPortalService: MyGTNPortalService
    ) {
        super();
        this.subscriptions.add(this.searchModel.onTextChanged.subscribe(searchText => {
            const search = <ContactSearchModel>{ searchTerm: searchText};
            this.myGTNPortalService.searchIndividuals(search).subscribe((res) => {
                    this.options = res;
                    const results = res.map((entity: MyGTNPortalSearchResultModel) => {
                        return {
                            id: entity.key,
                            name: entity.name,
                            subName: entity.nameDifferentiator,
                            iconClass: SearchResultIconEnum.user,
                            resultObject: entity
                        } as SearchResultModel<void, MyGTNPortalSearchResultModel>;
                    });
                    this.searchModel.onResultsReceived.next(results);
                },
                error => this.searchModel.onErrorReceived.next(error));
        }));

        this.subscriptions.add(this.searchModel.onResultClicked.subscribe(result => {
            this.searchResultClick.emit(result?.resultObject ? result.resultObject : null);
            this.writeValue(result?.resultObject?.key ? result.resultObject.key : null);
        }));
    }

    displayWith(searchResultItem: SearchResultModel<void, MyGTNPortalSearchResultModel>) {
        if (typeof searchResultItem === 'string' && this.options?.length) {
            const user = this.options.find(x => x.key === searchResultItem);
            return user ? user.name : null;
        }
        return null;
    }

    get value() {
        return this._value;
    }

    set value(userKey: string) {
        this._value = userKey;
        this.writeValue(userKey);
    }

    registerOnChange(fn: Function): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: Function): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    writeValue(userKey: string): void {
        this._value = userKey;
        this.onChange(userKey);
        this.onTouched();
    }

    private onChange: Function = () => {
    };
    private onTouched: Function = () => {
    };
}

