import { DateHelper } from './date.helper';
import { EnumHelper } from 'app/nexus-core/helpers/enum.helper';
import { ColDef, ColGroupDef, ExcelStyle, ICellRendererParams, RowClassRules, RowNode, StatusPanelDef, ToolPanelDef } from 'ag-grid-community';
import { ObjectHelper } from './object.helper';
import { CustomRowPropertyConstants } from 'app/nexus-shared/components/controls/components/base-grid/shared/constants/custom-row-property.constants';
import { MarketValuePipe } from 'app/nexus-shared';
import { GridSettingsPanelComponent } from 'app/nexus-shared/components/controls/components/base-grid/tool-panels/grid-settings-panel/grid-settings-panel.component';
import { LastUpdatedStatusBarComponent } from 'app/nexus-shared/components/base-component/base-enterprise-grid-components/last-updated-status-bar/last-updated-status-bar.component';
import { parsePhoneNumber } from 'libphonenumber-js';

export class AgGridHelper {
    static readonly headerHeight: number = 33;
    static readonly rowHeight: number = 38; //default row height
    static readonly minGridHeight: number = 71; //headerHeight + rowHeight;

    static readonly rightAligned = 'rightAligned';
    static readonly numberColumnFilter = 'agNumberColumnFilter';
    static readonly textColumnFilter = 'agTextColumnFilter';
    static readonly dateColumnFilter = 'agDateColumnFilter';
    static readonly multiColumnFilter = 'agMultiColumnFilter';
    static readonly setColumnFilter = 'agSetColumnFilter';
    static readonly multiColumnGroupDisplay = 'multipleColumns';
    static readonly singleColumnGroupDisplay = 'singleColumn';
    static readonly agGridAutoGroupColId = 'ag-Grid-AutoColumn';
    static readonly groupRowsDisplay = 'groupRows';
    static readonly copyPasteCellClass = 'copy-paste-cell';
    static readonly rowSelectionMultiple: 'single' | 'multiple' = 'multiple';
    static readonly rowSelectionSingle: 'single' | 'multiple' = 'single';
    static readonly agGridInputFieldClass: string = 'ag-input-field-input';
    static readonly totalAndFilteredRowCount: string = 'agTotalAndFilteredRowCountComponent';
    static readonly aggregation: string = 'agAggregationComponent';
    static readonly dateExcelClass: string = 'dateExcelClass';
    static readonly dateTimeExcelClass: string = 'dateTimeExcelClass';
    static readonly currencyExcelClass: string = 'currencyExcelClass';
    static readonly numberExcelClass: string = 'numberExcelClass';

    static readonly gridClasses = {
        pendingEdit: 'pending-edit'
    };

    static readonly columnsToolPanel: ToolPanelDef = {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
            suppressRowGroups: false,
            suppressValues: false,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressColumnFilter: false,
            suppressColumnSelectAll: false,
            suppressColumnExpandAll: false,
        }
    };

    static readonly filterToolPanel: ToolPanelDef = {
        id: 'filters',
        labelDefault: 'Filters',
        labelKey: 'filters',
        iconKey: 'filter',
        toolPanel: 'agFiltersToolPanel',
        toolPanelParams: {
            suppressExpandAll: true,
            suppressFilterSearch: true,
        }
    };

    static readonly gridSettingsPanel: ToolPanelDef = {
        id: 'gridSettings',
        labelDefault: 'Settings',
        labelKey: 'gridSettings',
        iconKey: 'menu',
        toolPanel: GridSettingsPanelComponent,
        toolPanelParams: {}
    };

    static readonly filteredRowCount: StatusPanelDef = {
        align: 'left',
        statusPanel: 'agFilteredRowCountComponent'
    };

    static readonly totalRowCount: StatusPanelDef = {
        align: 'left',
        statusPanel: 'agTotalRowCountComponent'
    };

    static readonly selectedRowCount: StatusPanelDef = {
        align: 'left',
        statusPanel: 'agSelectedRowCountComponent'
    };

    static readonly lastUpdatedDateTime: StatusPanelDef = {
        key: 'lastUpdatedPanel',
        statusPanel: LastUpdatedStatusBarComponent,
        align: 'right'
    };

    static readonly excelStyles: ExcelStyle[] = [
        {
            id: AgGridHelper.dateExcelClass,
            dataType: 'DateTime',
            numberFormat: {
                format: DateHelper.defaultExcelDateFormat,
            },
        },
        {
            id: AgGridHelper.dateTimeExcelClass,
            dataType: 'DateTime',
            numberFormat: {
                format: DateHelper.defaultExcelDateTimeFormat,
            },
        },
        {
            id: AgGridHelper.currencyExcelClass,
            numberFormat: {
                format: '#,##0.00',
            },
        },
        {
            id: AgGridHelper.numberExcelClass,
            numberFormat: {
                format: '#,##0.00',
            },
        },

    ];

    static readonly defaultDateFilterParams: any = {
        defaultOption: 'inRange',
        includeBlanksInLessThan: true,
        includeBlanksInGreaterThan: true,
        inRangeInclusive: true,
        comparator: AgGridHelper.dateFilterComparator
    };

    static readonly defaultDateRangeFilterParams: any = {
        type: 'inRange',
        defaultOption: 'inRange',
        includeBlanksInLessThan: true,
        includeBlanksInGreaterThan: true,
        inRangeInclusive: true,
        comparator: AgGridHelper.dateFilterComparator
    };

    static readonly baseRowClassRules: RowClassRules = {
        'pending-delete': (params) => {
            return !!params.node[CustomRowPropertyConstants.pendingDelete];
        },
        'pending-edit': (params) => {
            return !!params.node[CustomRowPropertyConstants.pendingEdit];
        },
        'pending-add': (params) => {
            return !!params.node[CustomRowPropertyConstants.pendingAdd];
        },
        'drag-hover': (params) => {
            return !!params.node[CustomRowPropertyConstants.dragHover];
        }
    };

    static calcGridHeight(shownRowCount: number = null, parentContainerHeight: number = null): number {
        let height = this.minGridHeight - this.rowHeight;

        if (shownRowCount) {
            height = this.headerHeight + (this.rowHeight * shownRowCount);
        }

        if (parentContainerHeight) {
            const maxRowCount = Math.floor((parentContainerHeight - this.headerHeight) / this.rowHeight);
            height = this.headerHeight + (this.rowHeight * maxRowCount);
        }

        return height;
    }

    static dateComparator(a, b, nodeA: RowNode, nodeB: RowNode, isDescending: boolean): number {
        const dateA = new Date(a).getTime() || 0;
        const dateB = new Date(b).getTime() || 0;

        return AgGridHelper.numberComparator(dateA, dateB, nodeA, nodeB, isDescending);
    }

    static numberComparator(a: number, b: number, nodeA: RowNode, nodeB: RowNode, isDescending: boolean): number {
        const sanitizedA: number = a ?? 0;
        const sanitizedB: number = b ?? 0;

        return sanitizedA - sanitizedB;
    }


    static dateFilterComparator(filterLocalDateAtMidnight: Date, cellValue: string): number {
        const dateAsString = cellValue;
        filterLocalDateAtMidnight = new Date(filterLocalDateAtMidnight);

        const datePart = dateAsString.split('T');
        const dateParts = datePart[0].split('-');
        const cellDate = new Date(
            Number(dateParts[0]),
            Number(dateParts[1]) - 1,
            Number(dateParts[2])
        );
        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
        }
        if (cellDate < filterLocalDateAtMidnight) {

            return -1;
        }
        if (cellDate > filterLocalDateAtMidnight) {
            return 1;
        }
    }

    static dateCellRenderer(data, format: string = DateHelper.standardDateFormat): string {
        if (data.value) {
            if (typeof data.value === 'string' && data.value.indexOf('T') > -1) {
                return DateHelper.format(data.value.split('T')[0], format);
            }
            return DateHelper.format(data.value, format);
        }
        return '';
    }

    static dateTimeCellRenderer(data, format: string = DateHelper.standardDateFormatTimeStamp): string {
        if (data.value) {
            return DateHelper.format(data.value, format);
        }
        return '';
    }

    static fmvCellRenderer(data, marketValuePipe: MarketValuePipe): string {
        if (data.value) {
            return marketValuePipe.transform(data.value);
        }
        return '';
    }

    static isColGroupDef(column: ColGroupDef | ColDef): boolean {
        return column.hasOwnProperty(ObjectHelper.nameOf<ColGroupDef>('children'));
    }

    static yesNoCellRenderer(data, showBlankForNull: boolean = false): string {
        let yesNo = showBlankForNull && (data.value === null || typeof data.value === 'undefined') ? '' : 'No';

        if (data.value) {
            yesNo = 'Yes';
        }

        return yesNo;
    }

    static checkboxRenderer(data): string {
        if (data.value) {
            return `<i class="fa fa-check"></i>`;
        }

        return null;
    }

    static moneyCellRenderer(data): string {
        if (data.value) {
            return '$' + data.value.toLocaleString();
        }

        return '$0.00';
    }

    static twoDecimalCellRenderer(data): string {
        if (data.value) {
            return data.value.toFixed(2);
        }

        return '';
    }

    static percentageCellRenderer(params: ICellRendererParams): string {
        if (params?.value) {
            return `${(params.value * 100).toFixed(2)}%`;
        }
    }

    static phoneNumberCellRenderer(params: ICellRendererParams): string {
        if (params?.value) {
            //try catch for any lingering phone numbers
            try {
                const phoneNumber = parsePhoneNumber(params.value);
                return phoneNumber ? phoneNumber.formatInternational() : params.value;
            } catch (e) {
                return params.value;
            }
        }
        return null;
    }

    static stringComparator(a: string, b: string, rowNodeA: RowNode, rowNodeB: RowNode, isDescending: boolean): number {
        if (typeof a !== 'string') {
            return isDescending ? -1 : 1;
        }

        if (typeof b !== 'string') {
            return isDescending ? 1 : -1;
        }

        if (a?.toLowerCase()?.trim() === b?.toLowerCase()?.trim()) {
            return 0;
        }
        if (isDescending) {
            return a?.toLowerCase()?.trim() < b?.toLowerCase()?.trim() ? -1 : 1;

        }

        return a?.toLowerCase()?.trim() > b?.toLowerCase()?.trim() ? 1 : -1;
    }

    static enumSortingComparator(valueA: number, valueB: number, enumValues: any): number {
        const enumValueA = EnumHelper.getDisplayName(enumValues, valueA)?.toLowerCase()?.trim() ?? null;
        const enumValueB = EnumHelper.getDisplayName(enumValues, valueB)?.toLowerCase()?.trim() ?? null;

        if (enumValueA === enumValueB) {
            return 0;
        }

        return (enumValueA > enumValueB) ? 1 : -1;
    }

    static booleanSortingComparator(a: string, b: string, rowNodeA: RowNode, rowNodeB: RowNode, isDescending: boolean) {
        if (a === b) {
            return 0;
        } else if (!a && b) {
            return -1;
        } else {
            return 1;
        }
    }

}
