<div class="modal-gtn">
    <div [ngClass]="{'modal-col': isSplitView, 'modal-full': !isSplitView}">
        <div @modalFadeIn class="modal-container modal-wrapper modal-medium-wide">
            <div class="modal-header-container flex align-items-center">
                <ng-container *ngIf="headerText || subHeaderText">
                    <div>
                        <div *ngIf="headerText && !subHeaderText">
                            <div class="single-header-text">
                                {{ headerText }}
                            </div>
                        </div>
                        <div *ngIf="subHeaderText">
                            <div *ngIf="headerText" class="main-header-text">
                                {{ headerText }}
                            </div>
                            <div class="sub-header-text">
                                {{ subHeaderText }}
                            </div>
                        </div>
                    </div>
                    <button *ngIf="showHeaderCloseButton" [disabled]="disableCancelButton" aria-label="Close"
                        class="close" type="button" (click)="onCloseClicked()">
                        <i class="fa fa-times"></i>
                    </button>
                    <gtn-validation *ngIf="validationModels && validationModels.length > 0 && canShowValidation"
                        [validationModels]="validationModels">
                    </gtn-validation>
                </ng-container>
                <ng-content select="gtn-modal-header-new"></ng-content>
            </div>
            <div class="modal-content" (scroll)="scroll.emit($event)">
                <ng-content select="gtn-modal-content"></ng-content>
            </div>
            <div class="gtn-modal-footer" *ngIf="showFooter">
                <div class="button-row">
                    <div *ngIf="confirmedCancel" class=" ml-2 footer-confirm-text">You have made changes to this
                        content.
                        Confirm to discard changes.
                    </div>
                    <div *ngIf="confirmedSave" class="ml-2 footer-confirm-text">This change cannot be undone. Confirm
                        to save changes.
                    </div>
                    <div *ngIf="confirmedDelete" class="ml-2 footer-confirm-text">This change cannot be undone. Confirm
                        to delete record.
                    </div>
                </div>
                <div class="button-row flex flex-row justify-content-between">
                    <div class="flex">
                        <button mat-flat-button *ngIf="showDeleteButton" (click)="onDeleteButtonClicked()"
                            [disabled]="disableDeleteButton"
                            class="delete" id="deleteButton" type="button">
                            {{ deleteButtonText }}
                        </button>
                        <ng-content select="gtn-modal-footer-left-buttons"></ng-content>
                    </div>
                    <div class="right-buttons flex">
                        <ng-content select="gtn-modal-footer-right-buttons"></ng-content>
                        <button mat-flat-button *ngIf="showOkButton" (click)="onOkButtonClicked()"
                            [disabled]="disableOkButton"
                            color="accent" id="okButton" type="button">
                            {{ okButtonText }}
                        </button>
                        <button mat-flat-button *ngIf="showCancelButton" (click)="onCancelClicked()"
                            [disabled]="disableCancelButton"
                            [ngClass]="{'override-color': isCancelButtonColorOrange}"
                            class="cancel-button" id="cancelButton" type="button">
                            {{ cancelButtonText }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div *ngIf="isSplitView" class="modal-col">
        <ng-content select="gtn-modal-split-view-content">
        </ng-content>
    </div>
</div>
