import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges, } from '@angular/core';
import { LocationModel } from 'app/nexus-shared/domain/locations/models';
import { SortingHelper } from 'app/nexus-core';
import { map } from 'rxjs/operators';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, } from '@angular/forms';
import { BaseLocationMultiSelectComponent } from 'app/nexus-shared/domain/locations/components/select/base-location-multiselect.component';
import { forkJoin } from 'rxjs';
import { LocationService } from 'app/nexus-core/services/location.service';

@Component({
    selector: 'gtn-state-multi-select',
    templateUrl: '../../../../components/controls/components/dropdown/dropdown.component.html',
    styleUrls: ['../../../../components/controls/components/dropdown/dropdown.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => StateMultiSelectComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => StateMultiSelectComponent),
            multi: true,
        },
    ],
})
export class StateMultiSelectComponent
    extends BaseLocationMultiSelectComponent
    implements ControlValueAccessor, OnChanges, OnInit {
    @Input() countryIds: number[] = null;
    @Input() label: string = 'States';

    constructor(private locationService: LocationService) {
        super();
    }

    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
    ngOnInit() {
        // overriding the base onInit so initOptions() doesn't get called when it's not supposed to
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.countryIds) {
            this.initOptions();
            this.resortOptions();
        }
    }

     dataFetch = () => {
        return forkJoin(
            this.countryIds.map((countryId) =>
                this.locationService
                    .listStates(countryId)
                    .pipe(
                        map((statesArrays) => [].concat.apply([], statesArrays))
                    )
            )
        );
    };

     handleResponse(data: LocationModel[]) {
        this.options = data.sort((a, b) => {
            return SortingHelper.sortByPropertyComparer(a, b, 'name');
        });

        this.setExtraOptions();
        this.resortOptions();
    }
}
