import { BaseEntryComponent } from "app/nexus-shared/components/base-component/base-entry.component";
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { IAgreementTypeModelInterface } from "../../../interfaces/iagreement-type-model.interface";
import { IAgreementFileModelInterface } from "../../../interfaces/iagreement-file-model.interface";
import { CompanyModel } from "app/nexus-shared/domain/contacts/models";
import { AgreementTypeEnum } from "../../../enums/agreement-type.enum";
import { IndividualInterface } from "app/nexus-shared/interfaces/individual.interface";
import { AgreementSpouseSendStatusEnum } from "../../../enums/agreement-spouse-send-status.enum";
import { AgreementUploadFormComponent } from "../../forms/agreement-upload-form/agreement-upload-form.component";

@Component({
    selector: 'gtn-agreement-upload-modal',
    templateUrl: './agreement-upload-modal.component.html',
    styleUrls: ['./agreement-upload-modal.component.scss']
})
export class AgreementUploadModalComponent extends BaseEntryComponent<IAgreementFileModelInterface> implements OnInit {
    @Input() agreementTypes: IAgreementTypeModelInterface[] = [];
    @Input() companies: CompanyModel[] = [];
    @Input() displayMainAgreementType: boolean = true;
    @Input() displaySigner: boolean = true;
    @Input() mainAgreementType = AgreementTypeEnum.Unknown;
    @Input() requireFileUpload: boolean = true;
    @Input() reviewers: IndividualInterface[] = [];
    @Input() signerCompany: CompanyModel;
    @Input() signerIndividual: IndividualInterface;
    @Input() signerSpouse: IndividualInterface;
    @Output() reviewerChange: EventEmitter<IndividualInterface> = new EventEmitter();
    @Output() sendStatusChange: EventEmitter<AgreementSpouseSendStatusEnum> = new EventEmitter<AgreementSpouseSendStatusEnum>();
    @Output() signerCompanyChange: EventEmitter<CompanyModel> = new EventEmitter<CompanyModel>();
    @Output() signerIndividualChange: EventEmitter<IndividualInterface> = new EventEmitter<IndividualInterface>();
    @Output() spouseNameChange: EventEmitter<string> = new EventEmitter<string>();
    @Output() typeChange: EventEmitter<IAgreementTypeModelInterface> = new EventEmitter<IAgreementTypeModelInterface>();

    @ViewChild('uploadForm') uploadForm: AgreementUploadFormComponent;

    fileUploadComplete: boolean = false;

    ngOnInit(): void {
        if (!this.requireFileUpload) {
            this.fileUploadComplete = true;
        }
    }

    onCancelClicked() {
        this.cancelClick.emit();
    }

    onFileChanged() {
        if (this.value.file && this.value.tempFileName) {
            this.fileUploadComplete = true;
        }
        else if (this.requireFileUpload) {
            this.fileUploadComplete = false;
        }
    }

    onReviewerChanged($event: IndividualInterface) {
        this.reviewerChange.emit($event);
    }

    onSaveClicked() {
        this.uploadForm?.onSaveClicked();
        this.saveClick.emit();
    }

    onSendStatusChanged($event: AgreementSpouseSendStatusEnum) {
        this.sendStatusChange.emit($event);
    }

    onSignerCompanyChanged($event: CompanyModel) {
        this.signerCompanyChange.emit($event);
    }

    onSignerIndividualChanged($event: IndividualInterface) {
        this.signerIndividualChange.emit($event);
    }

    onSpouseNameChanged($event: string) {
        this.spouseNameChange.emit($event);
    }

    onTypeChanged($event: IAgreementTypeModelInterface) {
        this.typeChange.emit($event);
    }
}
