<div class="form-body row">
    <div class="col-12 text-center">
        <div class="flex">
            <div class="applications-container">
                <div *ngFor="let navMenuItem of navMenuItems" class="application-container">
                    <gtn-template-card>
                        <gtn-template-card-content (click)="onToolClicked(navMenuItem)">
                            <div>
                                <img src="{{publicBlobStorageUrl + 'logos/' + navMenuItem.menu.img.fileName}}">
                            </div>
                            <p>
                                {{navMenuItem.menu.title}}
                            </p>
                        </gtn-template-card-content>
                    </gtn-template-card>
                </div>
            </div>
        </div>
    </div>
</div>
