import { ModalInputModel } from 'app/nexus-shared/components/modal/models/modal-input.model';

export class AlertModalInputModel extends ModalInputModel {
    constructor(
        mainHeaderText: string = '',
        message: string = '',
        subHeaderText: string = '',
        okButtonText: string = 'Ok',
        cancelButtonText: string = 'Cancel',
        showCloseButton: boolean = false
    ) {
        super(
            mainHeaderText,
            message,
            subHeaderText,
            okButtonText,
            cancelButtonText,
            showCloseButton
        );
    }
}
