import { Component } from '@angular/core';
import { NexusModalService } from 'app/nexus-core';
import { NexusAnimations, SelectModel } from 'app/nexus-shared/index';
import { UserInputModalComponentInputsModel } from 'app/nexus-shared/components/modal/models/select-modal-input.model';

@Component({
    selector: 'gtn-select-modal',
    templateUrl: './select-modal.component.html',
    styleUrls: ['./select-modal.component.scss'],
    animations: [
        NexusAnimations.modalFadeIn
    ]
})
export class SelectModalComponent extends UserInputModalComponentInputsModel {

    selectedValue: SelectModel;

    ok: (selectedValue: SelectModel) => void;
    cancel: () => void;

    public okClick = (() => {
        if (this.ok) {
            this.ok(this.selectedValue);
        }
        this.destroy();
    }).bind(this);

    public cancelClick = (() => {
        if (this.cancel) {
            this.cancel();
        }
        this.destroy();
    });

    constructor(protected modalService: NexusModalService) {
        super();
    }

    public destroy() {
        this.modalService.destroy();
    }
}
