<div class="section-filter">
    <div class="active-filter">
        <mat-chip-listbox *ngIf="isFilterPanelCollapsed" aria-label="Active Filters">
            <mat-chip-option class="active-filter-item" *ngFor="let item of activeFilters">
                <ng-container
                    *ngIf="item.id === 'date' || item.id === 'startDate' || item.id === 'endDate'; else defaultDisplay">
                    {{ item['prefix'] }}{{ item.value | gtnDate }}
                </ng-container>
                <ng-template #defaultDisplay>
                    {{ item['prefix'] }}{{ item.value }}
                </ng-template>
                <i *ngIf="!item.disabled" class="material-icons" matChipRemove (click)="onRemoveFilter(item)">cancel</i>
            </mat-chip-option>
        </mat-chip-listbox>
    </div>

    <i class="fa fa-filter" (click)="onToggleFilter()" matTooltip="Filter"></i>
    <i *gtnIfHasAuthorization="authorizationConstants.taxesAdmin" class="fa fa-plus" (click)="onNewRecord()"
        matTooltip="Add New"></i>
</div>

<div [@gtnFilterPanel]="!isFilterPanelCollapsed" #filterContainer class="filter-content">
    <ng-template gtnFilterHost></ng-template>
</div>
