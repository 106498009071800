import { Pipe, PipeTransform } from '@angular/core';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';

@Pipe({
    name: 'gtnPhoneNumber'
})

export class PhoneNumberPipe implements PipeTransform {
    constructor() {
    }

    transform(phoneNumber: string, countryCode: string = 'US'): string {
        if (!countryCode) {
            countryCode = 'US';
        }
        // @ts-ignore
        if (phoneNumber && isValidPhoneNumber(phoneNumber, countryCode)) {
            // @ts-ignore
            return parsePhoneNumber(phoneNumber, countryCode).formatInternational();
        } else if (phoneNumber) {
            return phoneNumber;
        } else {
            return '';
        }

    }
}
