import { Component, Input } from '@angular/core';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { ApplicationsEnum } from 'app/nexus-shared/enums';

@Component({
    selector: 'gtn-company-preview-icon',
    templateUrl: './company-preview-icon.component.html',
    styleUrls: ['./company-preview-icon.component.scss']
})
export class CompanyPreviewIconComponent extends BaseComponent {
    @Input() companyKey: string;
    @Input() application: ApplicationsEnum;

    showModal: boolean = false;

    openPreview(): void {
        this.showModal = true;
    }

    onCloseModal(): void {
        this.showModal = false;
    }

}
