import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { PDFDocumentProxy, PDFPageProxy, PdfViewerComponent } from 'ng2-pdf-viewer';
import { BaseFileViewComponent } from 'app/nexus-shared/components/controls/components/file-view/base-file-view.component';
import { ValidationModel } from 'app/nexus-shared/models';
import { PDFOutlineModel } from 'app/nexus-shared/models/pdf-outline.model';
import { NestedTreeControl } from '@angular/cdk/tree';
import { PDFTextSearchModel } from 'app/nexus-shared/models/pdf-text-search.model';
import { PDFTextSearchDirectionEnum } from 'app/nexus-shared/enums/pdf-text-search-direction.enum';
import { PDFTextSearchMatchDetailModel } from 'app/nexus-shared/models/pdf-text-search-match-detail.model';
import { PDFFindController } from 'pdfjs-dist/web/pdf_viewer';
import { PdfFileViewService } from 'app/nexus-core/services/domain/core/pdf-file-view.service';
import { PdfFileViewHelper, SpinnerService } from 'app/nexus-core';
import { PdfThumbnailModel } from '../../../shared';

@Component({
    selector: 'gtn-pdf-file-view',
    templateUrl: './pdf-file-view.component.html',
    styleUrls: ['./pdf-file-view.component.scss']
})
export class PdfFileViewComponent extends BaseFileViewComponent implements OnInit, OnChanges {
    @Input() enablePDFOutline: boolean = false;
    @Input() enableThumbnails: boolean = false;
    @Input() enablePrinting: boolean = false;
    @Input() hidePdf: boolean = true;
    @Input() isAgreement: boolean = false;
    @Input() pdfViewPadding: number;
    @Input() searchModel: PDFTextSearchModel = null;
    @Input() signatureValidationErrors: ValidationModel[];

    @Output() hidePdfChange: EventEmitter<boolean> = new EventEmitter();
    @Output() pdfLoadComplete: EventEmitter<void> = new EventEmitter();
    @Output() pdfPrintDataUpdate: EventEmitter<string> = new EventEmitter();
    @Output() pdfSearchComplete: EventEmitter<PDFTextSearchMatchDetailModel> = new EventEmitter();

    leftNavState: 'outline' | 'thumbnail' | 'closed' = 'closed';
    pdf: PDFDocumentProxy;
    pdfSrc: string;
    pdfPage: number = 1;
    zoom: number = 1;
    originalSize: boolean = true;
    pages: PDFPageProxy[] = [];
    showPDFOutline: boolean = false;
    thumbnails: PdfThumbnailModel[] = [];
    outlines: PDFOutlineModel[] = [];
    totalPages: number;
    selectedThumbnailPageNumber: number = 1;

    outlineChildrenAccessor = (outline: PDFOutlineModel) => outline.children;
    outlineDataSource = new MatTreeNestedDataSource<PDFOutlineModel>();
    outlineHasChild = (_: number, outline: PDFOutlineModel) => !!outline.children && outline.children.length > 0;
    outlineTreeControl = new NestedTreeControl<PDFOutlineModel>(outline => outline.children);

    @ViewChild('pdfViewer') pdfViewer: PdfViewerComponent;

    constructor(public pdfFileViewService: PdfFileViewService) {
        super();
    }

    ngOnInit(): void {
        if (this.isBlob) {
            this.pdfSrc = URL.createObjectURL(this.src.blob);
        } else {
            this.pdfSrc = this.src;
        }

        this.pdfPrintDataUpdate.emit(this.pdfSrc);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.searchModel) {
            this.searchPdf();
        }
    }

    onDecreasePageNumber() {
        if (this.pdfPage === 1) {
            return;
        }

        this.pdfPage--;
    }

    onIncreasePageNumber() {
        if (this.pdfPage === this.totalPages) {
            return;
        }

        this.pdfPage++;
    }

    onOutlineLinkClicked(id: string) {
        // @ts-ignore
        const pdfElement = this.pdfViewer.element.nativeElement.getElementsByClassName('ng2-pdf-viewer-container')[0] as HTMLElement;

        if (id) {
            const element = document.getElementById(id);
            const pageElement = element.parentElement.offsetParent as HTMLElement;
            const firstChildElement = element.getElementsByTagName('span')[0];

            const x = pageElement.offsetLeft + element.scrollLeft;
            const y = pageElement.offsetTop + (element.scrollHeight - firstChildElement.scrollHeight) - 30;

            pdfElement.scrollTo(x, y);
        }
    }

    onOutlineToggleClicked() {
        this.handleLeftNavStateChange('outline');
    }

    onPdfViewerAfterLoadCompleted(e: PDFDocumentProxy): void {
        // @ts-ignore
        const element = this.pdfViewer.element.nativeElement.getElementsByClassName('ng2-pdf-viewer-container')[0] as HTMLElement;
        element.style.padding = `${this.pdfViewPadding}px 0`;

        element.onclick = (e) => {
            if ((e.target as HTMLElement).className === 'pdfViewer removePageBorders') {
                this.outsideCloseClick.emit();
            }
        };

        this.pdf = e;
        this.totalPages = e.numPages;

        this.configurePdfSearchEvent();

        if (this.isAgreement || this.enablePDFOutline || this.enableThumbnails) {
            SpinnerService.start('Scanning pdf');

            this.pdfViewer.pdfViewer.container.style.visibility = 'hidden';

            this.pdfFileViewService.initPDF();
            this.pdfViewer.eventBus.on('pagerendered', (page => {
                if (this.pages?.length && this.pages.findIndex(x => x.pageNumber === page.pageNumber) > -1) {
                    return;
                }

                this.onPdfViewerPageRendered(page, element);
            }).bind(this));
        }
    }

    async onPdfViewerPageRendered(pdfPageProxy: PDFPageProxy, element: HTMLElement) {
        const page = await this.pdf.getPage(pdfPageProxy.pageNumber);
        this.pages.push(page);

        this.addThumbnail(page);
        this.pdfFileViewService.initPageAnnotations(page);

        if (pdfPageProxy.pageNumber < this.totalPages) {
            const nextPage = pdfPageProxy.pageNumber + 1;
            this.pdfViewer.pdfViewer.scrollPageIntoView({ pageNumber: nextPage });
        } else {
            this.initNavigationOutline();
            element.scrollTo(0, 0);
            this.pdfViewer.pdfViewer.container.style.visibility = 'visible';
            this.pdfLoadComplete.emit();
            SpinnerService.stop();
        }
    }

    onPdfPageChange($event): void {
        const minValue = 1;
        let value = Number($event?.target?.value);

        if (value < 1) {
            value = minValue;
        }

        if (value > this.totalPages) {
            value = this.totalPages;
        }

        if (value !== null && value !== undefined && !Number.isNaN(value)) {
            $event.srcElement.value = value;
            this.pdfPage = (value < minValue) ? minValue : value;
        }
    }

    onThumbnailClicked(pageNumber: number) {
        this.selectedThumbnailPageNumber = pageNumber;
        this.pdfPage = pageNumber;
    }

    onThumbnailToggleClicked() {
        this.handleLeftNavStateChange('thumbnail');
    }

    onZoomInClicked(): void {
        this.zoom += .25;
    }

    onZoomOutClicked(): void {
        if (this.zoom > 1) {
            this.zoom -= .25;
        }
    }

    onResetClicked(): void {
        this.zoom = 1;
    }

    private async addThumbnail(pdfPageProxy: PDFPageProxy) {
        const thumbnail = (await this.initNavigationThumbnail(pdfPageProxy));
        this.thumbnails.push(thumbnail);
    }

    private configurePdfSearchEvent() {
        const pdfFindController = this.pdfViewer?.pdfFindController;
        if (!pdfFindController) {
            return;
        }

        pdfFindController._eventBus.on('find', _ => {
            // Wait for find controller to update
            setTimeout(() => {
                const searchDetails = new PDFTextSearchMatchDetailModel();

                searchDetails.currentMatchId = this.getSearchMatchId(pdfFindController);
                searchDetails.matchCount = pdfFindController._matchesCountTotal;

                this.pdfSearchComplete.emit(searchDetails);
            }, 0);
        });
    }

    private getSearchMatchId(pdfFindController: PDFFindController): number {
        let previousPageCount = 0;

        for (let index = 0; index < pdfFindController._selected.pageIdx; index++) {
            previousPageCount += pdfFindController.pageMatches[index].length;
        }

        const indexOffset = 1;
        return previousPageCount + pdfFindController._selected.matchIdx + indexOffset;
    }

    private handleLeftNavStateChange(state: 'outline' | 'thumbnail' | 'closed') {
        if (this.leftNavState === state) {
            this.leftNavState = 'closed';
            return;
        }

        this.leftNavState = state;
    }

    private searchPdf() {
        const pdfFindController = this.pdfViewer?.pdfFindController;

        if (!pdfFindController) {
            return;
        }

        const findPrevious = this.searchModel?.searchDirection === PDFTextSearchDirectionEnum.Previous ? true : false,
            query = this.searchModel?.text;

        pdfFindController._eventBus.dispatch('find', {
            query: query, type: 'again', caseSensitive: false, findPrevious: findPrevious, highlightAll: true, phraseSearch: true
        });
    }

    private async initNavigationOutline() {
        // Wait for DOM to load
        setTimeout(async () => {
            const headerMap = await PdfFileViewHelper.processOutlineHeaderMap(this.pages);
            const outlines = (await PdfFileViewHelper.populateOutline(headerMap));

            this.outlineDataSource.data = outlines;
            this.outlineTreeControl.dataNodes = this.outlineDataSource.data;
            this.outlineTreeControl.expandAll();

        }, 1000);
    }

    private async initNavigationThumbnail(pdfPageProxy: PDFPageProxy): Promise<PdfThumbnailModel> {
        const canvasElement = document.createElement('canvas');

        canvasElement.height = 840; // adjust it according to your ui
        canvasElement.width = 600; // adjust it according to your ui

        const canvasContext = canvasElement.getContext('2d');
        let promise: any;

        if (canvasContext) {
            const renderPromise = pdfPageProxy.render({
                viewport: pdfPageProxy.getViewport({ scale: 1 }),
                canvasContext: canvasContext
            }).promise;

            promise = renderPromise.then<PdfThumbnailModel>(_ => ({
                pageNumber: pdfPageProxy.pageNumber,
                url: canvasElement.toDataURL()
            }));
        }

        return await Promise.resolve<PdfThumbnailModel>(promise);
    }
}
