<gtn-template-grid [showQuickFilter]="false"
    [gridSettings]="gridSettings"
    [gridStateKey]="gridStateKey"
    [application]="application"
    (chipGridSettingClick)="onSavedSettingClicked($event)">
    <gtn-template-grid-header>
        <button [hidden]="isFiltersVisible"
            (click)="onFilterVisibilityChanged(true)"
            class="mb-2"
            color="default"
            mat-mini-fab
            matTooltip="Toggle filters">
            <mat-icon>visibility</mat-icon>
        </button>
        <div [hidden]="!isFiltersVisible">
            <gtn-document-search-filters [(value)]="searchModel"
                [(isFiltersVisible)]="isFiltersVisible"
                (valueChange)="onSearchModelChanged($event)"></gtn-document-search-filters>
        </div>
    </gtn-template-grid-header>
    <gtn-template-grid-content>
        <gtn-template-grid-content>
            <ag-grid-angular [gtnHeight]="gridHeight"
                *ngIf="gridOptions"
                class="ag-theme-balham customized-theme"
                [gridOptions]="gridOptions"></ag-grid-angular>
        </gtn-template-grid-content>
    </gtn-template-grid-content>
</gtn-template-grid>
<gtn-file-view *ngIf="showPreview"
    [file]="fileView"
    [enableDownload]="true"
    [enablePrinting]="true"
    [enableSearch]="true"
    (close)="showPreview = false;">
    <gtn-file-view-header-controls>
        <div class="icon-button-fa"
            (click)="onPreviewNavigateClicked()"
            matTooltip="Go to folder">
            <i class="fas fa-folder-search"></i>
        </div>
    </gtn-file-view-header-controls>
</gtn-file-view>
