<form [formGroup]="formGroupRef">
    <div class="row align-items-center" *ngIf="!isManualAddress && !isGoogleLocationSelected">
        <gtn-google-location-search class="col-md-3"
            (addressClick)="onGoogleLocationClicked($event)"
            (addEntityClick)="onAddManualAddressClicked()"></gtn-google-location-search>
        <a *ngIf="!isGoogleLocationSelected" class="col-md-3" href="javascript:void(0)" matTooltip="Remove address">
            <mat-icon (click)="onRemoveAddressClicked()">delete</mat-icon>
        </a>
    </div>
    <ng-container *ngIf="isManualAddress || isGoogleLocationSelected">
        <div class="row align-items-center">
            <gtn-dropdown [ngClass]="{'col-md-3': !isModalView, 'col-6': isModalView}" formControlName="type"
                [options]="addressTypeOptions" label="Address type"
                [idOnly]="true"></gtn-dropdown>
            <gtn-input *ngIf="formGroupRef?.controls?.type?.value !== addressTypes.Physical"
                [ngClass]="{'col-md-3': !isModalView, 'col-6': isModalView}" formControlName="attnLine"
                label="Attn Line"></gtn-input>
            <a class="col-md-6" href="javascript:void(0)" matTooltip="Remove address">
                <mat-icon (click)="onRemoveAddressClicked()">delete</mat-icon>
            </a>
        </div>
        <div class="row">
            <gtn-input [ngClass]="{'col-md-3': !isModalView, 'col-6': isModalView}" formControlName="addressLineOne"
                [readonly]="!isManualAddress || isGoogleLocationSelected"
                label="Address line 1"></gtn-input>
            <gtn-input [ngClass]="{'col-md-3': !isModalView, 'col-6': isModalView}" formControlName="addressLineTwo"
                label="Address line 2"></gtn-input>
        </div>
        <div class="row">
            <gtn-input [ngClass]="{'col-md-3': !isModalView, 'col-6': isModalView}" formControlName="city"
                [readonly]="!isManualAddress || isGoogleLocationSelected"
                label="City"></gtn-input>
            <gtn-input [ngClass]="{'col-md-3': !isModalView, 'col-6': isModalView}" formControlName="stateName"
                [readonly]="!isManualAddress || isGoogleLocationSelected"
                label="State"></gtn-input>
        </div>
    </ng-container>
    <div class="row">
        <ng-container *ngIf="isGoogleLocationSelected || isManualAddress">
            <gtn-input [ngClass]="{'col-md-3': !isModalView, 'col-6': isModalView}" formControlName="postalCode"
                [readonly]="!isManualAddress || isGoogleLocationSelected"
                label="Postal code"></gtn-input>
            <gtn-input [ngClass]="{'col-md-3': !isModalView, 'col-6': isModalView}" formControlName="countryName"
                [readonly]="!isManualAddress || isGoogleLocationSelected"
                label="Country"></gtn-input>
        </ng-container>
    </div>
</form>
