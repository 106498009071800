<gtn-modal class="small" headerText="Reset Password" okButtonText="Continue"
           (okClick)="onResetClicked()" (cancelClick)="onCancelClicked()" [disableOkButton]="disableButtons">
    <gtn-modal-content>
        <div class="row">
            <div class="col-12">
                A password reset email will be sent to
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-12">
                <b>{{email}}</b>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-12">
                The password reset link within the email will be a one-time use link valid for only <b>{{passwordChangeLinkTimeToLiveInMinutes}} minutes</b> from now.
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-12">
                <b>You will be logged out immediately after clicking 'Continue'.</b>
            </div>
        </div>
    </gtn-modal-content>
</gtn-modal>
