export class ContactsRoutingKeyConstants {
    // query param keys

    // MOVE INTO BELOW OBJECTS WHICH ARE MORE MANAGABLE
    // route param keys
    public static companyKey = 'company-key';
    public static individualKey = 'individual-key';
    public static scopeOfWorkKey = 'scope-of-work-key';

    static routeParamKeys = {
        company: 'company-key',
        individual: 'individual-key',
    };

    // MENU IDS
    static menuIds = {
        companyDetailRoot: 1,
        companyDetailGeneral: 2,
        individualDetailRoot: 3,
        individualDetailGeneral: 4,
        controllingCompanyIndividualDetailRoot: 5,
        controllingCompanyIndividualDetailGeneral: 6
    };

    static breadcrumbIds = {
        companyRoot: 1,
        individualRoot: 2
    };
}
