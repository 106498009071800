<gtn-template-grid [showAddButton]="canAdd"
    [showQuickFilter]="true"
    [showExportButton]="true"
    [gridStateKey]="gridStateKey"
    [application]="application"
    (quickFilterTextChange)="onFilterTextChanged($event)"
    (chipGridSettingClick)="onSavedSettingClicked($event)"
    (addButtonClick)="onAddButtonClicked()"
    (exportButtonClick)="onExportButtonClicked()">
    <gtn-template-grid-header *ngIf="enableAdvancedSearch">
        <button mat-mini-fab
            class="mb-2"
            color="default"
            matTooltip="Toggle filters"
            [hidden]="showSearchPanel"
            (click)="onShowAdvancedSearchClicked()">
            <mat-icon>visibility</mat-icon>
        </button>
        <gtn-agreements-search *ngIf="showSearchPanel"
            [(showSearch)]="showSearchPanel"
            [agreementTypes]="agreementTypes"
            [value]="advancedSearch"
            (searchClick)="onSearchClicked($event)"
            (valueChange)="onSearchChanged($event)">
        </gtn-agreements-search>
    </gtn-template-grid-header>
    <gtn-template-grid-content>
        <ag-grid-angular *ngIf="gridOptions"
            class="ag-theme-balham"
            [gridOptions]="gridOptions"
            [gtnHeight]="gridHeight">
        </ag-grid-angular>
    </gtn-template-grid-content>
</gtn-template-grid>
