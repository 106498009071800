import { first } from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';

import { Observable } from 'rxjs';
import { LocationModel } from 'app/nexus-shared/domain/locations';
import { LocationService } from 'app/nexus-core/services/location.service';

@Pipe({
    name: 'country'
})
export class CountryPipe implements PipeTransform {
    constructor(private locationService: LocationService) {
    }

    transform(countryId: number): Observable<string> {
        return new Observable(observer => {
            if (countryId) {
                this.locationService.getCountry(countryId).pipe(first()).subscribe((country: LocationModel) => {
                    if (country) {
                        const response = country.name;
                        observer.next(response);
                    } else {
                        observer.next('Country Not Found');
                    }
                    observer.complete();
                });
            } else {
                observer.next('');
                observer.complete();
            }
        });
    }
}
