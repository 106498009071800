import { Component } from '@angular/core';
import { INoRowsOverlayAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'gtn-no-rows-overlay',
    templateUrl: './no-rows-overlay.component.html',
    styleUrls: ['./no-rows-overlay.component.scss']
})
export class NoRowsOverlayComponent implements INoRowsOverlayAngularComp {

    private params: any;

    agInit(params): void {
        this.params = params;
    }
}
