import { CommonBaseAuditModel } from '../../contacts/models/common-base-audit.model';

export class FolderSettingsModel extends CommonBaseAuditModel {
    public isReadOnly: boolean;
    public canAddChildFolders: boolean;
    public canAddDocuments: boolean;
    public isExternalReadOnly: boolean;
    public isRestricted: boolean;

    constructor(model: FolderSettingsModel = null) {
        super(model);
        this.isReadOnly = model?.isReadOnly ?? null;
        this.canAddChildFolders = model?.canAddChildFolders ?? null;
        this.canAddDocuments = model?.canAddDocuments ?? null;
        this.isExternalReadOnly = model?.isExternalReadOnly ?? null;
        this.isRestricted = model?.isRestricted ?? null;
    }
}
