<gtn-template-view [cardMode]="cardMode">
    <gtn-template-view-content>
        <ng-container *ngIf="viewMode === viewModes.All || viewMode === viewModes.General">
            <div class="row">
                <div class="col-4">
                    <label class="list-label">Company name</label>
                    <div class="data-field">
                        {{ value.name || noValueText }}
                    </div>
                </div>
                <div class="col-4">
                    <label class="list-label">Short name</label>
                    <div class="data-field">
                        {{ value.shortName || noValueText }}
                    </div>
                </div>
                <div class="col-4">
                    <label class="list-label">Legal name</label>
                    <div class="data-field">
                        {{ value.legalName || noValueText }}
                    </div>
                </div>

            </div>
            <div class="row">
                <div class="col-4">
                    <label class="list-label">Email domain</label>
                    <div class="data-field text-truncate">
                        {{ value.emailDomain || noValueText }}
                    </div>
                </div>
                <div class="col-4">
                    <label class="list-label">SEC registered</label>
                    <div class="data-field">
                        {{ (value.isSecRegistered | yesNoPipe) || noValueText }}
                    </div>
                </div>
                <div class="col-4">
                    <label class="list-label">Sec ticker</label>
                    <div class="data-field">
                        {{ value.secTickerSymbol || noValueText }}
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-4" *ngIf="isClient">
                    <label class="list-label">Region</label>
                    <div class="data-field">
                        {{ (value.region | enum: regionTypesEnum) || noValueText }}
                    </div>
                </div>
                <div class="col-4">
                    <label class="list-label">Company type(s)</label>
                    <ng-container *ngIf="value.companyTypes.length">
                        <div class="data-field" *ngFor="let companyType of value.companyTypes; let isLast = last">
                            {{ companyType.type | enum: companyTypesEnum }}{{ !isLast ? ', ' : '' }}
                        </div>
                    </ng-container>
                    <div *ngIf="!value.companyTypes.length" class="data-field">{{ noValueText }}</div>
                </div>
            </div>
            <ng-container *ngIf="value.phoneNumbers?.length">
                <div class="section-header">
                    Phone Numbers
                </div>
                <div class="row mb-1">
                    <div class="col-md-3">
                        <label class="list-label">Country</label>
                    </div>
                    <div class="col-md-3">
                        <label class="list-label">Phone number</label>
                    </div>
                    <div class="col-md-3">
                        <label class="list-label">Extension</label>
                    </div>
                    <div class="col-md-3">
                        <label class="list-label">Type</label>
                    </div>
                </div>
                <ng-container *ngFor="let phoneNumber of value.phoneNumbers">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="data-field">
                                {{ phoneNumber.countryCode || noValueText }}
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="data-field">
                                {{ phoneNumber.formattedPhoneNumber | gtnPhoneNumber : phoneNumber.countryCode }}
                                <span *ngIf="phoneNumber.isPrimary" matTooltip="Primary number"><i
                                    class="fa-duotone fa-phone-plus"></i></span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="data-field">
                                {{ phoneNumber.extension || noValueText }}
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="data-field">
                                {{ (phoneNumber.type | enum : phoneNumberTypes) || noValueText }}
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="viewMode === viewModes.All || viewMode === viewModes.AssociatedApplications">
            <div class="row">
                <div class="col-md-3">
                    <label class="list-label">Source application</label>
                    <div
                        class="data-field">{{ (value.applicationSourceType | enum : applicationsEnum) || noValueText }}
                    </div>
                </div>
                <div class="col-md-3">
                    <gtn-application-links [value]="value.companyToApplications"
                        [currentApplication]="application"
                        [companyKey]="value.companyKey"
                        [myGTNPortalCompanyKey]="value.myGTNPortalCompanyKey"></gtn-application-links>
                </div>
                <div class="col-md-3">
                    <label class="list-label">Portal company linked</label>
                    <div class="d-flex flex-row">
                        <span *ngIf="value.myGTNPortalCompanyKey && !myGTNPortalCompanyName"><i
                            class="fas fa-spinner fa-spin"></i></span>
                        <div class="data-field" *ngIf="value.myGTNPortalCompanyKey && myGTNPortalCompanyName">
                            {{ myGTNPortalCompanyName }}
                        </div>
                        <span class="data-field" *ngIf="!value.myGTNPortalCompanyKey">{{ noValueText }}</span>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="viewMode === viewModes.All || viewMode === viewModes.Addresses">
            <ng-container *ngIf="value.addresses?.length">
                <ng-container *ngFor="let address of value.addresses; last as isLast">
                    <gtn-address-view [value]="address" [viewMode]="viewModes.Addresses">
                    </gtn-address-view>
                    <hr *ngIf="!isLast">
                </ng-container>
            </ng-container>
            <ng-container *ngIf="!value.addresses?.length">
                No addresses defined
            </ng-container>
        </ng-container>
        <!--        <ng-container *ngIf="viewMode === viewModes.All || viewMode === viewModes.CostCenters">-->
        <!--            <ng-container *ngIf="value.costCenters?.length">-->
        <!--                <div *ngFor="let costCenter of value.costCenters">-->
        <!--                    {{costCenter.name}}-->
        <!--                </div>-->
        <!--            </ng-container>-->
        <!--            <ng-container *ngIf="!value.costCenters?.length">-->
        <!--                No cost centers defined-->
        <!--            </ng-container>-->
        <!--        </ng-container>-->

        <!--        <ng-container *ngIf="viewMode === viewModes.All || viewMode === viewModes.Integrations">-->
        <!--            <ng-container *ngIf="value.contactIntegrations?.length">-->
        <!--                <ng-container *ngFor="let contactIntegration of value.contactIntegrations; last as isLast">-->
        <!--                    <gtn-contact-integrations-view [value]="contactIntegration" [viewMode]="viewModes.Integrations">-->
        <!--                    </gtn-contact-integrations-view>-->
        <!--                    <hr *ngIf="!isLast">-->
        <!--                </ng-container>-->
        <!--            </ng-container>-->
        <!--            <ng-container *ngIf="!value.contactIntegrations?.length">-->
        <!--                No integrations defined-->
        <!--            </ng-container>-->
        <!--        </ng-container>-->
    </gtn-template-view-content>
</gtn-template-view>
