import { animateChild, AnimationMetadata, group, query, stagger, transition, trigger } from '@angular/animations';
import { ChildSlideDown } from './child-slide-down';

export class GroupBumpDown {
    /**
     * Use '@childSlide' in markup.
     */
    static animations = GroupBumpDown.getAnimation();

    private static getAnimation(): AnimationMetadata[] {
        return [trigger('groupBumpDown', [
            transition(':enter', [
                group([
                    query('@childSlide',
                        stagger(70, [
                            animateChild()
                        ]),
                        {optional: true})
                ])
            ])
        ]),
            ChildSlideDown.animations
        ];
    }
}
