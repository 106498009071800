<gtn-modal *ngIf="showModal"
    class="modal-small-responsive"
    headerText="Two-Factor Authentication Verification"
    [validationModels]="validationModels"
    [addFlexJustifyBetweenToFooter]="!showTwoFactorTypeSelector"
    (cancelClick)="onCancel2FAClicked()">
    <gtn-modal-content>
        <ng-container *ngIf="showTwoFactorTypeSelector">
            <gtn-radio-group *ngIf="twoFactorVerificationTypeOptions?.length"
                label="Two-factor notification method"
                [options]="twoFactorVerificationTypeOptions"
                (change)="onTowFactorTypeChanged($event)">
            </gtn-radio-group>
        </ng-container>

        <ng-container *ngIf="!showTwoFactorTypeSelector">
            <div class="row">
                <div class="col-12">
                    <div
                        *ngIf="!isTooManyAttemptsMessage">{{ selectedTwoFactorVerificationType.type === twoFactorVerificationTypesEnum.Email ? 'An email' : 'A text message' }}
                        was sent to <b>{{ selectedTwoFactorVerificationType.value }}</b> containing your
                        verification code.
                    </div>
                    <div *ngIf="isTooManyAttemptsMessage">{{ isTooManyAttemptsMessage }}
                        <gtn-email [email]="supportEmailAddress"></gtn-email>
                    </div>
                </div>
            </div>
            <gtn-verification-input
                [(ngModel)]="verificationNumber"
                (codeComplete)="isVerificationButtonDisabled = !$event"
                (enterClick)="onVerify2FAClicked()">
            </gtn-verification-input>
            <div *gtnIfDoesNotHaveAuthorization="authorizationConstants.portalCompanyAdmin">
                <div class="flex justify-content-end">
                    <gtn-slide-toggle [(ngModel)]="enableRememberMe" [labelPosition]="'before'"
                        label="Remember device for {{rememberDeviceInDays}} days"></gtn-slide-toggle>
                </div>
                <div class="flex justify-content-end" *ngIf="twoFactorVerificationTypeOptions.length > 1">
                    <a href="javascript:void(0)" (click)="onHaveTroubleLoggingInClicked()">Having trouble logging in?
                        Try a different method.</a>
                </div>
            </div>
        </ng-container>
    </gtn-modal-content>
    <gtn-modal-footer-left-buttons>
        <button mat-flat-button [disabled]="disableButtons"
            [ngClass]="{'cancel-button mat-button-default': !showTwoFactorTypeSelector, 'mat-accent': showTwoFactorTypeSelector}"
            (click)="onResend2FACodeClicked()"
            type="button">
            {{ showTwoFactorTypeSelector ? 'Send Code' : 'Resend Code' }}
        </button>
    </gtn-modal-footer-left-buttons>
    <gtn-modal-footer-right-buttons>
        <button *ngIf="!showTwoFactorTypeSelector" mat-flat-button
            [disabled]="disableButtons || isVerificationButtonDisabled" (click)="onVerify2FAClicked()"
            class="mr-1 mat-accent" type="button">
            Verify Code
        </button>
    </gtn-modal-footer-right-buttons>

</gtn-modal>
