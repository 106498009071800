<mat-card appearance="outlined" class="hero-number-container"
    [ngClass]="{'is-button': isButton, 'is-active': isActive, 'small': size === 'small'}">
    <h3 class="hero-number-title">{{ heroLabel }}</h3>
    <div *ngIf="!isLoading && number !== null && number !== undefined"
        class="hero-number">
        {{ currentValue }}
    </div>
    <ng-content *ngIf="!isLoading"></ng-content>
    <mat-progress-bar *ngIf="isLoading"
        mode="indeterminate"></mat-progress-bar>
</mat-card>
