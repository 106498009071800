import { BaseFormComponent } from 'app/nexus-shared/components/base-component/base-form.component';
import { Component, Input, OnInit } from '@angular/core';
import { QuestionnaireTemplateModel } from 'app/nexus-shared/domain/questionnaires/models/questionnaire-template.model';
import { UntypedFormGroup } from '@angular/forms';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { IndividualGtnOrganizerModel } from 'app/nexus-shared/domain/questionnaires/models/individual-gtn-organizer.model';
import { BaseFormModel } from 'app/nexus-shared/models/base-form.model';
import { QuestionnaireTypesEnum } from 'app/nexus-shared/domain/questionnaires/enums/questionnaire-types.enum';

@Component({ template: '' })
export abstract class BaseAssignQuestionnaireFormComponent<T> extends BaseFormComponent<T> implements OnInit {
    @Input() questionnaireTemplates: QuestionnaireTemplateModel[];
    @Input() questionnaireType: QuestionnaireTypesEnum;

    questionnaireFormGroup: UntypedFormGroup;
    showQuestionnaireTemplates: boolean = true;

    ngOnInit(configuration: BaseFormModel = null) {
        const formConfiguration = configuration ?? new BaseFormModel();
        formConfiguration.formConfiguration[ObjectHelper.nameOf<IndividualGtnOrganizerModel>('questionnaire')] = new BaseFormModel();
        super.ngOnInit(formConfiguration);
        this.showQuestionnaireTemplates = this.questionnaireType < 500;
    }

    protected initFormCustomizations() {
        this.questionnaireFormGroup = this.formGroupRef.get(ObjectHelper.nameOf<IndividualGtnOrganizerModel>('questionnaire')) as UntypedFormGroup;
        super.initFormCustomizations();
    }

}
