<mat-card appearance="outlined" *ngIf="mode !== cardModes.hidden">
    <div #headerContent [ngClass]="{'card-header': header, 'read-only-form': appearance === appearances.readonly}">
        <mat-card-title class="gtn-template-card-title" *ngIf="header">
            <ng-template [ngTemplateOutlet]="header"></ng-template>
        </mat-card-title>
        <mat-card-content class="gtn-template-card-content">
            <ng-template [ngTemplateOutlet]="content"></ng-template>
        </mat-card-content>
    </div>
</mat-card>
<div *ngIf="mode === cardModes.hidden">
    <ng-template [ngTemplateOutlet]="content"></ng-template>
</div>
