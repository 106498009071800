import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { IAgreementSearchModelInterface } from '../../../interfaces/iagreement-search-model.interface';

export class AgreementsSearchFormControlNameConstants {
    public static readonly agreementTypes = ObjectHelper.nameOf<IAgreementSearchModelInterface>('agreementTypeKeys');
    public static readonly companyKeys = ObjectHelper.nameOf<IAgreementSearchModelInterface>('companyKeys');
    public static readonly endDate = ObjectHelper.nameOf<IAgreementSearchModelInterface>('endDate');
    public static readonly expirationEndDate = ObjectHelper.nameOf<IAgreementSearchModelInterface>('expirationEndDate');
    public static readonly expirationStartDate = ObjectHelper.nameOf<IAgreementSearchModelInterface>('expirationStartDate');
    public static readonly isRequestIndependentsOnly = ObjectHelper.nameOf<IAgreementSearchModelInterface>('isRequestIndependentsOnly');
    public static readonly individualKeys = ObjectHelper.nameOf<IAgreementSearchModelInterface>('individualKeys');
    public static readonly startDate = ObjectHelper.nameOf<IAgreementSearchModelInterface>('startDate');
    public static readonly statuses = ObjectHelper.nameOf<IAgreementSearchModelInterface>('statuses');
}
