import { TableColumnModel } from 'app/nexus-shared/domain/questionnaires/models/table-column.model';
import { Guid } from 'guid-typescript';

export class TableRowModel {
    key: string;
    sectionColumnKey: string;
    order: number;
    columns: TableColumnModel[];

    constructor(model: TableRowModel = null) {
        this.key = model?.key ?? Guid.create().toString();
        this.sectionColumnKey = model?.sectionColumnKey ?? null;
        this.order = model?.order ?? null;
        this.columns = model?.columns?.length ? model.columns.map(x => new TableColumnModel(x)) : [];
    }
}
