import { Component, Input } from '@angular/core';
import { TableColumnModel } from 'app/nexus-shared/domain/questionnaires/models/table-column.model';
import { TableColumnHeaderModel } from 'app/nexus-shared/domain/questionnaires/models/table-column-header.model';
import { UserInputTypesEnum } from 'app/nexus-shared/domain/questionnaires/enums/user-input-types.enum';
import { BaseQuestionnaireFormComponent } from 'app/nexus-shared/domain/questionnaires/components/forms/base-questionnaire-form.component';

@Component({
    selector: 'gtn-table-column-view-form',
    templateUrl: './table-column-view-form.component.html',
    styleUrls: ['./table-column-view-form.component.scss', '../questionnaire-forms.component.scss']
})
export class TableColumnViewFormComponent extends BaseQuestionnaireFormComponent<TableColumnModel> {
    @Input() tableColumnHeader: TableColumnHeaderModel;
    userInputTypesEnum = UserInputTypesEnum;
}
