import { Component, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import {
    CellEvent,
    ColDef,
    ColGroupDef,
    ColumnApi,
    ColumnState,
    GridApi,
    GridOptions,
    GridReadyEvent,
    RowClassParams,
    RowDragEvent,
    RowEvent,
    RowGroupingDisplayType,
    RowHeightParams,
    RowNode,
    RowStyle
} from 'ag-grid-community';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { forkJoin, Observable, Subject } from 'rxjs';
import { debounceTime, takeWhile, tap } from 'rxjs/operators';
import { AgGridHelper, ArrayHelper, ResizeService } from 'app/nexus-core';
import { CustomLoadingOverlayComponent } from 'app/nexus-shared/components/controls/components/base-grid/custom-loading-overlay/custom-loading-overlay.component';
import { NoRowsOverlayComponent } from 'app/nexus-shared/components/controls/components/base-grid/no-rows-overlay/no-rows-overlay.component';
import { ColorConstants } from 'app/nexus-shared/constants/colors.constants';
import { CustomRowPropertyConstants } from 'app/nexus-shared/components/controls/components/base-grid/shared/constants/custom-row-property.constants';
import { environment } from 'environments/environment';
import { AgGridFilterHelper } from 'app/nexus-core/helpers/ag-grid-filter.helper';
import { FilterModel } from 'app/nexus-shared/components/controls/shared/models/filter-model';
import { FilterTypeEnum } from 'app/nexus-shared/components/controls/shared/enums/filter-type.enum';
import { ApplicationsEnum, SelectListInterface } from 'app/nexus-shared/index';
import { UserSettingsService } from 'app/nexus-core/services/domain/core/user-settings.service';
import { UserSettingsModel } from 'app/nexus-shared/domain/users/models/user-settings.model';
import { SimpleChangesTyped } from 'app/nexus-shared/models/simple-changes-typed.type';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';

@Component({
    selector: 'gtn-grid',
    templateUrl: './grid.component.html',
    styleUrls: ['./grid.component.scss']
})
export class GridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
    @Input() gridHeight: number | string;
    @Input() minGridHeight: number = AgGridHelper.minGridHeight;
    @Input() rowHeight: number = AgGridHelper.rowHeight;
    @Input() heightOffset: number;
    @Input() columnDefs: ColDef[] | ColGroupDef[];
    @Input() defaultSortModel: { colId: string, sort: 'asc' | 'desc' }[];
    @Input() data: any[];
    @Input() dataFetch: Observable<any[]>;
    @Input() additionalDataFetch: Function;
    @Input() dataTransform: Function;
    @Input() isCustomizedTheme: boolean = true;

    @Input() quickFilterText: string;
    @Input() defaultColDef: ColDef;
    @Input() editType: 'fullRow';
    @Input() fetchDataOnInit = true;
    @Input() fetchDataOnChanges = false;
    @Input() components: { [name: string]: ICellEditorAngularComp };
    @Input() gtnFilters: FilterModel[];
    @Input() hasRowStriping = true;
    @Input() hasBorder = true;
    @Input() headerHeight: number;
    @Input() isAllowClick: boolean = true;
    @Input() isRowClickable = false;

    @Input() isAllowRowSelection: boolean = false;
    @Input() isDraggable: boolean = false;
    @Input() isFullRowDrag: boolean = false;
    @Input() isEditable: boolean;
    @Input() isFilterPanelCollapsed: boolean = true;
    @Input() isLoading: boolean;
    @Input() isDisplayForPrint: boolean = false;
    @Input() suppressScrollOnNewData: boolean;
    @Input() suppressClickEdit: boolean;
    @Input() rowDragManaged: boolean = true;
    @Input() showRowCount: boolean;
    @Input() sizeColumnsToFit: boolean = true;
    @Input() isTreeData: boolean = false;
    @Input() autoGroupColumnDef: object = null;
    @Input() groupDefaultExpanded: number = -1;
    @Input() groupDisplayType: RowGroupingDisplayType;
    @Input() groupRowRendererParams: any;
    @Input() showOpenedGroup: boolean = false;
    @Input() groupIncludeTotalFooter: boolean = false;
    @Input() animateRows: boolean = false;
    @Input() stopEditingWhenCellsLoseFocus: boolean = false;
    @Input() suppressAggFunctionHeader: boolean = true;

    @Input() aggregateColumnIds: string[];
    @Input() ensureNodeVisible: Function;
    @Input() getRowStyle: (params: RowClassParams) => RowStyle;
    @Input() getRowHeight: (params: RowHeightParams) => number;
    @Input() getDataPath: (data: any) => string[];

    // Grid state
    @Input() gridStateKey: string;
    @Input() application: ApplicationsEnum = ApplicationsEnum.Global;

    // FILTER PANEL
    @Input() enableFilterPanel: boolean = false;
    @Input() addButtonTitle: string = null;
    @Input() showAddButton = true;

    @Output() addButtonClick = new EventEmitter<void>();
    @Output() filterPanelToggle = new EventEmitter<boolean>();

    @Output() aggregateValues: EventEmitter<any[]> = new EventEmitter();
    @Output() cellEditingStarted: EventEmitter<CellEvent> = new EventEmitter();
    @Output() cellEditingStopped: EventEmitter<CellEvent> = new EventEmitter();
    @Output() cellClick: EventEmitter<CellEvent> = new EventEmitter();
    @Output() loadingComplete: EventEmitter<any> = new EventEmitter();
    @Output() rowClick: EventEmitter<RowEvent> = new EventEmitter();
    @Output() rowDragEnd: EventEmitter<RowDragEvent> = new EventEmitter();
    @Output() rowDragStart: EventEmitter<RowDragEvent> = new EventEmitter();
    @Output() rowEditingStarted: EventEmitter<RowEvent> = new EventEmitter();
    @Output() rowEditingStopped: EventEmitter<RowEvent> = new EventEmitter();
    @Output() rowValueChanged: EventEmitter<RowEvent> = new EventEmitter();
    @Output() rowDragEnded: EventEmitter<RowDragEvent> = new EventEmitter();
    @Output() gridIsReady: EventEmitter<GridOptions> = new EventEmitter();

    @ViewChild('gridContainer') gridContainer: ElementRef;

    public readonly clickableCellClass = 'clickable-cell';
    public columnApi: ColumnApi;
    public gridOptions: GridOptions;
    public gridApi: GridApi;
    public rowCount: number = null;
    public actualGridHeight: number | string;
    public auto = 'auto';
    public gtnAppliedFilters: SelectListInterface[];
    public showCreateButton = false;
    public isGridStateChanged = false;

    private readonly saveStateThrottleInMs = 2000;
    private gridSettings: any;
    private fetchedData: any[];
    private throttledChanges: NodeJS.Timeout;
    private globalChanges: SimpleChangesTyped<this> = <SimpleChangesTyped<this>>{};
    private originalColumnState: ColumnState[];

    private saveState: Subject<void> = new Subject<void>();
    // temp - move to static list
    private readonly saveStateEvents: string[] = [
        'filterChanged',
        'sortChanged',
        'columnVisible',
        'columnMoved',
        'gridColumnsChanged',
        'displayedColumnsChanged',
        'columnResized'
    ];

    constructor(
        private ngZone: NgZone,
        private userSettingsService: UserSettingsService,
        private resizeService: ResizeService
    ) {
        super();

        this.subscriptions.add(this.resizeService.resize.subscribe(() => this.resizeGrid()));
    }

    ngOnInit(): void {
        if (!this.data && !this.dataFetch && !this.isLoading) {
            throw new Error('Either data or dataFetch must be passed');
        }

        this.actualGridHeight = this.gridHeight;
        this.showCreateButton = this.addButtonClick.observers.length && this.showAddButton;

        this.setGridDefinition();
    }

    ngOnChanges(newChanges: SimpleChangesTyped<this>): void {
        this.globalChanges = Object.assign({}, this.globalChanges, newChanges);

        if (newChanges.isLoading && !newChanges.isLoading.firstChange) {
            if (this.isLoading) {
                this.gridApi?.showLoadingOverlay();
            } else {
                this.gridApi?.hideOverlay();
            }
        }

        if (newChanges.headerHeight && !newChanges.headerHeight.firstChange && this.gridOptions?.api) {
            this.gridOptions.api.setHeaderHeight(newChanges.headerHeight.currentValue);
        }

        // throttle ngOnChanges, otherwise multiple changes can come in and can cause a race condition
        if (this.throttledChanges) {
            if (!environment().production) {
                console.warn('GridComponent.ngOnChanges is being throttled.');
            }
            clearTimeout(this.throttledChanges);
        }

        this.throttledChanges = setTimeout(() => {
            this.handleChanges(this.globalChanges);
            this.throttledChanges = null;
        }, 100);
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();

        this.gridApi = null;
        this.gridOptions = null;
    }

    public applyDataTransform(): void {
        if (this.dataTransform) {
            this.setRowData(this.dataTransform(this.fetchedData));
        }
    }

    public calculateAggregates(): void {
        if (this.aggregateColumnIds) {
            const gridOptionsApi = this.gridApi,
                aggregateValues: any = {};

            this.aggregateColumnIds.forEach(id => {
                const target: any = {};

                gridOptionsApi.forEachNodeAfterFilter((rowNode: RowNode) => {
                    const value: number = +rowNode.data[id];
                    if (value !== null && !rowNode['pendingDelete']) {
                        if (typeof target[id] === 'undefined') {
                            target[id] = value;
                        } else {
                            target[id] += value;
                        }
                    }
                });

                if (target) {
                    aggregateValues[id] = target[id];
                }
            });

            this.aggregateValues.emit(aggregateValues);
        } else {
            this.aggregateValues.emit(null);
        }
    }

    public onAddButtonClicked(): void {
        this.addButtonClick.emit();
    }

    public onFilterPanelToggleClicked(): void {
        this.filterPanelToggle.emit();
    }

    public onResetGridStateClicked(): void {
        this.gridOptions.columnApi.applyColumnState({ state: this.originalColumnState, applyOrder: true });
        if (this.enableFilterPanel) {
            this.onFiltersCleared();
        }
        this.resizeGrid();
    }

    public onFiltersApplied(): void {
        const filter = {};
        this.gtnAppliedFilters = [];
        this.gtnFilters?.forEach((gtnFilter) => {
            if (gtnFilter.isFilterActive) {
                filter[gtnFilter.field] = gtnFilter.filterObject;
                if (gtnFilter.appliedFilter) {
                    this.gtnAppliedFilters.push(gtnFilter.appliedFilter);
                }
            }
        });
        this.gridApi.setFilterModel(filter);
    }

    public onFiltersCleared(): void {
        this.createGtnFilters(this.data || this.fetchedData, false, null);
        this.gtnAppliedFilters = [];
        this.gridApi.setFilterModel(null);
    }

    public onFilterRemoved(event): void {
        const filterIndex = this.gtnFilters.findIndex(x => x.field === event.id);
        this.gtnFilters[filterIndex] = AgGridFilterHelper.createFilter(this.data || this.fetchedData, this.columnApi.getColumn(event.id).getColDef(), null);
        if (this.gtnFilters[filterIndex].controlType === FilterTypeEnum.ActiveDateStart) {
            const i = this.gtnFilters.findIndex(x => x.controlType === FilterTypeEnum.ActiveDateEnd);
            this.gtnFilters[i] = AgGridFilterHelper.createFilter(this.data || this.fetchedData, this.columnApi.getColumn(this.gtnFilters[i].field).getColDef(), null);
        }
        this.onFiltersApplied();
    }

    public onIsFilterPanelCollapsedChange(value: boolean): void {
        this.filterPanelToggle.emit(value);
    }

    public refreshData(redraw: boolean = false): void {
        if (this.data) {
            this.setRowData(this.data);
            if (this.gridStateKey) {
                this.setGridSettings().subscribe(_ => {
                    this.initGridState(this.data);
                });
            } else {
                if (this.enableFilterPanel) {
                    this.createGtnFilters(this.data, true, null);
                }
                this.resizeGrid();
            }
        } else if (this.dataFetch) {
            const observables: Observable<any>[] = [];
            observables.push(this.dataFetchFn(redraw));

            if (this.gridStateKey) {
                observables.push(this.setGridSettings());
            }

            forkJoin(observables).subscribe((responses) => {
                if (observables[1]) {
                    this.initGridState(responses[0]);
                } else if (this.enableFilterPanel) {
                    this.createGtnFilters(responses[0], true, null);
                    this.resizeGrid();
                }
            });
        }
    }

    public resizeGrid(): void {
        if (this.gridApi && this.sizeColumnsToFit) {
            setTimeout(() => {
                if (this.gridApi) {
                    // This stops the grid from sizing if there is no width.
                    if (this.gridContainer && this.gridContainer.nativeElement.offsetWidth > 0) {
                        this.gridApi.sizeColumnsToFit();
                    }
                }
            });
        }
    }

    private createGtnFilters(data: any[], resetColumnDefs: boolean, appliedFilters = null) {
        this.gtnFilters = [];
        this.columnDefs.forEach((col: ColDef) => {
            col.suppressMenu = true;
            if (col.filter && col.filterParams?.type) {
                const appliedFilter = appliedFilters && appliedFilters[col.field] ? appliedFilters[col.field] : null;
                this.gtnFilters.push(AgGridFilterHelper.createFilter(data, col, appliedFilter));
                if (col.filterParams.type >= FilterTypeEnum.ActiveDateStart && col.filterParams.type <= FilterTypeEnum.Datepicker) {
                    col.filterParams.comparator = col.filterParams.comparator ? col.filterParams.comparator : AgGridHelper.dateFilterComparator;
                    col.filterParams.includeBlanksInLessThan = col.filterParams.hasOwnProperty('includeBlanksInLessThan') ? col.filterParams.includeBlanksInLessThan : true;
                    col.filterParams.includeBlanksInGreaterThan = col.filterParams.hasOwnProperty('includeBlanksInGreaterThan') ? col.filterParams.includeBlanksInGreaterThan : true;
                    col.filterParams.includeBlanksInRange = col.filterParams.hasOwnProperty('includeBlanksInRange') ? col.filterParams.includeBlanksInRange : true;
                }
            }
        });
        if (resetColumnDefs && this.gridApi) {
            this.gridApi.setColumnDefs(this.columnDefs);
        }
    }

    private handleChanges(changes: SimpleChangesTyped<this>) {
        if (changes.data && this.data) {
            this.refreshData();
        } else if (!(changes?.dataFetch?.isFirstChange() && this.fetchDataOnInit) && this.fetchDataOnChanges && changes.dataFetch && this.gridApi) {
            this.refreshData();
        }

        if (changes.quickFilterText && this.gridOptions) {
            this.gridApi.setQuickFilter(this.quickFilterText);
        }

        if (changes.suppressScrollOnNewData && this.gridOptions) {
            this.gridOptions.suppressScrollOnNewData = this.suppressScrollOnNewData;
        }

        if (changes.components && this.gridOptions) {
            this.gridOptions.components = this.components;
        }

        if (changes.editType && this.gridOptions) {
            this.gridOptions.editType = this.editType;
        }

        if (changes.aggregateColumnIds &&
            !changes.aggregateColumnIds.firstChange &&
            changes.aggregateColumnIds.currentValue !== changes.aggregateColumnIds.previousValue &&
            changes.aggregateColumnIds.currentValue) {
            this.calculateAggregates();
        }

        if (!changes.data?.firstChange || !changes.columnDefs?.firstChange && this.gridApi) {
            if (!this.data?.length && !this.columnDefs?.length) {
                this.gridApi.setRowData([]);
            }
        }

        if (changes.defaultSortModel && this.gridApi) {
            this.columnApi.applyColumnState({ state: this.defaultSortModel });
        }

        if (changes.columnDefs && !changes.columnDefs.firstChange && this.columnDefs?.length && this.gridApi) {
            this.assignColId();
            this.gridApi.setColumnDefs(this.columnDefs);

            if (changes.columnDefs.currentValue !== changes.columnDefs.previousValue && changes.columnDefs.previousValue.length > 0) {
                // Refresh the cells if any columnDef updates have been made.
                this.gridApi.refreshCells({ force: true });
            }
        }

        if (changes.isDisplayForPrint && this.gridApi) {
            this.setDomLayout();
        }

        if (changes.rowHeight && this.gridApi) {
            this.gridOptions.rowHeight = this.rowHeight;
        }

        this.globalChanges = <SimpleChangesTyped<this>>{};
    }

    private setRowData(data: any[]): void {
        if (this.gridApi) {
            if (!data || data.length === 0) {
                if (!this.isLoading) {
                    this.gridApi.setRowData([]);
                    this.gridApi.showNoRowsOverlay();
                }
            } else {
                this.gridApi.setRowData(data);
                this.gridApi.hideOverlay();

                if (this.enableFilterPanel) {
                    this.createGtnFilters(data, true, null);
                }
            }

            this.rowCount = data?.length;
        }
    }

    private assignColId() {
        // if columnDefs get rebound to the grid, then they need to have matching colId,
        // otherwise ag-grid will append "_n" to the colId which causes issues with sorting (https://www.ag-grid.com/javascript-grid-column-definitions/#column-ids)
        this.columnDefs.forEach(columnDef => {
            if (!columnDef.colId) {
                columnDef.colId = columnDef.field;
            }

            if (columnDef.children?.length) {
                columnDef.children.forEach(child => {
                    if (!child.colId) {
                        child.colId = child.field;
                    }
                });
            }
        });
    }

    private getGridOptions(): GridOptions {
        this.assignColId();
        return {
            columnDefs: this.columnDefs || [],
            defaultColDef: {
                sortable: this.isAllowClick,
                filter: this.isAllowClick,
                resizable: this.isAllowClick,
                suppressMovable: !this.isAllowClick
            },
            editType: this.editType,
            enableBrowserTooltips: true,
            getRowHeight: this.getRowHeight,
            getRowStyle: this.getRowStyle || ((styleParams) => {
                if (styleParams.node[CustomRowPropertyConstants.pendingDelete]) {
                    return { background: ColorConstants.deletePending, 'border-color': ColorConstants.borderColor };
                }
                if (styleParams.node[CustomRowPropertyConstants.pendingEdit]) {
                    return { background: ColorConstants.editPending, 'border-color': ColorConstants.borderColor };
                }
                if (styleParams.node[CustomRowPropertyConstants.pendingAdd]) {
                    return { background: ColorConstants.editPending, 'border-color': ColorConstants.borderColor };
                }

            }),
            components: this.components,
            rowSelection: this.isAllowRowSelection ? 'single' : null,
            rowData: this.data || null,
            rowDragManaged: this.rowDragManaged,
            rowHeight: this.rowHeight,
            suppressRowHoverHighlight: !this.isAllowClick,
            suppressScrollOnNewData: this.suppressScrollOnNewData,
            suppressClickEdit: this.suppressClickEdit,
            suppressCellFocus: true,
            suppressContextMenu: true,
            treeData: this.isTreeData,
            groupDefaultExpanded: this.groupDefaultExpanded,
            groupDisplayType: this.groupDisplayType,
            groupRowRendererParams: this.groupRowRendererParams,
            autoGroupColumnDef: this.autoGroupColumnDef,
            showOpenedGroup: this.showOpenedGroup,
            groupIncludeTotalFooter: this.groupIncludeTotalFooter,
            animateRows: this.animateRows,
            stopEditingWhenCellsLoseFocus: this.stopEditingWhenCellsLoseFocus,
            suppressAggFuncInHeader: this.suppressAggFunctionHeader,
            getDataPath: this.isTreeData ? this.getDataPath : null,

            onCellClicked: this.isAllowClick ? event => this.cellClick.emit(event) : _ => {
            },

            onRowClicked: this.isAllowClick
                // this requires NgZone, otherwise angular will throw an error and parent components will not be able to navigate to other pages
                ? event => this.ngZone.run(() => this.rowClick.emit(event))
                : _ => {
                },
        };
    }

    private setGridDefinition(): void {
        this.applyHoverStylingToColumnDefs();
        this.gridOptions = this.getGridOptions();

        this.addCustomOverlays();

        if (this.isEditable) {
            this.addEditingEvents();
        }

        if (this.isDraggable) {
            this.addDragEvents();
        }

        if (this.rowDragEnded) {
            this.gridOptions.onRowDragEnd = ($event: RowDragEvent) => {
                this.rowDragEnded.emit($event);
            };
        }

        this.gridOptions.onGridReady = (params: GridReadyEvent) => {
            this.gridApi = params.api;
            this.columnApi = params.columnApi;
            this.columnApi.applyColumnState({ state: this.defaultSortModel });

            if (this.fetchDataOnInit) {
                this.refreshData();
            }

            if (this.isLoading) {
                this.gridApi?.showLoadingOverlay();
            }
            this.originalColumnState = this.columnApi.getColumnState();

            if (this.gridIsReady) {
                this.gridIsReady.emit(this.gridOptions);
            }

            this.setDomLayout();
        };
    }

    private applyHoverStylingToColumnDefs() {
        // only apply styling if click is allowed
        if (this.isAllowClick) {

            // if row has click handler then apply row styling for hover
            if (this.rowClick.observers.length > 0) {
                this.isRowClickable = true;
            }

            // if cells have click handlers then apply cell styling for hover
            this.applyCellHoverStylingToClickableColumnDefs(this.columnDefs);
            const childColumnDefs = this.columnDefs?.map(c => c.children).filter(c => c);
            if (childColumnDefs?.length) {
                this.applyCellHoverStylingToClickableColumnDefs(ArrayHelper.flatten(childColumnDefs));
            }
        }
    }

    private applyCellHoverStylingToClickableColumnDefs(columnDefs: ColDef[]) {
        const clickableColumnDefs = columnDefs.filter(colDef => colDef.onCellClicked && !colDef.hide);
        if (clickableColumnDefs.length > 0) {
            clickableColumnDefs.forEach(clickableColumnDef => {
                // skip any columns that have a function defined as their cell class
                if (typeof clickableColumnDef.cellClass !== 'function') {
                    if (!clickableColumnDef.cellClass) {
                        clickableColumnDef.cellClass = [this.clickableCellClass];
                    } else if (Array.isArray(clickableColumnDef.cellClass)) {
                        clickableColumnDef.cellClass.push(this.clickableCellClass);
                    } else {
                        clickableColumnDef.cellClass = [clickableColumnDef.cellClass, this.clickableCellClass];
                    }
                }
            });
        }
    }

    private addCustomOverlays(): void {
        this.gridOptions.loadingOverlayComponent = 'customLoadingOverlay';
        this.gridOptions.noRowsOverlayComponent = 'customNoRowsOverlay';
        if (!this.gridOptions.components) {
            this.gridOptions.components = {
                customLoadingOverlay: CustomLoadingOverlayComponent,
                customNoRowsOverlay: NoRowsOverlayComponent,
            };
        } else {
            this.gridOptions.components['customLoadingOverlay'] = CustomLoadingOverlayComponent;
            this.gridOptions.components['customNoRowsOverlay'] = NoRowsOverlayComponent;
        }
    }

    private addDragEvents() {
        this.gridOptions.onRowDragEnd = (this.onDragEnd).bind(this);
        this.gridOptions.onRowDragEnter = (this.onDragStart).bind(this);
    }

    private addEditingEvents() {
        this.gridOptions.onCellEditingStarted = (this.onCellEditingStarted).bind(this);
        this.gridOptions.onCellEditingStopped = (this.onCellEditingStopped).bind(this);
        this.gridOptions.onRowEditingStarted = (this.onRowEditingStarted).bind(this);
        this.gridOptions.onRowEditingStopped = (this.onRowEditingStopped).bind(this);
        this.gridOptions.onRowValueChanged = (this.onRowValueChanged).bind(this);
    }

    private onCellEditingStarted(event: any): void {
        this.cellEditingStarted.emit(event);
    }

    private onCellEditingStopped(event: any): void {
        this.cellEditingStopped.emit(event);
    }

    private onRowEditingStarted(event: any): void {
        this.rowEditingStarted.emit(event);
    }

    private onRowEditingStopped(event: any): void {
        this.rowEditingStopped.emit(event);
    }

    private onRowValueChanged(event: any): void {
        this.rowValueChanged.emit(event);
    }

    private onDragEnd(event: RowDragEvent): void {
        this.rowDragEnd.emit(event);
    }

    private onDragStart(event: RowDragEvent): void {
        this.rowDragStart.emit(event);
    }

    private setDomLayout() {
        if (this.isDisplayForPrint) {
            this.actualGridHeight = null;
            this.gridApi.setDomLayout('print');
        } else {
            this.actualGridHeight = this.gridHeight;
            this.gridApi.setDomLayout(null);
        }
    }

    private dataFetchFn(redraw): Observable<any> {
        return this.dataFetch.pipe(tap((data) => {
            this.fetchedData = data;

            if (this.dataTransform) {
                data = this.dataTransform(data);
            }

            this.setRowData(data);

            if (this.additionalDataFetch) {
                this.additionalDataFetch(data, this.gridOptions);
            }

            this.gridApi?.hideOverlay();
            this.loadingComplete.emit(data);

            if (this.aggregateColumnIds) {
                this.calculateAggregates();
            }

            if (redraw) {
                this.gridApi?.redrawRows();
            }
        },
            error => {
                console.error(error);
                this.gridApi?.hideOverlay();
                this.loadingComplete.emit(null);

            }));
    }

    private initGridState(data): void {
        this.saveStateEvents.forEach((type) => {
            if (this.gridApi) {
                this.gridApi.addEventListener(type, () => {
                    this.saveState.next();
                });
            }
        });

        this.subscriptions.add(this.saveState.pipe(debounceTime(this.saveStateThrottleInMs), takeWhile(() => {
            return (!!(this.gridOptions && this.gridOptions.columnApi && this.gridOptions.api));
        }), tap(() => {
            const newState = {
                val: {
                    columnState: this.gridOptions.columnApi.getColumnState(),
                    filterModel: this.gridOptions.api.getFilterModel()
                }
            };
            this.gridSettings = Object.assign(this.gridSettings || {}, newState);
            this.saveGridSettings();
            this.compareSettingsWithColumnState();
        })).subscribe());

        if (this.enableFilterPanel) {
            this.createGtnFilters(data, true, this.gridSettings?.val?.filterModel);
            this.gtnAppliedFilters = [];
            this.gtnFilters?.forEach((gtnFilter) => {
                if (gtnFilter.isFilterActive && gtnFilter.appliedFilter) {
                    this.gtnAppliedFilters.push(gtnFilter.appliedFilter);
                }
            });
        }
        this.applyGridState();
    }

    private applyGridState(): void {
        if (this.gridSettings && this.gridOptions && this.gridApi) {
            this.gridOptions.columnApi.applyColumnState({ state: this.gridSettings.val.columnState, applyOrder: true });
            this.gridApi.setFilterModel(this.gridSettings.val.filterModel);
            this.compareSettingsWithColumnState();
        }
        this.resizeGrid();
    }

    private setGridSettings(): Observable<any> {
        return this.userSettingsService.get(this.gridStateKey, this.application).pipe(tap((userSettings: UserSettingsModel) => {
            if (userSettings) {
                this.gridSettings = userSettings.value;
            }
        }));
    }

    private compareSettingsWithColumnState(): void {
        let edited = false;
        if (Object.keys(this.gridSettings.val.filterModel)?.length) {
            edited = true;
        } else {
            const gridSettingsArray = JSON.parse(JSON.stringify(this.gridSettings.val.columnState));
            const originalColumnState = JSON.parse(JSON.stringify(this.originalColumnState));
            for (let i = 0; i < gridSettingsArray.length; i++) {
                delete gridSettingsArray[i].width;
                delete originalColumnState[i].width;
                Object.keys(originalColumnState[i]).forEach((key) => {
                    if (originalColumnState[i][key] !== gridSettingsArray[i][key]) {
                        edited = true;
                    }
                });
                if (edited) {
                    break;
                }
            }
        }
        this.isGridStateChanged = edited;
    }


    private saveGridSettings(): void {
        const userSettings: UserSettingsModel = <UserSettingsModel>{
            application: this.application,
            key: this.gridStateKey,
            value: this.gridSettings
        };
        this.userSettingsService.upsert(userSettings).subscribe(_ => {
        }, error => {
            console.error(error);
        });
    }
}
