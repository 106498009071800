import { IndividualModel } from '../../contacts/models';
import { CommonBaseAuditModel } from '../../contacts/models/common-base-audit.model';
import { AgreementStatusEnum } from '../enums/agreement-status.enum';
import { AgreementReferentialValueModel } from '../models/agreement-referential-value.model';
import { IAgreementSignatureModelInterface } from './iagreement-signature-model.interface';
import { IAgreementTypeModelInterface } from './iagreement-type-model.interface';
import { IAgreementsCompanyModelInterface } from './iagreements-company-model.interface';

export interface IAgreementModelInterface extends CommonBaseAuditModel {
    agreementKey: string;
    agreementNumber: number;
    agreementTypeKey: string;
    templateHistoryKey: string;
    expirationDateTime: Date | string;
    name: string;
    reviewerDeclineReason?: string;
    reviewerIndividualKey?: string;
    signedAgreementFilePath: string;
    signedDateTime: Date | string;
    signerCompanyKey: string;
    signerIndividualKey: string;
    signerNotificationDateTime: Date | string;
    status: AgreementStatusEnum;

    agreementType: IAgreementTypeModelInterface;
    agreementReferentialValues: AgreementReferentialValueModel[];
    company: IAgreementsCompanyModelInterface;
    individual: IndividualModel;
    signatures: IAgreementSignatureModelInterface[];
}
