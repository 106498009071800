import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TaxWithholdingTypeModel } from 'app/nexus-shared/domain/taxes/models';
import { environment } from 'environments/environment';
import { BaseHttpService } from 'app/nexus-core/services/index';

@Injectable()
export class TaxWithholdingTypeService {
    private withholdingTypes: Observable<TaxWithholdingTypeModel[]>;

    constructor(private baseHttpService: BaseHttpService) {
    }

    list(): Observable<TaxWithholdingTypeModel[]> {
        if (!this.withholdingTypes) {
            this.withholdingTypes = this.baseHttpService.get(`${this.apiUrl()}taxWithholdingTypes`);
        }

        return this.withholdingTypes;
    }

    private apiUrl = () => environment().webAPIUrls.taxes;
}
