<gtn-file-drag-drop class="drag-and-drop"
    *ngIf="isDragAndDrop && (isMultiSelect || !fileToBeUploaded)"
    [isMultiSelect]="isMultiSelect"
    [hint]="isMultiSelect ? 'Drag files here' : 'Drag file here'"
    (fileEmitter)="onDragAndDropFiles($event)">
</gtn-file-drag-drop>
<ng-container *ngIf="isUploadButton">
    <div [ngClass]="{'upload-drag-and-drop': isDragAndDrop}">
        <div class="file-input" *ngIf="!fileToBeUploaded">
            <label for="file-upload" class="file-upload">{{ label }}</label>
            <input id="file-upload" type="file" (change)="onFileChanged($event)" [multiple]="isMultiSelect"/>
        </div>
    </div>
</ng-container>
<mat-chip-set *ngIf="fileToBeUploaded && showFilesToBeUploaded">
    <mat-chip>{{ fileToBeUploaded }}
        <i class="material-icons" matTooltip="Remove" matChipRemove
            (click)="onRemoveFileClicked()">cancel</i>
    </mat-chip>
</mat-chip-set>

