import { BaseFormComponent } from 'app/nexus-shared/components/base-component/base-form.component';
import { Component, Input, OnInit } from '@angular/core';
import { IQuestionnaireDropdownServiceInterface } from 'app/nexus-shared/domain/questionnaires/interfaces/iquestionnaire-dropdown-service.interface';
import { BaseFormModel } from 'app/nexus-shared/models/base-form.model';

@Component({ template: '' })
export abstract class BaseQuestionnaireFormComponent<T> extends BaseFormComponent<T> implements OnInit{
    @Input() questionnaireDropdownService: IQuestionnaireDropdownServiceInterface;
    @Input() isTemplateMode: boolean = true;
    @Input() isEditMode: boolean = false;
    @Input() sectionKey: string;

    ngOnInit(configuration: BaseFormModel = null, isReload: boolean = false) {
        super.ngOnInit(configuration, isReload);
    }
}
