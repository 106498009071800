import { Injectable } from '@angular/core';

import { DomService } from 'app/nexus-core/services/dom.service';
import { DynamicComponentConfigInterface } from 'app/nexus-shared/interfaces';

@Injectable()
export class NexusModalService {
    private isErrorModalShowing = true;
    private isInitializingSecond = false;
    private modalElementId = 'modal-container';
    private overlayElementId = 'overlay';

    private componentRef: any;

    constructor(private domService: DomService) {
    }

    init(component: any, inputs: object, outputs: object, toolInjector: any = null): void {
        const componentConfig: DynamicComponentConfigInterface = {
            inputs: inputs,
            outputs: outputs
        };

        if (this.componentRef) {
            if (this.isErrorModalShowing) {
                // TODO - ERROR HANDLING - This will need to append the error messages to the existing componentRef
                // as they are coming in.This will allow a single error modal to handle multiple errors received sequentially.
                // Instead of cascading error modals.
            }
            this.isInitializingSecond = true;
            setTimeout(() => {
                this.componentRef = this.domService.appendComponentTo(this.modalElementId, component, componentConfig, toolInjector);
            }, 100);

        } else {
            this.isErrorModalShowing = component.name === 'ErrorModalComponent' ? true : false;
            this.componentRef = this.domService.appendComponentTo(this.modalElementId, component, componentConfig, toolInjector);

            document.getElementById(this.overlayElementId).classList.remove('d-none');

            setTimeout(() => {
                document.getElementById(this.modalElementId).className = 'modal-shown';
                document.getElementById(this.overlayElementId).classList.add('overlay-shown');
            }, 100);
        }
    }

    destroy(): void {
        if (!this.isInitializingSecond) {
            document.getElementById(this.modalElementId).className = 'modal-hidden';
            document.getElementById(this.overlayElementId).classList.remove('overlay-shown');

            setTimeout(() => {
                this.domService.removeComponent(this.componentRef);
                this.componentRef = null;
                document.getElementById(this.overlayElementId).classList.add('d-none');
            }, 300);
            this.isErrorModalShowing = false;
        } else if (!this.isErrorModalShowing) {
            this.isInitializingSecond = false;
            this.domService.removeComponent(this.componentRef);
            this.componentRef = null;
        } else {
            this.isInitializingSecond = false;
        }
    }

    update(inputs: object): void {
        const componentConfig: DynamicComponentConfigInterface = {
            inputs: inputs,
            outputs: null
        };

        if (this.componentRef) {
            this.domService.updateComponent(componentConfig, this.componentRef);
        }
    }
}
