import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseFormComponent } from 'app/nexus-shared/components/base-component/base-form.component';
import { AddressModel } from 'app/nexus-shared/domain/contacts/models/address.model';
import { SelectListInterface } from 'app/nexus-shared/interfaces';
import { EnumHelper } from 'app/nexus-core';
import { AddressTypeEnum } from 'app/nexus-shared/enums/address-type.enum';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';

@Component({
    selector: 'gtn-address-form',
    templateUrl: './address-form.component.html',
    styleUrls: ['./address-form.component.scss'],
})

export class AddressFormComponent extends BaseFormComponent<AddressModel> implements OnInit {

    @Input() addressTypeOptions: SelectListInterface[] = EnumHelper.convertToSelectList(AddressTypeEnum, false, false);
    @Input() isModalView: boolean = false;

    @Output() addressTypeChange: EventEmitter<AddressTypeEnum> = new EventEmitter<AddressTypeEnum>();
    @Output() removeAddressClick: EventEmitter<void> = new EventEmitter<void>();

    isGoogleLocationSelected: boolean = false;
    isManualAddress: boolean = false;
    addressTypes = AddressTypeEnum;

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.isGoogleLocationSelected = !!this.formGroupRef.get(ObjectHelper.nameOf<AddressModel>('googlePlaceId'))?.value;
        this.isManualAddress = !this.isGoogleLocationSelected && !!this.formGroupRef.get(ObjectHelper.nameOf<AddressModel>('addressKey'))?.value;
    }

    onAddManualAddressClicked(): void {
        this.formGroupRef.reset();
        this.isManualAddress = true;
    }

    onRemoveAddressClicked(): void {
        this.removeAddressClick.emit();
    }

    onGoogleLocationClicked(address: AddressModel) {
        if (address?.googlePlaceId) {
            this.isGoogleLocationSelected = true;
            this.isManualAddress = false;
            this.mapGoogleForm(address);
        } else {
            this.isGoogleLocationSelected = false;
            this.formGroupRef.reset();
        }

    }

    private mapGoogleForm(address: AddressModel): void {
        this.formGroupRef.get(ObjectHelper.nameOf<AddressModel>('fullAddress')).setValue(address.fullAddress);
        this.formGroupRef.get(ObjectHelper.nameOf<AddressModel>('addressLineOne')).setValue(address.addressLineOne);
        this.formGroupRef.get(ObjectHelper.nameOf<AddressModel>('countryName')).setValue(address.countryName);
        this.formGroupRef.get(ObjectHelper.nameOf<AddressModel>('countryCode')).setValue(address.countryCode);
        this.formGroupRef.get(ObjectHelper.nameOf<AddressModel>('stateName')).setValue(address.stateName);
        this.formGroupRef.get(ObjectHelper.nameOf<AddressModel>('stateCode')).setValue(address.stateCode);
        this.formGroupRef.get(ObjectHelper.nameOf<AddressModel>('city')).setValue(address.city);
        this.formGroupRef.get(ObjectHelper.nameOf<AddressModel>('postalCode')).setValue(address.postalCode);
        this.formGroupRef.get(ObjectHelper.nameOf<AddressModel>('addressLineTwo')).setValue(address.addressLineTwo);
        this.formGroupRef.get(ObjectHelper.nameOf<AddressModel>('googlePrimaryType')).setValue(address.googlePrimaryType);
        this.formGroupRef.get(ObjectHelper.nameOf<AddressModel>('googlePlaceId')).setValue(address.googlePlaceId);
        this.formGroupRef.get(ObjectHelper.nameOf<AddressModel>('latitude')).setValue(address.latitude);
        this.formGroupRef.get(ObjectHelper.nameOf<AddressModel>('longitude')).setValue(address.longitude);
        this.formGroupRef.markAsDirty();
    }
}
