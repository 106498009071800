import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseEntryComponent } from 'app/nexus-shared/components/base-component/base-entry.component';
import { DocumentModel } from 'app/nexus-shared/domain/documents/models/document.model';
import { ValidationModel } from 'app/nexus-shared/models';
import { CardModesEnum } from 'app/nexus-shared/enums/card-modes.enum';
import { ViewModesEnum } from 'app/nexus-shared/enums/view-modes.enum';
import { AuthenticatedUserService } from 'app/nexus-core/services/authenticated-user.service';
import { DocumentStatusesEnum } from 'app/nexus-shared/domain/documents/enums/document-statuses.enum';
import { FileDocumentModel } from 'app/nexus-shared/domain/documents/models/document-view-models/file-document.model';
import { FileHelper } from 'app/nexus-core';
import { StringConstants } from 'app/nexus-shared/constants';
import { VirusScanErrorsEnum } from 'app/nexus-shared/domain/documents/enums/virus-scan-errors.enum';
import { Observable, of } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { DocumentProcessService } from 'app/nexus-core/services/domain/documents/document-process.service';

@Component({
    selector: 'gtn-document-upload-modal',
    templateUrl: './document-upload-modal.component.html',
    styleUrls: ['./document-upload-modal.component.scss']
})
export class DocumentUploadModalComponent extends BaseEntryComponent<DocumentModel> implements OnInit {
    @Input() documentProcessService: DocumentProcessService;

    @Output() uploadDocumentClick: EventEmitter<FileDocumentModel> = new EventEmitter();
    @Output() cancelClick: EventEmitter<void> = new EventEmitter();

    isCheckIn: boolean;
    isInvalidFileSize: boolean = false;
    file: File = null;
    cardMode = CardModesEnum.hidden;
    viewMode = ViewModesEnum.General;
    validationModels: ValidationModel[] = [];

    constructor(public authenticatedUserService: AuthenticatedUserService) {
        super();
    }

    ngOnInit() {
        this.isCheckIn = this.value.status === DocumentStatusesEnum.CheckedOut;
    }

    onSaveClicked(): void {
        if (this.validateEntry()) {
            let updateDocumentObservable: Observable<DocumentModel> = of(this.value);
            if (this.value.virusScanError !== VirusScanErrorsEnum.None && !this.value.uploadFailedEmailSent) {
                updateDocumentObservable = this.documentProcessService.getDocument(this.value.documentKey);
            }
            updateDocumentObservable.pipe(concatMap(res => {
                const updatedValue = new DocumentModel(res);
                updatedValue.file.extension = FileHelper.getExtension(this.file?.name);
                updatedValue.file.contentType = this.file?.type;
                updatedValue.file.originalFullFileName = this.file?.name;
                return this.documentProcessService.validateUpdate(updatedValue);
            })).subscribe(res => {
                this.uploadDocumentClick.emit(new FileDocumentModel(this.value, this.file));
            }, err => {
                this.onSaveFailure(err);
            });
        }
    }

    onCancelClicked(): void {
        this.cancelClick.emit();
    }

    onFileChanged(file: File) {
        this.isInvalidFileSize = file?.size > FileHelper.getFileSizeLimit();
        if (this.isInvalidFileSize) {
            this.validationModels.push({ code: '', message: StringConstants.messages.invalidFileSize, dataBag: null, field: null, fieldValue: null });
        } else {
            this.validationModels = [];
        }
    }
}
