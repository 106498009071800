<div class="stepper">
    <div>
        <ng-content select="gtn-progress-stepper-header"></ng-content>
    </div>
    <div class="stepper-container">
        <ng-container *ngFor="let step of steps; index as i">
            <div class="step"
                [ngClass]="{'active': i === selectedStepIndex, 'cursor': isClickableSteps &&  (i=== 0 || steps[i-1].completed)}"
                (click)="onStepClicked(i)"
                [matTooltipPosition]="'after'"
                [matTooltip]="i === selectedStepIndex ? tooltip : ''">
                <span><i *ngIf="step.completed" class="fa fa-check mr-2"></i></span><span
                [ngClass]="{'completed': i < selectedStepIndex}">{{step.label}}</span>
            </div>
        </ng-container>
    </div>
    <ng-content select="gtn-progress-stepper-content"></ng-content>
    <div class="row content-container">
        <ng-container *ngFor="let step of stepsContent; index as i">
            <div [hidden]="i !== selectedStepIndex">
                <ng-container [ngTemplateOutlet]="step.content"></ng-container>
            </div>
        </ng-container>
    </div>
    <div class="row d-flex justify-content-between controls-container" *ngIf="showStepControls">
        <div class="left-controls">
            <button mat-flat-button class="mat-button-default control-buttons" (click)="onPreviousClicked()"
                [disabled]="selectedStepIndex === 0">
                Previous
            </button>
        </div>

        <div class="right-controls">
            <ng-content select="gtn-progress-stepper-controls"></ng-content>
            <button mat-flat-button class="mat-button-default control-buttons" (click)="onNextClicked()"
                [disabled]="!steps[selectedStepIndex].completed">
                Next
            </button>
        </div>

    </div>
</div>
