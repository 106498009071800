import { Component } from '@angular/core';
import { HtmlHelper } from 'app/nexus-core/helpers/html.helper';
import { DisplayConstants } from 'app/nexus-shared/constants';

@Component({
    selector: 'gtn-display-none',
    template: '<span>{{noneText}}</span>'
})
export class DisplayNoneComponent {
    public noneText = HtmlHelper.replaceHtmlEntities(DisplayConstants.None);
}
