<form [formGroup]="formGroupRef" *ngIf="formGroupRef">
    <ng-container *ngIf="viewMode === viewModes.FolderProperties">
        <div class="flex flex-column justify-content-start mb-4">
            <gtn-input formControlName="name" label="Folder name"></gtn-input>
            <ng-container formGroupName="folderSettings">
                <gtn-checkbox formControlName="isReadOnly" label="Read-only"></gtn-checkbox>
                <gtn-checkbox formControlName="canAddChildFolders" label="Can add sub-folders"></gtn-checkbox>
                <gtn-checkbox formControlName="canAddDocuments" label="Can add documents"></gtn-checkbox>
                <gtn-checkbox formControlName="isExternalReadOnly" label="Read-only for external users"></gtn-checkbox>
                <gtn-checkbox formControlName="isRestricted" label="Restrict access to folder"></gtn-checkbox>
            </ng-container>
        </div>
        <ng-container *ngIf="value?.folderSettings?.isRestricted">
            <div class="section-header">
                Allow access to the following individuals <span class="ml-1 pointer"
                (click)="onAddFolderIndividualAccessClicked()"><i
                class="fa fa-add fa-blue"></i></span>
            </div>
            <div *ngFor="let formGroup of folderIndividualAccessFormArray?.controls; index as i; last as isLast">
                <gtn-folder-individual-access-form [isSubForm]="true"
                    [formGroupRef]="getFormGroupFromAbstractControl(formGroup)"
                    (deleteAccess)="onDeleteAccessClicked(i)"
                    (formGroupRefChange)="onFormChanged()"></gtn-folder-individual-access-form>
            </div>
            <div *ngIf="!folderIndividualAccessFormArray?.length" class="row">
                <div class="col-12">No individuals selected</div>
            </div>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="viewMode === viewModes.FolderTags || value?.tags?.length">
        <gtn-multiselect *ngIf="folderTagOptions?.length" label="Tags" formControlName="tags"
            displayKey="folderTagName"
            [options]="folderTagOptions" idKey="folderTagName">
        </gtn-multiselect>
    </ng-container>
</form>
