import { Injectable } from '@angular/core';
import { BaseService } from 'app/nexus-core/services/base-service.directive';
import { fromEvent, interval, merge, Subject, Subscription, timer } from 'rxjs';
import { repeat, takeUntil } from 'rxjs/operators';
import { AuthenticationService } from 'app/nexus-core/services/authentication.service';
import { environment } from 'environments/environment';
import { AuthenticatedUserService } from 'app/nexus-core/services/authenticated-user.service';

@Injectable({
    providedIn: 'root'
})
export class IdleService extends BaseService {
    idleWarning$: Subject<void> = new Subject();
    idleWarningInMinutes = (environment().auth.idleWarningInMinutes ?? 25);
    idleWarningLogoutInMinutes = (environment().auth.idleWarningLogoutInMinutes ?? 5);

    private logOutTimer: Subscription;
    private isIdleWarned = false;

    constructor(
        private authenticationService: AuthenticationService,
        private authenticatedUserService: AuthenticatedUserService) {
        super();

        if (environment().auth.enableIdleTimer) {
            this.subscriptions.add(interval(this.idleWarningInMinutes * 60000).pipe(takeUntil(merge(fromEvent(document, 'keydown'), fromEvent(document, 'mousemove'))), repeat()).subscribe(_ => {
                this.showIdleWarning();
            }));
        }
    }

    showIdleWarning(): void {
        if (!this.isIdleWarned) {
            this.isIdleWarned = true;
            this.idleWarning$.next();

            this.logOutTimer = timer(this.idleWarningLogoutInMinutes * 60000).subscribe(_ => {
                const logoutUrl = this.authenticatedUserService.isGtn ? window.location.href : environment().auth.idleWarningLogoutUrl;
                this.authenticationService.logout(logoutUrl);
            });
        }
    }

    resetIdleWarning(): void {
        this.isIdleWarned = false;
        this.idleWarning$.next();
        this.logOutTimer.unsubscribe();
        this.authenticationService.getTokenSilently().subscribe(_ => {
           //DO NOTHING SO WE INTERACT WITH THE AUTH SERVER TO RESET THE TOKEN TIMEOUT ON THEIR END
        });
    }
}
