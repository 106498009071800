import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gtn-time-picker-dialog',
  templateUrl: './time-picker-dialog.component.html',
  styleUrls: ['./time-picker-dialog.component.scss']
})
export class TimePickerDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
