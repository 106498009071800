import { StringHelper } from './string.helper';

export class FormControlHelper {
    static setControlFocus(formControlName: string) {
        let field: any = null;

        // ensure the name is camel case
        formControlName = StringHelper.lowerCaseFirst(formControlName);

        // gtn-input
        field = <any>document.querySelector(`gtn-input[formControlName=${formControlName}]`)?.querySelector('input');

        // gtn-dropdown
        if (!field) {
            field = <any>document.querySelector(`gtn-dropdown[formControlName=${formControlName}]`)?.querySelector('mat-select');
            field?.click();
        }

        // gtn-multiselect
        if (!field) {
            field = <any>document.querySelector(`gtn-multiselect[formControlName=${formControlName}]`)?.querySelector('textarea');
        }

        // gtn-datepicker
        if (!field) {
            field = <any>document.querySelector(`gtn-datepicker[formControlName=${formControlName}]`)?.querySelector('input');
        }

        // gtn-combobox
        if (!field) {
            field = <any>document.querySelector(`gtn-combobox[formControlName=${formControlName}]`)?.querySelector('input');
        }
    }

    if(field) {
        field.focus();
        if (field.select) {
            field.select();
        }
    }
}

