import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { BaseViewComponent } from 'app/nexus-shared/components/base-component/base-view.component';
import { IAgreementModelInterface } from '../../../interfaces/iagreement-model.interface';
import { FileViewModel } from 'app/nexus-shared/components/controls/shared/models/file-view.model';
import { AgreementSignatureModel } from '../../../models/agreement-signature.model';
import { AgreementStatusEnum } from '../../../enums/agreement-status.enum';
import { ValidationModel } from 'app/nexus-shared/models';
import { AgreementModel } from '../../../models/agreement.model';
import { PdfFileViewService } from 'app/nexus-core/services/domain/core/pdf-file-view.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'gtn-agreement-view',
    templateUrl: './agreement-view.component.html',
    styleUrls: ['./agreement-view.component.scss']
})
export class AgreementViewComponent extends BaseViewComponent<IAgreementModelInterface> implements OnChanges, OnDestroy {
    @Input() agreement: IAgreementModelInterface;
    @Input() fileView: FileViewModel;
    @Input() isReadOnly: boolean = false;
    @Input() signatureValidationErrors: ValidationModel[];

    @Output() agreementChange: EventEmitter<IAgreementModelInterface> = new EventEmitter();
    @Output() agreementReviewStatusChange: EventEmitter<IAgreementModelInterface> = new EventEmitter();
    @Output() close: EventEmitter<void> = new EventEmitter();
    @Output() signAgreementClick: EventEmitter<void> = new EventEmitter();

    agreementStatusEnum = AgreementStatusEnum;
    isFileLoaded: boolean = false;
    reviewerConfirmModalHeaderText = 'Confirm deny';
    reviewerConfirmModalBodyText = 'Are you sure you want to deny this agreement?';
    showReviewerConfirmModal: boolean = false;
    signButtonLabel: string = '';

    private signatureButtonTextListener: Subject<ValidationModel[]> = new Subject();

    constructor(
        public pdfFileViewService: PdfFileViewService
    ) {
        super();

        this.subscriptions.add(this.signatureButtonTextListener.pipe(debounceTime(300)).subscribe(errors => {
            this.updateSignButtonLabel(errors);
        }));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.signatureValidationErrors && this.isFileLoaded) {
            this.signatureButtonTextListener.next(this.signatureValidationErrors);
        }
    }

    ngOnDestroy(): void {
        this.pdfFileViewService.reset();

        super.ngOnDestroy();
    }

    onCloseClicked() {
        this.close.emit();
    }

    onFileLoadComplete() {
        this.isFileLoaded = true;
        this.updateSignButtonLabel(this.signatureValidationErrors);

        this.pdfFileViewService.formFieldChange.subscribe(res => {
            this.agreement = this.getUpdatedAgreement();
            this.agreementChange.next(this.agreement);
        });
    }

    onReviewerApproveClicked() {
        const agreement = new AgreementModel(this.agreement);
        agreement.status = AgreementStatusEnum.PendingSignature;

        this.agreementReviewStatusChange.emit(agreement);
    }

    onReviewerDenyClick() {
        this.showReviewerConfirmModal = true;
    }

    onReviewerDenyCancelClick() {
        this.showReviewerConfirmModal = false;
    }

    onReviewerDenyConfirmClick(reason: string) {
        this.showReviewerConfirmModal = false;

        const agreement = new AgreementModel(this.agreement);
        agreement.status = AgreementStatusEnum.Declined;
        agreement.reviewerDeclineReason = reason;

        this.agreementReviewStatusChange.emit(agreement);
    }

    onSignAgreementClicked() {
        if (this.signatureValidationErrors.length === 0) {
            this.agreement = this.getUpdatedAgreement();

            this.agreementChange.next(this.agreement);
            this.signAgreementClick.emit();
        }
        else {
            this.pdfFileViewService.moveToNextRequiredSignature(this.signatureValidationErrors);
        }
    }

    private getUpdatedAgreement(): AgreementModel {
        const formFieldValues = this.pdfFileViewService.getFormFieldValues();

        if (!formFieldValues?.length) {
            this.signAgreementClick.emit();
            return;
        }

        const agreement = JSON.parse(JSON.stringify(this.agreement));

        agreement.signatures ??= [];

        for (const formFieldValue of formFieldValues) {
            const signatureIndex = agreement.signatures.findIndex(x => x.placeholder === formFieldValue.id);

            if (signatureIndex === -1) {
                const signature = new AgreementSignatureModel();

                signature.agreementKey = agreement.agreementKey;
                signature.placeholder = formFieldValue.id as string;
                signature.isInitial = formFieldValue.id.toString().toLowerCase().indexOf('initial') > -1;
                signature.value = formFieldValue.value;

                agreement.signatures.push(signature);
                continue;
            }

            agreement.signatures[signatureIndex].value = formFieldValue.value;
        }

        return agreement;
    }

    private updateSignButtonLabel(signatureValidationErrors: ValidationModel[]) {
        if (!signatureValidationErrors?.length) {
            this.signButtonLabel = 'Sign agreement';
            return;
        }

        if (signatureValidationErrors?.length < this.pdfFileViewService.formFields.length) {
            this.signButtonLabel = 'Next';
            return;
        }

        this.signButtonLabel = 'Begin';
    }
}
