import { Component } from '@angular/core';
import { BaseDynamicComponentDirective } from 'app/nexus-shared/components/dynamic-form/shared';

@Component({
    selector: 'gtn-dynamic-text-area',
    templateUrl: './dynamic-text-area.component.html',
    styleUrls: ['./dynamic-text-area.component.scss']
})
export class DynamicTextAreaComponent extends BaseDynamicComponentDirective {
}
