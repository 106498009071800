<div class="calendar-container">
    <ng-content select="gtn-calendar-header"></ng-content>
    <div class="calendar-controls">
        <div class="calendar-controls-left">
            <gtn-button-toggle-group [options]="calendarViewTypes" [selectedButtonId]="calendarView" [navigateToLabel]="'Today'"
                (buttonClick)="onViewTypeChanged($event)" (navigateToClick)="onNavigateToTodayClicked()"></gtn-button-toggle-group>
        </div>
        <div class="calendar-controls-center">
            <ng-container *ngIf="calendarView === calendarViewTypesEnum.Year">
                <div class="selector">
                    <button [disabled]="!showPrevious" mat-mini-fab
                        class="mr-2" color="primary"
                        (click)="onChangeYearClicked(false)">
                        <mat-icon>chevron_left</mat-icon>
                    </button>
                    <div class="selector-label hover-div-link" [matMenuTriggerFor]="yearMenu">{{ year }}</div>
                    <mat-menu #yearMenu="matMenu" xPosition="before">
                        <ng-container *ngFor="let year of years;">
                            <div (click)="onYearClicked(year)" class="menu-item hover-div-link">{{ year }}</div>
                        </ng-container>
                    </mat-menu>
                    <button [disabled]="!showNext" mat-mini-fab
                        class="ml-2" color="primary"
                        (click)="onChangeYearClicked(true)">
                        <mat-icon>chevron_right</mat-icon>
                    </button>
                </div>
            </ng-container>
            <ng-container *ngIf="calendarView === calendarViewTypesEnum.Month">
                <div class="selector">
                    <button [disabled]="!showPrevious" mat-mini-fab
                        class="mr-2" color="primary"
                        (click)="onChangeMonthClicked(false)">
                        <mat-icon>chevron_left</mat-icon>
                    </button>
                    <div class="selector-label hover-div-link"
                        [matMenuTriggerFor]="monthMenu">{{ monthNames[month] }} {{ year }}
                    </div>
                    <mat-menu #monthMenu="matMenu" xPosition="before">
                        <ng-container *ngFor="let monthName of monthNames; index as i">
                            <div (click)="onMonthClicked(i)" [ngClass]="{'selected' : i === month}"
                                class="menu-item hover-div-link">{{ monthName }}
                            </div>
                        </ng-container>
                    </mat-menu>
                    <button [disabled]="!showNext" mat-mini-fab
                        class="ml-2" color="primary"
                        (click)="onChangeMonthClicked(true)">
                        <mat-icon>chevron_right</mat-icon>
                    </button>
                </div>
            </ng-container>
        </div>
        <div class="calendar-controls-right">
            <ng-content select="gtn-calendar-controls-right"></ng-content>
        </div>
    </div>
    <div class="calendar-body">
        <div *ngIf="calendarView === calendarViewTypesEnum.Year" class="calendar-year-view">
            <gtn-calendar-year-view [template]="templateRef" [year]="year"
                [date]="calendarStartDate" [weekDayStart]="calendarSettings.weekDayStartId"></gtn-calendar-year-view>
        </div>
        <div *ngIf="calendarView === calendarViewTypesEnum.Month" class="calendar-year-view">
            <gtn-calendar-month-view [template]="templateRef" [date]="calendarStartDate" [month]="month" [year]="year"
                [weekDayStart]="calendarSettings.weekDayStartId">
            </gtn-calendar-month-view>
        </div>
        <div *ngIf="calendarView === calendarViewTypesEnum.List">
            <ng-content select="gtn-calendar-list-content"></ng-content>
        </div>
    </div>
</div>
