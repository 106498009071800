<gtn-modal class="modal-medium"
    [headerText]="'Confirm ' + documentFolderMove?.documentsMoved?.length ? 'Document(s) Move' : 'Folder Move'"
    cancelButtonText="Cancel"
    okButtonText="Confirm"
    (okClick)="onOkClicked()"
    (cancelClick)="onCancelClicked()">
    <gtn-modal-content>
        <div class="flex-column justify-content-start">
            <div class="move-header mb-3">Continuing will move</div>
            <ng-container *ngIf="documentFolderMove?.folderMoved">
                <div class="mb-2 ml-2">
                    <span class="mr-1"><i class="fas fa-folder"></i></span>{{documentFolderMove.folderMoved.name}}
                    <span> and all of its content</span>
                </div>
            </ng-container>
            <ng-container *ngIf="documentFolderMove?.documentsMoved?.length">
                <ng-container *ngFor="let document of documentFolderMove.documentsMoved">
                    <div class="mb-1 ml-2">
                        <span class="mr-1"><i
                            [ngClass]="getFileTypeIcon(document.file.extension)"></i></span>
                        <span>{{document.name}}.{{document.file.extension}}</span>
                    </div>
                </ng-container>
            </ng-container>
            <div class="folder-paths mt-3 mb-4">
                <div class="label-column">
                    <div>From -</div>
                    <div>To -</div>
                </div>
                <div class="path-column">
                    <div>{{documentFolderMove.folderMovedFrom.folderPath}}</div>
                    <div>{{documentFolderMove.folderMovedTo.folderPath}}</div>
                </div>
            </div>
        </div>
    </gtn-modal-content>
</gtn-modal>
