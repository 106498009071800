import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { BaseFormModel } from 'app/nexus-shared/models/base-form.model';
import { Subscription } from 'rxjs';
import { FormHelper, NexusValidatorHelper } from 'app/nexus-core';
import { ViewModesEnum } from 'app/nexus-shared/enums/view-modes.enum';
import { CardModesEnum } from 'app/nexus-shared/enums/card-modes.enum';
import { ValidationModel } from 'app/nexus-shared/models';

@Component({ template: '' })
export abstract class BaseFormComponent<T> extends BaseComponent implements OnInit, OnChanges, OnDestroy {
    @Input() formGroupRef: UntypedFormGroup = null;
    @Input() isSubForm: boolean = false;
    @Output() formGroupRefChange: EventEmitter<UntypedFormGroup> = new EventEmitter();

    @Input() value: T = null;
    @Output() valueChange: EventEmitter<T> = new EventEmitter<T>();

    @Input() viewMode: ViewModesEnum = ViewModesEnum.All;
    @Input() cardMode: CardModesEnum = CardModesEnum.standard;

    @Input('validationModels') _validationModels: ValidationModel[];
    @Output() validationModelsChange: EventEmitter<ValidationModel[] | null> = new EventEmitter();

    formValueChangeSubscription: Subscription = null;
    viewModes = ViewModesEnum;
    formConfiguration: BaseFormModel = null;

    get validationModels() {
        return this._validationModels;
    }

    set validationModels(value: ValidationModel[]) {
        this._validationModels = value;
        this.validationModelsChange.next(value);
    }

    ngOnInit(configuration: BaseFormModel = null, isReload: boolean = false): void {
        if (!this.isSubForm || isReload) {
            this.formGroupRef = new UntypedFormGroup({});
        }
        this.cardMode = this.viewMode === ViewModesEnum.All ? CardModesEnum.standard : CardModesEnum.hidden;
        this.formConfiguration = configuration;

        this.initUIControls();
        this.initForm(configuration);
        this.initFormCustomizations();
        this.initFormEvents();

        if (!configuration?.disableFormValueChangesSubscription) {
            this.initFormValueChangesSubscription();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.value && !changes.value.isFirstChange() && !this.isSubForm) {
            this.reInitForm(this.value);
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    getFormGroupFromAbstractControl(formGroup: AbstractControl): UntypedFormGroup {
        return formGroup as UntypedFormGroup;
    }

    protected initUIControls(): void {
    }

    protected initFormCustomizations(): void {
    }

    protected initFormEvents(): void {
    }

    protected initFormValueChangesCustomizations(value: T): void {
        this.value = value;
    }

    protected initForm(configuration: BaseFormModel = null): void {
        if (this.value) {
            this.buildFormFromTemplate(this.value, this.formGroupRef, configuration);
        }

        if (this.formConfiguration?.validators || this.formConfiguration?.asyncValidators || this.formConfiguration?.formGroupValidators || this.formConfiguration?.formArrayValidators) {
            this.bindValidationModels();
        }

        this.formGroupRef.updateValueAndValidity();
    }

    protected initFormValueChangesSubscription(): void {
        // TODO THROTTLE SLIGHTLY FOR TEXT FIELDS
        this.formValueChangeSubscription = this.formGroupRef.valueChanges.subscribe(test => {
            this.onFormChanged();
        });
        this.subscriptions.add(this.formValueChangeSubscription);

        this.onFormChanged();
    }

    protected initFormGroupControl(controlKey: string, formGroupControl: UntypedFormGroup = new UntypedFormGroup({}), formGroup: UntypedFormGroup = this.formGroupRef): void {
        FormHelper.initFormGroupControl(controlKey, formGroupControl, formGroup);
    }

    protected initFormControl(controlKey: string, formControl: UntypedFormControl = new UntypedFormControl(), formGroup: UntypedFormGroup = this.formGroupRef): void {
        FormHelper.initFormControl(controlKey, formControl, formGroup);
    }

    protected initSubFormControl(formKey: string, controlKey: string, formControl: UntypedFormControl = new UntypedFormControl(), formGroup: UntypedFormGroup = this.formGroupRef): void {
        FormHelper.initSubFormControl(formKey, controlKey, formControl, formGroup);
    }

    protected initFormArrayControl(formKey: string, formArrayObject: any, controlKey: string, addBlank = false, formArray: UntypedFormArray = new UntypedFormArray([]), formGroup: UntypedFormGroup = this.formGroupRef): void {
        FormHelper.initFormArrayControl(formKey, formArrayObject, controlKey, addBlank, formArray, formGroup);
    }

    protected initFormGroupFromControl(formGroupObject: any, controlKey: string, formGroup: UntypedFormGroup, configuration: BaseFormModel = new BaseFormModel): void {
        FormHelper.initFormGroupFromControl(formGroupObject, controlKey, formGroup, configuration);
    }

    protected initFormArrayFormGroup(formArrayObject: any, formArray: UntypedFormArray, formConfiguration: BaseFormModel = new BaseFormModel(), insertIndex: number = null): void {
        FormHelper.initFormArrayFormGroup(formArrayObject, formArray, formConfiguration, insertIndex);
    }

    protected clearFormArray(formArray: UntypedFormArray): void {
        FormHelper.clearFormArray(formArray);
    }

    protected moveItemInFormArray(formArray: UntypedFormArray, toIndex: number, fromIndex: number, reOrderProperty: string = null, zeroBasedOrder: boolean = false) {
        return FormHelper.moveItemInFormArray(formArray, toIndex, fromIndex, reOrderProperty, zeroBasedOrder);
    }

    protected reInitForm(value: T): void {
        if (this.formValueChangeSubscription) {
            this.formValueChangeSubscription.unsubscribe();
        }

        this.value = value;
        this.formGroupRef.reset(value);
        this.formGroupRef.markAsPristine();
        this.formGroupRef.updateValueAndValidity();
        this.formGroupRefChange.emit(this.formGroupRef);

        if (!this.formConfiguration?.disableFormValueChangesSubscription) {
            this.initFormValueChangesSubscription();
        }
    }

    protected buildFormFromTemplate(model: any, formGroup: UntypedFormGroup, configuration: BaseFormModel = null) {
        FormHelper.buildFormFromTemplate(model, formGroup, configuration);
    }

    onFormChanged(): void {
        this.formGroupRefChange.emit(this.formGroupRef);

        if (this.value) {
            if (!this.formConfiguration?.disableValueUpdateOnFormChange) {
                Object.keys(this.value).forEach(key => {
                    this.value[key] = this.formGroupRef.controls[key]?.value;
                });
            }

            this.initFormValueChangesCustomizations(this.value);
            this.valueChange.emit(this.value);
        }
    }

    private bindValidationModels() {
        this.subscriptions.add(this.formGroupRef.statusChanges.subscribe(status => {
            if (status === 'VALID' || status === 'INVALID') {
                if (status === 'INVALID') {
                    // Get all invalid controls
                    const invalidControls = this.getInvalidControls(this.formGroupRef);

                    if (invalidControls?.length > 0) {
                        const validationModels: ValidationModel[] = [];

                        invalidControls.forEach(control => {
                            const errors = control.control.errors;

                            for (const key in errors) {
                                if (key && errors[key]) {
                                    let errorMsg = errors[key];

                                    if (typeof errors[key] === 'object') {
                                        errorMsg = NexusValidatorHelper.getErrorMessage(control.control);
                                    }

                                    const model: ValidationModel = {
                                        code: '',
                                        message: errorMsg,
                                        dataBag: null,
                                        field: control.name,
                                        fieldValue: control.control.value
                                    };

                                    validationModels.push(model);
                                }
                            }

                            this.validationModels = validationModels;
                        });
                    }
                } else if (this.validationModels?.length > 0) {
                    this.validationModels = [];
                }
            }
        }));
    }

    private getInvalidControls(group: UntypedFormGroup | UntypedFormArray) {
        const invalidControls: any[] = [];
        const recursiveFunc = (form: UntypedFormGroup | UntypedFormArray) => {
            Object.keys(form.controls).forEach(field => {
                const control = form.get(field);

                if (control instanceof UntypedFormGroup) {
                    recursiveFunc(control);
                } else if (control instanceof UntypedFormArray) {
                    recursiveFunc(control);
                } else if (control.invalid && !control.disabled) {
                    invalidControls.push({
                        name: field,
                        control: control
                    });
                }
            });
        };

        recursiveFunc(group);
        return invalidControls;
    }
}
