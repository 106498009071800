import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import {
    CellClickedEvent,
    ColDef,
    ColumnApi,
    ColumnState,
    ExcelExportParams,
    FilterChangedEvent,
    FirstDataRenderedEvent,
    GetContextMenuItemsParams,
    GridApi,
    GridOptions,
    GridReadyEvent,
    GridSizeChangedEvent,
    ProcessCellForExportParams,
    ProcessRowGroupForExportParams,
    RowClassRules,
    RowClickedEvent,
    RowNode,
    SideBarDef
} from 'ag-grid-community';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, first, map, takeWhile, tap } from 'rxjs/operators';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { AgGridHelper, DateHelper } from 'app/nexus-core';
import { CustomLoadingOverlayComponent } from 'app/nexus-shared/components/controls/components/base-grid/custom-loading-overlay/custom-loading-overlay.component';
import { ApplicationsEnum } from 'app/nexus-shared/enums';
import { NoRowsOverlayComponent } from 'app/nexus-shared/components/controls/components/base-grid/no-rows-overlay/no-rows-overlay.component';
import { FilterModel } from 'app/nexus-shared/components/controls/shared/models/filter-model';
import { GridSettingsModel } from 'app/nexus-shared/models/grid-settings.model';
import { SimpleChangesTyped } from 'app/nexus-shared/models/simple-changes-typed.type';
import { IndividualSettingModel } from 'app/nexus-shared/models/individual-setting.model';
import { IndividualSettingService } from 'app/nexus-core/services/domain/core/individual-setting.service';
import { AuthorizationConstants } from 'app/nexus-shared/constants/authorization.constants';
import { IndividualSettingSearchModel } from 'app/nexus-shared/models/individual-setting-search.model';
import { ClipboardHelper } from 'app/nexus-core/helpers/clipboard.helper';
import { AgGridColDefHelper } from 'app/nexus-core/helpers/ag-grid-col-def.helper';
import { SavedSettingModel } from 'app/nexus-shared/models/saved-setting.model';
import { Dictionary } from 'app/nexus-shared/models/dictionary';
import { LastUpdatedStatusBarComponent } from 'app/nexus-shared/components/base-component/base-enterprise-grid-components/last-updated-status-bar/last-updated-status-bar.component';
import { FilterChipModel } from 'app/nexus-shared/components/controls/shared/models/filter-chip.model';

@Component({
    template: ''
})
export abstract class BaseEnterpriseGridComponent<T> extends BaseComponent implements OnInit, OnChanges, OnDestroy {
    @Input() application: ApplicationsEnum;
    @Input() data: T[];
    @Input() isDataPassed: boolean = false;
    @Input() enableRowClick: boolean = false;
    @Input() gridHeight: number | string = 'auto';
    @Input() minGridHeight: number = AgGridHelper.minGridHeight;
    @Input() suppressResize: boolean = true;
    @Input() suppressContextMenu: boolean = false;
    @Input() isLoadingOverlay: boolean = true;
    @Input() gridAdminAuthConstant: AuthorizationConstants = AuthorizationConstants.gtnAdministrator;
    @Input() gridFilterChips: FilterChipModel[];
    @Input() protected gridSecondaryIdentifier: string;

    @Output() addButtonClick = new EventEmitter();
    @Output() rowClick = new EventEmitter<T>();
    @Output() cellClick = new EventEmitter<any>();
    @Output() gridReady = new EventEmitter();
    @Output() groupRowClick = new EventEmitter<RowClickedEvent>();

    baseRowClassRules: RowClassRules = AgGridHelper.baseRowClassRules;
    canAdd: boolean = true;
    clickableRows: boolean = true;
    defaultColumnWidth: number = 125;
    defaultFilterState: Dictionary<any>;
    domLayout: string = 'autoHeight';
    columnDefs: ColDef[] = null;
    columnApi: ColumnApi;
    defaultSortModel: ColumnState[];
    filterTextChange$: Subject<string> = new Subject<string>();
    gridOptions: GridOptions = {};
    gridApi: GridApi;
    gridSettings: GridSettingsModel;
    gridStateKey: string;
    isGridReady: boolean = false;
    isRowsGrouped: boolean = false;
    individualSettingId: number;
    rowData: T[];
    showAddButton: boolean;
    showExpandCollapse: boolean;
    isSessionGroupExpanded: boolean = false;
    sideBar: SideBarDef = {
        toolPanels: [],
        defaultToolPanel: ''
    };

    protected gridName: string;
    protected gridVersion: string;

    private readonly gridStateThrottle = 1000;
    private $gridStateSubject: Subject<void> = new Subject<void>();
    private resizeSubject$: Subject<void> = new Subject<void>();
    private readonly gridStateEvents: string[] = [
        'filterChanged',
        'sortChanged',
        'columnVisible',
        'columnMoved',
        'gridColumnsChanged',
        'displayedColumnsChanged',
        'columnResized',
        'expandOrCollapseAll'
    ];

    protected lastUpdatedDateTime: string | Date = null;
    private lastUpdatedStatusBarComponent: LastUpdatedStatusBarComponent = null;

    readonly preventRowClickClass = 'prevent-row-click';

    protected constructor(protected individualSettingService: IndividualSettingService) {
        super();
    }

    ngOnInit(gridOptions: GridOptions = null): void {
        this.subscriptions.add(this.filterTextChange$.pipe((debounceTime(200))).subscribe(filter => {
            if (this.gridApi) {
                this.gridApi.setQuickFilter(filter);
            }
        }));
        this.subscriptions.add(this.resizeSubject$.pipe((debounceTime(100))).subscribe(_ => {
            if (this.gridApi && !this.suppressResize) {
                this.gridApi.sizeColumnsToFit();
            }
        }));
        this.initGridOptions(gridOptions);
    }

    ngOnChanges(changes: SimpleChangesTyped<this>) {
        if (changes.data && !changes.data.isFirstChange() && this.data) {
            this.refreshGridData();
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();

        this.gridApi = null;
        this.gridOptions = null;
        this.columnApi = null;
    }

    onAddButtonClicked(): void {
        this.addButtonClick.emit();
    }

    onExportButtonClicked(customParams: ExcelExportParams = null): void {
        let params: ExcelExportParams = {
            fileName: `${this.gridName}_${DateHelper.format(new Date(), DateHelper.standardDateFormatTimeStamp)}`,
            sheetName: 'Sheet1',
            columnKeys: this.columnApi.getAllGridColumns().map(x => {
                if (x.isVisible() && x.getColDef().headerName) {
                    return x;
                }
            }),
            processCellCallback(params: ProcessCellForExportParams): string {
                return AgGridColDefHelper.excelExportCellCallback(params);
            },
            processRowGroupCallback(params: ProcessRowGroupForExportParams): string {
                if (DateHelper.isValid(new Date(params.node.key))) {
                    return DateHelper.format(params.node.key);
                }
                return params.node.key!;
            }
        };
        if (customParams) {
            params = Object.assign(params, customParams);
        }
        this.gridApi?.exportDataAsExcel(params);
    }

    onFilterChanged(event: FilterChangedEvent): void {
        if (this.gridFilterChips?.length) {
            this.gridFilterChips.forEach((filter, i) => {
                const instance = this.gridApi.getFilterInstance(filter.columnId);
                if (instance && !instance?.getModel()?.values?.some(x => x === filter.value)) {
                    this.gridApi.getFilterInstance(filter.columnId).setModel(null);
                    this.gridFilterChips.splice(i, 1);
                }
            });
        }
    }

    onFilterTextChanged(filter: string): void {
        this.filterTextChange$.next(filter);
    }

    onGridFilterChipRemoveClicked(filter: FilterChipModel): void {
        this.gridApi.getFilterInstance(filter.columnId).setModel(null);
        this.gridFilterChips.splice(this.gridFilterChips.findIndex(x => x.value === filter.value));
        this.gridApi.onFilterChanged();
    }

    onGridReady(): void {
        this.gridReady.emit();
    }

    onGridSettingsChanged(): void {
    };

    onSavedSettingClicked(savedSetting: SavedSettingModel): void {
        if (savedSetting) {
            this.gridSettings = {
                savedSettingId: savedSetting.savedSettingId,
                columnState: savedSetting.value.columnState,
                filterState: savedSetting.value.filterState,
            };
            this.applyGridSettings(savedSetting.value);
        } else {
            this.resetColumnState();
        }
    }

    onGroupRowClicked(rowClickedEvent: RowClickedEvent): void {
        this.groupRowClick.emit(rowClickedEvent);
    }

    onRowClicked(rowClickedEvent: RowClickedEvent): void {
        this.rowClick.emit(rowClickedEvent.node.data);
    }

    onCellClicked(cellClickedEvent: CellClickedEvent): void {
        this.cellClick.emit(cellClickedEvent.node.data);
    }

    expandCollapse(expand: boolean): void {
        if (expand) {
            this.gridApi.expandAll();
        } else {
            this.gridApi.collapseAll();
        }
        this.gridApi.forEachNode(x => {
            if (x.group || this.gridOptions.masterDetail) {
                x.setExpanded(expand);
            }
        });

        this.isSessionGroupExpanded = expand;
        this.gridApi.onGroupExpandedOrCollapsed();
    }

    refreshGridData$(): Observable<any[]> {
        if (this.isLoadingOverlay) {
            this.gridApi.showLoadingOverlay();
            this.gridApi.setRowData([]);
        }
        return this.setRowDataWrapper().pipe(first(), tap(data => {
            this.handleRowDataSet(data);
        }, _ => {
            this.gridApi?.showNoRowsOverlay();
        }));
    }

    refreshGridData(): void {
        const that = this;
        if (this.isLoadingOverlay) {
            this.gridApi?.setRowData([]);
            this.gridApi?.showLoadingOverlay();
        }
        this.setRowDataWrapper().pipe(first(), tap(data => {
            this.handleRowDataSet(data);
        })).subscribe(_ => {
            this.gridApi?.hideOverlay();
            if (!this.suppressResize && !this.gridSettings) {
                that.gridApi?.sizeColumnsToFit();
            }
        }, _ => {
            this.gridApi?.showNoRowsOverlay();
        });
    }

    getSelectedRows(): T[] {
        if (this.gridApi) {
            return this.gridApi.getSelectedNodes().map((x: RowNode<any>) => x.data);
        }

        return null;
    }

    resetColumnState(): void {
        this.gridSettings = null;
        this.columnApi?.resetColumnState();
        this.columnApi?.applyColumnState({ state: this.defaultSortModel });
        this.gridApi?.setFilterModel({});
        if (!this.suppressResize) {
            this.gridApi?.sizeColumnsToFit();
        }
    }

    onSettingsPanelAutosizeClicked(): void {
        this.suppressResize = false;
    }

    onSettingsPanelFitContentClicked(): void {
        this.suppressResize = true;
    }

    setDefaultSortModel(): void {
    }

    setGridSettingsPanelParams(): void {
        AgGridHelper.gridSettingsPanel.toolPanelParams = {
            sizeColumnsToFitClick: this.onSettingsPanelAutosizeClicked.bind(this),
            sizeColumnsToFitContentClick: this.onSettingsPanelFitContentClicked.bind(this),
            resetColumnFiltersClick: this.resetColumnState.bind(this),
            applyGridSettings: this.applyGridSettings.bind(this),
            savedGridSettingClick: this.onSavedSettingClicked.bind(this),
            gridAdminConstant: this.gridAdminAuthConstant,
            gridVersion: this.gridStateKey,
            application: this.application
        };
    }

    protected initGridOptions(gridOptions: GridOptions): void {
        this.setGridStateKey(this.gridName, this.gridVersion, this.gridSecondaryIdentifier ?? null);
        this.setGridSettingsPanelParams();
        this.columnDefs = this.columnDefs ?? this.setColumnDefinitions() ?? [];
        this.setDefaultSortModel();
        this.gridOptions = this.setBaseGridOptions(gridOptions);
    }

    protected onFirstDataRendered(event: FirstDataRenderedEvent<any>): void {
    };

    protected abstract setRowData(): Observable<any[]>;

    protected abstract setColumnDefinitions(): ColDef[];

    private setRowDataWrapper(): Observable<any[]> {
        if (!this.lastUpdatedStatusBarComponent) {
            const lastUpdatedStatusBarComponent = this.gridApi?.getStatusPanel<LastUpdatedStatusBarComponent>(AgGridHelper.lastUpdatedDateTime.key);
            if (lastUpdatedStatusBarComponent) {
                this.lastUpdatedStatusBarComponent = lastUpdatedStatusBarComponent;
            }
        }

        if (this.lastUpdatedStatusBarComponent) {
            this.lastUpdatedStatusBarComponent.setLastUpdatedDateTime(null);
        }

        return this.setRowData().pipe(tap(_ => {
            if (this.lastUpdatedStatusBarComponent) {
                this.lastUpdatedStatusBarComponent.setLastUpdatedDateTime(this.lastUpdatedDateTime ?? new Date());
            }
        }));
    }

    private setGridStateKey(name: string, version: string, secondaryIdentifier: string = null): void {
        if (name && version) {
            this.gridName = name;
            this.gridStateKey = secondaryIdentifier ? `${name}_${secondaryIdentifier}_${version}` : `${name}_${version}`;
        }
    }

    private setBaseGridOptions(customGridOptions: GridOptions): GridOptions {
        if (customGridOptions?.masterDetail) {
            this.showExpandCollapse = true;
        }
        const that = this;
        const gridOptions = <GridOptions>{
            rowData: this.rowData ?? null,
            columnDefs: this.columnDefs,
            autoGroupColumnDef: AgGridColDefHelper.defaultAutoGroupColDef,
            suppressRowClickSelection: true,
            defaultColDef: AgGridColDefHelper.defaultColDef,
            columnTypes: AgGridColDefHelper.columnTypes,
            domLayout: this.domLayout,
            rowClass: this.clickableRows ? 'clickable' : '',
            onCellClicked(event: CellClickedEvent) {
                that.onCellClicked(event);
            },
            onRowClicked(event: RowClickedEvent) {
                if (event?.node?.group) {
                    that.onGroupRowClicked(event);
                } else {
                    that.onRowClicked(event);

                }
            },
            onColumnRowGroupChanged: event => {
                const hasGroupedColumns: boolean = !!event.columns.length;
                this.showExpandCollapse = hasGroupedColumns;
                this.isSessionGroupExpanded = hasGroupedColumns && this.isSessionGroupExpanded;
                this.isSessionGroupExpanded ? this.expandCollapse(true) : null;
            },
            sideBar: {
                toolPanels: [AgGridHelper.columnsToolPanel, AgGridHelper.filterToolPanel, AgGridHelper.gridSettingsPanel]
            },
            statusBar: {
                statusPanels: [
                    AgGridHelper.totalRowCount,
                    AgGridHelper.filteredRowCount,
                    AgGridHelper.selectedRowCount,
                    AgGridHelper.lastUpdatedDateTime
                ],
            },
            excelStyles: AgGridHelper.excelStyles,
            suppressAggFuncInHeader: true,
            suppressCellFocus: true,
            tooltipShowDelay: 500,
            loadingOverlayComponent: CustomLoadingOverlayComponent,
            noRowsOverlayComponent: NoRowsOverlayComponent,
            onFirstDataRendered(event: FirstDataRenderedEvent<any>) {
                that.resizeSubject$.next();
                that.onFirstDataRendered(event);
            },
            onGridSizeChanged(event: GridSizeChangedEvent<any>) {
                that.resizeSubject$.next();
            },
            onToolPanelVisibleChanged() {
                if (!that.gridApi?.isToolPanelShowing() && !that.suppressResize) {
                    that.gridApi.sizeColumnsToFit();
                }
            },
            onFilterChanged(event: FilterChangedEvent) {
                that.onFilterChanged(event);
            },
            rowClassRules: customGridOptions?.rowClassRules ? Object.assign(this.baseRowClassRules, customGridOptions?.rowClassRules) : that.baseRowClassRules,
            suppressContextMenu: this.suppressContextMenu,
            getContextMenuItems: (params: GetContextMenuItemsParams) => {
                return [
                    {
                        name: 'Copy cell',
                        icon: `<i class='far fa-copy'></i>`,
                        action: () => {
                            if (params?.value) {
                                ClipboardHelper.copyTextToClipboard(params.value);
                            }
                        }
                    }
                ];
            },
            onGridReady(event: GridReadyEvent) {
                that.gridApi = event.api;
                that.columnApi = event.columnApi;
                that.columnApi.applyColumnState({ state: that.defaultSortModel });
                that.gridApi.setDomLayout(null);

                const getUserGridSettings = (that.gridStateKey && that.application) ? that.setGridState().pipe(catchError(error => of(error))) : of(null);

                forkJoin({ data: that.setRowDataWrapper(), gridSettings: getUserGridSettings }).subscribe(res => {
                    if (res.data) {
                        that.rowData = res.data;
                        that.isGridReady = true;
                        that.onGridReady();
                        that.handleRowDataSet(res.data);
                    }

                    if (res.gridSettings) {
                        that.applyGridSettings(that.gridSettings ?? res.gridSettings);
                    }

                    if (that.gridFilterChips && !res.gridSettings) {
                        that.applyGridFilterChips();
                    }

                    if (that.application && that.gridStateKey) {
                        that.initGridState();
                    }
                }, _ => {
                    that.gridApi?.showNoRowsOverlay();
                });
            },
        };
        if (customGridOptions) {
            return Object.assign(gridOptions, customGridOptions);
        }
        return gridOptions;
    }

    private handleRowDataSet(data): void {
        if (this.gridApi) {
            this.gridApi.setRowData(data || []);
            if (this.gridSettings) {
                this.applyGridSettings(this.gridSettings);
            }
            this.gridApi.hideOverlay();
            if (!data?.length) {
                setTimeout(() => {
                    this.gridApi?.showNoRowsOverlay();
                });
            }
        }
    }

    private applyGridFilterChips(): void {
        // this only works for the 'agSetColumnFilter'
        // todo: create filter generator for other types, multi, data, whatever else
        this.gridFilterChips.forEach(x => {
            const instance = this.gridApi?.getFilterInstance(x.columnId)!;
            instance.setModel({ values: [x.value], filterType: 'set' });
        });
        this.gridApi.onFilterChanged();
    }

    private applyGridSettings(gridSettings: GridSettingsModel): void {
        const columnState = gridSettings.columnState as ColumnState[];
        const filterState = gridSettings.filterState as FilterModel;
        this.gridOptions?.columnApi?.applyColumnState({ state: columnState, applyOrder: true });
        this.gridApi?.setFilterModel(filterState);
        this.onGridSettingsChanged();
    }

    private initGridState(): void {
        this.gridStateEvents.forEach((type) => {
            if (this.gridApi) {
                this.gridApi.addEventListener(type, () => {
                    this.$gridStateSubject.next();
                });
            }
        });
        this.subscriptions.add(this.$gridStateSubject.pipe(debounceTime(this.gridStateThrottle), takeWhile(() => {
            return (!!(this.gridOptions && this.gridOptions.columnApi && this.gridOptions.api));
        }), tap(() => {
            if (!this.gridSettings?.savedSettingId) {
                const gridState: GridSettingsModel = {
                    columnState: this.gridOptions?.columnApi?.getColumnState(),
                    filterState: this.gridOptions?.api?.getFilterModel(),
                    savedSettingId: null,
                };
                this.gridSettings = Object.assign(this.gridSettings || {}, gridState);
                this.saveGridState();
                this.onGridSettingsChanged();
            }
        })).subscribe());
    }

    private setGridState(): Observable<GridSettingsModel> {
        return this.individualSettingService.search(<IndividualSettingSearchModel>{
            key: this.gridStateKey,
            application: this.application
        }).pipe(map((individualSettings: IndividualSettingModel[]) => {
            if (individualSettings?.length) {
                this.gridSettings = individualSettings[0]?.value;
                this.individualSettingId = individualSettings[0]?.individualSettingId;
            }
            return this.gridSettings;
        }));
    }

    private saveGridState(): void {
        const individualSetting: IndividualSettingModel = new IndividualSettingModel();
        individualSetting.application = this.application;
        individualSetting.key = this.gridStateKey;
        individualSetting.value = this.gridSettings;
        individualSetting.individualSettingId = this.individualSettingId ?? null;
        this.individualSettingService.upsert(individualSetting).subscribe(individualSettingsId => {
            this.individualSettingId = individualSettingsId;
        }, error => {
            console.error(error);
        });
    }
}
