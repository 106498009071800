import { Component } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { EditorColumnParametersModel } from './editor-column-parameters.model';

@Component({
    selector: 'gtn-editor-column',
    templateUrl: './editor-column.component.html',
    styleUrls: ['./editor-column.component.scss']
})
export class EditorColumnComponent implements ICellRendererAngularComp {
    formControl: AbstractControl;
    isDisabled: boolean = false;
    isValid: boolean = true;
    value: number;

    agInit(params: EditorColumnParametersModel): void {
        if (!params) {
            return;
        }

        if (params.formGroupRef) {
            const formGroup = params.formGroupRef;

            const formArray = formGroup.controls[params.formArrayName] as UntypedFormArray;

            if (formArray?.controls) {

                const rowFormGroup = formArray.controls[params.rowIndex] as UntypedFormGroup;

                if (rowFormGroup?.controls) {
                    const formControl = rowFormGroup.controls[params.colDef.field];

                    if (formControl) {
                        this.formControl = formControl;
                        this.setValuesFromFormControl(formControl);
                    }
                }
            }
        } else {
            if (params.colDef) {
                this.value = params.data[params.colDef.field];
            }

            this.isValid = params.isValid;
            this.isDisabled = params.isDisabled;
        }

    }

    refresh(): boolean {
        if (this.formControl) {
            this.setValuesFromFormControl(this.formControl);
        }

        return false;
    }

    private setValuesFromFormControl(formControl: AbstractControl) {
        this.isValid = !formControl.disabled ? formControl.valid : true;
        this.isDisabled = formControl.disabled;
        this.value = formControl.value;

        return formControl;
    }
}
