<form [formGroup]="formGroupRef">
    <div class="flex flex-column">
        <div class="flex flex-row">
            <span class="mr-1"
                (click)="onButtonStyleClicked(boldFormControlName)">
                <i class="fa-solid fa-bold unselected"
                    [ngClass]="{'selected': formGroupRef.get(boldFormControlName).value}"></i>
            </span>
            <span class="unselected mr-1" (click)="onButtonStyleClicked(italicFormControlName)">
                <i class="fa-regular fa-italic unselected"
                    [ngClass]="{'selected': formGroupRef.get(italicFormControlName).value}"></i>
            </span>
            <span class="unselected mr-1" (click)="onButtonStyleClicked(underlineFormControlName)">
                <i class="fa-regular fa-underline unselected"
                    [ngClass]="{'selected': formGroupRef.get(underlineFormControlName).value}"></i>
            </span>
            <span class="unselected" (click)="onButtonStyleClicked(redFormControlName)">
                <i class="fa-regular fa-text unselected"
                    [ngClass]="{'selected': formGroupRef.get(redFormControlName).value}"></i>
            </span>
        </div>
        <gtn-text-area label="Text" formControlName="text"></gtn-text-area>
        <gtn-text-area label="Tooltip" formControlName="additionalInfo"></gtn-text-area>
    </div>
</form>
