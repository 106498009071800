import { Component } from '@angular/core';
import { TemplatePageComponent } from 'app/nexus-shared/components/templates/template-page/template-page.component';

@Component({
    selector: 'gtn-template-page-header',
    template: '<ng-content></ng-content>'
})
export class TemplatePageHeaderComponent {
    // noinspection JSUnusedLocalSymbols
    constructor(templateBasePageComponent: TemplatePageComponent) {
        // THIS WILL THROW AN ERROR IF ITS NOT NESTED WITHIN PARENT
    }
}
