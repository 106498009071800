import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { BaseHttpService } from 'app/nexus-core/services/index';
import { UserModel } from 'app/nexus-shared/domain/users/models/user.model';
import { UserSearchModel } from 'app/nexus-shared/domain/users/models/user-search.model';
import { UserUpdateEmailModel } from 'app/nexus-core/services/domain/users/user-update-email.model';

@Injectable()
export class UserService {
    private apiUrl = environment().webAPIUrls.auth + 'users/';

    constructor(private baseHttpService: BaseHttpService) {
    }

    list(): Observable<UserModel[]> {
        return this.baseHttpService.get(this.apiUrl);
    }

    search(model: UserSearchModel): Observable<UserModel[]> {
        return this.baseHttpService.post(`${this.apiUrl}search`, model);
    }

    getByKey(userKey: string): Observable<UserModel> {
        return this.baseHttpService.get(`${this.apiUrl}${userKey}`);
    }

    getProfilePicture(userKey: string): Observable<any> {
        return this.baseHttpService.get(`${this.apiUrl}${userKey}/profile-picture`, null, 'blob');
    }

    create(model: UserModel): Observable<string> {
        return this.baseHttpService.post(this.apiUrl, model);
    }

    invite(userKey: string): Observable<void> {
        return this.baseHttpService.post(`${this.apiUrl}${userKey}/invite`, null);
    }

    resetPassword(userKey: string): Observable<boolean> {
        return this.baseHttpService.post(`${this.apiUrl}${userKey}/reset-password`);
    }

    update(model: UserModel): Observable<boolean> {
        return this.baseHttpService.put(this.apiUrl, model);
    }

    createUpdateEmailVerification(model: UserUpdateEmailModel): Observable<string> {
        return this.baseHttpService.post(`${this.apiUrl}update-email`, model);
    }

    validateUpdateEmailVerification(model: UserUpdateEmailModel): Observable<boolean> {
        return this.baseHttpService.put(`${this.apiUrl}update-email/validate`, model);
    }
}
