import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { LocationModel } from 'app/nexus-shared/domain/locations/models';
import { LocationService } from 'app/nexus-core/services/location.service';

@Component({
    selector: 'gtn-country-column',
    templateUrl: './country-column.component.html',
    styleUrls: ['./country-column.component.scss']
})
export class CountryColumnComponent implements ICellRendererAngularComp {
    countryName: string = '';

    constructor(private locationService: LocationService) {
    }

    agInit(params: any): void {
        if (params.value) {
            this.mapCountry(params.countries, params.value);
        }
    }

    mapCountry(countries: LocationModel[], countryId): void {
        if (countries?.length) {
            this.countryName = countries.find((c) => {
                return c.id === countryId;
            })?.name;
        } else {
            this.locationService.getCountry(countryId).subscribe((res) => {
                this.countryName = res.name;
            });
        }
    }

    refresh(): boolean {
        return false;
    }
}
