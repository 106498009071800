import { AuthorizationConstants } from 'app/nexus-shared/constants/authorization.constants';
import { UserStatusesEnum } from 'app/nexus-shared/enums/user-statuses.enum';
import { CommonBaseAuditModel } from '../../contacts/models/common-base-audit.model';

export class UserModel extends CommonBaseAuditModel {
    userKey: string;
    userId: string;
    individualKey: string = null;
    firstName: string;
    lastName: string;
    fullName?: string;
    email: string;
    status?: UserStatusesEnum;
    lastLoginDateTime?: Date;
    lastInvitedDateTime?: Date;
    policies: AuthorizationConstants[];
    picture: string;
    roles?: string[];
    companyKeys: string[];
    myGTNPortalUserKey: string;
    isLockedOut: boolean;
    requires2FA: boolean;
    requiresAUA: boolean;
    requiresDataAcknowledgment: boolean;
    isImpersonating: boolean;

    constructor(userModel: UserModel = null) {
        super(userModel);
        this.userKey = userModel?.userKey ?? null;
        this.userId = userModel?.userId ?? null;
        this.individualKey = userModel?.individualKey ?? null;
        this.firstName = userModel?.firstName ?? null;
        this.lastName = userModel?.lastName ?? null;
        this.fullName = userModel?.fullName ?? null,
        this.email = userModel?.email ?? null;
        this.status = userModel?.status ?? null;
        this.lastLoginDateTime = userModel?.lastLoginDateTime ?? null;
        this.lastInvitedDateTime = userModel?.lastInvitedDateTime ?? null;
        this.policies = userModel?.policies ?? [];
        this.picture = userModel?.picture ?? null;
        this.companyKeys = userModel?.companyKeys ?? [];
        this.myGTNPortalUserKey = userModel?.myGTNPortalUserKey ?? null;
        this.isLockedOut = userModel?.isLockedOut ?? false;
        this.requires2FA = userModel?.requires2FA ?? false;
        this.requiresAUA = userModel?.requiresAUA ?? false;
        this.isImpersonating = userModel?.isImpersonating ?? false;
    }
}
