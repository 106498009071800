export enum RoleTypesEnum {
    NoAssignedRoles = 0,
    /******************************************/
    /* APPLICATION SPECIFIC ROLES (100+)      */
    /******************************************/
    /******************************************/
    /* APPLICATION SPECIFIC ROLES (100-9999)  */
    /******************************************/
    /******************************************/
    /* EQUITY SPECIFIC ROLES (100-199)        */
    /******************************************/
    EquityBasicAccessUser = 100,
    EquityFullAccessUser = 199,

    /******************************************/
    /* WHEREABOUTS SPECIFIC ROLES (200-299)   */
    /******************************************/
    WhereaboutsBasicAccessUser = 200,
    WhereaboutsRemoteWorkUser = 201,
    WhereaboutsFullAccessUser = 299,

    /******************************************/
    /* TRACTION SPECIFIC RESOURCES (300-399)  */
    /******************************************/
    TractionBasicAccessUser = 300,
    TractionFullAccessUser = 399,

    /******************************************/
    /* PORTAL 2.0 SPECIFIC RESOURCES (400-499)*/
    /******************************************/
    PortalMobileEmployeeUser = 450,
    PortalMobileEmployeeDocuments = 451,
    PortalMobileEmployeeTaxOrganizer = 452,
    PortalMobileEmployeeTravelCalendar = 453,
    PortalMobileEmployeeInvoicing = 454,
    PortalMobileEmployeeAgreements = 455,
    PortalAdminInvoicing = 460,
    PortalCompanyAdminUser = 475,
    PortalAdminMobileWorkForce = 480,
    PortalAdminMobileWorkforceChecklist = 481,
    PortalAdminDocuments = 485,
    PortalRelocationAdminUser = 495,
    PortalApiUser = 499,

    /******************************************/
    /* DOCUMENTS SPECIFIC RESOURCES (500-599) */
    /******************************************/
    Documents = 500,
    DocumentsTemplatesAdmin = 550,
    DocumentsAdmin = 599,

    /******************************************/
    /* VENDORS SPECIFIC RESOURCES (600-699)   */
    /******************************************/
    Vendors = 625,
    VendorsAdmin = 699,

    /******************************************/
    /* WORKFORCE SPECIFIC RESOURCES (700-799) */
    /******************************************/
    Workforce = 700,
    WorkforceAdmin = 799,

    /******************************************/
    /* AUTH SPECIFIC RESOURCES (800-899)      */
    /******************************************/
    AuthRolesAdmin = 750,
    AuthImpersonation = 760,

    /******************************************/
    /* AGREEMENTS RESOURCES (900-999)       */
    /******************************************/
    Agreements = 900,
    AgreementsAdmin = 999,

    /******************************************/
    /* TAX ORGANIZER RESOURCES (1000-1099)    */
    /******************************************/
    TaxOrganizer = 1000,
    TaxOrganizerAdmin = 1099,

    /******************************************/
    /* QUESTIONNAIRES (1000-1099)             */
    /******************************************/
    Questionnaires = 1100,
    QuestionnairesAdmin = 1199,

    /******************************************/
    /* TAXES RESOURCES (5000-5099)            */
    /******************************************/
    Taxes = 5000,
    TaxesAdmin = 5099,

    /******************************************/
    /* TAXABILITY RESOURCES (5200-5199)       */
    /******************************************/
    Taxability = 5100,
    TaxabilityAdmin = 5199,
    CchAxcessImport = 5200,
    CchAxcessImportAdmin = 5299,

    /******************************************/
    /* ACCOUNTING RESOURCES (5300-5399)       */
    /******************************************/
    AccountingUser = 5300,
    AccountingStaff = 5350,
    AccountingAdmin = 5399,

    /******************************************/
    /* CONTACTS RESOURCES (5400-5499)         */
    /******************************************/
    ContactsAdmin = 5499,

    /******************************************/
    /* GLOBAL/SPECIALIZED ROLES (10000+)      */
    /******************************************/
    GtnUser = 10000,
    GtnAdministrator = 99999
}
