import { Component } from '@angular/core';

@Component({
    selector: 'gtn-mat-card-cut-out',
    templateUrl: './mat-card-cut-out.component.html',
    styleUrls: ['./mat-card-cut-out.component.scss']
})
export class MatCardCutOutComponent {

}
