import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NexusAnimations } from 'app/nexus-shared/animations';

@Component({
    selector: 'gtn-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
    animations: [NexusAnimations.bumpDown]
})
export class NotificationsComponent {
    @Input() isLinkActive: boolean;
    @Input() isDeleteVisible: boolean;
    @Input() notificationIdPropertyName: string;
    @Input() notifications: any[]; // must have a message property

    @Output() deleteClick: EventEmitter<any> = new EventEmitter();
    @Output() linkClick: EventEmitter<any> = new EventEmitter();

    constructor() {
    }

    onDeleteClick(event: any) {
        this.deleteClick.emit(event);
    }

    onLinkClick(event: any) {
        this.linkClick.emit(event);
    }
}
