<div class="tool-summary-panel pointer" (click)="onClicked()">
    <div class="name-container column align-items-center justify-content-center">
        <img *ngIf="imageSrc" [src]="imageSrc"
            [ngClass]="{'img-with-text': imageTxt,'img-is-svg': imageSrc.indexOf('.svg') > 0, 'img-not-svg': imageSrc.indexOf('.svg') === -1}"/>
        <p *ngIf="imageTxt">{{imageTxt}}</p>
        <div class="subtitle mt-3">
            <h3>{{ subtitle ?? noValueText }}</h3>
        </div>
    </div>
    <div class="summary-container" [ngClass]="{'is-primary': isPrimaryBackground }">
        {{summaryTxt ?? noValueText}}
    </div>
</div>
