import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { ApplicationsEnum } from 'app/nexus-shared/enums';
import { GridSettingsModel } from 'app/nexus-shared/models/grid-settings.model';
import { FilterChipModel } from 'app/nexus-shared/components/controls/shared/models/filter-chip.model';

@Component({
    selector: 'gtn-template-grid',
    templateUrl: './template-grid.component.html',
    styleUrls: ['./template-grid.component.scss']
})
export class TemplateGridComponent extends BaseComponent {
    @Input() headerTitle: string = null;
    @Input() background: 'white' | 'none' = 'white';
    @Input() showQuickFilter: boolean = true;
    @Input() quickFilterLabel: string = 'Start typing to filter data...';
    @Input() showAddButton: boolean = false;
    @Input() isDisabledAddButton: boolean = false;
    @Input() showExportButton: boolean = false;
    @Input() showExpandCollapse: boolean = false;
    @Input() quickFilterPositionRight: boolean = true;
    @Input() gridStateKey: string;
    @Input() application: ApplicationsEnum;
    @Input() gridSettings: GridSettingsModel;
    @Input() isSessionGroupExpanded: boolean;
    @Input() gridFilterChips: FilterChipModel[];

    @Output() quickFilterTextChange: EventEmitter<string> = new EventEmitter<string>();
    @Output() addButtonClick: EventEmitter<void> = new EventEmitter<void>();
    @Output() exportButtonClick: EventEmitter<void> = new EventEmitter<void>();
    @Output() chipGridSettingClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() toggleExpandCollapse: EventEmitter<any> = new EventEmitter<any>();
    @Output() gridFilterChipRemoveClick: EventEmitter<FilterChipModel> = new EventEmitter<FilterChipModel>();

    filterText: string = '';

    onToggleExpandCollapsed(isCollapsed: boolean): void {
        this.toggleExpandCollapse.emit(isCollapsed);
    }
}
