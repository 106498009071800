export abstract class ModalInputModel {
    cancelButtonText: string;
    mainHeaderText: string;
    message: string;
    okButtonText: string;
    showCloseButton: boolean;
    subHeaderText: string;

    constructor(
        mainHeaderText: string = '',
        message: string = '',
        subHeaderText: string = '',
        okButtonText: string = 'Ok',
        cancelButtonText: string = 'Cancel',
        showCloseButton: boolean = false
    ) {
        this.cancelButtonText = cancelButtonText;
        this.mainHeaderText = mainHeaderText;
        this.message = message;
        this.okButtonText = okButtonText;
        this.showCloseButton = showCloseButton;
        this.subHeaderText = subHeaderText;
    }
}
