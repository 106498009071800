import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';

@Component({
    selector: 'gtn-file-drag-drop',
    templateUrl: './file-drag-drop.component.html',
    styleUrls: ['./file-drag-drop.component.scss']
})
export class FileDragDropComponent extends BaseComponent {
    @Input() hint: string = 'Drag files here';
    @Input() dropText: string = 'Drop It!';
    @Input() isMultiSelect: boolean;

    @Output() fileEmitter: EventEmitter<FileList> = new EventEmitter();

    showSingleWarning: boolean = false;
    isDragHover: boolean = false;

    onFilesDropped(files: FileList) {
        if (files.length > 0) {
            if (!this.isMultiSelect && files.length > 1) {
                this.showSingleWarning = true;
            } else {
                this.fileEmitter.emit(files);
            }
        }
    }

    onDragHoverChange(isDragHover: boolean) {
        this.isDragHover = isDragHover;
    }

}

