import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ErrorResponseModel } from 'app/nexus-shared/models/error-response.model';
import { Dictionary } from 'app/nexus-shared/models/dictionary';
import { FileBlobHttpResponseModel } from 'app/nexus-shared/models';

type QueryParamsType = string | boolean | number;

@Injectable()
export class BaseHttpService {
    public constructor(
        protected httpClient: HttpClient
    ) { }

    get(url: string, queryParams: Dictionary<QueryParamsType> = null, responseType: 'arraybuffer' | 'blob' | 'json' | 'text' = 'json'): Observable<any> {
        const params = this.buildQueryParams(queryParams);
        return this.httpClient.get(url, { observe: 'response', params, reportProgress: false, responseType: responseType as any }).pipe(map((response) => {
            if (responseType !== 'blob') {
                return this.parseSuccessfulResponse(response);
            } else {
                return this.parseSuccessfulBlobResponse(response);
            }
        }), catchError(this.parseErrorResponse));
    }

    post(url: string, body: any = null, queryParams: Dictionary<QueryParamsType> = null, responseType: 'arraybuffer' | 'blob' | 'json' | 'text' = 'json'): Observable<any> {
        const params = this.buildQueryParams(queryParams);
        return this.httpClient.post(url, body, { observe: 'response', params, reportProgress: false, responseType: responseType as any }).pipe(map((response) => {
            if (responseType !== 'blob') {
                return this.parseSuccessfulResponse(response);
            } else {
                return this.parseSuccessfulBlobResponse(response);
            }
        }), catchError(this.parseErrorResponse));
    }

    put(url: string, body: any = null, queryParams: Dictionary<QueryParamsType> = null, responseType: 'arraybuffer' | 'json' | 'text' = 'json'): Observable<any> {
        const params = this.buildQueryParams(queryParams);
        return this.httpClient.put(url, body, { observe: 'response', params, reportProgress: false, responseType: responseType as any }).pipe(map(this.parseSuccessfulResponse), catchError(this.parseErrorResponse));
    }

    patch(url: string, body: any = null, queryParams: Dictionary<QueryParamsType> = null, responseType: 'arraybuffer' | 'json' | 'text' = 'json'): Observable<any> {
        const params = this.buildQueryParams(queryParams);
        return this.httpClient.patch(url, body, { observe: 'response', params, reportProgress: false, responseType: responseType as any }).pipe(map(this.parseSuccessfulResponse), catchError(this.parseErrorResponse));
    }

    delete(url: string, queryParams: Dictionary<QueryParamsType> = null, responseType: 'arraybuffer' | 'json' | 'text' = 'json'): Observable<any> {
        const params = this.buildQueryParams(queryParams);
        return this.httpClient.delete(url, { observe: 'response', params, reportProgress: false, responseType: responseType as any }).pipe(map(this.parseSuccessfulResponse), catchError(this.parseErrorResponse));
    }

    private buildQueryParams(queryParams: Dictionary<QueryParamsType>): HttpParams {
        let params = new HttpParams();
        if (queryParams) {
            Object.keys(queryParams).forEach(key => {
                if (queryParams[key]) {
                    const uriEncodedParam = encodeURIComponent(queryParams[key]);
                    params = params.append(key, uriEncodedParam);
                }
            });
        }
        return params;
    }

    private parseSuccessfulResponse<T>(httpResponse: HttpResponse<T>): T {
        if (httpResponse.status.toString()[0] === '2') {
            return httpResponse.body;
        } else {
            throwError(httpResponse.body);
        }
    }

    private parseSuccessfulBlobResponse<T>(httpResponse: HttpResponse<T>): FileBlobHttpResponseModel {
        if (httpResponse.status.toString()[0] === '2') {
            const keys = httpResponse.headers.keys(),
                headers: any = {},
                fileModel: FileBlobHttpResponseModel = new FileBlobHttpResponseModel();

            fileModel.blob = httpResponse.body;

            keys.map(key => {
                headers[key] = httpResponse.headers.get(key);
            });

            if (headers['file-name']) {
                fileModel.fileName = headers['file-name'];
            }

            return fileModel;
        } else {
            throwError(httpResponse.body);
        }
    }

    private parseErrorResponse(httpResponse): Observable<ErrorResponseModel> {
        const isValidationError = httpResponse.status === 422;
        return throwError(new ErrorResponseModel(!isValidationError, isValidationError, (isValidationError ? httpResponse.error : httpResponse.message)));
    }
}
