import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BaseHttpService, LoggingService } from 'app/nexus-core/services/index';
import { Observable } from 'rxjs';
import { SupportEmailModel } from 'app/nexus-shared/models/support-email.model';

@Injectable()
export class SupportEmailService {
    private apiUrl = environment().webAPIUrls.core;

    constructor(private baseHttpService: BaseHttpService,
                private loggingService: LoggingService) {
    }

    sendSupportEmail(firstName: string, lastName: string, email: string, notes: string): Observable<boolean> {
        const env = environment();
        const request: SupportEmailModel = {
            environment: env.envPrefix,
            appName: env.appName,
            appUrl: window.location.href,
            firstName: firstName,
            lastName: lastName,
            email: email,
            errors: this.loggingService.errors,
            notes: notes
        };
        return this.baseHttpService.post(`${this.apiUrl}support-email/send`, request);
    }
}

