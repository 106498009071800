import { DateHelper } from './date.helper';
import { SelectListInterface } from 'app/nexus-shared';
import { Guid } from 'guid-typescript';

export class StringHelper {
    static shortDisplayWords: string[] = ['Of'];

    static getAlphabetArray(): string[] {
        return 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
    }

    static convertFromCamelCase(value: string, useReplacements: boolean = true) {
        if (value) {
            value = value.replace(/([A-Z])/g, ' $1');
            // TEMP UNTIL I FIGURE OUT HOW TO BETTER DO LEVEL1 ENUM
            value = value.replace(/([0-9])/g, ' $1');

            value = value.trim();

            if (useReplacements) {
                value = StringHelper.convertCasingOnShortDisplayWords(value);
            }

            //CHANGE I B M Computer to IBM Computers
            value = value.replace(/([A-Z])\s(?=[A-Z]\b)/g, '$1');

            return value;
        }

        return null;
    }

    static convertCasingOnShortDisplayWords(value: string): string {
        if (value) {
            StringHelper.shortDisplayWords.forEach(replacement => {
                const replacementString = ' ' + replacement + ' ';
                while (value.indexOf(replacementString) !== -1) {
                    value = value.replace(replacementString, replacementString.toLowerCase());
                }
            });
        }

        return value;
    }

    static format(value: string, args: string[]) {
        return value.replace(/{(\d+)}/g, function (match, number) {
            return typeof args[number] !== 'undefined' ? args[number] : match;
        });
    }

    static upperCaseFirst(value: string): string {
        return `${value.charAt(0).toUpperCase()}${value.slice(1)}`;
    }

    static lowerCaseFirst(value: string): string {
        return `${value.charAt(0).toLowerCase()}${value.slice(1)}`;
    }

    static measureText(text: string, fontSize?: number, fontFamily?: string, fontWeight?: number, format?: string): number {
        const div = document.createElement('div');
        document.body.appendChild(div);
        div.style.position = 'absolute';
        // This makes the line breaks work
        div.style.whiteSpace = 'pre-line';

        if (fontFamily) {
            div.style.fontFamily = fontFamily;
        }

        if (fontSize) {
            div.style.fontSize = `${fontSize}px`;
        }

        if (fontWeight) {
            div.style.fontWeight = fontWeight.toString();
        }

        if (format && text) {
            const options = {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            };
            switch (format) {
                case '_(#,##0.000_);_((#,##0.000);_(\"-\"??_);_(@_)':
                    options.minimumFractionDigits = 3;
                    options.maximumFractionDigits = 3;
                    text = Number(text).toLocaleString('en', options);
                    break;
                case '_(###0.000_);_((###0.000);_(\"-\"??_);_(@_)':
                    options.minimumFractionDigits = 3;
                    options.maximumFractionDigits = 3;
                    text = Number(text).toLocaleString('en', options).replace(',', '');
                    break;
                case '_(#,##0.00_);_((#,##0.00);_(\"-\"??_);_(@_)':
                    text = Number.parseFloat(text).toFixed(2);
                    break;
                case '_(###0.00_);_((###0.00);_(\"-\"??_);_(@_)':
                    text = Number.parseFloat(text).toFixed(2).replace(',', '');
                    break;
                case '###0.00':
                    text = Number(text).toLocaleString('en', options).replace(',', '');
                    break;
                case '#,##0.00':
                    text = Number(text).toLocaleString('en', options);
                    break;
                case '_($* #,##0.00_);_($* (#,##0.00);_($* \"-\"??_);_(@_)':
                    text = `$${Number(text).toLocaleString('en', options)}`;
                    break;
                case '_($* ###0.00_);_($* (###0.00);_($* \"-\"??_);_(@_)':
                    text = `$${Number(text).toLocaleString('en', options).replace(',', '')}`;
                    break;
                case 'D/M/YYYY':
                case 'M/D/YYYY':
                    text = DateHelper.format(DateHelper.convertToUtcDate(text), format);
                    break;
                case '#,##0.00%':
                    text = `${Number(parseFloat(text) * 100).toLocaleString('en', options)}%`;
                    break;
                case '###0.00%':
                    text = `${Number(parseFloat(text) * 100).toLocaleString('en', options)}%`;
                    break;
                case '#,##0.000%':
                    options.minimumFractionDigits = 3;
                    options.maximumFractionDigits = 3;
                    text = `${Number(parseFloat(text) * 100).toLocaleString('en', options)}%`;
                    break;
                case '###0.000%':
                    options.minimumFractionDigits = 3;
                    options.maximumFractionDigits = 3;
                    text = `${Number(parseFloat(text) * 100).toLocaleString('en', options)}%`;
                    break;
            }
        }

        const stringParts = text ? text.toString().split('\n') : null;
        if (stringParts?.length > 1) {
            text = stringParts.reduce(
                (a, b) => {
                    return a.length > b.length ? a : b;
                }
            );
        }

        div.innerHTML = text;

        const width: number = JSON.parse(JSON.stringify(div.clientWidth));

        document.body.removeChild(div);

        return width + 1;
    }

    static replaceAll(value: string, replaceValue: string, replaceWithValue: string): string {
        const regex = new RegExp(replaceValue, 'g');

        return value.replace(regex, replaceWithValue);
    }

    static getSentenceCaseOnSelectList(array: SelectListInterface[]): SelectListInterface[] {
        return array.map((item: SelectListInterface) => ({
            ...item,
            value: StringHelper.convertToSentenceCase(item.value)
        }));
    }

    static convertToSentenceCase(value: string): string {
        const words: string[] = value.split(' ');
        let result: string = '';

        for (let i: number = 0; i < words.length; i++) {
            const word: string = words[i];
            const hasConsecutiveUppercase: boolean = /[A-Z]{2,}/.test(word);
            let formattedWord: string = '';

            if (i === 0 || hasConsecutiveUppercase) {
                formattedWord = word;
            } else {
                formattedWord = word.charAt(0).toLowerCase() + word.slice(1).toLowerCase();
            }
            result += formattedWord + ' ';
        }

        return result.trim();
    }

    static isEmptyGuid(guid: string): boolean {
         return Guid.parse(guid).isEmpty();
    }
}
