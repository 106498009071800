import { PresenceLocationTypesEnum } from 'app/nexus-shared/domain/travel-calendar/enums/presence-location-types.enum';

export class PresenceLocationModel {
    daysInLocation: number;
    locationId: number;
    locationName: string;
    locationType: PresenceLocationTypesEnum;
    workDaysInLocation: number;
    childLocations: PresenceLocationModel[];

    constructor(model: PresenceLocationModel = null) {
        this.daysInLocation = model?.daysInLocation ?? null;
        this.locationId = model?.locationId ?? null;
        this.locationName = model?.locationName ?? null;
        this.locationType = model?.locationType ?? null;
        this.workDaysInLocation = model?.workDaysInLocation ?? null;
        this.childLocations = model?.childLocations ?? [];
    }
}
