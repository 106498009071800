<mat-form-field [appearance]="appearance"
    [ngClass]="{'gtn-read-only' : readonly}">
    <mat-label>{{ label }}</mat-label>
    <textarea gtnDisableAutofill #textarea matInput cdkTextareaAutosize
        (blur)="!this.disabled ? blur.emit($event) : null"
        [ngClass]="{'scroll': maxRows}"
        [cdkAutosizeMinRows]="minRows" [cdkAutosizeMaxRows]="maxRows" [(ngModel)]="value" [readonly]="readonly"
        [disabled]="disabled">
    </textarea>
</mat-form-field>
