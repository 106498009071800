<gtn-template-grid [showAddButton]="canAdd" [showQuickFilter]="true" [showExportButton]="true"
    [showExpandCollapse]="showExpandCollapse" [isSessionGroupExpanded]="isSessionGroupExpanded"
    [gridStateKey]="gridStateKey" [application]="application" [gridSettings]="gridSettings"
    (quickFilterTextChange)="onFilterTextChanged($event)"
    (toggleExpandCollapse)="expandCollapse($event)"
    (chipGridSettingClick)="onSavedSettingClicked($event)"
    (addButtonClick)="onAddButtonClicked()" (exportButtonClick)="onExportButtonClicked()">
    <gtn-template-grid-content>
        <ag-grid-angular [gtnHeight]="gridHeight" *ngIf="gridOptions" class="ag-theme-balham"
            [gridOptions]="gridOptions"></ag-grid-angular>
    </gtn-template-grid-content>
</gtn-template-grid>
