import { AfterContentInit, Component, EventEmitter, forwardRef, Input, Output, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { ControlAppearanceType, InputType } from 'app/nexus-shared/components/controls/shared';
import { DateHelper, NexusValidatorHelper } from 'app/nexus-core';
import * as moment from 'moment';
import { MatFormFieldAppearance } from '@angular/material/form-field';

@Component({
    selector: 'gtn-timepicker',
    templateUrl: './timepicker.component.html',
    styleUrls: ['./timepicker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TimepickerComponent),
            multi: true
        }
    ]
})
export class TimepickerComponent implements ControlValueAccessor, AfterContentInit {
    @Input('value') _value: Date;
    @Input() appearance: MatFormFieldAppearance = ControlAppearanceType.fill;
    @Input() canShowValidation: boolean;
    @Input() disabled: boolean;
    @Input() focus: boolean = false;
    @Input() label: string = '';
    @Input() max: string = null;
    @Input() min: string = null;
    @Input() readonly: boolean;
    @Input() step: number;
    @Input() type: string = InputType.time;
    @Output() timeChanged: EventEmitter<any> = new EventEmitter();

    formControl: UntypedFormControl;
    timeString: string;

    constructor(private renderer: Renderer2) {

    }

    ngAfterContentInit() {
        if (this.focus) {
            this.setFocus();
        }
    }

    get value(): Date {
        return this._value;
    }

    set value(date: Date) {
        this._value = date;
        this.timeString = DateHelper.getTime(this._value);
    }

    getErrorMessage(): string[] {
        return NexusValidatorHelper.getErrorMessage(this.formControl);
    }

    onBlur(): void {
        this.onTouched();
    }

    onChange: Function = () => { };

    onTimeUpdated(time: string) {
        const [hour, minute] = time.split(':');
        this._value = moment(this.value).set({ 'hour': Number(hour), 'minute': Number(minute) }).toDate();

        this.onChange(this._value);
        this.timeChanged.emit(this._value);
    }

    onTouched: Function = () => { };

    registerOnChange(fn): void {
        this.onChange = fn;
    }

    registerOnTouched(fn): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    setFocus(): void {
        this.renderer.selectRootElement('input').focus();
    }

    validate(formControl: UntypedFormControl) {
        this.formControl = formControl;
        return this.validateFn(formControl);
    }

    validateFn: Function = () => { };

    writeValue(value): void {
        const currentValue = this.value;

        if (typeof currentValue !== 'undefined' && currentValue !== null && currentValue !== value) {
            this.value = value;
        } else if (typeof value !== 'undefined' && value !== null && currentValue !== value) {
            this.value = value;
        }
    }
}
