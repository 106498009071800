import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseEnterpriseGridComponent } from 'app/nexus-shared/components/base-component/base-enterprise-grid.component';
import { DocumentModel } from 'app/nexus-shared/domain/documents/models/document.model';
import { AgGridHelper } from 'app/nexus-core';
import { ColDef, GridOptions, RowDragEnterEvent } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { DocumentService } from 'app/nexus-core/services/domain/documents/document.service';
import { DocumentSearchModel } from 'app/nexus-shared/domain/documents/models/document-search.model';
import { DocumentsAuthModel } from 'app/nexus-shared/domain/documents/models/documents-auth.model';
import { AreaTypesEnum } from 'app/nexus-shared/domain/documents/enums/area-types.enum';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { FileModel } from 'app/nexus-shared/domain/documents/models/file.model';
import { FolderDocumentCellRendererComponent } from 'app/nexus-shared/domain/documents/components/grids/folder-document-cell-renderer/folder-document-cell-renderer.component';
import { IndividualSettingService } from 'app/nexus-core/services/domain/core/individual-setting.service';
import { AgGridColDefHelper } from 'app/nexus-core/helpers/ag-grid-col-def.helper';
import { AgGridColDefExtras } from 'app/nexus-shared/models/ag-grid-col-def-extras.model';
import { CommonBaseAuditUserModel } from 'app/nexus-shared/domain/contacts/models/common-base-audit-user.model';

@Component({
    selector: 'gtn-template-document-grid',
    templateUrl: './template-document-grid.component.html',
    styleUrls: ['./template-document-grid.component.scss']
})
export class TemplateDocumentGridComponent extends BaseEnterpriseGridComponent<DocumentModel> implements OnInit {
    static readonly templatesNoRowsOverlay = `<span>No templates are available for use.</span>`;

    @Input() documentsUserAuth: DocumentsAuthModel;
    @Output() copyTemplates: EventEmitter<void> = new EventEmitter<void>();

    // gridName: string = 'template_documents_grid';
    // gridVersion: string = '1.0.0';
    isTemplatesSelected: boolean = false;

    constructor(
        protected individualSettingService: IndividualSettingService,
        private documentService: DocumentService) {
        super(individualSettingService);
    }

    ngOnInit(): void {
        const that = this;
        const gridOptions: GridOptions = {
            treeData: true,
            getDataPath: that.getDataPath,
            autoGroupColumnDef: {
                headerName: 'Templates',
                rowDrag: true,
                headerCheckboxSelection: true,
                tooltipField: ObjectHelper.nameOf<DocumentModel>('name'),
                cellRendererParams: {
                    checkbox: true,
                    suppressCount: true,
                    innerRenderer: FolderDocumentCellRendererComponent
                },
                sort: 'asc'
            },
            rowDragEntireRow: true,
            rowDragMultiRow: true,
            suppressRowDrag: true,
            sideBar: null,
            groupSelectsChildren: true,
            groupDefaultExpanded: 0,
            suppressRowClickSelection: true,
            rowSelection: AgGridHelper.rowSelectionMultiple,
            onRowDragEnter: that.onDragStart.bind(that),
            overlayNoRowsTemplate: TemplateDocumentGridComponent.templatesNoRowsOverlay,
            noRowsOverlayComponent: null,
            onSelectionChanged: event => {
                that.isTemplatesSelected = !!event.api.getSelectedNodes()?.length;
            }
        };
        super.ngOnInit(gridOptions);
    }

    getDataPath(data: DocumentModel): string[] {
        const filePathSplit = data.documentPath.split('/');
        filePathSplit.push(data.name);
        filePathSplit.splice(0, 1);
        return filePathSplit;
    }

    onDeselectAllClicked(): void {
        this.gridApi.deselectAll();
    }

    onDragStart(event: RowDragEnterEvent): void {
        event.node.setSelected(true);
        const selectedNodes = this.gridApi.getSelectedNodes();
        let dragText: string;
        if (selectedNodes?.length === 1) {
            dragText = `Copying ${selectedNodes[0].data.name}`;
        } else {
            dragText = `Copying ${selectedNodes?.length} templates`;
        }
        const dragLabel = document.querySelectorAll('.ag-dnd-ghost-label')[0] as HTMLElement;

        if (dragLabel) {
            dragLabel.innerHTML = dragText;
        }
    }

    onTemplateCopyClicked(): void {
        this.copyTemplates.emit();
    }

    protected setColumnDefinitions(): ColDef[] {
        return [
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOfSubProperty<DocumentModel, FileModel>('file', 'extension'), 'Type', null, <AgGridColDefExtras>{ colDef: { maxWidth: 70 } }),
            AgGridColDefHelper.getDateTimeColDef(ObjectHelper.nameOfSubProperty<DocumentModel, CommonBaseAuditUserModel>('lastModifiedByUser', 'changeDateTime'), 'Last Modified', <AgGridColDefHelper>{
                colDef: {
                    maxWidth: 140
                }
            })
        ];
    }

    protected setRowData(): Observable<any[]> {
        const documentSearchModel = <DocumentSearchModel>{ areaTypes: [AreaTypesEnum.FileTemplates] };
        return this.documentService.search(documentSearchModel);
    }

}
