import { Dictionary } from 'app/nexus-shared/models/dictionary';
import { BaseFormArrayModel } from 'app/nexus-shared/models/base-form-array.model';
import { AsyncValidatorFn, ValidatorFn } from '@angular/forms';

export class BaseFormModel {
    disableFormValueChangesSubscription?: boolean = false;
    disableValueUpdateOnFormChange?: boolean = false;
    template?: any;
    formConfiguration?: Dictionary<BaseFormModel> = {};
    formArrayConfiguration?: Dictionary<BaseFormArrayModel> = {};
    formArrayValidators?: Dictionary<ValidatorFn | ValidatorFn[]> = {};
    ignoreProperties: string[];
    ignoreAllExceptProperties: string[];
    validators?: Dictionary<ValidatorFn | ValidatorFn[]> = {};
    asyncValidators?: Dictionary<AsyncValidatorFn | AsyncValidatorFn[]> = {};
    formGroupValidators?: ValidatorFn | ValidatorFn[];
}
