import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BaseHttpService } from 'app/nexus-core/services/index';
import { Observable } from 'rxjs';
import { CompanyModel } from 'app/nexus-shared/domain/contacts/models/company.model';
import { CompanySearchModel } from 'app/nexus-shared/domain/contacts/models/company-search.model';
import { PhoneNumberHelper } from 'app/nexus-core/helpers/phone-number.helper';

@Injectable()
export class CompanyService {
    private apiUrl = environment().webAPIUrls.contacts + 'companies/';

    constructor(private baseHttpService: BaseHttpService) {
    }

    get(companyKey: string): Observable<CompanyModel> {
        return this.baseHttpService.get(`${this.apiUrl}${companyKey}`);
    }

    getByName(name: string): Observable<CompanyModel> {
        return this.baseHttpService.get(`${this.apiUrl}name`);
    }

    list(): Observable<CompanyModel[]> {
        return this.baseHttpService.get(this.apiUrl);
    }

    listByKeys(companyKeys: string[]): Observable<CompanyModel[]> {
        return this.baseHttpService.post(`${this.apiUrl}keys`, companyKeys);
    }

    search(search: CompanySearchModel): Observable<CompanyModel[]> {
        return this.baseHttpService.post(`${this.apiUrl}search`, search);
    }

    create(model: CompanyModel): Observable<string> {
        model.phoneNumbers = PhoneNumberHelper.filterBlankPhoneNumbers(model.phoneNumbers);
        return this.baseHttpService.post(this.apiUrl, model);
    }

    update(model: CompanyModel): Observable<boolean> {
        model.phoneNumbers = PhoneNumberHelper.filterBlankPhoneNumbers(model.phoneNumbers);
        return this.baseHttpService.put(this.apiUrl, model);
    }
}
