import { Injectable, OnDestroy } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { NotificationHubInfoModel } from 'app/nexus-shared/models/notification-hub-info.model';
import { NotificationMessageModel } from 'app/nexus-shared/models/notification-message.model';
import { Subject } from 'rxjs';
import { StringHelper } from '../helpers/string.helper';
import { NotificationHelper } from '../helpers/notification.helper';
import { BaseHttpService } from './base-http.service';
import { LoggingService } from './logging.service';
import { environment } from 'environments/environment';

@Injectable()
export class NotificationService implements OnDestroy {
    private hubConnections: { [apiUrl: string]: HubConnection } = {};
    private notifications: { [topic: string]: Subject<NotificationMessageModel> } = {};

    constructor(
        private http: BaseHttpService,
        private loggingService: LoggingService
    ) {
    }

    ngOnDestroy() {
        Object.keys(this.notifications).forEach(topic => {
            this.notifications[topic].complete();
            this.notifications[topic] = null;
        });

        Object.keys(this.hubConnections).forEach(apiUrl => {
            this.hubConnections[apiUrl].stop();
        });
    }

    registerNotificationSubscriber(apiUrl: string, topic: string, companyKey: string = ''): Subject<NotificationMessageModel> {
        const internalTopic = this.getTopic(topic, companyKey);

        if (!this.notifications[internalTopic]) {
            this.notifications[internalTopic] = new Subject();
        }

        if (!this.hubConnections[apiUrl]) {
            this.http.get(`${apiUrl}common/notification-hub`).subscribe(
                (response: NotificationHubInfoModel) => {

                    const options = {
                        accessTokenFactory: () => response.accessToken
                    };

                    this.hubConnections[apiUrl] = new HubConnectionBuilder()
                        .withUrl(response.url, options)
                        .withAutomaticReconnect()
                        .configureLogging(environment().websocketLogLevel)
                        .build();

                    this.hubConnections[apiUrl]
                        .start()
                        .catch(err => {
                            console.error(err.toString());
                        });

                    this.hubConnections[apiUrl].onclose(error => {
                        console.error(error);
                        this.loggingService.logError(error);
                    });

                    this.registerTopicSubscriber(this.hubConnections[apiUrl], topic, internalTopic, companyKey);
                }
            );
        } else {
            this.registerTopicSubscriber(this.hubConnections[apiUrl], topic, internalTopic, companyKey);
        }

        return this.notifications[internalTopic];
    }

    unregisterNotificationSubscriber(apiUrl: string, topic: string, companyKey: string = '') {
        const internalTopic = this.getTopic(topic, companyKey);

        if (this.notifications[internalTopic]) {
            this.notifications[internalTopic].complete();
            this.notifications[internalTopic] = null;

            if (Object.keys(this.notifications).filter(key => key.indexOf(topic) > -1).length === 0) {
                this.hubConnections[apiUrl].off(topic);
            }
        }
    }

    private registerTopicSubscriber(hubConnection: HubConnection, topic: string, internalTopic: string, companyKey: string) {
        hubConnection.on(topic, ((notification: string) => {
            const notificationModel = this.deserializeResponse(notification);
            if (this.notifications[internalTopic] && (!companyKey || notificationModel.companyKey === companyKey)) {
                this.notifications[internalTopic].next(notificationModel);
            }
        }));
    }

    private deserializeResponse(notificationSerialized: string): NotificationMessageModel {
        const notification = JSON.parse(notificationSerialized);
        Object.keys(notification).forEach(propertyName => {
            const newPropertyName = StringHelper.lowerCaseFirst(propertyName);
            notification[newPropertyName] = notification[propertyName];
            notification[propertyName] = undefined;
        });

        return notification;
    }

    private getTopic(topic: string, companyKey: string) {
        return NotificationHelper.getTopicName(topic) + companyKey;
    }
}
