import { Component, Input, OnInit } from '@angular/core';
import { BaseReadonlyEditComponent } from 'app/nexus-shared/components/base-component/base-readonly-edit.component';
import { DocumentModel } from 'app/nexus-shared/domain/documents/models/document.model';
import { Observable, of } from 'rxjs';
import { DocumentService } from 'app/nexus-core/services/domain/documents/document.service';
import { map, tap } from 'rxjs/operators';
import { DocumentEventTypesEnum } from 'app/nexus-shared/domain/documents/enums/document-event-types.enum';
import { FolderDocumentGridService } from 'app/nexus-core/services/domain/documents/folder-document-grid.service';

@Component({
    selector: 'gtn-document-readonly-edit',
    templateUrl: './document-readonly-edit.component.html',
    styleUrls: ['./document-readonly-edit.component.scss']
})
export class DocumentReadonlyEditComponent extends BaseReadonlyEditComponent<DocumentModel, boolean> implements OnInit {
    @Input() documentKey: string = null;
    @Input() canEdit: boolean = true;

    file: File = null;
    showReadOnlyEditToggle: boolean;

    constructor(
        private documentService: DocumentService,
        private folderDocumentGridService: FolderDocumentGridService) {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    getValue(value: DocumentModel): Observable<DocumentModel> {
        if (value) {
            return of(new DocumentModel(value));
        }

        return this.documentService.get(this.documentKey).pipe(map(res => {
            return new DocumentModel(res);
        }));
    }

    saveValue(): Observable<boolean> {
        this.value.eventType = DocumentEventTypesEnum.Metadata;
        return this.documentService.updateMetadata(this.value).pipe(tap(_ => {
            this.folderDocumentGridService.refreshDocuments(true);
        }));
    }

}
