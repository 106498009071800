import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { BaseEnterpriseGridComponent } from 'app/nexus-shared/components/base-component/base-enterprise-grid.component';
import { FolderModel } from 'app/nexus-shared/domain/documents/models/folder.model';
import { AgGridHelper, ArrayHelper } from 'app/nexus-core';
import { CellEditingStoppedEvent, ColDef, GetContextMenuItemsParams, GridOptions, IRowNode, MenuItemDef, RowClickedEvent, RowDragEndEvent, RowDragMoveEvent } from 'ag-grid-community';
import { Observable, of } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { SimpleChangesTyped } from 'app/nexus-shared/models/simple-changes-typed.type';
import { ValidationModel } from 'app/nexus-shared/models';
import { ToastService } from 'app/nexus-core/services/toast.service';
import { DocumentFolderService } from 'app/nexus-core/services/domain/documents/document-folder.service';
import { FolderCellGroupRendererComponent } from 'app/nexus-shared/domain/documents/components/grids/folder-cell-group-renderer/folder-cell-group-renderer.component';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { DocumentModel } from 'app/nexus-shared/domain/documents/models/document.model';
import { DocumentsGridViewTypes } from 'app/nexus-shared/domain/documents/enums/documents-grid-view.types';
import { ConfirmActionTypesEnum } from 'app/nexus-shared/domain/documents/enums/confirm-action-types.enum';
import { IDocumentMenuItem } from 'app/nexus-shared/domain/documents/models/document-menu-item.interface';
import { DocumentsAuthModel } from 'app/nexus-shared/domain/documents/models/documents-auth.model';
import { FolderFavoriteModel } from 'app/nexus-shared/domain/documents/models/folder-favorite.model';
import { AreaTypesEnum } from 'app/nexus-shared/domain/documents/enums/area-types.enum';
import { DocumentsRoutingConfigurationConstants } from 'app/modules/documents/routing/documents-routing-configuration.constants';
import { Router } from '@angular/router';
import { DocumentFolderMoveViewModel } from 'app/nexus-shared/domain/documents/models/document-view-models/document-folder-move-view.model';
import { FolderDocumentGridService } from 'app/nexus-core/services/domain/documents/folder-document-grid.service';
import { IndividualSettingService } from 'app/nexus-core/services/domain/core/individual-setting.service';
import { FolderTagModel } from 'app/nexus-shared/domain/documents/models/folder-tag.model';
import { FolderTagService } from 'app/nexus-core/services/domain/documents/folder-tag.service';
import { FolderService } from 'app/nexus-core/services/domain/documents/folder.service';
import { FolderSearchModel } from 'app/nexus-shared/domain/documents/models/folder-search.model';
import { DocumentRoutingKeyConstants } from 'app/modules/documents/routing/document-routing-key.constants';

@Component({
    selector: 'gtn-folders-grid',
    templateUrl: './folders-grid.component.html',
    styleUrls: ['./folders-grid.component.scss']
})
export class FoldersGridComponent extends BaseEnterpriseGridComponent<FolderModel> implements OnInit, OnChanges {
    static readonly foldersNoRowsOverlay = `<span>There are no folders; right click to add one.</span>`;
    static readonly basClientAreaOverlay = `<span>Please select a client folder</span>`;


    @Input() rootFolder: FolderModel;
    @Input() selectedFolderKey: string;
    @Input() documentsUserAuth: DocumentsAuthModel;
    @Input() folderFavorites: FolderFavoriteModel[];
    @Input() isClientAreaFolder: boolean;

    @Output() dataRefresh: EventEmitter<void> = new EventEmitter();
    @Output() itemsDeleted: EventEmitter<void> = new EventEmitter();
    @Output() addFolderFavorite: EventEmitter<FolderModel> = new EventEmitter();
    @Output() removeFolderFavorite: EventEmitter<FolderModel> = new EventEmitter();
    @Output() documentFolderMove: EventEmitter<DocumentFolderMoveViewModel> = new EventEmitter();
    @Output() viewFolderProperties: EventEmitter<FolderModel> = new EventEmitter();
    @Output() selectedFolderDeleted: EventEmitter<string> = new EventEmitter();
    @Output() dataChange: EventEmitter<FolderModel[]> = new EventEmitter();
    @Output() tagFoldersSearch: EventEmitter<FolderSearchModel> = new EventEmitter();

    @Input() selectedFolderTags: FolderTagModel[];
    @Output() selectedFolderTagsChange: EventEmitter<FolderTagModel[]> = new EventEmitter();
    @Input() isFolderTagSearch: boolean;
    @Output() isFolderTagSearchChange: EventEmitter<boolean> = new EventEmitter();

    gridName: string = 'folders_grid';
    gridVersion: string = '1.0.0';
    areaTypesEnum = AreaTypesEnum;
    confirmActionType: ConfirmActionTypesEnum;
    confirmActionEntities: string[];
    folders: FolderModel[];
    folderToDelete: FolderModel;
    folderTagOptions: FolderTagModel[];
    isEntityCopied: boolean;
    previousSelectedFolderTags: FolderTagModel[];
    quickFilterLabel: string = 'Folder search';
    renameRowKey: string;
    rowDragHoverFolderKey: string;
    showConfirmModal: boolean = false;
    validationModels: ValidationModel[];

    constructor(
        protected individualSettingService: IndividualSettingService,
        private documentFolderService: DocumentFolderService,
        private folderDocumentGridService: FolderDocumentGridService,
        private folderTagService: FolderTagService,
        private folderService: FolderService,
        private router: Router,
        private toastService: ToastService
    ) {
        super(individualSettingService);
    }

    ngOnChanges(changes: SimpleChangesTyped<this>) {
        super.ngOnChanges(changes);
        if (changes.selectedFolderKey && !changes.selectedFolderKey.isFirstChange()) {
            if (this.selectedFolderKey) {
                this.setSelectedFolder(this.selectedFolderKey, changes.selectedFolderKey.previousValue);
            } else {
                this.setSelectedFolder(this.rootFolder.folderKey, changes.selectedFolderKey.previousValue);
            }
        }
    }

    ngOnInit(): void {
        const that = this;
        const gridOptions: GridOptions = {
            headerHeight: 0,
            sideBar: null,
            treeData: true,
            enableGroupEdit: true,
            autoGroupColumnDef: {
                rowDrag: true,
                minWidth: 300,
                headerName: 'Files',
                cellEditor: 'agTextCellEditor',
                field: ObjectHelper.nameOf<FolderModel>('name'),
                editable: true,
                cellRendererParams: {
                    suppressCount: true,
                    innerRenderer: FolderCellGroupRendererComponent,
                    getSelectedFolderKey: that.getSelectedFolderKey.bind(that)
                },
                cellClassRules: {
                    'drag-hover': (params) => {
                        return params?.node?.data?.folderKey === that?.rowDragHoverFolderKey;
                    },
                },
                sortable: true,
                sort: 'asc',
                comparator: AgGridHelper.stringComparator
            },
            groupDefaultExpanded: 0,
            rowDragEntireRow: true,
            stopEditingWhenCellsLoseFocus: true,
            suppressRowDrag: true,
            suppressContextMenu: false,
            rowSelection: 'single',
            suppressClickEdit: true,
            overlayNoRowsTemplate: that.isClientAreaFolder ? FoldersGridComponent.basClientAreaOverlay : FoldersGridComponent.foldersNoRowsOverlay,
            noRowsOverlayComponent: null,
            getRowId: params => {
                return params.data.folderKey;
            },
            statusBar: {
                statusPanels: [AgGridHelper.lastUpdatedDateTime]
            },
            getDataPath: that.getDataPath.bind(that),
            onCellValueChanged: that.onFolderNameChanged.bind(that),
            onRowDragMove: that.onRowDragMove.bind(that),
            onRowDragLeave: that.onRowDragLeave.bind(that),
            onRowDragEnd: that.onRowDragEnd.bind(that),
            getContextMenuItems: (params) => {
                if (!this.isClientAreaFolder || (this.isFolderTagSearch && this.selectedFolderKey)) {
                    return that.setContextMenu(params);
                }
                return null;
            }
        };
        super.ngOnInit(gridOptions);

        this.isEntityCopied = this.folderDocumentGridService.isEntityCopied;
        this.subscriptions.add(this.folderDocumentGridService.documentEntityCopy$.subscribe(isCopied => {
            this.isEntityCopied = isCopied;
        }));

        this.getFolderTags();
    }

    getDataPath(data: FolderModel): string[] {
        const filePathSplit = data.folderPath.split('/');
        const index = this.isFolderTagSearch ? 2 : filePathSplit.findIndex(x => x === this.rootFolder.name) + 1;
        filePathSplit.splice(0, index);
        return filePathSplit;
    }

    getSelectedFolderKey(): string {
        return this.selectedFolderKey;
    }

    onClientFolderClicked(folder: FolderModel): void {
        if (folder?.folderKey) {
            this.router.navigate(DocumentsRoutingConfigurationConstants.documentAreaConfigurations.getClient().routing.navigationRoute(folder.folderKey));
        }
    }

    onGridReady() {
        super.onGridReady();
        if (this.selectedFolderKey) {
            setTimeout(() => {
                const node = this.gridApi.getRowNode(this.selectedFolderKey);
                if (node) {
                    this.setSelectedFolder(this.selectedFolderKey);
                }
            });
        }
    }

    onCancelClicked(): void {
        this.showConfirmModal = false;
        this.folderToDelete = null;
    }

    onConfirmClicked(): void {
        this.documentFolderService.deleteFolder(this.folderToDelete.folderKey).pipe(finalize(() => {
            if (this.isFolderTagSearch) {
                this.tagFoldersSearch.emit(<FolderSearchModel>{ folderKeys: [this.folderToDelete.folderKey] });
            }
            if (this.folderToDelete.folderKey === this.selectedFolderKey) {
                this.selectedFolderDeleted.emit(this.folderToDelete.parentFolderKey);
            } else {
                this.folderDocumentGridService.refreshDocuments();
            }

            this.folderToDelete = null;
            this.showConfirmModal = false;
        })).subscribe(_ => {
            this.itemsDeleted.emit();
        }, err => {
            this.toastService.showErrorToast(err);
        });
    }

    onFolderNameChanged(event: CellEditingStoppedEvent): void {
        event.data.name = event.newValue;
        this.folderDocumentGridService.updateFolder(event.data);
    }


    onFolderTagChange(): void {
        this.selectedFolderTagsChange.emit(this.selectedFolderTags);
        if (this.selectedFolderTags?.length) {
            if (this.rootFolder.folderKey !== DocumentRoutingKeyConstants.areaKeys.clients) {
                this.router.navigate(DocumentsRoutingConfigurationConstants.documentAreaConfigurations.getClients().routing.navigationRoute(),
                    {
                        queryParams: { tags: this.selectedFolderTags.map(x => x.folderTagName).toString() }
                    });
            } else if (JSON.stringify(this.selectedFolderTags) !== JSON.stringify(this.previousSelectedFolderTags)) {
                this.previousSelectedFolderTags = JSON.parse(JSON.stringify(this.selectedFolderTags));
                this.folderService.search(<FolderSearchModel>{ folderTags: this.selectedFolderTags.map(x => x.folderTagName) }).subscribe(res => {
                    this.data = res;
                    this.isFolderTagSearchChange.emit(this.isFolderTagSearch);
                    this.refreshGridData();
                    this.dataChange.emit(this.data);
                });
            }
        }
    }

    onRowClicked(rowClickedEvent: RowClickedEvent): void {
        if (this.isFolderTagSearch) {
            this.folderService.search(<FolderSearchModel>{ includeChildren: true, parentFolderPath: rowClickedEvent.data.folderPath }).subscribe(res => {
                this.data = ArrayHelper.getUniqueItemsByProperty([...this.data, ...res], ObjectHelper.nameOf<FolderModel>('folderKey'));
                this.refreshGridData();
                this.dataChange.emit(this.data);
            });
        }
        this.rowClick.emit(rowClickedEvent.data);

    }

    onRowDragEnd(event: RowDragEndEvent): void {
        this.resetRowHoverState();

        // coming from grouped templates grid and handled in the dropzone set
        if (!event?.node?.data || event.node.data.areaType === AreaTypesEnum.FolderTemplates || event.node.data.areaType === AreaTypesEnum.FileTemplates) {
            return;
        }

        if (event?.node?.data?.type === DocumentsGridViewTypes.File) {
            this.handleDocumentDragged(event);
        } else {
            this.handleFolderDragged(event);
        }
    }

    onRowDragLeave(): void {
        this.resetRowHoverState();
    }

    onRowDragMove(event: RowDragMoveEvent): void {
        if (event.overNode?.data?.folderKey === this.rowDragHoverFolderKey || event?.node?.data?.parentFolderKey === event?.overNode?.data?.folderKey) {
            return;
        }

        const overNodeFolderClient = event.overNode?.data?.clientFolder;
        const dragClient = event.node?.data?.clientFolder || event.node?.data?.folder?.clientFolder || event.node?.data?.document?.clientDocument;

        if (overNodeFolderClient?.companyKey !== dragClient?.companyKey ||
            overNodeFolderClient?.individualKey !== dragClient?.individualKey
        ) {
            return;
        }

        if (!event?.overNode) {
            if (this.rowDragHoverFolderKey) {
                this.resetRowHoverState();
            }
            return;
        }

        let folderKey: string;
        if (!event.node?.data) {
            folderKey = null;
        } else if (event.node?.data?.type === DocumentsGridViewTypes.File) {
            folderKey = event.node.data.document.folderKey;
        } else if (event.node?.data?.type === DocumentsGridViewTypes.Folder) {
            folderKey = event.node.data.folder.folderKey;
        } else if (event.node?.data?.folderKey) {
            folderKey = event.node.data.folderKey;
        }
        if (folderKey === event.overNode.data.folderKey) {
            if (this.rowDragHoverFolderKey) {
                this.resetRowHoverState();
            }
            return;
        }

        const refreshNodes: IRowNode[] = event?.overNode ? [event.overNode] : [];
        if (this.rowDragHoverFolderKey) {
            refreshNodes.push(this.gridApi.getRowNode(this.rowDragHoverFolderKey));
        }
        this.rowDragHoverFolderKey = event.overNode.data.folderKey;
        this.gridApi.refreshCells({
            rowNodes: refreshNodes,
            force: true
        });
    };

    protected setColumnDefinitions(): ColDef[] {
        return [];
    }

    protected setRowData(): Observable<FolderModel[]> {
        return of(this.data).pipe(finalize(() => {
            if (this.renameRowKey) {
                this.setExpandedRows(this.renameRowKey);
                setTimeout(() => {
                    const node = this.gridApi.getRowNode(this.renameRowKey);
                    this.gridApi.startEditingCell({
                        rowIndex: node.rowIndex,
                        colKey: AgGridHelper.agGridAutoGroupColId
                    });
                    this.renameRowKey = null;
                });
            }
        }), map(res => {
            this.folders = JSON.parse(JSON.stringify(res));
            return res.filter(x => x.parentFolderKey && x.parentFolderKey !== this.rootFolder?.parentFolderKey);
        }));
    }

    private getFolderTags(): void {
        this.folderTagService.search().subscribe(res => {
            this.folderTagOptions = res;
        });
    }

    private handleDocumentDragged(event: RowDragEndEvent): void {
        let folder: FolderModel;

        if (!event.overNode) {
            folder = this.rootFolder;
        } else {
            folder = event.overNode.data;
        }
        if (folder?.clientFolder?.companyKey !== event?.node?.data?.document?.clientDocument?.companyKey ||
            folder?.clientFolder?.individualKey !== event?.node?.data?.document?.clientDocument?.individualKey
        ) {
            return;
        }

        if (event.node.data.document.folderKey === folder.folderKey) {
            return;
        }

        const folderMovedFrom = this.selectedFolderKey ? this.gridApi.getRowNode(this.selectedFolderKey).data : this.rootFolder;
        const documentFolderMove = new DocumentFolderMoveViewModel(folderMovedFrom, folder);

        if (event?.nodes.length) {
            documentFolderMove.documentsMoved = event.nodes.map(x => x.data.document);
        } else {
            documentFolderMove.documentsMoved = [event.node.data.document];
        }

        this.documentFolderMove.emit(documentFolderMove);
    }

    private handleFolderDragged(event: RowDragEndEvent): void {
        const folderMoved: FolderModel = event.node.data?.type ? event.node.data.folder : event.node.data;
        let folderMovedTo: FolderModel;
        if (!event.overNode) {
            folderMovedTo = this.rootFolder;
        } else {
            folderMovedTo = event.overNode.data;
        }

        if (folderMoved?.clientFolder?.companyKey !== folderMovedTo?.clientFolder?.companyKey ||
            folderMoved?.clientFolder?.individualKey !== folderMovedTo?.clientFolder?.individualKey
        ) {
            return;
        }

        if (folderMoved.folderKey === folderMovedTo.folderKey || folderMoved.parentFolderKey === folderMovedTo.folderKey) {
            return;
        }

        const folderMovedFrom = this.gridApi.getRowNode(folderMoved.parentFolderKey)?.data ?? this.rootFolder;
        const documentFolderMove = new DocumentFolderMoveViewModel(folderMovedFrom, folderMovedTo);
        documentFolderMove.folderMoved = folderMoved;

        this.documentFolderMove.emit(documentFolderMove);
    }

    private resetRowHoverState(): void {
        if (this.rowDragHoverFolderKey) {
            const key = this.rowDragHoverFolderKey;
            this.rowDragHoverFolderKey = null;
            this.gridApi.refreshCells({
                rowNodes: [this.gridApi.getRowNode(key)],
                force: true
            });
        }
    }

    private setContextMenu(params: GetContextMenuItemsParams): MenuItemDef[] {
        const contextMenuItems = []; //setting these without pushing below gives type errors?
        if (this.documentsUserAuth.writeAccess) {
            contextMenuItems.push(
                this.contextMenuItems.newFolder(params),
            );
        }
        const isFolder: boolean = !!params?.node?.data?.folderKey;
        if (isFolder) {
            const isFavorite: boolean = !!this.folderFavorites.find(x => x.folder.folderKey === params.node.data.folderKey);
            if (params.node.data.folderKey !== this.rootFolder.folderKey) {

                contextMenuItems.push(
                    this.contextMenuItems.open(params),
                    'separator',
                    this.contextMenuItems.expandChildren(params),
                    this.contextMenuItems.collapseChildren(params),
                    'separator'
                );
            }

            if (isFavorite) {
                contextMenuItems.push(
                    this.contextMenuItems.removeFolderFavorite(params)
                );
            } else {
                contextMenuItems.push(
                    this.contextMenuItems.addFolderFavorite(params)
                );
            }

            if (this.isEntityCopied && this.documentsUserAuth.writeAccess) {
                contextMenuItems.push(
                    'separator',
                    this.contextMenuItems.paste(params)
                );
            }

            if (!params.node.data.folderSettings.isReadOnly && this.documentsUserAuth.writeAccess) {
                contextMenuItems.push(
                    'separator',
                    this.contextMenuItems.rename(params),
                    this.contextMenuItems.delete(params)
                );
            }

            if (this.documentsUserAuth.isAdmin) {
                contextMenuItems.push(
                    'separator',
                    this.contextMenuItems.viewFolderProperties(params)
                );
            }
        } else {
            if (this.rootFolder?.folderKey === DocumentRoutingKeyConstants.areaKeys.clients) {
                return;
            }
            if (this.folderFavorites.find(x => x.folder.folderKey === this.rootFolder.folderKey)) {
                contextMenuItems.push(this.contextMenuItems.removeFolderFavorite(params));
            } else {
                contextMenuItems.push(this.contextMenuItems.addFolderFavorite(params));
            }
            contextMenuItems.push(
                'separator',
                'expandAll',
                'contractAll');

            if (this.documentsUserAuth.isAdmin) {
                contextMenuItems.push(
                    'separator',
                    this.contextMenuItems.viewFolderProperties(params)
                );
            }
        }
        return contextMenuItems;
    }

    private setSelectedFolder(folderKey: string, previousFolderKey: string = null): void {
        const refreshNodes: IRowNode[] = [];
        const node = this.gridApi?.getRowNode(folderKey);
        if (node && folderKey !== this.rootFolder.folderKey) {
            refreshNodes.push(node);
            node?.setSelected(true);
            this.setExpandedRows(node?.data?.folderKey);
        } else {
            this.gridApi?.deselectAll();
        }

        const previousNode: IRowNode = this.gridApi?.getRowNode(previousFolderKey);
        if (previousNode) {
            refreshNodes.push(this.gridApi.getRowNode(previousFolderKey));
        }

        if (refreshNodes?.length) {
            this.gridApi.refreshCells({
                rowNodes: refreshNodes,
                force: true
            });
        }
    }

    private setExpandedRows(folderKey: string): void {
        const node = this.gridApi.getRowNode(folderKey);
        node?.setExpanded(true);
        if (node?.data?.parentFolderKey && node.data.parentFolderKey !== this.rootFolder?.folderKey) {
            this.setExpandedRows(node?.data?.parentFolderKey);
        }
    }

    private expandCollapseChildren(folder: FolderModel, expand: boolean = true): void {
        const node = this.gridApi.getRowNode(folder.folderKey);
        node.allLeafChildren.forEach(x => {
            x.setExpanded(expand);
        });
    }

    private contextMenuItems: IDocumentMenuItem<FolderModel> = {
        addFolderFavorite: (params) => {
            return {
                name: 'Add as favorite',
                action: () => {
                    if (params?.node?.data) {
                        this.addFolderFavorite.emit(params.node.data);
                    } else {
                        this.addFolderFavorite.emit(this.rootFolder);
                    }
                },
                icon: `<i class="fas fa-folder-heart"></i>`
            };
        },
        collapseChildren: (params) => {
            return {
                name: 'Collapse Sub-Folders',
                action: () => {
                    this.expandCollapseChildren(params.node.data, false);
                },
                icon: `<i class="fas fa-chevron-up"></i>`
            };
        },
        delete: (params) => {
            return {
                name: 'Delete',
                action: () => {
                    if (params?.node?.data) {
                        this.folderToDelete = params.node.data;
                        this.confirmActionType = ConfirmActionTypesEnum.DeleteFolder;
                        this.confirmActionEntities = [params.node.data.name];
                        this.showConfirmModal = true;
                    }
                },
                icon: `<i class='fas fa-trash'></i>`
            };
        },
        expandChildren: (params) => {
            return {
                name: 'Expand Sub-Folders',
                action: () => {
                    this.expandCollapseChildren(params.node.data);
                },
                icon: `<i class="fas fa-chevron-down"></i>`
            };
        },
        newFolder: (params) => {
            return {
                name: 'New folder',
                action: () => {
                    const parentFolder = params.node?.data ? params.node.data : this.rootFolder;
                    this.folderDocumentGridService.createFolder(parentFolder).subscribe(res => {
                        this.renameRowKey = res;
                        this.tagFoldersSearch.emit(<FolderSearchModel>{ folderKeys: [res] });
                    }, err => {
                        this.toastService.showErrorToast(err);
                    });
                },
                icon: `<i class='fas fa-folder-plus'></i>`
            };
        },
        open: (params) => {
            return {
                name: 'Open',
                action: () => {
                    this.rowClick.emit(params.node.data);
                },
                icon: `<i class='fas fa-folder-open'></i>`
            };
        },
        paste: (params) => {
            return {
                name: 'Paste',
                action: () => {
                    // paste document/folder
                    const entityToPaste = this.folderDocumentGridService.getPastedDocumentEntity();
                    const documentsToPaste = this.folderDocumentGridService.getPastedDocuments();
                    if (entityToPaste?.hasOwnProperty(ObjectHelper.nameOf<DocumentModel>('documentKey'))) {
                        this.folderDocumentGridService.onDocumentCopied(entityToPaste as DocumentModel, params.node.data);
                    } else if (entityToPaste?.hasOwnProperty(ObjectHelper.nameOf<FolderModel>('parentFolderKey'))) {
                        // no folder copy yet
                        this.toastService.showMessageToast('Folder copy is not supported yet');
                    } else if (documentsToPaste?.length) {
                        this.folderDocumentGridService.onDocumentsCopied(documentsToPaste, params.node.data);
                    }
                },
                icon: `<i class='fas fa-paste'></i>`,
                disabled: !this.isEntityCopied
            };
        },
        removeFolderFavorite: (params) => {
            return {
                name: 'Remove as favorite',
                action: () => {
                    if (params?.node?.data) {
                        this.removeFolderFavorite.emit(params.node.data);
                    } else {
                        this.removeFolderFavorite.emit(this.rootFolder);
                    }
                },
                icon: `<i class='fas fa-folder-minus'></i>`,
            };
        },
        rename: (params) => {
            return {
                name: 'Rename',
                action: () => {
                    this.gridApi.startEditingCell({
                        rowIndex: params.node.rowIndex,
                        colKey: params.column.getColId()
                    });
                },
                icon: `<i class='fas fa-edit'></i>`
            };
        },
        viewFolderProperties: (params) => {
            return {
                name: 'Properties',
                action: () => {
                    this.viewFolderProperties.emit(params.node?.data ?? this.rootFolder);
                },
                icon: `<i class='far fa-folder-gear'></i>`,
            };
        },
    };

}
