import { Component, OnInit } from '@angular/core';
import { QuestionnaireTemplateModel } from 'app/nexus-shared/domain/questionnaires/models/questionnaire-template.model';
import { SelectListInterface } from 'app/nexus-shared/interfaces';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { LocationModel } from 'app/nexus-shared/domain/locations';
import { QuestionnaireLocationModel } from 'app/nexus-shared/domain/questionnaires/models/questionnaire-location.model';
import { EnumHelper } from 'app/nexus-core';
import { QuestionnaireTypesEnum } from 'app/nexus-shared/domain/questionnaires/enums/questionnaire-types.enum';
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { QuestionnaireFormHelper } from 'app/nexus-shared/domain/questionnaires/components/forms/questionnaire-form.helper';
import { SectionModel } from 'app/nexus-shared/domain/questionnaires/models/section.model';
import { Guid } from 'app/nexus-shared/models/guid.type';
import { BaseQuestionnaireFormComponent } from 'app/nexus-shared/domain/questionnaires/components/forms/base-questionnaire-form.component';
import { QuestionnaireEventService } from 'app/nexus-core/services/domain/questionnaires/questionnaire-event.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { QuestionnaireDropdownsService } from 'app/nexus-core/services/domain/questionnaires/questionnaire-dropdowns.service';
import { finalize } from 'rxjs/operators';
import { QuestionnaireTemplateStatusesEnum } from 'app/nexus-shared/domain/questionnaires/enums/questionnaire-template-statuses.enum';
import { QuestionnaireHelper } from 'app/nexus-core/helpers/questionnaire.helper';

@Component({
    selector: 'gtn-questionnaire-template-form',
    templateUrl: './questionnaire-template-form.component.html',
    styleUrls: ['./questionnaire-template-form.component.scss', '../questionnaire-forms.component.scss']
})
export class QuestionnaireTemplateFormComponent extends BaseQuestionnaireFormComponent<QuestionnaireTemplateModel> implements OnInit {
    years: SelectListInterface[];
    questionnaireTypes: SelectListInterface[];
    questionnaireStatuses: SelectListInterface[];
    sectionsFormArray: UntypedFormArray;
    selectedSection: UntypedFormGroup;
    isTemplateMode = true;

    constructor(public questionnaireDropdownService: QuestionnaireDropdownsService,
                private questionnaireEventService: QuestionnaireEventService) {
        super();
    }

    ngOnInit() {
        const formConfiguration = QuestionnaireFormHelper.getQuestionnaireTemplateFormConfiguration();
        super.ngOnInit(formConfiguration);
        this.subscriptions.add(this.questionnaireEventService.sectionColumnAction$.subscribe(sectionAction => {
            const section = this.formGroupRef.get(ObjectHelper.nameOf<QuestionnaireTemplateModel>('sections')).value.find(x => x.key === sectionAction.newSectionKey) as SectionModel;
            const previousSection = this.formGroupRef.get(ObjectHelper.nameOf<QuestionnaireTemplateModel>('sections')).value.find(x => x.key === sectionAction.previousSectionKey) as SectionModel;
            if (section) {
                section.parentSectionColumnKey = sectionAction.sectionColumnKey;
            }

            if (previousSection) {
                section.parentSectionColumnKey = null;
            }
        }));
    }

    initUIControls() {
        this.isLoading = true;
        this.getYears();
        this.questionnaireTypes = EnumHelper.convertToSelectList(QuestionnaireTypesEnum, false, true).filter(x => +x.id < 500);
        this.questionnaireStatuses = EnumHelper.convertToSelectList(QuestionnaireTemplateStatusesEnum, false, false, null, false);
        this.questionnaireDropdownService.search(false).pipe(finalize(() => this.isLoading = false)).subscribe();
    }

    initFormCustomizations() {
        this.sectionsFormArray = this.formGroupRef.get(ObjectHelper.nameOf<QuestionnaireTemplateModel>('sections')) as UntypedFormArray;
        this.selectedSection = this.sectionsFormArray.at(0) as UntypedFormGroup;
    }

    onCountryChanged(country: LocationModel) {
        this.formGroupRef.get(ObjectHelper.nameOf<QuestionnaireTemplateModel>('location')).get(ObjectHelper.nameOf<QuestionnaireLocationModel>('name')).setValue(country?.name ?? null);
    }

    onAddSectionClicked() {
        const newSection = new SectionModel();
        newSection.order = this.sectionsFormArray?.length ? this.sectionsFormArray.length + 1 : 1;
        newSection.title = `Section ${newSection.order}`;
        newSection.key = Guid.newGuid();
        this.initFormArrayFormGroup(newSection, this.sectionsFormArray, QuestionnaireFormHelper.getSectionFormConfiguration());
        if (!this.selectedSection) {
            this.selectedSection = this.sectionsFormArray.at(this.sectionsFormArray.length - 1) as UntypedFormGroup;
        }
        this.questionnaireEventService.setQuestionnaireSections(this.value?.sections ?? []);
        this.formGroupRef.markAsDirty();
    }

    onCloneSectionClicked(formGroup: AbstractControl<any>): void {
        const val = QuestionnaireHelper.cloneSection(JSON.parse(JSON.stringify(this.getFormGroupFromAbstractControl(formGroup).value as SectionModel)));
        this.initFormArrayFormGroup(val, this.sectionsFormArray, QuestionnaireFormHelper.getSectionFormConfiguration(), this.sectionsFormArray.length);
        this.formGroupRef.markAsDirty();
    }

    onDeleteSectionClicked(index: number) {
        this.sectionsFormArray.removeAt(index);
        this.formGroupRef.markAsDirty();
    }

    onSectionClicked(formGroup: AbstractControl) {
        this.selectedSection = this.getFormGroupFromAbstractControl(formGroup);
        this.questionnaireEventService.setQuestionnaireSections(this.value?.sections ?? []);
    }

    onSectionDrop($event: CdkDragDrop<any>): void {
        this.moveItemInFormArray(this.sectionsFormArray, $event.currentIndex, $event.previousIndex, ObjectHelper.nameOf<SectionModel>('order'));
    }

    private getYears(): void {
        this.years = [];
        let year = new Date().getFullYear() - 3;
        for (let i = 0; i < 6; i++) {
            this.years.push({ id: year, value: year.toString() });
            year++;
        }
    }
}
