import { ComponentFactoryResolver, ComponentRef, Directive, Input, OnChanges, OnInit, ViewContainerRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DynamicFormFieldConfig } from 'app/nexus-shared/components/dynamic-form/shared/interfaces/dynamic-form-field-config.interface';
import { DynamicFormField } from 'app/nexus-shared/components/dynamic-form/shared/interfaces/dynamic-form-field.interface';

@Directive({
    selector: '[gtnDynamicFormField]'
})
export class DynamicFormFieldDirective implements OnChanges, OnInit {
    @Input() customField: DynamicFormFieldConfig;
    @Input() formGroup: UntypedFormGroup;

    component: ComponentRef<DynamicFormField>;

    constructor(
        private resolver: ComponentFactoryResolver,
        private container: ViewContainerRef
    ) {
    }

    ngOnInit() {
        if (this.customField?.controlComponent) {
            const component = this.resolver.resolveComponentFactory<DynamicFormField>(this.customField.controlComponent);
            this.component = this.container.createComponent(component);
            this.component.instance.config = this.customField;
            this.component.instance.formGroup = this.formGroup;
        }
    }

    ngOnChanges() {
        if (this.component && this.customField) {
            this.component.instance.config = this.customField;
            this.component.instance.formGroup = this.formGroup;
        }
    }
}
