import { LogLevel } from '@microsoft/signalr';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { versions } from './versions';

export class Environment {
    version: string;
    production: boolean;
    appInsightsKey: string;
    appName: string;
    envPrefix: string;
    supportEmailAddress: string;
    fileSizeLimitMB: number;
    publicBlobStorageUrl: string;
    externalUrls: {
        gtn: string;
        myGTNPortal: string;
        mobile: string;
    };
    licenseKeys: {
        agGrid: string;
        googleMaps: string;
    };
    controllingCompanyKey: string;
    auth: {
        enabled: boolean;
        rememberDeviceInDays: number,
        passwordChangeLinkTimeToLiveInMinutes: number,
        enableIdleTimer: boolean,
        idleWarningInMinutes: number,
        idleWarningLogoutInMinutes: number,
        idleWarningLogoutUrl: string,
        configuration: {
            domain: string;
            clientId: string;
            apiIdentifier: string;
            clientLogoutUrl: string;
        },
        mockUser: {
            userKey: '11111111-1111-1111-1111-111111111111',
            userId: 'SOME_FAKE_USER_ID',
            individualKey: '11111111-1111-1111-1111-111111111111',
            firstName: 'Fake',
            lastName: 'User',
            email: 'fake@user.com',
            isLockedOut: false,
            policies: ['GtnAdministrator', 'GtnUser'],
            picture: 'https://upload.wikimedia.org/wikipedia/en/7/77/EricCartman.png',
            companyKeys: ['11111111-1111-1111-1111-111111111111'],
            myGTNPortalUserKey: '11111111-1111-1111-1111-111111111111',
            requires2FA: false,
            requiresAUA: false,
            requiresDataAcknowledgment: false,
            isImpersonating: false,
            createdByUser: null,
            lastModifiedByUser: null
        }
    };
    externalWebAPIUrls: {
        googleMaps: string
        googlePlaces: string;
    };
    webAPIUrls: {
        agreements: string;
        accounting: string;
        auth: string;
        contacts: string;
        core: string;
        equity: string;
        documents: string;
        locations: string;
        portal: string;
        questionnaires: string;
        taxability: string;
        taxes: string;
        traction: string;
        whereabouts: string;
        workforce: string;
        vendors: string;
    };
    websocketLogLevel: LogLevel = LogLevel.Information;

    constructor() {
        const appSettings = window['appSettings'];

        if (appSettings) {
            Object.assign(this, JSON.parse(JSON.stringify(appSettings)));
            this.websocketLogLevel = LogLevel[appSettings[ObjectHelper.nameOf<Environment>('websocketLogLevel')]] as any;
        } else {
            Object.assign(this, {
                auth: {
                    configuration: {},
                    mockUser: {}
                },
                webAPIUrls: {},
                externalUrls: {}
            });
        }

        this.version = versions.version;
    }
}

export const environment = () => new Environment();
