import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, first, flatMap, map, mergeMap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ClaimsConstants } from 'app/nexus-shared/constants/claims.constants';
import { NexusRoutesConstants } from 'app/nexus-routing/nexus-routes.constants';
import { AuthenticatedUserModel } from 'app/nexus-shared/models/authenticated-user.model';
import { AuthService } from '@auth0/auth0-angular';
import { AuthenticatedUserService } from 'app/nexus-core/services/authenticated-user.service';
import { LocalStorageHelper, SessionStorageHelper } from 'app/nexus-core/helpers';
import { LocalStorageNameConstants, SessionStorageNameConstants } from 'app/nexus-shared';
import { UrlHelper } from 'app/nexus-core/helpers/url.helper';
import { LoggingService } from 'app/nexus-core/services/logging.service';
import { EnvironmentHelper } from 'app/nexus-core/helpers/environment.helper';

/*
NOTE:  IF YOU ARE LOOKING FOR A HOOK WHEN THE APP IS FULLY LOADED, ITS THE isSyncedCompleted OBSERVABLE FROM AuthenticatedUserSyncService
 */

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    authenticationInitiated$: ReplaySubject<boolean> = new ReplaySubject();
    error$: BehaviorSubject<string> = new BehaviorSubject(null);
    isAuthenticated$: Observable<boolean>;

    constructor(
        //https://www.npmjs.com/package/@auth0/auth0-angular
        private authService: AuthService,
        private loggingService: LoggingService,
        private authenticatedUserService: AuthenticatedUserService) {

        this.appInit().subscribe(_ => {
            this.refreshUserContext(true).subscribe();
        });
    }

    appInit(): Observable<boolean> {
        const auth0Initiated: ReplaySubject<boolean> = new ReplaySubject();

        const processInit = () => {
            if (environment().auth.enabled === false) {
                this.isAuthenticated$ = of(true);
                auth0Initiated.next(true);
                auth0Initiated.complete();
            } else {
                this.authService.isAuthenticated$.pipe(first()).subscribe(isAuthenticated => {
                    this.isAuthenticated$ = this.authService.isAuthenticated$;
                    auth0Initiated.next(isAuthenticated);
                    auth0Initiated.complete();
                });
            }

            return auth0Initiated;
        };

        return this.getTokenSilently().pipe(mergeMap((_ => {
            return processInit();
        })), catchError(_ => {
            return processInit();
        }));
    }

    login(): void {
        let redirectUri = '/';

        if (window.location.href.indexOf('#') > -1) {
            redirectUri = window.location.href.split('#')[1];
        }

        this.authService.loginWithRedirect({
            appState: { target: redirectUri },
            authorizationParams: {
                redirect_uri: window.location.origin,
                gtnDeviceId: LocalStorageHelper.get(LocalStorageNameConstants.deviceId),
                useRefreshTokens: true
            }
        });
    }

    logout(url: string = null): void {
        let redirectUrl;

        if (!EnvironmentHelper.isLocalhost()) {
            redirectUrl = url ?? environment().auth.configuration.clientLogoutUrl;
        } else {
            redirectUrl = url ?? window.location.origin + '/#/' + NexusRoutesConstants.landing.routing.routerModulePath;
        }

        if (!this.hasRememberedDevice()) {
            this.forgetDevice();
        }

        if (environment().auth.enabled === false) {
            window.location.href = redirectUrl;
        } else {
            this.authService.logout({
                logoutParams: {
                    client_id: environment().auth.configuration.clientId,
                    returnTo: redirectUrl
                }
            });
        }
    }

    hasRememberedDevice(): boolean {
        return !!LocalStorageHelper.get(LocalStorageNameConstants.deviceId);
    }

    forgetDevice(): void {
        SessionStorageHelper.delete(SessionStorageNameConstants.deviceId);
        LocalStorageHelper.delete(LocalStorageNameConstants.deviceId);
    }

    getTokenSilently(ignoreCache: boolean = true): Observable<string> {
        if (environment().auth.enabled === false) {
            return of('');
        } else {
            const gtnDeviceId = LocalStorageHelper.get(LocalStorageNameConstants.deviceId) ?? SessionStorageHelper.get(SessionStorageNameConstants.deviceId);
            return this.authService.getAccessTokenSilently({ authorizationParams: { gtnDeviceId: gtnDeviceId }, cacheMode: ignoreCache ? 'off' : 'on' }).pipe(map(t => {
                return <string><unknown>t;
            }), catchError(err => {
                const urlParams = UrlHelper.parseQueryString();

                if (urlParams.error_description) {
                    if (urlParams.error_description === 'user is blocked') {
                        this.loggingService.logError('User is locked out.');
                        console.error('User is locked out');
                        this.error$.next('Your account has been disabled, contact your GTN representative.');
                    } else {
                        this.loggingService.logError(<string>urlParams.error_description);
                        console.error(urlParams.error_description);
                        this.error$.next(urlParams.error_description);
                    }
                } else if ((err?.indexOf && err.indexOf('Login required') > -1) || (err?.error?.indexOf && err.error.indexOf('login_required') > -1)) {
                    this.login();
                } else if (err?.message?.indexOf && err.message.indexOf('Timeout') > -1) {
                    this.loggingService.logError('Authentication provider timed out or is misconfigured.');
                    this.error$.next('Authentication provider timed out or is misconfigured.');
                } else {
                    if (typeof err === 'string') {
                        this.loggingService.logError(err);
                    } else {
                        this.loggingService.logError('An unknown authentication error occurred.');
                    }

                    console.error(err);
                    this.error$.next('An unknown authentication error occurred.');
                }

                return of(null);
            }));
        }
    }

    getAuthenticatedUserModelFromClaims(): Observable<AuthenticatedUserModel> {
        return this.authService.user$.pipe(first(), map(userProfile => {
            if (userProfile) {
                const policies: string = userProfile[ClaimsConstants.policies];

                const currentUser: AuthenticatedUserModel = {
                    userKey: userProfile[ClaimsConstants.userKey],
                    userId: userProfile[ClaimsConstants.userId],
                    individualKey: userProfile[ClaimsConstants.individualKey],
                    firstName: userProfile[ClaimsConstants.firstName],
                    lastName: userProfile[ClaimsConstants.lastName],
                    email: userProfile[ClaimsConstants.email],
                    policies: policies?.split(',') ?? [],
                    requires2FA: userProfile[ClaimsConstants.requiresTwoFactor],
                    requiresAUA: userProfile[ClaimsConstants.requiresAUA],
                    requiresDataAcknowledgment: userProfile[ClaimsConstants.requiresDataAcknowledgment],
                    picture: userProfile[ClaimsConstants.picture],
                    companyKeys: userProfile[ClaimsConstants.companyKeys]?.split(',') ?? [],
                    myGTNPortalUserKey: userProfile[ClaimsConstants.myGTNPortalUserKey],
                    isLockedOut: false,
                    isImpersonating: userProfile[ClaimsConstants.isImpersonating],
                    createdByUser: null,
                    lastModifiedByUser: null
                };

                return currentUser;
            }

            return null;
        }));
    }

    updateUserContext(): Observable<void> {
        return this.getTokenSilently(true).pipe(flatMap(_ => {
            return this.refreshUserContext();
        }));
    }

    private refreshUserContext(isFirstAuthInitiated = false): Observable<void> {
        return this.getAuthenticatedUserModelFromClaims().pipe(map(user => {
            this.authenticatedUserService.setUserContext(user);

            if (isFirstAuthInitiated) {
                this.authenticationInitiated$.next(true);
            }
        }));
    }
}

