import { Component, OnInit } from '@angular/core';
import { BaseDynamicComponentDirective } from 'app/nexus-shared/components/dynamic-form/shared';

@Component({
    selector: 'gtn-dynamic-autocomplete',
    templateUrl: './dynamic-autocomplete.component.html',
    styleUrls: ['./dynamic-autocomplete.component.scss']
})
export class DynamicAutocompleteComponent extends BaseDynamicComponentDirective implements OnInit {
    stringOptions: string[];

    ngOnInit() {
        if (this.config?.customFieldOptions) {
            this.stringOptions = this.config.customFieldOptions.map(x => x.value);
        }
    }
}
