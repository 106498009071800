import { CommonBaseAuditModel } from '../../contacts/models/common-base-audit.model';
import { IAgreementReferentialValueModelInterface } from '../interfaces/iagreement-referential-value-model.interface';

export class AgreementReferentialValueModel extends CommonBaseAuditModel implements IAgreementReferentialValueModelInterface {
    agreementReferentialValueKey: string;
    agreementKey: string;
    id: string;
    value: string;

    constructor(model: AgreementReferentialValueModel = null) {
        super(model);

        this.agreementReferentialValueKey = model?.agreementReferentialValueKey ?? null;
        this.agreementKey = model?.agreementKey ?? null;
        this.id = model?.id ?? null;
        this.value = model?.value ?? null;
    }
}
