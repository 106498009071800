import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BaseEnterpriseGridComponent } from 'app/nexus-shared/components/base-component/base-enterprise-grid.component';
import { DocumentModel } from 'app/nexus-shared/domain/documents/models/document.model';
import { AgGridHelper, DateHelper, SpinnerService } from 'app/nexus-core';
import { DocumentService } from 'app/nexus-core/services/domain/documents/document.service';
import { CellClickedEvent, ColDef, GridOptions, RowClickedEvent } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { DocumentSearchModel } from 'app/nexus-shared/domain/documents/models/document-search.model';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { ClientDocumentModel } from 'app/nexus-shared/domain/documents/models/client-document.model';
import { DocumentsHelper } from 'app/nexus-core/helpers/documents.helper';
import { FileModel } from 'app/nexus-shared/domain/documents/models/file.model';
import { ClientDocumentTypesEnum } from 'app/nexus-shared/domain/documents/enums/client-document-types.enum';
import { FileViewModel } from 'app/nexus-shared/components/controls/shared/models/file-view.model';
import { finalize, tap } from 'rxjs/operators';
import { StringConstants } from 'app/nexus-shared/constants';
import { ToastService } from 'app/nexus-core/services/toast.service';
import { DocumentStatusesEnum } from 'app/nexus-shared/domain/documents/enums/document-statuses.enum';
import { IndividualSettingService } from 'app/nexus-core/services/domain/core/individual-setting.service';
import { AgGridColDefExtras } from 'app/nexus-shared/models/ag-grid-col-def-extras.model';
import { LocationModel } from 'app/nexus-shared/domain/locations';
import { LocationService } from 'app/nexus-core/services/location.service';
import { DocumentAdvancedSearchModel } from 'app/nexus-shared/domain/documents/models/document-advanced-search.model';
import { AgGridColDefHelper } from 'app/nexus-core/helpers/ag-grid-col-def.helper';
import { CommonBaseAuditUserModel } from 'app/nexus-shared/domain/contacts/models/common-base-audit-user.model';

@Component({
    selector: 'gtn-document-search-grid',
    templateUrl: './document-search-grid.component.html',
    styleUrls: ['./document-search-grid.component.scss']
})
export class DocumentSearchGridComponent extends BaseEnterpriseGridComponent<DocumentModel> implements OnInit {
    @Output() navigateToFolder: EventEmitter<DocumentModel> = new EventEmitter();
    @Output() previewFile: EventEmitter<DocumentModel> = new EventEmitter();

    countryList: LocationModel[];
    currentSearchModelString: string;
    gridName: string = 'document_search_grid';
    gridVersion: string = '1.0.1';
    isFiltersVisible: boolean = true;
    searchModel: DocumentSearchModel;
    showPreview: boolean = false;
    fileView: FileViewModel;
    fileViewDocument: DocumentModel;

    constructor(
        protected individualSettingService: IndividualSettingService,
        private documentService: DocumentService,
        private locationService: LocationService,
        private toastService: ToastService) {
        super(individualSettingService);
    }

    ngOnInit(): void {
        this.countryList = this.locationService.countryList;
        this.searchModel = new DocumentSearchModel();
        this.searchModel.advancedSearch = new DocumentAdvancedSearchModel();
        this.searchModel.includeIndividual = true;
        this.searchModel.includeCompany = true;
        this.searchModel.startDate = DateHelper.subtractDate(new Date(), 1, 'M');
        this.searchModel.endDate = new Date();
        const that = this;
        const gridOptions: GridOptions = {
            suppressContextMenu: false,
            onRowClicked(event: RowClickedEvent) {
                return;
            },
            getContextMenuItems: (params) => {
                if (params?.node?.data) {
                    return [
                        {
                            name: 'Go to document/folder',
                            icon: `<i class='fas fa-folder-search'></i>`,
                            action: () => {
                                that.navigateToFolder.emit(params.node.data);
                            }
                        },
                        {
                            name: 'View document details',
                            icon: `<i class='fas fa-binoculars'></i>`,
                            action: () => {
                                this.rowClick.emit(params.node.data);
                            }
                        },
                        {
                            name: 'Preview',
                            icon: `<i class='fas fa-eye'></i>`,
                            action: () => {
                                this.fileViewDocument = params.node.data;
                                SpinnerService.start();
                                this.documentService.preview(params.node.data.documentKey).pipe(finalize(() => SpinnerService.stop())).subscribe(res => {
                                    this.fileView = new FileViewModel();
                                    this.fileView.blobSrc = res;
                                    this.fileView.isBlobSrc = true;
                                    this.fileView.name = params.node.data.name;
                                    this.fileView.type = DocumentsHelper.getPreviewFileType(params.node.data.file.extension);
                                    this.fileView.iconClass = DocumentsHelper.getFileTypeIcon(params.node.data.file.extension);
                                    this.showPreview = true;
                                }, _ => {
                                    this.toastService.showMessageToast(StringConstants.messages.serverError);
                                });
                            },
                            disabled: !DocumentsHelper.isPreviewType(params?.node?.data?.file?.extension)
                        }
                    ];
                }

            }
        };
        super.ngOnInit(gridOptions);
    }

    onCellClicked(cellClick: CellClickedEvent): void {
        if (cellClick.colDef.field === AgGridColDefHelper.iconCellField) {
            this.navigateToFolder.emit(cellClick.data);
        } else {
            this.rowClick.emit(cellClick.data);
        }
    }

    onFilterVisibilityChanged(isFiltersVisible: boolean) {
        this.isFiltersVisible = isFiltersVisible;
    }

    onPreviewNavigateClicked(): void {
        this.navigateToFolder.emit(this.fileViewDocument);
    }

    onSearchModelChanged(searchModel: DocumentSearchModel): void {
        this.searchModel.includeCompany = true;
        this.searchModel.includeIndividual = true;
        if (JSON.stringify(searchModel) !== this.currentSearchModelString) {
            this.refreshGridData();
        }
    }

    setDefaultSortModel() {
        this.defaultSortModel = [
            { colId: ObjectHelper.nameOfSubProperty<DocumentModel, ClientDocumentModel>('clientDocument', 'companyName'), sort: 'asc', sortIndex: 0 },
            { colId: ObjectHelper.nameOfSubProperty<DocumentModel, ClientDocumentModel>('clientDocument', 'individualLastName'), sort: 'asc', sortIndex: 1 },
            { colId: ObjectHelper.nameOf<DocumentModel>('name'), sort: 'asc', sortIndex: 2 },
        ];
    }


    protected setColumnDefinitions(): ColDef[] {
        return [
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOf<DocumentModel>('name'), 'File Name', null, <AgGridColDefExtras>{
                tooltip: true,
                colDef: {
                    cellRenderer: params => {
                        if (params?.data?.file) {
                            const fileTypeClass = DocumentsHelper.getFileTypeIcon(params?.data?.file?.extension);
                            return `<span><i class="${fileTypeClass}"></i></span> ${params.value}`;
                        }
                        return null;
                    },
                    filter: AgGridHelper.textColumnFilter
                }
            }),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOfSubProperty<DocumentModel, FileModel>('file', 'extension'), 'File Type', null, <AgGridColDefHelper>{
                colDef: {
                    valueGetter: params => {
                        if (params?.data?.file) {
                            return DocumentsHelper.getFileTypeName(params?.data?.file?.extension);
                        }
                        return null;
                    }
                }
            }),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOfSubProperty<DocumentModel, ClientDocumentModel>('clientDocument', 'companyName'), 'Company', null, <AgGridColDefHelper>{
                colDef: {
                    valueGetter: params => {
                        if (params?.data?.clientDocument?.companyKey) {
                            return params.data?.clientDocument.companyName;
                        } else if (params?.data?.clientDocument?.individualKey) {
                            return 'Independents';
                        }
                        return null;
                    },
                }
            }),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOfSubProperty<DocumentModel, ClientDocumentModel>('clientDocument', 'individualKey'), 'Individual', null, <AgGridColDefHelper>{
                colDef: {
                    valueGetter: params => {
                        if (params?.data?.clientDocument) {
                            return params.data.clientDocument?.individualKey ? `${params.data.clientDocument?.individualLastName}, ${params.data.clientDocument?.individualFirstName}` : null;
                        }
                    },
                }
            }),
            AgGridColDefHelper.getDateTimeColDef(ObjectHelper.nameOfSubProperty<DocumentModel, CommonBaseAuditUserModel>('lastModifiedByUser', 'changeDateTime'), 'Last Modified'),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOfSubProperty<DocumentModel, CommonBaseAuditUserModel>('lastModifiedByUser', 'fullName'), 'Last Modified By'),
            AgGridColDefHelper.getNumberColDef(ObjectHelper.nameOfSubProperty<DocumentModel, ClientDocumentModel>('clientDocument', 'taxYear'),
                'Tax year', <AgGridColDefExtras>{
                    hide: true,
                    colDef: {
                        cellRenderer: params => {
                            return DocumentsHelper.getFiscalTaxYear(params?.data?.document?.clientDocument);
                        },
                    }
                }),
            AgGridColDefHelper.getEnumColumnDef(ObjectHelper.nameOfSubProperty<DocumentModel, ClientDocumentModel>('clientDocument', 'type'), 'Document Type', ClientDocumentTypesEnum),
            AgGridColDefHelper.getEnumColumnDef(ObjectHelper.nameOf<DocumentModel>('status'), 'Document Status', DocumentStatusesEnum),
            AgGridColDefHelper.getCountryColDef(ObjectHelper.nameOfSubProperty<DocumentModel, ClientDocumentModel>('clientDocument', 'countryId'), 'Country', this.countryList),
            AgGridColDefHelper.getActionColDef('fas fa-folder-search')
        ];
    }

    protected setRowData(): Observable<DocumentModel[]> {
        this.currentSearchModelString = JSON.stringify(this.searchModel);
        return this.documentService.search(this.searchModel).pipe(tap(res => {
            if (res?.length >= 10000) {
                this.toastService.showMessageToast('Result set exceed 2,000 records, try narrowing your search results by using the filters.');
            }
        }));
    }

}
