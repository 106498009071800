<div gtnResize [resizeHandler]="resizeGrid" #gridContainer class="grid-container"
    [ngClass]="{ 'clickable-row': isRowClickable, 'remove-row-shading': !hasRowStriping, 'remove-border': !hasBorder, 'remove-scroll': !isAllowClick }">
    <div *ngIf="rowCount && showRowCount" [ngClass]="{'loading': isLoading}"
        class="row-count">{{ rowCount }} rows
    </div>
    <gtn-filter-panel
        *ngIf="enableFilterPanel"
        [showClose]="false"
        [showFilterButton]="!!gtnFilters?.length"
        [(isFilterPanelCollapsed)]="isFilterPanelCollapsed"
        [appliedFilters]="gtnAppliedFilters"
        (applyFilters)="onFiltersApplied()"
        (clearFilters)="onFiltersCleared()" (isFilterPanelCollapsedChange)="onIsFilterPanelCollapsedChange($event)"
        (removeFilter)="onFilterRemoved($event)">
        <gtn-grid-filter-controls [(gtnFilters)]="gtnFilters">
        </gtn-grid-filter-controls>
        <gtn-filter-panel-button-content>
            <ng-container *ngIf="gridStateKey && isGridStateChanged">
                <button (click)="onResetGridStateClicked()" class="mr-2" color="default"
                    mat-mini-fab matTooltip="Reset grid">
                    <mat-icon>restart_alt</mat-icon>
                </button>
            </ng-container>
            <ng-container *ngIf="showCreateButton">
                <button (click)="onAddButtonClicked()" class="mr-2" color="primary"
                    mat-mini-fab matTooltip="Add {{addButtonTitle}}">
                    <mat-icon>add</mat-icon>
                </button>
            </ng-container>
        </gtn-filter-panel-button-content>
    </gtn-filter-panel>
    <ag-grid-angular [gtnHeight]="actualGridHeight ? actualGridHeight : auto" [minHeight]="minGridHeight"
        [bottomOffset]="heightOffset"
        class="ag-theme-balham" [ngClass]="{'customized-theme': isCustomizedTheme}" [gridOptions]="gridOptions">
    </ag-grid-angular>
</div>
