import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { CellClickedEvent, ColDef, GridOptions, RowClickedEvent } from 'ag-grid-community';
import { AgGridColDefExtras } from 'app/nexus-shared/models/ag-grid-col-def-extras.model';
import { AgGridColDefHelper } from 'app/nexus-core/helpers/ag-grid-col-def.helper';
import { BaseEnterpriseGridComponent } from 'app/nexus-shared/components/base-component/base-enterprise-grid.component';
import { Observable, of } from 'rxjs';
import { IndividualModel } from 'app/nexus-shared/domain/contacts/models';
import { IndividualSettingService } from 'app/nexus-core/services/domain/core/individual-setting.service';
import { AgreementSearchModel } from '../../../models/agreement-search.model';
import { AgreementStatusEnum } from '../../../enums/agreement-status.enum';
import { AgreementTypeModel } from '../../../models/agreement-type.model';
import { AgreementTypeService } from 'app/nexus-core/services/domain/agreements/agreement-type.service';
import { AgreementTypeSearchModel } from '../../../models/agreement-type-search.model';
import { AuthenticatedUserService } from 'app/nexus-core/services/authenticated-user.service';
import { AuthorizationConstants } from 'app/nexus-shared/constants/authorization.constants';
import { IAgreementModelInterface } from '../../../interfaces/iagreement-model.interface';
import { IAgreementsCompanyModelInterface } from '../../../interfaces/iagreements-company-model.interface';
import { IAgreementSearchModelInterface } from '../../../interfaces/iagreement-search-model.interface';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { SimpleChangesTyped } from 'app/nexus-shared/models/simple-changes-typed.type';

@Component({
    selector: 'gtn-agreements-grid',
    templateUrl: './agreements-grid.component.html'
})
export class AgreementsGridComponent extends BaseEnterpriseGridComponent<IAgreementModelInterface> implements OnInit, OnChanges {
    @Input() canAdd: boolean = false;
    @Input() enableAdvancedSearch: boolean = false;
    @Input() advancedSearch: IAgreementSearchModelInterface;

    @Output() advancedSearchChange: EventEmitter<IAgreementSearchModelInterface> = new EventEmitter();
    @Output() advancedSearchClick: EventEmitter<IAgreementSearchModelInterface> = new EventEmitter();
    @Output() uploadSignedAgreementClick: EventEmitter<IAgreementModelInterface> = new EventEmitter<IAgreementModelInterface>();

    agreementTypes: AgreementTypeModel[];
    showSearchPanel: boolean = false;

    protected readonly uploadIcon = 'fas fa-file-upload';

    constructor(
        protected individualSettingService: IndividualSettingService,
        protected agreementTypeService: AgreementTypeService,
        protected authenticatedUserService: AuthenticatedUserService) {
        super(individualSettingService);
    }

    ngOnInit(gridOptions?: GridOptions): void {
        this.advancedSearch = new AgreementSearchModel();

        if (this.enableAdvancedSearch) {
            this.agreementTypeService.search(new AgreementTypeSearchModel()).subscribe(agreementTypes => {
                this.agreementTypes = agreementTypes;
            });
        }

        gridOptions ??= {
            onRowClicked(event: RowClickedEvent) {
                return;
            }
        };

        super.ngOnInit(gridOptions);
    }

    ngOnChanges(changes: SimpleChangesTyped<this>): void {
        super.ngOnChanges(changes);

        if (changes.advancedSearch && this.advancedSearch) {
            // this.mapSearchModel(this.advancedSearch);
        }
    }

    onCellClicked(cellClick: CellClickedEvent): void {
        if (cellClick.colDef.field === AgGridColDefHelper.iconCellField) {
            this.processIconClickEvent(cellClick);
        } else {
            this.processRowClickEvent(cellClick);
        }
    }

    protected getAgreementTypeNameColDef() {
        return AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOfSubProperty<IAgreementModelInterface, AgreementTypeModel>(ObjectHelper.nameOf<IAgreementModelInterface>('agreementType'), ObjectHelper.nameOf<AgreementTypeModel>('name')), 'Agreement Type');
    }

    protected getCompanyColDef() {
        return AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOfSubProperty<IAgreementModelInterface, IAgreementsCompanyModelInterface>(ObjectHelper.nameOf<IAgreementModelInterface>('company'), ObjectHelper.nameOf<IAgreementsCompanyModelInterface>('name')), 'Company');
    }

    protected getIndividualColDef(): ColDef {
        return AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOfSubProperty<IAgreementModelInterface, IndividualModel>(ObjectHelper.nameOf<IAgreementModelInterface>('individual'), ObjectHelper.nameOf<IndividualModel>('fullName')), 'Individual');
    }

    protected getExpirationDateColDef(): ColDef {
        return AgGridColDefHelper.getDateColDef(ObjectHelper.nameOf<IAgreementModelInterface>('expirationDateTime'), 'Expiration Date');
    }

    protected getSignedDateColDef() {
        return AgGridColDefHelper.getDateColDef(ObjectHelper.nameOf<IAgreementModelInterface>('signedDateTime'), 'Signed Date');
    }

    protected getStatusColDef() {
        return AgGridColDefHelper.getEnumColumnDef(ObjectHelper.nameOf<IAgreementModelInterface>('status'), 'Status', AgreementStatusEnum);
    }

    protected getUploadIconColDef(): ColDef {
        return AgGridColDefHelper.getActionColDef(this.uploadIcon, AgGridColDefHelper.iconCellField, false, <AgGridColDefExtras>{
            colDef: {
                cellStyle: params => {
                    if (!this.shouldAllowUpload(params?.data?.status)) {
                        return { 'display': 'none' };
                    }
                },
                tooltipValueGetter: params => {
                    return 'Upload signed agreement';
                }
            }
        });
    }

    protected processIconClickEvent(cellClick) {
        if (cellClick?.event?.target?.className?.includes(this.uploadIcon)) {
            this.uploadSignedAgreementClick.emit(cellClick.data);
        }
    }

    protected processRowClickEvent(cellClick) {
        this.rowClick.emit(cellClick.data);
    }

    protected setColumnDefinitions(colDefs?: ColDef<any, any>[]): ColDef<any, any>[] {
        const columnDefinitions = colDefs ?? [
            this.getCompanyColDef(),
            this.getIndividualColDef(),
            this.getAgreementTypeNameColDef(),
            this.getStatusColDef(),
            this.getExpirationDateColDef(),
            this.getSignedDateColDef(),
            this.getUploadIconColDef()
        ];

        return columnDefinitions.filter(x => x);
    }

    protected setRowData(): Observable<any[]> {
        return of(this.data);
    }

    onSearchChanged(agreementSearch: AgreementSearchModel) {
        this.advancedSearchChange.emit(agreementSearch);
    }

    onSearchClicked(agreementSearch: AgreementSearchModel) {
        this.advancedSearchClick.emit(agreementSearch);
    }

    onShowAdvancedSearchClicked() {
        this.showSearchPanel = !this.showSearchPanel;
    }

    protected shouldAllowUpload(status: AgreementStatusEnum): boolean {
        switch (status) {
            case AgreementStatusEnum.Draft:
            case AgreementStatusEnum.PendingSignature:
                return this.authenticatedUserService.hasAuthorization(AuthorizationConstants.agreementsAdmin);;
            default:
                return false;
        }
    }
}
