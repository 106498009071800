import { Component, Input, OnInit } from '@angular/core';
import { RoleService } from 'app/nexus-core/services/domain/users/role.service';
import { RoleDefinitionModel } from 'app/nexus-shared/domain/users/models/role-definition.model';
import { UserService } from 'app/nexus-core/services/domain/users/user.service';
import { RoleTypesEnum } from 'app/nexus-shared/domain/users/enums/role-types.enum';
import { UserRoleService } from 'app/nexus-core/services/domain/users/user-role.service';
import { AuthenticatedUserService } from 'app/nexus-core/services/authenticated-user.service';
import { BaseFormComponent } from 'app/nexus-shared/components/base-component/base-form.component';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { BaseFormModel } from 'app/nexus-shared/models/base-form.model';
import { AuthorizationConstants } from 'app/nexus-shared/constants/authorization.constants';

@Component({
    selector: 'gtn-user-role-subform',
    templateUrl: './user-role-subform.component.html',
    styleUrls: ['./user-role-subform.component.scss']
})
export class UserRoleSubformComponent extends BaseFormComponent<number[]> implements OnInit {

    @Input() formArray: UntypedFormArray;
    @Input() isGtnEmail: boolean;

    isLoaded: boolean = false;
    roleDefinitions: RoleDefinitionViewModel[];
    gtnEmployeeRoleTypeId = RoleTypesEnum.GtnUser;

    constructor(private roleService: RoleService,
                private userRoleService: UserRoleService,
                private userService: UserService,
                private authenticatedUserService: AuthenticatedUserService) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
    }

    protected initUIControls() {
        const controlValues = this.formArray.controls.map(x => x.value);
        this.roleService.list().subscribe((res) => {
            this.roleDefinitions = this.convertToRoleDefinitionsViewModel(res, controlValues);
        });
    }

    onSelectedRoleChanged(roleDefinition: RoleDefinitionViewModel): void {
        if (!roleDefinition.initialChange) {
            const controlValues = this.formArray.controls.map(x => x.value);
            this.formArray.clear();
            const i = controlValues.findIndex(x => x.roleDefinitionId === roleDefinition.roleDefinitionId);
            if (i !== -1) {
                controlValues.splice(i, 1);
                roleDefinition.selected = false;
            } else {
                controlValues.push(roleDefinition);
                roleDefinition.selected = true;
            }
            const formConfiguration = new BaseFormModel();
            controlValues.forEach(x => {
                const formGroup = new UntypedFormGroup({});
                this.buildFormFromTemplate(new RoleDefinitionModel(x), formGroup, formConfiguration);
                this.formArray.push(formGroup);
            });
            this.formArray.markAsDirty();
            this.formArray.updateValueAndValidity();
        }
        roleDefinition.initialChange = false;
    }

    private sortRoleDefinitionsViewModel(roleDefinitionModels: RoleDefinitionViewModel[]): RoleDefinitionViewModel[] {
        return roleDefinitionModels.sort((a, b) => {
            if (a.name.startsWith('Gtn')) {
                return -1;
            }

            if (a.name < b.name && !b.name.startsWith('Gtn')) {
                return -1;
            } else if (a.name > b.name) {
                return 1;
            }
            return 0;
        });
    }

    private convertToRoleDefinitionsViewModel(roleDefinitionModels: RoleDefinitionModel[], userRoleDefinitions: RoleDefinitionModel[]): RoleDefinitionViewModel[] {
        const isGtnUser = !!userRoleDefinitions.find(t => t.roleType === RoleTypesEnum.GtnUser) || (this.authenticatedUserService.hasAuthorization(AuthorizationConstants.authUserAdmin) && this.isGtnEmail);

        const filteredRoleDefinitions = roleDefinitionModels.filter(roleDefinition => isGtnUser === roleDefinition.isGtnRole);

        const roleLength = filteredRoleDefinitions.length;
        const viewModels: RoleDefinitionViewModel[] = [];

        for (let i = 0; i < roleLength; i++) {
            const roleDefinition = filteredRoleDefinitions[i];
            const foundUserRoleDefinition = userRoleDefinitions.find(t => t.roleDefinitionId === roleDefinition.roleDefinitionId);
            const roleDefinitionViewModel = new RoleDefinitionViewModel(roleDefinition);
            roleDefinitionViewModel.selected = !!foundUserRoleDefinition;
            viewModels.push(roleDefinitionViewModel);
        }

        return this.sortRoleDefinitionsViewModel(viewModels);
    }
}

export class RoleDefinitionViewModel extends RoleDefinitionModel {
    selected: boolean;
    initialChange: boolean;

    constructor(model: RoleDefinitionModel = null) {
        super(model);
        this.selected = false;
        this.initialChange = true;
    }
}
