import { AfterViewInit, Component, Input, OnChanges, OnDestroy, ViewChild } from '@angular/core';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SearchResultModel } from 'app/nexus-shared/components/controls/shared/models/search-result.model';
import { UntypedFormControl } from '@angular/forms';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { SearchModel } from 'app/nexus-shared/components/controls/shared/models/search.model';
import { ControlAppearanceType } from '../..';
import { SimpleChangesTyped } from 'app/nexus-shared/models/simple-changes-typed.type';
import { MatFormFieldAppearance } from '@angular/material/form-field';

@Component({
    selector: 'gtn-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent<T, S> extends BaseComponent implements AfterViewInit, OnDestroy, OnChanges {

    @Input() value: S = null;
    @Input() requiredCharacterCount: number = 3;
    @Input() placeholderText: string = 'Search...';
    @Input() searchModel: SearchModel;
    @Input() label: string = null;
    @Input() useDefaultStyling = false;
    @Input() showSelectedValue = false;
    @Input() readonly: boolean = false;
    @Input() displayWith: Function = null;
    @Input() showClearButton = false;
    @Input() clearTextOnFocus = true;
    @Input() showAddLink = false;
    @Input() showSecondaryAddLink = false;
    @Input() primaryAddLabel = 'Create a new record';
    @Input() secondaryAddLabel = 'Create a new record';
    @Input() appearance: MatFormFieldAppearance = ControlAppearanceType.fill;
    @Input() defaultSearchResults: SearchResultModel<T, S>[];

    @ViewChild('searchAnchor') search: any;
    @ViewChild('searchResultList') searchResultList: any;

    searchText = '';
    showSearchResults = false;
    searchResultSubject: Subject<SearchResultModel<T, S>> = new Subject();
    searchResults: SearchResultModel<T, S>[];
    formControl = new UntypedFormControl();
    isLoading: boolean = false;

    //Function to show the chosen display value, rather than id value
    displayFn = ((event: SearchResultModel<T, S>) => {
        const displayWithVal = this.displayWith
            ? this.displayWith(event)
            : null;

        return displayWithVal
            ? displayWithVal
            : this.showSelectedValue ? event?.name : '';
    }).bind(this);

    private searchStateChange: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private selectedOption: SearchResultModel<T, S>;

    constructor() {
        super();
        this.subscriptions.add(this.searchResultSubject.pipe(debounceTime(10)).subscribe(((val: SearchResultModel<T, S>) => {
            this.searchModel.onResultClicked.next(val);
        }).bind(this)));
    }

    ngOnChanges(changes: SimpleChangesTyped<this>) {
        if (changes.value && changes.value.currentValue !== changes.value.previousValue) {
            this.formControl.setValue(this.value);

            if (!changes.value.firstChange &&
                (changes.value.currentValue === null || typeof changes.value.currentValue === 'undefined') &&
                changes.value.previousValue) {
                this.onClearClicked();
            }
        }

        if (changes.readonly) {
            if (this.readonly) {
                this.formControl.disable();
            } else {
                this.formControl.enable();
            }
        }
    }

    ngAfterViewInit() {
        this.subscriptions.add(
            this.searchStateChange
                .pipe(
                    debounceTime(300)
                )
                .subscribe(searchText => {
                    if (searchText) {
                        this.isLoading = true;
                        this.searchModel.onTextChanged.next(searchText);
                    } else {
                        return of([]);
                    }
                })
        );
        this.subscriptions.add(
            this.searchModel.onResultsReceived.subscribe(
                results => {
                    this.isLoading = false;
                    this.searchResults = results;
                }
            )
        );
        this.subscriptions.add(this.searchModel.onClearSearchText.subscribe(_ => {
            this.onClearClicked();
        }));
        this.subscriptions.add(
            this.searchModel.onErrorReceived.subscribe(
                _ => {
                    this.isLoading = false;
                    this.searchResults = [];
                }
            )
        );
    }

    optionSelected(event: any) {
        if (event.option.value) {
            const result = JSON.parse(JSON.stringify(event.option.value));
            this.search.nativeElement.value = this.showSelectedValue ? result?.name : '';
            this.searchText = '';
            this.searchResults = [];
            this.selectedOption = result;
            this.searchResultSubject.next(this.selectedOption);
        }
    }

    onFocus(searchText: string): void {
        if (!this.showSearchResults && this.clearTextOnFocus) {
            this.searchTextValueChange(searchText);
        }
    }

    onClearClicked() {
        this.showSearchResults = false;
        this.search.nativeElement.value = null;
        this.searchText = '';
        this.searchResults = [];
        this.selectedOption = null;
        this.searchResultSubject.next(this.selectedOption);
    }

    onAddClicked() {
        this.searchModel.onCreateNewClicked.next();
    }

    onSecondaryAddClicked() {
        this.searchModel.onCreateNewClicked.next(true);
    }

    searchTextValueChange(searchText: string): void {
        this.searchText = searchText;
        if (this.selectedOption && this.selectedOption.name !== this.searchText || (this.value && !this.searchText)) {
            this.selectedOption = null;
            this.searchResultSubject.next({
                resultObject: null
            } as any);
        }

        if (searchText.length >= this.requiredCharacterCount) {
            this.showSearchResults = true;
            this.searchStateChange.next(searchText);
        } else if (this.searchResults?.length > 0 && !this.defaultSearchResults?.length) {
            this.showSearchResults = !this.showSearchResults;
            this.searchResults = [];
        }
    }
}
