import { animate, AnimationMetadata, style, transition, trigger } from '@angular/animations';

export class FilterPanel {
    /**
     * Use '@filterPanel' in markup.
     */
    static animations = FilterPanel.getAnimation();

    private static getAnimation(): AnimationMetadata {
        return trigger('filterPanel', [
            transition(':enter', [
                style({
                    overflow: 'hidden',
                    height: '0px'
                }),
                animate('300ms', style({
                    overflow: 'hidden',
                    height: '*'
                }))
            ]),
            transition(':leave', [
                style({
                    overflow: 'hidden',
                    height: '*'
                }),
                animate('300ms', style({
                    overflow: 'hidden',
                    height: '0px'
                }))
            ])
        ]);
    }
}
