import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { Subject } from 'rxjs';

@Component({
    selector: 'gtn-hero-number',
    templateUrl: './hero-number.component.html',
    styleUrls: ['./hero-number.component.scss']
})
export class HeroNumberComponent extends BaseComponent implements OnChanges, OnInit {
    @Input() durationMs: number = 1000;
    @Input() heroLabel: string;
    @Input() number: number;
    @Input() isLoading = false;
    @Input() isButton = false;
    @Input() isActive = false;
    @Input() size: 'big' | 'small' = 'big';
    @Input() isCounterEnabled: boolean = false;

    currentValue: number | string;

    private loadingSubject: Subject<void> = new Subject();
    private startValue = 0;

    ngOnInit(): void {
        this.subscriptions.add(this.loadingSubject.subscribe(() => {
            if (this.number && this.number > 0 && this.isCounterEnabled) {
                this.runCounter();
            }
        }));
    }

    ngOnChanges() {
        if (this.isCounterEnabled) {
            this.loadingSubject.next();
        } else {
            this.currentValue = this.number;
        }
    }

    private runCounter(): void {
        this.startValue = 0;

        const endValue = +this.number,
            duration = this.durationMs;
        let currentValue = 0;


        const step = Math.abs(duration / endValue);

        const timer = setInterval(() => {
            if (currentValue >= endValue) {
                clearInterval(timer);
            } else {
                currentValue++;
                this.currentValue = currentValue;
            }
        }, step);
    }

}
