<div gtnChildResize>
    <div>
        <div class="float-left">
            <span class="filter-chips" *ngIf="isFilterPanelCollapsed">
                <mat-chip-listbox aria-label="Active Filters">
                    <mat-chip-option class="pointer" *ngFor="let appliedFilter of appliedFilters"
                        (click)="onFilterClicked(appliedFilter)">
                        {{ appliedFilter.value }}
                        <i class="material-icons" matTooltip="Remove" matChipRemove
                            (click)="onRemoveFilterClicked(appliedFilter)">cancel</i>
                    </mat-chip-option>
                </mat-chip-listbox>
            </span>
        </div>
        <div class="float-right">
            <button *ngIf="showFilterButton" class="mr-2" color="default" mat-mini-fab
                matTooltip="Toggle filter panel" (click)="onToggleFilterPanel()">
                <mat-icon>filter_alt</mat-icon>
            </button>
            <ng-content select="gtn-filter-panel-button-content"></ng-content>
        </div>
        <div class="float-clear"></div>
    </div>
    <div class="mt-2" [@gtnFilterPanel]="!isFilterPanelCollapsed">
        <mat-card appearance="outlined">
            <div *ngIf="showClose" class="mb-2">
                <i class="fa fa-times pointer close-icon" matTooltip="Close" (click)="onToggleFilterPanel()"></i>
            </div>
            <div>
                <ng-content></ng-content>
            </div>
            <div class="button-group">
                <button mat-flat-button class="mat-accent apply-button" (click)="onApplyFiltersClicked()">
                    Apply
                </button>
                <button mat-flat-button class="mat-button-default clear-button" (click)="onClearFiltersClicked()">
                    Clear
                </button>
            </div>
        </mat-card>
    </div>
</div>
