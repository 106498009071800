export enum AgreementSubtypeEnum {
    None = 0,

    // IEL (1-100)
    Compliance = 1,
    Consulting = 2,
    ComplianceAndConsulting = 3,

    // CF (101-200)
    ClientCompany = 105,
    CooperatingTaxOffice = 106,
    ThirdPartyVendor = 107
}
