import { AfterViewInit, Directive, ElementRef, OnDestroy } from '@angular/core';
import { ResizeService } from 'app/nexus-core';
import { Subject } from 'rxjs';
import { BaseDirective } from '../components/base-directive/base.directive';

@Directive({
    selector: '[gtnChildResize]'
})
export class ChildResizeDirective extends BaseDirective implements AfterViewInit, OnDestroy {

    // mutationObserver handles the case for filter panel collapsing
    private mutationObserver: MutationObserver = new MutationObserver(() => this.resizeSubject.next());
    private resizeSubject: Subject<void> = new Subject();

    constructor(
        private _elementRef: ElementRef,
        private resizeService: ResizeService
    ) {
        super();
    }

    ngAfterViewInit() {
        const config = { subtree: true, childList: true };
        this.mutationObserver.observe(this._elementRef.nativeElement as HTMLElement, config);
        this.subscriptions.add(this.resizeSubject.subscribe(_ => this.handleResize()));
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.mutationObserver.disconnect();
    }

    protected handleResize() {
        this.resizeService.resize.next();
    }
}
