<div *ngIf="mainHeaderText || subHeaderText || showCloseButton" class="modal-header-container">
    <button *ngIf="showCloseButton" aria-label="Close" class="close" type="button">
        <i (click)="closeClick()" class="fa fa-times"></i>
    </button>
    <div *ngIf="mainHeaderText && !subHeaderText">
        <div class="single-header-text">
            {{mainHeaderText}}
        </div>
    </div>
    <div *ngIf="subHeaderText">
        <div *ngIf="mainHeaderText" class="main-header-text">
            {{mainHeaderText}}
        </div>
        <div class="sub-header-text">
            {{subHeaderText}}
        </div>
    </div>
    <div class="close-button">
    </div>
</div>