<div class="dashboard-frame">
    <div class="dashboard-frame-header">
        <span class="title">{{ headerTitle }}</span>
        <i *ngIf="showLaunchButton" class="fas fa-external-link-square-alt" [matTooltip]="launchButtonTitle"
            [attr.data-cy]="TestSelectors.LaunchButton" (click)="onExternalLinkClicked()"></i>
    </div>
    <div class="dashboard-frame-content">
        <ng-content></ng-content>
    </div>
</div>
