import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { BaseHttpService } from 'app/nexus-core/services/index';
import { UserSettingsModel } from 'app/nexus-shared/domain/users/models/user-settings.model';
import { ApplicationsEnum } from 'app/nexus-shared/enums/applications.enum';

@Injectable({
    providedIn: 'root'
})
export class UserSettingsService {
    constructor(private baseHttpService: BaseHttpService) {
    }

    get(key: string, application: ApplicationsEnum, userKey: string = null): Observable<UserSettingsModel> {
        let url = this.apiUrl();

        if (userKey !== null) {
            url += `/user/${userKey}`;
        }

        url += `/key/${encodeURI(key)}/application/${application}`;

        return this.baseHttpService.get(url);
    }

    list(application: ApplicationsEnum = null, userKey: string = null): Observable<UserSettingsModel[]> {
        const queryParams = {};
        if (userKey) {
            queryParams['userKey'] = userKey;
        }

        if (application) {
            queryParams['application'] = application;
        }

        return this.baseHttpService.get(this.apiUrl(), queryParams);
    }

    upsert(model: UserSettingsModel): Observable<number> {
        return this.baseHttpService.post(this.apiUrl(), model);
    }

    deleteBykeys(key: string, application: ApplicationsEnum, userKey: string = null): Observable<boolean> {
        let url = this.apiUrl();

        if (userKey) {
            url += `/user/${userKey}`;
        }

        url += `/key/${encodeURI(key)}`;
        url += `/application/${application}`;

        return this.baseHttpService.delete(url);
    }

    private apiUrl = () => environment().webAPIUrls.auth + 'userSettings';
}
