import { publishReplay, refCount } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TaxJurisdictionQualifierModel } from 'app/nexus-shared/domain/taxes/models/tax-jurisdiction-qualifier.model';
import { environment } from 'environments/environment';
import { BaseHttpService } from 'app/nexus-core/services/index';

@Injectable()
export class TaxJurisdictionQualifierService {
    qualifiers: Observable<TaxJurisdictionQualifierModel[]>;

    constructor(private baseHttpService: BaseHttpService) {
    }

    get(id: number): Observable<TaxJurisdictionQualifierModel> {
        return this.baseHttpService.get(`${this.apiUrl()}taxQualifiers/${id}`);
    }

    list(): Observable<TaxJurisdictionQualifierModel[]> {
        if (!this.qualifiers) {
            this.qualifiers = this.baseHttpService.get(`${this.apiUrl()}taxQualifiers`).pipe(publishReplay(1), refCount());
        }

        return this.qualifiers;
    }

    private apiUrl = () => environment().webAPIUrls.taxes;
}
