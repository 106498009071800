import { Component, OnInit } from '@angular/core';
import { BaseViewComponent } from 'app/nexus-shared/components/base-component/base-view.component';
import { DocumentModel } from 'app/nexus-shared/domain/documents/models/document.model';
import { ClientDocumentTypesEnum } from 'app/nexus-shared/domain/documents/enums/client-document-types.enum';
import { DocumentsHelper } from 'app/nexus-core/helpers/documents.helper';
import { AreaTypesEnum } from 'app/nexus-shared/domain/documents/enums/area-types.enum';

@Component({
    selector: 'gtn-document-view',
    templateUrl: './document-view.component.html',
    styleUrls: ['./document-view.component.scss']
})
export class DocumentViewComponent extends BaseViewComponent<DocumentModel> implements OnInit {
    clientDocumentTypesEnum = ClientDocumentTypesEnum;
    areaTypesEnum = AreaTypesEnum;
    taxYear: string;

    ngOnInit() {
        this.taxYear = DocumentsHelper.getFiscalTaxYear(this.value.clientDocument);
    }
}
