import { Component } from '@angular/core';
import { IndividualCreateTemplateModalComponent } from 'app/nexus-shared/domain/contacts/components/modals/shared/individual-create-template-modal/individual-create-template-modal.component';

@Component({
    selector: 'gtn-individual-create-dual-form-content',
    template: '<ng-content></ng-content>'
})
export class IndividualCreateDualFormContentComponent {
    // noinspection JSUnusedLocalSymbols
    constructor(gtnIndividualCreateDualFormContentComponent: IndividualCreateTemplateModalComponent) {
        //THIS WILL THROW AN ERROR IF ITS NOT NESTED WITHIN THE gtn-modal ELEMENT
    }
}
