<form [formGroup]="formGroupRef">
    <div class="flex-row-start flex-wrap">
        <ng-container *ngIf="isTemplateMode">
            <gtn-dropdown class="medium-column mr-3" formControlName="type" label="Row type" [idOnly]="true"
                [options]="sectionRowTypes"></gtn-dropdown>
            <div *ngIf="formGroupRef.value?.type === sectionRowTypesEnum.Columns"
                class="medium-column flex-column-center mr-3">
                <div>
                    <button (click)="onAddColumnClicked()" color="primary" mat-flat-button matTooltip="Add"> Add Column
                    </button>
                </div>
            </div>
            <gtn-input *ngIf="formGroupRef.value?.type === sectionRowTypesEnum.Header" class="medium-column mr-3"
                label="Text" formControlName="text"></gtn-input>
        </ng-container>
        <div *ngIf="isTemplateMode" class="break"></div>
        <ng-container *ngIf="formGroupRef.value?.type === sectionRowTypesEnum.Columns">
            <div cdkDropList [ngClass]="{'flex-grow flex-row-start': !isTemplateMode || isEditMode}"
                (cdkDropListDropped)="onColumnDropped($event)">
                <ng-container *ngIf="sectionRowColumnsFormArray?.controls?.length">
                    <ng-container
                        *ngFor="let formGroup of sectionRowColumnsFormArray.controls; index as i; last as isLast">
                        <ng-container *ngIf="isTemplateMode">
                            <div cdkDrag class="flex-row-start"
                                [cdkDragDisabled]="sectionRowColumnsFormArray.controls?.length <= 1">
                                <!--            example for rima, maybe a template-->
                                <div *cdkDragPreview style="height:30px;width:500px;background:rgba(255,255,255,0.5)">
                                    <span class="ml-5"> Column {{ i + 1 }}</span>
                                </div>
                                <gtn-section-column-form [formGroupRef]="getFormGroupFromAbstractControl(formGroup)"
                                    [questionnaireDropdownService]="questionnaireDropdownService"
                                    [sectionKey]="sectionKey" [isSubForm]="true" [isTemplateMode]="isTemplateMode"
                                    (deleteColumn)="onDeleteColumnClicked($event)"></gtn-section-column-form>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!isTemplateMode || isEditMode">
                            <div class="flex-column-center mr-3"
                                [style.width]="getFormGroupFromAbstractControl(formGroup)?.get('widthPercentage')?.value + '%'">
                                <gtn-section-column-view-form
                                    [questionnaireDropdownService]="questionnaireDropdownService"
                                    [formGroupRef]="getFormGroupFromAbstractControl(formGroup)" [isSubForm]="true"
                                    [isEditMode]="isEditMode">
                                </gtn-section-column-view-form>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="!isTemplateMode || isEditMode">
            <div *ngIf="formGroupRef.value?.type === sectionRowTypesEnum.Break" class="flex-grow mt-3 mb-3 row-break">
            </div>
            <div *ngIf="formGroupRef.value?.type === sectionRowTypesEnum.Header" class="flex-grow">
                <div class="row-header"> {{ formGroupRef.value?.text }}</div>
            </div>
        </ng-container>
    </div>
</form>
