import { Component, EventEmitter, Input, Output } from '@angular/core';
import { concatMap } from 'rxjs/operators';
import { BaseEntryComponent } from 'app/nexus-shared/components/base-component/base-entry.component';
import { IndividualModel } from 'app/nexus-shared/domain/contacts/models';
import { CardModesEnum } from 'app/nexus-shared/enums/card-modes.enum';
import { ViewModesEnum } from 'app/nexus-shared/enums/view-modes.enum';
import { IndividualService } from 'app/nexus-core/services/domain/contacts/individual.service';

@Component({
    selector: 'gtn-individual-create-template-modal',
    templateUrl: './individual-create-template-modal.component.html',
    styleUrls: ['./individual-create-template-modal.component.scss']
})
export class IndividualCreateTemplateModalComponent extends BaseEntryComponent<IndividualModel> {

    @Input() isCreateNewIndividual: boolean;
    @Input() isDualFormDirty: boolean;
    @Input() cardMode: CardModesEnum = CardModesEnum.hidden;
    @Input() viewMode: ViewModesEnum = ViewModesEnum.General;
    @Input() headerText: string;
    @Input() isControllingCompany: boolean = false;

    @Output() individualSaveSuccess: EventEmitter<IndividualModel> = new EventEmitter<IndividualModel>();

    constructor(private individualService: IndividualService) {
        super();
    }

    onSaveClicked(): void {
        if (this.isCreateNewIndividual) {
            this.isSaving = true;
            this.individualService.create(this.value).pipe(
                concatMap((key: string) => {
                    this.value.individualKey = key;
                    return this.individualService.getByKey(key);
                })
            ).subscribe((res: IndividualModel): void => {
                const newIndividual = new IndividualModel(res);
                this.individualSaveSuccess.emit(newIndividual);
                this.isSaving = false;
                this.onSaveSuccess();
            }, err => {
                this.onSaveFailure(err);
                this.isSaving = false;
            });
        } else {
            this.saveClick.emit();
        }
    }

    onCancelClicked(): void {
        this.cancelClick.emit();
    }

}
