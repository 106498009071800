export class FileModel {
    fileKey: string;
    originalFullFileName: string;
    name: string;
    extension: string;
    fullFileName: string;
    path: string;
    fullFilePath: string;
    sizeInBytes: number;
    contentType: string;

    constructor(model: FileModel = null) {
        this.fileKey = model?.fileKey ?? null;
        this.originalFullFileName = model?.originalFullFileName ?? null;
        this.name = model?.name ?? null;
        this.extension = model?.extension ?? null;
        this.fullFileName = model?.fullFileName ?? null;
        this.path = model?.path ?? null;
        this.fullFilePath = model?.fullFilePath ?? null;
        this.sizeInBytes = model?.sizeInBytes ?? null;
        this.contentType = model?.contentType ?? null;
    }
}
