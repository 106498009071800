import { Component, forwardRef, Input, OnChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LocationService } from 'app/nexus-core/services/location.service';
import { BaseLocationSelectComponent } from 'app/nexus-shared/domain/locations/components/select/base-location-select.component';
import { LocationHelper } from 'app/nexus-core/helpers/location.helper';
import { SimpleChangesTyped } from 'app/nexus-shared/models/simple-changes-typed.type';
import { LocationOrderModel } from 'app/nexus-shared/domain/locations/models/location-order.model';

@Component({
    selector: 'gtn-country-select',
    templateUrl: '../../../../components/controls/components/dropdown/dropdown.component.html',
    styleUrls: ['../../../../components/controls/components/dropdown/dropdown.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CountrySelectComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => CountrySelectComponent),
            multi: true
        }
    ]
})
export class CountrySelectComponent extends BaseLocationSelectComponent implements ControlValueAccessor, OnChanges {
    @Input() countriesWithStatesOnly = false;
    @Input() label = 'Country';
    @Input() customLocationOrder: LocationOrderModel[] = LocationHelper.defaultLocationOrder;

    constructor(private locationService: LocationService) {
        super();
    }

    ngOnChanges(changes: SimpleChangesTyped<this>) {
        if (changes.customLocationOrder && this.customLocationOrder?.length) {
            this.options = LocationHelper.locationsSort(this.options, this.customLocationOrder);
        }
    }

    dataFetch = () => {
        const dataFetch = this.countriesWithStatesOnly
            ? this.locationService.listCountriesWithStates()
            : this.locationService.listCountries();
        return dataFetch;
    };

    focusInput(): void {
        this.element.focus();
        this.element.open();
    }
}
