import { Component } from '@angular/core';
import { TemplateViewComponent } from 'app/nexus-shared/components/templates/template-view/template-view.component';

@Component({
    selector: 'gtn-template-view-content',
    template: '<ng-content></ng-content>'
})
export class TemplateViewContentComponent<T> {
    // noinspection JSUnusedLocalSymbols
    constructor(templateViewComponent: TemplateViewComponent) {
        // THIS WILL THROW AN ERROR IF ITS NOT NESTED WITHIN PARENT
    }
}
