<gtn-modal class="modal-small-wide" [headerText]="headerText"
    okButtonText="Save" [disableOkButton]="(!formGroupRef.dirty && !isDualFormDirty) || isSaving" [validationModels]="validationModels"
    (okClick)="onSaveClicked()"
    (cancelClick)="onCancelClicked()" (closeClick)="onCancelClicked()" [formGroupRef]="formGroupRef">
    <gtn-modal-content>
        <ng-content select="gtn-company-create-dual-form-content"></ng-content>
        <gtn-company-form *ngIf="isCreateNewCompany" [(formGroupRef)]="formGroupRef"
            (formGroupRefChange)="onFormGroupRefChanged($event)"
            [(value)]="value" [cardMode]="cardMode" [viewMode]="viewMode"></gtn-company-form>
    </gtn-modal-content>
</gtn-modal>
