import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NexusAnimations } from 'app/nexus-shared/animations';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { ToastService } from 'app/nexus-core/services/toast.service';
import { LoggingService, ValidationErrorHelper } from 'app/nexus-core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UserImpersonationService } from 'app/nexus-core/services/domain/users/user-impersonation.service';
import { ValidationModel } from 'app/nexus-shared/models';
import { AuthenticatedUserService } from 'app/nexus-core/services/authenticated-user.service';
import { AuthenticationService } from 'app/nexus-core/services/authentication.service';
import { AuthorizationConstants } from 'app/nexus-shared/constants/authorization.constants';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { UserImpersonationModel } from 'app/nexus-shared/domain/users/models/user-impersonation.model';

@Component({
    selector: 'gtn-user-impersonation-modal',
    templateUrl: './user-impersonation-modal.component.html',
    animations: [
        NexusAnimations.modalFadeIn
    ]
})
export class UserImpersonationModalComponent extends BaseComponent implements OnInit {
    @Input() userImpersonationKey: string = null;
    @Input() defaultToCurrentUser = false;
    @Output() completed = new EventEmitter<boolean>();

    userSearchModel = { hasGtnRole: true };
    impersonationUserSearchModel = { hasGtnRole: false };
    hasImpersonationAccess = false;
    disableButtons = false;
    formGroupRef: UntypedFormGroup = null;
    disableEdit = true;

    constructor(
        public loggingService: LoggingService,
        public authenticatedUserService: AuthenticatedUserService,
        private authenticationService: AuthenticationService,
        private userImpersonationService: UserImpersonationService,
        private toastService: ToastService) {
        super();
    }

    ngOnInit(): void {
        this.hasImpersonationAccess = this.authenticatedUserService.hasAuthorization(AuthorizationConstants.authUserImpersonation);

        if (this.authenticatedUserService.hasAuthorization(AuthorizationConstants.gtnAdministrator)) {
            this.impersonationUserSearchModel.hasGtnRole = null;
        }

        if (this.userImpersonationKey) {
            this.userImpersonationService.search({userImpersonationKey: this.userImpersonationKey}).subscribe(userImpersonations => {
                if (userImpersonations.length === 1) {
                    this.setUserImpersonation(userImpersonations[0]);
                } else {
                    this.onCancelClicked();
                }
            });
        } else {
            this.userImpersonationService.getCurrentImpersonation().subscribe(userImpersonation => {
                this.setUserImpersonation(userImpersonation);

                if (this.defaultToCurrentUser) {
                    this.formGroupRef.get(ObjectHelper.nameOf<UserImpersonationModel>('userKey')).setValue(this.authenticatedUserService.user.userKey);
                    this.formGroupRef.get(ObjectHelper.nameOf<UserImpersonationModel>('userName')).setValue(`${this.authenticatedUserService.user.firstName} ${this.authenticatedUserService.user.lastName}` );
                }
            });
        }
    }

    onUserSelected(value: any): void {
        this.formGroupRef.get(ObjectHelper.nameOf<UserImpersonationModel>('userKey')).setValue(value?.userKey);
    }

    onImpersonationUserSelected(value: any): void {
        this.formGroupRef.get(ObjectHelper.nameOf<UserImpersonationModel>('impersonationUserKey')).setValue(value?.userKey);
    }

    onSubmitClicked(isDisable = false): void {
        this.disableButtons = true;
        if (!this.formGroupRef.value.userImpersonationKey) {
            this.userImpersonationService.create(this.formGroupRef.value).subscribe(result => {
                    if (this.authenticatedUserService.user.userKey == this.formGroupRef.value.userKey && this.formGroupRef.value.isActive) {
                        this.toastService.showMessageToast('You will be logged out automatically, log in using your GTN credentials to begin impersonation.');
                        setTimeout((): void => {
                            this.authenticationService.logout();
                        }, 5000); // 5 seconds for users to read the message before being logged out
                    } else {
                        this.completed.emit(true);
                    }
                },
                (error) => {
                    this.disableButtons = false;
                    ValidationErrorHelper.handleServiceError(error, (validationModels: ValidationModel[]): void => {
                        this.toastService.showErrorToast(error);
                    });
                }
            );
        } else {
            const value = this.formGroupRef.value;
            if (isDisable) {
                value.isActive = false;
            }

            this.userImpersonationService.update(this.formGroupRef.value).subscribe(result => {
                    if (this.authenticatedUserService.user.userKey == this.formGroupRef.value.userKey && !value.isActive) {
                        this.toastService.showMessageToast('You will be logged out automatically, log in using your GTN credentials to end impersonation.');
                        setTimeout((): void => {
                            this.authenticationService.logout();
                        }, 5000); // 5 seconds for users to read the message before being logged out
                    } else {
                        this.completed.emit(true);
                    }
                },
                (error) => {
                    this.disableButtons = false;
                    ValidationErrorHelper.handleServiceError(error, (validationModels: ValidationModel[]): void => {
                        this.toastService.showErrorToast(error);
                    });
                }
            );
        }
    }

    onCancelClicked(): void {
        this.completed.emit(false);
    }

    private setUserImpersonation(userImpersonation: UserImpersonationModel): void {
        this.disableEdit = userImpersonation?.userImpersonationKey && !userImpersonation?.isActive;

        this.formGroupRef = new UntypedFormGroup({
            userImpersonationKey: new UntypedFormControl(userImpersonation?.userImpersonationKey ?? null),
            userKey: new UntypedFormControl(userImpersonation?.userKey ?? !this.hasImpersonationAccess ? this.authenticatedUserService.user.userKey : null),
            userName: new UntypedFormControl(userImpersonation?.userName ?? null),
            impersonationUserKey: new UntypedFormControl(userImpersonation?.impersonationUserKey ?? null),
            impersonationUserName: new UntypedFormControl(userImpersonation?.impersonationUserName ?? null),
            userRoleDefinitionIds: new UntypedFormControl(userImpersonation?.userRoleDefinitionIds ?? []),
            isActive: new UntypedFormControl(userImpersonation?.isActive ?? true),
            requestReason: new UntypedFormControl(userImpersonation?.requestReason ?? null),
            changeNotes: new UntypedFormControl(userImpersonation?.changeNotes ?? null),
        });
    }
}
