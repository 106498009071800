import { Component, Input } from '@angular/core';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';

@Component({
    selector: 'gtn-validation-indicator',
    templateUrl: './validation-indicator.component.html',
    styleUrls: ['./validation-indicator.component.scss']
})
export class ValidationIndicatorComponent extends BaseComponent {
    @Input() count: number;
    @Input() title: string = '';
}
