import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { BaseFormComponent } from 'app/nexus-shared/components/base-component/base-form.component';
import { BaseFormModel } from 'app/nexus-shared/models/base-form.model';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { SimpleChangesTyped } from 'app/nexus-shared/models/simple-changes-typed.type';
import { FolderModel } from 'app/nexus-shared/domain/documents/models/folder.model';
import { ClientDocumentModel } from 'app/nexus-shared/domain/documents/models/client-document.model';
import { DocumentEventTypesEnum } from 'app/nexus-shared/domain/documents/enums/document-event-types.enum';
import { BulkDocumentModel } from 'app/nexus-shared/domain/documents/models/document-view-models/bulk-document.model';
import { FileDocumentModel } from 'app/nexus-shared/domain/documents/models/document-view-models/file-document.model';
import { DocumentModel } from 'app/nexus-shared/domain/documents/models/document.model';
import { FileModel } from 'app/nexus-shared/domain/documents/models/file.model';
import { FileHelper, NexusValidatorHelper } from 'app/nexus-core';

@Component({
    selector: 'gtn-bulk-documents-form',
    templateUrl: './bulk-documents-form.component.html',
    styleUrls: ['./bulk-documents-form.component.scss']
})
export class BulkDocumentsFormComponent extends BaseFormComponent<BulkDocumentModel> implements OnInit, OnChanges {
    documentFormArray: UntypedFormArray;

    @Input() files: File[];
    @Input() canShowValidation: boolean = false;
    @Input() folder: FolderModel;
    @Output() documentRemoved: EventEmitter<number> = new EventEmitter();

    constructor() {
        super();
    }

    ngOnChanges(changes: SimpleChangesTyped<this>) {
        super.ngOnChanges(changes);
        if (changes.files && !changes.files.isFirstChange()) {
            this.handleFilesChanged(this.files);
        }
    }

    ngOnInit(): void {
        const formConfiguration = new BaseFormModel();
        const fileDocumentSubformConfiguration = new BaseFormModel();
        formConfiguration.formArrayValidators[ObjectHelper.nameOf<BulkDocumentModel>('documents')] = this.validateDocumentNames();
        fileDocumentSubformConfiguration.formConfiguration[ObjectHelper.nameOf<FileDocumentModel>('document')] = new BaseFormModel();
        formConfiguration.formArrayConfiguration[ObjectHelper.nameOf<BulkDocumentModel>('documents')] = {
            template: new FileDocumentModel(),
            addBlank: false,
            formConfiguration: fileDocumentSubformConfiguration,
            validators: formConfiguration.formArrayValidators[ObjectHelper.nameOf<BulkDocumentModel>('documents')]
        };
        super.ngOnInit(formConfiguration);
        if (this.files?.length) {
            this.handleFilesChanged(this.files);
        }
    }

    initFormCustomizations(): void {
        this.documentFormArray = this.formGroupRef.get(ObjectHelper.nameOf<BulkDocumentModel>('documents')) as UntypedFormArray;
    }

    createNewFileDocument(fileDocumentModel: FileDocumentModel): void {
        const formConfiguration = new BaseFormModel();
        const documentFormConfiguration = new BaseFormModel();
        const fileFormConfiguration = new BaseFormModel();
        fileFormConfiguration.validators[ObjectHelper.nameOf<FileModel>('sizeInBytes')] = [NexusValidatorHelper.validateFileSize()];
        documentFormConfiguration.formConfiguration[ObjectHelper.nameOf<DocumentModel>('file')] = fileFormConfiguration;
        formConfiguration.formConfiguration[ObjectHelper.nameOf<FileDocumentModel>('document')] = documentFormConfiguration;
        const newFormGroup = new UntypedFormGroup({});
        this.buildFormFromTemplate(fileDocumentModel, newFormGroup, formConfiguration);
        this.documentFormArray.push(newFormGroup);

        //expression changed after check lifecycle break
        setTimeout(() => {
            this.documentFormArray.markAsDirty();
            this.documentFormArray.updateValueAndValidity();
        });
    }

    onRemoveFileClicked(i): void {
        this.documentFormArray.removeAt(i);
        this.documentRemoved.emit(i);
        if (!this.documentFormArray?.length) {
            this.formGroupRef.markAsPristine();
        }
    }

    handleFilesChanged(files: File[]): void {
        files.forEach((x, i) => {
            if (this.documentFormArray.at(i)) {
                this.documentFormArray.at(i).get(ObjectHelper.nameOf<FileDocumentModel>('file')).setValue(x);
            } else {
                const fileDocument = new FileDocumentModel(null, x);
                fileDocument.document.name = x.name.replace(/\.[^/.]+$/, '');
                fileDocument.document.clientDocument = <ClientDocumentModel>this.folder.clientFolder;
                fileDocument.document.folderKey = this.folder.folderKey;
                fileDocument.document.documentPath = this.folder.folderPath;
                fileDocument.document.areaType = this.folder.areaType;
                fileDocument.document.eventType = DocumentEventTypesEnum.Create;
                fileDocument.document = this.getFileData(fileDocument.document, x);
                this.createNewFileDocument(fileDocument);
            }
        });
    }

    getInvalid(formGroup: AbstractControl): boolean {
        const fileSizeFormControl = formGroup.get(ObjectHelper.nameOf<FileDocumentModel>('document'))?.get(ObjectHelper.nameOf<DocumentModel>('file'))?.get(ObjectHelper.nameOf<FileModel>('sizeInBytes')) as UntypedFormControl;
        return fileSizeFormControl?.invalid ? fileSizeFormControl.errors?.fileSize : null;
    }


    validateDocumentNames(): ValidatorFn {
        return (formArray: UntypedFormArray) => {
            const duplicates = {};
            formArray.controls.forEach((x, i) => {
                duplicates[`${x.value.document.name}.${x.value.document.file.extension}`] = duplicates[`${x.value.document.name}.${x.value.document.file.extension}`] || [];
                duplicates[`${x.value.document.name}.${x.value.document.file.extension}`].push(i);
            });

            Object.keys(duplicates).forEach(key => {
                if (duplicates[key].length > 1) {
                    duplicates[key].forEach(formArrayIndex => {
                        formArray.at(formArrayIndex).get(ObjectHelper.nameOf<FileDocumentModel>('document')).get(ObjectHelper.nameOf<DocumentModel>('name')).setErrors({ duplicates: 'File name must be unique' });
                    });
                } else {
                    formArray.at(duplicates[key][0]).get(ObjectHelper.nameOf<FileDocumentModel>('document')).get(ObjectHelper.nameOf<DocumentModel>('name')).setErrors(null);
                }
            });
            return null;
        };
    }

    private getFileData(model: DocumentModel, file: File): DocumentModel {
        model.file = new FileModel();
        model.file.originalFullFileName = file.name;
        model.file.sizeInBytes = file.size;
        model.file.contentType = file.type;
        model.file.extension = FileHelper.getExtension(file.name);

        return model;
    }
}
