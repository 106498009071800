export enum VirusScanErrorsEnum {
    None = 0,
    ContainsExecutables = 10,
    InvalidFileType = 20,
    ContainsScript = 30,
    PasswordProtected = 40,
    ContainsMacro = 50,
    ContainsXMLExternalEntities = 60,
    InsecureDeserialization = 70,
    ContainsHTML = 80,
    FileTypeDoesNotMatchFile = 90
}
