import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

export interface ComponentCanDeactivate {
    canDeactivate: (nextUrl: string, router: Router) => Observable<boolean> | boolean;
}

@Injectable()
export class PendingChangesGuard  {
    constructor(private router: Router) {
    }

    canDeactivate(component: ComponentCanDeactivate, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean | Observable<boolean> {
        return component?.canDeactivate ? component.canDeactivate(nextState?.url, this.router) : true;
    }
}
