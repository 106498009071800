import { Component, Input, OnInit } from '@angular/core';
import { BaseViewComponent } from 'app/nexus-shared/components/base-component/base-view.component';
import { IndividualModel } from 'app/nexus-shared/domain/contacts/models';
import { ApplicationsEnum } from 'app/nexus-shared/index';
import { MyGTNPortalService } from 'app/nexus-core/services/domain/contacts/my-gtn-portal.service';
import { IndividualTypesEnum } from '../../../enums/individual-types.enum';
import { RegionTypesEnum } from 'app/nexus-shared/domain/contacts/enums/region-types.enum';
import { AuthenticatedUserService } from 'app/nexus-core/services/authenticated-user.service';
import { IndividualService } from 'app/nexus-core/services/domain/contacts/individual.service';
import { PhoneNumberTypesEnum } from 'app/nexus-shared/domain/contacts/enums/phone-number-types.enum';
import { PronounTypesEnum } from 'app/nexus-shared/domain/contacts/enums/pronoun-types.enum';
import { PronounEnumHelper } from 'app/nexus-shared/domain/contacts/helpers/pronoun-enum.helper';
import { SortingHelper } from 'app/nexus-core';

@Component({
    selector: 'gtn-individual-view',
    templateUrl: './individual-view.component.html',
    styleUrls: ['./individual-view.component.scss']
})
export class IndividualViewComponent extends BaseViewComponent<IndividualModel> implements OnInit {
    @Input() application: ApplicationsEnum;

    applicationsEnum = ApplicationsEnum;
    individualTypesEnum = IndividualTypesEnum;
    phoneNumberTypes = PhoneNumberTypesEnum;
    myGTNPortalUserName: string;
    regionsEnum = RegionTypesEnum;
    pronounTypes = PronounTypesEnum;
    enumHelper = PronounEnumHelper;

    constructor(
        private authenticatedUserService: AuthenticatedUserService,
        private individualService: IndividualService,
        private myGTNPortalService: MyGTNPortalService,
    ) {
        super();
    }

    ngOnInit() {
        this.sortIndividualTypes();
        if (this.value?.myGTNPortalUserKey) {
            this.getMyGTNPortalUser(this.value.myGTNPortalUserKey);
        }

    }

    private getMyGTNPortalUser(key: string): void {
        this.myGTNPortalService.getIndividual(key).subscribe((res) => {
            this.myGTNPortalUserName = res;
        });
    }

    private sortIndividualTypes(): void {
        this.value.individualTypes.sort((a, b) =>
            SortingHelper.sortEnumAlphabetically(a.type, b.type, IndividualTypesEnum)
        );
    }

}
