import { Component, EventEmitter, Output } from '@angular/core';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';

@Component({
    selector: 'gtn-pending-changes-modal',
    templateUrl: './pending-changes-modal.component.html',
    styleUrls: ['./pending-changes-modal.component.scss']
})
export class PendingChangesModalComponent extends BaseComponent {
    @Output() saveClick = new EventEmitter();
    @Output() closeCancelClick = new EventEmitter();
    @Output() discardClick = new EventEmitter();

    onSaveClicked(): void {
        this.saveClick.emit();
    }

    onCloseCancelClicked(): void {
        this.closeCancelClick.emit();
    }

    onDiscardClicked(): void {
        this.discardClick.emit();
    }
}
