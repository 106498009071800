import { Component } from '@angular/core';
import { ITooltipAngularComp } from 'ag-grid-angular';
import { ITooltipParams } from 'ag-grid-community';

@Component({
    selector: 'gtn-cell-tooltip',
    templateUrl: './cell-tooltip.component.html',
    styleUrls: ['./cell-tooltip.component.scss']
})
export class CellTooltipComponent implements ITooltipAngularComp {
    params: ITooltipParams;

    agInit(params: ITooltipParams): void {
        this.params = params;
    }
}
