<gtn-template-dynamic-card [mode]="0">
    <ng-template #content>
        <form [formGroup]="formGroupRef">
            <div class="row">
                <gtn-input class="col-md-4" label="Dropdown label" formControlName="label"></gtn-input>
            </div>
            <div class="section-header">
                Options <span (click)="onAddClicked()" class="pointer ml-2"><i class="fa fa-plus"></i></span>
            </div>
            <div class="pl-1 pr-1" cdkDropList (cdkDropListDropped)="onDrop($event)">
                <ng-container *ngFor="let option of dropdownOptionsFormArray.controls; let i = index">
                    <ng-container [formGroup]="getFormGroupFromAbstractControl(option)">
                        <div cdkDrag class="flex flex-row-start align-items-center">
                            <div class="flex flex-column justify-content-center">
                                <i class="grab fa-solid fa-bars"></i>
                            </div>
                            <gtn-input class="col-3" formControlName="label"
                                [isFocused]="isFocused"
                                (keydown.enter)="onAddClicked()">
                            </gtn-input>
                            <i class="fas fa-trash pointer" (click)="onDeleteLabel(i)"></i>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
            <ng-container *ngIf="!dropdownOptionsFormArray?.controls?.length">
                No options set
            </ng-container>
        </form>
    </ng-template>
</gtn-template-dynamic-card>
