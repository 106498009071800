import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from 'app/nexus-core/services/domain/users/user.service';
import { RoleService } from 'app/nexus-core/services/domain/users/role.service';
import { AgGridHelper } from 'app/nexus-core/helpers';
import { GridComponent } from 'app/nexus-shared/components/controls/components/base-grid';
import { AuthorizationConstants } from 'app/nexus-shared/constants/authorization.constants';
import { AuthenticatedUserService } from 'app/nexus-core/services/authenticated-user.service';
import { UserModel } from 'app/nexus-shared/domain/users/models/user.model';
import { BaseEnterpriseGridComponent } from 'app/nexus-shared/components/base-component/base-enterprise-grid.component';
import { ColDef } from 'ag-grid-community';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { UserSearchModel } from 'app/nexus-shared/domain/users/models/user-search.model';
import { UserStatusesEnum } from 'app/nexus-shared/enums/user-statuses.enum';
import { IndividualSettingService } from 'app/nexus-core/services/domain/core/individual-setting.service';
import { AgGridColDefHelper } from 'app/nexus-core/helpers/ag-grid-col-def.helper';
import { CommonBaseAuditUserModel } from 'app/nexus-shared/domain/contacts/models/common-base-audit-user.model';

@Component({
    selector: 'gtn-users-grid',
    templateUrl: '../../../../components/base-component/base-grid-template.component.html'
})
export class UsersGridComponent extends BaseEnterpriseGridComponent<UserModel> implements OnInit {
    static cellClickableClass = AgGridHelper.copyPasteCellClass;

    @Input() isInternal: boolean;
    @Output() userClick: EventEmitter<UserModel> = new EventEmitter<UserModel>();

    gridName: string = 'users_grid';
    gridVersion: string = '1.0.4';
    isAdmin: boolean = false;

    @ViewChild('baseGrid') baseGridRef: GridComponent;

    constructor(
        protected individualSettingService: IndividualSettingService,
        private userService: UserService,
        private roleService: RoleService,
        private authenticatedUserService: AuthenticatedUserService,
    ) {
        super(individualSettingService);
    }

    ngOnInit() {
        this.isAdmin = this.authenticatedUserService.hasAuthorization(AuthorizationConstants.gtnAdministrator);
        this.gridSecondaryIdentifier = this.isInternal ? 'internal' : 'external';
        super.ngOnInit();
    }

    onRowClicked(row: any): void {
        if (row.event.target.classList.contains(UsersGridComponent.cellClickableClass)) {
            return;
        }

        this.userClick.emit(row.data);
    }

    refreshData(): void {
        this.baseGridRef.refreshData();
    }

    onSearchTextChanged(search: string): void {
        this.onFilterTextChanged(search);
    }

    setDefaultSortModel() {
        this.defaultSortModel = [
            { colId: ObjectHelper.nameOf<UserModel>('lastName'), sort: 'asc', sortIndex: 0 },
            { colId: ObjectHelper.nameOf<UserModel>('firstName'), sort: 'asc', sortIndex: 1 },
        ];
    }

    protected setColumnDefinitions(): ColDef[] {

        return [
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOf<UserModel>('lastName'), 'Last Name'),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOf<UserModel>('firstName'), 'First Name'),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOf<UserModel>('email'), 'Email'),
            AgGridColDefHelper.getEnumColumnDef(ObjectHelper.nameOf<UserModel>('status'), 'Status', UserStatusesEnum),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOf<UserModel>('roles'), 'Roles', null, null, true),
            AgGridColDefHelper.getYesNoColDef(ObjectHelper.nameOf<UserModel>('isLockedOut'), 'Locked Out'),
            AgGridColDefHelper.getDateTimeColDef(ObjectHelper.nameOf<UserModel>('lastLoginDateTime'), 'Last Login'),
            AgGridColDefHelper.getDateTimeColDef(ObjectHelper.nameOf<UserModel>('lastInvitedDateTime'), 'Last Invited'),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOf<UserModel>('userKey'), 'Key'),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOf<UserModel>('userId'), 'Auth0 Id'),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOf<UserModel>('individualKey'), 'Individual Key'),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOfSubProperty<UserModel, CommonBaseAuditUserModel>('createdByUser', 'fullName'), 'Created By', null, { hide: true }),
            AgGridColDefHelper.getDateTimeColDef(ObjectHelper.nameOfSubProperty<UserModel, CommonBaseAuditUserModel>('createdByUser', 'changeDateTime'), 'Created On', { hide: true }),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOfSubProperty<UserModel, CommonBaseAuditUserModel>('lastModifiedByUser', 'fullName'), 'Last Modified By', null, { hide: true }),
            AgGridColDefHelper.getDateTimeColDef(ObjectHelper.nameOfSubProperty<UserModel, CommonBaseAuditUserModel>('lastModifiedByUser', 'changeDateTime'), 'Modified On', { hide: true }),
        ];
    }

    protected setRowData(): Observable<UserModel[]> {
        const searchModel = new UserSearchModel();
        searchModel.hasGtnRole = this.isInternal;

        return this.userService.search(searchModel);
    }
}

