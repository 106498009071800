export class CalendarDayModel {
    day: number;
    isInMonth: boolean;
    date: Date;

    constructor(model: CalendarDayModel = null) {
        this.day = model?.day ?? null;
        this.isInMonth = model?.isInMonth ?? null;
        this.date = model?.date ?? null;
    }
}
