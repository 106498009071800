import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { DateHelper, LocalStorageHelper } from 'app/nexus-core';
import { LocalStorageNameConstants, SelectListInterface, SelectListPipe } from 'app/nexus-shared/index';
import { TaxesGridFilterType, TaxesRateFilterType } from 'app/nexus-shared/domain/taxes/enums';
import { TaxJurisdictionQualifierModel, TaxSpecializationModel } from 'app/nexus-shared/domain/taxes/models';
import { RatesFilterPanelFormControlNames } from 'app/nexus-shared/domain/taxes/components/tax-detail/filter-panel/rates-filter-panel-form-control-names';
import { FilterPanelFormGroupHelper } from 'app/nexus-shared/domain/taxes/components/tax-detail/filter-panel/rates-filter-panel-form-group.helper';
import { TaxJurisdictionQualifierService } from 'app/nexus-core/services/domain/taxes/tax-jurisdiction-qualifier.service';
import { TaxRateService } from 'app/nexus-core/services/domain/taxes/tax-rate.service';

@Component({
    selector: 'gtn-tax-rates-filter-panel',
    templateUrl: './rates-filter-panel.component.html',
    providers: [
        FilterPanelFormGroupHelper,
        LocalStorageHelper,
        SelectListPipe
    ]
})
export class GtnTaxRatesFilterPanelComponent implements OnInit {
    @Output() applyEmitter: EventEmitter<SelectListInterface[]> = new EventEmitter();
    @Output() updateChipsEmitter: Subject<SelectListInterface[]> = new Subject();

    formGroup: UntypedFormGroup = this.formGroupHelper.formGroup;
    date: Date;
    isDateDisabled: boolean = false;
    isSpecializationDisabled: boolean = false;
    qualifierOptions: SelectListInterface[];
    selectedSpecializationKeys: string[];
    specializationOptions: SelectListInterface[];
    subscriptions: Subscription = new Subscription;
    taxQualifiers: TaxJurisdictionQualifierModel[];
    controlNames = RatesFilterPanelFormControlNames;

    private selectedFilters: SelectListInterface[];
    private taxId: number;

    constructor(
        private selectListPipe: SelectListPipe,
        private formGroupHelper: FilterPanelFormGroupHelper,
        private taxJurisdictionQualifierService: TaxJurisdictionQualifierService,
        private taxRateService: TaxRateService,
        private activatedRoute: ActivatedRoute
    ) {
        this.taxId = this.activatedRoute.snapshot.params.id;

        this.processStoredFilters();

        this.subscriptions.add(this.formGroup.controls[this.controlNames.qualifier].valueChanges.subscribe(qualifiers => {
            if (qualifiers && qualifiers.length > 0) {
                this.addFilters(this.selectListPipe.transform(qualifiers, 'taxQualifierId', 'name', TaxesRateFilterType.qualifierId));
            } else {
                this.removeFilters(TaxesRateFilterType.qualifierId);
            }
        }));

        this.subscriptions.add(this.formGroup.controls[this.controlNames.specialization].valueChanges.subscribe(spec => {
            if (spec && spec.id !== 999) {
                this.addFilters([spec]);
            } else {
                this.removeFilters(TaxesRateFilterType.specializationKey);
            }
        }));

        this.subscriptions.add(this.formGroup.controls[this.controlNames.effectiveDate].valueChanges.subscribe(date => {
            if (!this.isDateDisabled) {
                if (date) {
                    const dateSelect: SelectListInterface = {
                        id: 'date',
                        typeId: TaxesRateFilterType.date,
                        value: date,
                        visible: true,
                        disabled: false
                    };
                    this.addFilters([dateSelect]);
                } else {
                    this.removeFilters(TaxesRateFilterType.date);
                }
            }
        }));

        // SET INPUTS TO STORED VALUES (IF ANY)
        this.taxJurisdictionQualifierService.list().subscribe((qualifiers: TaxJurisdictionQualifierModel[]) => {
            this.taxQualifiers = qualifiers;

            const selectedQualifiers = this.selectedFilters?.filter(x => x.typeId === TaxesRateFilterType.qualifierId);

            if (selectedQualifiers?.length > 0) {
                const selectedTypes: TaxJurisdictionQualifierModel[] = [];

                selectedQualifiers.map(type => {
                    const getType = this.taxQualifiers.find(t => t.name === type.value);
                    selectedTypes.push(getType);
                });

                this.formGroup.controls['qualifier'].setValue(selectedTypes);
            }
        });

        if (this.taxId) {
            const specializationList = [];
            this.taxRateService.specializations(this.taxId).subscribe(specs => {
                specs.map((item: TaxSpecializationModel) => {
                    specializationList.push(item);
                });

                this.specializationOptions = this.selectListPipe.transform(specializationList, 'specializationKey', 'specializationTitle', TaxesRateFilterType.specializationKey);
            });
        }
    }

    ngOnInit() {
        const effectiveDate = this.selectedFilters?.find(x => x.typeId === TaxesRateFilterType.date);
        if (effectiveDate) {
            this.formGroup.controls[this.controlNames.effectiveDate].setValue(effectiveDate.value);
        }

        const selectedSpecializationKey = this.selectedFilters?.find(x => x.typeId === TaxesRateFilterType.specializationKey);
        if (selectedSpecializationKey) {
            this.formGroup.controls[this.controlNames.specialization].setValue(selectedSpecializationKey);
        }
    }

    applyFilters(): void {
        this.applyEmitter.emit(this.selectedFilters);
    }

    clearFilters(item: SelectListInterface = null): void {
        switch (item?.typeId) {
            case TaxesRateFilterType.date:
                this.resetDate();
                this.applyFilters();
                break;
            case TaxesRateFilterType.specializationKey:
                this.resetSpecialization(item);
                this.applyFilters();
                break;
            case TaxesRateFilterType.qualifierId:
                this.resetQualifier(item);
                this.applyFilters();
                break;
            default:
                this.resetAll();
                break;
        }
    }

    private addFilters(filters: SelectListInterface[]): void {
        this.selectedFilters = this.selectedFilters || [];
        const typeId = filters[0].typeId;
        const existingFilter = this.selectedFilters?.find(x => x.typeId === typeId);

        if (existingFilter) {
            this.selectedFilters = this.selectedFilters.filter(x => x.typeId !== typeId);
            this.selectedFilters = Object.assign([{}], this.selectedFilters.concat(filters));
        } else {
            this.selectedFilters = Object.assign([{}], this.selectedFilters.concat(filters));
        }

        this.updateChipsEmitter.next(this.selectedFilters);
        LocalStorageHelper.set(LocalStorageNameConstants.TaxRatesGrid, this.selectedFilters);
    }

    private processStoredFilters() {
        const filters: SelectListInterface[] = [];
        const taxesGridFilters = LocalStorageHelper.get(LocalStorageNameConstants.TaxesGrid);

        if (taxesGridFilters) {
            taxesGridFilters.forEach((filter: SelectListInterface) => {
                switch (filter.typeId) {
                    case TaxesGridFilterType.date:
                        filter.typeId = TaxesRateFilterType.date;
                        filter.value = DateHelper.format(DateHelper.convertToUtcDate(filter.value), DateHelper.standardDateFormat);                        filter['prefix'] = 'Tax: ';
                        filter.disabled = true;
                        this.isDateDisabled = true;
                        filters.push(filter);
                        break;
                    case TaxesGridFilterType.specializationKey:
                        filter.value = filter.value;
                        filter['prefix'] = 'Tax: ';
                        filter.disabled = true;
                        this.isSpecializationDisabled = true;
                        filters.push(filter);
                        break;
                    default:
                        break;
                }
            });
        }

        const ratesGridFilters = LocalStorageHelper.get(LocalStorageNameConstants.TaxRatesGrid);
        if (ratesGridFilters) {
            ratesGridFilters.forEach((filter: SelectListInterface) => {
                switch (filter.typeId) {
                    case TaxesRateFilterType.date:
                        if (!filters.find(x => x.typeId === TaxesRateFilterType.date)) {
                            filters.push(filter);
                        }
                        break;
                    case TaxesRateFilterType.qualifierId:
                        filters.push(filter);
                        break;
                    case TaxesRateFilterType.specializationKey:
                        if (!filters.find(x => x.typeId === TaxesGridFilterType.date)) {
                            filters.push(filter);
                        }
                        break;
                }
            });
        }

        if (filters.length > 0) {
            this.selectedFilters = filters;
            setTimeout(() => {
                this.updateChipsEmitter.next(this.selectedFilters);
            }, 0);
        }
    }

    private removeFilters(typeId: TaxesRateFilterType): void {
        if (this.selectedFilters && this.selectedFilters.find(x => x.typeId === typeId)) {
            this.selectedFilters = this.selectedFilters.filter(x => x.typeId !== typeId);
        }

        if (!this.selectedFilters || this.selectedFilters.length < 1) {
            this.selectedFilters = null;
        }

        this.updateChipsEmitter.next(this.selectedFilters);

        LocalStorageHelper.set(LocalStorageNameConstants.TaxRatesGrid, this.selectedFilters);
    }

    private resetDate(): void {
        if (!this.isDateDisabled) {
            this.formGroup.controls[this.controlNames.effectiveDate].reset();
        }
    }

    private resetSpecialization(item = null): void {
        if (!this.isSpecializationDisabled) {
            const control = this.formGroup.controls[this.controlNames.specialization];
            control.reset();
            control.setValue([]);
        }
    }

    private resetQualifier(item = null): void {
        const control = this.formGroup.controls[this.controlNames.qualifier];

        if (!item) {
            control.reset();
            control.setValue([]);
            return;
        }

        const updatedValue = control.value.filter(currentItem => currentItem.taxQualifierId !== item.id);
        control.setValue(updatedValue);
    }

    private resetAll(): void {
        this.resetDate();
        this.resetSpecialization();
        this.resetQualifier();

        LocalStorageHelper.delete(LocalStorageNameConstants.TaxRatesGrid);
    }
}
