import { environment } from 'environments/environment';
import { BaseHttpService } from 'app/nexus-core/services/index';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IndividualToCompanyAccessModel } from 'app/nexus-shared/domain/contacts/models/individual-to-company-access.model';
import { PhoneNumberHelper } from 'app/nexus-core/helpers/phone-number.helper';

@Injectable()
export class IndividualCompanyAccessService {
    private apiUrl = environment().webAPIUrls.contacts + 'individual-to-company-access/';

    constructor(private baseHttpService: BaseHttpService) {
    }

    getByKey(individualKey: string): Observable<IndividualToCompanyAccessModel> {
        return this.baseHttpService.get(`${this.apiUrl}${individualKey}`);
    }

    listByIndividualKey(individualKey: string): Observable<IndividualToCompanyAccessModel[]> {
        return this.baseHttpService.get(`${this.apiUrl}individual/${individualKey}`);
    }

    listByCompanyKey(companyKey: string): Observable<IndividualToCompanyAccessModel[]> {
        return this.baseHttpService.get(`${this.apiUrl}company/${companyKey}`);
    }

    listByControllingCompany(): Observable<IndividualToCompanyAccessModel[]> {
        return this.listByCompanyKey(environment().controllingCompanyKey);
    }

    create(model: IndividualToCompanyAccessModel): Observable<string> {
        model.primaryPhoneNumber = PhoneNumberHelper.nullOutPhoneNumber(model.primaryPhoneNumber);
        model.secondaryPhoneNumber = PhoneNumberHelper.nullOutPhoneNumber(model.secondaryPhoneNumber);
        return this.baseHttpService.post(this.apiUrl, model);
    }

    update(model: IndividualToCompanyAccessModel): Observable<boolean> {
        model.primaryPhoneNumber = PhoneNumberHelper.nullOutPhoneNumber(model.primaryPhoneNumber);
        model.secondaryPhoneNumber = PhoneNumberHelper.nullOutPhoneNumber(model.secondaryPhoneNumber);
        return this.baseHttpService.put(this.apiUrl, model);
    }
}
