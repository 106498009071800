<form *ngIf="formGroupRef"
    [formGroup]="formGroupRef">
    <div class="filters"
        [hidden]="isFiltersHidden">
        <div class="filter">
            <gtn-input formControlName="searchTerm"
                label="Search"></gtn-input>
        </div>
        <div class="filter">
            <gtn-company-search #companySearch
                (searchResultClick)="onCompanySelected($event)"
                label="Company"></gtn-company-search>
        </div>
        <div class="filter">
            <gtn-individual-search #individualSearch
                (searchResultClick)="onIndividualSelected($event)"
                label="Individual"></gtn-individual-search>
        </div>
        <div class="filter">
            <gtn-multiselect label="Area"
                formControlName="areaTypes"
                [options]="areaTypeOptions"
                [idOnly]="true"></gtn-multiselect>
        </div>
        <div class="filter">
            <gtn-datepicker class="datepicker"
                label="Last modified start date"
                formControlName="startDate"></gtn-datepicker>
        </div>
        <div class="filter">
            <gtn-datepicker class="datepicker"
                label="Last modified end date"
                formControlName="endDate"></gtn-datepicker>
        </div>
        <div class="filter">
            <gtn-multiselect label="Document statuses"
                formControlName="statuses"
                [idOnly]="true"
                [options]="documentStatusesOptions"></gtn-multiselect>
        </div>
        <ng-container formGroupName="advancedSearch">
            <div class="filter">
                <gtn-dropdown label="Tax year"
                    formControlName="taxYear"
                    [idOnly]="true"
                    [options]="taxYearOptions"></gtn-dropdown>
            </div>
            <div class="filter">
                <gtn-multiselect label="Client document types"
                    formControlName="documentTypes"
                    [idOnly]="true"
                    [options]="clientDocumentTypes"></gtn-multiselect>
            </div>
            <div class="filter">
                <gtn-country-select label="Country"
                    formControlName="countryId"
                    [idOnly]="true"></gtn-country-select>
            </div>
        </ng-container>
        <div class="search-click">
            <button (click)="onSearchClicked()"
                [disabled]="!formGroupRef.dirty || isLoading"
                class="mr-2"
                color="primary"
                mat-mini-fab
                matTooltip="Search">
                <mat-icon>search</mat-icon>
            </button>
        </div>
        <div class="search-click">
            <button (click)="onResetFiltersClicked()"
                class="mr-2"
                color="default"
                mat-mini-fab
                matTooltip="Reset filters">
                <mat-icon>restart_alt</mat-icon>
            </button>
        </div>
        <div class="search-click">
            <button (click)="onToggleVisibilityClicked()"
                class="mr-2"
                color="default"
                mat-mini-fab
                matTooltip="Toggle filters">
                <mat-icon *ngIf="isFiltersHidden">visibility</mat-icon>
                <mat-icon *ngIf="!isFiltersHidden">visibility_off</mat-icon>
            </button>
        </div>
    </div>
</form>
