import { Pipe, PipeTransform } from '@angular/core';
import { EnumHelper } from 'app/nexus-core';

@Pipe({
    name: 'enum'
})
export class EnumPipe implements PipeTransform {

    transform(value: any, enumInstance: any, customDisplayNameFunc: (enumValue: number) => string = null): string {
        return EnumHelper.getDisplayName(enumInstance, value, customDisplayNameFunc);
    }

}
