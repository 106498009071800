import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { ReportHeaderParamsModel } from 'app/nexus-shared/components/controls/components/base-grid/report-header/report-header-params.model';

@Component({
    selector: 'gtn-report-header',
    templateUrl: './report-header.component.html',
    styleUrls: ['./report-header.component.scss']
})
export class ReportHeaderComponent extends BaseComponent implements IHeaderAngularComp {
    params: ReportHeaderParamsModel;

    @ViewChild('headerCell', { static: true }) headerCell: ElementRef;

    constructor(private renderer: Renderer2) {
        super();
    }

    agInit(params: ReportHeaderParamsModel): void {
        this.params = params;

        this.renderer.setStyle(this.headerCell.nativeElement.parentElement, 'height', '100%');
        this.renderer.setStyle(this.headerCell.nativeElement.parentElement, 'width', '100%');

        if (params.styles) {
            const styles = params.styles;

            Object.keys(styles).forEach((key => {
                this.renderer.setStyle(this.headerCell.nativeElement, key, styles[key]);
            }).bind(this));
        }
    }

    refresh = (params: ReportHeaderParamsModel): boolean => {
        return true;
    };
}
