<gtn-template-dynamic-card *ngIf="(viewMode === viewModes.All || viewMode === viewModes.General) && value.agreement"
    [mode]="cardMode">
    <ng-template #content>
        <ng-container>
            <div
                *ngIf="displaySigner && (!value.agreement?.agreementKey || value.agreement.status === agreementStatusEnum.Draft)">
                <div class="row">
                    <gtn-company-search class="col-12"
                        (searchResultClick)="onSignerCompanyChanged($event)"
                        label="Signer company"
                        [readonly]="value.agreement.status !== agreementStatusEnum.Draft"
                        [value]="signerCompany">
                    </gtn-company-search>
                </div>
                <div class="row">
                    <gtn-individual-search class="col-12"
                        label="Signer individual"
                        (searchResultClick)="onSignerIndividualChanged($event)"
                        [companyKey]="signerCompany?.companyKey"
                        [value]="signerIndividual">
                    </gtn-individual-search>
                </div>
            </div>
            <div class="row"
                *ngIf="displayMainAgreementType && value && (signerCompany || signerIndividual)">
                <gtn-dropdown class="col-12"
                    label="Agreement type"
                    [(ngModel)]="mainAgreementType"
                    [disabled]="value.agreement.status !== agreementStatusEnum.Draft"
                    [idOnly]="true"
                    [options]="mainAgreementTypes"></gtn-dropdown>
            </div>
            <gtn-agreement-consent-form #consentForm
                *ngIf="mainAgreementType === agreementTypeEnum.ConsentForm && (signerCompany || signerIndividual)"
                (sendStatusChange)="onSendStatusChanged($event)"
                (spouseNameChange)="onSpouseNameChanged($event)"
                (subtypeChange)="onSubtypeChanged($event)"
                [agreementTypes]="agreementTypes"
                [companies]="companies"
                [isSubForm]="true"
                [signerCompany]="signerCompany"
                [signerIndividual]="signerIndividual"
                [signerSpouse]="signerSpouse"
                [(formConfiguration)]="agreementFormConfiguration"
                [(formGroupRef)]="agreementFormGroupRef"
                [(validationModels)]="validationModels"
                [(value)]="value.agreement"></gtn-agreement-consent-form>
            <gtn-agreement-iel-form #ielForm
                *ngIf="mainAgreementType === agreementTypeEnum.IndependentEngagementLetter && (signerCompany || signerIndividual)"
                (reviewerChange)="onReviewerChanged($event)"
                (subtypeChange)="onSubtypeChanged($event)"
                [agreementTypes]="agreementTypes"
                [isSubForm]="true"
                [reviewers]="reviewers"
                [signerCompany]="signerCompany"
                [signerIndividual]="signerIndividual"
                [(formConfiguration)]="agreementFormConfiguration"
                [(formGroupRef)]="agreementFormGroupRef"
                [(validationModels)]="validationModels"
                [(value)]="value.agreement"></gtn-agreement-iel-form>
            <div class="row">
                <gtn-file-upload class="col-12"
                    label="Upload signed agreement"
                    (fileChange)="onFileChange($event)"
                    [isDragAndDrop]="true"></gtn-file-upload>
            </div>
        </ng-container>
    </ng-template>
</gtn-template-dynamic-card>
