import { IndividualModel } from 'app/nexus-shared/domain/contacts/models/individual.model';
import { IndividualRelationshipTypesEnum } from 'app/nexus-shared/domain/contacts/enums/individual-relationship-types.enum';
import { CommonBaseAuditModel } from 'app/nexus-shared/domain/contacts/models/common-base-audit.model';
import { MarriageEndTypesEnum } from 'app/nexus-shared/enums/marriage-end-types.enum';

export class IndividualRelationshipModel extends CommonBaseAuditModel {
    individualRelationshipKey: string;
    relationshipType: IndividualRelationshipTypesEnum;
    marriageEndType: MarriageEndTypesEnum;
    effectiveStartDate: Date | string;
    effectiveEndDate: Date | string;
    childIndividual: IndividualModel;
    parentIndividual: IndividualModel;
    marriageDate?: Date;
    divorceDate?: Date;
    isDivorced: boolean;
    marriageLocationCountryId?: number;
    active: boolean;

    constructor(model: IndividualRelationshipModel = null) {
        super(model);
        this.individualRelationshipKey = model?.individualRelationshipKey ?? null;
        this.relationshipType = model?.relationshipType ?? null;
        this.marriageEndType = model?.marriageEndType ?? null;
        this.effectiveStartDate = model?.effectiveStartDate ?? null;
        this.effectiveEndDate = model?.effectiveEndDate ?? null;
        this.childIndividual = model?.childIndividual ?? null;
        this.parentIndividual = model?.parentIndividual ?? null;
        this.marriageDate = model?.marriageDate ?? null;
        this.divorceDate = model?.divorceDate ?? null;
        this.isDivorced = model?.isDivorced ?? null;
        this.marriageLocationCountryId = model?.marriageLocationCountryId ?? null;
        this.active = model?.active ?? null;
    }
}
