import { CommonBaseAuditModel } from '../../contacts/models/common-base-audit.model';
import { IAgreementSignatureModelInterface } from '../interfaces/iagreement-signature-model.interface';

export class AgreementSignatureModel extends CommonBaseAuditModel implements IAgreementSignatureModelInterface {
    agreementKey: string;
    signatureKey: string;
    isInitial: boolean;
    placeholder: string;
    value: string;

    constructor(model: AgreementSignatureModel = null) {
        super(model);

        this.agreementKey = model?.agreementKey ?? null;
        this.signatureKey = model?.signatureKey ?? null;
        this.isInitial = model?.isInitial ?? false;
        this.placeholder = model?.placeholder ?? null;
        this.value = model?.value ?? null;
    }
}
