export class HtmlHelper {
    public static replaceHtmlEntities(html: string): string {
        return html
            .replace('&amp;', '&')
            .replace('&nbsp;', ' ')
            .replace('&lt;', '<')
            .replace('&rt;', '>')
            .replace('&gt;', '>')
            .replace('&quot', '"')
            .replace('&apos;', '\'');
    }

    public static convertToHtmlNode(htmlString: string): Element {
        const element = new DOMParser().parseFromString(htmlString, 'text/html').body.children[0];
        return element;
    }

    public static getParentByToken(htmlDocument: Document, tokenInParentBody: string, childClassName: string): Element {
        const elements = htmlDocument.getElementsByClassName(childClassName);

        for (let i = 0; i < elements.length; i++) {
            if (elements[i].textContent.indexOf(tokenInParentBody) > -1 || elements[i].innerHTML.indexOf(tokenInParentBody) > -1) {
                return elements[i] as Element;
            }
        }
    }

    public static findParentElementWithClassName(element: Element, classNameOptions: string[]): Element {

        if (!element) {
            return null;
        }

        if (!element.classList) {
            return HtmlHelper.findParentElementWithClassName(element.parentElement, classNameOptions);
        }

        const foundClass = classNameOptions.find(c => element.classList.contains(c));
        if (!foundClass) {
            return HtmlHelper.findParentElementWithClassName(element.parentElement, classNameOptions);
        }

        return element;
    }

    public static findParentElementWithAttributeName(element: Element, attributeNameOptions: string[]): Element {

        if (!element) {
            return null;
        }

        if (!element.classList) {
            return HtmlHelper.findParentElementWithAttributeName(element.parentElement, attributeNameOptions);
        }

        const foundAttribute = attributeNameOptions.find(c => !!element.attributes.getNamedItem(c));
        if (!foundAttribute) {
            return HtmlHelper.findParentElementWithAttributeName(element.parentElement, attributeNameOptions);
        }

        return element;
    }
}
