import { LocationInterface } from 'app/nexus-shared/interfaces';

export class QuestionnaireLocationModel implements LocationInterface {
    id?: number;
    name?: string;

    constructor(model: QuestionnaireLocationModel = null) {
        this.id = model?.id ?? null;
        this.name = model?.name ?? null;
    }
}
