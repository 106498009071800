<form [formGroup]="formGroupRef">
    <ng-container *ngIf="sectionColumnType === sectionColumnTypesEnum.UserInput">
        <ng-container
            *ngIf="formGroupRef.get(formControlNames.userInputType)?.value === userInputTypesEnum.FreeFormText ||
             formGroupRef.get(formControlNames.userInputType)?.value === userInputTypesEnum.PhoneNumber ||
             formGroupRef.get(formControlNames.userInputType)?.value === userInputTypesEnum.EmailAddress">
            <gtn-input formControlName="value"></gtn-input>
        </ng-container>
        <ng-container
            *ngIf="formGroupRef.get(formControlNames.userInputType)?.value === userInputTypesEnum.Numeric">
            <gtn-input type="number" formControlName="value" (change)="onNumericActionInputChanged()"></gtn-input>
        </ng-container>
        <ng-container
            *ngIf="formGroupRef.get(formControlNames.userInputType)?.value === userInputTypesEnum.Date">
            <gtn-datepicker formControlName="value"></gtn-datepicker>
        </ng-container>
        <ng-container
            *ngIf="formGroupRef.get(formControlNames.userInputType)?.value === userInputTypesEnum.DropDown">
            <gtn-questionnaire-dropdown-option-select [questionnaireDropdownService]="questionnaireDropdownService"
                [dropdownId]="formGroupRef.get(formControlNames.dropdownTypeId)?.value"
                (change)="onDropdownActionInputChanged()"
                formControlName="value"></gtn-questionnaire-dropdown-option-select>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="sectionColumnType === sectionColumnTypesEnum.Text">
        <div class="flex-column-center" [ngStyle]="
        {'font-weight': formGroupRef.get(formControlNames.isBoldText)?.value ? '700' : null,
        'font-style': formGroupRef.get(formControlNames.isItalicsText)?.value ? 'italic' : null,
        'color': formGroupRef.get(formControlNames.isRedText)?.value ? 'red' : null,
        'text-decoration': formGroupRef.get(formControlNames.isUnderlineText)?.value ? 'underline' : null}">
            <div class="flex-row-start">
                {{ formGroupRef.get(formControlNames.text)?.value }}
                <span *ngIf="!!formGroupRef.get(formControlNames.additionalInfo)?.value" class="ml-1"
                    [matTooltip]="formGroupRef.get(formControlNames.additionalInfo).value">
                <i class="fa fa-circle-info"></i>
            </span>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="sectionColumnType === sectionColumnTypesEnum.Table">
        <gtn-section-column-view-table-form [formGroupRef]="formGroupRef" [isSubForm]="true"
            [questionnaireDropdownService]="questionnaireDropdownService">
        </gtn-section-column-view-table-form>
    </ng-container>
    <ng-container *ngIf="sectionColumnType === sectionColumnTypesEnum.Header">
        <div class="flex-column-center align-center" [ngStyle]="
        {'font-weight': formGroupRef.get(formControlNames.isBoldText)?.value ? '700' : null,
        'font-style': formGroupRef.get(formControlNames.isItalicsText)?.value ? 'italic' : null,
        'color': formGroupRef.get(formControlNames.isRedText)?.value ? 'red' : null,
        'text-decoration': formGroupRef.get(formControlNames.isUnderlineText)?.value ? 'underline' : null}">
            {{ formGroupRef.get(formControlNames.text)?.value }}
            <span *ngIf="!!formGroupRef.get(formControlNames.additionalInfo)?.value" class="ml-1"
                [matTooltip]="formGroupRef.get(formControlNames.additionalInfo).value">
                <i class="fa fa-circle-info"></i>
            </span>
        </div>
    </ng-container>
</form>
