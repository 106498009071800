export enum SearchResultIconEnum {
    unknown = 0,
    user = 'fa fa-user',
    map = 'fa fa-map',
    userTie = 'fa fa-user-tie',
    calendar = 'fa fa-calendar-check',
    trophy = 'fa fa-trophy',
    airplane = 'fas fa-plane',
    country = 'fas fa-globe-americas',
    state = 'fas fa-globe-americas',
    city = 'fas fa-map-marker-alt',
    business = 'fa fa-building',
    folder = 'fas fa-folder'
}
