import { SectionRowModel } from 'app/nexus-shared/domain/questionnaires/models/section-row.model';
import { Guid } from 'guid-typescript';

export class SectionModel {
    key: string;
    parentSectionColumnKey?: string;
    title: string;
    order: number;
    isCompleted: boolean;
    isDeleted: boolean;
    rows: SectionRowModel[];

    constructor(model: SectionModel = null) {
        this.key = model?.key ?? Guid.create().toString();
        this.parentSectionColumnKey = model?.parentSectionColumnKey ?? null;
        this.title = model?.title ?? null;
        this.order = model?.order ?? null;
        this.rows = model?.rows?.length ? model.rows.map(x => new SectionRowModel(x)) : [];
        this.isCompleted = model?.isCompleted ?? null;
        this.isDeleted = model?.isDeleted ?? null;
    }
}
