export class InputType {
    static readonly color = 'color';
    static readonly currency = 'currency';
    static readonly date = 'date';
    static readonly datetimeLocal = 'datetime-local';
    static readonly email = 'email';
    static readonly month = 'month';
    static readonly number = 'number';
    static readonly password = 'password';
    static readonly search = 'search';
    static readonly tel = 'tel';
    static readonly text = 'text';
    static readonly time = 'time';
    static readonly url = 'url';
    static readonly week = 'week';
    static readonly fmv = 'fmv';
}
