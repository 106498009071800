import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';

@Component({
    selector: 'gtn-agreements-confirm-modal',
    templateUrl: './agreements-confirm-modal.component.html',
    styleUrls: ['./agreements-confirm-modal.component.scss']
})
export class AgreementsConfirmModalComponent extends BaseComponent {
    @Input() headerText: string;
    @Input() bodyText: string;

    @Output() confirmClick: EventEmitter<string> = new EventEmitter();
    @Output() cancelClick: EventEmitter<void> = new EventEmitter();

    allowSubmit: boolean = false;
    reason: string = '';

    onOkClicked(): void {
        this.confirmClick.emit(this.reason);
    }

    onCancelClicked(): void {
        this.cancelClick.emit();
    }

    onReasonChange($event): void {
        if (typeof $event === 'string') {
            this.reason = $event;
        }

        this.allowSubmit = (this.reason && this.reason !== '');
    }
}
