import { AfterViewInit, Component, forwardRef, Input, OnChanges, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseLocationSelectComponent } from 'app/nexus-shared/domain/locations/components/select/base-location-select.component';
import { LocationService } from 'app/nexus-core/services/location.service';
import { LocationHelper } from 'app/nexus-core/helpers/location.helper';
import { SimpleChangesTyped } from 'app/nexus-shared/models/simple-changes-typed.type';

@Component({
    selector: 'gtn-state-select',
    templateUrl: '../../../../components/controls/components/dropdown/dropdown.component.html',
    styleUrls: ['../../../../components/controls/components/dropdown/dropdown.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => StateSelectComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => StateSelectComponent),
            multi: true
        }
    ]
})
export class StateSelectComponent extends BaseLocationSelectComponent implements ControlValueAccessor, OnChanges, OnInit, AfterViewInit {
    @Input() countryId: number = null;
    @Input() label: string = 'State';

    constructor(private locationService: LocationService) {
        super();
    }

    ngOnInit() {
        // overriding the base onInit so initOptions() doesn't get called when it's not supposed to
    }

    ngOnChanges(changes: SimpleChangesTyped<this>): void {
        if (changes.countryId && changes.countryId.previousValue !== changes.countryId.currentValue && this.countryId) {
            this.options = null;
            this.initOptions();
            this.idToSetWhenOptionsLoaded = this.formControl?.value ?? null;
        }

        if (changes.customLocationOrder && this.customLocationOrder?.length) {
            this.options = LocationHelper.locationsSort(this.options, this.customLocationOrder);
        }
    }

    ngAfterViewInit() {
        if (!this.formControl?.value) {
            this.suppressInitialChangeEvent = false;
        }
        super.ngAfterViewInit();
    }

    dataFetch = () => this.locationService.listStates(this.countryId);
}
