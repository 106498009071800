import { Component, forwardRef, Input, OnChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseDropdownControlComponent } from '../base-dropdown-control.component';

@Component({
    selector: 'gtn-multiselect',
    templateUrl: '../dropdown/dropdown.component.html',
    styleUrls: ['../dropdown/dropdown.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MultiselectComponent),
            multi: true
        }
    ]
})
export class MultiselectComponent<T> extends BaseDropdownControlComponent<T[], T> implements ControlValueAccessor, OnChanges {
    @Input() showSelectAll = false;
    @Input() showDeselectAll = false;

    isInit: boolean = true;
    deselectAllId: number = -2;
    selectAllId: number = -1;

    constructor() {
        super();
        this.isMultiSelect = true;
    }

    get value(): T[] {
        // return what the consumer is expecting
        if (this.idOnly && this._value) {
            return this._value.map(x => x[this.idKey]);
        }

        return this._value;
    }

    set value(value: T[]) {
        this.setValue(value);
    }

    onSelectClosed() {
        this.resortOptions();

        if (this.isComboboxEnabled) {
            this.filterElementRef.nativeElement.focus();
        }
    }

    handleResponse(data: T[]) {
        this.options = JSON.parse(JSON.stringify(data));
    }

    setExtraOptions(): void {
        if (this.options?.length) {
            this.resortOptions();

            if ((this.showSelectAll || this.showDeselectAll) && !this.options.find(x => x[this.idKey] === this.deselectAllId)) {
                this.handleShowDeselectOption(this._value);
            }

            if (this.showSelectAll && !this.options.find(x => x[this.idKey] === this.selectAllId)) {
                this.handleShowSelectAllOption(this._value);
            }
        }
    }

    resortOptions() {
        const selectedValues = (this.value as unknown as T[]);
        // ensure the select/deselect all options stay on the top
        const selectAllOptions = this.options.filter(x => x[this.idKey] === this.selectAllId || x[this.idKey] === this.deselectAllId);

        // pull the select/deselect all options out before sorting
        let options = this.options.filter(x => x[this.idKey] !== this.selectAllId && x[this.idKey] !== this.deselectAllId);
        if (selectedValues?.length) {
            // do the sorting
            options = options.sort((a, b) => {
                const aSortName = this.getObjectValue(a, this.displayKey);
                const bSortName = this.getObjectValue(b, this.displayKey);
                // sort selected options at the top, otherwise use default sort order
                // this doesnt work for id only
                const aIsSelected = selectedValues.map(v => v[this.idKey]).indexOf(a[this.idKey]) > -1;
                const bIsSelected = selectedValues.map(v => v[this.idKey]).indexOf(b[this.idKey]) > -1;
                if (aIsSelected && bIsSelected) {
                    return aSortName?.toString()?.localeCompare(bSortName?.toString());
                }

                if (aIsSelected) {
                    return -1;
                }

                if (bIsSelected) {
                    return 1;
                }

                return aSortName.toString().localeCompare(bSortName.toString());
            });
        } else {
            options = options.sort((a, b) => {
                const aSortName = this.getObjectValue(a, this.displayKey);
                const bSortName = this.getObjectValue(b, this.displayKey);
                return aSortName?.toString()?.localeCompare(bSortName?.toString());
            });

            // concat the arrays
            this.options = [...selectAllOptions, ...options];
        }
        this.options = [...selectAllOptions, ...options];
    }

    private handleShowDeselectOption(values: T[]) {
        if (values?.length && this.options?.map(c => c[this.idKey]).indexOf(this.deselectAllId) === -1) {
            const deselectAllOption = {
                [this.idKey]: this.deselectAllId,
                [this.displayKey]: 'Deselect All'
            } as unknown as T;

            const showAllOptionIndex = this.options.findIndex(o => o[this.idKey] === this.selectAllId);

            if (showAllOptionIndex > -1) {
                this.options.splice(showAllOptionIndex, 1);
            }

            this.options = [deselectAllOption, ...this.options];

            if (showAllOptionIndex > -1) {
                const showAllOption = {
                    [this.idKey]: this.selectAllId,
                    [this.displayKey]: 'Select All'
                } as unknown as T;

                this.options = [showAllOption, ...this.options];
            }
        }
    }

    private handleShowSelectAllOption(values: T[]) {
        const tempOptions = JSON.parse(JSON.stringify(this.options));

        if ((!values?.length || values.length < tempOptions.filter(x => x[this.idKey] !== this.deselectAllId).length) && this.options.map(c => c[this.idKey]).indexOf(this.selectAllId) === -1) {
            const allOption = {
                [this.idKey]: this.selectAllId,
                [this.displayKey]: 'Select All'
            } as unknown as T;

            this.options = [allOption, ...this.options];
        }
    }

    private getIds(val: any[]) {
        return val?.filter(v => v)?.map(v => this.getId(v));
    }

    private setValue(value: T[]) {
        let emit = value?.length === 0 && this._value?.length > 0,
            values: any[] = [];

        if (value?.find(v => v[this.idKey] === this.deselectAllId)) {
            values = [];
            value = values;
            emit = true;
            this.options = this.options.filter(x => x[this.idKey] !== this.selectAllId && x[this.idKey] !== this.deselectAllId);
        } else if (value?.find(v => v[this.idKey] === this.selectAllId)) {
            this.options = this.options.filter(x => x[this.idKey] !== this.selectAllId && x[this.idKey] !== this.deselectAllId);
            values = this.options;
            emit = true;
        } else {
            value?.forEach(val => {
                if (typeof val !== 'undefined' && val !== null && (typeof this._value === 'undefined' || this._value === null || this._value.indexOf(val) === -1 || this._value !== val[this.idKey])) {
                    const id = this.getId(val);
                    if (id !== null) {
                        if (this.options) {
                            const optionValue = this.options.find(option => this.getId(option) === id);
                            if (optionValue) {
                                // only include unique values from options (LocationSort will include double countries)
                                if (!values?.find(v => this.getId(v) === this.getId(optionValue))) {
                                    values.push(optionValue);
                                    emit = true;
                                }
                            }
                        }
                    }
                } else if (typeof val === 'undefined' || val === null && this._value !== null) {
                    values.push(val);
                    emit = true;
                }
            });
        }

        if (this.isComboboxEnabled) {
            // if the only filtered option was selected, then clear the auto complete
            const val = (value as unknown as T[]);
            if (this.filteredOptions?.length === 1 && val?.find(v => v[this.idKey] === this.filteredOptions[0][this.idKey])) {
                this.filterControl.setValue('');
                this.filterElementRef.nativeElement.focus();
            }
        }

        this._value = values;

        if (emit) {
            if (this.idOnly) {
                this.onChange(this.getIds(values));
            } else {
                this.onChange(values);
            }

            this.triggerChanged(this.matSelectRef?.trigger);
            this.onTouched();
            this.setExtraOptions();
        } else {
            this.resortOptions();
        }
    }
}
