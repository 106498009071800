export class StringConstants {
    public static ControllingCompanyName: string = 'GTN';

    static messages = {
        serverError: 'Something went wrong, if it persists, try refreshing and try again',
        unauthorizedApp: 'You are not authorized to access this application, please contact your administrator',
        pendingChanges: 'You have pending changes. Are you sure you would like to leave without saving those changes?',
        invalidFileSize: 'Files larger than 500 MB are not supported'
    };

    static urlStrings = {
        coreBase: 'https://{0}core.gtn.com/#/',
        portalCompany: '/#/main/manage/companies/edit/{0}/company-info/basic-info',
        portalUser: '/#/main/manage/users/edit/{0}/profile-info/personal-info'
    };
}
