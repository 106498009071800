import { Component, Input, OnInit } from '@angular/core';
import { BaseEditTabComponent } from 'app/nexus-shared/components/base-component/base-edit-tab.component';
import { DocumentModel } from 'app/nexus-shared/domain/documents/models/document.model';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DocumentService } from 'app/nexus-core/services/domain/documents/document.service';
import { DocumentSearchModel } from 'app/nexus-shared/domain/documents/models/document-search.model';
import { SortingHelper } from 'app/nexus-core';
import { DocumentStatusesEnum } from 'app/nexus-shared/domain/documents/enums/document-statuses.enum';
import { AuthenticatedUserService } from 'app/nexus-core/services/authenticated-user.service';
import { FolderModel } from 'app/nexus-shared/domain/documents/models/folder.model';
import { DocumentsAuthModel } from 'app/nexus-shared/domain/documents/models/documents-auth.model';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { FolderDocumentGridService } from 'app/nexus-core/services/domain/documents/folder-document-grid.service';
import { DocumentsHelper } from 'app/nexus-core/helpers/documents.helper';
import { AreaTypesEnum } from 'app/nexus-shared/domain/documents/enums/area-types.enum';
import { CommonBaseAuditUserModel } from 'app/nexus-shared/domain/contacts/models/common-base-audit-user.model';

@Component({
    selector: 'gtn-document-detail',
    templateUrl: './document-detail.component.html',
    styleUrls: ['./document-detail.component.scss']
})
export class DocumentDetailComponent extends BaseEditTabComponent<DocumentModel> implements OnInit {
    @Input() documentKey: string;
    @Input() document: DocumentModel;
    @Input() documentsUserAuth: DocumentsAuthModel;
    @Input() selectedFolder: FolderModel;
    @Input() areaType: AreaTypesEnum;

    documentHistory: DocumentModel[];
    documentVersions: DocumentModel[];
    canEdit: boolean;
    canRestore: boolean;
    isFullHistory: boolean = false;
    canPreview: boolean = false;

    constructor(
        private documentService: DocumentService,
        private authenticatedUserService: AuthenticatedUserService,
        private folderDocumentGridService: FolderDocumentGridService) {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    getValue(): Observable<DocumentModel> {
        const searchModel = new DocumentSearchModel();
        searchModel.includeHistory = true;
        searchModel.documentKeys = [this.documentKey];
        searchModel.areaTypes = [this.areaType];
        return forkJoin({ document: this.documentService.get(this.documentKey), history: this.documentService.search(searchModel) }).pipe(map(res => {
            this.documentHistory = res.history.sort((a, b) => {
                return SortingHelper.sortByDatePropertyComparer(a.lastModifiedByUser, b.lastModifiedByUser, ObjectHelper.nameOf<CommonBaseAuditUserModel>('changeDateTime'));
            });

            this.documentVersions = DocumentsHelper.getValidVersions(this.documentHistory);

            this.canEdit = this.documentsUserAuth?.writeAccess && this.selectedFolder && res?.document?.isVirusScanned &&
                (res.document.status !== DocumentStatusesEnum.CheckedOut || this.authenticatedUserService.user.userKey === res.document.lastModifiedByUser.userKey || this.documentsUserAuth?.isAdmin);
            this.canRestore = this.documentsUserAuth?.isAdmin && res.document.status !== DocumentStatusesEnum.CheckedOut;
            this.canPreview = DocumentsHelper.isPreviewType(res.document.file.extension);
            return new DocumentModel(res.document);
        }));
    }

    onDocumentRestored(): void {
        this.folderDocumentGridService.refreshDocuments(true);
        this.getValue().subscribe(res => {
            this.value = res;
            this.originalValue = JSON.parse(JSON.stringify(res));
            this.tabs.selectedIndex = 0;
        });
    }

    onCopyVersion(document: DocumentModel): void {
        this.folderDocumentGridService.onDocumentCopied(document, this.selectedFolder);
    }

}
