import { Component, ElementRef } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { DateHelper } from 'app/nexus-core';

@Component({
    selector: 'gtn-date-cell-editor',
    templateUrl: './date-cell-editor.component.html',
    styleUrls: ['./date-cell-editor.component.scss']
})
export class DateCellEditorComponent implements ICellEditorAngularComp {
    cellValue: any;
    dateFormat: string = DateHelper.standardDateFormat;
    width: number;
    focus: boolean;
    selectAll: boolean;
    isInPopup: boolean;
    isInline: boolean;
    isCustomizedTheme: boolean;

    private params: any;

    constructor(private el: ElementRef) {
    }

    /**
     * TODO
     * Need to right align the date picker if the input is wider than 296px,
     * Can be accomplished with adding a left margin.
     * Not sure on any other way of accomplishing that.
     * **/

    agInit(params: any): void {
        this.params = params;
        this.cellValue = params.value;
        this.focus = params.focus;
        this.isInPopup = !!params.isPopup;
        this.isInline = !!params.isInline;
        this.isCustomizedTheme = !!params.isCustomizedTheme;

        if (this.isInPopup) {
            this.width = params.column.actualWidth - 32;
        } else if (this.isInline) {
            this.width = params.column.actualWidth - 15;
        }

        /**
         * Do not pass focus down to the gtn-datepicker.
         * Undesired affects may occur.
         * This method is the most consistent.
         **/
        if (this.focus) {
            setTimeout(() => {
                const focusElement = this.el.nativeElement.querySelector('input');
                focusElement.focus();
                if (this.selectAll) {
                    focusElement.select();
                }
            }, 10);
        }
    }

    getValue() {
        return DateHelper.convertToZeroDateString(DateHelper.convertToUtcDate(this.cellValue));
    }
}
