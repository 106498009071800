export class TaxRateDetailFormControlNames {
    public static effectiveEndDate: string = 'effectiveEndDate';
    public static effectiveStartDate: string = 'effectiveStartDate';
    public static limit: string = 'limit';
    public static rate: string = 'rate';
    public static specializationTitle: string = 'specializationTitle';
    public static specializationKey: string = 'specializationKey';
    public static taxQualifierId: string = 'taxQualifierId';
    public static taxResidencyId: string = 'taxResidencyId';
}
