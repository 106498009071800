import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IndividualService } from 'app/nexus-core/services/domain/contacts/individual.service';
import { ContactsRoutingIndividualsConfigurationConstants } from 'app/modules/contacts/routing/contacts-routing-individuals-configuration.constants';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { CompanyModel, IndividualModel } from 'app/nexus-shared/domain/contacts/models';
import { ApplicationsEnum } from 'app/nexus-shared/enums';
import { RoutingHelper } from 'app/nexus-routing/helpers/routing.helper';
import { IndividualTypesEnum } from 'app/nexus-shared/domain/contacts/enums/individual-types.enum';
import { RegionTypesEnum } from 'app/nexus-shared/domain/contacts/enums/region-types.enum';
import { IndividualCompanyAccessService } from 'app/nexus-core/services/domain/contacts/individual-company-access.service';

@Component({
    selector: 'gtn-individual-preview-modal',
    templateUrl: './individual-preview-modal.component.html',
    styleUrls: ['./individual-preview-modal.component.scss']
})
export class IndividualPreviewModalComponent extends BaseComponent implements OnInit {
    @Input() individualKey: string;
    @Input() application: ApplicationsEnum;

    @Output() cancelClick: EventEmitter<boolean> = new EventEmitter();

    individual: IndividualModel;
    applicationsEnum = ApplicationsEnum;
    individualTypesEnum = IndividualTypesEnum;
    regionsEnum = RegionTypesEnum;
    individualToAssociatedCompanies: CompanyModel[] = [];
    constructor(
        private individualService: IndividualService,
        private individualCompanyAccessService: IndividualCompanyAccessService
    ) {
        super();
    }

    ngOnInit(): void {
        this.individualService.getByKey(this.individualKey).subscribe(individual => {
            if (individual) {
                this.individual = new IndividualModel(individual);
            }
        });
        this.getIndividualCompanyRelationships();
    }

    navigateToContacts(): void {
        const navigationString = ContactsRoutingIndividualsConfigurationConstants.configurations.getGeneral().routing.navigationRoute(this.individual.individualKey)[0];
        RoutingHelper.navigateToContacts(navigationString);
    }

    onCancelClicked(): void {
        this.cancelClick.emit();
    }


    getIndividualCompanyRelationships(): void {
        if (this.individualKey) {
            this.individualCompanyAccessService.listByIndividualKey(this.individualKey).subscribe(res => {
                this.individualToAssociatedCompanies = [];
                res.forEach(associated => {
                    if (associated?.company) {
                        this.individualToAssociatedCompanies.push(associated.company);
                    }
                });
            });
        }
    }

}
