import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class EnvironmentSettingsService {
    public appInit(): Observable<void> {
        return from(
            fetch('environments/app-settings.json').then(function (response) {
                return response.json();
            })
        ).pipe(
            map((config) => {
                window['appSettings'] = config;
                return;
            }));
    }
}
