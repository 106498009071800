import { Component, Input, OnChanges, OnInit, TemplateRef } from '@angular/core';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { DateHelper } from 'app/nexus-core';
import { SimpleChangesTyped } from 'app/nexus-shared/models/simple-changes-typed.type';
import { CalendarHelper } from 'app/nexus-shared/components/calendar/helpers/calendar.helper';
import { CalendarDayModel } from 'app/nexus-shared/models/calendar-day.model';

@Component({
    selector: 'gtn-calendar-month-view',
    templateUrl: './calendar-month-view.component.html',
    styleUrls: ['./calendar-month-view.component.scss']
})
export class CalendarMonthViewComponent extends BaseComponent implements OnInit, OnChanges {
    @Input() month: number;
    @Input() year: number;
    @Input() date: Date;
    @Input() isYearView: boolean = false;
    @Input() template: TemplateRef<any>;
    @Input() weekDayStart: number = 0;

    days: CalendarDayModel[];
    weekDayNames: string[];

    ngOnChanges(changes: SimpleChangesTyped<this>) {
        if ((changes.year && this.year && !changes?.year?.isFirstChange()) || (changes.month && (this.month || this.month === 0) && !changes?.month?.isFirstChange())) {
            this.days = CalendarHelper.getDaysInMonth(this.month, this.year, this.weekDayStart);
        }

    }

    ngOnInit() {
        this.weekDayNames = DateHelper.getWeekDays(this.isYearView);
        if (this.weekDayStart !== 0) {
            const arr1 = this.weekDayNames.slice(0, this.weekDayStart);
            const arr2 = this.weekDayNames.slice(this.weekDayStart);
            this.weekDayNames = [...arr2, ...arr1];
        }
        this.days = CalendarHelper.getDaysInMonth(this.month, this.year, this.weekDayStart);
    }


}


