import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { BaseHttpService } from 'app/nexus-core/services/index';
import { TwoFactorVerificationTypesModel } from 'app/nexus-shared/models/two-factor-verification-types.model';

@Injectable()
export class TwoFactorService {
    apiUrl = `${environment().webAPIUrls.auth}two-factor`;

    constructor(private baseHttpService: BaseHttpService) {
    }

    listVerificationTypes(): Observable<TwoFactorVerificationTypesModel[]> {
        return this.baseHttpService.get(`${this.apiUrl}/verification-types`);
    }

    generateCode(twoFactorVerificationTypeKey: string): Observable<{twoFactorKey: string }> {
        if (twoFactorVerificationTypeKey) {
            return this.baseHttpService.get(`${this.apiUrl}/generate/${twoFactorVerificationTypeKey}`);
        }

        return this.baseHttpService.get(`${this.apiUrl}/generate`);
    }

    validateCode(model: { code: number, hasRememberMe: boolean, twoFactorKey: string}): Observable<string> {
        return this.baseHttpService.post(`${this.apiUrl}/validate`, model);
    }
}
