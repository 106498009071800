import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BaseHttpService } from 'app/nexus-core/services/base-http.service';
import { Observable } from 'rxjs';
import { DocumentModel } from 'app/nexus-shared/domain/documents/models/document.model';
import { DocumentMoveModel } from 'app/nexus-shared/domain/documents/models/document-move.model';
import { FolderMoveModel } from 'app/nexus-shared/domain/documents/models/folder-move.model';
import { FolderCopyModel } from 'app/nexus-shared/domain/documents/models/folder-copy.model';
import { IDocumentFolderServiceInterface } from 'app/nexus-core/services/domain/documents/idocument-folder-service.interface';
import { ClientFolderModel } from 'app/nexus-shared/domain/documents/models/client-folder.model';
import { FolderModel } from 'app/nexus-shared/domain/documents/models/folder.model';

@Injectable()
export class DocumentFolderService implements IDocumentFolderServiceInterface {
    private apiUrl = `${environment().webAPIUrls.documents}document-folder/`;

    constructor(private baseHttpService: BaseHttpService) {
    }

    copyFolder(folderCopy: FolderCopyModel): Observable<boolean> {
        return this.baseHttpService.post(`${this.apiUrl}copy`, folderCopy);
    }

    moveFolder(folderMove: FolderMoveModel): Observable<boolean> {
        return this.baseHttpService.post(`${this.apiUrl}move`, folderMove);
    }

    deleteFolder(folderKey: string): Observable<boolean> {
        return this.baseHttpService.post(`${this.apiUrl}delete`, { folderKey: folderKey });
    }

    getClientRootFolder(model: ClientFolderModel): Observable<FolderModel> {
        return this.baseHttpService.post(`${this.apiUrl}root-client-folder`, model);
    }

    moveDocument(documentMove: DocumentMoveModel): Observable<boolean> {
        return this.baseHttpService.post(`${this.apiUrl}move/document`,
            documentMove);
    }

    bulkMoveDocuments(documentMoves: DocumentMoveModel[]): Observable<boolean> {
        return this.baseHttpService.post(`${this.apiUrl}bulk-move/document`, documentMoves);
    }

    recoverDocument(model: DocumentModel): Observable<boolean> {
        return this.baseHttpService.post(`${this.apiUrl}restore/document`, model);
    }
}
