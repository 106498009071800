import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getName'
})
export class GetNamePipe implements PipeTransform {

    transform(value: number, options: any[], propName: string = 'value', id: string = 'id'): any {
        let response: any = null;

        if (options && value) {
            const option = options.find(x => x[id] === value);
            if (option) {
                response = option[propName];
            }
        }

        return response;
    }
}
