import { Component, EventEmitter, forwardRef, Output } from '@angular/core';
import { BaseSearchControlComponent } from 'app/nexus-shared/components/base-component/base-search-control.component';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SearchResultIconEnum, SearchResultModel } from 'app/nexus-shared/components/controls/shared';
import { GoogleLocationService } from 'app/nexus-core/services/google-location.service';
import { GoogleAddressPredictionModel } from 'app/nexus-shared/domain/contacts/models/google-address-prediction.model';
import { Observable } from 'rxjs';
import { AddressModel } from 'app/nexus-shared/domain/contacts/models/address.model';

@Component({
    selector: 'gtn-google-location-search',
    templateUrl: './google-location-search.component.html',
    styleUrls: ['./google-location-search.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => GoogleLocationSearchComponent),
            multi: true
        }
    ]
})

export class GoogleLocationSearchComponent extends BaseSearchControlComponent<GoogleAddressPredictionModel> implements ControlValueAccessor {
    primaryAddLabel = `Can't find your address? Add manually`;
    isCustomSearchResultClick = true;
    debounceTime = 750;

    @Output() addressClick: EventEmitter<AddressModel> = new EventEmitter<AddressModel>();

    constructor(private googleLocationService: GoogleLocationService) {
        super();

        this.subscriptions.add(this.searchModel.onResultClicked.subscribe(result => {
            if (result?.resultObject?.placeId) {
                this.getAddress(result.resultObject.placeId).subscribe(res => {
                    this.addressClick.emit(res);
                    this.writeValue(result.resultObject);
                });
            } else {
                this.addressClick.emit(null);
            }

        }));
    }

    protected onSearchTextChanged(searchText: string): void {
        // forcing them to enter some characters before adding manually
        if (searchText?.length > 4) {
            this.showAddLink = true;
        }
        this.googleLocationService.getLocationPredictions(searchText).subscribe(res => {
                const results = res.map((address, i) => {
                    return {
                        id: address.placeId,
                        name: address.address,
                        subName: '',
                        type: null,
                        iconClass: SearchResultIconEnum.map,
                        resultObject: address,
                        rank: 0
                    } as SearchResultModel<void, GoogleAddressPredictionModel>;
                });
                this.searchModel.onResultsReceived.next(results);
            },
            err => this.searchModel.onErrorReceived.next(err));

    }

    displayWith(searchResultItem: SearchResultModel<void, GoogleAddressPredictionModel> | GoogleAddressPredictionModel): string {
        if (searchResultItem && typeof searchResultItem === 'object') {
            if ('resultObject' in searchResultItem) {
                return searchResultItem?.resultObject
                    ? searchResultItem.resultObject.address
                    : null;
            }

            return searchResultItem.address;
        } else if (typeof searchResultItem === 'string') {
            return searchResultItem;
        }
        return null;
    }

    private getAddress(placeId: string): Observable<any> {
        return this.googleLocationService.getLocationByPlaceId(placeId);
    }

}
