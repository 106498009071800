import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { FileDragDropComponent } from 'app/nexus-shared/components/controls';
import { NexusModalService } from 'app/nexus-core';
import { NexusAnimations } from 'app/nexus-shared/animations';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';

@Component({
    templateUrl: './file-upload-modal.component.html',
    styleUrls: [
        '../../nexus-modal.component.scss',
        './file-upload-modal.component.scss'
    ],
    animations: [
        NexusAnimations.modalFadeIn
    ]
})
export class FileUploadModalComponent implements OnInit, OnDestroy {
    cancel: Function;
    cancelButtonText: string;
    dragDropInputText: string;
    enableOk: boolean;
    files: File[];
    filesAreSelected = false;
    fileSelectorInputText: string;
    gridHeight = 220;
    isMultiSelect: boolean = false;
    mainHeaderText: string;
    message: string;
    ok: Function;
    okButtonText: string;
    showCloseButton = true;
    subHeaderText: string;
    showDatePicker: boolean;
    requireDate: boolean;
    date: Date;
    columnDefs = [
        {
            headerName: 'File Name',
            field: ObjectHelper.nameOf<File>('name'),
            sortable: false,
            suppressMenu: true
        }
    ];
    cancelClick: Function = (() => {
        if (this.cancel) {
            this.cancel();
        }
        this.destroy();
    }).bind(this);
    okClick: Function = (() => {
        if (this.ok) {
            if (this.showDatePicker) {
                this.ok(this.files, this.date);
            } else {
                this.ok(this.files);
            }
        }
        this.destroy();
    }).bind(this);
    @ViewChild('dragDrop', { static: true }) private dragDrop: FileDragDropComponent;
    private subscriptions: Subscription = new Subscription();

    // bind "this" to the drop handler so context is not lost to the dragDrop
    private dropHandler: Function = ((fileList: FileList) => {
        this.receiveFileList(fileList);
    }).bind(this);

    constructor(
        private modalService: NexusModalService
    ) {
    }

    ngOnInit(): void {
        this.subscriptions.add(this.dragDrop.fileEmitter.subscribe(this.dropHandler));
        this.enableOk = this.files && (!this.showDatePicker || (this.showDatePicker && this.date)) ? true : false;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    receiveFileList(fileList: FileList) {
        let count = fileList.length;
        do {
            for (const fileIndex in fileList) {
                if (fileList[fileIndex]) {
                    --count;
                    if (parseInt(fileIndex, 10) > -1) {
                        if (!this.files || !this.isMultiSelect) {
                            this.files = [];
                        }
                        this.files.push(fileList[fileIndex]);
                    }

                    if (this.files && this.files.length > 0) {
                        this.filesAreSelected = true;
                    }
                }
            }
        } while (count > 0);

        const height = (this.files.length + 1) * 37;
        if (height <= 222) {
            this.gridHeight = height + 2;
        } else {
            this.gridHeight = 220;
        }
    }

    private destroy(): void {
        this.modalService.destroy();
    }
}
