import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TaxesGridFilterModel, TaxJurisdictionQualifierModel, TaxModel, TaxWithholdingTypeModel } from 'app/nexus-shared/domain/taxes/models';
import { environment } from 'environments/environment';
import { BaseHttpService } from 'app/nexus-core/services/index';
import { TaxSpecializationModel } from 'app/nexus-shared/domain/taxes/models/tax-specialization.model';

@Injectable()
export class TaxService {
    private withholdingTypes: Observable<TaxWithholdingTypeModel[]>;
    private qualifiers: Observable<TaxJurisdictionQualifierModel[]>;

    constructor(private baseHttpService: BaseHttpService) {
    }

    create(tax: TaxModel): Observable<TaxModel> {
        return this.baseHttpService.post(`${this.apiUrl()}taxes`, tax);
    }

    delete(id: number): Observable<boolean> {
        return this.baseHttpService.delete(`${this.apiUrl()}taxes/${id}`);
    }

    getTaxesByFilter(filter: TaxesGridFilterModel): Observable<TaxModel[]> {
        return this.baseHttpService.post(`${this.apiUrl()}taxes/filtered`, filter);
    }

    getTaxById(id: number): Observable<TaxModel> {
        return this.baseHttpService.get(`${this.apiUrl()}taxes/${id}`);
    }

    specializations(): Observable<TaxSpecializationModel[]> {
        return this.baseHttpService.get(`${this.apiUrl()}taxes/specializations`);
    }

    update(tax: TaxModel): Observable<TaxModel> {
        return this.baseHttpService.put(`${this.apiUrl()}taxes`, tax);
    }

    private apiUrl = () => environment().webAPIUrls.taxes;
}
