export enum AgreementStatusEnum {
    None = 0,
    Draft = 1,
    PendingApproval = 2, // TODO - Determine if this should be removed
    Approved = 3,
    PendingSignature = 4, // TODO - Determine if this should be removed
    Expired = 5,
    Signed = 6,
    Declined = 7, // TODO - Determine if this should be removed
    Superseded = 8, // TODO - Determine if this should be removed
    Revoked = 9,
    Cancelled = 10, // TODO - Determine if this should be removed
    RevokeRequested = 11,
    PendingReview = 12, // TODO - Determine if this should be removed
    NotApproved = 13 // TODO - Determine if this should be removed
}
