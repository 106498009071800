import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LocationTypesEnum } from 'app/nexus-shared/domain/locations/index';
import { BaseLocationSearchDirective } from 'app/nexus-shared/domain/locations/components/search/base-location-search.directive';

@Component({
    selector: 'gtn-city-search',
    templateUrl: './location-search.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CitySearchComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => CitySearchComponent),
            multi: true
        }
    ]
})
export class CitySearchComponent extends BaseLocationSearchDirective {
    @Input() label: string = 'City';
    @Input() placeholder: string = 'Enter city name';

    public locationType = LocationTypesEnum.City;
}
