export class UsersRoutingKeyConstants {
    // query param keys

    // MOVE INTO BELOW OBJECTS WHICH ARE MORE MANAGABLE
    // route param keys
    public static userKey = 'user-key';

    static routeParamKeys = {
        userKey: 'user-key'
    };

    // MENU IDS
    static menuIds = {
        userDetailPage: 1,
        userInternalListPage: 2,
        userExternalListPage: 3
    };

    static pathConstants = {
        internal: 'internal',
        external: 'external'
    };
}
