import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectListInterface } from 'app/nexus-shared/interfaces';
import { BaseComponent } from '../base-component/base.component';
import { FilterAnimation } from 'app/nexus-shared/components/filter/filter.animation';

@Component({
    selector: 'gtn-filter-panel',
    templateUrl: './filter-panel.component.html',
    styleUrls: ['./filter-panel.component.scss'],
    animations: [
        FilterAnimation.animations
    ]
})
export class FilterPanelComponent extends BaseComponent {
    @Input() isFilterPanelCollapsed: boolean = true;
    @Output() isFilterPanelCollapsedChange: EventEmitter<boolean> = new EventEmitter();

    @Input() appliedFilters: SelectListInterface[] = [];
    @Input() showClose: boolean = true;
    @Input() showFilterButton: boolean = true;

    @Output() applyFilters: EventEmitter<void> = new EventEmitter();
    @Output() clearFilters: EventEmitter<void> = new EventEmitter();
    @Output() filterClick: EventEmitter<SelectListInterface> = new EventEmitter();
    @Output() removeFilter: EventEmitter<SelectListInterface> = new EventEmitter();

    onApplyFiltersClicked() {
        this.applyFilters.emit();
        this.isFilterPanelCollapsed = true;
        this.isFilterPanelCollapsedChange.emit(this.isFilterPanelCollapsed);
    }

    onClearFiltersClicked() {
        this.clearFilters.emit();
    }

    onToggleFilterPanel() {
        this.isFilterPanelCollapsed = !this.isFilterPanelCollapsed;
        this.isFilterPanelCollapsedChange.emit(this.isFilterPanelCollapsed);
    }

    onFilterClicked(filter: SelectListInterface) {
        this.filterClick.emit(filter);
        this.isFilterPanelCollapsed = false;
        this.isFilterPanelCollapsedChange.emit(this.isFilterPanelCollapsed);
    }

    onRemoveFilterClicked(filter: SelectListInterface) {
        this.removeFilter.emit(filter);
    }
}
