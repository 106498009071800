import { UserInputTypesEnum } from 'app/nexus-shared/domain/questionnaires/enums/user-input-types.enum';
import { SectionColumnTypesEnum } from 'app/nexus-shared/domain/questionnaires/enums/section-column-types.enum';
import { TableColumnHeaderModel } from 'app/nexus-shared/domain/questionnaires/models/table-column-header.model';
import { SectionColumnActionModel } from 'app/nexus-shared/domain/questionnaires/models/section-column-action.model';
import { TableRowModel } from 'app/nexus-shared/domain/questionnaires/models/table-row.model';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { Guid } from 'guid-typescript';

export class SectionColumnModel {
    key: string;
    type: SectionColumnTypesEnum;
    order: number;
    text: string;

    additionalInfo: string;
    isBoldText: boolean;
    isItalicsText: boolean;
    isRedText: boolean;
    isUnderlineText: boolean;

    userInputType: UserInputTypesEnum;
    dropdownTypeId?: number;
    action: SectionColumnActionModel;
    value: string;

    widthPercentage: number;
    thirdPartyKey: string;

    tableColumnHeaders: TableColumnHeaderModel[] = [];
    tableRowHeaders: string;
    tableRows: TableRowModel[] = [];
    isEditableRows: boolean;

    constructor(model: SectionColumnModel = null) {
        this.key = model?.key ?? Guid.create().toString();
        this.type = model?.type ?? null;
        this.order = model?.order ?? null;
        this.text = model?.text ?? null;
        this.additionalInfo = model?.additionalInfo ?? null;
        this.isBoldText = model?.isBoldText ?? null;
        this.isItalicsText = model?.isItalicsText ?? null;
        this.isRedText = model?.isRedText ?? null;
        this.isUnderlineText = model?.isUnderlineText ?? null;
        this.userInputType = model?.userInputType ?? null;
        this.dropdownTypeId = model?.dropdownTypeId ?? null;
        this.action = model?.action ? new SectionColumnActionModel(model.action) : null;
        this.value = model?.value ?? null;
        this.widthPercentage = model?.widthPercentage ?? null;
        this.thirdPartyKey = model?.thirdPartyKey ?? null;
        this.tableColumnHeaders = model?.tableColumnHeaders?.length ? model.tableColumnHeaders.map(x => new TableColumnHeaderModel(x)) : [];
        this.tableRowHeaders = model?.tableRowHeaders ?? null;
        this.tableRows = model?.tableRows?.length ? model.tableRows.map(x => new TableRowModel(x)) : [];
        this.isEditableRows = model?.isEditableRows ?? null;
    }
}


export class SectionColumnFormControlNames {
    key: string = ObjectHelper.nameOf<SectionColumnModel>('key');
    type: string = ObjectHelper.nameOf<SectionColumnModel>('type');
    order: string = ObjectHelper.nameOf<SectionColumnModel>('order');
    text: string = ObjectHelper.nameOf<SectionColumnModel>('text');

    additionalInfo: string = ObjectHelper.nameOf<SectionColumnModel>('additionalInfo');
    isBoldText: string = ObjectHelper.nameOf<SectionColumnModel>('isBoldText');
    isItalicsText: string = ObjectHelper.nameOf<SectionColumnModel>('isItalicsText');
    isRedText: string = ObjectHelper.nameOf<SectionColumnModel>('isRedText');
    isUnderlineText: string = ObjectHelper.nameOf<SectionColumnModel>('isUnderlineText');

    userInputType: string = ObjectHelper.nameOf<SectionColumnModel>('userInputType');
    dropdownTypeId?: string = ObjectHelper.nameOf<SectionColumnModel>('dropdownTypeId');
    action: string = ObjectHelper.nameOf<SectionColumnModel>('action');
    value: string = ObjectHelper.nameOf<SectionColumnModel>('value');

    widthPercentage: string = ObjectHelper.nameOf<SectionColumnModel>('widthPercentage');
    thirdPartyKey: string = ObjectHelper.nameOf<SectionColumnModel>('thirdPartyKey');

    tableColumnHeaders: string = ObjectHelper.nameOf<SectionColumnModel>('tableColumnHeaders');
    tableRowHeaders: string = ObjectHelper.nameOf<SectionColumnModel>('tableColumnHeaders');
    tableRows: string = ObjectHelper.nameOf<SectionColumnModel>('tableRows');
    isEditableRows: string = ObjectHelper.nameOf<SectionColumnModel>('isEditableRows');
}
