<form [formGroup]="formGroupRef">
    <div class="flex-row-start flex-wrap">
        <ng-container *ngIf="!isEditMode">
            <div class="small-column flex-column-center mr-3">
                <div class="flex-row-start ">
                <span *ngIf="!isTemplateMode" class="mr-1" (click)="isTemplateMode = true;"><i
                    class="fa fa-edit"></i></span>
                    <span *ngIf="isTemplateMode" class="mr-1" (click)="isTemplateMode = false;"><i
                        class="fa fa-eye"></i></span>
                </div>
            </div>
        </ng-container>
        <gtn-input *ngIf="isTemplateMode" class="medium-column" label="Section title"
            formControlName="title"></gtn-input>
        <div *ngIf="!isTemplateMode" class="flex-grow align-center section-title">
            {{ formGroupRef.value?.title }}
        </div>
    </div>

    <ng-container *ngIf="sectionRowFormArray?.controls?.length">
        <ng-container *ngIf="!isEditMode">

            <div cdkDropList (cdkDropListDropped)="onRowDrop($event)">
                <ng-container *ngFor="let formGroup of sectionRowFormArray.controls; index as i; last as isLast">
                    <div class="flex-row-start mb-2" cdkDrag [cdkDragDisabled]="selectedRowEditIndex === i">
                        <!--            example for rima, maybe a template-->
                        <div *cdkDragPreview style="height:30px;width:500px;background:rgba(255,255,255,0.5)">
                            <span class="ml-5"> Row {{ i + 1 }}</span>
                        </div>
                        <div class="small-column flex-column-center mr-3"
                            [ngClass]="{'flex-column-start': selectedRowEditIndex === i}">
                            <div class="flex-row-start">
                            <span *ngIf="selectedRowEditIndex !== i" class="mr-1"><i
                                class="fa-solid fa-bars"></i></span>
                                <span *ngIf="selectedRowEditIndex !== i" class="mr-1 ml-1"
                                    (click)="selectedRowEditIndex = i;"><i
                                    class="fa fa-edit"></i></span>
                                <span *ngIf="selectedRowEditIndex === i" class="mr-1"
                                    [ngClass]="{'ml-1': selectedRowEditIndex !== i}"
                                    (click)="selectedRowEditIndex = null;"><i
                                    class="fa fa-eye"></i></span>
                                <span class="ml-1 mr-1" (click)="onCloneRowClicked(formGroup)"><i
                                    class="fa-regular fa-copy"></i></span>
                            </div>
                        </div>
                        <div class="flex-grow" [ngClass]="{'template-edit': selectedRowEditIndex === i}">
                            <gtn-section-row-form [formGroupRef]="getFormGroupFromAbstractControl(formGroup)"
                                [isSubForm]="true" [sectionKey]="sectionKey"
                                [isTemplateMode]="selectedRowEditIndex === i"
                                [isEditMode]="isEditMode"
                                [questionnaireDropdownService]="questionnaireDropdownService"
                                (cloneRowClick)="onCloneRowClicked(formGroup)"></gtn-section-row-form>
                        </div>

                        <div class="small-column flex-column-center"
                            [ngClass]="{'flex-column-start': selectedRowEditIndex === i}">
                            <span class="ml-2" (click)="onDeleteRowClicked(i)"><i class="fa fa-trash"></i></span>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="isEditMode">
            <ng-container *ngFor="let formGroup of sectionRowFormArray.controls; index as i; last as isLast">
                <div class="flex-grow">
                    <gtn-section-row-form [formGroupRef]="getFormGroupFromAbstractControl(formGroup)"
                        [isSubForm]="true" [sectionKey]="sectionKey" [isTemplateMode]="selectedRowEditIndex === i"
                        [isEditMode]="isEditMode"
                        [questionnaireDropdownService]="questionnaireDropdownService"
                        (cloneRowClick)="onCloneRowClicked(formGroup)"></gtn-section-row-form>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>
    <div class="row" *ngIf="!isEditMode">
        <div class="col-12">
            <button (click)="onAddRowClicked()" class="mr-2 mt-2" color="primary"
                mat-flat-button matTooltip="Add">
                Add Row
            </button>
        </div>
    </div>
</form>
