<gtn-template-grid [showExportButton]="true"
    (exportButtonClick)="onExportButtonClicked()"
    (quickFilterTextChange)="onFilterTextChanged($event)">
    <gtn-template-grid-controls-left>
        <div class="document-button-actions">
            <button (click)="onCreateNewFolderClicked()"
                class="mr-2"
                color="default"
                mat-mini-fab
                matTooltip="New folder"
                [disabled]="isClientRootFolder">
                <mat-icon>create_new_folder</mat-icon>
            </button>
            <button (click)="onAddButtonClicked()"
                class="mr-2"
                color="default"
                mat-mini-fab
                matTooltip="Upload file(s)"
                [disabled]="isClientRootFolder">
                <mat-icon>note_add</mat-icon>
            </button>
            <button *ngIf="showFolderTagIcon"
                class="mr-2"
                color="default"
                mat-mini-fab
                matTooltip="Tag client folder"
                (click)="folderTagClick.emit()">
                <mat-icon>loyalty</mat-icon>
            </button>
            <button *ngIf="documentsUserAuth.writeAccess"
                (click)="onCheckoutDocuments()"
                [disabled]="!isCheckoutDocuments"
                class="mr-2"
                color="default"
                mat-mini-fab
                matTooltip="Check out file(s)">
                <mat-icon>task</mat-icon>
            </button>
            <button (click)="onCopyDocuments()"
                [disabled]="!isDocumentsSelected"
                class="mr-2"
                color="default"
                mat-mini-fab
                matTooltip="Copy file(s)">
                <mat-icon>copy_all</mat-icon>
            </button>
            <button (click)="onPasteClicked()"
                [disabled]="!isEntityCopied"
                class="mr-2"
                color="default"
                mat-mini-fab
                matTooltip="Paste file(s)">
                <mat-icon>file_copy</mat-icon>
            </button>
            <button *ngIf="documentsUserAuth.writeAccess"
                (click)="onDeleteDocuments()"
                [disabled]="!isCheckoutDocuments"
                class="mr-2"
                color="default"
                mat-mini-fab
                matTooltip="Delete file(s)">
                <mat-icon>delete</mat-icon>
            </button>
            <button (click)="onDownloadMultipleClicked()"
                [disabled]="!isDocumentsSelected"
                class="mr-2"
                color="default"
                mat-mini-fab
                matTooltip="Download file(s)">
                <mat-icon>file_download</mat-icon>
            </button>
        </div>
    </gtn-template-grid-controls-left>
    <gtn-template-grid-content>
        <div class="drag-container"
            gtnDragAndDrop
            [isDebounceDragLeave]="true"
            [disableDragAndDropEvents]="disableDragAndDrop || isClientAreaFolder"
            (filesDropped)="onExternalFileDrop($event)"
            (dragHoverChange)="isDragHover = $event"
            [ngClass]="{'drag-file-over': isDragHover}">
            <ag-grid-angular [gtnHeight]="gridHeight"
                *ngIf="gridOptions"
                class="ag-theme-balham customized-theme"
                [gridOptions]="gridOptions"></ag-grid-angular>
        </div>
    </gtn-template-grid-content>
</gtn-template-grid>
<gtn-documents-confirm-modal *ngIf="showConfirmModal"
    [confirmActionsEnum]="confirmActionType"
    [confirmActionEntities]="confirmActionEntities"
    (cancelClick)="onCancelClicked()"
    (confirmClick)="onConfirmClicked()"></gtn-documents-confirm-modal>
<gtn-file-view *ngIf="showPreview"
    [enableDownload]="true"
    [enablePrinting]="true"
    [enableSearch]="true"
    [file]="filePreview"
    (close)="onPreviewClosed()"
    (download)="onPreviewDownloadClicked()">
    <gtn-file-view-header-controls>
        <div class="icon-button-fa"
            (click)="onPreviewCheckoutClicked()"
            matTooltip="Check out">
            <i class="fas fa-file-check"></i>
        </div>
    </gtn-file-view-header-controls>
</gtn-file-view>
<div @fadeIn
    class="drag-drop-container"
    *ngIf="isDragHover">
    <div class="drag-icon">
        <i class="fa fa-upload"></i>
    </div>
    <div class="drag-text">
        <div>Upload files to</div>
        <div class="folder-name"><i class="fa fa-folder mr-1"></i>{{ selectedFolder.name }}</div>
    </div>
</div>
