import { AgreementsVendorTypeEnum, IAgreementsVendorSearchModelInterface } from "..";

export class AgreementsVendorSearchModel implements IAgreementsVendorSearchModelInterface {
    agreementsVendorType: AgreementsVendorTypeEnum;
    companyKeys?: string[];
    individualKeys?: string[];

    constructor(model: AgreementsVendorSearchModel | IAgreementsVendorSearchModelInterface = null) {
        this.agreementsVendorType = model?.agreementsVendorType ?? AgreementsVendorTypeEnum.Unknown;
        this.companyKeys = model?.companyKeys ?? null;
        this.individualKeys = model?.individualKeys ?? null;
    }
}
