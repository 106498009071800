<mat-form-field [ngClass]="{'gtn-search-box': !useDefaultStyling}"
    [appearance]="appearance">
    <mat-label *ngIf="label">{{ label }}</mat-label>
    <input gtnDisableAutofill type="text" #searchAnchor matInput [matAutocomplete]="searchAutocomplete"
        [formControl]="formControl" (focus)="onFocus($event.target['value'])"
        (input)="searchTextValueChange($event.target['value'])"
        [placeholder]="placeholderText"/>
    <i class="fa fa-times clear-button" mat-icon-button matSuffix
        *ngIf="showClearButton && showSelectedValue && value && !readonly"
        (click)="onClearClicked()"></i>
    <mat-autocomplete #searchAutocomplete="matAutocomplete" [displayWith]="displayFn" panelWidth="auto"
        (optionSelected)="optionSelected($event)">
        <div *ngIf="showSearchResults && searchResults?.length > 0">
            <mat-option *ngFor="let result of searchResults; let i=index" tabindex="{{i}}" [value]="result">
                <div>
                    <i class="search-icon" [ngClass]="result.iconClass.toString()"></i>
                    <span>{{ result.name }}</span>
                </div>
                <div class="sub-name" *ngIf="result.subName">
                    {{ result.subName }}
                </div>
            </mat-option>
            <mat-option *ngIf="showAddLink" (click)="onAddClicked()">
                <a href="javascript:void(0)">{{ primaryAddLabel }}</a>
            </mat-option>
            <mat-option *ngIf="showSecondaryAddLink" (click)="onSecondaryAddClicked()">
                <a href="javascript:void(0)">{{ secondaryAddLabel }}</a>
            </mat-option>
        </div>
        <div *ngIf="!isLoading && !searchResults?.length">
            <mat-option *ngIf="!showAddLink && !defaultSearchResults?.length">
                No records.
            </mat-option>
            <mat-option *ngIf="showAddLink" (click)="onAddClicked()">
                <a href="javascript:void(0)">{{ primaryAddLabel }}</a>
            </mat-option>
            <mat-option *ngIf="showSecondaryAddLink" (click)="onSecondaryAddClicked()">
                <a href="javascript:void(0)">{{ secondaryAddLabel }}</a>
            </mat-option>
        </div>
        <div *ngIf="!isLoading && defaultSearchResults?.length">
            <mat-option *ngFor="let result of defaultSearchResults; let i=index" tabindex="{{i}}" [value]="result">
                <div>
                    <i class="search-icon" [ngClass]="result.iconClass.toString()"></i>
                    <span>{{ result.name }}</span>
                </div>
                <div class="sub-name" *ngIf="result.subName">
                    {{ result.subName }}
                </div>
            </mat-option>
        </div>
        <mat-option *ngIf="isLoading">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
