export class ObjectHelper {
    static nameOf = <T>(name: keyof T) => name;
    static nameOfSubProperty = <T1, T2>(name: keyof T1, name2: keyof T2) => `${name.toString()}.${name2.toString()}`;
    static nameOfSubProperties = <T1, T2, T3>(name: keyof T1, name2: keyof T2, name3: keyof T3) => `${name.toString()}.${name2.toString()}.${name3.toString()}`;
    static nameOfThreeSubProperties = <T1, T2, T3, T4>(name: keyof T1, name2: keyof T2, name3: keyof T3, name4: keyof T4) => `${name.toString()}.${name2.toString()}.${name3.toString()}.${name4.toString()}`;
    static nameOfFourSubProperties = <T1, T2, T3, T4, T5>(name: keyof T1, name2: keyof T2, name3: keyof T3, name4: keyof T4,  name5: keyof T5) => `${name.toString()}.${name2.toString()}.${name3.toString()}.${name4.toString()}.${name5.toString()}`;

    static getValueOfProperty<T>(object: any, propertyNames: string): T {
        return propertyNames.split('.').reduce((prev: string, curr: string) => {
            return prev ? prev[curr] : null;
        }, object || self);
    }

    static isNotNullOrUndefined<T>(value: T): boolean {
        return !this.isNullOrUndefined(value);
    }

    static isNullOrUndefined<T>(value: T): boolean {
        return value === null || value === undefined;
    }
}
