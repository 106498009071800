<gtn-template-card [showHeader]="true">
    <gtn-template-card-header>
        Dropdowns <span class="pointer" (click)="onAddClicked()"><i class="fa fa-add"></i></span>
    </gtn-template-card-header>
    <gtn-template-card-content>
        <ng-container *ngFor="let dropdown of dropdowns | sortBy: 'label' ">
            <div class="div-link" [ngClass]="{'selected': value?.id === dropdown.id}"
                (click)="onDropdownClicked(dropdown)">
                {{ dropdown.label || noValueText}}
            </div>
        </ng-container>
    </gtn-template-card-content>
</gtn-template-card>
