import { ContactIntegrationTypesEnum } from '../enums/contact-integration-types.enum';
import { CommonBaseAuditModel } from 'app/nexus-shared/domain/contacts/models/common-base-audit.model';

export class ContactIntegrationModel extends CommonBaseAuditModel {
    contactIntegrationKey: string;
    companyKey: string;
    individualKey: string;
    integrationValue: string;
    type: ContactIntegrationTypesEnum;
    constructor(model: ContactIntegrationModel = null) {
        super(model);
        this.contactIntegrationKey = model?.contactIntegrationKey ?? null;
        this.companyKey = model?.companyKey ?? null;
        this.individualKey = model?.individualKey ?? null;
        this.integrationValue = model?.integrationValue ?? null;
        this.type = model?.type ?? null;
    }
}
