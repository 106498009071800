import { ComponentFactoryResolver, Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, Renderer2, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingOverlayComponent } from '../components/loading-overlay/loading-overlay.component';
import { SimpleChangesTyped } from '../models/simple-changes-typed.type';
import { LoadingOverlayLoadingType } from '../types';

@Directive({
    selector: '[gtnLoadingOverlay]'
})
export class LoadingOverlayDirective implements OnInit, OnChanges, OnDestroy {
    @Input() gtnLoadingOverlay: boolean;

    /**
     * Sets loading overlay mode
     * - use LoadingOverlyLoadingModeConstants
     */
    @Input() overlayLoadingMode: LoadingOverlayLoadingType = 'indeterminate';

    /**
     * Determinate progress bar value. Update to show progress.
     */
    @Input() progressBarValue: number;

    /**
     * Pass true if used on a form field
     */
    @Input() isMatFormField: boolean;

    /**
     * Pass true if used in a mat card
     */
    @Input() isMatCard: boolean;

    /**
     * Message to be shown with loading indicator
     */
    @Input() loadingMessage: string;

    /**
     * Set background color
     * - default white
     */
    @Input() overlayBackgroundColor: 'white' | 'gray' = 'white';

    loadingOverlay: LoadingOverlayComponent;

    private width: number;
    private parentElementRef: HTMLElement;
    private progressBar: HTMLElement;
    private resizeObserver = new ResizeObserver(entries => {
        window.requestAnimationFrame(() => {
            if (!Array.isArray(entries) || !entries.length) {
                return;
            }

            this.handleParentResize(entries);
        });
    });
    private subscriptions: Subscription = new Subscription();

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private viewContainerRef: ViewContainerRef,
    ) {
    }

    ngOnInit() {
        if (!this.loadingOverlay) {
            this.createComponent();
        }
    }

    ngOnChanges(changes: SimpleChangesTyped<this>) {
        if (changes?.gtnLoadingOverlay != null && typeof changes?.gtnLoadingOverlay !== 'undefined') {
            if (changes.gtnLoadingOverlay.currentValue) {
                if (!this.loadingOverlay) {
                    this.createComponent();
                    // slight delay to ensure consistency
                    setTimeout(() => {
                        this.showComponent(true);
                    }, 5);
                } else {
                    // slight delay to ensure consistency
                    setTimeout(() => {
                        this.showComponent(true);
                    }, 5);
                }
            } else if (this.loadingOverlay?.isVisible) {
                this.showComponent(false);
            }
        }

        if (changes?.overlayBackgroundColor && this.loadingOverlay) {
            this.loadingOverlay.overlayBackgroundColor = this.overlayBackgroundColor;
        }
    }

    ngOnDestroy() {
        if (!this.isMatFormField && this.parentElementRef) {
            this.resizeObserver.unobserve(this.parentElementRef);
        }

        this.subscriptions.unsubscribe();
    }

    private createComponent() {
        const factory = this.componentFactoryResolver.resolveComponentFactory(LoadingOverlayComponent);
        const componentRef = this.viewContainerRef.createComponent(factory);
        this.loadingOverlay = componentRef.instance;
        this.loadingOverlay.mode = this.overlayLoadingMode;
        this.loadingOverlay.value = this.progressBarValue;
        this.loadingOverlay.message = this.loadingMessage;
        this.loadingOverlay.overlayBackgroundColor = this.overlayBackgroundColor;
        this.subscriptions.add(this.loadingOverlay.isPainted.subscribe(this.onLoadingOverlayPainted.bind(this)));
    }

    private getParentDimensions(parentElement: HTMLElement): { height: number, width: number } {
        let output = { height: 0, width: 0 };

        if (parentElement.offsetHeight) {
            output = { height: parentElement.offsetHeight, width: parentElement.offsetWidth + 2 };
        } else {
            const childCount = parentElement.childElementCount,
                children = parentElement.children;


            for (let i = 0; i < childCount; i++) {
                const childElement: HTMLElement = children[i] as HTMLElement;

                if (childElement.offsetHeight) {
                    output = { height: childElement.offsetHeight, width: childElement.offsetWidth + 2 };
                    break;
                }
            }
        }

        return output;
    }

    private handleParentResize(entries: ResizeObserverEntry[]) {
        if (this.loadingOverlay) {
            const contentRect = entries[0].contentRect;

            this.setWidth(contentRect.width);
            this.setHeight(contentRect.height);

            this.setTopPadding(contentRect.height);
        }
    }

    // this is required for proper progress bar sizing
    // this fires after the rest of the loading component has been painted
    // allowing the progress bar to be accessible here
    private onLoadingOverlayPainted(isPainted: boolean) {
        if (isPainted) {
            if (this.isMatCard || this.overlayLoadingMode === 'skeleton') {
                this.setBorderRadius();
            }

            if (!this.progressBar) {
                this.progressBar = this.loadingOverlay.elementRef.nativeElement.getElementsByClassName('mat-progress-bar')[0] as HTMLElement;

                if (this.progressBar) {
                    this.setProgressBarWidth(this.width);
                }
            } else {
                this.setProgressBarWidth(this.width);
            }
        }
    }

    private setBorderRadius() {
        // TODO - handle these in a more dynamic way
        // had a hard time pulling these values out of the mat-card itself
        // should be able to target the parent element to get these values
        this.loadingOverlay.background.nativeElement.style['border-bottom-left-radius'] = '4px';
        this.loadingOverlay.background.nativeElement.style['border-bottom-right-radius'] = '4px';
        this.loadingOverlay.background.nativeElement.style['border-top-left-radius'] = '4px';
        this.loadingOverlay.background.nativeElement.style['border-top-right-radius'] = '4px';
    }

    private setHeight(height: number) {
        if (this.isMatCard) {
            height += 32.375;
        }

        this.loadingOverlay.elementRef.nativeElement.style.height = `${height}px`;
    }

    private setProgressBarWidth(width: number) {
        // don't do anything here if there isn't a progressBar, it's handled in the onLoadingOverlayPainted
        if (this.progressBar) {
            if (!this.isMatFormField) {
                this.progressBar.style.width = `${width - 30}px`;
                this.progressBar.style['margin-left'] = '15px';
            } else {
                this.progressBar.style.width = `${width}px`;
            }
        }
    }

    private setTopPadding(height) {
        if (this.overlayLoadingMode !== 'skeleton') {
            let paddingTop = height / 2;

            if (this.isMatFormField) {
                paddingTop = paddingTop + 10;
            }

            this.loadingOverlay.elementRef.nativeElement.style['padding-top'] = `${paddingTop}px`;
        }
    }

    private setWidth(width: number) {
        if (this.isMatCard) {
            width += 50;
        }

        this.width = width;

        // set the width to the dom element first
        this.loadingOverlay.elementRef.nativeElement.style.width = `${width}px`;

        if (this.overlayLoadingMode !== 'skeleton') {
            this.setProgressBarWidth(width);
        } else {
            // set the width to the overlay width property
            // this enables the calculation of the width from the skeleton animation width
            this.loadingOverlay.width = width;
        }
    }

    private showComponent(show: boolean) {
        if (this.isMatFormField) {
            if (this.overlayLoadingMode !== 'skeleton') {
                this.parentElementRef = this.elementRef.nativeElement.getElementsByClassName('mat-form-field')[0] as HTMLElement;
            } else {
                this.parentElementRef = this.elementRef.nativeElement.getElementsByClassName('mat-form-field-flex')[0] as HTMLElement;
            }
        } else {
            this.parentElementRef = this.elementRef.nativeElement as HTMLElement;
            this.resizeObserver.observe(this.parentElementRef);
        }

        if (show) {
            this.elementRef.nativeElement.style.position = 'relative';

            const parentDimensions = this.getParentDimensions(this.parentElementRef);

            this.setWidth(parentDimensions.width);
            this.setHeight(parentDimensions.height);
            this.setTopPadding(parentDimensions.height);
            this.loadingOverlay.elementRef.nativeElement.style.position = 'absolute';
            this.loadingOverlay.elementRef.nativeElement.style.top = 0;
            this.loadingOverlay.elementRef.nativeElement.style.left = 0;
            this.loadingOverlay.isVisible = true;
            this.renderer.appendChild(this.parentElementRef, this.loadingOverlay.elementRef.nativeElement);
        } else {
            this.elementRef.nativeElement.style.position = 'unset';
            this.loadingOverlay.isVisible = false;
            this.renderer.removeChild(this.parentElementRef, this.loadingOverlay.elementRef.nativeElement);
            this.resizeObserver.unobserve(this.parentElementRef);
            this.loadingOverlay = null;
            this.progressBar = null;
            this.parentElementRef = null;
        }
    }
}
