import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { CalendarViewTypesEnum } from 'app/nexus-shared/enums/calendar-view-types.enum';
import { SelectListInterface } from 'app/nexus-shared/interfaces';
import { DateHelper, EnumHelper } from 'app/nexus-core';
import { CalendarSettingModel } from 'app/nexus-shared/domain/travel-calendar/models/calendar-setting.model';

@Component({
    selector: 'gtn-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent extends BaseComponent implements OnInit {
    @Input() calendarChangeInterval: 'month' | 'year' = 'year';
    @Input() calendarView: CalendarViewTypesEnum = CalendarViewTypesEnum.Month;
    @Input() calendarStartDate: Date = new Date(new Date().getFullYear(), 11, 31);
    @Input() templateRef: TemplateRef<any>;
    @Input() calendarSettings: CalendarSettingModel;
    @Input() maxCalendarDate: Date;
    @Input() minCalendarDate: Date;

    @Output() dayClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() calendarViewChange: EventEmitter<CalendarViewTypesEnum> = new EventEmitter<CalendarViewTypesEnum>();
    @Output() calendarDateChange: EventEmitter<Date> = new EventEmitter<Date>();

    calendarDate: Date;
    calendarViewTypes: SelectListInterface[];
    calendarViewTypesEnum = CalendarViewTypesEnum;
    showNext: boolean = true;
    showPrevious: boolean = true;
    month: number = new Date().getMonth();
    year: number = new Date().getFullYear();
    years: number[] = [];
    monthNames: string[] = DateHelper.getMonths();

    ngOnInit() {
        this.calendarDate = JSON.parse(JSON.stringify(this.calendarStartDate));
        this.calendarViewTypes = EnumHelper.convertToSelectList(CalendarViewTypesEnum, false, false, null, false);
        this.getStartingDays();
        this.getYears();
        this.checkMinMaxDates();
    }

    onViewTypeChanged(viewType: CalendarViewTypesEnum): void {
        this.calendarView = viewType;
        this.calendarViewChange.emit(this.calendarView);
        this.checkMinMaxDates();
    }

    onChangeMonthClicked(isNext: boolean): void {
        if (isNext) {
            this.month++;
        } else {
            this.month--;
        }
        if (this.month < 0 || this.month > 11) {
            // Set the date to the first day of the month with the new year
            // Possibly change to a specific day if we have a day view
            this.calendarDate = new Date(this.year, this.month, 1);
            // Set the year to the new year
            this.year = this.calendarDate.getFullYear();
            // Set the month to the new month
            this.month = this.calendarDate.getMonth();
        } else {
            this.calendarDate = new Date(this.year, this.month, 1);
        }

        if (!this.calendarDate) {
            //somehow theres no date?!?!
            this.calendarDate = new Date();
        }

        this.checkMinMaxDates();
        this.calendarDateChange.emit(this.calendarDate);
    }

    onChangeYearClicked(isNext: boolean) {
        if (isNext) {
            this.year++;
        } else {
            this.year--;
        }
        this.calendarStartDate = new Date(this.year, 0, 1);
        this.calendarDateChange.emit(this.calendarStartDate);
    }

    onMonthClicked(i: number): void {
        if (i !== this.month) {
            this.month = i;
            this.calendarDate = new Date(this.year, this.month, 1);
            this.calendarDateChange.emit(this.calendarDate);
        }
    }

    onYearClicked(year: number): void {
        if (year !== this.year) {
            this.year = year;
            this.calendarStartDate = new Date(this.year, 0, 1);
            this.calendarDateChange.emit(this.calendarStartDate);
        }
    }

    getStartingDays(): void {
        this.month = this.calendarStartDate.getMonth();
        this.year = this.calendarStartDate.getFullYear();
    }

    getYears(): void {
        this.years = [];
        if (this.minCalendarDate && this.maxCalendarDate) {
            const startYear: number = DateHelper.convertToFullUtcDate(this.calendarSettings.startDate).getUTCFullYear();
            const endYear: number = this.maxCalendarDate.getFullYear();
            for (let year: number = startYear; year <= endYear; year++) {
                this.years.push(year);
            }
        }
    }

    onNavigateToTodayClicked(): void {
        this.calendarView = this.calendarViewTypesEnum.Month;
        const today = new Date();
        this.year = today.getFullYear();
        this.month = today.getMonth();
        this.calendarDate = new Date(this.year, this.month, 1);
        this.calendarDateChange.emit(this.calendarDate);
        this.calendarViewChange.emit(this.calendarView);
    }

    private checkMinMaxDates(): void {
        const unit: 'M' | 'y' = this.calendarView === CalendarViewTypesEnum.Month ? 'M' : 'y';
        const calendarPrevDate = DateHelper.subtractDate(this.calendarDate, 1, unit);
        const calendarNextDate = DateHelper.addDate(this.calendarDate, 1, unit);
        this.showNext = DateHelper.isBetweenDates(this.minCalendarDate, this.maxCalendarDate, calendarNextDate);
        this.showPrevious = DateHelper.isBetweenDates(this.minCalendarDate, this.maxCalendarDate, calendarPrevDate);
    }
}
