import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';

@Component({
    selector: 'gtn-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent extends BaseComponent implements OnInit {
    @Input() label: string = 'Upload file';
    @Input() showFilesToBeUploaded = true;
    @Input() isMultiSelect: boolean = false;
    @Input() isUploadButton: boolean = true;
    @Input() isDragAndDrop: boolean = false;
    @Input() externalFiles: File[];

    @Output() fileChange: EventEmitter<File> = new EventEmitter<File>();
    @Output() filesChange: EventEmitter<File[]> = new EventEmitter<File[]>();

    private file: File;
    private files: File[] = [];
    fileToBeUploaded: string;
    filesToBeUploaded: string[] = [];

    ngOnInit(): void {
        if (this.externalFiles?.length) {
            this.files = this.externalFiles.map(x => x);
        }
    }

    onFileChanged(event): void {
        if (event.target.files && event.target.files.length && !this.isMultiSelect) {
            this.fileToBeUploaded = event.target.files[0].name;
            this.file = event.target.files[0];
            this.fileChange.emit(this.file);
        } else if (event.target.files && event.target.files.length && this.isMultiSelect) {
            this.handleFilesChanged(event.target.files);
        }
    }

    onDragAndDropFiles(event): void {
        this.handleFilesChanged(event);
    }

    onRemoveFileClicked(i: number = null): void {
        if (i >= 0 && this.isMultiSelect) {
            this.files.splice(i, 1);
            this.filesToBeUploaded.splice(i, 1);
            this.filesChange.emit(this.files);
        } else {
            this.fileToBeUploaded = null;
            this.fileChange.emit(null);
        }
    }

    private handleFilesChanged(files: File[]): void {
        for (let i = 0, numFiles = files.length; i < numFiles; i++) {
            this.files.push(files[i]);
            this.filesToBeUploaded.push(files[i].name);
        }
        if (!this.isMultiSelect) {
            this.fileChange.emit(this.files[0]);
            this.fileToBeUploaded = this.files[0].name;
        } else {
            this.filesChange.emit(this.files);
        }
    }
}
