import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { BaseFormComponent } from 'app/nexus-shared/components/base-component/base-form.component';
import { AddressModel } from 'app/nexus-shared/domain/contacts/models/address.model';
import { AddressTypeEnum } from 'app/nexus-shared/enums/address-type.enum';

@Component({
    selector: 'gtn-addresses-subform',
    templateUrl: './addresses-subform.component.html',
    styleUrls: ['./addresses-subform.component.scss']
})
export class AddressesSubformComponent extends BaseFormComponent<AddressModel[]> implements OnInit {
    @Input() formArray: UntypedFormArray;

    constructor() {
        super();
    }

    ngOnInit(): void {
        if (this.formArray?.length === 0) {
            this.initFormArrayFormGroup(new AddressModel(), this.formArray);
        }
    }

    onAddressTypeChanged(type: AddressTypeEnum): void {
        //handle only one type of eacha ddress
    }

    onRemoveAddressClicked(i: number): void {
        this.formArray.removeAt(i);
        this.formArray.markAsDirty();
        this.formArray.updateValueAndValidity();
    }
}
