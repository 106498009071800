import { Column, ColumnApi, GridApi, IHeaderParams } from 'ag-grid-community';
import { Dictionary } from 'app/nexus-shared/models/dictionary';

export class ReportHeaderParamsModel implements IHeaderParams {
    column: Column;
    displayName: string;
    enableSorting: boolean;
    enableMenu: boolean;
    showColumnMenu: (source: HTMLElement) => void;
    progressSort: (multiSort?: boolean) => void;
    setSort: (sort: string, multiSort?: boolean) => void;
    columnApi: ColumnApi;
    eGridHeader: HTMLElement;
    api: GridApi;
    context: any;
    template: string;
    styles: Dictionary<any>;
    height: number;
    propertyName: string;
}
