import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { AgGridHelper } from 'app/nexus-core/helpers';
import { GridComponent } from 'app/nexus-shared/components/controls/components/base-grid';
import { AuthorizationConstants } from 'app/nexus-shared/constants/authorization.constants';
import { AuthenticatedUserService } from 'app/nexus-core/services/authenticated-user.service';
import { BaseEnterpriseGridComponent } from 'app/nexus-shared/components/base-component/base-enterprise-grid.component';
import { ColDef } from 'ag-grid-community';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { IndividualSettingService } from 'app/nexus-core/services/domain/core/individual-setting.service';
import { AgGridColDefHelper } from 'app/nexus-core/helpers/ag-grid-col-def.helper';
import { UserImpersonationService } from 'app/nexus-core/services/domain/users/user-impersonation.service';
import { UserImpersonationModel } from 'app/nexus-shared/domain/users/models/user-impersonation.model';
import { CommonBaseAuditUserModel } from 'app/nexus-shared/domain/contacts/models/common-base-audit-user.model';

@Component({
    selector: 'gtn-user-impersonations-grid',
    templateUrl: '../../../../components/base-component/base-grid-template.component.html'
})
export class UserImpersonationsGridComponent extends BaseEnterpriseGridComponent<UserImpersonationModel> implements OnInit {
    static cellClickableClass = AgGridHelper.copyPasteCellClass;

    @Output() userImpersonationClick: EventEmitter<UserImpersonationModel> = new EventEmitter<UserImpersonationModel>();

    gridName: string = 'user_impersonations_grid';
    gridVersion: string = '1.0.0';
    isAdmin: boolean = false;

    @ViewChild('baseGrid') baseGridRef: GridComponent;

    constructor(
        protected individualSettingService: IndividualSettingService,
        private userImpersonationService: UserImpersonationService,
        private authenticatedUserService: AuthenticatedUserService,
    ) {
        super(individualSettingService);
    }

    ngOnInit() {
        this.isAdmin = this.authenticatedUserService.hasAuthorization(AuthorizationConstants.authUserImpersonation);
        this.canAdd = this.authenticatedUserService.hasAuthorization(AuthorizationConstants.authUserImpersonation);

        super.ngOnInit();
    }

    onRowClicked(row: any): void {
        if (row.event.target.classList.contains(UserImpersonationsGridComponent.cellClickableClass)) {
            return;
        }

        this.userImpersonationClick.emit(row.data);
    }

    refreshData(): void {
        this.baseGridRef.refreshData();
    }

    onSearchTextChanged(search: string): void {
        this.onFilterTextChanged(search);
    }

    setDefaultSortModel() {
        this.defaultSortModel = [
            { colId: ObjectHelper.nameOfSubProperty<UserImpersonationModel, CommonBaseAuditUserModel>('lastModifiedByUser', 'changeDateTime'), sort: 'asc', sortIndex: 0 },
        ];
    }

    protected setColumnDefinitions(): ColDef[] {

        return [
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOf<UserImpersonationModel>('userName'), 'Impersonation User'),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOf<UserImpersonationModel>('impersonationUserName'), 'User to Impersonate'),
            AgGridColDefHelper.getYesNoColDef(ObjectHelper.nameOf<UserImpersonationModel>('isActive'), 'Active'),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOf<UserImpersonationModel>('requestReason'), 'Request Reason'),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOf<UserImpersonationModel>('changeNotes'), 'Change Notes')
        ];
    }

    protected setRowData(): Observable<UserImpersonationModel[]> {
        return this.userImpersonationService.search();
    }
}

