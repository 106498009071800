export class SpinnerMessageModel {
    index: number;
    text: string;

    constructor(
        text: string = '',
        index: number = 0
    ) {
        this.text = text;
        this.index = index;
    }
}
