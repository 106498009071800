import { Injectable } from '@angular/core';
import { BaseService } from 'app/nexus-core/services/base-service.directive';
import { environment } from 'environments/environment';
import { BaseHttpService } from 'app/nexus-core/services/base-http.service';
import { Observable } from 'rxjs';
import { IndividualSupplementalOrganizerModel } from 'app/nexus-shared/domain/questionnaires/models/individual-supplemental-organizer.model';
import { BaseQuestionnaireSearchModel } from 'app/nexus-shared/domain/questionnaires/models/base-questionnaire-search.model';

@Injectable()
export class IndividualSupplementalOrganizerService extends BaseService {
    private apiUrl = environment().webAPIUrls.questionnaires + 'individual-supplemental-organizers/';

    constructor(private baseHttpService: BaseHttpService) {
        super();
    }

    get(individualSupplementalOrganizerKey: string): Observable<IndividualSupplementalOrganizerModel> {
        return this.baseHttpService.get(`${this.apiUrl}${individualSupplementalOrganizerKey}`);
    }

    search(search: BaseQuestionnaireSearchModel): Observable<IndividualSupplementalOrganizerModel[]> {
        return this.baseHttpService.post(`${this.apiUrl}search`, search);
    }

    create(model: IndividualSupplementalOrganizerModel): Observable<string> {
        return this.baseHttpService.post(this.apiUrl, model);
    }

    update(model: IndividualSupplementalOrganizerModel): Observable<boolean> {
        return this.baseHttpService.put(this.apiUrl, model);
    }
}
