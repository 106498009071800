import { Component, Input } from '@angular/core';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';

@Component({
    selector: 'gtn-email',
    templateUrl: './email.component.html',
    styleUrls: ['./email.component.scss']
})
export class EmailComponent extends BaseComponent {
    @Input() email: string;
    @Input() icon: string;
    @Input() text: string;
}
