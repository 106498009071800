<div class="folder-breadcrumbs" [ngClass]="{'documents-margin': isDocuments}">
    <ng-container *ngFor="let breadcrumb of folderBreadcrumbs; last as isLast; first as isFirst">
        <div class="folder-breadcrumb" [ngClass]="{'active': isLast}"
            (click)="onBreadcrumbClicked(breadcrumb)">
            <span *ngIf="!isFirst || !replaceRootWithIcon">{{ breadcrumb.name }}</span>
            <span *ngIf="isFirst && replaceRootWithIcon" [innerHTML]="breadcrumb.name"></span>
            <span *ngIf="breadcrumb?.folder?.tags?.length" [matTooltip]="breadcrumb.tags">&nbsp;<i
                class="fas fa-tag"></i></span>
        </div>
        <span *ngIf="!isLast"><i class="fa-duotone fa-chevron-right"></i></span>
    </ng-container>
</div>
<div class="corresponding-individual">
    <ng-container *ngIf="correspondingFolders?.length && selectedFolder?.clientFolder.individualKey">
        <ng-container *ngFor="let folder of correspondingFolders; last as isLast;">
            <ng-container *ngIf="!folder?.clientFolder?.companyKey">
                <div (click)="onNavigateToIndependent(folder)" class="folder-link"><span><i
                    class="fas fa-person-falling mr-1"></i></span>Independent
                </div>
            </ng-container>
            <ng-container *ngIf="folder?.clientFolder?.companyKey">
                <div (click)="onNavigateToMobileEmployee(folder)" class="folder-link">
                    <span><i class="fas fa-person-running mr-1"></i></span>{{ folder.clientFolder.companyName }}
                </div>
            </ng-container>
        </ng-container>
    </ng-container>
</div>
