import { SelectListInterface } from 'app/nexus-shared/index';
import { ControlTypeEnum } from 'app/nexus-shared/components/dynamic-form/shared/enums/control-type.enum';
import { DynamicFormFieldDataDefinitionTypeEnum } from 'app/nexus-shared/components/dynamic-form/shared/enums/dynamic-form-field-data-definition-type.enum';

export interface DynamicFormFieldConfig {
    controlType: ControlTypeEnum;
    labelText: string;
    dataDefinitionType: DynamicFormFieldDataDefinitionTypeEnum;
    customFieldOptions: SelectListInterface[];
    canShowValidation: boolean;
    disabled: boolean;
    focus: boolean;
    readonly: boolean;
    required: boolean;
    formControlName: string;
    idKey: string;
    displayKey: string;
    idOnly: boolean;
    controlComponent: any;
    classNames: string;
}
