import { BaseService } from 'app/nexus-core/services/base-service.directive';
import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IndividualSettingModel } from 'app/nexus-shared/models/individual-setting.model';
import { IndividualSettingSearchModel } from 'app/nexus-shared/models/individual-setting-search.model';
import { BaseHttpService } from 'app/nexus-core/services/base-http.service';

@Injectable()
export class IndividualSettingService extends BaseService {
    private apiUrl = `${environment().webAPIUrls.core}individual-settings`;

    constructor(private baseHttpService: BaseHttpService) {
        super();
    }

    get(id: number): Observable<IndividualSettingModel> {
        return this.baseHttpService.get(`${this.apiUrl}/id/${id}`);
    }

    search(searchModel: IndividualSettingSearchModel): Observable<IndividualSettingModel[]> {
        return this.baseHttpService.post(`${this.apiUrl}/search`, searchModel);
    }

    reset(models: IndividualSettingModel[]): Observable<number> {
        return this.baseHttpService.post(`${this.apiUrl}/reset`, models);
    }

    upsert(model: IndividualSettingModel): Observable<number> {
        return this.baseHttpService.post(`${this.apiUrl}`, model);
    }

    delete(id: number): Observable<IndividualSettingModel> {
        return this.baseHttpService.delete(`${this.apiUrl}/id/${id}`);
    }
}
