import { Directive, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class BaseService implements OnDestroy {
    protected subscriptions = new Subscription();

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
