import { AbstractControl, UntypedFormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { DateHelper } from './date.helper';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import { StringHelper } from './string.helper';
import { FileHelper } from 'app/nexus-core/helpers/file.helper';
import { StringConstants } from 'app/nexus-shared';

export class NexusValidatorHelper extends Validators {
    static currency(label?: string): ValidationErrors {
        return (control: UntypedFormControl): ValidationErrors | null => {
            const regex = new RegExp('(?=.*?\\d)^\\$?(([1-9]\\d{0,2}(,\\d{3})*)|\\d+)?(\\.\\d{1,2})?$');
            const inputValue = control.value;

            if (!label) {
                label = NexusValidatorHelper.getPrettyControlName(control);
            }

            return regex.test(inputValue) ? null : {
                currency: `${label ? label : 'Value'} is not valid currency.`
            };
        };
    }

    static getErrorMessage(control: AbstractControl, label: string = ''): string[] {
        const errors = control?.errors,
            errorMsg: string[] = [];

        if (!label) {
            label = NexusValidatorHelper.getPrettyControlName(control);
        }

        if (errors) {
            let count: number = Object.keys(errors).length;
            do {
                for (const key of Object.keys(errors)) {
                    --count;
                    let error = errors[key];
                    switch (key) {
                        case 'min':
                            errorMsg.push(`${label} minimum is ${error['min']}.`);
                            break;
                        case 'max':
                            errorMsg.push(`${label} maximum is ${error['max']}.`);
                            break;
                        case 'required':
                            errorMsg.push(`${label} is required.`);
                            break;
                        case 'requiredTrue':
                            errorMsg.push(`${label} is required.`);
                            break;
                        case 'email':
                            errorMsg.push(`${label} is formatted incorrectly.`);
                            break;
                        case 'minlength':
                            errorMsg.push(`${label} minimum length is ${error['requiredLength']}.`);
                            break;
                        case 'maxlength':
                            errorMsg.push(`${label} maximum length is ${error['requiredLength']}.`);
                            break;
                        case 'pattern':
                            errorMsg.push(`${label} does not conform to pattern: ${error}.`);
                            break;
                        default:
                            if (!error) {
                                error = `${label} is invalid.`;
                            }
                            errorMsg.push(`${error}`);
                            break;
                    }
                }
            } while (count > 0);
        }

        return errorMsg;
    }

    static defaultError(message: string = null): ValidatorFn {
        let init = true;
        return (control: AbstractControl): ValidationErrors | null => {
            if (init) {
                init = false;
                return { error: message ? message : 'This field is not valid.' };
            }
            return null;
        };
    }

    static gtnRequired(label: string = null): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            let errors = super.required(control);

            if (!label) {
                label = NexusValidatorHelper.getPrettyControlName(control);
            }

            if (errors && errors['required']) {
                errors = { 'gtnRequired': `${label ? label : 'Field'} is required.` };
            }

            return errors ? errors : null;
        };
    }


    static validateFileSize(): ValidatorFn {
        return (formControl: AbstractControl) => {
            const control = formControl as UntypedFormControl;
            if (control?.value >= FileHelper.getFileSizeLimit()) {
                return { fileSize: StringConstants.messages.invalidFileSize };
            }
            return null;
        };
    }

    static isInvalidDateRange(startDateControl: AbstractControl, endDateControl: AbstractControl, allowEqualDates = false): ValidatorFn {
        return () => {
            if (!startDateControl || !startDateControl.value || !endDateControl || !endDateControl.value) {
                return null;
            }

            if (!DateHelper.isValid(startDateControl.value) || !DateHelper.isValid(endDateControl.value)) {
                return null;
            }

            const startDateControlValue = startDateControl.value as Date;
            const endDateControlValue = endDateControl.value as Date;

            let isInvalid = endDateControl.value && startDateControl.value &&
                DateHelper.lessThan(endDateControlValue, startDateControlValue) || DateHelper.greaterThan(startDateControlValue, endDateControlValue);

            if (!isInvalid && !allowEqualDates) {
                isInvalid = DateHelper.areEqual(startDateControlValue, endDateControlValue);
            }

            if (isInvalid) {
                startDateControl.setErrors({ isInvalidDate: true });
                startDateControl.markAsTouched();
                endDateControl.setErrors({ isInvalidDate: true });
                endDateControl.markAsTouched();
                return { isInvalidDateRange: true };
            }

            startDateControl.setErrors(null);
            endDateControl.setErrors(null);
            return null;
        };
    }

    static gtnRequiredTrue(label?: string): ValidationErrors {
        return (control: AbstractControl): ValidationErrors | null => {
            let errors = super.requiredTrue(control);

            if (!label) {
                label = NexusValidatorHelper.getPrettyControlName(control);
            }

            if (errors && errors['required']) {
                errors = { 'gtnRequiredTrue': `${label ? label : 'Field'} is required.` };
            }

            return errors;
        };
    }

    static password(label?: string): any {
        return (control: UntypedFormControl): ValidationErrors => {
            if (!label) {
                label = NexusValidatorHelper.getPrettyControlName(control);
            }

            const strongRegex = new RegExp('(?=.*[A-Za-z])(?=.*[0-9])(?=.*[!@#\.\$%\^&\*])(?=.{8,})');
            return strongRegex.test(control.value) ? null : {
                password: `${label ? label : 'Value entered'} is not a valid password.`
            };
        };
    }

    static phoneNumberValidator(country: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            const regionCode: any = country ? country : 'US';
            let validNumber = false;
            try {
                const phoneNumber = parsePhoneNumber(control.value, regionCode);
                validNumber = isValidPhoneNumber(phoneNumber.number.toString(), regionCode);
            } catch (e) {
            }
            validNumber = control.value === null || control.value === '' ? true : validNumber;
            return validNumber ? null : { 'phoneNumber': 'Invalid number.' };
        };
    }

    static requiredIfOtherControlEqualTo(otherControl: AbstractControl, otherValue: any): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            return (otherControl.value === otherValue && !control.value) ? { requiredIfOtherControlEqualTo: true } : null;
        };
    }

    static notInArray(array: any[], message: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            return (control.value && array.find(x => DateHelper.areEqual(x, control.value))) ? { notInArray: message } : null;
        };
    }

    static validateMaxAggregateValue(controlNames: string[], maxValue: number) {
        return (c: AbstractControl) => {
            const group = c.parent;
            let aggregateValue = +c.value,
                prettyControlNames = '';

            for (const controlName of controlNames) {
                const prettyControlName = NexusValidatorHelper.getPrettyControlName(null, controlName);

                if (prettyControlNames.length) {
                    prettyControlNames += `, ${StringHelper.lowerCaseFirst(prettyControlName)}`;
                } else {
                    prettyControlNames += prettyControlName;
                }

                const control = group.controls[controlName];

                if (!Number.isNaN(control.value)) {
                    aggregateValue += +control.value;
                }
            }

            if (aggregateValue > maxValue) {
                return {
                    validateMaxAggregateValue: `Value combined with ${StringHelper.lowerCaseFirst(prettyControlNames)} maximum is ${maxValue}.`
                };
            } else {
                return null;
            }
        };
    }

    private static getPrettyControlName(control?: AbstractControl, controlName?: string): string {
        if (controlName === null || typeof controlName === 'undefined') {
            controlName = NexusValidatorHelper.getControlName(control);
        }

        const nameParts = controlName.split(/(?=[A-Z])/);

        let prettyControlName = '';

        for (const part of nameParts) {
            if (part === nameParts[0]) {
                if (prettyControlName.length) {
                    prettyControlName += `, ${StringHelper.upperCaseFirst(part)}`;
                } else {
                    prettyControlName += StringHelper.upperCaseFirst(part);
                }
            } else {
                prettyControlName += ` ${StringHelper.lowerCaseFirst(part)}`;
            }
        }

        return prettyControlName;
    }

    protected static getControlName(control: AbstractControl) {
        return (Object.keys(control.parent.controls).find(key => control.parent.controls[key] === control));
    }
}
