import { Injectable } from '@angular/core';
import { BaseHttpService } from 'app/nexus-core/services/base-http.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { BaseService } from 'app/nexus-core/services/base-service.directive';
import { QuestionnaireTemplateModel } from 'app/nexus-shared/domain/questionnaires/models/questionnaire-template.model';
import { QuestionnaireTemplateSearchModel } from 'app/nexus-shared/domain/questionnaires/models/questionnaire-template-search.model';

@Injectable()
export class QuestionnaireTemplatesService extends BaseService {
    private apiUrl = environment().webAPIUrls.questionnaires + 'questionnaire-templates/';

    constructor(private baseHttpService: BaseHttpService) {
        super();
    }

    get(questionnaireTemplateKey: string): Observable<QuestionnaireTemplateModel> {
        return this.baseHttpService.get(`${this.apiUrl}${questionnaireTemplateKey}`);
    }

    search(search: QuestionnaireTemplateSearchModel): Observable<QuestionnaireTemplateModel[]> {
        return this.baseHttpService.post(`${this.apiUrl}search`, search);
    }

    create(model: QuestionnaireTemplateModel): Observable<string> {
        return this.baseHttpService.post(this.apiUrl, model);
    }

    update(model: QuestionnaireTemplateModel): Observable<boolean> {
        return this.baseHttpService.put(this.apiUrl, model);
    }
}
