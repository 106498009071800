import { ModalOutputModel } from 'app/nexus-shared/components/modal/models/modal-output.model';

export class TimelineDetailModalOutputModel extends ModalOutputModel {
    eventDetailClick: Function;
    calculateDeliverableClick: Function;

    constructor(
        eventDetailClick: Function,
        calculateDeliverable: Function,
        okClick: Function = () => {
        }
    ) {
        super(
            okClick
        );

        this.eventDetailClick = eventDetailClick;
        this.calculateDeliverableClick = calculateDeliverable;
    }
}
