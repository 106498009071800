import { BaseEnterpriseGridComponent } from 'app/nexus-shared/components/base-component/base-enterprise-grid.component';
import { Component, Input } from '@angular/core';
import { IndividualSettingService } from 'app/nexus-core/services/domain/core/individual-setting.service';
import { BaseTravelCalendarSearchModel } from 'app/nexus-shared/domain/travel-calendar/models/base-travel-calendar-search.model';
import { ITravelCalendarReportServiceInterface } from 'app/nexus-core/services/domain/travel-calendar/itravel-calendar-report-service.interface';

@Component({
    template: ''
})
export abstract class BaseTravelCalendarReportingGridComponent<T> extends BaseEnterpriseGridComponent<T> {
    @Input() searchModel: BaseTravelCalendarSearchModel;
    @Input() travelCalendarReportService: ITravelCalendarReportServiceInterface;

    protected constructor(protected individualSettingService: IndividualSettingService) {
        super(individualSettingService);
    }
}
