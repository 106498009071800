import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { StringConstants, ValidationModel } from 'app/nexus-shared';
import { ErrorResponseModel } from 'app/nexus-shared/models/error-response.model';
import { ToastModel } from 'app/nexus-shared/models/toast.model';

@Injectable()
export class ToastService {
    public toast$: Subject<ToastModel> = new Subject();

    constructor() {
    }

    showMessageToast(message: string): void {
        const toast = new ToastModel();
        toast.message = message;
        this.toast$.next(toast);
    }

    hideMessageToast(): void {
        this.toast$.next(null);
    }

    showErrorToast(err: ErrorResponseModel | string, duration = 10000): void {
        const toast = new ToastModel();
        if (err instanceof ErrorResponseModel && err?.isValidationError) {
            const validationErrors = err?.dataBag as ValidationModel[];
            toast.message = validationErrors.map(x => x.message).join('\n');
        } else if (typeof err === 'string' && err) {
            toast.message = err;
        } else {
            toast.message = StringConstants.messages.serverError;
        }
        toast.action = 'ok';
        toast.config = {
            duration: duration,
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: 'gtn-error-toast'

        };
        this.toast$.next(toast);
    }
}
