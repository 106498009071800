import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BaseFormGroupHelper } from 'app/nexus-core';
import { TaxRateDetailFormControlNames } from 'app/nexus-shared/domain/taxes/components/tax-rate-detail/tax-rate-detail-form-control-names';

@Injectable()
export class TaxRateDetailFormGroupHelper extends BaseFormGroupHelper {
    constructor(
        private formBuilder: UntypedFormBuilder
    ) {
        super();
        this.buildFormGroup();
    }

    buildFormGroup(): UntypedFormGroup {
        return this.formGroup = this.formBuilder.group({
            [TaxRateDetailFormControlNames.effectiveEndDate]: [null],
            [TaxRateDetailFormControlNames.effectiveStartDate]: [null],
            [TaxRateDetailFormControlNames.limit]: [null],
            [TaxRateDetailFormControlNames.rate]: [null],
            [TaxRateDetailFormControlNames.specializationTitle]: [null],
            [TaxRateDetailFormControlNames.specializationKey]: [null],
            [TaxRateDetailFormControlNames.taxQualifierId]: [null],
            [TaxRateDetailFormControlNames.taxResidencyId]: [null]
        });
    }
}
