import { Component, Input, OnInit } from '@angular/core';
import { CompanyRelationshipService } from 'app/nexus-core/services/domain/contacts/company-relationship.service';
import { CompanyModel } from 'app/nexus-shared/domain/contacts/models/company.model';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { map } from 'rxjs/operators';
import { CompanyRelationshipTypesEnum } from 'app/nexus-shared/domain/contacts/enums/company-relationship-types.enum';
import { CompanyRelationshipModel } from 'app/nexus-shared/domain/contacts/models/company-relationship.model';
import { BaseEnterpriseGridComponent } from 'app/nexus-shared/components/base-component/base-enterprise-grid.component';
import { CellClickedEvent, ColDef } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { CompanyRelationshipSearchModel } from 'app/nexus-shared/domain/contacts/models/company-relationship-search.model';
import { IndividualSettingService } from 'app/nexus-core/services/domain/core/individual-setting.service';
import { AgGridColDefHelper } from 'app/nexus-core/helpers/ag-grid-col-def.helper';


@Component({
    selector: 'gtn-company-relationship-grid',
    template: `
        <gtn-template-grid [showAddButton]="isAdmin" [showQuickFilter]="true" [showExportButton]="true" [gridSettings]="gridSettings"
            [showExpandCollapse]="showExpandCollapse" [isSessionGroupExpanded]="isSessionGroupExpanded" (toggleExpandCollapse)="expandCollapse($event)"
            (exportButtonClick)="onExportButtonClicked()" (quickFilterTextChange)="onFilterTextChanged($event)" (addButtonClick)="onAddButtonClicked()">
            <gtn-template-grid-content>
                <ag-grid-angular [gtnHeight]="gridHeight" *ngIf="gridOptions" class="ag-theme-balham customized-theme"
                    [gridOptions]="gridOptions"></ag-grid-angular>
            </gtn-template-grid-content>
        </gtn-template-grid>
        <gtn-company-preview-modal *ngIf="showPreviewModal" (cancelClick)="onModalClosed()"
            [companyKey]="selectedCompanyKey"></gtn-company-preview-modal>
    `
})
export class CompanyRelationshipGridComponent extends BaseEnterpriseGridComponent<CompanyRelationshipGridViewModel> implements OnInit {
    @Input() companyKey: string = null;
    @Input() isAdmin: boolean = false;

    gridName: string = 'company_relationship_grid';
    gridVersion: string = '1.0.1';
    showPreviewModal: boolean = false;
    selectedCompanyKey: string;

    constructor(
        protected individualSettingService: IndividualSettingService,
        private clientRelationshipService: CompanyRelationshipService,
    ) {
        super(individualSettingService);
    }

    ngOnInit() {
        super.ngOnInit();
    }

    onModalClosed(): void {
        this.showPreviewModal = false;
        this.selectedCompanyKey = null;
    }

    onCellClicked(cellClick: CellClickedEvent) {
        if (cellClick?.colDef?.field === AgGridColDefHelper.previewCompanyColumnField) {
            this.selectedCompanyKey = cellClick.data.relationshipCompany.companyKey;
            this.showPreviewModal = true;
            return;
        }

        if (this.isAdmin) {
            this.cellClick.emit(cellClick.data);
        }
    }

    refresh(): void {
        this.refreshGridData();
    }

    setDefaultSortModel() {
        this.defaultSortModel = [
            {
                colId: ObjectHelper.nameOf<CompanyRelationshipGridViewModel>('isChildCompany'),
                sort: 'asc',
                sortIndex: 0
            },
            {
                colId: ObjectHelper.nameOfSubProperty<CompanyRelationshipGridViewModel, CompanyModel>('relationshipCompany', 'name'),
                sort: 'asc',
                sortIndex: 1
            }
        ];
    }

    protected setColumnDefinitions(): ColDef[] {
        return [
            AgGridColDefHelper.getCompanyPreviewColDef(),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOfSubProperty<CompanyRelationshipGridViewModel, CompanyModel>('relationshipCompany', 'name'), 'Company'),
            AgGridColDefHelper.getYesNoColDef(ObjectHelper.nameOf<CompanyRelationshipGridViewModel>('isChildCompany'), 'Is Parent'),
            AgGridColDefHelper.getEnumColumnDef(ObjectHelper.nameOf<CompanyRelationshipModel>('relationshipType'), 'Relationship Type', CompanyRelationshipTypesEnum),
            AgGridColDefHelper.getDateColDef(ObjectHelper.nameOf<CompanyRelationshipModel>('effectiveStartDate'), 'Start Date'),
            AgGridColDefHelper.getDateColDef(ObjectHelper.nameOf<CompanyRelationshipModel>('effectiveEndDate'), 'End Date'),
        ];
    }

    protected setRowData(): Observable<CompanyRelationshipModel[]> {
        return this.clientRelationshipService.search(<CompanyRelationshipSearchModel>{
            parentCompanyKeys: [this.companyKey],
            childCompanyKeys: [this.companyKey],
            includeAllRelationships: true
        }).pipe(map((relationships: CompanyRelationshipModel[]) => {
            return relationships.map((model: CompanyRelationshipModel) => {
                return new CompanyRelationshipGridViewModel(model, this.companyKey);
            });
        }));
    }
}

export class CompanyRelationshipGridViewModel extends CompanyRelationshipModel {
    isChildCompany: boolean;
    relationshipCompany: CompanyModel;

    constructor(companyRelationship: CompanyRelationshipModel = null, currentCompanyKey: string = null) {
        super(companyRelationship);
        this.isChildCompany = companyRelationship?.childCompany.companyKey === currentCompanyKey;
        this.relationshipCompany = companyRelationship?.childCompany.companyKey === currentCompanyKey ? new CompanyModel(companyRelationship?.parentCompany) : new CompanyModel(companyRelationship.childCompany);
    }
}
