<div class="speed-dial-container" [ngClass]="expandDirectionClass" [ngStyle]="{
        'flex-direction': flexDirection,
        'position': controlPosition,
        'bottom': controlPosition === 'fixed' ? '15px' : undefined,
        'right': controlPosition === 'fixed' ? '15px' : undefined
    }" (mouseover)="onExpand()" (mouseleave)="onCollapse()">
    <button mat-mini-fab color="primary" class="toggle-button" [disabled]="!buttonOptions?.length">
        <ng-container *ngIf="!buttons?.length">
            <mat-icon *ngIf="!speedDialButton">
                {{ expandDirection === 'above' ? 'expand_less' : expandDirection === 'below' ? 'expand_more' : 'chevron_' + expandDirection }}
            </mat-icon>
            <mat-icon *ngIf="speedDialButton">
                {{ speedDialButton.icon }}
            </mat-icon>
        </ng-container>

        <mat-icon *ngIf="buttons?.length">
            close
        </mat-icon>
    </button>
    <div [@speedDialStagger]="buttons?.length" [ngClass]="expandDirectionClass">
        <button *ngFor="let btn of buttons" class="button-option" mat-mini-fab color="default" [matTooltip]="btn.title"
            [disabled]="btn.disabled" (click)="onButtonOptionClicked(btn)">
            <mat-icon>
                {{ btn.icon }}
            </mat-icon>
        </button>
    </div>
</div>
