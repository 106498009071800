<form [formGroup]="formGroupRef">
    <gtn-assign-questionnaire-form [isSubForm]="true" [formGroupRef]="questionnaireFormGroup"
        [disableIndividualSearch]="disableIndividualSearch"
        [questionnaireTemplates]="questionnaireTemplates" [questionnaireType]="questionnaireType">
    </gtn-assign-questionnaire-form>
    <div class="row">
        <div class="col-md-6">
            <gtn-individual-search [individualSearchModel]="gatekeeperIndividualSearchModel" label="Gatekeeper"
                formControlName="gateKeeper"></gtn-individual-search>
        </div>
    </div>
</form>

