import { Component, EventEmitter, OnInit, Output, OnDestroy } from '@angular/core';
import { NexusAnimations } from 'app/nexus-shared/animations';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { IdleService } from 'app/nexus-core/services/idle.service';
import { AuthenticationService } from 'app/nexus-core/services/authentication.service';
import * as moment from 'moment';

@Component({
    selector: 'gtn-idle-warning-modal',
    templateUrl: './idle-warning-modal.component.html',
    animations: [
        NexusAnimations.modalFadeIn
    ],
    styles: [
        '.timer-text { font-size: 24px }'
    ]
})
export class IdleWarningModalComponent extends BaseComponent implements OnInit, OnDestroy {
    @Output() completed = new EventEmitter();

    idleWarningInMinutes: number;
    idleWarningLogoutInMinutes: number;
    interval = null;
    timerText = null;

    constructor(
        private idleService: IdleService, private authenticationService: AuthenticationService) {
        super();
    }

    ngOnInit(): void {
        this.idleWarningInMinutes = this.idleService.idleWarningInMinutes;
        this.idleWarningLogoutInMinutes = this.idleService.idleWarningLogoutInMinutes;

        const countdownDate = moment().add(this.idleWarningLogoutInMinutes, 'minute');
        const countdownTime = countdownDate.toDate().getTime();

        const that = this;

        this.interval = setInterval(function() {
            const now = new Date().getTime();
            const distance = countdownTime - now;

            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((distance % (1000 * 60)) / 1000).toString();

            if (parseInt(seconds, 10) < 10) {
                seconds = '0' + seconds;
            }

            that.timerText = `${minutes}:${seconds}`;

            if (distance < 0) {
                clearInterval(that.interval);
                that.timerText = 'Logging out . . ';
            }
        }, 1000);
    }

    ngOnDestroy() {
        if (this.interval) {
            clearInterval(this.interval);
        }

        super.ngOnDestroy();
    }

    onResetInactivityClicked(): void {
        this.idleService.resetIdleWarning();
        this.completed.emit();
    }

    onLogOutClicked(): void {
        this.authenticationService.logout();
        this.completed.emit();
    }
}
