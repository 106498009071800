<gtn-template-grid [showQuickFilter]="true" [showExportButton]="true" (exportButtonClick)="onExportButtonClicked()"
    (quickFilterTextChange)="onFilterTextChanged($event)">
    <gtn-template-grid-controls-left>
        <div class="row" *ngIf="documentsUserAuth.isAdmin">
            <gtn-datepicker class="col-5 ml-2" label="Start date" [suppressInitialChangeEvent]="true"
                [(ngModel)]="searchModel.startDate"
                (dateChanged)="refreshGridData()"></gtn-datepicker>
            <gtn-datepicker class="col-5" label="End date" [suppressInitialChangeEvent]="true"
                [(ngModel)]="searchModel.endDate"
                (dateChanged)="refreshGridData()"></gtn-datepicker>
        </div>
    </gtn-template-grid-controls-left>
    <gtn-template-grid-content>
        <ag-grid-angular [gtnHeight]="gridHeight" *ngIf="gridOptions" class="ag-theme-balham"
            [gridOptions]="gridOptions"></ag-grid-angular>
    </gtn-template-grid-content>
</gtn-template-grid>
<gtn-documents-confirm-modal *ngIf="showConfirmModal" [confirmActionsEnum]="confirmActionType"
    [confirmActionEntities]="confirmActionEntities"
    (cancelClick)="onCancelClicked()" (confirmClick)="onConfirmClicked()"></gtn-documents-confirm-modal>
