import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BaseDynamicComponentModel } from 'app/nexus-shared/components/dynamic-component/base-dynamic-component.model';
import { DynamicComponentHostComponent } from './dynamic-component-host.component';

@Component({
    selector: 'dynamic-component',
    template: `
    <div>
      <ng-template dynamicComponentHost></ng-template>
    </div>
  `
})
export class DynamicComponentComponent implements OnInit {
    @Input() componentConfiguration: BaseDynamicComponentModel<any>;

    @ViewChild(DynamicComponentHostComponent, { static: true }) dynamicComponentHost!: DynamicComponentHostComponent;

    ngOnInit(): void {
        this.loadComponent();
    }

    loadComponent() {
        const viewContainerRef = this.dynamicComponentHost.viewContainerRef;
        viewContainerRef.clear();

        const componentRef = viewContainerRef.createComponent<BaseDynamicComponentModel<any>>(this.componentConfiguration.component);

        if (this.componentConfiguration?.data) {
            for (const [key, value] of Object.entries(this.componentConfiguration.data)) {
                componentRef.instance[key] = value;
            }
        }
    }
}
