import { animate, AnimationMetadata, style, transition, trigger } from '@angular/animations';

export class FadeIn {
    /**
     * Use '@fadeIn' in the markup.
     */
    static animations = (delay: string) => FadeIn.getAnimation(delay);

    private static getAnimation(delay: string): AnimationMetadata {
        return trigger('fadeIn', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate(`${delay} ease-in`, style({ opacity: 1 }))
            ])
        ]);
    }
}
