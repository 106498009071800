import { AfterViewInit, Component, EventEmitter, forwardRef, Input, Output, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseControlComponent } from '../base-control.component';

@Component({
    selector: 'gtn-text-area',
    templateUrl: './text-area.component.html',
    styleUrls: ['./text-area.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TextAreaComponent),
            multi: true
        }
    ]
})
export class TextAreaComponent extends BaseControlComponent<string> implements ControlValueAccessor, AfterViewInit {
    @Input() minRows: number = 1;
    @Input() maxRows: number;
    @Input() readonly: boolean;
    @Output() change: EventEmitter<any> = new EventEmitter();
    @Output() blur: EventEmitter<any> = new EventEmitter();

    constructor(private renderer: Renderer2) {
        super();
    }

    onChange: Function = () => {
    };
    onTouched: Function = () => {
    };

    ngAfterViewInit() {
        if (this.focus) {
            this.setFocus();
        }

        if (this.readonly && !this.value) {
            this.readonly = false;
        }
    }

    get value(): any {
        return this._value;
    }

    set value(val: any) {
        this._value = val;
        this.onChange(val);
        this.change.emit(val);
        this.onTouched();
    }

    onBlur(): void {
        this.onTouched();
    }

    registerOnChange(fn): void {
        this.onChange = fn;
    }

    registerOnTouched(fn): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    writeValue(value): void {
        const currentValue = this.value;

        if (typeof currentValue !== 'undefined' && currentValue !== null && currentValue !== value) {
            this.value = value;
        } else if (typeof value !== 'undefined' && value !== null && currentValue !== value) {
            this.value = value;
        }
    }

    setFocus(): void {
        this.renderer.selectRootElement('textarea').focus();
    }
}
