import { AfterViewInit, ChangeDetectorRef, Component, ContentChildren, EventEmitter, Input, Output, QueryList } from '@angular/core';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { ProgressStepperModel } from 'app/nexus-shared/components/controls/shared/models/progress-stepper.model';
import { ProgressStepComponent } from 'app/nexus-shared/components/controls/components/progress-stepper/progress-step.component';

@Component({
    selector: 'gtn-progress-stepper',
    templateUrl: './progress-stepper.component.html',
    styleUrls: ['./progress-stepper.component.scss']
})
export class ProgressStepperComponent extends BaseComponent implements AfterViewInit {
    @Input() steps: ProgressStepperModel[] = [];
    @Input() showStepControls: boolean = true;
    @Input() isClickableSteps: boolean = true;
    @Input() selectedStepIndex: number = 0;
    @Input() tooltip: string = null;
    @Output() stepClick: EventEmitter<any> = new EventEmitter();
    @Output() stepChange: EventEmitter<ProgressStepperModel> = new EventEmitter<ProgressStepperModel>();

    @ContentChildren(ProgressStepComponent) stepsContent: QueryList<ProgressStepComponent>;

    constructor(private cdr: ChangeDetectorRef) {
        super();
    }

    ngAfterViewInit() {
        this.cdr.detectChanges();
    }

    onStepClicked(step: number) {
        if (this.isClickableSteps && (step === 0 || this.steps[step - 1].completed)) {
            this.steps[this.selectedStepIndex].active = false;
            this.selectedStepIndex = step;
            this.steps[this.selectedStepIndex].active = true;
        }
    }

    onPreviousClicked(): void {
        this.steps[this.selectedStepIndex].active = false;
        this.selectedStepIndex--;
        this.steps[this.selectedStepIndex].active = true;
        this.stepChange.emit(this.steps[this.selectedStepIndex]);
    }

    onNextClicked(): void {
        this.steps[this.selectedStepIndex].active = false;
        this.selectedStepIndex++;
        this.steps[this.selectedStepIndex].active = true;
        this.stepChange.emit(this.steps[this.selectedStepIndex]);
    }

}
