export class ModalOutputModel {
    cancel: Function;
    ok: Function;

    constructor(ok: Function = () => {
    }, cancel: Function = null) {
        this.cancel = cancel;
        // The ok function defaults to an empty function so that you don't have to pass anything in for the alert modal.
        this.ok = ok;
    }
}
