import * as moment from 'moment';
import { Moment } from 'moment';

export class DateHelper {
    // ToDo: use these globally
    static standardDateFormat = 'DD MMM YYYY';
    static standardDateFormatTimeStamp = 'DD MMM YYYY h:mm A';
    static defaultExcelDateFormat = 'dd mmm yyyy';
    static defaultExcelDateTimeFormat = 'dd mmm yyyy h:mm AM/PM';

    // Special cases
    static monthAbbreviations = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    static gridFilterDateFormat = 'YYYY-MM-DD HH:mm:ss';
    static urlStringDateFormat = 'YYYY-M-D';

    // none of these are used
    private static fileNameFormat = 'MMMM DD';
    private static breadcrumbFormat = 'MM/YYYY';
    private static fullMonthYearFormat = 'MMMM YYYY';
    private static displayDateFormat = 'dd MMM yyyy';
    private static accountingDateFormat = 'DD-MMM-YY';
    private static ticksInDay = (1000 * 3600 * 24);

    static areEqual(d1: Date, d2: Date, unit: moment.unitOfTime.StartOf = null): boolean {
        const date1 = moment(d1),
            date2 = moment(d2);

        let response = false;

        if (date1.isValid() && date2.isValid()) {
            response = date1.isSame(date2, unit);
        }

        return response;
    }

    static convertToZeroDate(date: Date | string): Date {
        return DateHelper.convertToZeroMomentDate(date).toDate();
    }

    static convertToZeroMomentDate(date: Date | string | Moment): Moment {
        return moment(`${moment(date).format('YYYY-MM-DD')}T00:00:00Z`).utc();
    }

    static convertToZeroDateString(date: Date | string): string {
        const newDate = date ? moment(date).format('YYYY-MM-DD') : 'Invalid date';

        if (newDate === 'Invalid date') {
            return null;
        }

        return `${newDate}T00:00:00+00:00`;
    }

    static convertToUTCZeroDateString(date: Date | string): string {
        return `${moment(DateHelper.convertToUtcDate(date)).format('YYYY-MM-DD')}T00:00:00+00:00`;
    }

    static convertToServerDate(date: Date): string {
        const utcDate = DateHelper.convertToUtcDate(date);
        return DateHelper.format(utcDate);
    }

    static convertToUtcDate(date: Date | string): Date {
        if (date && moment(date).isValid) {
            const utcDate = moment.utc(date).toDate();
            return new Date(utcDate.getUTCFullYear(), utcDate.getUTCMonth(), utcDate.getUTCDate());
        }

        return null;
    }

    static convertToFullUtcDate(date: Date | string): Date {
        if (date && moment(date).isValid) {
            return moment.utc(date).toDate();
        }

        return null;
    }

    static daysInMonth(year: number, month: number): number {
        return new Date(year, month, 0).getDate();
    }

    static getDuration(timeSpan: string): moment.Duration {
        return moment.duration(timeSpan);
    }

    static getTimezoneAddedDate(date: string | Date): Date {
        return new Date(new Date(date).getTime() - (new Date().getTimezoneOffset() * 60 * 1000));
    }

    public static getGreenwichDateFromLocal(date: Date): Date {
        if (date) {
            return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        } else {
            return null;
        }
    }

    static getMonthEndZeroDateValue(value: Date): Date {
        const momentDate = moment(value);

        if (momentDate.isValid()) {
            const year = momentDate.utc().year(),
                month = momentDate.utc().month(),
                lastDay = DateHelper.daysInMonth(year, month + 1);

            return this.convertToZeroDate(new Date(Date.UTC(year, month, lastDay, 23, 59, 59, 999)));
        }

        return null;
    }

    static getMonthStartZeroDateValue(value: Date): Date {
        const momentDate = moment(value);
        if (momentDate.isValid()) {
            const year = momentDate.utc().year(),
                month = momentDate.utc().month();

            return this.convertToZeroDate(new Date(Date.UTC(year, month, 1, 23, 59, 59, 999)));
        }

        return null;
    }

    static greaterThan(d1: Date, d2: Date, equalTo: boolean = false): boolean {
        const date1 = moment(d1),
            date2 = moment(d2);

        return date1.isValid() && date2.isValid() ? equalTo ? date1.isSameOrAfter(date2) : date1.isAfter(date2) : false;
    }

    static getDaysInCurrentMonth(): number {
        const currentDate = new Date();
        return this.daysInMonth(currentDate.getFullYear(), currentDate.getMonth()) - 1;
    }

    static getWeekDays(isShort: boolean = false): string[] {
        return isShort ? moment.weekdaysShort() : moment.weekdays();
    }

    static getMonths(): string[] {
        return moment.months();
    }

    static getTime(date: Date): string {
        return moment(date).format('HH:mm');
    }

    static getYear(date: Date | string): number {
        const d = moment(date);
        return d.isValid() ? d.year() : null;
    }

    static splitDateTimeString(date: string): string[] {
        return date.split('T');
    }

    static splitDatePartsFromDateTimeString(date: string): string[] {
        return date.split('T')[0]?.split('-');
    }

    static lessThan(d1: Date, d2: Date, equalTo: boolean = false): boolean {
        const date1 = moment(d1),
            date2 = moment(d2);

        return date1.isValid() && date2.isValid() ? equalTo ? date1.isSameOrBefore(date2) : date1.isBefore(date2) : false;
    }

    static formatDateRange(startDate: Date | string | null, endDate: Date | string | null, format: string = this.standardDateFormat): string {
        const formattedStartDate = (startDate ? DateHelper.format(DateHelper.convertToUtcDate(startDate), format) : '∞');
        const formattedEndDate = (endDate ? DateHelper.format(DateHelper.convertToUtcDate(endDate), format) : '∞');

        return `${formattedStartDate} — ${formattedEndDate}`;
    }

    static format(date: Date | string | null, format: string = this.standardDateFormat): string {
        if (date === null || date === undefined) {
            return '';
        }

        const momentDate = moment(date);

        if (momentDate.isValid()) {
            return momentDate.format(format);
        }

        return '';
    }

    static formatUtc(date: Date | string | null, format: string = this.standardDateFormat): string {
        if (date === null || date === undefined) {
            return '';
        }

        const momentDate = moment(date).utc();

        if (momentDate.isValid()) {
            return momentDate.format(format);
        }

        return '';
    }

    static addDate(date: Date, number: number, type: moment.unitOfTime.DurationConstructor = 'd') {
        let newDate = new Date(date); // assign to new date to keep function pure
        newDate = moment(newDate).add(number, type).toDate();
        return newDate;
    }

    static subtractDate(date: Date, number: number, type: moment.unitOfTime.DurationConstructor = 'd') {
        let newDate = new Date(date); // assign to new date to keep function pure
        newDate = moment(newDate).subtract(number, type).toDate();
        return newDate;
    }

    static addMonths(date: Date, months: number) {
        const newDate = new Date(date); // assign to new date to keep function pure
        let monthValue = newDate.getMonth() + months;

        // handle if month change will result in new year (.getMonth() evaluates as January == 0)
        if (monthValue <= 0) {
            monthValue += 12;
            newDate.setFullYear(newDate.getFullYear() - 1);
        } else if (monthValue > 11) {
            newDate.setFullYear(newDate.getFullYear() + 1);
            monthValue -= 12;
        }

        // change month value
        newDate.setMonth(monthValue);

        return newDate;
    }

    static isValid(date: Date): boolean {
        return moment(date).isValid();
    }

    static isWeekend(date: Date): boolean {
        return moment(date).day() === 0 || moment(date).day() === 6;
    }

    static propertyIsDateObject(property: any): boolean {
        return Object.prototype.toString.call(property) === '[object Date]';
    }

    static updateTime(date: Date, time: Date | string) {
        const [hour, minute] = typeof time === 'string' ? time.split(':') : this.getTime(time).split(':');
        return moment(date).set({ 'hour': Number(hour), 'minute': Number(minute) }).toDate();
    }

    static countDates(date1: Date, date2: Date, type: moment.unitOfTime.Diff = 'd') {
        return moment(date2).diff(moment(date1), type);
    }

    static isBetweenDates(startDate: Date | string, endDate: Date | string, date: Date | string = new Date()): boolean {
        const startDt = DateHelper.convertToZeroMomentDate(startDate);
        const endDt = DateHelper.convertToZeroMomentDate(endDate);
        const dt = DateHelper.convertToZeroMomentDate(date);

        return dt.isBetween(startDt, endDt, 'days', '[]');
    }

    static getDayCountBetweenRange(startDate: Date | string, endDate: Date | string): number {
        const startDateObject = moment(startDate.toString());
        const endDateObject = moment(endDate.toString());

        if (endDateObject.isBefore(startDateObject)) {
            return null;
        }
        return endDateObject.diff(startDateObject, 'd');
    }

    static getDatesBetweenRange(startDate: Date | string, endDate: Date | string, actionForEachDate: (date: Date) => void = null): Date[] {

        //throws warning, moment requires a format for the strings for browser compatability
        const startDateObject = moment(startDate.toString());
        const endDateObject = moment(endDate.toString());

        if (endDateObject.isBefore(startDateObject)) {
            return [];
        }

        let tmpDate = startDateObject.toDate();
        const dates = [];

        const dayDifference = endDateObject.diff(startDateObject, 'd');

        for (let i = 1; i < dayDifference; i++) {
            tmpDate = DateHelper.addDate(tmpDate, 1);
            dates.push(tmpDate);
            if (actionForEachDate) {
                actionForEachDate(tmpDate);
            }
        }

        return dates;
    }

    static getMonthsBetweenDateRange(startDate: Date | string, endDate: Date | string, precise: boolean = false): number {
        const start = moment(startDate.toString());
        const end = moment(endDate.toString());

        if (start.isValid() && end.isValid()) {
            return end.diff(start, 'months', precise);
        }
        return null;
    }
}
