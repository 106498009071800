import { Component, Input, OnInit } from '@angular/core';
import { BaseViewComponent } from 'app/nexus-shared/components/base-component/base-view.component';
import { CompanyModel } from 'app/nexus-shared/domain/contacts/models';
import { ApplicationsEnum } from 'app/nexus-shared/index';
import { MyGTNPortalService } from 'app/nexus-core/services/domain/contacts/my-gtn-portal.service';
import { CompanyTypesEnum } from 'app/nexus-shared/domain/contacts/enums/company-types.enum';
import { ServiceAreaTypesEnum } from '../../../enums/service-area-types.enum';
import { RegionTypesEnum } from 'app/nexus-shared/domain/contacts/enums/region-types.enum';
import { PhoneNumberTypesEnum } from 'app/nexus-shared/domain/contacts/enums/phone-number-types.enum';
import { AuthenticatedUserService } from 'app/nexus-core/services/authenticated-user.service';
import { CompanyService } from 'app/nexus-core/services/domain/contacts/company.service';
import { ToastService } from 'app/nexus-core/services/toast.service';

@Component({
    selector: 'gtn-company-view',
    templateUrl: './company-view.component.html',
    styleUrls: ['./company-view.component.scss']
})
export class CompanyViewComponent extends BaseViewComponent<CompanyModel> implements OnInit {
    @Input() application: ApplicationsEnum;

    applicationsEnum = ApplicationsEnum;
    isClient: boolean;
    companyTypesEnum = CompanyTypesEnum;
    myGTNPortalCompanyName: string;
    phoneNumberTypes = PhoneNumberTypesEnum;
    regionTypesEnum = RegionTypesEnum;
    serviceAreaTypesEnum = ServiceAreaTypesEnum;

    constructor(
        private authenticatedUserService: AuthenticatedUserService,
        private companyService: CompanyService,
        private myGTNPortalService: MyGTNPortalService,
        private toastService: ToastService
    ) {
        super();
    }

    ngOnInit() {
        if (this.value?.myGTNPortalCompanyKey) {
            this.getMyGTNPortalCompany(this.value.myGTNPortalCompanyKey);
        }
    }

    private getMyGTNPortalCompany(key: string): void {
        this.myGTNPortalService.getCompany(key).subscribe((res) => {
            this.myGTNPortalCompanyName = res;
        });
    }

}
