import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NexusAnimations, ValidationModel } from 'app/nexus-shared/index';
import { BaseEntryComponent } from 'app/nexus-shared/components/base-component/base-entry.component';
import { UntypedFormGroup } from '@angular/forms';
import { PendingChangesModel } from 'app/nexus-shared/models/pending-changes.model';

@Component({
    selector: 'gtn-template-readonly-edit-card',
    templateUrl: './template-readonly-edit-card.component.html',
    styleUrls: ['./template-readonly-edit-card.component.scss'],
    animations: [
        NexusAnimations.menuOpenDownChildSlideDown
    ]
})
export class TemplateReadonlyEditCardComponent<T> extends BaseEntryComponent<T> {
    @Input() editOnly: boolean = false; // this will override and hide everything edit/readonly related
    @Input() showActionButtonsInCard: boolean = false;
    @Input() showActionButtons: boolean = true;
    @Input() pageTitle: string;
    @Input() readonly: boolean = true;
    @Input() disableReadonlyEditToggle: boolean = false;
    @Output() readonlyChange: EventEmitter<boolean> = new EventEmitter();
    @Input() validationModels: ValidationModel[];
    @Input() formGroupRef: UntypedFormGroup = new UntypedFormGroup({});
    @Input() showCardTitleIcon = true;
    @Input() showCardTitle = true;
    @Input() pendingChanges: PendingChangesModel = new PendingChangesModel();
    @Input() isAlwaysCheckPendingChanges: boolean = false;
    @Input() isOverflowEditContent: boolean;
    @Input() deleteButtonText: string = 'Delete';
    @Input() showDeleteButton: boolean = false;

    toggleReadonly(): boolean {
        if (this.disableReadonlyEditToggle) {
            return;
        }
        if ((!this.readonly || this.isAlwaysCheckPendingChanges) && this.formGroupRef.dirty) {
            this.pendingChanges.showPendingChanges = true;
            return true;
        } else {
            this.readonly = !this.readonly;
            this.resetFormState();
            this.readonlyChange.emit(this.readonly);
        }

        return false;
    }

    onSaveClicked(): void {
        this.pendingChanges.showPendingChanges = false;
        this.pendingChanges.nextUrl = null;
        this.pendingChanges.nextTabIndex = null;

        if (this.validateEntry()) {
            this.saveClick.emit();
        }
    }

    onDeleteClicked(): void {
        this.pendingChanges.showPendingChanges = false;
        this.pendingChanges.nextUrl = null;
        this.pendingChanges.nextTabIndex = null;

        this.deleteClick.emit();
    }

    onCancelClicked(): void {
        if (this.validationModels?.length) {
            this.validationModels = [];
        }

        if (!this.toggleReadonly()) {
            this.resetFormState();
            this.cancelClick.emit();
        }
    }

    onDiscardPendingChangesClicked(): void {
        if (!this.pendingChangesDiscardClick?.observers?.length) {
            this.pendingChanges.nextUrl = null;
            this.pendingChanges.nextTabIndex = null;
            super.onDiscardPendingChangesClicked();
            this.onCancelClicked();
        } else {
            super.onDiscardPendingChangesClicked();
        }

    }

    private resetFormState(): void {
        this.formGroupRef.markAsPristine();
        this.formGroupRef.updateValueAndValidity();
        this.isSaving = false;
    }
}
