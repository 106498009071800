import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DocumentModel } from 'app/nexus-shared/domain/documents/models/document.model';
import { DocumentService } from 'app/nexus-core/services/domain/documents/document.service';
import { DocumentSearchModel } from 'app/nexus-shared/domain/documents/models/document-search.model';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { DocumentEventTypesEnum } from 'app/nexus-shared/domain/documents/enums/document-event-types.enum';
import { DateHelper, SortingHelper } from 'app/nexus-core';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { DocumentStatusesEnum } from 'app/nexus-shared/domain/documents/enums/document-statuses.enum';
import { DocumentsAuthModel } from 'app/nexus-shared/domain/documents/models/documents-auth.model';
import { AreaTypesEnum } from 'app/nexus-shared/domain/documents/enums/area-types.enum';
import { CommonBaseAuditUserModel } from 'app/nexus-shared/domain/contacts/models/common-base-audit-user.model';

@Component({
    selector: 'gtn-document-history',
    templateUrl: './document-history.component.html',
    styleUrls: ['./document-history.component.scss']
})
export class DocumentHistoryComponent extends BaseComponent implements OnInit {
    static systemUpdatedUser: string = 'System, System';

    @Input() areaType: AreaTypesEnum;
    @Input() documentKey: string;
    @Input() fullDocumentHistory: DocumentModel[];
    @Input() documentUserAuth: DocumentsAuthModel;

    @Input() isFullHistory: boolean = false;
    @Output() isFullHistoryChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    documentEventTypeEnum = DocumentEventTypesEnum;
    documentStatuses = DocumentStatusesEnum;
    filteredDocumentHistory: DocumentModel[];
    displayDocumentHistory: DocumentModel[];

    constructor(private documentService: DocumentService) {
        super();
    }

    ngOnInit(): void {
        if (this.fullDocumentHistory?.length) {
            this.initializeHistory();
            return;
        }

        const searchModel = <DocumentSearchModel>{
            documentKeys: [this.documentKey],
            includeHistory: true,
            areaTypes: [this.areaType]
        };

        this.documentService.search(searchModel).subscribe(res => {
            this.fullDocumentHistory = res.sort((a, b) => {
                return SortingHelper.sortByDatePropertyComparer(
                    a.lastModifiedByUser,
                    b.lastModifiedByUser,
                    ObjectHelper.nameOf<CommonBaseAuditUserModel>('changeDateTime')
                );
            });

            this.initializeHistory();
        });
    }

    onToggleFullHistory(fullHistory: boolean): void {
        if (fullHistory) {
            this.displayDocumentHistory = JSON.parse(JSON.stringify(this.fullDocumentHistory));
        } else {
            this.displayDocumentHistory = JSON.parse(JSON.stringify(this.filteredDocumentHistory));
        }

        this.sortHistoryDescending();
    }

    private filterHistory(): void {
        this.filteredDocumentHistory = JSON.parse(JSON.stringify(this.fullDocumentHistory)).filter(x => {
            return (x.eventType !== DocumentEventTypesEnum.VirusScan && x.eventType !== DocumentEventTypesEnum.SkippedVirusScan && x.lastModifiedByUserName !== DocumentHistoryComponent.systemUpdatedUser);
        });
        this.displayDocumentHistory = this.filteredDocumentHistory;
    }

    private initializeHistory(): void {
        this.filterHistory();
        this.sortHistoryDescending();
    }

    private sortHistoryDescending(): void {
        this.displayDocumentHistory.sort((a, b) => {
            return SortingHelper.sortByDateComparerDesc(
                DateHelper.convertToFullUtcDate(a.lastModifiedByUser.changeDateTime),
                DateHelper.convertToFullUtcDate(b.lastModifiedByUser.changeDateTime)
            );
        });
    }
}
