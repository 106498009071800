import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    template: ''
})
export abstract class BaseFileViewComponent {
    @Input() src: any;
    @Input() isBlob: boolean;

    @Output() outsideCloseClick: EventEmitter<void> = new EventEmitter<void>();

    onOutsideCloseClicked(): void {
        this.outsideCloseClick.emit();
    }
}
