import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseFormComponent } from 'app/nexus-shared/components/base-component/base-form.component';
import { DocumentModel } from 'app/nexus-shared/domain/documents/models/document.model';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { DocumentStatusesEnum } from 'app/nexus-shared/domain/documents/enums/document-statuses.enum';
import { BaseFormModel } from 'app/nexus-shared/models/base-form.model';
import { SelectListInterface } from 'app/nexus-shared/interfaces';
import { EnumHelper, NexusValidatorHelper } from 'app/nexus-core';
import { ClientDocumentTypesEnum } from 'app/nexus-shared/domain/documents/enums/client-document-types.enum';
import { ClientDocumentModel } from 'app/nexus-shared/domain/documents/models/client-document.model';
import { AreaTypesEnum } from 'app/nexus-shared/domain/documents/enums/area-types.enum';
import { FileModel } from 'app/nexus-shared/domain/documents/models/file.model';

@Component({
    selector: 'gtn-document-form',
    templateUrl: './document-form.component.html',
    styleUrls: ['./document-form.component.scss']
})
export class DocumentFormComponent extends BaseFormComponent<DocumentModel> implements OnInit {
    @Input() isUpload: boolean = false;
    @Input() isCheckIn: boolean;
    @Input() file: File = null;
    @Output() fileChange = new EventEmitter<File>();

    isClientDocument: boolean = false;
    isFileTemplate: boolean = false;
    clientDocumentTypeOptions: SelectListInterface[];
    suppressInitialCountryChange: boolean = false;
    taxYearOptions: SelectListInterface[];

    constructor() {
        super();
    }

    ngOnInit(): void {
        const formConfiguration = new BaseFormModel();
        const fileFormConfiguration = new BaseFormModel();
        fileFormConfiguration.validators[ObjectHelper.nameOf<FileModel>('sizeInBytes')] = [NexusValidatorHelper.validateFileSize()];
        formConfiguration.formConfiguration[ObjectHelper.nameOf<DocumentModel>('clientDocument')] = new BaseFormModel();
        formConfiguration.formConfiguration[ObjectHelper.nameOf<DocumentModel>('note')] = new BaseFormModel();
        super.ngOnInit(formConfiguration);
    }

    initUIControls() {
        this.taxYearOptions = this.getFiscalYearOptions();
        this.clientDocumentTypeOptions = EnumHelper.convertToSelectList(ClientDocumentTypesEnum);
    }

    initFormCustomizations() {
        this.isClientDocument = !!(this.value?.clientDocument?.individualKey || this.value?.clientDocument?.companyKey);
        this.isFileTemplate = this.value?.areaType === AreaTypesEnum.FileTemplates;
        this.suppressInitialCountryChange = !!this.value?.clientDocument?.countryId;
        if (this.isUpload) {
            this.formGroupRef.get(ObjectHelper.nameOf<DocumentModel>('status')).setValue(DocumentStatusesEnum.Active);
        }
        this.formGroupRef.get(ObjectHelper.nameOf<DocumentModel>('eventNote')).setValue(null);

    }

    onCheckInChanged(checkIn: boolean) {
        const status = checkIn ? DocumentStatusesEnum.Active : DocumentStatusesEnum.CheckedOut;
        this.formGroupRef.get(ObjectHelper.nameOf<DocumentModel>('status')).setValue(status);
    }

    onFileInputChanged(file: File): void {
        this.file = file;
        this.fileChange.emit(file);

        this.formGroupRef.markAsDirty();
        this.formGroupRef.updateValueAndValidity();
    }

    getFiscalYearOptions(): SelectListInterface[] {
        const years: SelectListInterface[] = [];
        let startYear = new Date().getFullYear() - 4;
        const endYear = new Date().getFullYear() + 4;
        for (let i = startYear; startYear < endYear; i++) {
            years.push({ id: startYear, value: startYear.toString() });
            startYear++;
        }
        return years;
    }

    getCheckboxLabel(): string {
        const formGroup = this.getFormGroupFromAbstractControl(this.formGroupRef.controls[ObjectHelper.nameOf<DocumentModel>('clientDocument')]);
        if (formGroup.get(ObjectHelper.nameOf<ClientDocumentModel>('taxYear')).value) {
            const year = formGroup.get(ObjectHelper.nameOf<ClientDocumentModel>('taxYear')).value;
            return `Fiscal year (${year}/${year + 1})`;
        } else {
            return 'Fiscal year';
        }
    }
}
