import { CalendarDayModel } from 'app/nexus-shared/models/calendar-day.model';

export class CalendarHelper {

    static getDaysInMonth(month: number, year: number, weekDayStart: number = 0): CalendarDayModel[] {
        const days = [];

        let dayOne = new Date(year, month, 1).getDay() - weekDayStart;
        if (dayOne < 0) {
            dayOne += 7;
        }

        // Get the last date of the month
        const lastDate = new Date(year, month + 1, 0).getDate();

        // Get the day of the last date of the month
        let dayEnd = new Date(year, month, lastDate).getDay() - weekDayStart;
        if (dayEnd < 0) {
            dayEnd += 7;
        }

        // Get the last date of the previous month
        const monthLastDate = new Date(year, month, 0).getDate();

        // loop to add the last dates of the previous month
        for (let i = dayOne; i > 0; i--) {
            const day = new CalendarDayModel(<CalendarDayModel>{ day: monthLastDate - i + 1, isInMonth: false });
            days.push(day);
        }

        // loop to add the dates of the current month
        for (let i = 1; i <= lastDate; i++) {
            const day = new CalendarDayModel(<CalendarDayModel>{ day: i, isInMonth: true, date: new Date(year, month, i) });
            days.push(day);
        }

        // loop to add the first dates of the next month
        for (let i = dayEnd; i < 6; i++) {
            const day = new CalendarDayModel(<CalendarDayModel>{ day: i - dayEnd + 1, isInMonth: false });
            days.push(day);
        }

        return days;
    }
}
