import { CommonModule, CurrencyPipe } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatNativeDateModule, MatOptionModule, MatRippleModule } from '@angular/material/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTreeModule } from '@angular/material/tree';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ClickOutsideDirective, GtnHeightDirective, IfHasAuthorizationDirective, LoadingOverlayDirective } from './directives';
import {
    CityPipe,
    CountryPipe,
    EnumPipe,
    FilterByPipe,
    GetNamePipe,
    GtnCurrencyPipe,
    GtnDatePipe,
    LimitToPipe,
    MarketValuePipe,
    PercentPipe,
    SelectListPipe,
    SomeDefaultValuePipe,
    SortByPipe,
    SpecializationNamePipe,
    StatePipe,
    TrimPipe,
    WeekdayPipe,
    YesNoPipe
} from './pipes';
import { DeleteSectionComponent } from './components/delete-section/delete-section.component';
import { DashboardFrameComponent } from './components/dashboard-frame/dashboard-frame.component';
import { IfHasAnyAuthorizationDirective } from 'app/nexus-shared/directives/if-has-any-authorization.directive';
import { FilterComponent } from './components/filter';
import { FilterDirective } from 'app/nexus-shared/components/filter/filter.directive';
import { DisplayNoneComponent } from './components/display-none/display-none.component';
import { FilterPanelComponent } from 'app/nexus-shared/components/filter-panel/filter-panel.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FilterPanelButtonContentComponent } from 'app/nexus-shared/components/filter-panel/filter-panel-button-content.component';
import { CustomLoadingOverlayComponent } from 'app/nexus-shared/components/controls/components/base-grid/custom-loading-overlay/custom-loading-overlay.component';
import {
    AutocompleteComponent,
    BreadcrumbsComponent,
    CheckboxColumnComponent,
    CheckboxComponent,
    CheckboxHeaderComponent,
    ControlColumnComponent,
    CountryColumnComponent,
    CurrencyColumnComponent,
    DateCellEditorComponent,
    DatepickerComponent,
    DisableAutofillDirective,
    DropdownComponent,
    EditorColumnComponent,
    FileDragDropComponent,
    GridComponent,
    GridHeaderComponent,
    HeroNumberComponent,
    IconDropdownComponent,
    InputCellEditorComponent,
    InputComponent,
    MultiselectComponent,
    NotificationIndicatorComponent,
    NotificationItemComponent,
    NotificationsComponent,
    ProgressBarComponent,
    RadioComponent,
    RadioGroupComponent,
    ReportHeaderComponent,
    ReportHeaderGroupComponent,
    SearchComponent,
    SliderComponent,
    SlideToggleComponent,
    TextAreaComponent,
    ValidationComponent,
    ValidationErrorSectionComponent,
    ValidationPopOverComponent
} from 'app/nexus-shared/components/controls';
import { BackButtonComponent } from 'app/nexus-shared/components/controls/components/back-button/back-button.component';
import { DraggableItemListComponent } from 'app/nexus-shared/components/controls/components/draggable-item-list/draggable-item-list.component';
import { ExpandableCardComponent } from 'app/nexus-shared/components/controls/components/expandable-card/expandable-card.component';
import { FilterableTilesComponent } from 'app/nexus-shared/components/controls/components/filterable-tiles/filterable-tiles.component';
import { FilterableTilesContentComponent } from 'app/nexus-shared/components/controls/components/filterable-tiles/filterable-tiles-content.component';
import { FilterableTilesHeaderComponent } from 'app/nexus-shared/components/controls/components/filterable-tiles/filterable-tiles-header.component';
import { PendingChangesModalComponent } from 'app/nexus-shared/components/controls/components/pending-changes-modal/pending-changes-modal.component';
import { SpeedDialButtonsComponent } from 'app/nexus-shared/components/controls/components/speed-dial-buttons/speed-dial-buttons.component';
import { TimepickerComponent } from 'app/nexus-shared/components/controls/components/timepicker/timepicker.component';
import { TooltipComponent } from 'app/nexus-shared/components/controls/components/tooltip/tooltip.component';
import { ValidationIndicatorComponent } from 'app/nexus-shared/components/controls/components/validation/validation-indicator/validation-indicator.component';
import { ValidationItemComponent } from 'app/nexus-shared/components/controls/components/validation/validation-item/validation-item.component';
import { ValidationItemsComponent } from 'app/nexus-shared/components/controls/components/validation/validation-items/validation-items.component';
import { NoRowsOverlayComponent } from 'app/nexus-shared/components/controls/components/base-grid/no-rows-overlay/no-rows-overlay.component';
import { TimePickerDialogComponent } from 'app/nexus-shared/components/controls/components/timepicker/time-picker-dialog/time-picker-dialog.component';
import { TemplateCardComponent } from 'app/nexus-shared/components/templates/template-card/template-card.component';
import { TemplateCardContentComponent } from 'app/nexus-shared/components/templates/template-card/template-card-content.component';
import { TemplateCardHeaderComponent } from 'app/nexus-shared/components/templates/template-card/template-card-header.component';
import { TemplateDynamicCardComponent } from 'app/nexus-shared/components/templates/template-dynamic-card/template-dynamic-card.component';
import { TemplateEntryPageComponent } from 'app/nexus-shared/components/templates/template-entry-page/template-entry-page.component';
import { TemplateEntryPageContentComponent } from 'app/nexus-shared/components/templates/template-entry-page/template-entry-page-content.component';
import { TemplateEntryPageFooterComponent } from 'app/nexus-shared/components/templates/template-entry-page/template-entry-page-footer.component';
import { TemplateEntryPageHeaderComponent } from 'app/nexus-shared/components/templates/template-entry-page/template-entry-page-header.component';
import { TemplateGridComponent } from 'app/nexus-shared/components/templates/template-grid/template-grid.component';
import { TemplateGridContentComponent } from 'app/nexus-shared/components/templates/template-grid/template-grid-content.component';
import { TemplateGridHeaderComponent } from 'app/nexus-shared/components/templates/template-grid/template-grid-header.component';
import { TemplateReadonlyEditCardComponent } from 'app/nexus-shared/components/templates/template-readonly-edit-card/template-readonly-edit-card.component';
import { TemplateReadonlyEditCardViewContentComponent } from 'app/nexus-shared/components/templates/template-readonly-edit-card/template-readonly-edit-card-view-content.component';
import { TemplateReadonlyEditCardEditContentComponent } from 'app/nexus-shared/components/templates/template-readonly-edit-card/template-readonly-edit-card-edit-content.component';
import { TemplateReadonlyEditCardHeaderComponent } from 'app/nexus-shared/components/templates/template-readonly-edit-card/template-readonly-edit-card-header.component';
import { TemplateReadonlyEditCardFooterComponent } from 'app/nexus-shared/components/templates/template-readonly-edit-card/template-readonly-edit-card-footer.component';
import { TemplatePageComponent } from 'app/nexus-shared/components/templates/template-page/template-page.component';
import { TemplatePageContentComponent } from 'app/nexus-shared/components/templates/template-page/template-page-content.component';
import { TemplatePageHeaderComponent } from 'app/nexus-shared/components/templates/template-page/template-page-header.component';
import { TemplateViewComponent } from 'app/nexus-shared/components/templates/template-view/template-view.component';
import { TemplateViewContentComponent } from 'app/nexus-shared/components/templates/template-view/template-view-content.component';
import { TemplateViewHeaderComponent } from 'app/nexus-shared/components/templates/template-view/template-view-header.component';
import { GridFilterControlsComponent } from 'app/nexus-shared/components/controls/components/base-grid/grid-filter-controls/grid-filter-controls.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-community';
import 'ag-grid-enterprise';
import {
    DynamicAutocompleteComponent,
    DynamicCheckboxComponent,
    DynamicComboboxComponent,
    DynamicDatepickerComponent,
    DynamicDropdownComponent,
    DynamicInputComponent,
    DynamicMultiselectComponent,
    DynamicRadioGroupComponent,
    DynamicTextAreaComponent
} from 'app/nexus-shared/components/dynamic-form/components';
import { BaseDynamicComponentDirective, DynamicFormComponent, DynamicFormFieldDirective } from 'app/nexus-shared/components/dynamic-form';
import {
    AlertComponent,
    ConfirmComponent,
    ErrorComponent,
    FileUploadModalComponent,
    ModalComponent,
    ModalContentComponent,
    ModalFooterRightButtonsComponent,
    ModalHeaderComponent
} from 'app/nexus-shared/components/modal';
import { NexusModalFooterComponent } from 'app/nexus-shared/components/modal/components/modal-footer/nexus-modal-footer.component';
import { NexusModalHeaderComponent } from 'app/nexus-shared/components/modal/components/modal-header/nexus-modal-header.component';
import { NexusModalComponent } from 'app/nexus-shared/components/modal/nexus-modal.component';
import { LocationNamePipe } from 'app/nexus-shared/domain/locations/pipes/location-name.pipe';
import { LocationNamesPipe } from 'app/nexus-shared/domain/locations/pipes/location-names.pipe';
import { LocationSearchComponent } from 'app/nexus-shared/domain/locations/components/search/location-search.component';
import { CountryNamesPipe } from 'app/nexus-shared/domain/locations/pipes/country-names.pipe';
import {
    AirportSearchComponent,
    CitySearchComponent,
    CitySelectComponent,
    CountryMultiSelectComponent,
    CountrySearchComponent,
    CountrySelectComponent,
    CountySelectComponent,
    StateMultiSelectComponent,
    StateSearchComponent,
    StateSelectComponent
} from './domain/locations/components';
import { UsersGridComponent } from 'app/nexus-shared/domain/users/components/users-grid/users-grid.component';
import { UserRoleSubformComponent } from 'app/nexus-shared/domain/users/components/user-role-selector/user-role-subform.component';
import { UsersListComponent } from 'app/modules/users/components/users-list/users-list.component';
import { UserFormComponent } from 'app/nexus-shared/domain/users/components/user-form/user-form.component';
import { CompanyReadonlyEditComponent } from 'app/nexus-shared/domain/contacts/components/readonly-edit/company-readonly-edit/company-readonly-edit.component';
import { CompanyFormComponent } from 'app/nexus-shared/domain/contacts/components/forms/company-form/company-form.component';
import { CompanyPreviewIconComponent } from 'app/nexus-shared/domain/contacts/components/preview-icons/company-preview-icon/company-preview-icon.component';
import { CompanyPreviewModalComponent } from 'app/nexus-shared/domain/contacts/components/modals/company-preview-modal/company-preview-modal.component';
import { CompanyViewComponent } from 'app/nexus-shared/domain/contacts/components/views/company-view/company-view.component';
import { CompaniesGridComponent } from 'app/nexus-shared/domain/contacts/components/grids/companies-grid.component';
import { IndividualCompanyAccessGridContainerComponent } from 'app/nexus-shared/domain/contacts/components/grids/individual-company-access-grid-container.component';
import { CompanyRelationshipFormComponent } from 'app/nexus-shared/domain/contacts/components/forms/company-relationship-form/company-relationship-form.component';
import { CompanyRelationshipGridComponent } from 'app/nexus-shared/domain/contacts/components/grids/company-relationship-grid.component';
import { CompanySearchComponent } from 'app/nexus-shared/domain/contacts/components/search/company-search/company-search.component';
import { IndividualCompanyAccessModalComponent } from 'app/nexus-shared/domain/contacts/components/modals/individual-company-access-modal/individual-company-access-modal.component';
import { IndividualToCompanyAccessFormComponent } from 'app/nexus-shared/domain/contacts/components/forms/individual-company-access-form/individual-to-company-access-form.component';
import { IndividualCompanyAccessGridComponent } from 'app/nexus-shared/domain/contacts/components/grids/individual-company-access-grid.component';
import { IndividualFormComponent } from 'app/nexus-shared/domain/contacts/components/forms/individual-form/individual-form.component';
import { IndividualsGridComponent } from 'app/nexus-shared/domain/contacts/components/grids/individuals-grid.component';
import { IndividualPreviewIconComponent } from 'app/nexus-shared/domain/contacts/components/preview-icons/individual-preview-icon/individual-preview-icon.component';
import { IndividualPreviewModalComponent } from 'app/nexus-shared/domain/contacts/components/modals/individual-preview-modal/individual-preview-modal.component';
import { IndividualReadonlyEditComponent } from 'app/nexus-shared/domain/contacts/components/readonly-edit/individual-readonly-edit/individual-readonly-edit.component';
import { IndividualSearchComponent } from 'app/nexus-shared/domain/contacts/components/search/individual-search/individual-search.component';
import { IndividualViewComponent } from 'app/nexus-shared/domain/contacts/components/views/individual-view/individual-view.component';
import { TaxDetailComponent } from 'app/nexus-shared/domain/taxes/components/tax-detail/tax-detail.component';
import { GtnTaxesGridFilterPanelComponent } from 'app/nexus-shared/domain/taxes/components/taxes-grid/filter-panel/filter-panel.component';
import { GtnTaxRatesFilterPanelComponent } from 'app/nexus-shared/domain/taxes/components/tax-detail/filter-panel/rates-filter-panel.component';
import { TaxRateDetailComponent } from 'app/nexus-shared/domain/taxes/components/tax-rate-detail/tax-rate-detail.component';
import { TaxesGridComponent } from 'app/nexus-shared/domain/taxes';
import { PhoneNumberSubformComponent } from 'app/nexus-shared/domain/contacts/components/forms/phone-number-subform/phone-number-subform.component';
import { SelectModalComponent } from './components/modal/components/select-modal/select-modal.component';
import { ValidationErrorsModalComponent } from 'app/nexus-shared/components/modal/components/validation-errors-modal/validation-errors-modal.component';
import { TextAreaModalComponent } from './components/modal/components/text-area-modal/text-area-modal.component';
import { IndividualCompanyAccessCompositeFormComponent } from './domain/contacts/components/forms/individual-company-access-composite-form/individual-company-access-composite-form.component';
import { IndividualCompanyAccessCompositeModalComponent } from './domain/contacts/components/modals/individual-company-access-composite-modal/individual-company-access-composite-modal.component';
import { MonthDayPickerComponent } from './components/controls/components/month-day-picker/month-day-picker.component';
import { LoadingOverlayComponent } from './components/loading-overlay/loading-overlay.component';
import { CompanyCostCentersSubformComponent } from 'app/nexus-shared/domain/contacts/components/forms/company-cost-centers-sub-form/company-cost-centers-subform.component';
import { DynamicMatTabDirective } from 'app/nexus-shared/directives/dynamic-mat-tab.directive';
import { ResizeDirective } from './directives/gtn-resize.directive';
import { ChildResizeDirective } from './directives/gtn-child-resize.directive';
import { FileUploadComponent } from 'app/nexus-shared/components/controls/components/file-upload/file-upload.component';
import { PhoneNumberPipe } from 'app/nexus-shared/pipes/phone-number.pipe';
import { MyGTNPortalCompanySearchComponent } from 'app/nexus-shared/domain/contacts/components/search/portal-company-search/my-gtn-portal-company-search.component';
import { MyGTNPortalUserSearchComponent } from 'app/nexus-shared/domain/contacts/components/search/portal-user-search/my-gtn-portal-user-search.component';
import { AddressesSubformComponent } from './domain/contacts/components/forms/addresses-subform/addresses-subform.component';
import { AddressFormComponent } from './domain/contacts/components/forms/address-form/address-form.component';
import { AddressViewComponent } from './domain/contacts/components/views/address-view/address-view.component';
import { CurrencySelectComponent } from 'app/nexus-shared/domain/currency';
import { ContactIntegrationsSubformComponent } from './domain/contacts/components/forms/contact-integrations-subform/contact-integrations-subform.component';
import { ContactIntegrationsViewComponent } from './domain/contacts/components/views/contact-integrations-view/contact-integrations-view.component';
import { SlideOutTabsComponent } from 'app/nexus-shared/components/controls/components/slide-out-tabs/slide-out-tabs.component';
import { SlideOutTabsHeaderComponent } from 'app/nexus-shared/components/controls/components/slide-out-tabs/slide-out-tabs-header.component';
import { SlideOutTabsContentComponent } from 'app/nexus-shared/components/controls/components/slide-out-tabs/slide-out-tabs-content.component';
import { ImageCarouselComponent } from './components/controls/components/image-carousel/image-carousel.component';
import { ImageCarouselContentComponent } from 'app/nexus-shared/components/controls/components/image-carousel/image-carousel-content.component';
import { ImageCarouselHeaderComponent } from 'app/nexus-shared/components/controls/components/image-carousel/image-carousel-header.component';
import { ModalSplitViewContentComponent } from 'app/nexus-shared/components/modal/modal-split-view-content.component';
import { ButtonToggleGroupComponent } from './components/controls/components/button-toggle-group/button-toggle-group.component';
import { TemplateGridControlsRightComponent } from 'app/nexus-shared/components/templates/template-grid/template-grid-controls-right.component';
import { TemplateGridControlsLeftComponent } from 'app/nexus-shared/components/templates/template-grid/template-grid-controls-left.component';
import { CellTooltipComponent } from './components/controls/components/base-grid/cell-renderers/cell-tooltip/cell-tooltip.component';
import { ClientSearchComponent } from './domain/contacts/components/search/client-search/client-search.component';
import { UserReadonlyEditComponent } from './domain/users/components/user-readonly-edit/user-readonly-edit.component';
import { UserViewComponent } from './domain/users/components/readonly-edit/user-view/user-view.component';
import { DocumentsGridComponent } from 'app/nexus-shared/domain/documents/components/grids/documents-grid/documents-grid.component';
import { DocumentFormComponent } from 'app/nexus-shared/domain/documents/components/forms/document-form/document-form.component';
import { DocumentDetailModalComponent } from 'app/nexus-shared/domain/documents/components/modals/document-detail-modal/document-detail-modal.component';
import { FoldersGridComponent } from 'app/nexus-shared/domain/documents/components/grids/folders-grid/folders-grid.component';
import { FolderCellGroupRendererComponent } from './domain/documents/components/grids/folder-cell-group-renderer/folder-cell-group-renderer.component';
import { TrashBinGridComponent } from './domain/documents/components/grids/trash-bin-grid/trash-bin-grid.component';
import { DocumentsConfirmModalComponent } from 'app/nexus-shared/domain/documents/components/modals/documents-confirm-modal/documents-confirm-modal.component';
import { DocumentReadonlyEditComponent } from './domain/documents/components/readonly-edit/document-readonly-edit/document-readonly-edit.component';
import { DocumentDetailComponent } from 'app/nexus-shared/domain/documents/components/document/document-detail/document-detail.component';
import { DocumentViewComponent } from './domain/documents/components/views/document-view/document-view.component';
import { DocumentVersionsComponent } from './domain/documents/components/document/document-versions/document-versions.component';
import { DocumentHistoryComponent } from './domain/documents/components/document/document-history/document-history.component';
import { DocumentUploadModalComponent } from './domain/documents/components/modals/document-upload-modal/document-upload-modal.component';
import { BulkDocumentsFormComponent } from './domain/documents/components/forms/bulk-documents-form/bulk-documents-form.component';
import { BulkFileUploadModalComponent } from './domain/documents/components/modals/bulk-file-upload-modal/bulk-file-upload-modal.component';
import { TemplateDocumentGridComponent } from './domain/documents/components/grids/template-document-grid/template-document-grid.component';
import { FolderDocumentCellRendererComponent } from './domain/documents/components/grids/folder-document-cell-renderer/folder-document-cell-renderer.component';
import { CheckedOutFilesComponent } from './domain/documents/components/dashboard/checked-out-files/checked-out-files.component';
import { FolderFavoritesComponent } from './domain/documents/components/dashboard/folder-favorites/folder-favorites.component';
import { DocumentSearchGridComponent } from './domain/documents/components/grids/document-search-grid/document-search-grid.component';
import { RedirectComponent } from 'app/nexus-shared/components/redirect/redirect.component';
import { FolderDocumentComponent } from 'app/nexus-shared/domain/documents/components/folder-document/folder-document.component';
import { ClientIndividualFolderSearchComponent } from 'app/nexus-shared/domain/documents/components/search/client-individual-folder-search/client-individual-folder-search.component';
import { FolderBreadcrumbsComponent } from 'app/nexus-shared/domain/documents/components/folder-breadcrumbs/folder-breadcrumbs.component';
import { MatCardCutOutComponent } from './components/mat-card-cut-out/mat-card-cut-out.component';
import { DocumentsMoveConfirmModalComponent } from 'app/nexus-shared/domain/documents/components/modals/documents-move-confirm-modal/documents-move-confirm-modal.component';
import { TemplateFolderGridComponent } from './domain/documents/components/grids/template-folder-grid/template-folder-grid.component';
import { FolderDetailModalComponent } from './domain/documents/components/modals/folder-detail-modal/folder-detail-modal.component';
import { FolderFormComponent } from './domain/documents/components/forms/folder-form/folder-form.component';
import { CollapsibleTrayComponent } from './components/controls/components/collapsible-tray/collapsible-tray.component';
import { CollapsibleTrayContentComponent } from 'app/nexus-shared/components/controls/components/collapsible-tray/collapsible-tray-content.component';
import { DocumentProcessTrayComponent } from 'app/nexus-shared/domain/documents/components/document/document-progress-tray/document-process-tray.component';
import { ProgressStepperComponent } from './components/controls/components/progress-stepper/progress-stepper.component';
import { ProgressStepperContentComponent } from 'app/nexus-shared/components/controls/components/progress-stepper/progress-stepper-content.component';
import { ProgressStepperHeaderComponent } from 'app/nexus-shared/components/controls/components/progress-stepper/progress-stepper-header.component';
import { ProgressStepperControlsComponent } from 'app/nexus-shared/components/controls/components/progress-stepper/progress-stepper-controls.component';
import { ProgressStepComponent } from 'app/nexus-shared/components/controls/components/progress-stepper/progress-step.component';
import { CompanyIndividualAccessModalComponent } from './domain/contacts/components/modals/company-individual-access-modal/company-individual-access-modal.component';
import { FileSizePipe } from 'app/nexus-shared/pipes/file-size.pipe';
import { FileViewComponent } from './components/controls/components/file-view/file-view.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PdfFileViewComponent } from 'app/nexus-shared/components/controls/components/file-view/pdf-file-view/pdf-file-view.component';
import { ImageFileViewComponent } from 'app/nexus-shared/components/controls/components/file-view/image-file-view/image-file-view.component';
import { ObjectFileViewComponent } from 'app/nexus-shared/components/controls/components/file-view/object-file-view/object-file-view.component';
import { FileViewHeaderControlsComponent } from 'app/nexus-shared/components/controls/components/file-view/file-view-header-controls.component';
import { GridSettingsPanelComponent } from 'app/nexus-shared/components/controls/components/base-grid/tool-panels/grid-settings-panel/grid-settings-panel.component';
import { DocumentsGridStatusBarComponent } from './domain/documents/components/grids/documents-grid-status-bar/documents-grid-status-bar.component';
import { DragAndDropDirective } from 'app/nexus-shared/directives/drag-and-drop.directive';
import { CompanyRelationshipModalComponent } from './domain/contacts/components/modals/company-relationship-modal/company-relationship-modal.component';
import { TotalsCellComponent } from './components/controls/components/base-grid/cell-renderers/totals-cell/totals-cell.component';
import { SavedSettingModalComponent } from 'app/nexus-shared/components/modal/components/grid-settings-modal/saved-setting-modal.component';
import { CompanyCreateTemplateModalComponent } from 'app/nexus-shared/domain/contacts/components/modals/shared/company-create-template-modal/company-create-template-modal.component';
import { CompanyCreateDualFormContentComponent } from 'app/nexus-shared/domain/contacts/components/modals/shared/company-create-template-modal/company-create-dual-form-content.component';
import { IndividualCreateTemplateModalComponent } from 'app/nexus-shared/domain/contacts/components/modals/shared/individual-create-template-modal/individual-create-template-modal.component';
import { IndividualCreateDualFormContentComponent } from 'app/nexus-shared/domain/contacts/components/modals/shared/individual-create-template-modal/individual-create-dual-form-content.component';
import { TwoFactorModalComponent } from 'app/nexus-shared/components/modal/components/two-factor-modal/two-factor-modal.component';
import { IfDoesNotHaveAuthorizationDirective } from 'app/nexus-shared/directives/if-does-not-have-authorization.directive';
import { ContactSupportModalComponent } from 'app/nexus-shared/components/modal/components/contact-support-modal/contact-support-modal.component';
import { AUAModalComponent } from 'app/nexus-shared/components/modal/components/aua-modal/aua-modal.component';
import { SafeHtmlPipe } from 'app/nexus-shared/pipes/safe-html.pipe';
import { PhoneNumberInputComponent } from './components/controls/components/phone-number-input/phone-number-input.component';
import {
    IndividualCreateCompositeTemplateModalComponent
} from 'app/nexus-shared/domain/contacts/components/modals/shared/individual-create-composite-template-modal/individual-create-composite-template-modal.component';
import {
    IndividualCreateCompositeTemplateContentComponent
} from 'app/nexus-shared/domain/contacts/components/modals/shared/individual-create-composite-template-modal/individual-create-composite-content.component';
import { ApplicationLinksComponent } from './components/application-links/application-links.component';
import { FuzzyCompanyOptionsViewComponent } from 'app/nexus-shared/domain/contacts/components/views/fuzzy-company-options-view/fuzzy-company-options-view.component';
import { FuzzyIndividualOptionsViewComponent } from 'app/nexus-shared/domain/contacts/components/views/fuzzy-individual-options-view/fuzzy-individual-options-view.component';
import { PhoneNumberFormComponent } from './components/forms/phone-number-form/phone-number-form.component';
import { DynamicComponentComponent } from 'app/nexus-shared/components/dynamic-component/dynamic-component.component';
import { DynamicComponentHostComponent } from 'app/nexus-shared/components/dynamic-component/dynamic-component-host.component';
import { SharedSavedViewChipsComponent } from './components/controls/components/shared-saved-view-chips/shared-saved-view-chips.component';
import { ApplicationLogoPipe } from 'app/nexus-shared/pipes/application-logo.pipe';
import { IndividualRelationshipsGridComponent } from 'app/nexus-shared/domain/contacts/components/grids/individual-relationships-grid.component';
import { IndividualRelationshipModalComponent } from './domain/contacts/components/modals/individual-relationship-modal/individual-relationship-modal.component';
import { IndividualRelationshipFormComponent } from './domain/contacts/components/modals/individual-relationship-form/individual-relationship-form.component';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { PropNamePipe } from 'app/nexus-shared/pipes/prop-name.pipe';
import { BooleanCheckComponent } from 'app/nexus-shared/components/boolean-check/boolean-check.component';
import { FilterChipsComponent } from './components/controls/components/filter-chips/filter-chips.component';
import { ToolSummaryPanelComponent } from 'app/nexus-shared/domain/core/components/tool-summary-panel/tool-summary-panel.component';
import { GoogleLocationSearchComponent } from './components/controls/components/google-location-search/google-location-search.component';
import { DocumentSearchFiltersComponent } from './domain/documents/components/grids/document-search-grid/document-search-filters/document-search-filters.component';
import { DocumentSearchFiltersFormComponent } from './domain/documents/components/forms/document-search-filters-form/document-search-filters-form.component';
import { VerificationInputComponent } from './components/verification-input/verification-input.component';
import { EmailComponent } from './components/email/email.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { CalendarControlsRightComponent } from 'app/nexus-shared/components/calendar/calendar-controls-right.component';
import { CalendarYearViewComponent } from './components/calendar/calendar-year-view/calendar-year-view.component';
import { CalendarMonthViewComponent } from './components/calendar/calendar-month-view/calendar-month-view.component';
import { CalendarDayViewComponent } from './components/calendar/calendar-day-view/calendar-day-view.component';
import { CalendarDayContentComponent } from 'app/nexus-shared/components/calendar/calendar-day-view/calendar-day-content.component';
import { LastUpdatedStatusBarComponent } from 'app/nexus-shared/components/base-component/base-enterprise-grid-components/last-updated-status-bar/last-updated-status-bar.component';
import { ForgotPasswordModalComponent } from 'app/nexus-shared/components/modal/components/forgot-password-modal/forgot-password-modal.component';
import { UpdateEmailModalComponent } from 'app/nexus-shared/components/modal/components/update-email-modal/update-email-modal.component';
import { UserImpersonationModalComponent } from 'app/nexus-shared/components/modal/components/user-impersonation-modal/user-impersonation-modal.component';
import { UserSearchComponent } from 'app/nexus-shared/domain/users/components/user-search/user-search.component';
import { UserImpersonationsComponent } from 'app/modules/users/components/user-impersonations/user-impersonations.component';
import { UserImpersonationsGridComponent } from 'app/nexus-shared/domain/users/components/user-impersonations-grid/user-impersonations-grid.component';
import { IdleWarningModalComponent } from 'app/nexus-shared/components/modal/components/idle-warning-modal/idle-warning-modal.component';
import { DeviceWarningModalComponent } from 'app/nexus-shared/components/modal/components/device-warning-modal/device-warning-modal.component';
import { AuditingInfoComponent } from 'app/nexus-shared/components/auditing/auditing-info/auditing-info.component';
import { CalendarListContentComponent } from 'app/nexus-shared/components/calendar/calendar-list-content.component';
import { AgreementsGridComponent, AgreementsViewComponent, AgreementUploadFormComponent, AgreementUploadModalComponent, AgreementViewComponent } from './domain/agreements';
import { QuestionnaireTemplatesGridComponent } from './domain/questionnaires/components/grids/questionnaire-templates-grid/questionnaire-templates-grid.component';
import { QuestionnaireTemplateFormComponent } from './domain/questionnaires/components/forms/questionnaire-template-form/questionnaire-template-form.component';
import { SectionFormComponent } from './domain/questionnaires/components/forms/section-form/section-form.component';
import { SectionRowFormComponent } from './domain/questionnaires/components/forms/section-row-form/section-row-form.component';
import { SectionColumnFormComponent } from './domain/questionnaires/components/forms/section-column-form/section-column-form.component';
import { ColumnEditorHeaderTextFormComponent } from './domain/questionnaires/components/forms/column-editor-header-text-form/column-editor-header-text-form.component';
import { ColumnEditorUserInputFormComponent } from './domain/questionnaires/components/forms/column-editor-user-input-form/column-editor-user-input-form.component';
import { ColumnEditorActionFormComponent } from './domain/questionnaires/components/forms/column-editor-action-form/column-editor-action-form.component';
import { ColumnEditorTableFormComponent } from './domain/questionnaires/components/forms/column-editor-table-form/column-editor-table-form.component';
import { SectionColumnViewFormComponent } from './domain/questionnaires/components/forms/section-column-view-form/section-column-view-form.component';
import { SectionColumnViewTableFormComponent } from './domain/questionnaires/components/forms/section-column-view-table-form/section-column-view-table-form.component';
import { TableColumnViewFormComponent } from './domain/questionnaires/components/forms/table-column-view-form/table-column-view-form.component';
import { QuestionnaireDropdownsListComponent } from 'app/nexus-shared/domain/questionnaires/components/questionnaire-dropdowns-list/questionnaire-dropdowns-list.component';
import { DropdownFormComponent } from './domain/questionnaires/components/forms/dropdown-form/dropdown-form.component';
import {
    QuestionnaireDropdownOptionTypeSelectComponent
} from 'app/nexus-shared/domain/questionnaires/components/questionnaire-dropdown-option-type-select/questionnaire-dropdown-option-type-select.component';
import { QuestionnaireDropdownOptionSelectComponent } from 'app/nexus-shared/domain/questionnaires/components/questionnaire-dropdown-option-select/questionnaire-dropdown-option-select.component';
import { TaxJurisdictionSummaryGridComponent } from 'app/nexus-shared/domain/travel-calendar/components/grids/tax-jurisdiction-summary-grid/tax-jurisdiction-summary-grid.component';
import { TaxPreparerTravelReportGridComponent } from 'app/nexus-shared/domain/travel-calendar/components/grids/tax-preparer-travel-report/tax-preparer-travel-report-grid.component';
import { PresenceGridComponent } from 'app/nexus-shared/domain/travel-calendar/components/grids/presence-grid/presence-grid.component';
import { WorkDaySummaryDateGridComponent } from 'app/nexus-shared/domain/travel-calendar/components/grids/work-day-summary-date-grid/work-day-summary-date-grid.component';
import { ReportGridSelectComponent } from 'app/nexus-shared/domain/travel-calendar/components/report-grid-select/report-grid-select.component';
import { DataAcknowledgmentModalComponent } from 'app/nexus-shared/components/modal/components/data-acknowledgment-modal/data-acknowledgment-modal.component';
import { AssignedQuestionnairesGridComponent } from './domain/questionnaires/components/grids/assigned-questionnaires-grid/assigned-questionnaires-grid.component';
import { AssignQuestionnaireModalComponent } from 'app/nexus-shared/domain/questionnaires/components/modals/questionnaire-modal/assign-questionnaire-modal.component';
import { AssignQuestionnaireFormComponent } from './domain/questionnaires/components/forms/assign-questionnaire-form/assign-questionnaire-form.component';
import { IndividualGtnOrganizerFormComponent } from './domain/questionnaires/components/forms/individual-gtn-organizer-form/individual-gtn-organizer-form.component';
import { QuestionnaireFormComponent } from './domain/questionnaires/components/forms/questionnaire-form/questionnaire-form.component';
import { IndividualCchOrganizerFormComponent } from './domain/questionnaires/components/forms/individual-cch-organizer-form/individual-cch-organizer-form.component';
import { IndividualSupplementalOrganizerFormComponent } from './domain/questionnaires/components/forms/individual-supplemental-organizer-form/individual-supplemental-organizer-form.component';
import { ModalFooterLeftButtonsComponent } from 'app/nexus-shared/components/modal/modal-footer-left-buttons.component';
import { QuestionnaireIsTriggeredByActionPipe } from 'app/nexus-shared/pipes/questionnaire-is-triggered-by-action.pipe';
import { FocusDirective } from './directives/focus.directive';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AgreementsSearchFormComponent } from './domain/agreements/components/forms/agreements-search-form/agreements-search-form.component';
import { AgreementsSearchComponent } from './domain/agreements/components/grids/agreements-grid/agreements-search/agreements-search.component';
import { AgreementConsentFormComponent, AgreementIELFormComponent } from 'app/nexus-shared/domain/agreements';
import { FileViewHeaderCenterControlsComponent } from './components/controls/components/file-view/file-view-header-center-controls.component';
import { FolderIndividualAccessFormComponent } from './domain/documents/components/forms/folder-individual-access-form/folder-individual-access-form.component';
import { AgreementsConfirmModalComponent } from './domain/agreements/components/modals/agreements-confirm-modal/agreements-confirm-modal.component';
import { RoundingPipe } from 'app/nexus-shared/pipes/rounding.pipe';
import { MatStepperModule } from '@angular/material/stepper';
import { UpdateEmailComponent } from './components/update-email/update-email.component';


@NgModule({
    imports: [
        ClipboardModule,
        CommonModule,
        DragDropModule,
        FormsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatChipsModule,
        MatDialogModule,
        MatExpansionModule,
        MatIconModule,
        MatListModule,
        MatMenuModule,
        MatProgressBarModule,
        MatRippleModule,
        MatSelectModule,
        MatOptionModule,
        MatSidenavModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        ReactiveFormsModule,
        ScrollingModule,
        MatTreeModule,
        ReactiveFormsModule,
        /* GTN CONTROLS */
        AgGridModule,
        MatAutocompleteModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatSliderModule,
        MatSlideToggleModule,
        PdfViewerModule
    ],
    providers: [
        { provide: MAT_DIALOG_DATA, useValue: {} },
        {
            provide: MatDialogRef,
            useValue: {}
        },
        CurrencyPipe
    ],
    declarations: [
        AgreementsGridComponent,
        AgreementConsentFormComponent,
        AgreementIELFormComponent,
        AgreementUploadFormComponent,
        AgreementUploadModalComponent,
        AgreementViewComponent,
        AgreementsConfirmModalComponent,
        AgreementsSearchComponent,
        AgreementsSearchFormComponent,
        AgreementsViewComponent,
        ApplicationLogoPipe,
        BooleanCheckComponent,
        ChildResizeDirective,
        CityPipe,
        ClickOutsideDirective,
        CountryPipe,
        DashboardFrameComponent,
        DeleteSectionComponent,
        DisableAutofillDirective,
        DynamicMatTabDirective,
        DynamicComponentComponent,
        DynamicComponentHostComponent,
        DragAndDropDirective,
        EnumPipe,
        FileSizePipe,
        FilterByPipe,
        GetNamePipe,
        GtnCurrencyPipe,
        GtnDatePipe,
        FilterComponent,
        FilterDirective,
        FocusDirective,
        GtnHeightDirective,
        IfDoesNotHaveAuthorizationDirective,
        IfHasAnyAuthorizationDirective,
        IfHasAuthorizationDirective,
        LimitToPipe,
        LoadingOverlayComponent,
        LoadingOverlayDirective,
        MarketValuePipe,
        MatCardCutOutComponent,
        PercentPipe,
        PhoneNumberPipe,
        PropNamePipe,
        RedirectComponent,
        ResizeDirective,
        RoundingPipe,
        QuestionnaireIsTriggeredByActionPipe,
        SafeHtmlPipe,
        SelectListPipe,
        SomeDefaultValuePipe,
        SortByPipe,
        SpecializationNamePipe,
        StatePipe,
        TrimPipe,
        WeekdayPipe,
        YesNoPipe,
        DashboardFrameComponent,
        FilterComponent,
        FilterDirective,
        DisplayNoneComponent,
        FilterPanelComponent,
        FilterPanelButtonContentComponent,
        /* GTN CONTROLS */
        AuditingInfoComponent,
        CustomLoadingOverlayComponent,
        ApplicationLinksComponent,
        AutocompleteComponent,
        BackButtonComponent,
        ButtonToggleGroupComponent,
        CalendarComponent,
        CollapsibleTrayComponent,
        CollapsibleTrayContentComponent,
        SavedSettingModalComponent,
        GridSettingsPanelComponent,
        GridComponent,
        GridHeaderComponent,
        BreadcrumbsComponent,
        CalendarYearViewComponent,
        CalendarControlsRightComponent,
        CalendarListContentComponent,
        CalendarMonthViewComponent,
        CalendarDayViewComponent,
        CalendarDayContentComponent,
        CellTooltipComponent,
        CheckboxComponent,
        CheckboxColumnComponent,
        CheckboxHeaderComponent,
        ControlColumnComponent,
        CountryColumnComponent,
        CurrencyColumnComponent,
        DateCellEditorComponent,
        DatepickerComponent,
        DraggableItemListComponent,
        DropdownComponent,
        EditorColumnComponent,
        ExpandableCardComponent,
        FileDragDropComponent,
        FileUploadComponent,
        FileViewComponent,
        FileViewHeaderControlsComponent,
        FileViewHeaderCenterControlsComponent,
        FilterChipsComponent,
        FilterableTilesComponent,
        FilterableTilesContentComponent,
        FilterableTilesHeaderComponent,
        GoogleLocationSearchComponent,
        HeroNumberComponent,
        IconDropdownComponent,
        ImageFileViewComponent,
        InputCellEditorComponent,
        InputComponent,
        ImageCarouselComponent,
        ImageCarouselContentComponent,
        ImageCarouselHeaderComponent,
        LastUpdatedStatusBarComponent,
        ModalComponent,
        ModalContentComponent,
        ModalFooterRightButtonsComponent,
        ModalFooterLeftButtonsComponent,
        ModalHeaderComponent,
        ModalSplitViewContentComponent,
        MonthDayPickerComponent,
        MultiselectComponent,
        NotificationIndicatorComponent,
        NotificationItemComponent,
        NotificationsComponent,
        ObjectFileViewComponent,
        PdfFileViewComponent,
        PendingChangesModalComponent,
        PhoneNumberInputComponent,
        PhoneNumberFormComponent,
        ProgressBarComponent,
        ProgressStepperComponent,
        ProgressStepperContentComponent,
        ProgressStepperControlsComponent,
        ProgressStepperHeaderComponent,
        ProgressStepComponent,
        RadioComponent,
        RadioGroupComponent,
        SearchComponent,
        SliderComponent,
        SlideToggleComponent,
        SpeedDialButtonsComponent,
        TextAreaComponent,
        TimepickerComponent,
        TooltipComponent,
        ValidationComponent,
        ValidationErrorSectionComponent,
        ValidationIndicatorComponent,
        ValidationItemComponent,
        ValidationItemsComponent,
        ValidationPopOverComponent,
        NoRowsOverlayComponent,
        ReportHeaderComponent,
        ReportHeaderGroupComponent,
        SharedSavedViewChipsComponent,
        SlideOutTabsComponent,
        SlideOutTabsHeaderComponent,
        SlideOutTabsContentComponent,
        TimePickerDialogComponent,
        TemplateCardComponent,
        TemplateCardContentComponent,
        TemplateCardHeaderComponent,
        TemplateDynamicCardComponent,
        TemplateEntryPageComponent,
        TemplateEntryPageContentComponent,
        TemplateEntryPageFooterComponent,
        TemplateEntryPageHeaderComponent,
        TemplateGridComponent,
        TemplateGridContentComponent,
        TemplateGridControlsLeftComponent,
        TemplateGridControlsRightComponent,
        TemplateGridHeaderComponent,
        TemplateReadonlyEditCardComponent,
        TemplateReadonlyEditCardViewContentComponent,
        TemplateReadonlyEditCardEditContentComponent,
        TemplateReadonlyEditCardHeaderComponent,
        TemplateReadonlyEditCardFooterComponent,
        TemplatePageComponent,
        TemplatePageContentComponent,
        TemplatePageHeaderComponent,
        TemplateViewComponent,
        TemplateViewContentComponent,
        TemplateViewHeaderComponent,
        TotalsCellComponent,
        GridFilterControlsComponent,
        /* DYNAMIC FORM */
        BaseDynamicComponentDirective,
        DynamicFormFieldDirective,
        DynamicAutocompleteComponent,
        DynamicCheckboxComponent,
        DynamicComboboxComponent,
        DynamicDatepickerComponent,
        DynamicDropdownComponent,
        DynamicFormComponent,
        DynamicInputComponent,
        DynamicMultiselectComponent,
        DynamicRadioGroupComponent,
        DynamicTextAreaComponent,
        /* MODAL */
        AlertComponent,
        AUAModalComponent,
        ConfirmComponent,
        ContactSupportModalComponent,
        DataAcknowledgmentModalComponent,
        ForgotPasswordModalComponent,
        IdleWarningModalComponent,
        DeviceWarningModalComponent,
        UserImpersonationModalComponent,
        ErrorComponent,
        FileUploadModalComponent,
        SelectModalComponent,
        NexusModalFooterComponent,
        NexusModalHeaderComponent,
        NexusModalComponent,
        TextAreaModalComponent,
        TwoFactorModalComponent,
        ValidationErrorsModalComponent,
        /* CURRENCY */
        CurrencySelectComponent,
        /* CORE */
        EmailComponent,
        ToolSummaryPanelComponent,
        VerificationInputComponent,
        /* LOCATIONS */
        AirportSearchComponent,
        CitySelectComponent,
        CountySelectComponent,
        CountrySelectComponent,
        CountryMultiSelectComponent,
        StateSelectComponent,
        StateMultiSelectComponent,
        CountrySearchComponent,
        StateSearchComponent,
        CitySearchComponent,
        LocationNamePipe,
        LocationNamesPipe,
        LocationSearchComponent,
        CountryNamesPipe,
        /* USERS */
        UsersGridComponent,
        UserImpersonationsGridComponent,
        UserSearchComponent,
        UserRoleSubformComponent,
        UserImpersonationsComponent,
        UsersListComponent,
        UserFormComponent,
        UserReadonlyEditComponent,
        UserViewComponent,
        UpdateEmailModalComponent,
        UpdateEmailComponent,
        /* CONTACTS */
        ClientSearchComponent,
        CompanyCostCentersSubformComponent,
        CompanyCreateDualFormContentComponent,
        CompanyCreateTemplateModalComponent,
        CompanyFormComponent,
        CompanyIndividualAccessModalComponent,
        CompanyPreviewIconComponent,
        CompanyPreviewModalComponent,
        CompanyViewComponent,
        CompaniesGridComponent,
        IndividualCompanyAccessGridContainerComponent,
        CompanyReadonlyEditComponent,
        CompanyRelationshipFormComponent,
        CompanyRelationshipGridComponent,
        CompanyRelationshipModalComponent,
        CompanySearchComponent,
        ContactIntegrationsSubformComponent,
        FuzzyIndividualOptionsViewComponent,
        FuzzyCompanyOptionsViewComponent,
        IndividualCreateTemplateModalComponent,
        IndividualCreateDualFormContentComponent,
        IndividualCreateCompositeTemplateModalComponent,
        IndividualCreateCompositeTemplateContentComponent,
        IndividualCompanyAccessCompositeFormComponent,
        IndividualCompanyAccessCompositeModalComponent,
        IndividualCompanyAccessModalComponent,
        IndividualToCompanyAccessFormComponent,
        IndividualCompanyAccessGridComponent,
        IndividualFormComponent,
        IndividualsGridComponent,
        IndividualPreviewIconComponent,
        IndividualPreviewModalComponent,
        IndividualReadonlyEditComponent,
        IndividualRelationshipsGridComponent,
        IndividualRelationshipModalComponent,
        IndividualRelationshipFormComponent,
        IndividualSearchComponent,
        IndividualViewComponent,
        MyGTNPortalCompanySearchComponent,
        MyGTNPortalUserSearchComponent,
        PhoneNumberSubformComponent,
        /* TAXES */
        TaxDetailComponent,
        GtnTaxesGridFilterPanelComponent,
        GtnTaxRatesFilterPanelComponent,
        TaxesGridComponent,
        TaxRateDetailComponent,
        AddressesSubformComponent,
        AddressFormComponent,
        AddressViewComponent,
        ContactIntegrationsViewComponent,
        /* DOCUMENTS */
        BulkDocumentsFormComponent,
        BulkFileUploadModalComponent,
        ClientIndividualFolderSearchComponent,
        CheckedOutFilesComponent,
        DocumentsConfirmModalComponent,
        DocumentDetailComponent,
        DocumentsGridComponent,
        DocumentsGridStatusBarComponent,
        DocumentFormComponent,
        DocumentDetailModalComponent,
        DocumentsMoveConfirmModalComponent,
        DocumentProcessTrayComponent,
        DocumentReadonlyEditComponent,
        DocumentSearchGridComponent,
        DocumentSearchFiltersComponent,
        DocumentSearchFiltersFormComponent,
        DocumentViewComponent,
        DocumentVersionsComponent,
        DocumentHistoryComponent,
        DocumentUploadModalComponent,
        FolderBreadcrumbsComponent,
        FoldersGridComponent,
        FolderCellGroupRendererComponent,
        FolderDocumentCellRendererComponent,
        FolderFavoritesComponent,
        FolderDocumentComponent,
        FolderDetailModalComponent,
        FolderFormComponent,
        TrashBinGridComponent,
        TemplateDocumentGridComponent,
        TemplateFolderGridComponent,
        GoogleLocationSearchComponent,
        DocumentSearchFiltersComponent,
        DocumentSearchFiltersFormComponent,

        /* QUESTIONNAIRES */
        ColumnEditorHeaderTextFormComponent,
        ColumnEditorUserInputFormComponent,
        ColumnEditorActionFormComponent,
        ColumnEditorTableFormComponent,
        QuestionnaireDropdownsListComponent,
        DropdownFormComponent,
        QuestionnaireDropdownOptionTypeSelectComponent,
        QuestionnaireDropdownOptionSelectComponent,
        QuestionnaireTemplatesGridComponent,
        QuestionnaireTemplateFormComponent,
        SectionFormComponent,
        SectionRowFormComponent,
        SectionColumnFormComponent,
        SectionColumnViewFormComponent,
        SectionColumnViewTableFormComponent,
        TableColumnViewFormComponent,
        AssignedQuestionnairesGridComponent,
        AssignQuestionnaireModalComponent,
        AssignQuestionnaireFormComponent,
        IndividualGtnOrganizerFormComponent,
        QuestionnaireFormComponent,
        IndividualCchOrganizerFormComponent,
        IndividualSupplementalOrganizerFormComponent,

        /* TRAVEL CALENDAR */
        PresenceGridComponent,
        TaxJurisdictionSummaryGridComponent,
        TaxPreparerTravelReportGridComponent,
        WorkDaySummaryDateGridComponent,
        ReportGridSelectComponent,
        FolderIndividualAccessFormComponent,
    ],
    exports: [
        AgreementsGridComponent,
        AgreementConsentFormComponent,
        AgreementIELFormComponent,
        AgreementUploadFormComponent,
        AgreementUploadModalComponent,
        AgreementViewComponent,
        AgreementsConfirmModalComponent,
        AgreementsSearchComponent,
        AgreementsSearchFormComponent,
        AgreementsViewComponent,
        ApplicationLogoPipe,
        BooleanCheckComponent,
        ChildResizeDirective,
        CityPipe,
        ClickOutsideDirective,
        ClipboardModule,
        CommonModule,
        CountryPipe,
        DashboardFrameComponent,
        DeleteSectionComponent,
        DisableAutofillDirective,
        DynamicMatTabDirective,
        DynamicComponentComponent,
        DragAndDropDirective,
        DragDropModule,
        EnumPipe,
        FileSizePipe,
        FilterByPipe,
        FormsModule,
        GetNamePipe,
        GtnCurrencyPipe,
        GtnDatePipe,
        FilterComponent,
        FilterDirective,
        GtnHeightDirective,
        IfDoesNotHaveAuthorizationDirective,
        IfHasAnyAuthorizationDirective,
        IfHasAuthorizationDirective,
        LimitToPipe,
        LoadingOverlayComponent,
        LoadingOverlayDirective,
        MarketValuePipe,
        MatCardCutOutComponent,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatChipsModule,
        MatDialogModule,
        MatExpansionModule,
        MatIconModule,
        MatListModule,
        MatMenuModule,
        MatProgressBarModule,
        MatRippleModule,
        MatSidenavModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        PercentPipe,
        PhoneNumberPipe,
        PropNamePipe,
        ReactiveFormsModule,
        ResizeDirective,
        RoundingPipe,
        RedirectComponent,
        ScrollingModule,
        SafeHtmlPipe,
        SelectListPipe,
        SomeDefaultValuePipe,
        SortByPipe,
        SpecializationNamePipe,
        StatePipe,
        TrimPipe,
        WeekdayPipe,
        YesNoPipe,
        FilterComponent,
        FilterDirective,
        DisplayNoneComponent,
        FilterPanelComponent,
        FilterPanelButtonContentComponent,
        /* GTN CONTROLS */
        AuditingInfoComponent,
        ApplicationLinksComponent,
        AgGridModule,
        AutocompleteComponent,
        BackButtonComponent,
        ButtonToggleGroupComponent,
        CalendarComponent,
        CalendarControlsRightComponent,
        CalendarListContentComponent,
        CollapsibleTrayComponent,
        CollapsibleTrayContentComponent,
        GridComponent,
        GridHeaderComponent,
        BreadcrumbsComponent,
        CalendarYearViewComponent,
        CalendarMonthViewComponent,
        CalendarDayViewComponent,
        CalendarDayContentComponent,
        CheckboxComponent,
        CheckboxColumnComponent,
        CheckboxHeaderComponent,
        ControlColumnComponent,
        CountryColumnComponent,
        CurrencyColumnComponent,
        CustomLoadingOverlayComponent,
        DateCellEditorComponent,
        DatepickerComponent,
        DraggableItemListComponent,
        DropdownComponent,
        EditorColumnComponent,
        ExpandableCardComponent,
        FileDragDropComponent,
        FileUploadComponent,
        FileViewComponent,
        FileViewHeaderControlsComponent,
        FileViewHeaderCenterControlsComponent,
        FilterChipsComponent,
        FilterableTilesComponent,
        FilterableTilesContentComponent,
        FilterableTilesHeaderComponent,
        HeroNumberComponent,
        IconDropdownComponent,
        ImageFileViewComponent,
        ImageCarouselComponent,
        ImageCarouselHeaderComponent,
        ImageCarouselContentComponent,
        InputCellEditorComponent,
        InputComponent,
        ModalComponent,
        ModalContentComponent,
        ModalFooterRightButtonsComponent,
        ModalFooterLeftButtonsComponent,
        ModalHeaderComponent,
        ModalSplitViewContentComponent,
        MonthDayPickerComponent,
        MultiselectComponent,
        NotificationIndicatorComponent,
        NotificationItemComponent,
        NotificationsComponent,
        ObjectFileViewComponent,
        PdfFileViewComponent,
        PendingChangesModalComponent,
        PhoneNumberInputComponent,
        PhoneNumberFormComponent,
        ProgressBarComponent,
        ProgressStepperComponent,
        ProgressStepperContentComponent,
        ProgressStepperControlsComponent,
        ProgressStepperHeaderComponent,
        ProgressStepComponent,
        RadioComponent,
        RadioGroupComponent,
        SearchComponent,
        SharedSavedViewChipsComponent,
        SliderComponent,
        SlideOutTabsComponent,
        SlideOutTabsHeaderComponent,
        SlideOutTabsContentComponent,
        SlideToggleComponent,
        SpeedDialButtonsComponent,
        TextAreaComponent,
        TimepickerComponent,
        TooltipComponent,
        ValidationComponent,
        ValidationErrorSectionComponent,
        ValidationPopOverComponent,
        MatAutocompleteModule,
        MatFormFieldModule,
        MatInputModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatStepperModule,
        ReportHeaderComponent,
        ReportHeaderGroupComponent,
        TemplatePageContentComponent,
        TemplatePageComponent,
        TemplatePageHeaderComponent,
        TemplateCardComponent,
        TemplateCardContentComponent,
        TemplateCardHeaderComponent,
        TemplateDynamicCardComponent,
        TemplateEntryPageComponent,
        TemplateEntryPageContentComponent,
        TemplateEntryPageFooterComponent,
        TemplateEntryPageHeaderComponent,
        TemplateGridComponent,
        TemplateGridContentComponent,
        TemplateGridControlsLeftComponent,
        TemplateGridControlsRightComponent,
        TemplateGridHeaderComponent,
        TemplateReadonlyEditCardComponent,
        TemplateReadonlyEditCardViewContentComponent,
        TemplateReadonlyEditCardEditContentComponent,
        TemplateReadonlyEditCardHeaderComponent,
        TemplateReadonlyEditCardFooterComponent,
        TemplateViewComponent,
        TemplateViewContentComponent,
        TemplateViewHeaderComponent,
        TotalsCellComponent,
        /* DYNAMIC FORM */
        BaseDynamicComponentDirective,
        DynamicFormFieldDirective,
        DynamicFormComponent,
        /* MODAL */
        AUAModalComponent,
        ContactSupportModalComponent,
        DataAcknowledgmentModalComponent,
        NexusModalComponent,
        NexusModalFooterComponent,
        NexusModalHeaderComponent,
        IdleWarningModalComponent,
        DeviceWarningModalComponent,
        TextAreaModalComponent,
        TwoFactorModalComponent,
        /* CORE */
        ToolSummaryPanelComponent,
        VerificationInputComponent,
        AddressViewComponent,
        EmailComponent,
        /* LOCATIONS */
        AirportSearchComponent,
        CitySelectComponent,
        CountySelectComponent,
        CountrySelectComponent,
        CountryMultiSelectComponent,
        StateSelectComponent,
        StateMultiSelectComponent,
        CountrySearchComponent,
        StateSearchComponent,
        CitySearchComponent,
        LocationNamePipe,
        LocationNamesPipe,
        LocationSearchComponent,
        CountryNamesPipe,
        /* CONTACTS */
        AddressFormComponent,
        AddressesSubformComponent,
        ClientSearchComponent,
        CompaniesGridComponent,
        CompanyCostCentersSubformComponent,
        CompanyCreateDualFormContentComponent,
        CompanyCreateTemplateModalComponent,
        CompanyFormComponent,
        CompanyIndividualAccessModalComponent,
        CompanyPreviewIconComponent,
        CompanyPreviewModalComponent,
        CompanyReadonlyEditComponent,
        CompanyRelationshipFormComponent,
        CompanyRelationshipGridComponent,
        CompanyRelationshipModalComponent,
        CompanySearchComponent,
        CompanyViewComponent,
        FuzzyCompanyOptionsViewComponent,
        FuzzyIndividualOptionsViewComponent,
        IndividualCompanyAccessCompositeFormComponent,
        IndividualCompanyAccessCompositeModalComponent,
        IndividualCompanyAccessGridContainerComponent,
        IndividualCompanyAccessModalComponent,
        IndividualCreateCompositeTemplateContentComponent,
        IndividualCreateCompositeTemplateModalComponent,
        IndividualCreateDualFormContentComponent,
        IndividualCreateTemplateModalComponent,
        IndividualFormComponent,
        IndividualPreviewIconComponent,
        IndividualPreviewModalComponent,
        IndividualReadonlyEditComponent,
        IndividualRelationshipFormComponent,
        IndividualRelationshipModalComponent,
        IndividualRelationshipsGridComponent,
        IndividualSearchComponent,
        IndividualToCompanyAccessFormComponent,
        IndividualViewComponent,
        IndividualsGridComponent,
        MyGTNPortalCompanySearchComponent,
        MyGTNPortalUserSearchComponent,
        PhoneNumberSubformComponent,
        /* CURRENCY */
        CurrencySelectComponent,
        /* TAXES */
        TaxDetailComponent,
        GtnTaxesGridFilterPanelComponent,
        GtnTaxRatesFilterPanelComponent,
        TaxesGridComponent,
        TaxRateDetailComponent,
        /* DOCUMENTS */
        BulkFileUploadModalComponent,
        ClientIndividualFolderSearchComponent,
        CheckedOutFilesComponent,
        DocumentProcessTrayComponent,
        DocumentsConfirmModalComponent,
        DocumentDetailComponent,
        DocumentFormComponent,
        DocumentsGridComponent,
        DocumentDetailModalComponent,
        DocumentUploadModalComponent,
        DocumentSearchGridComponent,
        FolderBreadcrumbsComponent,
        FolderFavoritesComponent,
        FoldersGridComponent,
        FolderDocumentComponent,
        FolderCellGroupRendererComponent,
        TemplateDocumentGridComponent,
        TrashBinGridComponent,
        /* Users */
        UpdateEmailModalComponent,
        UpdateEmailComponent,
        UserImpersonationModalComponent,
        ForgotPasswordModalComponent,
        UserFormComponent,
        UserSearchComponent,
        UserRoleSubformComponent,
        UserReadonlyEditComponent,
        ContactSupportModalComponent,

        /* Questionnaires */
        ColumnEditorHeaderTextFormComponent,
        ColumnEditorUserInputFormComponent,
        ColumnEditorActionFormComponent,
        ColumnEditorTableFormComponent,
        QuestionnaireTemplatesGridComponent,
        QuestionnaireTemplateFormComponent,
        QuestionnaireDropdownsListComponent,
        QuestionnaireDropdownOptionTypeSelectComponent,
        QuestionnaireDropdownOptionSelectComponent,
        SectionFormComponent,
        SectionRowFormComponent,
        SectionColumnFormComponent,
        SectionColumnViewFormComponent,
        SectionColumnViewTableFormComponent,
        TableColumnViewFormComponent,
        AssignedQuestionnairesGridComponent,
        AssignQuestionnaireModalComponent,
        AssignQuestionnaireFormComponent,
        QuestionnaireFormComponent,
        DropdownFormComponent,

        /* TRAVEL CALENDAR */
        PresenceGridComponent,
        TaxJurisdictionSummaryGridComponent,
        TaxPreparerTravelReportGridComponent,
        WorkDaySummaryDateGridComponent,
        ReportGridSelectComponent,
    ]
})
export class NexusSharedModule {
}
