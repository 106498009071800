import { BaseService } from 'app/nexus-core/index';
import { Subject } from 'rxjs';
import { SectionModel } from 'app/nexus-shared/domain/questionnaires/models/section.model';
import { Injectable } from '@angular/core';
import { SectionActionEventModel } from 'app/nexus-shared/domain/questionnaires/models/section-action-event.model';
import { NumericActionEventModel } from 'app/nexus-shared/domain/questionnaires/models/numeric-action-event.model';

@Injectable()
export class QuestionnaireEventService extends BaseService {
    questionnaireSections$: Subject<SectionModel[]> = new Subject<SectionModel[]>();
    questionnaireSections: SectionModel[];
    sectionColumnAction$: Subject<SectionActionEventModel> = new Subject<SectionActionEventModel>();
    sectionColumnDropdownActionValueChange$: Subject<void> = new Subject<void>();
    sectionColumnNumericActionValueChange$: Subject<NumericActionEventModel> = new Subject<NumericActionEventModel>();

    setQuestionnaireSections(sections: SectionModel[]): void {
        this.questionnaireSections = sections;
        this.questionnaireSections$.next(this.questionnaireSections);
    }

    getQuestionnaireSections(): SectionModel[] {
        return this.questionnaireSections;
    }
}
