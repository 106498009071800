import { Component, OnChanges } from '@angular/core';
import { IndividualSettingService } from 'app/nexus-core/services/domain/core/individual-setting.service';
import { ColDef } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AgGridColDefHelper } from 'app/nexus-core/helpers/ag-grid-col-def.helper';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { BaseTravelCalendarReportingGridComponent } from 'app/nexus-shared/domain/travel-calendar/components/grids/base-grids/base-travel-calendar-reporting-grid.component';
import { SimpleChangesTyped } from 'app/nexus-shared/models/simple-changes-typed.type';

@Component({
    selector: 'gtn-tax-jurisdiction-summary-grid',
    templateUrl: './tax-jurisdiction-summary-grid.component.html',
    styleUrls: ['./tax-jurisdiction-summary-grid.component.scss']
})
export class TaxJurisdictionSummaryGridComponent extends BaseTravelCalendarReportingGridComponent<TaxJurisdictionSummaryGridViewModel> implements OnChanges {
    constructor(protected individualSettingService: IndividualSettingService) {
        super(individualSettingService);
    }

    ngOnChanges(changes: SimpleChangesTyped<this>) {
        if (changes?.searchModel && !changes.searchModel.isFirstChange()) {
            this.refreshGridData();
        }
    }

    protected setColumnDefinitions(): ColDef[] {
        return [
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOf<TaxJurisdictionSummaryGridViewModel>('location'), 'Location'),
            AgGridColDefHelper.getNumberColDef(ObjectHelper.nameOf<TaxJurisdictionSummaryGridViewModel>('totalDaysWorked'), 'Total Days Worked'),
            AgGridColDefHelper.getNumberColDef(ObjectHelper.nameOf<TaxJurisdictionSummaryGridViewModel>('totalDaysPresence'), 'Total Days Presence'),
        ];
    }

    protected setRowData(): Observable<TaxJurisdictionSummaryGridViewModel[]> {
        return this.travelCalendarReportService.getTaxJurisdictionSummaryReport(this.searchModel).pipe(map(res => {
            const data = [];
            Object.keys(res.jurisdictions).forEach(location => {
                const model = new TaxJurisdictionSummaryGridViewModel();
                model.location = location;
                model.totalDaysPresence = res.jurisdictions[location].totalDaysPresence;
                model.totalDaysWorked = res.jurisdictions[location].totalDaysWorked;
                data.push(model);
            });
            return data;
        }));
    }
}

export class TaxJurisdictionSummaryGridViewModel {
    location: string;
    totalDaysPresence: number;
    totalDaysWorked: number;
}
