import { Component, Input } from '@angular/core';
import { ValidationModel } from 'app/nexus-shared/models';

@Component({
    selector: 'gtn-validation-item',
    templateUrl: './validation-item.component.html',
    styleUrls: ['./validation-item.component.scss']
})
export class ValidationItemComponent {
    @Input() canShowDelimiter: boolean;
    @Input() isLinkActive: boolean;
    @Input() validationModel: ValidationModel;

    constructor() {
    }
}
