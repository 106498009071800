<gtn-modal class="modal-medium" headerText="Questionnaire" cancelButtonText="Close" okButtonText="Save"
    [disableOkButton]="(!formGroupRef.dirty && (!questionnaireType || isUpdate)) || isSaving"
    [validationModels]="validationModels"
    (cancelClick)="onCancelClicked()" (okClick)="onSaveClicked()">
    <gtn-modal-content>
        <div class="row">
            <gtn-dropdown class="col-md-6" label="Questionnaire type" [idOnly]="true" [(ngModel)]="questionnaireType"
                [options]="questionnaireTypes" [suppressInitialChangeEvent]="isUpdate" [disabled]="isUpdate"
                (change)="onQuestionnaireTypeChanged($event['value'])"></gtn-dropdown>
        </div>
        <gtn-individual-gtn-organizer-form *ngIf="questionnaireType === questionnaireTypesEnum.GTNOrganizer"
            [(value)]="value" [(formGroupRef)]="formGroupRef" [disableIndividualSearch]="disableIndividualSearch"
            [questionnaireTemplates]="questionnaireTemplates" [questionnaireType]="questionnaireType"
            (formGroupRefChange)="onFormGroupRefChanged($event)">
        </gtn-individual-gtn-organizer-form>
        <gtn-individual-supplemental-organizer-form
            *ngIf="questionnaireType === questionnaireTypesEnum.SupplementalQuestionnaire"
            [(value)]="value" [(formGroupRef)]="formGroupRef" [disableIndividualSearch]="disableIndividualSearch"
            [questionnaireTemplates]="questionnaireTemplates" [questionnaireType]="questionnaireType"
            (formGroupRefChange)="onFormGroupRefChanged($event)">
        </gtn-individual-supplemental-organizer-form>
        <gtn-individual-cch-organizer-form *ngIf="questionnaireType === questionnaireTypesEnum.CCHOrganizer"
            [(value)]="value" [(formGroupRef)]="formGroupRef" [disableIndividualSearch]="disableIndividualSearch"
            [questionnaireTemplates]="questionnaireTemplates" [questionnaireType]="questionnaireType"
            (formGroupRefChange)="onFormGroupRefChanged($event)">
        </gtn-individual-cch-organizer-form>
        <ng-container *ngIf="dupeIndividualCchOrganizers?.length">
            <div>
                Duplicate username has been found for
                <ng-container
                    *ngFor="let dupe of dupeIndividualCchOrganizers; index as i;">{{ dupe.questionnaire.individual.fullName }}
                    <span *ngIf="i !== dupeIndividualCchOrganizers?.length -1">|&nbsp;</span>
                </ng-container>
            </div>
        </ng-container>
    </gtn-modal-content>
</gtn-modal>
