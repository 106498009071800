import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'gtnMarketValue',
    pure: true
})
export class MarketValuePipe implements PipeTransform {
    transform(value: number): any {
        if (typeof value === 'number' && value !== 0) {
            if (value > 1) {
                return Math.round(value * 100) / 100;
            } else {
                return Math.round(value * 10000) / 10000;
            }
        }

        return value;
    }

}
