import { Component } from '@angular/core';
import { BaseViewComponent } from 'app/nexus-shared/components/base-component/base-view.component';
import { ContactIntegrationTypesEnum } from '../../../enums/contact-integration-types.enum';
import { ContactIntegrationModel } from '../../../models/contact-integration.model';

@Component({
    selector: 'gtn-contact-integrations-view',
    templateUrl: './contact-integrations-view.component.html',
    styleUrls: ['./contact-integrations-view.component.scss']
})
export class ContactIntegrationsViewComponent extends BaseViewComponent<ContactIntegrationModel> {
    contactIntegrationType = ContactIntegrationTypesEnum;
}
