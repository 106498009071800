import { FilterTypeEnum } from 'app/nexus-shared/components/controls/shared/enums/filter-type.enum';
import { SelectListInterface } from 'app/nexus-shared/index';
import { ColDef } from 'ag-grid-community';

export class FilterModel {
    controlType: FilterTypeEnum;
    options: SelectListInterface[];
    field: string;
    label: string;
    idKey: string;
    isFilterActive: boolean;
    filterObject: any; //ag grid type for its filter!
    appliedFilter: SelectListInterface;

    constructor(model: ColDef = null) {
        this.controlType = model.filterParams.type;
        this.options = null;
        this.field = model?.field || null;
        this.label = model?.headerName || null;
        this.isFilterActive = null;
        this.filterObject = null;
        this.appliedFilter = <SelectListInterface>{ id: model?.field };
        this.idKey = 'id';
    }
}
