import { Injectable } from '@angular/core';
import { BaseHttpService, BaseService } from 'app/nexus-core/services';
import { Observable } from "rxjs";
import { environment } from "environments/environment";
import { AgreementsCompanyModel, AgreementsVendorSearchModel } from 'app/nexus-shared/domain/agreements';
import { IAgreementsVendorServiceInterface } from '.';

@Injectable()
export class AgreementsVendorService extends BaseService implements IAgreementsVendorServiceInterface {
    private apiUrl = environment().webAPIUrls.agreements + 'agreements-vendors';

    constructor(private baseHttpService: BaseHttpService) {
        super();
    }

    search(searchModel: AgreementsVendorSearchModel): Observable<AgreementsCompanyModel[]> {
        return this.baseHttpService.post(`${this.apiUrl}/search`, searchModel);
    }
}
