import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { Router } from '@angular/router';
import { NexusRoutesConstants } from 'app/nexus-routing/nexus-routes.constants';

export abstract class BasePageComponent extends BaseComponent {

    static navigateThroughRedirectComponent(router: Router, redirectUrl: [string] = NexusRoutesConstants.redirect.routing.navigationRoute(), redirectToUrl: string = null) {
        const url = redirectToUrl ?? router.url;
        router.navigate(redirectUrl, { skipLocationChange: true }).then(t => {
            router.navigateByUrl(url);
        });
    }
}
