import { ValidationModel } from 'app/nexus-shared/index';
import { ComponentCanDeactivate, ValidationErrorHelper } from 'app/nexus-core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { PendingChangesModel } from 'app/nexus-shared/models/pending-changes.model';
import { BaseComponent } from './base.component';
import { ViewModesEnum } from 'app/nexus-shared/enums/view-modes.enum';
import { CardModesEnum } from 'app/nexus-shared/enums/card-modes.enum';

@Component({ template: '' })
export abstract class BaseEntryComponent<T> extends BaseComponent implements ComponentCanDeactivate {
    @Input() formGroupRef: UntypedFormGroup = new UntypedFormGroup({});
    @Output() formGroupRefChange = new EventEmitter<UntypedFormGroup>();

    @Input() isSaving: boolean = false;
    @Output() isSavingChange: EventEmitter<boolean> = new EventEmitter();

    @Input() value: T = null;

    @Input() validationModels: ValidationModel[] = [];
    @Output() validationModelsChange: EventEmitter<ValidationModel[]> = new EventEmitter();

    @Output() saveClick: EventEmitter<void> = new EventEmitter();
    @Output() cancelClick: EventEmitter<void> = new EventEmitter();
    @Output() deleteClick: EventEmitter<void> = new EventEmitter();

    @Input() pendingChangesPageRedirect: PendingChangesModel = null;

    @Input() pendingChanges: PendingChangesModel = new PendingChangesModel();
    @Output() pendingChangesChange = new EventEmitter<PendingChangesModel>();
    @Output() pendingChangesDiscardClick = new EventEmitter<PendingChangesModel>();

    viewModes = ViewModesEnum;
    cardModes = CardModesEnum;

    isSaveButtonDisabled(): boolean {
        return !this.formGroupRef.valid || !this.formGroupRef.dirty || this.isSaving;
    }

    validateEntry(): boolean {
        const result = !this.isSaveButtonDisabled();

        if (result) {
            this.isSaving = true;
            this.isSavingChange.emit(true);
            this.validationModels = [];
        }

        return result;
    }

    onFormGroupRefChanged(formGroup: UntypedFormGroup): void {
        this.formGroupRef = formGroup;
        this.formGroupRefChange.emit(this.formGroupRef);
    }

    onClosePendingChangesClicked(): void {
        this.pendingChanges.showPendingChanges = false;
        this.pendingChanges.nextTabIndex = null;
        this.pendingChanges.nextUrl = null;
        this.pendingChangesChange.emit(this.pendingChanges);
    }

    onDiscardPendingChangesClicked(): void {
        this.pendingChanges.showPendingChanges = false;
        this.formGroupRef.markAsPristine();
        if (this.pendingChanges.nextUrl && this.pendingChanges.router) {
            this.pendingChanges.router.navigateByUrl(this.pendingChanges.nextUrl);
        } else {
            this.pendingChangesDiscardClick.emit(this.pendingChanges);
        }
    }

    canDeactivate(nextUrl: string, router: Router): boolean {
        if (this.formGroupRef.dirty) {
            this.pendingChanges.showPendingChanges = true;
            this.pendingChanges.nextUrl = nextUrl;
            this.pendingChanges.router = router;
            this.pendingChangesChange.emit(this.pendingChanges);
        }

        return !this.formGroupRef.dirty;
    }

    protected onSaveSuccess(): void {
        this.formGroupRef.markAsPristine();
        this.formGroupRef.updateValueAndValidity({ emitEvent: false });
        this.isSaving = false;
        this.isSavingChange.emit(this.isSaving);
        this.pendingChanges.showPendingChanges = false;
        this.pendingChangesChange.emit(this.pendingChanges);
        this.validationModels = [];
        this.validationModelsChange.emit(this.validationModels);

        if (this.pendingChanges.nextUrl && this.pendingChanges.router) {
            this.pendingChanges.router.navigateByUrl(this.pendingChanges.nextUrl);
        }
    }

    protected onSaveFailure(err): void {
        ValidationErrorHelper.handleServiceError(err, (validationModels) => {
            this.validationModels = validationModels;
        });

        this.validationModelsChange.emit(this.validationModels);
        this.isSaving = false;
        this.isSavingChange.emit(this.isSaving);
    }
}
