import { IndividualModel } from '../../contacts/models';
import { FolderModel } from './folder.model';
import { CommonBaseAuditModel } from 'app/nexus-shared/domain/contacts/models/common-base-audit.model';

export class FolderIndividualAccessModel extends CommonBaseAuditModel {
    public folderIndividualAccessKey: string;
    public folderKey: string;
    public individualKey: string;
    public hasAccess: boolean;
    public individual: IndividualModel;
    public folder: FolderModel;

    constructor(model: FolderIndividualAccessModel = null) {
        super(model);
        this.folderIndividualAccessKey = model?.folderIndividualAccessKey ?? null;
        this.folderKey = model?.folderKey ?? null;
        this.individualKey = model?.individualKey ?? null;
        this.hasAccess = model?.hasAccess ?? null;
        this.individual = model?.individual ?? null;
        this.folder = model?.folder ?? new FolderModel();
    }
}
