import { AfterViewInit, Component, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CheckboxLabelPositionType } from 'app/nexus-shared/components/controls/shared';
import { MatRadioButton } from '@angular/material/radio';

@Component({
    selector: 'gtn-radio',
    templateUrl: './radio.component.html',
    styleUrls: ['./radio.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RadioComponent),
            multi: true
        }
    ]

})
export class RadioComponent implements ControlValueAccessor, AfterViewInit {

    @Input('value') _value: string = '';
    @Input() disabled: boolean;
    @Input() focus: boolean = false;
    @Input() label: string = '';
    @Input() labelPosition: 'before' | 'after' = CheckboxLabelPositionType.after;
    //   @Input() name: string;
    @Input() required: boolean;
    //   @Input() radioGroup: MatRadioGroup;

    @Output() change: EventEmitter<any> = new EventEmitter();
    @ViewChild('matRadioButton') matRadioButton: MatRadioButton;

    onChange: Function = () => { };
    onTouched: Function = () => { };

    constructor() { }

    ngAfterViewInit() {
        if (this.focus) {
            this.setFocus();
        }
    }

    get value(): any {
        return this._value;
    }

    set value(val: any) {
        this._value = val;
        this.onChange(val);
        this.change.emit(val);
        this.onTouched();
    }

    onBlur(): void {
        this.onTouched();
    }

    registerOnChange(fn): void {
        this.onChange = fn;
    }

    registerOnTouched(fn): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    writeValue(value): void {
        const currentValue = this.value;

        if (typeof currentValue !== 'undefined' && currentValue !== null && currentValue !== value) {
            this.value = value;
        } else if (typeof value !== 'undefined' && value !== null && currentValue !== value) {
            this.value = value;
        }
    }

    setFocus(): void {
        this.matRadioButton.focus();
    }

}
