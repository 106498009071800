<form [formGroup]="formGroupRef">
    <gtn-template-dynamic-card [mode]="cardMode">
        <ng-template #content>
            <gtn-individual-form [(value)]="value.individual"
                [viewMode]="viewModes.Simple" [formGroupRef]="getCompositeFormGroupRef('individual')"
                [isSubForm]="true" [isControllingCompany]="isControllingCompanyEmployee">
            </gtn-individual-form>
            <gtn-individual-to-company-access-form [(value)]="value.individualToCompanyAccess"
                [formGroupRef]="getCompositeFormGroupRef('individualToCompanyAccess')"
                [viewMode]="viewModes.Simple" [isSubForm]="true" [isControllingCompany]="isControllingCompanyEmployee">
            </gtn-individual-to-company-access-form>
        </ng-template>
    </gtn-template-dynamic-card>
</form>
