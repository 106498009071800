import { animate, AnimationMetadata, style, transition, trigger } from '@angular/animations';

export class FadeInFadeOut {
    /**
     * Use '@fadeInFadeOut' in markup.
     */
    static animations = FadeInFadeOut.getAnimation();

    private static getAnimation(): AnimationMetadata {
        return trigger('fadeInFadeOut', [
            transition(':enter', [
                style({ transform: 'scale(.7)', opacity: 0 }),
                animate(`0.2s ease-in`, style({ transform: 'scale(1)', opacity: 1 }))
            ]),
            transition(':leave', [
                animate(`0.4s ease-out`, style({ transform: 'scale(1)', opacity: 0 }))
            ])
        ]);
    }
}
