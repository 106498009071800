<form [formGroup]="formGroupRef" *ngIf="formGroupRef">
    <div><b>Uploading files to:</b> {{ folder?.folderPath }}</div>
    <ng-container *ngIf="!documentFormArray?.length">
        <div class="mb-4">
            No files selected
        </div>
    </ng-container>
    <ng-container *ngFor="let formGroup of documentFormArray.controls; index as i; last as isLast">
        <form class="row upload-row" [formGroup]="getFormGroupFromAbstractControl(formGroup)">
            <div class="col-3 upload-file-chip">
                <mat-chip-listbox *ngIf="formGroup.value?.file">
                    <mat-chip-option>{{ formGroup.value.file.name }}
                        <i class="material-icons" matTooltip="Remove" matChipRemove
                            (click)="onRemoveFileClicked(i)">cancel</i>
                    </mat-chip-option>
                </mat-chip-listbox>
            </div>
            <ng-container formGroupName="document">
                <ng-container formGroupName="file">
                    <div class="col-md-2">
                        <ng-container *ngIf="getInvalid(formGroup); let invalidMessage">
                            <label class="list-label">File size</label>
                            <div class="data-field invalid-filesize">
                                {{ formGroup?.value?.file?.size | fileSize }}
                            </div>
                            <div class="invalid-filesize-message">
                                {{ invalidMessage }}
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!getInvalid(formGroup);">
                            <label class="list-label">File size</label>
                            <div class="data-field">
                                {{ formGroup?.value?.file?.size | fileSize }}
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
                <div class="col-md-4 document-no-margin">
                    <gtn-input [canShowValidation]="canShowValidation" formControlName="name" type="text" label="Name">
                    </gtn-input>
                </div>
                <div class="col-md-3 document-no-margin">
                    <gtn-text-area label="Upload note" formControlName="eventNote"></gtn-text-area>
                </div>
            </ng-container>
        </form>
    </ng-container>
</form>
