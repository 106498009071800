import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { CardModesEnum } from 'app/nexus-shared/enums/card-modes.enum';
import { CardAppearanceEnum } from 'app/nexus-shared/enums/card-appearance.enum';

@Component({
    selector: 'gtn-template-dynamic-card',
    templateUrl: './template-dynamic-card.component.html',
    styleUrls: ['./template-dynamic-card.component.scss']
})
export class TemplateDynamicCardComponent extends BaseComponent {
    @Input() mode: CardModesEnum = CardModesEnum.standard;
    @Input() appearance: CardAppearanceEnum = CardAppearanceEnum.unset;

    @ContentChild('header') header: TemplateRef<any>;
    @ContentChild('content') content: TemplateRef<any>;

    cardModes = CardModesEnum;
    appearances = CardAppearanceEnum;
}
