<gtn-folder-breadcrumbs [hidden]="!selectedFolder || !foldersGridData?.length" [selectedFolder]="selectedFolder"
    [folders]="foldersGridData" [rootFolder]="rootFolder" [isIndividualCompanyView]="isIndividualCompanyView"
    (breadcrumbClick)="onBreadcrumbClicked($event)"></gtn-folder-breadcrumbs>

<mat-card *ngIf="foldersGridData && documentsGridData" class="folder-document-card">
    <div class="folder-document-container">
        <div class="folders">
            <gtn-folders-grid #foldersGrid [selectedFolderKey]="selectedFolderKey"
                [rootFolder]="rootFolder" [(data)]="foldersGridData" (dataChange)="onFolderDataChanged($event)"
                [isLoadingOverlay]="false"
                [isClientAreaFolder]="isClientAreaFolder" [(selectedFolderTags)]="selectedFolderTags"
                [documentsUserAuth]="documentsUserAuth" [folderFavorites]="folderFavorites" [suppressResize]="false"
                [application]="application" [(isFolderTagSearch)]="isFolderTagSearch"
                (rowClick)="onSelectedFolderChanged($event)" (addFolderFavorite)="onFolderFavorited($event)"
                (removeFolderFavorite)="onFolderFavoriteDeleted($event)"
                (documentFolderMove)="onDocumentFolderMoved($event)"
                (viewFolderProperties)="onViewFolderPropertiesClicked($event)"
                (selectedFolderDeleted)="onSelectedFolderDeleted($event)"
                (itemsDeleted)="onDelete()" (tagFoldersSearch)="onFolderTagSearchModelChanged($event)"
                (gridReady)="onFoldersGridReady()"></gtn-folders-grid>
        </div>
        <div class="documents">
            <gtn-documents-grid #documentsGrid [data]="documentsGridData"
                [selectedFolder]="selectedFolder" [isLoadingOverlay]="true"
                [folderFavorites]="folderFavorites" [application]="application" [showFolderTagIcon]="showFolderTagIcon"
                [gridAdminAuthConstant]="gridAdminConstant" [isClientAreaFolder]="isClientAreaFolder"
                [documentsUserAuth]="documentsUserAuth" [isFolderTagSearch]="isFolderTagSearch"
                (addButtonClick)="onUploadDocumentClicked($event)"
                (folderRowClick)="onSelectedFolderChanged($event)"
                (documentRowClick)="onDocumentRowClicked($event)"
                (externalFileDrop)="onExternalFilesDropped($event)"
                (addFolderFavorite)="onFolderFavorited($event)"
                (removeFolderFavorite)="onFolderFavoriteDeleted($event)"
                (documentFolderMove)="onDocumentFolderMoved($event)"
                (viewFolderProperties)="onViewFolderPropertiesClicked($event)"
                (tagFolderSearch)="onFolderTagSearchModelChanged($event)"
                (itemsDeleted)="onDelete()" (folderTagClick)="onFolderTagsClicked()"
                (gridReady)="onDocumentsGridReady()"></gtn-documents-grid>
        </div>
    </div>
</mat-card>

<div class="folder-document-tabs">
    <gtn-slide-out-tabs [tabs]="tabs" [(selectedTab)]="selectedTab" [(isShowTabContent)]="isShowTabContent"
        [isWide]="documentsUserAuth.isAdmin"
        contentHeight="100%" (selectedTabChange)="onTabChanged()">
        <gtn-slide-out-tabs-content>
            <div class="slide-out-content">
                <gtn-trash-bin-grid #trashBinGrid *ngIf="selectedTab.id === tabsEnum.DeletedItems"
                    [gridAdminAuthConstant]="gridAdminConstant" [documentsUserAuth]="documentsUserAuth"
                    [application]="application"
                    [rootFolder]="rootFolder"></gtn-trash-bin-grid>
                <gtn-template-document-grid #templateDocumentGrid [hidden]="selectedTab.id !== tabsEnum.FileTemplates"
                    [documentsUserAuth]="documentsUserAuth"
                    [application]="application" [gridAdminAuthConstant]="gridAdminConstant"
                    (copyTemplates)="onCopyDocumentTemplates()"
                    (gridReady)="onTemplateDocumentsGridReady()">
                </gtn-template-document-grid>
                <gtn-template-folder-grid #templateFolderGrid
                    [hidden]="selectedTab.id !== tabsEnum.FolderTemplates"
                    [gridAdminAuthConstant]="gridAdminConstant"
                    [documentsUserAuth]="documentsUserAuth"
                    [application]="application"
                    [selectedFolderKey]="selectedFolderKey || rootFolder.folderKey"
                    (copyTemplates)="onCopyFolderTemplates($event)"
                    (gridReady)="onFolderTemplatesGridReady()">
                </gtn-template-folder-grid>
            </div>
        </gtn-slide-out-tabs-content>
    </gtn-slide-out-tabs>
</div>
<gtn-document-upload-modal *ngIf="showUploadModal"
    [value]="document"
    [documentProcessService]="documentProcessService"
    (uploadDocumentClick)="onSaveUploadDocumentClicked($event)"
    (cancelClick)="onCancelClicked()">
</gtn-document-upload-modal>
<gtn-document-detail-modal
    *ngIf="showDocumentDetailModal"
    [value]="document"
    [selectedFolder]="selectedFolder"
    [documentsUserAuth]="documentsUserAuth"
    (cancelClick)="onCancelClicked()">
</gtn-document-detail-modal>
<gtn-bulk-file-upload-modal *ngIf="showBulkUploadModal" [folder]="selectedFolder" [files]="externalDroppedFiles"
    [documentProcessService]="documentProcessService"
    (cancelClick)="onCancelClicked()" (documentSaveClick)="onCreateDocumentsClicked($event)">
</gtn-bulk-file-upload-modal>
<gtn-documents-move-confirm-modal *ngIf="showMoveConfirmModal" [documentFolderMove]="documentFolderMove"
    (cancelClick)="onCancelClicked()" (confirmClick)="onDocumentFolderMoveConfirmed()">
</gtn-documents-move-confirm-modal>
<gtn-folder-detail-modal *ngIf="showFolderModal" [folderKey]="modalFolderKey" [viewMode]="folderModalView"
    (saveClick)="onFolderPropertiesSaved()"
    (cancelClick)="onCancelClicked()"></gtn-folder-detail-modal>
