import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BaseHttpService } from 'app/nexus-core/services/base-http.service';
import { Observable, of } from 'rxjs';
import { FolderSearchModel } from 'app/nexus-shared/domain/documents/models/folder-search.model';
import { FolderModel } from 'app/nexus-shared/domain/documents/models/folder.model';
import { tap } from 'rxjs/operators';
import { IFolderServiceInterface } from 'app/nexus-core/services/domain/documents/ifolder-service.interface';

@Injectable()
export class FolderService implements IFolderServiceInterface {
    searchModel: FolderSearchModel;
    pathFolders: FolderModel[];

    private apiUrl = `${environment().webAPIUrls.documents}folders/`;

    constructor(private baseHttpService: BaseHttpService) {
    }

    get(folderKey: string): Observable<FolderModel> {
        return this.baseHttpService.get(`${this.apiUrl}${folderKey}`);
    }

    search(search: FolderSearchModel): Observable<FolderModel[]> {
        return this.baseHttpService.post(`${this.apiUrl}search`, search);
    }

    // we can cache the folder tree if no updates is made on folders - need to implement
    searchByPath(search: FolderSearchModel, useCache: boolean): Observable<FolderModel[]> {
        if (JSON.stringify(search) === JSON.stringify(this.searchModel) && this.pathFolders && useCache) {
            return of(this.pathFolders);
        } else {
            return this.search(search).pipe(tap(folders => {
                this.pathFolders = folders;
                this.searchModel = search;
            }));
        }
    }

    create(model: FolderModel): Observable<string> {
        return this.baseHttpService.post(this.apiUrl, model);
    }

    update(model: FolderModel): Observable<boolean> {
        return this.baseHttpService.put(this.apiUrl, model);
    }
}
