import { Component, ViewChild } from '@angular/core';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { AuthorizationConstants } from 'app/nexus-shared/constants/authorization.constants';
import { AuthenticatedUserService } from 'app/nexus-core/services/authenticated-user.service';
import { ApplicationsEnum } from 'app/nexus-shared';
import { UserImpersonationModel } from 'app/nexus-shared/domain/users/models/user-impersonation.model';
import { UserImpersonationsGridComponent } from 'app/nexus-shared/domain/users/components/user-impersonations-grid/user-impersonations-grid.component';

@Component({
    selector: 'gtn-user-impersonations',
    templateUrl: './user-impersonations.component.html',
    styleUrls: ['./user-impersonations.component.scss']
})
export class UserImpersonationsComponent extends BaseComponent {
    application = ApplicationsEnum.Users;
    authorizationConstants = AuthorizationConstants;
    gridAdminConstant: AuthorizationConstants = AuthorizationConstants.authUserAdmin;
    userImpersonation: UserImpersonationModel = null;
    showImpersonationModal: boolean;

    @ViewChild('userImpersonationGrid') userImpersonationGridRef: UserImpersonationsGridComponent;

    constructor(private authenticatedUserService: AuthenticatedUserService) {
        super();
    }

    onUserImpersonationCompleted(result: boolean): void {
        this.showImpersonationModal = false;
        if (result) {
            this.userImpersonationGridRef.refreshGridData();
        }
    }

    onAddUserImpersonationClicked(): void {
        if (this.authenticatedUserService.hasAuthorization(AuthorizationConstants.authUserImpersonation)) {
            this.userImpersonation = null;
            this.showImpersonationModal = true;
        }
    }

    onUserImpersonationClicked(userImpersonationModel: UserImpersonationModel): void {
        if (this.authenticatedUserService.hasAuthorization(AuthorizationConstants.authUserImpersonation)) {
            this.userImpersonation = userImpersonationModel;
            this.showImpersonationModal = true;
        }
    }
}
