import { Component } from '@angular/core';
import { FileViewComponent } from 'app/nexus-shared/components/controls/components/file-view/file-view.component';

@Component({
    selector: 'gtn-file-view-header-controls',
    template: '<ng-content></ng-content>'
})
export class FileViewHeaderControlsComponent {
    // noinspection JSUnusedLocalSymbols
    constructor(fileViewComponent: FileViewComponent) {
        //THIS WILL THROW AN ERROR IF ITS NOT NESTED WITHIN THE FILEVIEWHEADER ELEMENT
    }
}
