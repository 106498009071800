import { Component } from '@angular/core';
import {
    IndividualCreateCompositeTemplateModalComponent
} from 'app/nexus-shared/domain/contacts/components/modals/shared/individual-create-composite-template-modal/individual-create-composite-template-modal.component';

@Component({
    selector: 'gtn-individual-create-composite-template-content',
    template: '<ng-content></ng-content>'
})
export class IndividualCreateCompositeTemplateContentComponent {
    // noinspection JSUnusedLocalSymbols
    constructor(gtnIndividualCompositeTemplateContentComponent: IndividualCreateCompositeTemplateModalComponent) {
        //THIS WILL THROW AN ERROR IF ITS NOT NESTED WITHIN THE gtn-modal ELEMENT
    }
}
