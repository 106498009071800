import { formatCurrency } from '@angular/common';

export class NumberHelper {
    static formatCurrency(value: number, returnNull= false, numberOfDecimals: number = 2): string {
        if (!value && returnNull) {
            return null;
        }

        return formatCurrency(value ?? 0, 'en-us', '$', null, `1.${numberOfDecimals}-${numberOfDecimals}`);
    }

    static currencyCheck(event: any): void {
        const regex = new RegExp('(?=.*?\\d)^\\$?(([1-9]\\d{0,2}(,\\d{3})*)|\\d+)?(\\.\\d{1,2})?$');
        const inputValue = event.currentTarget.value;

        if (!regex.test(inputValue)) {
            event.preventDefault();
        }
    }

    /**
     * Precision must be multiple of 10. Passing no value rounds to nearest whole number.
    */
    static round(value: number, precision: number = null) {
        if (precision === null) {
            return Math.round(value);
        } else {
            return Math.round(precision * value) / precision;
        }
    }

    //Thank you chat gpt
    static roundingWithWholeNumberReturn(value: number | null | undefined, precision: number = 2): string | number {
        if (value === null || value === undefined) {
            return value; // Return the value as is if it's null or undefined
        }

        const decimalPart = value % 1; // Extract the decimal part
        const factor = Math.pow(10, precision); // Calculate the factor based on precision
        const roundedValue = Math.round(value * factor) / factor; // Round to the specified precision

        // If precision is 0 or the decimal part rounds to 0, return the whole number
        if (precision === 0 || (roundedValue % 1 === 0)) {
            return Math.round(roundedValue); // Whole number without decimals
        }

        // Otherwise, return the value rounded to the specified precision
        return roundedValue.toFixed(precision);
    }
}
