<div *ngIf="navigateClick"
    matRipple
    [matRippleCentered]="true"
    class="control-column-icon-button"
    (click)="onNavigateClicked($event)">
    <i class="fa fa-share"
        matTooltip="Navigate"></i>
</div>
<div *ngIf="refreshClick"
    matRipple
    [matRippleCentered]="true"
    class="control-column-icon-button"
    (click)="onRefreshClicked($event)">
    <i class="fas fa-sync"
        matTooltip="Refresh"></i>
</div>
<div *ngIf="editClick && !preventEditMode"
    matRipple
    [matRippleCentered]="true"
    class="control-column-icon-button"
    (click)="onEditClick($event)">
    <i class="fa"
        [matTooltip]="isValidateError ? 'Invalid' : !isEditing ? 'Edit' : 'Save'"
        [ngClass]="{'fa-exclamation': isValidateError, 'fa-pencil-alt': !isEditing && !isValidateError, 'fa-save': isEditing}"></i>
</div>
<div *ngIf="cloneClick"
    matRipple
    [matRippleCentered]="true"
    class="control-column-icon-button"
    (click)="onCloneClicked($event)">
    <i class="fa fa-copy"
        matTooltip="Copy"></i>
</div>
<div *ngIf="deleteClick"
    matRipple
    [matRippleCentered]="true"
    class="control-column-icon-button"
    (click)="onDeleteClicked($event)">
    <i class="fa fa-trash"
        matTooltip="Delete"></i>
</div>
