import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AgGridHelper } from 'app/nexus-core';
import { FolderModel } from 'app/nexus-shared/domain/documents/models/folder.model';
import { BaseEnterpriseGridComponent } from 'app/nexus-shared/components/base-component/base-enterprise-grid.component';
import { FolderSearchModel } from 'app/nexus-shared/domain/documents/models/folder-search.model';
import { CellClickedEvent, ColDef, GridOptions, RowDragEnterEvent } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { FolderService } from 'app/nexus-core/services/domain/documents/folder.service';
import { DocumentsAuthModel } from 'app/nexus-shared/domain/documents/models/documents-auth.model';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { DocumentModel } from 'app/nexus-shared/domain/documents/models/document.model';
import { FolderDocumentCellRendererComponent } from 'app/nexus-shared/domain/documents/components/grids/folder-document-cell-renderer/folder-document-cell-renderer.component';
import { FolderCopyModel } from 'app/nexus-shared/domain/documents/models/folder-copy.model';
import { IndividualSettingService } from 'app/nexus-core/services/domain/core/individual-setting.service';

@Component({
    selector: 'gtn-template-folder-grid',
    templateUrl: './template-folder-grid.component.html',
    styleUrls: ['./template-folder-grid.component.scss']
})
export class TemplateFolderGridComponent extends BaseEnterpriseGridComponent<FolderModel> implements OnInit {
    static readonly templatesNoRowsOverlay = `<span>No templates are available for use.</span>`;

    @Input() documentsUserAuth: DocumentsAuthModel;
    @Input() selectedFolderKey: string;

    @Output() copyTemplates: EventEmitter<FolderCopyModel> = new EventEmitter();

    // gridName: string = 'template_folders_grid';
    // gridVersion: string = '1.0.0';
    isRowSelected: boolean = false;

    constructor(
        protected individualSettingService: IndividualSettingService,
        private folderService: FolderService) {
        super(individualSettingService);
    }

    ngOnInit(): void {
        const that = this;
        const gridOptions: GridOptions = {
            treeData: true,
            getDataPath: that.getDataPath,
            autoGroupColumnDef: {
                headerName: 'Templates',
                rowDrag: true,
                onCellClicked: that.onGroupedRowHeaderClicked.bind(that),
                tooltipField: ObjectHelper.nameOf<DocumentModel>('name'),
                cellRendererParams: {
                    suppressCount: true,
                    innerRenderer: FolderDocumentCellRendererComponent
                },
                sort: 'asc'
            },
            rowMultiSelectWithClick: true,
            rowDragEntireRow: true,
            rowDragMultiRow: false,
            suppressRowDrag: true,
            sideBar: null,
            groupDefaultExpanded: 0,
            suppressRowClickSelection: true,
            rowSelection: AgGridHelper.rowSelectionMultiple,
            groupSelectsChildren: true,
            overlayNoRowsTemplate: TemplateFolderGridComponent.templatesNoRowsOverlay,
            noRowsOverlayComponent: null,
            onRowDragEnter: that.onDragStart.bind(that),
        };
        super.ngOnInit(gridOptions);
    }

    getDataPath(data: FolderModel): string[] {
        const filePathSplit = data.folderPath.split('/');
        filePathSplit.splice(0, 1);
        return filePathSplit;
    }

    onDragStart(event: RowDragEnterEvent): void {
        this.onGroupedRowHeaderClicked(event);
        const dragLabel = document.querySelectorAll('.ag-dnd-ghost-label')[0] as HTMLElement;

        if (dragLabel) {
            dragLabel.innerHTML = `Copying ${event.node.data.name} folders`;
        }
    }

    onGroupedRowHeaderClicked(event: RowDragEnterEvent | CellClickedEvent): void {
        this.isRowSelected = true;
        this.gridApi.forEachNode(x => {
            if (event.node.allLeafChildren.includes(x)) {
                x.setSelected(true);
            } else {
                x.setSelected(false);
            }
        });
    }

    onCopyClicked(): void {
        const lowestLevelNode = this.gridApi.getSelectedNodes().reduce((prev, curr) => {
            return prev.level < curr.level ? prev : curr;
        });
        const folderCopy: FolderCopyModel = new FolderCopyModel(lowestLevelNode.data.folderKey, this.selectedFolderKey);
        this.copyTemplates.emit(folderCopy);
    }

    setDefaultSortModel() {
        this.defaultSortModel = [
            { colId: ObjectHelper.nameOf<FolderModel>('name'), sort: 'asc' },
        ];
    }

    protected setColumnDefinitions(): ColDef[] {

        return [];
    }

    protected setRowData(): Observable<any[]> {
        const searchModel: FolderSearchModel = new FolderSearchModel();
        searchModel.parentFolderPath = 'Folder Templates';
        return this.folderService.search(searchModel);
    }

}
