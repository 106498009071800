import { IndividualModel } from '../../contacts/models';
import { CommonBaseAuditModel } from '../../contacts/models/common-base-audit.model';
import { AgreementStatusEnum } from '../enums/agreement-status.enum';
import { IAgreementModelInterface } from '../interfaces/iagreement-model.interface';
import { IAgreementsCompanyModelInterface } from '../interfaces/iagreements-company-model.interface';
import { AgreementReferentialValueModel } from './agreement-referential-value.model';
import { AgreementSignatureModel } from './agreement-signature.model';
import { AgreementTypeModel } from './agreement-type.model';

export class AgreementModel extends CommonBaseAuditModel implements IAgreementModelInterface {
    agreementKey: string;
    agreementNumber: number;
    agreementTypeKey: string;
    templateHistoryKey: string;
    expirationDateTime: Date | string;
    name: string;
    reviewerDeclineReason?: string;
    reviewerIndividualKey?: string;
    signedAgreementFilePath: string;
    signedDateTime: Date | string;
    signerCompanyKey: string;
    signerIndividualKey: string;
    signerNotificationDateTime: Date | string;
    status: AgreementStatusEnum;

    agreementType: AgreementTypeModel;
    agreementReferentialValues: AgreementReferentialValueModel[];
    company: IAgreementsCompanyModelInterface;
    individual: IndividualModel;
    signatures: AgreementSignatureModel[];

    constructor(model: AgreementModel | IAgreementModelInterface = null) {
        super(model);

        this.agreementKey = model?.agreementKey ?? null;
        this.agreementNumber = model?.agreementNumber ?? null;
        this.agreementTypeKey = model?.agreementTypeKey ?? null;
        this.templateHistoryKey = model?.templateHistoryKey ?? null;
        this.expirationDateTime = model?.expirationDateTime ?? null;
        this.name = model?.name ?? null;
        this.reviewerDeclineReason = model?.reviewerDeclineReason ?? null;
        this.reviewerIndividualKey = model?.reviewerIndividualKey ?? null;
        this.signedAgreementFilePath = model?.signedAgreementFilePath ?? null;
        this.signedDateTime = model?.signedDateTime ?? null;
        this.signerCompanyKey = model?.signerCompanyKey ?? null;
        this.signerIndividualKey = model?.signerIndividualKey ?? null;
        this.signerNotificationDateTime = model?.signerNotificationDateTime ?? null;
        this.status = model?.status ?? null;

        this.agreementType = model?.agreementType ? new AgreementTypeModel(model.agreementType) : null;
        this.agreementReferentialValues = model?.agreementReferentialValues?.length ? model.agreementReferentialValues.map(x => new AgreementReferentialValueModel(x)) : [];
        this.signatures = model?.signatures?.length ? model.signatures.map(x => new AgreementSignatureModel(x)) : null;
    }
}
