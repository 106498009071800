import { ModalInputModel } from 'app/nexus-shared/components/modal/models/modal-input.model';

export class FileUploadModalInputModel extends ModalInputModel {
    date: Date;
    dragDropInputText: string;
    fileSelectorInputText: string;
    isMultiSelect: boolean;
    requireDate: boolean;
    showDatePicker: boolean;
    showCountry: boolean;

    constructor(
        mainHeaderText: string = '',
        message: string = '',
        subHeaderText: string = '',
        okButtonText: string = 'Ok',
        cancelButtonText: string = 'Cancel',
        showCloseButton: boolean = false,
        showDatePicker: boolean = false,
        requireDate: boolean = false,
        date: Date = null,
        fileSelectorInputText: string = '',
        dragDropInputText: string = '',
        isMultiSelect: boolean = false,
        showCountry: boolean = true
    ) {
        super(
            mainHeaderText,
            message,
            subHeaderText,
            okButtonText,
            cancelButtonText,
            showCloseButton
        );

        this.date = date;
        this.dragDropInputText = dragDropInputText;
        this.fileSelectorInputText = fileSelectorInputText;
        this.isMultiSelect = isMultiSelect;
        this.requireDate = requireDate;
        this.showDatePicker = showDatePicker;
        this.showCountry = showCountry;
    }
}
