<div class="main-container">
    <div>
        <h1 class="page-top-header" *ngIf="pageTitle">
            <span>{{ pageTitle }}</span>
            <ng-content select="gtn-template-entry-page-header"></ng-content>
        </h1>
        <gtn-validation *ngIf="validationModels && validationModels.length > 0" [validationModels]="validationModels">
        </gtn-validation>
    </div>
    <div class="row">
        <div class="col-md-12">
            <ng-content select="gtn-template-entry-page-content">
            </ng-content>
        </div>
    </div>
</div>
<div class="form-button-group" *ngIf="!hideActionButtons">
    <ng-content select="gtn-template-entry-page-footer"></ng-content>
    <button mat-flat-button class="mr-2" color="accent" (click)="onSaveClicked()"
        [disabled]="isSaveButtonDisabled()">{{ isSaving ? 'Saving' : 'Save' }}
        <i *ngIf="isSaving" class="fas fa-spinner fa-spin"></i>
    </button>

    <button mat-flat-button class="cancel-button" *ngIf="showCancel" (click)="onCancelClicked()" [disabled]="isSaving">
        Cancel
    </button>
</div>
<gtn-pending-changes-modal *ngIf="pendingChanges.showPendingChanges"
    (closeCancelClick)="onClosePendingChangesClicked()"
    (discardClick)="onDiscardPendingChangesClicked()"
    (saveClick)="onSaveClicked(true)"></gtn-pending-changes-modal>
