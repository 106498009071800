import { Pipe, PipeTransform } from '@angular/core';
import { NumberHelper } from 'app/nexus-core';

@Pipe({
    name: 'rounding'
})
export class RoundingPipe implements PipeTransform {
    transform(value: number | null | undefined, precision: number = 2): string | number {
        return NumberHelper.roundingWithWholeNumberReturn(value, precision);
    }
}
