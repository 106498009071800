import { SectionColumnModel } from 'app/nexus-shared/domain/questionnaires/models/section-column.model';
import { SectionModel } from 'app/nexus-shared/domain/questionnaires/models/section.model';
import { QuestionnaireTemplateModel } from 'app/nexus-shared/domain/questionnaires/models/questionnaire-template.model';
import { QuestionnaireInterface } from 'app/nexus-shared/domain/questionnaires/interfaces/questionnaire.interface';
import { SectionRowModel } from 'app/nexus-shared/domain/questionnaires/models/section-row.model';
import { Guid } from 'app/nexus-shared/models/guid.type';
import { CommonBaseAuditUserModel } from 'app/nexus-shared/domain/contacts/models/common-base-audit-user.model';
import { QuestionnaireTemplateStatusesEnum } from 'app/nexus-shared/domain/questionnaires/enums/questionnaire-template-statuses.enum';

export class QuestionnaireHelper {

    static getColumnStyles(column: SectionColumnModel): { [key: string]: string } {
        return {
            ['font-weight']: column.isBoldText ? 'bold' : '',
            ['text-decoration']: column.isUnderlineText ? 'underline' : '',
            ['font-style']: column.isItalicsText ? 'italic' : '',
            ['color']: column.isRedText ? 'red' : 'black'
        };
    }

    static isTriggeredByAction(sectionKey: string, questionnaire: QuestionnaireTemplateModel): boolean {
        return !!questionnaire.sections
            .find(s => s.rows &&
                !!s.rows.find(r => r.columns &&
                    !!r.columns.find(c => c.action && c.action.childSectionKey === sectionKey)));
    }

    static isSectionVisible(section: SectionModel, questionnaire: QuestionnaireInterface): boolean {
        let isVisible = true;
        const isTriggered = !!section.parentSectionColumnKey;
        for (const sec of questionnaire.sections) {
            if (sec.rows) {
                for (const row of sec.rows) {
                    if (row.columns) {
                        const columns = row.columns.filter(c => c.action && c.action.childSectionKey);
                        for (const col of columns) {
                            if (isTriggered) {
                                if (col.value && !col.action.value && section.parentSectionColumnKey === col.key) {
                                    return true;
                                } else if (col.action.childSectionKey === section.key) {
                                    isVisible = false;
                                }
                            } else if (col.action.childSectionKey === section.key) {
                                isVisible = col.value && col.value.toString() === col.action.value?.toString();
                                if (isVisible) {
                                    return true;
                                }
                            }
                        }
                    }
                }
            }
        }

        return isVisible;
    }

    static cloneQuestionnaireTemplate(questionnaireTemplate: QuestionnaireTemplateModel): QuestionnaireTemplateModel {
        questionnaireTemplate.title += ' (Clone)';
        questionnaireTemplate.createdByUser = new CommonBaseAuditUserModel();
        questionnaireTemplate.lastModifiedByUser = new CommonBaseAuditUserModel();
        questionnaireTemplate.status = QuestionnaireTemplateStatusesEnum.Draft;

        return questionnaireTemplate;
    }

    static cloneSection(section: SectionModel): SectionModel {
        section.key = Guid.newGuid();
        section.rows.forEach((row: SectionRowModel) => {
            this.cloneRow(row);
        });
        return section;
    }

    static cloneRow(row: SectionRowModel): SectionRowModel {
        row.key = Guid.newGuid();
        row.columns.forEach((column: SectionColumnModel) => {
            this.cloneColumn(column);
        });

        return row;
    }

    static cloneColumn(column: SectionColumnModel): SectionColumnModel {
        column.key = Guid.newGuid();
        column.tableRows.forEach(tableRow => {
            tableRow.key = Guid.newGuid();
        });

        column.tableColumnHeaders.forEach(tableColumnHeader => {
            tableColumnHeader.key = Guid.newGuid();
        });

        if (column.action?.key) {
            column.action.key = Guid.newGuid();
            column.action.parentSectionColumnKey = column.key;
        }

        return column;
    }

}
