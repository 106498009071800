import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FolderFavoriteService } from 'app/nexus-core/services/domain/documents/folder-favorite.service';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { FolderFavoriteModel } from 'app/nexus-shared/domain/documents/models/folder-favorite.model';
import { FolderFavoriteSearchModel } from 'app/nexus-shared/domain/documents/models/folder-favorite-search.model';
import { AreaTypesEnum } from 'app/nexus-shared/domain/documents/enums/area-types.enum';
import { SortingHelper } from 'app/nexus-core';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { FolderModel } from 'app/nexus-shared/domain/documents/models/folder.model';

@Component({
    selector: 'gtn-folder-favorites',
    templateUrl: './folder-favorites.component.html',
    styleUrls: ['../dashboard-table.component.scss']
})
export class FolderFavoritesComponent extends BaseComponent implements OnInit {
    @Input() height: number;

    @Output() navigateToFolder: EventEmitter<FolderModel> = new EventEmitter<FolderModel>();
    @Output() navigateToArea: EventEmitter<FolderModel> = new EventEmitter<FolderModel>();

    folderFavorites: FolderFavoriteModel[];
    areaTypesEnum = AreaTypesEnum;

    constructor(
        private folderFavoriteService: FolderFavoriteService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.getFolderFavorites();
    }

    onFolderClicked(folderFavorite: FolderFavoriteModel): void {
        if (folderFavorite.folder.areaType !== AreaTypesEnum.Clients) {
            this.navigateToArea.emit(folderFavorite.folder);
        } else {
            this.navigateToFolder.emit(folderFavorite.folder);
        }
    }

    private getFolderFavorites(): void {
        this.folderFavoriteService.search(new FolderFavoriteSearchModel()).subscribe(res => {
            this.folderFavorites = res.sort((a, b) => SortingHelper.sortByPropertyComparer(a.folder, b.folder, ObjectHelper.nameOf<FolderModel>('name')));
        });
    }
}
