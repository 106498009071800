<div @modalFadeIn class="modal-wrapper">
    <gtn-modal-header (closeClick)="cancelClick" [mainHeaderText]="mainHeaderText" [showCloseButton]="showCloseButton"
        [subHeaderText]="subHeaderText">
    </gtn-modal-header>
    <div class="modal-content">
        <div>
            <div *ngIf="showDatePicker" class="picker-container">
                <gtn-datepicker [(ngModel)]="date" label="Compensation date">
                </gtn-datepicker>
            </div>
        </div>
        <input (change)="receiveFileList($event.currentTarget['files'])" [multiple]="isMultiSelect" class="d-none"
            id="file" type="file" />
        <gtn-file-drag-drop #dragDrop [isMultiSelect]="isMultiSelect"></gtn-file-drag-drop>

        <button mat-flat-button class="mat-button-base mat-button-default mt-3 mx-auto d-block"
            onClick="document.getElementById('file').click();">Or browse to file</button>

        <div #gridContainer class="files-grid-container mt-3" *ngIf="files">
            <gtn-grid [gridHeight]="gridHeight" [columnDefs]="columnDefs" [data]="files"
                [isAllowRowSelection]="true">
            </gtn-grid>
        </div>
    </div>
    <gtn-modal-footer [cancel]='cancelClick' [enableOkButton]="this.files
            && (!this.showDatePicker || (this.showDatePicker && this.date))
            ? true
            : false" [okButtonText]="okButtonText" [ok]='okClick' [showCancel]="cancel ? true : false"
        [showOk]="ok ? true : false">
    </gtn-modal-footer>
</div>
