<gtn-template-readonly-edit-card [(isSaving)]="isSaving" [formGroupRef]="formGroupRef"
    [validationModels]="validationModels" [readonly]="readonly" [showActionButtonsInCard]="true"
    [showCardTitleIcon]="canEdit"
    (readonlyChange)="onReadOnlyChanged($event)" (saveClick)="onConfirmationClicked()"
    (cancelClick)="onCancelClicked()">
    <gtn-template-readonly-edit-card-header>
        {{ pageTitle }}
        <button *ngIf="viewMode === viewModes.Addresses && !readonly" (click)="addAddressClicked()" class="mr-2"
            color="primary"
            mat-mini-fab matTooltip="Add">
            <mat-icon>add</mat-icon>
        </button>
    </gtn-template-readonly-edit-card-header>
    <gtn-template-readonly-edit-card-view-content>
        <gtn-individual-view [viewMode]="viewMode" *ngIf="readonly" [value]="value"
            [application]="application"></gtn-individual-view>
    </gtn-template-readonly-edit-card-view-content>
    <gtn-template-readonly-edit-card-edit-content>
        <gtn-individual-form #individualForm [viewMode]="viewMode" *ngIf="!readonly" [(formGroupRef)]="formGroupRef"
            (formGroupRefChange)="onFormGroupRefChanged($event)"
            [(value)]="value"></gtn-individual-form>
    </gtn-template-readonly-edit-card-edit-content>
</gtn-template-readonly-edit-card>
<gtn-modal class="modal-small-responsive" *ngIf="showConfirmationModal"
    (cancelClick)="onConfirmationCancelClicked()"
    (closeClick)="onConfirmationCancelClicked()"
    (okClick)="onConfirmationSavedClicked()"
    okButtonText="Confirm Change"
    headerText="Warning">
    <gtn-modal-content>
        Are you sure you should be doing this? Are you fully aware of the downstream effects? If not, cancel immediately
        and talk to Mike.
    </gtn-modal-content>
</gtn-modal>
