import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterTypeEnum } from 'app/nexus-shared/components/controls/shared/enums/filter-type.enum';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { FilterModel } from 'app/nexus-shared/components/controls/shared/models/filter-model';
import { SelectListInterface } from 'app/nexus-shared/index';

@Component({
    selector: 'gtn-grid-filter-controls',
    templateUrl: './grid-filter-controls.component.html',
    styleUrls: ['./grid-filter-controls.component.scss']
})
export class GridFilterControlsComponent extends BaseComponent {
    @Input() gtnFilters: FilterModel[];
    @Output() gtnFiltersChange: EventEmitter<FilterModel[]> = new EventEmitter();

    filterEnum = FilterTypeEnum;

    onFilterActiveChanged(event: boolean): void {
        if (event !== null) {
            const endFilter = this.gtnFilters.find(f => f.controlType === this.filterEnum.ActiveDateEnd);
            endFilter.isFilterActive = event;
        }
    }

    onComboboxChanged(event: SelectListInterface, filter: FilterModel): void {
        if (event) {
            filter.isFilterActive = true;
            filter.appliedFilter = {
                id: filter.field,
                value: event.value
            };
        } else {
            filter.isFilterActive = false;
        }
    }

    onDropdownChanged(val: string | number, filter: FilterModel): void {
        if (val) {
            filter.isFilterActive = true;
            filter.appliedFilter = {
                id: filter.field,
                value: filter.options.find(x => x[filter.idKey] === val).value
            };
        } else {
            filter.isFilterActive = false;
        }
    }

    onDateChanged(val: Date | string, filter: FilterModel): void {
        if (val) {
            filter.isFilterActive = true;
            filter.appliedFilter = {
                id: filter.field,
                value: null
            };
        } else {
            filter.isFilterActive = false;
        }
    }
}
