import { Injectable } from '@angular/core';
import { BaseHttpService, BaseService } from 'app/nexus-core/services';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { IAgreementTypeServiceInterface } from './iagreement-type-service.interface';
import { AgreementTypeModel } from 'app/nexus-shared/domain/agreements/models/agreement-type.model';
import { AgreementTypeSearchModel } from 'app/nexus-shared/domain/agreements/models/agreement-type-search.model';

@Injectable()
export class AgreementTypeService extends BaseService implements IAgreementTypeServiceInterface {
    private apiUrl = environment().webAPIUrls.agreements + 'agreement-types';

    constructor(private baseHttpService: BaseHttpService) {
        super();
    }

    create(agreementType: AgreementTypeModel): Observable<string> {
        return this.baseHttpService.post(`${this.apiUrl}`, agreementType);
    }

    delete(agreementTypeKey: string): Observable<boolean> {
        return this.baseHttpService.delete(`${this.apiUrl}/${agreementTypeKey}`);
    }

    get(agreementTypeKey: string): Observable<AgreementTypeModel> {
        return this.baseHttpService.get(`${this.apiUrl}/${agreementTypeKey}`);
    }

    search(searchModel: AgreementTypeSearchModel): Observable<AgreementTypeModel[]> {
        return this.baseHttpService.post(`${this.apiUrl}/search`, searchModel);
    }


    update(agreementType: AgreementTypeModel): Observable<boolean> {
        return this.baseHttpService.put(`${this.apiUrl}`, agreementType);
    }
}
