<mat-card appearance="outlined" class="expandable" [ngClass]="{ 'expanded': isExpanded }">
    <div class="card-content" [ngClass]="{ 'pointer': isCardContentClickable }" (click)="onCardContentClicked()">
        <ng-content></ng-content>
    </div>
    <div class="card-footer pointer text-center" *ngIf="isExpandable && !isExpanded" matTooltip="Expand"
        (click)="onExpandCard()">
        <mat-icon>expand_more</mat-icon>
    </div>
    <div class="card-footer pointer text-center" *ngIf="isCollapsible && isExpanded" matTooltip="Collapse"
        (click)="onCollapseCard()">
        <mat-icon>expand_less</mat-icon>
    </div>
</mat-card>
