import { UntypedFormGroup } from '@angular/forms';
import { ColDef, Column, ColumnApi, GridApi, ICellEditorParams, RowNode } from 'ag-grid-community';

export class InputCellEditorParametersModel implements ICellEditorParams {
    key: string;
    eventKey: string;
    focus?: boolean;
    isDisabled?: boolean;
    isPopup?: boolean;
    isInline?: boolean;
    isCustomizedTheme?: boolean;
    isReadonly?: boolean;
    selectAll?: boolean;
    type?: string;
    value: any;
    width?: number;
    formGroupRef?: UntypedFormGroup;
    formArrayName?: string;
    ngModelValue: any;

    keyPress: number;
    charPress: string;
    column: Column;
    colDef: ColDef;
    node: RowNode;
    data: any;
    rowIndex: number;
    api: GridApi;
    columnApi: ColumnApi;
    cellStartedEdit: boolean;
    context: any;
    $scope: any;
    onKeyDown: (event: KeyboardEvent) => void;
    stopEditing: (suppressNavigateAfterEdit?: boolean) => void;
    eGridCell: HTMLElement;
    parseValue: (value: any) => any;
    formatValue: (value: any) => any;
}
