<form cdkDropList [formGroup]="formGroupRef" (cdkDropListDropped)="onTableColumnDropped($event)">
    <ng-container *ngFor="let tableColumnHeader of tableColumnHeadersFormArray.controls; index as tableColumnIndex">
        <div cdkDrag class="flex-row-start">
            <!--            example for rima, maybe a template-->
            <div *cdkDragPreview style="height:30px;width:500px;background:rgba(255,255,255,0.5)">
                <span class="ml-5">Table Column {{ tableColumnIndex + 1 }}</span>
            </div>
            <ng-container [formGroup]="getFormGroupFromAbstractControl(tableColumnHeader)">
                <div class="small-column flex-column-center ml-2 mr-2">
                    <span><i class="fas fa-bars"></i></span>
                </div>
                <!--                <gtn-input class="small-column mr-3" label="Order" formControlName="order"></gtn-input>-->
                <gtn-input class="medium-column mr-3" label="Column header" formControlName="headerText"></gtn-input>
                <gtn-dropdown class="medium-column mr-3" label="Input type" formControlName="tableInputType"
                    [options]="inputTypeOptions" [idOnly]="true"></gtn-dropdown>
                <gtn-questionnaire-dropdown-option-type-select class="medium-column mr-3"
                    *ngIf="getFormGroupFromAbstractControl(tableColumnHeader).get(inputTypeFormControlName)?.value === userInputTypesEnum.DropDown"
                    formControlName="dropdownTypeId"></gtn-questionnaire-dropdown-option-type-select>
                <gtn-dropdown
                    *ngIf="getFormGroupFromAbstractControl(tableColumnHeader).get(inputTypeFormControlName)?.value !== userInputTypesEnum.DropDown ||
                            getFormGroupFromAbstractControl(tableColumnHeader).get(inputTypeFormControlName)?.value !== userInputTypesEnum.Date"
                    class="medium-column mr-3" label="Alignment" formControlName="alignment" [idOnly]="true"
                    [options]="alignmentOptions"></gtn-dropdown>
                <gtn-checkbox
                    *ngIf="getFormGroupFromAbstractControl(tableColumnHeader).get(inputTypeFormControlName)?.value === userInputTypesEnum.Numeric"
                    class="medium-column mr-3"
                    label="Separated by commas" formControlName="numberDelimiter"></gtn-checkbox>
            </ng-container>
        </div>
    </ng-container>
    <gtn-input label="Table row headers (deliminited by semi-colon)" formControlName="tableRowHeaders"></gtn-input>
</form>
