<gtn-modal class="small" [showCancelButton]="isMobileDevice && isTaxPayer" (cancelClick)="onSecondaryClicked()" cancelButtonText="Proceed to Desktop" headerText="Degraded Experience Detected" [okButtonText]="okButtonText"
           (okClick)="onPrimaryClicked()">
    <gtn-modal-content>
        <ng-container *ngIf="!isBrowserValid && !isMobileDevice">
            <div class="row">
                <div class="col-12">
                    We have detected that you are using an <b>unsupported browser</b> that will not provide an optimal experience. We recommend using one of the following browsers to improve your experience:
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="flex mt-3">
                        <i class="fa-brands fa-chrome fa-2x"></i>
                        <a class="browser-link" target="_blank" href="https://www.google.com/chrome/browser/desktop/index.html">Google Chrome</a>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="flex mt-3">
                        <i class="fa-brands fa-firefox fa-2x"></i>
                        <a class="browser-link" target="_blank" href="https://www.mozilla.org/en-US/firefox/new/">Mozilla Firefox</a>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="flex mt-3">
                        <i class="fa-brands fa-edge fa-2x"></i>
                        <a class="browser-link" target="_blank" href="https://www.microsoft.com/en-us/edge/download">Microsoft Edge</a>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12 flex justify-content-end">
                    <gtn-checkbox [(ngModel)]="isRememberSelectionChecked" label="Do not prompt again"></gtn-checkbox>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="isMobileDevice">
            <div class="row" *ngIf="isTaxPayer">
                <div class="col-12">
                    My GTN Portal has a <b>new optimized experience for mobile devices</b>.
                </div>
            </div>
            <div class="row" *ngIf="!isTaxPayer">
                <div class="col-12">
                    My GTN Portal <b>does not have a mobile experience for your user type.</b> To ensure the best experience please visit the site from a computer.
                </div>
            </div>
        </ng-container>
    </gtn-modal-content>
</gtn-modal>
