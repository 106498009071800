import { Injectable } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ResizeService } from './resize.service';

@Injectable()
export class WindowResizeService {
    resized: Subject<Window> = new Subject();

    private windowResizeSubject: Subject<void> = new Subject();
    private windowResizingSubject: Subject<UIEvent> = new Subject();

    constructor(private eventManager: EventManager, private resizeService: ResizeService) {
        window.addEventListener('resize', this.onResize.bind(this));

        this.windowResizingSubject.pipe(debounceTime(100)).subscribe(event => {
            this.onResized(event);
        });

        this.windowResizeSubject.pipe(debounceTime(10)).subscribe(_ => {
            this.doResize();
        });
    }

    resize() {
        this.windowResizeSubject.next();
    }

    private onResize(event: UIEvent) {
        this.windowResizingSubject.next(event);
        this.resizeService.resize.next();
    }

    private onResized(event: UIEvent) {
        this.resized.next(<Window>event.target);
    }

    private doResize() {
        window.dispatchEvent(new Event('resize'));
    }
}
