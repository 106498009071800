import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseEntryComponent } from 'app/nexus-shared/components/base-component/base-entry.component';
import { IndividualRelationshipModel } from 'app/nexus-shared/domain/contacts/models/individual-relationship.model';
import { ApplicationsEnum } from 'app/nexus-shared/enums';
import { IndividualRelationshipService } from 'app/nexus-core/services/domain/contacts/individual-relationship.service';
import { IndividualModel } from 'app/nexus-shared/domain/contacts/models';
import { Observable } from 'rxjs';

@Component({
    selector: 'gtn-individual-relationship-modal',
    templateUrl: './individual-relationship-modal.component.html',
    styleUrls: ['./individual-relationship-modal.component.scss']
})
export class IndividualRelationshipModalComponent extends BaseEntryComponent<IndividualRelationshipModel> implements OnInit {
    @Input() isControllingCompany: boolean = false;
    @Input() application: ApplicationsEnum = ApplicationsEnum.Contacts;
    @Input() individual: IndividualModel;
    @Input() isAdmin: boolean = false;

    @Output() saveSuccess: EventEmitter<void> = new EventEmitter<void>();

    constructor(private individualRelationshipService: IndividualRelationshipService) {
        super();
    }

    ngOnInit() {
        if (!this.value.individualRelationshipKey) {
            this.value.parentIndividual = this.individual;
        }
    }

    onCancelClicked(): void {
        this.cancelClick.emit();
    }

    onSaveClicked(): void {
        this.isSaving = true;
        this.save().subscribe(res => {
            this.saveSuccess.emit();
        }, err => {
            this.isSaving = false;
            this.onSaveFailure(err);
        });
    }

    private save(): Observable<string | boolean> {
        if (!this.value.individualRelationshipKey) {
            return this.individualRelationshipService.create(this.value);
        } else {
            return this.individualRelationshipService.update(this.value);
        }
    }

}
