<form [formGroup]="formGroup" class="tax-rate-detail-form">
    <h1 class="page-top-header">
        Tax rate
    </h1>
    <div *ngIf="tax" class="return">
        <i (click)="return()" class="fa fa-reply" matTooltip="Return to tax.name"></i>
        <span (click)="return()" matTooltip="Return to {{tax.name}}">{{ tax.name }}</span>
    </div>
    <div class="row">
        <gtn-datepicker class="col-sm-12 col-md-6 col-lg-4" [formControlName]="formControlNames.effectiveStartDate"
            label="Start date">
        </gtn-datepicker>
    </div>
    <div class="row">
        <gtn-datepicker class="col-sm-12 col-md-6 col-lg-4" [formControlName]="formControlNames.effectiveEndDate"
            label="End date">
        </gtn-datepicker>
    </div>
    <div class="row">
        <gtn-input class="col-sm-6 col-md-2" [formControlName]="formControlNames.rate" type="number" label="Rate">
        </gtn-input>

        <gtn-input class="col-sm-6 col-md-2" [formControlName]="formControlNames.limit" type="number" label="Limit">
        </gtn-input>
    </div>
    <div class="row">
        <gtn-dropdown class="col-md-6 col-lg-4" [formControlName]="formControlNames.taxResidencyId" [idOnly]="true"
            idKey="value" displayKey="label" label="Residency" [options]="taxResidencyOptions">
        </gtn-dropdown>
    </div>
    <div class="row">
        <gtn-dropdown class="col-md-6 col-lg-4" [formControlName]="formControlNames.taxQualifierId" [idOnly]="true"
            idKey="taxQualifierId" displayKey="name" label="Qualifier" [options]="taxQualifiers">
        </gtn-dropdown>
    </div>
    <div class="row">
        <gtn-input class="col-md-6 col-lg-5" [formControlName]="formControlNames.specializationTitle" type="string"
            label="Specialization title">
        </gtn-input>
    </div>
    <div class="row">
        <gtn-input class="col-md-6 col-lg-5" [formControlName]="formControlNames.specializationKey" type="string"
            label="Specialization key">
        </gtn-input>
    </div>
</form>
<div *ngIf="formGroup.dirty" @footerButtonGroupOpen class="form-button-group">
    <button mat-flat-button (click)="save()" [disabled]="formGroup.invalid" class="mat-accent save"
        id="saveButton">Save
    </button>
    <button mat-flat-button (click)="cancel()" class="mat-button-default cancel" id="cancelButton">Cancel</button>
    <gtn-checkbox label="Save and Continue" [(ngModel)]="repeatAction">
    </gtn-checkbox>
</div>
