import { Component, Input, OnDestroy } from '@angular/core';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { ViewModesEnum } from 'app/nexus-shared/enums/view-modes.enum';
import { CardModesEnum } from 'app/nexus-shared/enums/card-modes.enum';
import { CardAppearanceEnum } from 'app/nexus-shared/enums/card-appearance.enum';
import { DisplayConstants } from 'app/nexus-shared/constants';
import { HtmlHelper } from 'app/nexus-core/helpers/html.helper';
import { DateHelper } from 'app/nexus-core';

@Component({ template: '' })
export abstract class BaseViewComponent<T> extends BaseComponent implements OnDestroy {
    @Input() viewMode: ViewModesEnum = ViewModesEnum.All;
    @Input() cardMode: CardModesEnum = CardModesEnum.hidden;
    @Input() value: T = null;

    noValueText = HtmlHelper.replaceHtmlEntities(DisplayConstants.None);
    unknownValueText = HtmlHelper.replaceHtmlEntities(DisplayConstants.Unknown);
    viewModes = ViewModesEnum;
    cardModes = CardModesEnum;
    cardAppearances = CardAppearanceEnum;

    getDateRange(startDate: string | Date, endDate: string | Date): string {
        return DateHelper.formatDateRange(startDate, endDate);
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
}
