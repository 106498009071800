import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { FilterChipModel } from 'app/nexus-shared/components/controls/shared/models/filter-chip.model';

@Component({
    selector: 'gtn-filter-chips',
    templateUrl: './filter-chips.component.html',
    styleUrls: ['./filter-chips.component.scss']
})
export class FilterChipsComponent extends BaseComponent {
    @Input() filterChips: FilterChipModel[];
    @Input() isRemovableChips: boolean = false;
    @Output() filterChipClick: EventEmitter<FilterChipModel> = new EventEmitter<FilterChipModel>();
    @Output() filterChipRemoveClick: EventEmitter<FilterChipModel> = new EventEmitter<FilterChipModel>();

}
