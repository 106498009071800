import { Component } from '@angular/core';
import { BaseViewComponent } from 'app/nexus-shared/components/base-component/base-view.component';
import { AddressModel } from 'app/nexus-shared/domain/contacts/models/address.model';
import { AddressTypeEnum } from 'app/nexus-shared/enums/address-type.enum';

@Component({
    selector: 'gtn-address-view',
    templateUrl: './address-view.component.html',
    styleUrls: ['./address-view.component.scss']
})
export class AddressViewComponent extends BaseViewComponent<AddressModel> {
    addressTypeEnum = AddressTypeEnum;
}
