import { Component } from '@angular/core';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';

@Component({
    selector: 'gtn-nexus-modal',
    templateUrl: './nexus-modal.component.html',
    styleUrls: ['./nexus-modal.component.scss']
})
export class NexusModalComponent extends BaseComponent {
}
