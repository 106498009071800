import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { RowNode } from 'ag-grid-community';

@Component({
    selector: 'gtn-control-column',
    templateUrl: './control-column.component.html',
    styleUrls: ['./control-column.component.scss']
})
export class ControlColumnComponent implements ICellRendererAngularComp {
    cloneClick: Function;
    editClick: Function;
    dataModel: any;
    deleteClick: Function;
    isEditing: boolean;
    navigateClick: Function;
    preventEditMode: boolean;
    refreshClick: Function;
    rowIndex: number;
    rowNode: RowNode;
    saveClick: Function;
    isValidateError: boolean;
    isInlineEdit: boolean;

    constructor() { }

    agInit(params: any): void {
        this.dataModel = params.data;
        this.rowIndex = params.rowIndex;
        this.rowNode = params.node;
        this.cloneClick = params.cloneClick;
        this.deleteClick = params.deleteClick;
        this.editClick = params.editClick;
        this.navigateClick = params.navigateClick;
        this.refreshClick = params.refreshClick;
        this.saveClick = params.saveClick;
        this.preventEditMode = params.preventEditMode;
        this.isValidateError = !params.data?.isValid;
        this.isInlineEdit = params.isInlineEdit ?? true;
        if (this.isInlineEdit === false) {
            this.isValidateError = false;
        }
    }

    onCloneClicked(event: any) {
        event.stopPropagation();
        this.cloneClick(this.rowIndex);
    }

    onEditClick(event: any): void {
        event.stopPropagation();
        if (!this.isInlineEdit) {
            this.editClick(this.dataModel);
            return;
        }
        if (!this.isEditing) {
            // Edit
            this.editClick(this.dataModel);
        } else {
            // Save
            this.saveClick(this.dataModel);
        }
        if (!this.preventEditMode) {
            this.isEditing = !this.isEditing;
        }
    }

    onDeleteClicked(event: any): void {
        event.stopPropagation();
        this.deleteClick(this.rowIndex);
    }

    onNavigateClicked(event: any): void {
        event.stopPropagation();
        this.navigateClick(this.dataModel);
    }

    onRefreshClicked(event: any): void {
        event.stopPropagation();
        this.refreshClick(this.rowNode);
    }

    refresh(params): boolean {
        return false;
    }
}
