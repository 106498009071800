<form [formGroup]="formGroupRef" (ngSubmit)="onSubmit()">
    <ng-container *ngIf="!isValidationStep">
        <div class="row">
            <div class="col-12">
                <p>
                    Changing your account email address will impact:
                </p>
                <ul>
                    <li>where your notification emails go</li>
                    <li>how you log into your My GTN Portal account</li>
                </ul>
                <p>
                    To proceed, enter the new email address and confirm your account password. A confirmation email will then be sent to your new email address with a verification code. <b>Note that this code will only be valid for 15 minutes.</b>
                </p>
                <p>
                    Once your code is verified, you will be automatically logged out and will need to log back in with the new email address.
                </p>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-12">
                <label class="list-label">Current account email</label>
                <div class="data-field">{{currentEmail}}</div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <gtn-input formControlName="newEmail" label="New email address"></gtn-input>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-12">
                <gtn-input formControlName="userPassword" type="password" label="Account password"></gtn-input>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="isValidationStep">
        <div class="row">
            <div class="col-12">
                A verification code has been sent to <b>{{formGroupRef.value.newEmail}}</b>; this will confirm that you have access to this account.
                <br><br>
                Upon successful verification, your email change will be processed. You will be logged out, and can then re-log in with your newly set email address.
            </div>
            <div class="col-12">
                <gtn-verification-input formControlName="twoFactorCode" (codeComplete)="disableButtonsChange.emit(!$event)"></gtn-verification-input>
            </div>
        </div>
    </ng-container>
</form>
