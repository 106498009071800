import { Component } from '@angular/core';
import { CalendarComponent } from 'app/nexus-shared/components/calendar/calendar.component';

@Component({
    selector: 'gtn-calendar-list-content',
    template: '<ng-content></ng-content>'
})
export class CalendarListContentComponent {
    // noinspection JSUnusedLocalSymbols
    constructor(calendarComponent: CalendarComponent) {
        // THIS WILL THROW AN ERROR IF ITS NOT NESTED WITHIN PARENT
    }
}
