<div class="row">
    <div class="col-12">
        <table>
            <tr>
                <th class="small">Name</th>
                <th class="small">Description</th>
                <th class="extra-small"></th>
            </tr>
            <tr class="role-container" *ngFor="let roleDefinition of roleDefinitions">
                <td class="small">{{roleDefinition.name}}</td>
                <td class="small">{{roleDefinition.description}}</td>
                <td class="extra-small">
                    <gtn-slide-toggle
                        [(ngModel)]="roleDefinition.selected"
                        [disabled]="roleDefinition.roleType === gtnEmployeeRoleTypeId"
                        (change)="onSelectedRoleChanged(roleDefinition)">
                    </gtn-slide-toggle>
                </td>
            </tr>
        </table>
    </div>
</div>
