<div matRipple *ngIf="count" class="validation-indicator-wrapper" [matTooltip]="title" [matRippleCentered]="true">
    <div class="validation-icon-wrapper">
        <i class="fa fa-exclamation-circle"></i>
    </div>
    <div class="badge">
        <span class="validation-error-count">
            {{ count }}
        </span>
    </div>
</div>
