import { Component, Input, OnInit } from '@angular/core';
import { CompanyModel, IndividualModel, IndividualToCompanyAccessModel } from 'app/nexus-shared/domain/contacts/models';
import { IndividualCompanyAccessService } from 'app/nexus-core/services/domain/contacts/individual-company-access.service';
import { IndividualService } from 'app/nexus-core/services/domain/contacts/individual.service';
import { ApplicationsEnum } from 'app/nexus-shared/enums';
import { BaseAccessModalComponent } from 'app/nexus-shared/domain/contacts/components/modals/shared/access-modal-base/base-access-modal.component';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { IndividualTypeModel } from 'app/nexus-shared/domain/contacts/models/individual-type.model';

@Component({
    selector: 'gtn-company-individual-access-modal',
    templateUrl: './company-individual-access-modal.component.html',
    styleUrls: ['./company-individual-access-modal.component.scss']
})
export class CompanyIndividualAccessModalComponent extends BaseAccessModalComponent implements OnInit {
    @Input() company: CompanyModel;
    @Input() applicationSource: ApplicationsEnum = ApplicationsEnum.Contacts;
    @Input() isControllingCompany: boolean = false;
    @Input() canAddNewIndividual: boolean = false;
    @Input() isRelationshipTypeReadonly: boolean = false;

    individual: IndividualModel;
    isCreateIndividual: boolean = false;
    headerText: string;

    constructor(
        private individualCompanyAccessService: IndividualCompanyAccessService,
        private individualService: IndividualService,
    ) {
        super();
    }

    ngOnInit(): void {
        if (this.value?.individualToCompanyAccessKey) {
            this.individual = new IndividualModel(this.value.individual);
        }
        super.ngOnInit();
    }

    onIndividualSelected(individualKey: string): void {
        this.isSaving = true;
        this.individualService.getByKey(individualKey).subscribe(res => {
            this.formGroupRef.get(ObjectHelper.nameOf<IndividualToCompanyAccessModel>('individual')).setValue(new IndividualModel(res));
            this.isSaving = false;
        });
    }

    onCreateIndividualClicked(): void {
        this.individual = new IndividualModel();
        if (this.value?.relationshipType && !this.value?.individual) {
            const individualType = new IndividualTypeModel();
            individualType.type = this.value?.relationshipType;
            this.individual.individualTypes.push(individualType);
        }
        this.individual.applicationSourceType = this.applicationSource;
        this.isCreateIndividual = true;
    }

    onSaveClicked(): void {
        this.saveAccess(this.individualCompanyAccessService, this.individualService);
    }

    onIndividualSaveSuccess(individual: IndividualModel): void {
        this.value.individual = new IndividualModel(individual);
        this.isCreateIndividual = false;
        setTimeout((): void => {
            this.formGroupRef.markAsDirty();
        });
    }
}
