export class TaxJurisdictionModel {
    taxJurisdictionId: number;
    name: string;
    taxJurisdictionTypeId: number;
    effectiveStartDate: Date;
    effectiveEndDate?: Date;
    countryId?: number;
    stateId?: number;
    cityId?: number;
    countyId?: number;
    countryName: string;
    stateName: string;
    cityName: string;
    countyName: string;

    constructor() {
        this.taxJurisdictionTypeId = 1;
    }
}
