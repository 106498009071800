import { CommonBaseAuditUserModel } from './common-base-audit-user.model';

export class CommonBaseAuditModel {
    createdByUser: CommonBaseAuditUserModel;
    lastModifiedByUser: CommonBaseAuditUserModel;

    constructor(model: CommonBaseAuditModel = null){
        this.createdByUser = model?.createdByUser ?? null;
        this.lastModifiedByUser = model?.lastModifiedByUser ?? null;
    }
}
