import { Component, ComponentFactoryResolver, ComponentRef, EventEmitter, Input, OnDestroy, OnInit, Output, Type, ViewChild } from '@angular/core';
import { FilterDirective } from 'app/nexus-shared/components/filter/filter.directive';
import { FilterAnimation } from 'app/nexus-shared/components/filter/filter.animation';
import { SelectListInterface } from 'app/nexus-shared';
import { Subscription } from 'rxjs';
import { AuthorizationConstants } from 'app/nexus-shared/constants/authorization.constants';
import { DateHelper } from 'app/nexus-core';

@Component({
    selector: 'gtn-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss'],
    animations: [
        FilterAnimation.animations
    ]
})
export class FilterComponent implements OnInit, OnDestroy {

    @Input() component: Type<any>;
    @Input() isFilterPanelCollapsed: boolean = true;

    @Output() applyEmitter: EventEmitter<SelectListInterface[]> = new EventEmitter();
    @Output() newRecord: EventEmitter<any> = new EventEmitter();

    @ViewChild(FilterDirective, { static: true }) gtnFilterHost: FilterDirective;

    authorizationConstants = AuthorizationConstants;
    activeFilters: SelectListInterface[];
    dateFormat = DateHelper.standardDateFormatTimeStamp;

    private filterPanel: ComponentRef<any>;
    private subscription: Subscription = new Subscription;

    constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

    ngOnInit(): void {
        this.loadFilterContent();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    onNewRecord(): void {
        this.newRecord.emit();
    }

    onRemoveFilter(item: SelectListInterface): void {
        this.filterPanel.instance.clearFilters(item);
    }

    onToggleFilter(): void {
        this.isFilterPanelCollapsed = !this.isFilterPanelCollapsed;
    }

    private loadFilterContent(): void {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.component);
        const viewContainerRef = this.gtnFilterHost.viewContainerRef;

        viewContainerRef.clear();
        const containerRef = viewContainerRef.createComponent<any>(componentFactory);

        this.subscription.add(containerRef.instance.updateChipsEmitter.subscribe(filters => {
            setTimeout(() => {
                this.activeFilters = filters;
            });
        }));

        this.subscription.add(containerRef.instance.applyEmitter.subscribe(filters => {
            if (!this.isFilterPanelCollapsed) {
                this.onToggleFilter();
            }

            this.applyEmitter.emit(filters);
        }));

        this.filterPanel = containerRef;
    }
}
