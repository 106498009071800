import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'specializationName'
})
export class SpecializationNamePipe implements PipeTransform {

    transform(value: string, options: string): string {
        if (!value || !options) {
            return value;
        } else {
            return value.split(/\s*-\s*/).filter(item => options.indexOf(item) < 0).join(' - ');
        }
    }
}
