import { Component } from '@angular/core';
import { BaseQuestionnaireFormComponent } from 'app/nexus-shared/domain/questionnaires/components/forms/base-questionnaire-form.component';
import { SectionColumnModel } from 'app/nexus-shared/domain/questionnaires/models/section-column.model';
import { AbstractControl, UntypedFormArray } from '@angular/forms';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { TableRowModel } from 'app/nexus-shared/domain/questionnaires/models/table-row.model';
import { Guid } from 'app/nexus-shared/models/guid.type';
import { QuestionnaireFormHelper } from 'app/nexus-shared/domain/questionnaires/components/forms/questionnaire-form.helper';
import { TableColumnModel } from 'app/nexus-shared/domain/questionnaires/models/table-column.model';

@Component({
    selector: 'gtn-section-column-view-table-form',
    templateUrl: './section-column-view-table-form.component.html',
    styleUrls: ['./section-column-view-table-form.component.scss', '../questionnaire-forms.component.scss']
})
export class SectionColumnViewTableFormComponent extends BaseQuestionnaireFormComponent<SectionColumnModel> {
    tableColumnHeadersFormArray: UntypedFormArray;
    tableRowsFormArray: UntypedFormArray;
    rowHeaders: string[];

    protected initFormCustomizations() {
        this.tableColumnHeadersFormArray = this.formGroupRef.get(ObjectHelper.nameOf<SectionColumnModel>('tableColumnHeaders')) as UntypedFormArray;
        this.tableRowsFormArray = this.formGroupRef.get(ObjectHelper.nameOf<SectionColumnModel>('tableRows')) as UntypedFormArray;
        this.rowHeaders = this.formGroupRef.get(ObjectHelper.nameOf<SectionColumnModel>('tableRowHeaders'))?.value?.split(';') ?? null;
    }

    getTableColumnFormArray(tableRow: AbstractControl): UntypedFormArray {
        return this.getFormGroupFromAbstractControl(tableRow).get(ObjectHelper.nameOf<TableRowModel>('columns')) as UntypedFormArray;
    }

    onAddTableRowClicked(): void {
        const tableRow = new TableRowModel();
        tableRow.key = Guid.newGuid();
        tableRow.order = this.tableRowsFormArray?.length ? this.tableRowsFormArray?.length + 1 : 1;
        tableRow.sectionColumnKey = this.formGroupRef.get(ObjectHelper.nameOf<SectionColumnModel>('key')).value;
        tableRow.columns = this.createTableRowColumns(tableRow.key.toString());
        this.initFormArrayFormGroup(tableRow, this.tableRowsFormArray, QuestionnaireFormHelper.getTableRowFormConfiguration());
    }

    onDeleteTableRowClicked() {
        this.tableRowsFormArray.removeAt(this.tableRowsFormArray?.length - 1);
    }

    private createTableRowColumns(tableRowKey: string): TableColumnModel[] {
        const tableColumns: TableColumnModel[] = [];
        this.tableColumnHeadersFormArray.value?.forEach((x => {
            const tableColumn = new TableColumnModel();
            tableColumn.order = x.order;
            tableColumn.tableRowKey = tableRowKey;
            tableColumn.key = Guid.newGuid();
            tableColumns.push(tableColumn);
        }));
        return tableColumns;
    }
}
