import { Component, Input, OnInit } from '@angular/core';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { BaseFormComponent } from 'app/nexus-shared/components/base-component/base-form.component';
import { IndividualModel } from 'app/nexus-shared/domain/contacts/models/individual.model';
import { ContactsFormHelper } from 'app/nexus-core/helpers/contacts-form.helper';
import { MyGTNPortalService } from 'app/nexus-core/services/domain/contacts/my-gtn-portal.service';
import { MyGTNPortalSearchResultModel } from 'app/nexus-shared/domain/contacts/models/my-gtn-portal-search-restult.model';
import { AuthorizationHelper } from 'app/nexus-core/helpers/authorization.helper';
import { AuthorizationConstants } from 'app/nexus-shared/constants/authorization.constants';
import { AuthenticatedUserService } from 'app/nexus-core/services/authenticated-user.service';
import { IndividualTypeModel } from '../../../models/individual-type.model';
import { IndividualTypesEnum } from '../../../enums/individual-types.enum';
import { EnumHelper } from 'app/nexus-core';
import { SelectListInterface } from 'app/nexus-shared/interfaces';
import { RegionTypesEnum } from 'app/nexus-shared/domain/contacts/enums/region-types.enum';
import { UntypedFormArray } from '@angular/forms';
import { AddressModel } from 'app/nexus-shared/domain/contacts/models/address.model';
import { PronounTypesEnum } from 'app/nexus-shared/domain/contacts/enums/pronoun-types.enum';
import { PronounEnumHelper } from 'app/nexus-shared/domain/contacts/helpers/pronoun-enum.helper';

@Component({
    selector: 'gtn-individual-form',
    templateUrl: './individual-form.component.html',
    styleUrls: ['./individual-form.component.scss']
})
export class IndividualFormComponent extends BaseFormComponent<IndividualModel> implements OnInit {
    @Input() isControllingCompany: boolean;
    @Input() isPhoneNumberSubFormArrayModal: boolean = false;
    @Input() isIndividualTypeReadonly: boolean = false;

    addressFormArray: UntypedFormArray;
    initialKey: boolean = false;
    isIndependent: boolean = false;
    isGtnAdmin: boolean = false;
    isGtnIndividualType: boolean = false;
    individualTypeOptions: IndividualTypeModel[];
    phoneNumbersFormArray: UntypedFormArray;
    myGTNPortalUserName: string;
    regionTypeOptions: SelectListInterface[] = [];
    pronounTypes: SelectListInterface[];

    constructor(
        private myGTNPortalService: MyGTNPortalService,
        private authenticatedUserService: AuthenticatedUserService
    ) {
        super();
    }

    ngOnInit(): void {
        const formConfiguration = ContactsFormHelper.getIndividualFormConfiguration();
        super.ngOnInit(formConfiguration);
        if (this.value.myGTNPortalUserKey) {
            this.initialKey = true;
            this.getMyGTNPortalUser(this.value.myGTNPortalUserKey);
        }
        this.pronounTypes = EnumHelper.convertToSelectList(PronounTypesEnum, false, false, null, true, PronounEnumHelper.formatPronouns);
        this.isGtnAdmin = AuthorizationHelper.hasAuthorization(this.authenticatedUserService.user, AuthorizationConstants.gtnAdministrator);
    }

    initFormValueChangesCustomizations(value: IndividualModel) {
        if (value.addresses?.length) {
            value.addresses = value.addresses.filter(address => {
                return !Object.values(address).every(x => x === null || x === '');
            });
        }
        return value;
    }

    initUIControls() {
        this.individualTypeOptions = this.createIndividualTypeOptions();
        this.regionTypeOptions = EnumHelper.convertToSelectList(RegionTypesEnum, false, true);
        this.isIndependent = this.value?.individualTypes?.some(x => x.type === IndividualTypesEnum.Independent);
        this.isGtnIndividualType = this.value?.individualTypes?.some(x => x.type === IndividualTypesEnum.GtnEmployee);
    }

    initFormCustomizations(): void {
        this.phoneNumbersFormArray = this.formGroupRef.get(ObjectHelper.nameOf<IndividualModel>('phoneNumbers')) as UntypedFormArray;
        this.addressFormArray = this.formGroupRef.get(ObjectHelper.nameOf<IndividualModel>('addresses')) as UntypedFormArray;
        if (this.isControllingCompany || this.isIndividualTypeReadonly) {
            const individualType = new IndividualTypeModel();
            individualType.type = IndividualTypesEnum.GtnEmployee;
            this.formGroupRef.get(ObjectHelper.nameOf<IndividualModel>('individualTypes')).setValue([individualType]);
        }
    }

    onIndividualTypeChanged(individualTypes: IndividualTypeModel[]) {
        this.isIndependent = !!individualTypes?.some(x => x.type === IndividualTypesEnum.Independent);
        if (!this.isIndependent) {
            this.formGroupRef.get(ObjectHelper.nameOf<IndividualModel>('region')).reset();
        }
    }

    onRemoveMyGTNPortalLinkClicked(): void {
        this.formGroupRef.get(ObjectHelper.nameOf<IndividualModel>('myGTNPortalUserKey')).reset(null);
        this.formGroupRef.markAsDirty();
        this.formGroupRef.updateValueAndValidity();
        this.initialKey = false;
        this.myGTNPortalUserName = null;
    }

    onCreateAddressClicked(): void {
        this.initFormArrayFormGroup(new AddressModel(), this.addressFormArray);
    }
    onMyGTNPortalUserSelect(user: MyGTNPortalSearchResultModel): void {
        this.myGTNPortalUserName = user?.name;
    }

    individualTypeDisplayFn(value: IndividualTypeModel): string {
        return EnumHelper.getDisplayName(IndividualTypesEnum, value.type);
    }

    private getMyGTNPortalUser(key: string): void {
        this.myGTNPortalService.getIndividual(key).subscribe((res) => {
            this.myGTNPortalUserName = res;
        });
    }

    private createIndividualTypeOptions(): IndividualTypeModel[] {
        const individualOptions: IndividualTypeModel[] = this.value?.individualTypes?.length ? [].concat(this.value.individualTypes) : [];
        for (const enumItem in IndividualTypesEnum) {
            if (IndividualTypesEnum[enumItem] && isNaN(Number(IndividualTypesEnum[enumItem])) && parseInt(enumItem, 10) !== 0 && !individualOptions.find(x => x.type === parseInt(enumItem, 10))) {
                const option: IndividualTypeModel = new IndividualTypeModel();
                option.individualTypeKey = null;
                option.type = parseInt(enumItem, 10);
                individualOptions.push(option);
            }
        }
        if (!this.isIndividualTypeReadonly) {
            // Remove the GtnEmployee from individualOptions
            const gtnEmployeeIndex: number = individualOptions.findIndex(option => option.type === IndividualTypesEnum.GtnEmployee);
            if (gtnEmployeeIndex !== -1) {
                individualOptions.splice(gtnEmployeeIndex, 1);
            }
        }
        return individualOptions;
    }
}
