import { animate, AnimationMetadata, style, transition, trigger } from '@angular/animations';

export class FooterButtonGroupOpenUp {
    /**
     * Use '@footerButtonGroupOpen' in markup.
     */
    static animations = FooterButtonGroupOpenUp.getAnimation();

    private static getAnimation(): AnimationMetadata {
        return trigger('footerButtonGroupOpen', [
            transition(':enter', [
                style({transform: 'translateY(100%)'}),
                animate('200ms ease-in', style({transform: 'translateY(0%)'}))
            ]),
            transition(':leave', [
                animate('200ms ease-in', style({transform: 'translateY(100%)'}))
            ])
        ]);
    }
}
