<gtn-template-view [cardMode]="cardMode">
    <gtn-template-view-content>
        <ng-container *ngIf="viewMode === viewModes.All || viewMode === viewModes.General">
            <div class="row">
                <div class="col-3">
                    <label class="list-label">Individual Types</label>
                    <ng-container *ngIf="value.individualTypes.length">
                        <div class="data-field" *ngFor="let individualType of value.individualTypes; let isLast = last">
                            {{ individualType.type | enum: individualTypesEnum }}{{ !isLast ? ', ' : '' }}
                        </div>
                    </ng-container>
                    <div *ngIf="!value.individualTypes.length" class="data-field">{{ noValueText }}</div>
                </div>
                <div class="col-3">
                    <label class="list-label">Region</label>
                    <div class="data-field">
                        <span *ngIf="value.region">{{ value.region | enum: regionsEnum }}</span>
                        <span *ngIf="!value.region">{{ noValueText }}</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <label class="list-label">First name</label>
                    <div class="data-field">
                        {{ value.firstName || noValueText }}
                    </div>
                </div>
                <div class="col-3">
                    <label class="list-label">Middle name</label>
                    <div class="data-field">
                        {{ value.middleName || noValueText }}
                    </div>
                </div>
                <div class="col-3">
                    <label class="list-label">Last name</label>
                    <div class="data-field">
                        {{ value.lastName || noValueText }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <label class="list-label">Preferred name</label>
                    <div class="data-field">
                        {{ value.preferredName || noValueText }}
                    </div>
                </div>
                <div class="col-3">
                    <label class="list-label">Preferred pronouns</label>
                    <div class="data-field">
                        {{ (value.pronoun | enum: pronounTypes: enumHelper.formatPronouns) || noValueText }}
                    </div>
                </div>
                <div class="col-3">
                    <label class="list-label">Date of birth</label>
                    <div class="data-field">
                        {{ (value.birthDate | gtnDate) || noValueText }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <label class="list-label">Primary email</label>
                    <div class="data-field">
                        {{ value.emailAddress || noValueText }}
                    </div>
                </div>
                <div class="col-3">
                    <label class="list-label">Secondary email</label>
                    <div class="data-field">
                        {{ value.secondaryEmailAddress || noValueText }}
                    </div>
                </div>
            </div>
            <ng-container *ngIf="value.phoneNumbers?.length">
                <div class="section-header">
                    Phone Numbers
                </div>
                <div class="row mb-1">
                    <div class="col-md-3">
                        <label class="list-label">Country</label>
                    </div>
                    <div class="col-md-3">
                        <label class="list-label">Phone number</label>
                    </div>
                    <div class="col-md-3">
                        <label class="list-label">Extension</label>
                    </div>
                    <div class="col-md-3">
                        <label class="list-label">Type</label>
                    </div>
                </div>
                <ng-container *ngFor="let phoneNumber of value.phoneNumbers">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="data-field">
                                {{ phoneNumber.countryCode || noValueText }}
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="data-field">
                                {{ phoneNumber.formattedPhoneNumber | gtnPhoneNumber : phoneNumber.countryCode }}
                                <span *ngIf="phoneNumber.isPrimary" matTooltip="Primary number"><i
                                    class="fa-duotone fa-phone-plus"></i></span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="data-field">
                                {{ phoneNumber.extension || noValueText }}
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="data-field">
                                {{ (phoneNumber.type | enum : phoneNumberTypes) || noValueText }}
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="viewMode === viewModes.All || viewMode === viewModes.AssociatedApplications">
            <div class="row">
                <div class="col-md-3">
                    <label class="list-label">Source application</label>
                    <div class="data-field">{{ value.applicationSourceType | enum : applicationsEnum }}</div>
                </div>
                <div class="col-md-3">
                    <gtn-application-links [value]="value.individualToApplications"
                        [currentApplication]="application"
                        [individualKey]="value.individualKey"
                        [myGTNPortalUserKey]="value.myGTNPortalUserKey"></gtn-application-links>
                </div>
                <div class="col-md-3">
                    <label class="list-label">Portal user linked</label>
                    <div class="d-flex flex-row">
                        <span *ngIf="value.myGTNPortalUserKey && !myGTNPortalUserName"><i
                            class="fas fa-spinner fa-spin"></i></span>
                        <div class="data-field" *ngIf="value.myGTNPortalUserKey && myGTNPortalUserName">
                            {{ myGTNPortalUserName }}
                        </div>
                        <span class="data-field" *ngIf="!value.myGTNPortalUserKey">{{ noValueText }}</span>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="viewMode === viewModes.All || viewMode === viewModes.Addresses">
            <ng-container *ngIf="value.addresses?.length">
                <ng-container *ngFor="let address of value.addresses; last as isLast">
                    <gtn-address-view [value]="address" [viewMode]="viewModes.Addresses">
                    </gtn-address-view>
                    <hr *ngIf="!isLast">
                </ng-container>
            </ng-container>
            <ng-container *ngIf="!value.addresses?.length">
                No addresses defined
            </ng-container>
        </ng-container>
        <!--        <ng-container *ngIf="viewMode === viewModes.All || viewMode === viewModes.Integrations">-->
        <!--            <ng-container *ngIf="value.contactIntegrations?.length">-->
        <!--                <ng-container *ngFor="let contactIntegration of value.contactIntegrations; last as isLast">-->
        <!--                    <gtn-contact-integrations-view [value]="contactIntegration" [viewMode]="viewModes.Integrations">-->
        <!--                    </gtn-contact-integrations-view>-->
        <!--                    <hr *ngIf="!isLast">-->
        <!--                </ng-container>-->
        <!--            </ng-container>-->
        <!--            <ng-container *ngIf="!value.contactIntegrations?.length">-->
        <!--                No integrations defined-->
        <!--            </ng-container>-->
        <!--        </ng-container>-->
    </gtn-template-view-content>
</gtn-template-view>

