import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { CalendarDayModel } from 'app/nexus-shared/models/calendar-day.model';

@Component({
    selector: 'gtn-calendar-day-view',
    templateUrl: './calendar-day-view.component.html',
    styleUrls: ['./calendar-day-view.component.scss']
})
export class CalendarDayViewComponent extends BaseComponent implements OnInit {
    @Input() day: CalendarDayModel;
    @Input() template: TemplateRef<any>;

    ngOnInit() {
    }

}
