import { AfterContentChecked, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, Output, ViewChild } from '@angular/core';
import { NexusAnimations } from 'app/nexus-shared/animations';
import { ColorConstants } from 'app/nexus-shared/constants';
import { LoadingOverlayLoadingType } from 'app/nexus-shared/types';

@Component({
    selector: 'gtn-loading-overlay',
    templateUrl: './loading-overlay.component.html',
    styleUrls: ['./loading-overlay.component.scss'],
    animations: [
        NexusAnimations.fadeInFadeOut
    ]
})
export class LoadingOverlayComponent implements AfterContentChecked {
    @Output() isPainted: EventEmitter<boolean> = new EventEmitter();
    @ViewChild('background') background: ElementRef;

    _isVisible: boolean;
    _overlayBackgroundColor: 'white' | 'gray' = 'white';
    backgroundColorValue: string = '#ffffff';
    message: string;
    mode: LoadingOverlayLoadingType;
    value: any;

    // property that allows us to set a width here and consume it in the .scss
    @HostBinding('style.--target-background-width')
    private targetBackgroundWidth: string = '100px';

    private _width: number = 0;

    set isVisible(shouldBeVisible: boolean) {
        if (!shouldBeVisible && this._isVisible) {
            this.hasBeenPainted = false;
        }

        this._isVisible = shouldBeVisible;
    }

    get isVisible() {
        return this._isVisible;
    }

    set width(value: number) {
        this._width = value;
        this.targetBackgroundWidth = `${this.width + 100}px`;
    }

    get width() {
        return this._width;
    }

    set overlayBackgroundColor(value: 'white' | 'gray') {
        this._overlayBackgroundColor = value;

        if (value === 'white') {
            this.backgroundColorValue = ColorConstants.white;
        } else if (value === 'gray') {
            this.backgroundColorValue = ColorConstants.backgroundGray;
        }
    }

    get overlayBackgroundColor(): 'white' | 'gray' {
        return this._overlayBackgroundColor;
    }

    private hasBeenPainted: boolean = false;

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        public elementRef: ElementRef
    ) {
    }

    ngAfterContentChecked(): void {
        if (this.background && this._isVisible && !this.hasBeenPainted) {
            this.hasBeenPainted = true;
            this.isPainted.next(true);
        }
    }
}
