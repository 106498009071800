import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { EnumHelper } from 'app/nexus-core';
import { BaseFormComponent } from 'app/nexus-shared/components/base-component/base-form.component';
import { BaseFormModel } from 'app/nexus-shared/models/base-form.model';
import { ContactIntegrationTypesEnum } from '../../../enums/contact-integration-types.enum';
import { ContactIntegrationModel } from '../../../models/contact-integration.model';

@Component({
    selector: 'gtn-contact-integrations-subform',
    templateUrl: './contact-integrations-subform.component.html',
    styleUrls: ['./contact-integrations-subform.component.scss']
})
export class ContactIntegrationsSubformComponent extends BaseFormComponent<ContactIntegrationModel[]> implements OnInit {
    @Input() formArray: UntypedFormArray;

    integrationTypeOptions = EnumHelper.convertToSelectList(ContactIntegrationTypesEnum, false, false);

    constructor() {
        super();
    }

    ngOnInit(): void {
        if (this.formArray?.length === 0) {
            this.onAddContactIntegrationClicked(false);
        }
    }

    onAddContactIntegrationClicked(markAsDirty: boolean = true): void {
        const formConfiguration = new BaseFormModel();
        const newFormGroup = new UntypedFormGroup({});
        this.buildFormFromTemplate(new ContactIntegrationModel(), newFormGroup, formConfiguration);

        this.formArray.push(newFormGroup);
        if (markAsDirty) {
            this.formArray.markAsDirty();
            this.formArray.updateValueAndValidity();
        }
    }

    onRemoveContactIntegrationClicked(i: number): void {
        this.formArray.removeAt(i);
        this.formArray.markAsDirty();
        this.formArray.updateValueAndValidity();
    }
}
