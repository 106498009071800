import { DocumentProcessTypesEnum } from 'app/nexus-shared/domain/documents/enums/document-process-types.enum';
import { DocumentModel } from 'app/nexus-shared/domain/documents/models/document.model';
import { DocumentProcessStatusesEnum } from 'app/nexus-shared/domain/documents/enums/document-process-statuses.enum';
import { EnumHelper } from 'app/nexus-core';
import { AreaTypesEnum } from 'app/nexus-shared/domain/documents/enums/area-types.enum';

export class DocumentProcessModel {
    processType: DocumentProcessTypesEnum;
    processStatus: DocumentProcessStatusesEnum;
    processStatusIcon: string;
    errorMessage: string;
    uploadFile: File;
    document: DocumentModel;
    folderData: string;
    uploadProgress: number;

    constructor(document: DocumentModel, file: File = null) {
        this.processType = null;
        this.processStatus = null;
        this.processStatusIcon = null;
        this.errorMessage = null;
        this.document = document ?? null;
        this.uploadFile = file ?? null;
        this.folderData = document ? DocumentProcessModel.constructFolderData(document) : null;
    }

    private static constructFolderData(document: DocumentModel): string {
        let folderData: string = EnumHelper.getDisplayName(AreaTypesEnum, document.areaType);

        if (document?.clientDocument?.companyName) {
            folderData += ` > ${document.clientDocument.companyName}`;
        }

        if (document?.clientDocument?.individualLastName && document?.clientDocument?.individualFirstName) {
            folderData += ` > ${document.clientDocument.individualLastName}, ${document.clientDocument.individualFirstName}`;
        }

        const pathSplit = document.documentPath.split('/');
        folderData += ` > ${pathSplit[pathSplit.length - 1]}`;

        return folderData;
    }
}
