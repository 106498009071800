export class AuthorizationConstants {
    /******************************************/
    /* APPLICATION SPECIFIC RESOURCES         */
    /* [APIName][Resource][Specialization]    */
    /******************************************/
    public static readonly gtnAdministrator = 'GtnAdministrator';
    public static readonly gtnUser = 'GtnUser';

    /******************************************/
    /* EQUITY SPECIFIC POLICIES               */
    /******************************************/
    public static readonly equity = 'Equity';
    public static readonly equityAdmin = 'EquityAdmin';

    /******************************************/
    /* WHEREABOUTS SPECIFIC POLICIES          */
    /******************************************/
    public static readonly whereabouts = 'Whereabouts';
    public static readonly whereaboutsAdmin = 'WhereaboutsAdmin';
    public static readonly whereaboutsRemoteWorkUser = 'WhereaboutsRemoteWorkUser';

    /******************************************/
    /* TRACTION SPECIFIC POLICIES             */
    /******************************************/
    public static readonly traction = 'Traction';
    public static readonly tractionAdmin = 'TractionAdmin';

    /******************************************/
    /* PORTAL 2.0 SPECIFIC AUTHORIZATION      */
    /******************************************/
    public static readonly portal = 'Portal';
    public static readonly portalAdmin = "PortalAdmin";
    public static readonly portalAdminInvoicing = "PortalAdminInvoicing";
    public static readonly portalAdminDocuments ="PortalAdminDocuments";
    public static readonly portalAdminMobileWorkforce = 'PortalAdminMobileWorkForce';
    public static readonly portalCompanyAdmin = 'PortalCompanyAdmin';
    public static readonly portalRelocationAdmin = 'PortalRelocationAdmin';
    public static readonly portalAdminMobileWorkforceChecklist = 'PortalAdminMobileWorkForceChecklist';

    public static readonly portalMobileEmployee = 'PortalMobileEmployee';
    public static readonly portalMobileEmployeeDocuments = 'PortalMobileEmployeeDocuments';
    public static readonly portalMobileEmployeeTaxOrganizer = 'PortalMobileEmployeeTaxOrganizer';
    public static readonly portalMobileEmployeeTravelCalendar = 'PortalMobileEmployeeTravelCalendar';
    public static readonly portalMobileEmployeeInvoicing = 'PortalMobileEmployeeInvoicing';
    public static readonly portalMobileEmployeeAgreements = 'PortalMobileEmployeeAgreements';

    /******************************************/
    /* VENDORS SPECIFIC AUTHORIZATION      */
    /******************************************/
    public static readonly vendors = 'Vendors';
    public static readonly vendorsAdmin = 'VendorsAdmin';

    /******************************************/
    /* SHARED POLICIES                        */
    /******************************************/
    /******************************************/
    /* AUTH SPECIFIC POLICIES                 */
    /******************************************/
    public static readonly authUser = 'AuthUser';
    public static readonly authUserAdmin = 'AuthUserAdmin';
    public static readonly authUserRole = 'AuthUserRole';
    public static readonly authUserRoleAdmin = 'AuthUserRoleAdmin';
    public static readonly authUserImpersonation = 'AuthUserImpersonation';
    public static readonly authUserSettingsMgmt = 'AuthUserSettingsMgmt';

    /******************************************/
    /* CONTACTS POLICIES                        */
    /******************************************/
    public static readonly contacts = 'Contacts';
    public static readonly contactsAdmin = 'ContactsAdmin';

    /******************************************/
    /* DOCUMENTS SPECIFIC AUTHORIZATION        */
    /******************************************/
    public static readonly documents = "Documents";
    public static readonly documentsTemplatesAdmin = "DocumentsTemplatesAdmin";
    public static readonly documentsAdmin = "DocumentsAdmin";

    /******************************************/
    /* TAXES SPECIFIC POLICIES                 */
    /******************************************/
    public static readonly taxes = 'Taxes';
    public static readonly taxesAdmin = 'TaxesAdmin';

    /******************************************/
    /* QUESTIONNAIRES SPECIFIC POLICIES       */
    /******************************************/
    public static readonly questionnaires = 'Questionnaires';
    public static readonly questionnairesAdmin = 'QuestionnairesAdmin';

    /******************************************/
    /* AGREEMENTS SPECIFIC AUTHORIZATION      */
    /******************************************/
    public static readonly agreements = 'Agreements';
    public static readonly agreementsAdmin = 'AgreementsAdmin';

    /******************************************/
    /* TAXABILITY SPECIFIC POLICIES           */
    /******************************************/
    public static readonly taxability = 'Taxability';
    public static readonly taxabilityAdmin = 'TaxabilityAdmin';

    /******************************************/
    /* ACCOUNTING SPECIFIC AUTHORIZATION      */
    /******************************************/
    public static readonly accounting = 'Accounting';
    public static readonly accountingStaff = 'AccountingStaff';
    public static readonly accountingAdmin = 'AccountingAdmin';

    /******************************************/
    /* STAFF SPECIFIC AUTHORIZATION      */
    /******************************************/
    public static readonly workforce = 'Workforce';
    public static readonly workforceAdmin = 'WorkforceAdmin';

    /******************************************/
    /* DEVELOPER ONLY POLICIES TO HIDE THINGS */
    /******************************************/
    public static readonly unknownAuthorization = 'unknownAuthorization';
}
