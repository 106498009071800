import { Pipe, PipeTransform } from '@angular/core';
import { StringHelper } from 'app/nexus-core';

@Pipe({
    name: 'propName'
})
export class PropNamePipe implements PipeTransform {
    transform(value: string) {
        const string = StringHelper.convertFromCamelCase(value);
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
}
