import { Component, OnInit } from '@angular/core';
import { ApplicationConfigurationService } from 'app/nexus-core';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { environment } from 'environments/environment';
import { ApplicationsEnum } from 'app/nexus-shared';

@Component({
    selector: 'gtn-nav-logo',
    templateUrl: './nav-logo.component.html',
    styleUrls: ['./nav-logo.component.scss']
})
export class NavLogoComponent extends BaseComponent implements OnInit {
    publicBlobStorageUrl: string;
    applications = ApplicationsEnum;

    constructor(
        public applicationConfigurationService: ApplicationConfigurationService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.publicBlobStorageUrl = environment().publicBlobStorageUrl;
    }

    onLogoClicked(): void {
        const route = this.applicationConfigurationService.getApplicationConfigByRoute();
        this.applicationConfigurationService.redirectToRoute(route);
    }

    protected readonly ApplicationsEnum = ApplicationsEnum;
}
