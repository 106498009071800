import { Component, OnInit } from '@angular/core';
import { BaseFormComponent } from 'app/nexus-shared/components/base-component/base-form.component';
import { FolderModel } from 'app/nexus-shared/domain/documents/models/folder.model';
import { FolderTagService } from 'app/nexus-core/services/domain/documents/folder-tag.service';
import { FolderTagModel } from 'app/nexus-shared/domain/documents/models/folder-tag.model';
import { BaseFormModel } from 'app/nexus-shared/models/base-form.model';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { FolderIndividualAccessModel } from 'app/nexus-shared/domain/documents/models/folder-individual-access.model';
import { UntypedFormArray } from '@angular/forms';

@Component({
    selector: 'gtn-folder-form',
    templateUrl: './folder-form.component.html',
    styleUrls: ['./folder-form.component.scss']
})
export class FolderFormComponent extends BaseFormComponent<FolderModel> implements OnInit {
    folderTagOptions: FolderTagModel[];
    folderIndividualAccessFormArray: UntypedFormArray;

    constructor(private folderTagService: FolderTagService) {
        super();
    }

    ngOnInit() {
        const formConfiguration = new BaseFormModel();
        formConfiguration.formConfiguration[ObjectHelper.nameOf<FolderModel>('folderSettings')] = new BaseFormModel();
        formConfiguration.formArrayConfiguration[ObjectHelper.nameOf<FolderModel>('folderIndividualAccessModels')] = {
            template: new FolderIndividualAccessModel(),
            addBlank: false,
        };
        super.ngOnInit(formConfiguration);
    }

    initUIControls() {
        this.folderTagService.search().subscribe(res => {
            this.folderTagOptions = res;
        });
    }

    initFormCustomizations() {
        this.folderIndividualAccessFormArray = this.formGroupRef.get(ObjectHelper.nameOf<FolderModel>('folderIndividualAccessModels')) as UntypedFormArray;
        if (this.value?.folderSettings.isReadOnly) {
            this.formGroupRef.get(ObjectHelper.nameOf<FolderModel>('name')).disable();
        }
    }

    onAddFolderIndividualAccessClicked() {
        const folderIndividualAccess = new FolderIndividualAccessModel();
        folderIndividualAccess.folderKey = this.value.folderKey;
        folderIndividualAccess.hasAccess = true;
        this.initFormArrayFormGroup(folderIndividualAccess, this.folderIndividualAccessFormArray);
    }

    onDeleteAccessClicked(index: number) {
        this.folderIndividualAccessFormArray.removeAt(index);
        this.formGroupRef.markAsDirty();
    }

}
