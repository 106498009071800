export class TableColumnModel {
    key: string;
    tableRowKey: string;
    order: number;
    value: string;

    constructor(model: TableColumnModel = null) {
        this.key = model?.key ?? null;
        this.tableRowKey = model?.tableRowKey ?? null;
        this.order = model?.order ?? null;
        this.value = model?.value ?? null;
    }
}
