<form *ngIf="formGroupRef"
    [formGroup]="formGroupRef">
    <div class="row"
        *ngIf="value && filteredAgreementTypes">
        <gtn-dropdown class="col-12"
            formControlName="agreementType"
            idKey="agreementTypeKey"
            label="Consent form type"
            (change)="onAgreementSubtypeChanged($event)"
            [displayFunc]="agreementsHelper.getSubtypeDisplayName.bind(this)"
            [options]="filteredAgreementTypes"
            [readonly]="value.status !== agreementStatusEnum.Draft"></gtn-dropdown>
    </div>
    <div class="row">
        <div class="col-md-12">
            <gtn-datepicker formControlName="expirationDateTime"
                label="Expiration date"
                [readonly]="value.status !== agreementStatusEnum.Draft">
            </gtn-datepicker>
        </div>
    </div>
    <div class="row"
        *ngIf="signerSpouse && !value.agreementKey && spouseSendOptions?.length">
        <div class="col-md-12">
            <gtn-radio-group label="Send to"
                (change)="onSendStatusChanged($event)"
                [options]="spouseSendOptions">
            </gtn-radio-group>
        </div>
    </div>
    <div class="row">
        <gtn-input class="col-md-12"
            label="Signer name"
            type="text"
            (change)="onSignerNameChanged($event)"
            [readonly]="value.status !== agreementStatusEnum.Draft"
            [value]="signerName"></gtn-input>
    </div>
    <div class="row">
        <gtn-input class="col-md-12"
            label="Signer email"
            type="text"
            (change)="onSignerEmailChanged($event)"
            [readonly]="true"
            [value]="signerEmail"></gtn-input>
    </div>
    <div *ngIf="signerSpouse && spouseSendStatus === agreementSpouseSendStatus.IndividualAndSpouse"
        class="row">
        <gtn-input class="col-md-12"
            label="Spouse name"
            type="text"
            (change)="onSpouseNameChanged($event)"
            [readonly]="value.status !== agreementStatusEnum.Draft"
            [value]="signerSpouse.fullName"></gtn-input>
    </div>
    <div *ngIf="signerSpouse && spouseSendStatus === agreementSpouseSendStatus.IndividualAndSpouse"
        class="row">
        <gtn-input class="col-md-12"
            label="Spouse email"
            type="text"
            [readonly]="true"
            [value]="signerSpouse.emailAddress"></gtn-input>
    </div>
    <div class="row"
        *ngIf="showCompanyOptions && companies">
        <div class="col-md-12">
            <gtn-dropdown *ngIf="companies?.length"
                displayKey="name"
                idKey="companyKey"
                label="Company"
                (change)="onCompanyChange($event)"
                [idOnly]="false"
                [options]="companies"
                [readonly]="value.status !== agreementStatusEnum.Draft"
                [value]="selectedCompany">
            </gtn-dropdown>
        </div>
        <div *ngIf="companies?.length === 0">
            <div class="col-md-12">
                No offices found.
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="section-header">Applicable years</div>
        </div>
        <div class="col-md-6">
            <gtn-dropdown label="Start year"
                (change)="onApplicableYearChanged($event['value'],'start')"
                [disabled]="value.status !== agreementStatusEnum.Draft"
                [options]="applicableYearsOptions"
                [value]="selectedApplicableYearStart"></gtn-dropdown>
        </div>
        <div class="col-md-6">
            <gtn-dropdown label="End year"
                (change)="onApplicableYearChanged($event['value'],'end')"
                [disabled]="value.status !== agreementStatusEnum.Draft"
                [options]="applicableYearsOptions"
                [value]="selectedApplicableYearEnd">
            </gtn-dropdown>
        </div>
    </div>
</form>
