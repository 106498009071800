<gtn-template-view [cardMode]="cardMode">
    <gtn-template-view-content>
        <ng-container *ngIf="value?.length">
            <div class="row">
                <div class="col-12">
                    Matching companies
                </div>
            </div>
        </ng-container>
        <ng-container *ngFor="let c of value">
            <div class="row active" (click)="onCompanyClicked(c)">
                <div class="col-12">
                    <label class="list-label">Name</label>
                    <div class="data-field">
                        {{c.legalName ?? c.name}}
                    </div>
                </div>
            </div>
        </ng-container>
    </gtn-template-view-content>
</gtn-template-view>
