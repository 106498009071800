import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { DocumentProcessModel } from 'app/nexus-shared/domain/documents/models/document-view-models/document-process.model';
import { DocumentProcessService } from 'app/nexus-core/services/domain/documents/document-process.service';
import { DocumentProcessStatusesEnum } from 'app/nexus-shared/domain/documents/enums/document-process-statuses.enum';
import { DocumentProcessTypesEnum } from 'app/nexus-shared/domain/documents/enums/document-process-types.enum';
import { DocumentHelperService } from 'app/nexus-core/services/domain/documents/document-helper.service';
import { DocumentModel } from 'app/nexus-shared/domain/documents/models/document.model';

@Component({
    selector: 'gtn-document-progress-tray',
    templateUrl: './document-process-tray.component.html',
    styleUrls: ['./document-process-tray.component.scss']
})
export class DocumentProcessTrayComponent extends BaseComponent implements OnInit {

    @Input() documentsInProcess: DocumentProcessModel[] = [];
    @Output() documentsInProcessChange: EventEmitter<DocumentProcessModel[]> = new EventEmitter<DocumentProcessModel[]>();

    documentProcessStatusesEnum = DocumentProcessStatusesEnum;
    documentProcessTypesEnum = DocumentProcessTypesEnum;
    showClear: boolean = false;
    scanIndex: number;

    constructor(
        private documentProcessService: DocumentProcessService,
        private documentHelperService: DocumentHelperService
    ) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.add(this.documentProcessService.documentProcessEvent$.subscribe(documentProcess => {
            const existingProcessIndex = this.documentsInProcess.findIndex(x => x.document.documentKey === documentProcess.document.documentKey);
            if (existingProcessIndex > -1) {
                this.documentsInProcess[existingProcessIndex] = documentProcess;
            } else {
                this.documentsInProcess.push(documentProcess);
            }
            this.documentsInProcessChange.emit(this.documentsInProcess);
        }));
    }

    onClearCompletedProcess(index: number) {
        this.documentsInProcess.splice(index, 1);
    }

    navigateToFolder(document: DocumentModel): void {
        this.documentHelperService.routeToFolderDocument(document);
    }
}
