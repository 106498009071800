import { Component } from '@angular/core';
import { SectionModel } from 'app/nexus-shared/domain/questionnaires/models/section.model';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { SectionRowModel } from 'app/nexus-shared/domain/questionnaires/models/section-row.model';
import { Guid } from 'app/nexus-shared/models/guid.type';
import { SectionRowTypesEnum } from 'app/nexus-shared/domain/questionnaires/enums/section-row-types.enum';
import { QuestionnaireFormHelper } from 'app/nexus-shared/domain/questionnaires/components/forms/questionnaire-form.helper';
import { AbstractControl, UntypedFormArray } from '@angular/forms';
import { BaseQuestionnaireFormComponent } from 'app/nexus-shared/domain/questionnaires/components/forms/base-questionnaire-form.component';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { QuestionnaireHelper } from 'app/nexus-core/helpers/questionnaire.helper';

@Component({
    selector: 'gtn-section-form',
    templateUrl: './section-form.component.html',
    styleUrls: ['./section-form.component.scss', '../questionnaire-forms.component.scss']
})
export class SectionFormComponent extends BaseQuestionnaireFormComponent<SectionModel> {
    sectionRowFormArray: UntypedFormArray;
    selectedRowEditIndex: number = null;

    initFormCustomizations() {
        this.sectionRowFormArray = this.formGroupRef.get(ObjectHelper.nameOf<SectionModel>('rows')) as UntypedFormArray;
    }

    onAddRowClicked(): void {
        const newRow = new SectionRowModel();
        newRow.key = Guid.newGuid();
        newRow.order = this.sectionRowFormArray.value?.length ? this.sectionRowFormArray?.length + 1 : 1;
        newRow.type = SectionRowTypesEnum.Columns;
        this.initFormArrayFormGroup(newRow, this.sectionRowFormArray, QuestionnaireFormHelper.getSectionRowFormConfiguration());
        this.selectedRowEditIndex = this.sectionRowFormArray.length - 1;
    }

    onDeleteRowClicked(i: number): void {
        this.sectionRowFormArray.removeAt(i);
        this.formGroupRef.markAsDirty();
        if (i === this.selectedRowEditIndex) {
            this.selectedRowEditIndex = null;
        }
    }

    onRowDrop($event: CdkDragDrop<any>): void {
        this.moveItemInFormArray(this.sectionRowFormArray, $event.currentIndex, $event.previousIndex, ObjectHelper.nameOf<SectionRowModel>('order'));
    }

    onCloneRowClicked(formGroup: AbstractControl<any>): void {
        const val = QuestionnaireHelper.cloneRow(JSON.parse(JSON.stringify(this.getFormGroupFromAbstractControl(formGroup).value as SectionRowModel)));
        this.initFormArrayFormGroup(val, this.sectionRowFormArray, QuestionnaireFormHelper.getSectionRowFormConfiguration(), val.order);
        this.selectedRowEditIndex = val.order;
        this.formGroupRef.markAsDirty();
    }
}
