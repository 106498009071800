import { Injectable } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { BaseFormGroupHelper } from 'app/nexus-core';
import { RatesFilterPanelFormControlNames } from 'app/nexus-shared/domain/taxes/components/tax-detail/filter-panel/rates-filter-panel-form-control-names';

@Injectable()
export class FilterPanelFormGroupHelper extends BaseFormGroupHelper {
    constructor(private formBuilder: UntypedFormBuilder) {
        super();
        this.buildFormGroup();
    }

    buildFormGroup() {
        this.formGroup = this.formBuilder.group({
            [RatesFilterPanelFormControlNames.effectiveDate]: [null],
            [RatesFilterPanelFormControlNames.specialization]: [null],
            [RatesFilterPanelFormControlNames.qualifier]: [null]
        });
    }
}
