import { Component, forwardRef, Input } from '@angular/core';
import { IndividualModel } from 'app/nexus-shared/domain/contacts/models/individual.model';
import { IndividualService } from 'app/nexus-core/services/domain/contacts/individual.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SearchResultIconEnum } from 'app/nexus-shared/components/controls/shared/enums';
import { SearchResultModel } from 'app/nexus-shared/components/controls/shared/models';
import { SearchHelper } from 'app/nexus-core/helpers';
import { IndividualSearchModel } from 'app/nexus-shared/domain/contacts/models/individual-search.model';
import { BaseSearchControlComponent } from 'app/nexus-shared/components/base-component/base-search-control.component';

@Component({
    selector: 'gtn-individual-search',
    templateUrl: './individual-search.component.html',
    styleUrls: ['./individual-search.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => IndividualSearchComponent),
            multi: true
        }
    ]
})
export class IndividualSearchComponent extends BaseSearchControlComponent<IndividualModel> implements ControlValueAccessor {

    @Input() companyKey: string;
    @Input() individualSearchModel: IndividualSearchModel = <IndividualSearchModel>{ searchTerm: null, companyKey: null };

    constructor(
        private individualService: IndividualService,
    ) {
        super();
    }

    protected onSearchTextChanged(searchText: string): void {
        this.individualSearchModel.searchTerm = searchText;
        this.individualSearchModel.companyKey = this.companyKey;
        this.individualService.search(this.individualSearchModel).subscribe(
            response => {
                const results = response.map((individual: IndividualModel) => {
                    return {
                        id: individual.individualKey,
                        name: individual.fullName,
                        subName: individual.emailAddress,
                        type: null,
                        iconClass: SearchResultIconEnum.unknown,
                        resultObject: individual,
                        rank: 0
                    } as SearchResultModel<void, IndividualModel>;
                });
                const orderedResults = SearchHelper.orderSearchResults<void, IndividualModel>(results, searchText, []);
                this.searchModel.onResultsReceived.next(orderedResults);
            },
            error => this.searchModel.onErrorReceived.next(error));
    }

    displayWith(searchResultItem: SearchResultModel<void, IndividualModel> | IndividualModel) {
        if (searchResultItem) {
            if ('resultObject' in searchResultItem) {
                return searchResultItem?.resultObject
                    ? `${searchResultItem.resultObject.fullName}`
                    : null;
            }

            const individual = new IndividualModel(searchResultItem);
            return `${individual.fullName}`;
        }
        return null;
    }
}
