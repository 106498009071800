import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateHelper, EnumHelper } from 'app/nexus-core';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { ApplicationsEnum, SelectListInterface } from 'app/nexus-shared/index';
import { BaseFormComponent } from 'app/nexus-shared/components/base-component/base-form.component';
import { CompanyModel } from 'app/nexus-shared/domain/contacts/models';
import { CompanyRelationshipTypesEnum } from 'app/nexus-shared/domain/contacts/enums/company-relationship-types.enum';
import { CompanyRelationshipModel } from 'app/nexus-shared/domain/contacts/models/company-relationship.model';

@Component({
    selector: 'gtn-company-relationship-form',
    templateUrl: './company-relationship-form.component.html',
    styleUrls: ['./company-relationship-form.component.scss']
})
export class CompanyRelationshipFormComponent extends BaseFormComponent<CompanyRelationshipModel> implements OnInit {
    @Input() isParentChildRelationship: boolean = true;
    @Input() canCreateNew: boolean = false;

    @Output() isParentChildRelationshipChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    applicationSource = ApplicationsEnum.Contacts;
    relationshipTypeOptions: SelectListInterface[] = [];

    @Output() createCompany: EventEmitter<void> = new EventEmitter();

    ngOnInit(): void {
        super.ngOnInit();
    }

    initUIControls() {
        this.relationshipTypeOptions = EnumHelper.convertToSelectList(CompanyRelationshipTypesEnum, false);
    }

    protected initFormCustomizations(): void {
        if (!this.value?.companyRelationshipKey) {
            this.formGroupRef.controls[ObjectHelper.nameOf<CompanyRelationshipModel>('effectiveStartDate')].setValue(DateHelper.convertToUtcDate(new Date()));
        }
    }

    onIsParentChildRelationshipChanged(event: boolean): void {
        this.isParentChildRelationship = event;
        this.isParentChildRelationshipChange.emit(this.isParentChildRelationship);
    }

    onCompanyAdded(company: CompanyModel): void {
        this.formGroupRef.controls[ObjectHelper.nameOf<CompanyRelationshipModel>('childCompany')].setValue(company);
        this.formGroupRef.get(ObjectHelper.nameOf<CompanyRelationshipModel>('effectiveStartDate'))?.setValue(DateHelper.convertToUtcDate(new Date()));
    }

    onCreateNewCompanyClicked(): void {
        this.createCompany.emit();
    }
}
