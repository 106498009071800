import { PdfAnnotationBoarderStyleEnum } from '../enums';

export class PdfAnnotationBorderStyleModel {
    width?: number;
    style: PdfAnnotationBoarderStyleEnum;
    dashArray?: number[];
    horizontalCornerRadius?: number;
    verticalCornerRadius?: number;

    constructor() {
        this.dashArray = [3];  // TODO - don't know what this does
    }
}
