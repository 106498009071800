import { Component, Input } from '@angular/core';
import { BaseAssignQuestionnaireFormComponent } from 'app/nexus-shared/domain/questionnaires/components/forms/base-assign-questionnaire-form.component';
import { IndividualCchOrganizerModel } from 'app/nexus-shared/domain/questionnaires/models/individual-cch-organizer.model';
import { IndividualSearchModel } from 'app/nexus-shared/domain/contacts/models/individual-search.model';
import { IndividualTypesEnum } from 'app/nexus-shared/domain/contacts/enums/individual-types.enum';

@Component({
    selector: 'gtn-individual-cch-organizer-form',
    templateUrl: './individual-cch-organizer-form.component.html',
    styleUrls: ['./individual-cch-organizer-form.component.scss']
})
export class IndividualCchOrganizerFormComponent extends BaseAssignQuestionnaireFormComponent<IndividualCchOrganizerModel> {
    @Input() disableIndividualSearch: boolean = false;
    gatekeeperIndividualSearchModel: IndividualSearchModel = {
        individualTypes: [IndividualTypesEnum.GtnEmployee]
    };
}
