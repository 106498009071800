import { DocumentModel } from 'app/nexus-shared/domain/documents/models/document.model';

export class DocumentCopyModel {
    documentToCopyKey: string;
    document: DocumentModel;
    historicalKey: string;

    constructor(model: DocumentCopyModel = null) {
        this.documentToCopyKey = model?.documentToCopyKey ?? null;
        this.document = model?.document ?? null;
        this.historicalKey = model?.historicalKey ?? null;
    }
}
