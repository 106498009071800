import { Component, OnInit } from '@angular/core';
import { EnumHelper } from 'app/nexus-core';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { SelectListInterface } from 'app/nexus-shared/index';
import { BaseFormComponent } from 'app/nexus-shared/components/base-component/base-form.component';
import { CompanyModel } from 'app/nexus-shared/domain/contacts/models/company.model';
import { ContactsFormHelper } from 'app/nexus-core/helpers/contacts-form.helper';
import { MyGTNPortalService } from 'app/nexus-core/services/domain/contacts/my-gtn-portal.service';
import { MyGTNPortalSearchResultModel } from 'app/nexus-shared/domain/contacts/models/my-gtn-portal-search-restult.model';
import { CompanyTypesEnum } from 'app/nexus-shared/domain/contacts/enums/company-types.enum';
import { CompanyTypeModel } from 'app/nexus-shared/domain/contacts/models/company-type.model';
import { AuthenticatedUserService } from 'app/nexus-core/services/authenticated-user.service';
import { AuthorizationHelper } from 'app/nexus-core/helpers/authorization.helper';
import { AuthorizationConstants } from 'app/nexus-shared/constants/authorization.constants';
import { ServiceAreaTypeModel } from '../../../models/service-area-type.model';
import { VendorSubTypesEnum } from '../../../enums/vendor-sub-types.enum';
import { RegionTypesEnum } from 'app/nexus-shared/domain/contacts/enums/region-types.enum';
import { FormArray, UntypedFormArray } from '@angular/forms';
import { AddressModel } from 'app/nexus-shared/domain/contacts/models/address.model';

@Component({
    selector: 'gtn-company-form',
    templateUrl: './company-form.component.html',
    styleUrls: ['./company-form.component.scss']
})
export class CompanyFormComponent extends BaseFormComponent<CompanyModel> implements OnInit {
    // costCentersFormArray: FormArray;
    phoneNumbersFormArray: FormArray;
    // industryOptions: SelectListInterface[] = [];

    addressFormArray: UntypedFormArray;
    initialKey: boolean = false;
    myGTNPortalCompanyName: string;
    companyTypeOptions: CompanyTypeModel[];
    regionTypeOptions: SelectListInterface[] = [];
    vendorSubTypeOptions: SelectListInterface[] = [];
    serviceAreaTypeOptions: ServiceAreaTypeModel[];
    isGtnAdmin: boolean = false;
    isClientType: boolean = false;
    showVendorSubType: boolean = false;
    // temp dropdown until control is determined
    secRegisteredOptions = [
        {
            id: 0,
            displayText: '',
            value: null
        },
        {
            id: 1,
            displayText: 'Sec registered',
            value: true
        },
        {
            id: 2,
            displayText: 'Not sec registered',
            value: false
        }
    ];

    constructor(
        private myGTNPortalService: MyGTNPortalService,
        private authenticatedUserService: AuthenticatedUserService
    ) {
        super();
    }

    ngOnInit(): void {
        const formConfiguration = ContactsFormHelper.getCompanyFormConfiguration();
        super.ngOnInit(formConfiguration);
        if (this.value.myGTNPortalCompanyKey) {
            this.initialKey = true;
            this.getMyGTNPortalCompany(this.value.myGTNPortalCompanyKey);
        }
        this.isGtnAdmin = AuthorizationHelper.hasAuthorization(this.authenticatedUserService.user, AuthorizationConstants.gtnAdministrator);
        this.onCompanyTypeChanged(this.value.companyTypes);
    }

    initFormValueChangesCustomizations(value: CompanyModel) {
        if (value.addresses?.length) {
            value.addresses = value.addresses.filter(address => {
                return !Object.values(address).every(x => x === null || x === '');
            });
        }
        return value;
    }

    onCreateAddressClicked(): void {
        this.initFormArrayFormGroup(new AddressModel(), this.addressFormArray);
    }

    initUIControls() {
        // this.industryOptions = EnumHelper.convertToSelectList(IndustryTypesEnum, true, true);
        this.vendorSubTypeOptions = EnumHelper.convertToSelectList(VendorSubTypesEnum, false, true);
        this.regionTypeOptions = EnumHelper.convertToSelectList(RegionTypesEnum, false, true);

        this.companyTypeOptions = this.createCompanyTypeOptions();
        this.isClientType = this.value?.companyTypes?.some(x => x.type === CompanyTypesEnum.Client);
    }

    initFormCustomizations() {
        this.addressFormArray = this.formGroupRef.get(ObjectHelper.nameOf<CompanyModel>('addresses')) as UntypedFormArray;
        this.phoneNumbersFormArray = this.formGroupRef.get(ObjectHelper.nameOf<CompanyModel>('phoneNumbers')) as FormArray;
    }

    onRemoveMyGTNPortalLinkClicked(): void {
        this.formGroupRef.get(ObjectHelper.nameOf<CompanyModel>('myGTNPortalCompanyKey')).reset(null);
        this.formGroupRef.markAsDirty();
        this.formGroupRef.updateValueAndValidity();
        this.initialKey = false;
        this.myGTNPortalCompanyName = null;
    }

    onMyGTNPortalCompanySelected(company: MyGTNPortalSearchResultModel): void {
        this.myGTNPortalCompanyName = company?.name;
    }

    companyTypeDisplayFn(value: CompanyTypeModel): string {
        return EnumHelper.getDisplayName(CompanyTypesEnum, value.type);
    }

    onCompanyTypeChanged(companyTypes: CompanyTypeModel[]) {
        this.showVendorSubType = !!companyTypes?.some(x => x.type === CompanyTypesEnum.Vendor);
        this.isClientType = !!companyTypes?.some(x => x.type === CompanyTypesEnum.Client);
        if (!this.isClientType) {
            this.formGroupRef.get(ObjectHelper.nameOf<CompanyModel>('region')).reset();
        }
    }

    private getMyGTNPortalCompany(key: string): void {
        this.myGTNPortalService.getCompany(key).subscribe((res) => {
            this.myGTNPortalCompanyName = res;
        });
    }

    private createCompanyTypeOptions(): CompanyTypeModel[] {
        const companyOptions: CompanyTypeModel[] = this.value?.companyTypes?.length ? [].concat(this.value.companyTypes) : [];
        for (const enumItem in CompanyTypesEnum) {
            if (CompanyTypesEnum[enumItem] && isNaN(Number(CompanyTypesEnum[enumItem])) && parseInt(enumItem, 10) !== 0 && !companyOptions.find(x => x.type === parseInt(enumItem, 10))) {
                const option: CompanyTypeModel = {
                    companyTypeKey: null,
                    type: parseInt(enumItem, 10),
                    createdByUser: null,
                    lastModifiedByUser: null,
                };
                companyOptions.push(option);
            }
        }
        return companyOptions;
    }
}
