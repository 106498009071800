<gtn-modal *ngIf="company" class="modal-small-wide" [headerText]="company.name"
    [showFooter]="false" (closeClick)="onCancelClicked()">
    <gtn-modal-content>
        <gtn-template-view>
            <gtn-template-view-content>
                <ng-container>
                    <div class="row">
                        <div class="col-md-6">
                            <label class="list-label">Company name</label>
                            <div class="data-field">
                                {{company.name}}
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label class="list-label">Legal name</label>
                            <div class="data-field">
                                {{company.legalName || noValueText}}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label class="list-label">Company type(s)</label>
                            <div class="d-flex flex-wrap flex-row">
                                <div class="data-field" *ngFor="let companyType of company.companyTypes; last as isLast">
                                    {{companyType.type | enum: companyTypesEnum}}<span *ngIf="!isLast">,&nbsp;</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label class="list-label">Region</label>
                            <div class="data-field">
                                {{company.region | enum: regionTypesEnum}}
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container>
                    <div class="row">
                        <div class="col-md-6">
                            <label class="list-label">Source application</label>
                            <div class="data-field">{{company.applicationSourceType | enum : applicationsEnum}}</div>
                        </div>
                        <div class="col-md-6">
                            <gtn-application-links [value]="company.companyToApplications"
                                [currentApplication]="application"
                                [isDisplayingLogo]="true"
                                [companyKey]="company.companyKey"
                                [myGTNPortalUserKey]="company.myGTNPortalCompanyKey"></gtn-application-links>
                        </div>
                    </div>
                </ng-container>
            </gtn-template-view-content>
        </gtn-template-view>
    </gtn-modal-content>
</gtn-modal>
