import { Component, Input, OnInit } from '@angular/core';
import { BaseFormComponent } from 'app/nexus-shared/components/base-component/base-form.component';
import { QuestionnaireModel } from 'app/nexus-shared/domain/questionnaires/models/questionnaire.model';
import { QuestionnaireTemplateModel } from 'app/nexus-shared/domain/questionnaires/models/questionnaire-template.model';
import { QuestionnaireTypesEnum } from 'app/nexus-shared/domain/questionnaires/enums/questionnaire-types.enum';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';

@Component({
    selector: 'gtn-assign-questionnaire-form',
    templateUrl: './assign-questionnaire-form.component.html',
    styleUrls: ['./assign-questionnaire-form.component.scss']
})
export class AssignQuestionnaireFormComponent extends BaseFormComponent<QuestionnaireModel> implements OnInit {
    @Input() questionnaireTemplates: QuestionnaireTemplateModel[];
    @Input() questionnaireType: QuestionnaireTypesEnum;
    @Input() disableIndividualSearch: boolean = false;
    questionnaireTemplateOptions: QuestionnaireTemplateModel[];

    ngOnInit(): void {
        super.ngOnInit();
    }

    protected initUIControls(): void {
        this.questionnaireTemplateOptions = this.questionnaireTemplates?.filter((x: QuestionnaireTemplateModel): boolean => x.questionnaireType === this.questionnaireType)
            .map((template: QuestionnaireTemplateModel) => this.appendYearToTitle(template));
    }

    onQuestionnaireTemplateChanged(questionnaireTemplateKey: string): void {
        if (questionnaireTemplateKey) {
            this.setValuesFromTemplate(this.questionnaireTemplates.find(x => x.questionnaireTemplateKey === questionnaireTemplateKey));
        }
    }

    private setValuesFromTemplate(template: QuestionnaireTemplateModel): void {
        this.formGroupRef.get(ObjectHelper.nameOf<QuestionnaireModel>('title')).setValue(template.title);
        this.formGroupRef.get(ObjectHelper.nameOf<QuestionnaireModel>('year')).setValue(template.year);
        this.formGroupRef.get(ObjectHelper.nameOf<QuestionnaireModel>('location')).setValue(template.location);
    }

    private appendYearToTitle(template: QuestionnaireTemplateModel): QuestionnaireTemplateModel {
        if (template.year) {
            const yearString: string = template.year.toString();
            if (!template.title.endsWith(yearString)) {
                template.title = `${template.title} ${yearString}`;
            }
        }
        return template;
    }
}
