import { Component, OnInit } from '@angular/core';
import { BaseQuestionnaireFormComponent } from 'app/nexus-shared/domain/questionnaires/components/forms/base-questionnaire-form.component';
import { SectionColumnModel } from 'app/nexus-shared/domain/questionnaires/models/section-column.model';
import { UntypedFormArray } from '@angular/forms';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { TableColumnHeaderModel } from 'app/nexus-shared/domain/questionnaires/models/table-column-header.model';
import { Guid } from 'app/nexus-shared/models/guid.type';
import { SelectListInterface } from 'app/nexus-shared/interfaces';
import { UserInputTypesEnum } from 'app/nexus-shared/domain/questionnaires/enums/user-input-types.enum';
import { EnumHelper } from 'app/nexus-core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

@Component({
    selector: 'gtn-column-editor-table-form',
    templateUrl: './column-editor-table-form.component.html',
    styleUrls: ['./column-editor-table-form.component.scss', '../questionnaire-forms.component.scss']
})
export class ColumnEditorTableFormComponent extends BaseQuestionnaireFormComponent<SectionColumnModel> implements OnInit {
    tableColumnHeadersFormArray: UntypedFormArray;
    inputTypeOptions: SelectListInterface[];
    alignmentOptions: SelectListInterface[] = [
        { id: 'left', value: 'Left' },
        { id: 'right', value: 'Right' },
        { id: 'center', value: 'Center' },
    ];
    userInputTypesEnum = UserInputTypesEnum;
    inputTypeFormControlName: string = 'tableInputType';

    ngOnInit() {
        super.ngOnInit();
    }

    protected initUIControls() {
        this.inputTypeOptions = EnumHelper.convertToSelectList(UserInputTypesEnum, false, false).filter(x => x.id === UserInputTypesEnum.FreeFormText ||
            x.id === UserInputTypesEnum.Numeric ||
            x.id === UserInputTypesEnum.Date ||
            x.id === UserInputTypesEnum.DropDown);
    }

    protected initFormCustomizations() {
        this.tableColumnHeadersFormArray = this.formGroupRef.get(ObjectHelper.nameOf<SectionColumnModel>('tableColumnHeaders')) as UntypedFormArray;
    }

    addTableColumnClicked(): void {
        const newTableColumn = new TableColumnHeaderModel();
        newTableColumn.key = Guid.newGuid();
        newTableColumn.alignment = 'left';
        newTableColumn.order = this.tableColumnHeadersFormArray?.controls?.length ? this.tableColumnHeadersFormArray.controls.length + 1 : 1;
        this.initFormArrayFormGroup(newTableColumn, this.tableColumnHeadersFormArray);
    }

    onTableColumnDropped($event: CdkDragDrop<any>): void {
        this.moveItemInFormArray(this.tableColumnHeadersFormArray, $event.currentIndex, $event.previousIndex, ObjectHelper.nameOf<TableColumnHeaderModel>('order'));
    }
}
