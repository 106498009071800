export class SessionStorageHelper {
    static check(name: string): boolean {
        return sessionStorage.getItem(name) ? true : false;
    }

    static delete(name: string): void {
        sessionStorage.removeItem(name);
    }

    static get(name: string): any {
        return JSON.parse(sessionStorage.getItem(name));
    }

    static set(name: string, item: any) {
        sessionStorage.setItem(name, JSON.stringify(item));
    }
}
