import { AfterContentInit, Component, EventEmitter, forwardRef, HostBinding, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { ControlAppearanceType, InputType } from 'app/nexus-shared/components/controls/shared';
import { NexusValidatorHelper } from 'app/nexus-core';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';

@Component({
    selector: 'gtn-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => InputComponent),
            multi: true
        }
    ]
})
export class InputComponent extends BaseComponent implements ControlValueAccessor, AfterContentInit, OnInit {
    @Input('value') _value: string | number = null;
    @Input() appearance: MatFormFieldAppearance = ControlAppearanceType.fill;
    @Input() canShowValidation: boolean;
    @Input() classNames: string;
    @Input() focus: boolean = false;
    @Input() label: string = '';
    @Input() min: number;
    @Input() max: number;
    @Input() step: number = 1;
    @Input() readonly: boolean;
    @Input() textAlign: string = 'left';
    @Input() type: string = InputType.text;
    @Input() currencyCode: string = 'USD';
    @Input() showClearButton: boolean = false;
    @Input() icon: string;
    @Input() iconTooltip: string;
    @Input() precision: number = 2;

    @Output() change: EventEmitter<any> = new EventEmitter();
    @Output() currencyValueChanged: EventEmitter<any> = new EventEmitter();
    @Output() blur: EventEmitter<void> = new EventEmitter();

    @HostBinding('class') get className() {
        return this.classNames;
    }

    disabled: boolean;
    formControl: UntypedFormControl;
    isValid: boolean = false;
    isPasswordVisible = false;
    isPasswordInput = false;

    onChange: Function = () => {
    };
    onTouched: Function = () => {
    };
    validateFn: Function = () => {
    };

    constructor(
        protected renderer: Renderer2
    ) {
        super();
    }

    ngOnInit(): void {
        this.isPasswordInput = this.type === InputType.password;
    }

    ngAfterContentInit() {
        if (this.focus) {
            this.setFocus();
        }

        if (!this.value) {
            this.readonly = false;
        }
    }

    get value(): any {
        return this._value;
    }

    set value(val: any) {
        if (this.type === InputType.currency) {
            let outValue: any = null;

            if (Number.isNaN(val)) {
                this._value = val;
                this.onChange(outValue);
                this.currencyValueChanged.emit(outValue);
                this.onTouched();
                return;
            }

            if (typeof val !== 'undefined' && val !== null && val !== '') {
                if (typeof val === 'string') {
                    // Matches currency input with or without commas.
                    const currencyValidationRegex = new RegExp(`^\\$?([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(\\.[0-9]{0,${this.precision}})?$`);

                    if (val.match(currencyValidationRegex)) {
                        // Valid currency has been entered
                        outValue = this.processCurrencyStringValue(val);

                        this._value = outValue;
                    } else if (val) {
                        const currencyNumber = Number(val.replace(/[^0-9.-]+/g, ''));

                        // Ensure the value does not exceed the specified precision
                        const factor = Math.pow(10, this.precision);
                        outValue = Math.round(currencyNumber * factor) / factor;

                        this._value = outValue.toString();
                    } else {
                        outValue = +val;
                    }

                    this._value = outValue;
                } else {
                    outValue = val;
                    this._value = val;
                }
            } else if (typeof val === 'number') {
                this._value = val.toString();
                outValue = val;
            } else {
                this._value = val;
            }

            this.onChange(outValue);
            this.currencyValueChanged.emit(outValue);
            this.onTouched();
        } else {
            this._value = val;

            this.onChange(val);
            this.change.emit(val);
            this.onTouched();
        }
    }

    onBlur(): void {
        this.blur.emit();
        this.onTouched();
    }

    onClearClicked(): void {
        this.value = null;
    }

    registerOnChange(fn): void {
        this.onChange = fn;
    }

    registerOnTouched(fn): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    validate(formControl: UntypedFormControl) {
        this.formControl = formControl;
        return this.validateFn(formControl);
    }

    writeValue(value): void {
        const currentValue = this.value;

        if (typeof currentValue !== 'undefined' && currentValue !== null && currentValue !== value) {
            this.value = value;
        } else if (typeof value !== 'undefined' && value !== null && currentValue !== value) {
            this.value = value;
        }
    }

    setFocus(): void {
        this.renderer.selectRootElement('input').focus();
    }

    getErrorMessage(): string[] {
        return NexusValidatorHelper.getErrorMessage(this.formControl, this.label);
    }

    private processCurrencyStringValue(val: string) {
        if (val.indexOf('$') > -1) {
            val = val.substr(1);
        }

        if (val.indexOf(',') > -1) {
            val = val.replace(',', '');
        }

        return +val;
    }
}

