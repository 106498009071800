import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NexusAnimations } from 'app/nexus-shared/animations';
import { TwoFactorService } from 'app/nexus-core/services/two-factor.service';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { ToastService } from 'app/nexus-core/services/toast.service';
import { AuthenticationService } from 'app/nexus-core/services/authentication.service';
import { LocalStorageHelper, SessionStorageHelper, SpinnerService, ValidationErrorHelper } from 'app/nexus-core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { LocalStorageNameConstants, SessionStorageNameConstants } from 'app/nexus-shared/constants';
import { ValidationModel } from 'app/nexus-shared/models';
import { environment } from 'environments/environment';
import { TwoFactorVerificationTypesEnum } from 'app/nexus-shared/enums/two-factor-verification-types.enum';
import { TwoFactorVerificationTypesModel } from 'app/nexus-shared/models/two-factor-verification-types.model';
import { SelectListInterface } from 'app/nexus-shared/interfaces';

@Component({
    selector: 'gtn-two-factor-modal',
    templateUrl: './two-factor-modal.component.html',
    styleUrls: ['./two-factor-modal.component.scss'],
    animations: [
        NexusAnimations.modalFadeIn
    ]
})
export class TwoFactorModalComponent extends BaseComponent implements OnInit {
    @Output() completed = new EventEmitter<boolean>();

    validationModels: ValidationModel[];
    rememberDeviceInDays: number;
    showModal = false;
    showTwoFactorTypeSelector = false;
    disableButtons = false;
    enableRememberMe = false;
    enableRememberMethod = true; // Hard-coded to true
    twoFactorKey: string = null;
    twoFactorVerificationTypesEnum = TwoFactorVerificationTypesEnum;

    twoFactorVerificationTypes: TwoFactorVerificationTypesModel[] = null;
    twoFactorVerificationTypeOptions: SelectListInterface[] = null;
    selectedTwoFactorVerificationType: TwoFactorVerificationTypesModel;

    supportEmailAddress: string = environment().supportEmailAddress;
    isTooManyAttemptsMessage: string = null;
    isVerificationButtonDisabled: boolean = true;
    verificationNumber: number = null;
    constructor(private twoFactorService: TwoFactorService,
                private toastService: ToastService,
                private authenticationService: AuthenticationService) {
        super();
    }

    ngOnInit(): void {
        this.rememberDeviceInDays = environment().auth.rememberDeviceInDays;

        SpinnerService.start();

        this.twoFactorService.listVerificationTypes().subscribe((twoFactorVerificationTypes: TwoFactorVerificationTypesModel[]): void => {
            this.handleTwoFactorVerificationTypes(twoFactorVerificationTypes);
        });
    }

    onTowFactorTypeChanged($event: any): void {
        this.selectedTwoFactorVerificationType = this.twoFactorVerificationTypes.find((t: TwoFactorVerificationTypesModel): boolean => t.valueKey === $event.value);
    }

    onHaveTroubleLoggingInClicked(): void {
        this.selectedTwoFactorVerificationType = this.twoFactorVerificationTypes[0];
        this.showTwoFactorTypeSelector = true;
    }

    onVerify2FAClicked(): void {
        this.disableButtons = true;
        this.twoFactorService.validateCode({
            code: this.verificationNumber,
            twoFactorKey: this.twoFactorKey,
            hasRememberMe: this.enableRememberMe
        }).subscribe(result => {
            if (this.enableRememberMe) {
                LocalStorageHelper.set(LocalStorageNameConstants.deviceId, result);
                SessionStorageHelper.delete(LocalStorageNameConstants.deviceId);
            } else {
                SessionStorageHelper.set(SessionStorageNameConstants.deviceId, result);
                LocalStorageHelper.delete(LocalStorageNameConstants.deviceId);
            }

            if (this.enableRememberMethod) { // This will always be true (9389)
                LocalStorageHelper.set(LocalStorageNameConstants.twoFactorMethodKey, this.selectedTwoFactorVerificationType?.valueKey ?? null);
            } else {
                LocalStorageHelper.delete(LocalStorageNameConstants.twoFactorMethodKey);
            }

            this.authenticationService.updateUserContext().subscribe(_ => {
                this.completed.emit(!!result);
                this.showModal = false;
            });
        }, error => {
            this.resetVerificationInput();
            ValidationErrorHelper.handleServiceError(error, (validationModels: ValidationModel[]): void => {
                this.toastService.showErrorToast(error);
                this.disableButtons = false;
                const failedValidationHasTooManyAttempts: ValidationModel = validationModels.find(validation => validation?.code === '100');
                if (failedValidationHasTooManyAttempts) {
                    this.isTooManyAttemptsMessage = failedValidationHasTooManyAttempts.message;
                    setTimeout((): void => {
                        this.authenticationService.logout();
                    }, 5000); // 5 seconds for users to read the message before being logged out
                }
            });
        });
    }

    onCancel2FAClicked(): void {
        this.completed.emit(false);
    }

    onResend2FACodeClicked(): void {
        this.resetVerificationInput();

        this.generateTwoFactorCode().subscribe(_ => {
            this.toastService.showMessageToast('Two factor verification code was sent successfully.');
        });
    }

    private resetVerificationInput(): void {
        this.verificationNumber = null;
        this.isVerificationButtonDisabled = true;
    }

    private generateTwoFactorCode(): Observable<boolean> {
        this.disableButtons = true;

        return this.twoFactorService.generateCode(this.selectedTwoFactorVerificationType?.valueKey).pipe(tap((data: {twoFactorKey: string}) => {
            this.twoFactorKey = data.twoFactorKey;
            this.disableButtons = false;
            this.showTwoFactorTypeSelector = false;

            return true;
        }, err => {
            this.authenticationService.error$.next('Something went wrong with generating your two factor authentication code.');
            this.completed.emit(false);
            this.disableButtons = false;
            return false;
        }), map(_ => {
            return true;
        }));
    }
    private handleTwoFactorVerificationTypes(twoFactorVerificationTypes: TwoFactorVerificationTypesModel[]): void {
        this.twoFactorVerificationTypes = twoFactorVerificationTypes;
        this.selectedTwoFactorVerificationType = this.twoFactorVerificationTypes?.find((type: TwoFactorVerificationTypesModel) => type.isRememberedMethod) ?? this.twoFactorVerificationTypes[0];
        this.twoFactorVerificationTypeOptions = this.mapVerificationTypeOptions(twoFactorVerificationTypes);

        const rememberedMethodKey = LocalStorageHelper.get(LocalStorageNameConstants.twoFactorMethodKey);
        const isRememberedMethod: TwoFactorVerificationTypesModel = this.twoFactorVerificationTypes.find((x: TwoFactorVerificationTypesModel) => x.isRememberedMethod);

        if (isRememberedMethod) {
            this.handleRememberedMethod();
        } else {
            this.handleNonRememberedMethod(rememberedMethodKey);
        }
    }

    private mapVerificationTypeOptions(twoFactorVerificationTypes: TwoFactorVerificationTypesModel[]): SelectListInterface[] {
        return twoFactorVerificationTypes.map((t: TwoFactorVerificationTypesModel) => ({ id: t.valueKey, value: t.value }));
    }

    private handleRememberedMethod(): void {
        this.generateTwoFactorCode().subscribe(() => SpinnerService.stop());
        this.showTwoFactorTypeSelector = false;
        this.showModal = true;
    }

    private handleNonRememberedMethod(rememberedMethodKey: string): void {
        if (this.isRememberedMethodKeyValid(rememberedMethodKey)) {
            this.selectedTwoFactorVerificationType = this.twoFactorVerificationTypes.find((t: TwoFactorVerificationTypesModel): boolean => t.valueKey === rememberedMethodKey);
            this.generateTwoFactorCode().subscribe(() => SpinnerService.stop());
        } else if (this.twoFactorVerificationTypeOptions.length > 1) {
            this.showTwoFactorTypeSelector = true;
            this.showModal = true;
            SpinnerService.stop();
        } else if (this.twoFactorVerificationTypeOptions.length === 1) {
            this.selectedTwoFactorVerificationType = this.twoFactorVerificationTypes[0];
            this.generateTwoFactorCode().subscribe((): void => {
                this.showTwoFactorTypeSelector = false;
                this.showModal = true;
                SpinnerService.stop();
            });
        } else {
            this.toastService.showMessageToast('Two factor verification is not setup.');
        }
    }

    private isRememberedMethodKeyValid(rememberedMethodKey: string): boolean {
        return typeof rememberedMethodKey !== 'undefined' && this.twoFactorVerificationTypes.some((t: TwoFactorVerificationTypesModel): boolean => t.valueKey === rememberedMethodKey);
    }
}
