import { Component, forwardRef, OnChanges, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseDropdownControlComponent } from '../base-dropdown-control.component';
import { MatSelect } from '@angular/material/select';

@Component({
    selector: 'gtn-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DropdownComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => DropdownComponent),
            multi: true
        }
    ]
})
export class DropdownComponent<T> extends BaseDropdownControlComponent<T, T> implements ControlValueAccessor, OnInit, OnChanges {
    @ViewChild('element') element: MatSelect;

    idToSetWhenOptionsLoaded: number;

    get value(): T {
        // return what the consumer is expecting
        if (this.idOnly && this._value) {
            return this._value[this.idKey];
        }

        return this._value;
    }

    set value(val: T) {
        let value: T = null;
        let emit = false;

        if (typeof val !== 'undefined' && val !== null && (this._value !== val || this._value !== val[this.idKey])) {
            const id = typeof val === 'object' ? val[this.idKey] : val;

            if (!this.options) {
                this._value = val;
                this.idToSetWhenOptionsLoaded = id;
            } else {
                if (!this.idOnly && id !== null) {
                    value = this.options?.find(x => x[this.idKey] === id) as unknown as T;
                    emit = true;
                } else {
                    value = id;
                    emit = true;
                }

                // get the correct option or set it to an empty object only assigning the idKey value if no options exist
                if (this.options?.length) {
                    this._value = this.options?.find(x => x[this.idKey] === id) as unknown as T;
                } else if (value) {
                    if (this.idOnly) {
                        this._value = {} as T;
                        this._value[this.idKey] = value;
                    } else {
                        this._value = value;
                    }
                }

                emit = true;
            }
        } else if (typeof val === 'undefined' || val === null && this._value !== null) {
            this._value = val;
            emit = true;
        }

        if (emit) {
            if(!this.suppressInitialChangeEvent){
                this.onChange(value);
                this.triggerChanged(this.matSelectRef?.trigger);
                this.onTouched();
            }
            this.suppressInitialChangeEvent = false;
        }
    }

     handleResponse(data: T[]) {
        this.options = JSON.parse(JSON.stringify(data));
        if (!this._value && this.idToSetWhenOptionsLoaded) {
            const value = this.options.find(x => +x[this.idKey] === +this.idToSetWhenOptionsLoaded);
            this.value = value;
            this.idToSetWhenOptionsLoaded = null;
        }
    }
}
