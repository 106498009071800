export enum DocumentEventTypesEnum {
    Unknown = 0,
    Create = 10,
    CopyCreate = 20,
    CopyFromTemplate = 30,
    CopyFromVersionHistory = 40,
    CheckIn = 50,
    Upload = 60,
    Restore = 70,
    ///// no versions below this
    CheckOut = 80,
    CheckOutDiscarded = 85,
    Recover = 90,
    Delete = 100,
    Move = 110,
    Note = 120,
    Rename = 130,
    VirusScan = 140,
    SkippedVirusScan = 150,
    Preview = 160,
    Download = 170,
    Metadata = 500 // catch all
}
