import { ApplicationsEnum } from 'app/nexus-shared';
import { PhoneNumberModel } from 'app/nexus-shared/models/phone-number.model';
import { AddressModel } from './address.model';
import { ContactIntegrationModel } from './contact-integration.model';
import { IndividualTypeModel } from './individual-type.model';
import { RegionTypesEnum } from 'app/nexus-shared/domain/contacts/enums/region-types.enum';
import { SuffixTypesEnum } from 'app/nexus-shared/domain/contacts/enums/suffix-types.enum';
import { IndividualToCompanyAccessModel } from 'app/nexus-shared/domain/contacts/models/individual-to-company-access.model';
import { PronounTypesEnum } from 'app/nexus-shared/domain/contacts/enums/pronoun-types.enum';
import { CommonBaseAuditModel } from 'app/nexus-shared/domain/contacts/models/common-base-audit.model';
import { IndividualInterface } from 'app/nexus-shared/interfaces/individual.interface';

export class IndividualModel extends CommonBaseAuditModel implements IndividualInterface {
    individualKey: string;
    myGTNPortalUserKey?: string;
    userKey?: string;
    firstName: string;
    middleName?: string;
    lastName: string;
    preferredName?: string;
    pronoun: PronounTypesEnum;
    suffix?: SuffixTypesEnum;

    get fullName(): string {
        return `${this.lastName}, ${this.firstName}`;
    }

    emailAddress: string;
    secondaryEmailAddress: string;
    applicationSourceType?: ApplicationsEnum;
    region?: RegionTypesEnum;
    individualToApplications: ApplicationsEnum[];
    phoneNumbers?: PhoneNumberModel[];
    addresses?: AddressModel[];
    birthDate?: Date;
    individualTypes?: IndividualTypeModel[];
    contactIntegrations?: ContactIntegrationModel[];
    individualToCompanyAccess?: IndividualToCompanyAccessModel[];

    constructor(individual: IndividualModel = null) {
        super(individual);
        this.individualKey = individual?.individualKey || null;
        this.myGTNPortalUserKey = individual?.myGTNPortalUserKey || null;
        this.userKey = individual?.userKey ?? null;
        this.firstName = individual?.firstName || null;
        this.middleName = individual?.middleName ?? null;
        this.lastName = individual?.lastName || null;
        this.preferredName = individual?.preferredName || null;
        this.pronoun = individual?.pronoun ?? null;
        this.suffix = individual?.suffix ?? null;
        this.emailAddress = individual?.emailAddress || null;
        this.secondaryEmailAddress = individual?.secondaryEmailAddress ?? null;
        this.applicationSourceType = individual?.applicationSourceType || null;
        this.region = individual?.region ?? null;
        this.individualToApplications = individual?.individualToApplications?.length ? individual.individualToApplications : [];
        this.phoneNumbers = individual?.phoneNumbers ? IndividualModel.initializePhoneNumbers(individual.phoneNumbers) : [];
        this.addresses = individual?.addresses ? IndividualModel.initializeAddresses(individual.addresses) : [];
        this.birthDate = individual?.birthDate ?? null;
        this.individualTypes = individual?.individualTypes ?? [];
        this.contactIntegrations = individual?.contactIntegrations ? IndividualModel.initializeContactIntegrations(individual.contactIntegrations) : [];
        this.individualToCompanyAccess = individual?.individualToCompanyAccess ? IndividualModel.initializeCompanyAccess(individual.individualToCompanyAccess) : [];
    }

    static initializePhoneNumbers(numbers) {
        return numbers.map(n => {
            return new PhoneNumberModel(n);
        });
    }

    private static initializeContactIntegrations(contactIntegrations: ContactIntegrationModel[]) {
        return contactIntegrations.map(n => {
            return new ContactIntegrationModel(n);
        });
    }

    private static initializeAddresses(addresses) {
        return addresses.map(a => {
            return new AddressModel(a);
        });
    }

    private static initializeCompanyAccess(companyAccess: IndividualToCompanyAccessModel[]) {
        return companyAccess.map(n => {
            return new IndividualToCompanyAccessModel(n);
        });
    }

}
