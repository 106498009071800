import { defer, from, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AddressModel } from 'app/nexus-shared/domain/contacts/models/address.model';
import { GoogleAddressPredictionModel } from 'app/nexus-shared/domain/contacts/models/google-address-prediction.model';
import { environment } from 'environments/environment';
import { BypassHttpInterceptorService } from 'app/nexus-core/services/bypass-http-interceptor.service';

@Injectable()
export class GoogleLocationService {
    isApiLoaded: boolean = false;
    apiKey: string = environment().licenseKeys.googleMaps;
    mapsApiUrl: string = environment().externalWebAPIUrls.googleMaps;
    placesApiUrl: string = environment().externalWebAPIUrls.googlePlaces;

    constructor(private bypassHttpInterceptorService: BypassHttpInterceptorService) {
    }

    loadApi(): Observable<boolean> {
        if (this.isApiLoaded) {
            return of(true);
        }

        return this.bypassHttpInterceptorService.jsonp(`${this.mapsApiUrl}?key=${this.apiKey}&libraries=places`, 'callback')
            .pipe(
                map(() => {
                    this.isApiLoaded = true;
                    return true;
                }),
                catchError(_ => {
                    return of(false);
                }),
            );
    }

    getLocationPredictions(text: string): Observable<GoogleAddressPredictionModel[]> {
        return this.loadApi().pipe(mergeMap(_ => this.autocomplete(text)));
    }

    getLocationByPlaceId(placeId: string): Observable<any> {
        return this.loadApi().pipe(mergeMap(_ => {
            return from(fetch(`${this.placesApiUrl}/${placeId}?fields=formattedAddress,id,location,primaryType,addressComponents&key=${this.apiKey}`).then((res) => {
                return res.json().then(address => this.mapAddress(address));
            }));
        }));
    }

    private autocomplete(text: string): Observable<any> {
        const autocompleteService = new google.maps.places.AutocompleteService();
        return defer(() => from(autocompleteService.getPlacePredictions({
            input: text,
            types: ['geocode']
        }).then((results) => {
            return results.predictions.map(x => new GoogleAddressPredictionModel(x));
        })));
    }

    getCurrentLocation(): Observable<{ latitude: number, longitude: number }> {
        return Observable.create(observer => {
            if (window.navigator && window.navigator.geolocation) {
                window.navigator.geolocation.getCurrentPosition(
                    (position) => {
                        observer.next({ latitude: position.coords.latitude, longitude: position.coords.longitude });
                        observer.complete();
                    },
                    (error) => observer.error(error)
                );
            } else {
                observer.error('Unsupported browser to determine GPS position.');
            }
        });
    }

    private mapAddress(addressResult: any): AddressModel {
        const address: AddressModel = new AddressModel();

        let address1 = '';
        let postcode = '';

        for (const component of addressResult.addressComponents) {
            const componentType = component.types[0];
            switch (componentType) {
                case 'street_number': {
                    address1 = component.longText + ' ' + address1;
                    break;
                }
                case 'route': {
                    address1 += component.shortText;
                    break;
                }

                case 'postal_code': {
                    postcode = component.longText + postcode;
                    break;
                }

                case 'postal_code_suffix': {
                    postcode = postcode + '-' + component.longText;
                    break;
                }
                case 'locality':
                case 'postal_town':
                    address.city = component.longText;
                    break;
                case 'subpremise': {
                    address.addressLineTwo = component.longText;
                    break;
                }
                case 'administrative_area_level_1': {
                    address.stateCode = component.shortText;
                    address.stateName = component.longText;
                    break;
                }
                case 'country':
                    address.countryName = component.longText;
                    address.countryCode = component.shortText;
                    break;
            }
        }

        address.fullAddress = addressResult.formattedAddress;
        address.addressLineOne = address1;
        address.googlePlaceId = addressResult.id;
        address.postalCode = postcode;
        address.latitude = addressResult.location?.latitude;
        address.longitude = addressResult?.location?.longitude;
        address.googlePrimaryType = addressResult.primaryType;
        return address;
    }
}

