import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { BreadcrumbModel } from 'app/nexus-shared/components/controls/shared/models/breadcrumb.model';
import { RoutingService } from 'app/nexus-routing/services/routing.service';
import { StringHelper, WindowResizeService } from 'app/nexus-core';
import { Observable, of } from 'rxjs';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';

@Component({
    selector: 'gtn-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent extends BaseComponent implements OnInit, AfterViewInit {

    @Input() isBackButtonEnabled: boolean;
    @Input() breadcrumbWidthRatio: number = .45;
    @Input() shortenBreadcrumbs: boolean = false;
    @Input() breadcrumbSanitizer: (breadcrumbs: BreadcrumbModel[]) => Observable<BreadcrumbModel[]>;

    hiddenBreadcrumbs: boolean = false;
    breadcrumbs: BreadcrumbModel[] = [];
    isLoading: boolean = false;

    @ViewChild('breadcrumbParent') breadcrumbParent: ElementRef;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        public previousRouteService: RoutingService,
        private resizeService: WindowResizeService
    ) {
        super();
    }

    ngOnInit(): void {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            distinctUntilChanged(),
        ).subscribe(() => {
            this.setBreadcrumbs();
        });
        if (!this.shortenBreadcrumbs) {
            this.setBreadcrumbs();
        }
    }

    ngAfterViewInit(): void {
        if (this.shortenBreadcrumbs) {
            this.subscriptions.add(this.resizeService.resized.subscribe(_ => {
               this.breadcrumbs = this.calculateBreadcrumbWidth(this.breadcrumbs);
            }));
            setTimeout(() => {
                this.setBreadcrumbs();
            });
        }
    }

    onBreadcrumbClicked(breadcrumb: BreadcrumbModel): void {
        this.router.navigate([breadcrumb.navigationUrl]);
    }

    showHiddenBreadcrumbs(): void {
        this.breadcrumbs.forEach(x => x.isHidden = false);
        this.hiddenBreadcrumbs = false;
    }

    private buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: BreadcrumbModel[] = []): BreadcrumbModel[] {
        const breadcrumbConfig = route.routeConfig?.data?.breadcrumb;
        const title = breadcrumbConfig?.title || '';
        const id = breadcrumbConfig?.id || null;
        const isNavigationDisabled = breadcrumbConfig?.isNavigationDisabled || false;
        const isDisabled = breadcrumbConfig?.isDisabled || false;
        const icon = breadcrumbConfig?.icon || '';
        const isHidden = breadcrumbConfig?.isHidden || false;
        const width = title ? StringHelper.measureText(title) : 0;
        let path = route.routeConfig?.path || '';
        if (isDisabled) {
            return [];
        }

        if (path.indexOf(':') > -1 && !!route.snapshot) {
            const routeParts = path.split('/').filter(t => t.indexOf(':') > -1);

            for (const routePart of routeParts) {
                const routeParameter = routePart.split(':')[1];
                path = path.replace(':' + routeParameter, route.snapshot.params[routeParameter]);
            }
        }

        const nextUrl = path ? `${url}/${path}` : url;
        const breadcrumb: BreadcrumbModel = {
            title: title,
            navigationUrl: breadcrumbConfig?.navigationUrl || nextUrl,
            isNavigationDisabled: isNavigationDisabled,
            id: id,
            icon: icon,
            isHidden: isHidden,
            width: width
        };

        const newBreadcrumbs = breadcrumb.title ? [...breadcrumbs, breadcrumb] : [...breadcrumbs];
        if (route.firstChild) {
            return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
        }
        return newBreadcrumbs;
    }

    private calculateBreadcrumbWidth(breadcrumbs: BreadcrumbModel[]): BreadcrumbModel[] {
        this.hiddenBreadcrumbs = false;
        const parentWidth = this.breadcrumbParent.nativeElement.offsetWidth;
        if (breadcrumbs.length) {
            let w = breadcrumbs[0].width + breadcrumbs[breadcrumbs.length - 1].width;
            breadcrumbs.reverse().forEach((item, i) => {
                if (i !== breadcrumbs.length - 1 && i !== 0) {
                    w += (item.width ? item.width : 0);
                    if (w / parentWidth > this.breadcrumbWidthRatio) {
                        item.isHidden = true;
                        this.hiddenBreadcrumbs = true;
                    } else {
                        item.isHidden = false;
                    }
                }
            });
        }
       return breadcrumbs.reverse();
    }

    private defaultSanitizer(breadcrumbs): Observable<BreadcrumbModel[]> {
        return of(breadcrumbs);
    }

    private setBreadcrumbs(): void {
        // ONLY NEED TO HIDE WHEN RESIZE
        this.isLoading = !!this.shortenBreadcrumbs;
        const breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
        this.breadcrumbSanitizer = this.breadcrumbSanitizer || this.defaultSanitizer;
        this.breadcrumbSanitizer(breadcrumbs).subscribe((breadcrumbs) => {
            if (this.shortenBreadcrumbs) {
               breadcrumbs = this.calculateBreadcrumbWidth(breadcrumbs);
            }
            this.breadcrumbs = JSON.parse(JSON.stringify(breadcrumbs));
            this.isLoading = false;
        });
    }
}
