<div class="control-flex-container">
    <div *ngFor="let agFilter of gtnFilters">
        <div class="control-flex-default"
             *ngIf="agFilter.controlType === filterEnum.Select || agFilter.controlType === filterEnum.SelectEnum || agFilter.controlType === filterEnum.Combobox">
            <gtn-dropdown
                [(ngModel)]="agFilter.filterObject.filter"
                [options]="agFilter.options"
                [label]="agFilter.label" [idOnly]="true" [idKey]="agFilter.idKey"
                (change)="onDropdownChanged($event['value'], agFilter)">
            </gtn-dropdown>
        </div>
        <div class="control-flex-default" *ngIf="agFilter.controlType === filterEnum.ActiveDateStart">
            <gtn-checkbox class="align-checkbox" [(ngModel)]="agFilter.isFilterActive"
                          label="Active" (change)="onFilterActiveChanged($event['value'])">
            </gtn-checkbox>
        </div>
        <div class="control-flex-datepicker-container" *ngIf="agFilter.controlType === filterEnum.Daterange">
            <div class="control-flex-default">
                <gtn-datepicker [(ngModel)]="agFilter.filterObject.dateFrom" label="Start date"
                                (change)="onDateChanged($event['value'], agFilter)">
                </gtn-datepicker>
            </div>
            <div class="control-flex-default">
                <gtn-datepicker [(ngModel)]="agFilter.filterObject.dateTo" label="End date"
                                (change)="onDateChanged($event['value'], agFilter)">
                </gtn-datepicker>
            </div>
        </div>
    </div>
</div>
