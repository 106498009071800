import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'gtn-totals-cell',
    templateUrl: './totals-cell.component.html',
    styleUrls: ['./totals-cell.component.scss']
})
export class TotalsCellComponent implements ICellRendererAngularComp {
    cellValue: string;
    prefix: string;
    isFooter: boolean;

    constructor() {
    }

    agInit(params: ICellRendererParams): void {
        this.cellValue = params.value;
        this.isFooter = params?.node?.footer;
        if (this.isFooter) {
            this.prefix = params.node.level === -1 ? 'Total' : 'Sub Total';
        }

    }

    refresh(params: ICellRendererParams): boolean {
        return false;
    }
}
