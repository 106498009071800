<div @modalFadeIn class="modal-wrapper">
    <gtn-modal-header [closeClick]="destroy" [mainHeaderText]="mainHeaderText" [subHeaderText]="subHeaderText">
    </gtn-modal-header>
    <div class="modal-content">
        <div>
            {{message}}
        </div>
    </div>
    <gtn-modal-footer [ok]='okClick' [showOk]="ok ? true : false">
    </gtn-modal-footer>
</div>
