import { Pipe, PipeTransform } from '@angular/core';
import { SortingHelper } from 'app/nexus-core';

@Pipe({
    name: 'names'
})
export class NamesPipe implements PipeTransform {
    transform(objects: { name: string }[], separator: string = ', ', sort = true) {
        const items = sort
            ? [...objects.sort((a, b) => SortingHelper.sortByDatePropertyComparer(a, b, 'name'))]
            : [...objects];
        return items.map(o => o.name).join(separator);
    }
}
