<gtn-template-readonly-edit-card [(isSaving)]="isSaving" [formGroupRef]="formGroupRef"
    [validationModels]="validationModels" [readonly]="readonly" [showActionButtonsInCard]="true" [showCardTitleIcon]="canEdit"
    (readonlyChange)="onReadOnlyChanged($event)" (saveClick)="onSaveClicked()"  (cancelClick)="onCancelClicked()">
    <gtn-template-readonly-edit-card-header>{{pageTitle}}</gtn-template-readonly-edit-card-header>
    <gtn-template-readonly-edit-card-view-content>
        <gtn-document-view *ngIf="readonly" [value]="value" [viewMode]="viewMode"></gtn-document-view>
    </gtn-template-readonly-edit-card-view-content>
    <gtn-template-readonly-edit-card-edit-content>
        <gtn-document-form *ngIf="!readonly" [(formGroupRef)]="formGroupRef" (formGroupRefChange)="onFormGroupRefChanged($event)"
            [(value)]="value" [(file)]="file" [viewMode]="viewMode"></gtn-document-form>
    </gtn-template-readonly-edit-card-edit-content>
</gtn-template-readonly-edit-card>
