import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { DropdownOptionTypeModel } from 'app/nexus-shared/domain/questionnaires/models/dropdown-option-type.model';
import { QuestionnaireDropdownsService } from 'app/nexus-core/services/domain/questionnaires/questionnaire-dropdowns.service';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'gtn-questionnaire-dropdowns-list',
    templateUrl: './questionnaire-dropdowns-list.component.html',
    styleUrls: ['./questionnaire-dropdowns-list.component.scss']
})
export class QuestionnaireDropdownsListComponent extends BaseComponent implements OnInit {
    @Input() value: DropdownOptionTypeModel;
    @Output() valueChange: EventEmitter<DropdownOptionTypeModel> = new EventEmitter<DropdownOptionTypeModel>();
    @Output() addClick: EventEmitter<void> = new EventEmitter<void>();
    @Output() dropdownSelect: EventEmitter<DropdownOptionTypeModel> = new EventEmitter<DropdownOptionTypeModel>();

    dropdowns: DropdownOptionTypeModel[];

    constructor(private questionnaireDropdownsService: QuestionnaireDropdownsService) {
        super();
    }

    ngOnInit() {
        this.getDropdowns();
    }

    getDropdowns(): void {
        this.isLoading = true;
        this.questionnaireDropdownsService.search(false).pipe(finalize(() => {
            this.isLoading = false;
        })).subscribe(res => {
            this.dropdowns = res;
        });
    }

    onAddClicked(): void {
        this.addClick.emit();
    }

    onDropdownClicked(dropdown: DropdownOptionTypeModel): void {
        if (!this.isLoading) {
            this.dropdownSelect.emit(dropdown);
        }
    }

}
