import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BaseFormComponent } from 'app/nexus-shared/components/base-component/base-form.component';
import { DocumentSearchModel } from 'app/nexus-shared/domain/documents/models/document-search.model';
import { SelectListInterface } from 'app/nexus-shared/interfaces';
import { EnumHelper } from 'app/nexus-core';
import { AreaTypesEnum } from 'app/nexus-shared/domain/documents/enums/area-types.enum';
import { CompanyModel, IndividualModel } from 'app/nexus-shared/domain/contacts/models';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { BaseFormModel } from 'app/nexus-shared/models/base-form.model';
import { UntypedFormGroup } from '@angular/forms';
import { DocumentAdvancedSearchModel } from 'app/nexus-shared/domain/documents/models/document-advanced-search.model';
import { ClientDocumentTypesEnum } from 'app/nexus-shared/domain/documents/enums/client-document-types.enum';
import { CompanySearchComponent } from 'app/nexus-shared/domain/contacts/components/search/company-search/company-search.component';
import { IndividualSearchComponent } from 'app/nexus-shared/domain/contacts/components/search/individual-search/individual-search.component';
import { DocumentStatusesEnum } from '../../../enums/document-statuses.enum';

@Component({
    selector: 'gtn-document-search-filters-form',
    templateUrl: './document-search-filters-form.component.html',
    styleUrls: ['./document-search-filters-form.component.scss']
})
export class DocumentSearchFiltersFormComponent extends BaseFormComponent<DocumentSearchModel> implements OnInit {
    @Input() isFiltersVisible: boolean = true;
    @Output() isFiltersVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() searchClick: EventEmitter<void> = new EventEmitter<void>();

    areaTypeOptions: SelectListInterface[];
    clientDocumentTypes: SelectListInterface[];
    taxYearOptions: SelectListInterface[];
    documentStatusesOptions: SelectListInterface[];
    isFiltersHidden: boolean = false;

    @ViewChild('companySearch') companySearch: CompanySearchComponent;
    @ViewChild('individualSearch') individualSearch: IndividualSearchComponent;

    ngOnInit(): void {
        const formConfiguration = new BaseFormModel();
        formConfiguration.formConfiguration[ObjectHelper.nameOf<DocumentSearchModel>('advancedSearch')] = new BaseFormModel();
        super.ngOnInit(formConfiguration);
    }

    onCompanySelected(company: CompanyModel): void {
        const formGroup = this.formGroupRef.get(ObjectHelper.nameOf<DocumentSearchModel>('advancedSearch')) as UntypedFormGroup;
        formGroup.get(ObjectHelper.nameOf<DocumentAdvancedSearchModel>('companyKey')).setValue(company?.companyKey ?? null);
        this.formGroupRef.markAsDirty();
    }

    onIndividualSelected(individual: IndividualModel): void {
        const formGroup = this.formGroupRef.get(ObjectHelper.nameOf<DocumentSearchModel>('advancedSearch')) as UntypedFormGroup;
        formGroup.get(ObjectHelper.nameOf<DocumentAdvancedSearchModel>('individualKey')).setValue(individual?.individualKey ?? null);
        this.formGroupRef.markAsDirty();
    }

    onResetFiltersClicked(): void {
        this.individualSearch.value = null;
        this.companySearch.value = null;
        this.formGroupRef.reset();
    }

    onSearchClicked(): void {
        this.searchClick.emit();
    }

    onToggleVisibilityClicked(): void {
        this.isFiltersVisible = false;
        this.isFiltersVisibleChange.emit(false);
    }

    protected initUIControls() {
        this.areaTypeOptions = EnumHelper.convertToSelectList(AreaTypesEnum, false).filter(x => x.id !== AreaTypesEnum.Personal && x.id !== AreaTypesEnum.FileTemplates && x.id !== AreaTypesEnum.FolderTemplates);
        this.clientDocumentTypes = EnumHelper.convertToSelectList(ClientDocumentTypesEnum, false);
        this.documentStatusesOptions = EnumHelper.convertToSelectList(DocumentStatusesEnum, false);
        this.getTaxYearOptions();
    }

    private getTaxYearOptions(): void {
        const startYear: number = (new Date().getFullYear()) - 4;
        const endYear: number = (new Date().getFullYear()) + 1;

        const years: SelectListInterface[] = [];
        for (let i = startYear; i <= endYear; i++) {
            years.push({
                id: i,
                value: i.toString()
            });
        }
        this.taxYearOptions = years;
    }


}
