<h3 *ngIf="label">{{label}}</h3>
<div class="row">
    <gtn-dropdown class="col-md-6" [options]="monthOptions" [idOnly]="true" [(ngModel)]="selectedMonth"
        (change)="onMonthChanged($event)" displayKey="name" label="Month">
    </gtn-dropdown>
    <gtn-dropdown class="col-md-6" [options]="dayOptions" [idOnly]="true" [(ngModel)]="selectedDay"
        (change)="onDayChanged()" displayKey="name" label="Day">
    </gtn-dropdown>
    <div class="gtn-control-error-message error-message" *ngIf="!formControl?.valid && canShowValidation">
        <span *ngFor="let msg of getErrorMessage()">{{msg}}</span></div>
</div>
