import { Injectable } from '@angular/core';
import { BaseService } from 'app/nexus-core/services/base-service.directive';
import { environment } from 'environments/environment';
import { BaseHttpService } from 'app/nexus-core/services/base-http.service';
import { Observable, of } from 'rxjs';
import { DropdownOptionTypeModel } from 'app/nexus-shared/domain/questionnaires/models/dropdown-option-type.model';
import { tap } from 'rxjs/operators';
import { IQuestionnaireDropdownServiceInterface } from 'app/nexus-shared/domain/questionnaires/interfaces/iquestionnaire-dropdown-service.interface';

@Injectable()
export class QuestionnaireDropdownsService extends BaseService implements IQuestionnaireDropdownServiceInterface {
    private apiUrl = environment().webAPIUrls.questionnaires + 'dropdown-option-types/';
    private dropdowns: DropdownOptionTypeModel[] = [];

    constructor(private baseHttpService: BaseHttpService) {
        super();
    }

    get(dropdownId: number): Observable<DropdownOptionTypeModel> {
        if (this.dropdowns?.length && this.dropdowns.some(x => x.id === dropdownId)) {
            return of(this.dropdowns.find(x => x.id === dropdownId));
        }
        return this.baseHttpService.get(`${this.apiUrl}${dropdownId}`);
    }

    search(useCache: boolean = true): Observable<DropdownOptionTypeModel[]> {
        if (this.dropdowns?.length && useCache) {
            return of(this.dropdowns);
        }
        return this.baseHttpService.get(`${this.apiUrl}search`).pipe(tap(res => {
            this.dropdowns = res.map(x => new DropdownOptionTypeModel(x));
        }));
    }

    create(model: DropdownOptionTypeModel): Observable<number> {
        return this.baseHttpService.post(this.apiUrl, model).pipe(tap(_ => {
            this.dropdowns = null;
        }));
    }

    update(model: DropdownOptionTypeModel): Observable<boolean> {
        return this.baseHttpService.put(this.apiUrl, model).pipe(tap(_ => {
            this.dropdowns = null;
        }));
    }
}
