import { SortingHelper } from 'app/nexus-core/index';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { LocationCompositeModel, LocationModel, LocationTypesEnum } from 'app/nexus-shared/domain/locations';
import { LocationService } from 'app/nexus-core/services/location.service';
import { ArrayHelper } from './array.helper';
import { LocationOrderModel } from 'app/nexus-shared/domain/locations/models/location-order.model';

export class LocationHelper {
    static readonly countryIds = {
        unitedStatesLocationId: 6252001,
        canadaLocationId: 6251999,
        israelLocationId: 294640,
        switzerlandLocationId: 2658434,
        unitedKingdomLocationId: 2635167,
        israelId: 294640
    };

    static defaultLocationOrder: LocationOrderModel[] = [
        {
            location: {
                id: LocationHelper.countryIds.unitedStatesLocationId
            },
            order: 1
        },
        {
            location: {
                id: LocationHelper.countryIds.canadaLocationId
            },
            order: 2
        },
    ];

    static containsLocation(locations: LocationCompositeModel[], location: LocationCompositeModel, includeCityAndCounty: boolean = true): boolean {
        if (locations && location) {
            return locations.findIndex(loc => LocationHelper.areLocationsEqual(loc, location, includeCityAndCounty)) > -1;
        }

        return false;
    }

    static areLocationsEqual(locA: LocationCompositeModel, locB: LocationCompositeModel, includeCityAndCounty: boolean = true): boolean {
        if (locA && locB) {
            if (includeCityAndCounty && locA.city?.id && locB.city?.id) {
                if (locA.city.id === locB.city.id) {
                    return true;
                } else if (locA.city?.id || locB.city?.id) {
                    return false;
                }
            }

            if (includeCityAndCounty && locA.county?.id && locB.county?.id) {
                if (locA.county.id === locB.county.id) {
                    return true;
                } else {
                    return false;
                }
            } else if (locA.state?.id || locB.state?.id) {
                return false;
            }

            if (locA.state?.id && locB.state?.id) {
                if (locA.state.id === locB.state.id) {
                    return true;
                } else {
                    return false;
                }
            } else if (locA.state?.id || locB.state?.id) {
                return false;
            }

            if (locA.country?.id && locB.country?.id) {
                if (locA.country.id === locB.country.id) {
                    return true;
                } else {
                    return false;
                }
            } else if (locA.country?.id || locB.country?.id) {
                return false;
            }
        }

        return false;
    }

    static getLocationNames(locations: LocationModel[], isCountries = false, separator: string = ', ', sort = true): string {
        const locs = sort
            ? isCountries
                ? [...LocationService.sortCountries(LocationHelper.locationsSort(locations))]
                : [...LocationHelper.locationsSort(locations)]
            : [...locations];

        return locs.map(c => c.name).join(separator);
    }

    static getLocationNameFromRelatedLocations(location: LocationModel): string {
        const compositeModel = LocationHelper.buildLocationCompositeModel(location);
        return LocationHelper.getLocationNameFromCompositeModel(compositeModel);
    }

    static buildLocationCompositeModel(location: LocationModel): LocationCompositeModel {
        return {
            country: this.getLocationByType(location, LocationTypesEnum.Country),
            state: this.getLocationByType(location, LocationTypesEnum.StateProvince),
            county: this.getLocationByType(location, LocationTypesEnum.County),
            city: this.getLocationByType(location, LocationTypesEnum.City),
            airport: this.getLocationByType(location, LocationTypesEnum.Airport)
        };
    }

    private static getLocationByType(location: LocationModel, type: LocationTypesEnum) {
        return location.type?.id === type
            ? location
            : location.relatedLocations.find(rl => rl.type?.id === type);
    }

    static getLocationNameFromCompositeModel(location: LocationCompositeModel, includeCityAndCounty: boolean = true, includeCountry: boolean = true, includeAirport: boolean = true): string {
        let country: string = '',
            state: string = '',
            county: string = '',
            city: string = '',
            airport: string = '';

        if (location) {
            if (includeCountry && location.country && location.country.name) {
                country = location.country.name;
            }

            if (location.state && location.state.name) {
                state = location.state.name;

                if (country) {
                    state += ', ';
                }
            }

            if (includeCityAndCounty && location.city && location.city.name) {
                city = location.city.name;

                if (state || country) {
                    city += ', ';
                }
            }

            if (includeCityAndCounty && location.county && location.county.name) {
                county = location.county.name;

                if (state || country) {
                    county += ', ';
                }
            }

            if (includeAirport && location.airport?.airportCode) {
                airport += ` (${location.airport.airportCode})`;
            }
        }

        return city + county + state + country + airport;
    }

    static getLocationModelFromComposite(composite: LocationCompositeModel): LocationModel {
        return composite?.airport || composite?.city || composite?.county || composite?.state || composite?.country || null;
    }

    static getSortPositionForSearch(locationA: LocationCompositeModel, locationB: LocationCompositeModel) {

        const locationAType = LocationHelper.getLocationTypeFromCompositeModel(locationA);
        const locationBType = LocationHelper.getLocationTypeFromCompositeModel(locationB);

        // check if locations are the same type (different sorting)
        if (locationAType === locationBType) {
            if (locationA.airport && locationB.airport) {
                if (locationA.country.id === locationB.country.id) {

                    if (locationA.state && locationB.state && locationA.state?.id !== locationB.state?.id) {
                        return SortingHelper.sortByPropertyComparerDesc(locationA.state, locationB.state, ObjectHelper.nameOf<LocationModel>('population'));
                    }

                    return locationA.airport.name.localeCompare(locationB.airport.name);
                }

                return SortingHelper.sortByPropertyComparerDesc(locationA.country, locationB.country, ObjectHelper.nameOf<LocationModel>('population'));
            }

            if (locationA.city && locationB.city) {
                return SortingHelper.sortByPropertyComparerDesc(locationA.city, locationB.city, ObjectHelper.nameOf<LocationModel>('population'));
            }

            if (locationA.country.id === locationB.country.id && locationA.state?.id === locationB.state?.id && locationA.county && locationB.county) {
                return SortingHelper.sortByPropertyComparerDesc(locationA.county, locationB.county, ObjectHelper.nameOf<LocationModel>('population'));
            }

            if (locationA.country.id === locationB.country.id && locationA.state && locationB.state) {
                return SortingHelper.sortByPropertyComparerDesc(locationA.state, locationB.state, ObjectHelper.nameOf<LocationModel>('population'));
            }

            return SortingHelper.sortByPropertyComparerDesc(locationA.country, locationB.country, ObjectHelper.nameOf<LocationModel>('population'));
        }

        // at this point, locations are different types
        if (locationAType === LocationTypesEnum.Country && locationBType !== LocationTypesEnum.Country) {
            return -1;
        }

        if (locationBType === LocationTypesEnum.Country && locationAType !== LocationTypesEnum.Country) {
            return 1;
        }

        if (locationAType === LocationTypesEnum.StateProvince && locationBType !== LocationTypesEnum.StateProvince) {
            return -1;
        }

        if (locationBType === LocationTypesEnum.StateProvince && locationAType !== LocationTypesEnum.StateProvince) {
            return 1;
        }

        if (locationAType === LocationTypesEnum.Airport && locationBType !== LocationTypesEnum.Airport) {
            return -1;
        }

        if (locationBType === LocationTypesEnum.Airport && locationAType !== LocationTypesEnum.Airport) {
            return 1;
        }

        if (locationAType === LocationTypesEnum.County && locationBType !== LocationTypesEnum.County) {
            return -1;
        }

        if (locationBType === LocationTypesEnum.County && locationAType !== LocationTypesEnum.County) {
            return 1;
        }

        if (locationAType === LocationTypesEnum.City && locationBType !== LocationTypesEnum.City) {
            return -1;
        }

        if (locationBType === LocationTypesEnum.City && locationAType !== LocationTypesEnum.City) {
            return 1;
        }

        // if no decision made by this point, then something is wrong with logic
        return 0;
    }

    public static getLocationTypeFromCompositeModel(location: LocationCompositeModel): LocationTypesEnum {
        if (!location.airport) {
            if (!location.city) {
                if (!location.county) {
                    if (!location.state) {
                        if (!location.country) {
                            return LocationTypesEnum.Unknown;
                        } else {
                            return LocationTypesEnum.Country;
                        }
                    } else {
                        return LocationTypesEnum.StateProvince;
                    }
                } else {
                    return LocationTypesEnum.County;
                }
            } else {
                return LocationTypesEnum.City;
            }
        } else {
            return LocationTypesEnum.Airport;
        }
    }

    // will include parent locations (ex: "Minnesota, US" will return Minnesota and United States)
    public static getUniqueLocations(locations: LocationCompositeModel[]): LocationCompositeModel[] {
        const uniqueCountryIds = ArrayHelper.getUniqueValues(locations.map(r => r.country.id));
        const uniqueStateIds = ArrayHelper.getUniqueValues(locations.filter(r => r.state?.id).map(r => r.state.id));
        const uniqueCityIds = ArrayHelper.getUniqueValues(locations.filter(r => r.city?.id).map(r => r.city.id));
        const uniqueAirportIds = ArrayHelper.getUniqueValues(locations.filter(r => r.airport?.id).map(r => r.airport.id));
        const uniqueCountyIds = ArrayHelper.getUniqueValues(locations.filter(r => r.county?.id).map(r => r.county.id));

        const countries = uniqueCountryIds.map(countryId => locations.find(l => l.country.id === countryId));
        const states = uniqueStateIds.map(stateId => locations.find(l => l.state?.id === stateId));
        const cities = uniqueCityIds.map(cityId => locations.find(l => l.city?.id === cityId));
        const airports = uniqueAirportIds.map(airportId => locations.find(l => l.airport?.id === airportId));
        const counties = uniqueCountyIds.map(countyId => locations.find(l => l.county?.id === countyId));

        return [...countries.concat(states).concat(cities).concat(airports).concat(counties)];
        // if no decision made by this point, then something is wrong with logic
    }

    public static locationsSort(locations: LocationModel[], customOrderLocations: LocationOrderModel[] = null): LocationModel[] {
        locations = locations?.sort((a, b) => SortingHelper.sortByPropertyComparer(a, b, ObjectHelper.nameOf<LocationModel>('name')));
        if (locations?.length && customOrderLocations?.length) {
            customOrderLocations.sort((a, b) => SortingHelper.sortByDatePropertyComparer(a, b, ObjectHelper.nameOf<LocationOrderModel>('order'))).reverse().forEach(customOrder => {
                const location = locations.find(x => x?.id === customOrder.location.id);
                const index = locations.findIndex(x => x?.id === customOrder.location.id);
                locations.splice(index, 1);
                locations.unshift(location);
            });
        }
        return locations;
    }
}
