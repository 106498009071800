import { environment } from 'environments/environment';
import { BaseHttpService } from 'app/nexus-core/services/index';
import { Observable } from 'rxjs';
import { IndividualModel } from 'app/nexus-shared/domain/contacts/models/individual.model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { IndividualToCompanyAccessCompositeModel } from 'app/nexus-shared/domain/contacts/models/individual-to-company-access-composite.model';
import { IndividualToCompanyAccessCompositeResponseModel } from 'app/nexus-shared/domain/contacts/models/individual-to-company-access-composite-response.model';
import { IndividualSearchModel } from 'app/nexus-shared/domain/contacts/models/individual-search.model';
import { PhoneNumberHelper } from 'app/nexus-core/helpers/phone-number.helper';

@Injectable()
export class IndividualService {
    private apiUrl = environment().webAPIUrls.contacts + 'individuals/';

    constructor(private baseHttpService: BaseHttpService) {
    }

    getByKey(individualKey: string): Observable<IndividualModel> {
        return this.baseHttpService.get(`${this.apiUrl}${individualKey}`).pipe(map((individual: IndividualModel) => {
            if (individual) {
                return new IndividualModel(individual);
            }

            return null;
        }));
    }

    getByEmail(email: string): Observable<IndividualModel> {
        return this.baseHttpService.get(`${this.apiUrl}email?email=${encodeURI(email)}`).pipe(map((individual: IndividualModel) => {
            if (individual) {
                return new IndividualModel(individual);
            }

            return null;
        }));
    }

    getByUserKey(userKey: string): Observable<IndividualModel> {
        return this.baseHttpService.get(`${this.apiUrl}user/${userKey}`).pipe(map((individual: IndividualModel) => {
            if (individual) {
                return new IndividualModel(individual);
            }

            return null;
        }));
    }

    list(): Observable<IndividualModel[]> {
        return this.baseHttpService.get(this.apiUrl).pipe(map((individuals: IndividualModel[]) => {
            return individuals.map((individual: IndividualModel) => {
                return new IndividualModel(individual);
            });
        }));
    }

    listByCompanyKey(companyKey: string): Observable<IndividualModel[]> {
        return this.baseHttpService.get(`${this.apiUrl}company/${companyKey}`).pipe(map((individuals: IndividualModel[]) => {
            return individuals.map((individual: IndividualModel) => {
                return new IndividualModel(individual);
            });
        }));
    }

    listByKeys(individualKeys: string[]): Observable<IndividualModel[]> {
        return this.baseHttpService.post(`${this.apiUrl}keys`, individualKeys).pipe(map((individuals: IndividualModel[]) => {
            return individuals.map((individual: IndividualModel) => {
                return new IndividualModel(individual);
            });
        }));
    }

    search(search: IndividualSearchModel): Observable<IndividualModel[]> {
        return this.baseHttpService.post(`${this.apiUrl}search`, search).pipe(map((individuals: IndividualModel[]) => {
            return individuals.map((individual: IndividualModel) => {
                return new IndividualModel(individual);
            });
        }));
    }

    createIndividualAccessComposite(model: IndividualToCompanyAccessCompositeModel): Observable<IndividualToCompanyAccessCompositeResponseModel> {
        model.individual.phoneNumbers = PhoneNumberHelper.filterBlankPhoneNumbers(model.individual.phoneNumbers);
        model.individualToCompanyAccess.primaryPhoneNumber = PhoneNumberHelper.nullOutPhoneNumber(model.individualToCompanyAccess?.primaryPhoneNumber);
        model.individualToCompanyAccess.secondaryPhoneNumber = PhoneNumberHelper.nullOutPhoneNumber(model.individualToCompanyAccess?.secondaryPhoneNumber);
        return this.baseHttpService.post(`${this.apiUrl}individual-to-company-access`, model);
    }

    updateIndividualAccessComposite(model: IndividualToCompanyAccessCompositeModel): Observable<IndividualToCompanyAccessCompositeResponseModel> {
        model.individual.phoneNumbers = PhoneNumberHelper.filterBlankPhoneNumbers(model.individual.phoneNumbers);
        model.individualToCompanyAccess.primaryPhoneNumber = PhoneNumberHelper.nullOutPhoneNumber(model.individualToCompanyAccess?.primaryPhoneNumber);
        model.individualToCompanyAccess.secondaryPhoneNumber = PhoneNumberHelper.nullOutPhoneNumber(model.individualToCompanyAccess?.secondaryPhoneNumber);
        return this.baseHttpService.put(`${this.apiUrl}individual-to-company-access`, model);
    }

    create(model: IndividualModel): Observable<string> {
        model.phoneNumbers = PhoneNumberHelper.filterBlankPhoneNumbers(model.phoneNumbers);
        return this.baseHttpService.post(this.apiUrl, model);
    }

    update(model: IndividualModel): Observable<boolean> {
        model.phoneNumbers = PhoneNumberHelper.filterBlankPhoneNumbers(model.phoneNumbers);
        return this.baseHttpService.put(this.apiUrl, model);
    }
}
