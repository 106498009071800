import { Component, OnInit } from '@angular/core';
import { CompanyService } from 'app/nexus-core/services/domain/contacts/company.service';
import { CompanyModel } from 'app/nexus-shared/domain/contacts/models/company.model';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { AuthenticatedUserService } from 'app/nexus-core/services/authenticated-user.service';
import { AuthorizationConstants } from 'app/nexus-shared/constants/authorization.constants';
import { BaseEnterpriseGridComponent } from 'app/nexus-shared/components/base-component/base-enterprise-grid.component';
import { AgGridHelper, EnumHelper } from 'app/nexus-core';
import { ColDef, RowClickedEvent } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { CompanyTypesEnum } from 'app/nexus-shared/domain/contacts/enums/company-types.enum';
import { CompanySearchModel } from 'app/nexus-shared/domain/contacts/models/company-search.model';
import { IndividualSettingService } from 'app/nexus-core/services/domain/core/individual-setting.service';
import { AgGridColDefHelper } from 'app/nexus-core/helpers/ag-grid-col-def.helper';
import { AgGridColDefExtras } from 'app/nexus-shared/models/ag-grid-col-def-extras.model';
import { CommonBaseAuditUserModel } from 'app/nexus-shared/domain/contacts/models/common-base-audit-user.model';

@Component({
    selector: 'gtn-companies-grid',
    templateUrl: '../../../../components/base-component/base-grid-template.component.html'
})
export class CompaniesGridComponent extends BaseEnterpriseGridComponent<CompanyModel> implements OnInit {
    static companyKeyCellClass = AgGridHelper.copyPasteCellClass;

    gridName: string = 'companies_grid';
    gridVersion: string = '1.0.2';
    isAdmin: boolean = false;
    companyTypes: string[] = EnumHelper.convertToSelectList(CompanyTypesEnum, false, false).map(x => x.value);

    constructor(
        protected individualSettingService: IndividualSettingService,
        private companyService: CompanyService,
        private authenticatedUserService: AuthenticatedUserService
    ) {
        super(individualSettingService);
    }

    ngOnInit() {
        this.isAdmin = this.authenticatedUserService.hasAuthorization(AuthorizationConstants.contactsAdmin);
        super.ngOnInit();
    }


    onRowClicked(row: RowClickedEvent): void {
        const target = row.event.target as HTMLElement;
        if (target.classList.contains(CompaniesGridComponent.companyKeyCellClass)) {
            return;
        }
        this.rowClick.emit(row.data);
    }

    setDefaultSortModel() {
        this.defaultSortModel = [
            { colId: ObjectHelper.nameOf<CompanyModel>('name'), sort: 'asc' }
        ];
    }

    protected setColumnDefinitions(): ColDef[] {
        return [
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOf<CompanyModel>('name'), 'Name'),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOf<CompanyModel>('shortName'), 'Short Name'),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOf<CompanyModel>('companyTypes'), 'Type', null, <AgGridColDefExtras>{
                colDef: {
                    valueGetter: (params) => {
                        return params?.data?.companyTypes.map(x => EnumHelper.getDisplayName(CompanyTypesEnum, x.type));
                    },
                    filter: AgGridHelper.setColumnFilter,
                    filterParams: {
                        values: this.companyTypes
                    }
                }
            }),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOf<CompanyModel>('companyKey'), 'Key', null, <AgGridColDefExtras>{ hide: !this.isAdmin }),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOfSubProperty<CompanyModel, CommonBaseAuditUserModel>('createdByUser', 'fullName'), 'Created By', null, { hide: true }),
            AgGridColDefHelper.getDateTimeColDef(ObjectHelper.nameOfSubProperty<CompanyModel, CommonBaseAuditUserModel>('createdByUser', 'changeDateTime'), 'Created On', { hide: true }),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOfSubProperty<CompanyModel, CommonBaseAuditUserModel>('lastModifiedByUser', 'fullName'), 'Last Modified By', null, { hide: true }),
            AgGridColDefHelper.getDateTimeColDef(ObjectHelper.nameOfSubProperty<CompanyModel, CommonBaseAuditUserModel>('lastModifiedByUser', 'changeDateTime'), 'Modified On', { hide: true }),
        ];
    }

    protected setRowData(): Observable<CompanyModel[]> {
        const searchModel = <CompanySearchModel>{ includeCompanyTypes: true };
        return this.companyService.search(searchModel);
    }
}
