export class CustomCellEditorComponent {
    eGui: HTMLElement;
    input: HTMLInputElement;

    init(params): void {
        const div = document.createElement('div');
        const input = document.createElement('input');
        div.classList.add('ag-cell-editor', 'ag-input-wrapper');
        input.classList.add('ag-input-field-input');
        input.value = params.value;
        div.appendChild(input);
        this.eGui = div;
        this.input = input;

        input.addEventListener('mousedown', this.mouseDownListener);
    }

    mouseDownListener(e) {
        e.stopPropagation();
    }

    getValue(): string {
        return this.input.value;
    }

    getGui(): HTMLElement {
        return this.eGui;
    }

    afterGuiAttached(): void {
        this.input.focus();
    }

    destroy(): void {
        this.input.addEventListener('mousedown', this.mouseDownListener);
    }
}
