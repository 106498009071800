import { PdfAnnotationBorderStyleModel } from "./pdf-annotation-border-style.model";

export class PDFAnnotationModel {
    actions: any;
    alternativeText: string;
    annotationFlags: any;
    annotationType: any;
    backgroundColor: {
        "0": number,
        "1": number,
        "2": number
    };
    borderColor: {
        "0": number,
        "1": number,
        "2": number
    };
    borderStyle: PdfAnnotationBorderStyleModel;
    color: {
        "0": number,
        "1": number,
        "2": number
    };
    comb: boolean;
    contentsObj: { str: string, dir: string };
    defaultAppearanceData: {
        fontSize: number, fontName: string, fontColor: {
            "0": number,
            "1": number,
            "2": number
        }
    };
    defaultFieldValue: any;
    fieldFlags: any;
    fieldName: string;
    fieldType: string;
    fieldValue: string;
    hasAppearance: boolean;
    hasOwnCanvas: boolean;
    hidden: boolean;
    id: string;
    maxLen: any;
    modificationDate: any;
    multiLine: boolean;
    readOnly: boolean;
    rect: any;
    subtype: string;
    textAlignment: any;
}
