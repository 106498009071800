import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { StringConstants } from 'app/nexus-shared';
import { environment } from 'environments/environment';

export class RoutingHelper {
    static getStrippedUrl(url: string): string {
        const strippedUrlWithQueryParams = url.split('/').slice(2).join('/');
        return strippedUrlWithQueryParams.replace('%253F', '?').split('?')[0];
    }

    static getParameterFromActivatedRouteSnapshot(key: string, snapshot: ActivatedRouteSnapshot = null): any {
        while (snapshot) {
            if (snapshot.params?.[key]) {
                return snapshot.params?.[key];
            }
            snapshot = snapshot.parent;
        }
        return undefined;
    }

    // this is specifically used by top-level (in routing hierarchy) resolvers
    static getChildParameterFromActivatedRouteSnapshot(key: string, snapshot: ActivatedRouteSnapshot = null): any {
        while (snapshot) {
            if (ObjectHelper.isNotNullOrUndefined(snapshot.params?.[key])) {
                return snapshot.params?.[key];
            }
            snapshot = snapshot.firstChild;
        }
        return undefined;
    }

    static getParameterFromRouter(key: string, router: Router): any {
        let currentActivatedRoute = router.routerState.root;

        if (currentActivatedRoute === null) {
            return null;
        }

        while (currentActivatedRoute.firstChild) {
            currentActivatedRoute = currentActivatedRoute.firstChild;
        }

        return RoutingHelper.getParameterFromActivatedRouteSnapshot(key, currentActivatedRoute.snapshot);
    }

    static getDataFromActivatedRouteSnapshot(key: string, snapshot: ActivatedRouteSnapshot = null): any {
        let childFound = false;
        while (!childFound) {
            if (snapshot.firstChild) {
                snapshot = snapshot.firstChild;
            } else {
                childFound = true;
            }
        }

        return snapshot.data?.[key];
    }

    static getSnapshotFromActivatedRouteSnapshotByUrl(url: string, snapshot: ActivatedRouteSnapshot = null): any {
        while (snapshot) {
            if (RoutingHelper.getResolvedUrl(snapshot) === url) {
                return snapshot;
            }
            snapshot = snapshot.firstChild;
            RoutingHelper.getSnapshotFromActivatedRouteSnapshotByUrl(url, snapshot);
        }
        return undefined;
    }

    static getResolvedUrl(activatedRouteSnapshot: ActivatedRouteSnapshot): string {
        if (!activatedRouteSnapshot) {
            return null;
        }

        return activatedRouteSnapshot.pathFromRoot.map(v => v.url.map(segment => segment.toString()).join('/')).join('/').replace('//', '/');
    }

    static getConfiguredUrl(activatedRouteSnapshot: ActivatedRouteSnapshot): string {
        if (!activatedRouteSnapshot) {
            return null;
        }

        return '/' + activatedRouteSnapshot.pathFromRoot.filter(v => v.routeConfig).map(v => v.routeConfig?.path).join('/').replace('//', '/');
    }

    static getParameterFromRoute(key: string, snapshot: ActivatedRouteSnapshot = null): any {
        return RoutingHelper.getParameterFromActivatedRouteSnapshot(key, snapshot) || null;
    }

    static getQueryParameterFromRoute(key: string, snapshot: ActivatedRouteSnapshot = null): any {
        return snapshot.queryParams[key] || null;
    }

    static coreRedirect(location: Location): void {
        if (location.hostname !== 'localhost' && location.hostname.indexOf('core') < 0) {
            const navigationRoute = location.hash.substring(2);
            const coreUrl = `${StringConstants.urlStrings.coreBase.replace('{0}', environment().envPrefix)}${navigationRoute}`;
            location.assign(coreUrl);
        }
    }

    static navigateToContacts(navigationRoute: string): void {
        const contactsUrl: string = `${StringConstants.urlStrings.coreBase.replace('{0}', environment().envPrefix)}${navigationRoute}`;
        location.assign(contactsUrl);
    }
}
