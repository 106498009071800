<button (click)="onAddCostCenterClicked()" color="primary" mat-flat-button>Add Cost Center
</button>
<div *ngFor="let formGroup of formArray.controls; index as i; first as isFirst">
    <ng-container [formGroup]="getFormGroupFromAbstractControl(formGroup)">
        <div class="row controls">
            <gtn-input class="col-md-3 col-sm-6" formControlName="name" label="Name"></gtn-input>
            <div class="col-md-1 col-sm-6">
                <a href="javascript:void(0)" matTooltip="Remove cost center">
                    <mat-icon (click)="onRemoveCostCenterClicked(getFormGroupFromAbstractControl(formGroup),i)">delete
                    </mat-icon>
                </a>
            </div>
        </div>
    </ng-container>
</div>
