import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { ConfirmActionTypesEnum } from 'app/nexus-shared/domain/documents/enums/confirm-action-types.enum';
import { DocumentsHelper } from 'app/nexus-core/helpers/documents.helper';

@Component({
    selector: 'gtn-documents-confirm-modal',
    templateUrl: './documents-confirm-modal.component.html',
    styleUrls: ['./documents-confirm-modal.component.scss']
})
export class DocumentsConfirmModalComponent extends BaseComponent implements OnInit {

    @Input() confirmActionsEnum: ConfirmActionTypesEnum;
    @Input() confirmActionEntities: string[];

    @Output() confirmClick: EventEmitter<void> = new EventEmitter();
    @Output() cancelClick: EventEmitter<void> = new EventEmitter();

    confirmActionHeader: string;
    confirmActionText: string;
    confirmActionWarning: string;
    isConfirmDisabled: boolean = false;

    ngOnInit() {
        this.confirmActionHeader = DocumentsHelper.getConfirmActionHeader(this.confirmActionsEnum);
        this.confirmActionText = DocumentsHelper.getConfirmActionText(this.confirmActionsEnum);
        this.confirmActionWarning = DocumentsHelper.getConfirmActionWarning(this.confirmActionsEnum);
    }

    onOkClicked(): void {
        this.isConfirmDisabled = true;
        this.confirmClick.emit();
    }

    onCancelClicked(): void {
        this.cancelClick.emit();
    }
}
