import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { DateHelper } from 'app/nexus-core';
import { BaseDynamicComponentModel } from 'app/nexus-shared/components/dynamic-component/base-dynamic-component.model';
import { NexusAnimations } from 'app/nexus-shared/animations';

@Component({
    selector: 'gtn-calendar-year-view',
    templateUrl: './calendar-year-view.component.html',
    styleUrls: ['./calendar-year-view.component.scss'],
    animations: [
        NexusAnimations.fadeIn
    ]
})
export class CalendarYearViewComponent extends BaseComponent implements OnInit {
    @Input() date: Date;
    @Input() endDate: Date;
    @Input() year: number;
    @Input() dynamicDayComponent: BaseDynamicComponentModel<any>;
    @Input() template: TemplateRef<any>;
    @Input() weekDayStart: number = 0;

    months: string[];
    displayedMonths: number[] = [];

    ngOnInit() {
        this.months = DateHelper.getMonths();
        if (!this.date) {
            this.year = new Date().getFullYear();
        }
        this.setDisplayedMonths();
    }

    private setDisplayedMonths(): void {
        let offset = 0;
        for (let i = 0; i < 12; i++) {
            setTimeout(() => {
                this.displayedMonths.push(i);
            }, offset);
            offset += 10;
        }
    }
}
