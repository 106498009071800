export enum DocumentStatusesEnum {
    Unknown = 0,
    Pending = 5,
    Active = 10,
    CheckedOut = 20,
    ContainsError = 25,
    Inactive = 30,
    UploadFailed = 100,
    DownloadFailed = 110
}
