<form [formGroup]="formGroupRef">
    <div class="flex-row-start">
        <gtn-dropdown class="medium-column mr-3" label="User input type"
            [formControlName]="userInputTypeFormControlName"
            [idOnly]="true"
            [options]="userInputTypes"></gtn-dropdown>
        <gtn-questionnaire-dropdown-option-type-select
            *ngIf="formGroupRef.get(userInputTypeFormControlName)?.value === userInputTypesEnum.DropDown"
            label="Dropdown type" formControlName="dropdownTypeId"></gtn-questionnaire-dropdown-option-type-select>
    </div>
</form>
