import { Component, Input, OnInit } from '@angular/core';
import { BaseFormComponent } from 'app/nexus-shared/components/base-component/base-form.component';
import { IndividualRelationshipModel } from 'app/nexus-shared/domain/contacts/models/individual-relationship.model';
import { SelectListInterface } from 'app/nexus-shared/interfaces';
import { DateHelper, EnumHelper } from 'app/nexus-core';
import { IndividualRelationshipTypesEnum } from 'app/nexus-shared/domain/contacts/enums/individual-relationship-types.enum';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { MarriageEndTypesEnum } from 'app/nexus-shared/enums/marriage-end-types.enum';

@Component({
    selector: 'gtn-individual-relationship-form',
    templateUrl: './individual-relationship-form.component.html',
    styleUrls: ['./individual-relationship-form.component.scss']
})
export class IndividualRelationshipFormComponent extends BaseFormComponent<IndividualRelationshipModel> implements OnInit {
    @Input() isControllingCompany: boolean = false;
    public spouseRelationshipType: IndividualRelationshipTypesEnum;
    individualRelationshipTypeOptions: SelectListInterface[];
    marriageEndTypeOptions: SelectListInterface[];

    constructor() {
        super();
    }

    protected initUIControls() {
        let selectList = EnumHelper.convertToSelectList(IndividualRelationshipTypesEnum, false);
        this.marriageEndTypeOptions = EnumHelper.convertToSelectList(MarriageEndTypesEnum, false, true, null,false);
        if (!this.isControllingCompany) {
            selectList = selectList.filter(t => t.id !== IndividualRelationshipTypesEnum.ClientSupport);
        }
        this.individualRelationshipTypeOptions = selectList;
        this.spouseRelationshipType = IndividualRelationshipTypesEnum.Spouse;
    }

    protected initFormCustomizations(): void {
        if (!this.value?.individualRelationshipKey) {
            this.formGroupRef.get(ObjectHelper.nameOf<IndividualRelationshipModel>('effectiveStartDate')).setValue(DateHelper.convertToUtcDate(new Date()));
        }
    }
}
