import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ValidationModel } from 'app/nexus-shared/models';
import { FormControlHelper } from 'app/nexus-core';
import { SimpleChangesTyped } from 'app/nexus-shared/models/simple-changes-typed.type';

@Component({
    selector: 'gtn-validation',
    templateUrl: './validation.component.html',
    styleUrls: ['./validation.component.scss']
})
export class ValidationComponent implements OnChanges {
    @Input() isReactiveForm: boolean = true;
    @Input() validationModels: ValidationModel[];
    @Output() errorClick: EventEmitter<ValidationModel> = new EventEmitter();

    isPopoverVisible: boolean = false;

    ngOnChanges(changes: SimpleChangesTyped<this>) {
        if (changes?.validationModels?.currentValue.length === 0 && this.isPopoverVisible) {
            this.onIndicatorClicked();
        }
    }

    onErrorClick(model: ValidationModel) {
        if (this.isReactiveForm && model?.field) {
            FormControlHelper.setControlFocus(model.field);
        }

        this.onIndicatorClicked();
        this.errorClick.emit(model);
    }

    onIndicatorClicked(): void {
        this.isPopoverVisible = !this.isPopoverVisible;
    }
}
