import { Injectable } from '@angular/core';
import { BaseService } from 'app/nexus-core/services/base-service.directive';
import { Observable, of, Subject } from 'rxjs';
import { DocumentProcessModel } from 'app/nexus-shared/domain/documents/models/document-view-models/document-process.model';
import { DocumentModel } from 'app/nexus-shared/domain/documents/models/document.model';
import { DocumentProcessStatusesEnum } from 'app/nexus-shared/domain/documents/enums/document-process-statuses.enum';
import { DocumentProcessTypesEnum } from 'app/nexus-shared/domain/documents/enums/document-process-types.enum';
import { FileDocumentModel } from 'app/nexus-shared/domain/documents/models/document-view-models/file-document.model';
import { Guid } from 'guid-typescript';
import { catchError, concatMap, delay, finalize, map, tap } from 'rxjs/operators';
import { EnumHelper, FileHelper } from 'app/nexus-core/helpers';
import { ErrorResponseModel } from 'app/nexus-shared/models/error-response.model';
import { FileBlobHttpResponseModel, StringConstants, ValidationModel } from 'app/nexus-shared';
import { DocumentSearchModel } from 'app/nexus-shared/domain/documents/models/document-search.model';
import { DocumentStatusesEnum } from 'app/nexus-shared/domain/documents/enums/document-statuses.enum';
import { VirusScanErrorsEnum } from 'app/nexus-shared/domain/documents/enums/virus-scan-errors.enum';
import { AreaTypesEnum } from 'app/nexus-shared/domain/documents/enums/area-types.enum';
import { IDocumentServiceInterface } from 'app/nexus-core/services/domain/documents/idocument-service.interface';
import { IFolderServiceInterface } from 'app/nexus-core/services/domain/documents/ifolder-service.interface';
import { FolderModel } from 'app/nexus-shared/domain/documents/models/folder.model';
import { FileViewModel } from 'app/nexus-shared/components/controls/shared/models/file-view.model';
import { DocumentsHelper } from 'app/nexus-core/helpers/documents.helper';
import { DocumentDeleteModel } from 'app/nexus-shared/domain/documents/models/document-delete.model';
import { IDocumentFolderServiceInterface } from 'app/nexus-core/services/domain/documents/idocument-folder-service.interface';
import { DocumentMoveModel } from 'app/nexus-shared/domain/documents/models/document-move.model';
import { FolderMoveModel } from 'app/nexus-shared/domain/documents/models/folder-move.model';
import { FolderSearchModel } from 'app/nexus-shared/domain/documents/models/folder-search.model';

@Injectable()
export class DocumentProcessService extends BaseService {

    public documentService: IDocumentServiceInterface;
    public folderService: IFolderServiceInterface;
    public documentFolderService: IDocumentFolderServiceInterface;

    documentProcessEvent$: Subject<DocumentProcessModel> = new Subject();
    documentGridEvent$: Subject<DocumentModel> = new Subject();
    documentsInVirusScan: DocumentProcessModel[] = [];
    virusScanUpdateInProgress: boolean = false;
    browserOpenQueue$: Subject<FileBlobHttpResponseModel> = new Subject<FileBlobHttpResponseModel>();

    constructor() {
        super();
        if (this.documentService === null) {
            throw new Error('You need to instantiate a document service');
        }

        if (this.folderService === null) {
            throw new Error('You need to instantiate a folder service');
        }

        this.subscriptions.add(this.browserOpenQueue$.pipe(
            concatMap((document) => {
                return this.openDownloadLink(document);
            })
        ).subscribe());
    }

    // document actions

    getDocument(documentKey: string): Observable<DocumentModel> {
        return this.documentService.get(documentKey);
    }

    createDocuments(documents: FileDocumentModel[]): void {
        const documentProcessModels = documents.map(x => new DocumentProcessModel(x.document, x.file));
        documentProcessModels.forEach(documentProcess => {
            documentProcess.processStatus = DocumentProcessStatusesEnum.Processing;
            documentProcess.processType = DocumentProcessTypesEnum.Creating;
            documentProcess.document.documentKey = Guid.create().toString();
            this.documentProcessEvent$.next(documentProcess);
            this.documentService.create(documentProcess).subscribe(dp => {
                documentProcess = dp;
                this.documentProcessEvent$.next(documentProcess);
                if (documentProcess.processStatus === DocumentProcessStatusesEnum.VirusScanning) {
                    this.documentsInVirusScan.push(documentProcess);
                    this.documentGridEvent$.next(documentProcess.document);
                    this.getVirusScanStatus();
                }
            }, err => {
                documentProcess.processStatus = DocumentProcessStatusesEnum.Error;
                documentProcess.errorMessage = DocumentProcessService.getErrorMessage(err);
                this.documentProcessEvent$.next(documentProcess);
            });
        });
    }

    downloadHistoricalDocument(document: DocumentModel, historicalKey: string): Observable<any> {
        const documentProcess = new DocumentProcessModel(document);
        this.documentProcessEvent$.next(documentProcess);
        return this.documentService.downloadHistorical(document.documentKey, historicalKey).pipe(finalize(() => {
            this.documentProcessEvent$.next(documentProcess);
        }), tap(download => {
            FileHelper.openFile(download.blob, download.fileName);
            documentProcess.processStatus = DocumentProcessStatusesEnum.Complete;
        }), catchError(err => {
            documentProcess.processStatus = DocumentProcessStatusesEnum.Error;
            documentProcess.errorMessage = DocumentProcessService.getErrorMessage(err);
            return err;
        }));
    }

    downloadDocuments(documents: DocumentModel[]): void {
        const documentProcessModels = documents.map(x => new DocumentProcessModel(x));
        documentProcessModels.forEach(documentProcess => {
            this.downloadDocument(documentProcess);
        });
    }

    previewDocument(document: DocumentModel): Observable<FileViewModel> {
        return this.documentService.preview(document.documentKey).pipe(map(res => {
            const filePreview = new FileViewModel();
            filePreview.blobSrc = res;
            filePreview.isBlobSrc = true;
            filePreview.name = document.name;
            filePreview.type = DocumentsHelper.getPreviewFileType(document.file.extension);
            filePreview.iconClass = DocumentsHelper.getFileTypeIcon(document.file.extension);
            return filePreview;
        }));
    }

    deleteDocument(document: DocumentDeleteModel): Observable<boolean> {
        return this.documentService.delete(document);
    }

    validateCreate(documentModel: DocumentModel): Observable<boolean> {
        return this.documentService.validateCreate(documentModel);
    }

    validateUpdate(documentModel: DocumentModel): Observable<boolean> {
        return this.documentService.validateUpdate(documentModel);
    }

    updateDocumentMetadata(document: DocumentModel): Observable<boolean> {
        return this.documentService.updateMetadata(document);
    }

    uploadDocument(document: FileDocumentModel): void {
        let documentProcess = new DocumentProcessModel(document.document, document.file);
        documentProcess.processStatus = DocumentProcessStatusesEnum.Processing;
        documentProcess.processType = DocumentProcessTypesEnum.Uploading;
        this.documentProcessEvent$.next(documentProcess);
        this.documentService.update(documentProcess).subscribe(dp => {
            documentProcess = dp;
            this.documentProcessEvent$.next(documentProcess);
            if (documentProcess.processStatus === DocumentProcessStatusesEnum.VirusScanning) {
                this.documentsInVirusScan.push(documentProcess);
                this.documentGridEvent$.next(documentProcess.document);
                this.getVirusScanStatus();
            }
        }, err => {
            documentProcess.processStatus = DocumentProcessStatusesEnum.Error;
            documentProcess.errorMessage = DocumentProcessService.getErrorMessage(err);
            this.documentProcessEvent$.next(documentProcess);
            this.documentGridEvent$.next(documentProcess.document);
        });
    }

    getVirusScanStatus(count: number = 0): void {
        if (this.documentsInVirusScan.filter(x => x.processStatus === DocumentProcessStatusesEnum.VirusScanning)?.length && !this.virusScanUpdateInProgress && count < 50) {
            this.virusScanUpdateInProgress = true;
            setTimeout(() => {
                count++;
                const searchModel = <DocumentSearchModel>{ includeIndividual: true, includeCompany: true, areaTypes:
                    [AreaTypesEnum.Clients, AreaTypesEnum.FileTemplates, AreaTypesEnum.FolderTemplates, AreaTypesEnum.Personal, AreaTypesEnum.Resources,
                        AreaTypesEnum.Solutions, AreaTypesEnum.Tax, AreaTypesEnum.Teams, AreaTypesEnum.Training
                    ]
                };
                searchModel.documentKeys = this.documentsInVirusScan.filter(x => x.processStatus === DocumentProcessStatusesEnum.VirusScanning).map(x => x.document.documentKey);
                this.documentService.search(searchModel).subscribe(res => {
                    this.virusScanUpdateInProgress = false;
                    res.forEach(x => {
                        const documentProcess = this.documentsInVirusScan.find(y => x.documentKey === y.document.documentKey);
                        if (documentProcess && x.isVirusScanned) {
                            if (x.status === DocumentStatusesEnum.ContainsError) {
                                documentProcess.processStatus = DocumentProcessStatusesEnum.Error;
                                documentProcess.errorMessage = `Virus scan failed - ${EnumHelper.getDisplayName(VirusScanErrorsEnum, x.virusScanError)}`;
                            } else if (x.status === DocumentStatusesEnum.UploadFailed) {
                                documentProcess.processStatus = DocumentProcessStatusesEnum.Error;
                                documentProcess.errorMessage = `Upload Failed`;
                            } else {
                                documentProcess.processStatus = DocumentProcessStatusesEnum.Complete;
                            }

                            this.documentGridEvent$.next(x);
                            this.documentProcessEvent$.next(documentProcess);
                        }
                    });
                    this.documentsInVirusScan = this.documentsInVirusScan.filter(x => x.processStatus === DocumentProcessStatusesEnum.VirusScanning);
                    this.getVirusScanStatus(count);
                });
            }, DocumentProcessService.getTimeoutDuration(count));
        }
    }

    //folder actions

    getFolder(key: string): Observable<FolderModel> {
        return this.folderService.get(key);
    }

    searchFolders(searchModel: FolderSearchModel): Observable<FolderModel[]> {
        return this.folderService.search(searchModel);
    }

    createFolder(folder: FolderModel): Observable<string> {
        return this.folderService.create(folder);
    }

    updateFolder(folder: FolderModel): Observable<boolean> {
        return this.folderService.update(folder);
    }

    //document folder actions

    moveDocument(model: DocumentMoveModel): Observable<boolean> {
        return this.documentFolderService.moveDocument(model);
    }

    moveFolder(model: FolderMoveModel): Observable<boolean> {
        return this.documentFolderService.moveFolder(model);
    }

    deleteFolder(folderKey: string): Observable<boolean> {
        return this.documentFolderService.deleteFolder(folderKey);
    }

    private downloadDocument(documentProcess: DocumentProcessModel): void {
        documentProcess.processStatus = DocumentProcessStatusesEnum.VirusScanning;
        documentProcess.processType = DocumentProcessTypesEnum.Downloading;
        this.documentProcessEvent$.next(documentProcess);
        this.documentService.download(documentProcess.document.documentKey).pipe(finalize(() => {
            this.documentProcessEvent$.next(documentProcess);
            this.documentService.get(documentProcess.document.documentKey).subscribe(document => {
                documentProcess.document = new DocumentModel(document);
                this.documentGridEvent$.next(documentProcess.document);
            });
        })).subscribe(download => {
            this.browserOpenQueue$.next(download);
            documentProcess.processStatus = DocumentProcessStatusesEnum.Complete;
        }, err => {
            documentProcess.processStatus = DocumentProcessStatusesEnum.Error;
            documentProcess.errorMessage = DocumentProcessService.getErrorMessage(err);
        });
    }

    private openDownloadLink(download: FileBlobHttpResponseModel): Observable<boolean> {
        return of(true).pipe(tap(_ => {
            FileHelper.openFile(download.blob, download.fileName);
        }), delay(200));
    }

    private static getErrorMessage(error: ErrorResponseModel): string {
        let errorMessage: string;
        if (error?.isValidationError) {
            const validationErrors = error.dataBag as ValidationModel[];
            errorMessage = validationErrors.map(x => x.message).join('\n \n');
        } else {
            return StringConstants.messages.serverError;
        }

        return errorMessage;
    }

    private static getTimeoutDuration(i): number {
        if (i <= 1) {
            return 5000;
        } else if (i <= 3) {
            return 10000;
        } else {
            return 15000;
        }
    }
}
