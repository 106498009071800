export enum AreaTypesEnum {
    Unknown = 0,
    Clients = 10,
    Teams = 20,
    Personal = 30,
    FileTemplates = 40,
    FolderTemplates = 45,
    Resources = 50,
    Solutions = 60,
    Training = 70,
    DocumentTranslation = 80,
    Tax = 1000
}
