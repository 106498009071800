<div [style.height.px]="height">
    <div class="dashboard-header-row">
        <div class="dashboard-header">
            <div>
                <span><i class="fa-solid fa-folder-heart"></i></span>
                Favorites
            </div>
            <div class="subtext">{{ folderFavorites?.length }} folders</div>
        </div>
    </div>
    <div *ngIf="folderFavorites?.length === 0">
        You have no favorite folders; right click on any folder to add as a favorite.
    </div>
    <div class="dashboard-table-container">
        <table class="dashboard-table">
            <ng-container *ngFor="let folderFavorite of folderFavorites;">
                <tr class="dashboard-table-row">
                    <td>
                        <span><i class="fas fa-folder dashboard-table-icon"></i></span>
                    </td>
                    <td>
                        <div>
                            {{ folderFavorite.folder.name }}
                        </div>
                        <div class="subtext">
                            {{ folderFavorite.folder.areaType | enum : areaTypesEnum }}
                        </div>
                    </td>
                    <td>
                        <span
                            *ngIf="folderFavorite.folder.clientFolder?.companyKey">{{ folderFavorite.folder.clientFolder?.companyName }}</span>
                        <span
                            *ngIf="folderFavorite.folder.clientFolder?.companyKey && folderFavorite?.folder.clientFolder?.individualKey"> - </span>
                        <span
                            *ngIf="folderFavorite.folder.clientFolder?.individualKey">{{ folderFavorite?.folder.clientFolder?.individualLastName }}
                            , {{ folderFavorite?.folder.clientFolder?.individualFirstName }}</span>
                    </td>
                    <td>
                        <button (click)="onFolderClicked(folderFavorite)" class="mt-2" color="default"
                            mat-mini-fab matTooltip="Go To Folder">
                            <mat-icon>arrow_forward</mat-icon>
                        </button>
                    </td>
                </tr>
            </ng-container>
        </table>
    </div>
</div>
