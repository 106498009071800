import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { BaseFormComponent } from 'app/nexus-shared/components/base-component/base-form.component';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { BaseFormModel } from 'app/nexus-shared/models/base-form.model';
import { PhoneNumberModel } from 'app/nexus-shared/models/phone-number.model';
import { IndividualModel } from 'app/nexus-shared/domain/contacts/models';
import { SortingHelper } from 'app/nexus-core';

@Component({
    selector: 'gtn-phone-number-subform',
    templateUrl: './phone-number-subform.component.html',
    styleUrls: ['./phone-number-subform.component.scss']
})
export class PhoneNumberSubformComponent extends BaseFormComponent<PhoneNumberModel[]> implements OnInit {
    @Input() type: string = 'individual' || 'company';
    @Input() formArray: UntypedFormArray;
    @Input() isPhoneNumberSubFormArrayModal: boolean = false;

    constructor() {
        super();
    }

    ngOnInit(): void {
        if (!this.formArray?.length) {
            this.onPhoneNumberAddClicked(false);
        }
        this.sortPhoneNumbers();
    }

    onPhoneNumberAddClicked(markAsDirty: boolean = true): void {
        const formConfiguration = new BaseFormModel();
        const newFormGroup = new UntypedFormGroup({});
        this.buildFormFromTemplate(new PhoneNumberModel(), newFormGroup, formConfiguration);
        this.formArray.push(newFormGroup);
        if (markAsDirty) {
            this.formArray.markAsDirty();
            this.formArray.updateValueAndValidity();
        }
    }

    onPhoneNumberRemoveClicked(i): void {
        this.formArray.parent.controls[ObjectHelper.nameOf<IndividualModel>('phoneNumbers')].removeAt(i);
        this.formArray.markAsDirty();
        this.formArray.updateValueAndValidity();
    }

    private sortPhoneNumbers(): void {
        this.formArray?.controls?.sort((a, b) => {
            return SortingHelper.sortByPropertyComparerDesc(a.value, b.value, ObjectHelper.nameOf<PhoneNumberModel>('isPrimary'));
        });
    }
}

