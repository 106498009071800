import { Component, Input, OnInit } from '@angular/core';
import { BaseEntryComponent } from 'app/nexus-shared/components/base-component/base-entry.component';

@Component({
    selector: 'gtn-template-entry-page',
    templateUrl: './template-entry-page.component.html',
    styleUrls: ['./template-entry-page.component.scss']
})
export class TemplateEntryPageComponent<T> extends BaseEntryComponent<T> implements OnInit {
    @Input() pageTitle: string = null;
    @Input() hideActionButtons: boolean = false;
    @Input() cancelText: string = 'Cancel';
    @Input() alignButtonsRight: boolean = false;

    showCancel = false;

    ngOnInit(): void {
        this.showCancel = !!this.cancelClick?.observers?.length ?? false;
    }

    onSaveClicked(isPendingChangesSave: boolean = false): void {
        if (this.pendingChanges.showPendingChanges) {
            this.pendingChanges.showPendingChanges = false;
            this.pendingChangesChange.emit(this.pendingChanges);
        }

        //  NOTE: isPendingChangesSave was added due isSaving being set to true on second bypass using the pending changes guard.
        //        If we add FE validation at some point we will need to pass this into validateEntry to ensure the valid flag is set correctly.
        //  Why?  We are using execute save to pass a flag to the child component to tell it at the page level to save.
        //        This is specifically for the readonly-edit cards.
        if (isPendingChangesSave || this.validateEntry()) {
            this.saveClick.emit();
        }
    }

    onCancelClicked(): void {
        this.cancelClick.emit();
    }
}
