<div *ngIf="params?.formGroupRef" [formGroup]="params.formGroupRef">
    <gtn-input *ngIf="params"
        [canShowValidation]="true"
        [formControl]="formControl"
        [ngClass]="{'in-popup': params.isPopup, 'inline': params.isInline, 'customized-theme': params.isCustomizedTheme}"
        [readonly]="params.isReadonly"
        [style.width.px]="params.width"
        [textAlign]="textAlign"
        [type]="params.type">
    </gtn-input>
</div>
<div *ngIf="!params?.formGroupRef">
    <gtn-input *ngIf="params"
        [(ngModel)]="params.value"
        [ngClass]="{'in-popup': params.isPopup, 'inline': params.isInline, 'customized-theme': params.isCustomizedTheme}"
        [readonly]="params.isReadonly"
        [style.width.px]="params.width"
        [textAlign]="textAlign"
        [type]="params.type">
    </gtn-input>
</div>
