import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, Renderer2 } from '@angular/core';
import { ResizeService } from 'app/nexus-core';
import { ResizeDirective } from './gtn-resize.directive';

@Directive({
    selector: '[gtnHeight]'
})
export class GtnHeightDirective extends ResizeDirective implements AfterViewInit, OnDestroy {

    public static ResizeDebounceTimeInMs = 10;

    /**
     * Pass 'auto' for auto-height (default)
     * Pass number of pixels for fixed pixel height
     * Pass string with px or vh defined, such as '50vh' or '300px'
     */
    @Input() gtnHeight: number | string = 'auto';

    /**
     * Will ensure that the height never goes below the minHeight, even with the height set to 'auto'.
     * Minimum height of pixels
     */
    @Input() minHeight: number;

    /**
     * Allow the consumer to offset the height.
     * Bottom offset in pixels
     */
    @Input() bottomOffset: number;

    private readonly minBottomOffset: number = 30; // padding bottom for .main-content

    private domElement: HTMLElement;

    constructor(
        protected resizeService: ResizeService,
        private elementRef: ElementRef,
        private renderer: Renderer2
    ) {
        super(resizeService, elementRef);

        this.domElement = this.elementRef.nativeElement as HTMLElement;
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    protected handleResize() {
        this.setHeight();
    }

    private setHeight() {
        if (this.domElement?.style) {

            let height: string;

            if (this.gtnHeight === 'auto' || !this.gtnHeight) {
                const topOffset = this.calcTopOffset();

                // should never have topOffset of 0, if it is then wait another cycle for other components to be painted
                if (topOffset) {
                    const windowHeight = window?.innerHeight,
                        bottomOffset = this.bottomOffset ? this.bottomOffset + this.minBottomOffset : this.minBottomOffset;
                    height = `${windowHeight - topOffset - bottomOffset}px`;
                }
            } else {
                height = typeof this.gtnHeight === 'string' && (this.gtnHeight.indexOf('vh') > -1 || this.gtnHeight.indexOf('px') > -1)
                    ? this.gtnHeight
                    : `${this.gtnHeight}px`;
            }

            // if minHeight is defined and it is greater than calculated height, then set height
            if (this.minHeight) {
                const minHeightInPixels = this.getHeightInPixels(this.minHeight);
                const heightInPixels = this.getHeightInPixels(height);

                if (minHeightInPixels > heightInPixels) {
                    height = `${this.minHeight}px`;
                }
            }

            if (height && height !== '0px' && !height?.startsWith('-')) {
                this.renderer.setStyle(this.domElement, 'height', height);
            }
        }
    }

    private calcTopOffset(): number {
        if (this.domElement?.getBoundingClientRect) {
            const rect = this.domElement.getBoundingClientRect();
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            return rect.top + scrollTop;
        }

        return 0;
    }

    private getHeightInPixels(heightInVhOrPixels: number | string): number {
        if (typeof heightInVhOrPixels === 'number') {
            return heightInVhOrPixels;
        }

        // only px and vh is supported
        if (typeof heightInVhOrPixels === 'string' && (heightInVhOrPixels.indexOf('vh') > -1 || heightInVhOrPixels.indexOf('px') > -1)) {
            return heightInVhOrPixels.indexOf('vh') > -1
                ? window.innerHeight * +heightInVhOrPixels.replace('vh', '') / 100
                : +heightInVhOrPixels.replace('px', '');
        }

        return -1;
    }
}
