<mat-form-field [appearance]="appearance"
    [ngClass]="{'gtn-disabled' : disabled, 'gtn-read-only' : readonly, 'gtn-control-show-error': !formControl?.valid && !formControl?.pending && canShowValidation}">
    <mat-label>{{label}}</mat-label>
    <input *ngIf="disabled || readonly" type="text" #element matInput gtnDisableAutofill
        [formControl]="autocompleteControl">
    <input *ngIf="!disabled && !readonly" type="text" #element matInput gtnDisableAutofill
        [formControl]="autocompleteControl" [matAutocomplete]="auto">
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
        (optionSelected)="onOptionChange($event.option.value)">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
            {{option}}
        </mat-option>
    </mat-autocomplete>
    <div class="gtn-control-error-message error-message" *ngIf="!formControl?.valid && !formControl?.pending && canShowValidation">
        <span *ngFor="let msg of getErrorMessage()">{{msg}}</span></div>
</mat-form-field>
