import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AuthenticatedUserService } from 'app/nexus-core/services/authenticated-user.service';
import { BaseEntryComponent } from 'app/nexus-shared/components/base-component/base-entry.component';
import { DocumentModel } from 'app/nexus-shared/domain/documents/models/document.model';
import { DocumentDetailComponent } from 'app/nexus-shared/domain/documents/components/document/document-detail/document-detail.component';
import { FolderModel } from 'app/nexus-shared/domain/documents/models/folder.model';
import { DocumentsAuthModel } from 'app/nexus-shared/domain/documents/models/documents-auth.model';

@Component({
    selector: 'gtn-document-detail-modal',
    templateUrl: './document-detail-modal.component.html',
    styleUrls: ['./document-detail-modal.component.scss']
})
export class DocumentDetailModalComponent extends BaseEntryComponent<DocumentModel> implements OnInit {
    @Input() selectedFolder: FolderModel;
    @Input() documentsUserAuth: DocumentsAuthModel;

    @Output() navigateToClientFolder: EventEmitter<DocumentModel> = new EventEmitter();

    isVirusScanned: boolean;

    @ViewChild('documentDetail') documentDetail: DocumentDetailComponent;

    constructor(public authenticatedUserService: AuthenticatedUserService) {
        super();
    }

    ngOnInit() {
        this.isVirusScanned = this.value.isVirusScanned;
    }

    onCancelClicked(): void {
        this.cancelClick.emit();
    }

    onNavigateToFolderClicked(): void {
        this.navigateToClientFolder.emit(this.value);
    }
}
