import { DateHelper } from './date.helper';
import { EnumHelper } from 'app/nexus-core/helpers/enum.helper';

export class SortingHelper {
    static sortByPropertyComparer<T>(a: T, b: T, property: keyof T) {
        if (a[property] < b[property]) {
            return -1;
        } else if (a[property] > b[property]) {
            return 1;
        }

        return 0;
    }

    static sortByPropertyComparerDesc<T>(a: T, b: T, property: keyof T) {
        if (a[property] > b[property]) {
            return -1;
        } else if (a[property] < b[property]) {
            return 1;
        }

        return 0;
    }

    static sortByDateComparer(a: Date, b: Date) {
        if (DateHelper.lessThan(a, b)) {
            return -1;
        } else if (DateHelper.greaterThan(a, b)) {
            return 1;
        }

        return 0;
    }

    static sortByDateComparerDesc(a: Date, b: Date) {
        if (DateHelper.greaterThan(a, b)) {
            return -1;
        } else if (DateHelper.lessThan(a, b)) {
            return 1;
        }

        return 0;
    }

    static sortByDatePropertyComparer(a, b, property) {
        if (DateHelper.lessThan(a[property], b[property])) {
            return -1;
        } else if (DateHelper.greaterThan(a[property], b[property])) {
            return 1;
        }

        return 0;
    }

    static sortEnumAlphabetically(valueA: number, valueB: number, enumValues: any): number {
        const enumValueA = EnumHelper.getDisplayName(enumValues, valueA)?.toLowerCase()?.trim() ?? null;
        const enumValueB = EnumHelper.getDisplayName(enumValues, valueB)?.toLowerCase()?.trim() ?? null;

        if (enumValueA === enumValueB) {
            return 0;
        }

        return (enumValueA > enumValueB) ? 1 : -1;
    }
}

