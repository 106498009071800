import { SectionRowTypesEnum } from 'app/nexus-shared/domain/questionnaires/enums/section-row-types.enum';
import { SectionColumnModel } from 'app/nexus-shared/domain/questionnaires/models/section-column.model';
import { Guid } from 'guid-typescript';

export class SectionRowModel {
    key: string;
    type: SectionRowTypesEnum;
    order: number;
    columns: SectionColumnModel[] = [];
    text: string;

    constructor(model: SectionRowModel = null) {
        this.key = model?.key ?? Guid.create().toString();
        this.type = model?.type ?? null;
        this.order = model?.order ?? null;
        this.columns = model?.columns?.length ? model.columns.map(x => new SectionColumnModel(x)) : [];
        this.text = model?.text ?? null;
    }
}
