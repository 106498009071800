import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FileViewModel } from 'app/nexus-shared/components/controls/shared/models/file-view.model';
import { PreviewFileTypesEnum } from 'app/nexus-shared/components/controls/shared/enums/preview-file-types.enum';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { ValidationModel } from 'app/nexus-shared/models';
import { PDFTextSearchModel } from 'app/nexus-shared/models/pdf-text-search.model';
import { PDFTextSearchDirectionEnum } from 'app/nexus-shared/enums/pdf-text-search-direction.enum';
import { PDFTextSearchMatchDetailModel } from 'app/nexus-shared/models/pdf-text-search-match-detail.model';
import { InputComponent } from '../input';

@Component({
    selector: 'gtn-file-view',
    templateUrl: './file-view.component.html',
    styleUrls: ['./file-view.component.scss']
})
export class FileViewComponent extends BaseComponent implements OnInit {
    @Input() enableDownload: boolean = false;
    @Input() enablePDFOutline: boolean = false;
    @Input() enablePDFThumbnails: boolean = false;
    @Input() enablePrinting: boolean = false;
    @Input() enableSearch: boolean = false;
    @Input() file: FileViewModel;
    @Input() isAgreement: boolean = false;
    @Input() signatureValidationErrors: ValidationModel[];

    @Output() close: EventEmitter<void> = new EventEmitter<void>();
    @Output() download: EventEmitter<void> = new EventEmitter<void>();
    @Output() fileLoadComplete: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild('searchInput') set searchInputField(element) {
        this.searchInput = element;

        if (this.searchInput) {
            this.setSearchFocus();
        }
    };

    filePrintData: string = null;
    fileTypesEnum = PreviewFileTypesEnum;
    isDownload: boolean = false;
    isPadding: boolean = true;
    searchMatchDetail: PDFTextSearchMatchDetailModel = null;
    searchModel: PDFTextSearchModel = null;
    searchText: string = '';
    showSearch: boolean = false;

    private searchInput: InputComponent = null;

    ngOnInit(): void {
        this.isDownload = this.enableDownload && !!this.download.observers?.length;
        this.isPadding = this.file.type !== PreviewFileTypesEnum.PDF;

        if (this.file?.type === PreviewFileTypesEnum.PDF) {
            this.initPDFKeyboardEvents();
        }
    }

    onCloseClicked(): void {
        this.close.emit();
    }

    onDownloadClicked(): void {
        this.download.emit();
    }

    onFileLoadComplete(): void {
        this.fileLoadComplete.emit();
    }

    onPrintDataUpdated($event: string): void {
        // break out of change detection cycle
        setTimeout(() => {
            this.filePrintData = $event;
        }, 0);
    }

    onSearchIconClicked(): void {
        this.showSearch = !this.showSearch;

        if (this.showSearch) {
            this.searchModel = this.getUpdatedSearchText(this.searchText);
        }
        else {
            this.resetSearch();
        }
    }

    onSearchTextChanged($event): void {
        if (typeof $event === 'string') {
            this.searchModel = this.getUpdatedSearchText($event);
            this.searchText = $event;
        }
    }

    onSearchPreviousClicked() {
        this.searchModel = this.getUpdatedSearchText(this.searchModel?.text, PDFTextSearchDirectionEnum.Previous);
    }

    onSearchNextClicked() {
        this.searchModel = this.getUpdatedSearchText(this.searchModel?.text, PDFTextSearchDirectionEnum.Next);
    }

    onSearchUpdated($event: PDFTextSearchMatchDetailModel) {
        if ($event.currentMatchId >= 0 && $event.matchCount > 0) {
            this.searchMatchDetail = $event;
        }
        else {
            this.searchMatchDetail = null;
        }
    }

    printFile() {
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = this.filePrintData;

        document.body.appendChild(iframe);

        iframe.addEventListener("load", () => {
            iframe.contentWindow.print();
        });
    }

    private getUpdatedSearchText(text: string, direction = PDFTextSearchDirectionEnum.Next): PDFTextSearchModel {
        const searchText = new PDFTextSearchModel();

        searchText.text = text;
        searchText.searchDirection = direction;

        return searchText;
    }

    private handleCtrlFEvent() {
        this.searchInput?.setFocus();

        if (!this.showSearch) {
            this.showSearch = true;
            this.searchModel = this.getUpdatedSearchText(this.searchText);
        }
    }

    private handleEscEvent() {
        this.showSearch = false;
        this.resetSearch();
    }

    private initPDFKeyboardEvents() {
        document.addEventListener('keydown', (event: KeyboardEvent) => {
            if (this.enableSearch && event.ctrlKey && event.key === 'f') {
                event.preventDefault();
                this.handleCtrlFEvent();
            }

            if (this.showSearch && event.key === 'Escape') {
                event.preventDefault();
                this.handleEscEvent();
            }
        });
    }

    private resetSearch() {
        this.searchMatchDetail = null;
        this.searchModel = this.getUpdatedSearchText('');
    }

    private setSearchFocus() {
        // break out of change detection cycle
        setTimeout(() => {
            this.searchInput?.setFocus();
        }, 0);
    }
}
