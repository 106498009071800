import { CompanyTypesEnum } from 'app/nexus-shared/domain/contacts/enums/company-types.enum';
import { ContactIntegrationModel } from 'app/nexus-shared/domain/contacts/models/contact-integration.model';

export class CompanySearchModel {
    searchTerm?: string;
    emailDomain?: string;
    companyTypes: CompanyTypesEnum[];
    myGTNPortalCompanyKey?: string;
    isFullModel?: boolean;
    includeCompanyTypes?: boolean;
    integrationValues?: ContactIntegrationModel[];
}
