import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { CardAppearanceEnum } from 'app/nexus-shared/enums/card-appearance.enum';

@Component({
    selector: 'gtn-template-card',
    templateUrl: './template-card.component.html',
    styleUrls: ['./template-card.component.scss']
})
export class TemplateCardComponent extends BaseComponent  {
    @Input() mode: 'read-only' | 'standard' = 'standard';
    @Input() appearance: CardAppearanceEnum = CardAppearanceEnum.unset;
    @Input() isFillContentHeight: boolean = false;
    @Input() showHeader: boolean = false;

    @ViewChild('headerContent') headerContent: ElementRef;

    appearances = CardAppearanceEnum;
}
