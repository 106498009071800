<div class="document-progress-container">
    <ng-container *ngFor="let documentProcess of documentsInProcess; index as i">
        <div class="document-in-progress" (click)="navigateToFolder(documentProcess.document)">
            <div class="document-icon">
                <span *ngIf="documentProcess.processType === documentProcessTypesEnum.Downloading"><i
                    class="fas fa-down-to-line"></i></span>
                <span
                    *ngIf="documentProcess.processType === documentProcessTypesEnum.Uploading ||documentProcess.processType === documentProcessTypesEnum.Creating"><i
                    class="fas fa-up-from-line"></i></span>
            </div>
            <div class="document-data">
                <div class="document-name"> {{documentProcess.document.name}}</div>
                <div class="document-folder">{{documentProcess.folderData}}</div>
            </div>
            <div class="document-status">
                <mat-progress-spinner *ngIf="documentProcess.processStatus <= documentProcessStatusesEnum.VirusScanning"
                    [diameter]="18" mode="indeterminate"></mat-progress-spinner>
                <div *ngIf="documentProcess.processStatus === documentProcessStatusesEnum.Complete"
                    (mouseenter)="showClear = true; scanIndex = i;" (mouseleave)="showClear = false; scanIndex = null;">
                    <i *ngIf="!showClear || scanIndex !== i" class="fas fa-check success"></i>
                    <i *ngIf="showClear && scanIndex === i" (click)="onClearCompletedProcess(i)"
                        class="fas fa-times"></i>
                </div>
                <div *ngIf="documentProcess.processStatus === documentProcessStatusesEnum.Error"
                    [matTooltip]="documentProcess.errorMessage" matTooltipClass="error-tooltip"
                    (mouseenter)="showClear = true; scanIndex = i;" (mouseleave)="showClear = false; scanIndex = null;">
                    <i *ngIf="!showClear || scanIndex !== i" class="fas fa-circle-exclamation error"></i>
                    <i *ngIf="showClear && scanIndex === i" (click)="onClearCompletedProcess(i)"
                        class="fas fa-times"></i>
                </div>
            </div>
        </div>
    </ng-container>
</div>
