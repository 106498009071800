import { TaxJurisdictionModel } from 'app/nexus-shared/domain/taxes/models/tax-jurisdiction-model';
import { TaxRateModel } from 'app/nexus-shared/domain/taxes/models/tax-rate-model';
import { TaxWithholdingTypeModel } from 'app/nexus-shared/domain/taxes/models/tax-withholding-type-model';
import { LimitIncomePeriodType } from 'app/nexus-shared/domain/taxes/enums';
import { CompositeTaxComponentModel } from 'app/nexus-shared/domain/taxes/models/composite-tax-component-model';

export class TaxModel {
    taxId: number;
    name: string;
    shortName: string;
    taxJurisdictionId: number;
    taxWithholdingTypeId: number;
    displayOrder: number;
    effectiveStartDate: Date;
    effectiveEndDate?: Date;
    specializationKey: string;
    specializationTitle: string;
    compositeReplacesAllOtherTaxes: boolean;
    isLocal: boolean;
    useFinalRateTierOnly: boolean;

    taxJurisdiction: TaxJurisdictionModel;
    taxWithholdingType: TaxWithholdingTypeModel;
    taxRates: TaxRateModel[];
    rateMin?: number;
    rateMax?: number;
    compositeTaxComponents: CompositeTaxComponentModel[];
    limitIncomePeriodType: LimitIncomePeriodType;

    constructor() {
        this.name = '';
        this.shortName = '';
        this.taxJurisdictionId = null;
        this.taxWithholdingTypeId = null;
        this.displayOrder = null;
        this.effectiveEndDate = null;
        this.effectiveStartDate = null;
        this.specializationKey = '';
        this.specializationTitle = '';
        this.compositeReplacesAllOtherTaxes = null;
        this.useFinalRateTierOnly = null;

        this.taxRates = null;
    }
}
