import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CompanyService } from 'app/nexus-core/services/domain/contacts/company.service';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { CompanyModel } from 'app/nexus-shared/domain/contacts/models';
import { ApplicationsEnum } from 'app/nexus-shared/enums';
import { RegionTypesEnum } from 'app/nexus-shared/domain/contacts/enums/region-types.enum';
import { CompanyTypesEnum } from 'app/nexus-shared/domain/contacts/enums/company-types.enum';

@Component({
    selector: 'gtn-company-preview-modal',
    templateUrl: './company-preview-modal.component.html',
    styleUrls: ['./company-preview-modal.component.scss']
})
export class CompanyPreviewModalComponent extends BaseComponent implements OnInit {
    @Input() companyKey: string;
    @Input() application: ApplicationsEnum;

    @Output() cancelClick: EventEmitter<boolean> = new EventEmitter();

    company: CompanyModel;
    applicationsEnum = ApplicationsEnum;
    regionTypesEnum = RegionTypesEnum;
    companyTypesEnum = CompanyTypesEnum;

    constructor(
        private companyService: CompanyService
    ) {
        super();
    }

    ngOnInit(): void {
        this.companyService.get(this.companyKey).subscribe(company => {
            if (company) {
                this.company = new CompanyModel(company);
            }
        });
    }

    onCancelClicked(): void {
        this.cancelClick.emit();
    }
}


