import { BaseRouteApplicationConfigurationModel } from 'app/nexus-shared';
import { ContactsRoutingKeyConstants } from 'app/modules/contacts/routing/contacts-routing-key.constants';

export class ContactsRoutingIndividualsConfigurationConstants {
    static baseRoute: string = 'contacts/individuals/individual-detail';

    static readonly configurations = {
        getRoot: (): BaseRouteApplicationConfigurationModel => {
            return {
                applicationMenuId: ContactsRoutingKeyConstants.menuIds.individualDetailRoot,
                routing: {
                    routerModulePath: `individual-detail/:${ContactsRoutingKeyConstants.routeParamKeys.individual}`,
                    navigationRoute: (key: string) => [`${ContactsRoutingIndividualsConfigurationConstants.baseRoute}/${key}`]
                }
            };
        },
        getGeneral: (): BaseRouteApplicationConfigurationModel => {
            return {
                applicationMenuId: ContactsRoutingKeyConstants.menuIds.individualDetailGeneral,
                menu: {
                    title: 'General',
                    iconClass: 'fas fa-address-card',
                    order: 0,
                },
                routing: {
                    routerModulePath: 'general',
                    navigationRoute: (key: string) => [`${ContactsRoutingIndividualsConfigurationConstants.baseRoute}/${key}/general`]
                }
            };
        },
        getRelationships: (): BaseRouteApplicationConfigurationModel => {
            return {
                menu: {
                    title: 'Relationships',
                    iconClass: 'fas fa-user-friends',
                    order: 10,
                    isExpanded: true
                },
                childRoutes: [
                    ContactsRoutingIndividualsConfigurationConstants.configurations.getCompanyRelationships(),
                    ContactsRoutingIndividualsConfigurationConstants.configurations.getIndividualRelationships()
                ]
            };
        },
        getIndividualRelationships: (): BaseRouteApplicationConfigurationModel => {
            return {
                menu: {
                    title: 'Individuals',
                    iconClass: 'fas fa-user-friends',
                    order: 10,
                },
                routing: {
                    routerModulePath: 'relationships/individuals',
                    navigationRoute: (key: string) => [`${ContactsRoutingIndividualsConfigurationConstants.baseRoute}/${key}/relationships/individuals`]
                }
            };
        },
        getCompanyRelationships: (): BaseRouteApplicationConfigurationModel => {
            return {
                menu: {
                    title: 'Companies',
                    iconClass: 'far fa-buildings',
                    order: 0,
                },
                routing: {
                    routerModulePath: 'relationships/companies',
                    navigationRoute: (key: string) => [`${ContactsRoutingIndividualsConfigurationConstants.baseRoute}/${key}/relationships/companies`]
                }
            };
        }
    };

    static mainMenuItems = [
        ContactsRoutingIndividualsConfigurationConstants.configurations.getGeneral(),
        ContactsRoutingIndividualsConfigurationConstants.configurations.getRelationships()
    ];
}
