import { Pipe, PipeTransform } from '@angular/core';
import { ApplicationConfigurationService } from 'app/nexus-core';
import { ApplicationsEnum } from 'app/nexus-shared/enums';

@Pipe({
    name: 'appLogo'
})
export class ApplicationLogoPipe implements PipeTransform {

    constructor(private applicationConfigurationService: ApplicationConfigurationService) {}
    transform(value: ApplicationsEnum): string {
        return this.applicationConfigurationService.getApplicationLogo(value);
    }
}
