export class TaxDetailFormControlNames {
    public static displayOrder: string = 'displayOrder';
    public static effectiveEndDate: string = 'effectiveEndDate';
    public static effectiveStartDate: string = 'effectiveStartDate';
    public static shortName: string = 'shortName';
    public static taxWithholdingTypeId: string = 'taxWithholdingTypeId';
    public static taxJurisdictionId: string = 'taxJurisdictionId';
    public static countryId: string = 'countryId';
    public static stateId: string = 'stateId';
    public static cityId: string = 'cityId';
    public static countyId: string = 'countyId';
    public static specializationTitle: string = 'specializationTitle';
    public static specializationKey: string = 'specializationKey';
    public static compositeReplacesAllOtherTaxes: string = 'compositeReplacesAllOtherTaxes';
    public static compositeTaxComponents: string = 'compositeTaxComponents';
    public static specializationId: string = 'specializationId';
    public static limitIncomePeriodType: string = 'limitIncomePeriodType';
}

// This is a way to override the default name property on the class.
Object.defineProperty(TaxDetailFormControlNames, 'name', { value: 'name', writable: false });
