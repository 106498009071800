export enum ViewModesEnum {
    All = 0,
    General = 1,
    Roles = 2,
    Workflow = 3,
    Billing = 4,
    Actions = 5,
    ThirdPartyVendor = 6,
    Simple = 7,
    Reoccurrence = 8,
    ClientMobility = 9,
    Contact = 10,
    Budget = 11,
    Settings = 12,
    CostCenters = 13,
    ServiceLevels = 14,
    Addresses = 15,
    ServiceCountries = 16,
    ForeignDrafts = 17,
    Integrations = 18,
    Notes = 19,
    AssociatedApplications = 20,
    BigTime = 21,
    ServiceOfferings = 22,
    ServiceYears = 23,
    Dependents = 24,
    ClientTeam = 25,
    Agreements = 26,
    Clients = 27,
    Recurrence = 28,
    FolderProperties = 29,
    FolderTags = 30
}
