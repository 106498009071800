import { Component } from '@angular/core';
import { CompanyCreateTemplateModalComponent } from 'app/nexus-shared/domain/contacts/components/modals/shared/company-create-template-modal/company-create-template-modal.component';

@Component({
    selector: 'gtn-company-create-dual-form-content',
    template: '<ng-content></ng-content>'
})
export class CompanyCreateDualFormContentComponent {
    // noinspection JSUnusedLocalSymbols
    constructor(gtnCompanyCreateDualFormContentComponent: CompanyCreateTemplateModalComponent) {
        //THIS WILL THROW AN ERROR IF ITS NOT NESTED WITHIN THE gtn-modal ELEMENT
    }
}
