import { Component, Input } from '@angular/core';
import { BaseViewComponent } from 'app/nexus-shared/components/base-component/base-view.component';

@Component({
    selector: 'gtn-template-view',
    templateUrl: './template-view.component.html',
    styleUrls: ['./template-view.component.scss']
})
export class TemplateViewComponent extends BaseViewComponent<any> {
    @Input() viewTitle: string = null;
}
