<div [style.height.px]="height">
    <div class="dashboard-header-row">
        <div class="dashboard-header">
            <div>
                <span><i class="fas fa-lock"></i></span>
                Checked Out Files
            </div>
            <div class="subtext">{{ documents?.length }} documents</div>
        </div>
        <div class="dashboard-header-actions">
            <gtn-button-toggle-group [options]="checkoutButtonOptions" [isSubtle]="true"
                [selectedButtonId]="selectedButtonId"
                (buttonClick)="onCheckoutOptionChange($event)"></gtn-button-toggle-group>
        </div>
    </div>
    <div *ngIf="documents?.length === 0">
        <span *ngIf="selectedButtonId === currentId">You have no currently checked out files</span>
        <span *ngIf="selectedButtonId === recentId">You have no recently checked out files</span>
        <span *ngIf="selectedButtonId === allId">There are no currently checked out files</span>
    </div>
    <div class="dashboard-table-container">

        <table *ngIf="documents?.length" class="dashboard-table">
            <ng-container *ngFor="let document of documents;">
                <tr class="dashboard-table-row">
                    <td>
                        <span><i class="fas fa-file dashboard-table-icon"></i></span>
                    </td>
                    <td>
                        <div>
                            {{ document.name }}
                        </div>
                        <div class="subtext">
                            {{ document.lastModifiedByUser.changeDateTime| gtnDate : true }}
                        </div>
                    </td>
                    <td>
                        <span
                            *ngIf="document.clientDocument?.companyKey">{{ document.clientDocument.companyName }}</span>
                        <span
                            *ngIf="document.clientDocument?.companyKey && document.clientDocument?.individualKey"> - </span>
                        <span *ngIf="document.clientDocument?.individualKey">
                            {{ document.clientDocument.individualLastName }}
                            , {{ document?.clientDocument.individualFirstName }}</span>
                    </td>
                    <td>
                        <button (click)="onDocumentClicked(document)" class="mt-2" color="default"
                            mat-mini-fab matTooltip="Go To Document">
                            <mat-icon>arrow_forward</mat-icon>
                        </button>
                    </td>
                </tr>
            </ng-container>
        </table>
    </div>
</div>


