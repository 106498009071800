import { animate, AnimationMetadata, state, style, transition, trigger } from '@angular/animations';

export class SlideInOut {
    /**
     * Use '@slideInOut' in markup.
     */
    static animations = SlideInOut.getAnimation();

    private static getAnimation(): AnimationMetadata {
        return trigger('slideInOut', [
            state('true', style({ width: '*', overflow: 'hidden' })),
            state('false', style({ width: '0', overflow: 'hidden' })),
            transition('* => *', animate('300ms linear'))
        ]);
    }
}
