import { Component, Input } from '@angular/core';

@Component({
    selector: 'gtn-notification-indicator',
    templateUrl: './notification-indicator.component.html',
    styleUrls: ['./notification-indicator.component.scss']
})
export class NotificationIndicatorComponent {
    @Input() isInToolNavBar: boolean;
    @Input() isVisible: boolean;
    @Input() count: number;
    @Input() title: string = '';

    constructor() {
    }
}
