<gtn-template-dynamic-card [mode]="cardMode"
    [appearance]="cardAppearances.readonly">
    <ng-template #header>
        {{viewTitle}}
        <ng-content select="gtn-template-view-header"></ng-content>
    </ng-template>
    <ng-template #content>
        <div class="read-only-form">
            <ng-content select="gtn-template-view-content"></ng-content>
        </div>
    </ng-template>
</gtn-template-dynamic-card>
