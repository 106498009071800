import { Component } from '@angular/core';
import { GridComponent } from 'app/nexus-shared/components/controls/components/base-grid/grid.component';

@Component({
    selector: 'gtn-grid-header',
    template: '<ng-content></ng-content>'
})
export class GridHeaderComponent {
    // noinspection JSUnusedLocalSymbols
    constructor(GridComponent: GridComponent) {
        // THIS WILL THROW AN ERROR IF ITS NOT NESTED WITHIN PARENT
    }
}
