import { Component, OnInit } from '@angular/core';
import { BaseEnterpriseGridComponent } from 'app/nexus-shared/components/base-component/base-enterprise-grid.component';
import { QuestionnaireTemplateModel } from 'app/nexus-shared/domain/questionnaires/models/questionnaire-template.model';
import { IndividualSettingService } from 'app/nexus-core/services/domain/core/individual-setting.service';
import { QuestionnaireTemplatesService } from 'app/nexus-core/services/domain/questionnaires/questionnaire-templates.service';
import { Observable } from 'rxjs';
import { ColDef, GetContextMenuItemsParams, GridOptions } from 'ag-grid-community';
import { AgGridColDefHelper } from 'app/nexus-core/helpers/ag-grid-col-def.helper';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { QuestionnaireLocationModel } from 'app/nexus-shared/domain/questionnaires/models/questionnaire-location.model';
import { CommonBaseAuditUserModel } from 'app/nexus-shared/domain/contacts/models/common-base-audit-user.model';
import { concatMap, map } from 'rxjs/operators';
import { QuestionnaireTypesEnum } from 'app/nexus-shared/domain/questionnaires/enums/questionnaire-types.enum';
import { QuestionnaireTemplateStatusesEnum } from 'app/nexus-shared/domain/questionnaires/enums/questionnaire-template-statuses.enum';
import { QuestionnaireTemplateSearchModel } from 'app/nexus-shared/domain/questionnaires/models/questionnaire-template-search.model';
import { QuestionnaireHelper } from 'app/nexus-core/helpers/questionnaire.helper';
import { ToastService } from 'app/nexus-core';

@Component({
    selector: 'gtn-questionnaire-templates-grid',
    templateUrl: '../../../../../components/base-component/base-grid-template.component.html'

})
export class QuestionnaireTemplatesGridComponent extends BaseEnterpriseGridComponent<QuestionnaireTemplateModel> implements OnInit {
    gridName: string = 'companies_grid';
    gridVersion: string = '1.0.1';

    constructor(protected individualSettingService: IndividualSettingService,
                private toastService: ToastService,
                private questionnaireTemplatesService: QuestionnaireTemplatesService) {
        super(individualSettingService);
    }

    ngOnInit() {
        const that = this;
        const gridOptions: GridOptions = {
            getContextMenuItems: (params: GetContextMenuItemsParams) => {
                return [
                    {
                        name: 'Clone questionnaire',
                        icon: `<i class='fas fa-copy'></i>`,
                        action: () => {
                            that.cloneQuestionnaireTemplate(params.node.data);
                        }
                    }
                ];
            },
        };
        super.ngOnInit(gridOptions);
    }

    protected setColumnDefinitions(): ColDef[] {
        return [
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOf<QuestionnaireTemplateModel>('title'), 'Title'),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOf<QuestionnaireTemplateModel>('year'), 'Year'),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOfSubProperty<QuestionnaireTemplateModel, QuestionnaireLocationModel>('location', 'name'), 'Country'),
            AgGridColDefHelper.getEnumColumnDef(ObjectHelper.nameOf<QuestionnaireTemplateModel>('questionnaireType'), 'Type', QuestionnaireTypesEnum),
            AgGridColDefHelper.getEnumColumnDef(ObjectHelper.nameOf<QuestionnaireTemplateModel>('status'), 'Status', QuestionnaireTemplateStatusesEnum),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOfSubProperty<QuestionnaireTemplateModel, CommonBaseAuditUserModel>('createdByUser', 'fullName'), 'Created By'),
            AgGridColDefHelper.getDateTimeColDef(ObjectHelper.nameOfSubProperty<QuestionnaireTemplateModel, CommonBaseAuditUserModel>('createdByUser', 'changeDateTime'), 'Created On'),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOfSubProperty<QuestionnaireTemplateModel, CommonBaseAuditUserModel>('lastModifiedByUser', 'fullName'), 'Last Modified By'),
            AgGridColDefHelper.getDateTimeColDef(ObjectHelper.nameOfSubProperty<QuestionnaireTemplateModel, CommonBaseAuditUserModel>('lastModifiedByUser', 'changeDateTime'), 'Last Modified On'),
        ];
    }

    protected setRowData(): Observable<QuestionnaireTemplateModel[]> {
        return this.questionnaireTemplatesService.search(
            <QuestionnaireTemplateSearchModel>{ statuses: [QuestionnaireTemplateStatusesEnum.Draft, QuestionnaireTemplateStatusesEnum.Active, QuestionnaireTemplateStatusesEnum.Deleted] }).pipe(map(res => {
            return res.map(x => new QuestionnaireTemplateModel(x));
        }));
    }

    private cloneQuestionnaireTemplate(questionnaireTemplate: QuestionnaireTemplateModel): void {
        this.questionnaireTemplatesService.get(questionnaireTemplate.questionnaireTemplateKey).pipe(concatMap(res => {
            const questionnaireTemplateClone = QuestionnaireHelper.cloneQuestionnaireTemplate(res);
            return this.questionnaireTemplatesService.create(questionnaireTemplateClone);
        })).subscribe(_ => {
            this.toastService.showMessageToast('Template created');
            this.refreshGridData();
        }, err => {
            this.toastService.showErrorToast(err);
        });
    }
}
