<gtn-modal class="modal-medium"
    [headerText]="headerText"
    okButtonText="Save"
    [disableOkButton]="(!formGroupRef.dirty && !isDualFormDirty) || isSaving"
    [validationModels]="validationModels"
    (okClick)="onSaveClicked()"
    (cancelClick)="onCancelClicked()"
    (closeClick)="onCancelClicked()"
    [formGroupRef]="formGroupRef">
    <gtn-modal-content>
        <ng-content select="gtn-individual-create-composite-template-content"></ng-content>
        <gtn-individual-company-access-composite-form
            *ngIf="isCreateNewIndividual"
            [(formGroupRef)]="formGroupRef"
            [isControllingCompanyEmployee]="false"
            (formGroupRefChange)="onFormGroupRefChanged($event)"
            [(value)]="value"
            [cardMode]="cardMode">
        </gtn-individual-company-access-composite-form>
    </gtn-modal-content>
</gtn-modal>
