import { Component } from '@angular/core';
import { BaseFormComponent } from 'app/nexus-shared/components/base-component/base-form.component';
import { SectionColumnModel } from 'app/nexus-shared/domain/questionnaires/models/section-column.model';
import { DropdownOptionTypeModel } from 'app/nexus-shared/domain/questionnaires/models/dropdown-option-type.model';
import { SelectListInterface } from 'app/nexus-shared/interfaces';
import { UserInputTypesEnum } from 'app/nexus-shared/domain/questionnaires/enums/user-input-types.enum';
import { EnumHelper } from 'app/nexus-core';

@Component({
    selector: 'gtn-column-editor-user-input-form',
    templateUrl: './column-editor-user-input-form.component.html',
    styleUrls: ['./column-editor-user-input-form.component.scss', '../questionnaire-forms.component.scss']
})
export class ColumnEditorUserInputFormComponent extends BaseFormComponent<SectionColumnModel> {
    dropdownOptions: DropdownOptionTypeModel[];
    userInputTypeFormControlName: string = 'userInputType';
    userInputTypes: SelectListInterface[];
    userInputTypesEnum = UserInputTypesEnum;
    constructor() {
        super();
    }

    protected initUIControls() {
        this.userInputTypes = EnumHelper.convertToSelectList(UserInputTypesEnum, false, false);
    }
}
