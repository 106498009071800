import { environment } from 'environments/environment';

export class FileHelper {
    static mbInBytes: number = 1048576;

    static asFormData(files: File[]): FormData {
        let count = files.length;
        const formData: FormData = new FormData();

        do {
            for (const file of files) {
                --count;
                formData.append(file.name, file);
            }
        } while (count > 0);

        return formData;
    }

    static convertFilesToArray(files: FileList): File[] {
        const fileArray = [];
        for (let i = 0, numFiles = files.length; i < numFiles; i++) {
            fileArray.push(files[i]);
        }
        return fileArray;
    }

    static getFileSizeLimit(): number {
        return environment().fileSizeLimitMB * this.mbInBytes;
    }

    static openFile(blob: string, fileName: string) {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('display', 'none');
        // @ts-ignore
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = `${fileName}`;
        a.click();
        window.URL.revokeObjectURL(url);
    }

    static getExtension(fileName: string): string {
        return fileName?.split('.').pop().toLowerCase();
    }
}
