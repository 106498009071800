import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { IndividualModel } from 'app/nexus-shared/domain/contacts/models/individual.model';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SearchResultIconEnum } from 'app/nexus-shared/components/controls/shared/enums';
import { SearchResultModel } from 'app/nexus-shared/components/controls/shared/models';
import { IndividualSearchModel } from 'app/nexus-shared/domain/contacts/models/individual-search.model';
import { CompanySearchModel } from 'app/nexus-shared/domain/contacts/models/company-search.model';
import { forkJoin, Observable } from 'rxjs';
import { CompanyModel } from 'app/nexus-shared/domain/contacts/models';
import { SearchHelper } from 'app/nexus-core';
import { IndividualTypesEnum } from 'app/nexus-shared/domain/contacts/enums/individual-types.enum';
import { CompanyTypesEnum } from 'app/nexus-shared/domain/contacts/enums/company-types.enum';
import { BaseSearchControlComponent } from 'app/nexus-shared/components/base-component/base-search-control.component';
import { IndividualService } from 'app/nexus-core/services/domain/contacts/individual.service';
import { CompanyService } from 'app/nexus-core/services/domain/contacts/company.service';

@Component({
    selector: 'gtn-client-search',
    templateUrl: './client-search.component.html',
    styleUrls: ['./client-search.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ClientSearchComponent),
            multi: true
        }
    ]
})
export class ClientSearchComponent extends BaseSearchControlComponent<CompanyModel | IndividualModel> implements ControlValueAccessor {
    @Input() individualSearchModel: IndividualSearchModel = <IndividualSearchModel>{ individualTypes: [IndividualTypesEnum.Independent], searchTerm: null };
    @Input() companySearchModel: CompanySearchModel = <CompanySearchModel>{ companyTypes: [CompanyTypesEnum.Client], searchTerm: null };
    @Output() searchResultClick: EventEmitter<IndividualModel> = new EventEmitter();
    @Output() createCompany: EventEmitter<void> = new EventEmitter();
    @Output() createIndividual: EventEmitter<void> = new EventEmitter();

    constructor(
        private companyService: CompanyService,
        private individualService: IndividualService,
    ) {
        super();

        this.subscriptions.add(this.searchModel.onCreateNewClicked.subscribe((individual) => {
            if (individual) {
                this.createIndividual.emit();
            } else {
                this.createCompany.emit();
            }
        }));
    }

    protected onSearchTextChanged(searchText: string) {
        this.individualSearchModel.searchTerm = searchText;
        this.companySearchModel.searchTerm = searchText;
        const observables: Observable<any>[] = [
            this.individualService.search(this.individualSearchModel), this.companyService.search(this.companySearchModel)
        ];
        forkJoin(observables).subscribe(responses => {
            const individuals = responses[0].map((individual: IndividualModel) => {
                return {
                    id: individual.individualKey,
                    name: individual.fullName,
                    subName: 'Independent',
                    type: null,
                    iconClass: SearchResultIconEnum.user,
                    resultObject: individual,
                    rank: 0
                } as SearchResultModel<void, IndividualModel>;
            });
            const companies = responses[1].map((company: CompanyModel) => {
                return {
                    id: company.companyKey,
                    name: company.name,
                    subName: 'Corporate Client',
                    type: null,
                    iconClass: SearchResultIconEnum.business,
                    resultObject: company,
                    rank: 0
                } as SearchResultModel<void, CompanyModel>;
            });
            const orderedCompanies = SearchHelper.orderSearchResults<void, CompanyModel>(companies, searchText, []);
            const orderedIndividuals = SearchHelper.orderSearchResults<void, IndividualModel>(individuals, searchText, []);
            this.searchModel.onResultsReceived.next(orderedCompanies.concat(orderedIndividuals));
        }, err => {
            this.searchModel.onErrorReceived.next(err);
        });
    }

    displayWith(searchResultItem: SearchResultModel<void, any>) {
        if (searchResultItem) {
            if ('resultObject' in searchResultItem) {
                return searchResultItem?.resultObject?.individualKey
                    ? `${searchResultItem.resultObject.lastName}, ${searchResultItem.resultObject.firstName}`
                    : searchResultItem?.resultObject?.companyKey ? searchResultItem?.resultObject?.name : null;
            }
        }
        return null;
    }
}
