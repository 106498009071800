<gtn-modal
    headerText="Settings"
    okButtonText="Save" class="modal-small" [disableOkButton]="isSaving"
    [validationModels]="validationModels" (okClick)="onSaveClicked()" (cancelClick)="cancelClick.emit()"
    (closeClick)="cancelClick.emit()" [formGroupRef]="formGroupRef">
    <gtn-modal-content>
        <div *ngIf="userSettings?.length">
            <gtn-dropdown label="My settings" [options]="userSettings"
                [(ngModel)]="selectedUserSetting"
                [showBlank]="true"
                displayKey="name"
                idKey="savedSettingId"
                (change)="onUserSettingChanged($event['value'])"></gtn-dropdown>
        </div>
        <div *ngIf="sharedUserSettings?.length">
            <gtn-dropdown label="Shared settings" [options]="sharedUserSettings"
                [(ngModel)]="selectedSharedSetting"
                [showBlank]="true"
                displayKey="name"
                idKey="savedSettingId"
                (change)="onSharedSettingChanged($event['value'])"></gtn-dropdown>
        </div>
        <div>
            <gtn-input label="Name" [(ngModel)]="value.name" (change)="onNameChanged()"></gtn-input>
            <gtn-checkbox *ngIf="canShareSettings" [(ngModel)]="isSharedSetting"
                label="Shared view"
                (change)="onSharedChanged($event['value'])"></gtn-checkbox>
            <gtn-checkbox *ngIf="canShareSettings && isSharedSetting" [(ngModel)]="isChipView"
                label="Chip view"
                (change)="onChipValueChange($event['value'])"></gtn-checkbox>
        </div>
    </gtn-modal-content>
    <gtn-modal-footer-right-buttons>
        <button *ngIf="!isNameChanged && value.savedSettingId" mat-flat-button (click)="onDeleteSettingClicked()"
            class="ok-button mat-accent" type="button">
            Delete
        </button>
    </gtn-modal-footer-right-buttons>
</gtn-modal>
