<gtn-nexus-spinner></gtn-nexus-spinner>

<ng-container *ngIf="isAuthInitiated">
    <gtn-error-page *ngIf="errorMessage" [message]="errorMessage"></gtn-error-page>
    <mat-sidenav-container id="sidenav" *ngIf="!errorMessage">
        <mat-sidenav *ngIf="selectedApp?.childRoutes?.length" #sidenav mode="side"
            [opened]="authenticatedUserService.isAuthenticated && startOpened" position="start"
            class="sidenav-menu" (closedStart)="onNavMenuCloseStarted()">
            <div class="sidenav-header">
                <i class="material-icons noselect" (click)="onSideNavToggled()">menu</i>
            </div>
            <div *ngIf="selectedApp">
                <mat-accordion [multi]="isAllowMultiItemsExpanded" displayMode="flat">
                    <ng-container *ngFor="let item of selectedApp.childRoutes">
                        <ng-container *ngIf="item.menu && !item.menu?.isHidden">
                            <ng-container *ngIf="item.childRoutes; else navLink">
                                <mat-expansion-panel [expanded]="item?.menu?.isExpanded"
                                    #subMenu class="sidenav-accordion"
                                    [hideToggle]="!sidenav?.opened">
                                    <mat-expansion-panel-header
                                        [ngStyle]="{'padding': sidenav?.opened ? '0 24px' : '0'}"
                                        *ngIf="item.routing?.routerLink"
                                        class="sidenav-item"
                                        [routerLinkActiveOptions]="{exact: (item.routing?.useExact || false)}"
                                        [routerLink]="item.routing.routerLink" [routerLinkActive]="['link-active']">
                                        <div class="sidenav-box">
                                            <i [ngClass]="item.menu.iconClass" matTooltipPosition="right"
                                                [matTooltip]="item.menu.title"></i>
                                        </div>
                                        <div
                                            class="sidenav-expanded">{{ item.menu.title }}
                                        </div>
                                    </mat-expansion-panel-header>

                                    <mat-expansion-panel-header *ngIf="!item.routing?.routerLink"
                                        [ngStyle]="{'padding': sidenav?.opened ? '0 24px' : '0'}"
                                        class="sidenav-item">
                                        <div class="sidenav-box">
                                            <i [ngClass]="item.menu.iconClass" matTooltipPosition="right"
                                                [matTooltip]="item.menu.title"></i>
                                        </div>
                                        <div
                                            class="sidenav-expanded">{{ item.menu.title }}
                                        </div>
                                    </mat-expansion-panel-header>

                                    <div class="sidenav-expanded-content">
                                        <ng-container *ngFor="let subItem of item.childRoutes">
                                            <a class="sidenav-subitem" *ngIf="subItem.menu && !subItem.menu.isHidden"
                                                [ngClass]="subItem.menu.cssClass"
                                                [routerLinkActiveOptions]="{exact: (item.routing?.useExact || false)}"
                                                [routerLinkActive]="['link-active']"
                                                [routerLink]="subItem.routing.routerLink">
                                                <i [ngClass]="subItem?.menu?.iconClass" matTooltipPosition="right"
                                                    [matTooltip]="subItem?.menu?.title"></i>
                                                <span class="sidenav-expanded sidenav-subitem-title"
                                                    *ngIf="sidenav.opened">
                                                    {{ subItem?.menu?.title }}
                                                </span>
                                            </a>
                                        </ng-container>
                                    </div>
                                </mat-expansion-panel>
                            </ng-container>
                            <ng-template #navLink>
                                <a *ngIf="item.menu && !item.menu.isHidden" class="sidenav-item"
                                    [ngClass]="item.menu.cssClass"
                                    [routerLinkActiveOptions]="{exact: (item.routing?.useExact || false)}"
                                    [routerLinkActive]="['link-active']" [routerLink]="item.routing.routerLink">
                                    <div class="sidenav-box">
                                        <i [ngClass]="item.menu.iconClass" matTooltipPosition="right"
                                            [matTooltip]="item.menu.title"></i>
                                    </div>
                                    <div class="sidenav-expanded">{{ item.menu.title }}</div>
                                </a>
                            </ng-template>
                        </ng-container>
                    </ng-container>
                </mat-accordion>
            </div>
            <gtn-nexus-footer (openModal)="onOpenModal($event)"></gtn-nexus-footer>
        </mat-sidenav>
        <mat-sidenav-content class="sidenav-content">
            <div class="nav-logo">
                <gtn-nav-logo></gtn-nav-logo>
            </div>
            <gtn-nav-header></gtn-nav-header>
            <div #mainContent class="main-content" *ngIf="isUserSyncCompleted">
                <router-outlet></router-outlet>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
    <gtn-nexus-modal></gtn-nexus-modal>
    <gtn-two-factor-modal *ngIf="show2FADialog" (completed)="onTwoFactorCompleted($event)"></gtn-two-factor-modal>
    <gtn-aua-modal *ngIf="showAUADialog" (completed)="onAUACompleted($event)"></gtn-aua-modal>
    <gtn-data-acknowledgment-modal *ngIf="showDataAcknowledgmentDialog"
        (completed)="onDataAcknowledgmentCompleted($event)"></gtn-data-acknowledgment-modal>
    <gtn-device-warning-modal *ngIf="showBrowserCheckDialog"
        (completed)="onBrowserWarningAcknowledged()"></gtn-device-warning-modal>
    <gtn-user-impersonation-modal *ngIf="applicationConfigurationService.showUserImpersonationModal"
        (completed)="applicationConfigurationService.showUserImpersonationModal = false"
        [defaultToCurrentUser]="true"></gtn-user-impersonation-modal>
    <gtn-contact-support-modal *ngIf="applicationConfigurationService.showContactSupportModal"
        (completed)="applicationConfigurationService.showContactSupportModal = false"></gtn-contact-support-modal>
    <gtn-forgot-password-modal *ngIf="applicationConfigurationService.showForgotPasswordModal"
        (completed)="applicationConfigurationService.showForgotPasswordModal = false"></gtn-forgot-password-modal>
    <gtn-update-email-modal *ngIf="applicationConfigurationService.showUpdateEmailModal"
        (completed)="applicationConfigurationService.showUpdateEmailModal = false"></gtn-update-email-modal>
    <gtn-idle-warning-modal *ngIf="applicationConfigurationService.showIdleWarningModal"
        (completed)="applicationConfigurationService.showIdleWarningModal = false"></gtn-idle-warning-modal>
    <gtn-modal *ngIf="showTermsModal"
        headerText="Terms of Use Agreement"
        cancelButtonText="Close"
        [isCancelButtonColorOrange]="true"
        (cancelClick)="onCloseModal()"
        class="modal-medium-wide" (closeClick)="onCloseModal()">
        <gtn-modal-content>
            <gtn-terms-and-conditions></gtn-terms-and-conditions>
        </gtn-modal-content>
    </gtn-modal>
    <gtn-modal *ngIf="showPrivacyModal"
        headerText="Website Privacy Policy"
        cancelButtonText="Close"
        [isCancelButtonColorOrange]="true"
        (cancelClick)="onCloseModal()"
        class="modal-medium-wide" (closeClick)="onCloseModal()">
        <gtn-modal-content>
            <gtn-gtn-privacy-policy></gtn-gtn-privacy-policy>
        </gtn-modal-content>
    </gtn-modal>
</ng-container>
