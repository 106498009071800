import { Injectable } from '@angular/core';
import { BaseHttpService, BaseService } from 'app/nexus-core/services';
import { IAgreementServiceInterface } from './iagreement-service.interface';
import { Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { AgreementSearchModel } from 'app/nexus-shared/domain/agreements/models/agreement-search.model';
import { AgreementModel } from 'app/nexus-shared/domain/agreements/models/agreement.model';
import { AgreementNotificationRequestModel } from 'app/nexus-shared/domain/agreements/models/agreement-notification-request.model';
import { IAgreementFileModelInterface } from 'app/nexus-shared/domain/agreements/interfaces/iagreement-file-model.interface';
import { FileBlobHttpResponseModel, ValidationModel } from 'app/nexus-shared';
import { BlobStorageRequest, NexusBlobRequest } from '../../blob/types/azure-storage';
import { BlobStorageService } from '../../blob/services/blob-storage.service';
import { concatMap } from 'rxjs/operators';

@Injectable()
export class AgreementService extends BaseService implements IAgreementServiceInterface {
    private apiUrl = environment().webAPIUrls.agreements + 'agreements';

    constructor(private baseHttpService: BaseHttpService,
        private blobStorageService: BlobStorageService) {
        super();
    }

    create(agreement: AgreementModel): Observable<string> {
        return this.baseHttpService.post(`${this.apiUrl}`, agreement);
    }

    getFile(agreementKey: string, isReadOnly: boolean): Observable<FileBlobHttpResponseModel> {
        return this.baseHttpService.get(`${this.apiUrl}/${agreementKey}/${isReadOnly}`, null, 'blob');
    }

    getSigned(agreementKey: string): Observable<FileBlobHttpResponseModel> {
        return this.baseHttpService.get(`${this.apiUrl}/signed/${agreementKey}`, null, 'blob');
    }

    delete(agreementKey: string): Observable<boolean> {
        return this.baseHttpService.delete(`${this.apiUrl}/${agreementKey}`);
    }

    get(agreementKey: string): Observable<AgreementModel> {
        return this.baseHttpService.get(`${this.apiUrl}/${agreementKey}`);
    }

    getSasToken(): Observable<BlobStorageRequest> {
        return this.baseHttpService.get(`${this.apiUrl}/token`);
    }

    search(searchModel: AgreementSearchModel): Observable<AgreementModel[]> {
        return this.baseHttpService.post(`${this.apiUrl}/search`, searchModel);
    }

    sendNotification(request: AgreementNotificationRequestModel): Observable<boolean> {
        return this.baseHttpService.post(`${this.apiUrl}/send-notification`, request);
    }

    sign(agreement: AgreementModel): Observable<boolean> {
        return this.baseHttpService.post(`${this.apiUrl}/sign`, agreement);
    }

    update(agreement: AgreementModel): Observable<boolean> {
        return this.baseHttpService.put(`${this.apiUrl}`, agreement);
    }

    updateReviewerStatus(agreement: AgreementModel): Observable<boolean> {
        return this.baseHttpService.put(`${this.apiUrl}/reviewer-status`, agreement);
    }

    uploadSigned(agreementFileModel: IAgreementFileModelInterface): Observable<string> {
        return this.uploadToBlob(agreementFileModel).pipe(concatMap(_ => {
            return this.baseHttpService.post(`${this.apiUrl}/upload-signed`, agreementFileModel);
        }));
    }

    validateSignatures(agreement: AgreementModel): Observable<ValidationModel[]> {
        return this.baseHttpService.post(`${this.apiUrl}/validate-signatures`, agreement);
    }

    private uploadToBlob(agreementFileModel: IAgreementFileModelInterface): Observable<number> {
        if (!agreementFileModel?.file || !agreementFileModel?.tempFileName) {
            return of(-1);
        }

        return this.getSasToken().pipe(concatMap(blobStorageRequest => {
            const request = blobStorageRequest as NexusBlobRequest;
            request.filename = agreementFileModel.tempFileName;

            return this.blobStorageService.uploadToBlobStorage(agreementFileModel.file, request);
        }));
    }
}
