import { GpsCoordinatesModel } from 'app/nexus-shared/domain/locations/models/gps-coordinates.model';
import { LocationInterface } from 'app/nexus-shared/interfaces';

export class LocationModel implements LocationInterface {
    id?: number;
    name?: string;
    type?: { id: number, description: string };
    gpsCoordinates?: GpsCoordinatesModel;
    relatedLocations?: LocationModel[];
    countryCode?: string;
    stateCode?: string;
    airportCode?: string;
    population?: number;

    constructor(model: LocationModel = null) {
        this.id = model?.id ?? null;
        this.name = model?.name ?? null;
        this.type = model?.type ?? null;
        this.gpsCoordinates = model?.gpsCoordinates ?? null;
        this.relatedLocations = model?.relatedLocations ?? null;
        this.countryCode = model?.countryCode ?? null;
        this.stateCode = model?.stateCode ?? null;
        this.airportCode = model?.airportCode ?? null;
        this.population = model?.population ?? null;
    }
}
