<gtn-modal class="small" headerText="User Impersonation" okButtonText="Save" (okClick)="onSubmitClicked()"
           (cancelClick)="onCancelClicked()" [disableOkButton]="disableButtons || disableEdit">
    <gtn-modal-content>
        <form [formGroup]="formGroupRef" *ngIf="formGroupRef">

            <div class="row"
                 *ngIf="!formGroupRef.value.userImpersonationKey && hasImpersonationAccess && !defaultToCurrentUser">
                <gtn-user-search
                    [userSearchModel]="userSearchModel"
                    class="col-md-12"
                    label="Impersonation user"
                    (searchResultClick)="onUserSelected($event)">
                </gtn-user-search>
            </div>
            <div class="row" *ngIf="formGroupRef.value.userImpersonationKey || defaultToCurrentUser">
                <div class="col-md-12">
                    <label class="list-label">Impersonation User</label>
                    <div class="data-field">
                        {{formGroupRef.value.userName}}
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="!formGroupRef.value.userImpersonationKey">
                <gtn-user-search
                    [userSearchModel]="impersonationUserSearchModel"
                    class="col-md-12"
                    label="User to impersonate"
                    (searchResultClick)="onImpersonationUserSelected($event)">
                </gtn-user-search>
            </div>
            <div class="row" *ngIf="formGroupRef.value.userImpersonationKey">
                <div class="col-md-12">
                    <label class="list-label">User to impersonate</label>
                    <div class="data-field">
                        {{formGroupRef.value.impersonationUserName}}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <gtn-text-area
                        [readonly]="disableEdit"
                        [minRows]="5"
                        label="Reason for impersonation"
                        formControlName="requestReason">
                    </gtn-text-area>
                </div>
            </div>
            <div class="row" *ngIf="formGroupRef.value.userImpersonationKey">
                <div class="col-12">
                    <gtn-text-area
                        [readonly]="disableEdit"
                        [minRows]="5"
                        label="Changes performed"
                        formControlName="changeNotes">
                    </gtn-text-area>
                </div>
            </div>
        </form>
    </gtn-modal-content>
    <gtn-modal-footer-right-buttons>
        <button mat-flat-button
                *ngIf="!disableEdit && formGroupRef?.value?.userImpersonationKey && formGroupRef?.value?.userKey === authenticatedUserService.user.userKey"
                (click)="onSubmitClicked(true)" [disabled]="disableButtons"
                class="ok-button mat-accent" id="secondaryButton" type="button">
            Disable Impersonation
        </button>
    </gtn-modal-footer-right-buttons>
</gtn-modal>
