import { Component } from '@angular/core';
import { FilterPanelComponent } from 'app/nexus-shared/components/filter-panel/filter-panel.component';

@Component({
    selector: 'gtn-filter-panel-button-content',
    template: '<ng-content></ng-content>'
})
export class FilterPanelButtonContentComponent {
    // noinspection JSUnusedLocalSymbols
    constructor(gtnFilterPanelComponent: FilterPanelComponent) {
        // THIS WILL THROW AN ERROR IF ITS NOT NESTED WITHIN PARENT
    }
}
