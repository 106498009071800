import { ModalInputModel } from 'app/nexus-shared/components/modal/models/modal-input.model';
import { GridFilterDropDownConfigModel } from 'app/nexus-shared/index';

export class GridFilterModalInputModel extends ModalInputModel {
    filterLapses: boolean;
    filterTaxes: boolean;
    gridFilters: GridFilterDropDownConfigModel[];

    constructor(
        mainHeaderText: string = '',
        okButtonText: string = 'Apply',
        gridFilters: GridFilterDropDownConfigModel[] = [],
        filterLapses: boolean = false,
        filterTaxes: boolean = false
    ) {
        super(
            mainHeaderText,
            null,
            null,
            okButtonText
        );
        this.filterLapses = filterLapses;
        this.filterTaxes = filterTaxes;
        this.gridFilters = gridFilters;
    }
}
