import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { BaseQuestionnaireFormComponent } from 'app/nexus-shared/domain/questionnaires/components/forms/base-questionnaire-form.component';
import { SectionColumnFormControlNames, SectionColumnModel } from 'app/nexus-shared/domain/questionnaires/models/section-column.model';
import { SelectListInterface } from 'app/nexus-shared/interfaces';
import { SectionColumnTypesEnum } from 'app/nexus-shared/domain/questionnaires/enums/section-column-types.enum';
import { UntypedFormArray } from '@angular/forms';
import { EnumHelper } from 'app/nexus-core';
import { UserInputTypesEnum } from 'app/nexus-shared/domain/questionnaires/enums/user-input-types.enum';
import { ColumnEditorTableFormComponent } from 'app/nexus-shared/domain/questionnaires/components/forms/column-editor-table-form/column-editor-table-form.component';

@Component({
    selector: 'gtn-section-column-form',
    templateUrl: './section-column-form.component.html',
    styleUrls: ['./section-column-form.component.scss', '../questionnaire-forms.component.scss']
})
export class SectionColumnFormComponent extends BaseQuestionnaireFormComponent<SectionColumnModel> {
    sectionColumnTypes: SelectListInterface[];
    sectionColumnTypesEnum = SectionColumnTypesEnum;
    userInputTypesEnum = UserInputTypesEnum;
    tableColumnHeaderFormArray: UntypedFormArray;
    tableRowsFormArray: UntypedFormArray;
    sectionColumnFormControlNames = new SectionColumnFormControlNames();

    @Output() deleteColumn: EventEmitter<string> = new EventEmitter<string>();

    @ViewChild('tableEditorForm') columnEditorTableFormComponent: ColumnEditorTableFormComponent;

    protected initUIControls(): void {
        this.sectionColumnTypes = EnumHelper.convertToSelectList(SectionColumnTypesEnum, false, false);
    }

    onDeleteColumnClicked(): void {
        this.deleteColumn.emit(this.formGroupRef.get(this.sectionColumnFormControlNames.key)?.value);
    }

    onAddTableColumn(): void {
        this.columnEditorTableFormComponent.addTableColumnClicked();
    }

}
