import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectListInterface } from 'app/nexus-shared/index';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { ReportOptionsEnum } from 'app/nexus-shared/domain/travel-calendar/enums/report-options.enum';

@Component({
    selector: 'gtn-report-grid-select',
    templateUrl: './report-grid-select.component.html',
    styleUrls: ['./report-grid-select.component.scss']
})
export class ReportGridSelectComponent extends BaseComponent implements OnInit {
    @Input() individualKey: string;
    @Input() startDate: Date;
    @Input() endDate: Date;
    @Input() allReportOptions: SelectListInterface[];
    @Input() selectedReportOption: number;
    @Output() selectedReportOptionChange: EventEmitter<number> = new EventEmitter<number>();

    @Output() startDateChange: EventEmitter<Date> = new EventEmitter<Date>();
    @Output() endDateChange: EventEmitter<Date> = new EventEmitter<Date>();

    gridOptions: SelectListInterface[];
    reportOptions: SelectListInterface[];

    ngOnInit() {
        this.reportOptions = this.allReportOptions.filter(x => +x.id < ReportOptionsEnum.DaysPresenceGrid);
        this.gridOptions = this.allReportOptions.filter(x => +x.id >= ReportOptionsEnum.DaysPresenceGrid);
    }

    onStartDateChanged(startDate: Date): void {
        this.startDateChange.emit(startDate);
    }

    onEndDateChanged(endDate: Date): void {
        this.endDateChange.emit(endDate);
    }

    onReportOptionClicked(reportOption: SelectListInterface): void {
        this.selectedReportOption = reportOption.id as number;
        this.selectedReportOptionChange.emit(this.selectedReportOption);
    }
}
