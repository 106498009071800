import { ModalInputModel } from 'app/nexus-shared/components/modal/models/modal-input.model';

export class TimelineDetailModalInputModel extends ModalInputModel {
    details: any[];
    showCalculateButton: boolean;

    constructor(
        details: any[],
        message: string = '',
        mainHeaderText: string = '',
        subHeaderText: string = '',
        okButtonText: string = 'Ok',
        showCalculateButton: boolean = false
    ) {
        super(
            mainHeaderText,
            message,
            subHeaderText,
            okButtonText
        );

        this.details = details;
        this.showCalculateButton = showCalculateButton;
    }
}
