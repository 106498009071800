<mat-list>
    <mat-list-item class="list-header">
        <div class="col-4">
            Date
        </div>
        <div class="col-4 flex flex-row">
            <div class="flex flex-column justify-content-center">
                Event
            </div>
            <gtn-checkbox *ngIf="documentUserAuth?.isAdmin" class="history-toggle" label="Full history"
                (change)="onToggleFullHistory($event['value'])"></gtn-checkbox>
        </div>
        <div class="col-4">
            User
        </div>
    </mat-list-item>
    <ng-container *ngFor="let historyDocument of displayDocumentHistory">
        <mat-list-item class="list-item">
            <div class="col-4">
                {{ historyDocument.lastModifiedByUser?.changeDateTime | gtnDate : true }}
            </div>
            <div class="col-4">
                {{ historyDocument.eventType | enum: documentEventTypeEnum }}
            </div>
            <div class="col-4">
                {{ historyDocument.lastModifiedByUser?.fullName }}
            </div>
        </mat-list-item>
    </ng-container>
</mat-list>
