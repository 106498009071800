import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseFormComponent } from 'app/nexus-shared/components/base-component/base-form.component';
import { IAgreementSearchModelInterface } from '../../../interfaces/iagreement-search-model.interface';
import { CompanyModel, IndividualModel } from 'app/nexus-shared/domain/contacts/models';
import { AgreementsHelper, EnumHelper } from 'app/nexus-core';
import { SelectListInterface } from 'app/nexus-shared/interfaces';
import { AgreementsSearchFormControlNameConstants } from './agreements-search-form-control-name.constants';
import { AgreementStatusEnum } from '../../../enums/agreement-status.enum';
import { AgreementTypeModel } from '../../../models/agreement-type.model';
import { AbstractControl } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
    selector: 'gtn-agreements-search-form',
    templateUrl: './agreements-search-form.component.html',
    styleUrls: [
        './agreements-search-form.component.scss'
    ]
})
export class AgreementsSearchFormComponent extends BaseFormComponent<IAgreementSearchModelInterface> implements OnInit {
    @Input() agreementTypes: AgreementTypeModel[] = [];
    @Input() showSearch: boolean;
    @Output() searchClick: EventEmitter<IAgreementSearchModelInterface> = new EventEmitter();
    @Output() showSearchChange: EventEmitter<boolean> = new EventEmitter();

    companyFilter: CompanyModel;
    individualFilter: IndividualModel;
    isRequestIndependentsOnly: boolean = false;
    statusOptions: SelectListInterface[] = EnumHelper.convertToSelectList(AgreementStatusEnum, false, true, 'number', true);

    agreementsHelper = AgreementsHelper;
    formControlNames = AgreementsSearchFormControlNameConstants;

    entityChange: Subject<'company' | 'individual' | 'independent'> = new Subject();

    constructor() {
        super();

        this.subscriptions.add(this.entityChange.subscribe(((entityType: 'company' | 'individual' | 'independent') => {
            switch (entityType) {
                case 'company':
                    if (this.value.individualKeys) {
                        this.clearControl(this.formGroupRef.controls[this.formControlNames.individualKeys]);
                    }

                    if (this.individualFilter) {
                        this.individualFilter = null;
                    }

                    if (this.value.isRequestIndependentsOnly) {
                        this.isRequestIndependentsOnly = false;
                        this.clearControl(this.formGroupRef.controls[this.formControlNames.isRequestIndependentsOnly]);
                    }

                    break;
                case 'independent':
                    if (this.value.companyKeys) {
                        this.clearControl(this.formGroupRef.controls[this.formControlNames.companyKeys]);
                    }

                    if (this.companyFilter) {
                        this.companyFilter = null;
                    }

                    if (this.value.individualKeys) {
                        this.clearControl(this.formGroupRef.controls[this.formControlNames.individualKeys]);
                    }

                    if (this.individualFilter) {
                        this.individualFilter = null;
                    }

                    break;
                case 'individual':
                    if (this.value.companyKeys) {
                        this.clearControl(this.formGroupRef.controls[this.formControlNames.companyKeys]);
                    }

                    if (this.companyFilter) {
                        this.companyFilter = null;
                    }

                    if (this.value.isRequestIndependentsOnly) {
                        this.isRequestIndependentsOnly = false;
                        this.clearControl(this.formGroupRef.controls[this.formControlNames.isRequestIndependentsOnly]);
                    }

                    break;
            }
        }).bind(this)));
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    onCompanySearchFilterChanged(company: CompanyModel) {
        if (!company && !this.value?.companyKeys?.length) {
            return;
        }

        const companyKeysControl = this.formGroupRef.controls[this.formControlNames.companyKeys];

        if (company) {
            companyKeysControl.setValue([company.companyKey]);
            companyKeysControl.markAsDirty();
        } else {
            companyKeysControl.reset();
            companyKeysControl.markAsPristine();
        }

        this.entityChange.next('company');
    }

    onIncludeIndependentFilterChanged(value: boolean) {
        const includeIndependentsControl = this.formGroupRef.controls[this.formControlNames.isRequestIndependentsOnly];

        this.isRequestIndependentsOnly = value;

        includeIndependentsControl.setValue(value);
        includeIndependentsControl.markAsDirty();

        this.entityChange.next('independent');
    }

    onIndividualSearchFilterChanged(individual: IndividualModel) {
        if (!individual && !this.value?.individualKeys?.length) {
            return;
        }

        const individualKeysControl = this.formGroupRef.controls[this.formControlNames.individualKeys];

        if (individual) {
            individualKeysControl.setValue([individual.individualKey]);
            individualKeysControl.markAsDirty();
        } else {
            individualKeysControl.reset();
            individualKeysControl.markAsPristine();
        }

        this.entityChange.next('individual');
    }

    onResetFiltersClicked() {
        this.resetFilters();
    }

    onSearchClicked() {
        this.searchClick.emit(this.formGroupRef.value);
    }

    onToggleVisibilityClicked() {
        this.showSearch = !this.showSearch;
        this.showSearchChange.emit(this.showSearch);
    }

    private clearControl(control: AbstractControl) {
        // control.setValue(null);
        control.reset();
        control.markAsPristine();
        control.markAsUntouched();
    }

    private resetFilters(): void {
        this.companyFilter = null;
        this.individualFilter = null;
        this.isRequestIndependentsOnly = false;
        this.formGroupRef.reset();
        this.formGroupRef.markAsPristine();
    }
}
