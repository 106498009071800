import { Injectable } from '@angular/core';
import { SearchModel } from 'app/nexus-shared/components/controls/shared/models/search.model';
import { Subject } from 'rxjs';

@Injectable()
export class ApplicationSearchService extends SearchModel {
    [x: string]: any;

    isSearchbarVisible: Subject<boolean> = new Subject<boolean>();

    showSearchBar(): void {
        this.isSearchbarVisible.next(true);
    }

    hideSearchBar(): void {
        this.isSearchbarVisible.next(false);
    }
}
