export enum UserStatusesEnum {
    Unknown = 0,

    // valid statuses
    Created = 1,
    Invited = 20,
    Complete = 100,

    // error statuses
    LockedOut = 501
}
