<form [formGroup]="formGroupRef">
    <div class="row">
        <gtn-company-search class="col-md-6" *ngIf="isMappingIndividualToCompany && viewMode === viewModes.All"
            formControlName="company" label="Company"
            [readonly]="!!value.individualToCompanyAccessKey"
            [showAddLink]="!value.individualToCompanyAccessKey && canAddNewCompany"
            (addEntityClick)="onCreateCompanyClicked()" (searchResultClick)="onCompanySelected($event)">
        </gtn-company-search>
        <gtn-individual-search class="col-md-6"
            *ngIf="isMappingCompanyToIndividual && viewMode === viewModes.All"
            formControlName="individual" label="Individual"
            [readonly]="!!value.individualToCompanyAccessKey"
            [individualSearchModel]="individualSearchModel"
            [showAddLink]="!value.individualToCompanyAccessKey && canAddNewIndividual"
            (searchResultClick)="onIndividualSelected($event)"
            (addEntityClick)="onCreateIndividualClicked()">
        </gtn-individual-search>
    </div>
    <div class="row">
        <gtn-dropdown *ngIf="!isControllingCompany" class="col-md-6" label="Relationship type"
            formControlName="relationshipType" [readonly]="isRelationshipTypeReadonly" [options]="relationshipTypes"
            [idOnly]="true"></gtn-dropdown>
        <gtn-dropdown *ngIf="isControllingCompany" class="col-md-6" formControlName="jobTitle" label="Job title"
            [options]="gtnTitles" [idOnly]="true" idKey="value"></gtn-dropdown>
        <gtn-multiselect label="Contact type"
            *ngIf="formGroupRef.get('relationshipType')?.value === relationshipTypesEnum.CompanyContact"
            class="col-md-6" formControlName="relationshipSubTypes"
            [options]="relationshipSubTypeOptions" [idOnly]="true">
        </gtn-multiselect>
    </div>
    <div class="row">
        <gtn-input class="col-md-6" formControlName="emailAddress" label="Email address"></gtn-input>
    </div>
    <div class="row">
        <gtn-input *ngIf="!isControllingCompany" class="col-md-6" formControlName="jobTitle"
            label="Job title"></gtn-input>
        <gtn-input *ngIf="value.relationshipType !== relationshipTypesEnum.GtnEmployee" class="col-md-6"
            formControlName="employeeId" label="Employee ID"></gtn-input>
    </div>
    <div class="row">
        <gtn-datepicker class="col-md-6" formControlName="effectiveStartDate"
            label="Effective start date"></gtn-datepicker>
        <gtn-datepicker class="col-md-6" formControlName="effectiveEndDate" label="End date"></gtn-datepicker>
        <gtn-dropdown class="col-md-6" *ngIf="value.effectiveEndDate" formControlName="relationshipEndReasonType"
            [idOnly]="true" idKey="id" displayKey="value" label="Relationship end reason"
            [options]="relationshipEndOptions">
        </gtn-dropdown>
    </div>
    <div class="section-header">
        Primary Phone Number
    </div>
    <ng-container *ngIf="primaryPhoneNumberFormGroup" formGroupName="primaryPhoneNumber">
        <gtn-phone-number-form [(formGroupRef)]="primaryPhoneNumberFormGroup" [isSubForm]="true" [isModal]="true"
            [showIsPrimary]="false">
        </gtn-phone-number-form>
    </ng-container>
    <div class="section-header">
        Secondary Phone Number
    </div>
    <ng-container *ngIf="primaryPhoneNumberFormGroup" formGroupName="secondaryPhoneNumber">
        <gtn-phone-number-form [(formGroupRef)]="secondaryPhoneNumberFormGroup" [isSubForm]="true" [isModal]="true"
            [showIsPrimary]="false">
        </gtn-phone-number-form>
    </ng-container>

    <div class="row">
        <gtn-text-area class="col-md-12" [minRows]="3" appearance="outline" label="Notes"
            formControlName="notes"></gtn-text-area>
    </div>
</form>
