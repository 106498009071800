import { animate, animateChild, AnimationMetadata, group, query, stagger, style, transition, trigger } from '@angular/animations';
import { FadeInBumpDown } from './fade-in-bump-down';

export class GridPanelOpen {
    /**
     * Use '@gridPanelOpen' on the parent element
     * and '@gridSlide' on the child element
     * in the markup.
     */
    static animations = GridPanelOpen.getAnimation();

    private static getAnimation(): AnimationMetadata[] {
        return [trigger('gridPanelOpen', [
            transition(':enter', [
                style({
                    overflow: 'hidden',
                    height: '0px'
                }),
                group([
                    animate('300ms', style({
                        overflow: 'hidden',
                        height: '*',
                        'padding-bottom': '15px',
                        'padding-top': '15px'
                    })),
                    query('@fadeInBumpDown',
                        stagger(100, [
                            animateChild()
                        ]),
                        { optional: true })
                ])
            ]),
            transition(':leave', [
                style({
                    overflow: 'hidden',
                    height: '*'
                }),
                group([
                    animate('300ms', style({
                        overflow: 'hidden',
                        height: '0px',
                        'padding-bottom': '0',
                        'padding-top': '0'
                    })),
                    query('@fadeInBumpDown',
                        animateChild(),
                        { optional: true })
                ])
            ])
        ]),
        FadeInBumpDown.animations('400ms')
        ];
    }
}
