import { CommonBaseAuditModel } from 'app/nexus-shared/domain/contacts/models/common-base-audit.model';
import { QuestionnaireTypesEnum } from 'app/nexus-shared/domain/questionnaires/enums/questionnaire-types.enum';
import { SectionModel } from 'app/nexus-shared/domain/questionnaires/models/section.model';
import { IndividualInterface } from 'app/nexus-shared/interfaces/individual.interface';
import { QuestionnaireInterface } from 'app/nexus-shared/domain/questionnaires/interfaces/questionnaire.interface';
import { QuestionnaireLocationModel } from 'app/nexus-shared/domain/questionnaires/models/questionnaire-location.model';


export class QuestionnaireModel extends CommonBaseAuditModel implements QuestionnaireInterface {
    questionnaireKey: string;
    historicalKey: string;
    questionnaireTemplateKey: string | null;
    type: QuestionnaireTypesEnum;
    title: string;
    year: number | null;
    dueDate: Date | string | null;
    lastAccessedDateTime: Date | string | null;
    isCompleted: boolean;
    isAccessible: boolean;
    isDeleted: boolean;
    notes: string;
    individual: IndividualInterface;
    location: QuestionnaireLocationModel;
    sections: SectionModel[];

    constructor(model: QuestionnaireModel = null) {
        super(model);
        this.questionnaireKey = model?.questionnaireKey ?? null;
        this.historicalKey = model?.historicalKey ?? null;
        this.questionnaireTemplateKey = model?.questionnaireTemplateKey ?? null;
        this.type = model?.type ?? null;
        this.title = model?.title ?? null;
        this.year = model?.year ?? null;
        this.lastAccessedDateTime = model?.lastAccessedDateTime ?? null;
        this.dueDate = model?.dueDate ?? null;
        this.isCompleted = model?.isCompleted ?? null;
        this.isAccessible = model?.isAccessible ?? null;
        this.isDeleted = model?.isDeleted ?? null;
        this.notes = model?.notes ?? null;
        this.individual = model?.individual ?? null;
        this.location = model?.location ?? null;
        this.sections = model?.sections?.length ? model.sections.map(x => new SectionModel(x)) : [];
    }
}
