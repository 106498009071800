<from [formGroup]="formGroupRef">
    <div class="row">
        <div class="col-md-3">
            <gtn-template-dynamic-card [mode]="cardMode">
                <ng-template #header>
                    General
                </ng-template>
                <ng-template #content>
                    <div class="row">
                        <gtn-input class="col-12" label="Title" formControlName="title"></gtn-input>
                        <ng-container formGroupName="location">
                            <gtn-country-select class="col-12" label="Country" [idOnly]="true"
                                formControlName="id" [suppressInitialChangeEvent]="!!value?.location?.id"
                                (idOnlyFormControlValueChange)="onCountryChanged($event)"></gtn-country-select>
                        </ng-container>
                        <gtn-dropdown class="col-12" label="Year" formControlName="year" [options]="years"
                            [idOnly]="true"></gtn-dropdown>
                        <gtn-dropdown class="col-12" label="Type" formControlName="questionnaireType"
                            [options]="questionnaireTypes"
                            [idOnly]="true"></gtn-dropdown>
                        <gtn-dropdown class="col-12" label="Status" formControlName="status"
                            [options]="questionnaireStatuses"
                            [idOnly]="true"></gtn-dropdown>
                    </div>
                </ng-template>
            </gtn-template-dynamic-card>
            <gtn-template-dynamic-card [mode]="cardMode">
                <ng-template #header>
                    Sections
                    <button (click)="onAddSectionClicked()" class="mr-2" color="primary"
                        mat-mini-fab matTooltip="Add">
                        <mat-icon>add</mat-icon>
                    </button>
                </ng-template>
                <ng-template #content>
                    <div cdkDropList (cdkDropListDropped)="onSectionDrop($event)">
                        <ng-container *ngFor="let formGroup of sectionsFormArray.controls; index as i; last as isLast">
                            <div cdkDrag class="section"
                                (click)="onSectionClicked(formGroup)">
                                <div>
                                    <span><i class="fa-solid fa-bars"></i></span>
                                    <span
                                        [ngClass]="{'active': getFormGroupFromAbstractControl(formGroup.value.key) === selectedSection.value.key}">
                                        {{ formGroup?.value?.title }}
                                    </span>
                                    <span class="triggered-text"
                                        *ngIf="this.formGroupRef.value | isTriggeredByAction: formGroup.value">(Triggered)</span>
                                </div>
                                <div class="align-right">
                                    <span (click)="onCloneSectionClicked(formGroup)"><i
                                        class="fa fa-copy mr-1"></i></span>
                                    <span (click)="onDeleteSectionClicked(i)"><i class="fa fa-trash"></i></span>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-template>
            </gtn-template-dynamic-card>
        </div>
        <div class="col-md-9">
            <gtn-template-dynamic-card [mode]="cardMode">
                <ng-template #content>
                    <ng-container *ngIf="!isLoading">
                        <ng-container *ngFor="let formGroup of sectionsFormArray.controls; index as i; last as isLast">
                            <ng-container
                                *ngIf="selectedSection?.value?.key === getFormGroupFromAbstractControl(formGroup)?.value?.key">
                                <gtn-section-form [formGroupRef]="getFormGroupFromAbstractControl(formGroup)"
                                    [questionnaireDropdownService]="questionnaireDropdownService"
                                    [isTemplateMode]="isTemplateMode"
                                    [sectionKey]="formGroup.value?.key"
                                    [isSubForm]="true"></gtn-section-form>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </ng-template>
            </gtn-template-dynamic-card>
        </div>
    </div>
</from>
