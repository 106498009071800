import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { DocumentService } from 'app/nexus-core/services/domain/documents/document.service';
import { DocumentModel } from 'app/nexus-shared/domain/documents/models/document.model';
import { AgGridHelper, EnumHelper, FileHelper, SpinnerService } from 'app/nexus-core';
import { BaseEnterpriseGridComponent } from 'app/nexus-shared/components/base-component/base-enterprise-grid.component';
import {
    CellEditingStartedEvent,
    CellEditingStoppedEvent,
    ColDef,
    GetContextMenuItemsParams,
    GridOptions,
    IRowNode,
    MenuItemDef,
    RowClickedEvent,
    RowDragEndEvent,
    RowDragLeaveEvent,
    RowDragMoveEvent,
    SelectionChangedEvent
} from 'ag-grid-community';
import { finalize } from 'rxjs/operators';
import { FolderModel } from 'app/nexus-shared/domain/documents/models/folder.model';
import { DocumentsGridViewTypes } from 'app/nexus-shared/domain/documents/enums/documents-grid-view.types';
import { DocumentStatusesEnum } from 'app/nexus-shared/domain/documents/enums/document-statuses.enum';
import { ToastService } from 'app/nexus-core/services/toast.service';
import { DocumentsHelper } from 'app/nexus-core/helpers/documents.helper';
import { DocumentFolderService } from 'app/nexus-core/services/domain/documents/document-folder.service';
import { CustomRowPropertyConstants } from 'app/nexus-shared/components/controls/components/base-grid/shared';
import { ConfirmActionTypesEnum } from 'app/nexus-shared/domain/documents/enums/confirm-action-types.enum';
import { DocumentEventTypesEnum } from 'app/nexus-shared/domain/documents/enums/document-event-types.enum';
import { SimpleChangesTyped } from 'app/nexus-shared/models/simple-changes-typed.type';
import { ClientDocumentModel } from 'app/nexus-shared/domain/documents/models/client-document.model';
import { DocumentGridViewModel } from 'app/nexus-shared/domain/documents/models/document-view-models/document-grid-view.model';
import { forkJoin, Observable, of } from 'rxjs';
import { FolderStatusesEnum } from 'app/nexus-shared/domain/documents/enums/folder-statuses.enum';
import { IDocumentMenuItem } from 'app/nexus-shared/domain/documents/models/document-menu-item.interface';
import { DocumentsAuthModel } from 'app/nexus-shared/domain/documents/models/documents-auth.model';
import { DocumentDeleteModel } from 'app/nexus-shared/domain/documents/models/document-delete.model';
import { FolderFavoriteModel } from 'app/nexus-shared/domain/documents/models/folder-favorite.model';
import { DocumentFolderMoveViewModel } from 'app/nexus-shared/domain/documents/models/document-view-models/document-folder-move-view.model';
import { FolderDocumentGridService } from 'app/nexus-core/services/domain/documents/folder-document-grid.service';
import { AreaTypesEnum } from 'app/nexus-shared/domain/documents/enums/area-types.enum';
import { DocumentProcessService } from 'app/nexus-core/services/domain/documents/document-process.service';
import { VirusScanErrorsEnum } from 'app/nexus-shared/domain/documents/enums/virus-scan-errors.enum';
import { ClientDocumentTypesEnum } from 'app/nexus-shared/domain/documents/enums/client-document-types.enum';
import { FileViewModel } from 'app/nexus-shared/components/controls/shared/models/file-view.model';
import { DocumentsGridStatusBarComponent } from 'app/nexus-shared/domain/documents/components/grids/documents-grid-status-bar/documents-grid-status-bar.component';
import { NexusAnimations } from 'app/nexus-shared/animations';
import { CustomCellEditorComponent } from 'app/nexus-shared/domain/documents/components/grids/documents-grid/custom-cell-editor.component';
import { IndividualSettingService } from 'app/nexus-core/services/domain/core/individual-setting.service';
import { AgGridColDefHelper } from 'app/nexus-core/helpers/ag-grid-col-def.helper';
import { AgGridColDefExtras } from 'app/nexus-shared/models/ag-grid-col-def-extras.model';
import { LocationService } from 'app/nexus-core/services/location.service';
import { LocationModel } from 'app/nexus-shared/domain/locations';
import { CommonBaseAuditUserModel } from 'app/nexus-shared/domain/contacts/models/common-base-audit-user.model';
import { FolderSearchModel } from 'app/nexus-shared/domain/documents/models/folder-search.model';
import { DocumentRoutingKeyConstants } from 'app/modules/documents/routing/document-routing-key.constants';
import { FolderSettingsModel } from 'app/nexus-shared/domain/documents/models/folder-settings.model';

@Component({
    selector: 'gtn-documents-grid',
    templateUrl: './documents-grid.component.html',
    styleUrls: ['./documents-grid.component.scss'],
    animations: [
        NexusAnimations.fadeIn
    ]
})
export class DocumentsGridComponent extends BaseEnterpriseGridComponent<DocumentGridViewModel> implements OnInit, OnChanges {
    static readonly documentsNoRowsOverlay = `<span>There are no folders or files; right click or use the add button (+) to upload files.</span>`;
    static readonly basClientAreaOverlay = `<span>Please select a client folder</span>`;

    @Input() selectedFolder: FolderModel;
    @Input() documentsUserAuth: DocumentsAuthModel;
    @Input() folderFavorites: FolderFavoriteModel[];
    @Input() isClientAreaFolder: boolean;
    @Input() showFolderTagIcon!: boolean;
    @Input() isFolderTagSearch: boolean;

    @Output() folderRowClick: EventEmitter<FolderModel> = new EventEmitter();
    @Output() documentRowClick: EventEmitter<DocumentModel> = new EventEmitter();
    @Output() itemsDeleted: EventEmitter<void> = new EventEmitter();
    @Output() addFolderFavorite: EventEmitter<FolderModel> = new EventEmitter();
    @Output() removeFolderFavorite: EventEmitter<FolderModel> = new EventEmitter();
    @Output() documentFolderMove: EventEmitter<DocumentFolderMoveViewModel> = new EventEmitter();
    @Output() viewFolderProperties: EventEmitter<FolderModel> = new EventEmitter();
    @Output() externalFileDrop: EventEmitter<File[]> = new EventEmitter<File[]>();
    @Output() folderTagClick: EventEmitter<void> = new EventEmitter();
    @Output() tagFolderSearch: EventEmitter<FolderSearchModel> = new EventEmitter();

    gridName: string = 'documents_grid';
    gridVersion: string = '1.1.3';
    areaTypesEnum = AreaTypesEnum;
    isClientRootFolder: boolean;
    clientDocument: ClientDocumentModel;
    confirmActionType: ConfirmActionTypesEnum;
    confirmActionEntities: string[];
    countryList: LocationModel[];
    disableDragAndDrop: boolean = false;
    documentToDelete: DocumentModel;
    documentsToDelete: DocumentModel[];
    documentToCheckout: DocumentModel;
    documentsToCheckOut: DocumentModel[] = [];
    documentPreview: DocumentModel;
    documentStatuses = DocumentStatusesEnum;
    editingNode: IRowNode;
    filePreview: FileViewModel;
    folderToDelete: FolderModel;
    isDragHover: boolean = false;
    isEntityCopied: boolean;
    isDocumentsSelected: boolean = false;
    isCheckoutDocuments: boolean = false;
    renameRowKey: string;
    rowDragHoverFolder: IRowNode;
    showConfirmModal: boolean = false;
    showPreview: boolean = false;

    constructor(
        protected individualSettingService: IndividualSettingService,
        private folderDocumentGridService: FolderDocumentGridService,
        private documentFolderService: DocumentFolderService,
        private documentService: DocumentService,
        private documentProcessService: DocumentProcessService,
        private locationService: LocationService,
        private toastService: ToastService) {
        super(individualSettingService);
        this.documentProcessService.documentService = this.documentService;
    }

    ngOnChanges(changes: SimpleChangesTyped<this>) {
        super.ngOnChanges(changes);
        if (changes.selectedFolder && this.selectedFolder) {
            this.isClientRootFolder = DocumentRoutingKeyConstants.areaKeys.clients === this.selectedFolder.folderKey;
            this.clientDocument = <ClientDocumentModel>this.selectedFolder.clientFolder;
        }
    }

    ngOnInit(): void {
        this.gridSecondaryIdentifier = EnumHelper.getDisplayName(AreaTypesEnum, this.selectedFolder?.areaType);
        this.countryList = this.locationService.countryList;
        const that = this;
        const gridOptions: GridOptions = {
            rowDragEntireRow: true,
            rowDragMultiRow: true,
            suppressContextMenu: false,
            stopEditingWhenCellsLoseFocus: true,
            allowContextMenuWithControlKey: true,
            defaultColDef: Object.assign(AgGridColDefHelper.defaultColDef, {
                enableRowGroup: false
            }),
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: DocumentsGridStatusBarComponent,
                        align: 'left'
                    },
                    AgGridHelper.filteredRowCount,
                    AgGridHelper.selectedRowCount,
                    AgGridHelper.lastUpdatedDateTime,
                ]
            },
            onRowDragMove: that.onRowDragMove.bind(that),
            onRowDragLeave: that.onRowDragLeave.bind(that),
            onRowDragEnd: that.onRowDragEnd.bind(that),
            suppressRowClickSelection: true,
            overlayNoRowsTemplate: that.isClientAreaFolder ? DocumentsGridComponent.basClientAreaOverlay : DocumentsGridComponent.documentsNoRowsOverlay,
            noRowsOverlayComponent: null,
            rowSelection: AgGridHelper.rowSelectionMultiple,
            onSelectionChanged(event: SelectionChangedEvent) {
                that.isDocumentsSelected = !!event.api.getSelectedNodes()?.length;
                that.isCheckoutDocuments = !!event.api.getSelectedNodes().filter(x => x.data.status !== DocumentStatusesEnum.CheckedOut)?.length;
            },
            isRowSelectable: params => {
                if (params.data?.type === DocumentsGridViewTypes.File) {
                    return params.data?.document.status !== DocumentStatusesEnum.ContainsError && params.data?.document.status !== DocumentStatusesEnum.UploadFailed
                        && params.data?.document.status !== DocumentStatusesEnum.DownloadFailed;
                }
                return false;
            },
            getRowId: params => {
                return params.data.key;
            },
            getRowStyle: params => {
                const status = params?.node?.data?.document?.status;
                if (params.node?.data?.document && (!params.node.data.document?.isVirusScanned || status === DocumentStatusesEnum.ContainsError || status === DocumentStatusesEnum.UploadFailed || status === DocumentStatusesEnum.DownloadFailed)) {
                    return { color: '#d9d9d9', fontStyle: 'italic' };
                } else if (params.node?.data?.type === DocumentsGridViewTypes.File && status === DocumentStatusesEnum.CheckedOut) {
                    return { backgroundColor: 'rgba(247, 150, 70, .4)' };
                } else if (params.node[CustomRowPropertyConstants.dragHover]) {
                    return { backgroundColor: '#e5e5ff' };
                }
                return { backgroundColor: 'inherit', color: 'inherit', fontStyle: 'normal' };
            },
            onCellValueChanged: that.onDocumentNameChanged.bind(that),
            onCellEditingStarted(event: CellEditingStartedEvent<CellEditingStartedEvent>) {
                that.disableDragAndDrop = true;
            },
            onCellEditingStopped(event: CellEditingStoppedEvent<CellEditingStoppedEvent>) {
                that.disableDragAndDrop = false;
            },
            getContextMenuItems: (params) => {
                if (!this.isClientAreaFolder || (this.isFolderTagSearch && this.selectedFolder?.folderKey)) {
                    return that.setContextMenu(params);
                }
                return null;
            }
        };
        super.ngOnInit(gridOptions);

        this.isEntityCopied = this.folderDocumentGridService.isEntityCopied;
        this.subscriptions.add(this.folderDocumentGridService.documentEntityCopy$.subscribe(isCopied => {
            this.isEntityCopied = isCopied;
        }));

        this.subscriptions.add(this.documentProcessService.documentGridEvent$.subscribe(document => {
            if (this.selectedFolder.folderKey === document.folderKey) {
                this.updateGridNode(document);
            }
        }));
    }

    onCreateNewFolderClicked(): void {
        this.folderDocumentGridService.createFolder(this.selectedFolder).subscribe(res => {
            this.renameRowKey = res;
            this.tagFolderSearch.emit(<FolderSearchModel>{ folderKeys: [res] });
        }, err => {
            this.toastService.showErrorToast(err);
        });
    }

    onCancelClicked(): void {
        this.showConfirmModal = false;
        this.documentToDelete = null;
        this.folderToDelete = null;
        this.documentToCheckout = null;
        this.documentsToCheckOut = [];
    }

    onConfirmClicked(): void {
        if (this.folderToDelete) {
            this.deleteFolder();
        } else if (this.documentToDelete) {
            this.deleteDocument();
        } else if (this.documentsToDelete?.length) {
            this.deleteDocuments();
        } else if (this.documentToCheckout) {
            this.checkoutDocument();
        } else if (this.documentsToCheckOut?.length) {
            this.checkoutDocuments();
        }
    }

    onCheckoutDocuments(): void {
        this.gridApi.getSelectedNodes().forEach(x => {
            if (x.data.status !== DocumentStatusesEnum.CheckedOut) {
                const document = new DocumentModel(x.data.document);
                document.eventType = DocumentEventTypesEnum.CheckOut;
                document.status = DocumentStatusesEnum.CheckedOut;
                this.documentsToCheckOut.push(document);
            }
        });

        this.confirmActionEntities = this.documentsToCheckOut.map(x => `${x.name}.${x.file.extension}`);
        this.confirmActionType = ConfirmActionTypesEnum.CheckOut;
        this.showConfirmModal = true;
    }

    onCopyDocuments(): void {
        const selectedDocuments = this.gridApi.getSelectedNodes().map(x => new DocumentModel(x.data.document));
        if (this.gridApi.getSelectedNodes()?.length > 1) {
            this.folderDocumentGridService.copyMultipleDocuments(selectedDocuments);
            this.toastService.showMessageToast('Files copied');
        } else {
            this.folderDocumentGridService.copyDocumentEntity(selectedDocuments[0]);
            this.toastService.showMessageToast('File copied');
        }
    }

    onDeleteDocument(document: DocumentModel): void {
        this.documentToDelete = new DocumentModel(document);
        this.confirmActionType = ConfirmActionTypesEnum.DeleteDocument;
        this.confirmActionEntities = [`${this.documentToDelete.name}.${this.documentToDelete.file.extension}`];
        this.showConfirmModal = true;
    }

    onDeleteDocuments(): void {
        const selectedDocuments = this.gridApi.getSelectedNodes().filter(x => x.data.status !== DocumentStatusesEnum.CheckedOut);
        if (selectedDocuments?.length > 1) {
            this.documentsToDelete = selectedDocuments.map(x => new DocumentModel(x.data.document));
            this.confirmActionType = ConfirmActionTypesEnum.DeleteDocument;
            this.confirmActionEntities = this.documentsToDelete.map(x => `${x.name}.${x.file.extension}`);
            this.showConfirmModal = true;
        } else {
            this.documentToDelete = new DocumentModel();
            this.onDeleteDocument(selectedDocuments[0].data.document);
        }
    }

    onDocumentNameChanged(event: CellEditingStoppedEvent): void {
        if (event.data.type === DocumentsGridViewTypes.File) {
            const document = new DocumentModel(event.data.document);
            document.name = event.newValue;
            document.eventType = DocumentEventTypesEnum.Rename;
            this.documentService.updateMetadata(document).pipe(finalize(() => {
                this.folderDocumentGridService.refreshDocuments(true);
            })).subscribe(_ => {
                },
                err => {
                    this.toastService.showErrorToast(err);
                });
        } else if (event.data.type === DocumentsGridViewTypes.Folder) {
            event.data.folder.name = event.newValue;
            this.tagFolderSearch.emit(<FolderSearchModel>{ folderKeys: [event.data.folder.folderKey] });
            this.folderDocumentGridService.updateFolder(event.data.folder);
        }
    }

    onDownloadClicked(rowNode: IRowNode): void {
        this.documentProcessService.downloadDocuments([rowNode.data.document]);
        this.setProcessingDocuments([rowNode]);
    }

    onDownloadMultipleClicked(includeCheckout: boolean = true): void {
        const nodes = this.gridApi.getSelectedNodes().filter(y => y.data.status !== DocumentStatusesEnum.CheckedOut || includeCheckout);
        const documentDownloads: DocumentModel[] = nodes.map(x => x.data.document);
        this.documentProcessService.downloadDocuments(documentDownloads);
        this.setProcessingDocuments(nodes);
    }

    onExternalFileDrop(files: FileList): void {
        if (!this.isClientAreaFolder) {
            this.externalFileDrop.emit(FileHelper.convertFilesToArray(files));
        }
    }

    onPasteClicked(folder: FolderModel = null): void {
        const entityToPaste = this.folderDocumentGridService.getPastedDocumentEntity();
        const documentsToPaste = this.folderDocumentGridService.getPastedDocuments();
        const folderToPasteTo = folder ?? this.selectedFolder;
        if (entityToPaste?.hasOwnProperty(ObjectHelper.nameOf<DocumentModel>('documentKey'))) {
            this.folderDocumentGridService.onDocumentCopied(entityToPaste as DocumentModel, folderToPasteTo);
        } else if (entityToPaste?.hasOwnProperty(ObjectHelper.nameOf<FolderModel>('parentFolderKey'))) {
            // no folder copy yet
            this.toastService.showMessageToast('Folder copy is not supported yet');
        } else if (documentsToPaste?.length) {
            this.folderDocumentGridService.onDocumentsCopied(documentsToPaste, folderToPasteTo);
        }
    }

    onPreviewClosed(): void {
        this.filePreview = null;
        this.documentPreview = null;
        this.showPreview = false;
    }

    onPreviewCheckoutClicked(): void {
        const documentNode = this.gridApi.getRowNode(this.documentPreview.documentKey);
        if (documentNode?.data?.document?.isVirusScanned && documentNode?.data?.document?.status !== DocumentStatusesEnum.CheckedOut) {
            this.documentToCheckout = new DocumentModel(documentNode.data.document);
            this.checkoutDocument();
        }
    }

    onPreviewDownloadClicked(): void {
        const documentNode = this.gridApi.getRowNode(this.documentPreview.documentKey);
        if (documentNode?.data?.document?.isVirusScanned) {
            this.onDownloadClicked(documentNode);
        }
    }

    onRowClicked(row: RowClickedEvent): void {
        const target = row.event.target as HTMLElement;
        if (!target.classList.contains(AgGridHelper.agGridInputFieldClass)) {
            if (row?.data?.type === DocumentsGridViewTypes.File) {
                this.documentRowClick.emit(row.data?.document);
            } else if (row?.data?.type === DocumentsGridViewTypes.Folder) {
                this.folderRowClick.emit(row.data?.folder);
            }
        }
    }

    onRowDragEnd(event: RowDragEndEvent): void {
        this.resetHoverState();

        // coming from grouped templates grid
        if (!event?.node?.data || event.node.data.areaType === AreaTypesEnum.FileTemplates || event.node.data.areaType === AreaTypesEnum.FolderTemplates) {
            return;
        }

        if (event.node?.data.type === DocumentsGridViewTypes.Folder || event.node.data.folderKey) {
            //folder drag
            const folderMoved: FolderModel = event.node.data?.type ? event.node.data.folder : event.node.data;
            let folderMovedTo: FolderModel;
            if (!event.overNode) {
                folderMovedTo = this.selectedFolder;
            } else {
                folderMovedTo = event.overNode.data.folder;
            }
            if (folderMoved?.parentFolderKey === folderMovedTo.folderKey || folderMoved?.folderKey === folderMovedTo.folderKey) {
                return;
            }

            const documentFolderMove = new DocumentFolderMoveViewModel(null, folderMovedTo);

            if (event.node?.data?.type === DocumentsGridViewTypes.Folder) {
                documentFolderMove.folderMovedFrom = this.selectedFolder;
            } else {
                documentFolderMove.folderMovedFromParentFolderKey = event.node.data.parentFolderKey;
            }

            documentFolderMove.folderMoved = folderMoved;

            this.documentFolderMove.emit(documentFolderMove);
        } else if (event.overNode?.data?.type === DocumentsGridViewTypes.Folder) {
            // file drag
            const documentFolderMove = new DocumentFolderMoveViewModel(this.selectedFolder, event.overNode.data.folder);
            if (event?.nodes.length) {
                documentFolderMove.documentsMoved = event.nodes.map(x => x.data.document);
            } else {
                documentFolderMove.documentsMoved = [event.node.data.document];
            }

            this.documentFolderMove.emit(documentFolderMove);
        }
    }

    onRowDragLeave(event: RowDragLeaveEvent): void {
        this.resetHoverState();
    }

    onRowDragMove(event: RowDragMoveEvent): void {
        if (event.node?.allLeafChildren?.length) {
            return;
        }
        if (event.overNode?.data?.key === this.rowDragHoverFolder?.data?.key) {
            return;
        }

        if ((!event?.overNode || event.overNode.data.type === DocumentsGridViewTypes.File)) {
            this.resetHoverState();
            return;
        }

        const folderKey: string = event.node.data?.type === DocumentsGridViewTypes.File ? null : event.node.data?.type === DocumentsGridViewTypes.Folder ? event.node.data.folder.folderKey : event.node.data.folderKey;
        if (folderKey && folderKey === event.overNode.data.folder.folderKey) {
            this.resetHoverState();
            return;
        }

        const refreshNodes: IRowNode[] = event?.overNode ? [event.overNode] : [];
        if (this.rowDragHoverFolder) {
            this.rowDragHoverFolder[CustomRowPropertyConstants.dragHover] = false;
            refreshNodes.push(this.gridApi.getRowNode(this.rowDragHoverFolder.data.key));
        }
        event.overNode[CustomRowPropertyConstants.dragHover] = true;
        this.rowDragHoverFolder = event.overNode;
        this.redrawRows(refreshNodes);
    }

    setProcessingDocuments(nodes: IRowNode[]) {
        nodes.forEach(x => {
            x.data.document.isVirusScanned = false;
            x.setSelected(false);
        });
        this.redrawRows(nodes);
    }

    setDefaultSortModel() {
        this.defaultSortModel = [
            { colId: ObjectHelper.nameOf<DocumentGridViewModel>('type'), sort: 'desc', sortIndex: 0 },
            { colId: ObjectHelper.nameOf<DocumentGridViewModel>('name'), sort: 'asc', sortIndex: 1 },
        ];
    }

    protected setColumnDefinitions(): ColDef[] {
        return [
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOf<DocumentGridViewModel>('name'), 'File Name',
                [AgGridColDefHelper.checkboxSelectColumn, AgGridColDefHelper.editColumn], <AgGridColDefExtras>{
                    tooltip: true,
                    colDef: {
                        cellRenderer: params => {
                            if (params.data?.type === DocumentsGridViewTypes.Folder) {
                                return `<span><i class="fas fa-folder mr-1"></i></span> ${params.value}`;
                            } else if (params?.data?.type === DocumentsGridViewTypes.File) {
                                let fileTypeClass: string;
                                const status: DocumentStatusesEnum = params?.data?.document?.status;
                                if ((!params.data?.document.isVirusScanned && status !== DocumentStatusesEnum.UploadFailed) || status === DocumentStatusesEnum.Pending) {
                                    fileTypeClass = 'fas fa-rotate regular-text-color';
                                } else if (params.data?.document.virusScanError !== VirusScanErrorsEnum.None || status === DocumentStatusesEnum.ContainsError || status === DocumentStatusesEnum.UploadFailed) {
                                    fileTypeClass = 'fas fa-circle-exclamation regular-text-color';
                                } else {
                                    fileTypeClass = DocumentsHelper.getFileTypeIcon(params.data?.document.file.extension);
                                }
                                return `<span><i class="${fileTypeClass} mr-1"></i></span> ${params.value}`;
                            }
                            return null;
                        },
                        pinned: false,
                        width: 300,
                        minWidth: null,
                        maxWidth: null,
                        sortable: true,
                        resizable: true,
                        filter: AgGridHelper.textColumnFilter,
                        suppressColumnsToolPanel: false,
                        suppressFiltersToolPanel: false,
                        cellClass: null,
                        useValueFormatterForExport: false,
                        cellEditor: CustomCellEditorComponent,
                        checkboxSelection: params => {
                            return params.data?.document?.isVirusScanned;
                        },
                        comparator: AgGridHelper.stringComparator
                    }
                }),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOf<DocumentGridViewModel>('type'), 'File Type', null, <AgGridColDefExtras>{
                colDef: {
                    valueGetter: params => {
                        if (params.data?.type === DocumentsGridViewTypes.Folder) {
                            return EnumHelper.getDisplayName(DocumentsGridViewTypes, params?.data?.type);
                        } else {
                            return DocumentsHelper.getFileTypeName(params.data?.document.file.extension);
                        }
                    },
                    comparator: DocumentsHelper.fileTypeSort
                }
            }),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOf<DocumentGridViewModel>('size'), 'Size', null, <AgGridColDefExtras>{
                colDef: {
                    cellRenderer: params => {
                        if (params.value) {
                            return DocumentsHelper.formatBytes(params.value);
                        }
                        return null;
                    },
                    maxWidth: 80,
                    hide: this.selectedFolder.areaType === AreaTypesEnum.FolderTemplates
                }
            }),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOf<DocumentGridViewModel>('status'), 'Status', null, <AgGridColDefExtras>{
                colDef: {
                    valueGetter: params => {
                        if (params?.data?.status && params.data?.type === DocumentsGridViewTypes.Folder) {
                            return EnumHelper.getDisplayName(FolderStatusesEnum, params.data?.status);
                        } else if (params?.data?.status && params.data?.type === DocumentsGridViewTypes.File) {
                            return EnumHelper.getDisplayName(DocumentStatusesEnum, params.data?.status);
                        }
                        return null;
                    },
                    hide: true,
                    filter: false
                }
            }),
            AgGridColDefHelper.getDateTimeColDef(ObjectHelper.nameOfSubProperty<DocumentGridViewModel, CommonBaseAuditUserModel>('createdByUser', 'changeDateTime'), 'Created On'),
            AgGridColDefHelper.getDateTimeColDef(ObjectHelper.nameOfSubProperty<DocumentGridViewModel, CommonBaseAuditUserModel>('lastModifiedByUser', 'changeDateTime'), 'Last Modified'),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOfSubProperty<DocumentGridViewModel, CommonBaseAuditUserModel>('lastModifiedByUser', 'fullName'), 'Last Modified By'),
            AgGridColDefHelper.getIconCheckboxColDef(ObjectHelper.nameOfSubProperties<DocumentGridViewModel, FolderModel, FolderSettingsModel>('folder', 'folderSettings', 'isReadOnly'), 'Read-only', <AgGridColDefExtras>{
                hide: this.selectedFolder.areaType !== AreaTypesEnum.FolderTemplates
            }),
            AgGridColDefHelper.getNumberColDef(ObjectHelper.nameOfSubProperties<DocumentGridViewModel, DocumentModel, ClientDocumentModel>('document', 'clientDocument', 'taxYear'),
                'Tax year', <AgGridColDefExtras>{
                    hide: true,
                    colDef: {
                        valueGetter: params => {
                            return DocumentsHelper.getFiscalTaxYear(params?.data?.document?.clientDocument);
                        },
                        useValueFormatterForExport: false
                    },
                }),
            AgGridColDefHelper.getCountryColDef(ObjectHelper.nameOfSubProperties<DocumentGridViewModel, DocumentModel, ClientDocumentModel>('document', 'clientDocument', 'countryId'), 'Country', this.countryList),
            AgGridColDefHelper.getEnumColumnDef(ObjectHelper.nameOfSubProperties<DocumentGridViewModel, DocumentModel, ClientDocumentModel>('document', 'clientDocument', 'type'), 'Document Type', ClientDocumentTypesEnum),
            AgGridColDefHelper.getIconCheckboxColDef(ObjectHelper.nameOfSubProperties<DocumentGridViewModel, FolderModel, FolderSettingsModel>('folder', 'folderSettings', 'canAddChildFolders'), 'Folder Create', <AgGridColDefExtras>{ hide: this.selectedFolder.areaType !== AreaTypesEnum.FolderTemplates }),
            AgGridColDefHelper.getIconCheckboxColDef(ObjectHelper.nameOfSubProperties<DocumentGridViewModel, FolderModel, FolderSettingsModel>('folder', 'folderSettings', 'canAddDocuments'), 'Document Create', <AgGridColDefExtras>{ hide: this.selectedFolder.areaType !== AreaTypesEnum.FolderTemplates }),
        ];
    }

    protected setRowData(): Observable<DocumentGridViewModel[]> {
        return of(this.data).pipe(finalize(() => {
            if (this.renameRowKey) {
                const node = this.gridApi.getRowNode(this.renameRowKey);
                this.gridApi.startEditingCell({
                    rowIndex: node.rowIndex,
                    colKey: ObjectHelper.nameOf<DocumentGridViewModel>('name')
                });
                this.renameRowKey = null;
            }
            this.gridApi.deselectAll();
        }));
    }

    private checkoutDocument(): void {
        this.documentToCheckout.eventType = DocumentEventTypesEnum.CheckOut;
        this.documentToCheckout.status = DocumentStatusesEnum.CheckedOut;
        this.documentService.updateMetadata(this.documentToCheckout).pipe(finalize(() => {
            this.showConfirmModal = false;
            this.documentToCheckout = null;
        })).subscribe(_ => {
            this.setProcessingDocuments([this.gridApi.getRowNode(this.documentToCheckout.documentKey)]);
            this.documentProcessService.downloadDocuments([this.documentToCheckout]);
        }, err => {
            this.toastService.showErrorToast(err);
        });
    }

    private checkoutDocuments(): void {
        SpinnerService.start();
        this.documentService.bulkMetadata(this.documentsToCheckOut).pipe(finalize(() => {
            this.showConfirmModal = false;
            this.folderDocumentGridService.refreshDocuments(true);
            this.documentsToCheckOut = [];
            SpinnerService.stop();
        })).subscribe(_ => {
            this.onDownloadMultipleClicked(false);
        }, err => {
            this.toastService.showErrorToast(err);
        });
    }

    private resetHoverState(): void {
        if (this.rowDragHoverFolder) {
            this.rowDragHoverFolder[CustomRowPropertyConstants.dragHover] = false;
            this.redrawRows([this.rowDragHoverFolder]);
            this.rowDragHoverFolder = null;
        }
    }

    private deleteDocument(): void {
        this.documentService.delete(new DocumentDeleteModel(this.documentToDelete)).pipe(finalize(() => {
            this.folderDocumentGridService.refreshDocuments(true);
            this.showConfirmModal = false;
            this.documentToDelete = null;
        })).subscribe(_ => {
            this.itemsDeleted.emit();
        }, err => {
            this.toastService.showErrorToast(err);
        });
    }

    private deleteDocuments(): void {
        const observables: Observable<boolean>[] = [];
        this.documentsToDelete.forEach(x => {
            observables.push(this.documentService.delete(new DocumentDeleteModel(x)));
        });
        SpinnerService.start();
        forkJoin(observables).pipe(finalize(() => {
            this.folderDocumentGridService.refreshDocuments(true);
            this.showConfirmModal = false;
            this.documentsToDelete = null;
            SpinnerService.stop();
        })).subscribe(_ => {
            this.itemsDeleted.emit();
        }, err => {
            this.toastService.showErrorToast(err);
        });
    }

    private deleteFolder(): void {
        this.documentFolderService.deleteFolder(this.folderToDelete.folderKey).pipe(finalize(() => {
            if (this.isFolderTagSearch) {
                this.tagFolderSearch.emit(<FolderSearchModel>{ folderKeys: [this.folderToDelete.folderKey] });
            }
            this.folderDocumentGridService.refreshDocuments();
            this.showConfirmModal = false;
            this.folderToDelete = null;
        })).subscribe(_ => {
            this.itemsDeleted.emit();
        }, err => {
            this.toastService.showErrorToast(err);
        });
    }

    private discardCheckOut(document: DocumentModel): void {
        document.eventType = DocumentEventTypesEnum.CheckOutDiscarded;
        document.status = DocumentStatusesEnum.Active;
        this.documentService.updateMetadata(document).pipe(finalize(() => {
            this.folderDocumentGridService.refreshDocuments(true);
        })).subscribe(_ => {
        }, err => {
            this.toastService.showMessageToast(err);
        });
    }

    private redrawRows(nodes: IRowNode[]): void {
        if (this.gridApi) {
            this.gridApi.redrawRows({
                rowNodes: nodes
            });
        }
    }

    private updateGridNode(document: DocumentModel): void {
        const node = this.gridApi.getRowNode(document.documentKey);
        if (node) {
            if (document.status === DocumentStatusesEnum.ContainsError) {
                node.selectable = false;
            }
            node.data = new DocumentGridViewModel(document);
            this.redrawRows([node]);
        } else {
            this.gridApi.applyTransaction({
                add: [new DocumentGridViewModel(document)]
            });
        }
    }

    private setContextMenu(params: GetContextMenuItemsParams): MenuItemDef[] {
        if (this.isClientRootFolder) {
            return;
        }
        let contextMenuItems = [];
        const isLastModifiedUser: boolean = params.node?.data?.lastModifiedByUser?.userKey === this.documentsUserAuth.userKey;
        const isFile = params?.node?.data?.type === DocumentsGridViewTypes.File;
        const isFolder = params?.node?.data?.type == DocumentsGridViewTypes.Folder;
        if (isFile && !params.node.data.document.isVirusScanned && params.node.data.document.status !== DocumentStatusesEnum.UploadFailed) {
            return;
        }

        if (isFile) {
            params.node.setSelected(true);
        }

        let isMultipleFiles: boolean = this.gridApi.getSelectedNodes()?.length > 1;
        const isCheckOut = !!this.gridApi.getSelectedNodes().filter(x => x.data.status !== DocumentStatusesEnum.CheckedOut)?.length;

        if (isFile) {
            if (!isMultipleFiles) {
                if (params.node.data.document.status === DocumentStatusesEnum.ContainsError || params.node.data.document.status === DocumentStatusesEnum.UploadFailed
                    || params.node.data.document.status === DocumentStatusesEnum.DownloadFailed) {
                    contextMenuItems = [
                        this.contextMenuItems.upload(params),
                        this.contextMenuItems.restorePreviousVersion(params),
                        'separator',
                        this.contextMenuItems.deleteDocument(params)
                    ];
                    return contextMenuItems;
                }
                const isCheckedOut = params.node.data.status === DocumentStatusesEnum.CheckedOut;
                if (!isCheckedOut) {
                    contextMenuItems = [
                        this.contextMenuItems.copy(params),
                        'separator',
                        this.contextMenuItems.previewFile(params),
                        this.contextMenuItems.download(params),
                    ];

                    if (this.documentsUserAuth.writeAccess) {
                        contextMenuItems.push(
                            this.contextMenuItems.upload(params),
                            'separator',
                            this.contextMenuItems.checkout(params),
                            this.contextMenuItems.rename(params),
                            'separator',
                            this.contextMenuItems.deleteDocument(params)
                        );
                    }
                } else {
                    contextMenuItems = [
                        this.contextMenuItems.copy(params),
                        'separator',
                        this.contextMenuItems.previewFile(params),
                        this.contextMenuItems.download(params),
                    ];
                    if (isLastModifiedUser || this.documentsUserAuth.isAdmin) {
                        contextMenuItems.push(
                            this.contextMenuItems.discardCheckOut(params),
                            this.contextMenuItems.checkIn(params)
                        );
                    }
                    contextMenuItems.push(
                        this.contextMenuItems.checkoutDisabled(params)
                    );
                }
            } else {
                contextMenuItems = [
                    this.contextMenuItems.copyMultiple(params),
                    this.contextMenuItems.downloadMultiple(params),
                ];
                if (isCheckOut) {
                    contextMenuItems.push(
                        'separator',
                        this.contextMenuItems.checkoutMultiple(params),
                        this.contextMenuItems.deleteMultipleDocuments(params)
                    );
                }
            }
        } else if (isFolder) {
            if (!params.node.data.folder.isReadOnly && this.documentsUserAuth.writeAccess) {
                contextMenuItems.push(
                    this.contextMenuItems.rename(params),
                    this.contextMenuItems.deleteFolder(params)
                );
            }

            if (this.isEntityCopied) {
                contextMenuItems.push(
                    this.contextMenuItems.paste(params)
                );
            }

            const isFavorite: boolean = !!this.folderFavorites.find(x => x.folder.folderKey === params.node.data.folder.folderKey);
            if (isFavorite) {
                contextMenuItems.push(
                    this.contextMenuItems.removeFolderFavorite(params)
                );
            } else {
                contextMenuItems.push(
                    this.contextMenuItems.addFolderFavorite(params)
                );
            }

            if (this.documentsUserAuth.isAdmin) {
                contextMenuItems.push(
                    'separator',
                    this.contextMenuItems.viewFolderProperties(params)
                );
            }
        } else {
            isMultipleFiles = !!this.gridApi.getSelectedNodes()?.length;
            if (this.documentsUserAuth.writeAccess) {
                if (this.selectedFolder?.folderSettings.canAddChildFolders) {
                    contextMenuItems.push(this.contextMenuItems.newFolder(params));
                }

                if (this.selectedFolder?.folderSettings.canAddDocuments) {
                    contextMenuItems.push(this.contextMenuItems.newFile(params));

                }

                if (this.isEntityCopied) {
                    contextMenuItems.push(
                        'separator',
                        this.contextMenuItems.paste(params)
                    );
                }
            }

            if (isMultipleFiles) {
                contextMenuItems.push(
                    'separator',
                    this.contextMenuItems.copyMultiple(params)
                );
            }

            if (isCheckOut) {
                contextMenuItems.push(
                    'separator',
                    this.contextMenuItems.checkoutMultiple(params),
                );
            }
            if (this.folderFavorites.find(x => x.folder.folderKey === this.selectedFolder.folderKey)) {
                contextMenuItems.push(this.contextMenuItems.removeFolderFavorite(params));
            } else {
                contextMenuItems.push(this.contextMenuItems.addFolderFavorite(params));
            }
        }
        return contextMenuItems;
    }

    private contextMenuItems: IDocumentMenuItem<DocumentGridViewModel> = {
        addFolderFavorite: (params) => {
            return {
                name: 'Add as favorite',
                action: () => {
                    if (params?.node?.data?.folder) {
                        this.addFolderFavorite.emit(params.node.data.folder);
                    } else {
                        this.addFolderFavorite.emit(this.selectedFolder);
                    }
                },
                icon: `<i class="fas fa-folder-heart"></i>`
            };
        },
        checkout: (params) => {
            return {
                name: 'Check out file',
                icon: `<i class='fas fa-file-check'></i>`,
                action: () => {
                    this.documentToCheckout = new DocumentModel(params.node.data.document);

                    this.confirmActionType = ConfirmActionTypesEnum.CheckOut;
                    this.confirmActionEntities = [`${this.documentToCheckout.name}.${this.documentToCheckout.file.extension}`];
                    this.showConfirmModal = true;
                }
            };
        },
        checkoutMultiple: (params) => {
            return {
                name: 'Check out files',
                icon: `<i class='fas fa-file-check'></i>`,
                action: () => {
                    this.onCheckoutDocuments();
                }
            };
        },
        checkoutDisabled: (params) => {
            return {
                name: `Checked out (${params.node.data.document.lastModifiedByUser.fullName})`,
                disabled: true,
                icon: `<i class='fas fa-user-lock'></i>`
            };
        },
        checkIn: (params) => {
            return {
                name: 'Check in document',
                action: () => {
                    const document = new DocumentModel(params.node.data.document);
                    document.eventType = DocumentEventTypesEnum.CheckIn;
                    this.addButtonClick.emit(document);
                },
                icon: `<i class='fas fa-file-import'></i>`
            };
        },
        copy: (params) => {
            return {
                name: 'Copy file',
                icon: `<i class='far fa-copy'></i>`,
                action: () => {
                    this.folderDocumentGridService.copyDocumentEntity(params.node.data.document);
                    this.toastService.showMessageToast('File Copied');
                }
            };
        },
        copyMultiple: (params) => {
            return {
                name: 'Copy files',
                icon: `<i class='far fa-copy'></i>`,
                action: () => {
                    this.folderDocumentGridService.copyMultipleDocuments(this.gridApi.getSelectedNodes().map(x => new DocumentModel(x.data.document)));
                    this.toastService.showMessageToast('Files Copied');
                }
            };
        },
        deleteDocument: (params) => {
            return {
                name: 'Delete file',
                icon: `<i class='fas fa-trash'></i>`,
                action: () => {
                    this.onDeleteDocument(params.node.data.document);
                }
            };
        },
        deleteMultipleDocuments: (params) => {
            return {
                name: 'Delete files',
                icon: `<i class='fas fa-trash'></i>`,
                action: () => {
                    this.onDeleteDocuments();
                }
            };
        },
        deleteFolder: (params) => {
            return {
                name: 'Delete folder',
                icon: `<i class='fas fa-trash'></i>`,
                action: () => {
                    this.folderToDelete = new FolderModel(params.node.data.folder);
                    this.confirmActionType = ConfirmActionTypesEnum.DeleteFolder;
                    this.confirmActionEntities = [params.node.data.folder.name];
                    this.showConfirmModal = true;
                }
            };
        },
        discardCheckOut: (params) => {
            return {
                name: 'Discard checkout',
                action: () => {
                    this.discardCheckOut(params.node.data.document);
                    this.toastService.showMessageToast('Check out discarded');
                },
                icon: `<i class='fas fa-file-slash'></i>`
            };
        },
        download: (params) => {
            return {
                name: 'Download file',
                icon: `<i class='fas fa-down-to-line'></i>`,
                action: () => {
                    this.onDownloadClicked(params.node);
                }
            };
        },
        downloadMultiple: (params) => {
            return {
                name: 'Download files',
                icon: `<i class='fas fa-down-to-line'></i>`,
                action: () => {
                    this.onDownloadMultipleClicked();
                }
            };
        },
        newFile: (params) => {
            return {
                name: 'Upload file(s)',
                action: () => {
                    this.onAddButtonClicked();
                },
                icon: `<i class="fa-solid fa-file-plus"></i>`
            };
        },
        newFolder: (params) => {
            return {
                name: 'New folder',
                action: () => {
                    this.onCreateNewFolderClicked();
                },
                icon: `<i class='fas fa-folder-plus'></i>`
            };
        },
        paste: (params) => {
            return {
                name: 'Paste',
                action: () => {
                    // paste document/folder
                    this.onPasteClicked(params.node?.data?.folder);

                },
                icon: `<i class='fas fa-paste'></i>`,
            };
        },
        removeFolderFavorite: (params) => {
            return {
                name: 'Remove as favorite',
                action: () => {
                    if (params?.node?.data?.folder) {
                        this.removeFolderFavorite.emit(params.node.data.folder);
                    } else {
                        this.removeFolderFavorite.emit(this.selectedFolder);
                    }
                },
                icon: `<i class='fas fa-folder-minus'></i>`,
            };
        },
        rename: (params) => {
            return {
                name: 'Rename',
                icon: `<i class='fas fa-edit'></i>`,
                action: () => {
                    this.gridApi.startEditingCell({
                        rowIndex: params.node.rowIndex,
                        colKey: ObjectHelper.nameOf<DocumentGridViewModel>('name')
                    });
                },
                disabled: params?.node?.data?.folder?.folderSettings?.isReadOnly
            };
        },
        restorePreviousVersion: (params) => {
            return {
                name: 'Restore previous version',
                icon: `<i class="fas fa-clock-rotate-left"></i>`,
                action: () => {
                    this.documentService.restoreMostRecentVersion(params.node.data.document).subscribe(_ => {
                        this.folderDocumentGridService.refreshDocuments(true);
                    }, err => {
                        this.toastService.showErrorToast(err);
                    });
                },
                disabled: params?.node?.data?.folder?.folderSettings?.isReadOnly
            };
        },
        upload: (params) => {
            return {
                name: 'Upload and replace',
                icon: `<i class='fas fa-up-from-line'></i>`,
                action: () => {
                    const uploadDocument = new DocumentModel(params.node.data.document);
                    uploadDocument.eventType = DocumentEventTypesEnum.Upload;
                    this.addButtonClick.emit(uploadDocument);
                }
            };
        },
        viewFolderProperties: (params) => {
            return {
                name: 'Properties',
                action: () => {
                    this.viewFolderProperties.emit(params.node.data.folder);
                },
                icon: `<i class='far fa-folder-gear'></i>`,
            };
        },
        previewFile: (params) => {
            return {
                name: 'Preview file',
                action: () => {
                    const document = params.node.data.document;
                    SpinnerService.start();
                    this.documentService.preview(params.node.data.document.documentKey).pipe(finalize(() => SpinnerService.stop())).subscribe(res => {
                        this.filePreview = new FileViewModel();
                        this.filePreview.blobSrc = res;
                        this.filePreview.isBlobSrc = true;
                        this.documentPreview = new DocumentModel(document);
                        this.filePreview.name = document.name;
                        this.filePreview.type = DocumentsHelper.getPreviewFileType(document.file.extension);
                        this.filePreview.iconClass = DocumentsHelper.getFileTypeIcon(document.file.extension);
                        this.showPreview = true;
                    }, err => {
                        this.toastService.showErrorToast(err);
                    });
                },
                icon: `<i class='far fa-eye'></i>`,
                disabled: !DocumentsHelper.isPreviewType(params?.node?.data?.document?.file?.extension)
            };
        }
    };
}

