import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { environment } from 'environments/environment';
import { ApplicationConfigurationService, BaseHttpService, LoggingService } from 'app/nexus-core/services';
import { first } from 'rxjs/operators';
import { AuthenticationService } from 'app/nexus-core/services/authentication.service';
import { AuthenticatedUserService } from 'app/nexus-core/services/authenticated-user.service';
import { PolicyModalTypes } from 'app/nexus-shared/enums/policy-modal-types.enum';

@Component({
    selector: 'gtn-nexus-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    @Output() openModal = new EventEmitter<number>();

    version = environment().version;
    apiVersion = null;
    copyrightYear = new Date().getFullYear();

    constructor(
        public loggingService: LoggingService,
        private authenticatedUserService: AuthenticatedUserService,
        private authenticationService: AuthenticationService,
        private applicationConfigurationService: ApplicationConfigurationService,
        private baseHttpService: BaseHttpService) {
    }

    ngOnInit() {
        this.applicationConfigurationService.appChange$.subscribe(appRouteConfig => {
            const appConfig = this.applicationConfigurationService.getApplicationConfig(appRouteConfig?.applicationId);

            if (appConfig?.apiVersionUrl) {
                this.baseHttpService.get(appConfig.apiVersionUrl + 'applicationInfo').subscribe((appInfo => {
                    this.apiVersion = appInfo.appVersion ?? null;
                }));
            } else {
                this.apiVersion = null;
            }
        });
    }

    onDebugClicked(): void {
        console.log('[Is Authenticated]', this.authenticatedUserService.isAuthenticated);
        console.log('[Current User]', this.authenticatedUserService.user);
        console.log('[Is Gtn User]', this.authenticatedUserService.isGtn);
        console.log('[Env Vars]', environment());
        console.log('[Errors]', this.loggingService.errors);

        this.authenticationService.getTokenSilently(true).pipe(first()).subscribe(token => {
            console.log('[Uncached Token]', 'Bearer ' + token);
        });

        this.authenticationService.getTokenSilently().pipe(first()).subscribe(token => {
            console.log('[Cached Token]', 'Bearer ' + token);
        });
    }

    onErrorNotificationClicked(): void {
        this.applicationConfigurationService.showContactSupportModal = true;
    }

    onOpenTermsAndConditionsClicked(): void {
        this.openModal.emit(PolicyModalTypes.Terms);
    }

    onOpenPrivacyPolicyClicked(): void {
        this.openModal.emit(PolicyModalTypes.Privacy);
    }
}
