import { ErrorHandler, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FooterComponent } from 'app/nexus-core/components/footer/footer.component';
import { HttpInterceptorUtility } from 'app/nexus-core/utilities/http-interceptor.utility';
import { NexusSharedModule } from 'app/nexus-shared/nexus-shared.module';
import { SpinnerComponent } from 'app/nexus-core/components/spinner/spinner.component';
import {
    ApplicationConfigurationService,
    ApplicationSearchService,
    BaseHttpService,
    DomService,
    LoggingService,
    NexusModalService,
    NotificationService,
    ResizeService,
    WindowResizeService
} from 'app/nexus-core/services';
import { BaseFormGroupHelper } from 'app/nexus-core/helpers';
import { ErrorHandlerUtility } from 'app/nexus-core/utilities';
import { SpinnerService } from 'app/nexus-core/components/spinner';
import { PendingChangesGuard } from 'app/nexus-core/guards';
import { HttpCancelService } from './services/http-cancel.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NavHeaderComponent } from 'app/nexus-core/components/nav-header/nav-header.component';
import { ScrollService } from './services/scroll.service';
import { NavLogoComponent } from './components/nav-logo';
import { ApplicationInitializerService } from './services/application-initializer.service';
import { EnvironmentSettingsService } from 'app/nexus-core/services/environment-settings.service';
import { CurrencyService } from 'app/nexus-core/services/currency.service';
import { AuthGuard } from 'app/nexus-core/guards/auth.guard';
import { AuthenticatedUserSyncService } from 'app/nexus-core/services/authenticated-user-sync.service';
import { LocationService } from 'app/nexus-core/services/location.service';
import { RoleService } from 'app/nexus-core/services/domain/users/role.service';
import { UserRoleService } from 'app/nexus-core/services/domain/users/user-role.service';
import { UserService } from 'app/nexus-core/services/domain/users/user.service';
import { UserSettingsService } from 'app/nexus-core/services/domain/core/user-settings.service';
import { CompanyService } from 'app/nexus-core/services/domain/contacts/company.service';
import { CompanyRelationshipService } from 'app/nexus-core/services/domain/contacts/company-relationship.service';
import { IndividualCompanyAccessService } from 'app/nexus-core/services/domain/contacts/individual-company-access.service';
import { IndividualService } from 'app/nexus-core/services/domain/contacts/individual.service';
import { TaxesApplicationInfoService } from 'app/nexus-core/services/domain/taxes/tax-app-info.service';
import { TaxJurisdictionQualifierService } from 'app/nexus-core/services/domain/taxes/tax-jurisdiction-qualifier.service';
import { TaxRateService } from 'app/nexus-core/services/domain/taxes/tax-rate.service';
import { TaxService } from 'app/nexus-core/services/domain/taxes/tax.service';
import { TaxWithholdingTypeService } from 'app/nexus-core/services/domain/taxes/tax-withholding-type.service';
import { MyGTNPortalService } from 'app/nexus-core/services/domain/contacts/my-gtn-portal.service';
import { SupportEmailService } from 'app/nexus-core/services/support-email.service';
import { DocumentService } from 'app/nexus-core/services/domain/documents/document.service';
import { ToastService } from 'app/nexus-core/services/toast.service';
import { FolderService } from 'app/nexus-core/services/domain/documents/folder.service';
import { DocumentHelperService } from 'app/nexus-core/services/domain/documents/document-helper.service';
import { DocumentFolderService } from 'app/nexus-core/services/domain/documents/document-folder.service';
import { FolderFavoriteService } from 'app/nexus-core/services/domain/documents/folder-favorite.service';
import { FolderDocumentGridService } from 'app/nexus-core/services/domain/documents/folder-document-grid.service';
import { DocumentProcessService } from 'app/nexus-core/services/domain/documents/document-process.service';
import { azureBlobStorageFactory, BLOB_STORAGE_TOKEN } from 'app/nexus-core/services/blob/services/token';
import { BlobStorageService } from 'app/nexus-core/services/blob/services/blob-storage.service';
import { ContactsGlobalSearchService } from 'app/nexus-core/services/domain/contacts/contacts-global-search.service';
import { IndividualSettingService } from 'app/nexus-core/services/domain/core/individual-setting.service';
import { SavedSettingService } from 'app/nexus-core/services/domain/core/saved-setting.service';
import { TwoFactorService } from 'app/nexus-core/services/two-factor.service';
import { ClientAuaService } from 'app/nexus-core/services/client-aua.service';
import { ApplicationSettingsService } from 'app/nexus-core/services/application-settings.service';
import { ApplicationUrlsService } from 'app/nexus-core/services/domain/core/application-urls.service';
import { CountryResolver } from './resolvers/country.resolver';
import { IndividualRelationshipService } from 'app/nexus-core/services/domain/contacts/individual-relationship.service';
import { GoogleLocationService } from 'app/nexus-core/services/google-location.service';
import { BypassHttpInterceptorService } from 'app/nexus-core/services/bypass-http-interceptor.service';
import { UserImpersonationService } from 'app/nexus-core/services/domain/users/user-impersonation.service';
import { AgreementService, AgreementTypeService, AgreementsReviewerService, AgreementsVendorService } from './services/domain/agreements';
import { QuestionnaireTemplatesService } from 'app/nexus-core/services/domain/questionnaires/questionnaire-templates.service';
import { QuestionnaireDropdownsService } from 'app/nexus-core/services/domain/questionnaires/questionnaire-dropdowns.service';
import { ClientDataAcknowledgmentService } from 'app/nexus-core/services/client-data-acknowledgment.service';
import { QuestionnaireService } from 'app/nexus-core/services/domain/questionnaires/questionnaire.service';
import { QuestionnaireCoordinatorService } from 'app/nexus-core/services/domain/questionnaires/questionnaire-coordinator.service';
import { IndividualGtnOrganizerService } from 'app/nexus-core/services/domain/questionnaires/individual-gtn-organizer.service';
import { IndividualCchOrganizerService } from 'app/nexus-core/services/domain/questionnaires/individual-cch-organizer.service';
import { IndividualSupplementalOrganizerService } from 'app/nexus-core/services/domain/questionnaires/individual-supplemental-organizer.service';
import { QuestionnaireEventService } from 'app/nexus-core/services/domain/questionnaires/questionnaire-event.service';
import { DocumentTranslationService } from 'app/nexus-core/services/domain/documents/document-translation.service';
import { FolderTagService } from 'app/nexus-core/services/domain/documents/folder-tag.service';
import { PdfFileViewService } from './services/domain/core/pdf-file-view.service';

@NgModule({
    imports: [
        RouterModule.forRoot([], {}),
        NexusSharedModule,
    ],
    declarations: [
        FooterComponent,
        NavHeaderComponent,
        SpinnerComponent,
        NavLogoComponent
    ],
    providers: [
        CountryResolver
    ],
    exports: [
        FooterComponent,
        NavHeaderComponent,
        SpinnerComponent,
        NavLogoComponent
    ]
})
export class NexusCoreModule {
    // This ensures the core module is only imported one time in the application.
    constructor(@Optional() @SkipSelf() core: NexusCoreModule) {
        if (core) {
            throw new Error('Import Not Allowed! CoreModule cannot be imported outside of the AppModule!');
        }
    }

    // This provides the services to the application which ensures a single instance of the services in the app.
    static forRoot(): ModuleWithProviders<NexusCoreModule> {
        return {
            ngModule: NexusCoreModule,
            providers: [
                ApplicationConfigurationService,
                ApplicationInitializerService,
                ApplicationSearchService,
                ApplicationSettingsService,
                BlobStorageService,
                ClientAuaService,
                ClientDataAcknowledgmentService,
                EnvironmentSettingsService,
                SupportEmailService,
                ResizeService,
                AuthGuard,
                AuthenticatedUserSyncService,
                BaseFormGroupHelper,
                BaseHttpService,
                BypassHttpInterceptorService,
                DomService,
                CurrencyService,
                HttpCancelService,
                LoggingService,
                NexusModalService,
                NotificationService,
                PendingChangesGuard,
                ScrollService,
                SpinnerService,
                ToastService,
                TwoFactorService,
                WindowResizeService,
                {
                    provide: ErrorHandler,
                    useClass: ErrorHandlerUtility
                },
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: HttpInterceptorUtility,
                    multi: true
                },
                {
                    provide: BLOB_STORAGE_TOKEN,
                    useFactory: azureBlobStorageFactory
                },
                /* CORE */
                ApplicationUrlsService,
                SupportEmailService,
                IndividualSettingService,
                PdfFileViewService,
                SavedSettingService,

                /* LOCATION */
                LocationService,
                GoogleLocationService,

                /* USER */
                RoleService,
                UserRoleService,
                UserService,
                UserImpersonationService,
                UserSettingsService,

                /* CONTACTS */
                ContactsGlobalSearchService,
                CompanyService,
                CompanyRelationshipService,
                IndividualCompanyAccessService,
                IndividualRelationshipService,
                IndividualService,
                MyGTNPortalService,

                /* DOCUMENTS */
                DocumentHelperService,
                DocumentFolderService,
                DocumentProcessService,
                DocumentService,
                DocumentTranslationService,
                FolderDocumentGridService,
                FolderService,
                FolderFavoriteService,
                FolderTagService,

                /* TAXES */
                TaxesApplicationInfoService,
                TaxJurisdictionQualifierService,
                TaxRateService,
                TaxService,
                TaxWithholdingTypeService,

                /* AGREEMENTS */
                AgreementService,
                AgreementTypeService,
                AgreementsReviewerService,
                AgreementsVendorService,

                /* Questionnaires */
                QuestionnaireTemplatesService,
                QuestionnaireDropdownsService,
                QuestionnaireService,
                QuestionnaireEventService,
                IndividualCchOrganizerService,
                IndividualSupplementalOrganizerService,
                IndividualGtnOrganizerService,
                QuestionnaireCoordinatorService
            ]
        };
    }
}
