import { Component } from '@angular/core';

import { NexusModalService } from 'app/nexus-core/services';
import { NexusAnimations } from 'app/nexus-shared/animations';

@Component({
    templateUrl: './alert.component.html',
    styleUrls: [
        '../../nexus-modal.component.scss',
        './alert.component.scss'
    ],
    animations: [
        NexusAnimations.modalFadeIn
    ]
})
export class AlertComponent {
    // Not setting these as inputs because the dynamic component loader sets them.
    mainHeaderText: string;
    subHeaderText: string;
    okButtonText: string;
    ok: Function;
    message: string;
    okClick: Function = (() => {
        if (this.ok) {
            this.ok();
        }
        this.destroy();
    }).bind(this);

    constructor(private modalService: NexusModalService) {
    }

    destroy() {
        this.modalService.destroy();
    }
}
