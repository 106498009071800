import { Component, OnInit } from '@angular/core';
import { AuthenticatedUserService } from 'app/nexus-core/services/authenticated-user.service';
import { ApplicationConfigurationService } from 'app/nexus-core';
import { BasePageComponent } from 'app/nexus-shared/components/base-component/base-page.component';
import { BaseRouteApplicationConfigurationModel } from 'app/nexus-shared';
import { AuthorizationHelper } from 'app/nexus-core/helpers/authorization.helper';
import { environment } from 'environments/environment';

@Component({
  selector: 'gtn-internal-landing-page',
  templateUrl: './internal-landing-page.component.html',
  styleUrls: ['./internal-landing-page.component.scss']
})
export class InternalLandingPageComponent extends BasePageComponent implements OnInit  {
    navMenuItems: BaseRouteApplicationConfigurationModel[] = null;
    publicBlobStorageUrl: string;

    constructor(
        private authenticatedUserService: AuthenticatedUserService,
        private applicationConfigurationService: ApplicationConfigurationService
    ) {
        super();
    }

    ngOnInit(): void {
        this.publicBlobStorageUrl = environment().publicBlobStorageUrl;
        const navMenuItems = this.applicationConfigurationService.getApplicationMenuConfiguration().filter(t => t.menu.img.fileName);
        if (navMenuItems?.length) {
            this.navMenuItems = navMenuItems.filter(t => AuthorizationHelper.hasAuthorization(this.authenticatedUserService.user, t.authorization));
        }
    }

    onToolClicked(navigationData: BaseRouteApplicationConfigurationModel): void {
        this.applicationConfigurationService.redirectToRoute(navigationData);
    }
}
