import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { DateHelper } from 'app/nexus-core';
import { AuthenticatedUserService } from 'app/nexus-core/services/authenticated-user.service';

@Pipe({
    name: 'gtnDate'
})
export class GtnDatePipe extends DatePipe implements PipeTransform {

    constructor(private authenticatedUserService: AuthenticatedUserService) {
        super('en-US');
    }

    transform(value: Date | string | number): string | null;
    transform(value: null | undefined): null;
    transform(value: Date | string | number | null | undefined): string | null;
    transform(value: Date | string | number | null | undefined): string | null
    transform(value: Date | string | number, includeTime?: boolean): string | null;
    transform(value: null | undefined, includeTime?: boolean): null;
    transform(value: Date | string | number | null | undefined, includeTime?: boolean): string | null;
    transform(value: Date | string | number | null | undefined, includeTime?: boolean): string | null;
    transform(value: any, includeTime: boolean = false): string | null {
        if (includeTime) {
            return DateHelper.format(value, this.authenticatedUserService?.settings?.dateFormat ?? DateHelper.standardDateFormatTimeStamp);
        }
        return DateHelper.formatUtc(value, this.authenticatedUserService?.settings?.dateTimeFormat ?? DateHelper.standardDateFormat);
    }
}
