import { Component, EventEmitter, Output } from '@angular/core';
import { BaseViewComponent } from 'app/nexus-shared/components/base-component/base-view.component';
import { CompanyModel } from 'app/nexus-shared/domain/contacts/models';

@Component({
    selector: 'gtn-fuzzy-company-options-view',
    templateUrl: './fuzzy-company-options-view.component.html',
    styleUrls: ['./fuzzy-company-options-view.component.scss']
})
export class FuzzyCompanyOptionsViewComponent extends BaseViewComponent<CompanyModel[]> {
    @Output() companyClick: EventEmitter<CompanyModel> = new EventEmitter<CompanyModel>();

    onCompanyClicked(company: CompanyModel): void {
        this.companyClick.emit(company);
    }
}
