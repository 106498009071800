import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TaxJurisdictionModel, TaxModel, TaxWithholdingTypeModel } from 'app/nexus-shared/domain/taxes/index';
import { BaseFormGroupHelper, NexusValidatorHelper } from 'app/nexus-core';
import { SelectListInterface } from 'app/nexus-shared/index';
import { CompositeTaxComponentModel } from 'app/nexus-shared/domain/taxes/models/composite-tax-component-model';
import { TaxDetailFormControlNames } from 'app/nexus-shared/domain/taxes/components/tax-detail/tax-detail-form-control-names';


@Injectable()
export class TaxDetailFormGroupHelper extends BaseFormGroupHelper implements OnDestroy {
    isWithholdingTypeComposite: boolean = false;
    taxRateGroup: UntypedFormGroup = new UntypedFormGroup({});
    taxJurisdiction: TaxJurisdictionModel;
    taxId: number = -1;
    tax: TaxModel;
    selectedCountryId: number;
    selectedStateId: number;
    withholdingTypes: TaxWithholdingTypeModel[] = [];

    constructor(
        private formBuilder: UntypedFormBuilder,
    ) {
        super();
        this.buildFormGroup();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    buildFormGroup(): UntypedFormGroup {
        return this.formGroup = this.formBuilder.group({
            [TaxDetailFormControlNames.displayOrder]: [null],
            [TaxDetailFormControlNames.effectiveEndDate]: [null],
            [TaxDetailFormControlNames.effectiveStartDate]: [null, NexusValidatorHelper.required],
            [TaxDetailFormControlNames.name]: [null, NexusValidatorHelper.required],
            [TaxDetailFormControlNames.shortName]: [null],
            [TaxDetailFormControlNames.taxWithholdingTypeId]: [null, NexusValidatorHelper.required],
            [TaxDetailFormControlNames.taxJurisdictionId]: [null, NexusValidatorHelper.required],
            [TaxDetailFormControlNames.countryId]: [null],
            [TaxDetailFormControlNames.stateId]: [null],
            [TaxDetailFormControlNames.cityId]: [null],
            [TaxDetailFormControlNames.countyId]: [null],
            [TaxDetailFormControlNames.specializationTitle]: [{ value: null }],
            [TaxDetailFormControlNames.specializationKey]: [{ value: null }],
            [TaxDetailFormControlNames.compositeReplacesAllOtherTaxes]: [null],
            [TaxDetailFormControlNames.compositeTaxComponents]: [{ value: null, disabled: true }],
            [TaxDetailFormControlNames.specializationId]: null,
            [TaxDetailFormControlNames.limitIncomePeriodType]: null
        });
    }

    resetFormGroup(): void {
        this.setControlValues(false);
        this.formGroup.markAsUntouched();
        this.formGroup.markAsPristine();
    }

    setControlValues(addJurisdictionSubscriptions: boolean = true): void {
        const tax = this.tax;
        let count = Object.keys(this.formGroup.controls).length;
        do {
            for (const controlName in this.formGroup.controls) {
                if (controlName) {
                    --count;
                    const control = this.formGroup.controls[controlName];
                    if (control) {
                        if (controlName === TaxDetailFormControlNames.compositeTaxComponents && tax[controlName]) {
                            if (!tax.compositeReplacesAllOtherTaxes) {
                                this.setCompositeTaxComponentControl();
                            }
                        } else {
                            if (controlName === TaxDetailFormControlNames.taxWithholdingTypeId) {
                                control.setValue(tax[controlName] ? tax[controlName] : '');
                                if (tax[controlName]) {
                                    const withholdingType: TaxWithholdingTypeModel = this.withholdingTypes.find(x => x.taxWithholdingTypeId === tax[controlName]);
                                    if (withholdingType && withholdingType.name.toLowerCase() === 'composite') {
                                        this.isWithholdingTypeComposite = true;
                                    } else {
                                        this.isWithholdingTypeComposite = false;
                                    }
                                }
                            } else {
                                control.setValue(tax[controlName]);
                            }
                        }
                    }
                }
            }
        } while (count > 0);

        if (tax.taxJurisdiction) {
            this.setupJurisdictionControls(tax.taxJurisdiction, addJurisdictionSubscriptions);
        } else {
            tax.taxJurisdiction = new TaxJurisdictionModel();
            tax.taxJurisdiction.taxJurisdictionId = 0;
            tax.taxJurisdictionId = 0;
            this.taxJurisdiction = tax.taxJurisdiction;
            this.addJurisdictionSubscriptions();
        }

        if (addJurisdictionSubscriptions) {
            this.setupWithholdingTypeSubscriptions();
        }

        setTimeout(() => {
            this.formGroup.markAsPristine();
            this.formGroup.markAsUntouched();
        }, 0);
    }

    updateModel(tax: TaxModel): TaxModel {
        if (tax) {
            let count = Object.keys(this.formGroup.controls).length;
            tax.taxJurisdiction = this.taxJurisdiction;

            do {
                for (const controlName in this.formGroup.controls) {
                    if (controlName) {
                        --count;
                        const control = this.formGroup.controls[controlName];
                        if (control && control.dirty) {
                            if (controlName === TaxDetailFormControlNames.compositeTaxComponents) {
                                const values = control.value,
                                    compositeTaxComponents: CompositeTaxComponentModel[] = [];

                                for (const value of values) {
                                    const compositeTaxComponent: CompositeTaxComponentModel = {
                                        compositeTaxComponentId: 0,
                                        taxId: this.taxId,
                                        taxWithholdingTypeId: value
                                    };

                                    compositeTaxComponents.push(compositeTaxComponent);
                                }

                                tax[controlName] = compositeTaxComponents;
                            } else if (controlName !== TaxDetailFormControlNames.countryId
                                && controlName !== TaxDetailFormControlNames.stateId
                                && controlName !== TaxDetailFormControlNames.cityId
                                && controlName !== TaxDetailFormControlNames.countyId) {
                                tax[controlName] = control.value;
                            } else {
                                if (tax.taxJurisdictionId) {
                                    tax.taxJurisdictionId = null;
                                    tax.taxJurisdiction.taxJurisdictionId = null;
                                    tax.taxJurisdiction.name = null;
                                }

                                if (controlName === TaxDetailFormControlNames.cityId || controlName === TaxDetailFormControlNames.countyId) {
                                    if (control.value === -1) {
                                        tax.isLocal = true;
                                    } else {
                                        tax.isLocal = false;
                                        tax.taxJurisdiction[controlName] = control.value;
                                    }
                                } else {
                                    tax.taxJurisdiction[controlName] = control.value;
                                }

                            }
                        }
                    }
                }
            } while (count > 0);

            return tax;
        } else {
            return null;
        }
    }

    private setCompositeTaxComponentControl() {
        const componentIds: number[] = [],
            compositeControl = this.formGroup.controls[TaxDetailFormControlNames.compositeTaxComponents];

        if (this.tax['compositeTaxComponents']) {
            for (const component of this.tax['compositeTaxComponents']) {
                if (component) {
                    const comp: CompositeTaxComponentModel = component;
                    componentIds.push(comp.taxWithholdingTypeId);
                }
            }
            compositeControl.setValue(componentIds);
        }

        compositeControl.enable();
    }

    private addJurisdictionSubscriptions(): void {
        const controls = this.formGroup.controls;

        this.subscriptions.add(controls.countryId.valueChanges.subscribe(((value: number) => {
            if (this.taxJurisdiction.countryId !== value) {
                this.taxJurisdiction.countryId = value;
                this.taxJurisdiction.taxJurisdictionTypeId = 1;

                if (controls.stateId.value) {
                    controls.stateId.setValue(null);
                }

                if (controls.cityId.value) {
                    controls.cityId.setValue(null);
                }

                if (controls.countyId.value) {
                    controls.countyId.setValue(null);
                }

                controls.taxJurisdictionId.setValue(value);
            }
        }).bind(this)));

        this.subscriptions.add(controls.stateId.valueChanges.subscribe(((value: number) => {
            if (this.taxJurisdiction.stateId !== value) {
                this.taxJurisdiction.stateId = value;
                if (value) {
                    this.taxJurisdiction.taxJurisdictionTypeId = 2;
                    controls.taxJurisdictionId.setValue(value);
                } else {
                    this.taxJurisdiction.taxJurisdictionTypeId = 1;
                    this.taxJurisdiction.stateName = null;
                    if (controls.cityId.value) {
                        controls.cityId.setValue(null);
                    }
                    if (controls.countyId.value) {
                        controls.countyId.setValue(null);
                    }
                    controls.taxJurisdictionId.setValue(this.taxJurisdiction.countryId);
                }
            }
        }).bind(this)));

        this.subscriptions.add(controls.cityId.valueChanges.subscribe(((value: number) => {
            if (value === -1) {
                this.taxJurisdiction.taxJurisdictionTypeId = 3;
                this.tax.isLocal = true;
                this.taxJurisdiction.cityId = null;
                this.taxJurisdiction.cityName = null;
                controls.taxJurisdictionId.setValue(-1);
            } else {
                this.tax.isLocal = false;
                if (this.taxJurisdiction.cityId !== value) {
                    this.taxJurisdiction.cityId = value;
                    if (value) {
                        this.taxJurisdiction.taxJurisdictionTypeId = 3;
                        controls.taxJurisdictionId.setValue(value);
                    } else {
                        this.taxJurisdiction.taxJurisdictionTypeId = 2;
                        this.taxJurisdiction.cityName = null;
                        controls.taxJurisdictionId.setValue(this.taxJurisdiction.stateId);
                    }
                }
            }
        }).bind(this)));

        this.subscriptions.add(controls.countyId.valueChanges.subscribe(((value: number) => {
            if (value === -1) {
                this.taxJurisdiction.taxJurisdictionTypeId = 3;
                this.tax.isLocal = true;
                this.taxJurisdiction.countyId = null;
                this.taxJurisdiction.countyName = null;
                controls.taxJurisdictionId.setValue(-1);
            } else {
                this.tax.isLocal = false;
                if (this.taxJurisdiction.countyId !== value) {
                    this.taxJurisdiction.countyId = value;
                    if (value) {
                        this.taxJurisdiction.taxJurisdictionTypeId = 3;
                        controls.taxJurisdictionId.setValue(value);
                    } else {
                        this.taxJurisdiction.taxJurisdictionTypeId = 2;
                        this.taxJurisdiction.countyName = null;
                        controls.taxJurisdictionId.setValue(this.taxJurisdiction.stateId);
                    }
                }
            }
        }).bind(this)));
    }

    private setupJurisdictionControls(jurisdiction: TaxJurisdictionModel, addSubscriptions: boolean): void {
        const controls = this.formGroup.controls;
        let location: SelectListInterface;

        if (jurisdiction.countryId) {
            controls.countryId.setValue(jurisdiction.countryId);
            location = { id: jurisdiction.countryId, typeId: 0, value: '', visible: true };
        } else if (controls.countryId.value) {
            controls.countryId.setValue(null);
        }

        if (jurisdiction.stateId) {
            controls.stateId.setValue(jurisdiction.stateId);
            location = { id: jurisdiction.stateId, typeId: 1, value: '', visible: true };
        } else if (controls.stateId.value) {
            controls.stateId.setValue(null);
        }

        if (jurisdiction.cityId) {
            controls.cityId.setValue(!this.tax.isLocal ? jurisdiction.cityId : -1);
        } else if (jurisdiction.countyId) {
            controls.countyId.setValue(!this.tax.isLocal ? jurisdiction.countyId : -1);
        } else if (controls.cityId.value !== null && typeof controls.cityId.value !== 'undefined') {
            // We can have -1 here so this if must be more strict
            controls.cityId.setValue(null);
        } else if (controls.countyId.value !== null && typeof controls.countyId.value !== 'undefined') {
            // We can have -1 here so this if must be more strict
            controls.countyId.setValue(null);
        }

        // Add the control subscriptions so that the taxJurisdictionId gets set properly.
        if (addSubscriptions) {
            this.formGroup.markAsPristine();
            this.addJurisdictionSubscriptions();
        }
    }

    private setupWithholdingTypeSubscriptions(): void {
        this.subscriptions.add(this.formGroup.controls[TaxDetailFormControlNames.taxWithholdingTypeId].valueChanges.subscribe((value: number) => {
            const selectedWithholdingType: TaxWithholdingTypeModel = this.withholdingTypes.find(x => x.taxWithholdingTypeId === value);
            this.isWithholdingTypeComposite = selectedWithholdingType.name.toLowerCase() === 'composite';
        }));

        this.subscriptions.add(this.formGroup.controls[TaxDetailFormControlNames.compositeReplacesAllOtherTaxes].valueChanges.subscribe((value: boolean) => {
            const compositeTaxComponents = this.formGroup.controls[TaxDetailFormControlNames.compositeTaxComponents];

            if (value === true && compositeTaxComponents?.value?.length) {
                compositeTaxComponents.setValue([]);
                compositeTaxComponents.disable();
            } else if (value === false && this.tax?.compositeTaxComponents?.length) {
                this.setCompositeTaxComponentControl();
            }
        }));
    }
}
