export enum MenusEnum {
    Unknown = 0,
    Equity = 100,
    Whereabouts = 200,
    Traction = 300,
    TractionTimeTracking = 301,
    Portal = 400,
    Accounting = 500,
    Vendors = 600,

    Landing = 1000,
    Users = 1001,
    Taxes = 1002,
    Taxability = 1003
}
