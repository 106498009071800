import { StringHelper } from './string.helper';
import { SelectListInterface, SelectModel } from 'app/nexus-shared';
import { SortingHelper } from 'app/nexus-core/helpers/sorting.helper';

export class EnumHelper {
    static getDisplayName(enumObject: any, enumItem: number, customDisplayNameFunc: (enumValue: number) => string = null): string {
        if (customDisplayNameFunc != null) {
            const value: string = customDisplayNameFunc(enumItem);
            if (value !== null) {
                return value;
            }
        }

        if (enumObject[enumItem] && isNaN(enumObject[enumItem])) {
            return StringHelper.convertFromCamelCase(enumObject[enumItem]);
        }

        return null;
    }

    static getEnumValueFromStringValue(enumObject: any, enumStringValue: string): number {
        if (enumObject && enumStringValue) {
            const convertedValue = enumStringValue.replace(/\s/g, '');
            if (enumObject[convertedValue]) {
                return enumObject[convertedValue];
            }
        }
        return null;
    }

    static convertToSelectList(enumObject: any, includeZeroValue: boolean = true, includeBlank: boolean = false, enumObjectType: number | string = null, sortByValue: boolean = true, customDisplayNameFunc: (enumValue: number) => string = null): SelectListInterface[] {
        const selectList: SelectListInterface[] = [];

        for (const enumItem in enumObject) {
            if (enumObject[enumItem] && isNaN(enumObject[enumItem]) && (includeZeroValue || (!includeZeroValue && parseInt(enumItem, 10) !== 0))) {
                const stringValue: string = EnumHelper.getDisplayName(enumObject, parseInt(enumItem, 10), customDisplayNameFunc);
                selectList.push({
                    value: stringValue,
                    id: parseInt(enumItem, 10),
                    typeId: enumObjectType,
                    visible: true
                });
            }
        }

        if (includeBlank) {
            selectList.unshift({ value: '', id: null, typeId: null, visible: true });
        }

        if (sortByValue) {
            return selectList.sort((a, b) => {
                return SortingHelper.sortByPropertyComparer(a, b, 'value');
            });
        } else {
            return selectList.sort((a, b) => {
                if (a < b) {
                    return -1;
                } else if (a > b) {
                    return 1;
                }

                return 0;
            });
        }

    }

    static convertToSelectListWithStringId(enumObject: any, includeZeroValue: boolean = true): SelectListInterface[] {
        const selectList: SelectListInterface[] = [];

        for (const enumItem in enumObject) {
            if (enumObject[enumItem] && isNaN(enumObject[enumItem]) && (includeZeroValue || (!includeZeroValue && parseInt(enumItem, 10) !== 0))) {
                const stringValue = StringHelper.convertFromCamelCase(enumObject[enumItem]);
                selectList.push({ value: stringValue, id: enumItem, typeId: null, visible: true });
            }
        }

        return selectList.sort((a, b) => {
            if (a < b) {
                return -1;
            } else if (a > b) {
                return 1;
            }

            return 0;
        });
    }

    static convertToSelectModels(enumObject: any, includeZeroValue: boolean = true): SelectModel[] {
        const selectModels: SelectModel[] = [];

        for (const enumItem in enumObject) {
            if (enumObject[enumItem] && isNaN(enumObject[enumItem]) && (includeZeroValue || (!includeZeroValue && parseInt(enumItem, 10) !== 0))) {
                const stringValue = StringHelper.convertFromCamelCase(enumObject[enumItem]);
                selectModels.push({ value: parseInt(enumItem, 10), label: stringValue, });
            }
        }

        return selectModels.sort((a, b) => {
            if (a < b) {
                return -1;
            } else if (a > b) {
                return 1;
            }

            return 0;
        });
    }
}
