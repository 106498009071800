<div id="footer" class="sidenav-item">
    <a href="https://www.gtn.com/" target="_blank" *ngIf="!loggingService?.errors?.length">
        <div class="sidenav-box">
            <img class="logo" src="../../../../assets/images/gtn-logo.svg"/>
        </div>
    </a>
    <div class="sidenav-box" *ngIf="loggingService?.errors?.length">
        <gtn-notification-indicator
            (click)="onErrorNotificationClicked()"
            [isVisible]="true"
            [count]="loggingService.errors.length">
        </gtn-notification-indicator>
    </div>
    <div class="sidenav-expanded">
        <div class="content">
            <div class="links">
                <a (click)="onOpenTermsAndConditionsClicked()">Terms of Use</a>
                <a (click)="onOpenPrivacyPolicyClicked()">Privacy Policies</a>
            </div>
            <div class="copyright">
                <i class="material-icons">copyright</i>
                <span>{{copyrightYear}} Global Tax Network</span>
            </div>
            <div class="version">
                <div class="flex" (click)="onDebugClicked()">ver {{version}}</div>
                <div class="flex" *ngIf="apiVersion">api {{apiVersion}}</div>
            </div>
        </div>
    </div>
</div>
