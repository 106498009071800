import { Guid } from 'guid-typescript';

export class SectionColumnActionModel {
    key: string;
    parentSectionColumnKey: string;
    childSectionKey: string;
    value: number;

    constructor(model: SectionColumnActionModel = null) {
        this.key = model?.key ?? Guid.create().toString();
        this.parentSectionColumnKey = model?.parentSectionColumnKey ?? null;
        this.childSectionKey = model?.childSectionKey ?? null;
        this.value = model?.value ?? null;
    }
}
