import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[isFocused]'
})
export class FocusDirective implements OnChanges { // This is for ngFor loops to set focus to the dynamically created input field
    @Input('isFocused') shouldFocus: boolean;

    constructor(private el: ElementRef) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.shouldFocus && this.shouldFocus) {
            // Wait for the DOM to update
            setTimeout(() => {
                const inputElement = this.el?.nativeElement?.querySelector('input');
                if (inputElement) {
                    inputElement.focus();
                }
            });
        }
    }
}
