import { Component, EventEmitter, forwardRef, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { AsYouType, getExampleNumber } from 'libphonenumber-js';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormGroup } from '@angular/forms';
import { InputComponent } from 'app/nexus-shared/components/controls/components/input';
import examples from 'libphonenumber-js/examples.mobile.json';
import { PhoneNumberHelper } from 'app/nexus-core/helpers/phone-number.helper';

@Component({
    selector: 'gtn-phone-number-input',
    templateUrl: '../input/input.component.html',
    styleUrls: ['../input/input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PhoneNumberInputComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => PhoneNumberInputComponent),
            multi: true
        }
    ]
})
export class PhoneNumberInputComponent extends InputComponent implements OnInit {
    @Input() formControlName: string;
    @Input() countryCodeFormControl: string = 'countryCode';
    @Input() formGroup: UntypedFormGroup;
    @Input() enableValidation: boolean = false;
    @Output() validNumberChange: EventEmitter<boolean> = new EventEmitter();
    @Output() countryPhoneNumberMismatch: EventEmitter<boolean> = new EventEmitter();

    labelCopy: string;

    constructor(protected renderer: Renderer2) {
        super(renderer);
    }

    ngOnInit(): void {
        this.labelCopy = JSON.parse(JSON.stringify(this.label));
        const value = this.formGroup.get(this.formControlName)?.value;
        if (!!value) {
            const countryCode = this.formGroup.get(this.countryCodeFormControl)?.value;
            const parsedPhoneNumber = PhoneNumberHelper.parsePhoneNumber(value, countryCode);
            this.label = !!countryCode ? `e.g. ${getExampleNumber(countryCode, examples)?.formatInternational()}` : this.labelCopy;
            if (parsedPhoneNumber) {
                this.setPhoneNumberValue(parsedPhoneNumber.formatInternational());
            } else {
                this.validNumberChange.emit(false);
            }
        } else {
            this.readonly = true;
        }
        this.subscriptions.add(this.phoneNumberSubscription());
        this.subscriptions.add(this.countryCodeChangesSubscription());
        this.canShowValidation = !!(this.formGroup.get(this.formControlName)?.value);
    }

    onBlur(): void {
        if (this.enableValidation) {
            this.canShowValidation = true;
            const countryCode = this.formGroup.get(this.countryCodeFormControl)?.value;
            const validNumber = this.validatePhoneNumber(countryCode);
            if (validNumber && PhoneNumberHelper.isCountryPhoneNumberMismatch(this.formGroup.get(this.formControlName)?.value, countryCode)) {
                this.countryPhoneNumberMismatch.emit(true);
            } else {
                this.countryPhoneNumberMismatch.emit(false);
            }
            this.validNumberChange.emit(validNumber);
        }
    }

    private phoneNumberSubscription(): Subscription {
        return this.formGroup.get(this.formControlName).valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(val => {
            this.setPhoneNumberValue(val);
            if (this.canShowValidation) {
                const countryCode = this.formGroup.get(this.countryCodeFormControl)?.value;
                this.validNumberChange.emit(this.validatePhoneNumber(countryCode));
            }
        });
    }

    private countryCodeChangesSubscription(): Subscription {
        return this.formGroup.get(this.countryCodeFormControl).valueChanges.pipe((tap(val => {
            this.label = val ? `e.g. ${getExampleNumber(val, examples)?.formatInternational()}` : this.labelCopy;
            if (this.readonly) {
                this.readonly = false;
            }
        })), debounceTime(100), distinctUntilChanged()).subscribe(val => {
            const phoneControl = this.formGroup.get(this.formControlName);
            this.setPhoneNumberValue(phoneControl.value);
        });
    }

    private setPhoneNumberValue(value: string): void {
        const numberControl = this.formGroup.get(this.formControlName);
        const countryCode = this.formGroup.get(this.countryCodeFormControl)?.value;
        try {
            numberControl.setValue(new AsYouType(countryCode ? countryCode : 'US').input(value));
        } catch (e) {
        }
    }

    private validatePhoneNumber(countryCode: any): boolean {
        const regionCode: any = countryCode ? countryCode : 'US';
        const control = this.formGroup.get(this.formControlName);
        return PhoneNumberHelper.validatePhoneNumber(control?.value, regionCode);
    }
}
