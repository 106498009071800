import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectListInterface } from 'app/nexus-shared/interfaces';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';

@Component({
    selector: 'gtn-button-toggle-group',
    templateUrl: './button-toggle-group.component.html',
    styleUrls: ['./button-toggle-group.component.scss']
})
export class ButtonToggleGroupComponent extends BaseComponent {
    @Input() options: SelectListInterface[];
    @Input() selectedButtonId: number = 0;
    @Input() isSubtle: boolean = false;
    @Input() navigateToLabel: string;
    @Output() buttonClick: EventEmitter<number> = new EventEmitter<number>();
    @Output() navigateToClick: EventEmitter<void> = new EventEmitter<void>();

    onButtonClicked(id: number | boolean | string): void {
        if (id !== this.selectedButtonId) {
            let outId: number = null;
            if (typeof id === 'number') {
                outId = id as number;
            }
            else if (typeof id === 'boolean') {
                outId = id ? 1 : 0;
            }
            this.selectedButtonId = outId;
            this.buttonClick.emit(outId);
        }
    }

    onNavigateToClicked(): void {
        this.navigateToClick.emit();
    }
}
