import { Component, OnInit } from '@angular/core';
import { InputType } from 'app/nexus-shared/components/controls';
import { BaseDynamicComponentDirective, ControlTypeEnum, DynamicFormField } from 'app/nexus-shared/components/dynamic-form/shared';

@Component({
    selector: 'gtn-dynamic-input',
    templateUrl: './dynamic-input.component.html',
    styleUrls: ['./dynamic-input.component.scss']
})
export class DynamicInputComponent extends BaseDynamicComponentDirective implements DynamicFormField, OnInit {
    ngOnInit() {
        switch (this.config.controlType) {
            case ControlTypeEnum.Currency:
                this.type = InputType.currency;
                break;
            case ControlTypeEnum.Number:
                this.type = InputType.number;
                break;
            default:
                this.type = InputType.text;
                break;
        }
    }
}
