<form [formGroup]="formGroupRef">
    <ng-container *ngIf="isTemplateMode">
        <div class="flex-row-start flex">
            <div class="column-actions-container medium-small-column mr-3">
                <div class="column-actions">
                    <span><i class="fas fa-bars mr-2"></i></span>
                    <span (click)="onDeleteColumnClicked()"><i
                        class="fa fa-trash"></i></span>
                </div>
            </div>
            <gtn-input class="small-column mr-3" type="number" formControlName="widthPercentage"
                label="Width %"></gtn-input>
            <gtn-dropdown class="medium-column mr-3" label="Column type" [options]="sectionColumnTypes" [idOnly]="true"
                formControlName="type"></gtn-dropdown>
            <ng-container
                *ngIf="formGroupRef.value?.type === sectionColumnTypesEnum.Header ||
                formGroupRef.value?.type === sectionColumnTypesEnum.Text">
                <gtn-column-editor-header-text-form [formGroupRef]="formGroupRef"
                    [isSubForm]="true">
                </gtn-column-editor-header-text-form>
            </ng-container>
            <ng-container *ngIf="formGroupRef.value?.type === sectionColumnTypesEnum.UserInput">
                <gtn-column-editor-user-input-form [formGroupRef]="formGroupRef"
                    [isSubForm]="true"></gtn-column-editor-user-input-form>
            </ng-container>
            <ng-container *ngIf="formGroupRef.value?.type === sectionColumnTypesEnum.Table">
                <div class="flex-column-center">
                    <div>
                        <button mat-flat-button color="primary" (click)="onAddTableColumn()"> Add Table Column</button>
                    </div>
                </div>
            </ng-container>
        </div>
        <ng-container *ngIf="formGroupRef.value?.type === sectionColumnTypesEnum.UserInput &&
            (formGroupRef.value?.userInputType === userInputTypesEnum.DropDown ||
             formGroupRef.value?.userInputType === userInputTypesEnum.Numeric)">
            <div class="flex-row-start">
                <gtn-column-editor-action-form [formGroupRef]="formGroupRef" [isSubForm]="true"
                    [questionnaireDropdownService]="questionnaireDropdownService"
                    [dropdownTypeId]="formGroupRef.value?.dropdownTypeId ?? null"
                    [sectionKey]="sectionKey">
                </gtn-column-editor-action-form>
            </div>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="formGroupRef.value?.type === sectionColumnTypesEnum.Table">
        <gtn-column-editor-table-form #tableEditorForm [formGroupRef]="formGroupRef"
            [isSubForm]="true" [isTemplateMode]="isTemplateMode"></gtn-column-editor-table-form>
    </ng-container>
</form>
