import { Component, OnInit } from '@angular/core';
import { QuestionnaireDropdownsService } from 'app/nexus-core/services/domain/questionnaires/questionnaire-dropdowns.service';
import { QuestionnaireEventService } from 'app/nexus-core/services/domain/questionnaires/questionnaire-event.service';
import { BaseEditQuestionnaireFormComponent } from 'app/nexus-shared/domain/questionnaires/components/forms/base-edit-questionnaire-form.component';

@Component({
    selector: 'gtn-questionnaire-form',
    templateUrl: './questionnaire-form.component.html',
    styleUrls: ['./questionnaire-form.component.scss']
})
export class QuestionnaireFormComponent extends BaseEditQuestionnaireFormComponent implements OnInit {
    constructor(public questionnaireDropdownService: QuestionnaireDropdownsService, protected questionnaireEventService: QuestionnaireEventService) {
        super(questionnaireEventService);
    }

    ngOnInit() {
        super.ngOnInit();
    }

}
