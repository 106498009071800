import { CurrencyPipe, Location } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RowEvent } from 'ag-grid-community';
import { CheckboxLabelPositionType, ControlColumnComponent, GridComponent } from 'app/nexus-shared/components/controls';
import { GtnTaxRatesFilterPanelComponent } from 'app/nexus-shared/domain/taxes/components/tax-detail/filter-panel/rates-filter-panel.component';
import { TaxDetailFormControlNames } from 'app/nexus-shared/domain/taxes/components/tax-detail/tax-detail-form-control-names';
import { TaxDetailFormGroupHelper } from 'app/nexus-shared/domain/taxes/components/tax-detail/tax-detail-form-group.helper';
import { TaxJurisdictionModel, TaxJurisdictionQualifierModel, TaxModel, TaxRateFilterModel, TaxRateModel, TaxSpecializationModel, TaxWithholdingTypeModel } from 'app/nexus-shared/domain/taxes/models';
import { AgGridHelper, DateHelper, EnumHelper, LocalStorageHelper, NexusModalService, WindowResizeService } from 'app/nexus-core';
import { AuthorizationConstants } from 'app/nexus-shared/constants/authorization.constants';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { ConfirmComponent, ConfirmModalInputModel, ModalOutputModel } from 'app/nexus-shared/components/modal';
import { LocalStorageNameConstants, LocationModel, SelectListInterface, SpecializationNamePipe, StringConstants } from 'app/nexus-shared/index';
import { Observable, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { TaxesGridFilterType, TaxesRateFilterType } from 'app/nexus-shared/domain/taxes/index';
import { LimitIncomePeriodType, TaxResidencyEnum } from 'app/nexus-shared/domain/taxes/enums';
import { TaxJurisdictionQualifierService } from 'app/nexus-core/services/domain/taxes/tax-jurisdiction-qualifier.service';
import { TaxWithholdingTypeService } from 'app/nexus-core/services/domain/taxes/tax-withholding-type.service';
import { TaxService } from 'app/nexus-core/services/domain/taxes/tax.service';
import { TaxRateService } from 'app/nexus-core/services/domain/taxes/tax-rate.service';

@Component({
    selector: 'gtn-tax-detail',
    templateUrl: './tax-detail.component.html',
    styleUrls: ['./tax-detail.component.scss'],
    providers: [
        CurrencyPipe,
        DateHelper,
        TaxDetailFormGroupHelper,
        SpecializationNamePipe
    ]
})
export class TaxDetailComponent implements OnInit {
    @Input() gridFilter: TaxRateFilterModel;
    @Input() specializations: TaxSpecializationModel[];
    @Input() taxId: number;
    @Input() returnName: string = 'Taxes';
    @ViewChild('expandableCardContainer') expandableCardContainer: ElementRef;
    @ViewChild('expandableMainContent') expandableMainContent: ElementRef;

    authorizationConstants = AuthorizationConstants;
    formControlNames = TaxDetailFormControlNames;
    CheckboxLabelPositionType = CheckboxLabelPositionType;
    panelComponent = GtnTaxRatesFilterPanelComponent;

    cardExpandedWidth: number = 700;
    formGroup: UntypedFormGroup = this.formGroupHelper.formGroup;
    isCardOpen: boolean = false;
    isLoading = false;
    limitIncomePeriodTypes: SelectListInterface[] = this.createLimitIncomePeriodTypeOptions();
    locationName: string = '';
    localLocationModel: LocationModel = {
        id: -1,
        name: '<local>'
    };
    name: string = '';
    qualifiers: TaxJurisdictionQualifierModel[] = [];
    replaceableWithholdingTypes: TaxWithholdingTypeModel[];
    selectedIncomePeriodType: SelectListInterface;
    tax: TaxModel;
    taxRates: TaxRateModel[] = [];
    withholdingTypes: TaxWithholdingTypeModel[];
    taxResidencyTypes = TaxResidencyEnum;
    isCountySelectVisible: boolean = false;

    public defaultSortModel: { colId: string, sort: 'asc' | 'desc' }[] = [
        { colId: 'startDate', sort: 'asc' }
    ];

    public columnDefs = [
        {
            headerName: 'Start Date',
            field: ObjectHelper.nameOf<TaxRateModel>('effectiveStartDate'),
            cellRenderer: AgGridHelper.dateCellRenderer,
            filter: false
        },
        {
            headerName: 'End Date',
            field: ObjectHelper.nameOf<TaxRateModel>('effectiveEndDate'),
            cellRenderer: AgGridHelper.dateCellRenderer,
            filter: false
        },
        {
            headerName: 'Residency',
            field: ObjectHelper.nameOf<TaxRateModel>('taxResidencyId'),
            cellRenderer: params => {
                return EnumHelper.getDisplayName(TaxResidencyEnum, params.data.taxResidencyId);
            },
            filter: false
        },
        {
            headerName: 'Qualifier',
            field: ObjectHelper.nameOf<TaxRateModel>('taxQualifierId'),
            cellRenderer: params => {
                const model = params.data as TaxRateModel;
                if (!model || !this.qualifiers) {
                    return '';
                }
                const qualifier = this.qualifiers.find(x => x.taxQualifierId === model.taxQualifierId);
                return qualifier?.name;
            },
            filter: false
        },
        {
            headerName: 'Specialization',
            field: ObjectHelper.nameOf<TaxRateModel>('specializationTitle'),
            cellRenderer: params => {
                return this.specializationNamePipe.transform(params.data.specializationTitle, this.formGroup.controls[this.formControlNames.specializationTitle].value);
            },
            filter: false
        },
        {
            headerName: 'Limit',
            field: ObjectHelper.nameOf<TaxRateModel>('limit'),
            cellRenderer: params => {
                const model = params.data as TaxRateModel;
                if (!model) {
                    return '';
                }
                return this.currencyPipe.transform(model.limit);
            },
            filter: false
        },
        {
            headerName: 'Rate',
            field: ObjectHelper.nameOf<TaxRateModel>('rate'),
            filter: false
        },
        {
            headerName: '',
            field: 'controlColumn',
            width: 100,
            suppressSizeToFit: false,
            cellRenderer: ControlColumnComponent,
            cellRendererParams: {
                deleteClick: this.onDeleteClick.bind(this),
                cloneClick: this.onCloneClick.bind(this)
            },
            filter: false
        }
    ];

    @ViewChild('gtnBaseGrid') private gtnBaseGrid: GridComponent;

    constructor(
        public formGroupHelper: TaxDetailFormGroupHelper,
        private currencyPipe: CurrencyPipe,
        private activatedRoute: ActivatedRoute,
        private location: Location,
        private modalService: NexusModalService,
        private router: Router,
        private specializationNamePipe: SpecializationNamePipe,
        private taxJurisdictionQualifierService: TaxJurisdictionQualifierService,
        private taxWithholdingTypeService: TaxWithholdingTypeService,
        private taxService: TaxService,
        private taxRateService: TaxRateService,
        private windowResizeService: WindowResizeService
    ) {
    }

    ngOnInit() {
        this.taxId = this.activatedRoute.snapshot.params.id;
        const returnName = this.activatedRoute.snapshot.queryParamMap.get('returnName');


        if (returnName) {
            this.returnName = returnName;
        }

        this.taxWithholdingTypeService.list().pipe(first()).subscribe((withholdingTypes => {
            this.withholdingTypes = withholdingTypes;
            this.formGroupHelper.withholdingTypes = withholdingTypes;
            this.replaceableWithholdingTypes = withholdingTypes.filter(x => x.name !== 'Composite');
        }).bind(this));

        this.taxJurisdictionQualifierService.list().subscribe((qualifiers => {
            if (qualifiers) {
                this.qualifiers = qualifiers;
            }
        }).bind(this));

        if (this.taxId) {
            this.taxService.getTaxById(this.taxId).subscribe(tax => {
                if (tax) {
                    this.name = tax.name;
                    this.tax = tax;
                    this.setControlValues();
                    this.selectedIncomePeriodType = this.limitIncomePeriodTypes.find(x => x.id === tax.limitIncomePeriodType);
                    this.locationName = this.getReadonlyLocationName();
                } else {
                    this.createNewTax();
                }

            });
            this.processStoredFilters();
        } else {
            this.createNewTax();
        }
    }

    applyFilter(filters: SelectListInterface[]): void {
        this.gridFilter = new TaxRateFilterModel(this.taxId);
        if (filters?.length > 0) {
            filters.forEach((filter: SelectListInterface) => {
                switch (filter.typeId) {
                    case TaxesRateFilterType.date:
                        this.gridFilter.effectiveDate = DateHelper.convertToUtcDate(filter.value);
                        break;
                    case TaxesRateFilterType.specializationKey:
                        this.gridFilter.taxSpecializationTitle = filter.value;
                        break;
                    default:
                        this.gridFilter.fields.push({ id: +filter.id, idType: <TaxesGridFilterType>filter.typeId });
                        break;
                }
            });
        }

        this.fetchTaxData();
    }

    cancel(): void {
        this.setControlValues();
        this.toggleCardExpand(!this.isCardOpen);
    }

    canDeactivate(): boolean | Observable<boolean> {
        if (this.formGroup.dirty) {
            const response: Subject<boolean> = new Subject<boolean>();

            const modalInputs = new ConfirmModalInputModel();
            modalInputs.okButtonText = 'Yes';
            modalInputs.cancelButtonText = 'No';
            modalInputs.mainHeaderText = 'Pending Changes';
            modalInputs.message = StringConstants.messages.pendingChanges;

            const modalOutputs = new ModalOutputModel();
            modalOutputs.ok = (() => {
                response.next(true);
            }).bind(this);
            modalOutputs.cancel = (() => {
                response.next(false);
            }).bind(this);
            this.modalService.init(ConfirmComponent, modalInputs, modalOutputs);

            return response.asObservable();
        }

        return true;
    }

    createNewRate(): void {
        const newRate = new TaxRateModel(this.taxId);
        newRate.taxRateId = 0;

        this.router.navigate(['tax/rate/detail'], {
            queryParams: {
                taxId: this.taxId
            }
        });
    }

    fetchTaxData(): void {
        this.isLoading = true;
        this.taxRateService.getTaxRatesByFilter(this.gridFilter).subscribe(
            (taxRates: TaxRateModel[]) => {
                this.isLoading = false;
                if (taxRates) {
                    this.taxRates = taxRates;
                }
            });
    }

    getReadonlyLocationName(): string {
        if (this.tax && this.tax.taxJurisdiction) {
            const jurisdiction = this.tax.taxJurisdiction;
            let locationName: string = '';
            if (jurisdiction.countryName) {
                locationName = jurisdiction.countryName;
            }

            if (jurisdiction.stateName) {
                if (locationName) {
                    locationName += `, ${jurisdiction.stateName}`;
                } else {
                    locationName = jurisdiction.stateName;
                }
            }

            if (jurisdiction.cityName) {
                if (locationName) {
                    locationName += `, ${jurisdiction.cityName}`;
                } else {
                    locationName = jurisdiction.cityName;
                }
            }

            if (jurisdiction.countyName) {
                if (locationName) {
                    locationName += `, ${jurisdiction.countyName}`;
                } else {
                    locationName = jurisdiction.countyName;
                }
            }

            return locationName;
        }

        return '';
    }

    rateActiveCheck(params) {
        const today: Date = new Date();
        const endDate: Date = DateHelper.convertToUtcDate(params?.data?.effectiveEndDate);
        if (endDate && DateHelper.greaterThan(today, endDate)) {
            return { opacity: '.5' };
        }
        return;
    }

    onRowClick(row: RowEvent): void {
        const taxRateId = row?.data?.taxRateId;
        if (taxRateId) {
            this.router.navigate(['tax/rate/detail', taxRateId]);
        }
    }

    onDeleteClick(rowIndex: number) {
        const row = this.gtnBaseGrid.gridApi.getRowNode(rowIndex.toString());
        const taxRateId = row?.data?.taxRateId;

        if (!taxRateId) {
            return;
        }

        this.formGroupHelper.tax.taxRates = this.formGroupHelper.tax.taxRates.filter(x => x.taxRateId !== taxRateId);
        this.taxRateService.delete(taxRateId).subscribe((success: boolean) => {
            if (success) {
                this.fetchTaxData();
            }
        });
    }

    onCloneClick(rowIndex: number) {
        const row = this.gtnBaseGrid.gridApi.getRowNode(rowIndex.toString());
        const taxRateId = row?.data?.taxRateId;

        if (!taxRateId) {
            return;
        }

        this.router.navigate(['tax/rate/detail'], {
            queryParams: {
                taxRateId
            }
        });
    }

    onIncomePeriodTypeChanged(value: LimitIncomePeriodType) {
        if (value) {
            this.selectedIncomePeriodType = this.limitIncomePeriodTypes.find(x => x.id === value);
        } else {
            this.selectedIncomePeriodType = null;
        }
    }

    return(): void {
        this.location.back();
    }

    save(): void {
        this.tax = this.formGroupHelper.updateModel(this.tax);
        this.tax.taxWithholdingType.taxWithholdingTypeId = this.tax.taxWithholdingTypeId;

        if (this.tax.taxId > 0) {
            this.taxService.update(this.tax).subscribe((tax: TaxModel) => {
                this.toggleCardExpand(!this.isCardOpen);
                this.name = tax.name;
                this.resetForm(JSON.parse(JSON.stringify(tax)));
            });
        } else {
            this.taxService.create(this.tax).subscribe((tax: TaxModel) => {
                this.toggleCardExpand(!this.isCardOpen);
                this.name = tax.name;
                this.router.navigate(['tax/detail', tax.taxId]);
                this.resetForm(JSON.parse(JSON.stringify(tax)));
            });
        }
    }


    toggleCardExpand(willBeExpanded: boolean) {
        if (willBeExpanded) {
            const rowWidth = this.expandableCardContainer.nativeElement.scrollWidth + this.expandableMainContent.nativeElement.scrollWidth;
            const mainContentExpandedWidth = rowWidth - this.cardExpandedWidth - 18;

            setTimeout(() => {
                this.expandableCardContainer.nativeElement.style.width = this.cardExpandedWidth + 'px';
                this.expandableCardContainer.nativeElement.style.maxWidth = this.cardExpandedWidth + 'px';
                this.expandableCardContainer.nativeElement.style.flex = '0 0 100%';

                this.expandableMainContent.nativeElement.style.width = mainContentExpandedWidth + 'px';
                this.expandableMainContent.nativeElement.style.maxWidth = mainContentExpandedWidth + 'px';
            }, 0);
        }

        if (!willBeExpanded) {
            this.expandableCardContainer.nativeElement.style.width = null;
            this.expandableCardContainer.nativeElement.style.maxWidth = null;
            this.expandableCardContainer.nativeElement.style.flex = null;
            this.expandableMainContent.nativeElement.style.width = null;
            this.expandableMainContent.nativeElement.style.maxWidth = null;
        }

        setTimeout(() => {
            this.isCardOpen = willBeExpanded;
            this.windowResizeService.resize();
        }, 300);
    }

    onCityCountyCheckboxChanged(showCountySelect: boolean) {
        this.isCountySelectVisible = showCountySelect;
    }

    private processStoredFilters() {
        const filters: SelectListInterface[] = [];
        const taxesGridFilters = LocalStorageHelper.get(LocalStorageNameConstants.TaxesGrid);
        if (taxesGridFilters) {
            taxesGridFilters.forEach((filter: SelectListInterface) => {
                switch (filter.typeId) {
                    case TaxesGridFilterType.date:
                        filter.typeId = TaxesRateFilterType.date;
                        filters.push(filter);
                        break;
                    case TaxesGridFilterType.specializationKey:
                        filters.push(filter);
                        break;
                    default:
                        break;
                }
            });
        }

        const ratesGridFilters = LocalStorageHelper.get(LocalStorageNameConstants.TaxRatesGrid);
        if (ratesGridFilters) {
            ratesGridFilters.forEach((filter: SelectListInterface) => {
                switch (filter.typeId) {
                    case TaxesRateFilterType.date:
                        if (!filters.find(x => x.typeId === TaxesGridFilterType.date)) {
                            filters.push(filter);
                        }
                        break;
                    case TaxesRateFilterType.qualifierId:
                        filters.push(filter);
                        break;
                    case TaxesRateFilterType.specializationKey:
                        if (!filters.find(x => x.typeId === TaxesGridFilterType.date)) {
                            filters.push(filter);
                        }
                        break;
                }
            });
        }

        this.applyFilter(filters);
    }

    private createNewTax(): void {
        this.tax = new TaxModel();
        const tax = JSON.parse(JSON.stringify(this.tax));
        this.formGroupHelper.tax = tax;
        this.name = tax.name;
        this.tax.taxJurisdiction = new TaxJurisdictionModel();
        this.tax.taxWithholdingType = new TaxWithholdingTypeModel();
        this.tax.limitIncomePeriodType = LimitIncomePeriodType.YearToDate;
        this.formGroupHelper.taxJurisdiction = this.tax.taxJurisdiction;
        this.formGroupHelper.setControlValues();
        setTimeout(() => {
            this.taxRates = [];
            this.toggleCardExpand(true);
        }, 0);
    }

    private createLimitIncomePeriodTypeOptions(): SelectListInterface[] {
        const types: SelectListInterface[] = EnumHelper.convertToSelectList(LimitIncomePeriodType, true, false, 'number');

        types.find(x => x.id === LimitIncomePeriodType.BiWeekly).value = 'Bi-weekly';
        types.find(x => x.id === LimitIncomePeriodType.YearToDate).value = 'Year to Date';

        return types;
    }

    private resetForm(tax: TaxModel): void {
        this.tax = tax;
        this.locationName = this.getReadonlyLocationName();
        this.formGroupHelper.tax = tax;
        this.formGroupHelper.taxJurisdiction = tax.taxJurisdiction;
        this.formGroup.updateValueAndValidity();
        this.formGroup.markAsPristine();
    }

    private setControlValues() {
        if (this.tax?.taxJurisdiction?.countyId) {
            this.onCityCountyCheckboxChanged(true);
        }

        this.formGroupHelper.tax = JSON.parse(JSON.stringify(this.tax));
        this.formGroupHelper.taxJurisdiction = JSON.parse(JSON.stringify(this.tax.taxJurisdiction));
        this.formGroupHelper.taxId = this.tax.taxId;
        this.formGroupHelper.setControlValues();
    }
}
