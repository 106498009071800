export enum ControlTypeEnum {
    Unknown = 0,
    Autocomplete = 1,
    Checkbox = 2,
    Combobox = 3,
    Currency = 4,
    Date = 5,
    Dropdown = 6,
    Multiselect = 7,
    Number = 8,
    Radio = 9,
    Text = 10,
    TextArea = 11
}
