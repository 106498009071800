import { CommonBaseAuditModel } from '../../contacts/models/common-base-audit.model';
import { AgreementSubtypeEnum } from '../enums/agreement-subtype.enum';
import { AgreementTypeEnum } from '../enums/agreement-type.enum';

export interface IAgreementTypeModelInterface extends CommonBaseAuditModel {
    agreementTypeKey: string;
    templateKey: string;
    isActive: boolean;
    name: string;
    shortName: string;
    subtype: AgreementSubtypeEnum;
    type: AgreementTypeEnum;
}
