import { CommonBaseAuditModel } from 'app/nexus-shared/domain/contacts/models/common-base-audit.model';
import { QuestionnaireTypesEnum } from 'app/nexus-shared/domain/questionnaires/enums/questionnaire-types.enum';
import { QuestionnaireLocationModel } from 'app/nexus-shared/domain/questionnaires/models/questionnaire-location.model';
import { SectionModel } from 'app/nexus-shared/domain/questionnaires/models/section.model';
import { QuestionnaireTemplateStatusesEnum } from 'app/nexus-shared/domain/questionnaires/enums/questionnaire-template-statuses.enum';

export class QuestionnaireTemplateModel extends CommonBaseAuditModel {
    questionnaireTemplateKey: string;
    historicalKey: string;
    questionnaireType: QuestionnaireTypesEnum;
    title: string;
    year: number | null;
    notes: string;
    status: QuestionnaireTemplateStatusesEnum;
    location: QuestionnaireLocationModel;
    sections: SectionModel[];

    constructor(model: QuestionnaireTemplateModel = null) {
        super(model);
        this.questionnaireTemplateKey = model?.questionnaireTemplateKey ?? null;
        this.historicalKey = model?.historicalKey ?? null;
        this.questionnaireType = model?.questionnaireType ?? null;
        this.title = model?.title ?? null;
        this.year = model?.year ?? null;
        this.notes = model?.notes ?? null;
        this.status = model?.status ?? null;
        this.location = new QuestionnaireLocationModel(model?.location);
        this.sections = model?.sections?.length ? model?.sections.map(x => new SectionModel(x)) : [];
    }
}
