import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BaseFormComponent } from 'app/nexus-shared/components/base-component/base-form.component';
import { IndividualModel, IndividualToCompanyAccessModel } from 'app/nexus-shared/domain/contacts/models';
import { IndividualToCompanyAccessCompositeModel } from 'app/nexus-shared/domain/contacts/models/individual-to-company-access-composite.model';
import { ContactsFormHelper } from 'app/nexus-core/helpers/contacts-form.helper';

@Component({
    selector: 'gtn-individual-company-access-composite-form',
    templateUrl: './individual-company-access-composite-form.component.html',
    styleUrls: ['./individual-company-access-composite-form.component.scss']
})
export class IndividualCompanyAccessCompositeFormComponent extends BaseFormComponent<IndividualToCompanyAccessCompositeModel> implements OnInit {
    @Input() isControllingCompanyEmployee: boolean = true;

    ngOnInit() {
        if (!this.value.individual) {
            this.value.individual = new IndividualModel();
        }
        if (!this.value.individualToCompanyAccess) {
            this.value.individualToCompanyAccess = new IndividualToCompanyAccessModel();
        }

        const formConfiguration = ContactsFormHelper.getIndividualToCompanyAccessCompositeFormConfiguration();
        super.ngOnInit(formConfiguration);
    }

    getCompositeFormGroupRef(ref): UntypedFormGroup {
        return this.formGroupRef.get(ref) as UntypedFormGroup;
    }
}
