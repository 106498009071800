import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { TaxPreparersTravelReportModel } from 'app/nexus-shared/domain/travel-calendar/models/tax-preparers-travel-report.model';
import { IndividualSettingService } from 'app/nexus-core/services/domain/core/individual-setting.service';
import { ColDef, GridOptions } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { AgGridColDefHelper } from 'app/nexus-core/helpers/ag-grid-col-def.helper';
import { map } from 'rxjs/operators';
import { Dictionary } from 'app/nexus-shared/models/dictionary';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { BaseTravelCalendarReportingGridComponent } from 'app/nexus-shared/domain/travel-calendar/components/grids/base-grids/base-travel-calendar-reporting-grid.component';
import { DateHelper } from 'app/nexus-core';
import { SimpleChangesTyped } from 'app/nexus-shared/models/simple-changes-typed.type';

@Component({
    selector: 'gtn-tax-preparer-travel-report-grid',
    templateUrl: './tax-preparer-travel-report-grid.component.html',
    styleUrls: ['./tax-preparer-travel-report-grid.component.scss']
})
export class TaxPreparerTravelReportGridComponent extends BaseTravelCalendarReportingGridComponent<TaxPreparersTravelReportModel> implements OnInit, OnChanges {
    @Input() individualKey: string;
    @Input() startDate: Date;
    @Input() endDate: Date;

    constructor(protected individualSettingService: IndividualSettingService) {
        super(individualSettingService);
    }

    ngOnChanges(changes: SimpleChangesTyped<this>) {
        if (changes?.searchModel && !changes.searchModel.isFirstChange()) {
            this.refreshGridData();
        }
    }
    ngOnInit() {
        const gridOptions: GridOptions = {};
        super.ngOnInit(gridOptions);
    }

    protected setColumnDefinitions(): ColDef[] {
        const colDefs = this.createYearColumnDefinitions();
        colDefs.unshift(AgGridColDefHelper.colDefGenerator('location', 'Location'));
        return colDefs;
    }

    protected setRowData(): Observable<any> {
        return this.travelCalendarReportService.getTaxPreparersTravelReport(this.searchModel).pipe(map(res => {
            return this.createRowData(res);
        }));
    }

    private createRowData(model: TaxPreparersTravelReportModel): any {
        const rowData = [];
        Object.keys(model).forEach(key => {
            Object.keys(model[key]).forEach(location => {
                const rowModel = new TravelReportGridViewModel();
                rowModel.location = location;
                rowModel.years = model[key][location];
                rowData.push(rowModel);
            });
        });
        return rowData;
    }

    private createYearColumnDefinitions(): ColDef[] {
        const columnDefinitions: ColDef[] = [];
        let startYear = DateHelper.convertToUtcDate(this.searchModel.startDate).getFullYear();
        const endYear = DateHelper.convertToUtcDate(this.searchModel.endDate).getFullYear();
        while (startYear <= endYear) {
            columnDefinitions.push(AgGridColDefHelper.getNumberColDef(ObjectHelper.nameOfSubProperty<TravelReportGridViewModel, Dictionary<string>>('years', startYear.toString()), startYear.toString()));
            startYear++;
        }
        columnDefinitions.push(AgGridColDefHelper.getNumberColDef(ObjectHelper.nameOfSubProperty<TravelReportGridViewModel, Dictionary<string>>('years', 'total'), 'Total'));
        return columnDefinitions;
    }
}

export class TravelReportGridViewModel {
    location: string;
    years: Dictionary<string>;
    total: number;
}
