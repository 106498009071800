import { animate, AnimationMetadata, style, transition, trigger } from '@angular/animations';

export class ModalFadeIn {
    /**
     * Use '@modalFadeIn' in markup.
     */
    static animations = ModalFadeIn.getAnimation();

    private static getAnimation(): AnimationMetadata {
        return trigger('modalFadeIn', [
            transition(':enter', [
                style({ transform: 'scale(.7)', opacity: 0 }),
                animate(`0.2s ease-in`, style({ transform: 'scale(1)', opacity: 1 }))
            ]),
            transition(':leave', [
                animate(`0.2s ease-out`, style({ transform: 'scale(.7)', opacity: 0 }))
            ])
        ]);
    }
}
