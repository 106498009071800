import { animate, AnimationMetadata, style, transition, trigger } from '@angular/animations';

export class FadeInBumpDown {
    /**
     * Use '@fadeInBumpDown' in the markup.
     */
    static animations = (delay: string) => FadeInBumpDown.getAnimation(delay);

    private static getAnimation(delay: string): AnimationMetadata {
        return trigger('fadeInBumpDown', [
            transition(':enter', [
                style({ transform: 'translateY(-40%)', opacity: 0 }),
                animate(`${delay} ease-in`, style({ transform: 'translateY(0%)', opacity: 1 }))
            ]),
            transition(':leave', [
                animate(`${delay} ease-out`, style({ transform: 'translateY(-40%)', opacity: 0 }))
            ])
        ]);
    }
}
