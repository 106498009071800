import { Injectable } from '@angular/core';
import { IFolderIndividualAccessServiceInterface } from './ifolder-individual-access.service';
import { environment } from 'environments/environment';
import { BaseHttpService } from '../../base-http.service';
import { FolderIndividualAccessModel } from 'app/nexus-shared/domain/documents/models/folder-individual-access.model';
import { Observable } from 'rxjs';
import { FolderIndividualAccessSearchModel } from 'app/nexus-shared/domain/documents/models/folder-individual-access-search.model';

@Injectable({
    providedIn: 'root'
})
export class FolderIndividualAccessService implements IFolderIndividualAccessServiceInterface {

    private apiUrl = `${environment().webAPIUrls.documents}folder-individual-accesses/`;

    constructor(private baseHttpService: BaseHttpService) {
    }

    get(folderKey: string): Observable<FolderIndividualAccessModel> {
        return this.baseHttpService.get(`${this.apiUrl}${folderKey}`);
    }

    search(searchModel: FolderIndividualAccessSearchModel): Observable<FolderIndividualAccessModel[]> {
        return this.baseHttpService.post(`${this.apiUrl}search`, searchModel);
    }

    create(model: FolderIndividualAccessModel): Observable<string> {
        return this.baseHttpService.post(this.apiUrl, model);
    }

    update(model: FolderIndividualAccessModel): Observable<boolean> {
        return this.baseHttpService.put(this.apiUrl, model);
    }

    upsert(folderKey: string, model: FolderIndividualAccessModel[]): Observable<boolean> {
        return this.baseHttpService.post(`${this.apiUrl}${folderKey}/upsert`, model);
    }

    delete(folderIndividualAccessKey: string): Observable<boolean> {
        return this.baseHttpService.delete(`${this.apiUrl}/${folderIndividualAccessKey}}`);
    }
}