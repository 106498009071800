import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';

@Component({
    selector: 'gtn-tool-summary-panel',
    templateUrl: './tool-summary-panel.component.html',
    styleUrls: ['./tool-summary-panel.component.scss']
})
export class ToolSummaryPanelComponent extends BaseComponent {
    @Input() imageSrc: string;
    @Input() imageTxt: string;
    @Input() subtitle: string;
    @Input() isPrimaryBackground: boolean;
    @Input() summaryTxt: string;

    @Output() toolClick: EventEmitter<void> = new EventEmitter();

    onClicked() {
        this.toolClick.emit();
    }
}
