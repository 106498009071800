import { Component, TemplateRef, ViewChild } from '@angular/core';

@Component({
    selector: 'gtn-progress-step',
    template: `
        <ng-template #content>
            <ng-content></ng-content>
        </ng-template>`
})

export class ProgressStepComponent {
    @ViewChild('content') content: TemplateRef<any>;
}
