<gtn-template-grid [showQuickFilter]="true" (quickFilterTextChange)="onFilterTextChanged($event)">
    <gtn-template-grid-controls-left>
        <div>
            <button (click)="onTemplateCopyClicked()" [disabled]="!isTemplatesSelected" class="ml-2 mt-3"
                color="default"
                mat-mini-fab matTooltip="Copy to selected folder">
                <mat-icon>copy_all</mat-icon>
            </button>
            <button (click)="onDeselectAllClicked()" [disabled]="!isTemplatesSelected" class="ml-2 mt-3" color="default"
                mat-mini-fab matTooltip="Deselect All">
                <mat-icon>deselect</mat-icon>
            </button>
        </div>
    </gtn-template-grid-controls-left>
    <gtn-template-grid-content>
        <ag-grid-angular [gtnHeight]="gridHeight" *ngIf="gridOptions" class="ag-theme-balham customized-theme"
            [gridOptions]="gridOptions"></ag-grid-angular>
    </gtn-template-grid-content>
</gtn-template-grid>
