import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { DateHelper, EnumHelper } from 'app/nexus-core';
import { ApplicationsEnum, SelectListInterface } from 'app/nexus-shared/index';
import { BaseFormComponent } from 'app/nexus-shared/components/base-component/base-form.component';
import { RelationshipEndReasonTypesEnum } from 'app/nexus-shared/domain/contacts/enums/relationship-end-reason-types.enum';
import { CompanyModel, IndividualModel } from 'app/nexus-shared/domain/contacts/models';
import { IndividualToCompanyAccessModel } from 'app/nexus-shared/domain/contacts/models/individual-to-company-access.model';
import { CostCenterModel } from 'app/nexus-shared/domain/contacts/models/cost-center.model';
import { GtnDepartmentsEnum } from 'app/nexus-shared/domain/contacts/enums/gtn-departments.enum';
import { GtnTitlesEnum } from 'app/nexus-shared/domain/contacts/enums/gtn-titles.enum';
import { environment } from 'environments/environment';
import { SimpleChangesTyped } from 'app/nexus-shared/models/simple-changes-typed.type';
import { IndividualTypesEnum } from 'app/nexus-shared/domain/contacts/enums/individual-types.enum';
import { RelationshipSubTypesEnum } from 'app/nexus-shared/domain/contacts/enums/relationship-sub-types.enum';
import { UntypedFormGroup } from '@angular/forms';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { IndividualSearchModel } from 'app/nexus-shared/domain/contacts/models/individual-search.model';
import { ContactsFormHelper } from 'app/nexus-core/helpers/contacts-form.helper';

@Component({
    selector: 'gtn-individual-to-company-access-form',
    templateUrl: './individual-company-access-form.component.html',
    styleUrls: ['./individual-company-access-form.component.scss']
})
export class IndividualToCompanyAccessFormComponent extends BaseFormComponent<IndividualToCompanyAccessModel> implements OnChanges, OnInit {
    @Input() individual: IndividualModel;
    @Input() isMappingIndividualToCompany: boolean;
    @Input() isMappingCompanyToIndividual: boolean;
    @Input() applicationSource = ApplicationsEnum.Contacts;
    @Input() isControllingCompany: boolean = false;
    @Input() isRelationshipTypeReadonly: boolean = false;
    @Input() canAddNewIndividual: boolean = false;
    @Input() canAddNewCompany: boolean = false;

    @Output() isControllingCompanyChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() individualChange: EventEmitter<string> = new EventEmitter<string>();
    @Output() createIndividual: EventEmitter<void> = new EventEmitter();
    @Output() createCompany: EventEmitter<void> = new EventEmitter();

    applicationsEnum = ApplicationsEnum;
    costCenterOptions: CostCenterModel[] = [];
    gtnDepartmentOptions: SelectListInterface[];
    gtnTitles: SelectListInterface[];
    isSecondaryPhoneNumber: boolean;
    primaryPhoneNumberFormGroup: UntypedFormGroup;
    secondaryPhoneNumberFormGroup: UntypedFormGroup;
    relationshipTypesEnum = IndividualTypesEnum;
    relationshipEndOptions: SelectListInterface[] = [];
    relationshipSubTypeOptions: SelectListInterface[] = [];
    relationshipTypes: SelectListInterface[];
    individualSearchModel: IndividualSearchModel = <IndividualSearchModel>{ individualTypes: [] };

    private individualTypes: IndividualTypesEnum[] = [
        IndividualTypesEnum.Unknown,
        IndividualTypesEnum.MobileEmployee,
        IndividualTypesEnum.Independent,
        IndividualTypesEnum.CompanyContact,
        IndividualTypesEnum.CompanyAdministrator,
        IndividualTypesEnum.RelocationAdministrator,
        IndividualTypesEnum.GtnEmployee,
    ];
    private controllingCompanyKey: string = environment().controllingCompanyKey;

    ngOnChanges(changes: SimpleChangesTyped<this>) {
        super.ngOnChanges(changes);
    }

    ngOnInit(): void {
        this.individualSearchModel.individualTypes = this.individualTypes;
        const formConfiguration = ContactsFormHelper.getIndividualToCompanyAccessFormConfiguration();
        super.ngOnInit(formConfiguration);
    }

    initUIControls(): void {
        this.relationshipEndOptions = EnumHelper.convertToSelectList(RelationshipEndReasonTypesEnum, false, true);
        this.gtnDepartmentOptions = EnumHelper.convertToSelectList(GtnDepartmentsEnum, false, true);
        this.gtnTitles = EnumHelper.convertToSelectList(GtnTitlesEnum, false, true);
        this.relationshipTypes = EnumHelper.convertToSelectList(IndividualTypesEnum, false, false).filter(x => x.id !== IndividualTypesEnum.GtnEmployee);
        this.relationshipSubTypeOptions = EnumHelper.convertToSelectList(RelationshipSubTypesEnum, false, false);
    }

    protected initFormCustomizations(): void {
        this.primaryPhoneNumberFormGroup = this.formGroupRef.get(ObjectHelper.nameOf<IndividualToCompanyAccessModel>('primaryPhoneNumber')) as UntypedFormGroup;
        this.secondaryPhoneNumberFormGroup = this.formGroupRef.get(ObjectHelper.nameOf<IndividualToCompanyAccessModel>('secondaryPhoneNumber')) as UntypedFormGroup;
        if (!this.value?.individualToCompanyAccessKey) {
            this.formGroupRef.get(ObjectHelper.nameOf<IndividualToCompanyAccessModel>('effectiveStartDate')).setValue(DateHelper.convertToUtcDate(new Date()));
        }
    }

    onCreateCompanyClicked(): void {
        this.createCompany.emit();
    }

    onCreateIndividualClicked(): void {
        this.createIndividual.emit();
    }

    onCompanySelected(company: CompanyModel): void {
        this.isControllingCompany = company?.companyKey === this.controllingCompanyKey;
        this.isControllingCompanyChange.emit(this.isControllingCompany);
    }

    onIndividualSelected(individual: IndividualModel) {
        if (individual) {
            this.individualChange.emit(individual.individualKey);
        }
    }

    // private setCompanyCostCenterOptions(companyKey: string = null): void {
    //     if (companyKey || this.value.company?.companyKey || this.companyKey) {
    //         this.costCenterOptions = [];
    //         this.companyService.get(companyKey || this.companyKey || this.value.company.companyKey).subscribe((company: CompanyModel) => {
    //             this.costCenterOptions = company?.costCenters || [];
    //             if (company?.costCenters?.length) {
    //                 this.costCenterOptions.unshift({ costCenterKey: null, name: '' });
    //             }
    //         });
    //     } else {
    //         this.costCenterOptions = [];
    //     }
    // }
}
