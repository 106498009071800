<h1 class="page-top-header"
    *ngIf="pageTitle">
    <span>{{ pageTitle }}</span>
</h1>
<mat-card>
    <gtn-validation *ngIf="validationModels?.length"
        class="validation-container"
        [validationModels]="validationModels">
    </gtn-validation>
    <div #cardContainer
        [ngClass]="{'read-only-form': readonly, 'edit-form': !readonly}">
        <mat-card-title class="gtn-template-readonly-edit-card-header"
            *ngIf="showCardTitle">
            <ng-content select="gtn-template-readonly-edit-card-header"></ng-content>
            <a href="javascript:void(0);"
                class="card-title-icon"
                *ngIf="showCardTitleIcon"
                (click)="toggleReadonly()">
                <i class="fas"
                    [ngClass]="{'fa-times': !readonly, 'fa-edit': readonly, 'disabled': disableReadonlyEditToggle}"></i>
            </a>
        </mat-card-title>
        <mat-card-content class="gtn-template-readonly-edit-card-view-content"
            [ngClass]="{'overflow-x': isOverflowEditContent}"
            *ngIf="readonly">
            <ng-content select="gtn-template-readonly-edit-card-view-content"></ng-content>
        </mat-card-content>
        <mat-card-content @menuOpen
            class="gtn-template-readonly-edit-card-edit-content"
            [ngClass]="{'overflow-x': isOverflowEditContent}"
            *ngIf="!readonly">
            <ng-content select="gtn-template-readonly-edit-card-edit-content"></ng-content>
        </mat-card-content>
        <div class="card-edit-form-button-group"
            *ngIf="showActionButtonsInCard && !readonly">
            <button *ngIf="showDeleteButton"
                mat-flat-button
                (click)="onDeleteClicked()"
                class="delete">{{ deleteButtonText }}
            </button>
            <ng-content select="gtn-template-readonly-edit-card-footer"></ng-content>
            <button mat-flat-button
                (click)="onSaveClicked()"
                [disabled]="isSaveButtonDisabled()"
                color="accent">{{ isSaving ? 'Saving' : 'Save' }}
                <i *ngIf="isSaving"
                    class="fas fa-spinner fa-spin"></i>
            </button>
            <button mat-flat-button
                (click)="onCancelClicked()"
                [disabled]="isSaving"
                class="cancel-button">Cancel
            </button>
        </div>
    </div>
</mat-card>
<div class="form-button-group"
    *ngIf="!showActionButtonsInCard && showActionButtons">
    <ng-content select="gtn-template-readonly-edit-card-footer"></ng-content>
    <button mat-flat-button
        (click)="onSaveClicked()"
        [disabled]="isSaveButtonDisabled()"
        class="mat-accent save">{{ isSaving ? 'Saving' : 'Save' }}
        <i *ngIf="isSaving"
            class="fas fa-spinner fa-spin"></i>
    </button>
    <button mat-flat-button
        (click)="onCancelClicked()"
        [disabled]="isSaving"
        class="cancel-button">Cancel
    </button>
</div>
<gtn-pending-changes-modal *ngIf="pendingChanges.showPendingChanges"
    (closeCancelClick)="onClosePendingChangesClicked()"
    (discardClick)="onDiscardPendingChangesClicked()"
    (saveClick)="onSaveClicked()"></gtn-pending-changes-modal>
