import { Component, OnInit } from '@angular/core';
import { ApplicationConfigurationService } from 'app/nexus-core';
import { BaseRouteApplicationConfigurationModel } from 'app/nexus-shared/models/base-route-application-configuration.model';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { AuthenticationService } from 'app/nexus-core/services/authentication.service';
import { AuthenticatedUserService } from 'app/nexus-core/services/authenticated-user.service';
import { ApplicationSearchService } from 'app/nexus-core/services/application-search.service';
import { AuthorizationHelper } from 'app/nexus-core/helpers/authorization.helper';
import { AuthenticatedUserSyncService } from 'app/nexus-core/services/authenticated-user-sync.service';
import { environment } from 'environments/environment';
import { UserImpersonationService } from 'app/nexus-core/services/domain/users/user-impersonation.service';
import { UserImpersonationModel } from 'app/nexus-shared/domain/users/models/user-impersonation.model';
import { AuthorizationConstants } from 'app/nexus-shared/constants/authorization.constants';
import { UserService } from 'app/nexus-core/services/domain/users/user.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
    selector: 'gtn-nav-header',
    templateUrl: './nav-header.component.html',
    styleUrls: ['./nav-header.component.scss']
})
export class NavHeaderComponent extends BaseComponent implements OnInit {
    navMenuItems: BaseRouteApplicationConfigurationModel[] = null;
    isSearchVisible: boolean = false;
    publicBlobStorageUrl: string;
    userImpersonation: UserImpersonationModel = null;
    hasImpersonationAccess = false;
    hasRememberedDevice = false;
    hasGtnAuthorization = false;
    isSyncCompleted = false;
    profilePictureSrcUrl: SafeUrl | string = null;

    constructor(
        private userImpersonationService: UserImpersonationService,
        private userService: UserService,
        private domSanitizer: DomSanitizer,
        public applicationConfigurationService: ApplicationConfigurationService,
        public searchService: ApplicationSearchService,
        public authenticationService: AuthenticationService,
        public authenticatedUserService: AuthenticatedUserService,
        public authenticatedUserSyncService: AuthenticatedUserSyncService,
    ) {
        super();
    }

    ngOnInit() {
        this.publicBlobStorageUrl = environment().publicBlobStorageUrl;

        this.authenticatedUserSyncService.isSyncedCompleted.subscribe(_ => {
            this.hasRememberedDevice = this.authenticationService.hasRememberedDevice();
            this.hasGtnAuthorization = this.authenticatedUserService.isGtn;
            this.isSyncCompleted = true;

            const navMenuItems = this.applicationConfigurationService.getApplicationMenuConfiguration();
            if (navMenuItems?.length) {
                this.navMenuItems = navMenuItems.filter(t => AuthorizationHelper.hasAuthorization(this.authenticatedUserService.user, t.authorization));
            }

            this.authenticatedUserService.isImpersonating$.subscribe(isImpersonating => {
                if (isImpersonating) {
                    this.userImpersonationService.getCurrentImpersonation().subscribe(userImpersonation => {
                        this.userImpersonation = userImpersonation;
                        this.hasImpersonationAccess = userImpersonation !== null || this.authenticatedUserService.hasAuthorization(AuthorizationConstants.authUserImpersonation);
                    });
                } else {
                    this.userImpersonation = null;
                    this.hasImpersonationAccess = this.authenticatedUserService.hasAuthorization(AuthorizationConstants.authUserImpersonation);
                }
            });

            this.profilePictureSrcUrl = this.authenticatedUserService.user.picture;
            if (this.authenticatedUserService.isGtn) {
                this.userService.getProfilePicture(this.authenticatedUserService.user.userKey).subscribe(blob => {
                    if (blob && blob.blob && blob.blob.size) {
                        this.profilePictureSrcUrl = this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob.blob));
                    }
                });
            }
        });

        this.searchService.isSearchbarVisible.subscribe(isVisible => {
            this.isSearchVisible = isVisible;
        });
    }

    navigateToNavMenuItem(navMenuItem: BaseRouteApplicationConfigurationModel): void {
        this.applicationConfigurationService.redirectToRoute(navMenuItem);
    }

    onLoginClicked(): void {
        this.authenticationService.login();
    }

    onLogoutClicked(): void {
        this.authenticationService.logout();
    }

    onForgetDeviceClicked(): void {
        this.authenticationService.forgetDevice();
        this.authenticationService.logout();
    }
}
