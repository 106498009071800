import { Pipe, PipeTransform } from '@angular/core';
import { LocationHelper } from 'app/nexus-core/helpers/location.helper';
import { LocationCompositeModel } from 'app/nexus-shared/domain/locations/models';

@Pipe({
    name: 'countryNames'
})
export class CountryNamesPipe implements PipeTransform {
    transform(locations: LocationCompositeModel[], separator: string = ', ', sort = true): string {
        const countries = locations?.map(l => l?.country);
        return LocationHelper.getLocationNames(countries, true, separator, sort);
    }
}
