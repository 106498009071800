import { CompanyModel } from 'app/nexus-shared/domain/contacts/models/company.model';
import { CompanyRelationshipTypesEnum } from 'app/nexus-shared/domain/contacts/enums/company-relationship-types.enum';
import { CommonBaseAuditModel } from 'app/nexus-shared/domain/contacts/models/common-base-audit.model';

export class CompanyRelationshipModel extends CommonBaseAuditModel {
    companyRelationshipKey: string;
    relationshipType: CompanyRelationshipTypesEnum;
    effectiveStartDate: Date;
    effectiveEndDate: Date;
    childCompany: CompanyModel;
    parentCompany: CompanyModel;

    constructor(model: CompanyRelationshipModel = null) {
        super(model);
        this.companyRelationshipKey = model?.companyRelationshipKey;
        this.relationshipType = model?.relationshipType;
        this.effectiveStartDate = model?.effectiveStartDate;
        this.effectiveEndDate = model?.effectiveEndDate;
        this.childCompany = model?.childCompany ?? null;
        this.parentCompany = model?.parentCompany ?? null;
    }
}
