import { BaseFormModel } from 'app/nexus-shared/models/base-form.model';
import { ValidatorFn } from '@angular/forms';

export class BaseFormArrayModel {
    template: any;
    addBlank?: boolean = false;
    formConfiguration?: BaseFormModel;
    validators?: ValidatorFn | ValidatorFn[];

    constructor(template: any, addBlank = false, formConfiguration = null, validators = null) {
        this.template = template;
        this.addBlank = addBlank;
        this.formConfiguration = formConfiguration;
        this.validators = validators;
    }
}

