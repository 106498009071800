import { Component } from '@angular/core';
import { BaseViewComponent } from 'app/nexus-shared/components/base-component/base-view.component';
import { IAgreementModelInterface } from '../../../interfaces/iagreement-model.interface';

@Component({
    selector: 'gtn-agreements-view',
    templateUrl: './agreements-view.component.html',
    styleUrls: ['./agreements-view.component.scss']
})
export class AgreementsViewComponent extends BaseViewComponent<IAgreementModelInterface> {
}
