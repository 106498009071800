import { Component, Input } from '@angular/core';
import { NexusModalService } from 'app/nexus-core';
import { NexusAnimations, ValidationModel } from 'app/nexus-shared/index';

@Component({
    selector: 'gtn-validation-errors-modal',
    templateUrl: './validation-errors-modal.component.html',
    styleUrls: ['./validation-errors-modal.component.scss'],
    animations: [
        NexusAnimations.modalFadeIn
    ]
})
export class ValidationErrorsModalComponent {

    @Input() validationModels: ValidationModel[];

    public destroyClick = (() => {
        this.destroy();
    }).bind(this);

    constructor(protected modalService: NexusModalService) {
    }

    public destroy() {
        this.modalService.destroy();
    }
}
