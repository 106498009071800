import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NexusAnimations } from 'app/nexus-shared/animations';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { DeviceService } from 'app/nexus-core/services/device.service';
import { AuthenticatedUserService } from 'app/nexus-core/services/authenticated-user.service';
import { AuthorizationConstants } from 'app/nexus-shared/constants/authorization.constants';
import { environment } from 'environments/environment';

@Component({
    selector: 'gtn-device-warning-modal',
    templateUrl: './device-warning-modal.component.html',
    animations: [
        NexusAnimations.modalFadeIn
    ],
    styles: [
        `.browser-link {
            margin-top: 6px;
            margin-left: 10px;
        }`
    ]
})
export class DeviceWarningModalComponent extends BaseComponent implements OnInit {
    @Output() completed = new EventEmitter();

    isRememberSelectionChecked: boolean = false;
    isBrowserValid: boolean = false;
    isMobileDevice: boolean = false;
    isTaxPayer: boolean = false;
    okButtonText: string = 'Acknowledge';

    constructor(
        public deviceService: DeviceService,
        private authenticatedUserService: AuthenticatedUserService) {
        super();
    }

    ngOnInit(): void {
        this.isMobileDevice = this.deviceService.isMobileDevice();
        this.isBrowserValid = (this.deviceService.isValidBrowser() || (!this.deviceService.isValidBrowser() && this.deviceService.isAcknowledged()));

        this.isTaxPayer = !this.authenticatedUserService.hasAuthorization(AuthorizationConstants.gtnAdministrator) && this.authenticatedUserService.hasAuthorization(AuthorizationConstants.portalMobileEmployee);

        // HELPERS FOR TESTING
        // this.isBrowserValid = false;
        // this.isMobileDevice = true;
        // this.isTaxPayer = true;

        if (this.isTaxPayer) {
            this.okButtonText = 'Go to Mobile Site';
        }
    }

    onPrimaryClicked(): void {
        if (this.isMobileDevice && this.isTaxPayer) {
            window.location.href = environment().externalUrls.mobile;
            return;
        } else {
            if (this.isMobileDevice && !this.isBrowserValid) {
                this.resetScreenToDesktop();

                return;
            }

            if (this.isRememberSelectionChecked) {
                this.deviceService.setAcknowledged();
            }
        }

        this.completed.emit();
    }

    onSecondaryClicked(): void {
        if (this.isMobileDevice && !this.isBrowserValid) {
            this.resetScreenToDesktop();

            return;
        }

        this.completed.emit();
    }

    private resetScreenToDesktop(): void {
        this.isMobileDevice = false;
        this.okButtonText = 'Acknowledge';
    }
}
