import { Component } from '@angular/core';
import { CollapsibleTrayComponent } from 'app/nexus-shared/components/controls/components/collapsible-tray/collapsible-tray.component';

@Component({
    selector: 'gtn-collapsible-tray-content',
    template: '<ng-content></ng-content>'
})
export class CollapsibleTrayContentComponent {
    // noinspection JSUnusedLocalSymbols
    constructor(collapsibleTrayComponent: CollapsibleTrayComponent) {
        //THIS WILL THROW AN ERROR IF ITS NOT NESTED WITHIN THE gtn-slide-out-tabs ELEMENT
    }
}
