import { AuthorizationConstants } from 'app/nexus-shared/constants/authorization.constants';
import { BaseRouteApplicationConfigurationModel } from 'app/nexus-shared/models/base-route-application-configuration.model';

export class NexusRoutesConstants {
    public static logout: BaseRouteApplicationConfigurationModel = {
        routing: {
            routerModulePath: 'logout',
            navigationRoute: () => [`/logout`]
        }
    };

    public static landing: BaseRouteApplicationConfigurationModel = {
        routing: {
            routerModulePath: 'landing',
            navigationRoute: () => [`landing`]
        }
    };

    public static redirect: BaseRouteApplicationConfigurationModel = {
        routing: {
            routerModulePath: 'redirect',
            navigationRoute: () => ['redirect']
        }
    };

    public static emailVerification: BaseRouteApplicationConfigurationModel = {
        routing: {
            routerModulePath: 'email-verification',
            navigationRoute: () => [`email-verification`]
        }
    };

    public static agreements: BaseRouteApplicationConfigurationModel = {
        routing: {
            routerModulePath: 'agreements',
            navigationRoute: () => [`agreements`]
        },
        authorization: [AuthorizationConstants.agreements],
    };

    public static contacts: BaseRouteApplicationConfigurationModel = {
        routing: {
            routerModulePath: 'contacts',
            navigationRoute: () => [`contacts`]
        },
        authorization: [AuthorizationConstants.contacts],
    };

    public static users: BaseRouteApplicationConfigurationModel = {
        routing: {
            routerModulePath: 'users',
            navigationRoute: () => [`users`]
        },
        authorization: [AuthorizationConstants.authUser],
    };

    public static tax: BaseRouteApplicationConfigurationModel = {
        routing: {
            routerModulePath: 'tax',
            navigationRoute: () => [`tax`]
        },
        authorization: [AuthorizationConstants.gtnUser],
    };

    public static questionnaires: BaseRouteApplicationConfigurationModel = {
        routing: {
            routerModulePath: 'questionnaires',
            navigationRoute: () => [`questionnaires`]
        },
        authorization: [AuthorizationConstants.gtnUser],
    };

    public static taxability: BaseRouteApplicationConfigurationModel = {
        routing: {
            routerModulePath: 'taxability',
            navigationRoute: () => [`taxability`]
        },
        authorization: [AuthorizationConstants.gtnUser],
    };

    public static documents: BaseRouteApplicationConfigurationModel = {
        routing: {
            routerModulePath: 'documents',
            navigationRoute: () => [`documents`]
        },
        authorization: [AuthorizationConstants.documents],
    };

    public static portal: BaseRouteApplicationConfigurationModel = {
        routing: {
            routerModulePath: 'portal',
            navigationRoute: () => [`portal`]
        },
        authorization: [AuthorizationConstants.portal],
    };
}
