import { Injectable } from '@angular/core';
import { BaseService } from 'app/nexus-core/services/base-service.directive';
import { environment } from 'environments/environment';
import { BaseHttpService } from 'app/nexus-core/services/base-http.service';
import { Observable } from 'rxjs';
import { ApplicationUrlsRequestModel } from 'app/nexus-shared/models/application-urls-request.model';
import { ApplicationUrlsModel } from 'app/nexus-shared/models/application-urls.model';

@Injectable()
export class ApplicationUrlsService extends BaseService {
    private apiUrl = `${environment().webAPIUrls.core}application-urls`;

    constructor(private baseHttpService: BaseHttpService) {
        super();
    }

    list(model: ApplicationUrlsRequestModel): Observable<ApplicationUrlsModel> {
        return this.baseHttpService.post(`${this.apiUrl}`, model);
    }
}
