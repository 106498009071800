import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatRadioButton } from '@angular/material/radio';
import { SelectListInterface } from 'app/nexus-shared/index';
import { CheckboxLabelPositionType } from 'app/nexus-shared/components/controls';
import { BaseControlComponent } from '../base-control.component';

@Component({
    selector: 'gtn-radio-group',
    templateUrl: './radio-group.component.html',
    styleUrls: ['./radio-group.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RadioGroupComponent),
            multi: true
        }
    ]
})
export class RadioGroupComponent extends BaseControlComponent<SelectListInterface> implements ControlValueAccessor {
    @Input() labelPosition: 'before' | 'after' = CheckboxLabelPositionType.after;
    @Input() options: SelectListInterface[] = [];
    @Input() idKey: string = 'id';
    @Input() displayKey: string = 'value';
    @Input() selected: MatRadioButton;
    @Input() horizontal: boolean = false;
}
