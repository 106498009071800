import { CommonBaseAuditModel } from "../../contacts/models/common-base-audit.model";
import { IAgreementsCompanyModelInterface } from "../interfaces/iagreements-company-model.interface";

export class AgreementsCompanyModel extends CommonBaseAuditModel implements IAgreementsCompanyModelInterface {
    companyKey: string;
    legalName: string;
    name: string;
    shortName: string;

    constructor(model: AgreementsCompanyModel | IAgreementsCompanyModelInterface = null) {
        super(model);

        this.companyKey = model?.companyKey ?? null;
        this.legalName = model?.legalName ?? null;
        this.name = model?.name ?? null;
        this.shortName = model?.shortName ?? null;
    }
}
