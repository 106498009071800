import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { IAgreementTypeModelInterface } from '../../../interfaces/iagreement-type-model.interface';
import { AgreementBaseFormComponent } from '../agreement-base-form.component';
import { AgreementTypeEnum } from '../../../enums/agreement-type.enum';
import { SelectListInterface } from 'app/nexus-shared/interfaces';
import { CompanyModel } from 'app/nexus-shared/domain/contacts/models';
import { AgreementWildcardConstants } from '../../../constants/agreement-wildcard.constants';
import { AgreementSubtypeEnum } from '../../../enums/agreement-subtype.enum';
import { SimpleChangesTyped } from 'app/nexus-shared/models/simple-changes-typed.type';
import { AgreementSpouseSendStatusEnum } from '../../../enums/agreement-spouse-send-status.enum';
import { EnumHelper } from 'app/nexus-core';
import { AgreementStatusEnum } from '../../../enums/agreement-status.enum';

@Component({
    selector: 'gtn-agreement-consent-form',
    templateUrl: './agreement-consent-form.component.html',
    styleUrls: ['./agreement-consent-form.component.scss']
})
export class AgreementConsentFormComponent extends AgreementBaseFormComponent implements OnInit, OnChanges {
    @Input() companies: CompanyModel[] = null;
    @Output() sendStatusChange: EventEmitter<AgreementSpouseSendStatusEnum> = new EventEmitter<AgreementSpouseSendStatusEnum>();

    applicableYearsOptions: SelectListInterface[] = [];
    agreementSpouseSendStatus = AgreementSpouseSendStatusEnum;
    selectedApplicableYearStart: SelectListInterface;
    selectedApplicableYearEnd: SelectListInterface;
    selectedCompany: CompanyModel;
    showCompanyOptions = false;
    spouseSendOptions: SelectListInterface[] = [];
    spouseSendStatus: AgreementSpouseSendStatusEnum = AgreementSpouseSendStatusEnum.Individual;

    ngOnInit(): void {
        super.ngOnInit();
    }

    ngOnChanges(changes: SimpleChangesTyped<this>) {
        super.ngOnChanges(changes);

        if (changes.companies) {
            this.processCompaniesChange();
        }
    }

    onApplicableYearChanged(year: SelectListInterface, controlName: string | 'start' | 'end') {
        if (controlName === 'start') {
            this.selectedApplicableYearStart = year;
        } else {
            this.selectedApplicableYearEnd = year;
        }

        this.setApplicableYearsControlValue();
    }

    onCompanyChange($event) {
        this.selectedCompany = $event.value;

        if (this.selectedCompany) {
            this.setCompany(this.selectedCompany);
        }
    }

    onSaveClicked(): void {
        this.value.status = AgreementStatusEnum.PendingSignature;
    }

    onSendStatusChanged($event) {
        this.spouseSendStatus = $event.value;

        switch (this.spouseSendStatus) {
            case AgreementSpouseSendStatusEnum.Spouse:
                this.updateSignerIndividual(this.signerSpouse);
                break;
            default:
                this.updateSignerIndividual(this.signerIndividual);
                break;
        }

        this.sendStatusChange.emit(this.spouseSendStatus);
    }

    protected getFilteredAgreementTypes(): IAgreementTypeModelInterface[] {
        return this.agreementTypes.filter(x => x.type === AgreementTypeEnum.ConsentForm);
    }

    protected initReferentialValues() {
        super.initReferentialValues();

        this.setApplicableYearsControlValue();
    }

    protected initUIControls(): void {
        super.initUIControls();

        this.spouseSendOptions = EnumHelper.convertToSelectList(AgreementSpouseSendStatusEnum, false, false, null, false);
        this.setInitialApplicableYears();
        this.applicableYearsOptions = this.setupApplicableYearsOptions();
    }

    protected updateSubtypeFlags() {
        if (!this.companies) {
            return;
        }

        const subtype = this.value.agreementType?.subtype ?? AgreementSubtypeEnum.None;

        switch (subtype) {
            case AgreementSubtypeEnum.CooperatingTaxOffice:
            case AgreementSubtypeEnum.ThirdPartyVendor:
                if (this.selectedCompany) {
                    this.setCompany(this.selectedCompany);
                }

                this.showCompanyOptions = true;
                break;
            default:
                this.showCompanyOptions = false;
                break;
        }
    }

    private createApplicationYearSelectOption(year: number): SelectListInterface {
        if (Number.isNaN(year)) {
            return null;
        }

        return {
            id: year, value: year.toString()
        };
    }

    private getApplicableYearOption(year: number) {
        if (this.selectedApplicableYearStart?.id && year === this.selectedApplicableYearStart?.id) {
            return this.selectedApplicableYearStart;
        }

        if (this.selectedApplicableYearEnd?.id && year === this.selectedApplicableYearEnd?.id) {
            return this.selectedApplicableYearEnd;
        }

        return this.createApplicationYearSelectOption(year);
    }

    private getApplicableYearsString(): string {
        if (!this.selectedApplicableYearStart && !this.selectedApplicableYearEnd) {
            return '';
        }

        if (this.selectedApplicableYearStart === this.selectedApplicableYearEnd || !this.selectedApplicableYearEnd) {
            return this.selectedApplicableYearStart.value;
        }

        if (!this.selectedApplicableYearStart) {
            return this.selectedApplicableYearEnd.value;
        }

        return `${this.selectedApplicableYearStart.value}-${this.selectedApplicableYearEnd.value}`;
    }

    private processCompaniesChange() {
        if (!this.companies || this.companies?.length < 1) {
            return;
        }

        let companyToSet: CompanyModel = null;

        if (this.showCompanyOptions) {
            this.selectedCompany = this.getInitialSelectedCompany();
            companyToSet = this.selectedCompany;
        }
        else {
            companyToSet = this.companies[0];
        }

        // break out of change detection cycle
        setTimeout(() => {
            this.setCompany(companyToSet);
        }, 0);
    }

    private setApplicableYearsControlValue(): void {
        const applicableYearsValue = this.getApplicableYearsString();
        this.setReferentialValue(AgreementWildcardConstants.taxYearsRange, applicableYearsValue);
    }

    private setCompany(company: CompanyModel) {
        const companyLegalName = company?.legalName ?? company?.name ?? '';
        this.setReferentialValue(AgreementWildcardConstants.companyLegalName, companyLegalName);
    }

    private getInitialSelectedCompany(): CompanyModel {
        const companyName = this.agreementsHelper.getReferentialValue(this.value.agreementReferentialValues, AgreementWildcardConstants.companyLegalName);
        return this.companies?.find(x => x.legalName === companyName || x.name === companyName);
    }

    private setInitialApplicableYears() {
        const applicableYearsString = this.agreementsHelper.getReferentialValue(this.value.agreementReferentialValues, AgreementWildcardConstants.taxYearsRange);
        if (!applicableYearsString) {
            return;
        }

        const years = applicableYearsString.split('-');

        if (years?.length > 0) {
            const startYear = Number(years[0]);
            this.selectedApplicableYearStart = this.createApplicationYearSelectOption(startYear);
        }

        if (years?.length > 1) {
            const endYear = Number(years[1]);
            this.selectedApplicableYearEnd = this.createApplicationYearSelectOption(endYear);
        }
    }

    private setupApplicableYearsOptions(): SelectListInterface[] {
        const nowYear = new Date().getFullYear(),
            endYear = nowYear + 10,
            response: SelectListInterface[] = [];
        let currentYear = nowYear - 5;

        if (this.selectedApplicableYearStart && currentYear > Number(this.selectedApplicableYearStart.id)) {
            response.push(this.selectedApplicableYearStart);
        }

        while (currentYear <= endYear) {
            const option = this.getApplicableYearOption(currentYear);

            if (option) {
                response.push(option);
            }

            if (!this.selectedApplicableYearStart && currentYear === nowYear - 2) {
                this.selectedApplicableYearStart = option;
            }
            else if (!this.selectedApplicableYearEnd && currentYear === nowYear + 7) {
                this.selectedApplicableYearEnd = option;
            }

            currentYear++;
        }

        if (this.selectedApplicableYearEnd && endYear < Number(this.selectedApplicableYearEnd.id)) {
            response.push(this.selectedApplicableYearEnd);
        }

        return response;
    }
}
