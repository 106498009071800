import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthenticatedUserService } from 'app/nexus-core/services/authenticated-user.service';
import { BaseDirective } from '../components/base-directive/base.directive';

@Directive({
    selector: '[gtnIfHasCompanyAuthorization]'
})
export class IfHasCompanyAuthorizationDirective extends BaseDirective implements OnInit {
    @Input() gtnIfHasCompanyAuthorization: string[] = [];

    private isRendered: boolean = false;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private authenticatedUserService: AuthenticatedUserService
    ) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.add(this.authenticatedUserService.user$.subscribe((_ => {
            if (!Array.isArray(this.gtnIfHasCompanyAuthorization)) {
                throw Error('Invalid input to IfHasCompanyAuthorizationDirective');
            }

            if (!this.isRendered && this.authenticatedUserService.hasCompanyAuthorization(this.gtnIfHasCompanyAuthorization)) {
                this.viewContainer.createEmbeddedView(this.templateRef);
                this.isRendered = true;
            } else if (!this.isRendered) {
                this.viewContainer.clear();
            }
        }).bind(this)));
    }
}
