import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CompanyService } from 'app/nexus-core/services/domain/contacts/company.service';
import { BaseReadonlyEditComponent } from 'app/nexus-shared/components/base-component/base-readonly-edit.component';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CompanyModel } from 'app/nexus-shared/domain/contacts/models';
import { AuthenticatedUserService } from 'app/nexus-core/services/authenticated-user.service';
import { AuthorizationConstants } from 'app/nexus-shared/constants/authorization.constants';
import { ApplicationsEnum } from 'app/nexus-shared/enums';
import { CompanyFormComponent } from 'app/nexus-shared/domain/contacts/components/forms/company-form/company-form.component';

@Component({
    selector: 'gtn-company-readonly-edit',
    templateUrl: './company-readonly-edit.component.html',
    styleUrls: ['./company-readonly-edit.component.scss']
})
export class CompanyReadonlyEditComponent extends BaseReadonlyEditComponent<CompanyModel, boolean> implements OnInit {
    @Input() companyKey: string = null;

    application = ApplicationsEnum.Contacts;
    canEdit = false;

    @ViewChild('companyForm') companyForm: CompanyFormComponent;

    constructor(private companyService: CompanyService, private authenticatedUserService: AuthenticatedUserService) {
        super();
    }

    ngOnInit(): void {
        this.canEdit = this.authenticatedUserService.hasAuthorization(AuthorizationConstants.contactsAdmin);

        super.ngOnInit();
    }

    getValue(value: CompanyModel = null): Observable<CompanyModel> {
        if (value) {
            return of(new CompanyModel(value));
        }

        return this.companyService.get(this.value.companyKey).pipe(map(company => {
            return new CompanyModel(company);
        }));
    }

    saveValue(): Observable<boolean> {
        return this.companyService.update(this.value);
    }

    addAddressClicked(): void {
        this.companyForm.onCreateAddressClicked();
    }
}
