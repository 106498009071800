import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseEntryComponent } from 'app/nexus-shared/components/base-component/base-entry.component';
import { CompanyModel } from 'app/nexus-shared/domain/contacts/models';
import { CompanyService } from 'app/nexus-core/services/domain/contacts/company.service';
import { CardModesEnum } from 'app/nexus-shared/enums/card-modes.enum';
import { ViewModesEnum } from 'app/nexus-shared/enums/view-modes.enum';
import { ApplicationsEnum } from 'app/nexus-shared/enums';

@Component({
    selector: 'gtn-company-create-template-modal',
    templateUrl: './company-create-template-modal.component.html',
    styleUrls: ['./company-create-template-modal.component.scss']
})
export class CompanyCreateTemplateModalComponent extends BaseEntryComponent<CompanyModel> {

    @Input() applicationSource: ApplicationsEnum = ApplicationsEnum.Contacts;
    @Input() isCreateNewCompany: boolean;
    @Input() isDualFormDirty: boolean;
    @Input() cardMode: CardModesEnum = CardModesEnum.hidden;
    @Input() viewMode: ViewModesEnum = ViewModesEnum.General;
    @Input() headerText: string;

    @Output() companySaveSuccess: EventEmitter<CompanyModel> = new EventEmitter<CompanyModel>();

    constructor(private companyService: CompanyService) {
        super();
    }

    onSaveClicked(): void {
        if (this.isCreateNewCompany) {
            this.isSaving = true;
            this.companyService.create(this.value).subscribe((key: string) => {
                this.value.companyKey = key;
                this.companySaveSuccess.emit(new CompanyModel(this.value));
                this.isSaving = false;
                this.onSaveSuccess();
            }, err => {
                this.onSaveFailure(err);
                this.isSaving = false;
            });
        } else {
            this.saveClick.emit();
        }
    }

    onCancelClicked(): void {
        this.cancelClick.emit();
    }
}
