import { FolderAccessTypesEnum } from 'app/nexus-shared/domain/documents/enums/folder-access-types.enum';
import { AreaTypesEnum } from 'app/nexus-shared/domain/documents/enums/area-types.enum';
import { FolderStatusesEnum } from 'app/nexus-shared/domain/documents/enums/folder-statuses.enum';
import { ClientFolderModel } from 'app/nexus-shared/domain/documents/models/client-folder.model';
import { CommonBaseAuditModel } from '../../contacts/models/common-base-audit.model';
import { FolderTagModel } from 'app/nexus-shared/domain/documents/models/folder-tag.model';
import { FolderSettingsModel } from './folder-settings.model';
import { FolderIndividualAccessModel } from 'app/nexus-shared/domain/documents/models/folder-individual-access.model';

export class FolderModel extends CommonBaseAuditModel {
    folderKey: string;
    parentFolderKey: string;
    folderPath: string;
    name: string;
    accessType: FolderAccessTypesEnum;
    areaType: AreaTypesEnum;
    status: FolderStatusesEnum;
    childFolders: FolderModel[];
    clientFolder: ClientFolderModel;
    tags: FolderTagModel[];
    folderSettings: FolderSettingsModel;
    folderIndividualAccessModels: FolderIndividualAccessModel[];

    constructor(model: FolderModel = null) {
        super(model);
        this.folderKey = model?.folderKey ?? null;
        this.parentFolderKey = model?.parentFolderKey ?? null;
        this.folderPath = model?.folderPath ?? null;
        this.name = model?.name ?? null;
        this.accessType = model?.accessType ?? FolderAccessTypesEnum.Unknown;
        this.areaType = model?.areaType ?? AreaTypesEnum.Unknown;
        this.status = model?.status ?? FolderStatusesEnum.Unknown;
        this.tags = model?.tags ?? null;
        this.childFolders = model?.childFolders ?? [];
        this.clientFolder = model?.clientFolder ?? null;
        this.folderSettings = new FolderSettingsModel(model?.folderSettings);
        this.folderIndividualAccessModels = model?.folderIndividualAccessModels?.length ? model.folderIndividualAccessModels.map(x => new FolderIndividualAccessModel(x)) : [];
    }
}
