import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { DateHelper, LocalStorageHelper } from 'app/nexus-core';
import { LocalStorageNameConstants, LocationModel, SelectListInterface, SelectListPipe } from 'app/nexus-shared/index';
import { TaxJurisdictionQualifierModel, TaxSpecializationModel, TaxWithholdingTypeModel } from 'app/nexus-shared/domain/taxes/models';
import { TaxesGridFilterType } from 'app/nexus-shared/domain/taxes/enums';
import { FilterPanelFormGroupHelper } from 'app/nexus-shared/domain/taxes/components/taxes-grid/filter-panel/filter-panel-form-group.helper';
import { FilterPanelFormControlNames } from 'app/nexus-shared/domain/taxes/components/taxes-grid/filter-panel/filter-panel-form-control-names';
import { TaxWithholdingTypeService } from 'app/nexus-core/services/domain/taxes/tax-withholding-type.service';
import { TaxService } from 'app/nexus-core/services/domain/taxes/tax.service';
import { LocationService } from 'app/nexus-core/services/location.service';

@Component({
    selector: 'gtn-taxes-grid-filter-panel',
    templateUrl: './filter-panel.component.html',
    providers: [
        FilterPanelFormGroupHelper,
        SelectListPipe
    ]
})
export class GtnTaxesGridFilterPanelComponent implements OnInit, OnDestroy {
    @Output() applyEmitter: EventEmitter<SelectListInterface[]> = new EventEmitter();
    @Output() updateChipsEmitter: Subject<SelectListInterface[]> = new Subject();

    countryOptions: LocationModel[];
    formControlNames = FilterPanelFormControlNames;
    formGroup: UntypedFormGroup = this.formGroupHelper.formGroup;
    date: Date;
    qualifierOptions: SelectListInterface[];
    selectedSpecializationKeys: string[];
    specializationOptions: SelectListInterface[];
    subscriptions: Subscription = new Subscription;
    taxQualifiers: TaxJurisdictionQualifierModel[];
    withholdingTypes: TaxWithholdingTypeModel[];

    private isDefaultDateFilterSet: boolean;
    private selectedFilters: SelectListInterface[];

    constructor(
        private selectListPipe: SelectListPipe,
        private locationService: LocationService,
        private taxWithholdingTypeService: TaxWithholdingTypeService,
        private formGroupHelper: FilterPanelFormGroupHelper,
        private taxService: TaxService
    ) {
        this.selectedFilters = LocalStorageHelper.get(LocalStorageNameConstants.TaxesGrid);

        const specializationList = [];
        this.taxService.specializations().subscribe((specs: TaxSpecializationModel[]) => {
            // prepend blank reset option
            const reset: TaxSpecializationModel = { specializationKey: 999, specializationTitle: '' };
            specializationList.push(reset);

            // prepend 'Exclude Specializations' option
            const noSpec: TaxSpecializationModel = {
                specializationKey: 998,
                specializationTitle: 'Exclude Specializations'
            };
            specializationList.push(noSpec);

            // exclude duplicates
            specs.forEach(spec => {
                if (specializationList.find(item => !spec.specializationKey || item.specializationKey === spec.specializationKey)) {
                    return;
                }
                specializationList.push(spec);
            });

            this.specializationOptions = this.selectListPipe.transform(specializationList, 'specializationKey', 'specializationTitle', TaxesGridFilterType.specializationKey);
        });

        const controls = this.formGroup.controls,
            controlNames = this.formControlNames,
            subscriptions = this.subscriptions;

        subscriptions.add(controls[controlNames.country].valueChanges.subscribe(country => {
            if (country) {
                this.addFilters(this.selectListPipe.transform([country], 'id', 'name', TaxesGridFilterType.countryId, 'shortName'));
            } else {
                this.removeFilters(TaxesGridFilterType.countryId);
                this.removeFilters(TaxesGridFilterType.stateId);
                this.removeFilters(TaxesGridFilterType.cityId);
                this.formGroup.get(controlNames.state).reset();
                this.formGroup.get(controlNames.city).reset();
            }
        }));

        subscriptions.add(controls[controlNames.state].valueChanges.subscribe(state => {
            if (state) {
                this.addFilters(this.selectListPipe.transform([state], 'id', 'name', TaxesGridFilterType.stateId, 'shortName'));
            } else {
                this.removeFilters(TaxesGridFilterType.stateId);
                this.removeFilters(TaxesGridFilterType.cityId);
                this.formGroup.get(controlNames.city).reset();
            }
        }));

        subscriptions.add(controls[controlNames.city].valueChanges.subscribe(city => {
            if (city) {
                this.addFilters(this.selectListPipe.transform([city], 'id', 'name', TaxesGridFilterType.cityId, 'shortName'));
            } else {
                this.removeFilters(TaxesGridFilterType.cityId);
            }
        }));

        subscriptions.add(controls[controlNames.withholdingTypes].valueChanges.subscribe(withholdingTypes => {
            if (withholdingTypes && withholdingTypes.length > 0) {
                this.addFilters(this.selectListPipe.transform(withholdingTypes, 'taxWithholdingTypeId', 'name', TaxesGridFilterType.withholdingType));
            } else {
                this.removeFilters(TaxesGridFilterType.withholdingType);
            }
        }));

        subscriptions.add(controls[controlNames.specialization].valueChanges.subscribe(spec => {
            if (spec && spec.id !== 999) {
                this.addFilters([spec]);
            } else {
                this.removeFilters(TaxesGridFilterType.specializationKey);
            }
        }));

        subscriptions.add(controls[controlNames.date].valueChanges.subscribe(date => {
            if (date) {
                const dateSelect: SelectListInterface = {
                    id: 'date',
                    typeId: TaxesGridFilterType.date,
                    value: date,
                    visible: true,
                    disabled: false
                };
                this.addFilters([dateSelect]);
            } else {
                this.removeFilters(TaxesGridFilterType.date);
            }
        }));
    }

    ngOnInit() {
        const selectedCountry = this.selectedFilters?.find(x => x.typeId === TaxesGridFilterType.countryId),
            selectedState = this.selectedFilters?.find(x => x.typeId === TaxesGridFilterType.stateId),
            selectedCity = this.selectedFilters?.find(x => x.typeId === TaxesGridFilterType.cityId),
            controls = this.formGroup.controls,
            controlNames = this.formControlNames;

        const observables: Observable<any>[] = [this.locationService.listCountries()];
        if (selectedState) {
            observables.push(this.locationService.listStates(+selectedCountry.id));
        }
        if (selectedCity) {
            observables.push(this.locationService.listCities(+selectedCountry.id, +selectedState.id));
        }
        forkJoin(observables).subscribe((responses) => {
            this.countryOptions = LocationService.sortCountries(responses[0]);
            if (selectedCountry) {
                const country = this.countryOptions.find(x => x.id === selectedCountry.id);
                controls[controlNames.country].setValue(country);
            }

            if (selectedState) {
                const state = responses[1].find(x => x.id === selectedState.id);
                controls[controlNames.state].setValue(state);
            }

            if (selectedCity) {
                const city = responses[2].find(x => x.id === selectedCity.id);
                controls[controlNames.city].setValue(city);
            }
        });

        this.taxWithholdingTypeService.list().subscribe(withholdingTypes => {
            this.withholdingTypes = withholdingTypes;

            const selectedWithholdingTypes = this.selectedFilters?.filter(x => x.typeId === TaxesGridFilterType.withholdingType);

            if (selectedWithholdingTypes?.length > 0) {
                const selectedTypes: TaxWithholdingTypeModel[] = [];

                selectedWithholdingTypes.map(type => {
                    const getType = this.withholdingTypes.find(t => t.name === type.value);
                    selectedTypes.push(getType);
                });

                controls[controlNames.withholdingTypes].setValue(selectedTypes);
            }
        });

        const selectedSpecializationKey = this.selectedFilters?.find(x => x.typeId === TaxesGridFilterType.specializationKey);
        if (selectedSpecializationKey) {
            controls[controlNames.specialization].setValue(selectedSpecializationKey);
        }

        const selectedDate = this.selectedFilters?.find(x => x.typeId === TaxesGridFilterType.date);
        if (selectedDate) {
            this.isDefaultDateFilterSet = false;
            controls[controlNames.date].setValue(selectedDate.value);
        } else {
            this.isDefaultDateFilterSet = true;
            controls[controlNames.date].setValue(DateHelper.convertToUtcDate(new Date()));
        }
    }

    ngOnDestroy() {
        if (this.isDefaultDateFilterSet) {
            this.resetDate();
        }
    }

    clearFilters(item: SelectListInterface = null): void {
        switch (item?.typeId) {
            case TaxesGridFilterType.countryId:
                this.resetCountry();
                this.onApplyClicked();
                break;
            case TaxesGridFilterType.stateId:
                this.resetState();
                this.onApplyClicked();
                break;
            case TaxesGridFilterType.cityId:
                this.resetCity();
                this.onApplyClicked();
                break;
            case TaxesGridFilterType.date:
                this.resetDate();
                this.onApplyClicked();
                break;
            case TaxesGridFilterType.withholdingType:
                this.resetWithholding(item);
                this.onApplyClicked();
                break;
            case TaxesGridFilterType.specializationKey:
                this.resetSpecialization();
                this.onApplyClicked();
                break;
            default:
                this.resetAll();
                break;
        }
    }

    onApplyClicked(): void {
        this.applyEmitter.emit(this.selectedFilters);
    }

    onClearClicked(item: SelectListInterface = null): void {
        this.clearFilters(item);
    }

    private addFilters(filters: SelectListInterface[]): void {
        const typeId = filters[0].typeId;
        if (!this.selectedFilters) {
            this.selectedFilters = [];
        }
        if (this.selectedFilters) {
            const existingFilter = this.selectedFilters.find(x => x.typeId === typeId);
            if (existingFilter) {
                this.selectedFilters = this.selectedFilters.filter(x => x.typeId !== typeId);
                this.selectedFilters = Object.assign([{}], this.selectedFilters.concat(filters));
            } else {
                this.selectedFilters = Object.assign([{}], this.selectedFilters.concat(filters));
            }
        } else {
            this.selectedFilters = Object.assign([{}], filters);
        }
        this.updateChipsEmitter.next(this.selectedFilters);

        LocalStorageHelper.set(LocalStorageNameConstants.TaxesGrid, this.selectedFilters);
    }

    private removeFilters(typeId: TaxesGridFilterType): void {
        if (this.selectedFilters && this.selectedFilters.find(x => x.typeId === typeId)) {
            this.selectedFilters = this.selectedFilters.filter(x => x.typeId !== typeId);
        }

        if (!this.selectedFilters || this.selectedFilters.length < 1) {
            this.selectedFilters = null;
        }

        this.updateChipsEmitter.next(this.selectedFilters);

        LocalStorageHelper.set(LocalStorageNameConstants.TaxesGrid, this.selectedFilters);
    }

    private resetCountry(): void {
        this.formGroup.controls[this.formControlNames.country].reset();
    }

    private resetState(): void {
        this.formGroup.controls[this.formControlNames.state].reset();
    }

    private resetCity(): void {
        this.formGroup.controls[this.formControlNames.city].reset();
    }

    private resetDate(): void {
        this.formGroup.controls[this.formControlNames.date].reset();
    }

    private resetWithholding(item = null): void {
        if (!item) {
            this.formGroup.controls[this.formControlNames.withholdingTypes].reset();
            this.formGroup.controls[this.formControlNames.withholdingTypes].setValue([]);
            return;
        }

        const updatedValue = this.formGroup.controls[this.formControlNames.withholdingTypes].value.filter(currentItem => currentItem.taxWithholdingTypeId !== item.id);
        this.formGroup.controls[this.formControlNames.withholdingTypes].setValue(updatedValue);
    }

    private resetSpecialization(item = null): void {
        this.formGroup.controls[this.formControlNames.specialization].reset();
        this.formGroup.controls[this.formControlNames.specialization].setValue([]);
    }

    private resetAll(): void {
        this.resetCountry();
        this.resetState();
        this.resetCity();
        this.resetDate();
        this.resetWithholding();
        this.resetSpecialization();

        LocalStorageHelper.delete(LocalStorageNameConstants.TaxesGrid);
    }
}
