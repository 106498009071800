export class TaxJurisdictionQualifierModel {
    displayIndex: number;
    effectiveEndDate: Date;
    effectiveStartDate: Date;
    name: string;
    shortName: string;
    taxJurisdictionId: number;
    taxQualifierId: number;
    isDefaultQualifier: boolean;

    constructor(model: TaxJurisdictionQualifierModel = null) {
        this.displayIndex = model?.displayIndex || null;
        this.effectiveEndDate = model?.effectiveEndDate || null;
        this.effectiveStartDate = model?.effectiveStartDate || null;
        this.name = model?.name || null;
        this.shortName = model?.shortName || null;
        this.taxJurisdictionId = model?.taxJurisdictionId || null;
        this.taxQualifierId = model?.taxQualifierId || null;
        this.isDefaultQualifier = model?.isDefaultQualifier || null;
    }
}
