import { Injectable } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { BaseFormGroupHelper } from 'app/nexus-core';
import { FilterPanelFormControlNames } from 'app/nexus-shared/domain/taxes/components/taxes-grid/filter-panel/filter-panel-form-control-names';

@Injectable()
export class FilterPanelFormGroupHelper extends BaseFormGroupHelper {
    constructor(private formBuilder: UntypedFormBuilder) {
        super();
        this.buildFormGroup();
    }

    buildFormGroup() {
        this.formGroup = this.formBuilder.group({
            [FilterPanelFormControlNames.country]: [null],
            [FilterPanelFormControlNames.state]: [null],
            [FilterPanelFormControlNames.city]: [null],
            [FilterPanelFormControlNames.date]: [null],
            [FilterPanelFormControlNames.withholdingTypes]: [null],
            [FilterPanelFormControlNames.specialization]: [null],
            [FilterPanelFormControlNames.qualifier]: [null]
        });
    }
}
