import { CommonBaseAuditModel } from 'app/nexus-shared/domain/contacts/models/common-base-audit.model';

export class CostCenterModel extends CommonBaseAuditModel {
    costCenterKey: string;
    name: string;

    constructor(model: CostCenterModel = null) {
        super(model);
        this.costCenterKey = model?.costCenterKey || null;
        this.name = model?.name || null;
    }
}
