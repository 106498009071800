import { Component, OnDestroy } from '@angular/core';
import { TestSelectors } from 'app/nexus-shared/constants/test-selector.constants';
import { Subscription } from 'rxjs';
import { AuthorizationConstants } from 'app/nexus-shared/constants/authorization.constants';
import { HtmlHelper } from 'app/nexus-core/helpers/html.helper';
import { DisplayConstants } from 'app/nexus-shared/constants';

@Component({
    template: ''
})
export abstract class BaseComponent implements OnDestroy {
    public TestSelectors = TestSelectors;
    public authorizationConstants = AuthorizationConstants;
    public isLoading = false;
    public noValueText = HtmlHelper.replaceHtmlEntities(DisplayConstants.None);

    protected subscriptions = new Subscription();

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
