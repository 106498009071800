import { Component, Input, OnInit } from '@angular/core';
import { IndividualCompanyAccessService } from 'app/nexus-core/services/domain/contacts/individual-company-access.service';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { map } from 'rxjs/operators';
import { CompanyModel, IndividualModel, IndividualToCompanyAccessModel } from 'app/nexus-shared/domain/contacts/models';
import { BaseEnterpriseGridComponent } from 'app/nexus-shared/components/base-component/base-enterprise-grid.component';
import { CellClickedEvent, ColDef } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { ApplicationsEnum } from 'app/nexus-shared/enums';
import { IndividualSettingService } from 'app/nexus-core/services/domain/core/individual-setting.service';
import { AgGridColDefHelper } from 'app/nexus-core/helpers/ag-grid-col-def.helper';
import { IndividualTypesEnum } from 'app/nexus-shared/domain/contacts/enums/individual-types.enum';
import { PhoneNumberModel } from 'app/nexus-shared/models/phone-number.model';
import { RelationshipEndReasonTypesEnum } from 'app/nexus-shared/domain/contacts/enums/relationship-end-reason-types.enum';
import { AgGridColDefExtras } from 'app/nexus-shared/models/ag-grid-col-def-extras.model';
import { RelationshipSubTypesEnum } from 'app/nexus-shared/domain/contacts/enums/relationship-sub-types.enum';
import { CommonBaseAuditUserModel } from 'app/nexus-shared/domain/contacts/models/common-base-audit-user.model';

@Component({
    selector: 'gtn-individual-company-access-grid',
    template: `
        <gtn-template-grid [showAddButton]="isAdmin" [gridStateKey]="gridStateKey" [application]="application" [gridSettings]="gridSettings"
            [showExpandCollapse]="showExpandCollapse" [isSessionGroupExpanded]="isSessionGroupExpanded" (toggleExpandCollapse)="expandCollapse($event)"
            [showExportButton]="true" (chipGridSettingClick)="onSavedSettingClicked($event)" (exportButtonClick)="onExportButtonClicked()"
            (addButtonClick)="onAddButtonClicked()" (quickFilterTextChange)="onFilterTextChanged($event)">
            <gtn-template-grid-content>
                <ag-grid-angular [gtnHeight]="gridHeight" *ngIf="gridOptions" class="ag-theme-balham customized-theme"
                    [gridOptions]="gridOptions"></ag-grid-angular>
            </gtn-template-grid-content>
        </gtn-template-grid>
        <gtn-company-preview-modal *ngIf="showCompanyPreviewModal" [application]="application"
            [companyKey]="selectedCompanyKey" (cancelClick)="onCompanyPreviewModalClosed()"></gtn-company-preview-modal>
        <gtn-individual-preview-modal *ngIf="showIndividualPreviewModal" [application]="application"
            [individualKey]="selectedIndividualKey" (cancelClick)="onIndividualPreviewModalClosed()"></gtn-individual-preview-modal>
    `
})
export class IndividualCompanyAccessGridComponent extends BaseEnterpriseGridComponent<IndividualToCompanyAccessModel> implements OnInit {
    @Input() companyKey: string;
    @Input() individualKey: string;
    @Input() application: ApplicationsEnum;
    @Input() isAdmin: boolean = false;

    gridName: string = 'company_individual_access_grid';
    gridSecondaryIdentifier: 'individual' | 'company';
    gridVersion: string = '1.0.2';
    hideCompany: boolean;
    selectedCompanyKey: string;
    selectedIndividualKey: string;
    showCompanyPreviewModal: boolean = false;
    showIndividualPreviewModal: boolean = false;

    constructor(
        protected individualSettingService: IndividualSettingService,
        private individualCompanyAccessService: IndividualCompanyAccessService) {
        super(individualSettingService);
    }

    ngOnInit() {
        this.hideCompany = !!this.companyKey;
        this.gridSecondaryIdentifier = this.hideCompany ? 'individual' : 'company';
        super.ngOnInit();
    }

    onCompanyPreviewModalClosed(): void {
        this.showCompanyPreviewModal = false;
        this.selectedCompanyKey = null;
    }

    onIndividualPreviewModalClosed(): void {
        this.showIndividualPreviewModal = false;
        this.selectedIndividualKey = null;
    }

    onCellClicked(cellClick: CellClickedEvent): void {
        if (cellClick.colDef.field === AgGridColDefHelper.previewCompanyColumnField) {
            this.selectedCompanyKey = cellClick.data.company.companyKey;
            this.showCompanyPreviewModal = true;
            return;
        } else if (cellClick.colDef.field === AgGridColDefHelper.previewIndividualColumnField) {
            this.selectedIndividualKey = cellClick.data.individual.individualKey;
            this.showIndividualPreviewModal = true;
            return;
        } else if (this.isAdmin) {
            this.cellClick.emit(cellClick.data);
        }

    }

    setDefaultSortModel() {
        this.defaultSortModel = [
            {
                colId: ObjectHelper.nameOfSubProperty<IndividualToCompanyAccessModel, IndividualModel>('individual', 'fullName'),
                sort: 'asc'
            },
            {
                colId: ObjectHelper.nameOfSubProperty<IndividualToCompanyAccessModel, CompanyModel>('company', 'name'),
                sort: 'asc'
            }
        ];
    }

    protected setColumnDefinitions(): ColDef[] {
        return [
            AgGridColDefHelper.getIndividualPreviewColDef(<AgGridColDefExtras>{ hide: !this.hideCompany }),
            AgGridColDefHelper.getCompanyPreviewColDef(<AgGridColDefExtras>{ hide: this.hideCompany }),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOfSubProperty<IndividualToCompanyAccessModel, IndividualModel>('individual', 'fullName'), 'Employee', null, { hide: !this.hideCompany }),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOfSubProperty<IndividualToCompanyAccessModel, CompanyModel>('company', 'name'), 'Company', null, { hide: this.hideCompany }),
            AgGridColDefHelper.getDateColDef(ObjectHelper.nameOf<IndividualToCompanyAccessModel>('effectiveStartDate'), 'Start Date'),
            AgGridColDefHelper.getDateColDef(ObjectHelper.nameOf<IndividualToCompanyAccessModel>('effectiveEndDate'), 'End Date'),
            AgGridColDefHelper.getEnumColumnDef(ObjectHelper.nameOf<IndividualToCompanyAccessModel>('relationshipType'), 'Type', IndividualTypesEnum),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOf<IndividualToCompanyAccessModel>('emailAddress'), 'Email'),
            AgGridColDefHelper.getPhoneNumberColDef(ObjectHelper.nameOfSubProperty<IndividualToCompanyAccessModel, PhoneNumberModel>('primaryPhoneNumber', 'formattedPhoneNumber'), 'Primary Phone no.'),
            AgGridColDefHelper.getPhoneNumberColDef(ObjectHelper.nameOfSubProperty<IndividualToCompanyAccessModel, PhoneNumberModel>('secondaryPhoneNumber', 'formattedPhoneNumber'), 'Secondary Phone no.'),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOf<IndividualToCompanyAccessModel>('jobTitle'), 'Job Title'),
            AgGridColDefHelper.getNumberColDef(ObjectHelper.nameOf<IndividualToCompanyAccessModel>('employeeId'), 'Employee ID'),
            AgGridColDefHelper.getEnumColumnDef(ObjectHelper.nameOf<IndividualToCompanyAccessModel>('relationshipEndReasonType'), 'End Reason Type', RelationshipEndReasonTypesEnum),
            AgGridColDefHelper.getEnumListColumnDef(ObjectHelper.nameOf<IndividualToCompanyAccessModel>('relationshipSubTypes'), 'Relationship Sub Type(s)', RelationshipSubTypesEnum),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOf<IndividualToCompanyAccessModel>('individualToCompanyAccessKey'), 'Key', null, { hide: !this.isAdmin }),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOf<CompanyModel>('companyKey'), 'Key', null, <AgGridColDefExtras>{ hide: !this.isAdmin }),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOfSubProperty<IndividualToCompanyAccessModel, CommonBaseAuditUserModel>('createdByUser', 'fullName'), 'Created By', null, { hide: true }),
            AgGridColDefHelper.getDateTimeColDef(ObjectHelper.nameOfSubProperty<IndividualToCompanyAccessModel, CommonBaseAuditUserModel>('createdByUser', 'changeDateTime'), 'Created On', { hide: true }),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOfSubProperty<IndividualToCompanyAccessModel, CommonBaseAuditUserModel>('lastModifiedByUser', 'fullName'), 'Last Modified By', null, { hide: true }),
            AgGridColDefHelper.getDateTimeColDef(ObjectHelper.nameOfSubProperty<IndividualToCompanyAccessModel, CommonBaseAuditUserModel>('lastModifiedByUser', 'changeDateTime'), 'Modified On', { hide: true }),
        ];
    }

    protected setRowData(): Observable<any> {
        if (this.individualKey) {
            return this.individualCompanyAccessService.listByIndividualKey(this.individualKey).pipe(map((individuals: IndividualToCompanyAccessModel[]) => {
                return individuals.map((individual: IndividualToCompanyAccessModel) => {
                    return new IndividualToCompanyAccessModel(individual);
                });
            }));
        } else if (this.companyKey) {
            return this.individualCompanyAccessService.listByCompanyKey(this.companyKey).pipe(map((individuals: IndividualToCompanyAccessModel[]) => {
                return individuals.map((individual: IndividualToCompanyAccessModel) => {
                    return new IndividualToCompanyAccessModel(individual);
                });
            }));
        }
    }
}
