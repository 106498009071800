import { Injectable } from '@angular/core';
import { BaseService } from 'app/nexus-core/services/base-service.directive';
import { environment } from 'environments/environment';
import { BaseHttpService } from 'app/nexus-core/services/base-http.service';
import { Observable } from 'rxjs';
import { IndividualGtnOrganizerModel } from 'app/nexus-shared/domain/questionnaires/models/individual-gtn-organizer.model';
import { BaseQuestionnaireSearchModel } from 'app/nexus-shared/domain/questionnaires/models/base-questionnaire-search.model';

@Injectable()
export class IndividualGtnOrganizerService extends BaseService {
    private apiUrl = environment().webAPIUrls.questionnaires + 'individual-gtn-organizers/';

    constructor(private baseHttpService: BaseHttpService) {
        super();
    }

    get(individualGtnOrganizerKey: string): Observable<IndividualGtnOrganizerModel> {
        return this.baseHttpService.get(`${this.apiUrl}${individualGtnOrganizerKey}`);
    }

    search(search: BaseQuestionnaireSearchModel): Observable<IndividualGtnOrganizerModel[]> {
        return this.baseHttpService.post(`${this.apiUrl}search`, search);
    }

    create(model: IndividualGtnOrganizerModel): Observable<string> {
        return this.baseHttpService.post(this.apiUrl, model);
    }

    update(model: IndividualGtnOrganizerModel): Observable<boolean> {
        return this.baseHttpService.put(this.apiUrl, model);
    }
}
