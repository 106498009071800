import { AfterContentInit, Component, EventEmitter, forwardRef, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSlider } from '@angular/material/slider';

@Component({
    selector: 'gtn-slider',
    templateUrl: './slider.component.html',
    styleUrls: ['./slider.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SliderComponent),
            multi: true
        }
    ]
})
export class SliderComponent implements ControlValueAccessor, AfterContentInit {

    @Input('value') _value: string = '';
    @Input() disabled: boolean;
    @Input() focus: boolean = false;
    @Input() label: string = '';
    @Input() readonly: boolean;

    //slider-specific properties
    @Input() invert: boolean = false;
    @Input() max: number;
  @Input() min: number;
  @Input() step: number = 1;
    //TODO MDC Migration Error
    // @Input() thumbLabel: boolean = true;
  @Input() tickInterval: number | 'auto'; // ToDo: Ryan, Remove?
  @Input() vertical: boolean;

  @Output() change: EventEmitter<any> = new EventEmitter();

  @ViewChild('matSlider') matSlider: MatSlider;


  onChange: Function = () => { };
  onTouched: Function = () => { };

  constructor(private renderer: Renderer2) { }

  ngAfterContentInit() {
      if (this.focus) {
          //TODO MDC Migration Error
          // this.setFocus();
      }
  }

  get value(): any {
      return this._value;
  }

  set value(val: any) {
      this._value = val;
      this.onChange(val);
      this.change.emit(val);
      this.onTouched();
  }

  onBlur(): void {
      this.onTouched();
  }

  registerOnChange(fn): void {
      this.onChange = fn;
  }

  registerOnTouched(fn): void {
      this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
      this.disabled = isDisabled;
  }

  writeValue(value): void {
      const currentValue = this.value;

      if (typeof currentValue !== 'undefined' && currentValue !== null && currentValue !== value) {
          this.value = value;
      } else if (typeof value !== 'undefined' && value !== null && currentValue !== value) {
          this.value = value;
      }
  }

  setFocus(): void {
      //TODO MDC Migration Error
      // this.matSlider.focus();
  }

}
