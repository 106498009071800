import { animate, AnimationMetadata, style, transition, trigger } from '@angular/animations';

export class ChildSlideDown {
    /**
     * Use '@childSlide' in markup.
     */
    static animations = ChildSlideDown.getAnimation();

    private static getAnimation(): AnimationMetadata {
        return trigger('childSlide', [
            transition(':enter', [
                style({ transform: 'translateY(-80%)' }),
                animate('400ms ease-in', style({ transform: 'translateY(0%)' }))
            ]),
            transition(':leave', [
                animate('300ms ease-out', style({ transform: 'translateY(-100%)' }))
            ])
        ]);
    }
}
