import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { BaseEntryComponent } from './base-entry.component';
import { ViewModesEnum } from 'app/nexus-shared/enums/view-modes.enum';
import { Observable } from 'rxjs';
import { SimpleChangesTyped } from 'app/nexus-shared/models/simple-changes-typed.type';
import { PendingChangesModel } from 'app/nexus-shared/models/pending-changes.model';

@Component({ template: '' })
export abstract class BaseReadonlyEditComponent<T1, T2> extends BaseEntryComponent<T1> implements OnInit, OnDestroy, OnChanges {
    @Input() pageTitle: string;
    @Input() value: T1 = null;
    @Input() readonly: boolean = true;
    @Input() viewMode: ViewModesEnum = ViewModesEnum.All;
    @Input() executeSave: boolean = null;
    @Input() pendingChanges: PendingChangesModel = new PendingChangesModel();
    @Output() executeSaveChange: EventEmitter<boolean> = new EventEmitter();
    @Output() saveComplete: EventEmitter<T1> = new EventEmitter<T1>();
    @Output() readonlyChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    originalValue: T1 = null;
    viewModes = ViewModesEnum;

    ngOnInit(): void {
        this.getValue(this.value).subscribe((value: T1) => {
            this.setValue(value);
        });
    }

    ngOnChanges(changes: SimpleChangesTyped<this>): void {
        if (changes.executeSave && changes.executeSave.currentValue) {
            this.saveValue().subscribe(_ => {
                this.executeSave = false;
                this.executeSaveChange.emit(this.executeSave);
                this.onSaveSuccess();
            }, err => {
                this.executeSave = false;
                this.executeSaveChange.emit(this.executeSave);
                this.onSaveFailure(err);
            });
        }
    }

    onSaveClicked(value: T1 = null): void {
        this.saveValue().subscribe(_ => {
            this.onSaveSuccess(value);
        }, err => {
            this.onSaveFailure(err);
        });
    }

    onCancelClicked(): void {
        this.getValue(this.originalValue).subscribe((value: T1) => {
            this.setValue(value);
        });
    }

    onSaveSuccess(value: T1 = null): void {
        this.getValue(value).subscribe((newValue: T1) => {
            this.setValue(newValue);
            this.readonly = true;
            this.readonlyChange.emit(this.readonly);
            this.saveComplete.emit(newValue);
            super.onSaveSuccess();
        });
    }

    onReadOnlyChanged(isReadOnly: boolean): void {
        this.getValue(this.originalValue).subscribe((value: T1) => {
            this.setValue(value);
            this.readonly = isReadOnly;
            this.readonlyChange.emit(this.readonly);
        });
    }

    protected abstract getValue(value: T1): Observable<T1>;

    protected abstract saveValue(): Observable<T2>;

    private setValue(value: T1): void {
        this.value = value;
        this.originalValue = JSON.parse(JSON.stringify(value));
    }
}
