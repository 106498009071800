<gtn-modal class="modal-medium-wide" headerText="Document Details"
    okButtonText="Save" [disableOkButton]="!formGroupRef.dirty || isSaving" [validationModels]="validationModels"
    cancelButtonText="Close"
    (cancelClick)="onCancelClicked()" (closeClick)="onCancelClicked()" [formGroupRef]="formGroupRef">
    <gtn-modal-content>
        <gtn-document-detail #documentDetail *ngIf="value?.documentKey" [documentKey]="value.documentKey"
            [areaType]="value.areaType"
            [formGroup]="formGroupRef" [selectedFolder]="selectedFolder"
            [documentsUserAuth]="documentsUserAuth"></gtn-document-detail>
    </gtn-modal-content>
    <gtn-modal-footer-right-buttons>
        <button mat-flat-button *ngIf="!selectedFolder" color="accent"
            type="button" (click)="onNavigateToFolderClicked()">
            Go to folder
        </button>
    </gtn-modal-footer-right-buttons>
</gtn-modal>
