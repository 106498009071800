export enum RegionTypesEnum {
    Unknown = 0,
    Pacific = 10,
    GreatLakes = 20,
    WestCentral = 30,
    Atlantic = 40,
    South = 50,
    MobileWorkforceManagement = 60,
    Canada = 70
}
