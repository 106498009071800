<div class="gtn-slide-toggle-container">
    <label *ngIf="!labelPosition"
        class="gtn-label">{{ label }}</label>
    <mat-slide-toggle #matSlideToggle
        #matSlideElement
        [(ngModel)]="value"
        [labelPosition]="labelPosition"
        [disabled]="disabled"
        [checked]="checked"
        [id]="id"
        [name]="name"
        [required]="required">
        {{ (labelPosition ? label : '') }}
    </mat-slide-toggle>
</div>
