import { Component, OnInit, ViewChild } from '@angular/core';
import { UsersGridComponent } from 'app/nexus-shared/domain/users/components/users-grid/users-grid.component';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { AuthorizationConstants } from 'app/nexus-shared/constants/authorization.constants';
import { AuthenticatedUserService } from 'app/nexus-core/services/authenticated-user.service';
import { UserModel } from 'app/nexus-shared/domain/users/models/user.model';
import { UsersRoutingUsersConfigurationConstants } from '../../routing/users-routing-users-configuration.constants';
import { UsersRoutingKeyConstants } from 'app/modules/users/routing/users-routing-key.constants';
import { ApplicationsEnum } from 'app/nexus-shared';

@Component({
    selector: 'gtn-users-list',
    templateUrl: './users-list.component.html',
    styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent extends BaseComponent implements OnInit {
    application = ApplicationsEnum.Users;
    authorizationConstants = AuthorizationConstants;
    gridAdminConstant: AuthorizationConstants = AuthorizationConstants.authUserAdmin;
    isInternal: boolean;
    path: string;

    @ViewChild('userGrid') userGridRef: UsersGridComponent;

    constructor(private authenticatedUserService: AuthenticatedUserService,
                private router: Router, private activatedRoute: ActivatedRoute) {
        super();
    }

    ngOnInit() {
        const menuId = this.activatedRoute.snapshot.data.menuId;
        this.isInternal = menuId === UsersRoutingKeyConstants.menuIds.userInternalListPage;
        this.path = this.isInternal ? UsersRoutingKeyConstants.pathConstants.internal : UsersRoutingKeyConstants.pathConstants.external;

    }

    onAddUserClicked(): void {
        if (this.authenticatedUserService.hasAuthorization(AuthorizationConstants.authUserRole)) {
            this.navigateToUserCreatePage();
        }
    }

    onUserClicked(user: UserModel): void {
        if (this.authenticatedUserService.hasAuthorization(AuthorizationConstants.authUserRole)) {
            this.navigateToUserDetailsPage(user);
        }
    }

    private navigateToUserDetailsPage(user: UserModel) {
        this.router.navigate(UsersRoutingUsersConfigurationConstants.configurations.getDetails(this.path).routing.navigationRoute(user?.userKey));
    }

    private navigateToUserCreatePage() {
        this.router.navigate(UsersRoutingUsersConfigurationConstants.configurations.getCreateUser(this.path).routing.navigationRoute());
    }
}
