import { IAgreementsReviewerSearchModelInterface } from "..";

export class AgreementsReviewerSearchModel implements IAgreementsReviewerSearchModelInterface {
    companyKeys?: string[];
    individualKeys?: string[];

    constructor(model: AgreementsReviewerSearchModel | IAgreementsReviewerSearchModelInterface = null) {
        this.companyKeys = model?.companyKeys ?? null;
        this.individualKeys = model?.individualKeys ?? null;
    }
}
