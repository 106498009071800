import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UserModel } from 'app/nexus-shared/domain/users/models/user.model';
import { IndividualModel } from 'app/nexus-shared/domain/contacts/models/individual.model';
import { AuthenticatedUserService } from 'app/nexus-core/services/authenticated-user.service';
import { AuthorizationConstants } from 'app/nexus-shared/constants/authorization.constants';
import { BaseFormComponent } from 'app/nexus-shared/components/base-component/base-form.component';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { BaseFormModel } from 'app/nexus-shared/models/base-form.model';
import { BaseFormArrayModel } from 'app/nexus-shared/models/base-form-array.model';
import { UntypedFormArray } from '@angular/forms';
import { RoleDefinitionModel } from 'app/nexus-shared/domain/users/models/role-definition.model';
import { UserViewModel } from 'app/nexus-shared/domain/users/models/user-view.model';
import { UserStatusesEnum } from 'app/nexus-shared/enums/user-statuses.enum';

@Component({
    selector: 'gtn-user-form',
    templateUrl: './user-form.component.html',
    styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent extends BaseFormComponent<UserViewModel> implements OnInit {
    @Output() navigateToIndividualClick: EventEmitter<string> = new EventEmitter();

    policiesFormArray: UntypedFormArray;
    authorizationConstants = AuthorizationConstants;
    isIndividualSearchVisible: boolean;
    userStatuses = UserStatusesEnum;
    isGtnEmail: boolean;

    constructor(
        public authenticatedUserService: AuthenticatedUserService,
    ) {
        super();
    }

    ngOnInit(): void {
        const formConfiguration = new BaseFormModel();
        formConfiguration.formArrayConfiguration[ObjectHelper.nameOf<UserViewModel>('userRoleDefinitions')] = new BaseFormArrayModel(new RoleDefinitionModel());
        super.ngOnInit(formConfiguration);
    }

    initFormCustomizations() {
        this.policiesFormArray = this.formGroupRef.get(ObjectHelper.nameOf<UserViewModel>('userRoleDefinitions')) as UntypedFormArray;
        this.isIndividualSearchVisible = !this.formGroupRef.get(ObjectHelper.nameOf<UserViewModel>('individualKey')).value;
        if(this.value.email){
            this.isGtnEmail = this.value.email.split('@').pop() === 'gtn.com';
        }
    }

    onIndividualClicked(value: IndividualModel): void {
        if (this.value?.userKey) {
            this.formGroupRef.get(ObjectHelper.nameOf<UserModel>('firstName')).setValue(this.value.firstName);
            this.formGroupRef.get(ObjectHelper.nameOf<UserModel>('lastName')).setValue(this.value.lastName);
            this.formGroupRef.get(ObjectHelper.nameOf<UserModel>('email')).setValue(this.value.email);
            this.formGroupRef.get(ObjectHelper.nameOf<UserModel>('individualKey')).setValue(value.individualKey);
            this.formGroupRef.get(ObjectHelper.nameOf<UserModel>('userKey')).setValue(this.value.userKey);
            this.formGroupRef.get(ObjectHelper.nameOf<UserModel>('isLockedOut')).setValue(this.value.isLockedOut);
            this.formGroupRef.get(ObjectHelper.nameOf<UserModel>('status')).setValue(this.value?.status);
            this.formGroupRef.markAsDirty();
            this.formGroupRefChange.emit(this.formGroupRef);
        } else {
            if (value) {
                this.formGroupRef.get(ObjectHelper.nameOf<UserModel>('firstName')).setValue(value.firstName);
                this.formGroupRef.get(ObjectHelper.nameOf<UserModel>('lastName')).setValue(value.lastName);
                this.formGroupRef.get(ObjectHelper.nameOf<UserModel>('email')).setValue(value.emailAddress);
                this.formGroupRef.get(ObjectHelper.nameOf<UserModel>('userKey')).setValue(this.value?.userKey);
                this.formGroupRef.get(ObjectHelper.nameOf<UserModel>('individualKey')).setValue(value.individualKey);
                this.formGroupRef.get(ObjectHelper.nameOf<UserModel>('isLockedOut')).setValue(false);
                this.formGroupRef.get(ObjectHelper.nameOf<UserModel>('status')).setValue(UserStatusesEnum.Created);
                this.formGroupRef.markAsDirty();
                this.formGroupRefChange.emit(this.formGroupRef);
            } else {
                this.formGroupRef.reset();
            }
        }
    }
}
