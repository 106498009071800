import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BaseService } from 'app/nexus-core/services/base-service.directive';

@Injectable()
export class ScrollService extends BaseService {
    public onScrollTo = new Subject<{ scrollLeft: number, scrollTop: number }>();
    public scrollToTop = () => {
        this.onScrollTo.next({
            scrollTop: 0,
            scrollLeft: 0
        });
    };
}
