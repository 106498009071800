import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { BaseFormModel } from 'app/nexus-shared/models/base-form.model';
import { ValidationModel } from 'app/nexus-shared';
import { NexusValidatorHelper } from 'app/nexus-core/helpers/nexus-validator.helper';
import { StringHelper } from './string.helper';

export class FormHelper {
    static addValidators(form: UntypedFormGroup, validationErrors: ValidationModel[]): void {
        validationErrors.forEach((error) => {
            const control = form.get(StringHelper.lowerCaseFirst(error.field));
            control.setValidators(this.getValidator(error));
            control.updateValueAndValidity();
        });
    }

    static clearValidators(form: UntypedFormGroup): void {
        form.clearValidators();
        form.clearAsyncValidators();

        for (const key in form.controls) {
            if (form.controls[key]) {
                form.get(key).clearValidators();
                form.get(key).clearAsyncValidators();
                form.get(key).updateValueAndValidity();
            }
        }
    }

    static initFormGroupControl(controlKey: string, formGroupControl: UntypedFormGroup = new UntypedFormGroup({}), formGroup: UntypedFormGroup): void {
        if (formGroup.get(controlKey) !== null) {
            formGroup.removeControl(controlKey);
        }

        if (formGroupControl) {
            formGroup.addControl(controlKey, formGroupControl);
        }
    }

    static initFormControl(controlKey: string, formControl: UntypedFormControl = new UntypedFormControl(), formGroup: UntypedFormGroup): void {
        if (formGroup.get(controlKey) !== null) {
            formGroup.removeControl(controlKey);
        }

        if (formControl) {
            formGroup.addControl(controlKey, formControl);
        }
    }

    static initSubFormControl(formKey: string, controlKey: string, formControl: UntypedFormControl = new UntypedFormControl(), formGroup: UntypedFormGroup): void {
        const subFormGroup = formGroup.get(formKey) as UntypedFormGroup;

        if (subFormGroup !== null) {
            subFormGroup.removeControl(controlKey);
        }

        if (formControl) {
            subFormGroup.addControl(controlKey, formControl);
        }
    }

    static initFormArrayControl(formKey: string, formArrayObject: any, controlKey: string, addBlank = false, formArray: UntypedFormArray = new UntypedFormArray([]), formGroup: UntypedFormGroup): void {
        if (formGroup !== null) {
            formGroup.removeControl(controlKey);
        }

        if (formArray) {
            formGroup.addControl(controlKey, formArray);
        }
    }

    static initFormGroupFromControl(formGroupObject: any, controlKey: string, formGroup: UntypedFormGroup, configuration: BaseFormModel = new BaseFormModel) {
        const newFormGroup = new UntypedFormGroup({});
        FormHelper.buildFormFromTemplate(formGroupObject, newFormGroup, configuration);

        formGroup.removeControl(controlKey);

        formGroup.addControl(controlKey, newFormGroup);
    }

    static initFormArrayFormGroup(formArrayObject: any, formArray: UntypedFormArray, configuration: BaseFormModel = new BaseFormModel, insertIndex: number = null): void {
        const newFormGroup = new UntypedFormGroup({});
        FormHelper.buildFormFromTemplate(formArrayObject, newFormGroup, configuration);

        if (insertIndex || insertIndex === 0) {
            formArray.insert(insertIndex, newFormGroup); // Insert newFormGroup at index
        } else {
            formArray.push(newFormGroup); // Add newFormGroup to the end of the array
        }
    }

    static clearFormArray(formArray: UntypedFormArray): void {
        while (formArray?.length !== 0) {
            formArray.removeAt(0);
        }
    }

    static moveItemInFormArray(formArray: UntypedFormArray, toIndex: number, fromIndex: number, reOrderProperty: string = null, zeroBasedOrder: boolean = false) {
        const dir = toIndex > fromIndex ? 1 : -1;

        const from = fromIndex;
        const to = toIndex;

        const temp = formArray.at(from);
        for (let i = from; i * dir < to * dir; i = i + dir) {
            const current = formArray.at(i + dir);
            formArray.setControl(i, current);
        }
        formArray.setControl(to, temp);
        formArray.markAsDirty();

        if (reOrderProperty) {
            formArray.controls.forEach((formGroup, i) => {
                const formControl = (formGroup as UntypedFormGroup).get(reOrderProperty);
                const val = zeroBasedOrder ? i : i + 1;
                formControl.setValue(val);
            });
        }
    }

    static buildFormFromTemplate(model, formGroup: UntypedFormGroup, configuration: BaseFormModel = null) {
        if (configuration?.ignoreProperties && configuration?.ignoreAllExceptProperties) {
            throw new Error('Invalid form configuration');
        }

        Object.keys(model).forEach(key => {
            if (!configuration?.ignoreProperties || configuration?.ignoreProperties?.indexOf(key) === -1) {
                if (!configuration?.ignoreAllExceptProperties || configuration?.ignoreAllExceptProperties?.indexOf(key) !== -1) {
                    if (model[key] !== null && Array.isArray(model[key]) && configuration?.formArrayConfiguration[key]) {
                        const formArrayConfiguration = configuration.formArrayConfiguration[key];
                        const formArray = new UntypedFormArray([], formArrayConfiguration?.validators);
                        formGroup.addControl(key, formArray);
                        if (model[key].length) {
                            model[key].forEach(element => {
                                const formArrayGroup = new UntypedFormGroup({});
                                this.buildFormFromTemplate(element, formArrayGroup, formArrayConfiguration.formConfiguration);
                                formArray.push(formArrayGroup);
                            });
                        } else if (formArrayConfiguration.addBlank) {
                            const formArrayGroup = new UntypedFormGroup({});
                            this.buildFormFromTemplate(formArrayConfiguration.template, formArrayGroup, formArrayConfiguration.formConfiguration);
                            formArray.push(formArrayGroup);
                        }
                    } else if (model[key] !== null && typeof model[key] === 'object') {
                        if (configuration && configuration.formConfiguration[key]) {
                            const nestedFormGroup = new UntypedFormGroup({});
                            formGroup.addControl(key, nestedFormGroup);
                            this.buildFormFromTemplate(model[key], nestedFormGroup, (configuration && configuration.formConfiguration ? configuration.formConfiguration[key] : null));
                        } else {
                            formGroup.addControl(key, new UntypedFormControl(model[key]));
                        }
                    } else {
                        const control = formGroup.get(key);
                        if (!control) {
                            formGroup.addControl(key, new UntypedFormControl());
                        }
                        if (!(model[key] instanceof Array)) {
                            formGroup.controls[key].setValue(model[key]);
                        }
                        if (configuration?.validators && configuration.validators[key]) {
                            formGroup.controls[key].setValidators(configuration.validators[key]);
                            formGroup.controls[key].updateValueAndValidity();
                        }
                        if (configuration?.asyncValidators && configuration.asyncValidators[key]) {
                            formGroup.controls[key].setAsyncValidators(configuration.asyncValidators[key]);
                            formGroup.controls[key].updateValueAndValidity();
                        }
                    }
                }
            }
        });

        if (configuration?.formGroupValidators) {
            formGroup.setValidators(configuration.formGroupValidators);
        }
    }

    private static getValidator(error: ValidationModel): ValidatorFn {
        let validator: ValidatorFn;

        switch (error.code) {
            case GTNValidationExceptionCodeConstants.RequiredValue:
                validator = NexusValidatorHelper.gtnRequired();
                break;
            default:
                validator = NexusValidatorHelper.defaultError();
                break;
        }

        return validator;
    }
}

export class GTNValidationExceptionCodeConstants {
    static RequiredValue: string = 'RequiredValue';
    static InvalidValue: string = 'InvalidValue';
    static InvalidCompositeValues: string = 'InvalidCompositeValues';
    static UniqueValueRequired: string = 'UniqueValueRequired';
    static InvalidDateRange: string = 'InvalidDateRange';
    static ObjectHasDependents: string = 'ObjectHasDependents';
    static ObjectDoesNotExist: string = 'ObjectDoesNotExist';
    static ObjectAlreadyExists: string = 'ObjectAlreadyExists';
    static ValueIsImmutable: string = 'ValueIsImmutable';
}
