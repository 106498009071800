import { Directive, EventEmitter, HostBinding, HostListener, Input, OnInit, Output } from '@angular/core';
import { BaseDirective } from 'app/nexus-shared/components/base-directive/base.directive';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
    selector: '[gtnDragAndDrop]'
})
export class DragAndDropDirective extends BaseDirective implements OnInit {
    @HostBinding('class.file-over') dragOver: boolean;

    @Input() isDebounceDragLeave: boolean = false;
    @Input() disableDragAndDropEvents: boolean = false;

    @Output() filesDropped: EventEmitter<FileList> = new EventEmitter<FileList>();
    @Output() dragHoverChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    files: File[];
    dragLeave: Subject<void> = new Subject();

    ngOnInit(): void {
        if (this.isDebounceDragLeave) {
            this.dragLeave.pipe(debounceTime(100)).subscribe(_ => {
                if (!this.dragOver) {
                    this.dragHoverChange.emit(false);
                }
            });
        }
    }

    @HostListener('dragover', ['$event']) onDragOver(e) {
        this.prevent(e);

        if (!this.disableDragAndDropEvents) {
            this.dragOver = true;
            this.dragHoverChange.emit(this.dragOver);
        }

    }

    @HostListener('dragleave', ['$event']) onDragLeave(e) {
        this.prevent(e);

        this.dragOver = false;
        if (this.isDebounceDragLeave) {
            this.dragLeave.next();
        } else {
            this.dragHoverChange.emit(this.dragOver);
        }
    }

    @HostListener('drop', ['$event'])
    public ondrop(e) {
        this.prevent(e);
        if (!this.disableDragAndDropEvents) {
            const files = e?.dataTransfer?.files;
            if (files?.length > 0) {
                this.filesDropped.emit(files);
            }
            this.dragOver = false;
            this.dragHoverChange.emit(this.dragOver);
        }
    }

    private prevent(e): void {
        e.preventDefault();
        e.stopPropagation();
    }
}
