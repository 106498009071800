<div class="month-container">
    <div class="week-day-labels">
        <ng-container *ngFor="let day of weekDayNames; index as i">
            <div class="week-day-label">{{ day }}</div>
        </ng-container>
    </div>
    <div class="week-days">
        <ng-container *ngFor="let day of days; index as i">
            <div class="week-day" [ngClass]="{'out-of-month': !day.isInMonth, 'year': isYearView}">
                <ng-container *ngTemplateOutlet="template; context:{date: day.date}"></ng-container>
            </div>
        </ng-container>
    </div>
</div>

