import { Component, ContentChild, EventEmitter, Input, OnChanges, OnDestroy, Output, TemplateRef } from '@angular/core';
import { FilterableTilesModel } from 'app/nexus-shared/components/controls/shared/models/filterable-tiles.model';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';

@Component({
    selector: 'gtn-filterable-tiles',
    templateUrl: './filterable-tiles.component.html',
    styleUrls: ['./filterable-tiles.component.scss']
})
export class FilterableTilesComponent extends BaseComponent implements OnDestroy, OnChanges {
    @Input() values: FilterableTilesModel[] = [];
    @Input() showAll: boolean = true;
    @Input() allLabel: string = 'ALL';

    @Input() hideFilters: boolean = false;

    @Input() selectedValue: string = null;
    @Output() selectedValueChange: EventEmitter<string> = new EventEmitter();

    @Output() tileClick: EventEmitter<any> = new EventEmitter();

    headerValues: { disabled: boolean, value: string }[] = [];
    contentValues: any[] = [];

    @ContentChild(TemplateRef) templateRef: TemplateRef<any>;

    ngOnChanges(): void {
        if (this.values) {
            this.headerValues = this.values.map(t => {
                return {
                    disabled: !t.data?.length,
                    value: t.value
                };
            });

            if (!this.showAll && !this.selectedValue) {
                this.selectedValue = this.headerValues[0].value;
            }

            this.setContentValues();
        }
    }

    onHeaderItemClicked(selectedValue: { disabled: boolean, value: string }): void {
        if (selectedValue?.disabled) {
            return;
        }

        this.selectedValue = selectedValue?.value || null;
        this.selectedValueChange.emit(selectedValue?.value || null);

        this.setContentValues();
    }

    private setContentValues(): void {
        this.contentValues = [];
        this.values.filter(t => (this.selectedValue === null || t.value === this.selectedValue) && t.data?.length).map(t => {
            this.contentValues = this.contentValues.concat(t.data);
        });
    }
}
