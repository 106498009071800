import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SearchResultIconEnum } from 'app/nexus-shared/components/controls/shared/enums';
import { SearchResultModel } from 'app/nexus-shared/components/controls/shared/models';
import { SearchHelper } from 'app/nexus-core/helpers';
import { BaseSearchControlComponent } from 'app/nexus-shared/components/base-component/base-search-control.component';
import { UserModel } from 'app/nexus-shared/domain/users/models/user.model';
import { UserService } from 'app/nexus-core/services/domain/users/user.service';
import { UserSearchModel } from 'app/nexus-shared/domain/users/models/user-search.model';

@Component({
    selector: 'gtn-user-search',
    templateUrl: './user-search.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => UserSearchComponent),
            multi: true
        }
    ]
})
export class UserSearchComponent extends BaseSearchControlComponent<UserModel> implements ControlValueAccessor {
    @Input() userSearchModel: UserSearchModel = <UserSearchModel>{ searchTerm: null };

    constructor(
        private userService: UserService,
    ) {
        super();
    }

    protected onSearchTextChanged(searchText: string): void {
        this.userSearchModel.searchTerm = searchText;
        this.userService.search(this.userSearchModel).subscribe(
            response => {
                const results = response.map((user: UserModel) => {
                    return {
                        id: user.userKey,
                        name: user.fullName,
                        subName: user.email,
                        type: null,
                        iconClass: SearchResultIconEnum.unknown,
                        resultObject: user,
                        rank: 0
                    } as SearchResultModel<void, UserModel>;
                });
                const orderedResults = SearchHelper.orderSearchResults<void, UserModel>(results, searchText, []);
                this.searchModel.onResultsReceived.next(orderedResults);
            },
            error => this.searchModel.onErrorReceived.next(error));
    }

    displayWith(searchResultItem: SearchResultModel<void, UserModel> | UserModel) {
        if (searchResultItem) {
            if ('resultObject' in searchResultItem) {
                return searchResultItem?.resultObject
                    ? `${searchResultItem.resultObject.fullName}`
                    : null;
            }

            const User = new UserModel(searchResultItem);
            return `${User.fullName}`;
        }
        return null;
    }
}
