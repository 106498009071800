import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';

@Component({
    selector: 'gtn-expandable-card',
    templateUrl: './expandable-card.component.html',
    styleUrls: ['./expandable-card.component.scss']
})
export class ExpandableCardComponent extends BaseComponent {

    @Input() isExpandable: boolean = true;
    @Input() isCollapsible: boolean = true;
    @Input() isExpanded: boolean;
    @Input() isCardContentClickable: boolean = true;
    @Output() cardContentClick: EventEmitter<void> = new EventEmitter();
    @Output() expandCardClick: EventEmitter<void> = new EventEmitter();
    @Output() collapseCardClick: EventEmitter<void> = new EventEmitter();

    onExpandCard() {
        this.isExpanded = true;
        this.expandCardClick.emit();
    }

    onCollapseCard() {
        this.isExpanded = false;
        this.collapseCardClick.emit();
    }

    onCardContentClicked() {
        if (this.isCardContentClickable) {
            this.cardContentClick.emit();
        }
    }
}
