import { ApiFilterBase } from 'app/nexus-shared/models/filters';

import { TaxesGridFilterField } from 'app/nexus-shared/domain/taxes/interfaces';

export class TaxRateFilterModel extends ApiFilterBase {
    taxId: number;
    effectiveDate: Date;
    taxSpecializationTitle: string;
    fields: TaxesGridFilterField[];

    constructor(taxId) {
        super();
        this.taxId = taxId;
        this.fields = [];
    }
}
