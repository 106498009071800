import { AgreementReferentialValueModel } from 'app/nexus-shared/domain/agreements/models/agreement-referential-value.model';
import { AgreementTypeModel } from 'app/nexus-shared/domain/agreements/models/agreement-type.model';
import { EnumHelper } from '..';
import { AgreementSubtypeEnum } from 'app/nexus-shared/domain/agreements/enums/agreement-subtype.enum';
import { AgreementTypeEnum } from 'app/nexus-shared/domain/agreements/enums/agreement-type.enum';

export class AgreementsHelper {
    static getReferentialValue(referentialValues: AgreementReferentialValueModel[], id: string): string {
        if (!referentialValues) {
            return '';
        }

        const referentialValue = referentialValues.find(x => x.id == id);
        return referentialValue?.value ?? '';
    }

    static getTypeDisplayName(agreementType: AgreementTypeModel): string {
        const type = agreementType?.type ?? AgreementTypeEnum.Unknown;
        return EnumHelper.getDisplayName(AgreementTypeEnum, type);
    }

    static getSubtypeDisplayName(agreementType: AgreementTypeModel): string {
        const subtype = agreementType?.subtype ?? AgreementSubtypeEnum.None;
        return EnumHelper.getDisplayName(AgreementSubtypeEnum, subtype);
    }

    static setReferentialValue(referentialValues: AgreementReferentialValueModel[], id: string, value: string) {
        if (!referentialValues) {
            return;
        }

        const referentialValue = referentialValues.find(x => x.id == id) ?? new AgreementReferentialValueModel(<AgreementReferentialValueModel>{ id: id });

        referentialValue.value = value;

        if (!referentialValues.some(x => x.id == id)) {
            referentialValues.push(referentialValue);
        }
    }
}
