import { Component } from '@angular/core';
import { SlideOutTabsComponent } from 'app/nexus-shared/components/controls/components/slide-out-tabs/slide-out-tabs.component';

@Component({
    selector: 'gtn-slide-out-tabs-header',
    template: '<ng-content></ng-content>'
})
export class SlideOutTabsHeaderComponent {
    // noinspection JSUnusedLocalSymbols
    constructor(slideOutTabsComponent: SlideOutTabsComponent) {
        //THIS WILL THROW AN ERROR IF ITS NOT NESTED WITHIN THE gtn-slide-out-tabs ELEMENT
    }
}
