import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { Router } from '@angular/router';

@Component({
    selector: 'gtn-boolean-check',
    templateUrl: './boolean-check.component.html',
    styleUrls: ['./boolean-check.component.scss']
})
export class BooleanCheckComponent extends BaseComponent implements OnInit {
    @Input() checkTitle: string;
    @Input() value: boolean;
    @Output() navigateClick = new EventEmitter<any>();

    showNavigationIcon = false;

    constructor(private router: Router) {
        super();
    }

    ngOnInit(): void {
        this.showNavigationIcon = !!this.navigateClick.observers.length;
    }

    onNavigateClicked($event): void {
        this.navigateClick.emit($event);
    }
}


