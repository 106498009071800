import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { DropdownComponent } from 'app/nexus-shared/components/controls';
import { LocationModel } from 'app/nexus-shared/domain/locations/models';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { Observable } from 'rxjs';
import { LocationHelper } from 'app/nexus-core/helpers/location.helper';
import { LocationOrderModel } from 'app/nexus-shared/domain/locations/models/location-order.model';

@Component({
    template: ''
})
export abstract class BaseLocationSelectComponent extends DropdownComponent<LocationModel> implements ControlValueAccessor, OnChanges, AfterViewInit {
    displayKey = ObjectHelper.nameOf<LocationModel>('name');
    @Input() customLocationOrder: LocationOrderModel[];

    @Output() idOnlyFormControlValueChange: EventEmitter<LocationModel> = new EventEmitter<LocationModel>();

    abstract dataFetch: () => Observable<LocationModel[]>;

    ngAfterViewInit() {
        super.ngAfterViewInit();
        if (this.idOnlyFormControlValueChange.observers?.length) {
            this.subscriptions.add(this.formControl.valueChanges.subscribe(value => {
                this.idOnlyFormControlValueChange.emit(this.options?.find(x => x[this.idKey] === value) ?? null);
            }));
        }
    }

    handleResponse(data: LocationModel[]): void {
        this.options = LocationHelper.locationsSort(data, this.customLocationOrder);
        if (this.idToSetWhenOptionsLoaded) {
            this.value = this.options.find(x => x.id === this.idToSetWhenOptionsLoaded);
            this.idToSetWhenOptionsLoaded = null;
        }
    }

    initOptions(): void {
        if (this.options == null) {
            this.dataFetch().subscribe(data => {
                this.handleResponse(data);
            });
        }
    }
}
