<div @modalFadeIn class="modal-wrapper">
    <gtn-modal-header [closeClick]="destroy" [mainHeaderText]="mainHeaderText">
    </gtn-modal-header>
    <div class="modal-content">
        <div>
            {{message}}
        </div>
        <div class="select-container">
            <gtn-dropdown [label]="controlLabel" [options]="options" [(ngModel)]="selectedValue" idKey="value"
                displayKey="label"></gtn-dropdown>
        </div>
    </div>
    <gtn-modal-footer [cancel]='cancelClick' [enableOkButton]="selectedValue" [ok]='okClick'
        [showCancel]="cancel ? true : false" [showOk]="ok ? true : false" [okButtonText]="okButtonText"
        [cancelButtonText]="cancelButtonText">
    </gtn-modal-footer>
</div>