import { QuestionnaireModel } from 'app/nexus-shared/domain/questionnaires/models/questionnaire.model';
import { CommonBaseAuditModel } from 'app/nexus-shared/domain/contacts/models/common-base-audit.model';
import { IndividualInterface } from 'app/nexus-shared/interfaces/individual.interface';

export class IndividualCchOrganizerModel extends CommonBaseAuditModel {
    individualCchOrganizerKey: string;
    userName: string;
    password: string;
    gateKeeper: IndividualInterface;
    questionnaire: QuestionnaireModel;

    constructor(model: IndividualCchOrganizerModel = null) {
        super(model);
        this.individualCchOrganizerKey = model?.individualCchOrganizerKey ?? null;
        this.userName = model?.userName ?? null;
        this.password = model?.password ?? null;
        this.gateKeeper = model?.gateKeeper ?? null;
        this.questionnaire = new QuestionnaireModel(model?.questionnaire ?? null);
    }

}
