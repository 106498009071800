import { Injectable } from '@angular/core';
import { DateHelper } from 'app/nexus-core/helpers';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Moment } from 'moment';
import * as moment from 'moment/moment';

@Injectable()
export class DatePickerDateAdapter extends MomentDateAdapter {

    format(date: Moment): string {
        return DateHelper.format(DateHelper.getGreenwichDateFromLocal(date.toDate()));
    }

    parse(value: any, parseFormat: string | string[]): Moment | null {
        if (value && typeof value === 'string') {
            // Define the array of possible formats you want to support
            // const formats = ['DD/MM/YYYY', 'MM-DD-YYYY', 'YYYY-MM-DD', 'DD MMM YYYY', 'MM/DD/YYYY'];

            // Parse the input value using moment's `moment` function with multiple formats
            // Dont pass any and let moment use all its formats
            const date = moment(value);

            if (date.isValid()) {
                return date;
            }
        }
        return null;
    }
}
