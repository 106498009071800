import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SavedSettingModel } from 'app/nexus-shared/models/saved-setting.model';
import { BaseEntryComponent } from 'app/nexus-shared/components/base-component/base-entry.component';
import { SavedSettingService } from 'app/nexus-core/services/domain/core/saved-setting.service';
import { Observable } from 'rxjs';
import { SavedSettingSearchModel } from 'app/nexus-shared/models/saved-setting-search.model';
import { UserModel } from 'app/nexus-shared/domain/users/models/user.model';
import { ApplicationsEnum } from 'app/nexus-shared/enums';
import { concatMap, tap } from 'rxjs/operators';

@Component({
    selector: 'gtn-saved-setting-modal',
    templateUrl: './saved-setting-modal.component.html',
    styleUrls: ['./saved-setting-modal.component.scss']
})
export class SavedSettingModalComponent extends BaseEntryComponent<SavedSettingModel> implements OnInit {
    @Output() saveSuccess: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input() canShareSettings: boolean;
    @Input() user: UserModel;
    @Input() key: string;
    @Input() settingValue: any;
    @Input() application: ApplicationsEnum;

    isChipView: boolean;
    isNameChanged: boolean;
    isSharedSetting: boolean;
    sharedUserSettings: SavedSettingModel[];
    selectedSharedSetting: SavedSettingModel;
    selectedUserSetting: SavedSettingModel;
    userSettings: SavedSettingModel[];
    originalValue: SavedSettingModel;

    constructor(private saveSettingService: SavedSettingService) {
        super();
    }

    ngOnInit(): void {
        this.getUserSettings();
        if (this.canShareSettings) {
            this.getSharedSettings();
        }
        this.initValue();
    }

    onDeleteSettingClicked() {
        this.saveSettingService.delete(this.value.savedSettingId, true).pipe(concatMap(_ => {
            if (this.value.individualKey) {
                return this.userSettings$();
            } else {
                return this.sharedSettings$();
            }
        })).subscribe((res) => {
            this.onSaveSuccess();
            this.initValue();
            this.saveSuccess.emit(false);
        }, err => {
            this.onSaveFailure(err);
        });
    }

    onSaveClicked(): void {
        this.saveSetting().subscribe(res => {
            this.onSaveSuccess();
            this.saveSuccess.emit(true);
        }, err => {
            this.onSaveFailure(err);
        });
    }

    onUserSettingChanged(userSetting: SavedSettingModel) {
        if (userSetting?.savedSettingId) {
            this.selectedSharedSetting = null;
            this.setValue(this.selectedUserSetting);
        }
    }

    onChipValueChange(isChip: boolean) {
        this.settingValue['isChipView'] = isChip;
    }

    onSharedSettingChanged(sharedSetting: SavedSettingModel) {
        if (sharedSetting?.savedSettingId) {
            this.selectedUserSetting = null;
            this.setValue(this.selectedSharedSetting);
        }
    }


    onNameChanged() {
        this.isNameChanged = this.value.name !== this.originalValue.name;
    }

    onSharedChanged(isShared: boolean) {
        if (isShared) {
            this.value.individualKey = null;
        } else {
            this.value.individualKey = this.user.individualKey;
        }
    }

    private saveSetting(): Observable<number | boolean> {
        this.value.value = this.settingValue;
        this.value.savedSettingId = this.isValueChanged() ? null : this.value.savedSettingId;
        if (this.value?.savedSettingId) {
            return this.saveSettingService.update(this.value, true);
        } else {
            return this.saveSettingService.create(this.value, true);
        }
    }

    private getUserSettings(): void {
        this.userSettings$().subscribe();
    }

    private getSharedSettings(): void {
        this.sharedSettings$().subscribe();
    }

    private initValue(): void {
        this.value = new SavedSettingModel();
        this.value.key = this.key;
        this.value.application = this.application;
        this.value.individualKey = this.user.individualKey;
        this.setValue(this.value);
    }

    private setValue(setting: SavedSettingModel): void {
        this.value = new SavedSettingModel(setting);
        this.originalValue = new SavedSettingModel(JSON.parse(JSON.stringify(setting)));
        this.isNameChanged = false;
        this.isChipView = this.value?.value?.isChipView ?? false;
        this.isSharedSetting = !this.value?.individualKey;
    }

    private sharedSettings$(): Observable<SavedSettingModel[]> {
        return this.saveSettingService.search(<SavedSettingSearchModel>{
                application: this.application, key: this.key
            }
        ).pipe(tap(res => {
            this.sharedUserSettings = res.filter(x => x.createdByUser.userKey === this.user.userKey);
        }));
    }

    private userSettings$(): Observable<SavedSettingModel[]> {
        return this.saveSettingService.search(<SavedSettingSearchModel>{
            application: this.application, key: this.key, individualKey: this.user.individualKey
        }).pipe((tap(res => {
            this.userSettings = res;
        })));
    }

    private isValueChanged(): boolean {
        return this.isNameChanged || this.value.individualKey !== this.originalValue.individualKey;
    }

}
