import { animate, AnimationMetadata, style, transition, trigger } from '@angular/animations';

export class FadeInBumpDownFadeOutBumpUp {
    /**
     * Use '@fadeInBumpDownFadeOutBumpUp' in the markup.
     */
    static animations = FadeInBumpDownFadeOutBumpUp.getAnimation();

    private static getAnimation(): AnimationMetadata {
        return trigger('fadeInBumpDownFadeOutBumpUp', [
            transition(':enter', [
                style({transform: 'translateY(-20%)', opacity: 0}),
                animate('400ms ease-in', style({transform: 'translateY(0%)', opacity: 1}))
            ]),
            transition(':leave', [
                style({transform: 'translateY(0%)', opacity: 1}),
                animate('400ms ease-out', style({transform: 'translateY(-20%)', opacity: 0}))
            ])
        ]);
    }
}
