import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { DocumentsHelper } from 'app/nexus-core/helpers/documents.helper';

@Component({
    selector: 'gtn-folder-document-cell-renderer',
    templateUrl: './folder-document-cell-renderer.component.html',
    styleUrls: ['./folder-document-cell-renderer.component.scss']
})
export class FolderDocumentCellRendererComponent implements ICellRendererAngularComp {
    params: ICellRendererParams;
    icon: string;

    agInit(params: ICellRendererParams) {
        this.params = params;
        this.icon = this.getIcon(params);
    }

    refresh(): boolean {
        return true;
    }

    private getIcon(params: ICellRendererParams): string {
        if (params.node?.group) {
            return 'fas fa-folder';
        } else if (params.node.data?.file) {
            return DocumentsHelper.getFileTypeIcon(params.node.data?.file.extension);
        } else {
            return 'fas fa-folder';
        }
    }

}
