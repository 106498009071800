import { UntypedFormGroup } from '@angular/forms';
import { ColDef, Column, ColumnApi, GridApi, ICellRendererParams, RowNode } from 'ag-grid-community';

export class EditorColumnParametersModel implements ICellRendererParams {
    fullWidth?: boolean;
    pinned?: 'left' | 'right';
    value: any;
    valueFormatted: any;
    data: any;
    node: RowNode;
    colDef?: ColDef;
    column?: Column;
    $scope: any;
    rowIndex: number;
    api: GridApi;
    columnApi: ColumnApi;
    context: any;
    eGridCell: HTMLElement;
    eParentOfValue: HTMLElement;
    getValue?: () => any;
    setValue?: (value: any) => void;
    formatValue?: (value: any) => any;
    refreshCell?: () => void;
    addRenderedRowListener: (eventType: string, listener: Function) => void;
    registerRowDragger: (rowDraggerElement: HTMLElement, dragStartPixels?: number, value?: string) => void;

    formGroupRef: UntypedFormGroup;
    formArrayName?: string;
    isValid: boolean = true;
    isDisabled: boolean;
}
