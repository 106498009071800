import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { FolderFavoriteSearchModel } from 'app/nexus-shared/domain/documents/models/folder-favorite-search.model';
import { BaseHttpService } from 'app/nexus-core/services/base-http.service';
import { FolderFavoriteModel } from 'app/nexus-shared/domain/documents/models/folder-favorite.model';

@Injectable()
export class FolderFavoriteService {
    private apiUrl = environment().webAPIUrls.documents + 'folder-favorite/';

    constructor(private baseHttpService: BaseHttpService) {
    }

    search(search: FolderFavoriteSearchModel): Observable<FolderFavoriteModel[]> {
        return this.baseHttpService.post(`${this.apiUrl}search`, search);
    }

    create(model: FolderFavoriteModel): Observable<string> {
        return this.baseHttpService.post(this.apiUrl, model);
    }

    delete(key: string): Observable<boolean> {
        return this.baseHttpService.delete(`${this.apiUrl}delete/${key}`);
    }
}
