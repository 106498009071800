import { Component, OnInit } from '@angular/core';
import { AuthenticatedUserService } from 'app/nexus-core/services/authenticated-user.service';

@Component({
    selector: 'gtn-landing-page',
    templateUrl: './landing-page.component.html'
    //styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
    isGtnUser: boolean = false;

    constructor(
        private authenticatedUserService: AuthenticatedUserService) { }

    ngOnInit(): void {
        this.isGtnUser = this.authenticatedUserService.isGtn || !this.authenticatedUserService.hasCompletedSetup();
    }
}
