import { CommonBaseAuditModel } from '../../contacts/models/common-base-audit.model';
import { IAgreementTypeSearchModelInterface } from '../interfaces/iagreement-type-search-model.interface';
import { AgreementSubtypeEnum } from 'app/nexus-shared/domain/agreements/enums/agreement-subtype.enum';
import { AgreementTypeEnum } from 'app/nexus-shared/domain/agreements/enums/agreement-type.enum';

export class AgreementTypeSearchModel extends CommonBaseAuditModel implements IAgreementTypeSearchModelInterface {
    agreementTypeKeys: string[];
    isActive: boolean;
    nameSearchString: string;
    subtypes: AgreementSubtypeEnum[];
    templateKeys: string[];
    types: AgreementTypeEnum[];

    constructor(model: AgreementTypeSearchModel = null) {
        super(model);

        this.agreementTypeKeys = model?.agreementTypeKeys ?? null;
        this.isActive = model?.isActive ?? null;
        this.nameSearchString = model?.nameSearchString ?? null;
        this.subtypes = model?.subtypes ?? null;
        this.templateKeys = model?.templateKeys ?? null;
        this.types = model?.types ?? null;
    }
}
