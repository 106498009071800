import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { BaseHttpService } from 'app/nexus-core/services/index';

@Injectable()
export class ClientDataAcknowledgmentService {
    apiUrl = `${environment().webAPIUrls.auth}clients/data-acknowledgments`;

    constructor(private baseHttpService: BaseHttpService) {
    }

    agree(): Observable<boolean> {
        return this.baseHttpService.post(`${this.apiUrl}/agree/`);
    }
}
