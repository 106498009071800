<mat-list>
    <mat-list-item class="list-header version-table-header">
        <div class="col col-3">
            File name
        </div>
        <div class="col col-2">
            Date
        </div>
        <div class="col col-2">
            User
        </div>
        <div class="col col-3">
            Note
        </div>
    </mat-list-item>
    <ng-container *ngFor="let versionDocument of documentVersions; first as isFirst; last as isLast">
        <mat-list-item class="list-item version-item mt-2"
            [ngClass]="{'disabled-version': versionDocument.status === documentStatuses.UploadFailed || versionDocument.virusScanError !== virusScanErrors.None}">
            <div class="col col-3 first-column">
                {{versionDocument.name}}
            </div>
            <div class="col col-2">
                {{versionDocument.lastModifiedByUser.changeDateTime | gtnDate : true}}
            </div>
            <div class="col col-2">
                {{versionDocument.lastModifiedByUser.fullName}}
            </div>
            <div class="col col-3">
                {{versionDocument.eventNote}}
            </div>
            <div class="col col-2 actions-column" *ngIf="versionDocument.status !== documentStatuses.UploadFailed && versionDocument.virusScanError === virusScanErrors.None">
                <span matTooltip="Download version" (click)="onDownloadClicked(versionDocument)"><i class="fas fa-download"></i></span>
                <span *ngIf="canPreview" matTooltip="Preview" (click)="onPreviewClicked(versionDocument)">
          <i class="fas fa-eye"></i>
        </span>
                <span *ngIf="canEdit" matTooltip="Create copy of version" (click)="onCopyVersionClicked(versionDocument)"><i class="fas fa-copy"></i></span>
                <span *ngIf="!isLast && canRestore" matTooltip="Restore document to this version" (click)="onRestoreVersionClicked(versionDocument)"><i class="fas fa-clock-rotate-left"></i></span>
            </div>
        </mat-list-item>
    </ng-container>
</mat-list>
<gtn-file-view *ngIf="showPreview" [file]="fileView" (close)="showPreview = false;" (download)="onDownloadClicked(this.previewDocument)">
</gtn-file-view>
