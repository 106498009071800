<form *ngIf="formGroupRef"
    [formGroup]="formGroupRef">
    <div [hidden]="!showSearch">
        <div class="row">
            <div class="col-12">
                <div class="filters">
                    <div class="filter">
                        <gtn-company-search label="Company search"
                            [(value)]="companyFilter"
                            (searchResultClick)="onCompanySearchFilterChanged($event)">
                        </gtn-company-search>
                    </div>
                    <div class="filter">
                        <gtn-individual-search label="Individual search"
                            [(value)]="individualFilter"
                            (searchResultClick)="onIndividualSearchFilterChanged($event)">
                        </gtn-individual-search>
                    </div>
                    <div class="filter checkbox">
                        <gtn-checkbox label="Independents"
                            [value]="isRequestIndependentsOnly"
                            (change)="onIncludeIndependentFilterChanged($event['value'])">
                        </gtn-checkbox>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="filters">
                    <div class="filter">
                        <gtn-multiselect idKey="agreementTypeKey"
                            label="Type"
                            [formControlName]="formControlNames.agreementTypes"
                            [displayFunc]="agreementsHelper.getSubtypeDisplayName.bind(this)"
                            [idOnly]="true"
                            [options]="agreementTypes">
                        </gtn-multiselect>
                    </div>
                    <div class="filter">
                        <gtn-multiselect label="Status"
                            [formControlName]="formControlNames.statuses"
                            [idOnly]="true"
                            [options]="statusOptions">
                        </gtn-multiselect>
                    </div>
                    <div class="filter">
                        <gtn-datepicker label="Expiration start date"
                            [formControlName]="formControlNames.expirationStartDate">
                        </gtn-datepicker>
                    </div>
                    <div class="filter">
                        <gtn-datepicker label="Expiration end date"
                            [formControlName]="formControlNames.expirationEndDate">
                        </gtn-datepicker>
                    </div>
                    <div class="search-click">
                        <button (click)="onSearchClicked()"
                            [disabled]="!formGroupRef.dirty || isLoading"
                            class="mr-2"
                            color="primary"
                            mat-mini-fab
                            matTooltip="Search">
                            <mat-icon>
                                search
                            </mat-icon>
                        </button>
                    </div>
                    <div class="search-click">
                        <button (click)="onResetFiltersClicked()"
                            class="mr-2"
                            color="default"
                            mat-mini-fab
                            matTooltip="Reset filters">
                            <mat-icon>
                                restart_alt
                            </mat-icon>
                        </button>
                    </div>
                    <div class="search-click">
                        <button (click)="onToggleVisibilityClicked()"
                            class="mr-2"
                            color="default"
                            mat-mini-fab
                            matTooltip="Toggle filters">
                            <mat-icon *ngIf="showSearch">
                                visibility
                            </mat-icon>
                            <mat-icon *ngIf="!showSearch">
                                visibility_off
                            </mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
