<ng-container *ngIf="isDisplayHeader">
    <label class="list-label" *ngIf="!isDisplayingLogo">Associated application(s)</label>
    <label class="list-label" *ngIf="isDisplayingLogo">Application link(s)</label>
</ng-container>
<div *ngIf="value?.length"
    class="data-field flex"
    [class.flex-column]="!isDisplayingLogo"
    [class.flex-row]="isDisplayingLogo">
    <ng-container *ngFor="let app of value">
        <a *ngIf="!isDisplayingLogo" class="link" (click)="onLinkClicked(app)">
            {{getAppName(app)}}
            <span><i class="fa fa-external-link ml-1"></i></span>
        </a>
        <a *ngIf="isDisplayingLogo" class="link mr-1" (click)="onLinkClicked(app)">
            <img [matTooltip]="getAppName(app)" [src]="(app | appLogo) ?? 'fallback-image-url'"/>
        </a>
    </ng-container>
    <ng-container *ngIf="(myGTNPortalUserKey || myGTNPortalCompanyKey) && !isAssociatedMyGTNPortal">
        <a *ngIf="!isDisplayingLogo" class="link" (click)="onLinkClicked(applicationsEnum.MyGTNPortal)">
            {{getAppName(applicationsEnum.MyGTNPortal)}}
            <span><i class="fa fa-external-link ml-1"></i></span>
        </a>
        <a *ngIf="isDisplayingLogo" class="link mr-1" (click)="onLinkClicked(applicationsEnum.MyGTNPortal)">
            <img [matTooltip]="getAppName(applicationsEnum.MyGTNPortal)" [src]="(applicationsEnum.MyGTNPortal | appLogo) ?? 'fallback-image-url'"/>
        </a>
    </ng-container>
</div>

