import { Component, EventEmitter, Output } from '@angular/core';
import { NexusAnimations } from 'app/nexus-shared/animations';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { AuthenticationService } from 'app/nexus-core/services/authentication.service';
import { ValidationErrorHelper } from 'app/nexus-core';
import { ValidationModel } from 'app/nexus-shared/models';
import { ClientDataAcknowledgmentService } from 'app/nexus-core/services/client-data-acknowledgment.service';

@Component({
    selector: 'gtn-data-acknowledgment-modal',
    templateUrl: './data-acknowledgment-modal.component.html',
    styleUrls: [
        './data-acknowledgment-modal.component.scss'
    ],
    animations: [
        NexusAnimations.modalFadeIn
    ]
})
export class DataAcknowledgmentModalComponent extends BaseComponent {
    @Output() completed = new EventEmitter<boolean>();

    validationModels: ValidationModel[];

    constructor(private clientDataAcknowledgmentService: ClientDataAcknowledgmentService,
                private authenticationService: AuthenticationService) {
        super();
    }

    onAgreeClicked(): void {
        this.clientDataAcknowledgmentService.agree().subscribe(result => {
            this.authenticationService.updateUserContext().subscribe(_ => {
                this.completed.emit(!!result);
            });
        }, error => {
            ValidationErrorHelper.handleServiceError(error, (validationModels) => {
                this.validationModels = validationModels;
            });
        });
    }

    onCancelClicked(): void {
        this.completed.emit(false);
    }
}
