import { AfterViewInit, Component, forwardRef, Input, OnChanges, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { ComboboxFilterType } from 'app/nexus-shared/components/controls/shared';
import { SimpleChangesTyped } from 'app/nexus-shared/models/simple-changes-typed.type';
import { BaseControlComponent } from '../base-control.component';

@Component({
    selector: 'gtn-autocomplete',
    templateUrl: './autocomplete.component.html',
    styleUrls: ['./autocomplete.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AutocompleteComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => AutocompleteComponent),
            multi: true
        }
    ]
})
export class AutocompleteComponent extends BaseControlComponent<string> implements ControlValueAccessor, AfterViewInit, OnChanges {
    @Input() filterType: ComboboxFilterType = ComboboxFilterType.contains;
    @Input() options: string[];

    filteredOptions: Observable<any[]>;
    autocompleteControl: UntypedFormControl = new UntypedFormControl();

    constructor(private renderer: Renderer2) {
        super();
    }

    ngOnChanges(changes: SimpleChangesTyped<this>) {
        if (changes.options) {
            this.filteredOptions = of(this.options);
        }

        if ((changes.disabled && changes.disabled.currentValue) || (changes.readonly && changes.readonly.currentValue)) {
            if (!this.autocompleteControl.disabled) {
                this.autocompleteControl.disable();
            } else {
                this.autocompleteControl.enable();
            }
        }
    }

    ngAfterViewInit() {
        if (this.focus) {
            this.setFocus();
        }

        this.subscriptions.add(this.autocompleteControl.valueChanges.subscribe(value => {
            this.filteredOptions = of(this.filter(value));

            if ((value == null || typeof value === 'undefined' || value === '') && (this._value != null || typeof this._value !== 'undefined') && value !== this._value) {
                this.value = null;
            }
        }));
    }

    onOptionChange(val: any) {
        this.value = this.options?.find(x => x === val);
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;

        if (isDisabled) {
            this.autocompleteControl.disable();
        } else {
            this.autocompleteControl.enable();
        }
    }

    setFocus(): void {
        this.renderer.selectRootElement('input').focus();
    }

    writeValue(value): void {
        const currentValue = this._value;

        if (typeof currentValue !== 'undefined' && currentValue !== null && currentValue !== value) {
            this.value = value;
        } else if (typeof value !== 'undefined' && value !== null && currentValue !== value) {
            this.value = value;
        }

        this.autocompleteControl.setValue(value);
    }

    private filter(value: string): string[] {
        const filteredValue = value?.toLowerCase();
        let options = [];

        if (this.options && value) {
            if (this.filterType === ComboboxFilterType.begins) {
                options = this.options.filter(x => x.toLowerCase().indexOf(filteredValue) === 0);
            } else if (this.filterType === ComboboxFilterType.contains) {
                options = this.options.filter(x => x.toLowerCase().indexOf(filteredValue) > -1);
            }
        } else if (!value) {
            options = this.options;
        }

        if (options?.length === 0) {
            setTimeout(() => {
                this.value = value;
            }, 0);
        }

        return options;
    }
}
