import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DateHelper, EnumHelper, LocalStorageHelper, NexusModalService } from 'app/nexus-core';
import { AlertComponent, AlertModalInputModel, ModalOutputModel } from 'app/nexus-shared/components/modal';
import { LocalStorageNameConstants, NexusAnimations, SelectListInterface, SelectModel } from 'app/nexus-shared/index';
import { TaxesGridFilterType, TaxesRateFilterType, TaxResidencyEnum } from 'app/nexus-shared/domain/taxes/enums';
import { TaxJurisdictionQualifierModel, TaxModel, TaxRateModel } from 'app/nexus-shared/domain/taxes/models';
import { TaxRateDetailFormGroupHelper } from 'app/nexus-shared/domain/taxes/components/tax-rate-detail/tax-rate-detail-form-group.helper';
import { TaxRateDetailFormControlNames } from 'app/nexus-shared/domain/taxes/components/tax-rate-detail/tax-rate-detail-form-control-names';
import { TaxJurisdictionQualifierService } from 'app/nexus-core/services/domain/taxes/tax-jurisdiction-qualifier.service';
import { TaxRateService } from 'app/nexus-core/services/domain/taxes/tax-rate.service';
import { TaxService } from 'app/nexus-core/services/domain/taxes/tax.service';

@Component({
    selector: 'gtn-tax-rate-detail',
    templateUrl: './tax-rate-detail.component.html',
    styleUrls: ['./tax-rate-detail.component.scss'],
    providers: [
        TaxRateDetailFormGroupHelper
    ],
    animations: [
        NexusAnimations.footerButtonGroupOpenUp
    ]
})
export class TaxRateDetailComponent implements OnInit {
    continueMsg: string = '';
    formControlNames = TaxRateDetailFormControlNames;
    formGroup: UntypedFormGroup = this.taxRateDetailFormGroupHelper.formGroup;
    qualifiers: TaxJurisdictionQualifierModel[];
    repeatAction: boolean = false;
    tax: TaxModel;
    taxRate: TaxRateModel;
    taxQualifiers: TaxJurisdictionQualifierModel[];
    taxResidencyOptions: SelectModel[] = [];

    constructor(
        private activatedRoute: ActivatedRoute,
        private location: Location,
        private modalService: NexusModalService,
        private taxJurisdictionQualifierService: TaxJurisdictionQualifierService,
        private taxRateDetailFormGroupHelper: TaxRateDetailFormGroupHelper,
        private taxRateService: TaxRateService,
        private taxService: TaxService
    ) {
        this.taxResidencyOptions = EnumHelper.convertToSelectModels(TaxResidencyEnum);
    }

    ngOnInit() {
        this.taxJurisdictionQualifierService.list().subscribe((qualifiers: TaxJurisdictionQualifierModel[]) => {
            if (qualifiers) {
                this.taxQualifiers = JSON.parse(JSON.stringify(qualifiers));
                this.taxQualifiers.unshift(<TaxJurisdictionQualifierModel>{ taxQualifierId: null, name: '' });
            }
        });

        const taxId = +this.activatedRoute.snapshot.queryParamMap.get('taxId');
        if (taxId) {
            // Create
            this.continueMsg = 'Save and create again.';
            this.taxRate = new TaxRateModel(taxId);
            this.fetchTaxInfo(taxId);
        } else {
            // Edit/Clone
            let rateId: number = +this.activatedRoute.snapshot.params.id,
                isEdit: boolean = true;

            if (!rateId) {
                rateId = +this.activatedRoute.snapshot.queryParamMap.get('taxRateId');
                if (rateId) {
                    // Clone
                    this.continueMsg = 'Save and clone again.';
                    isEdit = false;
                }
            }

            if (rateId) {
                this.taxRateService.getById(rateId).subscribe((model: TaxRateModel) => {
                    if (model) {
                        this.taxRate = JSON.parse(JSON.stringify(model));

                        if (!isEdit) {
                            this.taxRate.taxRateId = 0;
                        }

                        if (model.tax) {
                            this.tax = model.tax;
                        } else {
                            this.fetchTaxInfo(model.taxId);
                        }

                        this.taxRateDetailFormGroupHelper.setControlValues(model);

                        if (isEdit) {
                            this.formGroup.markAsPristine();
                        } else {
                            this.formGroup.markAsDirty();
                        }
                    }
                });
            }
        }
    }

    cancel() {
        this.taxRateDetailFormGroupHelper.setControlValues(JSON.parse(JSON.stringify(this.taxRate)));
        this.formGroup.markAsPristine();
    }

    save() {
        const originalRate = JSON.parse(JSON.stringify(this.taxRate));

        this.taxRateDetailFormGroupHelper.updateModel(this.taxRate);

        if (this.taxRate.taxRateId) {
            // Update
            this.taxRateService.update(this.taxRate).subscribe((taxRate: TaxRateModel) => {
                if (taxRate) {
                    this.doFilterCheck();
                    if (this.repeatAction) {
                        this.formGroup.markAsPristine();
                    }
                } else {
                    // TODO - not sure what to do here... Should never land here.
                }
            });
        } else {
            // Create
            this.taxRateService.create(this.taxRate).subscribe((taxRateId: number) => {
                if (this.repeatAction) {
                    this.taxRate = originalRate;
                    this.taxRateDetailFormGroupHelper.setControlValues(JSON.parse(JSON.stringify(originalRate)));
                    if (+this.activatedRoute.snapshot.queryParamMap.get('taxRateId')) {
                        // Clone
                        this.formGroup.markAsDirty();
                    } else {
                        // Create
                        this.formGroup.markAsPristine();
                    }
                } else {
                    this.taxRate.taxRateId = taxRateId;
                }
                this.doFilterCheck();
            });
        }
    }

    return() {
        this.location.back();
    }

    private doFilterCheck() {
        const currentFilters: SelectListInterface[] = [];
        const taxesFilters: SelectListInterface[] = LocalStorageHelper.get(LocalStorageNameConstants.TaxesGrid);
        if (taxesFilters) {
            const dateFilter = taxesFilters.find(x => x.typeId === TaxesGridFilterType.date);
            if (dateFilter) {
                const dateFilterDate = DateHelper.convertToUtcDate(dateFilter.value);
                if ((this.tax.effectiveStartDate && DateHelper.greaterThan(DateHelper.convertToUtcDate(this.tax.effectiveStartDate), dateFilterDate)) || (this.tax.effectiveEndDate || DateHelper.lessThan(DateHelper.convertToUtcDate(this.tax.effectiveEndDate), dateFilterDate))) {
                    currentFilters.push(dateFilter);
                }
            }
        }

        const taxRatesFilters: SelectListInterface[] = LocalStorageHelper.get(LocalStorageNameConstants.TaxRatesGrid);
        if (taxRatesFilters) {
            const dateFilter = taxRatesFilters.find(x => x.typeId === TaxesRateFilterType.date);
            if (dateFilter) {
                const dateFilterDate = DateHelper.convertToUtcDate(dateFilter.value);
                if ((this.tax.effectiveStartDate && DateHelper.greaterThan(DateHelper.convertToUtcDate(this.tax.effectiveStartDate), dateFilterDate)) || (this.tax.effectiveEndDate || DateHelper.lessThan(DateHelper.convertToUtcDate(this.tax.effectiveEndDate), dateFilterDate))) {
                    currentFilters.push(dateFilter);
                }
            }

            const qualifierFilter = taxRatesFilters.find(x => x.typeId === TaxesRateFilterType.qualifierId);
            if (qualifierFilter) {
                if (qualifierFilter.id !== this.taxRate.taxQualifierId) {
                    currentFilters.push(qualifierFilter);
                }
            }

        }

        if (currentFilters.length === 0 && !this.repeatAction) {
            this.return();
        } else {
            this.showFilterAlert(currentFilters);
        }
    }

    private fetchTaxInfo(taxId: number) {
        this.taxService.getTaxById(taxId).subscribe((tax: TaxModel) => {
            if (tax) {
                this.tax = tax;
            }
        });
    }

    private showFilterAlert(filters: SelectListInterface[]) {
        const modalInputs = new AlertModalInputModel();
        modalInputs.okButtonText = 'Ok';
        modalInputs.mainHeaderText = 'Alert';
        modalInputs.message = 'This tax rate will not show due to a selected filter.';

        const modalOutputs = new ModalOutputModel();
        modalOutputs.ok = (() => {
            if (!this.repeatAction) {
                this.return();
            }
        }).bind(this);

        this.modalService.init(AlertComponent, modalInputs, modalOutputs);
    }
}
