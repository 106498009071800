export class AgreementWildcardConstants {
    public static readonly agreementNumber = "{{agreement-number}}";
    public static readonly agreementRecipient = "{{agreement-recipient}}";
    public static readonly agreementStatus = "{{agreement-status}}";
    public static readonly agreementTypeName = "{{agreement-type-name}}";
    public static readonly contactId = "{{contact-id}}";
    public static readonly createdDate = "{{created-date}}";
    public static readonly signerEmail = "{{signer-email}}";

    //Consent Form
    public static readonly companyLegalName = "{{company-legal-name}}";
    public static readonly taxYearsRange = "{{tax-years-range}}";

    //Independent Engagement Letters
    public static readonly calendarYears = "{{calendar-years}}";
    public static readonly complianceFee = "{{compliance-fee}}";
    public static readonly consultingFee = "{{consulting-fee}}";
    public static readonly fullName = "{{full-name}}";
    public static readonly services = "{{services}}";
    public static readonly totalFee = "{{total-fee}}";
}
