/* eslint-disable no-bitwise */
export class Guid extends String {
    static Empty = '00000000-0000-0000-0000-000000000000';

    static newGuid(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0;
            const v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    static isEmpty(value: string): boolean {
        return value === Guid.Empty;
    }
}
