export enum ApplicationsEnum {
    //INTERNAL
    Unknown = 0,
    Equity = 1,
    Whereabouts = 2,
    Traction = 3,
    Portal = 4,
    Equity_Dev = 5,
    Equity_Test = 6,
    Global = 99,

    // SUB APPLICATIONS
    Users = 100,
    Taxes = 101,
    Contacts = 102,
    TractionTimeTracking = 103,
    Taxability = 104,
    Email = 105,
    Compliance = 106,
    Accounting = 107,
    Documents = 108,
    TaxOrganizer = 109,
    Vendors = 110,
    Workforce = 111,
    Templates = 112,
    TravelCalendar = 113,
    Agreements = 114,
    Questionnaires = 115,

    //EXTERNAL
    Gtn = 1000,
    MyGTNPortal = 1001,
    GlobalTracker = 1002,
    BigTime = 1003,
}
