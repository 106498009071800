import { DocumentModel } from 'app/nexus-shared/domain/documents/models/document.model';
import { ConfirmActionTypesEnum } from 'app/nexus-shared/domain/documents/enums/confirm-action-types.enum';
import { AreaTypesEnum } from 'app/nexus-shared/domain/documents/enums/area-types.enum';
import { BaseRouteApplicationConfigurationModel } from 'app/nexus-shared';
import { DocumentsRoutingConfigurationConstants } from 'app/modules/documents/routing/documents-routing-configuration.constants';
import { ClientFolderModel } from 'app/nexus-shared/domain/documents/models/client-folder.model';
import { ClientDocumentModel } from 'app/nexus-shared/domain/documents/models/client-document.model';
import { DocumentEventTypesEnum } from 'app/nexus-shared/domain/documents/enums/document-event-types.enum';
import { PreviewFileTypesEnum } from 'app/nexus-shared/components/controls/shared/enums/preview-file-types.enum';
import { EnumHelper } from 'app/nexus-core/helpers/enum.helper';
import { DocumentsGridViewTypes } from 'app/nexus-shared/domain/documents/enums/documents-grid-view.types';

export class DocumentsHelper {
    static readonly documentEntityCopy: string = 'documentEntityCopy';
    static readonly documentMultipleCopy: string = 'multipleDocumentCopy';
    static readonly gridFolderTypeName: string = EnumHelper.getDisplayName(DocumentsGridViewTypes, DocumentsGridViewTypes.Folder);
    static readonly virusScannedVersionEvents: DocumentEventTypesEnum[] = [DocumentEventTypesEnum.CheckIn, DocumentEventTypesEnum.Upload];
    static readonly noVirusScannedVersionEvents: DocumentEventTypesEnum[] = [DocumentEventTypesEnum.Create, DocumentEventTypesEnum.CopyCreate, DocumentEventTypesEnum.CopyFromTemplate, DocumentEventTypesEnum.CopyFromVersionHistory, DocumentEventTypesEnum.Restore];

    static getAreaRoutingConfiguration(areaType: AreaTypesEnum): BaseRouteApplicationConfigurationModel {
        const routingConfigurations = DocumentsRoutingConfigurationConstants.documentAreaConfigurations;
        for (const x in routingConfigurations) {
            if (routingConfigurations[x]().applicationMenuId === areaType) {
                return routingConfigurations[x]();
            }
        }
    }

    static getFiscalTaxYear(clientDocument: ClientDocumentModel): string {
        if (clientDocument?.taxYear && clientDocument.nonStandardTaxYear) {
            return `${clientDocument.taxYear}/${clientDocument.taxYear + 1}`;
        } else if (clientDocument?.taxYear) {
            return clientDocument.taxYear.toString();
        }
    }

    static getClientFolderPath(clientFolder: ClientFolderModel): string {
        return `Clients/Companies/${clientFolder.companyName}`;
    }

    static getIndependentFolderPath(clientFolder: ClientFolderModel): string {
        return `Clients/Independents/${clientFolder.individualLastName}, ${clientFolder.individualFirstName}`;
    }

    static getMobileEmployeeFolderPath(clientFolder: ClientFolderModel): string {
        return `Clients/Companies/${clientFolder.companyName}/Mobile Employees/${clientFolder.individualLastName}, ${clientFolder.individualFirstName}`;
    }

    static getCommonPath(path1: string, path2: string): string {
        if (typeof path1 !== 'string' || typeof path2 !== 'string') {
            return null;
        }
        const parts1 = path1.split('/');
        const parts2 = path2.split('/');
        const minLength = Math.min(parts1.length, parts2.length);

        let index = 0;
        while (index < minLength && parts1[index] === parts2[index] && index < 100) {
            index++;
        }
        return parts1.slice(0, index).join('/');
    }

    static getClientName(clientEntity: ClientFolderModel | ClientDocumentModel): string {
        if (clientEntity.companyKey && clientEntity.individualKey) {
            return `${clientEntity?.companyName} - ${clientEntity?.individualLastName}, ${clientEntity?.individualFirstName}`;
        } else if (clientEntity?.companyKey) {
            return clientEntity.companyName;
        } else if (clientEntity.individualKey) {
            return `${clientEntity?.individualLastName}, ${clientEntity?.individualFirstName}`;
        }
        return null;
    }

    static getValidVersions(documentHistory: DocumentModel[]): DocumentModel[] {
        const validVersions: DocumentModel[] = [];
        for (let i = 0; i <= documentHistory.length - 1; i++) {
            if (DocumentsHelper.noVirusScannedVersionEvents.includes(documentHistory[i]?.eventType)) {
                validVersions.push(documentHistory[i]);
            } else if (DocumentsHelper.virusScannedVersionEvents.includes(documentHistory[i]?.eventType)) {
                if (documentHistory[i + 1]?.eventType === DocumentEventTypesEnum.VirusScan
                    || documentHistory[i + 1]?.eventType === DocumentEventTypesEnum.SkippedVirusScan) {
                    validVersions.push(documentHistory[i]);
                }
            }
        }
        return validVersions;
    }

    static fileTypeSort(a, b, nodeA, nodeB, isDescending): number {
        if (isDescending) {
            if (a === DocumentsHelper.gridFolderTypeName && b !== DocumentsHelper.gridFolderTypeName) {
                return 1;
            } else {
                if (a === b) {
                    return 0;
                }
            }
            return (a > b) ? -1 : 1;
        } else {
            if (a === DocumentsHelper.gridFolderTypeName && b !== DocumentsHelper.gridFolderTypeName) {
                return 1;
            } else {
                if (a === b) {
                    return 0;
                }
            }
            return (a > b) ? -1 : 1;
        }
    }

    static isPreviewType(fileExtension: string): boolean {
        return this.getPreviewFileType(fileExtension) !== PreviewFileTypesEnum.Unknown;
    }

    static getPreviewFileType(fileName: string): PreviewFileTypesEnum {
        const fileExtension = fileName.split('.').pop().toLowerCase();
        switch (fileExtension) {
            case 'pdf':
                return PreviewFileTypesEnum.PDF;
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif':
            case 'jfif':
            case 'bmp':
            case 'svg':
                return PreviewFileTypesEnum.Image;
            case 'txt':
            case 'rtf':
                return PreviewFileTypesEnum.Text;
            default:
                return PreviewFileTypesEnum.Unknown;
        }
    }

    static getFileTypeName(fileName: string): string {
        const fileExtension = fileName.split('.').pop().toLowerCase();
        switch (fileExtension) {
            case 'doc':
            case 'docx':
                return 'Word File';
            case 'pdf':
                return 'PDF File';
            case 'csv':
                return 'CSV File';
            case 'xls':
            case 'xlsx':
            case 'xlsm':
                return 'Excel File';
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif':
            case 'heic':
            case 'bmp':
            case 'svg':
                return 'Image File';
            case 'msg':
                return 'Email Message';
            case 'txt':
            case 'rtf':
                return 'Text File';
            case 'zip':
            case 'rar':
                return 'Compressed File(s)';
            case 'ppt':
            case 'pptm':
            case 'pptx':
                return 'PowerPoint File';
            case 'numbers':
                return 'Numbers File';
            case 'key':
                return 'Keynote File';
            case 'pages':
                return 'Pages File';
            case 'mp4':
                return 'Video File';
            default:
                return 'Unknown';
        }
    }

    static getFileTypeIcon(fileName: string): string {
        const fileExtension = fileName.split('.').pop().toLowerCase();
        switch (fileExtension) {
            case 'doc':
            case 'docx':
                return 'far fa-file-word';
            case 'pdf':
                return 'far fa-file-pdf';
            case 'csv':
                return 'fa-regular fa-file-csv';
            case 'xls':
            case 'xlsx':
            case 'xlsm':
                return 'far fa-file-excel';
            case 'jpg':
            case 'png':
            case 'jpeg':
            case 'gif':
            case 'svg':
            case 'bmp':
                return 'fas fa-image';
            case 'msg':
                return 'fas fa-envelope';
            case 'zip':
            case 'rar':
                return 'far fa-file-zipper';
            case 'ppt':
            case 'pptm':
            case 'pptx':
                return 'far fa-file-powerpoint';
            case 'numbers':
                return 'fas fa-chart-simple';
            case 'key':
                return 'fas fa-keynote';
            case 'pages':
                return 'fas fa-file-pen';
            case 'mp4':
                return 'far fa-file-video';
            default:
                return 'fas fa-file';
        }
    }

    static formatBytes(bytes, decimals = 2): string {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    static getConfirmActionHeader(action: ConfirmActionTypesEnum): string {
        let actionText: string;
        switch (action) {
            case ConfirmActionTypesEnum.DeleteDocument:
                actionText = `File Deletion`;
                break;
            case ConfirmActionTypesEnum.DeleteFolder:
                actionText = `Folder Deletion`;
                break;
            case ConfirmActionTypesEnum.CheckOut:
                actionText = 'Check Out';
                break;
            case ConfirmActionTypesEnum.Recover:
                actionText = 'File Recover';
                break;
        }
        return actionText;
    }

    static getConfirmActionText(action: ConfirmActionTypesEnum): string {
        let actionText: string;
        switch (action) {
            case ConfirmActionTypesEnum.DeleteDocument:
                actionText = `deleting the following file(s)?`;
                break;
            case ConfirmActionTypesEnum.DeleteFolder:
                actionText = `deleting the following folder?`;
                break;
            case ConfirmActionTypesEnum.CheckOut:
                actionText = 'checking out the following file(s)?';
                break;
            case ConfirmActionTypesEnum.Recover:
                actionText = 'recovering this file?';
                break;
        }
        return actionText;
    }

    static getConfirmActionWarning(action: ConfirmActionTypesEnum): string {
        let warningText: string;
        switch (action) {
            case ConfirmActionTypesEnum.DeleteDocument:
                warningText = `All deleted files are recoverable through the deleted files tab for a limited period.`;
                break;
            case ConfirmActionTypesEnum.DeleteFolder:
                warningText = `All contained deleted files are recoverable through the deleted files tab for a limited period.`;
                break;
            case ConfirmActionTypesEnum.CheckOut:
                warningText = 'Checking out files will lock them for all others to edit.';
                break;
            case ConfirmActionTypesEnum.Recover:
                warningText = 'Recovering this file will put it in its previously contained folder.';
                break;
        }
        return warningText;
    }
}
