<gtn-file-view *ngIf="fileView"
    [enableSearch]="true"
    [enablePrinting]="true"
    [file]="fileView"
    [enablePDFOutline]="true"
    [enablePDFThumbnails]="true"
    [enableDownload]="pdfFileViewService?.download ? true : false"
    [isAgreement]="true"
    [signatureValidationErrors]="signatureValidationErrors"
    (close)="onCloseClicked()"
    (download)="pdfFileViewService?.download()"
    (fileLoadComplete)="onFileLoadComplete()">
    <gtn-file-view-header-center-controls *ngIf="agreement.status !== agreementStatusEnum.Signed && signButtonLabel">
        <div *ngIf="signatureValidationErrors && signatureValidationErrors.length > 0"
            class="remaining-signature-text">
            Please complete and sign in {{signatureValidationErrors?.length ?? 0}} location(s).
        </div>
        <button mat-flat-button
            *ngIf="!agreement.signedDateTime && !isReadOnly"
            class="sign-agreement-button"
            (click)="onSignAgreementClicked()">
            {{signButtonLabel}}
        </button>
    </gtn-file-view-header-center-controls>
    <gtn-file-view-header-center-controls *ngIf="agreement.status === agreementStatusEnum.PendingReview">
        <button mat-flat-button
            class="review-agreement-approve-button"
            (click)="onReviewerApproveClicked()">
            <i class="fa-solid fa-circle-check"></i>
            <div class="review-agreement-approve-label">Approve</div>
        </button>
        <button mat-flat-button
            class="review-agreement-deny-button"
            (click)="onReviewerDenyClick()">
            <i class="fa-solid fa-circle-xmark"></i>
            <div class="review-agreement-deny-label">Deny</div>
        </button>
    </gtn-file-view-header-center-controls>
</gtn-file-view>
<gtn-agreements-confirm-modal *ngIf="showReviewerConfirmModal"
    [headerText]="reviewerConfirmModalHeaderText"
    [bodyText]="reviewerConfirmModalBodyText"
    (confirmClick)="onReviewerDenyConfirmClick($event)"
    (cancelClick)="onReviewerDenyCancelClick()">
</gtn-agreements-confirm-modal>
