<gtn-modal class="modal-wide" [headerText]="(this.value?.individual?.individualKey ? 'Edit Individual' : 'Create Individual')"
    okButtonText="Save"
    [disableOkButton]="!formGroupRef.dirty || isSaving" [validationModels]="validationModels"
    (okClick)="(authenticatedUserService.hasAuthorization(authorizationConstants.contactsAdmin) ? onSaveClicked() : null)"
    (cancelClick)="onCancelClicked()" (closeClick)="onCancelClicked()" [formGroupRef]="formGroupRef"
    [shouldConfirmCancel]="true">
    <gtn-modal-content>
        <gtn-individual-company-access-composite-form [(formGroupRef)]="formGroupRef" [isControllingCompanyEmployee]="isControllingCompany"
            (formGroupRefChange)="onFormGroupRefChanged($event)" [(value)]="value" [cardMode]="cardMode">
        </gtn-individual-company-access-composite-form>
    </gtn-modal-content>
</gtn-modal>
