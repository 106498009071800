<gtn-modal class="modal-small-wide" [headerText]="headerText"
    okButtonText="Save" [disableOkButton]="(!formGroupRef.dirty && !isDualFormDirty) || isSaving" [validationModels]="validationModels"
    (okClick)="onSaveClicked()" [shouldConfirmCancel]="true"
    (cancelClick)="onCancelClicked()" (closeClick)="onCancelClicked()" [formGroupRef]="formGroupRef">
    <gtn-modal-content>
        <ng-content></ng-content>
        <gtn-individual-form *ngIf="isCreateNewIndividual" [isPhoneNumberSubFormArrayModal]="true" [(formGroupRef)]="formGroupRef"
            (formGroupRefChange)="onFormGroupRefChanged($event)"
            [isIndividualTypeReadonly]="isControllingCompany"
            [(value)]="value" [cardMode]="cardMode" [viewMode]="viewMode"></gtn-individual-form>
    </gtn-modal-content>
</gtn-modal>
