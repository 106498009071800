import { DocumentStatusesEnum } from 'app/nexus-shared/domain/documents/enums/document-statuses.enum';
import { DocumentAccessTypesEnum } from 'app/nexus-shared/domain/documents/enums/document-access-types.enum';
import { FileModel } from 'app/nexus-shared/domain/documents/models/file.model';
import { AreaTypesEnum } from 'app/nexus-shared/domain/documents/enums/area-types.enum';
import { ClientDocumentModel } from 'app/nexus-shared/domain/documents/models/client-document.model';
import { DocumentEventTypesEnum } from 'app/nexus-shared/domain/documents/enums/document-event-types.enum';
import { NoteModel } from 'app/nexus-shared/domain/documents/models/note.model';
import { VirusScanErrorsEnum } from 'app/nexus-shared/domain/documents/enums/virus-scan-errors.enum';
import { CommonBaseAuditModel } from '../../contacts/models/common-base-audit.model';
import { ApplicationsEnum } from 'app/nexus-shared/enums';

export class DocumentModel extends CommonBaseAuditModel {
    documentKey: string;
    folderKey: string;
    historicalKey: string;
    noteKey: string;
    documentPath: string;
    name: string;
    status: DocumentStatusesEnum;
    accessType: DocumentAccessTypesEnum;
    areaType: AreaTypesEnum;
    sourceApp: ApplicationsEnum;
    tags: string[];
    eventNote: string;
    file: FileModel;

    eventType: DocumentEventTypesEnum;
    copiedFromDocumentKey: string;
    virusScanError: VirusScanErrorsEnum;
    isVirusScanned: boolean;
    uploadFailedEmailSent: boolean;
    uploadNotificationEmailSent: boolean;
    clientDocument: ClientDocumentModel;
    note: NoteModel;

    constructor(model: DocumentModel = null) {
        super(model);
        this.documentKey = model?.documentKey ?? null;
        this.folderKey = model?.folderKey ?? null;
        this.historicalKey = model?.historicalKey ?? null;
        this.noteKey = model?.noteKey ?? null;
        this.documentPath = model?.documentPath ?? null;
        this.name = model?.name ?? null;
        this.status = model?.status ?? DocumentStatusesEnum.Active;
        this.accessType = model?.accessType ?? DocumentAccessTypesEnum.Unknown;
        this.areaType = model?.areaType ?? AreaTypesEnum.Unknown;
        this.sourceApp = model?.sourceApp ?? ApplicationsEnum.Unknown;
        this.tags = model?.tags ?? null;
        this.eventNote = model?.eventNote ?? null;
        this.file = model?.file ?? null;
        this.eventType = model?.eventType ?? DocumentEventTypesEnum.Unknown;
        this.copiedFromDocumentKey = model?.copiedFromDocumentKey ?? null;
        this.virusScanError = model?.virusScanError ?? VirusScanErrorsEnum.None;
        this.isVirusScanned = model?.isVirusScanned ?? false;
        this.uploadFailedEmailSent = model?.uploadFailedEmailSent ?? false;
        this.uploadNotificationEmailSent = model?.uploadNotificationEmailSent ?? false;
        this.clientDocument = model?.clientDocument ? new ClientDocumentModel(model.clientDocument) : new ClientDocumentModel();
        this.note = model?.note ? new NoteModel(model.note) : new NoteModel();
    }
}
