import { Component, Input, OnInit } from '@angular/core';
import { BaseEnterpriseGridComponent } from 'app/nexus-shared/components/base-component/base-enterprise-grid.component';
import { DocumentModel } from 'app/nexus-shared/domain/documents/models/document.model';
import { AgGridHelper, DateHelper, SpinnerService } from 'app/nexus-core';
import { Observable } from 'rxjs';
import { DocumentService } from 'app/nexus-core/services/domain/documents/document.service';
import { ColDef, GridOptions } from 'ag-grid-community';
import { DocumentSearchModel } from 'app/nexus-shared/domain/documents/models/document-search.model';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { concatMap } from 'rxjs/operators';
import { DocumentFolderService } from 'app/nexus-core/services/domain/documents/document-folder.service';
import { ToastService } from 'app/nexus-core/services/toast.service';
import { FolderModel } from 'app/nexus-shared/domain/documents/models/folder.model';
import { ConfirmActionTypesEnum } from 'app/nexus-shared/domain/documents/enums/confirm-action-types.enum';
import { DocumentsAuthModel } from 'app/nexus-shared/domain/documents/models/documents-auth.model';
import { FileModel } from 'app/nexus-shared/domain/documents/models/file.model';
import { DocumentsHelper } from 'app/nexus-core/helpers/documents.helper';
import { DocumentStatusesEnum } from 'app/nexus-shared/domain/documents/enums/document-statuses.enum';
import { FolderDocumentGridService } from 'app/nexus-core/services/domain/documents/folder-document-grid.service';
import { VirusScanErrorsEnum } from 'app/nexus-shared/domain/documents/enums/virus-scan-errors.enum';
import { AreaTypesEnum } from 'app/nexus-shared/domain/documents/enums/area-types.enum';
import { IndividualSettingService } from 'app/nexus-core/services/domain/core/individual-setting.service';
import { AgGridColDefHelper } from 'app/nexus-core/helpers/ag-grid-col-def.helper';
import { AgGridColDefExtras } from 'app/nexus-shared/models/ag-grid-col-def-extras.model';
import { CommonBaseAuditUserModel } from 'app/nexus-shared/domain/contacts/models/common-base-audit-user.model';

@Component({
    selector: 'gtn-trash-bin-grid',
    templateUrl: './trash-bin-grid.component.html',
    styleUrls: ['./trash-bin-grid.component.scss']
})
export class TrashBinGridComponent extends BaseEnterpriseGridComponent<DocumentModel> implements OnInit {
    static readonly trashbinNoRowsOverlay = `<span>No deleted files found</span>`;


    @Input() rootFolder: FolderModel;
    @Input() documentsUserAuth: DocumentsAuthModel;

    gridName: string = 'trash_bin_grid';
    gridVersion: string = '1.0.0';
    confirmActionType: ConfirmActionTypesEnum.Recover;
    confirmActionEntities: string[];
    documentToRecover: DocumentModel;
    searchModel: DocumentSearchModel = new DocumentSearchModel();
    showConfirmModal: boolean = false;

    constructor(
        protected individualSettingService: IndividualSettingService,
        private documentService: DocumentService,
        private documentFolderService: DocumentFolderService,
        private folderDocumentGridService: FolderDocumentGridService,
        private toastService: ToastService
    ) {
        super(individualSettingService);
    }

    ngOnInit(): void {
        const gridOptions: GridOptions = {
            suppressContextMenu: false,
            overlayNoRowsTemplate: TrashBinGridComponent.trashbinNoRowsOverlay,
            noRowsOverlayComponent: null,
            getRowClass: params => {
                if (params.node?.data?.virusScanError !== VirusScanErrorsEnum.None) {
                    return 'disabled-row';
                }
            },
            getContextMenuItems: params => {
                const canRecover = (this.documentsUserAuth.isAdmin || (this.documentsUserAuth.userKey === params?.node?.data?.lastModifiedByUser?.userKey && params?.node?.data?.virusScanError === VirusScanErrorsEnum.None));
                if (params.node?.data) {
                    return [
                        {
                            name: 'Recover File',
                            action: () => {
                                this.documentToRecover = params.node.data;
                                this.confirmActionType = ConfirmActionTypesEnum.Recover;
                                this.confirmActionEntities = [`${params.node.data.name}.${params.node.data.file.extension}`];
                                this.showConfirmModal = true;
                            },
                            icon: `<i class='fas fa-trash-restore'></i>`,
                            disabled: !canRecover
                        },
                    ];
                }
            }
        };
        this.searchModel = <DocumentSearchModel>{
            startDate: DateHelper.subtractDate(new Date(), 7),
            endDate: DateHelper.addDate(new Date(), 1),
            areaTypes: [this.rootFolder.areaType],
            statuses: [DocumentStatusesEnum.Inactive],
            showDeletedFilesFromLastWeek: true,
            includeInactive: true,
            folderPath: this.rootFolder.areaType === AreaTypesEnum.Personal ? `Personal/${this.documentsUserAuth.firstName} ${this.documentsUserAuth.lastName}` : null
        };
        super.ngOnInit(gridOptions);
    }

    onConfirmClicked(): void {
        SpinnerService.start();
        this.documentFolderService.recoverDocument(this.documentToRecover).pipe(concatMap(_ => {
            return this.refreshGridData$();
        })).subscribe(_ => {
            this.toastService.showMessageToast(`Document ${this.documentToRecover.name} recovered`);
            this.documentToRecover = null;
            this.showConfirmModal = false;
            this.folderDocumentGridService.refreshDocuments();
            SpinnerService.stop();
        }, err => {
            SpinnerService.stop();
            this.toastService.showErrorToast(err);
        });
    }

    onCancelClicked(): void {
        this.showConfirmModal = false;
    }

    setDefaultSortModel() {
        this.defaultSortModel = [
            { colId: ObjectHelper.nameOfSubProperty<DocumentModel, CommonBaseAuditUserModel>('lastModifiedByUser', 'changeDateTime'), sort: 'desc' },
        ];
    }

    protected setColumnDefinitions(): ColDef[] {
        return [
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOf<DocumentModel>('name'), 'File Name', null, <AgGridColDefExtras>{
                tooltip: true,
                colDef: {
                    cellRenderer: params => {
                        if (params?.data?.file) {
                            const fileTypeClass = DocumentsHelper.getFileTypeIcon(params?.data?.file?.extension);
                            return `<span><i class="${fileTypeClass}"></i></span> ${params.value}`;
                        }
                        return null;
                    },
                    filter: AgGridHelper.textColumnFilter
                }
            }),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOfSubProperty<DocumentModel, FileModel>('file', 'extension'), 'Ext'),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOfSubProperty<DocumentModel, CommonBaseAuditUserModel>('lastModifiedByUser', 'fullName'), 'User'),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOf<DocumentModel>('documentPath'), 'Path', null, <AgGridColDefExtras>{ tooltip: true }),
            AgGridColDefHelper.getDateTimeColDef(ObjectHelper.nameOfSubProperty<DocumentModel, CommonBaseAuditUserModel>('lastModifiedByUser', 'changeDateTime'), 'Date Deleted')
        ];
    }

    protected setRowData(): Observable<DocumentModel[]> {
        return this.documentService.search(this.searchModel);
    }
}
