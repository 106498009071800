import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BaseHttpService } from 'app/nexus-core/services/base-http.service';
import { Observable } from 'rxjs';
import { IndividualRelationshipModel } from 'app/nexus-shared/domain/contacts/models/individual-relationship.model';
import { IndividualRelationshipSearchModel } from 'app/nexus-shared/domain/contacts/models/individual-relationship-search.model';

@Injectable()
export class IndividualRelationshipService {
    private apiUrl = `${environment().webAPIUrls.contacts}individual-relationships`;

    constructor(private baseHttpService: BaseHttpService) {
    }

    get(key: string): Observable<IndividualRelationshipModel> {
        return this.baseHttpService.get(`${this.apiUrl}/${key}`);
    }

    search(search: IndividualRelationshipSearchModel): Observable<IndividualRelationshipModel[]> {
        return this.baseHttpService.post(`${this.apiUrl}/search`, search);
    }

    create(model: IndividualRelationshipModel): Observable<string> {
        return this.baseHttpService.post(this.apiUrl, model);
    }

    update(model: IndividualRelationshipModel): Observable<boolean> {
        return this.baseHttpService.put(this.apiUrl, model);
    }
}
