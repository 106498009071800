import { Component, ElementRef, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { DialogPosition, MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { StringHelper } from 'app/nexus-core';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { TooltipDataModel, TooltipLocationEnum } from 'app/nexus-shared/components/controls/shared';

@Component({
    selector: 'gtn-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent extends BaseComponent implements OnInit {
    @Output() messageClick: EventEmitter<void> = new EventEmitter();

    dialogLocation: TooltipLocationEnum = TooltipLocationEnum.Above;
    isCloseVisible: boolean;
    message: string;

    private isInitialized: boolean = false;
    private rect: any;
    private readonly triggerElementRef: any;

    constructor(
        public dialogRef: MatDialogRef<TooltipComponent>,
        @Inject(MAT_DIALOG_DATA) public data: TooltipDataModel
    ) {
        super();
        if (data.trigger) {
            this.triggerElementRef = data.trigger as ElementRef;
            this.rect = this.triggerElementRef.getBoundingClientRect();
        }

        if (data.message) {
            this.message = data.message;
        }

        if (data.dialogLocation) {
            this.dialogLocation = data.dialogLocation;
        }

        if (data.isCloseVisible != null && typeof data.isCloseVisible !== 'undefined') {
            this.isCloseVisible = data.isCloseVisible;
        }
    }

    ngOnInit() {
        const matDialogConfig: MatDialogConfig = new MatDialogConfig();

        if (this.rect) {
            matDialogConfig.position = this.processPosition();
        }

        this.dialogRef.updatePosition(matDialogConfig.position);

        this.subscriptions.add(this.dialogRef.afterOpened().subscribe(() => {
            this.isInitialized = true;
        }));
    }

    onMessageClicked(): void {
        this.messageClick.emit();
        this.onCloseClicked();
    }

    onCloseClicked(): void {
        this.dialogRef.close(null);
    }

    onOutsideClicked(): void {
        if (this.isInitialized) {
            this.onCloseClicked();
        }
    }

    private calcDialogLeft(): number {
        switch (this.dialogLocation) {
            case TooltipLocationEnum.Above:
            case TooltipLocationEnum.Below:
                return this.calcRectHorizontalCenter() - (this.calcDialogWidth() / 2);
            case TooltipLocationEnum.After:
                return this.rect.right + 5;
            case TooltipLocationEnum.Before:
                return this.rect.left - 5 - this.calcDialogWidth();
            default:
                break;
        }
    }

    private calcDialogTop(): number {
        // This needs to be updated to handle multiple rows, this is a single row message.

        switch (this.dialogLocation) {
            case TooltipLocationEnum.Above:
                return this.rect.top - 65;
            case TooltipLocationEnum.Below:
                return this.rect.bottom + 10;
            case TooltipLocationEnum.After:
            case TooltipLocationEnum.Before:
                return this.calcRectVerticalCenter() - 22;
            default:
                break;
        }
    }

    private calcDialogWidth(): number {
        const messageWidth = StringHelper.measureText(this.message);
        const initialDialogWidth = this.isCloseVisible ? 75 : 49;

        return messageWidth + 4 + initialDialogWidth;
    }

    private calcRectHorizontalCenter(): number {
        const rect = this.rect;
        const rectWidth = rect.right - rect.left;

        return rect.left + (rectWidth / 2);
    }

    private calcRectVerticalCenter(): number {
        const rect = this.rect;
        const rectHeight = rect.bottom - rect.top;

        return rect.top + (rectHeight / 2);
    }

    private processPosition(): DialogPosition {
        const dialogLeft = this.calcDialogLeft(),
            dialogTop = this.calcDialogTop();

        return { left: `${dialogLeft}px`, top: `${dialogTop}px` };
    }
}
