import { Component } from '@angular/core';
import { NexusModalService } from 'app/nexus-core';
import { NexusAnimations } from 'app/nexus-shared/animations';
import { UserInputModalComponentInputsModel } from '../../models/select-modal-input.model';

@Component({
    selector: 'gtn-text-area-modal',
    templateUrl: './text-area-modal.component.html',
    styleUrls: ['./text-area-modal.component.scss'],
    animations: [
        NexusAnimations.modalFadeIn
    ]
})
export class TextAreaModalComponent extends UserInputModalComponentInputsModel {

    value: string;
    minRows = 3;

    ok: (value: string) => void;
    cancel: () => void;

    public okClick = (() => {
        if (this.ok) {
            this.ok(this.value);
        }
        this.destroy();
    }).bind(this);

    public cancelClick = (() => {
        if (this.cancel) {
            this.cancel();
        }
        this.destroy();
    });

    constructor(protected modalService: NexusModalService) {
        super();
    }

    public destroy() {
        this.modalService.destroy();
    }
}
