<div class="gtn-modal-footer">
        <button mat-flat-button *ngIf="showOk"
            (click)="ok()"
            [disabled]="!enableOkButton"
            class="ok-button mat-accent"
            id="okButton"
            type="button">
            {{okButtonText}}
        </button>
        <button mat-flat-button *ngIf="showCancel"
            (click)="cancel()"
            [disabled]="!enableCancelButton"
            class="cancel-button mat-button-default"
            id="cancelButton"
            type="button">
            {{cancelButtonText}}
        </button>
</div>
