<gtn-modal class="modal-small" okButtonText="Save"
    [headerText]="value?.individualRelationshipKey ? 'Edit Relationship' : 'Add Relationship'"
    [validationModels]="validationModels"
    [disableOkButton]="!formGroupRef.dirty || isSaving"
    [formGroupRef]="formGroupRef"
    (okClick)="onSaveClicked()"
    (cancelClick)="onCancelClicked()">
    <gtn-modal-content>
        <gtn-individual-relationship-form *ngIf="value"
            [isControllingCompany]="isControllingCompany"
            [(formGroupRef)]="formGroupRef" [(value)]="value"
            (formGroupRefChange)="onFormGroupRefChanged($event)">
        </gtn-individual-relationship-form>
    </gtn-modal-content>
</gtn-modal>
