import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseEntryComponent } from 'app/nexus-shared/components/base-component/base-entry.component';
import { IAgreementSearchModelInterface } from 'app/nexus-shared/domain/agreements/interfaces/iagreement-search-model.interface';
import { AgreementSearchModel } from 'app/nexus-shared/domain/agreements/models/agreement-search.model';
import { AgreementTypeModel } from 'app/nexus-shared/domain/agreements/models/agreement-type.model';

@Component({
    selector: 'gtn-agreements-search',
    templateUrl: './agreements-search.component.html',
    styleUrls: ['./agreements-search.component.scss']
})
export class AgreementsSearchComponent extends BaseEntryComponent<AgreementSearchModel> {
    @Input() showSearch: boolean;
    @Input() agreementTypes: AgreementTypeModel[];
    @Output() searchClick: EventEmitter<IAgreementSearchModelInterface> = new EventEmitter();
    @Output() showSearchChange: EventEmitter<boolean> = new EventEmitter();
    @Output() valueChange: EventEmitter<IAgreementSearchModelInterface> = new EventEmitter();

    onSearchClicked(searchRequest: IAgreementSearchModelInterface) {
        this.searchClick.emit(searchRequest);
    }

    onToggleVisibilityClicked() {
        this.showSearchChange.emit(this.showSearch);
    }

    onValueChanged(value: IAgreementSearchModelInterface) {
        this.valueChange.emit(value);
    }
}
