import { Component, Input } from '@angular/core';
import { ValidationModel } from 'app/nexus-shared/models/validation.model';

@Component({
    selector: 'gtn-validation-error-section',
    templateUrl: './validation-error-section.component.html',
    styleUrls: ['./validation-error-section.component.scss']
})
export class ValidationErrorSectionComponent {
    @Input() validationModels: ValidationModel[];
}
