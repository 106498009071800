import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseEntryComponent } from 'app/nexus-shared/components/base-component/base-entry.component';
import { IndividualToCompanyAccessCompositeModel } from 'app/nexus-shared/domain/contacts/models/individual-to-company-access-composite.model';
import { IndividualService } from 'app/nexus-core/services/domain/contacts/individual.service';
import { AuthenticatedUserService } from 'app/nexus-core/services/authenticated-user.service';
import { ApplicationsEnum } from 'app/nexus-shared/enums';
import { CompanyModel, IndividualModel } from 'app/nexus-shared/domain/contacts/models';
import { CardModesEnum } from 'app/nexus-shared/enums/card-modes.enum';

@Component({
    selector: 'gtn-individual-company-access-composite-modal',
    templateUrl: './individual-company-access-composite-modal.component.html',
    styleUrls: ['./individual-company-access-composite-modal.component.scss']
})
export class IndividualCompanyAccessCompositeModalComponent extends BaseEntryComponent<IndividualToCompanyAccessCompositeModel> implements OnInit {
    @Input() companyKey: string = null;
    @Input() applicationSource: ApplicationsEnum;
    @Input() isControllingCompany: boolean = false;

    @Output() saveSuccess: EventEmitter<IndividualToCompanyAccessCompositeModel> = new EventEmitter();
    @Output() cancelClick: EventEmitter<void> = new EventEmitter();

    cardMode: CardModesEnum = CardModesEnum.hidden;

    constructor(
        public authenticatedUserService: AuthenticatedUserService,
        private individualService: IndividualService
    ) {
        super();
    }

    ngOnInit(): void {
    }

    onSaveClicked(): void {
        this.value.individualToCompanyAccess.company = <CompanyModel>{ companyKey: this.companyKey };
        this.value.individualToCompanyAccess.individual = <IndividualModel>{ individualKey: this.value.individual.individualKey };
        if (this.validateEntry()) {
            if (this.value.individual.individualKey) {
                this.individualService.updateIndividualAccessComposite(this.value).subscribe(response => {
                    this.value.individualToCompanyAccess.individualToCompanyAccessKey = response.individualToCompanyAccessKey;
                    this.value.individual.individualKey = response.individualKey;
                    this.saveSuccess.emit(this.value);
                    this.onSaveSuccess();
                }, error => {
                    this.onSaveFailure(error);
                });
            } else {
                this.value.individual.applicationSourceType = this.applicationSource;
                this.individualService.createIndividualAccessComposite(this.value).subscribe(response => {
                    this.value.individualToCompanyAccess.individualToCompanyAccessKey = response.individualToCompanyAccessKey;
                    this.value.individual.individualKey = response.individualKey;
                    this.saveSuccess.emit(this.value);
                    this.onSaveSuccess();
                }, error => {
                    this.onSaveFailure(error);
                });
            }
        }
    }

    onCancelClicked(): void {
        this.cancelClick.emit();
    }

}
