<mat-form-field [appearance]="appearance"
    [ngClass]="{'gtn-disabled' : disabled, 'gtn-read-only' : readonly, 'show-error': !formControl?.valid && canShowValidation}">
    <mat-label>{{label}}</mat-label>
    <input
        gtnDisableAutoFill
        matInput
        #matInput
        [type]="type"
        [value]="timeString"
        [min]="min"
        [max]="max"
        [step]="step"
        (change)="onTimeUpdated($event.target['value'])">

    <div class="error-message" *ngIf="!formControl?.valid && canShowValidation">
        <span *ngFor="let msg of getErrorMessage()">{{msg}}</span>
    </div>
</mat-form-field>
