import { EventEmitter, Injectable } from '@angular/core';
import { SpinnerMessageModel } from 'app/nexus-core/components/spinner/spinner-message.model';

@Injectable()
export class SpinnerService {
    static onStateChanged: EventEmitter<any> = new EventEmitter<any>();
    static onCounterChange: EventEmitter<string> = new EventEmitter<string>(); // Used to emit the message.text to the spinner component.

    static spinnerCounter = 0;

    static delayTime = 300;

    static spinnerMessages: SpinnerMessageModel[] = [];

    private static isGlobalActive = false;
    private static isResolverSpinnerActive = false;

    private static spinnerTimeout = null;

    static isGlobalSpinnerActive() {
        return this.isGlobalActive;
    }

    static start(message: string = '', isTransparent: boolean = false, delay: number = 300) {
        const spinnerMessage: SpinnerMessageModel = new SpinnerMessageModel(message, this.spinnerCounter);
        this.spinnerMessages.push(spinnerMessage);

        if (!this.spinnerCounter) {
            this.spinnerTimeout = setTimeout(() => {
                if (this.spinnerCounter) {
                    this.isGlobalActive = true;
                    this.onStateChanged.emit({
                        isTransparent: isTransparent
                    });
                }
            }, delay);
        }
        this.spinnerCounter++;
        this.onCounterChange.emit(spinnerMessage.text);
    }

    static stop(callBack?: Function) {
        if (!(this.isResolverSpinnerActive && this.spinnerCounter === 1)) {
            if (this.spinnerTimeout) {
                clearTimeout(this.spinnerTimeout);
            }

            if (this.isGlobalActive) {
                setTimeout(() => {
                    this.stopSpinner();
                    if (callBack) {
                        callBack();
                    }
                }, this.delayTime);
            } else {
                this.stopSpinner();
                if (callBack) {
                    callBack();
                }
            }
        }
    }

    static startResolverSpinner() {
        this.spinnerCounter = 1;
        this.isGlobalActive = true;
        this.isResolverSpinnerActive = true;
        this.onStateChanged.emit();
    }

    static stopResolverSpinner() {
        this.isResolverSpinnerActive = false;
        this.stop();
    }

    private static stopSpinner() {
        if (this.spinnerCounter === 1) {
            this.isGlobalActive = false;
            this.spinnerMessages = [];
        }
        if (this.spinnerCounter !== 0) {
            this.spinnerCounter--;
            this.spinnerMessages.pop();
            if (this.spinnerMessages[this.spinnerCounter]) {
                this.onCounterChange.emit(this.spinnerMessages[this.spinnerCounter].text);
            }
        }
        this.onStateChanged.emit();
    }
}
