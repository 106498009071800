export class CommonBaseAuditUserModel {
    userKey: string;
    changeDateTime: Date;
    firstName: string;
    lastName: string;
    fullName: string; 

    constructor(model: CommonBaseAuditUserModel = null){
        this.userKey = model?.userKey ?? null;
        this.changeDateTime = model?.changeDateTime ?? null;
        this.firstName = model?.firstName ?? null;
        this.lastName = model?.lastName ?? null;
        this.fullName = model?.fullName ?? null;
    }
}