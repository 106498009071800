import { BaseService } from 'app/nexus-core/services/base-service.directive';
import { Injectable } from '@angular/core';
import { LocalStorageHelper } from 'app/nexus-core/helpers';
import { LocalStorageNameConstants } from 'app/nexus-shared';

@Injectable({
    providedIn: 'root'
})
export class DeviceService extends BaseService {
    isValidDevice(): boolean {
        return (this.isValidBrowser() || (!this.isValidBrowser() && this.isAcknowledged())) && !this.isMobileDevice();
    }

    isValidBrowser(): boolean {
        return this.isChrome() || this.isFirefox() || this.isEdge();
    }

    isMobileDevice(): boolean {
        return window.screen.width <= 1024;
    }

    isAcknowledged(): boolean {
        const value = LocalStorageHelper.get(LocalStorageNameConstants.browserCheckAcknowledged);

        console.log(value);

        if (value) {
            return JSON.parse(value);
        }

        return false;
    }

    setAcknowledged(): void {
        LocalStorageHelper.set(LocalStorageNameConstants.browserCheckAcknowledged, JSON.stringify(true));
    }

    private isChrome(): boolean {
        return /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    }

    private isFirefox(): boolean {
        return /firefox/i.test(navigator.userAgent);
    }

    private isEdge(): boolean {
        return /Edge/.test(navigator.userAgent);
    }
}
