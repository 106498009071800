import { RoleTypesEnum } from 'app/nexus-shared/domain/users/enums/role-types.enum';

export class UserSearchModel {
    searchTerm?: string;
    roles?: RoleTypesEnum[];
    hasGtnRole?: boolean;

    constructor(model: UserSearchModel = null) {
        this.searchTerm = model?.searchTerm ?? null;
        this.roles = model?.roles ?? null;
        this.hasGtnRole = model?.hasGtnRole ?? null;
    }
}
