import { AuthorizationConstants } from 'app/nexus-shared/constants/authorization.constants';
import { ApplicationsEnum } from '../enums/applications.enum';

export class BaseRouteApplicationConfigurationModel {
    // UNIQUE IDENTIFIER
    coreMenuId?: number; //USED BY CORE
    applicationMenuId?: number; //IDS USED BY CONSUMER APPS

    // ROUTE DATA
    applicationId?: ApplicationsEnum;
    authorization?: AuthorizationConstants[] = [];
    learnMoreUrl?: string;

    // MENU DATA
    menu?: {
        isHidden?: boolean;
        img?: {
            header?: string;
            fileName: string;
        };
        isExpanded?: boolean;
        title: string;
        iconClass?: string;
        cssClass?: string;
        order?: number;
    };

    routing?: {
        fullUrl?: string;
        routerModulePath?: string;
        relativeUrl?: string;
        routerLink?: string[];
        navigationRoute?: Function;
        useExact?: boolean;
    };

    //CHILD ROUTES
    childRoutes?: BaseRouteApplicationConfigurationModel[];
}
