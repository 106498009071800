import { Component, forwardRef, Input, OnChanges, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SimpleChangesTyped } from 'app/nexus-shared/models/simple-changes-typed.type';
import { BaseLocationSelectComponent } from 'app/nexus-shared/domain/locations/components/select/base-location-select.component';
import { LocationService } from 'app/nexus-core/services/location.service';

@Component({
    selector: 'gtn-county-select',
    templateUrl: '../../../../components/controls/components/dropdown/dropdown.component.html',
    styleUrls: ['../../../../components/controls/components/dropdown/dropdown.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CountySelectComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => CountySelectComponent),
            multi: true
        }
    ]
})
export class CountySelectComponent extends BaseLocationSelectComponent implements ControlValueAccessor, OnChanges, OnInit {
    @Input() stateId: number = null;
    @Input() label = 'County';

    constructor(private locationService: LocationService) {
        super();
    }

    ngOnInit() {
        // overriding the base onInit so initOptions() doesn't get called when it's not supposed to
    }

    ngOnChanges(changes: SimpleChangesTyped<this>): void {
        if (changes.stateId && this.stateId) {
            this.initOptions();
        }
    }

     dataFetch = () => this.locationService.listCounties(this.stateId);
}
