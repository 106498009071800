import { BaseFormModel } from 'app/nexus-shared/models/base-form.model';
import { PhoneNumberModel } from 'app/nexus-shared/models/phone-number.model';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { CompanyModel, IndividualModel, IndividualToCompanyAccessModel } from 'app/nexus-shared/domain/contacts/models';
import { IndividualToCompanyAccessCompositeModel } from 'app/nexus-shared/domain/contacts/models/individual-to-company-access-composite.model';
import { AddressModel } from 'app/nexus-shared/domain/contacts/models/address.model';
import { LocationModel } from 'app/nexus-shared';
import { ContactIntegrationModel } from 'app/nexus-shared/domain/contacts/models/contact-integration.model';

export class ContactsFormHelper {
    static getAddressFormConfiguration(rootPropertyName: string, baseFormModel = new BaseFormModel(), addBlank: boolean = true): BaseFormModel {
        baseFormModel.formArrayConfiguration[rootPropertyName] = {
            template: new AddressModel(),
            addBlank: addBlank,
        };

        return baseFormModel;
    }

    static getPhoneNumberFormConfiguration(rootPropertyName: string, baseFormModel = new BaseFormModel(), addBlank: boolean = true): BaseFormModel {
        baseFormModel.formArrayConfiguration[rootPropertyName] = {
            template: new PhoneNumberModel(),
            addBlank: addBlank,
        };

        return baseFormModel;
    }

    static getCostCentersFormConfiguration(rootPropertyName: string, baseFormModel = new BaseFormModel(), addBlank: boolean = true): BaseFormModel {
        baseFormModel.formArrayConfiguration[rootPropertyName] = {
            template: new PhoneNumberModel(),
            addBlank: addBlank,
        };

        return baseFormModel;
    }

    static getServiceCountriesFormConfiguration(rootPropertyName: string, baseFormModel = new BaseFormModel(), addBlank: boolean = true): BaseFormModel {
        baseFormModel.formArrayConfiguration[rootPropertyName] = {
            template: new LocationModel(),
            addBlank: addBlank,
        };

        return baseFormModel;
    }

    static getContactIntegrationsFormConfiguration(rootPropertyName: string, baseFormModel = new BaseFormModel(), addBlank: boolean = true): BaseFormModel {
        baseFormModel.formArrayConfiguration[rootPropertyName] = {
            template: new ContactIntegrationModel(),
            addBlank: addBlank,
        };

        return baseFormModel;
    }

    static getCompanyFormConfiguration(): BaseFormModel {
        const formConfiguration = new BaseFormModel();
        ContactsFormHelper.getAddressFormConfiguration(ObjectHelper.nameOf<CompanyModel>('addresses'), formConfiguration, false);
        ContactsFormHelper.getPhoneNumberFormConfiguration(ObjectHelper.nameOf<CompanyModel>('phoneNumbers'), formConfiguration, false);
        ContactsFormHelper.getCostCentersFormConfiguration(ObjectHelper.nameOf<CompanyModel>('costCenters'), formConfiguration, false);
        ContactsFormHelper.getContactIntegrationsFormConfiguration(ObjectHelper.nameOf<CompanyModel>('contactIntegrations'), formConfiguration, false);
        return formConfiguration;
    }

    static getIndividualFormConfiguration(): BaseFormModel {
        const formConfiguration = new BaseFormModel();
        ContactsFormHelper.getPhoneNumberFormConfiguration(ObjectHelper.nameOf<IndividualModel>('phoneNumbers'), formConfiguration, false);
        ContactsFormHelper.getAddressFormConfiguration(ObjectHelper.nameOf<IndividualModel>('addresses'), formConfiguration, false);
        ContactsFormHelper.getContactIntegrationsFormConfiguration(ObjectHelper.nameOf<IndividualModel>('contactIntegrations'), formConfiguration, false);

        return formConfiguration;

    }

    static getIndividualToCompanyAccessCompositeFormConfiguration(): BaseFormModel {
        const formConfiguration = new BaseFormModel();
        formConfiguration.formConfiguration[ObjectHelper.nameOf<IndividualToCompanyAccessCompositeModel>('individual')] = ContactsFormHelper.getIndividualFormConfiguration();
        formConfiguration.formConfiguration[ObjectHelper.nameOf<IndividualToCompanyAccessCompositeModel>('individualToCompanyAccess')] = ContactsFormHelper.getIndividualToCompanyAccessCompositeFormConfiguration();
        return formConfiguration;
    }

    static getIndividualToCompanyAccessFormConfiguration(): BaseFormModel {
        const formConfig = new BaseFormModel();
        formConfig.formConfiguration[ObjectHelper.nameOf<IndividualToCompanyAccessModel>('primaryPhoneNumber')] = new BaseFormModel();
        formConfig.formConfiguration[ObjectHelper.nameOf<IndividualToCompanyAccessModel>('secondaryPhoneNumber')] = new BaseFormModel();
        return formConfig;
    }
}
