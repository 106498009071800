import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseEntryComponent } from 'app/nexus-shared/components/base-component/base-entry.component';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { QuestionnaireTemplateModel } from 'app/nexus-shared/domain/questionnaires/models/questionnaire-template.model';
import { QuestionnaireTypesEnum } from 'app/nexus-shared/domain/questionnaires/enums/questionnaire-types.enum';
import { EnumHelper } from 'app/nexus-core';
import { SelectListInterface } from 'app/nexus-shared/interfaces';
import { QuestionnaireCoordinatorService } from 'app/nexus-core/services/domain/questionnaires/questionnaire-coordinator.service';
import { IndividualGtnOrganizerModel } from 'app/nexus-shared/domain/questionnaires/models/individual-gtn-organizer.model';
import { IndividualSupplementalOrganizerModel } from 'app/nexus-shared/domain/questionnaires/models/individual-supplemental-organizer.model';
import { IndividualCchOrganizerModel } from 'app/nexus-shared/domain/questionnaires/models/individual-cch-organizer.model';
import { IndividualInterface } from 'app/nexus-shared/interfaces/individual.interface';
import { LocationHelper } from 'app/nexus-core/helpers/location.helper';
import { QuestionnaireLocationModel } from 'app/nexus-shared/domain/questionnaires/models/questionnaire-location.model';
import { IndividualCchOrganizerService } from 'app/nexus-core/services/domain/questionnaires/individual-cch-organizer.service';
import { IndividualCchOrganizerSearchModel } from 'app/nexus-shared/domain/questionnaires/models/individual-cch-organizer-search.model';

@Component({
    selector: 'gtn-assign-questionnaire-modal',
    templateUrl: './assign-questionnaire-modal.component.html',
    styleUrls: ['./assign-questionnaire-modal.component.scss']
})
export class AssignQuestionnaireModalComponent extends BaseEntryComponent<any> implements OnInit {
    @Input() questionnaireTemplates: QuestionnaireTemplateModel[];
    @Input() assigneeIndividual: IndividualInterface;
    @Input() gateKeeper: IndividualInterface;
    @Input() isUpdate: boolean = false;
    @Input() disableIndividualSearch: boolean = false;
    @Output() cancelClick: EventEmitter<void> = new EventEmitter<void>();
    @Output() saveSuccess: EventEmitter<void> = new EventEmitter<void>();

    questionnaireTypes: SelectListInterface[];
    questionnaireType: QuestionnaireTypesEnum;
    questionnaireTypesEnum = QuestionnaireTypesEnum;
    isCheckedDupes: boolean = false;
    dupeIndividualCchOrganizers: IndividualCchOrganizerModel[];

    private cchLocation: QuestionnaireLocationModel = {
        name: 'United States',
        id: LocationHelper.countryIds.unitedStatesLocationId
    };

    constructor(private questionnaireCoordinatorService: QuestionnaireCoordinatorService, private individualCchOrganizerService: IndividualCchOrganizerService) {
        super();
    }

    ngOnInit() {
        this.questionnaireTypes = EnumHelper.convertToSelectList(QuestionnaireTypesEnum, false, false);
        if (this.value?.questionnaire?.questionnaireKey) {
            this.questionnaireType = JSON.parse(JSON.stringify(this.value.questionnaire.type));
        }
    }

    onCancelClicked(): void {
        this.cancelClick.emit();
    }

    onQuestionnaireTypeChanged(type: QuestionnaireTypesEnum) {
        if (type === QuestionnaireTypesEnum.GTNOrganizer) {
            this.value = new IndividualGtnOrganizerModel();
            this.value.questionnaire.type = QuestionnaireTypesEnum.GTNOrganizer;
            this.value.gateKeeper = this.gateKeeper ?? null;
        } else if (type === QuestionnaireTypesEnum.SupplementalQuestionnaire) {
            this.value = new IndividualSupplementalOrganizerModel();
            this.value.questionnaire.type = QuestionnaireTypesEnum.SupplementalQuestionnaire;
        } else if (type === QuestionnaireTypesEnum.CCHOrganizer) {
            this.value = new IndividualCchOrganizerModel();
            this.value.questionnaire.location = this.cchLocation;
            this.value.questionnaire.type = QuestionnaireTypesEnum.CCHOrganizer;
            this.value.questionnaire.title = EnumHelper.getDisplayName(QuestionnaireTypesEnum, type);
            this.value.questionnaire.year = new Date().getFullYear() - 1;
            this.value.gateKeeper = this.gateKeeper ?? null;
        }
        this.value.questionnaire.individual = this.assigneeIndividual ?? null;

    }

    onSaveClicked(): void {
        this.isSaving = true;
        if (this.value.questionnaire.type === QuestionnaireTypesEnum.CCHOrganizer && !this.isCheckedDupes) {
            this.saveCchQuestionnaire();
        } else {
            this.saveQuestionnaire().pipe(finalize(() => {
                this.isSaving = false;
                this.isCheckedDupes = false;
                this.dupeIndividualCchOrganizers = [];
            })).subscribe(_ => {
                this.onSaveSuccess();
                this.saveSuccess.emit();
            }, err => {
                this.onSaveFailure(err);
            });
        }
    }


    private saveQuestionnaire(): Observable<string | boolean> {
        if (this.value?.questionnaire?.questionnaireKey) {
            return this.questionnaireCoordinatorService.update(this.value.questionnaire.type, this.value);
        } else {
            return this.questionnaireCoordinatorService.create(this.value.questionnaire.type, this.value);
        }
    }

    private saveCchQuestionnaire(): void {
        this.individualCchOrganizerService.search(<IndividualCchOrganizerSearchModel>{ userName: this.value.userName }).subscribe(res => {
            this.isCheckedDupes = true;
            res = res.filter(x => x.individualCchOrganizerKey !== this.value.individualCchOrganizerKey);
            if (res?.length) {
                this.isSaving = false;
                this.dupeIndividualCchOrganizers = res;
            } else {
                this.onSaveClicked();
            }
        });
    }
}
