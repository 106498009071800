<div @modalFadeIn class="modal-wrapper">
    <form [formGroup]="formGroup">
        <gtn-modal-header [closeClick]="destroy" [mainHeaderText]="mainHeaderText" [showCloseButton]="showCloseButton"
            [subHeaderText]="subHeaderText">
        </gtn-modal-header>
        <div class="modal-content">
            <div>
                {{message}}
            </div>
            <div *ngIf="showHideConfirmModalCheckbox" class="mt-5">
                <gtn-checkbox label="Do not show again" [value]="hideConfirmationModalSelected"
                    (change)="onHideConfirmModal($event['value'])"></gtn-checkbox>
            </div>
        </div>
        <input #interactionInput *ngIf="interactionInfo" class="interaction-input" formControlName="interactionInfo"
            id="interactionInput" placeholder="{{interactionInfo}}*">
        <gtn-modal-footer [cancel]='cancelClick' [enableOkButton]="formGroup.valid" [ok]='okClick'
            [showCancel]="cancel ? true : false" [showOk]="ok ? true : false" [okButtonText]="okButtonText"
            [cancelButtonText]="cancelButtonText">
        </gtn-modal-footer>
    </form>
</div>
