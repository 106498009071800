import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'someDefaultValue'
})
export class SomeDefaultValuePipe implements PipeTransform {

    transform(value: any, defaultValue: any, output: string): any {
        if (value === defaultValue) {
            return output;
        } else {
            return value;
        }

    }

}
