import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { DocumentFolderMoveViewModel } from 'app/nexus-shared/domain/documents/models/document-view-models/document-folder-move-view.model';
import { DocumentsHelper } from 'app/nexus-core/helpers/documents.helper';

@Component({
    selector: 'gtn-documents-move-confirm-modal',
    templateUrl: './documents-move-confirm-modal.component.html',
    styleUrls: ['./documents-move-confirm-modal.component.scss']
})
export class DocumentsMoveConfirmModalComponent extends BaseComponent {
    @Input() documentFolderMove: DocumentFolderMoveViewModel;

    @Output() confirmClick: EventEmitter<void> = new EventEmitter();
    @Output() cancelClick: EventEmitter<void> = new EventEmitter();

    constructor() {
        super();
    }

    getFileTypeIcon(ext: string): string {
      return DocumentsHelper.getFileTypeIcon(ext);
    };

    onOkClicked(): void {
        this.confirmClick.emit();
    }

    onCancelClicked(): void {
        this.cancelClick.emit();
    }

}
