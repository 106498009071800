import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseReadonlyEditComponent } from 'app/nexus-shared/components/base-component/base-readonly-edit.component';
import { AuthenticatedUserService } from 'app/nexus-core/services/authenticated-user.service';
import { AuthorizationConstants } from 'app/nexus-shared/constants/authorization.constants';
import { forkJoin, Observable, of } from 'rxjs';
import { UserService } from 'app/nexus-core/services/domain/users/user.service';
import { map } from 'rxjs/operators';
import { UserRoleService } from 'app/nexus-core/services/domain/users/user-role.service';
import { UserViewModel } from 'app/nexus-shared/domain/users/models/user-view.model';
import { ViewModesEnum } from 'app/nexus-shared/enums/view-modes.enum';
import { UserStatusesEnum } from 'app/nexus-shared/enums/user-statuses.enum';
import { ToastService } from 'app/nexus-core';

@Component({
    selector: 'gtn-user-readonly-edit',
    templateUrl: './user-readonly-edit.component.html',
    styleUrls: ['./user-readonly-edit.component.scss']
})
export class UserReadonlyEditComponent extends BaseReadonlyEditComponent<UserViewModel, boolean> implements OnInit {
    @Input() userKey: string = null;
    @Output() inviteFailure: EventEmitter<Error> = new EventEmitter<Error>();
    canEdit: boolean = false;
    isInvited: boolean = false;
    isInviting: boolean = false;
    isReset: boolean = false;
    isResetting: boolean = false;
    userStatusesEnum = UserStatusesEnum;

    constructor(
        private authenticatedUserService: AuthenticatedUserService,
        private userService: UserService,
        private toastService: ToastService,
        private userRoleService: UserRoleService
    ) {
        super();
    }

    ngOnInit(): void {
        //THIS IS ALL HOSED UNLESS YOU ARE AN GTN ADMIN - TICKET #8721 TO ADDRESS THE CLUSTER F
        this.canEdit = this.authenticatedUserService.hasAuthorization(AuthorizationConstants.authUserAdmin) || this.authenticatedUserService.hasAuthorization(AuthorizationConstants.authUserRoleAdmin);
        super.ngOnInit();
    }

    protected getValue(value: UserViewModel): Observable<UserViewModel> {
        if (value) {
            const user = new UserViewModel(value);
            user.userRoleDefinitions = value.userRoleDefinitions;
            return of(user);
        }

        return forkJoin({
            user: this.userService.getByKey(this.userKey),
            userRoles: this.userRoleService.listByUserKey(this.userKey)
        }).pipe(map(res => {
            let user: UserViewModel;
            if (res.user) {
                user = new UserViewModel(res.user);
            }
            if (res.userRoles) {
                user.userRoleDefinitions = res.userRoles;
                user.policies = res.userRoles.map(x => x.roleDefinitionId);
            }
            return user;
        }));
    }

    protected saveValue(): Observable<boolean> {
        const policies = this.value.userRoleDefinitions.map(x => x.roleDefinitionId);
        if (this.viewMode === ViewModesEnum.General) {
            return this.userService.update(this.value);
        } else if (this.viewMode === ViewModesEnum.Roles) {
            return this.userRoleService.update(this.value.userKey, policies);
        }
    }

    onInviteClicked() {
        this.isInviting = true;
        this.userService.invite(this.value.userKey).subscribe(
            _ => {
                this.isInviting = false;
                this.isInvited = true;
                this.toastService.showMessageToast('Invite was sent successfully.');
                this.readonly = true;
            },
            err => {
                this.onSaveFailure(err);

                this.isInviting = false;
                this.isInvited = false;
            });
    }

    onPasswordResetClicked() {
        this.isResetting = true;
        this.userService.resetPassword(this.value.userKey).subscribe(
            _ => {
                this.isResetting = false;
                this.isReset = true;
                this.toastService.showMessageToast('Password reset was sent successfully.');
            },
            err => {
                this.onSaveFailure(err);

                this.isResetting = false;
                this.isReset = false;
            });
    }
}
