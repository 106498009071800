import { AgreementStatusEnum } from '../enums/agreement-status.enum';
import { IAgreementSearchModelInterface } from '../interfaces/iagreement-search-model.interface';

export class AgreementSearchModel implements IAgreementSearchModelInterface {
    agreementKeys?: string[];
    agreementTypeKeys?: string[];
    companyKeys?: string[];
    endDate?: Date | string;
    expirationEndDate?: Date | string;
    expirationStartDate?: Date | string;
    isRequestIndependentsOnly?: boolean;
    individualKeys?: string[];
    startDate?: Date | string;
    statuses?: AgreementStatusEnum[];

    constructor(model?: AgreementSearchModel) {
        this.agreementKeys = model?.agreementKeys ?? null;
        this.agreementTypeKeys = model?.agreementTypeKeys ?? null;
        this.companyKeys = model?.companyKeys ?? null;
        this.endDate = model?.endDate ?? null;
        this.expirationEndDate = model?.expirationEndDate ?? null;
        this.expirationStartDate = model?.expirationStartDate ?? null;
        this.isRequestIndependentsOnly = model?.isRequestIndependentsOnly ?? null;
        this.individualKeys = model?.individualKeys ?? null;
        this.startDate = model?.startDate ?? null;
        this.statuses = model?.statuses ?? null;
    }
}
