import { BaseRouteApplicationConfigurationModel } from 'app/nexus-shared/models/base-route-application-configuration.model';

export class BaseRouteApplicationConfigurationHelper {
    static getMenuItemByApplicationMenuId(menuItems: BaseRouteApplicationConfigurationModel[], applicationMenuId: number): BaseRouteApplicationConfigurationModel {
        // TODO - USE RECURSION TO TRAVERSE THE ENTIRE OBJECT GRAPH (USING RECURSION FOR NOW WE ARE ONLY 2 DEEP)
        const topLevelMenuItem = menuItems.find(m => m.applicationMenuId === applicationMenuId);

        if (topLevelMenuItem) {
            return topLevelMenuItem;
        }

        for (let i = 0; i < menuItems.length; i++) {
            const menuItem = menuItems[i];
            if (menuItem.childRoutes) {
                const childMenuItem = menuItem.childRoutes.find(t => t.applicationMenuId === applicationMenuId);

                if (childMenuItem) {
                    return childMenuItem;
                }
            }
        }
    }

    static expandMenuOrParentMenuItem(menuItems: BaseRouteApplicationConfigurationModel[], applicationMenuId: number): void {
        const topLevelMenuItem = menuItems.find(m => m.applicationMenuId === applicationMenuId);

        if (topLevelMenuItem) {
            topLevelMenuItem.menu.isExpanded = true;
        }

        for (let i = 0; i < menuItems.length; i++) {
            const menuItem = menuItems[i];
            if (menuItem.childRoutes) {
                const childMenuItem = menuItem.childRoutes.find(t => t.applicationMenuId === applicationMenuId);

                if (childMenuItem) {
                    menuItems[i].menu.isExpanded = true;
                }
            }
        }
    }

    static setRouterLinkFromNavigationRoute(configuration: BaseRouteApplicationConfigurationModel[] | BaseRouteApplicationConfigurationModel, navigationRouteParams: string[]): void {
        if (configuration instanceof Array) {
            configuration.forEach((configurationModel: BaseRouteApplicationConfigurationModel) => {
                if (configurationModel?.routing?.navigationRoute) {
                    configurationModel.routing.routerLink = ['/' + configurationModel.routing.navigationRoute(...navigationRouteParams)];
                }

                if (configurationModel.childRoutes?.length) {
                    BaseRouteApplicationConfigurationHelper.setRouterLinkFromNavigationRoute(configurationModel.childRoutes, navigationRouteParams);
                }
            });
        } else if (configuration?.routing?.navigationRoute) {
            configuration.routing.routerLink = ['/' + configuration.routing.navigationRoute(...navigationRouteParams)];
        }
    }

    static removeMenuItemByApplicationMenuId(menuItems: BaseRouteApplicationConfigurationModel[], applicationMenuId: number): void {
        const foundMenuItem = BaseRouteApplicationConfigurationHelper.getMenuItemByApplicationMenuId(menuItems, applicationMenuId);

        if (foundMenuItem) {
            menuItems.splice(menuItems.indexOf(foundMenuItem), 1);
        }
    }

    static hideShowMenuItemByApplicationMenuId(menuItems: BaseRouteApplicationConfigurationModel[], applicationMenuId: number, hide: boolean): void {
        const foundMenuItem = BaseRouteApplicationConfigurationHelper.getMenuItemByApplicationMenuId(menuItems, applicationMenuId);

        if (foundMenuItem != null) {
            menuItems[menuItems.indexOf(foundMenuItem)].menu.isHidden = hide;
        }
    }

    static sortByMenuItems(menuItems: BaseRouteApplicationConfigurationModel[]): void {
        if (menuItems && menuItems.length) {
            menuItems.sort(this.sortMenuFunc);

            const menuItemsChildRoutes = menuItems.filter(m => m.childRoutes?.length).map(m => m.childRoutes);

            menuItemsChildRoutes.forEach((childRoutes: BaseRouteApplicationConfigurationModel[]) => {
                this.sortByMenuItems(childRoutes);
            });
        }
    }

    private static sortMenuFunc(a: BaseRouteApplicationConfigurationModel, b: BaseRouteApplicationConfigurationModel) {
        if (a.menu?.order < b.menu?.order) {
            return -1;
        }

        if (a.menu?.order > b.menu?.order) {
            return 1;
        }

        return 0;
    }
}
