export class FolderSearchModel {
    folderKeys: string[];
    folderTags: string[];
    parentFolderKey: string;
    folderKey: string;
    parentFolderPath: string;
    searchTerm: string;
    companyKey: string;
    individualKey: string;
    requestingIndividualKey: string;
    includeChildren: boolean;
    includeParent: boolean;
    includeIndividual: boolean;
    includeCompany: boolean;

    constructor(model: FolderSearchModel = null) {
        this.folderKeys = model?.folderKeys ?? null;
        this.parentFolderKey = model?.parentFolderKey ?? null;
        this.folderKey = model?.folderKey ?? null;
        this.parentFolderPath = model?.parentFolderPath ?? null;
        this.searchTerm = model?.searchTerm ?? null;
        this.companyKey = model?.companyKey ?? null;
        this.individualKey = model?.individualKey ?? null;
        this.requestingIndividualKey = model?.requestingIndividualKey ?? null;
        this.includeChildren = model?.includeChildren ?? null;
        this.includeParent = model?.includeParent ?? null;
        this.includeIndividual = model?.includeIndividual ?? null;
        this.includeCompany = model?.includeCompany ?? null;
    }
}
