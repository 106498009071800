import { Component } from '@angular/core';
import { RoutingService } from 'app/nexus-routing/services/routing.service';

@Component({
    selector: 'gtn-back-button',
    templateUrl: './back-button.component.html'
})
export class BackButtonComponent {

    constructor(public previousRouteService: RoutingService) {
    }

    onBackClicked() {
        this.previousRouteService.navigateToPreviousUrl();
    }
}
