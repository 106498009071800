import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SearchResultIconEnum, SearchResultModel } from 'app/nexus-shared/components/controls';
import { SearchHelper } from 'app/nexus-core';
import { CompanyModel } from 'app/nexus-shared/domain/contacts/models/company.model';
import { CompanyService } from 'app/nexus-core/services/domain/contacts/company.service';
import { CompanySearchModel } from 'app/nexus-shared/domain/contacts/models/company-search.model';
import { BaseSearchControlComponent } from 'app/nexus-shared/components/base-component/base-search-control.component';

@Component({
    selector: 'gtn-company-search',
    templateUrl: './company-search.component.html',
    styleUrls: ['./company-search.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CompanySearchComponent),
            multi: true
        }
    ]
})
export class CompanySearchComponent extends BaseSearchControlComponent<CompanyModel> implements ControlValueAccessor {
    @Input() companySearchModel: CompanySearchModel = new CompanySearchModel();

    constructor(
        private companyService: CompanyService
    ) {
        super();
    }

    protected onSearchTextChanged(searchText: string) {
        this.companySearchModel.searchTerm = searchText;
        this.companyService.search(this.companySearchModel).subscribe(
            response => {
                const results = response.map((company: CompanyModel) => {
                    return {
                        id: company.companyKey,
                        name: company.name,
                        subName: null,
                        type: null,
                        iconClass: SearchResultIconEnum.unknown,
                        resultObject: company,
                        rank: 0
                    } as SearchResultModel<void, CompanyModel>;
                });
                const orderedResults = SearchHelper.orderSearchResults<void, CompanyModel>(results, searchText, []);
                this.searchModel.onResultsReceived.next(orderedResults);
            },
            error => this.searchModel.onErrorReceived.next(error));
    }

    displayWith(searchResultItem: SearchResultModel<void, CompanyModel> | CompanyModel) {
        if (searchResultItem) {
            if ('resultObject' in searchResultItem) {
                return searchResultItem?.resultObject
                    ? `${searchResultItem.resultObject.name}`
                    : null;
            }
            return `${searchResultItem.name}`;
        }
        return null;
    }
}
