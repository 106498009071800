import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BaseFileViewComponent } from 'app/nexus-shared/components/controls/components/file-view/base-file-view.component';

@Component({
    selector: 'gtn-object-file-view',
    templateUrl: './object-file-view.component.html',
    styleUrls: ['./object-file-view.component.scss']
})
export class ObjectFileViewComponent extends BaseFileViewComponent implements OnInit {
    @Input() src: any;
    @Input() isBlob: boolean = false;

    constructor(private domSanitizer: DomSanitizer) {
        super();
    }

    ngOnInit(): void {
        if (this.isBlob) {
            const blob = this.src.blob.slice(0, this.src.blob.size, 'text/html');
            this.src = this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob));
        }
    }
}
