import { Component } from '@angular/core';
import { ILoadingOverlayAngularComp } from 'ag-grid-angular';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';

@Component({
    selector: 'gtn-custom-loading-overlay',
    templateUrl: 'custom-loading-overlay.component.html',
    styleUrls: ['./custom-loading-overlay.component.scss']
})
export class CustomLoadingOverlayComponent extends BaseComponent implements ILoadingOverlayAngularComp {

    private params: any;

    agInit(params): void {
        this.params = params;
    }
}
