<mat-sidenav-container *ngIf="enablePDFOutline || enableThumbnails"
    class="pdf-sidenav-container">
    <mat-sidenav class="pdf-sidenav"
        #sidenav
        mode="side"
        [opened]="true">
        <div *ngIf="enablePDFOutline"
            class="pdf-nav-item"
            [ngClass]="{'active': leftNavState === 'outline'}">
            <i class="fa-solid fa-list"
                [matTooltip]="'Outline'"
                (click)="onOutlineToggleClicked()"></i>
        </div>
        <div *ngIf="enableThumbnails"
            class="pdf-nav-item"
            [ngClass]="{'active': leftNavState === 'thumbnail'}">
            <i class="fa-sharp fa-regular fa-images"
                [matTooltip]="'Thumbnails'"
                (click)="onThumbnailToggleClicked()"></i>
        </div>
    </mat-sidenav>
    <div *ngIf="leftNavState !== 'closed'"
        class="pdf-nav-menu">
        <div *ngIf="leftNavState === 'outline'"
            class="outlines">
            <mat-tree #tree
                class="outline-tree"
                [dataSource]="outlineDataSource"
                [treeControl]="outlineTreeControl">
                <mat-tree-node *matTreeNodeDef="let outline"
                    matTreeNodeToggle>
                    <li class="mat-tree-node">
                        <a (click)="onOutlineLinkClicked(outline.linkId)">{{outline.text}}</a>
                    </li>
                </mat-tree-node>

                <mat-nested-tree-node *matTreeNodeDef="let parentOutline; when: outlineHasChild">
                    <li>
                        <div class="mat-tree-node">
                            <button class="outline-toggle"
                                mat-icon-button
                                matTreeNodeToggle
                                [attr.aria-label]="'toggle ' + parentOutline.linkId">
                                <mat-icon class="mat-icon-rtl-mirror">
                                    {{outlineTreeControl.isExpanded(parentOutline) ? 'expand_more' :
                                    'chevron_right'}}
                                </mat-icon>
                            </button>
                            <a (click)="onOutlineLinkClicked(parentOutline.linkId)">{{parentOutline.text}}</a>
                        </div>
                        <ul [class.outline-tree-invisible]="!outlineTreeControl.isExpanded(parentOutline)">
                            <ng-container matTreeNodeOutlet></ng-container>
                        </ul>
                    </li>
                </mat-nested-tree-node>
            </mat-tree>
        </div>
        <div *ngIf="leftNavState === 'thumbnail'"
            class="thumbnail-pages">
            <div class="thumbnail-page"
                [ngClass]="{'selected-thumbnail': item.pageNumber === selectedThumbnailPageNumber}"
                *ngFor="let item of thumbnails"
                id="page{{item.pageNumber}}"
                (click)="onThumbnailClicked(item.pageNumber)">
                <img src="{{item.url}}">
                <span class="page-number"
                    [ngClass]="{'selected-thumbnail': item.pageNumber === selectedThumbnailPageNumber}">
                    {{item.pageNumber}}
                </span>
            </div>
        </div>
    </div>
</mat-sidenav-container>


<pdf-viewer #pdfViewer
    *ngIf="pdfSrc"
    class="pdf"
    [src]="pdfSrc"
    [zoom]="zoom"
    (after-load-complete)="onPdfViewerAfterLoadCompleted($event)"
    [(page)]="pdfPage">
</pdf-viewer>

<div class="pdf-footer"
    [ngClass]="{'sidenav-offset': (enablePDFOutline || enableThumbnails), 'sidenav-pdf-menu-offset': leftNavState !== 'closed'}">
    <div class="pdf-footer-controls">
        <div class="controls">
            <div class="pages mr-2">
                <span (click)="onDecreasePageNumber()"><i class="fa fa-solid fa-minus mr-2"></i></span>
                <span class="mr-2">
                    Page
                    <input type="number"
                        [value]="pdfPage"
                        (change)="onPdfPageChange($event)" />
                    of {{totalPages}}</span>
                <span (click)="onIncreasePageNumber()"><i class="fa fa-solid fa-plus mr-2"></i></span>
            </div>
            <div class="spacer">
                |
            </div>
            <div class="zoom">
                <span (click)="onZoomInClicked()"><i class="fa fa-magnifying-glass-plus mr-2"></i></span>
                <span (click)="onZoomOutClicked()"><i class="fa fa-magnifying-glass-minus mr-2"></i></span>
                <span (click)="onResetClicked()"><i class="fa fa-rotate-left"></i></span>
            </div>
        </div>
    </div>
</div>
