import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { SelectListInterface } from 'app/nexus-shared/interfaces';
import { NexusAnimations } from 'app/nexus-shared/animations';
import { SimpleChangesTyped } from 'app/nexus-shared/models/simple-changes-typed.type';

@Component({
    selector: 'gtn-slide-out-tabs',
    templateUrl: './slide-out-tabs.component.html',
    styleUrls: ['./slide-out-tabs.component.scss'],
    animations: [
        NexusAnimations.slideInOut
    ]
})
export class SlideOutTabsComponent extends BaseComponent implements OnChanges, OnInit {
    @Input() contentHeight: string;
    @Input() isMouseOverOpen: boolean = false;  //not hooked up yet
    @Input() tabs: SelectListInterface[] = [];
    @Input() showTabContentWithoutTabs: boolean = false;
    @Input() tabColors: string[] = ['#335c88', '#50a572', '#303636', '#8c346f', '#f79646'];
    @Input() isSlideOver: boolean = true;
    @Input() isWide: boolean = false;
    @Input() isNarrow: boolean = false;

    @Input() isShowTabContent: boolean = false;
    @Output() isShowTabContentChange: EventEmitter<boolean> = new EventEmitter();

    @Input() selectedTab: SelectListInterface;
    @Output() selectedTabChange: EventEmitter<SelectListInterface> = new EventEmitter<SelectListInterface>();

    isAnimating: boolean = false;

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.setContentHeight();
    }

    ngOnChanges(changes: SimpleChangesTyped<this>) {
        //handle tab and selected tab changes
    }

    onShowTabClicked(tab: SelectListInterface) {
        if (!tab) {
            this.setShowTabContent(!this.isShowTabContent, tab);
        } else if (!this.isShowTabContent) {
            this.setShowTabContent(true, tab);
        } else if (tab.id === this.selectedTab.id) {
            this.setShowTabContent(false, tab);
        } else {
            this.selectedTab = tab;
            this.selectedTabChange.emit(this.selectedTab);
        }
    }

    private setShowTabContent(isShowTabContent: boolean, tab): void {
        this.isAnimating = !this.isAnimating;

        //change tab content
        if (tab) {
            this.selectedTab = tab;
            this.selectedTabChange.emit(this.selectedTab);
        }

        //handle animation on tab
        setTimeout(() => {
            this.isShowTabContent = isShowTabContent;
            this.isShowTabContentChange.emit(this.isShowTabContent);
            this.selectedTabChange.emit(this.selectedTab);
        }, 300);
    }

    private setContentHeight(): void {
        if (!this.contentHeight) {
            let height = 436;
            if (this.tabs.length > 3) {
                height += ((this.tabs.length - 3) * 134);
            }
            this.contentHeight = `${height}px`;
        }
    }
}
