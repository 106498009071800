import { PDFOutlineModel } from 'app/nexus-shared/models/pdf-outline.model';
import { PDFPageProxy } from 'ng2-pdf-viewer';
import { StructTreeContent, StructTreeNode } from 'pdfjs-dist/types/src/display/api';

export class PdfFileViewHelper {

    static populateOutline(headerMaps: Map<StructTreeNode, string[]>): PDFOutlineModel[] {

        const headerOutlines: PDFOutlineModel[] = [];

        for (const headerMap of headerMaps) {
            const elements = this.getHtmlElementsByIds(headerMap[1]),
                outline = new PDFOutlineModel();

            outline.ids = headerMap[1];
            outline.href = `#${outline.ids[0]}`;
            outline.linkId = outline.ids[0];
            outline.role = headerMap[0].role;
            outline.text = this.getOutlineText(elements);

            const parentOutline = this.getParentOutline(outline, headerOutlines);

            if (parentOutline) {
                parentOutline.children.push(outline);
            } else {
                headerOutlines.push(outline);
            }
        }

        return headerOutlines;
    }

    static async processOutlineHeaderMap(pages: PDFPageProxy[]): Promise<Map<StructTreeNode, string[]>> {
        let headerMap = new Map<StructTreeNode, string[]>();

        for (const page of pages) {
            await page.getStructTree().then(x => {
                const pageHeaderMap = this.getOutlineHeadersInPage(page._structTreePromise.__zone_symbol__value);
                headerMap = new Map<StructTreeNode, string[]>([...headerMap.entries(), ...pageHeaderMap.entries()]);

            });
        }

        return headerMap;
    }

    private static getContentIds(node: StructTreeNode): string[] {
        const childContentNodes = node.children.filter((x): x is StructTreeContent => "id" in x);
        let contentIds = childContentNodes.map(x => x.id);

        const childNodes = node.children.filter((x): x is StructTreeNode => "role" in x);
        for (const childNode of childNodes) {
            const childContentIds = this.getContentIds(childNode);
            contentIds = contentIds.concat(childContentIds);
        }

        return contentIds;
    }

    private static getHtmlElementsByIds(ids: string[]): HTMLElement[] {
        const elements: HTMLElement[] = [];

        for (const id of ids) {
            const element = document.getElementById(id);

            if (element) {
                elements.push(element);
            }
        }

        return elements;
    }

    private static getLatestOutlineInstance(role: string, outlines: PDFOutlineModel[]): PDFOutlineModel {
        if (!outlines?.length) {
            return null;
        }

        for (const outline of [...outlines].reverse()) {
            if (outline?.role === role) {
                return outline;
            }

            if (outline?.children) {
                const latestChildOutlineInstance = this.getLatestOutlineInstance(role, outline.children);
                if (latestChildOutlineInstance) {
                    return latestChildOutlineInstance;
                }
            }
        }

        return null;
    }

    private static getOutlineHeadersInPage(node: StructTreeNode): Map<StructTreeNode, string[]> {
        let headerMap = new Map<StructTreeNode, string[]>();

        const childNodes = node?.children?.filter((x): x is StructTreeNode => "role" in x) ?? [];
        const headerNodes = childNodes?.filter(x => x.role.includes('H') && x.role !== 'TH');

        for (const headerNode of headerNodes) {
            const contentIds = this.getContentIds(headerNode);

            if (contentIds?.length > 0) {
                headerMap.set(headerNode, contentIds);
            }
        }

        for (const childNode of childNodes) {
            const childMap = this.getOutlineHeadersInPage(childNode);
            headerMap = new Map<StructTreeNode, string[]>([...headerMap.entries(), ...childMap.entries()]);
        }

        return headerMap;
    }

    private static getOutlineText(elements: HTMLElement[]): string {
        const elementTexts = elements.map(x => x.textContent);
        return elementTexts.join(' - ');
    }

    private static getParentOutline(outline: PDFOutlineModel, outlines: PDFOutlineModel[]): PDFOutlineModel {
        const role = outline?.role;
        const roleNumber = Number(role.replace('H', '').trim());
        if (!role || !roleNumber || Number.isNaN(roleNumber) || roleNumber < 2) {
            return null;
        }

        for (let index = roleNumber; index > 0; index--) {
            const parentRoleNumber = index - 1;
            const parentRole = `H${parentRoleNumber}`;

            const parent = this.getLatestOutlineInstance(parentRole, outlines);
            if (parent) {
                return parent;
            }
        }

        return null;
    }
}
