export enum ClientDocumentTypesEnum {
    Unknown = 0,
    CertificateOfCoverage = 10,
    CompensationInformation = 20,
    ConsentForms = 30,
    Correspondence = 40,
    DraftTaxReturn = 50,
    EFileForms = 60,
    EngagementLetter = 70,
    EstimatedNetPayCalculation = 80,
    EstimatedTax = 90,
    Extension = 100,
    FinalAmendedReturn = 110,
    FinalTaxReturn = 120,
    ForeignTaxInformation = 130,
    FormFinCEN114= 140,
    FormW7= 150,
    GrossUpCalculation = 160,
    HypotheticalTaxCalculation = 170,
    IncomeTaxInformation = 180,
    Notice = 190,
    Organizer = 200,
    Other = 210,
    PayrollDocuments = 220,
    Permanent = 230,
    ProcessingDocuments = 240,
    RelocationInformation = 250,
    ReviewNotes = 260,
    ShippingReceipt = 270,
    TaxCostEstimate = 280,
    TaxCostProjection = 290,
    TaxCounselingSession = 300,
    TEQCompanyCopy = 310,
    TEQTaxpayerCopy = 320,
    TEQWorkpapers = 330,
    TravelCalendar = 340,
    Workpapers = 350,
}
