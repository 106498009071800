import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NexusAnimations } from 'app/nexus-shared/animations';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { ToastService } from 'app/nexus-core/services/toast.service';
import { AuthenticatedUserService } from 'app/nexus-core/services/authenticated-user.service';
import { LoggingService } from 'app/nexus-core';
import { UserService } from 'app/nexus-core/services/domain/users/user.service';
import { AuthenticationService } from 'app/nexus-core/services/authentication.service';
import { environment } from 'environments/environment';

@Component({
    selector: 'gtn-forgot-password-modal',
    templateUrl: './forgot-password-modal.component.html',
    animations: [
        NexusAnimations.modalFadeIn
    ]
})
export class ForgotPasswordModalComponent extends BaseComponent implements OnInit {
    @Input() userKey: string;

    @Output() completed = new EventEmitter<boolean>();

    email: string = null;
    disableButtons = false;
    passwordChangeLinkTimeToLiveInMinutes: number;

    constructor(
        public loggingService: LoggingService,
        private userService: UserService,
        private authenticationService: AuthenticationService,
        private toastService: ToastService,
        private authenticatedUserService: AuthenticatedUserService) {
        super();
    }

    ngOnInit(): void {
        this.passwordChangeLinkTimeToLiveInMinutes = environment().auth.passwordChangeLinkTimeToLiveInMinutes;

        if (this.userKey) {
            this.userService.getByKey(this.userKey).subscribe(user => {
                this.email = user?.email;
            });
        } else {
            if (this.authenticatedUserService.user) {
                this.email = this.authenticatedUserService.user.email;
            }
        }
    }

    onResetClicked(): void {
        if (this.email) {
            this.disableButtons = true;
            this.userService.resetPassword(this.authenticatedUserService.user.userKey).subscribe(
                _ => {
                    this.toastService.showMessageToast('Password reset email has been sent successfully.');

                    setTimeout(_ => {
                        this.completed.emit(true);
                        this.disableButtons = false;
                        this.authenticationService.logout();
                    }, 2000);
                },
                err => {
                    this.completed.emit(false);
                    this.toastService.showErrorToast(err);
                    this.disableButtons = false;
                }
            );
        }
    }

    onCancelClicked(): void {
        this.completed.emit(false);
    }
}
