import { Pipe, PipeTransform } from '@angular/core';
import { SectionModel } from 'app/nexus-shared/domain/questionnaires/models/section.model';
import { QuestionnaireHelper } from 'app/nexus-core/helpers/questionnaire.helper';
import { QuestionnaireTemplateModel } from 'app/nexus-shared/domain/questionnaires/models/questionnaire-template.model';

@Pipe({ name: 'isTriggeredByAction' })
export class QuestionnaireIsTriggeredByActionPipe implements PipeTransform {

    transform(questionnaire: QuestionnaireTemplateModel, section: SectionModel): boolean {
        if (!section || !questionnaire || !questionnaire.sections) {
            return false;
        }
        return QuestionnaireHelper.isTriggeredByAction(section.key, questionnaire);
    }
}
