import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IndividualService } from 'app/nexus-core/services/domain/contacts/individual.service';
import { BaseReadonlyEditComponent } from 'app/nexus-shared/components/base-component/base-readonly-edit.component';
import { Observable, of } from 'rxjs';
import { IndividualModel } from 'app/nexus-shared/domain/contacts/models';
import { map } from 'rxjs/operators';
import { AuthorizationConstants } from 'app/nexus-shared/constants/authorization.constants';
import { AuthenticatedUserService } from 'app/nexus-core/services/authenticated-user.service';
import { ApplicationsEnum } from 'app/nexus-shared/enums';
import { IndividualFormComponent } from 'app/nexus-shared/domain/contacts/components/forms/individual-form/individual-form.component';
import { IndividualTypesEnum } from 'app/nexus-shared/domain/contacts/enums/individual-types.enum';

@Component({
    selector: 'gtn-individual-readonly-edit',
    templateUrl: './individual-readonly-edit.component.html',
    styleUrls: ['./individual-readonly-edit.component.scss']
})
export class IndividualReadonlyEditComponent extends BaseReadonlyEditComponent<IndividualModel, boolean> implements OnInit {
    @Input() individualKey: string = null;

    application = ApplicationsEnum.Contacts;
    canEdit = false;

    @ViewChild('individualForm') individualForm: IndividualFormComponent;
    showConfirmationModal: boolean = false;

    constructor(private individualService: IndividualService, private authenticatedUserService: AuthenticatedUserService) {
        super();
    }

    ngOnInit(): void {
        this.canEdit = this.authenticatedUserService.hasAuthorization(AuthorizationConstants.contactsAdmin);

        super.ngOnInit();
    }

    getValue(value: IndividualModel): Observable<IndividualModel> {
        if (value) {
            return of(new IndividualModel(value));
        }

        return this.individualService.getByKey(this.value.individualKey).pipe(map(individual => {
            return new IndividualModel(individual);
        }));
    }

    saveValue(): Observable<boolean> {
        return this.individualService.update(this.value);
    }

    addAddressClicked(): void {
        this.individualForm.onCreateAddressClicked();
    }

    onConfirmationClicked(): void {
        const isGTNAdmin : boolean = this.authenticatedUserService.hasAuthorization(AuthorizationConstants.gtnAdministrator);
        const isGTNEmployee: boolean = this.value.individualTypes.some(individual => individual.type === IndividualTypesEnum.GtnEmployee);
        if ((isGTNAdmin && isGTNEmployee) && this.formGroupRef.get('emailAddress').dirty) {
            this.showConfirmationModal = true;
        } else {
            this.onSaveClicked();
            this.showConfirmationModal = false;
        }
    }

    onConfirmationCancelClicked(): void {
        this.onCancelClicked();
        this.isSaving = false;
        this.showConfirmationModal = false;
    }

    onConfirmationSavedClicked(): void {
        this.onSaveClicked();
        this.showConfirmationModal = false;
    }
}
