import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DocumentModel } from 'app/nexus-shared/domain/documents/models/document.model';
import { DocumentService } from 'app/nexus-core/services/domain/documents/document.service';
import { DocumentSearchModel } from 'app/nexus-shared/domain/documents/models/document-search.model';
import { DocumentStatusesEnum } from 'app/nexus-shared/domain/documents/enums/document-statuses.enum';
import { SelectListInterface } from 'app/nexus-shared/interfaces';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { SortingHelper } from 'app/nexus-core';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { AuthenticatedUserService } from 'app/nexus-core/services/authenticated-user.service';
import { AreaTypesEnum } from 'app/nexus-shared/domain/documents/enums/area-types.enum';

@Component({
    selector: 'gtn-checked-out-files',
    templateUrl: './checked-out-files.component.html',
    styleUrls: ['../dashboard-table.component.scss']
})
export class CheckedOutFilesComponent extends BaseComponent implements OnInit {

    @Input() isRecent: boolean;
    @Input() isAdmin: boolean;
    @Input() height: number;

    @Output() navigateToFolder: EventEmitter<DocumentModel> = new EventEmitter<DocumentModel>();
    @Output() navigateToArea: EventEmitter<DocumentModel> = new EventEmitter<DocumentModel>();

    currentId = 0;
    recentId = 1;
    allId = 2;
    documents: DocumentModel[];
    checkoutButtonOptions: SelectListInterface[];
    selectedButtonId: number = 0;
    searchModel: DocumentSearchModel;

    constructor(
        private authenticatedUserService: AuthenticatedUserService,
        private documentService: DocumentService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.checkoutButtonOptions = [
            { id: this.currentId, value: 'Current' },
            { id: this.recentId, value: 'Recent' },
        ];
        if (this.isAdmin) {
            this.checkoutButtonOptions.push({ id: this.allId, value: 'All' });
        }

        this.searchModel = new DocumentSearchModel();
        this.searchModel.userKey = this.authenticatedUserService.user.userKey;
        this.searchModel.statuses = [DocumentStatusesEnum.CheckedOut];
        this.searchModel.includeCompany = true;
        this.searchModel.includeIndividual = true;
        this.getDocuments();
    }

    onCheckoutOptionChange(id: number): void {
        this.selectedButtonId = id;
        if (this.selectedButtonId === this.currentId) {
            this.searchModel.userKey = this.authenticatedUserService.user.userKey;
            this.getDocuments();
        } else if (this.selectedButtonId === this.recentId) {
            this.getRecentCheckedOutFiles();
        } else if (this.selectedButtonId === this.allId) {
            this.searchModel.userKey = null;
            this.searchModel.areaTypes = [AreaTypesEnum.Clients];
            this.getDocuments();
        }
    }

    onDocumentClicked(document: DocumentModel): void {
        if (document.areaType !== AreaTypesEnum.Clients) {
            this.navigateToArea.emit(document);
        } else {
            this.navigateToFolder.emit(document);
        }
    }

    private getDocuments(): void {
        this.documentService.search(this.searchModel).subscribe(res => {
            this.documents = res.sort((a, b) => SortingHelper.sortByPropertyComparer(a, b, ObjectHelper.nameOf<DocumentModel>('name')));
        });
    }

    private getRecentCheckedOutFiles(): void {
        this.documentService.getRecentlyCheckedOut().subscribe(res => {
            this.documents = res.sort((a, b) => SortingHelper.sortByPropertyComparer(a, b, ObjectHelper.nameOf<DocumentModel>('name')));
        });
    }
}
