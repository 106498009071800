import { Component, Input } from '@angular/core';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { SelectModel } from 'app/nexus-shared/models';

@Component({
    selector: 'gtn-modal-footer',
    templateUrl: './nexus-modal-footer.component.html',
    styleUrls: ['./nexus-modal-footer.component.scss']
})
export class NexusModalFooterComponent extends BaseComponent {
    @Input() cancel: Function;
    @Input() cancelButtonText = 'Cancel';
    @Input() enableOkButton: SelectModel | boolean | string = true;
    @Input() enableCancelButton = true;
    @Input() ok: Function;
    @Input() okButtonText = 'Ok';
    @Input() showCancel = false;
    @Input() showOk = false;
}
