import { CommonBaseAuditModel } from '../../contacts/models/common-base-audit.model';
import { IAgreementTypeModelInterface } from '../interfaces/iagreement-type-model.interface';
import { AgreementSubtypeEnum } from 'app/nexus-shared/domain/agreements/enums/agreement-subtype.enum';
import { AgreementTypeEnum } from 'app/nexus-shared/domain/agreements/enums/agreement-type.enum';

export class AgreementTypeModel extends CommonBaseAuditModel implements IAgreementTypeModelInterface {
    agreementTypeKey: string;
    templateKey: string;
    isActive: boolean;
    name: string;
    shortName: string;
    subtype: AgreementSubtypeEnum;
    type: AgreementTypeEnum;

    constructor(model: AgreementTypeModel = null) {
        super(model);

        this.agreementTypeKey = model?.agreementTypeKey ?? null;
        this.templateKey = model?.templateKey ?? null;
        this.isActive = model?.isActive ?? false;
        this.name = model?.name ?? null;
        this.shortName = model?.shortName ?? null;
        this.subtype = model?.subtype ?? null;
        this.type = model?.type ?? null;
    }
}
