import { Component } from '@angular/core';

@Component({
    selector: 'gtn-gtn-privacy-policy',
    template: `
      <div>
          <div><p>Effective: 27 July 2023</p>
              <h3><span style="color: #1D4681;">Introduction and Scope</span></h3>
              <p>Global Tax Network US, LLC (“<strong>GTN</strong>” “<strong>we</strong>,” “<strong>us</strong>,” “<strong>our</strong>”) takes the protection of your personally identifiable
                  information (<strong>PII</strong>) very seriously. This GTN Website Privacy Policy (the “<strong>Policy</strong>”) applies to PII processed (e.g. collected, recorded, organized,
                  structured, stored, adapted, altered, used, disclosed, erased, destroyed, etc.) in connection with GTN’s sales and marketing operations, including where we received PII when you use
                  GTN’s website located at <a href="https://www.gtn.com">https://www.gtn.com</a> (the “Website”) or participate in a GTN event, webinar, or other marketing initiative or activity
                  online (each, a “GTN Event”). This Policy explains how we collect and use your PII for these purposes.</p>
              <p>This Policy does not address processing of PII in the context of our professional services or web applications, such as the My GTN Portal or our payment processing application
                  (collectively, the “Applications and Services”). If you use the Applications and Services, the PII processed therein will be processed according to the GTN Applications and Services
                  Privacy Policy, available here: <a href="https://www.gtn.com/applications-and-services-privacy-policy">https://www.gtn.com/applications-and-services-privacy-policy</a>.</p>
              <h3><span style="color: #1D4681;">Controllership</span></h3>
              <p>In the context of this Policy, GTN acts as a data controller for PII we process about you.</p>
              <h3><span style="color: #1D4681;">Data We Collect</span></h3>
              <p>We may process the following categories of PII about you:</p>
              <ul>
                  <li>biographical information such as first and last name;</li>
                  <li>contact information such as phone number, email address, and mailing address;</li>
                  <li>professional information such as job title and position; and</li>
                  <li>any other categories of PII you choose to submit to us by email, phone, or through a web form.</li>
              </ul>
              <p>We may receive your PII from you directly, when you visit our website, participate in a GTN Event, or contact us by email, phone, teleconferencing software, or through a web form.</p>
              <h3><span style="color: #1D4681;">How We Use Your Data</span></h3>
              <h3><span style="color: #1D4681;">GTN Will Use Your PII to:</span></h3>
              <ul>
                  <li>manage our customer relationships;</li>
                  <li>contact you and to respond to your sales inquiries, and/or other requests or questions;</li>
                  <li>facilitate your participation in a GTN Event;</li>
                  <li>help improve our website; and</li>
                  <li>enable us to send you our email newsletter.</li>
              </ul>
              <h3><span style="color: #1D4681;">Basis of Processing</span></h3>
              <p>Within the scope of this Policy, GTN processes PII on the basis of the need to perform our contractual obligations, or to take pre-contractual measures at your request. Additionally,
                  GTN may process PII on the basis of the pursuit of our legitimate interests, such as marketing, selling, and providing our services to prospective and current customers. In certain
                  cases, GTN relies on your consent as the basis for processing. In such cases, you may revoke your consent at any time. To do so, please contact GTN using the contact information in
                  the “Contact Us” section of this Policy. Where we receive your PII as part of providing our services to you based on a contract, we require such PII (and in some cases, we will need
                  your consent to process your PII) to be able to carry out the contract. Without that necessary PII, we will not be able to provide the services to you.</p>
              <h3><span style="color: #1D4681;">Cookies</span></h3>
              <p>A “cookie” is a small file stored on your device that contains information about your computer. We may use cookies for session management, targeted advertising, and web analytics.</p>
              <p>The use of cookies is industry standard, so your browser may be set to accept cookies. If you would prefer not to accept cookies, you can alter the configuration of your browser to
                  reject all cookies or some cookies. Note, if you reject certain cookies, you may not be able to access all of the features available on our website.</p>
              <h3><span style="color: #1D4681;">Sharing PII with Third Parties</span></h3>
              <p>We may use third-party vendors to perform certain services on our behalf. These third-party vendors include those providing:</p>
              <ul>
                  <li>hosting services;</li>
                  <li>payment processing services or software;</li>
                  <li>e-signature software; and</li>
                  <li>customer relationship management (CRM) software.</li>
              </ul>
              <p>We may share your PII with these third-party vendors solely to enable them to perform the services for us. In providing them with your PII, we require that these third-party vendors
                  maintain at least the same level of data protection that we maintain for your PII. We do not provide your PII to parties unconnected with the services we provide.</p>
              <p>We may also share your contact information, including your name and email address, with affiliated cooperating companies in order to send you informational surveys about our services
                  and our industry.</p>
              <p>When you participate in a GTN Event, we may, with your permission, share your contact information, including your name and email address, with our business partners and GTN Event
                  sponsors for marketing purposes.</p>
              <p>Also, some of these third parties may be located outside of the country that you reside in. In some cases, the authorities in your country may not have determined that the data
                  protection laws in the countries where our third party service providers are located provide a level of protection equivalent to the laws in your country. We will only transfer your
                  PII to third parties in these countries when there are appropriate safeguards in place. For example, if you are resident in the EU and the European Commission has not determined that
                  the countries where our third party service providers are located provide an adequate level of protection for your PII, we will ensure that we have appropriate safeguards in place
                  with the third parties, such as the European-Commission-approved standard contractual data protection clauses.</p>
              <h3><span style="color: #1D4681;">Other Disclosure of Your PII</span></h3>
              <p>We may also disclose your PII:</p>
              <ul>
                  <li>to the extent required by law or if we have a good-faith belief that such disclosure is necessary in order to comply with official investigations or legal proceedings initiated
                      by governmental and/or law enforcement officials, or private parties, including but not limited to: in response to subpoenas, search warrants, or court orders;
                  </li>
                  <li>if we sell or transfer all or a portion of our company’s business interests, assets, or both, or in connection with a corporate merger, consolidation, restructuring, or other
                      company change; or
                  </li>
                  <li>to our subsidiaries or affiliates only if necessary for business and operational purposes as described in the section above.</li>
              </ul>
              <p>We reserve the right to use, transfer, sell, and share aggregated, anonymous data, which does not include any PII, about the users of our Service as a group for any legal business
                  purpose, such as analyzing usage trends and seeking compatible advertisers, sponsors, clients, and customers.</p>
              <p>If we must disclose your PII in order to comply with official investigations or legal proceedings initiated by governmental and/or law enforcement officials, we may not be able to
                  ensure that such recipients of your PII will maintain the privacy or security of your PII.</p>
              <h3><span style="color: #1D4681;">Data Retention</span></h3>
              <p>PII processed by us in the context of this Policy may be retained indefinitely. However, you may send a request to us to ask to delete your PII, subject to the limitations described
                  in this Policy.</p>
              <h3><span style="color: #1D4681;">Data Integrity &amp; Security</span></h3>
              <p>GTN has implemented and will maintain technical, administrative, and physical measures that are reasonably designed to help protect PII from unauthorized processing such as
                  unauthorized access, disclosure, alteration, or destruction.</p>
              <h3><span style="color: #1D4681;">How We Respond to Do Not Track Signals</span></h3>
              <p>Our website does not have the capability to respond to “Do Not Track” signals received from various web browsers. Please note that we do not allow third parties to collect PII about
                  your online activities while using our website.</p>
              <h3><span style="color: #1D4681;">Accessing, Deleting &amp; Updating Your PII</span></h3>
              <p>If you are a data subject about whom we store PII, you may have a right to request access to, and the opportunity to update, port, correct or delete such PII. Under certain
                  circumstances, you may have a right to restrict or object to the processing of your PII. To submit such requests or raise any other questions, please reach out to us by using the
                  contact information provided in the “Contact Us” section below.</p>
              <h3><span style="color: #1D4681;">Children’s Privacy</span></h3>
              <p>Our services are not designed to collect data from children under the age of 13. We do not knowingly collect PII from anyone under 18. If you believe your child’s PII may be processed
                  in our services, you can contact us using the information in the “Contact Us” section of this Policy to request that we delete the PII.</p>
              <h3><span style="color: #1D4681;">Changes to this Policy</span></h3>
              <p>If we make any material change to this Policy, we will post the revised Policy to this web page and update the “Effective” date above to reflect the date on which the new Policy
                  became effective.</p>
              <h3><span style="color: #1D4681;">EU Representative </span></h3>
              <p>VeraSafe has been appointed as GTN's representative in the European Union for data protection matters, pursuant to Article 27 of the General Data Protection Regulation of the European
                  Union. VeraSafe can be contacted in addition to GTN, only on matters related to the processing of PII. To make such an inquiry, please contact VeraSafe using this contact form: <a
                      href="https://www.verasafe.com/privacy-services/contact-article-27-representative">https://www.verasafe.com/privacy-services/contact-article-27-representative</a></p>
              <p>Alternatively, VeraSafe can be contacted as follows:</p>
              <p><strong><span style="color: #000000;">VeraSafe Czech Republic s.r.o.</span></strong><span style="color: #000000;"><br>Klimentská 46<br>Prague 1, 11002<br>Czech Republic</span></p>
              <p><strong><span style="color: #000000;">VeraSafe Ireland Ltd</span></strong><span style="color: #000000;"><br>Unit 3D<br>North Point House<br>North Point Business Park<br>New Mallow Road<br>Cork T23AT2P<br>Ireland</span>
              </p>
              <p><span style="color: #000000;">If you are a resident of the European Union, you may have the right to lodge a complaint with a data protection regulator in one or more of the EU member states.</span>
              </p>
              <h3><span style="color: #1D4681;">UK Representative</span></h3>
              <p>VeraSafe has been appointed as GTN's representative in the United Kingdom for data protection matters, pursuant to Article 27 of the United Kingdom General Data Protection Regulation.
                  If you are located within the&nbsp;United Kingdom, VeraSafe can be contacted in addition to GTN, only on matters related to the processing of your PII. To make such an inquiry,
                  please contact VeraSafe using this contact form: <a href="https://protect-us.mimecast.com/s/W8eoCgJDnruZAOOCND0Ic?domain=verasafe.com">https://verasafe.com/public-resources/contact-data-protection-representative</a>
                  or via telephone at +44 (20) 4532 2003.</p>
              <p>Alternatively, VeraSafe can be contacted at:</p>
              <p><strong>VeraSafe United Kingdom Ltd.</strong></p>
              <p>37 Albert Embankment<br>London SE1 7TL<br>United Kingdom</p>
              <h3><span style="color: #1D4681;">Contact Us</span></h3>
              <p>If you have any questions about this Policy or our processing of your PII, please write to us by email at <gtn-email [email]="'privacy@gtn.com'"></gtn-email> or by postal mail at:</p>
              <p>Global Tax Network US, LLC<br>Attn: COO / Data Privacy &amp; Security Officer<br>6900 Wedgwood Road N, Suite 400<br>Maple Grove, MN 55311<br>USA&nbsp;&nbsp;&nbsp;&nbsp;</p>
              <p>We will respond to your inquiry within one month or less.</p>
              <p>&nbsp;</p>
          </div>

      </div>
  `,
    styleUrls: ['./gtn-privacy-policy.component.scss']
})
export class GTNPrivacyPolicyComponent {

}
