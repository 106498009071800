<gtn-template-view [cardMode]="cardMode">
    <gtn-template-view-content>
        <ng-container *ngIf="viewMode === viewModes.All || viewMode === viewModes.Integrations">
            <div class="row">
                <div class="col-md-3">
                    <label class="list-label">Type</label>
                    <div class="data-field" *ngIf="value.type">
                        {{value.type | enum: contactIntegrationType}}
                    </div>
                    <div class="data-field" *ngIf="!value.type">
                        {{noValueText}}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label class="list-label">Value</label>
                    <div class="data-field">
                        {{value.integrationValue || noValueText}}
                    </div>
                </div>
            </div>
        </ng-container>
    </gtn-template-view-content>
</gtn-template-view>
