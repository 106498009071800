import { Component, EventEmitter, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'gtn-verification-input',
    templateUrl: './verification-input.component.html',
    styleUrls: ['./verification-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: VerificationInputComponent,
            multi: true
        }
    ]
})
export class VerificationInputComponent implements ControlValueAccessor {
    @Output() codeComplete: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() enterClick: EventEmitter<void> = new EventEmitter<void>();
    verificationCode: string[] = Array(6).fill('');

    writeValue(value: number): void {
        if (!value && this.verificationCode.join('') !== '') {
            this.resetForm();
        }
    }

    registerOnChange(fn: Function): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: Function): void {
        this.onTouched = fn;
    }

    onVerificationCodeChange(): void {
        this.emitCodeComplete(this.areAllIndexesFilled());
        this.onChange(this.verificationCode.join(''));
    }

    onKeydown(index: number, event: KeyboardEvent): void {
        const target = event.target as HTMLInputElement;
        const previousInput = target.parentElement?.previousElementSibling?.querySelector('input');
        const nextInput = target.parentElement?.nextElementSibling?.querySelector('input');

        if (event.key === 'Backspace') {
            if (index === 0) {
                // If we are on the first input and backspace nothing should happen
                return;
            } else if (this.verificationCode[index] === '') {
                // If backspace is pressed and the current input is empty, focus on the previous input
                previousInput?.focus();
            } else {
                // If backspace is pressed and the current input is not empty, clear the current input
                this.verificationCode[index] = '';
            }
        } else if (event.key === 'ArrowRight' && nextInput) {
            nextInput.focus();
        } else if (event.key === 'ArrowLeft' && previousInput) {
            previousInput.focus();
        } else if (!isNaN(Number(event.key)) && nextInput) {
            // Prevent the browser from automatically inserting the digit
            event.preventDefault();
            // Manually add the digit to the input field
            this.verificationCode[index] = event.key;
            // If a digit is entered, focus on the next input
            setTimeout(() => nextInput.focus(), 0);
        } else if (event.key === 'Enter') {
            // If 'Enter' is pressed, submit the form
            if (this.areAllIndexesFilled()) {
                this.submitForm();
            }
        }
    }


    onPaste(event: ClipboardEvent): void {
        const clipboardData = event.clipboardData || window.navigator.clipboard as any;
        const pastedText = clipboardData.getData('text');
        this.verificationCode = pastedText.split('').slice(0, 6);
        event.preventDefault();

        const lastInput = document.querySelector(`.verification-code-col:nth-child(6) .code-input`);
        if (lastInput instanceof HTMLElement) {
            lastInput.focus();
        }
        this.onVerificationCodeChange();
    }

    protected onChange: Function = (): void => {
    };
    protected onTouched: Function = (): void => {
    };

    private emitCodeComplete(isFilledOut: boolean): void {
        this.codeComplete.emit(isFilledOut);
    }

    private areAllIndexesFilled(): boolean {
        return this.verificationCode.every(code => code !== '');
    }

    private submitForm(): void {
        this.enterClick.emit();
    }

    private resetForm(): void {
        // Reset the verification code
        this.verificationCode = Array(6).fill('');
        this.focusOnFirstInput();
    }

    private focusOnFirstInput(): void {
        // Get the first input element
        const firstInput = document.querySelector('.code-input');
        // Check if the element exists and if it's an instance of HTMLElement
        if (firstInput instanceof HTMLElement) {
            // Focus on the first input element
            firstInput.focus();
        }
    }
}
