import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { SearchModel } from 'app/nexus-shared/components/controls/shared/models/search.model';
import { debounceTime } from 'rxjs/operators';

@Component({
    template: ''
})
export abstract class BaseSearchControlComponent<T> extends BaseComponent implements OnInit, ControlValueAccessor {
    @Input('value') _value: any = null;
    @Input() ngModel: T = null;
    @Input() disabled: boolean;
    @Input() readonly: boolean;
    @Input() label: string;
    @Input() placeholder: string = 'Search';
    @Input() requiredCharacterCount: number = 2;
    @Input() debounceTime: number = 200;
    @Input() showAddLink: boolean = false;
    @Input() useModal: boolean = false;
    @Input() primaryAddLabel: string;
    @Input() secondaryAddLabel: string;
    @Input() isCustomSearchResultClick: boolean = false;
    @Output() searchResultClick: EventEmitter<T> = new EventEmitter();
    @Output() addEntityClick: EventEmitter<void> = new EventEmitter();
    @Output() valueChange: EventEmitter<T> = new EventEmitter();

    searchModel = new SearchModel();

    protected constructor() {
        super();

    }

    ngOnInit() {
        this.subscriptions.add(this.searchModel.onTextChanged.pipe(debounceTime(this.debounceTime)).subscribe(searchText => {
            this.onSearchTextChanged(searchText);
        }));

        if (!this.isCustomSearchResultClick) {
            this.subscriptions.add(this.searchModel.onResultClicked.subscribe((result => {
                this.searchResultClick.emit(result?.resultObject ?? null);
                this.writeValue(result?.resultObject ?? null);
            }).bind(this)));
        }

        this.subscriptions.add(this.searchModel.onCreateNewClicked.subscribe(() => {
            this.onAddClicked();
        }));
    }

    get value() {
        return this._value;
    }

    set value(value: T) {
        this._value = value;
        this.writeValue(value);
    }

    registerOnChange(fn: Function): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: Function): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    writeValue(value: T): void {
        this.ngModel = value;
        this._value = value;
        this.valueChange.emit(value);
        this.onChange(value);
        this.onTouched();
    }

    onSaveSuccess(value: T): void {
        this.searchResultClick.emit(value);
    }

    protected onAddClicked(): void {
        this.addEntityClick.emit();
    }

    protected abstract onSearchTextChanged(searchText: string);

    private onChange: Function = () => {
    };
    private onTouched: Function = () => {
    };
}
