<gtn-modal class="modal-medium" [headerText]="isCheckIn ? 'Check In Document' : 'Upload Document'"
    okButtonText="Save" [disableOkButton]="!formGroupRef.dirty || isSaving || isInvalidFileSize || !file"
    [validationModels]="validationModels"
    (okClick)="onSaveClicked()"
    (cancelClick)="onCancelClicked()" (closeClick)="onCancelClicked()" [formGroupRef]="formGroupRef"
    [shouldConfirmCancel]="false">
    <gtn-modal-content>
        <gtn-document-form [(formGroupRef)]="formGroupRef"
            [isUpload]="true" [isCheckIn]="isCheckIn" [cardMode]="cardMode" [viewMode]="viewMode"
            [(value)]="value" [(file)]="file" (formGroupRefChange)="onFormGroupRefChanged($event)"
            (fileChange)="onFileChanged($event)"></gtn-document-form>
    </gtn-modal-content>
</gtn-modal>
