import { Routes } from '@angular/router';
import { NexusRoutesConstants } from 'app/nexus-routing/nexus-routes.constants';
import { LandingPageComponent } from 'app/components/landing-page/landing-page.component';
import { AuthGuard } from 'app/nexus-core/guards/auth.guard';
import { RedirectComponent } from 'app/nexus-shared/components/redirect/redirect.component';

export const NexusRoutes: Routes = [
    {
        path: '',
        redirectTo: NexusRoutesConstants.landing.routing.routerModulePath,
        pathMatch: 'full',
    },
    {
        path: NexusRoutesConstants.landing.routing.routerModulePath,
        component: LandingPageComponent,
        pathMatch: 'full',
        canActivate: [
            AuthGuard
        ]
    },
    {
        path: NexusRoutesConstants.redirect.routing.routerModulePath,
        component: RedirectComponent,
        pathMatch: 'full'
    },
    {
        path: NexusRoutesConstants.agreements.routing.routerModulePath,
        canActivate: [
            AuthGuard
        ],
        data: {
            authorization: NexusRoutesConstants.agreements.authorization
        },
        loadChildren: () => import('../modules/agreements/agreements.module').then(m => m.AgreementsModule)
    },
    {
        path: NexusRoutesConstants.contacts.routing.routerModulePath,
        canActivate: [
            AuthGuard
        ],
        data: {
            authorization: NexusRoutesConstants.contacts.authorization
        },
        loadChildren: () => import('../modules/contacts/contacts.module').then(m => m.ContactsModule)
    },
    {
        path: NexusRoutesConstants.users.routing.routerModulePath,
        canActivate: [
            AuthGuard
        ],
        data: {
            authorization: NexusRoutesConstants.users.authorization
        },
        loadChildren: () => import('../modules/users/users.module').then(m => m.UsersModule)
    },
    {
        path: NexusRoutesConstants.tax.routing.routerModulePath,
        canActivate: [
            AuthGuard
        ],
        loadChildren: () => import('../modules/tax/tax.module').then(m => m.TaxModule)
    },
    {
        path: NexusRoutesConstants.questionnaires.routing.routerModulePath,
        canActivate: [
            AuthGuard
        ],
        loadChildren: () => import('app/modules/questionnaires/questionnaires.module').then(m => m.QuestionnairesModule)
    },
    {
        path: NexusRoutesConstants.documents.routing.routerModulePath,
        canActivate: [
            AuthGuard
        ],
        data: {
            authorization: NexusRoutesConstants.documents.authorization
        },
        loadChildren: () => import('../modules/documents/documents.module').then(m => m.DocumentsModule)
    },
    {
        path: NexusRoutesConstants.portal.routing.routerModulePath,
        canActivate: [
            AuthGuard
        ],
        data: {
            authorization: NexusRoutesConstants.portal.authorization
        },
        loadChildren: () => import('../modules/portal/portal.module').then(m => m.PortalModule)
    },
    //THIS MUST BE THE LAST ROUTE!!!!
    {
        path: '**',
        redirectTo: NexusRoutesConstants.landing.routing.routerModulePath,
    }
];
