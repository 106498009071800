<mat-form-field [appearance]="appearance"
    [ngClass]="{'gtn-disabled' : disabled, 'gtn-read-only' : readonly, 'gtn-control-show-error': !formControl?.valid && !formControl?.pending && canShowValidation}">
    <mat-label>{{label}}</mat-label>
    <mat-select #element [(ngModel)]="value" [disabled]="disabled || readonly">
        <mat-select-trigger *ngIf="value">
            <mat-icon [class]="value.fontClass" fontSet="fa" [fontIcon]="value.fontName"></mat-icon>{{value.value}}
        </mat-select-trigger>
        <mat-option *ngFor="let option of options" [value]="option">
            <mat-icon [class]="option.fontClass" fontSet="fa" [fontIcon]="option.fontName"></mat-icon>
            {{option.value}}
        </mat-option>
    </mat-select>
    <div class="gtn-control-error-message error-message" *ngIf="!formControl?.valid && !formControl?.pending && canShowValidation">
        <span *ngFor="let msg of getErrorMessage()">{{msg}}</span>
    </div>
</mat-form-field>
