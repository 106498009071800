import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseEntryComponent } from 'app/nexus-shared/components/base-component/base-entry.component';
import { IndividualModel } from 'app/nexus-shared/domain/contacts/models';
import { ApplicationsEnum } from 'app/nexus-shared/enums';
import { CardModesEnum } from 'app/nexus-shared/enums/card-modes.enum';
import { ViewModesEnum } from 'app/nexus-shared/enums/view-modes.enum';
import { IndividualService } from 'app/nexus-core/services/domain/contacts/individual.service';
import { IndividualToCompanyAccessCompositeResponseModel } from 'app/nexus-shared/domain/contacts/models/individual-to-company-access-composite-response.model';
import { IndividualToCompanyAccessCompositeModel } from 'app/nexus-shared/domain/contacts/models/individual-to-company-access-composite.model';

@Component({
    selector: 'gtn-individual-create-composite-template-modal',
    templateUrl: './individual-create-composite-template-modal.component.html',
})
export class IndividualCreateCompositeTemplateModalComponent extends BaseEntryComponent<IndividualToCompanyAccessCompositeModel> {
    @Input() applicationSource: ApplicationsEnum = ApplicationsEnum.Contacts;
    @Input() isCreateNewIndividual: boolean;
    @Input() isDualFormDirty: boolean;
    @Input() cardMode: CardModesEnum = CardModesEnum.hidden;
    @Input() viewMode: ViewModesEnum = ViewModesEnum.General;
    @Input() headerText: string;

    @Output() individualSaveSuccess: EventEmitter<IndividualModel> = new EventEmitter<IndividualModel>();

    constructor(private individualService: IndividualService) {
        super();
    }

    onSaveClicked(): void {
        if (this.isCreateNewIndividual) {
            this.individualService.createIndividualAccessComposite(this.value).subscribe((res: IndividualToCompanyAccessCompositeResponseModel): void => {
                    this.individualSaveSuccess.emit(new IndividualModel(this.value.individual));
                    this.onSaveSuccess();
                }, err => {
                    this.onSaveFailure(err);
                });
        } else {
            this.saveClick.emit();
        }
    }

    onCancelClicked(): void {
        this.cancelClick.emit();
    }
}
