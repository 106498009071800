import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BaseHttpService, BaseService, ToastService } from 'app/nexus-core/services/index';
import { BehaviorSubject, Observable } from 'rxjs';
import { DocumentModel } from 'app/nexus-shared/domain/documents/models/document.model';
import { DocumentSearchModel } from 'app/nexus-shared/domain/documents/models/document-search.model';
import { ApplicationsEnum, FileBlobHttpResponseModel } from 'app/nexus-shared';
import { DocumentCopyModel } from 'app/nexus-shared/domain/documents/models/document-copy.model';
import { DocumentDeleteModel } from 'app/nexus-shared/domain/documents/models/document-delete.model';
import { BlobStorageRequest, NexusBlobRequest } from 'app/nexus-core/services/blob/types/azure-storage';
import { concatMap, finalize, tap } from 'rxjs/operators';
import { BlobStorageService } from 'app/nexus-core/services/blob/services/blob-storage.service';
import { DocumentProcessModel } from 'app/nexus-shared/domain/documents/models/document-view-models/document-process.model';
import { DocumentProcessStatusesEnum } from 'app/nexus-shared/domain/documents/enums/document-process-statuses.enum';
import { IDocumentServiceInterface } from 'app/nexus-core/services/domain/documents/idocument-service.interface';

@Injectable()
export class DocumentService extends BaseService implements IDocumentServiceInterface {
    private apiUrl = environment().webAPIUrls.documents + 'documents/';

    constructor(private baseHttpService: BaseHttpService, private blobStorageService: BlobStorageService, private toastService: ToastService) {
        super();
    }

    bulkMetadata(documents: DocumentModel[]): Observable<boolean> {
        documents.forEach(x => x.sourceApp = ApplicationsEnum.Documents);
        return this.baseHttpService.put(`${this.apiUrl}bulk-metadata`, documents);
    }

    bulkCopy(documentCopies: DocumentCopyModel[]): Observable<string[]> {
        return this.baseHttpService.post(`${this.apiUrl}bulk-copy`, documentCopies);
    }

    create(documentProcess: DocumentProcessModel): BehaviorSubject<DocumentProcessModel> {
        const mainObservable$: BehaviorSubject<DocumentProcessModel> = new BehaviorSubject(documentProcess);
        this.uploadToBlob(documentProcess, mainObservable$);
        return mainObservable$;
    }

    copyDocument(documentCopy: DocumentCopyModel): Observable<string> {
        return this.baseHttpService.post(`${this.apiUrl}copy`, documentCopy);
    }

    delete(model: DocumentDeleteModel): Observable<boolean> {
        return this.baseHttpService.post(`${this.apiUrl}delete`, model);
    }

    download(documentKey: string): Observable<FileBlobHttpResponseModel> {
        return this.baseHttpService.get(`${this.apiUrl}${documentKey}/download`, null, 'blob');
    }

    downloadHistorical(documentKey: string, historicalKey: string): Observable<FileBlobHttpResponseModel> {
        return this.baseHttpService.get(`${this.apiUrl}${documentKey}/${historicalKey}/download`, null, 'blob');
    }

    get(documentKey: string): Observable<DocumentModel> {
        return this.baseHttpService.get(`${this.apiUrl}${documentKey}`);
    }

    getRecentlyCheckedOut(): Observable<DocumentModel[]> {
        return this.baseHttpService.get(`${this.apiUrl}recently-checked-out`);
    }

    getSasToken(): Observable<BlobStorageRequest> {
        return this.baseHttpService.get(`${this.apiUrl}token`);
    }

    preview(key: string): Observable<FileBlobHttpResponseModel> {
        return this.baseHttpService.get(`${this.apiUrl}${key}/preview`, null, 'blob');
    }

    previewHistorical(documentKey: string, historicalKey: string): Observable<FileBlobHttpResponseModel> {
        return this.baseHttpService.get(`${this.apiUrl}${documentKey}/${historicalKey}/preview`, null, 'blob');
    }

    restoreVersion(model: DocumentModel): Observable<boolean> {
        return this.baseHttpService.put(`${this.apiUrl}restore`, model);
    }

    restoreMostRecentVersion(model: DocumentModel): Observable<boolean> {
        return this.baseHttpService.put(`${this.apiUrl}restore-most-recent`, model);
    }

    scanVirus(): void {  // this is a manual kick off not to be used in any environment other than local
        this.baseHttpService.post(`${this.apiUrl}move-uploaded-files`, {}).subscribe(res => {
        });
    }

    search(search: DocumentSearchModel): Observable<DocumentModel[]> {
        return this.baseHttpService.post(`${this.apiUrl}search`, search).pipe(tap(res => {
            if (res?.length >= 2000) {
                this.toastService.showMessageToast('Result set exceed 2,000 records, try narrowing your search results by using the filters.');
            }
        }));
    }

    update(documentProcess: DocumentProcessModel): BehaviorSubject<any> {
        const mainObservable$: BehaviorSubject<DocumentProcessModel> = new BehaviorSubject<DocumentProcessModel>(documentProcess);
        this.uploadToBlob(documentProcess, mainObservable$, false);
        return mainObservable$;
    }

    updateMetadata(model: DocumentModel): Observable<boolean> {
        model.sourceApp = ApplicationsEnum.Documents;
        return this.baseHttpService.put(`${this.apiUrl}metadata`, model);
    }

    validateCreate(model: DocumentModel): Observable<boolean> {
        return this.baseHttpService.post(`${this.apiUrl}validate-create`, model);
    }

    validateUpdate(model: DocumentModel): Observable<boolean> {
        return this.baseHttpService.post(`${this.apiUrl}validate-update`, model);
    }

    getFileData(model: DocumentModel, file: File): DocumentModel {
        model.file.fullFileName = file.name;
        model.file.sizeInBytes = file.size;
        model.file.contentType = file.type;

        return model;
    }

    private uploadToBlob(documentProcess: DocumentProcessModel, mainObservable$: BehaviorSubject<any>, isCreate: boolean = true): void {
        this.getSasToken().pipe(concatMap(blobStorageRequest => {
            const request = blobStorageRequest as NexusBlobRequest;
            documentProcess.document.sourceApp = ApplicationsEnum.Documents;
            request.filename = documentProcess.document.documentKey;
            return this.blobStorageService.newUploadToBlobStorage(documentProcess.uploadFile, request).pipe(this.blobStorageService.mapUploadResponse(documentProcess.uploadFile, request), tap(x => {
                documentProcess.uploadProgress = x.progress;
                mainObservable$.next(documentProcess);
                if (x.progress >= 100) {
                    if (isCreate) {
                        this.baseHttpService.post(this.apiUrl, documentProcess.document).pipe(finalize(() => {
                            mainObservable$.complete();
                        })).subscribe(res => {
                            if (res) {
                                documentProcess.processStatus = DocumentProcessStatusesEnum.VirusScanning;
                                documentProcess.document = new DocumentModel(res);
                                mainObservable$.next(documentProcess);
                            }
                        }, err => {
                            mainObservable$.error(err);
                        });
                    } else {
                        this.baseHttpService.put(this.apiUrl, documentProcess.document).pipe(finalize(() => {
                            mainObservable$.complete();
                        })).subscribe(res => {
                            if (res) {
                                documentProcess.processStatus = DocumentProcessStatusesEnum.VirusScanning;
                                documentProcess.document.isVirusScanned = false;
                                mainObservable$.next(documentProcess);
                                mainObservable$.complete();
                            }
                        }, err => {
                            mainObservable$.error(err);
                        });
                    }
                }
            }));
        })).subscribe(_ => {
        }, err => {
            mainObservable$.error(err);
        });
    }


}
