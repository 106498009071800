<form [formGroup]="formGroup">
    <h1 class="page-top-header">
        Tax
        <span *ngIf="name"> -</span>
        {{ name }}
    </h1>
    <div *ngIf="returnName" class="return">
        <i (click)="return()" class="fa fa-reply" matTooltip="Return to {{returnName}}"></i>
        <span (click)="return()" matTooltip="Return to {{returnName}}">{{ returnName }}</span>
    </div>
    <div class="row">
        <div #expandableCardContainer class="gtn-sidebar">
            <mat-card appearance="outlined" #expandableCard class="expandable-card"
                [ngClass]="{'collapsed' : !isCardOpen}">
                <div class="gtn-expandable-card-toggle-container">
                    <div class="text-right">
                        <a href="javascript:void(0);" *ngIf="isCardOpen" (click)="toggleCardExpand(false)"><i
                            class="fas fa-times"></i></a>
                    </div>
                    <div #leftPanel *ngIf="!isCardOpen" class="read-only-form">
                        <mat-card-title>
                            {{ name }}{{ tax?.shortName ? ' (' + tax.shortName + ')' : '' }}
                            <ng-container *gtnIfHasAuthorization="authorizationConstants.taxesAdmin">
                                <a href="javascript:void(0);" class="card-title-icon" *ngIf="!isCardOpen"
                                    (click)="toggleCardExpand(true)">
                                    <i class="fas fa-edit"></i>
                                </a>
                            </ng-container>
                        </mat-card-title>
                        <mat-card-content>
                            <div class="data-field">{{ locationName }}</div>
                            <label class="list-label">Withholding type</label>
                            <div class="data-field">
                                {{ tax?.taxWithholdingType.name }}
                            </div>
                            <label class="list-label">Start date</label>
                            <div class="data-field">
                                {{ tax?.effectiveStartDate | gtnDate }}
                            </div>
                            <label class="list-label">End date</label>
                            <div class="data-field" [ngClass]="{'none-value-weight': !tax?.effectiveEndDate}">
                                {{ tax?.effectiveEndDate ? (tax.effectiveEndDate | gtnDate) : '&lt;none&gt;' }}
                            </div>
                            <label class="list-label">Display order</label>
                            <div class="data-field">
                                {{ tax?.displayOrder }}
                            </div>
                            <div *ngIf="selectedIncomePeriodType">
                                <label class="list-label">Income basis for rates</label>
                                <div class="data-field">
                                    {{ selectedIncomePeriodType.value }}
                                </div>
                            </div>
                            <label class="list-label">Specialization</label>
                            <div class="data-field" [ngClass]="{'none-value-weight':!tax?.specializationTitle}">
                                {{ tax?.specializationTitle ? tax?.specializationTitle : '&lt;none&gt;' }}
                            </div>

                        </mat-card-content>
                    </div>
                    <div #rightPanel *ngIf="isCardOpen">
                        <div class="row">
                            <gtn-input class="col-md-8" [formControlName]="formControlNames.name" type="string"
                                label="Name"></gtn-input>
                            <gtn-input class="col-md-4" [formControlName]="formControlNames.shortName" type="string"
                                label="Short name">
                            </gtn-input>
                            <div class="col-md-6 mat-child-card">
                                <mat-card appearance="outlined">
                                    <gtn-country-select [formControlName]="formControlNames.countryId" [idOnly]="true">
                                    </gtn-country-select>

                                    <gtn-state-select [formControlName]="formControlNames.stateId" [idOnly]="true"
                                        [countryId]="formGroup.controls['countryId'].value" [showBlank]="true">
                                    </gtn-state-select>

                                    <gtn-city-select *ngIf="!isCountySelectVisible"
                                        [formControlName]="formControlNames.cityId" [idOnly]="true"
                                        [countryId]="formGroup.controls['countryId'].value"
                                        [stateId]="formGroup.controls['stateId'].value" [showBlank]="true"
                                        [customOptions]="localLocationModel" customOptionsLocation="top">
                                    </gtn-city-select>

                                    <gtn-county-select *ngIf="isCountySelectVisible"
                                        [formControlName]="formControlNames.countyId" [idOnly]="true"
                                        [stateId]="formGroup.controls['stateId'].value" [showBlank]="true">
                                    </gtn-county-select>

                                    <div class="row">
                                        <div class="col-12 text-right county-checkbox-row">
                                            <gtn-checkbox class="county-checkbox"
                                                (change)="onCityCountyCheckboxChanged($event['value'])"
                                                [value]="isCountySelectVisible" [label]="'Is county tax'"
                                                [labelPosition]="'before'">
                                            </gtn-checkbox>
                                        </div>
                                    </div>

                                </mat-card>
                            </div>
                            <div class="col-md-6 mat-child-card">
                                <mat-card appearance="outlined">
                                    <gtn-dropdown class="" [formControlName]="formControlNames.taxWithholdingTypeId"
                                        [idOnly]="true" idKey="taxWithholdingTypeId" displayKey="name"
                                        label="Withholding type" [options]="withholdingTypes">
                                    </gtn-dropdown>
                                    <gtn-checkbox *ngIf="formGroupHelper.isWithholdingTypeComposite"
                                        class="align-checkbox"
                                        [formControlName]="formControlNames.compositeReplacesAllOtherTaxes"
                                        label="Replace all other taxes">
                                    </gtn-checkbox>
                                    <gtn-multiselect *ngIf="formGroupHelper.isWithholdingTypeComposite"
                                        [formControlName]="formControlNames.compositeTaxComponents" [idOnly]="true"
                                        idKey="taxWithholdingTypeId" displayKey="name" label="Other taxes to replace"
                                        [options]="replaceableWithholdingTypes">
                                    </gtn-multiselect>
                                </mat-card>
                            </div>
                            <gtn-datepicker class="col-md-6" [formControlName]="formControlNames.effectiveStartDate"
                                label="Start date">
                            </gtn-datepicker>
                            <gtn-datepicker class="col-md-6" [formControlName]="formControlNames.effectiveEndDate"
                                label="End date">
                            </gtn-datepicker>
                            <gtn-input class="col-md-6" [formControlName]="formControlNames.displayOrder" type="string"
                                label="Display order"></gtn-input>
                            <gtn-dropdown class="col-md-6" [formControlName]="formControlNames.limitIncomePeriodType"
                                [options]="limitIncomePeriodTypes" label="Income basis for rates" [idOnly]="true"
                                (change)="onIncomePeriodTypeChanged($event['value'])">
                            </gtn-dropdown>
                            <gtn-input class="col-12" *ngIf="!specializations" [ngClass]="{'override-disabled-input-font-weight':
                                tax.specializationTitle}" [formControlName]="formControlNames.specializationTitle"
                                type="string" label="Specialization title">
                            </gtn-input>
                            <gtn-input class="col-12" *ngIf="!specializations"
                                [formControlName]="formControlNames.specializationKey" type="string"
                                label="Specialization key">
                            </gtn-input>
                        </div>
                        <div *ngIf="isCardOpen" class="card-edit-form-button-group">
                            <button mat-flat-button (click)="save()" [disabled]="formGroup.invalid || !formGroup.dirty"
                                class="mat-accent save" id="saveButton">Save
                            </button>
                            <button mat-flat-button (click)="cancel()" class="mat-button-default cancel"
                                id="cancelButton">Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
        <div #expandableMainContent class="gtn-main-content grid-container">
            <div class="grid-container-header">
                <h3>Rates</h3>

                <gtn-filter [component]="panelComponent" (newRecord)="createNewRate()"
                    (applyEmitter)="applyFilter($event)">
                </gtn-filter>
                <div class="grid-container-body">
                    <gtn-grid #gtnBaseGrid [columnDefs]="columnDefs" [defaultSortModel]="defaultSortModel"
                        [data]="taxRates" [isLoading]="isLoading" [getRowStyle]="rateActiveCheck"
                        (rowClick)="onRowClick($event)">
                    </gtn-grid>
                </div>
            </div>

        </div>
    </div>
</form>
