export class LocalStorageNameConstants {
    static readonly gtnTimelineSettings = 'gtnTimelineSettings';
    static readonly TaxesGrid: string = 'gtnTaxesGrid';
    static readonly TaxRatesGrid: string = 'gtnTaxesRateGrid';
    static readonly StartMenuOpened: string = 'gtnStartMenuOpened';
    static readonly deviceId: string = 'gtnDeviceId';
    static readonly twoFactorMethodKey: string = 'gtnTwoFactorMethod';
    static readonly browserCheckAcknowledged: string = 'gtnBrowserCheckAcknowledged';
    static readonly documentTranslation: string = 'gtnDocumentTranslation';
}
