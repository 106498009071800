export enum ContactIntegrationTypesEnum {
    Unknown = 0,
    Acc1st = 10,
    BillDotCom = 20,
    CCHClientCode = 30,
    CCHClientEngagementCode = 31,
    SageIntacct = 40,
    Documents = 50,
    TractionClient = 60,
    TractionEmployee = 70,
    BigTimeClientSystemId = 80,
    BigTimeProjectSystemId = 90,
    BigTimeStaffSystemId = 100
}
