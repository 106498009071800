import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DraggableItemModel } from 'app/nexus-shared/components/controls/shared/models/draggable-item.model';
import { NexusModalService } from 'app/nexus-core';
import { ConfirmComponent } from 'app/nexus-shared/components/modal';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseControlComponent } from '../base-control.component';

@Component({
    selector: 'gtn-draggable-item-list',
    templateUrl: './draggable-item-list.component.html',
    styleUrls: ['./draggable-item-list.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DraggableItemListComponent),
            multi: true
        }
    ]
})
export class DraggableItemListComponent<T> extends BaseControlComponent<DraggableItemModel<T>[]> {

    @Input() selectedItem: DraggableItemModel<T>;
    @Input() confirmModalTitle: string;
    @Input() isDragAllowed: boolean = true;
    @Input() isRemoveAllowed: boolean = true;
    @Input() isRemoveOnDropOutsideContainer: boolean = this.isRemoveAllowed && this.isDragAllowed;

    @Output() itemClick: EventEmitter<DraggableItemModel<T>> = new EventEmitter();

    public selectedItemId: number;
    public removeButtonText = 'Remove';

    constructor(private modalService: NexusModalService) {
        super();
    }

    onDropped(event: CdkDragDrop<any>) {
        const items = this.value;

        // if item is outside of the container then remove it, otherwise reorder list
        if (!event.isPointerOverContainer && this.isRemoveOnDropOutsideContainer) {
            const item = items.find(i => i.orderIndex === event.currentIndex);
            this.onRemoveItemClicked(item);
        } else {
            moveItemInArray(items, event.previousIndex, event.currentIndex);
            this.setOrderIndex(items);
            this.value = items;
        }
    }

    onItemClicked(item: DraggableItemModel<T>) {
        this.itemClick.emit(item);
    }

    onRemoveItemClicked(item: DraggableItemModel<T>) {
        if (this.isRemoveAllowed) {
            this.modalService.init(ConfirmComponent, {
                mainHeaderText: this.confirmModalTitle,
                message: `Are you sure you want to ${this.removeButtonText.toLowerCase()} "${item.label}"?`,
                okButtonText: 'Yes',
                cancelButtonText: 'No'
            }, {
                ok: () => {
                    const items = this.value;

                    const itemIndex = items.findIndex(i => i.value === item.value);
                    items.splice(itemIndex, 1);

                    this.setOrderIndex(items);

                    this.value = items;
                },
                cancel: () => { }
            });
        }
    }

    private setOrderIndex(items: DraggableItemModel<T>[]) {
        for (let i = 0; i < items.length; i++) {
            items[i].orderIndex = i;
        }
    }
}
