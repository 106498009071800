import { RelationshipEndReasonTypesEnum } from 'app/nexus-shared/domain/contacts/enums/relationship-end-reason-types.enum';
import { CompanyModel } from 'app/nexus-shared/domain/contacts/models/company.model';
import { IndividualModel } from 'app/nexus-shared/domain/contacts/models/individual.model';
import { CostCenterModel } from 'app/nexus-shared/domain/contacts/models/cost-center.model';
import { GtnDepartmentsEnum } from 'app/nexus-shared/domain/contacts/enums/gtn-departments.enum';
import { IndividualTypesEnum } from 'app/nexus-shared/domain/contacts/enums/individual-types.enum';
import { RelationshipSubTypesEnum } from 'app/nexus-shared/domain/contacts/enums/relationship-sub-types.enum';
import { PhoneNumberModel } from 'app/nexus-shared/models/phone-number.model';
import { CommonBaseAuditModel } from 'app/nexus-shared/domain/contacts/models/common-base-audit.model';

export class IndividualToCompanyAccessModel extends CommonBaseAuditModel {
    individualToCompanyAccessKey?: string;
    individual?: IndividualModel;
    company?: CompanyModel;
    effectiveStartDate?: Date | string;
    effectiveEndDate?: Date | string;
    createdOn?: Date | string;
    lastModifiedOn?: Date | string;
    emailAddress?: string;
    employeeId?: string;
    jobTitle?: string;
    primaryPhoneNumber: PhoneNumberModel;
    secondaryPhoneNumber: PhoneNumberModel;
    companyCostCenter: CostCenterModel;
    relationshipEndReasonType?: RelationshipEndReasonTypesEnum;
    department?: GtnDepartmentsEnum;
    relationshipType: IndividualTypesEnum;
    relationshipSubTypes: RelationshipSubTypesEnum[];
    notes: string;

    constructor(model: IndividualToCompanyAccessModel = null) {
        super(model);
        this.individualToCompanyAccessKey = model?.individualToCompanyAccessKey ?? null;
        this.individual = model?.individual ?? null;
        this.company = model?.company ?? null;
        this.effectiveStartDate = model?.effectiveStartDate ?? null;
        this.effectiveEndDate = model?.effectiveEndDate ?? null;
        this.createdOn = model?.createdOn ?? null;
        this.lastModifiedOn = model?.lastModifiedOn ?? null;
        this.emailAddress = model?.emailAddress ?? null;
        this.employeeId = model?.employeeId ?? null;
        this.jobTitle = model?.jobTitle ?? null;
        this.primaryPhoneNumber = model?.primaryPhoneNumber ? new PhoneNumberModel(model?.primaryPhoneNumber) : new PhoneNumberModel();
        this.secondaryPhoneNumber = model?.secondaryPhoneNumber ? new PhoneNumberModel(model?.secondaryPhoneNumber) : new PhoneNumberModel();
        this.companyCostCenter = model?.companyCostCenter ? new CostCenterModel(model.companyCostCenter) : null;
        this.relationshipEndReasonType = model?.relationshipEndReasonType ?? null;
        this.department = model?.department ?? null;
        this.relationshipType = model?.relationshipType ?? null;
        this.relationshipSubTypes = model?.relationshipSubTypes ?? [];
        this.notes = model?.notes ?? null;
    }
}
