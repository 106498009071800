import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { NexusModalService } from 'app/nexus-core/services';
import { NexusAnimations } from 'app/nexus-shared/animations';

@Component({
    templateUrl: './confirm.component.html',
    styleUrls: [
        '../../nexus-modal.component.scss',
        './confirm.component.scss'
    ],
    animations: [
        NexusAnimations.modalFadeIn
    ]
})
export class ConfirmComponent implements AfterViewInit {
    @ViewChild('interactionInput', { static: true }) interactionInput;
    // Not setting these as inputs because the dynamic component loader sets them.
    cancel: Function;
    cancelButtonText: string;
    formGroup: UntypedFormGroup = new UntypedFormGroup({});
    interactionInfo: string;
    mainHeaderText: string;
    message: string;
    ok: Function;
    okButtonText: string;
    showCloseButton: boolean;
    subHeaderText: string;
    cancelClick: Function = (() => {
        if (this.cancel) {
            this.cancel();
        }
        this.destroy();
    });
    showHideConfirmModalCheckbox: boolean;
    hideConfirmationModalSelected = false;

    okClick: Function = (() => {
        if (this.ok) {
            this.ok(this.hideConfirmationModalSelected);
        }
        this.destroy();
    }).bind(this);

    constructor(
        private formBuilder: UntypedFormBuilder,
        private modalService: NexusModalService
    ) {
        this.formGroup = formBuilder.group({
            interactionInfo: ['']
        });
    }

    ngAfterViewInit() {
        if (this.interactionInfo) {
            this.interactionInput.nativeElement.focus();
            const interactionInfo = this.formGroup.controls.interactionInfo;
            interactionInfo.setValidators(Validators.required);
            // This is a hack to stop the changed after checked error received when updating the value and validity.
            setTimeout(() => {
                interactionInfo.updateValueAndValidity();
            });
        } else {
            // const interactionInfo = this.formGroup.controls.interactionInfo;
            // interactionInfo.clearValidators();
            // interactionInfo.updateValueAndValidity();
        }
    }

    onHideConfirmModal(hideConfirmModal: boolean) {
        this.hideConfirmationModalSelected = hideConfirmModal;
    }

    destroy(): void {
        this.modalService.destroy();
    }
}
