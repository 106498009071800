import { BaseRouteApplicationConfigurationModel } from 'app/nexus-shared/models';
import { DocumentRoutingKeyConstants } from 'app/modules/documents/routing/document-routing-key.constants';
import { AreaTypesEnum } from 'app/nexus-shared/domain/documents/enums/area-types.enum';
import { NexusRoutingKeyConstants } from 'app/nexus-routing/nexus-routing-key.constants';
import { AuthorizationConstants } from 'app/nexus-shared/constants/authorization.constants';

export class DocumentsRoutingConfigurationConstants {
    static baseRoute: string = 'documents';

    static readonly configurations = {
        getRoot: (): BaseRouteApplicationConfigurationModel => {
            return {
                routing: {
                    routerLink: [DocumentsRoutingConfigurationConstants.baseRoute],
                    routerModulePath: '',
                    navigationRoute: () => [`${DocumentsRoutingConfigurationConstants.baseRoute}`]
                }
            };
        },
        getDashboard: (): BaseRouteApplicationConfigurationModel => {
            return {
                menu: {
                    title: 'Dashboard',
                    iconClass: 'fas fa-grid-2',
                    order: 10
                },
                routing: {
                    useExact: true,
                    routerLink: [`${DocumentsRoutingConfigurationConstants.baseRoute}/dashboard`],
                    routerModulePath: 'dashboard',
                    navigationRoute: () => [`${DocumentsRoutingConfigurationConstants.baseRoute}/dashboard`]
                }
            };
        },
        getContactsRedirect: (): BaseRouteApplicationConfigurationModel => {
            return {
                routing: {
                    routerLink: [`${DocumentsRoutingConfigurationConstants.baseRoute}/${NexusRoutingKeyConstants.contactsRedirect}`],
                    routerModulePath: NexusRoutingKeyConstants.contactsRedirect,
                    navigationRoute: () => [`${DocumentsRoutingConfigurationConstants.baseRoute}/${NexusRoutingKeyConstants.contactsRedirect}`]
                }
            };
        },
        getTax: (): BaseRouteApplicationConfigurationModel => {
            return {
                applicationMenuId: AreaTypesEnum.Tax,
                menu: {
                    title: 'Tax Practice',
                    iconClass: 'fa-solid fa-cabinet-filing',
                    isExpanded: true,
                    order: 20
                },
                routing: {
                    useExact: false
                },
                childRoutes: [
                    DocumentsRoutingConfigurationConstants.documentAreaConfigurations.getClientsArea(),
                    DocumentsRoutingConfigurationConstants.documentAreaConfigurations.getResourcesArea(),
                    DocumentsRoutingConfigurationConstants.documentAreaConfigurations.getSolutionsArea(),
                    DocumentsRoutingConfigurationConstants.documentAreaConfigurations.getTrainingArea(),
                    DocumentsRoutingConfigurationConstants.configurations.getFileSearch(),
                    DocumentsRoutingConfigurationConstants.documentAreaConfigurations.getFileTemplatesArea(),
                    DocumentsRoutingConfigurationConstants.documentAreaConfigurations.getFolderTemplatesArea(),
                ]
            };
        },
        getDocumentTranslation: (): BaseRouteApplicationConfigurationModel => {
            return {
                menu: {
                    title: 'Translation',
                    iconClass: 'fas fa-language',
                    order: 30
                },
                routing: {
                    useExact: true,
                    routerLink: [`${DocumentsRoutingConfigurationConstants.baseRoute}/document-translation`],
                    routerModulePath: 'document-translation',
                    navigationRoute: () => [`${DocumentsRoutingConfigurationConstants.baseRoute}/document-translation`]
                }
            };
        },
        getFileSearch: (): BaseRouteApplicationConfigurationModel => {
            return {
                menu: {
                    title: 'File Search',
                    iconClass: 'fas fa-file-magnifying-glass',
                    order: 50
                },
                routing: {
                    useExact: true,
                    routerLink: [`${DocumentsRoutingConfigurationConstants.baseRoute}/file-search`],
                    routerModulePath: 'file-search',
                    navigationRoute: ()=> [`${DocumentsRoutingConfigurationConstants.baseRoute}/file-search`]
                }
            };
        }
    };

    // area routes must be added here to route to based on the document area type
    static readonly documentAreaConfigurations = {
        getClientsArea: (): BaseRouteApplicationConfigurationModel => {
            return {
                applicationMenuId: AreaTypesEnum.Clients,
                menu: {
                    title: 'Clients',
                    iconClass: 'fa-duotone fa-briefcase-blank',
                    order: 10
                },
                routing: {
                    useExact: true,
                    routerLink: [`${DocumentsRoutingConfigurationConstants.baseRoute}/clients/${DocumentRoutingKeyConstants.areaKeys.clients}/all`],
                    routerModulePath: `clients`,
                    navigationRoute: () => [`${DocumentsRoutingConfigurationConstants.baseRoute}/clients`]
                },
            };
        },
        getClients: (): BaseRouteApplicationConfigurationModel => {
            return {
                routing: {
                    useExact: true,
                    routerModulePath: `:${DocumentRoutingKeyConstants.routeParamKeys.area}/all`,
                    navigationRoute: () => [`${DocumentsRoutingConfigurationConstants.baseRoute}/clients/${DocumentRoutingKeyConstants.areaKeys.clients}/all`]
                },
            };
        },
        getClient: (): BaseRouteApplicationConfigurationModel => {
            return {
                routing: {
                    useExact: true,
                    routerModulePath: `:${DocumentRoutingKeyConstants.routeParamKeys.client}/client`,
                    navigationRoute: (key: string) => [`${DocumentsRoutingConfigurationConstants.baseRoute}/clients/${key}/client`]
                },
            };
        },
        getClientRedirect: (): BaseRouteApplicationConfigurationModel => {
            return {
                routing: {
                    routerLink: [`${DocumentsRoutingConfigurationConstants.baseRoute}/clients/redirect`],
                    routerModulePath: 'redirect',
                    navigationRoute: () => [`${DocumentsRoutingConfigurationConstants.baseRoute}/clients/redirect`]
                }
            };
        },
        getResourcesArea: (): BaseRouteApplicationConfigurationModel => {
            return {
                applicationMenuId: AreaTypesEnum.Resources,
                menu: {
                    title: 'Resources',
                    iconClass: 'fas fa-box-archive',
                    order: 20
                },
                routing: {
                    useExact: true,
                    routerLink: [`${DocumentsRoutingConfigurationConstants.baseRoute}/resources/${DocumentRoutingKeyConstants.areaKeys.resources}`],
                    routerModulePath: `resources/:${DocumentRoutingKeyConstants.routeParamKeys.area}`,
                    navigationRoute: () => [`${DocumentsRoutingConfigurationConstants.baseRoute}/resources/${DocumentRoutingKeyConstants.areaKeys.resources}`]
                },
            };
        },
        getSolutionsArea: (): BaseRouteApplicationConfigurationModel => {
            return {
                applicationMenuId: AreaTypesEnum.Solutions,
                menu: {
                    title: 'Solutions',
                    iconClass: 'fa-duotone fa-puzzle',
                    order: 30
                },
                routing: {
                    useExact: true,
                    routerLink: [`${DocumentsRoutingConfigurationConstants.baseRoute}/solutions/${DocumentRoutingKeyConstants.areaKeys.solutions}`],
                    routerModulePath: `solutions/:${DocumentRoutingKeyConstants.routeParamKeys.area}`,
                    navigationRoute: () => [`${DocumentsRoutingConfigurationConstants.baseRoute}/solutions/${DocumentRoutingKeyConstants.areaKeys.solutions}`]
                },
            };
        },
        getTrainingArea: (): BaseRouteApplicationConfigurationModel => {
            return {
                applicationMenuId: AreaTypesEnum.Training,
                menu: {
                    title: 'Training',
                    iconClass: 'fa-regular fa-laptop-file',
                    order: 40
                },
                routing: {
                    useExact: true,
                    routerLink: [`${DocumentsRoutingConfigurationConstants.baseRoute}/training/${DocumentRoutingKeyConstants.areaKeys.training}`],
                    routerModulePath: `training/:${DocumentRoutingKeyConstants.routeParamKeys.area}`,
                    navigationRoute: () => [`${DocumentsRoutingConfigurationConstants.baseRoute}/training/${DocumentRoutingKeyConstants.areaKeys.training}`]
                },
            };
        },
        getFileTemplatesArea: (): BaseRouteApplicationConfigurationModel => {
            return {
                authorization: [AuthorizationConstants.documentsAdmin],
                applicationMenuId: AreaTypesEnum.FileTemplates,
                menu: {
                    title: 'File Templates',
                    iconClass: 'fa-regular fa-files',
                    order: 60,
                    isHidden: true
                },
                routing: {
                    useExact: true,
                    routerLink: [`${DocumentsRoutingConfigurationConstants.baseRoute}/file-templates/${DocumentRoutingKeyConstants.areaKeys.fileTemplates}`],
                    routerModulePath: `file-templates/:${DocumentRoutingKeyConstants.routeParamKeys.area}`,
                    navigationRoute: () => [`${DocumentsRoutingConfigurationConstants.baseRoute}/file-templates/${DocumentRoutingKeyConstants.areaKeys.fileTemplates}`]
                },
            };
        },
        getFolderTemplatesArea: (): BaseRouteApplicationConfigurationModel => {
            return {
                authorization: [AuthorizationConstants.documentsAdmin],
                applicationMenuId: AreaTypesEnum.FolderTemplates,
                menu: {
                    title: 'Folder Templates',
                    iconClass: 'fa-regular fa-folders',
                    order: 70,
                    isHidden: true
                },
                routing: {
                    useExact: true,
                    routerLink: [`${DocumentsRoutingConfigurationConstants.baseRoute}/folder-templates/${DocumentRoutingKeyConstants.areaKeys.folderTemplates}`],
                    routerModulePath: `folder-templates/:${DocumentRoutingKeyConstants.routeParamKeys.area}`,
                    navigationRoute: () => [`${DocumentsRoutingConfigurationConstants.baseRoute}/folder-templates/${DocumentRoutingKeyConstants.areaKeys.folderTemplates}`]
                },
            };
        },
        getDepartmentsArea: (): BaseRouteApplicationConfigurationModel => {
            return {
                applicationMenuId: AreaTypesEnum.Teams,
                menu: {
                    title: 'Teams',
                    iconClass: 'fas fa-users-rectangle',
                    order: 30
                },
                routing: {
                    useExact: true,
                    routerLink: [`${DocumentsRoutingConfigurationConstants.baseRoute}/teams/${DocumentRoutingKeyConstants.areaKeys.teams}`],
                    routerModulePath: `teams/:${DocumentRoutingKeyConstants.routeParamKeys.area}`,
                    navigationRoute: () => [`${DocumentsRoutingConfigurationConstants.baseRoute}/teams/${DocumentRoutingKeyConstants.areaKeys.teams}`]
                },
            };
        },
        getPersonalArea: (): BaseRouteApplicationConfigurationModel => {
            return {
                applicationMenuId: AreaTypesEnum.Personal,
                menu: {
                    title: 'Personal',
                    iconClass: 'fa-regular fa-folder-user',
                    order: 40
                },
                routing: {
                    useExact: true,
                    routerLink: [`${DocumentsRoutingConfigurationConstants.baseRoute}/personal/${DocumentRoutingKeyConstants.areaKeys.personal}`],
                    routerModulePath: `personal/:${DocumentRoutingKeyConstants.routeParamKeys.area}`,
                    navigationRoute: () => [`${DocumentsRoutingConfigurationConstants.baseRoute}/personal/${DocumentRoutingKeyConstants.areaKeys.personal}`]
                },
            };
        }

    };

    static mainMenuItems = [
        DocumentsRoutingConfigurationConstants.configurations.getDashboard(),
        DocumentsRoutingConfigurationConstants.configurations.getDocumentTranslation(),
        DocumentsRoutingConfigurationConstants.configurations.getTax(),
        DocumentsRoutingConfigurationConstants.documentAreaConfigurations.getPersonalArea(),
        DocumentsRoutingConfigurationConstants.documentAreaConfigurations.getDepartmentsArea(),
    ];
}

