import { Component, Input } from '@angular/core';
import { BaseAssignQuestionnaireFormComponent } from 'app/nexus-shared/domain/questionnaires/components/forms/base-assign-questionnaire-form.component';
import { IndividualSupplementalOrganizerModel } from 'app/nexus-shared/domain/questionnaires/models/individual-supplemental-organizer.model';

@Component({
    selector: 'gtn-individual-supplemental-organizer-form',
    templateUrl: './individual-supplemental-organizer-form.component.html',
    styleUrls: ['./individual-supplemental-organizer-form.component.scss']
})
export class IndividualSupplementalOrganizerFormComponent extends BaseAssignQuestionnaireFormComponent<IndividualSupplementalOrganizerModel> {
    @Input() disableIndividualSearch: boolean = false;
}
