import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { DocumentModel } from 'app/nexus-shared/domain/documents/models/document.model';
import { DocumentService } from 'app/nexus-core/services/domain/documents/document.service';
import { DocumentSearchModel } from 'app/nexus-shared/domain/documents/models/document-search.model';
import { DateHelper, SortingHelper, SpinnerService } from 'app/nexus-core';
import { DocumentEventTypesEnum } from 'app/nexus-shared/domain/documents/enums/document-event-types.enum';
import { DocumentProcessService } from 'app/nexus-core/services/domain/documents/document-process.service';
import { DocumentsHelper } from 'app/nexus-core/helpers/documents.helper';
import { FileViewModel } from 'app/nexus-shared/components/controls/shared/models/file-view.model';
import { DocumentStatusesEnum } from 'app/nexus-shared/domain/documents/enums/document-statuses.enum';
import { finalize } from 'rxjs/operators';
import { ToastService } from 'app/nexus-core/services/toast.service';
import { VirusScanErrorsEnum } from 'app/nexus-shared/domain/documents/enums/virus-scan-errors.enum';
import { AreaTypesEnum } from 'app/nexus-shared/domain/documents/enums/area-types.enum';

@Component({
    selector: 'gtn-document-versions',
    templateUrl: './document-versions.component.html',
    styleUrls: ['./document-versions.component.scss']
})
export class DocumentVersionsComponent extends BaseComponent implements OnInit {
    @Input() documentKey: string;
    @Input() documentVersions: DocumentModel[];
    @Input() canEdit: boolean;
    @Input() canRestore: boolean;
    @Input() isVirusScanned: boolean;
    @Input() canPreview: boolean = false;
    @Input() areaType: AreaTypesEnum;

    @Output() restoreVersion: EventEmitter<void> = new EventEmitter();
    @Output() copyVersionToFolder: EventEmitter<DocumentModel> = new EventEmitter();

    documentStatuses = DocumentStatusesEnum;
    virusScanErrors = VirusScanErrorsEnum;
    fileView: FileViewModel;
    isDownloading: boolean = false;
    previewDocument: DocumentModel;
    showPreview: boolean = false;


    constructor(private documentService: DocumentService, private documentProcessService: DocumentProcessService, private toastService: ToastService) {
        super();
    }

    ngOnInit(): void {
        if (this.documentVersions?.length) {
            this.sortDocumentVersionsDescending();
            return;
        }

        const searchModel = <DocumentSearchModel>{
            documentKeys: [this.documentKey],
            includeHistory: true, areaTypes: [this.areaType]
        };

        this.documentService.search(searchModel).subscribe(res => {
            this.documentVersions = DocumentsHelper.getValidVersions(res);
            this.sortDocumentVersionsDescending();
        });
    }

    onDownloadClicked(document: DocumentModel): void {
        if (!this.isDownloading) {
            this.isDownloading = true;
            this.documentProcessService.downloadHistoricalDocument(document, document.historicalKey).subscribe(_ => {
                this.isDownloading = false;
            });
        }
    }

    onPreviewClicked(document: DocumentModel): void {
        this.previewDocument = new DocumentModel(document);
        SpinnerService.start();
        this.documentService.previewHistorical(document.documentKey, document.historicalKey).pipe(finalize(() => {
            SpinnerService.stop();
        })).subscribe(res => {
            this.fileView = new FileViewModel();
            this.fileView.blobSrc = res;
            this.fileView.isBlobSrc = true;
            this.fileView.name = document.name;
            this.fileView.type = DocumentsHelper.getPreviewFileType(document.file.extension);
            this.fileView.iconClass = DocumentsHelper.getFileTypeIcon(document.file.extension);
            this.showPreview = true;
        }, err => {
            this.toastService.showErrorToast(err);
        });
    }

    onRestoreVersionClicked(document: DocumentModel): void {
        document.eventType = DocumentEventTypesEnum.Restore;
        document.eventNote = null;
        this.documentService.restoreVersion(document).subscribe(res => {
            this.restoreVersion.emit();
        }, err => {
            console.error(err);
        });
    }

    onCopyVersionClicked(document: DocumentModel): void {
        this.copyVersionToFolder.emit(document);
    }

    private sortDocumentVersionsDescending(): void {
        this.documentVersions.sort((a, b) => {
            return SortingHelper.sortByDateComparerDesc(
                DateHelper.convertToFullUtcDate(a.lastModifiedByUser.changeDateTime),
                DateHelper.convertToFullUtcDate(b.lastModifiedByUser.changeDateTime)
            );
        });
    }
}
