<form [formGroup]="formGroupRef">
    <div class="row">
        <gtn-company-search *ngIf="!value?.companyRelationshipKey" class="col-md-8"
            formControlName="childCompany"
            label="Company"
            [showAddLink]="canCreateNew"
            [useModal]="false"
            (searchResultClick)="onCompanyAdded($event)"
            (addEntityClick)="onCreateNewCompanyClicked()">
        </gtn-company-search>
        <div class="col-md-6" *ngIf="!!value?.companyRelationshipKey && !isParentChildRelationship">
            <label class="list-label">
                Company
            </label>
            <div class="data-field">
                {{value?.parentCompany.name}}
            </div>
        </div>
        <div class="col-md-6" *ngIf="!!value?.companyRelationshipKey && isParentChildRelationship">
            <label class="list-label">
                Company
            </label>
            <div class="data-field">
                {{value?.childCompany.name}}
            </div>
        </div>
        <gtn-checkbox class="col-md-2 flex flex-column justify-content-center" label="Is child"
            [disabled]="!!value.companyRelationshipKey"
            [value]="isParentChildRelationship"
            (change)="onIsParentChildRelationshipChanged($event['value'])"></gtn-checkbox>

    </div>
    <div class="row">
        <gtn-dropdown class="col-md-6" [options]="relationshipTypeOptions"
            [idOnly]="true"
            idKey="id"
            displayKey="value"
            sortBy="value"
            label="Relationship type"
            formControlName="relationshipType">
        </gtn-dropdown>
    </div>
    <div class="row">
        <gtn-datepicker class="col-6"
            formControlName="effectiveStartDate"
            label="Effective start date">
        </gtn-datepicker>
        <gtn-datepicker class="col-6"
            formControlName="effectiveEndDate"
            label="Effective end date"></gtn-datepicker>
    </div>
</form>
