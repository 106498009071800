import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AgreementsHelper } from 'app/nexus-core/helpers/agreements.helper';
import { AgreementStatusEnum } from '../../enums/agreement-status.enum';
import { AgreementWildcardConstants } from '../../constants/agreement-wildcard.constants';
import { CompanyModel } from 'app/nexus-shared/domain/contacts/models';
import { IndividualInterface } from 'app/nexus-shared/interfaces/individual.interface';
import { BaseFormComponent } from 'app/nexus-shared/components/base-component/base-form.component';
import { BaseFormModel } from 'app/nexus-shared/models/base-form.model';
import { IAgreementModelInterface } from '../../interfaces/iagreement-model.interface';
import { IAgreementTypeModelInterface } from '../../interfaces/iagreement-type-model.interface';
import { IAgreementReferentialValueModelInterface } from '../../interfaces/iagreement-referential-value-model.interface';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { SimpleChangesTyped } from 'app/nexus-shared/models/simple-changes-typed.type';

@Component({ template: '' })
export abstract class AgreementBaseFormComponent extends BaseFormComponent<IAgreementModelInterface> implements OnInit, OnChanges {
    @Input() agreementTypes: IAgreementTypeModelInterface[];
    @Input() formConfiguration: BaseFormModel;
    @Input() signerCompany: CompanyModel;
    @Input() signerIndividual: IndividualInterface;
    @Input() signerSpouse: IndividualInterface;
    @Output() formConfigurationChange: EventEmitter<BaseFormModel> = new EventEmitter<BaseFormModel>();
    @Output() spouseNameChange: EventEmitter<string> = new EventEmitter<string>();
    @Output() subtypeChange: EventEmitter<IAgreementTypeModelInterface> = new EventEmitter<IAgreementTypeModelInterface>();

    agreementsHelper = AgreementsHelper;
    agreementStatusEnum = AgreementStatusEnum;
    filteredAgreementTypes: IAgreementTypeModelInterface[] = [];
    signerName = '';
    signerEmail = '';

    ngOnInit(): void {
        const configuration = this.getFormConfiguration();
        super.ngOnInit(configuration);

        // break out of change detection cycle
        setTimeout(() => {
            this.initReferentialValues();
        }, 0);
    }

    ngOnChanges(changes: SimpleChangesTyped<this>) {
        if (changes.signerCompany && (!this.value.agreementKey || !changes.signerCompany.firstChange)) {
            // break out of change detection cycle
            setTimeout(() => {
                this.updateSignerCompany();
            }, 0);
        }

        if (changes.signerIndividual && (!this.value.agreementKey || !changes.signerIndividual.firstChange)) {
            // break out of change detection cycle
            setTimeout(() => {
                this.updateSignerIndividual(this.signerIndividual);
            }, 0);
        }
    }

    onAgreementSubtypeChanged($event) {
        this.formGroupRef.get(ObjectHelper.nameOf<IAgreementModelInterface>('agreementTypeKey')).setValue($event.value?.agreementTypeKey);
        this.subtypeChange.emit(this.value.agreementType);

        // break out of change detection cycle
        setTimeout(() => {
            this.updateSubtypeFlags();
        }, 0);
    }

    onSaveClicked() { }

    onSignerEmailChanged($event): void {
        const value = $event?.target?.value;

        if (value) {
            this.setReferentialValue(AgreementWildcardConstants.signerEmail, value);
        }
    }

    onSignerNameChanged($event): void {
        const value = $event?.target?.value;

        if (value) {
            this.setReferentialValue(AgreementWildcardConstants.fullName, value);
        }
    }

    onSpouseNameChanged($event): void {
        const value = $event?.target?.value;

        if (value) {
            this.spouseNameChange.emit(value);
        }
    }

    protected getDefaultReferentialValues(): IAgreementReferentialValueModelInterface[] {
        return this.value.agreementReferentialValues?.filter(x =>
            x.id === AgreementWildcardConstants.agreementNumber ||
            x.id === AgreementWildcardConstants.agreementRecipient ||
            x.id === AgreementWildcardConstants.agreementStatus ||
            x.id === AgreementWildcardConstants.agreementTypeName ||
            x.id === AgreementWildcardConstants.contactId ||
            x.id === AgreementWildcardConstants.createdDate ||
            x.id === AgreementWildcardConstants.fullName);
    }

    protected getFormConfiguration(): BaseFormModel {
        return this.formConfiguration ?? new BaseFormModel();
    }

    protected initReferentialValues() {
        if (this.value.status === AgreementStatusEnum.Draft) {
            const generatedReferentialValues = this.getDefaultReferentialValues();

            this.formGroupRef.get(ObjectHelper.nameOf<IAgreementModelInterface>('agreementReferentialValues')).setValue([]);
            this.setReferentialValues(generatedReferentialValues);
        }
    }

    protected initSigner() {
        this.signerName = this.agreementsHelper.getReferentialValue(this.value.agreementReferentialValues, AgreementWildcardConstants.fullName);
        if (!this.signerName) {
            setTimeout(() => {
                this.updateSignerName(this.signerIndividual, this.signerName);
            }, 0);
        }

        this.signerEmail = this.agreementsHelper.getReferentialValue(this.value.agreementReferentialValues, AgreementWildcardConstants.signerEmail);
        if (!this.signerEmail) {
            setTimeout(() => {
                this.updateSignerEmail(this.signerIndividual);
            }, 0);
        }
    }

    protected initUIControls(): void {
        this.filteredAgreementTypes = this.getFilteredAgreementTypes();

        // break out of change detection cycle
        setTimeout(() => {
            this.initReferentialValues();
            this.initSigner();
            this.updateSubtypeFlags();
        }, 0);
    }

    protected setReferentialValue(id: string, value: string) {
        this.value.agreementReferentialValues ??= [];
        this.agreementsHelper.setReferentialValue(this.value.agreementReferentialValues, id, value);

        this.formGroupRef.reset(this.value);
        this.formGroupRef.markAsDirty();
    }

    protected setReferentialValues(generatedReferentialValues: IAgreementReferentialValueModelInterface[]) {
        for (const value of generatedReferentialValues) {
            this.setReferentialValue(value.id, value.value);
        }
    }

    protected updateSignerCompany() {
        this.formGroupRef.get(ObjectHelper.nameOf<IAgreementModelInterface>('signerCompanyKey')).setValue(this.signerCompany?.companyKey);
    }

    protected updateSignerEmail(individual: IndividualInterface) {
        this.signerEmail = individual?.emailAddress;
        this.setReferentialValue(AgreementWildcardConstants.signerEmail, this.signerEmail);
    }

    protected updateSignerIndividual(individual: IndividualInterface) {
        this.formGroupRef.get(ObjectHelper.nameOf<IAgreementModelInterface>('signerIndividualKey')).setValue(individual?.individualKey);
        this.updateSignerReferentialValues(individual);
    }

    protected updateSignerName(individual: IndividualInterface, fullName?: string) {
        this.signerName = fullName ? fullName : individual?.fullName;
        this.setReferentialValue(AgreementWildcardConstants.fullName, this.signerName);
    }

    protected updateSignerReferentialValues(individual: IndividualInterface) {
        this.updateSignerName(individual);
        this.updateSignerEmail(individual);
    }

    protected updateSubtypeFlags() {

    }

    protected abstract getFilteredAgreementTypes(): IAgreementTypeModelInterface[];
}
