<gtn-template-card>
    <gtn-template-card-content>
        <div class="section-header">Report Data
        </div>
        <div class="reportOptions">
            <ng-container *ngFor="let reportOption of reportOptions">
                <div class="report-option" [ngClass]="{'active-report': selectedReportOption === reportOption.id}"
                    (click)="onReportOptionClicked(reportOption)">
                    {{ reportOption.value }}
                </div>
            </ng-container>
        </div>
        <div class="section-header">Data Sets</div>
        <div class="reportOptions">
            <ng-container *ngFor="let reportOption of gridOptions">
                <div class="report-option" [ngClass]="{'active-report': selectedReportOption === reportOption.id}"
                    (click)="onReportOptionClicked(reportOption)">
                    {{ reportOption.value }}
                </div>
            </ng-container>
        </div>
        <div class="section-header">Search Parameters</div>
        <div class="row">
            <gtn-datepicker class="col-12" label="Start date" [(ngModel)]="startDate"
                [suppressInitialChangeEvent]="true"
                (dateChanged)="onStartDateChanged($event)"></gtn-datepicker>
            <gtn-datepicker class="col-12" label="End date" [(ngModel)]="endDate" [suppressInitialChangeEvent]="true"
                (dateChanged)="onEndDateChanged($event)"></gtn-datepicker>
        </div>
    </gtn-template-card-content>
</gtn-template-card>
