import { ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Injectable, Injector } from '@angular/core';
import { DynamicComponentConfigInterface } from 'app/nexus-shared';

@Injectable()
export class DomService {
    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector
    ) {
    }

    appendComponentTo(parentId: string, component: any, componentConfig?: DynamicComponentConfigInterface, resolver: any = null): ComponentRef<any> {

        // Create a component reference from the component
        const componentRef = resolver ?
            resolver
                .resolveComponentFactory(component)
                .create(this.injector) :
            this.componentFactoryResolver
                .resolveComponentFactory(component)
                .create(this.injector);

        // Attach the config to the component (inputs and outputs)
        this.attachConfig(componentConfig, componentRef);

        // Attach component to the appRef so that it's inside the ng component tree
        this.appRef.attachView(componentRef.hostView);

        // Get DOM element from component
        const childDomElem = (componentRef.hostView as EmbeddedViewRef<any>)
            .rootNodes[0] as HTMLElement;

        // Append DOM element to the body
        document.getElementById(parentId).appendChild(childDomElem);

        return componentRef;
    }

    removeComponent(componentRef: ComponentRef<any>): void {
        if (componentRef) {
            this.appRef.detachView(componentRef.hostView);
            componentRef.destroy();
            componentRef = null;
        }
    }

    updateComponent(componentConfig: DynamicComponentConfigInterface, componentRef: ComponentRef<any>): void {
        if (componentRef) {
            this.attachConfig(componentConfig, componentRef);
        }
    }

    private attachConfig(config: DynamicComponentConfigInterface, componentRef: ComponentRef<any>): void {
        const inputs = config.inputs,
            outputs = config.outputs;

        for (const key in inputs) {
            if (inputs[key]) {
                componentRef.instance[key] = inputs[key];
            }
        }

        if (outputs) {
            for (const key in outputs) {
                if (outputs[key]) {
                    componentRef.instance[key] = outputs[key];
                }
            }
        }
    }
}

