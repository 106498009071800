import { Component, OnInit } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { MultiselectComponent } from 'app/nexus-shared/components/controls';
import { LocationModel } from 'app/nexus-shared/domain/locations/models';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';

@Component({
    template: ''
})
export abstract class BaseLocationMultiSelectComponent extends MultiselectComponent<LocationModel> implements ControlValueAccessor, OnInit {
    displayKey = ObjectHelper.nameOf<LocationModel>('name');
}
