import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseFormComponent } from 'app/nexus-shared/components/base-component/base-form.component';
import { PhoneNumberModel } from 'app/nexus-shared/models/phone-number.model';
import { LocationService } from 'app/nexus-core/services/location.service';
import { CountryCallingCode, getCountryCallingCode } from 'libphonenumber-js';
import { SelectListInterface } from 'app/nexus-shared/interfaces';
import { EnumHelper } from 'app/nexus-core';
import { PhoneNumberTypesEnum } from 'app/nexus-shared/domain/contacts/enums/phone-number-types.enum';
import { PhoneNumberHelper } from 'app/nexus-core/helpers/phone-number.helper';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';

@Component({
    selector: 'gtn-phone-number-form',
    templateUrl: './phone-number-form.component.html',
    styleUrls: ['./phone-number-form.component.scss']
})
export class PhoneNumberFormComponent extends BaseFormComponent<PhoneNumberModel> implements OnInit {
    @Input() countries: CountryCodeViewModel[] = [];
    @Input() canRemove: boolean = false;
    @Input() type: string = 'individual' || 'company';
    @Input() showIsPrimary: boolean = true;
    @Input() isModal: boolean = false;
    @Input() isSubFormArrayModal: boolean = false;
    @Input() showExtension: boolean = true;
    @Input() canAddPhoneNumber: boolean = true;

    @Output() removePhoneNumber: EventEmitter<void> = new EventEmitter<void>();

    individualPhoneNumberTypes: PhoneNumberTypesEnum[] = [PhoneNumberTypesEnum.Mobile, PhoneNumberTypesEnum.Work, PhoneNumberTypesEnum.Home, PhoneNumberTypesEnum.Fax, PhoneNumberTypesEnum.Other];
    isCountryPhoneNumberMismatch: boolean = false;
    companyPhoneNumberTypes: PhoneNumberTypesEnum[] = [PhoneNumberTypesEnum.Main, PhoneNumberTypesEnum.Work, PhoneNumberTypesEnum.Fax, PhoneNumberTypesEnum.Other];
    phoneNumberTypeOptions: SelectListInterface[] = [];
    validNumber: boolean = true;

    constructor(private locationService: LocationService) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
    }

    onRemovePhoneNumberClicked() {
        this.removePhoneNumber.emit();
    }

    onCountryChanged(country: string) {
        const phoneNumber = this.formGroupRef.get(ObjectHelper.nameOf<PhoneNumberModel>('formattedPhoneNumber'))?.value;
        if (PhoneNumberHelper.validatePhoneNumber(phoneNumber, country)) {
            this.isCountryPhoneNumberMismatch = PhoneNumberHelper.isCountryPhoneNumberMismatch(phoneNumber, country);
        }
    }

    protected initUIControls() {
        this.countries = LocationService.sortCountries(this.locationService.countryList).map((x) => {
            // @ts-ignore;
            let countryCode: CountryCallingCode = 'Unknown';
            try {
                // @ts-ignore
                countryCode = getCountryCallingCode(x.countryCode);
            } catch (e) {
            }
            return {
                name: x.name,
                countryCode: x.countryCode,
                callingCode: countryCode,
                display: `${x.name} (${countryCode})`
            };
        });
        this.phoneNumberTypeOptions = this.type === 'individual'
            ? this.individualPhoneNumberTypes.map(t => <SelectListInterface>{
                id: t,
                value: EnumHelper.getDisplayName(PhoneNumberTypesEnum, t)
            })
            : this.companyPhoneNumberTypes.map(t => <SelectListInterface>{
                id: t,
                value: EnumHelper.getDisplayName(PhoneNumberTypesEnum, t)
            });
        this.phoneNumberTypeOptions.unshift(<SelectListInterface>{ id: null, value: '' });
    }

    protected initFormCustomizations() {
        const phoneNumber = this.formGroupRef.get(ObjectHelper.nameOf<PhoneNumberModel>('formattedPhoneNumber'))?.value;
        const countryCode = this.formGroupRef.get(ObjectHelper.nameOf<PhoneNumberModel>('countryCode'))?.value;
        if (!!phoneNumber && !!countryCode) {
            this.isCountryPhoneNumberMismatch = PhoneNumberHelper.isCountryPhoneNumberMismatch(phoneNumber, countryCode);
        }
    }
}

export class CountryCodeViewModel {
    name: string;
    countryCode: string;
    callingCode: CountryCallingCode;
    display: string;
}
