import { Component, ElementRef, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'gtn-checkbox-column',
    templateUrl: './checkbox-column.component.html',
    styleUrls: ['./checkbox-column.component.scss']
})
export class CheckboxColumnComponent implements ICellRendererAngularComp {
    @ViewChild('cellElement') cellElement: ElementRef;

    changeEvent: Function;
    dataModel: any;
    label: string;
    checked: boolean = false;

    private params: ICellRendererParams;

    constructor() {
    }

    agInit(params: ICellRendererParams): void {
        this.dataModel = params.node;
        this.label = params.colDef.cellRendererParams.label;
        if (typeof (params.colDef.cellRendererParams.changeEvent) === 'function') {
            this.changeEvent = params.colDef.cellRendererParams.changeEvent;
        }
    }

    onCheckboxChanged(): void {
        this.changeEvent(this.dataModel);
    }
    refresh(): boolean {
        return false;
    }

}
