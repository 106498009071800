import { Injectable } from '@angular/core';
import { BaseService } from 'app/nexus-core/services/base-service.directive';
import { DocumentModel } from 'app/nexus-shared/domain/documents/models/document.model';
import { FolderModel } from 'app/nexus-shared/domain/documents/models/folder.model';
import { DocumentCopyModel } from 'app/nexus-shared/domain/documents/models/document-copy.model';
import { SpinnerService } from 'app/nexus-core/components';
import { finalize } from 'rxjs/operators';
import { DocumentMoveModel } from 'app/nexus-shared/domain/documents/models/document-move.model';
import { Observable, Subject } from 'rxjs';
import { DocumentsHelper } from 'app/nexus-core/helpers/documents.helper';
import { FolderMoveModel } from 'app/nexus-shared/domain/documents/models/folder-move.model';
import { DocumentService } from 'app/nexus-core/services/domain/documents/document.service';
import { FolderService } from 'app/nexus-core/services/domain/documents/folder.service';
import { ToastService } from 'app/nexus-core/services/toast.service';
import { DocumentEventTypesEnum } from 'app/nexus-shared/domain/documents/enums/document-event-types.enum';
import { DocumentFolderService } from 'app/nexus-core/services/domain/documents/document-folder.service';
import { LocalStorageHelper } from 'app/nexus-core/helpers';
import { FolderCopyModel } from 'app/nexus-shared/domain/documents/models/folder-copy.model';

@Injectable()
export class FolderDocumentGridService extends BaseService {
    public documentsGridRefresh$: Subject<boolean> = new Subject();
    public documentEntityCopy$: Subject<boolean> = new Subject();

    isEntityCopied: boolean;

    constructor(
        private documentService: DocumentService,
        private documentFolderService: DocumentFolderService,
        private folderService: FolderService,
        private toastService: ToastService
    ) {
        super();
    }

    refreshDocuments(useCache: boolean = false): void {
        this.documentsGridRefresh$.next(useCache);
    }

    copyDocumentEntity(entity: DocumentModel | FolderModel): void {
        LocalStorageHelper.set(DocumentsHelper.documentEntityCopy, entity);
        LocalStorageHelper.delete(DocumentsHelper.documentMultipleCopy);
        this.documentEntityCopy$.next(true);
        this.isEntityCopied = true;
    }

    copyMultipleDocuments(documents: DocumentModel[]): void {
        LocalStorageHelper.set(DocumentsHelper.documentMultipleCopy, documents);
        LocalStorageHelper.delete(DocumentsHelper.documentEntityCopy);
        this.documentEntityCopy$.next(true);
        this.isEntityCopied = true;
    }

    getPastedDocumentEntity(): DocumentModel | FolderModel {
        return LocalStorageHelper.get(DocumentsHelper.documentEntityCopy);
    }

    getPastedDocuments(): DocumentModel[] {
        return LocalStorageHelper.get(DocumentsHelper.documentMultipleCopy);
    }

    onDocumentCopied(document: DocumentModel, folder: FolderModel): void {
        const copyModel: DocumentCopyModel = FolderDocumentGridService.createDocumentCopy(document, folder);
        SpinnerService.start();
        this.documentService.copyDocument(copyModel).pipe(finalize(() => {
            SpinnerService.stop();
            this.refreshDocuments(true);
        })).subscribe(_ => {
            },
            err => {
                this.toastService.showErrorToast(err);
            });
    }

    onDocumentsCopied(documents: DocumentModel[], folder: FolderModel): void {
        const copyModels: DocumentCopyModel[] = documents.map(x => FolderDocumentGridService.createDocumentCopy(x, folder));
        SpinnerService.start();
        this.documentService.bulkCopy(copyModels).pipe(finalize(() => {
            this.refreshDocuments(true);
            SpinnerService.stop();
        })).subscribe(_ => {
        }, err => {
            this.toastService.showErrorToast(err);
        });
    }

    moveDocuments(documents: DocumentModel[], newFolderKey: string): void {
        SpinnerService.start();
        if (documents.length > 1) {
            const documentMoves = documents.map(x => {
                return new DocumentMoveModel(x.documentKey, newFolderKey);
            });
            this.documentFolderService.bulkMoveDocuments(documentMoves).pipe(finalize(() => {
                SpinnerService.stop();
                this.refreshDocuments(true);
            })).subscribe(_ => {
            }, err => {
                this.toastService.showErrorToast(err);
            });
            return;
        }
        const documentMove = new DocumentMoveModel(documents[0].documentKey, newFolderKey);
        this.documentFolderService.moveDocument(documentMove).pipe(finalize(() => {
            SpinnerService.stop();
            this.refreshDocuments(true);
        })).subscribe(_ => {
        }, err => {
            this.toastService.showErrorToast(err);
        });
    }

    createFolder(parentFolder: FolderModel): Observable<string> {
        const newFolder = new FolderModel();
        const fileName = 'New Folder';
        newFolder.name = fileName;
        newFolder.folderPath = `${parentFolder.folderPath}/${fileName}`;
        newFolder.areaType = parentFolder.areaType;
        newFolder.accessType = parentFolder.accessType;
        newFolder.parentFolderKey = parentFolder.folderKey;
        newFolder.clientFolder = parentFolder.clientFolder;
        newFolder.folderSettings.isReadOnly = false;
        newFolder.folderSettings.canAddChildFolders = true;
        newFolder.folderSettings.canAddDocuments = true;

        return this.folderService.create(newFolder).pipe(finalize(() => {
            this.refreshDocuments();
        }));
    }

    copyFolder(folderCopy: FolderCopyModel): void {
        SpinnerService.start();
        this.documentFolderService.copyFolder(folderCopy).pipe(finalize(() => {
            SpinnerService.stop();
            this.refreshDocuments();
        })).subscribe(_ => {
        }, err => {
            this.toastService.showErrorToast(err);
        });
    }

    moveFolder(folderMove: FolderMoveModel): void {
        SpinnerService.start();
        this.documentFolderService.moveFolder(folderMove).pipe(finalize(() => {
            SpinnerService.stop();
            this.refreshDocuments();
        })).subscribe(_ => {
        }, err => {
            this.toastService.showErrorToast(err);
        });
    }

    updateFolder(updateFolder: FolderModel): void {
        this.folderService.update(updateFolder).pipe(finalize(() => {
            this.refreshDocuments();
        })).subscribe(_ => {
        }, err => {
            this.toastService.showErrorToast(err);
        });
    }

    private static createDocumentCopy(document: DocumentModel, folder: FolderModel): DocumentCopyModel {
        const copiedDocument = new DocumentModel();
        copiedDocument.name = document.name;
        copiedDocument.documentPath = folder.folderPath;
        copiedDocument.folderKey = folder.folderKey;
        copiedDocument.areaType = folder.areaType;
        copiedDocument.clientDocument.companyKey = folder?.clientFolder?.companyKey ? folder.clientFolder.companyKey : null;
        copiedDocument.clientDocument.individualKey = folder?.clientFolder?.individualKey ? folder.clientFolder.individualKey : null;
        copiedDocument.eventType = DocumentEventTypesEnum.CopyCreate;

        const copy = new DocumentCopyModel();
        copy.documentToCopyKey = document.documentKey;
        copy.document = copiedDocument;
        copy.historicalKey = document.historicalKey;
        return copy;
    }
}
