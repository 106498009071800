import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { SavedSettingService } from 'app/nexus-core/services/domain/core/saved-setting.service';
import { SavedSettingSearchModel } from 'app/nexus-shared/models/saved-setting-search.model';
import { ApplicationsEnum } from 'app/nexus-shared/enums';
import { SavedSettingModel } from 'app/nexus-shared/models/saved-setting.model';
import { SimpleChangesTyped } from 'app/nexus-shared/models/simple-changes-typed.type';
import { GridSettingsModel } from 'app/nexus-shared/models/grid-settings.model';

@Component({
    selector: 'gtn-shared-saved-view-chips',
    templateUrl: './shared-saved-view-chips.component.html',
    styleUrls: ['./shared-saved-view-chips.component.scss']
})
export class SharedSavedViewChipsComponent extends BaseComponent implements OnInit, OnChanges {
    @Input() application: ApplicationsEnum;
    @Input() key: string;
    @Input() sharedUserChipSettings: SavedSettingModel[];
    @Input() gridSettings: GridSettingsModel;

    @Output() settingClick: EventEmitter<any> = new EventEmitter<any>();

    constructor(private savedSettingService: SavedSettingService) {
        super();
    }

    ngOnChanges(changes: SimpleChangesTyped<this>) {
        if (this.key && changes.key && !changes.key?.isFirstChange()) {
            this.getSavedSettings();
        }
    }

    ngOnInit() {
        this.savedSettingService.savedSettingsSubject$.subscribe(_ => {
            this.getSavedSettings();
        });
        if (!this.sharedUserChipSettings?.length) {
            this.getSavedSettings();
        }
    }

    onSettingClicked(setting: SavedSettingModel): void {
        if (this.gridSettings?.savedSettingId === setting.savedSettingId) {
            this.settingClick.emit(null);
        } else {
            this.settingClick.emit(setting);
        }
    }

    private getSavedSettings(): void {
        this.savedSettingService.search(<SavedSettingSearchModel>{ application: this.application, key: this.key }).subscribe(res => {
            this.sharedUserChipSettings = res.filter(x => x.value?.isChipView);
        });
    }
}
