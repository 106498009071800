import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BaseHttpService } from 'app/nexus-core/services/index';
import { Observable } from 'rxjs';
import { CompanyRelationshipModel } from 'app/nexus-shared/domain/contacts/models/company-relationship.model';
import { CompanyRelationshipSearchModel } from 'app/nexus-shared/domain/contacts/models/company-relationship-search.model';

@Injectable()
export class CompanyRelationshipService {
    private apiUrl = `${environment().webAPIUrls.contacts}company-relationships`;

    constructor(private http: BaseHttpService) {
    }

    get(key: string): Observable<CompanyRelationshipModel> {
        return this.http.get(`${this.apiUrl}/${key}`);
    }

    list(): Observable<CompanyRelationshipModel[]> {
        return this.http.get(`${this.apiUrl}`);
    }

    search(searchModel: CompanyRelationshipSearchModel): Observable<CompanyRelationshipModel[]> {
        return this.http.post(`${this.apiUrl}/search`, searchModel);
    }

    create(model: CompanyRelationshipModel): Observable<string> {
        return this.http.post(this.apiUrl, model);
    }

    update(model: CompanyRelationshipModel): Observable<boolean> {
        return this.http.put(`${this.apiUrl}`, model);
    }

    delete(key: string): Observable<boolean> {
        return this.http.delete(`${this.apiUrl}/${key}`);
    }
}
