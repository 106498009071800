<mat-form-field
    [ngClass]="{'gtn-read-only' :  readonly, 'gtn-control-show-error': !formControl?.valid && !formControl?.pending && canShowValidation}">
    <mat-label>{{ label }}</mat-label>
    <input gtnDisableAutofill
        matInput
        [matDatepicker]="picker"
        [value]="value"
        [min]="minDate"
        [max]="maxDate"
        [readonly]="readonly"
        (dateChange)="value = $event.target.value" [disabled]="disabled"/>
    <!-- TODO - matSuffix is incorrect here, needs to be matIconSuffix, keep as-is till legacy is removed -->
    <mat-datepicker-toggle *ngIf=" !disabled && !readonly"
        matSuffix
        [for]="picker">
    </mat-datepicker-toggle>
    <mat-datepicker #picker [startAt]="startAt">
    </mat-datepicker>
    <div class="gtn-control-error-message error-message"
        *ngIf="!formControl?.valid && !formControl?.pending && canShowValidation">
        <span *ngFor="let msg of getErrorMessage()">{{ msg }}</span>
    </div>
</mat-form-field>
