<div class="grid-actions">
    <div class="header">
        Settings
    </div>
    <div class="action">
        <label class="setting-label">Autosize columns</label>
        <div class="setting-icon">
            <button (click)="onSizeColumnsToFitClicked()" class="mr-2" color="default"
                mat-mini-fab matTooltip="Reset grid">
                <mat-icon>autorenew</mat-icon>
            </button>
        </div>
    </div>
    <div class="action">
        <label class="setting-label">Fit columns to content</label>
        <div class="setting-icon">
            <button (click)="onSizeColumnsToFitContentClicked()" class="mr-2" color="default"
                mat-mini-fab matTooltip="Reset grid">
                <mat-icon>open_in_full</mat-icon>
            </button>
        </div>
    </div>
    <div class="action">
        <label class="setting-label">Reset columns and filters</label>
        <div class="setting-icon">
            <button (click)="onResetColumnFiltersClicked()" class="mr-2" color="default"
                mat-mini-fab matTooltip="Reset grid">
                <mat-icon>restart_alt</mat-icon>
            </button>
        </div>
    </div>
    <div class="action" *ngIf="params?.application && params?.gridVersion">
        <label class="setting-label">Save grid settings</label>
        <div class="setting-icon">
            <button (click)="onSaveUserGridSettingsClicked()" class="mr-2" color="default"
                mat-mini-fab matTooltip="Save">
                <mat-icon>save</mat-icon>
            </button>
        </div>

    </div>
    <div class="action" *ngIf="userGridSettings?.length">
        <gtn-dropdown label="My settings" [options]="userGridSettings"
            [(ngModel)]="userSetting"
            (change)="onSavedSettingSelected($event['value'])"
            displayKey="name"
            idKey="savedSettingId"></gtn-dropdown>
    </div>
    <div class="action" *ngIf="sharedUserGridSettings?.length">
        <gtn-dropdown label="Shared settings" [options]="sharedUserGridSettings"
            [(ngModel)]="sharedSetting"
            (change)="onSavedSettingSelected($event['value'])"
            displayKey="name"
            idKey="savedSettingId"></gtn-dropdown>
    </div>
    <div class="action" *ngIf="lastSettingApplied">
        <label class="list-label">Last setting applied</label>
        <div class="data-field">
            {{ lastSettingApplied }}
        </div>
    </div>
</div>
<gtn-saved-setting-modal *ngIf="isSaveGridSettings" [user]="user" [key]="params.gridVersion"
    [application]="params.application" [settingValue]="gridSettings"
    [canShareSettings]="canShareGrid"
    (cancelClick)="isSaveGridSettings = false;" (saveSuccess)="onSaveSettingSuccess($event)">
</gtn-saved-setting-modal>
