<div class="gtn-slider-container">
    <label class="gtn-label mb-3">{{ label }}</label>
    <!-- TODO: The 'invert' property no longer exists -->
    <!-- TODO: The 'tickInterval' property no longer exists -->
    <!-- TODO: The 'vertical' property no longer exists -->
    <mat-slider
        #matSlider
        [disabled]="disabled"
        [max]="max"
        [min]="min"
        [step]="step"
        [(ngModel)]="value"><input matSliderThumb/><input matSliderThumb/><input matSliderThumb/><input matSliderThumb/><input
        matSliderThumb/>
    </mat-slider>
</div>
