<ng-container *ngIf="imageSources?.length">
    <div [ngClass]="{ 'carousel': !isModal, 'carousel-modal': isModal}" (click)="onCloseCarouselClicked(true)">

        <div class="carousel-header">
            <ng-content select="gtn-carousel-header"></ng-content>
        </div>
        <div class="image-container">
            <span class="close" (click)="onCloseCarouselClicked()">close <i class="fa fa-times"></i></span>

            <img (click)="$event.stopPropagation()" @fadeIn
                [src]="imageSources[selectedImageIndex].src" class="image">
        </div>
        <div class="carousel-footer" (click)="$event.stopPropagation()">
            <div *ngIf="imageSources?.length > 1" class="control-button" (click)="previousImage()">
                <span><i class="fa fa-chevron-left"></i></span>
            </div>
            <div class="title" *ngIf="imageSources?.length > 1">
                {{selectedImageIndex + 1}}/{{imageSources.length}}
            </div>
            <div *ngIf="imageSources?.length > 1" class="control-button" (click)="nextImage()">
                <span><i class="fa fa-chevron-right"></i></span>
            </div>
        </div>
    </div>
</ng-container>
