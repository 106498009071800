import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'gtn-redirect',
    templateUrl: './redirect.component.html',
})
export class RedirectComponent implements OnInit {
    showRedirect: boolean = false;

    ngOnInit() {
        setTimeout(() => {
            this.showRedirect = true;
        }, 1000);
    }
}
