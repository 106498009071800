import { Component, EventEmitter, Output } from '@angular/core';
import { BaseFormComponent } from 'app/nexus-shared/components/base-component/base-form.component';
import { FolderIndividualAccessModel } from 'app/nexus-shared/domain/documents/models/folder-individual-access.model';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { IndividualModel } from 'app/nexus-shared/domain/contacts/models';

@Component({
    selector: 'gtn-folder-individual-access-form',
    templateUrl: './folder-individual-access-form.component.html',
    styleUrls: ['./folder-individual-access-form.component.scss']
})
export class FolderIndividualAccessFormComponent extends BaseFormComponent<FolderIndividualAccessModel> {
    @Output() deleteAccess: EventEmitter<void> = new EventEmitter<void>();

    onIndividualChanged(individual: IndividualModel) {
        this.formGroupRef.get(ObjectHelper.nameOf<FolderIndividualAccessModel>('individualKey')).setValue(individual?.individualKey ?? null);
    }
}
