import { Directive, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { SearchResultIconEnum, SearchResultModel } from 'app/nexus-shared/components/controls';
import { SearchModel } from 'app/nexus-shared/components/controls/shared/models/search.model';
import { SortingHelper } from 'app/nexus-core';
import { LocationHelper } from 'app/nexus-core/helpers/location.helper';
import { LocationService } from 'app/nexus-core/services/location.service';
import { LocationCompositeModel, LocationModel, LocationSearchModel, LocationTypesEnum } from 'app/nexus-shared/domain/locations/index';
import { BaseDirective } from 'app/nexus-shared/components/base-directive/base.directive';

@Directive({
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BaseLocationSearchDirective),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => BaseLocationSearchDirective),
            multi: true
        }
    ]
})
export abstract class BaseLocationSearchDirective extends BaseDirective implements ControlValueAccessor {
    @Input('value') _value: any = null;
    @Input() canShowValidation: boolean = false;
    @Input() ngModel: LocationModel | LocationCompositeModel = null;
    @Input() countryId: number;
    @Input() stateId: number;
    @Input() disabled: boolean;
    @Input() readonly: boolean;
    @Input() take: number = 50;

    @Output() searchResultClick: EventEmitter<LocationModel | LocationCompositeModel> = new EventEmitter();

    public searchModel = new SearchModel();
    public formControl: UntypedFormControl;
    public label: string;
    public placeholder: string;
    public locationType: LocationTypesEnum;
    public requiredCharacterCount: number = 2;

    constructor(
        private locationService: LocationService
    ) {
        super();
        this.subscriptions.add(this.searchModel.onTextChanged.subscribe(searchText => {
            const request: LocationSearchModel = {
                locationType: this.locationType,
                nameOrCode: searchText,
                countryId: this.countryId,
                stateId: this.stateId
            };

            this.locationService.search(request).subscribe(
                response => {
                    const results = response.map((location: LocationModel) => {
                        const locationTypeId = location.type.id;

                        let locationCompositeModel: LocationCompositeModel = null;
                        let locationName = locationTypeId === LocationTypesEnum.Airport && location.airportCode
                            ? `${location.name} (${location.airportCode})`
                            : location.name;

                        if (this.locationType === LocationTypesEnum.Any) {
                            const country = this.getLocation(LocationTypesEnum.Country, location);
                            const state = this.getLocation(LocationTypesEnum.StateProvince, location);
                            const county = this.getLocation(LocationTypesEnum.County, location);
                            const city = this.getLocation(LocationTypesEnum.City, location);
                            const airport = this.getLocation(LocationTypesEnum.Airport, location);
                            locationCompositeModel = {
                                country,
                                state,
                                county,
                                city,
                                airport
                            } as LocationCompositeModel;

                            locationName = !locationCompositeModel.airport
                                ? LocationHelper.getLocationNameFromCompositeModel(locationCompositeModel)
                                : locationCompositeModel.airport.name + ` (${locationCompositeModel.airport.airportCode})`;
                        }

                        return {
                            id: location.id,
                            name: locationName,
                            subName: null,
                            type: null,
                            iconClass: this.getIconClass(locationTypeId),
                            resultObject: locationCompositeModel || location,
                            rank: 0
                        } as SearchResultModel<void, LocationModel | LocationCompositeModel>;
                    });
                    const orderedResults = this.orderSearchResults(results);

                    // only display top results, otherwise UI will lag when rendering results
                    const topResults = orderedResults.slice(0, this.take);

                    this.searchModel.onResultsReceived.next(topResults);
                },
                error => this.searchModel.onErrorReceived.next(error));
        }));

        this.subscriptions.add(this.searchModel.onResultClicked.subscribe(result => {
            this.searchResultClick.emit(result.resultObject);
            this.writeValue(result.resultObject);
        }));
    }

    get value() {
        return this._value;
    }

    set value(location: LocationModel) {
        this._value = location;
        this.writeValue(location);
    }

    displayWith(item: LocationModel | LocationCompositeModel) {
        if (item) {
            // do not use instanceof, it will not work
            if ('country' in item) {
                return LocationHelper.getLocationNameFromCompositeModel(item, true);
            }

            // do not use instanceof, it will not work
            if ('countryCode' in item) {
                return item.name;
            }
        }

        return '';
    }

    registerOnChange(fn: Function): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: Function): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    validate(formControl: UntypedFormControl): void {
        this.formControl = formControl;
        return this.validateFn(formControl);
    }

    writeValue(location: LocationModel): void {
        this.ngModel = location;
        this._value = location;
        this.onChange(location);
        this.onTouched();
    }

    private onChange: Function = () => {
    };
    private onTouched: Function = () => {
    };
    private validateFn: Function = () => {
    };

    private getIconClass(locationType: LocationTypesEnum) {
        switch (locationType) {
            case LocationTypesEnum.Country:
                return SearchResultIconEnum.country;
            case LocationTypesEnum.StateProvince:
                return SearchResultIconEnum.state;
            case LocationTypesEnum.County:
                return SearchResultIconEnum.city;
            case LocationTypesEnum.City:
                return SearchResultIconEnum.city;
            case LocationTypesEnum.Airport:
                return SearchResultIconEnum.airplane;
            default:
                return SearchResultIconEnum.unknown;
        }
    }

    private getLocation(locationType: LocationTypesEnum, locationModel: LocationModel) {
        return locationModel.type.id === locationType
            ? locationModel
            : locationModel.relatedLocations.find(rl => rl.type && rl.type.id === locationType);
    }

    private orderSearchResults(results: SearchResultModel<void, LocationModel | LocationCompositeModel>[]): SearchResultModel<void, LocationModel | LocationCompositeModel>[] {
        if (results && results.length) {
            const isLocationCompositeModel = 'country' in results[0].resultObject; // do not use instanceof, it will not work
            if (results.length && isLocationCompositeModel) {
                return results.sort((resultA, resultB) =>
                    LocationHelper.getSortPositionForSearch(<LocationCompositeModel>resultA.resultObject, <LocationCompositeModel>resultB.resultObject));
            }

            return results.sort((resultA: SearchResultModel<void, LocationModel>, resultB: SearchResultModel<void, LocationModel>) => {
                return SortingHelper.sortByPropertyComparerDesc(resultA.resultObject, resultB.resultObject, 'population');
            });
        }

        return [];
    }
}
