<gtn-modal
    headerText="Unsaved Changes"
    cancelButtonText="Cancel"
    okButtonText="Save Changes" class="modal-small-wide" (closeClick)="onCloseCancelClicked()">
    <gtn-modal-content>
        <div>
            You have made changes to this content. Save or discard these changes to proceed.
        </div>
        <div>&nbsp;</div>
    </gtn-modal-content>
    <gtn-modal-footer-left-buttons>
        <button mat-flat-button
            (click)="onCloseCancelClicked()"
            class="cancel-button"
            id="cancelButton"
            type="button">
            Cancel
        </button>
    </gtn-modal-footer-left-buttons>
    <gtn-modal-footer-right-buttons>
        <button mat-flat-button
            class="mr-2"
            color="accent"
            type="button"
            (click)="onSaveClicked()">
            Save Changes
        </button>
        <button mat-flat-button
            color="accent"
            type="button"
            (click)="onDiscardClicked()">
            Discard Changes
        </button>
    </gtn-modal-footer-right-buttons>
</gtn-modal>
