import { AfterViewInit, Component, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CheckboxLabelPositionType } from 'app/nexus-shared/components/controls';
import { MatCheckbox } from '@angular/material/checkbox';
import { BaseControlComponent } from '../base-control.component';

@Component({
    selector: 'gtn-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => CheckboxComponent),
            multi: true
        }
    ]
})
export class CheckboxComponent<T> extends BaseControlComponent<T | boolean> implements ControlValueAccessor, AfterViewInit {
    @Input() labelPosition: 'before' | 'after' = CheckboxLabelPositionType.after;
    @ViewChild('element') matCheckbox: MatCheckbox;

    ngAfterViewInit() {
        if (this.focus) {
            this.setFocus();
        }
    }

    setFocus(): void {
        this.matCheckbox.focus();
    }

    triggerChanged() {
        super.triggerChanged(this.matCheckbox?._elementRef);
    }
}
