import { EventEmitter, Injectable } from '@angular/core';
import { PdfAnnotationBoarderStyleEnum, PDFAnnotationModel, PDFFormFieldInputModel, PDFFormFieldTypesEnum, SelectListInterface, ValidationModel } from 'app/nexus-shared';
import { PDFPageProxy } from 'pdfjs-dist/types/src/display/api';

@Injectable()
export class PdfFileViewService {
    activeFormField: PDFFormFieldInputModel = null;
    download: Function = null;
    formValues: SelectListInterface[] = [];
    formFields: PDFFormFieldInputModel[] = [];

    formFieldChange: EventEmitter<void> = new EventEmitter();
    pagesLoad: EventEmitter<void> = new EventEmitter<void>();

    getFormFieldValues(): SelectListInterface[] {
        return this.formFields.map(this.getFormFieldValue);
    }

    initPDF() {
        this.formValues = [];
        this.formFields = [];
        this.activeFormField = null;
    }

    initPageAnnotations(page: PDFPageProxy) {
        page.getAnnotations().then(x => {
            const annotations = (<any>x) as any[];
            annotations.filter(a => a.subtype === 'Widget')
                .forEach(a => {
                    this.createInput(a);
                });
        });
    }

    moveToLastFormField(): void {
        const formFields = this.formFields;

        if (formFields) {
            const firstField = formFields[0];

            if (firstField === this.activeFormField) {
                this.setFormFieldFocus(firstField.id);
                return;
            }
        }

        for (let index = (formFields.length - 1); index >= 0; index--) {
            const field = formFields[index];

            if (!this.activeFormField?.id || this.activeFormField?.id === '') {
                this.setFormFieldFocus(field.id);
                return;
            }

            if (this.activeFormField?.id == field.id) {
                this.activeFormField = null;
            }
        }
    }

    moveToNextFormField(): void {
        const formFields = this.formFields;

        if (formFields) {
            const lastIndex = formFields.length - 1;
            const lastField = formFields[lastIndex];

            if (lastField === this.activeFormField) {
                this.setFormFieldFocus(lastField.id);
                return;
            }
        }

        for (const field of formFields) {
            if (!this.activeFormField?.id || this.activeFormField?.id === '') {
                this.setFormFieldFocus(field.id);
                return;
            }

            if (this.activeFormField?.id == field.id) {
                this.activeFormField = null;
            }
        }
    }

    moveToNextRequiredSignature(signatureValidationErrors: ValidationModel[]): void {
        if (!signatureValidationErrors || signatureValidationErrors.length === 0) {
            const signatureField = this.formFields.find(x => x.name.includes('signature'));
            this.setFormFieldFocus(signatureField?.id);
        }

        for (const field of this.formFields) {
            const validationError = signatureValidationErrors.find(x => x.field === field.name);
            if (!validationError) {
                continue;
            }

            this.setFormFieldFocus(field.id);
            return;
        }
    }

    reset() {
        this.formValues = [];
        this.formFields = [];
        this.activeFormField = null;
    }

    private addFormFieldEvents(formField: PDFFormFieldInputModel) {
        setTimeout(() => { // have to break out here
            const element = document.getElementById(formField.id);

            if (!element) {
                return;
            }

            element.addEventListener("focusin", this.onFormFieldFocused.bind(this));
            element.addEventListener("input", this.onFormFieldChanged.bind(this));
        });
    }

    private createInput(annotation: PDFAnnotationModel): void {
        const input: PDFFormFieldInputModel = new PDFFormFieldInputModel();

        input.name = annotation.fieldName;
        input.id = annotation.id;

        // TODO - put font styling here
        // TODO - fontName not working here
        annotation.defaultAppearanceData = { fontSize: 16, fontName: '', fontColor: { '0': 0, '1': 0, '2': 0 } };

        annotation.borderStyle = {
            style: PdfAnnotationBoarderStyleEnum.None
        };

        switch (annotation.fieldType) {
            case "Tx":
                input.type = PDFFormFieldTypesEnum.Text;
                input.value = annotation.fieldValue || '';
                break;
            case "Ch":
                input.type = PDFFormFieldTypesEnum.Dropdown;
                input.value = annotation.fieldValue || '';
                break;
            case "Btn":
                if (annotation['checkbox']) { // TODO - check this out, this didn't come with the model I inspected
                    input.type = PDFFormFieldTypesEnum.Checkbox;
                    input.value = !annotation.fieldValue ? 'false' : 'true';
                    break;
                }

                input.type = PDFFormFieldTypesEnum.Radio;
                input.value = annotation.fieldValue;
                break;
            default:
                input.type = 0;
                input.value = annotation.fieldValue || '';
        }

        this.addFormFieldEvents(input);

        this.formFields.push(input);
    }

    private getFormFieldValue(input: PDFFormFieldInputModel): SelectListInterface {
        const ele = <HTMLInputElement>document.getElementById(input.id);

        if (!ele) {
            return {
                id: input.name,
                value: ele.value
            };
        }

        const fieldType: PDFFormFieldTypesEnum = input.type;

        if (fieldType === PDFFormFieldTypesEnum.Checkbox) {
            return {
                id: input.name,
                value: ele.checked ? 'true' : 'false'
            };
        }

        if (fieldType === PDFFormFieldTypesEnum.Radio) {
            return {
                id: input.name,
                value: ele.checked ? 'true' : 'false'
            };
        }

        return {
            id: input.name,
            value: ele.value
        };
    }

    private onFormFieldChanged($event) {
        this.formFieldChange.next($event);
    }

    private onFormFieldFocused($event) {
        const focusId = $event?.target?.id ?? '';
        const formField = this.formFields?.find(x => x.id == focusId);

        this.activeFormField = formField ?? null;
    }

    private setFormFieldFocus(fieldId: string) {
        if (!fieldId || fieldId === '') {
            return;
        }

        const fieldElement = document.getElementById(fieldId);
        fieldElement.focus();
    }
}
