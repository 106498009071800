import { Component, Input } from '@angular/core';
import { BasePageComponent } from 'app/nexus-shared/components/base-component/base-page.component';

@Component({
    selector: 'gtn-template-page',
    templateUrl: './template-page.component.html',
    styleUrls: ['./template-page.component.scss']
})
export class TemplatePageComponent extends BasePageComponent {
    @Input() pageTitle: string = null;
}
