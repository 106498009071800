import { Component } from '@angular/core';
import { TemplateReadonlyEditCardComponent } from 'app/nexus-shared/components/templates/template-readonly-edit-card/template-readonly-edit-card.component';

@Component({
    selector: 'gtn-template-readonly-edit-card-edit-content',
    template: '<ng-content></ng-content>'
})
export class TemplateReadonlyEditCardEditContentComponent<T> {
    // noinspection JSUnusedLocalSymbols
    constructor(templateReadonlyEditCardContentComponent: TemplateReadonlyEditCardComponent<T>) {
        // THIS WILL THROW AN ERROR IF ITS NOT NESTED WITHIN PARENT
    }
}
