<form *ngIf="formGroupRef"
    [formGroup]="formGroupRef">
    <div class="row"
        *ngIf="value && filteredAgreementTypes">
        <gtn-dropdown class="col-12"
            formControlName="agreementType"
            idKey="agreementTypeKey"
            label="IEL type"
            (change)="onAgreementSubtypeChanged($event)"
            [displayFunc]="agreementsHelper.getSubtypeDisplayName.bind(this)"
            [options]="filteredAgreementTypes"
            [readonly]="value.status !== agreementStatusEnum.Draft"></gtn-dropdown>
    </div>
    <div class="row"
        *ngIf="reviewers?.length > 0">
        <gtn-dropdown class="col-md-12"
            label="Reviewer"
            [value]="selectedReviewerOption"
            [options]="reviewerOptions"
            [showBlank]="true"
            (change)="onReviewerChanged($event['value'])">
        </gtn-dropdown>
    </div>
    <div class="row">
        <div class="col-md-12">
            <gtn-datepicker formControlName="expirationDateTime"
                label="Expiration date"
                [readonly]="value.status !== agreementStatusEnum.Draft">
            </gtn-datepicker>
        </div>
    </div>
    <div class="row">
        <gtn-input class="col-md-12"
            type="text"
            label="Signer name"
            (change)="onSignerNameChanged($event)"
            [readonly]="value.status !== agreementStatusEnum.Draft"
            [value]="signerName"></gtn-input>
    </div>
    <div class="row">
        <gtn-input class="col-md-12"
            label="Signer email"
            type="text"
            (change)="onSignerEmailChanged($event)"
            [readonly]="true"
            [value]="signerEmail"></gtn-input>
    </div>
    <div class="row"
        *ngIf="isCompliance || isConsulting">
        <gtn-input class="col-md-12"
            type="test"
            label="Service years"
            (change)="updateServiceYears($event)"
            [readonly]="value.status !== agreementStatusEnum.Draft"
            [value]="serviceYears"></gtn-input>
    </div>
    <div class="row"
        *ngIf="isCompliance || isConsulting">
        <gtn-input class="col-md-12"
            type="test"
            label="Services"
            (change)="updateServices($event)"
            [readonly]="value.status !== agreementStatusEnum.Draft"
            [value]="services"></gtn-input>
    </div>
    <div class="row"
        *ngIf="isCompliance || isConsulting">
        <gtn-input *ngIf="isCompliance"
            class="col-md-6"
            type="number"
            label="Compliance fee"
            (change)="updateComplianceFee($event)"
            [readonly]="value.status !== agreementStatusEnum.Draft"
            [value]="complianceFee"></gtn-input>
        <gtn-input *ngIf="isConsulting"
            class="col-md-6"
            type="number"
            label="Consulting fee"
            (change)="updateConsultingFee($event)"
            [readonly]="value.status !== agreementStatusEnum.Draft"
            [value]="consultingFee"></gtn-input>
    </div>
    <div class="row"
        *ngIf="isCompliance || isConsulting">
        <gtn-input class="col-md-6"
            type="text"
            label="Total fees"
            [readonly]="true"
            [value]="totalFee"></gtn-input>
    </div>
</form>
