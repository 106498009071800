import { ValidationModel } from 'app/nexus-shared/models/validation.model';

export class ErrorResponseModel {
    constructor(isServerError: boolean, isValidationError: boolean, dataBag: any) {
        this.isServerError = isServerError;
        this.isValidationError = isValidationError;
        this.dataBag = dataBag;
    }

    public readonly isServerError: boolean = false;
    public readonly isValidationError: boolean = false;
    public readonly dataBag: ValidationModel[] | string = null;
}
