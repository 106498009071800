import { BaseRouteApplicationConfigurationModel } from 'app/nexus-shared';
import { UsersRoutingKeyConstants } from './users-routing-key.constants';

export class UsersRoutingUsersConfigurationConstants {
    static baseRoute: string = 'users';

    static readonly configurations = {
        getRoot: (): BaseRouteApplicationConfigurationModel => {
            return {
                routing: {
                    routerLink: [`${UsersRoutingUsersConfigurationConstants.baseRoute}`],
                    routerModulePath: '',
                    navigationRoute: () => [`${UsersRoutingUsersConfigurationConstants.baseRoute}`]
                },
            };
        },
        getInternalUsers: (): BaseRouteApplicationConfigurationModel => {
            return {
                menu: {
                    title: 'Internal Users',
                    iconClass: 'fas fa-user-group-crown',
                    order: 10
                },
                applicationMenuId: UsersRoutingKeyConstants.menuIds.userInternalListPage,
                routing: {
                    routerLink: [`${UsersRoutingUsersConfigurationConstants.baseRoute}/internal`],
                    routerModulePath: 'internal',
                    navigationRoute: () => [`${UsersRoutingUsersConfigurationConstants.baseRoute}/internal`]
                },
            };
        },
        getExternalUsers: (): BaseRouteApplicationConfigurationModel => {
            return {
                menu: {
                    title: 'External Users',
                    iconClass: 'fas fa-users',
                    order: 20
                },
                applicationMenuId: UsersRoutingKeyConstants.menuIds.userExternalListPage,
                routing: {
                    routerLink: [`${UsersRoutingUsersConfigurationConstants.baseRoute}/external`],
                    routerModulePath: 'external',
                    navigationRoute: () => [`${UsersRoutingUsersConfigurationConstants.baseRoute}/external`]
                },
            };
        },
        getUserImpersonations: (): BaseRouteApplicationConfigurationModel => {
            return {
                menu: {
                    title: 'Impersonations',
                    iconClass: 'fas fa-user-secret',
                    order: 30
                },
                routing: {
                    routerLink: [`${UsersRoutingUsersConfigurationConstants.baseRoute}/impersonations`],
                    routerModulePath: 'impersonations',
                    navigationRoute: () => [`${UsersRoutingUsersConfigurationConstants.baseRoute}/impersonations`]
                },
            };
        },
        getDetails: (path: string): BaseRouteApplicationConfigurationModel => {
            return {
                routing: {
                    useExact: true,
                    routerModulePath: `user/:${UsersRoutingKeyConstants.userKey}`,
                    navigationRoute: (key: string) => [`${UsersRoutingUsersConfigurationConstants.baseRoute}/${path}/user/${key}`]
                }
            };
        },
        getCreateUser(path) {
            return {
                routing: {
                    useExact: true,
                    routerModulePath: `user`,
                    navigationRoute: () => [`${UsersRoutingUsersConfigurationConstants.baseRoute}/${path}/user`]
                }
            };
        }
    };

    static mainMenuItems = [
        UsersRoutingUsersConfigurationConstants.configurations.getInternalUsers(),
        UsersRoutingUsersConfigurationConstants.configurations.getExternalUsers(),
        UsersRoutingUsersConfigurationConstants.configurations.getUserImpersonations()
    ];
}
