import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { TaxRateModel } from 'app/nexus-shared/domain/taxes';
import { BaseHttpService } from 'app/nexus-core/services/index';
import { TaxSpecializationModel } from 'app/nexus-shared/domain/taxes/models/tax-specialization.model';

@Injectable()
export class TaxRateService {

    constructor(private baseHttpService: BaseHttpService) {
    }

    create(rate: TaxRateModel): Observable<number> {
        return this.baseHttpService.post(`${this.apiUrl()}taxRates`, rate);
    }

    delete(id: number): Observable<boolean> {
        return this.baseHttpService.delete(`${this.apiUrl()}taxRates/${id}`);
    }

    getById(id: number): Observable<TaxRateModel> {
        return this.baseHttpService.get(`${this.apiUrl()}taxRates/${id}`);
    }

    getByTaxId(taxId: number): Observable<TaxRateModel[]> {
        return this.baseHttpService.get(`${this.apiUrl()}taxRates/tax/${taxId}`);
    }

    getTaxRatesByFilter(filter: any): Observable<TaxRateModel[]> {
        return this.baseHttpService.post(`${this.apiUrl()}taxRates/filter`, filter);
    }

    update(rate: TaxRateModel): Observable<TaxRateModel> {
        return this.baseHttpService.put(`${this.apiUrl()}taxRates/${rate.taxRateId}`, rate);
    }

    specializations(taxId: number): Observable<TaxSpecializationModel[]> {
        return this.baseHttpService.get(`${this.apiUrl()}taxRates/tax/${taxId}/specializations`);
    }

    private apiUrl = () => environment().webAPIUrls.taxes;
}
