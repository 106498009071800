import { Component, OnChanges, OnInit } from '@angular/core';
import { WorkDaySummaryDateModel } from 'app/nexus-shared/domain/travel-calendar/models/work-day-summary-date.model';
import { IndividualSettingService } from 'app/nexus-core/services/domain/core/individual-setting.service';
import { ColDef } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { AgGridColDefHelper } from 'app/nexus-core/helpers/ag-grid-col-def.helper';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { BaseTravelCalendarReportingGridComponent } from 'app/nexus-shared/domain/travel-calendar/components/grids/base-grids/base-travel-calendar-reporting-grid.component';
import { SimpleChangesTyped } from 'app/nexus-shared/models/simple-changes-typed.type';

@Component({
    selector: 'gtn-work-day-summary-date-grid',
    templateUrl: './work-day-summary-date-grid.component.html',
    styleUrls: ['./work-day-summary-date-grid.component.scss']
})
export class WorkDaySummaryDateGridComponent extends BaseTravelCalendarReportingGridComponent<WorkDaySummaryDateModel> implements OnInit, OnChanges {
    constructor(protected individualSettingService: IndividualSettingService) {
        super(individualSettingService);
    }

    ngOnChanges(changes: SimpleChangesTyped<this>) {
        if (changes?.searchModel && !changes.searchModel.isFirstChange()) {
            this.refreshGridData();
        }
    }

    ngOnInit() {
        super.ngOnInit();
    }

    protected setColumnDefinitions(): ColDef[] {
        return [
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOf<WorkDaySummaryDateModel>('countryName'), 'Country Name'),
            AgGridColDefHelper.getDateColDef(ObjectHelper.nameOf<WorkDaySummaryDateModel>('startDate'), 'Date Arrived'),
            AgGridColDefHelper.getDateColDef(ObjectHelper.nameOf<WorkDaySummaryDateModel>('endDate'), 'Date Departed'),
            AgGridColDefHelper.getRoundedDecimalColDef(ObjectHelper.nameOf<WorkDaySummaryDateModel>('totalDaysWorked'), 'Total Days Worked'),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOf<WorkDaySummaryDateModel>('stateName'), 'State/Province Name'),
            AgGridColDefHelper.getNumberColDef(ObjectHelper.nameOf<WorkDaySummaryDateModel>('totalDaysPresence'), 'Total Days Presence'),
            AgGridColDefHelper.getIconCheckboxColDef(ObjectHelper.nameOf<WorkDaySummaryDateModel>('isConflict'), 'Conflict'),
        ];
    }

    protected setRowData(): Observable<any[]> {
        return this.travelCalendarReportService.getWorkDaySummaryDate(this.searchModel);
    }
}
