<gtn-individual-create-template-modal
    [headerText]="headerText"
    [validationModels]="validationModels"
    [isCreateNewIndividual]="isCreateIndividual"
    [value]="individual"
    [isControllingCompany]="isControllingCompany"
    [formGroupRef]="formGroupRef"
    (cancelClick)="onCancelClicked()"
    (saveClick)="onSaveClicked()"
    (individualSaveSuccess)="onIndividualSaveSuccess($event)">
    <gtn-individual-create-dual-form-content>
        <gtn-individual-to-company-access-form *ngIf="!isCreateIndividual" [(formGroupRef)]="formGroupRef"
            [(value)]="value" [applicationSource]="applicationSource"
            [isControllingCompany]="isControllingCompany" [individual]="individual"
            [isRelationshipTypeReadonly]="isRelationshipTypeReadonly"
            [canAddNewIndividual]="canAddNewIndividual"
            [isMappingCompanyToIndividual]="true"
            (formGroupRefChange)="onFormGroupRefChanged($event)"
            (individualChange)="onIndividualSelected($event)"
            (createIndividual)="onCreateIndividualClicked()">
        </gtn-individual-to-company-access-form>
    </gtn-individual-create-dual-form-content>
</gtn-individual-create-template-modal>

