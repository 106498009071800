import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IndividualCompanyAccessGridComponent } from 'app/nexus-shared/domain/contacts/components/grids/individual-company-access-grid.component';
import { IndividualToCompanyAccessModel } from 'app/nexus-shared/domain/contacts/models/individual-to-company-access.model';
import { ApplicationsEnum } from 'app/nexus-shared/index';
import { BasePageComponent } from 'app/nexus-shared/components/base-component/base-page.component';
import { CompanyModel, IndividualModel } from 'app/nexus-shared/domain/contacts/models';
import { IndividualService } from 'app/nexus-core/services/domain/contacts/individual.service';
import { environment } from 'environments/environment';
import { AuthorizationConstants } from 'app/nexus-shared/constants/authorization.constants';
import { IndividualTypesEnum } from 'app/nexus-shared/domain/contacts/enums/individual-types.enum';

@Component({
    selector: 'gtn-individual-company-access-grid-container',
    template: `
        <gtn-individual-company-access-grid #mappingGrid (cellClick)="onCellClicked($event)"
            [gridAdminAuthConstant]="gridAdminConstant"
            [isAdmin]="isAdmin"
            [application]="applicationSource" [companyKey]="company?.companyKey"
            [individualKey]="individual?.individualKey"
            (addButtonClick)="onAddClicked()"></gtn-individual-company-access-grid>
        <gtn-individual-company-access-modal
            *ngIf="showIndividualToCompanyModal"
            [individual]="individual" [canAddNewCompany]="isAdmin"
            [value]="selectedMapping" [applicationSource]="applicationSource"
            (cancelClick)="onCancelClicked()"
            (saveSuccess)="onSaveSuccess()">
        </gtn-individual-company-access-modal>
        <gtn-company-individual-access-modal *ngIf="showCompanyToIndividualModal"
            [company]="company" [value]="selectedMapping"
            [canAddNewIndividual]="isAdmin"
            [isControllingCompany]="isControllingCompany"
            [applicationSource]="applicationSource"
            (cancelClick)="onCancelClicked()" (saveSuccess)="onSaveSuccess()">
        </gtn-company-individual-access-modal>
    `
})
export class IndividualCompanyAccessGridContainerComponent extends BasePageComponent implements OnInit {
    @Input() company: CompanyModel;
    @Input() individual: IndividualModel;
    @Input() applicationSource: ApplicationsEnum;
    @Input() isAdmin: boolean = false;

    @ViewChild('mappingGrid') mappingGrid: IndividualCompanyAccessGridComponent;

    gridAdminConstant: AuthorizationConstants = AuthorizationConstants.contactsAdmin;
    isControllingCompany: boolean = false;
    selectedMapping: IndividualToCompanyAccessModel;
    showCompanyToIndividualModal: boolean = false;
    showIndividualToCompanyModal: boolean;

    constructor(private individualService: IndividualService) {
        super();
    }

    ngOnInit() {
        this.isControllingCompany = this.company?.companyKey === environment().controllingCompanyKey;
    }

    onAddClicked(): void {
        this.selectedMapping = new IndividualToCompanyAccessModel();
        if(this.isControllingCompany) {
            this.selectedMapping.relationshipType = IndividualTypesEnum.GtnEmployee;
        }
        if (this.individual) {
            this.selectedMapping.individual = new IndividualModel(this.individual);
            this.showIndividualToCompanyModal = true;
        } else if (this.company) {
            this.selectedMapping.company = new CompanyModel(this.company);
            this.showCompanyToIndividualModal = true;
        }
    }

    onCancelClicked(): void {
        this.showIndividualToCompanyModal = false;
        this.showCompanyToIndividualModal = false;
        this.selectedMapping = null;
    }

    onSaveSuccess(): void {
        this.mappingGrid.refreshGridData$().subscribe(_ => {
            this.showIndividualToCompanyModal = false;
            this.showCompanyToIndividualModal = false;
            this.selectedMapping = null;
        });
    }

    onCellClicked(individual: IndividualToCompanyAccessModel): void {
        this.individualService.getByKey(individual.individual.individualKey).subscribe(res => {
            this.selectedMapping = new IndividualToCompanyAccessModel(individual);
            this.selectedMapping.individual = new IndividualModel(res);
            if (this.individual) {
                this.showIndividualToCompanyModal = true;
            } else if (this.company) {
                this.showCompanyToIndividualModal = true;
            }
        });
    }
}
