import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { AuthenticationService } from 'app/nexus-core/services/authentication.service';
import { ApplicationConfigurationService, LoggingService, ToastService } from 'app/nexus-core/services';

@Injectable()
export class HttpInterceptorUtility implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService,
        private toastService: ToastService,
        private loggingService: LoggingService,
        private applicationConfigurationService: ApplicationConfigurationService
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.authenticationService.getTokenSilently(false).pipe(
            mergeMap(token => {
                let headers: HttpHeaders = request.headers ? request.headers : new HttpHeaders();

                headers = headers.append('Accept', 'q=0.8;application/json;q=0.9');

                if (token) {
                    headers = headers.append('Authorization', `Bearer ${token}`);
                }

                request = request.clone({
                    headers: headers,
                    url: request.url
                });

                return next.handle(request).pipe(catchError(err => {

                    if (err.status === 401) {
                        this.applicationConfigurationService.errorPageTrigger$.next('Your session has expired, try to log in again (401).');
                    } else if (err.status === 403) {
                        this.applicationConfigurationService.errorPageTrigger$.next('You do not have access to this resource (403).');
                    } else if (err.status === 500) {
                        this.toastService.showMessageToast('A server error occurred (500).');
                    } else if (err.status === 400) {
                        this.toastService.showMessageToast('A contact error occurred (400).');
                    } else if (err.status === 504) {
                        this.toastService.showMessageToast('A timeout error occurred (504).');
                    } else if (err.status === 422) {
                        // IF RESPONSE TYPE == TEXT THEN THE VALIDATION RESPONSE MESSAGE ENDS UP BEING A STRING, CONVERT TO JSON FOR DOWNSTREAM PROCESSING
                        if (request.responseType === 'blob') {
                            this.toastService.showMessageToast('The system failed to return the requested file.');
                            return;
                        }

                        if (typeof err.error === 'string') {
                            err.error = JSON.parse(err.error);
                        }

                        return throwError(err);
                    } else {
                        this.toastService.showMessageToast(`An unknown has error occurred (${err?.status ?? -1}).`);
                    }

                    this.loggingService.logError(err);

                    return throwError(err.error || err?.error?.message || err?.message || err.statusText);
                }));
            }),
            catchError((error) => {
                if (error && error.error === 'login_required') {
                    this.authenticationService.login();
                }

                return throwError(error);
            })
        );
    }
}
