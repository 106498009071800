<gtn-template-readonly-edit-card [(isSaving)]="isSaving" [formGroupRef]="formGroupRef"
    [validationModels]="validationModels" [readonly]="readonly" [showActionButtonsInCard]="true"
    [showCardTitleIcon]="canEdit"
    (readonlyChange)="onReadOnlyChanged($event)" (saveClick)="onSaveClicked()" (cancelClick)="onCancelClicked()">
    <gtn-template-readonly-edit-card-header>{{ pageTitle }}</gtn-template-readonly-edit-card-header>
    <gtn-template-readonly-edit-card-view-content>
        <gtn-user-view [viewMode]="viewMode" *ngIf="readonly" [value]="value"></gtn-user-view>
    </gtn-template-readonly-edit-card-view-content>
    <gtn-template-readonly-edit-card-edit-content>
        <gtn-user-form [viewMode]="viewMode" *ngIf="!readonly" [(formGroupRef)]="formGroupRef" [(value)]="value"
            (formGroupRefChange)="onFormGroupRefChanged($event)"></gtn-user-form>
    </gtn-template-readonly-edit-card-edit-content>
    <gtn-template-readonly-edit-card-footer>
        <button mat-flat-button
            *ngIf="value?.userKey && viewMode === viewModes.General && (value?.status === userStatusesEnum.Created || value?.status === userStatusesEnum.Invited)"
            class="mat-button-primary"
            [disabled]="isInvited || isInviting"
            matTooltip="Invite"
            (click)="onInviteClicked()">
            Invite
        </button>
        <button mat-flat-button
            *ngIf="value?.userKey && viewMode === viewModes.General && (value?.status === userStatusesEnum.Complete || value?.status === userStatusesEnum.LockedOut)"
            class="mat-button-primary mat-accent"
            [disabled]="isReset || isResetting || value?.status === userStatusesEnum.LockedOut"
            matTooltip="Reset Password"
            (click)="onPasswordResetClicked()">
            Reset Password
        </button>
    </gtn-template-readonly-edit-card-footer>
</gtn-template-readonly-edit-card>
