export enum FilterTypeEnum {
    Unknown = 0,
    Select = 1,
    SelectEnum = 2,
    ActiveDateStart = 3,
    ActiveDateEnd = 4,
    Daterange = 5,
    Datepicker = 6,
    Multiselect = 7,
    Combobox = 8
}
