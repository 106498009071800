import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { IHeaderGroupAngularComp } from 'ag-grid-angular';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { ReportHeaderGroupParamsModel } from 'app/nexus-shared/components/controls/components/base-grid/report-header-group/report-header-group-params.model';

@Component({
    selector: 'gtn-report-header-group',
    templateUrl: './report-header-group.component.html',
    styleUrls: ['./report-header-group.component.scss']
})
export class ReportHeaderGroupComponent extends BaseComponent implements IHeaderGroupAngularComp {
    params: ReportHeaderGroupParamsModel;

    @ViewChild('headerCell', { static: true }) headerCell: ElementRef;

    constructor(private renderer: Renderer2) {
        super();
    }

    refresh = (params: ReportHeaderGroupParamsModel): boolean => {
        return true;
    };

    agInit(params: ReportHeaderGroupParamsModel): void {
        this.params = params;

        this.renderer.setStyle(this.headerCell.nativeElement.parentElement, 'height', '100%');
        this.renderer.setStyle(this.headerCell.nativeElement.parentElement, 'width', '100%');

        if (params.styles) {
            const styles = params.styles;

            Object.keys(styles).forEach((key => {
                this.renderer.setStyle(this.headerCell.nativeElement, key, styles[key]);
            }).bind(this));
        }
    }
}
