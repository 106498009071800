<form [formGroup]="formGroupRef"
    *ngIf="formGroupRef">
    <gtn-template-dynamic-card *ngIf="(viewMode === viewModes.All || viewMode === viewModes.General)"
        [mode]="cardMode">
        <ng-template #content>
            <div class="row"
                *ngIf="isIndividualSearchVisible">
                <div class="col-3">
                    <gtn-individual-search
                        label="{{(!value?.userKey ? 'Create from Individual. . .' : 'Associated to contact . . .')}}"
                        (searchResultClick)="onIndividualClicked($event)">
                    </gtn-individual-search>
                </div>
            </div>
            <div class="row"
                *ngIf="value.userKey">
                <gtn-input class="col-3"
                    [disabled]="true"
                    formControlName="firstName"
                    type="text"
                    label="First Name">
                </gtn-input>
                <gtn-input class="col-3"
                    [disabled]="true"
                    formControlName="lastName"
                    type="text"
                    label="Last Name">
                </gtn-input>
                <gtn-input class="col-3"
                    [disabled]="true"
                    formControlName="email"
                    type="text"
                    label="Login Email Address">
                </gtn-input>
            </div>
            <div class="row"
                *ngIf="value.userKey && value.status !== userStatuses.Created">
                <div class="col-3">
                    <gtn-slide-toggle formControlName="isLockedOut"
                        label="Locked Out">
                    </gtn-slide-toggle>
                </div>
            </div>
        </ng-template>
    </gtn-template-dynamic-card>
    <gtn-template-dynamic-card *ngIf="(viewMode === viewModes.All || viewMode === viewModes.Roles)"
        [mode]="cardMode">
        <ng-template #content>
            <gtn-user-role-subform [formArray]="policiesFormArray"
                [isGtnEmail]="isGtnEmail"></gtn-user-role-subform>
        </ng-template>
    </gtn-template-dynamic-card>
</form>
