import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'gtn-notification-item',
    templateUrl: './notification-item.component.html',
    styleUrls: ['./notification-item.component.scss']
})
export class NotificationItemComponent {
    @Input() isLinkActive: boolean;
    @Input() isDeleteVisible: boolean;
    @Input() notification: any;
    @Input() notificationIdPropertyName: string;

    @Output() deleteClick: EventEmitter<any> = new EventEmitter();
    @Output() linkClick: EventEmitter<any> = new EventEmitter();

    constructor() {
    }

    onDeleteClick() {
        if (this.notificationIdPropertyName && this.notification) {
            this.deleteClick.emit(this.notification);
        }
    }

    onLinkClick() {
        if (this.isLinkActive && this.notification) {
            this.linkClick.emit(this.notification);
        }
    }
}
