import { Component } from '@angular/core';
import { ProgressStepperComponent } from 'app/nexus-shared/components/controls/components/progress-stepper/progress-stepper.component';

@Component({
    selector: 'gtn-progress-stepper-content',
    template: '<ng-content></ng-content>'
})
export class ProgressStepperContentComponent {
    // noinspection JSUnusedLocalSymbols
    constructor(gtnProgressStepperComponent: ProgressStepperComponent) {
        //THIS WILL THROW AN ERROR IF ITS NOT NESTED WITHIN THE progress stepper
    }
}
