<div class="year-view-months">
    <div class="row">
        <ng-container *ngFor="let month of months; index as i">
            <ng-container *ngIf="displayedMonths.indexOf(i) > -1">
                <div @fadeIn class="col-sm-12 col-md-6 col-lg-4">
                    <div class="month-header">{{ month }}</div>
                    <gtn-calendar-month-view [template]="template" [month]="i" [year]="year" [isYearView]="true"
                        [weekDayStart]="weekDayStart">
                    </gtn-calendar-month-view>
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>

