import { Component } from '@angular/core';
import { TemplateGridComponent } from 'app/nexus-shared/components/templates/template-grid/template-grid.component';

@Component({
    selector: 'gtn-template-grid-controls-left',
    template: '<ng-content></ng-content>'
})
export class TemplateGridControlsLeftComponent {
    // noinspection JSUnusedLocalSymbols
    constructor(templateGridPageComponent: TemplateGridComponent) {
        // THIS WILL THROW AN ERROR IF ITS NOT NESTED WITHIN PARENT
    }
}
