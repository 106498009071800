import { Injectable } from '@angular/core';
import { BaseHttpService, BaseService } from 'app/nexus-core/services';
import { Observable } from "rxjs";
import { environment } from "environments/environment";
import { AgreementsReviewerSearchModel } from 'app/nexus-shared/domain/agreements';
import { IAgreementsReviewerServiceInterface } from './iagreements-reviewer-service.interface';
import { IndividualInterface } from 'app/nexus-shared/interfaces/individual.interface';

@Injectable()
export class AgreementsReviewerService extends BaseService implements IAgreementsReviewerServiceInterface {
    private apiUrl = environment().webAPIUrls.agreements + 'agreements-reviewers';

    constructor(private baseHttpService: BaseHttpService) {
        super();
    }

    search(searchModel: AgreementsReviewerSearchModel): Observable<IndividualInterface[]> {
        return this.baseHttpService.post(`${this.apiUrl}/search`, searchModel);
    }
}
