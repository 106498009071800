export enum GtnTitlesEnum {
    Unknown = 0,
    AccountantI = 1,
    AccountingSupervisor = 2,
    AccountsPayableSupervisor = 3,
    AccountsReceivableSupervisor = 4,
    BusinessDevelopmentRepresentative = 5,
    COO = 6,
    CSPLead = 7,
    ClientServiceProfessional = 8,
    Director = 9,
    DirectorBusinessDevelopment = 10,
    DirectorCompliance = 11,
    DirectorFinance = 12,
    DirectorSoftwareDevelopment = 13,
    DirectorTechnology = 14,
    ExperienceDesignerI = 15,
    ExperienceDesignerII = 16,
    ExperienceDesignerIII = 17,
    HumanResourcesManager = 18,
    Intern = 19,
    Manager = 20,
    ManagingDirector = 21,
    MarketingCoordinator = 22,
    MarketingDirector = 23,
    MarketingManager = 24,
    President = 25,
    Senior = 26,
    SeniorManager = 27,
    SoftwareDeveloperI = 28,
    SoftwareDeveloperII = 29,
    SoftwareDeveloperIII = 30,
    SrDirectorStrategicandSustainabilityInitiatives = 31,
    Staff = 32,
    TechnicalSupportI = 33,
    TechnicalSupportII = 34,
    TechnologyOperationsEngineerI = 35,
    SupervisingSenior = 36
}
