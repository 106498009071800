import { Component, forwardRef, Input, OnChanges } from '@angular/core';
import { DropdownComponent } from 'app/nexus-shared/components/controls';
import { CurrencyModel } from 'app/nexus-shared/domain/currency/index';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { CurrencyService } from 'app/nexus-core/services/currency.service';
import { SimpleChangesTyped } from 'app/nexus-shared/models/simple-changes-typed.type';

@Component({
    selector: 'gtn-currency-select',
    templateUrl: '../../../components/controls/components/dropdown/dropdown.component.html',
    styleUrls: ['../../../components/controls/components/dropdown/dropdown.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CurrencySelectComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => CurrencySelectComponent),
            multi: true
        }
    ]
})
export class CurrencySelectComponent extends DropdownComponent<CurrencyModel> implements ControlValueAccessor, OnChanges {
    @Input() sortCurrencies: boolean = true;
    @Input() removeDuplicates: boolean = true;
    @Input() locationIdToSetCurrency: number;
    displayKey = ObjectHelper.nameOf<CurrencyModel>('currencyCode');
    label = 'Currency';
    allCurrencies: CurrencyModel[];

    constructor(private currencyService: CurrencyService) {
        super();
    }

    ngOnChanges(changes: SimpleChangesTyped<this>) {
        super.ngOnChanges(changes);
        if(changes.locationIdToSetCurrency && this.locationIdToSetCurrency && this.options?.length){
            this.setCurrencyToCountryId();
        }
    }

     dataFetch = () => {
        return this.currencyService.list(this.sortCurrencies);
    };

     handleResponse(data: CurrencyModel[]): void {
        this.allCurrencies = JSON.parse(JSON.stringify(data));
        this.options = this.removeDuplicates ? CurrencyService.removeDuplicates(data) : data;

        if (this.locationIdToSetCurrency) {
            this.setCurrencyToCountryId();
        }
    }

     initOptions(): void {
        if (this.options == null) {
            this.dataFetch().subscribe(data => {
                this.handleResponse(data);
            });
        }
    }

    private setCurrencyToCountryId(): void {
        const option = this.options.find(x => x.locationId === this.locationIdToSetCurrency);
        if (option) {
            this.value = option;
        } else {
            const currency = this.allCurrencies.find(x => x.locationId === this.locationIdToSetCurrency);
            this.options.push(currency);
            this.value = currency;
        }
    }
}
