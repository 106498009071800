import { ApplicationsEnum } from 'app/nexus-shared/enums';

export class IndividualSettingModel {
    individualSettingId: number;
    individualKey: string;
    application: ApplicationsEnum;
    key: string;
    value: any;

    constructor(model: IndividualSettingModel = null) {
        this.individualSettingId = model?.individualSettingId ?? null;
        this.individualKey = model?.individualKey ?? null;
        this.application = model?.application ?? null;
        this.key = model?.key ?? null;
        this.value = model?.value ?? null;
    }
}
