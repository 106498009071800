import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DynamicFormFieldConfig } from 'app/nexus-shared/components/dynamic-form/shared/interfaces/dynamic-form-field-config.interface';

@Component({
    selector: 'gtn-dynamic-form',
    templateUrl: './dynamic-form.component.html',
    styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent {
    @Input() customFields: DynamicFormFieldConfig[] = [];
    @Input() formGroup: UntypedFormGroup;

    constructor() {
    }

}
