<gtn-modal [headerText]="'Confirm ' + confirmActionHeader"
    cancelButtonText="Cancel"
    okButtonText="Confirm"
    [disableOkButton]="isConfirmDisabled"
    (okClick)="onOkClicked()"
    (cancelClick)="onCancelClicked()">
    <gtn-modal-content>
        <div class="mb-3">
            Continue with {{confirmActionText}}
        </div>
        <div class="delete-entities">
            <ng-container *ngFor="let entity of confirmActionEntities">
                <div class="entity">{{entity}}</div>
            </ng-container>
        </div>
        <div class="delete-warning">
            {{confirmActionWarning}}
        </div>
    </gtn-modal-content>
</gtn-modal>
