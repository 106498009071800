<gtn-template-entry-page [formGroupRef]="formGroupRef" [(pendingChanges)]="pendingChanges" [hideActionButtons]="true"
    (saveClick)="onSaveClicked()" (pendingChangesDiscardClick)="onPendingChangesDiscardClicked()">
    <gtn-template-entry-page-content>
        <mat-tab-group #tabGroup class="gtn-tabs modal-tab-container" [(selectedIndex)]="activeTab">
            <mat-tab class="mat-tab-fixed" label="General">
                <gtn-document-readonly-edit *ngIf="value && activeTab === 0" pageTitle="Details"
                    [viewMode]="viewModes.General" [(executeSave)]="executeSave" [(formGroupRef)]="formGroupRef"
                    [documentKey]="value.documentKey" [canEdit]="canEdit"
                    [value]="value" (saveComplete)="onSaveSuccess()">
                </gtn-document-readonly-edit>
            </mat-tab>
            <mat-tab class="mat-tab-fixed" label="Versions">
                <gtn-document-versions *ngIf="documentVersions?.length && activeTab === 1"
                    [documentVersions]="documentVersions" [canRestore]="canRestore" [areaType]="areaType"
                    [documentKey]="documentKey" [canEdit]="canEdit" [canPreview]="canPreview"
                    (restoreVersion)="onDocumentRestored()"
                    (copyVersionToFolder)="onCopyVersion($event)"></gtn-document-versions>
            </mat-tab>
            <mat-tab class="mat-tab-fixed" label="History">
                <gtn-document-history *ngIf="documentHistory?.length && activeTab === 2"
                    [fullDocumentHistory]="documentHistory" [documentUserAuth]="documentsUserAuth" [areaType]="areaType"
                    [documentKey]="documentKey" [(isFullHistory)]="isFullHistory"></gtn-document-history>
            </mat-tab>
            <mat-tab class="mat-tab-fixed" label="Notes">
                <gtn-document-readonly-edit *ngIf="value && activeTab === 3" pageTitle="Document Notes"
                    [viewMode]="viewModes.Notes" [(executeSave)]="executeSave" [(formGroupRef)]="formGroupRef"
                    [documentKey]="value.documentKey" [canEdit]="canEdit"
                    [value]="value" (saveComplete)="onSaveSuccess()">
                </gtn-document-readonly-edit>
            </mat-tab>
        </mat-tab-group>
    </gtn-template-entry-page-content>
</gtn-template-entry-page>
