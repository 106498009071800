import { Component, Input, OnInit } from '@angular/core';
import { BaseViewComponent } from 'app/nexus-shared/components/base-component/base-view.component';
import { ApplicationUrlsService } from 'app/nexus-core/services/domain/core/application-urls.service';
import { ApplicationsEnum } from 'app/nexus-shared/enums';
import { ApplicationUrlsRequestModel } from 'app/nexus-shared/models/application-urls-request.model';
import { ApplicationUrlsModel } from 'app/nexus-shared/models/application-urls.model';
import { EnumHelper } from 'app/nexus-core';

@Component({
    selector: 'gtn-application-links',
    templateUrl: './application-links.component.html',
    styleUrls: ['./application-links.component.scss']
})
export class ApplicationLinksComponent extends BaseViewComponent<ApplicationsEnum[]> implements OnInit {
    @Input() currentApplication: ApplicationsEnum;
    @Input() companyKey: string;
    @Input() individualKey: string;
    @Input() myGTNPortalUserKey: string;
    @Input() myGTNPortalCompanyKey: string;
    @Input() documentsClientKey: string;
    @Input() isDisplayingLogo: boolean = false;
    @Input() isDisplayHeader: boolean = true;

    isAssociatedMyGTNPortal: boolean = false;
    requestModel: ApplicationUrlsRequestModel;
    applicationsEnum = ApplicationsEnum;

    constructor(private applicationUrlsService: ApplicationUrlsService) {
        super();
    }

    ngOnInit(): void {
        this.requestModel = <ApplicationUrlsRequestModel>{
            companyKey: this.companyKey ?? null,
            individualKey: this.individualKey ?? null,
            myGTNPortalUserKey: this.myGTNPortalUserKey ?? null,
            myGTNPortalCompanyKey: this.myGTNPortalCompanyKey ?? null,
            documentsClientKey: this.documentsClientKey ?? null,
            application: null
        };
        if ((!!this.companyKey || !!this.individualKey) && this.value.indexOf(ApplicationsEnum.Contacts) < 0) {
            this.value.unshift(ApplicationsEnum.Contacts);
        }
        this.isAssociatedMyGTNPortal = this.value.some(x => x === ApplicationsEnum.MyGTNPortal);
        this.orderApplicationEnums();
    }

    getAppName(application: ApplicationsEnum): string {
        if (application === ApplicationsEnum.Traction) {
            return 'ClientHub';
        }

        return EnumHelper.getDisplayName(ApplicationsEnum, application);
    }

    onLinkClicked(application: ApplicationsEnum): void {
        this.requestModel.application = application;
        this.applicationUrlsService.list(this.requestModel).subscribe(res => {
            this.navigateToLink(application, res);
        });
    }

    private navigateToLink(application: ApplicationsEnum, applicationUrls: ApplicationUrlsModel): void {
        if (application === ApplicationsEnum.MyGTNPortal) {
            if (!!this.myGTNPortalCompanyKey) {
                window.open(applicationUrls.myGTNPortalCompanyUrl, '_blank');
            } else if (!!this.myGTNPortalUserKey) {
                window.open(applicationUrls.myGTNPortalUserUrl, '_blank');
            } else {
                window.open(applicationUrls.rootMyGTNPortalApplicationUrl, '_blank');
            }
        } else {
            if (!!this.documentsClientKey) {
                window.open(applicationUrls.documentsClientsUrl, '_blank');
            } else if (!!this.individualKey || !!this.companyKey) {
                if (applicationUrls.applicationContactsRedirectUrl.indexOf('contacts.gtn') > -1) {
                    applicationUrls.applicationContactsRedirectUrl = applicationUrls.applicationContactsRedirectUrl.replace('contacts.gtn', 'core.gtn');
                }
                window.open(applicationUrls.applicationContactsRedirectUrl, '_blank');
            } else {
                window.open(applicationUrls.rootNexusApplicationUrl, '_blank');
            }
        }
    }

    private orderApplicationEnums(): void {
        const sortOrder: ApplicationsEnum[] = [
            ApplicationsEnum.Contacts,
            ApplicationsEnum.Traction,
            ApplicationsEnum.Vendors,
            ApplicationsEnum.Documents,
            ApplicationsEnum.Accounting,
            ApplicationsEnum.Portal
        ];
        this.value.sort((a, b) => sortOrder.indexOf(a) - sortOrder.indexOf(b));
    }
}
