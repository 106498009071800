import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IconSelectListModel } from 'app/nexus-shared/components/controls/shared';
import { DropdownComponent } from '../dropdown';

@Component({
    selector: 'gtn-icon-dropdown',
    templateUrl: './icon-dropdown.component.html',
    styleUrls: ['./icon-dropdown.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => IconDropdownComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => IconDropdownComponent),
            multi: true
        }
    ]
})
export class IconDropdownComponent extends DropdownComponent<IconSelectListModel> implements ControlValueAccessor {
}
