import { Injectable } from '@angular/core';
import { IndividualService } from 'app/nexus-core/services/domain/contacts/individual.service';
import { CompanyService } from 'app/nexus-core/services/domain/contacts/company.service';
import { forkJoin, Observable } from 'rxjs';
import { IndividualSearchModel } from 'app/nexus-shared/domain/contacts/models/individual-search.model';
import { CompanySearchModel } from 'app/nexus-shared/domain/contacts/models/company-search.model';
import { Router } from '@angular/router';
import { ContactsRoutingCompaniesConfigurationConstants } from 'app/modules/contacts/routing/contacts-routing-companies-configuration.constants';
import { ContactsRoutingIndividualsConfigurationConstants } from 'app/modules/contacts/routing/contacts-routing-individuals-configuration.constants';
import { BaseCoreGlobalSearchService } from 'app/nexus-core/services/base-core-global-search.service';
import { CompanyModel, IndividualModel } from 'app/nexus-shared/domain/contacts/models';
import { SearchResultIconEnum, SearchResultModel } from 'app/nexus-shared/components/controls';
import { SearchHelper } from 'app/nexus-core/index';
import { ContactsGlobalSearchResultModel } from 'app/nexus-shared/domain/contacts/models/contacts-global-search-result.model';

@Injectable()
export class ContactsGlobalSearchService extends BaseCoreGlobalSearchService<ContactsGlobalSearchResultModel, IndividualModel | CompanyModel> {

    constructor(
        private companyService: CompanyService,
        private individualService: IndividualService,
        private router: Router
    ) {
        super();
    }

    protected getSearchResults(searchText: string): Observable<ContactsGlobalSearchResultModel> {
        return forkJoin({
            individuals: this.individualService.search(<IndividualSearchModel>{ searchTerm: searchText }),
            companies: this.companyService.search(<CompanySearchModel>{ searchTerm: searchText })
        });
    }

    protected handleSearchResults(results: ContactsGlobalSearchResultModel, searchText: string): SearchResultModel<void, CompanyModel | IndividualModel>[] {
        const individualSearchResults = results.individuals.map((individual: IndividualModel) => {
            return {
                id: individual.individualKey,
                name: individual.fullName,
                subName: individual.emailAddress,
                type: null,
                iconClass: SearchResultIconEnum.user,
                resultObject: individual,
                rank: 0
            } as SearchResultModel<void, IndividualModel>;
        });
        const companySearchResults = results.companies.map((company: CompanyModel) => {
            return {
                id: company.companyKey,
                name: company.name,
                subName: null,
                type: null,
                iconClass: SearchResultIconEnum.business,
                resultObject: company,
                rank: 1
            } as SearchResultModel<void, CompanyModel>;
        });
        const orderedIndividualResults: SearchResultModel<void, IndividualModel>[] = SearchHelper.orderSearchResults(individualSearchResults, searchText, []);
        const orderedCompanyResults: SearchResultModel<void, CompanyModel>[]  = SearchHelper.orderSearchResults(companySearchResults, searchText, []);
        return [...orderedCompanyResults, ...orderedIndividualResults];
    }

    protected onResultClicked(searchResult: SearchResultModel<any, any>) {
        if (searchResult?.resultObject) {
            if (searchResult.resultObject?.companyKey) {
                this.router.navigate(ContactsRoutingCompaniesConfigurationConstants.configurations.getGeneral().routing.navigationRoute(searchResult.resultObject.companyKey));
            } else if (searchResult.resultObject?.individualKey) {
                this.router.navigate(ContactsRoutingIndividualsConfigurationConstants.configurations.getGeneral().routing.navigationRoute(searchResult.resultObject.individualKey));
            }
        }
    }
}
