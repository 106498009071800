// @ts-ignore
import {} from '@types/google.maps';

export class GoogleAddressPredictionModel {
    address: string;
    placeId: string;

    constructor(model: google.maps.places.AutocompletePrediction = null) {
        this.address = model?.description;
        this.placeId = model?.place_id;
    }

}
