import { Injectable } from '@angular/core';
import { HttpClient, JsonpClientBackend } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class BypassHttpInterceptorService {
    private httpClient: HttpClient;

    //jsonp client backend is used here, but HttpClientBackend can used to send http requests in a separate handler
    constructor(
        private handler: JsonpClientBackend
    ) {
        this.httpClient = new HttpClient(this.handler);
    }

    jsonp(url: string, callback: string): Observable<any> {
        return this.httpClient.jsonp(url, callback);
    }
}
