<mat-card [ngClass]="{'fill-content-height': isFillContentHeight}">
    <div #headerContent [ngClass]="{'card-header': showHeader, 'read-only-form': appearance === appearances.readonly}">
        <mat-card-title class="gtn-template-card-title" *ngIf="showHeader">
            <ng-content select="gtn-template-card-header"></ng-content>
        </mat-card-title>
        <mat-card-content class="gtn-template-card-content">
            <ng-content select="gtn-template-card-content"></ng-content>
        </mat-card-content>
    </div>
</mat-card>
