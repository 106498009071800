import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { SearchResultModel } from 'app/nexus-shared/components/controls';
import { ApplicationSearchService } from 'app/nexus-core/services/application-search.service';
import { BaseService } from 'app/nexus-core/services/base-service.directive';

@Injectable()
export abstract class BaseCoreGlobalSearchService<R, T> extends BaseService {
    textChangeSubscription: Subscription;
    resultClickSubscription: Subscription;

    initGlobalSearch(applicationSearchService: ApplicationSearchService): void {
        applicationSearchService.showSearchBar();
        this.textChangeSubscription = applicationSearchService.onTextChanged.subscribe(searchText => {
            this.getSearchResults(searchText).subscribe(res => {
                applicationSearchService.onResultsReceived.next(this.handleSearchResults(res, searchText));
            });
        });

        this.resultClickSubscription = applicationSearchService.onResultClicked.subscribe(result => {
            this.onResultClicked(result);
        });
    }

    destroyGlobalSearch(applicationSearchService: ApplicationSearchService): void {
        applicationSearchService.onClearSearchText.next();
        this.textChangeSubscription.unsubscribe();
        this.resultClickSubscription.unsubscribe();
    }

    protected abstract onResultClicked(searchResult: SearchResultModel<void, T>): void;

    protected abstract getSearchResults(searchText: string): Observable<R>;

    protected abstract handleSearchResults(results: R, searchText: string): SearchResultModel<void, T>[];
}
