import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NexusAnimations } from 'app/nexus-shared/animations';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { SupportEmailService } from 'app/nexus-core/services/support-email.service';
import { environment } from 'environments/environment';
import { ToastService } from 'app/nexus-core/services/toast.service';
import { AuthenticatedUserService } from 'app/nexus-core/services/authenticated-user.service';
import { LoggingService } from 'app/nexus-core';

@Component({
    selector: 'gtn-contact-support-modal',
    templateUrl: './contact-support-modal.component.html',
    styles: [
        `
            .error-toggle {
                text-align: right;
                cursor: pointer;
            }
        `
    ],
    animations: [
        NexusAnimations.modalFadeIn
    ]
})
export class ContactSupportModalComponent extends BaseComponent implements OnInit {
    @Output() completed = new EventEmitter<boolean>();

    firstName: string = null;
    lastName: string = null;
    email: string = null;
    contactNotes: string = null;
    showErrors = false;

    disableButtons = false;

    constructor(
        public loggingService: LoggingService,
        private supportEmailService: SupportEmailService,
        private toastService: ToastService,
        private authenticatedUserService: AuthenticatedUserService) {
        super();
    }

    ngOnInit(): void {
        if (this.authenticatedUserService.user) {
            this.firstName = this.authenticatedUserService.user.firstName;
            this.lastName = this.authenticatedUserService.user.lastName;
            this.email = this.authenticatedUserService.user.email;
        }
    }

    onContactClicked(): void {
        this.disableButtons = true;
        this.supportEmailService.sendSupportEmail(this.firstName, this.lastName, this.email, this.contactNotes).subscribe(
            _ => {
                this.toastService.showMessageToast('Support email has been sent successfully');
                this.completed.emit(true);
                this.disableButtons = false;
            },
            _ => {
                this.toastService.showMessageToast(`An error occurred, please email ${environment().supportEmailAddress} directly`);
                this.disableButtons = false;
            }
        );
    }

    onCancelClicked(): void {
        this.completed.emit(false);
    }
}
