<ng-content select="gtn-template-grid-header"></ng-content>
<div [ngClass]="background">
    <h3 *ngIf="headerTitle">{{ headerTitle }}</h3>
    <div class="template-grid-controls align-items-end">
        <div class="template-grid-control-containers left">
            <ng-content select="gtn-template-grid-controls-left"></ng-content>
            <div class="quick-filter-container">
                <div *ngIf="showQuickFilter && !quickFilterPositionRight"
                    class="time-entry-quick-filter left-quick-filter">
                    <gtn-input [(ngModel)]="filterText" [label]="quickFilterLabel"
                        [showClearButton]="true" (change)="quickFilterTextChange.emit($event)"></gtn-input>
                </div>
            </div>
            <div class="chips-container flex align-items-end">
                <gtn-shared-saved-view-chips *ngIf="gridStateKey && application" class="shared-setting-chips"
                    [application]="application" [key]="gridStateKey" [gridSettings]="gridSettings"
                    (settingClick)="chipGridSettingClick.emit($event)"></gtn-shared-saved-view-chips>
                <gtn-filter-chips *ngIf="gridFilterChips?.length" [filterChips]="gridFilterChips"
                    [isRemovableChips]="true"
                    (filterChipRemoveClick)="gridFilterChipRemoveClick.emit($event)"></gtn-filter-chips>
            </div>
        </div>
        <div class="template-grid-control-containers right">
            <div *ngIf="showQuickFilter &&  quickFilterPositionRight"
                class="time-entry-quick-filter right-quick-filter">
                <gtn-input *ngIf="showQuickFilter" [(ngModel)]="filterText" [label]="quickFilterLabel"
                    [showClearButton]="true" (change)="quickFilterTextChange.emit($event)"></gtn-input>
            </div>
            <ng-content select="gtn-template-grid-controls-right"></ng-content>
            <div class="flex mb-2">
                <button *ngIf="showExpandCollapse"
                    (click)="onToggleExpandCollapsed(isSessionGroupExpanded = !isSessionGroupExpanded)"
                    class="mr-2"
                    color="default"
                    mat-mini-fab
                    [matTooltip]="isSessionGroupExpanded ? 'Collapse' : 'Expand'">
                    <mat-icon *ngIf="isSessionGroupExpanded">keyboard_double_arrow_up</mat-icon>
                    <mat-icon *ngIf="!isSessionGroupExpanded">keyboard_double_arrow_down</mat-icon>
                </button>
                <button *ngIf="showExportButton"
                    (click)="exportButtonClick.emit()"
                    class="mr-2"
                    color="default"
                    mat-mini-fab
                    matTooltip="Download Excel">
                    <mat-icon>file_download</mat-icon>
                </button>
                <button *ngIf="showAddButton" (click)="addButtonClick.emit()" [disabled]="isDisabledAddButton"
                    class="mr-2" color="primary"
                    mat-mini-fab matTooltip="Add">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div>
        <ng-content select="gtn-template-grid-content"></ng-content>
    </div>
</div>
