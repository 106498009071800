import { Directive, OnDestroy } from '@angular/core';
import { TestSelectors } from 'app/nexus-shared/constants/test-selector.constants';
import { Subscription } from 'rxjs';

@Directive()
export abstract class BaseDirective implements OnDestroy {
    public TestSelectors = TestSelectors;

    protected subscriptions = new Subscription();

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
