<div class="main-container">
    <div *ngIf="pageTitle">
        <h1 class="page-top-header">
            <span>{{pageTitle}}</span>
            <ng-content select="gtn-template-page-header"></ng-content>
        </h1>
    </div>
    <div class="row">
        <div class="col-12">
            <ng-content select="gtn-template-page-content">
            </ng-content>
        </div>
    </div>
</div>
