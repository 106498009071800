<gtn-template-view [cardMode]="cardMode">
    <gtn-template-view-content>
        <ng-container *ngIf="viewMode === viewModes.All || viewMode === viewModes.General">
            <div class="row">
                <div class="col-6">
                    <label class="list-label">File name</label>
                    <div class="data-field">
                        {{value.name}}
                    </div>
                </div>
                <div class="col-6">
                    <label class="list-label">File type</label>
                    <div class="data-field">
                        {{value.file.extension}}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <label class="list-label">File path</label>
                    <div class="data-field">
                        {{value.documentPath}}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <label class="list-label">Created by/date</label>
                    <div class="data-field">
                        {{value.createdByUser.fullName}} - {{value.createdByUser.changeDateTime| gtnDate : true}}
                    </div>
                </div>
                <div class="col-6">
                    <label class="list-label">Last modified by/date</label>
                    <div class="data-field">
                        {{value.lastModifiedByUser.fullName}} - {{value.lastModifiedByUser.changeDateTime| gtnDate : true}}
                    </div>
                </div>
            </div>
            <ng-container *ngIf="value?.clientDocument?.individualKey || value?.clientDocument?.companyKey || value.areaType === areaTypesEnum.FileTemplates">
                <div class="section-header">
                    Client document details
                </div>
                <div class="row">
                    <div class="col-4" *ngIf="value.clientDocument.companyKey">
                        <label class="list-label">Company</label>
                        <div class="data-field">
                            {{value.clientDocument.companyLegalName || value.clientDocument.companyName}}
                        </div>
                    </div>
                    <div class="col-4" *ngIf="value.clientDocument.individualKey">
                        <label class="list-label">Individual</label>
                        <div class="data-field">
                            {{value.clientDocument.individualLastName}}, {{value.clientDocument.individualFirstName}}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <label class="list-label">Tax year</label>
                        <div class="data-field">
                            {{taxYear || noValueText}}
                        </div>
                    </div>
                    <div class="col-4">
                        <label class="list-label">Country</label>
                        <div class="data-field" *ngIf="value.clientDocument.countryId">
                            {{value.clientDocument.countryId | country | async}}
                        </div>
                        <div class="data-field" *ngIf="!value.clientDocument.countryId">
                            {{noValueText}}
                        </div>
                    </div>
                    <div class="col-4">
                        <label class="list-label">Document type</label>
                        <div class="data-field" *ngIf="value.clientDocument.type">
                            {{value.clientDocument.type | enum : clientDocumentTypesEnum}}
                        </div>
                        <div class="data-field" *ngIf="!value.clientDocument.type">
                            {{noValueText}}
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="viewMode === viewModes.All || viewMode === viewModes.Notes">
            <div class="col-md-8">
                <div class="pre-line">
                    {{value?.note?.text || noValueText}}
                </div>
            </div>
        </ng-container>
    </gtn-template-view-content>
</gtn-template-view>
