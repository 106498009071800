import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'gtnPercent'
})
export class PercentPipe implements PipeTransform {
    transform(value: number): any {
        if (typeof value === 'number') {
            return (Math.round(10000 * (value * 100)) / 10000) + '%';
        }

        return value;
    }

}
