import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { LocalStorageHelper, ValidationErrorHelper } from 'app/nexus-core/helpers';
import { AuthenticatedUserSyncService } from 'app/nexus-core/services/authenticated-user-sync.service';
import { ApplicationConfigurationService, ResizeService } from 'app/nexus-core/services';
import { filter, first, flatMap, map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { NavigationEnd, Router } from '@angular/router';
import { BaseRouteApplicationConfigurationModel, LocalStorageNameConstants } from 'app/nexus-shared';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';
import { ScrollService } from './nexus-core/services/scroll.service';
import { AuthenticationService } from 'app/nexus-core/services/authentication.service';
import { AuthenticatedUserService } from 'app/nexus-core/services/authenticated-user.service';
import { ToastService } from 'app/nexus-core/services/toast.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { of } from 'rxjs';
import { IdleService } from 'app/nexus-core/services/idle.service';
import { DeviceService } from 'app/nexus-core/services/device.service';
import { PolicyModalTypes } from 'app/nexus-shared/enums/policy-modal-types.enum';

@Component({
    selector: 'gtn-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseComponent implements AfterViewInit, OnInit {

    @ViewChild('mainContent') mainContent: ElementRef;
    @ViewChild('sidenav') sidenav: MatSidenav;

    selectedApp: BaseRouteApplicationConfigurationModel;
    startOpened = true;

    title = 'app';
    isAuthInitiated = false;
    isUserSyncCompleted = false;
    errorMessage: string = null;
    show2FADialog = false;
    showAUADialog = false;
    showDataAcknowledgmentDialog = false;
    showBrowserCheckDialog = false;

    isAllowMultiItemsExpanded = true; // TODO: abstract this into menu configuration
    showTermsModal = false;
    showPrivacyModal = false;

    constructor(
        public authenticationService: AuthenticationService,
        public authenticatedUserService: AuthenticatedUserService,
        public applicationConfigurationService: ApplicationConfigurationService,
        private idleService: IdleService,
        private toastService: ToastService,
        private snackbar: MatSnackBar,
        private authenticatedUserSyncService: AuthenticatedUserSyncService,
        private router: Router,
        private scrollService: ScrollService,
        private resizeService: ResizeService,
        private deviceService: DeviceService
    ) {
        super();
        const startOpened = LocalStorageHelper.get(LocalStorageNameConstants.StartMenuOpened);
        if (startOpened === false) {
            this.startOpened = startOpened;
        }
        this.toastService.toast$.subscribe(toast => {
            this.snackbar.open(toast.message, toast.action, toast.config);
        });
    }

    ngOnInit(): void {
        this.authenticationService.error$.subscribe(errorMessage => {
            if (errorMessage) {
                this.hideAppLoadingSpinner();
                this.applicationConfigurationService.errorPageTrigger$.next(errorMessage);
            }
        });

        this.authenticationService.authenticationInitiated$.pipe(first()).subscribe(_ => {
            this.authenticatedUserService.user$.pipe(first()).subscribe((currentUser) => {
                if (currentUser && !!environment().auth.enabled) {
                    this.authenticatedUserSyncService.sync().pipe(flatMap(
                        (hasStateChanged: boolean) => {
                            if (hasStateChanged) {
                                return this.authenticationService.updateUserContext().pipe(map(_ => true));
                            }

                            return of(true);
                        })).subscribe(_ => {
                        if (!this.deviceService.isValidDevice()) {
                            this.hideAppLoadingSpinner();
                            this.showBrowserCheckDialog = true;
                        } else {
                            this.finalizeLoginSetup();
                        }
                    }, err => {
                        if (err.isServerError) {
                            this.redirectToError('There was a server error syncing your user.');
                        } else {
                            ValidationErrorHelper.handleServiceError(err, (validationModels) => {
                                if (validationModels?.length) {
                                    this.redirectToError(validationModels[0].message);
                                } else {
                                    this.redirectToError('There was a problem syncing your user.');
                                }

                                console.error(err);
                            });
                        }
                    });
                } else if (!this.authenticationService.error$.value) {
                    this.authenticationService.login();
                }

                this.isAuthInitiated = true;
            }, err => {
                this.redirectToError('Authentication failed to initialize user.');
            });
        }, err => {
            this.redirectToError('Authentication failed to initialize.');
            console.error(err);
        });

        this.applicationConfigurationService.appChange$.subscribe(app => {
            this.selectedApp = app;
        });

        this.applicationConfigurationService.errorPageTrigger$.subscribe(message => {
            this.errorMessage = message;
        });

        this.subscriptions.add(this.scrollService.onScrollTo.subscribe(
            scrollTo => {
                this.mainContent.nativeElement.scroll({
                    behavior: 'smooth',
                    block: 'start',
                    top: scrollTo.scrollTop,
                    left: scrollTo.scrollLeft
                } as ScrollIntoViewOptions);
            }
        ));
    }

    ngAfterViewInit(): void {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
            this.applicationConfigurationService.setActiveConfiguration();
        });
    }

    onBrowserWarningAcknowledged(): void {
        this.showBrowserCheckDialog = false;
        this.finalizeLoginSetup();
    }

    onTwoFactorCompleted(result: boolean): void {
        this.show2FADialog = false;

        if (result) {
            if (this.authenticatedUserService.user.requires2FA) {
                this.authenticationService.error$.next('There was a problem setting your 2FA claim your user.');
                return;
            }

            this.finalizeLoginSetup();
        } else {
            this.authenticationService.logout();
        }
    }

    onAUACompleted(result: boolean): void {
        this.showAUADialog = false;

        if (result) {
            if (this.authenticatedUserService.user.requiresAUA) {
                this.authenticationService.error$.next('There was a problem setting your AUA claim your user.');
                return;
            }

            this.finalizeLoginSetup();
        } else {
            this.authenticationService.logout();
        }
    }

    onDataAcknowledgmentCompleted(result: boolean): void {
        this.showDataAcknowledgmentDialog = false;

        if (result) {
            if (this.authenticatedUserService.user.requiresDataAcknowledgment) {
                this.authenticationService.error$.next('There was a problem setting your acknowledgment claim your user.');
                return;
            }

            this.finalizeLoginSetup();
        } else {
            this.authenticationService.logout();
        }
    }

    onNavMenuCloseStarted() {
        // when menu is collapsed, collapse all child menus
    }

    onSideNavToggled() {
        // once side nav is done collapsing, dispatch window resize so that components will size accordingly
        this.sidenav.toggle().then(() => {
            this.resizeService.resize.next();

            // persist the menu state to local storage
            LocalStorageHelper.set(LocalStorageNameConstants.StartMenuOpened, this.sidenav.opened);

            if (!this.sidenav.opened) {
                for (const mainMenuItem of this.selectedApp.childRoutes) {
                    if (mainMenuItem.childRoutes) {
                        for (const childRoute of mainMenuItem.childRoutes.filter(t => !t.menu.iconClass)) {
                            mainMenuItem.menu.isExpanded = false;
                            childRoute.menu.isExpanded = false;
                        }
                    }
                }
            }
        });
    }

    onOpenModal(modalType: PolicyModalTypes): void {
        if (modalType === PolicyModalTypes.Terms) {
            this.showTermsModal = true;
        } else if (modalType === PolicyModalTypes.Privacy) {
            this.showPrivacyModal = true;
        }
    }

    onCloseModal(): void {
        this.showTermsModal = false;
        this.showPrivacyModal = false;
    }

    private finalizeLoginSetup(): void {
        if (this.authenticatedUserService.user.requires2FA) {
            this.show2FADialog = true;
        } else if (this.authenticatedUserService.user.requiresAUA) {
            this.showAUADialog = true;
        } else if (this.authenticatedUserService.user.requiresDataAcknowledgment) {
            this.showDataAcknowledgmentDialog = true;
        } else {
            this.finalizeLogin();
        }

        this.hideAppLoadingSpinner();
    }

    private finalizeLogin(): void {
        this.isUserSyncCompleted = true;
        this.authenticatedUserSyncService.isSyncedCompleted.next(true);
        this.hideAppLoadingSpinner();

        this.idleService.idleWarning$.subscribe(_ => {
            this.applicationConfigurationService.showIdleWarningModal = true;
        });
    }

    private redirectToError(message: string): void {
        this.hideAppLoadingSpinner();
        this.authenticationService.error$.next(message);
    }

    private hideAppLoadingSpinner(): void {
        const matchingElements = document.getElementsByClassName('main-loading-spinner-container');

        if (matchingElements?.length) {
            matchingElements[0].remove();
        }
    }
}
