import { animate, animateChild, AnimationMetadata, group, query, stagger, style, transition, trigger } from '@angular/animations';
import { ChildSlideDown } from './child-slide-down';

export class MenuOpenDownChildSlideDown {
    /**
     * Use '@menuOpen' on the parent element
     * and '@childSlide' on the child element
     * in the markup.
     */
    static animations = MenuOpenDownChildSlideDown.getAnimation();

    private static getAnimation(): AnimationMetadata[] {
        return [trigger('menuOpen', [
            transition(':enter', [
                style({
                    overflow: 'hidden',
                    height: '0px'
                }),
                group([
                    animate('300ms', style({
                        overflow: 'hidden',
                        height: '*',
                        'padding': '5px 0'
                    })),
                    query('@childSlide',
                        stagger(70, [
                            animateChild()
                        ]),
                        {optional: true})
                ])
            ]),
            transition(':leave', [
                style({
                    overflow: 'hidden',
                    height: '*'
                }),
                group([
                    animate('300ms', style({
                        overflow: 'hidden',
                        height: '0px',
                        'padding': '0'
                    })),
                    query('@childSlide',
                        animateChild(),
                        {optional: true})
                ])
            ])
        ]),
            ChildSlideDown.animations
        ];
    }
}
