import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';

@Component({
    selector: 'gtn-collapsible-tray',
    templateUrl: './collapsible-tray.component.html',
    styleUrls: ['./collapsible-tray.component.scss']
})
export class CollapsibleTrayComponent extends BaseComponent {
    @Input() headerText: string;
    @Input() showCloseTray: boolean = false;
    @Input() showCollapsibleTrayContent: boolean = false;
    @Input() isInQuestionnaires: boolean = false;
    
    @Output() showCollapsibleTrayContentChange: EventEmitter<boolean> = new EventEmitter();
    @Output() closeTrayClick: EventEmitter<void> = new EventEmitter();

    onCloseTrayClicked(): void {
        this.closeTrayClick.emit();
    }

    onToggleTrayClicked(): void {
        this.showCollapsibleTrayContent = !this.showCollapsibleTrayContent;
        this.showCollapsibleTrayContentChange.emit(this.showCollapsibleTrayContent);
    }
}
