import { Component } from '@angular/core';
import { FilterableTilesComponent } from 'app/nexus-shared/components/controls/components/filterable-tiles/filterable-tiles.component';

@Component({
    selector: 'gtn-filterable-tiles-header',
    template: '<ng-content></ng-content>'
})
export class FilterableTilesHeaderComponent {
    // noinspection JSUnusedLocalSymbols
    constructor(gtnFilterableTilesComponent: FilterableTilesComponent) {
        //THIS WILL THROW AN ERROR IF ITS NOT NESTED WITHIN THE gtn-filterable-tiles ELEMENT
    }
}
