import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NexusAnimations } from 'app/nexus-shared/animations';
import { BaseFileViewComponent } from 'app/nexus-shared/components/controls/components/file-view/base-file-view.component';

@Component({
    selector: 'gtn-image-file-view',
    templateUrl: './image-file-view.component.html',
    styleUrls: ['./image-file-view.component.scss'],
    animations: [
        NexusAnimations.fadeIn
    ]
})
export class ImageFileViewComponent extends BaseFileViewComponent implements OnInit {

    constructor(private domSanitizer: DomSanitizer) {
        super();
    }

    ngOnInit(): void {
        if (this.isBlob) {
            this.src = this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.src.blob));
        }
    }
}
