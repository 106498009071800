import { Component } from '@angular/core';
import { BaseComponent } from 'app/nexus-shared/components/base-component/base.component';

@Component({
    templateUrl: './error.component.html',
    styleUrls: [
        '../../nexus-modal.component.scss',
        './error.component.scss'
    ]
})
export class ErrorComponent extends BaseComponent {
}
