<form [formGroup]="formGroupRef">
    <div class="row">
        <gtn-individual-search class="col-md-6" [readonly]="disableIndividualSearch" formControlName="individual"
            label="Assignee"></gtn-individual-search>
        <gtn-dropdown *ngIf="questionnaireType < 500" class="col-md-6" label="Questionnaire template"
            [idOnly]="true" formControlName="questionnaireTemplateKey" idKey="questionnaireTemplateKey"
            displayKey="title" [options]="questionnaireTemplateOptions"
            (change)="onQuestionnaireTemplateChanged($event['value'])"></gtn-dropdown>
    </div>
    <div class="row">
        <gtn-input class="col-md-6" formControlName="title" label="Title"></gtn-input>
        <gtn-input class="col-md-6" [readonly]="true" formControlName="year" type="number" label="Year"></gtn-input>
    </div>
    <div class="row">
        <gtn-slide-toggle class="col-md-2" formControlName="isAccessible" label="Accessible"></gtn-slide-toggle>
        <gtn-slide-toggle class="col-md-2" formControlName="isCompleted" label="Completed"></gtn-slide-toggle>
        <gtn-slide-toggle class="col-md-2" formControlName="isDeleted" label="Delete"></gtn-slide-toggle>
        <gtn-datepicker class="col-md-6" formControlName="dueDate" label="Due date"></gtn-datepicker>
    </div>
</form>
