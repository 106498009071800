import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { DateHelper, LocalStorageHelper } from 'app/nexus-core';
import { LocalStorageNameConstants, SelectListInterface } from 'app/nexus-shared/index';

import { TaxesGridFilterType } from 'app/nexus-shared/domain/taxes/enums/taxes-grid-filter-type.enum';
import { GtnTaxesGridFilterPanelComponent } from 'app/nexus-shared/domain/taxes/components/taxes-grid/filter-panel/filter-panel.component';
import { TaxesGridFilterModel } from 'app/nexus-shared/domain/taxes/models/taxes-grid-filter.model';
import { TaxJurisdictionModel, TaxModel } from 'app/nexus-shared/domain/taxes/models';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { TaxWithholdingType } from 'app/nexus-shared/domain/taxes/interfaces';
import { TaxService } from 'app/nexus-core/services/domain/taxes/tax.service';

@Component({
    selector: 'gtn-taxes-grid',
    templateUrl: './taxes-grid.component.html',
    styleUrls: ['./taxes-grid.component.scss']
})
export class TaxesGridComponent implements OnInit {
    @Input() isCollapsible = false;
    @Input() showTitle = false;
    @Input() title = 'Taxes';
    @Input() gridFilter: TaxesGridFilterModel;

    gridHeight: number | string | 'auto' = 'auto';
    data: TaxModel[] = [];
    isCollapsed: boolean = false;
    isLoading: boolean = false;
    selectedFilters: SelectListInterface[];
    panelComponent = GtnTaxesGridFilterPanelComponent;

    public defaultSortModel: { colId: string, sort: 'asc' | 'desc' }[] = [
        { colId: 'countryName', sort: 'asc' }
    ];

    public columnDefs = [
        {
            headerName: 'Country',
            field: ObjectHelper.nameOfSubProperty<TaxModel, TaxJurisdictionModel>('taxJurisdiction', 'countryName'),
            filter: false
        },
        {
            headerName: 'State/Province',
            field: ObjectHelper.nameOfSubProperty<TaxModel, TaxJurisdictionModel>('taxJurisdiction', 'stateName'),
            filter: false
        },
        {
            headerName: 'City',
            field: ObjectHelper.nameOfSubProperty<TaxModel, TaxJurisdictionModel>('taxJurisdiction', 'cityName'),
            filter: false
        },
        {
            headerName: 'Name',
            field: ObjectHelper.nameOf<TaxModel>('name'),
            filter: false
        },
        {
            headerName: 'Specialization',
            field: ObjectHelper.nameOf<TaxModel>('specializationTitle'),
            filter: false
        },
        {
            headerName: 'Withholding Type',
            field: ObjectHelper.nameOfSubProperty<TaxModel, TaxWithholdingType>('taxWithholdingType', 'name'),
            filter: false
        },
        {
            headerName: 'Rates',
            field: 'Tax Rates',
            filter: false,
            cellRenderer: params => {
                return this.buildTaxRateRanges(params.data.taxRates);
            }
        },
    ];
    @ViewChild('gridFilterPanel', { static: true }) private gridFilterPanel: GtnTaxesGridFilterPanelComponent;

    constructor(
        private taxService: TaxService,
        private router: Router
    ) {
    }

    ngOnInit() {
        this.selectedFilters = LocalStorageHelper.get(LocalStorageNameConstants.TaxesGrid);

        if (this.selectedFilters?.length) {
            this.applyFilter(this.selectedFilters);
        } else {
            this.fetchTaxModelData();
        }
    }

    fetchTaxModelData() {
        this.isLoading = true;
        if (!this.gridFilter) {
            this.gridFilter = new TaxesGridFilterModel();
            this.gridFilter.date = null;
            this.gridFilter.fields = [];
        }

        this.taxService.getTaxesByFilter(this.gridFilter).subscribe(
            (taxModels: TaxModel[]) => {
                this.data = taxModels;
                this.isLoading = false;
            },
            _ => {
                this.isLoading = false;
            });
    }

    applyFilter(filters: SelectListInterface[]): void {
        this.selectedFilters = filters;

        if (!this.gridFilter) {
            this.gridFilter = new TaxesGridFilterModel();
            this.gridFilter.date = null;
            this.gridFilter.fields = [];
        }

        if (filters && filters.length > 0) {
            this.gridFilter.fields = [];
            for (const filter of filters) {
                if (filter.id === 'date') {
                    this.gridFilter.date = DateHelper.convertToUtcDate(filter.value);
                } else {
                    if (isNaN(+filter.id)) {
                        this.gridFilter.fields.push({
                            key: filter.id.toString(),
                            idType: <TaxesGridFilterType>filter.typeId
                        });
                    } else {
                        this.gridFilter.fields.push({ id: +filter.id, idType: <TaxesGridFilterType>filter.typeId });

                    }
                }

            }
        } else {
            this.gridFilter = null;
        }
        this.fetchTaxModelData();
    }

    newRate(): void {
        if (this.gridFilter.fields && this.gridFilter.fields.length > 0) {
            const fields = this.gridFilter.fields;
            if (fields.find(x => x.idType === TaxesGridFilterType.cityId)) {
                const cityId = fields.find(x => x.idType === TaxesGridFilterType.cityId).id;
                this.router.navigate(['tax/detail/city', cityId]);
            } else if (fields.find(x => x.idType === TaxesGridFilterType.stateId)) {
                const stateId = fields.find(x => x.idType === TaxesGridFilterType.stateId).id;
                this.router.navigate(['tax/detail/state', stateId]);
            } else if (fields.find(x => x.idType === TaxesGridFilterType.countryId)) {
                const countryId = fields.find(x => x.idType === TaxesGridFilterType.countryId).id;
                this.router.navigate(['tax/detail/country', countryId]);
            }
        } else {
            this.router.navigate(['tax/detail']);
        }
    }

    onSelectionChange(selection: any): void {
        const taxId = selection?.data?.taxId;
        if (taxId) {
            this.router.navigate(['tax/detail', taxId]);
        }
    }

    private buildTaxRateRanges(rates): string {
        if (rates?.length) {
            const today = new Date();
            const taxRates = rates.filter(rate => {
                return !rate.effectiveEndDate || DateHelper.greaterThan(rate.effectiveEndDate, DateHelper.convertToZeroDate(today));
            });

            if (taxRates?.length > 0) {
                const rateMin = taxRates.reduce((acc, curr) => curr.rate < acc.rate ? curr : acc);
                const rateMax = taxRates.reduce((acc, curr) => curr.rate > acc.rate ? curr : acc);
                rates.rateMin = rateMin.rate;
                rates.rateMax = rateMax.rate;
            }
            return rates.rateMin + (rates.rateMax && rates.rateMax !== rates.rateMin ? ' -- ' + rates.rateMax : '');
        }
        return '0';
    }
}
