<gtn-template-card>
    <gtn-template-card-content>
        <gtn-agreements-search-form [(formGroupRef)]="formGroupRef"
            [(showSearch)]="showSearch"
            [(value)]="value"
            [agreementTypes]="agreementTypes"
            (showSearchChange)="onToggleVisibilityClicked()"
            (searchClick)="onSearchClicked($event)"
            (valueChange)="onValueChanged($event)">
        </gtn-agreements-search-form>
    </gtn-template-card-content>
</gtn-template-card>
