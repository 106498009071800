import { first } from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { LocationModel } from 'app/nexus-shared/domain/locations';
import { LocationService } from 'app/nexus-core/services/location.service';

@Pipe({
    name: 'city'
})
export class CityPipe implements PipeTransform {
    constructor(private locationService: LocationService) {
    }

    transform(cityId: number): Observable<string> {
        return new Observable(observer => {
            if (cityId) {
                this.locationService.getCity(cityId).pipe(first()).subscribe((city: LocationModel) => {
                    if (city) {
                        const response = city.name;
                        observer.next(response);
                    } else {
                        observer.next('City Not Found');
                    }
                    observer.complete();
                });
            } else {
                observer.next('');
                observer.complete();
            }
        });
    }
}
