import { Component, OnInit } from '@angular/core';
import { IndividualService } from 'app/nexus-core/services/domain/contacts/individual.service';
import { IndividualModel } from 'app/nexus-shared/domain/contacts/models/individual.model';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { AuthenticatedUserService } from 'app/nexus-core/services/authenticated-user.service';
import { AuthorizationConstants } from 'app/nexus-shared/constants/authorization.constants';
import { BaseEnterpriseGridComponent } from 'app/nexus-shared/components/base-component/base-enterprise-grid.component';
import { ColDef } from 'ag-grid-community';
import { AgGridHelper, EnumHelper } from 'app/nexus-core';
import { Observable } from 'rxjs';
import { IndividualTypesEnum } from 'app/nexus-shared/domain/contacts/enums/individual-types.enum';
import { IndividualSearchModel } from 'app/nexus-shared/domain/contacts/models/individual-search.model';
import { IndividualSettingService } from 'app/nexus-core/services/domain/core/individual-setting.service';
import { AgGridColDefHelper } from 'app/nexus-core/helpers/ag-grid-col-def.helper';
import { IndividualTypeModel } from 'app/nexus-shared/domain/contacts/models/individual-type.model';
import { CommonBaseAuditUserModel } from 'app/nexus-shared/domain/contacts/models/common-base-audit-user.model';

@Component({
    selector: 'gtn-individuals-grid',
    templateUrl: '../../../../components/base-component/base-grid-template.component.html'
})
export class IndividualsGridComponent extends BaseEnterpriseGridComponent<IndividualModel> implements OnInit {
    static individualKeyCellClass = AgGridHelper.copyPasteCellClass;

    gridName: string = 'individuals_grid';
    gridVersion: string = '1.0.2';
    isAdmin: boolean = false;
    individualTypes: string[] = EnumHelper.convertToSelectList(IndividualTypesEnum, false, false).map(x => x.value);
    individualTypeFilters: number[] = [];

    constructor(
        protected individualSettingService: IndividualSettingService,
        private individualService: IndividualService,
        private authenticatedUserService: AuthenticatedUserService
    ) {
        super(individualSettingService);
    }

    ngOnInit() {
        this.isAdmin = this.authenticatedUserService.hasAuthorization(AuthorizationConstants.contactsAdmin);
        super.ngOnInit();
    }

    onRowClicked(row: any): void {
        if (row.event.target.classList.contains(IndividualsGridComponent.individualKeyCellClass)) {
            return;
        }
        this.rowClick.emit(row.data);
    }

    setDefaultSortModel() {
        this.defaultSortModel = [
            { colId: ObjectHelper.nameOf<IndividualModel>('lastName'), sort: 'asc', sortIndex: 0 },
            { colId: ObjectHelper.nameOf<IndividualModel>('firstName'), sort: 'asc', sortIndex: 1 },
        ];
    }

    protected setColumnDefinitions(): ColDef[] {
        return [
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOf<IndividualModel>('lastName'), 'Last Name'),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOf<IndividualModel>('firstName'), 'First Name'),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOf<IndividualModel>('emailAddress'), 'Email'),
            AgGridColDefHelper.getObjectListColumnDef(ObjectHelper.nameOf<IndividualModel>('individualTypes'), 'Types', ObjectHelper.nameOf<IndividualTypeModel>('type'), IndividualTypesEnum),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOf<IndividualModel>('individualKey'), 'Key'),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOfSubProperty<IndividualModel, CommonBaseAuditUserModel>('createdByUser', 'fullName'), 'Created By', null, { hide: true }),
            AgGridColDefHelper.getDateTimeColDef(ObjectHelper.nameOfSubProperty<IndividualModel, CommonBaseAuditUserModel>('createdByUser', 'changeDateTime'), 'Created On', { hide: true }),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOfSubProperty<IndividualModel, CommonBaseAuditUserModel>('lastModifiedByUser', 'fullName'), 'Last Modified By', null, { hide: true }),
            AgGridColDefHelper.getDateTimeColDef(ObjectHelper.nameOfSubProperty<IndividualModel, CommonBaseAuditUserModel>('lastModifiedByUser', 'changeDateTime'), 'Modified On', { hide: true }),
        ];
    }

    protected setRowData(): Observable<IndividualModel[]> {
        const searchModel: IndividualSearchModel = <IndividualSearchModel>{
            includeIndividualToCompanyAccess: true,
            includeIndividualTypes: true,
            individualTypes: []
        };
        return this.individualService.search(searchModel);
    }
}
