import { DocumentsGridViewTypes } from 'app/nexus-shared/domain/documents/enums/documents-grid-view.types';
import { DocumentStatusesEnum } from 'app/nexus-shared/domain/documents/enums/document-statuses.enum';
import { FolderStatusesEnum } from 'app/nexus-shared/domain/documents/enums/folder-statuses.enum';
import { DocumentModel } from 'app/nexus-shared/domain/documents/models/document.model';
import { FolderModel } from 'app/nexus-shared/domain/documents/models/folder.model';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { CommonBaseAuditUserModel } from 'app/nexus-shared/domain/contacts/models/common-base-audit-user.model';
import { FolderTagModel } from 'app/nexus-shared/domain/documents/models/folder-tag.model';

export class DocumentGridViewModel {
    name: string;
    type: DocumentsGridViewTypes;
    path: string;
    key: string;
    parentFolderKey: string;
    folder: FolderModel;
    size: number;
    tags: FolderTagModel[];
    createdByUser: CommonBaseAuditUserModel;
    lastModifiedByUser: CommonBaseAuditUserModel;
    status: DocumentStatusesEnum | FolderStatusesEnum;
    document: DocumentModel;

    constructor(model: DocumentModel | FolderModel) {
        this.name = model?.name;
        this.status = model?.status;
        this.createdByUser = model?.createdByUser;
        this.lastModifiedByUser = model?.lastModifiedByUser;
        this.folder = null;
        this.document = null;

        if (model.hasOwnProperty(ObjectHelper.nameOf<DocumentModel>('documentKey'))) {
            model = model as DocumentModel;
            this.document = model;
            this.type = DocumentsGridViewTypes.File;
            this.key = model?.documentKey;
            this.path = model?.documentPath;
            this.size = model?.file?.sizeInBytes;
            this.parentFolderKey = model?.folderKey;
        } else if (model.hasOwnProperty(ObjectHelper.nameOf<FolderModel>('folderKey'))) {
            model = model as FolderModel;
            this.folder = model;
            this.tags = model?.tags;
            this.type = DocumentsGridViewTypes.Folder;
            this.key = model?.folderKey;
            this.path = model?.folderPath;
            this.size = null;
            this.parentFolderKey = model?.parentFolderKey;
        }
    }
}
