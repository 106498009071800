import { FolderModel } from 'app/nexus-shared/domain/documents/models/folder.model';
import { DocumentModel } from 'app/nexus-shared/domain/documents/models/document.model';

export class DocumentFolderMoveViewModel {
    folderMovedFrom: FolderModel;
    folderMovedTo: FolderModel;
    documentsMoved: DocumentModel[];
    folderMoved: FolderModel;
    folderMovedFromParentFolderKey: string;

    constructor(folderMovedFrom: FolderModel, folderMovedTo: FolderModel) {
        this.folderMovedFrom = folderMovedFrom;
        this.folderMovedTo = folderMovedTo;
        this.documentsMoved = null;
        this.folderMoved = null;
        this.folderMovedFromParentFolderKey = null;
    }
}
