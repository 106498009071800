import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { NexusValidatorHelper } from 'app/nexus-core';
import { SimpleChangesTyped } from 'app/nexus-shared/models/simple-changes-typed.type';
import { Observable, of, Subscription } from 'rxjs';
import { ComboboxFilterType } from 'app/nexus-shared/components/controls/shared/types';
import { BaseControlComponent } from 'app/nexus-shared/components/controls/components/base-control.component';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { SelectListInterface } from 'app/nexus-shared/interfaces';
import { TooltipPosition } from '@angular/material/tooltip';

// this class should only be extended by the GtnDropdownComponent and the GtnMultiselectComponent
@Component({
    selector: 'gtn-base-dropdown',
    template: ''
})
export abstract class BaseDropdownControlComponent<T, OptionType> extends BaseControlComponent<T> implements ControlValueAccessor, OnInit, OnChanges, AfterViewInit {
    @Input('options') _options: OptionType[] = null;
    @Input() blankOptionText: string = '';
    @Input() idKey: string = 'id';
    @Input() displayKey: string = 'value';
    @Input() displayFunc: (value: T) => string = null;
    @Input() idOnly: boolean = false;
    @Input() focus: boolean = false;
    @Input() showBlank: string | boolean = false;
    @Input() isMultiSelect: boolean = false;
    @Input() debounceInterval: number = 1000;
    @Input() isFilterByText: boolean = false;
    @Input() isTooltip: boolean = false;
    @Input() tooltipDisplayFunc: (value: any) => string = null;
    @Input() isOptionGroups: boolean = false;
    @Input() numberOfElementsForCombobox = 15;
    @Input() tooltipPosition: TooltipPosition = 'right';

    // comparison function to tell if dropdown options are equal
    @Input() compareWith: (o1: T, o2: T) => boolean = ((o1: Object, o2: Object) => {
        return o1 && o2 ? this.getId(o1) === this.getId(o2) : false;
    }).bind(this);
    @Input() sortBy: string = null;
    @Input() sortByDescending: boolean = false;
    @Input() filterType: ComboboxFilterType = ComboboxFilterType.contains;

    @Output() closed: EventEmitter<void> = new EventEmitter();

    @ViewChild('filter') filterElementRef: ElementRef;
    @ViewChild('element') matSelectRef: MatSelect;

    filteredOptions: OptionType[] = [];
    filterControl: UntypedFormControl = new UntypedFormControl();
    isComboboxEnabled = false;
    optionGroups: { label: string, options: OptionType[] }[] = [];

    private autoCompleteSubscription: Subscription;
    private filterText: string;

    ngOnInit() {
        this.initOptions();
    }

    ngOnChanges(changes: SimpleChangesTyped<this>) {
        if (changes._options && this._options) {
            if (this.sortBy) {
                this.sort(this._options);
            }

            this.onOptionsSet(this._options);
        }
    }

    ngAfterViewInit() {
        if (this.focus) {
            this.setFocus();
        }

        if (this.isMultiSelect) {
            // override mat-select onSelect event so that the options list does not jump
            (<any>this.matSelectRef).baseonselect = (<any>this.matSelectRef)._onSelect;
            (<any>this.matSelectRef)._onSelect = (ev, isUserInput) => {
                (<any>this.matSelectRef).baseonselect(ev, false);
            };
        }
    }

    get options() {
        return this._options;
    }

    set options(options: OptionType[]) {
        this._options = options;
        this.onOptionsSet(options);
    }

    onSelectOpened() {
        if (this.isComboboxEnabled) {
            this.filterElementRef.nativeElement.focus();
        }
    }

    onSelectClosed() {
        this.closed.emit();
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;

        if (isDisabled) {
            this.filterControl.disable();
        } else {
            this.filterControl.enable();
        }
    }

    onValueChange(value: T | T[]) {
        this.value = value as unknown as T;
    }

    open() {
        if (this.matSelectRef) {
            this.matSelectRef.open();
        }
    }

    setFocus(): void {
        this.elementRef.nativeElement.focus();
    }

    triggerChanged(el: ElementRef<HTMLElement> = null) {
        const event = new CustomEvent('change', { bubbles: true });

        // use the getter instead of passing out the _value
        event['value'] = this.value;

        if (el) {
            el.nativeElement.dispatchEvent(event);
        } else {
            this.elementRef?.nativeElement?.dispatchEvent(event);
        }
    }

    getErrorMessage(): string[] {
        return NexusValidatorHelper.getErrorMessage(this.formControl, this.label);
    }

    // default options to empty array if dataFetch isn't defined in consumer
    dataFetch: () => Observable<OptionType[]> = () => of([]);

    abstract handleResponse(data: OptionType[]): void;

    initOptions() {
        if (this.options == null) {
            this.dataFetch().subscribe(data => {
                this.handleResponse(data);
            });
        }
    }

    getId(val: any) {
        return typeof val !== 'undefined' &&
        val !== null &&
        val[this.idKey] !== null &&
        typeof val[this.idKey] !== 'undefined' &&
        (
            (typeof val[this.idKey] === 'string' && val[this.idKey] !== '') ||
            (typeof val[this.idKey] === 'number' && val[this.idKey] > -1)
        )
            ? val[this.idKey]
            : val;
    }

    getObjectValue(object: any, propertyName: string): any {
        if (this.displayFunc) {
            return this.displayFunc(object);
        }

        return ObjectHelper.getValueOfProperty(object, propertyName);
    }

    private onOptionsSet(options: OptionType[]) {
        this.isComboboxEnabled = options?.length > this.numberOfElementsForCombobox || this.isFilterByText;

        // if combobox then make debounce time ridiculously high so that filtering does not try to center the option
        if (this.isComboboxEnabled) {
            this.debounceInterval = 99999999999;

            if (!this.autoCompleteSubscription) {

                // add filter handler for combobox
                this.autoCompleteSubscription = this.filterControl.valueChanges.subscribe(value => {
                    this.filterText = value;
                    this.filteredOptions = this.filter(value);
                    if (this.isOptionGroups) {
                        this.setOptionGroups(this.filteredOptions);
                    }
                });

                this.subscriptions.add(this.autoCompleteSubscription);
            } else {
                this.filteredOptions = this.filter(this.filterText);
            }
        }

        this.filteredOptions = options;
        if (this.isOptionGroups) {
            this.setOptionGroups(this.filteredOptions);
        }
    }

    private filter(value: string | T): OptionType[] {
        if (typeof value === 'string') {
            const filteredValue = value?.toLowerCase();
            let options = [];

            if (this.options && value) {
                if (this.filterType === ComboboxFilterType.begins) {
                    options = this.options.filter(x => ObjectHelper.getValueOfProperty<string>(x, this.displayKey)?.toString().toLowerCase().indexOf(filteredValue) === 0);
                } else if (this.filterType === ComboboxFilterType.contains) {
                    options = this.options.filter(x => ObjectHelper.getValueOfProperty<string>(x, this.displayKey)?.toString().toLowerCase().indexOf(filteredValue) > -1);
                }
            } else if (!value) {
                options = this.options;
            }

            if (!options || options.length === 0) {
                setTimeout(() => {
                    this.filterControl.setValue(value?.slice(0, -1));
                }, 0);
            }

            return options;
        } else if (value) {
            return [value as unknown as OptionType];
        }

        return this.options;
    }

    private sort(options: OptionType[]) {
        options?.sort((a, b) => {
            const aValue = (this.displayFunc ? this.displayFunc(<any>a) : ObjectHelper.getValueOfProperty<string>(a, this.sortBy))?.toLowerCase();
            const bValue = (this.displayFunc ? this.displayFunc(<any>b) : ObjectHelper.getValueOfProperty<string>(b, this.sortBy))?.toLowerCase();

            if (aValue < bValue) {
                return -1;
            } else if (aValue > bValue) {
                return 1;
            }

            return 0;
        });

        if (this.sortByDescending) {
            options.reverse();
        }
    }

    private setOptionGroups(filteredOptions: OptionType[]): void {
        this.optionGroups = [];
        filteredOptions.forEach((x) => {
            // casting this because option type is inferred, groups need to be in a select list interface
            const option = x as SelectListInterface;
            const existingGroup = this.optionGroups.find(y => y.label === option.groupKey);
            if (existingGroup) {
                existingGroup.options.push(x);
            } else {
                this.optionGroups.push({ label: option.groupKey, options: [x] });
            }
        });
    }
}
