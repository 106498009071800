import { ArrayHelper } from 'app/nexus-core/helpers/array.helper';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { DateHelper } from 'app/nexus-core/helpers/date.helper';
import { FilterTypeEnum } from 'app/nexus-shared/components/controls/shared/enums/filter-type.enum';
import { FilterModel } from 'app/nexus-shared/components/controls/shared/models/filter-model';
import { SelectListInterface } from 'app/nexus-shared';
import { EnumHelper } from 'app/nexus-core/helpers/enum.helper';
import { ColDef } from 'ag-grid-community';

export class AgGridFilterHelper {

    static createFilter(data: any[], col: ColDef, appliedFilter: any): FilterModel {
        switch (col.filterParams.type) {
            case FilterTypeEnum.Select:
                return this.createDropdownFilter(data, col, appliedFilter);
            case FilterTypeEnum.SelectEnum:
                return this.createEnumDropdownFilter(data, col, appliedFilter);
            case FilterTypeEnum.ActiveDateStart:
                return this.createActiveStartDateFilter(col, appliedFilter);
            case FilterTypeEnum.ActiveDateEnd:
                return this.createActiveEndDateFilter(col, appliedFilter);
            case FilterTypeEnum.Daterange:
                return this.createRangeDatepicker(col, appliedFilter);
            default:
                break;
        }
    }

    static createActiveStartDateFilter(columnDef: ColDef, appliedFilter: any): FilterModel {
        const agFilter = new FilterModel(columnDef);
        agFilter.filterObject = {
            dateFrom: DateHelper.format(new Date(), DateHelper.gridFilterDateFormat),
            dateTo: null,
            type: 'lessThan',
            filterType: 'date'
        };
        agFilter.appliedFilter.value = 'Active date';
        agFilter.isFilterActive = !!appliedFilter;
        return agFilter;
    }

    static createActiveEndDateFilter(columnDef: ColDef, appliedFilter: any): FilterModel {
        const agFilter = new FilterModel(columnDef);
        agFilter.appliedFilter = null;
        agFilter.filterObject = {
            dateFrom: DateHelper.format(new Date(), DateHelper.gridFilterDateFormat),
            dateTo: null,
            type: 'greaterThan',
            filterType: 'date'
        };
        agFilter.isFilterActive = !!appliedFilter;
        return agFilter;
    }

    static createComboboxFilter(data: any[], columnDef: ColDef, appliedFilter: any): FilterModel {
        const agFilter = new FilterModel(columnDef);
        if (appliedFilter) {
            agFilter.filterObject = appliedFilter;
            agFilter.isFilterActive = true;
            agFilter.appliedFilter.value = appliedFilter.filter;
        } else {
            agFilter.filterObject = {
                filterType: 'text',
                type: 'equal',
                filter: null
            };
        }

        agFilter.options = columnDef.filterParams.options ? columnDef.filterParams.options : this.createSelectListFromUniqueValues(ArrayHelper.getUniqueValues(data.map(val => ObjectHelper.getValueOfProperty(val, columnDef.field))));
        return agFilter;
    }

    static createRangeDatepicker(columnDef: ColDef, appliedFilter: any): FilterModel {
        const agFilter = new FilterModel(columnDef);
        if (appliedFilter) {
            agFilter.filterObject = appliedFilter;
            agFilter.isFilterActive = true;
            agFilter.appliedFilter.value = this.dateRangeChipValue(appliedFilter);
        } else {
            agFilter.filterObject = {
                dateFrom: null,
                dateTo: null,
                type: 'inRange',
                filterType: 'date'
            };
        }
        return agFilter;
    }

    static createDropdownFilter(data: any[], columnDef: ColDef, appliedFilter: any): FilterModel {
        const agFilter = new FilterModel(columnDef);
        if (appliedFilter) {
            agFilter.isFilterActive = true;
            agFilter.filterObject = appliedFilter;
            agFilter.appliedFilter.value = appliedFilter.filter;
        } else {
            agFilter.filterObject = {
                filterType: 'text',
                type: 'equals',
                filter: null
            };
        }
        agFilter.options = columnDef.filterParams?.options ? columnDef.filterParams.options : this.createSelectListFromUniqueValues(ArrayHelper.getUniqueValues(data.map(val => ObjectHelper.getValueOfProperty(val, columnDef.field))));
        agFilter.idKey = 'value';
        return agFilter;
    }

    static createEnumDropdownFilter(data: any[], columnDef: ColDef, appliedFilter: any): FilterModel {
        const agFilter = new FilterModel(columnDef);
        if (appliedFilter) {
            agFilter.isFilterActive = true;
            agFilter.filterObject = appliedFilter;
            if (columnDef.filterParams.enum) {
                agFilter.appliedFilter.value = EnumHelper.getDisplayName(columnDef.filterParams.enum, appliedFilter.filter);
            } else if (columnDef.filterParams.options) {
                agFilter.appliedFilter.value = columnDef.filterParams.options.find(t => t.id === appliedFilter.filter)?.value;
            }
        } else {
            agFilter.filterObject = {
                filterType: 'number',
                type: 'equals',
                filter: null
            };
        }
        if (!columnDef.filterParams.options) {
            const values = data.map(val => ObjectHelper.getValueOfProperty(val, columnDef.field));
            const options: SelectListInterface[] = EnumHelper.convertToSelectList(columnDef.filterParams.enum, false, false, null, true).filter(select => values.includes(select.id));
            options.unshift({ value: '', id: null });
            agFilter.options = options;
        } else {
            agFilter.options = columnDef.filterParams.options;
        }
        return agFilter;
    }

    static createSelectListFromUniqueValues(data: any[]): SelectListInterface[] {
        const selectList = data.map((val, i) => {
            if (val) {
                return { id: i, value: val };
            }
        });
        selectList.unshift({ value: '', id: null });
        return selectList;
    }


    static dateRangeChipValue(filter: any): string {
        const dateFrom: string = DateHelper.format(filter.dateFrom, DateHelper.standardDateFormat);
        const dateTo: string = DateHelper.format(filter.dateTo, DateHelper.standardDateFormat);

        if (filter.dateFrom && filter.dateTo) {
            return `${dateFrom} - ${dateTo}`;
        } else if (filter.dateFrom) {
            return `> ${dateFrom}`;
        } else if (filter.dateTo) {
            return `< ${dateTo}`;
        } else {
            return null;
        }
    }
}
