<form [formGroup]="formGroupRef">
    <div class="flex-row-start">
        <gtn-slide-toggle class="mr-3" label="Trigger section" [(ngModel)]="isAction"
            [ngModelOptions]="{standalone: true}"
            [suppressInitialChangeEvent]="isAction"
            (change)="onActionChange()"></gtn-slide-toggle>
        <ng-container *ngIf="isAction" [formGroup]="actionFormGroup">
            <gtn-dropdown class="medium-column" label="Action section"
                [options]="sections"
                displayKey="title"
                formControlName="childSectionKey"
                idKey="key" [idOnly]="true"
                (change)="onSectionChanged($event['value'])"></gtn-dropdown>
            <gtn-questionnaire-dropdown-option-select *ngIf="dropdownTypeId" class="ml-3" [dropdownId]="dropdownTypeId"
                [questionnaireDropdownService]="questionnaireDropdownService"
                formControlName="value"></gtn-questionnaire-dropdown-option-select>
        </ng-container>
    </div>
</form>
