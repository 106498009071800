import { AfterViewInit, Directive, ElementRef, Input, OnChanges } from '@angular/core';
import { BaseDirective } from 'app/nexus-shared/components/base-directive/base.directive';
import { SimpleChangesTyped } from 'app/nexus-shared/models/simple-changes-typed.type';

@Directive({
    selector: '[gtnDynamicMatTab]'
})
export class DynamicMatTabDirective extends BaseDirective implements AfterViewInit, OnChanges {
    @Input() gtnDynamicMatTab: number[] = [];

    constructor(private elementRef: ElementRef) {
        super();
    }

    ngOnChanges(changes: SimpleChangesTyped<this>) {
        if (changes.gtnDynamicMatTab && !changes.gtnDynamicMatTab.isFirstChange()) {
            this.setTabs();
        }
    }

    ngAfterViewInit(): void {
        this.setTabs();
    }

    private setTabs(): void {
        if (this.elementRef?.nativeElement) {
            const matTabs = this.elementRef.nativeElement.querySelector('.mat-mdc-tab-labels')?.children;
            for (let i = 0; i < matTabs.length; i++) {
                if (this.gtnDynamicMatTab.indexOf(i) !== -1) {
                    matTabs[i].style.display = 'none';
                } else {
                    matTabs[i].style.display = 'flex';
                }
            }
        }
    }
}
