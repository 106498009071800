import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'gtn-delete-section',
    templateUrl: './delete-section.component.html',
    styleUrls: ['./delete-section.component.scss']
})
export class DeleteSectionComponent implements OnInit {

    @Input() label: string;
    @Input() buttonText: string = 'Delete';
    @Output() deleteButtonClick: EventEmitter<void> = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
    }

    onDeleteButtonClick() {
        this.deleteButtonClick.emit();
    }
}
