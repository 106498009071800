<gtn-modal [disableOkButton]="!allowSubmit"
    [headerText]="headerText"
    cancelButtonText="Cancel"
    okButtonText="Confirm"
    (okClick)="onOkClicked()"
    (cancelClick)="onCancelClicked()">
    <gtn-modal-content>
        {{bodyText}}
        <gtn-text-area (change)="onReasonChange($event)"></gtn-text-area>
    </gtn-modal-content>
</gtn-modal>
