<mat-dialog-content class="tooltip"
    (gtnClickOutside)="onOutsideClicked()"
    (click)="onMessageClicked()">
    {{message}}
</mat-dialog-content>
<mat-dialog-actions *ngIf="isCloseVisible"
    class="tooltip-actions">
    <button mat-flat-button
        color="primary"
        (click)="onCloseClicked()">
        Close
    </button>
</mat-dialog-actions>
