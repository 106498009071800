import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthorizationConstants } from 'app/nexus-shared/constants/authorization.constants';
import { AuthenticatedUserService } from 'app/nexus-core/services/authenticated-user.service';
import { Subscription } from 'rxjs';

@Directive({
    selector: '[gtnIfHasAnyAuthorization]'
})
export class IfHasAnyAuthorizationDirective implements OnInit, OnDestroy {
    @Input() gtnIfHasAnyAuthorization: AuthorizationConstants[] = [];

    private isRendered: boolean = false;
    private subscription: Subscription;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private authenticatedUserService: AuthenticatedUserService
    ) {
    }

    ngOnInit(): void {
        this.subscription = this.authenticatedUserService.user$.subscribe((_ => {
            if (!Array.isArray(this.gtnIfHasAnyAuthorization)) {
                throw ('Invalid input to IfHasAnyAuthorizationDirective');
            }

            if (!this.isRendered && this.authenticatedUserService.hasAnyAuthorization(this.gtnIfHasAnyAuthorization)) {
                this.viewContainer.createEmbeddedView(this.templateRef);
                this.isRendered = true;
            } else if (!this.isRendered) {
                this.viewContainer.clear();
            }
        }).bind(this));
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
