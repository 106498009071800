import { UserInputTypesEnum } from 'app/nexus-shared/domain/questionnaires/enums/user-input-types.enum';
import { Guid } from 'guid-typescript';

export class TableColumnHeaderModel {
    key: string;
    headerText: string;
    tableInputType: UserInputTypesEnum;
    dropdownTypeId: number;
    alignment: string;
    numberDelimiter: boolean;
    numberDecimalPlaces: number;
    order: number;

    //this used to pass the order
    constructor(model: TableColumnHeaderModel = null) {
        this.key = model?.key ?? Guid.create().toString();
        this.headerText = model?.headerText ?? null;
        this.tableInputType = model?.tableInputType ?? null;
        this.dropdownTypeId = model?.dropdownTypeId ?? null;
        this.alignment = model?.alignment ?? null;
        this.numberDelimiter = model?.numberDelimiter ?? null;
        this.numberDecimalPlaces = model?.numberDecimalPlaces ?? null;
        this.order = model?.order ?? null;
    }
}
