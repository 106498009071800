import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BaseEntryComponent } from 'app/nexus-shared/components/base-component/base-entry.component';
import { UserUpdateEmailModel } from 'app/nexus-core/services/domain/users/user-update-email.model';
import { UserService } from 'app/nexus-core/services/domain/users/user.service';
import { ToastService } from 'app/nexus-core';
import { Observable, throwError } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { AuthenticationService } from 'app/nexus-core/services/authentication.service';

@Component({
    selector: 'gtn-update-email',
    templateUrl: './update-email.component.html',
    styleUrls: ['./update-email.component.scss']
})
export class UpdateEmailComponent extends BaseEntryComponent<UserUpdateEmailModel> implements OnInit {
    @Input() currentEmail: string;
    @Output() updateEmailVerificationCompleted: EventEmitter<string> = new EventEmitter<string>();
    @Output() isValidationStepChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() disableButtonsChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    isValidationStep: boolean = false;

    constructor(private userService: UserService,
                private toastService: ToastService,
                public authenticationService: AuthenticationService) {
        super();
    }

    ngOnInit(): void {
        this.formGroupRef = new UntypedFormGroup({
            userPassword: new UntypedFormControl(),
            newEmail: new UntypedFormControl(),
            twoFactorKey: new UntypedFormControl(),
            twoFactorCode: new UntypedFormControl()
        });
    }

    onSubmit(): Observable<string | boolean> {
        if (this.currentEmail) {
            this.disableButtonsChange.emit(true);

            if (!this.isValidationStep) {
                return this.userService.createUpdateEmailVerification({
                    userPassword: this.formGroupRef.value.userPassword,
                    newEmail: this.formGroupRef.value.newEmail
                }).pipe(
                    tap(result => {
                        this.toastService.showMessageToast(`Two factor code to '${this.formGroupRef.value.newEmail}' has been sent successfully.`);
                        this.disableButtonsChange.emit(false);
                        this.isValidationStep = true;
                        this.isValidationStepChange.emit(this.isValidationStep);
                        this.formGroupRef.get(ObjectHelper.nameOf<UserUpdateEmailModel>('twoFactorKey')).setValue(result);
                    }, err => {
                        this.resetVerificationInput();
                        this.toastService.showErrorToast(err);
                        this.disableButtonsChange.emit(false);
                        this.onSaveFailure(err);
                    })
                );
            } else {
                return this.userService.validateUpdateEmailVerification(this.formGroupRef.value).pipe(
                    concatMap((res: boolean) => {
                        return this.authenticationService.updateUserContext().pipe(
                            tap(() => {
                                this.toastService.showMessageToast(`Email has been changed successfully.`);
                                this.updateEmailVerificationCompleted.emit(this.formGroupRef.value.newEmail);
                                this.disableButtonsChange.emit(false);
                            }),
                            map(() => res)
                        );
                    }),
                    catchError(err => {
                        this.resetVerificationInput();
                        this.toastService.showErrorToast(err);
                        this.disableButtonsChange.emit(false);
                        this.onSaveFailure(err);
                        return throwError(err);
                    })
                );
            }
        }
    }

    private resetVerificationInput(): void {
        this.formGroupRef.get(ObjectHelper.nameOf<UserUpdateEmailModel>('twoFactorCode')).reset();
        this.disableButtonsChange.emit(true);
    }
}
