import { Component, OnChanges, OnInit } from '@angular/core';
import { PresenceLocationModel } from 'app/nexus-shared/domain/travel-calendar/models/presence-location.model';
import { IndividualSettingService } from 'app/nexus-core/services/domain/core/individual-setting.service';
import { ColDef, GridOptions, ISetFilterParams } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AgGridColDefHelper } from 'app/nexus-core/helpers/ag-grid-col-def.helper';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';
import { AgGridHelper } from 'app/nexus-core';
import { BaseTravelCalendarReportingGridComponent } from 'app/nexus-shared/domain/travel-calendar/components/grids/base-grids/base-travel-calendar-reporting-grid.component';
import { SimpleChangesTyped } from 'app/nexus-shared/models/simple-changes-typed.type';

@Component({
    selector: 'gtn-presence-grid',
    templateUrl: './presence-grid.component.html',
    styleUrls: ['./presence-grid.component.scss']
})
export class PresenceGridComponent extends BaseTravelCalendarReportingGridComponent<PresenceLocationGridViewModel> implements OnInit, OnChanges {

    constructor(protected individualSettingService: IndividualSettingService) {
        super(individualSettingService);
    }

    ngOnChanges(changes: SimpleChangesTyped<this>) {
        if (changes?.searchModel && !changes.searchModel.isFirstChange()) {
            this.refreshGridData();
        }
    }

    ngOnInit() {
        const that = this;
        const gridOptions: GridOptions = {
            treeData: true,
            autoGroupColumnDef: {
                headerName: 'Location',
                field: ObjectHelper.nameOf<PresenceLocationGridViewModel>('locationName'),
                cellRendererParams: {
                    suppressCount: true,
                },
                filter: 'agSetColumnFilter',
                filterParams: {
                    treeList: true,
                    keyCreator: (params) => {
                        return params.value.join('#');
                    }
                } as ISetFilterParams,
                sortable: true,
                sort: 'asc',
                comparator: AgGridHelper.stringComparator
            },
            getDataPath: that.getDataPath.bind(that),

        };
        super.ngOnInit(gridOptions);
    }

    getDataPath(data: PresenceLocationGridViewModel): string[] {
        return data.dataPath;
    }

    protected setColumnDefinitions(): ColDef[] {
        return [
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOf<PresenceLocationGridViewModel>('countryName'), 'Country', null, {
                hide: true
            }),
            AgGridColDefHelper.colDefGenerator(ObjectHelper.nameOf<PresenceLocationGridViewModel>('stateName'), 'State', null, {
                hide: true
            }),
            AgGridColDefHelper.getNumberColDef(ObjectHelper.nameOf<PresenceLocationGridViewModel>('workDaysInLocation'), 'Total Work Days'),
            AgGridColDefHelper.getNumberColDef(ObjectHelper.nameOf<PresenceLocationGridViewModel>('daysInLocation'), 'Total Days Presence'),
        ];
    }

    protected setRowData(): Observable<PresenceLocationGridViewModel[]> {
        return this.travelCalendarReportService.getDaysPresence(this.searchModel).pipe(map(res => {
            const data: PresenceLocationGridViewModel[] = [];
            res.locations.forEach(x => {
                const model = new PresenceLocationGridViewModel(x);
                data.push(model);
                model.childLocations.forEach(y => {
                    const childModel = new PresenceLocationGridViewModel(y, model);
                    data.push(childModel);
                });
            });
            return data;
        }));
    }
}

export class PresenceLocationGridViewModel extends PresenceLocationModel {
    dataPath: string[];
    countryName: string;
    countryId: number;
    stateName: string;
    stateId: number;

    constructor(model: PresenceLocationModel, parentPresenceLocationGridView: PresenceLocationGridViewModel = null) {
        super(model);
        this.dataPath = PresenceLocationGridViewModel.getDataPath(model, parentPresenceLocationGridView?.dataPath ? [...parentPresenceLocationGridView?.dataPath] : []);
        this.countryName = parentPresenceLocationGridView ? parentPresenceLocationGridView.countryName : model?.locationName;
        this.countryId = parentPresenceLocationGridView ? parentPresenceLocationGridView.countryId : model?.locationId;
        this.stateName = parentPresenceLocationGridView ? model.locationName : null;
        this.stateId = parentPresenceLocationGridView ? model.locationId : null;
    }

    static getDataPath(presenceLocation: PresenceLocationModel, parentPath: string[]): string[] {
        parentPath.push(presenceLocation.locationName);
        return parentPath;
    }
}
