import { SearchResultModel } from 'app/nexus-shared/components/controls/shared/models/search-result.model';
import { SortingHelper } from './sorting.helper';

export class SearchHelper {

    private static readonly maxNumber: number = 99999;

    public static getLevenshteinDistance(s: string, t: string): number {
        const n = s.length;
        const m = t.length;
        const d = [];

        if (n === 0) {
            return m;
        }

        if (m === 0) {
            return n;
        }

        for (let i = 0; i <= n; i++) {
            if (!d[i]) {
                d[i] = [];
            }
            d[i][0] = i;
        }

        for (let j = 0; j <= m; j++) {
            d[0][j] = j;
        }

        for (let j = 1; j <= m; j++) {
            for (let i = 1; i <= n; i++) {
                if (s[i - 1] === t[j - 1]) {
                    d[i][j] = d[i - 1][j - 1]; //no operation
                } else {
                    d[i][j] = Math.min(
                        Math.min(
                            d[i - 1][j] + 1, //a deletion
                            d[i][j - 1] + 1
                        ), //an insertion
                        d[i - 1][j - 1] + 1 //a substitution
                    );
                }
            }
        }

        return d[n][m];
    }

    public static getMatchingRank(value: string, searchTerm: string) {
        value = value ? value.trim().toLowerCase() : null;
        searchTerm = searchTerm ? searchTerm.trim().toLowerCase() : null;

        if (!searchTerm || !value) {
            return this.maxNumber;
        }

        if (value === searchTerm) {
            return -1;
        }

        if (value.startsWith(searchTerm)) {
            return 0;
        }

        return this.getLevenshteinDistance(value, searchTerm);
    }

    public static orderSearchResults<T, S>(results: SearchResultModel<T, S>[], searchText: string, firstLastNameMatchingTypes: T[], firstLastNameIdMatchingTypes: T[] = []): SearchResultModel<T, S>[] {
        const maxNumber = 99999;
        results.forEach(result => {
            let firstNameRank = maxNumber;
            let lastNameRank = maxNumber;
            let nameRank = maxNumber;
            const idRank = maxNumber;

            if (result.name && firstLastNameMatchingTypes.includes(result.type)) {
                // All others
                nameRank = SearchHelper.getMatchingRank(result.name, searchText);

            } else if (result.name && firstLastNameIdMatchingTypes.includes(result.type)) {
                const parts = result.name.split(' - ');



                if (parts.length > 1) {
                    const nameParts = parts[0].split(' ');
                    const id = parts[1];

                    const name0 = nameParts[0];
                    const name1 = nameParts[1];
                    const name2 = nameParts[2];
                    const name3 = nameParts[1];

                    if (nameParts.length > 2) {
                        let name0Rank = maxNumber;
                        let name1Rank = maxNumber;
                        let name2Rank = maxNumber;
                        let name3Rank = maxNumber;

                        if (name0) {
                            name0Rank = SearchHelper.getMatchingRank(name0, searchText);
                        }

                        if (name1) {
                            name1Rank = SearchHelper.getMatchingRank(name1, searchText);
                        }

                        if (name2) {
                            name2Rank = SearchHelper.getMatchingRank(name2, searchText);
                        }

                        if (name3) {
                            name3Rank = SearchHelper.getMatchingRank(name3, searchText);
                        }

                        if (name3) {
                            firstNameRank = Math.min(name0Rank, name1Rank - 10);
                            lastNameRank = Math.min(name2Rank, name3Rank - 10);
                        } else {
                            firstNameRank = name0Rank;
                            lastNameRank = Math.min(name1Rank, name2Rank - 10);
                        }
                    } else {
                        if (name0) {
                            firstNameRank = SearchHelper.getMatchingRank(name0, searchText);
                        }

                        if (name1) {
                            lastNameRank = SearchHelper.getMatchingRank(name1, searchText);
                        }
                    }

                    if (id) {
                        lastNameRank = SearchHelper.getMatchingRank(id, searchText);
                    }
                } else {
                    nameRank = SearchHelper.getMatchingRank(result.name, searchText);
                }
            } else {
                // Traveler
                const nameParts = result.name.split(', ');

                if (nameParts.length > 1) {
                    const lastName = nameParts[0];
                    const firstName = nameParts[1];

                    if (firstName) {
                        firstNameRank = SearchHelper.getMatchingRank(firstName, searchText);
                    }

                    if (lastName) {
                        lastNameRank = SearchHelper.getMatchingRank(lastName, searchText);
                    }
                } else {
                    nameRank = SearchHelper.getMatchingRank(result.name, searchText);
                }
            }

            result.rank = Math.min(nameRank, firstNameRank - 10, lastNameRank - 100, idRank - 1000);
        });

        return results.sort((a, b) => SortingHelper.sortByPropertyComparer(a, b, 'rank'));
    }
}
