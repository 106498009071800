import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ViewModesEnum } from 'app/nexus-shared/enums/view-modes.enum';
import { UntypedFormGroup } from '@angular/forms';
import { PendingChangesModel } from 'app/nexus-shared/models/pending-changes.model';
import { BasePageComponent } from 'app/nexus-shared/components/base-component/base-page.component';
import { ComponentCanDeactivate } from 'app/nexus-core';
import { Router } from '@angular/router';
import { MatTab, MatTabGroup, MatTabHeader } from '@angular/material/tabs';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({ template: '' })
export abstract class BaseEditTabComponent<T> extends BasePageComponent implements OnInit, AfterViewInit, OnDestroy, ComponentCanDeactivate {
    @ViewChild('tabGroup') tabs: MatTabGroup;

    value: T;
    originalValue: T;
    activeTab: number = 0;
    previousTab: number = 0;
    viewModes = ViewModesEnum;
    formGroupRef: UntypedFormGroup = new UntypedFormGroup({});
    ignoreTabChangeIndices: number[] = [];
    readonly: boolean = true;
    isValueSet: boolean = false;

    pendingChanges: PendingChangesModel = new PendingChangesModel();
    pendingChangesChange = new EventEmitter<PendingChangesModel>();
    tabsToHideByIndex: number[] = [];

    executeSave: boolean = false;

    ngOnInit(): void {
        this.getValue().pipe(tap((value: T) => {
            this.setValue(value);
            this.isValueSet = true;
            this.setTabClickHandler();
        })).subscribe();
    }

    ngAfterViewInit(): void {
        this.setTabClickHandler();
    }

    interceptTabChange(tab: MatTab, tabHeader: MatTabHeader, idx: number) {
        var ignoreTabChange = false;

        if (this.ignoreTabChangeIndices.length) {
            const index = this.ignoreTabChangeIndices.find(x => x == idx);

            if (typeof index != 'undefined' && index != null) {
                ignoreTabChange = true;
            }
        }

        if (this.formGroupRef.dirty) {
            if (!ignoreTabChange) {
                this.pendingChanges.nextTabIndex = idx;
                this.pendingChanges.showPendingChanges = true;
            } else {
                this.pendingChanges.nextTabIndex = null;
                this.activeTab = idx;
                MatTabGroup.prototype._handleClick.apply(this.tabs, arguments);
            }
        } else {
            this.pendingChanges.nextTabIndex = null;
            this.activeTab = idx;

            if (!ignoreTabChange) {
                this.formGroupRef = new UntypedFormGroup({});
                this.value = JSON.parse(JSON.stringify(this.originalValue));
                this.readonly = true;
            }

            MatTabGroup.prototype._handleClick.apply(this.tabs, arguments);
        }
    }

    onPendingChangesDiscardClicked(): void {
        this.activeTab = this.pendingChanges.nextTabIndex;
        this.pendingChanges.nextTabIndex = null;
        this.pendingChangesChange.emit(this.pendingChanges);
    }

    onSaveClicked(): void {
        this.executeSave = true;
    }

    onSaveSuccess(): void {
        if (!this.hasPostSaveRedirect()) {
            this.getValue().subscribe((value: T) => {
                this.setValue(value);
            });
        }
    }

    hasPostSaveRedirect(): boolean {
        if (typeof this.pendingChanges?.nextTabIndex === 'number') {
            this.activeTab = this.pendingChanges.nextTabIndex;
            this.pendingChanges.nextTabIndex = null;
            return false;
        } else if (this.pendingChanges.nextUrl && this.pendingChanges.router) {
            this.pendingChanges.router.navigateByUrl(this.pendingChanges.nextUrl);
            return true;
        }

        return false;
    }

    canDeactivate(nextUrl: string, router: Router): boolean {
        if (this.formGroupRef.dirty) {
            this.pendingChanges.showPendingChanges = true;
            this.pendingChanges.nextUrl = nextUrl;
            this.pendingChanges.router = router;
            this.pendingChangesChange.emit(this.pendingChanges);
        }

        return !this.formGroupRef.dirty;
    }

    setHiddenTabByIndex(hiddenTabs: number[]): void {
        this.tabsToHideByIndex = [...hiddenTabs];
    }

    protected abstract getValue(): Observable<T>;

    private setTabClickHandler(): void {
        if (this.tabs) {
            this.tabs._handleClick = this.interceptTabChange.bind(this);
        }
    }

    private setValue(value: T): void {
        this.value = value;
        this.originalValue = JSON.parse(JSON.stringify(value));
    }
}
