import { ErrorResponseModel } from 'app/nexus-shared/models/error-response.model';
import { ValidationModel } from 'app/nexus-shared/models/validation.model';

export class ValidationErrorHelper {
    public static handleServiceError(error: any, validationErrorCallback: (validationErrors: ValidationModel[]) => void) {
        if (!error) {
            alert('An unexpected problem has occurred on the server.');
        }

        if (error instanceof ErrorResponseModel === false) {
            alert(error);
            console.error(error);
            return;
        }

        const errorResponseModel = error as ErrorResponseModel;
        if (errorResponseModel.isServerError) {
            //alert(error.dataBag || 'A problem has occurred on the server.');
            console.error(error);
            return;
        }

        if (!validationErrorCallback) {
            return;
        }

        validationErrorCallback(errorResponseModel.dataBag as ValidationModel[]);
    }
}
