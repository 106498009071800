import { BaseService } from 'app/nexus-core/services/base-service.directive';
import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { SavedSettingModel } from 'app/nexus-shared/models/saved-setting.model';
import { BaseHttpService } from 'app/nexus-core/services/base-http.service';
import { SavedSettingSearchModel } from 'app/nexus-shared/models/saved-setting-search.model';
import { tap } from 'rxjs/operators';

@Injectable()
export class SavedSettingService extends BaseService {
    private apiUrl = `${environment().webAPIUrls.core}saved-settings`;

    savedSettingsSubject$: Subject<void> = new Subject();

    constructor(private baseHttpService: BaseHttpService) {
        super();
    }

    get(id: number): Observable<SavedSettingModel> {
        return this.baseHttpService.get(`${this.apiUrl}/id/${id}`);
    }

    search(searchModel: SavedSettingSearchModel): Observable<SavedSettingModel[]> {
        return this.baseHttpService.post(`${this.apiUrl}/search`, searchModel);
    }

    create(model: SavedSettingModel, emitSubject: boolean = false): Observable<number> {
        return this.baseHttpService.post(`${this.apiUrl}`, model).pipe(tap(_ => {
            if (emitSubject) {
                this.savedSettingsSubject$.next();
            }
        }));
    }

    update(model: SavedSettingModel, emitSubject: boolean = false): Observable<boolean> {
        return this.baseHttpService.put(`${this.apiUrl}`, model).pipe(tap(_ => {
            if (emitSubject) {
                this.savedSettingsSubject$.next();
            }
        }));
    }

    delete(id: number, emitSubject: boolean = false): Observable<boolean> {
        return this.baseHttpService.delete(`${this.apiUrl}/id/${id}`).pipe(tap(_ => {
            if (emitSubject) {
                this.savedSettingsSubject$.next();
            }
        }));
    }
}
