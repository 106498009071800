import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { BaseHttpService } from 'app/nexus-core/services/index';
import { ClientAUAModel } from 'app/nexus-shared/models/client-aua.model';

@Injectable()
export class ClientAuaService {
    apiUrl = `${environment().webAPIUrls.auth}clients/aua`;

    constructor(private baseHttpService: BaseHttpService) {
    }

    get(): Observable<ClientAUAModel> {
        return this.baseHttpService.get(`${this.apiUrl}`);
    }

    agree(clientAUA: ClientAUAModel): Observable<boolean> {
        return this.baseHttpService.post(`${this.apiUrl}/agree/`, clientAUA);
    }
}
