import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { BaseHttpService } from 'app/nexus-core/services/index';
import { RoleDefinitionModel } from 'app/nexus-shared/domain/users/models/role-definition.model';
import { ApplicationsEnum } from 'app/nexus-shared/enums/applications.enum';

@Injectable()
export class RoleService {

    constructor(private baseHttpService: BaseHttpService) {
    }

    list(): Observable<RoleDefinitionModel[]> {
        return this.baseHttpService.get(this.apiUrl());
    }

    listByApplication(application: ApplicationsEnum): Observable<RoleDefinitionModel[]> {
        return this.baseHttpService.get(`${this.apiUrl()}application/${application}`);
    }

    listByUserId(userKey: string): Observable<RoleDefinitionModel[]> {
        return this.baseHttpService.get(`${this.apiUrl()}users/${userKey}`);
    }

    update(userKey: string, roleDefinitionIds: number[]): Observable<boolean> {
        return this.baseHttpService.put(`${this.apiUrl()}users/${userKey}`, roleDefinitionIds);
    }

    private apiUrl = () => environment().webAPIUrls.auth + 'roles/';
}
