import { Component, Input, OnInit } from '@angular/core';
import { CompanyService } from 'app/nexus-core/services/domain/contacts/company.service';
import { IndividualCompanyAccessService } from 'app/nexus-core/services/domain/contacts/individual-company-access.service';
import { IndividualService } from 'app/nexus-core/services/domain/contacts/individual.service';
import { AuthenticatedUserService } from 'app/nexus-core/services/authenticated-user.service';
import { ApplicationsEnum } from 'app/nexus-shared/index';
import { CompanyModel, IndividualModel } from 'app/nexus-shared/domain/contacts/models';
import { BaseAccessModalComponent } from 'app/nexus-shared/domain/contacts/components/modals/shared/access-modal-base/base-access-modal.component';
import { environment } from 'environments/environment';

@Component({
    selector: 'gtn-individual-company-access-modal',
    templateUrl: './individual-company-access-modal.component.html',
    styleUrls: ['./individual-company-access-modal.component.scss']
})
export class IndividualCompanyAccessModalComponent extends BaseAccessModalComponent implements OnInit {
    @Input() individual: IndividualModel;
    @Input() applicationSource: ApplicationsEnum;
    @Input() canAddNewCompany: boolean = false;
    @Input() isRelationshipTypeReadonly: boolean = false;

    company: CompanyModel;
    isCreateCompany: boolean = false;

    private controllingCompanyKey: string = environment().controllingCompanyKey;

    constructor(
        public authenticatedUserService: AuthenticatedUserService,
        private individualCompanyAccessService: IndividualCompanyAccessService,
        private companyService: CompanyService,
        private individualService: IndividualService
    ) {
        super();
    }

    ngOnInit() {
        this.isControllingCompany = this.value?.individualToCompanyAccessKey ? this.value.company.companyKey === this.controllingCompanyKey : false;
        super.ngOnInit();
    }

    onCreateCompanyClicked(): void {
        this.company = new CompanyModel();
        this.company.applicationSourceType = this.applicationSource;
        this.isCreateCompany = true;
    }

    onSaveClicked(): void {
        this.saveAccess(this.individualCompanyAccessService, this.individualService);
    }

    onCompanySaveSuccess(company: CompanyModel) {
        this.value.company = new CompanyModel(company);
        this.isCreateCompany = false;
        setTimeout(() => {
            this.formGroupRef.markAsDirty();
        });
    }
}
