import { Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseControlComponent } from '../base-control.component';
import { MatSlideToggle } from '@angular/material/slide-toggle';

@Component({
    selector: 'gtn-slide-toggle',
    templateUrl: './slide-toggle.component.html',
    styleUrls: ['./slide-toggle.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SlideToggleComponent),
            multi: true
        }
    ]

})
export class SlideToggleComponent extends BaseControlComponent<boolean> implements ControlValueAccessor {
    @Input() labelPosition: 'before' | 'after';
    @Input() checked: boolean;
    @Input() id: string;
    @Input() name: string | null;

    @ViewChild('matSlideToggle') matSlideToggle: MatSlideToggle;
    @ViewChild('matSlideElement') matSlideElement: ElementRef;

    setFocus(): void {
        this.matSlideToggle.focus();
    }

    triggerChanged() {
        const el = this.matSlideElement ? this.matSlideElement['_elementRef'] : null;
        super.triggerChanged(el);
    }
}
