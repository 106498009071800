<form [formGroup]="formGroupRef" *ngIf="formGroupRef">
    <gtn-template-dynamic-card *ngIf="(viewMode === viewModes.All || viewMode === viewModes.General)" [mode]="cardMode">
        <ng-template #content>
            <div class="row">
                <div [ngClass]="{'col-md-6': isUpload, 'col-md-4': !isUpload}">
                    <gtn-input formControlName="name" type="text" label="File name" [disabled]="isCheckIn">
                    </gtn-input>
                </div>
                <div class="col-md-6" *ngIf="isUpload">
                    <gtn-text-area [label]="isCheckIn ? 'Check-in note' : 'Upload note'"
                        formControlName="eventNote"></gtn-text-area>
                </div>
            </div>
            <ng-container *ngIf="(isClientDocument || isFileTemplate) && !isUpload" formGroupName="clientDocument">
                <div class="row">
                    <div class="col-md-2">
                        <gtn-dropdown formControlName="taxYear" label="Tax year" [idOnly]="true"
                            [options]="taxYearOptions"></gtn-dropdown>
                    </div>
                    <div class="col-md-2 tax-year-checkbox">
                        <gtn-checkbox class="align-checkbox" formControlName="nonStandardTaxYear"
                            [label]="getCheckboxLabel()"></gtn-checkbox>
                    </div>
                    <div class="col-md-3">
                        <gtn-country-select formControlName="countryId" [idOnly]="true"
                            [suppressInitialChangeEvent]="suppressInitialCountryChange"></gtn-country-select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <gtn-dropdown formControlName="type" label="Document type" [idOnly]="true"
                            [options]="clientDocumentTypeOptions"></gtn-dropdown>
                    </div>
                </div>
            </ng-container>
            <div class="row mb-4" *ngIf="isUpload">
                <div class="col-12 file-input">
                    <gtn-file-upload label="Select file" [isDragAndDrop]="true" [isMultiSelect]="false"
                        (fileChange)="onFileInputChanged($event)"></gtn-file-upload>
                </div>
            </div>
        </ng-template>

    </gtn-template-dynamic-card>
    <gtn-template-dynamic-card *ngIf="(viewMode === viewModes.Notes)" [mode]="cardMode">
        <ng-template #content>
            <ng-container formGroupName="note">
                <div class="row">
                    <div class="col-md-8 notes-column">
                        <gtn-text-area appearance="fill" label="Notes on this file" formControlName="text"
                            [readonly]="true"
                            [minRows]="3"></gtn-text-area>
                    </div>
                </div>
            </ng-container>
        </ng-template>
    </gtn-template-dynamic-card>
</form>
