import { animate, AnimationMetadata, state, style, transition, trigger } from '@angular/animations';

export class FilterAnimation {
    /**
     * Use '@gtnFilterPanel' in markup.
     */
    static animations = FilterAnimation.getAnimation();

    private static getAnimation(): AnimationMetadata {
        return trigger('gtnFilterPanel', [
            state('true', style({
                height: '*',
                opacity: '1'
            })),
            state('false', style({
                height: '0',
                opacity: '0'
            })),
            transition('true <=> false', [
                animate('300ms')
            ])
        ]);
    }
}
