import { CommonBaseAuditModel } from 'app/nexus-shared/domain/contacts/models/common-base-audit.model';
import { QuestionnaireModel } from 'app/nexus-shared/domain/questionnaires/models/questionnaire.model';

export class IndividualSupplementalOrganizerModel extends CommonBaseAuditModel {
    individualSupplementalOrganizerKey: string;
    questionnaire: QuestionnaireModel;

    constructor(model: IndividualSupplementalOrganizerModel = null) {
        super(model);
        this.individualSupplementalOrganizerKey = model?.individualSupplementalOrganizerKey ?? null;
        this.questionnaire = model?.questionnaire ? new QuestionnaireModel(model.questionnaire) : new QuestionnaireModel();
    }
}
