import { animate, AnimationMetadata, style, transition, trigger } from '@angular/animations';

export class ModalSlideIn {
    /**
     * Use '@modalSlideIn' in markup.
     */
    static animations = ModalSlideIn.getAnimation();

    private static getAnimation(): AnimationMetadata {
        return trigger('modalSlideIn', [
            transition(':enter', [
                style({
                    transform: 'translateX(-100vw)'
                }),
                animate('200ms ease-in', style({
                    transform: 'translateX(0)'
                }))
            ]),
            transition(':leave', [
                style({
                    transform: 'translateX(0)'
                }),
                animate('300ms ease-out', style({
                    transform: 'translateX(-100vw)'
                }))
            ])
        ]);
    }
}
