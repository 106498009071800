import { Component, OnInit } from '@angular/core';
import { BaseFormComponent } from 'app/nexus-shared/components/base-component/base-form.component';
import { SectionColumnModel } from 'app/nexus-shared/domain/questionnaires/models/section-column.model';
import { ObjectHelper } from 'app/nexus-core/helpers/object.helper';

@Component({
    selector: 'gtn-column-editor-header-text-form',
    templateUrl: './column-editor-header-text-form.component.html',
    styleUrls: ['./column-editor-header-text-form.component.scss']
})
export class ColumnEditorHeaderTextFormComponent extends BaseFormComponent<SectionColumnModel> implements OnInit {
    boldFormControlName: string = ObjectHelper.nameOf<SectionColumnModel>('isBoldText');
    italicFormControlName: string = ObjectHelper.nameOf<SectionColumnModel>('isItalicsText');
    redFormControlName: string = ObjectHelper.nameOf<SectionColumnModel>('isRedText');
    underlineFormControlName: string = ObjectHelper.nameOf<SectionColumnModel>('isUnderlineText');

    ngOnInit() {
        super.ngOnInit();
    }

    onButtonStyleClicked(formControlName): void {
        this.formGroupRef.get(formControlName).setValue(!this.formGroupRef.get(formControlName).value);
        this.formGroupRef.markAsDirty();
    }

}
