//NOTE THIS WORKS IN TANDUM WITH THE COMPANY PHONE NUMBER TYPES ENUM
export enum PhoneNumberTypesEnum {
    Unknown = 0,
    Home = 1,
    Mobile = 2,
    Fax = 3,
    Work = 4,
    Main = 5,
    Other = 99
}
