import { BaseFormComponent } from "app/nexus-shared/components/base-component/base-form.component";
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { AgreementsHelper } from "app/nexus-core/helpers/agreements.helper";
import { IAgreementTypeModelInterface } from "../../../interfaces/iagreement-type-model.interface";
import { AgreementTypeEnum } from "../../../enums/agreement-type.enum";
import { SelectListInterface } from "app/nexus-shared/interfaces";
import { DateHelper, EnumHelper } from "app/nexus-core";
import { AgreementSubtypeEnum } from "../../../enums/agreement-subtype.enum";
import { CompanyModel, IndividualModel } from "app/nexus-shared/domain/contacts/models";
import { BaseFormModel } from "app/nexus-shared/models/base-form.model";
import { IAgreementFileModelInterface } from "../../../interfaces/iagreement-file-model.interface";
import { ObjectHelper } from "app/nexus-core/helpers/object.helper";
import { FormGroup, UntypedFormGroup } from "@angular/forms";
import { AgreementStatusEnum } from "../../../enums/agreement-status.enum";
import { IndividualInterface } from "app/nexus-shared/interfaces/individual.interface";
import { AgreementSpouseSendStatusEnum } from "../../../enums/agreement-spouse-send-status.enum";
import { AgreementConsentFormComponent } from "../agreement-consent-form/agreement-consent-form.component";
import { AgreementIELFormComponent } from "../agreement-iel-form/agreement-iel-form.component";

@Component({
    selector: 'gtn-agreement-upload-form',
    templateUrl: './agreement-upload-form.component.html',
    styleUrls: ['./agreement-upload-form.component.scss']
})
export class AgreementUploadFormComponent extends BaseFormComponent<IAgreementFileModelInterface> implements OnInit {
    @Input() agreementTypes: IAgreementTypeModelInterface[];
    @Input() companies: CompanyModel[] = [];
    @Input() displayMainAgreementType: boolean = true;
    @Input() displaySigner: boolean = true;
    @Input() mainAgreementType = AgreementTypeEnum.Unknown;
    @Input() requireFileUpload: boolean = true;
    @Input() reviewers: IndividualInterface[] = [];
    @Input() signerCompany: CompanyModel;
    @Input() signerIndividual: IndividualInterface;
    @Input() signerSpouse: IndividualInterface;
    @Output() fileChange: EventEmitter<void> = new EventEmitter<void>();
    @Output() reviewerChange: EventEmitter<IndividualInterface> = new EventEmitter();
    @Output() sendStatusChange: EventEmitter<AgreementSpouseSendStatusEnum> = new EventEmitter<AgreementSpouseSendStatusEnum>();
    @Output() signerCompanyChange: EventEmitter<CompanyModel> = new EventEmitter<CompanyModel>();
    @Output() signerIndividualChange: EventEmitter<IndividualInterface> = new EventEmitter<IndividualInterface>();
    @Output() spouseNameChange: EventEmitter<string> = new EventEmitter<string>();
    @Output() typeChange: EventEmitter<IAgreementTypeModelInterface> = new EventEmitter<IAgreementTypeModelInterface>();

    @ViewChild('consentForm') consentForm: AgreementConsentFormComponent;
    @ViewChild('ielForm') ielForm: AgreementIELFormComponent;

    agreementFormConfiguration: BaseFormModel = null;
    agreementFormGroupRef: UntypedFormGroup = null;
    agreementsHelper = AgreementsHelper;
    agreementTypeEnum = AgreementTypeEnum;
    agreementStatusEnum = AgreementStatusEnum;
    agreementSubtypeEnum = AgreementSubtypeEnum;

    mainAgreementTypes: SelectListInterface[];

    ngOnInit() {
        const formConfiguration = new BaseFormModel();

        this.agreementFormConfiguration = new BaseFormModel();
        formConfiguration.formConfiguration[ObjectHelper.nameOf<IAgreementFileModelInterface>('agreement')] = this.agreementFormConfiguration;

        super.ngOnInit(formConfiguration);

        this.agreementFormGroupRef = this.formGroupRef.get(ObjectHelper.nameOf<IAgreementFileModelInterface>('agreement')) as FormGroup;
    }

    onFileChange($event: File) {
        const fileValidationPassed = this.processFileValidation($event);
        if (!$event || !fileValidationPassed) {
            this.formGroupRef.get(ObjectHelper.nameOf<IAgreementFileModelInterface>('file')).setValue(null);
            this.formGroupRef.get(ObjectHelper.nameOf<IAgreementFileModelInterface>('tempFileName')).setValue(null);

            this.fileChange.emit();
            return;
        }

        this.formGroupRef.get(ObjectHelper.nameOf<IAgreementFileModelInterface>('file')).setValue($event);

        const generatedFileName = (this.value.agreement?.agreementKey && this.value.agreement.agreementKey !== '') ?
            this.value.agreement.agreementKey :
            DateHelper.convertToFullUtcDate(new Date()).getTime().toString();
        this.formGroupRef.get(ObjectHelper.nameOf<IAgreementFileModelInterface>('tempFileName')).setValue(`${generatedFileName}.pdf`);

        this.fileChange.emit();
    }

    onReviewerChanged($event: IndividualInterface) {
        this.reviewerChange.emit($event);
    }

    onSaveClicked() {
        switch (this.mainAgreementType) {
            case AgreementTypeEnum.ConsentForm:
                this.consentForm?.onSaveClicked();
                break;
            case AgreementTypeEnum.IndependentEngagementLetter:
                this.ielForm?.onSaveClicked();
                break;
            default:
                break;
        }
    }

    onSendStatusChanged($event: AgreementSpouseSendStatusEnum) {
        this.sendStatusChange.emit($event);
    }

    onSignerCompanyChanged($event: CompanyModel) {
        this.signerCompany = new CompanyModel($event);
        this.signerCompanyChange.emit(this.signerCompany);
    }

    onSignerIndividualChanged($event: IndividualModel) {
        this.signerIndividual = new IndividualModel($event);
        this.signerIndividualChange.emit(this.signerIndividual);
    }

    onSpouseNameChanged($event: string) {
        this.spouseNameChange.emit($event);
    }

    onSubtypeChanged($event: IAgreementTypeModelInterface) {
        this.typeChange.emit($event);
    }

    protected initUIControls(): void {
        const definedMainAgreementTypeIds = this.agreementTypes?.map(x => x.type.toString());

        this.mainAgreementTypes = EnumHelper.convertToSelectList(AgreementTypeEnum, false, false).filter(x => definedMainAgreementTypeIds.includes(x.id.toString()));
        this.mainAgreementType = this.value.agreement.agreementType?.type ?? this.mainAgreementType;
    }

    private processFileValidation(file: File): boolean {
        const validationMessage = 'Only PDF files are allowed.';

        switch (file?.type) {
            case 'application/pdf':
            case null:
            case undefined:
                this.validationModels = this.validationModels.filter(x => x.message !== validationMessage);
                return true;
            default:
                this.validationModels.push({ code: '', message: validationMessage, dataBag: null, field: null, fieldValue: null });
                return false;
        }
    }
}
