import { PronounTypesEnum } from 'app/nexus-shared/domain/contacts/enums/pronoun-types.enum';

export class PronounEnumHelper {
    static formatPronouns(pronoun: PronounTypesEnum): string {
        switch (pronoun) {
            case PronounTypesEnum.HeHim:
                return 'He/Him';
            case PronounTypesEnum.SheHer:
                return 'She/Her';
            case PronounTypesEnum.TheyThem:
                return 'They/Them';
            case PronounTypesEnum.Unspecified:
                return 'Unspecified';
            default:
                return '';
        }
    }
}
