export class ValidationModel {
    constructor(code: string, message: string = null, dataBag: any = null, field: string = null, fieldValue: string = null) {
        this.code = code;
        this.message = message;
        this.dataBag = dataBag;
        this.field = field;
        this.fieldValue = fieldValue;
    }

    public readonly code: string;
    public readonly message: string;
    public readonly dataBag: any = null;
    public readonly field: string = null;
    public readonly fieldValue: string = null;
}
