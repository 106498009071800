<gtn-template-readonly-edit-card [(isSaving)]="isSaving" [formGroupRef]="formGroupRef"
    [validationModels]="validationModels" [readonly]="readonly" [showActionButtonsInCard]="true"
    [showCardTitleIcon]="canEdit"
    (readonlyChange)="onReadOnlyChanged($event)" (saveClick)="onSaveClicked()" (cancelClick)="onCancelClicked()">
    <gtn-template-readonly-edit-card-header>{{ pageTitle }}
        <button *ngIf="viewMode === viewModes.Addresses && !readonly" (click)="addAddressClicked()" class="mr-2"
            color="primary"
            mat-mini-fab matTooltip="Add">
            <mat-icon>add</mat-icon>
        </button>
    </gtn-template-readonly-edit-card-header>
    <gtn-template-readonly-edit-card-view-content>
        <gtn-company-view *ngIf="readonly" [viewMode]="viewMode" [value]="value"
            [application]="application"></gtn-company-view>
    </gtn-template-readonly-edit-card-view-content>
    <gtn-template-readonly-edit-card-edit-content>
        <gtn-company-form #companyForm *ngIf="!readonly" [viewMode]="viewMode" [(formGroupRef)]="formGroupRef"
            (formGroupRefChange)="onFormGroupRefChanged($event)"
            [(value)]="value"></gtn-company-form>
    </gtn-template-readonly-edit-card-edit-content>
</gtn-template-readonly-edit-card>
