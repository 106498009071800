import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ValidationModel } from 'app/nexus-shared/models';

@Component({
    selector: 'gtn-validation-pop-over',
    templateUrl: './validation-pop-over.component.html',
    styleUrls: ['./validation-pop-over.component.scss']
})
export class ValidationPopOverComponent {
    @Input() validationModels: ValidationModel[];
    @Input() isVisible: boolean;
    @Output() rowClick: EventEmitter<ValidationModel> = new EventEmitter();

    constructor() {
    }

    onItemClick(model: ValidationModel) {
        this.rowClick.emit(model);
    }
}
